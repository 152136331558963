import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, Form, ModalBody, Card, Row, Col } from "react-bootstrap";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { CurrentPermitActions } from "../../Actions";
import { useDispatch, useSelector } from "react-redux";
import CustomContentAlert from "../helper";
import t from "../../Actions/CurrentPermitActions/types";
import moment from "moment";
import SearchAutocomplete from "../Ticket/SearchAutocomplete";
import { Translation } from "react-i18next";

const EditCurrentPermit = ({ closeModal, permitData, modelOpen, status, type, PageObject, unit_language }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [parkingPermitDisbaleFields, SetParkingPermitDisbaleFields] = useState(false);
  const [state, setState] = useState({
    email: "",
    lpn: "",
    lot: "",
    FirstName: "",
    LastName: "",
    country_code: "",
    phone: "",
    make: "",
    modal: "",
    color: "",
    permit_end_date: "",
    card_number: "",
    employee_reference: "",
    stall_id: "",
    country_code_list: [
      { id: 1, text: "+1", value: "+1" },
      { id: 2, text: "+91", value: "+91" },
      { id: 3, text: "+44", value: "+44" },
      { id: 4, text: "+61", value: "+61" },
    ],
    phone_options: [],
    deleted_phone_id: [],
    permit_class: "",
    lpn_options: [],
    groupid: "",
    group_type: "",
    primary_permit: 0,
    group_permit_count: 0,
    slots: {
      day: 0,
      evening: 0,
      night: 0,
    },
  });

  const dispatch = useDispatch();
  const [validation1, setValidation1] = useState({
    isemail: false,
    isfirstName: false,
    isstall_id: false,
    islastName: false,
    isLPN: false,
    isphone: false,
    disabledDate: true,
  });
  const [validation, setValidation] = useState({
    email: "",
    FirstName: "",
    LastName: "",
    phone: "",
    userType: "",
    lpn: "",
    stall_id: "",
  });

  const [lpn_btn_status, setLpn_btn_status] = useState(true);
  const [costError, setCostError] = useState("");

  let apiMessage = useSelector((currentPermitState) => currentPermitState.CurrentPermitDetails.message);
  const UserProfileDetails = useSelector((UserProfileDetails) => UserProfileDetails.UserProfileDetails);
  const permission_id = useSelector((state) => state.UserProfileDetails?.permission_id);
  const stallList = useSelector((state) => state.ResidenDetails.stall_list);

  const { permit_slots, multiple_permits } = useSelector((state) => state.CurrentPermitDetails);

  useEffect(() => {
    let timeout = null;
    if (apiMessage?.isSuccess && apiMessage.message !== "") {
      timeout = setTimeout(() => {
        closeModal(false);
      }, 1000);
    } else {
      if (apiMessage.message.includes("Permit already exists for license")) {
        setState({
          ...state,
          lpn: "",
          lpn_options: [],
        });
      }
    }

    return () => {
      if (timeout !== null) clearTimeout(timeout);
    };
  }, [dispatch, apiMessage]);

  useEffect(() => {
    if (modelOpen) {
      dispatch({
        type: t.UPDATE_PERMIT_ERROR,
        payload: "",
      });
    }
  }, [modelOpen]);
  
  useEffect(() => {
    /****Remove Disable email for Permit Package. **/
    /*if (permitData.paid_permit_option_id || permitData.paid_permit_type) {
      SetParkingPermitDisbaleFields(true);
    }*/
    if (!multiple_permits) {
      dispatch(CurrentPermitActions.getSlots(permitData.property_id, permitData.id));
    }
  }, []);

  useEffect(() => {
    if (
      (!state.primary_permit && state.groupid && state.group_type === "system") ||
      state.group_type === "user" ||
      state?.group_permit_count >= 2 ||
      state.permit_class === "Visitor" ||
      state.permit_class === "Resident"
    ) {
      setLpn_btn_status(true);
    } else if (state.lpn.toString().length > 0 && state.lpn_options.length === 0) {
      setLpn_btn_status(false);
    } else if (state.lpn_options.length === 1) {
      if (state.lpn_options[0]?.lpn?.toString().length > 0) {
        if (state?.group_permit_count === 1 && state.group_type === "system") {
          setLpn_btn_status(true);
        } else {
          setLpn_btn_status(false);
        }
      } else {
        setLpn_btn_status(true);
      }
    } else {
      setLpn_btn_status(true);
    }
  }, [state.lpn, state.lpn_options]);

  const checkLpnValidation = useCallback(
    (mainObj) => {
      const allLpn = [mainObj.lpn, ...mainObj.lpn_options.map((item) => item.lpn)];

      const checkValueBlank = allLpn.filter((item) => item === "");
      if (checkValueBlank.length === 0 && allLpn.length <= 2 && mainObj.permit_class === "PermitPackage") {
        if (
          (!mainObj?.primary_permit && mainObj?.groupid && mainObj?.group_type === "system") ||
          mainObj?.group_type === "user" ||
          mainObj?.group_permit_count >= 2
        ) {
          setLpn_btn_status(true);
        } else {
          if (mainObj?.primary_permit && mainObj?.group_permit_count === 1 && mainObj?.group_type === "system") {
            setLpn_btn_status(true);
          } else {
            setLpn_btn_status(false);
          }
        }
      } else {
        setLpn_btn_status(true);
      }

      const duplicateLPN = allLpn.filter((item, index) => allLpn.indexOf(item) !== index);
      if (duplicateLPN.length >= 1) {
        setValidation((prevState) => ({
          ...prevState,
          lpn: "Duplicate Plate Number Exists",
        }));
      } else {
        setValidation((prevState) => ({
          ...prevState,
          lpn: "",
        }));
      }
    },
    [state.lpn, state.lpn_options],
  );

  useEffect(() => {
    if (!validation1.isemail && !validation1.isfirstName && !validation1.islastName && !validation1.isLPN && !validation1.isphone && isMultiplePhoneValid()) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
    if (!state.stall_id && state.stall_type === "user_selected") {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [validation1, state.phone_options, state.stall_type, state.stall_id]);

  useEffect(() => {
    if (permitData) {
      const {
        email,
        lot_name,
        country_code,
        phone,
        first_name,
        last_name,
        lpn,
        begin_time,
        expire_time,
        card_number,
        owner_reference,
        stall_type,
        stall_id,
        phone_options,
        permit_class,
        groupid,
        permit_share_group,
        primary_permit,
        lpn_options,
        cost,
        original_stall_id,
        stall_name,
      } = permitData;
      console.log("PermitData on edit:::::::::>>>>>>>>>>.1", permitData);
      let updatedAllStallData = stallList;
      if (stall_id && original_stall_id) {
        updatedAllStallData = [
          ...stallList,
          {
            id: original_stall_id,
            stall_id: stall_id,
            stall_name: stall_name,
          },
        ];
      }

      setState({
        ...state,
        email: email,
        stall_id: stall_id,
        stall_type: stall_type,
        original_stall_id: original_stall_id,
        allStallData: updatedAllStallData,
        stall_name: stall_name,
        lpn: lpn,
        lot: lot_name,
        FirstName: first_name,
        LastName: last_name,
        card_number: card_number,
        employee_reference: owner_reference,
        country_code: country_code,
        phone: phone,
        cost: cost,
        color: permitData.car_color,
        is_extended_lpn_info: permitData.is_extended_lpn_info,
        make: permitData.car_make,
        modal: permitData.car_model,
        permit_start_date: begin_time,
        permit_end_date: expire_time,
        country_code_list: [
          { id: 1, text: "+1", value: "+1" },
          { id: 2, text: "+91", value: "+91" },
          { id: 3, text: "+44", value: "+44" },
          { id: 4, text: "+61", value: "+61" },
        ],
        phone_options: phone_options,
        permit_class: permit_class,
        groupid: groupid,
        group_type: permit_share_group?.group_type,
        primary_permit: primary_permit,
        group_permit_count: lpn_options?.length,
      });
      const expire_date = moment(expire_time).format("YYYY-MM-DD HH:mm:SS");
      const convertedExpireTime = moment.tz(expire_date, UserProfileDetails.timezone).format();
      const currentDate = new Date().toLocaleString("en-US", {
        timeZone: UserProfileDetails.timezone,
      });
      const formatedCurrentTime = moment(currentDate).format("YYYY-MM-DD HH:mm:SS");
      const convertedCurrentTime = moment.tz(formatedCurrentTime, UserProfileDetails.timezone).format();
      if (convertedExpireTime > convertedCurrentTime) {
        setState((prevState) => ({
          ...prevState,
          disabledDate: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          disabledDate: false,
        }));
      }
    }
  }, [permitData, stallList]);

  useEffect(() => {
    if (permit_slots) {
      const slots = {};
      permit_slots.forEach((permit_slot) => {
        slots[permit_slot.slot] = permit_slot?.credit_allocated || 0;
      });
      setState((prevState) => ({
        ...prevState,
        slots,
      }));
    }
  }, [permit_slots]);

  const PhoneValidation = (phone) => {
    if ((phone && phone.length >= 11) || (phone && phone.length < 10)) {
      setIsFormValid(true);
      return "Please  enter valid Phone Number";
    } else if (!/^\d+$/.test(phone)) {
      return "Please  enter valid Phone Number";
    } else {
      setIsFormValid(false);
      return "";
    }
  };

  const isMultiplePhoneValid = () => {
    // WORLD-1128
    const phoneListCount = {};
    const phoneList = state.phone_options.map((item) => {
      return item.country_code + item.phone;
    });
    phoneList.push(state.country_code + state.phone);
    phoneList.forEach(function (x) {
      phoneListCount[x] = (phoneListCount[x] || 0) + 1;
    });
    const phoneListStatus = Object.keys(phoneListCount).filter((item) => phoneListCount[item] > 1);
    const validPhone = state.phone_options.map((options) => {
      if (options.phone.trim().length > 10 || options.phone.trim().length < 10 || options.phone.trim().length === 0 || phoneListStatus.length) {
        return !(options.phone.trim().length > 10 || options.phone.trim().length < 10 || options.phone.trim().length === 0 || phoneListStatus.length);
      }
      return true;
    });
    // WORLD-1128
    return !validPhone.includes(false);
  };

  const permitUpdate = () => {
    const {
      email,
      country_code,
      phone,
      FirstName,
      LastName,
      permit_end_date,
      card_number,
      employee_reference,
      original_stall_id,
      stall_id,
      phone_options,
      deleted_phone_id,
      lpn,
      lpn_options,
      groupid,
      cost,
      slots,
      make,
      color,
      modal,
      stall_type,
    } = state;
    permitData.lpn_options = [];
    const expire_date = moment(permit_end_date).format("YYYY-MM-DD HH:mm:ss");
    const convertedExpireTime = moment.tz(expire_date, UserProfileDetails.timezone).format();
    const UTCET = moment.tz(convertedExpireTime, "UTC").format();
    const data = {
      email,
      country_code,
      phone,
      first_name: FirstName,
      last_name: LastName,
      expire_time: UTCET,
      card_number: card_number,
      owner_reference: employee_reference,
      stall_id: stall_id,
      stall_type,
      original_stall_id,
      old_data: permitData,
      status,
      phone_options,
      deleted_phone_id,
      lpn,
      lpn_options,
      groupid,
      cost,
      slots,
      car_make: make,
      car_color: color,
      car_model: modal,
    };
    if (type) {
      data.permit_type = "PassagePass";
      data.status = "current";
    }
    dispatch(CurrentPermitActions.updatePermit(permitData.id, data, PageObject));
  };

  const handleAddPhoneOptions = () => {
    setState({
      ...state,
      phone_options: [...state.phone_options, { country_code: "+1", phone: "" }],
    });

    console.log("state.phone_options:::", state.phone_options);
  };

  const handlePhoneOptions = (index, value, type) => {
    const { phone_options } = state;
    let phone_values;
    let country_code_values;

    setState({
      ...state,
      phone_options: state.phone_options.map((item, i) => {
        const obj = { ...item };
        if (i === index) {
          if (type === "phone_number_options") {
            phone_values = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
            obj.phone = phone_values;
          }
          if (type === "country_code_options") {
            country_code_values = value;
            obj.country_code = country_code_values;
          }
        }
        return obj;
      }),
    });

    console.log("phone_options::::", phone_options);
  };

  const removePhoneOption = (index) => {
    const deleted_ID = state.phone_options[index];
    setState({
      ...state,
      phone_options: [...state.phone_options.filter((_x, i) => i !== index)],
      deleted_phone_id: [...state.deleted_phone_id, deleted_ID],
    });
  };

  const handleChange = useCallback(
    (e) => {
      console.log("e.....................", e);
      let {
        target: { name, value },
      } = e;
      let Obj = {};
      if (name === "day" || name === "evening" || name === "night") {
        let finalCost;
        let newValue = state.slots[name];

        if (/^\d{1,2}(\.\d{0,2}|\.\d{0,2})?$/.test(value)) {
          newValue = Number(value);
        } else {
          if (value === "") {
            newValue = 0;
          }
        }

        const updatedSlots = { ...state.slots, [name]: newValue };
        const tempCost = Number(updatedSlots.day || 0) + Number(updatedSlots.evening || 0) + Number(updatedSlots.night || 0);
        if (tempCost <= 99) {
          finalCost = tempCost;
        } else {
          setCostError("The total cost cannot exceed 99. Please adjust your values.");
          setTimeout(() => {
            setCostError("");
          }, 3000);
          finalCost = Number(state.cost || 0);
          updatedSlots[name] = state.slots[name];
        }
        const updatedCost = isNaN(finalCost) ? "" : finalCost.toString();
        const Obj = {
          ...state,
          slots: updatedSlots,
          cost: updatedCost,
        };
        setState(Obj);
        checkLpnValidation(Obj);
      } else {
        if (name === "lpn") {
          value = value.toUpperCase().replace(/[^a-zA-Z0-9]/g, "");
        } else if (name === "country_code" || name === "phone" || name === "card_number") {
          value = value.replace(/[a-zA-Z]/, "");
          setValidations(e);
        } else if (name === "stall_id") {
          const selectedStall = e.target.selectedOptions[0];
          console.log("selectedStall::::::::::::::::", selectedStall, selectedStall.value);
          const dropObj = {
            ...state,
            stall_id: selectedStall.value || "",
            original_stall_id: selectedStall.getAttribute("id"),
            stall_name: selectedStall.text,
          };
          console.log("dropObj:::::::::::::::", dropObj);
          setState(dropObj);
          setValidations(e);
        } else if (name === "driver_stall_id") {
          const StallObj = {
            ...state,
            stall_id: value,
          };
          setState(StallObj);
        } else if (name === "cost") {
          let newValue = state.cost;
          if (/^\d{1,2}(\.\d{0,2}|\.\d{0,2})?$/.test(value)) {
            newValue = Number(value);
          } else {
            if (value === "") {
              newValue = 0;
            }
          }
          value = newValue;
        } else {
          setValidations(e);
        }
        Obj = { ...state, [name]: value };
        setState((oldState) => ({ ...oldState, [name]: value }));
        checkLpnValidation(Obj);
      }
    },
    [state],
  );

  const setValidations = useCallback(
    (e) => {
      if (!e.target.value) {
        if (state.stall_type === "user_selected" && e.target.name === "stall_id") {
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "Stall is Required",
          }));
        } else {
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.name !== "stall_id" && e.target.name + " is required",
          }));
        }

        setIsFormValid(false);
        if (e.target.name === "card_number" || e.target.name === "employee_reference" || e.target.name === "stall_id") {
          setIsFormValid(true);
        }
      } else {
        if (e.target.name === "lpn") {
          setValidation1((prevState) => ({
            ...prevState,
            isLPN: false,
          }));
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
        } else if (e.target.name === "LastName") {
          setValidation1((prevState) => ({
            ...prevState,
            islastName: false,
          }));
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
        } else if (e.target.name === "stall_id" && state.stall_type === "user_selected") {
          setValidation1((prevState) => ({
            ...prevState,
            isstall_id: false,
          }));
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
        } else if (e.target.name === "FirstName") {
          setValidation1((prevState) => ({
            ...prevState,
            isfirstName: false,
          }));
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
        } else if (e.target.name === "email") {
          const regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value && !regex.test(e.target.value) ? "Please enter valid email" : "",
          }));
          if (e.target.value && regex.test(e.target.value)) {
            setValidation1((prevState) => ({
              ...prevState,
              isemail: false,
            }));
          } else {
            setValidation1((prevState) => ({
              ...prevState,
              isemail: true,
            }));
          }
        } else if (e.target.name === "phone") {
          const errMsg = PhoneValidation(e.target.value);
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: errMsg,
          }));

          if (e.target.value && errMsg === "") {
            setValidation1((prevState) => ({
              ...prevState,
              isphone: false,
            }));
          } else {
            setValidation1((prevState) => ({
              ...prevState,
              isphone: true,
            }));
          }
        } else {
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
        }
      }
      // }
    },
    [validation, state],
  );

  const handleAddLpnCarDescription = () => {
    const mainObj = {
      ...state,
      lpn_options: [...state.lpn_options, { lpn: "" }],
    };
    setState(mainObj);
    checkLpnValidation(mainObj);
  };

  const handleLpnCarOptions = (index, e, type) => {
    const {
      target: { value },
      //  target,
    } = e;
    const { lpn_options } = state;
    if (lpn_options.length < 2) {
      setLpn_btn_status(false);
    }
    let lpn_values;
    const mainObj = {
      ...state,
      lpn_options: lpn_options.map((item, i) => {
        const obj = { ...item };
        if (i === index && type === "lpn_options") {
          lpn_values = value.toUpperCase().replace(/[^a-zA-Z0-9]/g, "");
          obj.lpn = lpn_values;
        }
        return obj;
      }),
    };

    const obj = { ...state, ...mainObj };

    setState(obj);

    checkLpnValidation(obj);
  };

  const removeLpnCarOption = (index) => {
    const mainObj = {
      ...state,
      lpn_options: [...(state?.lpn_options?.filter((_x, i) => i !== index) || [])],
    };
    setState(mainObj);
    checkLpnValidation(mainObj);
  };

  const handleChangeEndingDates = (date) => {
    const expire_date = new Date(date);
    setState((prevState) => ({
      ...prevState,
      permit_end_date: expire_date,
      disabledDate: true,
    }));
  };
  return (
    <Modal id="ImportPermits" lg={"medium"} backdrop={"static"} show={true} onHide={closeModal} dialogClassName="modal-90w" fullscreen={"sm-down"}>
      <Card.Header className="d-flex justify-content-between bg-white align-items-center">
        <h4 className="m-0 font-weight-bold">
          <Card.Title className="m-0">
            {" "}
            <Translation>{(t) => <>{t("Edit Permit")}</>}</Translation>
          </Card.Title>
        </h4>
        <Translation>
          {(t) => (
            <Button title={t("Deactivate")} type="button" onClick={closeModal} className="close-btn py-0" data-dismiss="modal">
              <i className="nc-icon nc-simple-remove text-danger" />
            </Button>
          )}
        </Translation>
      </Card.Header>

      <ModalBody>
        <Form.Group style={{ marginTop: -20 }}>
          <Row>
            <Col>
              <Form.Label>
                <Translation>{(t) => <>{t("Licence Plate No")}</>}</Translation>
              </Form.Label>
              <Form.Control
                placeholder="LPN"
                type="text"
                name="lpn"
                value={state?.lpn}
                autoComplete="off"
                onChange={handleChange}
                maxLength="11"
                disabled={state?.permit_class !== "PermitPackage"}
              />
              <div className="help-block">{!_.isEmpty(validation) ? validation.lpn : ""}</div>
            </Col>
            <Col className="pl-sm-0 col-auto">
              <Form.Label className={`invisible d-sm-block d-none ${unit_language === "fn" ? "mb-sm-4" : ""}`}>
                <Translation>{(t) => <>{t("Licence Plate No")}</>}</Translation>
              </Form.Label>
              <div className="pt-2">
                <Button size="sm" className="h-40px px-1 d-block" disabled={lpn_btn_status} onClick={handleAddLpnCarDescription}>
                  <Translation>{(t) => <>{t("Add Plate")}</>}</Translation>.
                </Button>
              </div>

              <div className="help-block invisible">{!_.isEmpty(validation) ? validation.lpn : ""}</div>
            </Col>
          </Row>
          {state.lpn_options &&
            typeof state.lpn_options === "object" &&
            Array.isArray(state.lpn_options) &&
            state.lpn_options.map((option, index) => {
              return (
                <Row key={option?.id} className="">
                  <Col className="">
                    <Form.Group>
                      <Form.Label>
                        {" "}
                        <Translation>{(t) => <>{t("Licence Plate No")}</>}</Translation>. {index + 1}
                      </Form.Label>
                      <span className="asterisk">*</span>
                      <Form.Control
                        type="text"
                        name="lpn_options"
                        onChange={(e) => handleLpnCarOptions(index, e, "lpn_options")}
                        value={option?.lpn}
                        maxLength="11"
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="col-auto pl-0">
                    <div>
                      <label>&nbsp;</label>
                    </div>
                    <Button size="sm" className="h-40px px-1" onClick={() => removeLpnCarOption(index)}>
                      <Translation>{(t) => <>{t("Remove Plate")}</>}</Translation>.
                    </Button>
                  </Col>
                  <br />
                </Row>
              );
            })}

          <Row>
            <Col>
              <label>
                {" "}
                <Translation>{(t) => <>{t("Lot Name")}</>}</Translation>
              </label>
              <Form.Control placeholder="phone" type="text" name="lot" value={state?.lot} autoComplete="off" disabled />
            </Col>
          </Row>
          <label>
            <Translation>{(t) => <>{t("Email")}</>}</Translation>
            <span className="asterisk">*</span>
          </label>
          <Form.Control
            placeholder="email"
            type="email"
            name="email"
            value={state?.email}
            onChange={handleChange}
            autoComplete="off"
            required
            disabled={parkingPermitDisbaleFields}
          />
          <div className="help-block">{!_.isEmpty(validation) ? validation.email : ""}</div>

          <label>
            <Translation>{(t) => <>{t("First Name")}</>}</Translation> <span className="asterisk">*</span>
          </label>
          <Form.Control placeholder="First Name" type="text" name="FirstName" value={state?.FirstName} onChange={handleChange} autoComplete="off" required />
          <div className="help-block">{!_.isEmpty(validation) ? validation.FirstName : ""}</div>
          <label>
            <Translation>{(t) => <>{t("Last Name")}</>}</Translation>
            <span className="asterisk">*</span>
          </label>
          <Form.Control placeholder="Last Name" type="text" name="LastName" value={state?.LastName} onChange={handleChange} autoComplete="off" required />
          <div className="help-block">{!_.isEmpty(validation) ? validation.LastName : ""}</div>
          <label>
            <Translation>{(t) => <>{t("Access Card/ Tag")}</>}</Translation>
          </label>
          <Form.Control
            placeholder="Card Number"
            type="text"
            name="card_number"
            value={state?.card_number}
            onChange={handleChange}
            autoComplete="off"
            required
          />
          <label>
            <Translation>{(t) => <>{t("Employee Reference")}</>}</Translation>
          </label>
          <Form.Control
            placeholder="Employee Reference"
            type="text"
            name="employee_reference"
            value={state?.employee_reference}
            onChange={handleChange}
            autoComplete="off"
            required
          />
          {state.permit_class.toLowerCase() === "visitor" && (
            <>
              <label>
                <Translation>{(t) => <>{t("Cost")}</>}</Translation>
              </label>
              <Form.Control
                placeholder="Cost"
                type="text"
                name="cost"
                value={state?.cost || 0}
                onChange={handleChange}
                autoComplete="off"
                disabled={!multiple_permits && permit_slots?.length > 0 && state?.permit_class.toLowerCase() === "visitor"}
                required
              />
            </>
          )}
          {state.permit_class.toLowerCase() === "visitor" && permission_id !== 2 && (
            <>
              {!multiple_permits ? (
                <>
                  {permit_slots?.length >= 0 &&
                    permit_slots.map((permit_slot) => (
                      <>
                        <label>{permit_slot.slot}</label>
                        <Form.Control
                          placeholder="Cost"
                          type="text"
                          name={permit_slot.slot}
                          value={state?.slots?.[permit_slot.slot]}
                          onChange={handleChange}
                          autoComplete="off"
                          required
                        />
                      </>
                    ))}
                </>
              ) : null}
            </>
          )}
          {UserProfileDetails.user_type !== "unit" && (
            <>
              {state.permit_class === "PermitPackage" && state.stall_type !== "no_stall" && (
                <>
                  {state.group_type !== "system" || state.primary_permit ? (
                    // <Col xl="2" lg="4" md="6" sm="12">
                    <Form.Group controlId="exampleForm.ControlSelect1" className="mb-3">
                      <Form.Label className="mb-0">Select Stall:</Form.Label>
                      <Form.Control
                        as="select"
                        name="stall_id"
                        value={state.stall_id}
                        onChange={handleChange}
                        placeholder="Select Stall"
                        disabled={!state.allStallData?.length}
                      >
                        <option key="" id="" value="">
                          --Select Stall--
                        </option>
                        {state.allStallData?.length &&
                          state.allStallData.map((item) => (
                            <option key={item.id} id={item.id} value={item.stall_id}>
                              {item.stall_name}
                            </option>
                          ))}
                      </Form.Control>
                      <div className="help-block">{!_.isEmpty(validation) ? validation.stall_id : ""}</div>
                      <label className="mt-2">{!state.allStallData?.length && "Note: There are no stall available for this lot"}</label>
                    </Form.Group>
                  ) : (
                    // </Col>
                    <></>
                  )}
                </>
              )}
              {(state.permit_class !== "PermitPackage" || state.stall_type === "no_stall") && (
                // <Col xl="2" lg="4" md="8" sm="12">
                <Form.Group className="mb-3">
                  <label>
                    <Translation>{(t) => <>{t("Driver Stall Number")}</>}</Translation>
                  </label>
                  <Form.Control
                    placeholder="P1-10"
                    type="text"
                    name="driver_stall_id"
                    value={state.stall_id}
                    onChange={handleChange}
                    disabled={permission_id === 2}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
                // </Col>
              )}
            </>
          )}

          {state.is_extended_lpn_info && state.permit_class.toLowerCase() === "resident" && (
            <Row>
              <Col md="4" lg="4" xl="4" className="mb-3">
                <SearchAutocomplete
                  isRequired
                  label={"Make"}
                  name={"make"}
                  value={state.make}
                  type={"make"}
                  fromResidentPermit
                  handleChange={(value) => {
                    handleChange({
                      target: {
                        name: "make",
                        value: value.make,
                      },
                    });
                  }}
                />
              </Col>
              <Col md="4" lg="4" xl="4" className="mb-3">
                <SearchAutocomplete
                  isRequired={true}
                  label={"Model"}
                  name={"modal"}
                  value={state.modal}
                  type={"model"}
                  fromResidentPermit
                  make={state.make}
                  handleChange={(value) => {
                    if (!state.make && value.make) {
                      setTimeout(() => {
                        handleChange({
                          target: {
                            name: "make",
                            value: value.make,
                          },
                        });
                      }, 500);
                    }
                    handleChange({
                      target: {
                        name: "modal",
                        value: value.model,
                      },
                    });
                  }}
                />
              </Col>
              <Col md="4" lg="4" xl="4" className="mb-3">
                <SearchAutocomplete
                  isRequired={true}
                  label={"Color"}
                  name={"color"}
                  value={state.color}
                  type={"color"}
                  fromResidentPermit
                  defaultOptions={["White", "Black", "Gray", "Silver", "Red", "Blue", "Brown", "Green", "Beige", "Orange", "Gold", "Yellow", "Purple"]}
                  handleChange={(value) => {
                    handleChange({
                      target: {
                        name: "color",
                        value: value.color,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
          )}
          <div className="custom-time d-flex align-items-center mb-1">
            <div className="d-flex align-items-center sm-flex-column">
              <div className="my-3">
                <label className="mr-1">
                  <Translation>{(t) => <>{t("End Date")}</>}</Translation>
                  {state.disabledDate}
                  <span className="asterisk">*</span>
                </label>
                <DatePicker
                  placeholder="04/05/16"
                  minDate={new Date()}
                  selected={state.permit_end_date ? new Date(state.permit_end_date) : new Date()}
                  disabled={!state.disabledDate || parkingPermitDisbaleFields}
                  onChange={handleChangeEndingDates}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeSelect
                  selectsEnd
                />

                <div className="help-block">{state.disabledDate === false ? "This date is expired so you can not edit." : ""}</div>
              </div>
            </div>
          </div>
          <Row>
            <Col className="col-auto">
              <label>
                <Translation>{(t) => <>{t("Country Code")}</>}</Translation>
              </label>
              <Form.Control className="w-85px" as="select" name="country_code" onChange={handleChange} value={state?.country_code} required>
                {state?.country_code_list && (
                  <>
                    {(state.country_code_list ?? []).map((item) => (
                      <option key={item?.id} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </>
                )}
              </Form.Control>
            </Col>
            <Col className="col p-0">
              {" "}
              <label>
                <Translation>{(t) => <>{t("Phone")}</>}</Translation>
                <span className="asterisk">*</span>
              </label>
              <Form.Control
                placeholder="phone"
                type="text"
                name="phone"
                value={state?.phone}
                onChange={handleChange}
                autoComplete="off"
                maxLength="10"
                required
              />
              <div className="help-block">{!_.isEmpty(validation) ? validation.phone : ""}</div>
            </Col>
            <Col className="col-auto">
              <div>
                <label>&nbsp;</label>
              </div>
              <Button
                size="sm"
                className="h-40px"
                disabled={state.phone_options?.length > 9 || state?.phone?.trim()?.length === 0 || !isMultiplePhoneValid()}
                onClick={handleAddPhoneOptions}
              >
                <i className="fas fa-plus-circle"></i>
              </Button>
            </Col>
          </Row>
          {state.phone_options &&
            typeof state.phone_options === "object" &&
            Array.isArray(state.phone_options) &&
            state.phone_options.map((option, index) => {
              return (
                <Row key={option?.country_code} className="my-0 mt-2">
                  <Col className="col-auto">
                    <Form.Group>
                      <Form.Control
                        className="w-85px"
                        as="select"
                        name="country_code_options"
                        onChange={(e) => handlePhoneOptions(index, e.target.value, "country_code_options")}
                        value={option?.country_code}
                        required
                      >
                        {state.country_code_list ? (
                          <>
                            {(state.country_code_list ?? []).map((item) => (
                              <option key={item?.id} value={item.value}>
                                {item.text}
                              </option>
                            ))}
                          </>
                        ) : null}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="col p-0">
                    <Form.Group className="p-0">
                      <Form.Control
                        placeholder="Phone Number"
                        type="text"
                        name="phone_number_options"
                        value={option?.phone}
                        // onBlur={(e) => e.target.value.trim() === "" && removeNoteOption(index)}
                        onChange={(e) => handlePhoneOptions(index, e.target.value, "phone_number_options")}
                        maxLength="10"
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-auto">
                    <Button size="sm" className="h-40px" onClick={() => removePhoneOption(index)}>
                      <i className="fas fa-minus"></i>
                    </Button>
                  </Col>
                </Row>
              );
            })}
        </Form.Group>
      </ModalBody>

      <Modal.Footer>
        <Button onClick={closeModal}>
          <Translation>{(t) => <>{t("Cancel")}</>}</Translation>
        </Button>
        <Button variant="primary" disabled={!isFormValid} onClick={permitUpdate}>
          <Translation>{(t) => <>{t("Update")}</>}</Translation>
        </Button>
      </Modal.Footer>
      {apiMessage && apiMessage.message !== "" && (
        <Translation>
          {(t) => <CustomContentAlert delay={4000} message={t(apiMessage.message)} className={apiMessage.isSuccess ? "toast-success" : "toast-error"} />}
        </Translation>
      )}
      {costError && <Translation>{(t) => <CustomContentAlert delay={4000} message={t(costError)} className={"toast-error"} />}</Translation>}
    </Modal>
  );
};

export default EditCurrentPermit;

import t from "./types";
import loginType from "../LoginActions/types";
import premiType from "../CurrentPermitActions/types";
import apiType from "../APIResponseActions/type";
import {
  LoginAPI,
  AuthAPI,
  UnAuthAPI,
  RefreshAuthAPI,
  // get_config,
} from "../../../Auth/requestHandler";
// import mainReducer from "..Reducers/";
// import mainReducer from '../../Reducers/index';
// import reduxStore from '../../redux_store/index'
import Dexie from "dexie";
import db from "../../Components/Enforcement/dixie";
const rootScope = { user: {} };

export const checkLink = (loginObj) => async (dispatch) => {
  try {
    const res = await LoginAPI("/auth", "POST", { ...loginObj, flag: false });
    console.log("checkLink response::", res);
    if (res?.success === 1) {
      const isLinkExist = "link" in res;
      if (res.user_type !== "superadmin" && isLinkExist) {
        const encodedUsr = encodeURIComponent(loginObj.username);
        const encodedPwd = encodeURIComponent(loginObj.password);
        const link = res.link + "/redirect/" + encodedUsr + "/" + encodedPwd;
        const high_security = res.high_security;

        console.log("checkLink high_security::", res.high_security);
        return { link, high_security };
      }
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: res.message,
      });
    }
  } catch (error) {
    if (localStorage.getItem("basicAuth")) localStorage.removeItem("basicAuth");
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "The server did not respond, Please try again after sometime",
    });
  }
};
function setCrossSubdomainCookie(name, value, days) {
  try {
    const assign = name + "=" + escape(value) + ";";
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString() + ";";
    const path = "path=/;";
    const documentDomain = document.domain.match(/[^.]*\.[^.]*$/) || "";
    const domain = "domain=" + documentDomain + ";";
    document.cookie = assign + expires + path + domain;
  } catch (e) {
    console.log(e);
  }
}

export const userLogin = (loginObj, isAutoLogin, authToken) => async (dispatch) => {
  let res = null;
  try {
    if (isAutoLogin) {
      res = await AuthAPI("domain", "user/auto/auth", "GET", null, authToken);
    } else {
      res = await LoginAPI("/auth", "POST", { ...loginObj, flag: true });
    }
    if (res.token) {
      await handleSuccessfulLogin(dispatch, res, loginObj, isAutoLogin);
    } else {
      await handleFailedLogin(dispatch, res);
    }
    return res;
  } catch (error) {
    await removeLocalStorage();
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message || "The server did not respond, Please try again after sometime",
    });
    return null;
  }
};

const handleSuccessfulLogin = async (dispatch, res, loginObj, isAutoLogin) => {
  const { high_security, changePassword } = res;
  console.log("res:---", res);
  await dispatch({ type: loginType.HIGH_SECURITY, payload: high_security });

  if (high_security && !isAutoLogin) {
    await handleHighSecurityLogin(dispatch, res, loginObj, changePassword);
  } else {
    await handleBasicLogin(dispatch, res, loginObj);
  }
};

const handleBasicLogin = async (dispatch, res, loginObj) => {
  const { token, refresh_login_token } = res;
  // Save tokens to localStorage and cookies
  saveTokens(token, refresh_login_token);

  if (res.success === 1) {
    const success = res.success === 1;
    await dispatch({ type: loginType.USER_LOGIN, payload: success });
    await handleAllUserOnBasicLogin(dispatch, res, loginObj, success);
  }
};

const handleAllUserOnBasicLogin = async (dispatch, res, loginObj, success) => {
  const { user_type, property_id, admin_login, link, timezone, token } = res;
  const isLinkExist = "link" in res;
  const tokenVariable = token;
  const url = "user/profile/self";

  if (user_type !== "superadmin") {
    const userDataResponse = await AuthAPI("domain", url, "GET");
    console.log("userDataResponse:---", userDataResponse);
    const appConfigURL = "site_setting/" + property_id;
    const appConfigResponse = await AuthAPI("domain", appConfigURL, "GET"); // this is for non-superadmin user
    console.log("appConfigResponse:---", appConfigResponse);
    const appConfig = {
      availableSpots: appConfigResponse.available_spot,
      globaleCredit: appConfigResponse.credits,
      ...appConfigResponse,
    };
    const domain = appConfigResponse?.property?.domain;
    localStorage.setItem("page-title", domain);
    userDataResponse.property_id = property_id;
    const userData = {
      ...userDataResponse,
      user_type: user_type,
      admin_login: admin_login,
      user: user_type === "admin" ? user_type : userDataResponse.email,
      timezone: timezone,
      id: userDataResponse.id ? userDataResponse.id : "",
    };
    const username = loginObj ? loginObj.username : userDataResponse.username;
    rootScope.user.basicAuth = tokenVariable;
    rootScope.user.info = userData;
    rootScope.user.login = username;
    rootScope.user.property_id = property_id;
    const userObj = {
      basicAuth: tokenVariable,
      menu: true,
      link: isLinkExist ? link : "",
      username: username,
      ...appConfig,
      ...userData,
    };
    await dispatch({ type: loginType.USER_LOGIN, payload: success });
    await dispatch({
      type: loginType.USER_PROFILE_INFO,
      payload: userObj,
    });
    await dispatch({ type: t.FETCH_USERPROFILE, payload: userObj });
  } else {
    const userDataResponse = await AuthAPI("domain", url, "GET");
    const userData = constructUserData(userDataResponse, user_type, admin_login, property_id, timezone);
    const username = loginObj ? loginObj.username : userDataResponse.username;
    rootScope.user.basicAuth = tokenVariable;
    rootScope.user.info = userData;
    rootScope.user.login = username;
    rootScope.user.property_id = property_id;
    localStorage.setItem("user", JSON.stringify(rootScope.user));
    const userObj = {
      basicAuth: tokenVariable,
      menu: true,
      link: isLinkExist ? res.link : "",
      username: username,
      ...userData,
    };

    await dispatch({ type: loginType.USER_LOGIN, payload: success });
    await dispatch({
      type: loginType.USER_PROFILE_INFO,
      payload: userObj,
    });
    await dispatch({ type: t.FETCH_USERPROFILE, payload: userObj });
  }
};

const constructUserData = (userDataResponse, user_type, admin_login, property_id, timezone) => {
  const { email, available_spot, credits } = userDataResponse;
  const domain = userDataResponse?.property?.domain ? userDataResponse?.property?.domain : "";
  localStorage.setItem("page-title", domain);
  return {
    ...userDataResponse,
    property_id,
    user_type,
    user: user_type === "admin" ? user_type : email,
    timezone,
    admin_login,
    availableSpots: available_spot,
    globaleCredit: credits,
  };
};

const saveTokens = (token, refresh_token) => {
  localStorage.setItem("basicAuth", JSON.stringify(token));
  setCrossSubdomainCookie("basicAuth", token, 365);
  localStorage.setItem("basicRefreshAuth", JSON.stringify(refresh_token));
  setCrossSubdomainCookie("basicRefreshAuth", refresh_token, 365);
};

const handleHighSecurityLogin = async (dispatch, res, loginObj, changePassword) => {
  await dispatch({ type: loginType.HIGH_SECURITY_RESULT, payload: res });
  await dispatch({ type: loginType.HIGH_SECURITY_LOGINOBJ, payload: loginObj });
  await dispatch({ type: loginType.CHANGE_PASSWORD, payload: changePassword });
};

const handleFailedLogin = async (dispatch, res) => {
  const { high_security, changePassword, message, failed_login_attempts, already_active_user } = res;

  if (already_active_user && high_security === 1) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: "" });
  } else {
    await dispatch({ type: loginType.HIGH_SECURITY, payload: high_security });
    await dispatch({ type: loginType.CHANGE_PASSWORD, payload: changePassword });
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: message });
    await dispatch({ type: apiType.API_RESPONSE_FAILURE_ATTEMPTS, payload: failed_login_attempts });
  }
};

export const semiLogin = (userName, epochTime, domain, menu, key) => async (dispatch) => {
  try {
    const res = await UnAuthAPI("semi/auth/" + userName + "/" + epochTime + "/" + domain + "/" + key, "GET");
    localStorage.setItem("basicAuth", JSON.stringify(res.token));
    if (res.token) {
      setCrossSubdomainCookie("basicAuth", res.token, 365);
      const url = "user/profile/self";
      localStorage.setItem("basicAuth", JSON.stringify(res.token));
      const tokenVariable = res.token;
      const success = res.success === 1;
      await dispatch({ type: loginType.USER_LOGIN, payload: success });
      const userDataResponse = await AuthAPI("domain", url, "GET");
      const appConfigURL = "site_setting/" + res.property_id;
      const appConfigResponse = await AuthAPI("domain", appConfigURL, "GET");
      const appConfig = {
        availableSpots: appConfigResponse.available_spot,
        globaleCredit: appConfigResponse.credits,
        ...appConfigResponse,
      };
      userDataResponse.property_id = res.property_id;
      const userData = {
        ...userDataResponse,
        user_type: res.user_type,
        admin_login: res.admin_login,
        user: res.user_type === "admin" ? res.user_type : userDataResponse.email,
        timezone: res.timezone,
        id: userDataResponse.id ? userDataResponse.id : "",
      };
      const username = userDataResponse.username;
      rootScope.user.basicAuth = tokenVariable;
      rootScope.user.info = userData;
      rootScope.user.login = username;
      rootScope.user.property_id = res.property_id;
      const userObj = {
        basicAuth: tokenVariable,
        menu: menu,
        username: username,
        ...appConfig,
        ...userData,
      };
      await dispatch({ type: loginType.USER_LOGIN, payload: success });
      await dispatch({
        type: loginType.USER_PROFILE_INFO,
        payload: userObj,
      });
      await dispatch({ type: t.FETCH_USERPROFILE, payload: userObj });
    } else {
      await removeLocalStorage();
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: res.message,
      });
    }
  } catch (error) {
    await removeLocalStorage();
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message || "The server did not respond, Please try again after sometime",
    });
  }
};

const removeLocalStorage = async () => {
  if (localStorage.getItem("basicAuth")) localStorage.removeItem("basicAuth");
  if (localStorage.getItem("persist:root")) localStorage.removeItem("persist:root");
};

export const getBrandingData = () => async (dispatch) => {
  try {
    const url = "user/profile/self";
    const userResponse = await AuthAPI("domain", url, "GET");
    if (userResponse?.success === false) {
      await dispatch({ type: t.FETCH_USERPROFILE, payload: {} });
    } else {
      const backgroundColor = {
        menuHeader: userResponse.menu_header,
        menuBackground: userResponse.menu_background,
        mainBodyBackground: userResponse.main_body_background,
        MainBackground: userResponse.main_body,
        headerLogo: userResponse.header_logo,
        footerLogo: userResponse.footer_logo,
        footerText: userResponse.footer_text,
        userType: userResponse.user_type,
      };
      const domain = userResponse?.property?.domain ? userResponse?.property?.domain : "";

      localStorage.setItem("page-title", domain);
      const userData = {
        ...userResponse,

        availableSpots: userResponse.available_spot,
        globaleCredit: userResponse.credits,
      };
      rootScope.user.info = userData;

      localStorage.setItem("user", JSON.stringify(rootScope.user));
      const userObj = {
        ...userData,
      };

      await dispatch({
        type: loginType.USER_PROFILE_INFO,
        payload: userObj,
      });
      await dispatch({ type: t.FETCH_USERPROFILE, payload: userObj });
      await dispatch({
        type: loginType.SETBACKGROUNDCOLOR,
        payload: backgroundColor,
      });
    }
  } catch (error) {
    console.error("getBrandingData Error on action", error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message || "The server did not respond, Please try again after sometime",
    });
  }
};

export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const userLogout = (data) => async (dispatch) => {
  try {
    const body = {
      userid: data.userid,
      property_id: data.property_id,
      login_id: data.login_id,
      highSecureProperty: data?.highSecureProperty,
      user_offline: data?.user_offline,
    };
    const response = await AuthAPI("domain", "user/logout", "POST", body);
    if (response?.success === 1) {
      localStorage.removeItem("basicAuth");
      localStorage.clear();
      setCrossSubdomainCookie("basicAuth", null, -1);
      localStorage.removeItem("basicRefreshAuth");
      setCrossSubdomainCookie("basicRefreshAuth", null, -1);
      await dispatch({ type: t.LOGOUT_USER });
      await dispatch({ type: premiType.REMOVE_CURRENT_PERMIT });
      await dispatch({ type: loginType.USER_PROFILE_INFO, payload: {} });
      await dispatch({ type: t.FETCH_USERPROFILE, payload: {} });
      const databaseNames = await Dexie.getDatabaseNames();
      if (databaseNames.includes("offline_citation")) {
        if (db.isOpen()) {
          db.close();
        }
        await Dexie.delete("offline_citation");
      } else {
        console.log("dixie db not exist");
      }
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response?.message?.toString(),
      });
    }
  } catch (error) {
    console.log(error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const checkStartedOfflineSession = (data) => async (dispatch) => {
  try {
    const body = {
      user_id: data.user_id,
      property_id: data.property_id,
    };
    const response = await AuthAPI("domain", "user/offline-session-check", "POST", body);
    console.log("response on checkStartedOfflineSession:::::", response);
    if (response?.success === 1) {
      await dispatch({ type: t.GET_OFFLINE_USER, payload: response?.offline_user });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response?.message?.toString(),
      });
    }
  } catch (error) {
    console.log(error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};
export const resetPassword = (passwordData) => async (dispatch) => {
  try {
    let data;
    if (passwordData.id) {
      data = await AuthAPI("domain", "password/change", "POST", passwordData);
    } else {
      data = await AuthAPI("domain", "user/password/change", "POST", passwordData);
    }
    if (data.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: data.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: data.message,
      });
    }
    return data;
  } catch (error) {
    console.log(error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const verifyOtp = (otp, username, res, loginObj) => async (dispatch) => {
  try {
    let url = "user/verify_otp";
    const response = await UnAuthAPI(url, "POST", {
      otp,
      username,
      previous_response: res,
    });

    if (response.success === 1 && res) {
      console.log("High security result:::", res);

      localStorage.setItem("basicAuth", JSON.stringify(res.token));
      setCrossSubdomainCookie("basicAuth", res.token, 365);
      localStorage.setItem("basicRefreshAuth", JSON.stringify(res.refresh_login_token));
      setCrossSubdomainCookie("basicRefreshAuth", res.refresh_login_token, 365);
      const isLinkExist = "link" in res;
      if (res.success === 1) {
        url = "user/profile/self";
        localStorage.setItem("basicAuth", JSON.stringify(res.token));
        const tokenVariable = res.token;
        const success = res.success === 1;
        await dispatch({ type: loginType.USER_LOGIN, payload: success });

        await subVerifyOtp(res, tokenVariable, isLinkExist, url, loginObj, dispatch, success);
      }

      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: res.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE_ATTEMPTS,
        payload: response.failed_login_attempts,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

const subVerifyOtp = async (res, tokenVariable, isLinkExist, url, loginObj, dispatch, success) => {
  if (res.user_type !== "superadmin") {
    const userDataResponse = await AuthAPI("domain", url, "GET");
    console.log("userDataResponse:---", userDataResponse);
    const appConfigURL = "site_setting/" + res.property_id;
    const appConfigResponse = await AuthAPI("domain", appConfigURL, "GET"); // this is for non-superadmin user
    console.log("appConfigResponse:---", appConfigResponse);
    const appConfig = {
      availableSpots: appConfigResponse.available_spot,
      globaleCredit: appConfigResponse.credits,
      ...appConfigResponse,
    };
    const domain = appConfigResponse?.property?.domain;
    localStorage.setItem("page-title", domain);
    userDataResponse.property_id = res.property_id;
    const userData = {
      ...userDataResponse,
      user_type: res.user_type,
      admin_login: res.admin_login,
      user: res.user_type === "admin" ? res.user_type : userDataResponse.email,
      timezone: res.timezone,
      id: userDataResponse.id ? userDataResponse.id : "",
      login_id: res.login_id,
    };
    const user_name = loginObj ? loginObj.username : userDataResponse.username;
    rootScope.user.basicAuth = tokenVariable;
    rootScope.user.info = userData;
    rootScope.user.login = user_name;
    rootScope.user.property_id = res.property_id;
    const userObj = {
      basicAuth: tokenVariable,
      menu: true,
      link: isLinkExist ? res.link : "",
      username: user_name,
      ...appConfig,
      ...userData,
    };
    await dispatch({ type: loginType.USER_LOGIN, payload: success });
    await dispatch({
      type: loginType.USER_PROFILE_INFO,
      payload: userObj,
    });
    await dispatch({ type: t.FETCH_USERPROFILE, payload: userObj });
  } else {
    const userDataResponse = await AuthAPI("domain", url, "GET");

    const { userObj } = await subVerifyOtpSuperAdmin(res, userDataResponse, loginObj, tokenVariable, isLinkExist);

    await dispatch({ type: loginType.USER_LOGIN, payload: success });
    await dispatch({
      type: loginType.USER_PROFILE_INFO,
      payload: userObj,
    });
    await dispatch({ type: t.FETCH_USERPROFILE, payload: userObj });
  }
};

const subVerifyOtpSuperAdmin = async (res, userDataResponse, loginObj, tokenVariable, isLinkExist) => {
  const domain = userDataResponse?.property?.domain ? userDataResponse?.property?.domain : "";
  localStorage.setItem("page-title", domain);
  const userData = {
    ...userDataResponse,
    property_id: res.property_id,
    user_type: res.user_type,
    user: res.user_type === "admin" ? res.user_type : userDataResponse.email,
    timezone: res.timezone,
    admin_login: res.admin_login,
    availableSpots: userDataResponse.available_spot,
    globaleCredit: userDataResponse.credits,
    login_id: res.login_id,
  };
  const username = loginObj ? loginObj.username : userDataResponse.username;
  rootScope.user.basicAuth = tokenVariable;
  rootScope.user.info = userData;
  rootScope.user.login = username;
  rootScope.user.property_id = res.property_id;
  localStorage.setItem("user", JSON.stringify(rootScope.user));
  const userObj = {
    basicAuth: tokenVariable,
    menu: true,
    link: isLinkExist ? res.link : "",
    username: username,
    ...userData,
  };
  return { userObj };
};

export const forgetPassword = (email) => async (dispatch) => {
  try {
    const url = "user/verify_email/" + email;
    const res = await UnAuthAPI(url, "GET");
    if (res.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: res.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: res.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const generateOtp = (username, expiration_time) => async (dispatch) => {
  try {
    const url = "user/generate_otp";
    const res = await UnAuthAPI(url, "POST", { username, expiration_time });
    console.log("Generate Otp response:", res);
    if (res.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: res.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: res.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const userUpdate = (userData) => async (dispatch) => {
  try {
    const scope = userData;
    const url = "user/" + userData.id;
    const url2 = "user/profile/self";
    let userDataResponse;

    const response = await AuthAPI("domain", url, "PATCH", scope);
    if (response?.success) {
      userDataResponse = await AuthAPI("domain", url2, "GET");
      const basicAuth = localStorage.getItem("basicAuth");
      const userObj = {
        basicAuth,
        ...userDataResponse,
      };
      await dispatch({ type: t.FETCH_USERPROFILE, payload: userObj });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const changeSuiteId = (id) => async (dispatch) => {
  await dispatch({ type: t.CHANGE_SUITE_ID, payload: id });
};

export const getLoginInfo = () => async (dispatch) => {
  try {
    const url = "login_info";
    const result = await AuthAPI("domain", url, "GET");

    if (result && result.success === 1) {
      await dispatch({ type: loginType.USER_LOGIN_INFO, payload: result.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: result.msg,
      });
    } else {
      await dispatch({
        type: loginType.IS_SUBDOMIAN_NOT_EXIT,
        payload: false,
      });
      await dispatch({
        type: loginType.SUBDOMIAN_NOT_EXIT_ERROR,
        payload: result,
      });
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: result.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const propertyData = () => async (dispatch) => {
  try {
    const result = await AuthAPI("domain", `property/get-child-properties`, "GET");

    if (result && result.success === 1) {
      await dispatch({
        type: loginType.USER_PROPERTY_INFO,
        payload: result.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: "",
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: result.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const showHidePropertyModal = (showHide) => async (dispatch) => {
  dispatch({
    type: loginType.TOGGLE_PROPERTY_MODAL,
    payload: showHide,
  });
};

export const checkIfUserSessionIsActive = () => async () => {
  try {
    await RefreshAuthAPI();
    return true;
  } catch (error) {
    return false;
  }
};

import { Col, Container, Row, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomContentAlert, { navigationButtons } from "../helper";
import AddEdgeDeviceTypeFunc from "./AddEdgeDeviceTypeFunc";
import React from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import * as EdgeDeviceActions from "../../Actions/EdgeDeviceActions/action";
import * as SuperAdminAction from "../../Actions/SuperAdminAction/actions";
import styled from "styled-components";
import t from "../../Actions/EdgeDeviceActions/types";
import Loader from "../Loader/Loader";
import EdgedeviceDeleteModel from "../Modal/EdgedeviceDeleteModel";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const AddEdgeDeviceFunc = () => {
  const addActionRef = React.useRef(null);
  const [propertyId, setProertyId] = React.useState("");
  const [isModalOpen, setisModalOpen] = React.useState(false);
  const [deleteRowData, setdeleteRowData] = React.useState({});
  const dispatch = useDispatch();
  const deviceTypes = useSelector((state) => state.EdgeDeviceDetails.deviceTypes);
  const selectedDeviceType = useSelector((state) => state.EdgeDeviceDetails.selectedDeviceType);
  const api_loader = useSelector((state) => state.EdgeDeviceDetails.loader);
  const edgeDevices = useSelector((state) => state.EdgeDeviceDetails.edgeDevices);
  const apiMessage = useSelector((state) => state.EdgeDeviceDetails.message);

  const modelOpenCLose = useSelector((state) => state.EdgeDeviceDetails.modelOpenClose);

  const [loader, setLoader] = React.useState(api_loader);

  const properties = useSelector((state) => state.SuperAdminDetails.getAllProperties);

  const lastSelectedType = React.useRef("");

  const ValidateDeviceType = (rowData) => {
    if (rowData?.type?.length) {
      if (lastSelectedType.current !== rowData.type) {
        dispatch(EdgeDeviceActions.fetchFieldsForDeviceType(rowData.type));
        lastSelectedType.current = rowData.type;
        dispatch({
          type: t.CLOSE_OPEN_MODEL,
          payload: true,
        });
      }
    }
    return rowData.type === "" ? "Device cannot be empty" : "";
  };

  const EditRow = (rowData) => {
    dispatch({
      type: t.SET_EDGE_DEVICE_DATA,
      payload: rowData.row,
    });
    dispatch({
      type: t.CLOSE_OPEN_MODEL,
      payload: true,
    });
  };

  const DeleteRow = async (rowData) => {
    setisModalOpen(true);
    setdeleteRowData(rowData);
  };

  const deleteEdgedeviceData = async (rowData) => {
    dispatch(EdgeDeviceActions.deleteSelectedDevice(rowData.id, propertyId));
    setisModalOpen(false);
  };

  const deleIcon = (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z" fill="black" />
    </svg>
  );
  const columns = React.useRef([
    {
      flex: 1,
      minWidth: 200,
      headerName: "Device Type",
      field: "type",
      lookup: {},
      validate: ValidateDeviceType,
    },
    {
      flex: 1,
      minWidth: 200,
      headerName: "Serial Number",
      field: "serial_number",
      lookup: {},
    },
    {
      flex: 1,
      minWidth: 200,
      headerName: "Device Name",
      field: "device_name",
      validate: (rowData) => (rowData.device === "" ? "Please add device name" : ""),
      customSort: (a, b) => (a.device_name.toLowerCase() > b.device_name.toLowerCase() ? 1 : -1),
    },
    {
      flex: 1,
      minWidth: 200,
      headerName: "MAC ID",
      field: "mac_id",
    },
    {
      flex: 1,
      minWidth: 120,
      maxWidth: 150,
      headerName: "Action",
      field: "-",
      renderCell: (rowData) =>
        !modelOpenCLose && (
          <React.Fragment>
            <OverlayTrigger placement="bottom" overlay={editTooltip}>
              <ActionIcon onClick={() => EditRow(rowData)}>
                <i className="fas fa-pen m-auto"></i>
              </ActionIcon>
            </OverlayTrigger>
            {/* <i
              className="fas fa-trash "
              aria-hidden="true"
              onClick={() => DeleteRow(rowData)}
            ></i> */}
            <OverlayTrigger placement="bottom" overlay={deleteTooltip}>
              <ActionIcon onClick={() => DeleteRow(rowData)}>
                <div className="m-auto text-center">{deleIcon}</div>
              </ActionIcon>
            </OverlayTrigger>
          </React.Fragment>
        ),
    },
  ]);
  const deleteTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete
    </Tooltip>
  );
  const editTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );

  React.useEffect(() => {
    dispatch(SuperAdminAction.get_all_properties());
  }, [dispatch]);

  React.useEffect(() => {
    if (edgeDevices) {
      setLoader(false);
    }
  }, [edgeDevices]);

  React.useEffect(() => {
    const propertyId_ = window.document.location.href.split("?")[1];
    setProertyId(propertyId_);
    const selectedPropertyObj = (Array.isArray(properties) ? properties : []).filter((item) => item.id === propertyId_);
    const selectedPropertyName = selectedPropertyObj?.length ? selectedPropertyObj[0].site_name : "";
    const selectedDomainName = selectedPropertyObj?.length ? selectedPropertyObj[0].domain : "";

    dispatch(SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName));
  }, [properties]);

  React.useEffect(() => {
    setLoader(true);
    batch(() => {
      dispatch(EdgeDeviceActions.fetchAllDeviceTypes());
      dispatch(EdgeDeviceActions.fetchAllEdgeDevices());
    });
  }, []);

  React.useEffect(() => {
    let timeout = null;
    if (apiMessage?.message) {
      timeout = setTimeout(() => {
        dispatch({
          type: t.SET_EDGE_DEVICE_ERROR,
          payload: "",
        });
      }, 5000);
    }
    return () => {
      if (timeout !== null) clearTimeout(timeout);
    };
  }, [apiMessage]);

  React.useEffect(() => {
    if (Array.isArray(deviceTypes)) {
      const deviceTypeObj = deviceTypes.reduce(function (acc, cur) {
        acc[cur.type] = cur.type;
        return acc;
      }, {});
      columns.current.forEach((column) => {
        if (column.lookup) {
          column.lookup = deviceTypeObj;
        }
      });
    }
  }, [deviceTypes]);

  const modelhandle = () => {
    dispatch({
      type: t.CLOSE_OPEN_MODEL,
      payload: true,
    });
  };
  return (
    <>
      <Container fluid>
        <Row className="h-100">
          <Col md="12">
            <Card className="h-100">
              <Card.Header className="d-flex align-items-start justify-content-between">
                <div>
                  <Card.Title as="h4">Manage Edge Devices</Card.Title>
                  <p className="card-category">Fill out below form to add new Edge Device. </p>
                </div>
              </Card.Header>
              <Card.Body>
                {loader ? (
                  <Loader />
                ) : (
                  <>
                    <StyledDiv style={{ width: "100%" }} className="edgeDevicesTable">
                      {edgeDevices?.length === 0 ? (
                        <div style={{ fontSize: 15, textAlign: "center" }}>There are no records to display</div>
                      ) : (
                        <DataGrid
                          disableColumnMenu={true}
                          disableColumnFilter={true}
                          disableColumnSelector
                          disableDensitySelector
                          getRowClassName={"p-2"}
                          rows={(edgeDevices && Array.isArray(edgeDevices) ? edgeDevices : []).map((item) => {
                            return {
                              ...item,
                              serial_number: item.serial_number ? item.serial_number : "-",
                            };
                          })}
                          columns={columns.current}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{
                            toolbar: {
                              printOptions: { disableToolbarButton: true },
                              csvOptions: { disableToolbarButton: true },
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 500 },
                            },
                          }}
                          disableRowSelectionOnClick={true}
                          disableMultipleRowSelection={true}
                          experimentalFeatures={{
                            lazyLoading: true,
                          }}
                          pageSizeOptions={[5, 10, 20]}
                        />
                      )}
                    </StyledDiv>
                    <Col lg="12" className="p-0">
                      {navigationButtons("/addcameraform", "/manage-meter-config", propertyId, addActionRef, "Add Edge Device", null, null, null, () => {
                        modelhandle();
                      })}
                    </Col>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {isModalOpen ? (
        <EdgedeviceDeleteModel
          showBrowserModal={isModalOpen}
          closeModal={() => setisModalOpen(!isModalOpen)}
          deleteEdgedeviceData={deleteEdgedeviceData}
          rowData={deleteRowData}
        />
      ) : null}
      {modelOpenCLose ? <AddEdgeDeviceTypeFunc show={modelOpenCLose} selectedType={selectedDeviceType} /> : null}
      {apiMessage?.isSuccess && apiMessage?.message !== "" && (
        <CustomContentAlert delay={2000} message={apiMessage.message} className={apiMessage.isSuccess ? "toast-success" : ""} />
      )}
    </>
  );
};
export default AddEdgeDeviceFunc;
const StyledDiv = styled.div`
  max-width: 100%;
  height: calc(100vh - 300px);
  table > thead {
    th:last-child {
      // display: none;
    }
  }
  table > tbody {
    td:last-child {
      // display: none;
    }
    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft:last-child {
      padding: 0 3px;
    }
  }
`;
const ActionIcon = styled.div`
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 13px 16px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 50%;
  }
  // @media (max-width: 450px) {
  //   width: 32px;
  //   height: 32px;
  //   padding: 10px;
  // }
`;
// const CustomButton = styled(Button)`
//   height: 40px;
//   width: 40px;
//   border-radius: 25px;
//   padding: 5px;
// `;

import React, { Component } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import CustomContentAlert, { findBySearch, tzDate } from "../helper"; // findBySearch, // routing_using_history,
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import { SuperAdminAction } from "../../Actions";
import PaginationforData from "../pagination/pagination";
import Replay from "@material-ui/icons/Replay";
import Delete from "@material-ui/icons/Delete";
import DeleteResidentModal from "../Modal/DeleteResidentModal";

class ArchivedProperty extends Component {
  constructor(props) {
    super(props);
    const url = window.location.href;
    const TLD = this.isSubdomain(url);
    this.state = {
      searchValue: "",
      prevPage: "",
      prevSearch: "",
      prevNorow: "",
      pageChanged: false,
      filterdProperties: [],
      allPropertyList: [],
      newPropertyList: [],
      count: 0,
      loader: false,
      api_response: false,
      showPropertyModal: false,
      isModalOpen: false,
      page: 1,
      Norow: 100,
      selectedColumn: "site_name",
      asc: "false",
      sort_direction: "DESC",
      findMore: false,
      timezone: this.props.timezone,
      topLevelDomain: TLD,
      confirmRemoveProperty: false,
      confirmRemovePropertyData: null,
      confirmUnArchiveProperty: false,
      confirmUnArchvePropertyData: null,
      uuid: "",
      id: "",
      show_alert: false,
      show_error: false,
      api_success_message: "",
      api_error_message: "",
    };
    this.customStyles = {
      rows: {
        style: {
          minHeight: "50px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "0", // override the cell padding for head cells
          paddingRight: "0",
        },
      },
      cells: {
        style: {
          paddingLeft: "0", // override the cell padding for data cells
          paddingRight: "0",
        },
      },
    };

    this.columns = [
      {
        name: "Name",
        width: "120px",
        center: false,
        sortable: true,
        selector: (row) => row.site_name,
        cell: (row) => this.showSiteField(row),
      },
      {
        name: "Domain",
        center: false,
        width: "130px",
        sortable: true,
        onclick: this.setState({ a: "domain" }),
        selector: (row) => row.domain,
        cell: (row) => this.showDomainField(row),
      },
      {
        name: "Timezone",
        sortable: true,
        width: "160px",
        selector: (row) => row.timezone,
        cell: (row) => this.showTimeZoneField(row),
      },
      {
        name: "Create Time",
        sortable: true,
        width: "160px",
        selector: (row) => row.createdAt,
        cell: (row) => this.showCreateTime(row),
      },
      {
        name: "Parent Property",
        sortable: true,
        width: "130px",
        selector: (row) => row.parent_property,
        cell: (row) => this.showParentPropertyField(row),
      },
      {
        name: "Site Last Update",
        sortable: true,
        minWidth: "160px",
        maxWidth: "250px",
        selector: (row) => row.updatedAt,
        cell: (row) => this.showSiteLastUpdate(row),
      },
      {
        name: "Site Last Access",
        sortable: true,
        center: false,
        minWidth: "160px",
        maxWidth: "250px",
        selector: (row) => row.last_acessed_at,
        cell: (row) => this.showSiteLastAccessField(row),
      },
      {
        name: "Address",
        center: false,
        width: "200px",
        sortable: true,
        selector: (row) => row.address,
        cell: (row) => this.showAddressField(row),
      },
      {
        name: "Action",
        center: true,
        width: "130px",
        sortable: true,
        cell: (row) => this.showActionField(row),
      },
    ];
  }

  showActionField = (row) => (
    <div className="d-flex align-items-center">
      <Button
        type="button"
        variant="primary"
        className="btn-blue-bg btn-xs  mr-2 ml-2 "
        onClick={() => this.handleUnArchiveModal(row.id)}
      >
        <Replay />
      </Button>
      <Button
        type="button"
        variant="primary"
        className="btn-blue-bg btn-xs"
        onClick={() => this.handleDeleteModal(row.id)}
      >
        <Delete />
      </Button>
    </div>
  )

  showAddressField = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{row.address}</Tooltip>}
    >
      <div className="text-nowrap text-truncate pr-2">{row.address}</div>
    </OverlayTrigger>
  )

  showSiteLastAccessField = (row) => (
    <>
      {row.last_acessed_at ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{tzDate(this.state.timezone, row.last_acessed_at)}</Tooltip>}>
          <div className="text-truncate text-nowrap pr-2">
            {tzDate(this.state.timezone, row.last_acessed_at)}
          </div>
        </OverlayTrigger>
      ) : "--"}
    </>
  )

  showSiteLastUpdate = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{tzDate(this.state.timezone, row.updatedAt)}</Tooltip>}>
        <div className="text-truncate text-nowrap pr-2">{tzDate(this.state.timezone, row.updatedAt)}</div>
      </OverlayTrigger>
    </>
  )

  showParentPropertyField = (row) => (
    <>
      {row.parent_property ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.parent_property.name}</Tooltip>}>
          <div className="text-truncate text-nowrap pr-2">
            {row.parent_property.name}
          </div>
        </OverlayTrigger>
      ) : "--"}
    </>
  )

  showCreateTime = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{tzDate(this.state.timezone, row.createdAt)}</Tooltip>}>
        <div className="text-truncate text-nowrap pr-2">{tzDate(this.state.timezone, row.createdAt)}</div>
      </OverlayTrigger>
    </>
  )

  showTimeZoneField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.timezone}</Tooltip>}>
        <div className="text-truncate text-nowrap pr-2">{row.timezone}</div>
      </OverlayTrigger>
    </>
  )

  showDomainField = (row) => (
    <div className="text-truncate text-nowrap curserPointer">
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement={"top"}
        overlay={<Tooltip id={`tooltip-qw`}>{row.domain}</Tooltip>}
      >
        <a
          className={"top-header--title text-truncate text-nowrap pr-2"}
          href={
            "https://" +
            row.domain +
            ".worldstream." +
            this.state.topLevelDomain
          }
          target="_blank"
          rel="noreferrer"
        >
          {row.domain}
        </a>
      </OverlayTrigger>
    </div>
  )

  showSiteField = (row) => (
    <div className="text-truncate text-nowrap curserPointer">
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement={"top"}
        overlay={<Tooltip id={`tooltip-qw`}>{row.site_name}</Tooltip>}
      >
        <a
          className={"top-header--title text-truncate text-nowrap pr-2"}
          onClick={() => this.handlePropertyClick(row.id)}
        >
          {row.site_name}
        </a>
      </OverlayTrigger>
    </div>
  )

  componentDidMount() {
    this.setState({ loader: true }, async () => {
      const { page, Norow, searchValue, selectedColumn, sort_direction } =
        this.state;
      const pageobj = {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
      };
      const type = "archived";
      await this.props.get_all_properties_paginated(pageobj, type);
      this.setState({
        allPropertyList: this.props.archivedPropertyList,
        newPropertyList: this.props.archivedPropertyList,
        count: this.props.count,
        api_response: this.props.api_response,
      });
      this.setState({ loader: false });
      const selectedPropertyName = "";
      this.props.setDomainHeader(selectedPropertyName);
    });
  }

  isSubdomain = (url) => {
    url = url.replace(/^\s+/, "");
    url = url.replace(/\s+$/, "");
    url = url.replace(/\\/g, "/");
    url = url.replace(/^http:\/\/|^https:\/\/|^ftp:\/\//i, "");
    url = url.replace(/^www\./i, "");
    url = url.replace(/\/(.*)/, "");
    let domain = "";
    if (url.match(/\.[a-z]{2,3}\.[a-z]{2}$/i)) {
      url = url.replace(/\.[a-z]{2,3}\.[a-z]{2}$/i, "");
    } else if (url.match(/\.[a-z]{2,4}$/i)) {
      domain = url.split(".")[1];
    }
    return domain;
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.archivedPropertyList) !==
      JSON.stringify(this.props.archivedPropertyList)
    ) {
      this.setState({
        newPropertyList: this.props.archivedPropertyList,
        allPropertyList: this.props.archivedPropertyList,
        count: this.props.count,
      });
    }
  }

  handlePropertyClick = (property_id) => {
    const history = require("../../history").default;
    history.push({
      pathname: "/settings",
      search: property_id,
    });
  };

  handleSearchChange = ({ target }) => {
    this.setState({
      prevSearch: this.state.searchValue,
      page: 1,
      pageChanged: true,
    });
    this.setState({ searchValue: target.value }, () => {
      if (this.state.searchValue.length) {
        if (!this.state.findMore) {
          this.setState(
            {
              newPropertyList: findBySearch(
                this.state.searchValue,
                this.state.allPropertyList,
                this.state.allPropertyList
              ),
            },
            () => {
              this.setState({ count: this.state.newPropertyList.length });
            }
          );
        }
      } else {
        this.setState({ loader: true, pageChanged: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction } =
            this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          const type = "archived";
          await this.props.get_all_properties_paginated(pageobj, type);
          this.setState({
            pageChanged: true,
            page: 1,
            newPropertyList: this.props.archivedPropertyList,
            count: this.props.count,
          });
          this.setState({ loader: false });
        });
      }
      setTimeout(() => {
        this.setState({ findMore: false });
      }, 1000);
    });
  };

  handleRestoreProperty = async () => {
    const id = this.state.id;
    this.setState({ confirmUnArchiveProperty: false }, async () => {
      await this.props.restoreProperty(id);
      if (this.props.api_response) {
        this.setState({
          show_alert: true,
          api_success_message: this.props.api_success_message,
        });
        const { page, Norow, searchValue, selectedColumn, sort_direction } =
          this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        const type = "archived";
        await this.props.get_all_properties_paginated(pageobj, type);
        this.setState({
          allPropertyList: this.props.archivedPropertyList,
          count: this.props.count,
          api_response: this.props.api_response,
        });

        setTimeout(() => {
          this.setState({
            show_alert: false,
          });
        }, 4000);
      } else {
        this.setState({
          show_error: true,
          api_error_message: this.props.api_error_message,
        });
        setTimeout(() => {
          this.setState({ show_error: false });
        }, 4000);
      }
    });
  };

  handleUnArchiveModal = async (id) => {
    const message = " Are you sure you want to Unarchive this property?";
    const data = { title: "Unarchive Property", message: message };
    this.setState({
      confirmUnArchvePropertyData: data,
      confirmUnArchiveProperty: true,
      id: id,
    });
  };

  handlepage = ({ target }) => {
    this.setState({ prevPage: this.state.page, prevNorow: this.state.Norow });
    this.setState(
      {
        [target.name]: target.value,
      },
      async () => {
        switch (target.name) {
          case "page":
            this.setState({ page: target.value }, async () => {
              this.setState({ loader: true, pageChanged: true }, async () => {
                const {
                  page,
                  Norow,
                  searchValue,
                  selectedColumn,
                  sort_direction,
                } = this.state;
                const pageobj = {
                  page,
                  Norow,
                  searchValue,
                  selectedColumn,
                  sort_direction,
                };
                const type = "archived";
                await this.props.get_all_properties_paginated(pageobj, type);
                this.setState({
                  allPropertyList: this.props.archivedPropertyList,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
          case "Norow":
            this.setState({ Norow: target.value }, async () => {
              this.setState({ loader: true, pageChanged: true }, async () => {
                if (this.state.Norow >= this.state.count) {
                  this.setState(
                    {
                      page: 1,
                    },
                    async () => {
                      const {
                        page,
                        Norow,
                        searchValue,
                        selectedColumn,
                        sort_direction,
                      } = this.state;
                      const pageobj = {
                        page,
                        Norow,
                        searchValue,
                        selectedColumn,
                        sort_direction,
                      };
                      const type = "archived";
                      await this.props.get_all_properties_paginated(
                        pageobj,
                        type
                      );
                    }
                  );
                } else {
                  const {
                    page,
                    Norow,
                    searchValue,
                    selectedColumn,
                    sort_direction,
                  } = this.state;
                  const pageobj = {
                    page,
                    Norow,
                    searchValue,
                    selectedColumn,
                    sort_direction,
                  };
                  const type = "archived";
                  await this.props.get_all_properties_paginated(pageobj, type);
                }
                this.setState({
                  allPropertyList: this.props.archivedPropertyList,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
        }
        if (
          Number(this.state.page) !== Number(this.state.prevPage) ||
          Number(this.state.Norow) !== Number(this.state.prevNorow)
        ) {
          const { page, Norow, searchValue, selectedColumn, sort_direction } =
            this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          this.setState({ loader: true }, async () => {
            const type = "archived";
            await this.props.get_all_properties_paginated(pageobj, type);
            this.setState({
              newPropertyList: this.props.archivedPropertyList,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        }
      }
    );
  };

  handleonsort = async (e) => {
    this.setState({ asc: !this.state.asc }, () => {
      const { asc } = this.state;
      if (!asc) {
        this.setState({ sort_direction: "ASC" }, async () => {
          this.setState({ selectedColumn: e.name }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } =
              this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            this.setState({ loader: true }, async () => {
              const type = "archived";
              await this.props.get_all_properties_paginated(pageobj, type);
              this.setState({
                propertiesAvailable: await this.props.archivedPropertyList,
                api_response: this.props.api_response,
              });
              this.setState({ loader: false });
            });
          });
        });
      } else {
        this.setState({ sort_direction: "DESC" }, async () => {
          this.setState({ selectedColumn: e.name }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } =
              this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            this.setState({ loader: true }, async () => {
              const type = "archived";
              await this.props.get_all_properties_paginated(pageobj, type);
              this.setState({
                propertiesAvailable: await this.props.archivedPropertyList,
                api_response: this.props.api_response,
              });
              this.setState({ loader: false });
            });
          });
        });
      }
    });
  };

  handleRowsPerPage = () => {
    const { Norow, page } = this.state;
    return { Norow, page };
  };

  handlepagenext = () => {
    const { page, Norow } = this.state;
    const count = this.props.count;
    const finalPage = Math.ceil(count / Norow);
    if (page + 1 <= finalPage) {
      this.setState({ page: page + 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } =
          this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true, pageChanged: true }, async () => {
          const type = "archived";
          await this.props.get_all_properties_paginated(pageobj, type);
          this.setState({
            newPropertyList: await this.props.archivedPropertyList,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepageprevious = () => {
    const { page } = this.state;
    if (page !== 1) {
      this.setState({ page: page - 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } =
          this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true }, async () => {
          const type = "archived";
          await this.props.get_all_properties_paginated(pageobj, type);
          this.setState({
            newPropertyList: this.props.archivedPropertyList,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepageinitial = () => {
    if (this.state.page !== 1) {
      this.setState({ page: 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } =
          this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true }, async () => {
          const type = "archived";
          await this.props.get_all_properties_paginated(pageobj, type);
          this.setState({
            newPropertyList: this.props.archivedPropertyList,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepagefinal = async () => {
    const { page, Norow } = this.state;
    const count = this.props.count;
    let last_page = count / Norow;
    if (last_page === 0) {
      last_page = 1;
    }
    const rounded = Math.ceil(last_page);
    if (page < rounded) {
      this.setState({ page: rounded, pageChanged: true }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } =
          this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true, pageChanged: true }, async () => {
          const type = "archived";
          await this.props.get_all_properties_paginated(pageobj, type);
          this.setState({
            newPropertyList: this.props.archivedPropertyList,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  findMorePressed = () => {
    this.setState({ findMore: true }, () => {
      if (this.state.findMore) {
        if (this.state.searchValue) {
          this.setState({ loader: true, pageChanged: true }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } =
              this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            const type = "archived";
            await this.props.get_all_properties_paginated(pageobj, type);
            this.setState({
              pageChanged: true,
              page: 1,
              newPropertyList: this.props.archivedPropertyList,
            });
            this.setState({ loader: false });
          });
          setTimeout(() => {
            this.setState({ findMore: true });
          }, 1000);
        }
      } else {
        this.setState({ loader: true, pageChanged: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction } =
            this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          const type = "archived";
          await this.props.get_all_properties_paginated(pageobj, type);
          this.setState({
            pageChanged: true,
            page: 1,
            newPropertyList: this.props.archivedPropertyList,
          });
          this.setState({ loader: false });
        });
        setTimeout(() => {
          this.setState({ findMore: true });
        }, 1000);
      }
    });
  };

  handleDeleteModal = (id) => {
    this.removerpropertylist(id);
  };

  removerpropertylist = async (id) => {
    const message = " Are you sure you want to delete this property?";
    const data = { title: "Delete Property", message: message };
    this.setState({
      confirmRemovePropertyData: data,
      confirmRemoveProperty: true,
      uuid: id,
    });
  };

  onConfrim = () => {
    const id = this.state.uuid;
    this.setState({ confirmRemoveProperty: false }, async () => {
      await this.props.deleteProperty(id);
      if (this.props.api_response) {
        this.setState({
          show_alert: true,
          api_success_message: this.props.api_success_message,
        });
        const { page, Norow, searchValue, selectedColumn, sort_direction } =
          this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        const type = "archived";
        await this.props.get_all_properties_paginated(pageobj, type);
        this.setState({
          allPropertyList: this.props.archivedPropertyList,
          count: this.props.count,
          api_response: this.props.api_response,
        });

        setTimeout(() => {
          this.setState({
            show_alert: false,
          });
        }, 4000);
      } else {
        this.setState({
          show_error: true,
          api_error_message: this.props.api_error_message,
        });
        setTimeout(() => {
          this.setState({ show_error: false });
        }, 4000);
      }
    });
  };

  onCancel = () => {
    this.setState({
      confirmRemoveProperty: false,
      confirmUnArchiveProperty: false,
    });
  };

  render() {
    const {
      searchValue,
      allPropertyList,
      newPropertyList,
      pageChanged,
      loader,
      api_response,
      show_alert,
      show_error,
      confirmRemoveProperty,
      confirmRemovePropertyData,
      confirmUnArchvePropertyData,
      confirmUnArchiveProperty,
      // showPropertyModal,
      // isModalOpen,
      page,
      Norow,
    } = this.state;
    return (
      <>
        <Container fluid>
          <Row className="h-100">
            <Col md="12">
              <Card className="h-100 mb-0">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Archived Properties</Card.Title>
                      {/* <p className="card-category">All properties</p> */}
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide">
                  <header>
                    <div className="active-permit-header sm-flex-column sm-align-items-start">
                      <h5 className="mb-2 mb-md-0"></h5>
                      <div className="filter-button d-flex flex-wrap">
                        <div className="d-flex align-items-center search-mb-0 mr-2 loc-searchbox">
                          <DatatableHeader
                            // Header={"Properties"}
                            searchValue={this.state.searchValue}
                            HandleChange={this.handleSearchChange}
                            findMorePressed={this.findMorePressed}
                            findMore={true}
                            noShowFilter={true}
                          />
                        </div>
                      </div>
                    </div>
                  </header>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {!api_response && (
                        <CustomContentAlert
                          delay={4000}
                          message={
                            "The server did not respond, Please try again after sometime"
                          }
                          className="toast-error"
                        />
                      )}
                      <div
                        className="citation-list-table list-of-ravenue-table sa-all-property-table"
                        style={{ paddingLeft: "10px" }}
                      >
                        <DataTable
                          data={
                            searchValue.length || pageChanged
                              ? newPropertyList
                              : allPropertyList
                          }
                          columns={this.columns}
                          customStyles={this.customStyles}
                          // pagination={true}
                          paginationServer={true}
                          responsive={true}
                          // subHeader
                          defaultSortAsc={false}
                          fixedHeader={true}
                          fixedHeaderScrollHeight="38vh"
                        />
                      </div>
                      <PaginationforData
                        count={this.state.count}
                        data={this.state.newPropertyList}
                        // data={this.props?.getallpropertiespaginated}
                        handlepageinitial={this.handlepageinitial}
                        handlepageprevious={this.handlepageprevious}
                        handlepagenext={this.handlepagenext}
                        handlepagefinal={this.handlepagefinal}
                        handlepage={this.handlepage}
                        page={page}
                        Norow={Norow}
                      />
                    </>
                  )}
                  {confirmRemoveProperty ? (
                    <DeleteResidentModal
                      showBrowserModal={confirmRemoveProperty}
                      details={confirmRemovePropertyData}
                      closeModal={() =>
                        this.setState({ confirmRemoveProperty: false })
                      }
                      handleSave={(id) => this.onConfrim(id)}
                      handleCancel={this.onCancel}
                    />
                  ) : null}
                  {confirmUnArchiveProperty ? (
                    <DeleteResidentModal
                      showBrowserModal={confirmUnArchiveProperty}
                      details={confirmUnArchvePropertyData}
                      closeModal={() =>
                        this.setState({ confirmUnArchiveProperty: false })
                      }
                      handleSave={(id) => this.handleRestoreProperty(id)}
                      handleCancel={this.onCancel}
                    />
                  ) : null}

                  {show_alert ? (
                    <CustomContentAlert
                      delay={4000}
                      message={this.state.api_success_message}
                      className="toast-success"
                    />
                  ) : null}
                  {show_error ? (
                    <CustomContentAlert
                      delay={4000}
                      message={this.state.api_error_message}
                      className="toast-error"
                    />
                  ) : null}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStatetoProps = ({
  SuperAdminDetails,
  APIResponseDetails,
  UserProfileDetails,
}) => {
  const { archivedPropertyList, archivedPropertyCount: count } =
    SuperAdminDetails;
  const { api_response, api_success_message, api_error_message } =
    APIResponseDetails;
  const { timezone } = UserProfileDetails;
  return {
    api_response,
    archivedPropertyList,
    count,
    api_success_message,
    api_error_message,
    timezone,
  };
};
const mapStateToDispatch = {
  get_all_properties: SuperAdminAction.get_all_properties,
  get_all_properties_paginated: SuperAdminAction.get_all_properties_paginated,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  restoreProperty: SuperAdminAction.restoreProperty,
  deleteProperty: SuperAdminAction.deleteProperty,
};

export default connect(mapStatetoProps, mapStateToDispatch)(ArchivedProperty);

/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import QuickPermitForm from "../Components/AddQuickPermit/QuickPermitForm";
import { CurrentPermitActions, EnforcementActions } from "../Actions";
import { useDispatch } from "react-redux";

const AddQuickPermit = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(EnforcementActions.get_user_lots());
    dispatch(CurrentPermitActions.fetchResidentList());
  }, []);
  return (
    <div>
      <QuickPermitForm />
    </div>
  );
};

export default AddQuickPermit;

import React, { Component } from "react";
import { Card, Row, Col, Button, Form, FormLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import DataTable from "react-data-table-component";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { connect } from "react-redux";
import { SuperAdminAction, EnforcementActions } from "../../Actions";
import Loader from "../Loader/Loader";
import { tableHeightScrollBarsTab, findBySearch, utcToSpecificTimeZone, scroolToTop } from "../helper";
import isEqual from "lodash/isEqual";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropertDetails from "../LandingPage/PropertySelection";
import moment from "moment";
import { saveAs } from "file-saver";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";

class RevenueSummaryReport extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.state = {
      PropertyId: this.props.PropertyId,
      ticketList: [],
      searchValue: "",
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      showModal: false,
      status: "Net Total",
      showTicketDetails: false,
      ticketPdfUrl: "",
      isSendMailModalOpen: false,
      emailToSend: "",
      isMailFormValid: false,
      selectedTicketId: "",
      isMailModal: false,
      selectedProperty: "",
      selectedPropertyObj: {},
      all_properties: [],
      childcheckbox: true,
      ticketReportLots: [],
      lot_id: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: endDate,
      month: "",
      apiResponse: "",
      exportLink: false,
      exportButtonDisable: true,
      daysDifference: 30,
      isSetMaxTime: 1,
      startDateTime: "",
      endDateTime: "",
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };
    this.columns = [
      {
        name: "Domain",
        width: "160px",
        sortable: true,
        cell: (row) => this.showCellValue(row?.domain, "text"),
        selector: (row) => row?.domain,
      },
      {
        name: "Meter",
        width: "180px",
        sortable: true,
        cell: (row) => this.showCellValue(row.Agent, "text"),
        selector: (row) => row.Agent,
      },
      {
        name: "Location",
        sortable: true,
        width: "180px",
        cell: (row) => this.showCellValue(row.Location, "text"),
        selector: (row) => row.Location,
      },
      {
        name: "Total $",
        sortable: true,
        cell: (row) => this.showCellValue(row.TotalAmount, "amount"),
        selector: (row) => row.TotalAmount,
      },
      {
        name: "Total #",
        sortable: true,
        cell: (row) => this.showCellValue(row.TotalTicket, "text"),
        selector: (row) => row.TotalTicket,
      },
      {
        name: "Cash $",
        sortable: true,
        cell: (row) => this.showCellValue(row.CashAmount, "amount"),
        selector: (row) => row.CashAmount,
      },
      {
        name: "Cash #",
        sortable: true,
        cell: (row) => this.showCellValue(row.CashCount, "text"),
        selector: (row) => row.CashCount,
      },
      {
        name: "Interac Debit $",
        sortable: true,
        minWidth: "130px",
        cell: (row) => this.showCellValue(row.InteracAmount, "amount"),
        selector: (row) => row.InteracAmount,
      },
      {
        name: "Interac Debit #",
        sortable: true,
        minWidth: "130px",
        cell: (row) => this.showCellValue(row.InteracCount, "amount"),
        selector: (row) => row.InteracCount,
      },
      {
        name: "Master Card $",
        sortable: true,
        minWidth: "130px",
        cell: (row) => this.showCellValue(row.MasterAmount, "amount"),
        selector: (row) => row.MasterAmount,
      },
      {
        name: "Master Card #",
        sortable: true,
        minWidth: "130px",
        cell: (row) => this.showCellValue(row.MasterCount, "text"),
        selector: (row) => row.MasterCount,
      },
      {
        name: "Visa $",
        sortable: true,
        cell: (row) => this.showCellValue(row.VisaAmount, "amount"),
        selector: (row) => row.VisaAmount,
      },
      {
        name: "Visa #",
        sortable: true,
        cell: (row) => this.showCellValue(row.VisaCount, "text"),
        selector: (row) => row.VisaCount,
      },
      {
        name: "Amex $",
        sortable: true,
        cell: (row) => this.showCellValue(row.AmexAmount, "amount"),
        selector: (row) => row.AmexAmount,
      },
      {
        name: "Amex #",
        sortable: true,
        cell: (row) => this.showCellValue(row.AmexCount, "text"),
        selector: (row) => row.AmexCount,
      },
      {
        name: "Other $",
        sortable: true,
        cell: (row) => this.showCellValue(row.OtherAmount, "amount"),
        selector: (row) => row.OtherAmount,
      },
      {
        name: "Other #",
        sortable: true,
        cell: (row) => this.showCellValue(row.OtherCount, "text"),
        selector: (row) => row.OtherCount,
      },
    ];

    this.newColumns = [
      {
        name: "Month/Year",
        sortable: true,
        width: "160px",
        cell: (row) => `${this.state.month.getMonth() + 1} / ${this.state.month.getFullYear()}`,
        selector: (row) => row.created_at,
      },
      ...this.columns,
    ];
  }

  showCellValue = (cellValue, valueType) => {
    switch (valueType) {
      case "text":
        return (
          <>
            {cellValue ? (
              <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{cellValue}</Tooltip>}>
                <div className="text-nowrap text-truncate pr-3">{cellValue}</div>
              </OverlayTrigger>
            ) : (
              "-"
            )}
          </>
        );
      case "amount":
        return (
          <>
            {cellValue ? (
              <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                placement={"top"}
                overlay={<Tooltip id={`tooltip-qw`}>{Math.max(0, parseFloat(cellValue)).toFixed(2)}</Tooltip>}
              >
                <div className="text-nowrap text-truncate pr-3">{Math.max(0, parseFloat(cellValue)).toFixed(2)}</div>
              </OverlayTrigger>
            ) : (
              "-"
            )}
          </>
        );
      case "time":
        return (
          <>
            {cellValue ? (
              <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                placement={"top"}
                overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(this.props.timezone, cellValue)}</Tooltip>}
              >
                <div className="text-nowrap text-truncate pr-3">{utcToSpecificTimeZone(this.props.timezone, cellValue)}</div>
              </OverlayTrigger>
            ) : (
              "-"
            )}
          </>
        );
      default:
        break;
    }
  };

  changeProperty(event) {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 90);
    this.setState(
      {
        selectedProperty: event.value,
        selectedPropertyObj: event,
        startDate: new Date(datebeforeThirtyDays),
        endDate: new Date(),
        month: null,
      },
      () => {
        const selectedPropertyObj = this.state.all_properties.length && this.state.all_properties.find((item) => item.id === this.state.selectedProperty);
        const selectedPropertyName = selectedPropertyObj ? selectedPropertyObj.site_name : "";
        const selectedDomainName = selectedPropertyObj ? selectedPropertyObj.domain : "";

        this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
        this.props.selected_Property(selectedPropertyObj);
        this.props.get_all_lots(event.value);
        const current_lot = this.props.lots;
        this.setState({
          ticketReportLots: current_lot,
        });
        this.setState({
          isFormValid: true,
          pageDropdownDisabled: false,
        });
      },
    );
  }

  handleCheckBox = () => {
    this.setState({ childcheckbox: !this.state.childcheckbox });
  };

  componentDidMount = async () => {
    await this.getTickets();
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    await this.props.get_all_lots(PropertyId);
    const current_lot = this.props.lots;
    this.setState(
      {
        all_properties: this.props?.getAllProperties,
        ticketReportLots: current_lot,
      },
      async () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );

    const exportUrl = `${process.env.REACT_APP_CONFIG_BASE_URL}`;
    if (exportUrl) {
      this.setState({
        exportUrl: `${exportUrl}/api/v1/revenue_report/export/${this.state?.property?.id}`,
        exportPdfUrl: `${exportUrl}/api/v1/revenue_report/exportPdf`,
      });
    }
    const height = tableHeightScrollBarsTab();
    this.setState({
      finalTableHeight: height,
    });
  };

  getTickets = async () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, childcheckbox, timezone, startDate, endDate, status } = this.state;
      const pageobj = {
        PropertyId,
        timezone,
        childcheckbox,
        status,
      };
      if (startDate) {
        pageobj.startDate = startDate;
      }
      if (endDate) {
        pageobj.endDate = endDate;
      }
      await this.props.getRevenueSummary(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        ticketList: this.props.ticketList?.rows?.length ? JSON.parse(JSON.stringify(this.props.ticketList.rows)) : [],
        count: this.props.ticketList.count,
      });
      this.setState({ loader: false });
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.ticketList, this.props.ticketList)) {
      this.setState({
        ticketList: JSON.parse(JSON.stringify(this.props.ticketList.rows)),
        count: this.props.ticketList.count,
      });
    }
    if (!isEqual(prevProps.lots, this.props.lots)) {
      this.setState({
        ticketReportLots: this.props.lots,
      });
    }
  };

  dataTableHandle = (ticketList) => {
    return (
      <DataTable
        data={this.state.searchValue.length ? this.state.searchedticketList : ticketList}
        columns={this.state.month ? this.newColumns : this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        onChangePage={scroolToTop}
        fixedHeader={true}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleChangeLot = ({ target }) => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 90);
    this.setState({
      [target.name]: target.value,
      month: null,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
    });
  };

  handleChangeStartingDates = (date) => {
    const selectedStartDate = moment(date);
    const month = moment();
    const calculatedEndDate = selectedStartDate.clone().add(3, "months");
    const endDate = calculatedEndDate.isAfter(month) ? month : calculatedEndDate;
    const selectedStartDateFormatted = selectedStartDate.format("YYYY-MM-DD");
    const stateSelectedStartDateFormatted = moment(this.state.startDate).format("YYYY-MM-DD");
    if (selectedStartDateFormatted !== stateSelectedStartDateFormatted) {
      selectedStartDate.startOf("day");
    }
    endDate.set({ hour: 23, minute: 59, second: 59 });
    this.setState({
      startDate: new Date(selectedStartDate),
      endDate: new Date(endDate),
      maxEndDate: new Date(endDate),
      month: null,
    });
  };

  handleChangeEndDates = (date) => {
    let endDate = date;
    if (this.state.isSetMaxTime) {
      endDate = moment(date).set({ hour: 23, minute: 59, second: 59 });
    }
    this.setState({
      endDate: new Date(endDate),
      month: null,
    });
  };

  handleSearch = () => {
    this.setState({ loader: true }, async () => {
      const { lot_id, childcheckbox, month, startDate, endDate, selectedPropertyObj, PropertyId, exportLink, timezone, status } = this.state;
      const pageobj = {
        childcheckbox,
        PropertyId,
        timezone,
        exportLink,
        status,
      };
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }
      if (lot_id) {
        pageobj.lot_id = lot_id;
      }

      if (month) {
        pageobj.month = month;
      }

      if (startDate && endDate) {
        pageobj.startDate = startDate;
        pageobj.endDate = endDate;
      }
      await this.props.getRevenueSummary(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      this.setState({
        ticketList: JSON.parse(JSON.stringify(this.props.ticketList.rows)),
        count: this.props.ticketList.count,
        exportButtonDisable: false,
      });
      this.setState({ loader: false });
      document.body.classList.remove("foc-open");
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ searchValue: val }, async () => {
      const columnList = [
        "Agent",
        "Location",
        "TotalAmount",
        "TotalTicket",
        "CashAmount",
        "CashCount",
        "InteracAmount",
        "InteracCount",
        "MasterAmount",
        "MasterCount",
        "VisaAmount",
        "VisaCount",
        "OtherAmount",
        "OtherCount",
        "transaction_time",
      ];
      if (this.state.searchValue.length) {
        this.setState(
          {
            searchedticketList: findBySearch(this.state.searchValue, this.state.ticketList, this.state.ticketList, columnList, this.props.timezone),
          },
          () => {
            this.setState({ count: this.state.searchedticketList.length });
          },
        );
      } else {
        this.setState({
          count: this.props.ticketList.count,
          searchedticketList: [],
          ticketList: this.props.ticketList.rows,
        });
      }
    });
  };

  handleChangeStatus = ({ target }) => {
    this.setState({
      status: target.value,
    });
  };

  handleCurrentDate = (date) => {
    this.setState({
      month: date,
      startDate: null,
      endDate: null,
    });
  };

  resetAll = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 59);
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState({
      month: null,
      lot_id: "",
      agent_id_type: "",
      childcheckbox: true,
      startDate: new Date(datebeforeThirtyDays),
      endDate: endDate,
      selectedPropertyObj: {
        label: this.state.selectedDomainName,
        value: this.state.PropertyId,
      },
      status: "Net Total",
      payment_type: "",
      isSetMaxTime: 1,
    });
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleExport = async () => {
    const { lot_id, childcheckbox, month, startDate, endDate, selectedPropertyObj, PropertyId, selectedDomainName, timezone, status } = this.state;
    const pageobj = {
      childcheckbox,
      PropertyId,
      timezone,
      exportData: true,
      status,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (lot_id) {
      pageobj.lot_id = lot_id;
    }

    if (month) {
      pageobj.month = month;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }

    const bufferData = await this.props.getRevenueSummary(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `REVENUE-SUMMARY-${currentTimeDate}-${selectedDomainName}.xls`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj, true);
    this.setState({
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    });
  };

  handleMaxTime = () => {
    if (!this.state.isSetMaxTime) {
      const endDate = moment(this.state.endDate).set({ hour: 23, minute: 59, second: 59 });
      this.setState({
        endDate: new Date(endDate),
      });
    }
    this.setState({
      isSetMaxTime: !this.state.isSetMaxTime,
    });
  };

  render() {
    const {
      ticketList,
      loader,
      childcheckbox,
      ticketReportLots,
      lot_id,
      startDate,
      maxEndDate,
      endDate,
      searchValue,
      month,
      status,
      startDateTime,
      endDateTime,
    } = this.state;

    return (
      <div>
        <Card className="mb-0">
          <Card.Body className="pos-rel-overflow-hide">
            <>
              <div className="sidebarFilter">
                <Card className="contact-container mb-0">
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title as="h4">Revenue Summary Report</Card.Title>
                      <a
                        onClick={this.hideSlider}
                        href="javascript:void(0);"
                        className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <label className="fancy-checkbox">
                            <input
                              type="checkbox"
                              name={"childcheckbox"}
                              checked={childcheckbox}
                              onChange={this.handleCheckBox}
                              className="custom-control-input"
                              id="childcheckbox1"
                            ></input>
                            <span>
                              <i></i>Include Children Sites
                            </span>
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>LOTS:</Form.Label>
                          <Form.Control
                            as="select"
                            name="lot_id"
                            value={lot_id}
                            onChange={this.handleChangeLot}
                            disabled={ticketReportLots.length < 1}
                            placeholder="Select Lot"
                          >
                            {ticketReportLots && ticketReportLots.length ? (
                              <>
                                <option value="">Any</option>
                                {ticketReportLots.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.alias}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <Form.Label>Status:</Form.Label>
                          <Form.Control as="select" name="status" value={status} onChange={this.handleChangeStatus}>
                            <>
                              <option value="Paid">Paid</option>
                              <option value="Refunded">Refunded</option>
                              <option value="Net Total">Total Summary</option>
                            </>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Month/Year:</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Date"
                            selected={month}
                            value={month}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleCurrentDate}
                            showMonthYearPicker
                            dateFormat="MM/yyyy"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Start Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Start Date"
                            selected={startDate}
                            defaultHour={24}
                            value={startDate}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleChangeStartingDates}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeSelect
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <div class="d-flex align-items-center">
                            <label className="mb-0 mr-2">End Date(Date Range):</label>
                            <OverlayTrigger
                              delay={{ show: 250, hide: 400 }}
                              placement={"top"}
                              overlay={<Tooltip style={{ zIndex: "20000" }}>When checked, it sets max_time to 23:59, indicating end of day.</Tooltip>}
                            >
                              <FormLabel className="ml-auto d-flex align-items-center text-nowrap">
                                <input type="checkbox" className="mr-1" checked={this.state.isSetMaxTime} onChange={this.handleMaxTime}></input>
                                <span>Max Time </span>{" "}
                              </FormLabel>
                            </OverlayTrigger>{" "}
                          </div>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select End Date"
                            selected={endDate}
                            disabled={loader}
                            value={endDate}
                            selectsEnd
                            timeInputLabel="Time:"
                            onChange={this.handleChangeEndDates}
                            dateFormat="MM/dd/yyyy h:mm aa"
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            maxDate={maxEndDate}
                            showTimeSelect
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-right justify-content-between d-flex">
                    <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                      Reset
                    </Button>
                    <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                      Search
                    </Button>
                  </Card.Footer>
                </Card>
              </div>
              <header>
                <div className="active-permit-header sm-flex-column sm-align-items-start">
                  <h5 className="mb-2 mb-md-0">{status} Summary</h5>
                  <div className="filter-button d-flex flex-wrap">
                    <div className="d-flex align-items-center search-mb-0">
                      <DatatableHeader
                        className="mt-0 mb-0"
                        searchValue={searchValue}
                        HandleChange={this.handleSearchChange}
                        // findMorePressed={this.findMorePressed}
                      />
                    </div>
                    <div className="ml-2">
                      <Button variant="info" className="btn-fill btn-sm" onClick={this.handleExport} disabled={ticketList.length > 0 ? 0 : 1} size="medium">
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </header>
            </>
            {loader ? (
              <Loader />
            ) : (
              <>
                {ticketList && (
                  <>
                    <div className="citation-list-table list-of-ravenue-table has-parent-tab revenue-report-table">{this.dataTableHandle(ticketList)}</div>
                    <DateRangeAndAmount
                      ReportList={this.props?.ticketList?.rows}
                      amountName="TotalAmount"
                      startDateTime={startDateTime}
                      endDateTime={endDateTime}
                    />
                  </>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails, SuperAdminDetails, CurrentPermitDetails, EnforcementDetails }) => {
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;
  const { revenueSummaryList: ticketList } = EnforcementDetails;
  const { backgroundColor } = Logindetails;
  const { residentList } = CurrentPermitDetails;
  const { getAllProperties, lots } = SuperAdminDetails;
  const { MainBackground: main_body } = backgroundColor;
  return {
    PropertyId,
    timezone,
    property,
    ticketList,
    main_body,
    getAllProperties,
    residentList,
    lots,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  getRevenueSummary: EnforcementActions.getRevenueSummary,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  get_all_lots: SuperAdminAction.get_all_lots,
};

export default connect(mapStateToProps, mapStateToDispatch)(RevenueSummaryReport);

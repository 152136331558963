/**  <-------------------------- Read this once before you do any changes in this file. ---------------------------------->
  This is a common modal for citations actions.
  The changes we do in this modal will reflect
  at two places ( when we are creating citation and when we are clicking on citation number from citation list).
  All Apis are same
 <--------------------------- Important Information --------------------------------------------> */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Button, Row, Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { TicketActions } from "../../Actions";
import ModalLoader from "../Loader/ModalLoader";
import download from "../../../assets/img/svg/ticket-icons/citation-download.svg";
import print from "../../../assets/img/svg/ticket-icons/citation-print.svg";
import printzebra from "../../../assets/img/svg/ticket-icons/citation-print-zebra.svg";
import email from "../../../assets/img/svg/ticket-icons/citation-email.svg";
import cancel from "../../../assets/img/svg/ticket-icons/citation-cancel.svg";
import { getBlobUrl } from "../helper";
const CitationPrintModal = ({
  onOpen,
  onClose,
  modalTitle,
  pdfLoader,
  pdfUrl,
  zplData,
  isMobile,
  detectedDevice,
  printTicketDetails,
  printToZebra,
  pngurl,
  sendPdfPngMail,
  printButtonDisable,
  zplUrlForAndroid,
  // isCitationList,
  getPDFandPNGfromZpl,
}) => {
  useEffect(() => {
    const aurl = document.getElementById("hiddenPrint");
    aurl.href = zplUrlForAndroid;
  }, [zplUrlForAndroid]);

  // const printToIOS = () => {
  //   const aurl = document.getElementById("hiddenPrint");
  //   aurl.click();
  // };

  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log("numPages", numPages);
  };

  const saveAsPng = async () => {
    const pngUrl = await getPDFandPNGfromZpl(zplData, "png");
    const blobPng = getBlobUrl(pngUrl, "png");
    const png = document.getElementById("png");
    png.href = blobPng;
    png.click();
  };

  return (
    <React.Fragment>
      <Modal
        id="fullTicketDetails"
        lg={"medium"}
        show={onOpen}
        onHide={onClose}
        dialogClassName="modal-90w"
        fullscreen={"sm-down"}
        className="modalData"
      >
        <Modal.Header
          id="ignoreHeader"
          className="d-flex justify-content-between bg-white align-items-center"
        >
          <h4 className="m-0 font-weight-bold">
            <Modal.Title as="h4" className="m-0">
              {modalTitle}
            </Modal.Title>
          </h4>
          <Button
            title={"Deactivate"}
            type="button"
            onClick={onClose}
            className="close-btn py-0"
            data-dismiss="modal"
          >
            <i className="nc-icon nc-simple-remove text-danger"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {console.log("in    modal")}
          {pdfLoader ? (
            <PdfContainer>
              <ModalLoader />
            </PdfContainer>
          ) : (
            <div>
              {pdfUrl && !isMobile && (
                <>
                  <EmbedIframe
                    className={"pdf-frame"}
                    src={pdfUrl}
                    rotate={180}
                  />
                </>
              )}
              {pdfUrl && isMobile && (
                <>
                  {detectedDevice === "iOS" && (
                    <EmbedIframe
                      className={"pdf-frame"}
                      src={pdfUrl}
                      rotate={180}
                    />
                  )}
                  {detectedDevice !== "iOS" && (
                    <div>
                      <Document
                        file={pdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page pageNumber={1} />
                      </Document>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <a
              href=""
              // target="_blank"
              id="hiddenPrint"
              style={{ display: "none" }}
            >
              click here to download your file
            </a>
            <Button
              id="ignoreButton2"
              className="btn btn-primary"
              type="button"
              onClick={printTicketDetails}
            >
              <img className="mr-1" src={print} width={18} />
              Print
            </Button>
            <Button
              className="btn btn-primary ml-2"
              type="button"
              onClick={printToZebra}
              disabled={printButtonDisable}
            >
              <img className="mr-1" src={printzebra} width={18} />
              Print to Zebra
            </Button>
            {/* {isCitationList && (
              <Button
                className="btn btn-primary ml-2"
                type="button"
                onClick={() => printToIOS()}
              >
                <img className="mr-1" src={printzebra} width={18} />
                Print to Ios
              </Button>
            )} */}
          </Row>
          <a
            id="pdf"
            href={pdfUrl}
            target={"_blank"}
            rel="noopener noreferrer"
            style={{ display: "none" }}
          >
            pdf
          </a>

          <a
            id="png"
            href={pngurl}
            target={"_blank"}
            download="Invoice.png"
            rel="noopener noreferrer"
            style={{ display: "none" }}
          >
            pdf
          </a>
          <Row>
            <Dropdown className="m-0">
              <Dropdown.Toggle>
                <img className="ml-0" src={download} width={18} /> Save as
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    document.getElementById("pdf").click();
                  }}
                >
                  PDF
                </Dropdown.Item>
                <Dropdown.Item onClick={saveAsPng}>PNG</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="m-0 ml-2">
              <Dropdown.Toggle>
                <img className="" src={email} width={18} /> Send mail as
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={sendPdfPngMail}>
                  PDF And PNG
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              id="ignoreButton3"
              className="btn-fill ml-0 mt-2 mt-sm-0 ml-sm-2"
              type="button"
              variant="secondary"
              onClick={onClose}
            >
              <img className="ml-0 mr-1" src={cancel} width={18} />
              Cancel
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToDispatch = {
  getPDFandPNGfromZpl: TicketActions.getPDFandPNGfromZpl,
};
export default connect(null, mapStateToDispatch)(CitationPrintModal);
const PdfContainer = styled.div`
  width: 100%;

  height: calc(100vh - 200px);
`;
const EmbedIframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 200px);
  rotate: degrees(-45);
`;

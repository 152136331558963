import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const SendMailModal = ({ closeModal, handleMailChange, emailToSend, emailError, ticketDataPdf, isMailFormValid, tital }) => {
  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      ticketDataPdf(event);
    }
  };
  return (
    <React.Fragment>
      <Modal
        id="ticketdetails"
        lg={"medium"}
        show={true}
        // onHide={closeModal}
        dialogClassName="modal-90w"
        fullscreen={"sm-down"}
        className="modalData"
      >
        <Modal.Header id="import" className="d-flex justify-content-between bg-white align-items-center">
          <h5 className="m-0 font-weight-bold">
            <Modal.Title className="m-0">{tital || "Send Mail as PDF And PNG"}</Modal.Title>
          </h5>
          <Button id="modal" title={"Ticket"} type="button" onClick={closeModal} className="close-btn py-0" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form>
            <label>
              Email Address
              <span className="asterisk">*</span>
            </label>
            <Form.Control
              placeholder="example@gmail.com"
              type="text"
              name="email"
              value={emailToSend}
              onChange={handleMailChange}
              onKeyDown={handleEnterPress}
              autoComplete="off"
              required
            />
          </Form>
          <span className="help-block">{emailError}</span>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button disabled={!isMailFormValid} onClick={(e) => ticketDataPdf(e)}>
            Send Mail
          </Button>
          <Button onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default SendMailModal;

import React, { Component } from "react";
import { Card, Row, Col, Button, Form, Tooltip, OverlayTrigger, Container, FormLabel } from "react-bootstrap";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import DataTable from "react-data-table-component";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { connect } from "react-redux";
import { SuperAdminAction, TicketActions } from "../../Actions";
import Loader from "../Loader/Loader";
import { tableHeightScrollBars, findBySearch, utcToSpecificTimeZone, scroolToTop } from "../helper";
import isEqual from "lodash/isEqual";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropertDetails from "../LandingPage/PropertySelection";
import { saveAs } from "file-saver";
import moment from "moment";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";

class RatePayment extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.state = {
      PropertyId: this.props.PropertyId,
      rateTrackList: [],
      loader: false,
      searchValue: "",
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      selectedPropertyObj: {},
      all_properties: [],
      childcheckbox: true,
      ticketReportLots: [],
      lot_id: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: endDate,
      currentSummaryDate: "",
      finalTableHeight: 0,
      daysDifference: 30,
      isSetMaxTime: 1,
      startDateTime: "",
      endDateTime: "",
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "Domain",
        sortable: true,
        width: "120px",
        cell: (row) => this.showDomainField(row),
      },
      {
        name: "Entry Time",
        width: "160px",
        cell: (row) => this.showEntryTimeField(row),
        selector: (row) => row.entry_time,
      },
      {
        name: "Exit Time",
        width: "160px",
        cell: (row) => this.showExitTimeField(row),
        selector: (row) => row.end_time,
      },
      {
        name: "Ticket barcode Number",
        width: "200px",
        sortable: true,
        cell: (row) => this.showTicketBarCodeField(row),
        selector: (row) => row?.barcode_no,
      },
      {
        name: "Parking Discount Coupon",
        width: "160px",
        sortable: true,
        cell: (row) => this.showDiscountCouponField(row),
        selector: (row) => row?.merchant_code,
      },
      {
        name: "LPN",
        width: "140px",
        sortable: true,
        cell: (row) => this.showLpnField(row),
        selector: (row) => row.lpn,
      },
      {
        name: "Rate Name",
        sortable: true,
        width: "160px",
        cell: (row) => this.showRateNameField(row),
        selector: (row) => row.rate_names,
      },
      {
        name: "Payment Type",
        width: "160px",
        sortable: true,
        cell: (row) => this.showPaymentTypeField(row),
        selector: (row) => row.payment_type,
      },
      {
        name: "One Time Validation",
        sortable: true,
        width: "200px",
        cell: (row) => this.showOneTimeValidationField(row),
        selector: (row) => row.onetime_validation,
      },
      {
        name: "Amount Discounted",
        sortable: true,
        width: "160px",
        cell: (row) => this.showAmountDiCountedField(row),
        selector: (row) => row.discounted_amount,
      },
      {
        name: "Meter Type",
        sortable: true,
        width: "160px",
        cell: (row) => this.showMeterTypeField(row),
        selector: (row) => row.meter_type,
      },
      {
        name: "Cashier Name",
        sortable: true,
        width: "160px",
        cell: (row) => this.showCashierField(row),
        selector: (row) => row.cashier_name,
      },
      {
        name: "Manual Payment",
        sortable: true,
        width: "160px",
        cell: (row) => this.showManualPaymentField(row),
        selector: (row) => row.manual_payment,
      },
      {
        name: "Rate Amount",
        width: "160px",
        sortable: true,
        cell: (row) => this.showRateAmountField(row),
        selector: (row) => row.rate_amount,
      },
      {
        name: "Amount Paid",
        width: "160px",
        sortable: true,
        cell: (row) => this.AmountPaidField(row),
        selector: (row) => row.paid_amount,
      },
      {
        name: "Processor Response",
        sortable: true,
        width: "160px",
        cell: (row) => this.showVoucherCodeField(row),
        selector: (row) => row.voucher_code,
      },
    ];
  }

  showVoucherCodeField = (row) => (
    <>
      {row.voucher_code ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.voucher_code}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.voucher_code}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  AmountPaidField = (row) => (
    <>
      {row.paid_amount ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>${parseFloat(row.paid_amount).toFixed(2)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">${parseFloat(row.paid_amount).toFixed(2)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showRateAmountField = (row) => (
    <>
      {row.rate_amount ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>${parseFloat(row.rate_amount).toFixed(2)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">${parseFloat(row.rate_amount).toFixed(2)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showManualPaymentField = (row) => (
    <>
      {row.manual_payment ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.manual_payment}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.manual_payment}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showCashierField = (row) => (
    <>
      {row.cashier_name ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.cashier_name}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.cashier_name}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showMeterTypeField = (row) => (
    <>
      {row.meter_type ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.meter_type}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.meter_type}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showAmountDiCountedField = (row) => (
    <>
      {row.discounted_amount ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.discounted_amount}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.discounted_amount}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showOneTimeValidationField = (row) => (
    <>
      {row.onetime_validation ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.onetime_validation}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.onetime_validation}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showPaymentTypeField = (row) => (
    <>
      {row.payment_type ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.payment_type}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.payment_type}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showRateNameField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.rate_names}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2">{row.rate_names}</div>
    </OverlayTrigger>
  );

  showLpnField = (row) => (
    <>
      {row.lpn ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.lpn}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.lpn}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showDiscountCouponField = (row) => (
    <>
      {row?.merchant_code ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.merchant_code}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.merchant_code}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showTicketBarCodeField = (row) => (
    <>
      {row?.barcode_no ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}> {row?.barcode_no}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.barcode_no}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showExitTimeField = (row) => (
    <>
      {row.end_time ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(this.props.timezone, row.end_time, true)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(this.props.timezone, row.end_time, true)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showEntryTimeField = (row) => (
    <>
      {row.entry_time ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(this.props.timezone, row.entry_time, true)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(this.props.timezone, row.entry_time, true)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showDomainField = (row) => (
    <>
      {row?.properties?.domain ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.properties?.domain}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.properties?.domain}</div>
        </OverlayTrigger>
      ) : (
        ""
      )}
    </>
  );

  changeProperty(event) {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState(
      {
        selectedProperty: event.value,
        selectedPropertyObj: event,
        startDate: new Date(datebeforeThirtyDays),
        endDate: new Date(),
        currentSummaryDate: null,
      },
      () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === this.state.selectedProperty);
        this.props.selected_Property(selectedPropertyObj);
        this.props.get_all_lots(event.value);
        const current_lot = this.props.lots;
        this.setState({
          ticketReportLots: current_lot,
        });
      },
    );
  }

  handleCheckBox = () => {
    this.setState({ childcheckbox: !this.state.childcheckbox });
  };

  componentDidMount = async () => {
    await this.getTickets();
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    await this.props.get_all_lots(PropertyId);
    const current_lot = this.props.lots;
    this.setState(
      {
        all_properties: this.props?.getAllProperties,
        ticketReportLots: current_lot,
      },
      async () => {
        const selectedPropertyObj = (this.state?.all_properties ?? []).find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );

    const exportUrl = `${process.env.REACT_APP_CONFIG_BASE_URL}`;
    if (exportUrl) {
      this.setState({
        exportUrl: `${exportUrl}/api/v1/revenue_report/export/${this.state?.property?.id}`,
        exportPdfUrl: `${exportUrl}/api/v1/revenue_report/exportPdf`,
      });
    }
    const height = tableHeightScrollBars();
    this.setState({
      finalTableHeight: height,
    });
  };

  getTickets = async () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, childcheckbox, timezone, startDate, endDate } = this.state;
      const pageobj = {
        PropertyId,
        timezone,
        childcheckbox,
      };
      if (startDate) {
        pageobj.startDate = startDate;
      }
      if (endDate) {
        pageobj.endDate = endDate;
      }
      await this.props.getReportList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        rateTrackList: this.props?.rateTrackList?.rows?.length ? JSON.parse(JSON.stringify(this.props.rateTrackList.rows)) : [],
        count: this.props.rateTrackList.count,
      });
      this.setState({ loader: false });
    });
  };

  GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj, true);
    this.setState({
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.rateTrackList, this.props.rateTrackList)) {
      this.setState({
        rateTrackList: JSON.parse(JSON.stringify(this.props.rateTrackList.rows)),
        count: this.props.rateTrackList.count,
      });
    }
    if (!isEqual(prevProps.lots, this.props.lots)) {
      this.setState({
        ticketReportLots: this.props.lots,
      });
    }
  };

  dataTableHandle = (rateTrackList) => {
    return (
      <DataTable
        data={this.state.searchValue.length ? this.state.searchedrateTrackList : rateTrackList}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleChangeLot = ({ target }) => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState({
      [target.name]: target.value,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentSummaryDate: null,
    });
  };

  handleCurrentDate = (date) => {
    this.setState({
      currentSummaryDate: date,
      startDate: null,
      endDate: null,
    });
  };

  handleChangeStartingDates = (date) => {
    const selectedStartDate = moment(date);
    const currentSummaryDate = moment();
    const calculatedEndDate = selectedStartDate.clone().add(3, "months");
    const endDate = calculatedEndDate.isAfter(currentSummaryDate) ? currentSummaryDate : calculatedEndDate;
    const selectedStartDateFormatted = selectedStartDate.format("YYYY-MM-DD");
    const stateSelectedStartDateFormatted = moment(this.state.startDate).format("YYYY-MM-DD");
    if (selectedStartDateFormatted !== stateSelectedStartDateFormatted) {
      selectedStartDate.startOf("day");
    }
    endDate.set({ hour: 23, minute: 59, second: 59 });
    this.setState({
      startDate: new Date(selectedStartDate),
      endDate: new Date(endDate),
      maxEndDate: new Date(endDate),
      currentSummaryDate: null,
    });
  };

  handleChangeEndDates = (date) => {
    let endDate = date;
    if (this.state.isSetMaxTime) {
      endDate = moment(date).set({ hour: 23, minute: 59, second: 59 });
    }
    this.setState({
      endDate: new Date(endDate),
      currentSummaryDate: null,
    });
  };

  handleMaxTime = () => {
    if (!this.state.isSetMaxTime) {
      const endDate = moment(this.state.endDate).set({ hour: 23, minute: 59, second: 59 });
      this.setState({
        endDate: new Date(endDate),
      });
    }
    this.setState({
      isSetMaxTime: !this.state.isSetMaxTime,
    });
  };

  handleSearch = () => {
    this.setState({ loader: true }, async () => {
      const { lot_id, childcheckbox, currentSummaryDate, startDate, endDate, selectedPropertyObj, PropertyId, timezone } = this.state;
      const pageobj = {
        childcheckbox,
        PropertyId,
        timezone,
      };
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }
      if (lot_id) {
        pageobj.lot_id = lot_id;
      }
      if (currentSummaryDate) {
        pageobj.currentSummaryDate = currentSummaryDate;
      }
      if (startDate && endDate) {
        pageobj.startDate = startDate;
        pageobj.endDate = endDate;
      }
      await this.props.getReportList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      this.setState({
        rateTrackList: JSON.parse(JSON.stringify(this.props.rateTrackList.rows)),
        count: this.props.rateTrackList.count,
      });
      this.setState({ loader: false });
      document.body.classList.remove("foc-open");
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ searchValue: val }, async () => {
      const columnList = [
        "entry_time",
        "end_time",
        "barcode_no",
        "merchant_code",
        "validation_code",
        "lpn",
        "rate_names",
        "payment_type",
        "onetime_validation",
        "discounted_amountss",
        "meter_type",
        "cashier_name",
        "manual_payment",
        "rate_amount",
        "paid_amount",
        "voucher_code",
        "voucher_amount",
      ];
      if (this.state.searchValue.length) {
        if (!this.state.findMore) {
          this.setState(
            {
              searchedrateTrackList: findBySearch(this.state.searchValue, this.state.rateTrackList, this.state.rateTrackList, columnList, this.props.timezone),
            },
            () => {
              this.setState({ count: this.state.searchedrateTrackList.length });
            },
          );
        }
      } else {
        this.setState({
          count: this.props.rateTrackList.count,
          searchedrateTrackList: [],
          rateTrackList: this.props.rateTrackList.rows,
        });
      }
    });
  };

  resetAll = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 59);
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState({
      startDate: new Date(datebeforeThirtyDays),
      endDate: endDate,
      currentSummaryDate: null,
      lot_id: "",
      childcheckbox: true,
      isSetMaxTime: 1,
      selectedPropertyObj: {
        label: this.state.selectedDomainName,
        value: this.state.PropertyId,
      },
    });
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleExport = async () => {
    const { lot_id, childcheckbox, currentSummaryDate, startDate, endDate, selectedPropertyObj, PropertyId, selectedDomainName, timezone } = this.state;
    const pageobj = {
      childcheckbox,
      PropertyId,
      timezone,
      exportData: true,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (lot_id) {
      pageobj.lot_id = lot_id;
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    const bufferData = await this.props.getReportList(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `RATE-TRACKING-${currentTimeDate}-${selectedDomainName}.xls`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  render() {
    const { rateTrackList, loader, childcheckbox, ticketReportLots, lot_id, startDate, endDate, currentSummaryDate, searchValue, maxEndDate } = this.state;

    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="mb-0">
                <Card.Body className="pos-rel-overflow-hide">
                  <>
                    <div className="sidebarFilter">
                      <Card className="contact-container mb-0">
                        <Card.Header style={{ backgroundColor: this.props.main_body }}>
                          <div className="d-flex align-items-center justify-content-between">
                            <Card.Title as="h4">Rate Tracking</Card.Title>
                            <a
                              onClick={this.hideSlider}
                              href="javascript:void(0);"
                              className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            <Col md="12">
                              <div className="mb-2">
                                <label className="fancy-checkbox">
                                  <input
                                    type="checkbox"
                                    name={"childcheckbox"}
                                    checked={childcheckbox}
                                    onChange={this.handleCheckBox}
                                    className="custom-control-input"
                                    id="childcheckbox1"
                                  ></input>
                                  <span>
                                    <i></i>Include Children Sites
                                  </span>
                                </label>
                              </div>
                            </Col>
                            <Col md="12">
                              <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                            </Col>
                            <Col md="12">
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>LOTS:</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="lot_id"
                                  value={lot_id}
                                  onChange={this.handleChangeLot}
                                  disabled={ticketReportLots.length < 1}
                                  placeholder="Select Lot"
                                >
                                  {ticketReportLots && ticketReportLots.length ? (
                                    <>
                                      <option value="">Any</option>
                                      {ticketReportLots.map((item, index) => (
                                        <option key={index} value={item.id}>
                                          {item.alias}
                                        </option>
                                      ))}
                                    </>
                                  ) : null}
                                </Form.Control>
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">Specific Date:</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Date"
                                  selected={currentSummaryDate}
                                  value={currentSummaryDate}
                                  disabled={loader}
                                  selectsStart
                                  timeInputLabel="Time:"
                                  onChange={this.handleCurrentDate}
                                  dateFormat="MM/dd/yyyy"
                                />
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">Start Date(Date Range):</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Start Date"
                                  selected={startDate}
                                  value={startDate}
                                  disabled={loader}
                                  selectsStart
                                  onChange={this.handleChangeStartingDates}
                                  dateFormat="MM/dd/yyyy h:mm aa"
                                  startDate={startDate}
                                  endDate={endDate}
                                  maxDate={new Date()}
                                  showTimeSelect
                                />
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <div class="d-flex align-items-center">
                                  <label className="mb-0 mr-2">End Date(Date Range):</label>
                                  <OverlayTrigger
                                    delay={{ show: 250, hide: 400 }}
                                    placement={"top"}
                                    overlay={<Tooltip style={{ zIndex: "20000" }}>When checked, it sets max_time to 23:59, indicating end of day.</Tooltip>}
                                  >
                                    <FormLabel className="ml-auto d-flex align-items-center text-nowrap">
                                      <input type="checkbox" className="mr-1" checked={this.state.isSetMaxTime} onChange={this.handleMaxTime}></input>
                                      <span>Max Time</span>{" "}
                                    </FormLabel>
                                  </OverlayTrigger>{" "}
                                </div>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select End Date"
                                  selected={endDate}
                                  disabled={loader}
                                  value={endDate}
                                  selectsEnd
                                  timeInputLabel="Time:"
                                  onChange={this.handleChangeEndDates}
                                  dateFormat="MM/dd/yyyy h:mm aa"
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={startDate}
                                  maxDate={maxEndDate}
                                  showTimeSelect
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer className="text-right justify-content-between d-flex">
                          <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                            Reset
                          </Button>
                          <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                            Search
                          </Button>
                        </Card.Footer>
                      </Card>
                    </div>
                    <header>
                      <div className="active-permit-header sm-flex-column sm-align-items-start">
                        <h5 className="mb-2 mb-md-0">Rate Tracking</h5>
                        <div className="filter-button d-flex flex-wrap">
                          <div className="d-flex align-items-center search-mb-0">
                            <DatatableHeader
                              className="mt-0 mb-0"
                              searchValue={searchValue}
                              HandleChange={this.handleSearchChange}
                              // findMorePressed={this.findMorePressed}
                            />
                          </div>
                          <div className="ml-2">
                            <Button
                              variant="info"
                              className="btn-fill btn-sm"
                              onClick={this.handleExport}
                              disabled={rateTrackList.length > 0 ? 0 : 1}
                              size="medium"
                            >
                              Export
                            </Button>
                          </div>
                        </div>
                      </div>
                    </header>
                  </>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {rateTrackList && (
                        <>
                          <div className="citation-list-table">{this.dataTableHandle(rateTrackList)}</div>
                        </>
                      )}
                      <DateRangeAndAmount startDateTime={this.state.startDateTime} endDateTime={this.state.endDateTime} />
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails, SuperAdminDetails, CurrentPermitDetails, TicketDetails }) => {
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;

  const { backgroundColor } = Logindetails;
  const { getAllProperties, lots } = SuperAdminDetails;
  const { MainBackground: main_body } = backgroundColor;
  const { ratePaymentList: rateTrackList } = TicketDetails;
  return {
    PropertyId,
    timezone,
    property,
    rateTrackList,
    main_body,
    getAllProperties,
    lots,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  get_all_lots: SuperAdminAction.get_all_lots,
  getReportList: TicketActions.getRatePaymentList,
};

export default connect(mapStateToProps, mapStateToDispatch)(RatePayment);

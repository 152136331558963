export const getDateInFrenchLanguage = (date, language) => {
  if (language === "fn") {
    const English = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const French = [
      "janv.",
      "févr.",
      "mars",
      "avril",
      "mai",
      "juin",
      "juil.",
      "août",
      "sept.",
      "oct.",
      "nov.",
      "déc.",
    ];
    console.log("date.substring(0, 3)", date.substring(0, 3));
    const month = date.substring(0, 3);
    const index = English.indexOf(month);
    return date.replace(month, French[index]);
  } else {
    return date;
  }
};

export const getColorInFrenchLanguage = (color, language) => {
  if (language === "fn") {
    const English = [
      "White",
      "Black",
      "Gray",
      "Silver",
      "Red",
      "Blue",
      "Brown",
      "Green",
      "Beige",
      "Orange",
      "Gold",
      "Yellow",
      "Purple",
    ];
    const French = [
      "Blanc",
      "Noir",
      "Gris",
      "Argent",
      "Rouge",
      "Bleu",
      "Marron",
      "Vert",
      "Beige",
      "Orange",
      "Or",
      "Jaune",
      "Violet",
    ];

    const index = English.indexOf(color);
    return French[index];
  } else {
    return color;
  }
};

export const gettemplateDateInFrenchLanguage = (date, language) => {
  if (language === "fn") {
    const french_date_format = date;
    const WeekEnglish = [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat",
      "Sun",
    ];
    const WeekFrench = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];

    const English = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const French = [
      "janv.",
      "févr.",
      "mars",
      "avril",
      "mai",
      "juin",
      "juil.",
      "août",
      "sept.",
      "oct.",
      "nov.",
      "déc.",
    ];

    const week = french_date_format.substring(0, 3);
    const week_index = WeekEnglish.indexOf(week);

    const month = french_date_format.substring(3, 7).replace(" ", "");
    const index = English.indexOf(month);
    return french_date_format
      .replace(week, WeekFrench[week_index])
      .replace(month, French[index]);
  } else {
    return date;
  }
};

export const gettimeSlotDateInFrenchLanguage = (date, language) => {
  if (language === "fn") {
    const English = ["AM", "PM"];
    const French = ["du matin.", "de l'après-midi"];

    const time = date.slice(-2);
    const index = English.indexOf(time);
    return date.replace(time, French[index]);
  } else {
    return date;
  }
};

export const getBookingSlotDateInFrenchLanguage = (date, language) => {
  if (language === "fn") {
    const English = ["AM", "PM"];
    const French = ["du matin.", "de l'après-midi"];

    const MonthEnglish = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const MonthFrench = [
      "janv.",
      "févr.",
      "mars",
      "avril",
      "mai",
      "juin",
      "juil.",
      "août",
      "sept.",
      "oct.",
      "nov.",
      "déc.",
    ];

    const month = date.substring(0, 3).replace(" ", "");

    const indexmonth = MonthEnglish.indexOf(month);

    const time = date.slice(-2);
    const index = English.indexOf(time);
    return date
      .replace(month, MonthFrench[indexmonth])
      .replace(time, French[index]);
  } else {
    return date;
  }
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Form, Button, Tabs, Tab } from "react-bootstrap";
import { SuperAdminAction, TicketActions } from "../../Actions";
import { Translation } from "react-i18next";
import _i18n from "../Language/i18n";
import CustomContentAlert, { validations, validation_message, navigationButtons, isEmailValid, email_validation_message } from "../helper";
import _ from "lodash";

class CreateProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      selectedProperty: "",
      contractId: "",
      contactEmail: "",
      validContactEmail: true,
      costAndDaysExistErrorMessage: "",
      costErrorMessage: "",
      daysErrorMessage: "",
      minCostValue: 0,
      violationReasonFields: [
        {
          id: "",
          reason: "",
          description: "",
          language: "en",
          reasonCosts: [{ id: "", cost: 125, periodInDays: 0 }],
        },
      ],
      reasonValidityArray: [{ reasonValidity: true }],
      descriptionValidityArray: [{ descriptionValidity: true }],
      costValidityArray: [
        {
          isCostValid: [{ costValidity: true, costErrorMessage: "", daysErrorMessage: "" }],
        },
      ],
      costAndDaysLimitValidityArray: [
        {
          costAndDaysLimitValidityErrorMessage: "",
          isAddCostAndDaysDisabled: true,
        },
      ],
      validReason: true,
      validDescription: true,
      validPeriodInDays: true,
      validCost: true,
      isFormValid: false,
      isAddCostAndDaysDisabled: true,
      isUpdate: false,
      selectedKey: "en",
      isNextButtonDisable: true,
      propertyIdToBePassed: "",
      defalutLanguage: "",
      noCharge: false,
      hideVIN: false,
    };
  }

  componentDidMount = async () => {
    const property_id = this.props.location?.search?.split("?")[1] || "";
    await this.props.get_all_properties();
    this.setState(
      {
        properties: this.props?.getAllProperties,
        selectedProperty: property_id,
        propertyIdToBePassed: property_id,
      },
      async () => {
        const selectedPropertyObj = (this.state.properties ?? []).filter((item) => item.id === this.state.propertyIdToBePassed);
        const selectedPropertyName = selectedPropertyObj?.[0]?.site_name;
        const selectedDomainName = selectedPropertyObj?.[0]?.domain;

        this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
        await this.props.getReasonDetails(this.state.selectedProperty, true);
        if (this.props.api_response) {
          this.setState({
            isUpdate: !!this.props.reasonSetupDetail?.reasons?.length,
          });
          this.handleReasonSetup();
        }
      },
    );
  };

  handleReasonSetup = () => {
    if (this.props.reasonSetupDetail?.reasons?.length) {
      const { reasons, costValidityArray, reasonValidityArray, descriptionValidityArray, costAndDaysLimitValidityArray } = this.findReasons();
      this.setState(
        {
          violationReasonFields: reasons,
          contractId: this.props.reasonSetupDetail?.site_contract_id || "",
          contactEmail: this.props.reasonSetupDetail?.contact_email || "",
          defalutLanguage: this.props.reasonSetupDetail?.ticket_default_language || "en",
          noCharge: this.props.reasonSetupDetail?.no_charge === 1,
          citation_footer: this.props.reasonSetupDetail?.citation_footer || "",
          hideVIN: this.props.reasonSetupDetail?.vin_visibility === 1,
          isFormValid: this.isFormValid(),
          costValidityArray: costValidityArray,
          reasonValidityArray: reasonValidityArray,
          descriptionValidityArray: descriptionValidityArray,
          costAndDaysLimitValidityArray: costAndDaysLimitValidityArray,
          graceTime: this.props.reasonSetupDetail?.grace_time,
        },
        this.setIsFormValid,
      );
    } else {
      this.setState(
        {
          contractId: "",
          violationReasonFields: [
            {
              reason: "",
              description: "",
              language: "en",
              reasonCosts: [{ cost: 125, periodInDays: 0 }],
            },
          ],
          isFormValid: this.isFormValid(),
          reasonValidityArray: [{ reasonValidity: true }],
          descriptionValidityArray: [...this.state.descriptionValidityArray, { descriptionValidity: true }],
          costValidityArray: [
            {
              isCostValid: [
                {
                  costValidity: true,
                  costErrorMessage: "",
                  daysErrorMessage: "",
                },
              ],
            },
          ],
          costAndDaysLimitValidityArray: [
            {
              costAndDaysLimitValidityErrorMessage: "",
              isAddCostAndDaysDisabled: true,
            },
          ],
        },
        this.setIsFormValid,
      );
    }
  };

  findReasons = () => {
    const costValidityArray = [];
    const reasonValidityArray = [];
    const descriptionValidityArray = [];
    const costAndDaysLimitValidityArray = [];
    const reasons = this.props.reasonSetupDetail?.reasons.map((item) => {
      const reasonCosts = [];
      const isCostValid = [];
      const _reasonCost = item?.reason_costs?.length ? item.reason_costs : [];
      _reasonCost.sort(function (a, b) {
        const keyA = +a.cost;
        const keyB = +b.cost;
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      _reasonCost.map((reasonCost) => {
        const reasonCostsObj = {};
        const costValidityObj = {};
        reasonCostsObj.cost = reasonCost.cost;
        reasonCostsObj.periodInDays = reasonCost.period_in_days ? reasonCost.period_in_days : 0;
        reasonCostsObj.id = reasonCost.id;
        costValidityObj.costValidity = true;
        costValidityObj.costErrorMessage = "";
        costValidityObj.daysErrorMessage = "";
        reasonCosts.push(reasonCostsObj);
        isCostValid.push(costValidityObj);
        return 1;
      });
      const isCostValidObj = {};
      isCostValidObj.isCostValid = isCostValid;
      costValidityArray.push(isCostValidObj);
      reasonValidityArray.push({ reasonValidity: true });
      descriptionValidityArray.push({ descriptionValidity: true });
      costAndDaysLimitValidityArray.push({
        costAndDaysLimitValidityErrorMessage: "",
        isAddCostAndDaysDisabled: true,
      });
      return {
        reason: item.title,
        description: item.description,
        language: item.language,
        reasonCosts: reasonCosts,
        id: item.id,
      };
    });
    return { reasons, costValidityArray, reasonValidityArray, descriptionValidityArray, costAndDaysLimitValidityArray };
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value }, async () => {
      const isEmailValidate = isEmailValid(this.state.contactEmail);
      switch (target.name) {
        case "contactEmail":
          {
            const ContactEmail = validations(target.name, "contactEmail", this.state.contactEmail);
            this.setState({
              contactEmail: ContactEmail.value,
              validContactEmail: ContactEmail.validator,
            });
          }
          break;
        case "defalutLanguage":
          // eslint-disable-next-line no-lone-blocks
          {
            this.setState({
              defalutLanguage: target.value,
            });
          }
          break;
        case "citation_footer":
          // eslint-disable-next-line no-lone-blocks
          {
            if (target.value.length <= 400) {
              this.setState({
                citation_footer: target.value,
              });
            } else {
              this.setState({
                citation_footer_error: target.value,
              });
            }
          }
          break;
        case "graceTime":
          // eslint-disable-next-line no-lone-blocks
          {
            const inputValue = target.value;
            const isNumber = /^[0-9\b]+$/.test(inputValue);
            if (!inputValue) {
              this.setState({ graceTimeError: "Please enter grace time" });
            }
            if (isNumber && inputValue <= 2880) {
              this.setState({
                graceTime: parseInt(inputValue, 10),
                graceTimeError: null, // Reset the error state
              });
            } else {
              const graceTimeError_ = inputValue > 2880 ? "Grace time should be less than 2880 minutes" : "";
              this.setState({
                graceTimeError: !isNumber && inputValue !== "" ? "Please enter value in number" : graceTimeError_,
                graceTime: null, // Reset the graceTime state to null if the input is not a number or exceeds the limit
              });
            }
          }
          break;
        default:
          break;
      }
      this.setState(
        {
          wrongEmail: !isEmailValidate,
        },
        () => {
          this.setState({ isFormValid: this.isFormValid() });
        },
      );
    });
  };

  handleReasonChange = (index, event) => {
    const { violationReasonFields, selectedKey } = this.state;
    const inputValues = [...violationReasonFields];
    inputValues[index][event.target.name] = event.target.value;
    inputValues[index].language = selectedKey;
    this.setState(
      {
        violationReasonFields: inputValues,
      },
      () => {
        const inputValues = [...this.state.violationReasonFields];
        const reasonValidity = [...this.state.reasonValidityArray];
        const descriptionValidity = [...this.state.descriptionValidityArray];
        switch (event.target.name) {
          case "reason":
            {
              let reason = "";
              if (index === 0) {
                reason = validations(event.target.name, "reason", inputValues[index][event.target.name]);
                inputValues[index][event.target.name] = reason.value;
                reasonValidity[index].reasonValidity = reason.validator;
              }
              this.setState({
                violationReasonFields: inputValues,
                validReason: reason.validator,
                reasonValidityArray: reasonValidity,
              });
            }
            break;
          case "description":
            {
              let description = "";
              if (index === 0) {
                description = validations(event.target.name, "description", inputValues[index][event.target.name]);
                inputValues[index][event.target.name] = description.value;
                descriptionValidity[index].descriptionValidity = description.validator;
              }
              this.setState({
                violationReasonFields: inputValues,
                validDescription: description.validator,
                descriptionValidityArray: descriptionValidity,
              });
            }
            break;
        }
        const isCostFieldEmpty = [];
        this.state.violationReasonFields.forEach((item) => {
          const costFieldsNotHavingValues = item.reasonCosts.filter((reasonCost) => !reasonCost.cost);
          isCostFieldEmpty.push(!!costFieldsNotHavingValues.length);
          return item;
        });
        console.log("check cost field", isCostFieldEmpty);
        this.setState({ isFormValid: this.isFormValid() });
      },
    );
  };

  isFormValid = () => {
    const isCostOkay = !!this.state.costValidityArray.find((item) =>
      item.isCostValid.find((ele) => !ele.costValidity || ele.costErrorMessage?.trim()?.length > 0 || ele.daysErrorMessage?.trim()?.length > 0),
    );
    const isReasonOkay = !!this.state.violationReasonFields?.slice(0, 1)?.find((item) => {
      return item.reason.trim().length === 0 || item.description.trim().length === 0;
    });
    return this.state.contactEmail && !this.state.wrongEmail && !isReasonOkay && !isCostOkay && !this.state.graceTimeError;
  };

  setIsFormValid = () => {
    this.setState({
      isFormValid: this.isFormValid(),
    });
  };

  handleReasonCostChange = (index, costIndex, event) => {
    const { violationReasonFields } = this.state;
    const reasonCostsInputValues = [...violationReasonFields[index].reasonCosts];
    reasonCostsInputValues[costIndex][event.target.name] = event.target.value;
    const prevViolationReasonFields = [...violationReasonFields];
    prevViolationReasonFields[index].reasonCosts = reasonCostsInputValues;

    this.setState(
      {
        violationReasonFields: prevViolationReasonFields,
      },
      () => {
        const inputValues = [...this.state.violationReasonFields[index].reasonCosts];
        const tempPrevViolationReasonFields = [...this.state.violationReasonFields];
        const costValidityArray = [...this.state.costValidityArray[index].isCostValid];
        const tempCostValidityArray = [...this.state.costValidityArray];
        switch (event.target.name) {
          case "cost":
            {
              const cost = validations(event.target.name, "cost", inputValues[costIndex][event.target.name]);
              inputValues[costIndex][event.target.name] = +cost.value;
              tempPrevViolationReasonFields[index].reasonCosts = inputValues;
              costValidityArray[costIndex].costValidity = cost.validator;
              tempCostValidityArray[index].isCostValid = costValidityArray;

              this.setState(
                {
                  violationReasonFields: tempPrevViolationReasonFields,
                  validCost: cost.validator,
                  costValidityArray: tempCostValidityArray,
                  costAndDaysExistErrorMessage: "",
                },
                () => {
                  const { costAndDaysLimitValidityArray } = this.state;

                  if (event.target.value === "0") {
                    const costAndDaysLimitValidity = [...costAndDaysLimitValidityArray];
                    costAndDaysLimitValidity[index].isAddCostAndDaysDisabled = false;
                    this.setState({
                      costAndDaysLimitValidityArray: costAndDaysLimitValidity,
                      isAddCostAndDaysDisabled: true,
                    });
                  } else {
                    const costAndDaysLimitValidity = [...costAndDaysLimitValidityArray];
                    costAndDaysLimitValidity[index].isAddCostAndDaysDisabled = true;
                    this.setState({
                      costAndDaysLimitValidityArray: costAndDaysLimitValidity,
                      isAddCostAndDaysDisabled: false,
                    });
                  }
                  const reasonCosts = _.cloneDeep(violationReasonFieldValues[index]).reasonCosts;
                  costValidityArray[costIndex].costErrorMessage = "";
                  const costsValidityArray = [...this.state.costValidityArray[index].isCostValid];
                  const isFormValid = true;

                  const testCostValidityArray = [...this.state.costValidityArray];
                  testCostValidityArray[index].isCostValid = costsValidityArray;
                  this.setState({
                    costValidityArray: testCostValidityArray,
                    costErrorMessage: "",
                    isFormValid,
                  });
                  const costsValidityArray_ = [...this.state.costValidityArray[index].isCostValid];
                  reasonCosts.forEach((_reasonCost, _reasonCostsIndex) => {
                    if (costIndex === 1) {
                      if (event.target.value >= Number(reasonCosts[0].cost) || event.target.value < Number(reasonCosts[2]?.cost)) {
                        costsValidityArray[1].costErrorMessage = "Cost must be less then previous";
                      } else {
                        costsValidityArray[1].costErrorMessage = "";
                        if (reasonCosts.length > 2) {
                          costsValidityArray[2].costErrorMessage = "";
                        }
                      }
                    }
                    if (costIndex === 2) {
                      if (event.target.value >= Number(reasonCosts[1].cost)) {
                        costsValidityArray[2].costErrorMessage = "Cost must be less then previous";
                      } else {
                        costsValidityArray[2].costErrorMessage = "";
                        costsValidityArray[1].costErrorMessage = "";
                      }
                    }
                    if (costIndex === 0 && reasonCosts.length > 1) {
                      if (event.target.value <= Number(reasonCosts[1].cost)) {
                        costsValidityArray[0].costErrorMessage = "Cost must be greater then previous";
                      } else {
                        costsValidityArray[2].costErrorMessage = "";
                        costsValidityArray[1].costErrorMessage = "";
                      }
                    }
                  });
                  const testCostValidityArray_ = [...this.state.costValidityArray];
                  testCostValidityArray_[index].isCostValid = costsValidityArray_;
                  this.setState({
                    costValidityArray: testCostValidityArray_,
                    costErrorMessage: "",
                    isFormValid: this.isFormValid(),
                  });
                },
              );
            }
            break;
          case "periodInDays":
            {
              const periodInDays = validations(event.target.name, "periodInDays", inputValues[costIndex][event.target.name]);
              inputValues[costIndex][event.target.name] = periodInDays.value;
              tempPrevViolationReasonFields[index].reasonCosts = inputValues;
              this.setState(
                {
                  violationReasonFields: tempPrevViolationReasonFields,
                  validPeriodInDays: periodInDays.validator,
                  costAndDaysExistErrorMessage: "",
                },
                () => {
                  const parsedDays = parseInt(periodInDays.value, 10);
                  if (!isNaN(parsedDays) && periodInDays.value <= -1) {
                    const costValidityArray = [...this.state.costValidityArray[index].isCostValid];
                    const tempCostValidityArray = [...this.state.costValidityArray];
                    costValidityArray[costIndex].daysErrorMessage = "Days can't be less then 1";
                    tempCostValidityArray[index].isCostValid = costValidityArray;
                    this.setState({
                      costValidityArray: tempCostValidityArray,
                      daysErrorMessage: "Days can't be less then 1",
                      isFormValid: false,
                    });
                  } else {
                    let previousDays = 0; // manage previous days values
                    let previousDaysIndex = 0; // store index of previous day for show error message
                    let currentDays = 0; // store current days values
                    let isNextDaysSmaller = false; // for manage flag of all days are right or not
                    for (let i = 1; i < violationReasonFieldValues[index].reasonCosts.length; i++) {
                      currentDays = Number(violationReasonFieldValues[index].reasonCosts[i].periodInDays);
                      if (previousDays && currentDays && previousDays <= currentDays) {
                        isNextDaysSmaller = true;
                        previousDaysIndex = i;
                        break;
                      }
                      previousDays = currentDays;
                    }
                    const costValidityArray = [...this.state.costValidityArray[index].isCostValid];
                    const tempCostValidityArray = [...this.state.costValidityArray];
                    if (isNextDaysSmaller) {
                      costValidityArray[previousDaysIndex].daysErrorMessage = "Days must be less then previous";
                    } else {
                      for (const element of costValidityArray) {
                        element.daysErrorMessage = "";
                      }
                    }
                    tempCostValidityArray[index].isCostValid = costValidityArray;
                    this.setState({
                      costValidityArray: tempCostValidityArray,
                      isFormValid: false,
                    });
                  }
                },
              );
            }
            break;
          default:
        }
        const violationReasonFieldValues = [...this.state.violationReasonFields];
        const cost = violationReasonFieldValues[index].reasonCosts[costIndex]?.cost;
        const prevCost = costIndex > 0 ? violationReasonFieldValues[index].reasonCosts[costIndex - 1]?.cost : null;
        const isCostSmaller = !!(+cost <= +prevCost);
        // Removed for now, no need to validate days
        const periodInDays = violationReasonFieldValues[index].reasonCosts[costIndex].periodInDays;
        const prevPeriodInDays = costIndex > 0 ? violationReasonFieldValues[index].reasonCosts[costIndex - 1].periodInDays : null;
        const isDaysSmaller = !!(+periodInDays <= +prevPeriodInDays);
        const temp = JSON.parse(JSON.stringify(this.state.violationReasonFields));
        const alteredReasonCosts = temp[index].reasonCosts.length > 1 ? temp[index].reasonCosts.filter((_item, index) => costIndex !== index) : [];
        temp[index].reasonCosts = alteredReasonCosts;
        const isCostAndDaysExist =
          temp[index]?.reasonCosts &&
          temp[index]?.reasonCosts.length &&
          !!temp[index]?.reasonCosts.filter((item) => {
            const currentCost = cost;
            const currentDays = periodInDays;
            return item.cost === currentCost && item.periodInDays === currentDays;
          }).length;
        console.log("isCostAndDaysExist", isCostAndDaysExist, isCostSmaller, isDaysSmaller);
        this.setState({
          isFormValid: this.isFormValid(),
          isAddCostAndDaysDisabled: true,
          costAndDaysExistErrorMessage: "",
        });
      },
    );
  };

  handleAddFields = () => {
    const { violationReasonFields } = this.state;
    this.setState(
      {
        violationReasonFields: [
          ...violationReasonFields,
          {
            reason: "",
            description: "",
            language: "en",
            reasonCosts: [{ cost: 125, periodInDays: 0 }],
          },
        ],
        isFormValid: false,
        reasonValidityArray: [
          ...this.state.reasonValidityArray,
          {
            reasonValidity: true,
          },
        ],
        descriptionValidityArray: [
          ...this.state.descriptionValidityArray,
          {
            descriptionValidity: true,
          },
        ],
        costValidityArray: [
          ...this.state.costValidityArray,
          {
            isCostValid: [{ costValidity: true, costErrorMessage: "" }],
          },
        ],
        costAndDaysLimitValidityArray: [
          ...this.state.costAndDaysLimitValidityArray,
          {
            costAndDaysLimitValidityErrorMessage: "",
            isAddCostAndDaysDisabled: true,
          },
        ],
      },
      this.setIsFormValid,
    );
  };

  handleAddCostFields = (index, costIndex) => {
    const { violationReasonFields, costAndDaysLimitValidityArray } = this.state;
    const reasonCostsLength = violationReasonFields[index].reasonCosts?.length;
    if (reasonCostsLength === 3) {
      const costAndDaysLimitValidity = [...costAndDaysLimitValidityArray];
      costAndDaysLimitValidity[index].costAndDaysLimitValidityErrorMessage = "You can not create more than three cost and days";
      costAndDaysLimitValidity[index].isAddCostAndDaysDisabled = false;
      this.setState({
        costAndDaysLimitValidityArray: costAndDaysLimitValidity,
        isAddCostAndDaysDisabled: false,
        costAndDaysExistErrorMessage: "You can not create more than three cost and days",
      });
    } else {
      const preCost = violationReasonFields[index].reasonCosts[costIndex].cost;
      const costToBeDecreasdBy = (preCost * 20) / 100;
      const decreasedCost = preCost - costToBeDecreasdBy;
      console.log("violationReasonFields[index].reasonCosts::", violationReasonFields[index].reasonCosts);
      const reasonCosts = [...violationReasonFields[index].reasonCosts, { cost: +decreasedCost, periodInDays: 0 }];
      const costValidityArray = [...this.state.costValidityArray[index].isCostValid, { costValidity: true, costErrorMessage: "" }];
      const prevViolationReasonFields = [...violationReasonFields];
      prevViolationReasonFields[index].reasonCosts = reasonCosts;
      const prevCostValidityArray = [...this.state.costValidityArray];
      prevCostValidityArray[index].isCostValid = costValidityArray;
      this.setState({
        violationReasonFields: prevViolationReasonFields,
        isFormValid: this.isFormValid(),
        costValidityArray: prevCostValidityArray,
      });
    }
  };

  handleRemoveFields = (index) => {
    const { violationReasonFields, costAndDaysLimitValidityArray, reasonValidityArray, descriptionValidityArray } = this.state;
    const inputValues = [...violationReasonFields];
    const _costAndDaysLimitValidityArray = [...costAndDaysLimitValidityArray];
    const _reasonValidityArray = [...reasonValidityArray];
    const _descriptionValidityArray = [...descriptionValidityArray];
    inputValues.splice(index, 1);
    _costAndDaysLimitValidityArray.splice(index, 1);
    _reasonValidityArray.splice(index, 1);
    _descriptionValidityArray.splice(index, 1);
    const reasonCosts = [...violationReasonFields[index].reasonCosts];
    reasonCosts.splice(index, 1);
    const prevViolationReasonFields = [...violationReasonFields];
    prevViolationReasonFields[index].reasonCosts = reasonCosts;
    this.setState({
      violationReasonFields: inputValues,
      costAndDaysLimitValidityArray: _costAndDaysLimitValidityArray,
      reasonValidityArray: _reasonValidityArray,
      descriptionValidityArray: _descriptionValidityArray,
    });
  };

  handleTabSelect(lng) {
    _i18n.changeLanguage(lng);
    this.setState({
      selectedKey: lng,
      violationReasonField: { language: lng },
    });
  }

  handleCheckBox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
      isFormValid: this.isFormValid(),
    });
  };

  handleCreateTicketReasons = async () => {
    const { violationReasonFields, contractId, selectedProperty, isUpdate, contactEmail, defalutLanguage, noCharge, hideVIN, citation_footer } = this.state;
    let reasons = [];
    if (isUpdate) {
      reasons = (violationReasonFields ?? []).map((item) => {
        const reasonCosts = [];
        (item.reasonCosts ?? []).forEach((reasonCost) => {
          const reasonCostsObj = {};
          reasonCostsObj.cost = reasonCost.cost;
          reasonCostsObj.period_in_days = reasonCost.periodInDays;
          reasonCostsObj.id = reasonCost.id ? reasonCost.id : "";
          reasonCosts.push(reasonCostsObj);
        });
        return {
          title: item.reason,
          description: item.description,
          language: item.language,
          reason_costs: reasonCosts,
          id: item.id ? item.id : "",
        };
      });
    } else {
      reasons = violationReasonFields.map((item) => {
        const reasonCosts = [];
        item.reasonCosts.forEach((reasonCost) => {
          const reasonCostsObj = {};
          reasonCostsObj.cost = reasonCost.cost;
          reasonCostsObj.period_in_days = reasonCost.periodInDays;
          reasonCosts.push(reasonCostsObj);
        });
        return {
          title: item.reason,
          description: item.description,
          language: item.language,
          reason_costs: reasonCosts,
        };
      });
    }
    const data = {
      no_charge: noCharge,
      citation_footer: citation_footer,
      hideVIN: hideVIN,
      site_contract_id: contractId,
      contact_email: contactEmail,
      property_id: selectedProperty,
      default_language: this.state.defalutLanguage,
      reasons: reasons,
      defalutLanguage: defalutLanguage,
      graceTime: this.state.graceTime,
    };
    await this.props.createTicketReasons(data, isUpdate);
    if (this.props.api_response) {
      this.setState({
        showAlert: true,
        // contractId: "",
        // contactEmail: "",
        // defalutLanguage: "en",
        // violationReasonFields: [
        //   {
        //     reason: "",
        //     description: "",
        //     language: "en",
        //     reasonCosts: [{ cost: 0, periodInDays: 0 }],
        //   },
        // ],
        // isUpdate: false,
        isUpdate: true,
        // isFormValid: false,
        // costAndDaysLimitValidityArray: [
        //   {
        //     costAndDaysLimitValidityErrorMessage: "",
        //     isAddCostAndDaysDisabled: true,
        //   },
        // ],
        // isNextButtonDisable: false,
      });
      setTimeout(() => {
        this.setState({
          showAlert: false,
        });
      }, 4000);
    } else {
      this.setState({
        showError: true,
      });
      setTimeout(() => {
        this.setState({
          showError: false,
        });
      }, 4000);
    }
  };

  render() {
    const {
      violationReasonFields,
      contractId,
      isFormValid,
      showAlert,
      showError,
      graceTime,
      contactEmail,
      defalutLanguage,
      validContactEmail,
      wrongEmail,
      noCharge,
      hideVIN,
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Manage Citations</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                {showAlert && <CustomContentAlert delay={4000} message={this.props.api_success_message} className="toast-success" />}
                {showError && <CustomContentAlert delay={4000} message={this.props.api_error_message} className="toast-error" />}
                <Card.Body className="pos-rel-overflow-hide">
                  <Row>
                    <Col lg="12">
                      <Form.Group>
                        <Row>
                          <Col md="4">
                            <Form.Group>
                              <label>Site Contract ID</label>
                              <Form.Control
                                placeholder="2100"
                                type="text"
                                name="contractId"
                                value={contractId}
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <label className="fancy-checkbox">Grace time (In minutes)</label>
                              <div>
                                <Form.Control
                                  type="text"
                                  name={"graceTime"}
                                  onChange={this.handleChange}
                                  checked={graceTime}
                                  value={graceTime}
                                  autoComplete="off"
                                />
                              </div>
                            </Form.Group>
                            <span className="help-block">{this.state.graceTimeError}</span>
                          </Col>
                          <Col>
                            <Form.Group>
                              <label className="fancy-checkbox">Hide VIN</label>
                              <div>
                                <input type="checkbox" name={"hideVIN"} onChange={this.handleCheckBox} checked={hideVIN} value={hideVIN} />
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <Form.Group>
                              <label>Contact Email</label>
                              <span className="asterisk">*</span>
                              <Form.Control
                                placeholder="Email"
                                type="text"
                                name="contactEmail"
                                value={contactEmail}
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                            </Form.Group>
                            {email_validation_message(contactEmail, validContactEmail, wrongEmail)}
                          </Col>
                          {/* WORLD-677 */}
                          <Col md="2">
                            <Form.Group>
                              <label className="fancy-checkbox">Charge for 2nd reason</label>
                              <div>
                                <input type="checkbox" name={"noCharge"} onChange={this.handleCheckBox} checked={noCharge} value={noCharge} />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>Default Language for enforcement.zone</label>
                              <span className="asterisk">*</span>
                              <Form.Control as="select" name="defalutLanguage" value={defalutLanguage} onChange={this.handleChange} autoComplete="off">
                                <option value="en">English</option>
                                <option value="fn">French</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        {(violationReasonFields ?? []).map((violationReasonField, index) => (
                          <>
                            <Row key={violationReasonField?.id} className="ticket-setup-reason">
                              <Col xl="8">
                                <Row className="">
                                  {/* /*-----------------------Reasons-------------------------- */}
                                  {violationReasonFields?.length > 1 && index > 0 ? (
                                    <Col sm="auto">
                                      <label className="d-block" style={{ minHeight: "21px" }}>
                                        &nbsp;
                                      </label>
                                      <Button onClick={() => this.handleRemoveFields(index)}>
                                        <i className="fas fa-minus-circle"></i>
                                      </Button>
                                    </Col>
                                  ) : null}
                                  <Col md>
                                    <Form.Group>
                                      <label>
                                        Reason
                                        {index === 0 && <span className="asterisk">*</span>}
                                      </label>
                                      <Form.Control
                                        placeholder="Reason"
                                        type="text"
                                        name="reason"
                                        value={violationReasonField.reason}
                                        onChange={(event) => this.handleReasonChange(index, event)}
                                        autoComplete="off"
                                        required
                                        maxlength="80"
                                      ></Form.Control>
                                    </Form.Group>
                                    {validation_message(violationReasonField.reason, "Reason", this.state.reasonValidityArray[index].reasonValidity)}
                                  </Col>
                                  {/* /*-----------------------Cost and days-------------------------- */}
                                  <Col xs>
                                    {(violationReasonField?.reasonCosts ?? []).map((reasonCost, costIndex) => {
                                      return (
                                        <>
                                          <Row className="mt-3 mt-sm-0" key={costIndex}>
                                            <Col>
                                              <Form.Group>
                                                {costIndex <= 0 ? (
                                                  <label>
                                                    Cost
                                                    <span className="asterisk">*</span>
                                                  </label>
                                                ) : null}
                                                <Form.Control
                                                  placeholder="Reason"
                                                  type="number"
                                                  name="cost"
                                                  value={reasonCost?.cost ? Number(reasonCost.cost).toString() : null}
                                                  onChange={(event) => this.handleReasonCostChange(index, costIndex, event)}
                                                  min={this.state.minCostValue}
                                                  autoComplete="off"
                                                  required
                                                ></Form.Control>
                                              </Form.Group>
                                              {validation_message(
                                                reasonCost?.cost,
                                                "Cost",
                                                this.state?.costValidityArray[index]?.isCostValid[costIndex]?.costValidity,
                                              )}
                                              <span className="help-block">{this.state.costValidityArray[index].isCostValid[costIndex].costErrorMessage}</span>
                                            </Col>
                                            <Col>
                                              <Form.Group>
                                                {costIndex <= 0 ? (
                                                  <label>
                                                    Days
                                                    {/* <span className="asterisk">
                                                            *
                                                          </span> */}
                                                  </label>
                                                ) : null}
                                                {costIndex > 0 ? (
                                                  <Form.Control
                                                    placeholder="Days"
                                                    type="number"
                                                    name="periodInDays"
                                                    min="0"
                                                    value={reasonCost.periodInDays}
                                                    onChange={(event) => this.handleReasonCostChange(index, costIndex, event)}
                                                    autoComplete="off"
                                                    required
                                                  ></Form.Control>
                                                ) : null}
                                              </Form.Group>

                                              <span className="help-block">{this.state.costValidityArray[index].isCostValid[costIndex].daysErrorMessage}</span>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="auto">
                                              {violationReasonField?.reasonCosts && violationReasonField?.reasonCosts.length - 1 === costIndex ? (
                                                <Button
                                                  disabled={!this.state.costAndDaysLimitValidityArray[index]?.isAddCostAndDaysDisabled}
                                                  onClick={(event) => this.handleAddCostFields(index, costIndex)}
                                                >
                                                  <i className="fas fa-plus-circle"></i>
                                                </Button>
                                              ) : null}
                                            </Col>
                                          </Row>
                                        </>
                                      );
                                    })}
                                    <span className="help-block">{this.state.costAndDaysLimitValidityArray[index]?.costAndDaysLimitValidityErrorMessage}</span>
                                  </Col>
                                </Row>
                              </Col>
                              {/* /*-----------------------Description in languages-------------------------- */}
                              <Col xl="4">
                                {index === 0 ? (
                                  <>
                                    <Tabs
                                      // defaultActiveKey="en"
                                      id="tab"
                                      className="mb-3"
                                      activeKey={this.state.selectedKey}
                                      onSelect={(key) => this.handleTabSelect(key)}
                                    >
                                      <Tab eventKey="en" title="English"></Tab>
                                      <Tab eventKey="fn" title="French"></Tab>
                                      <Tab eventKey="sp" title="Spanish"></Tab>
                                    </Tabs>
                                    <Form.Group>
                                      <label>
                                        <Translation>{(t) => <>{t("Footer")}</>}</Translation>
                                        {index === 0 && <span className="asterisk">*</span>}
                                      </label>
                                      <Translation>
                                        {(t) => (
                                          <Form.Control
                                            placeholder={t("Enter Footer")}
                                            type="textarea"
                                            name="citation_footer"
                                            value={this.state.citation_footer}
                                            onChange={this.handleChange}
                                            max={400}
                                            autoComplete="off"
                                            required
                                          ></Form.Control>
                                        )}
                                      </Translation>
                                    </Form.Group>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <Form.Group>
                                  <label>
                                    <Translation>{(t) => <>{t("Description")}</>}</Translation>
                                    {index === 0 && <span className="asterisk">*</span>}
                                  </label>
                                  <Translation>
                                    {(t) => (
                                      <Form.Control
                                        placeholder={t("Enter Description")}
                                        type="text"
                                        name="description"
                                        value={violationReasonField.description}
                                        onChange={(event) => this.handleReasonChange(index, event)}
                                        autoComplete="off"
                                        required
                                      ></Form.Control>
                                    )}
                                  </Translation>
                                  {validation_message(
                                    violationReasonField.description,
                                    "Description",
                                    this.state.descriptionValidityArray[index].descriptionValidity,
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <br />
                          </>
                        ))}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button onClick={this.handleAddFields}>
                    <i className="fas fa-plus-circle"></i>
                  </Button>
                  <Button className="ml-2" onClick={this.handleCreateTicketReasons} disabled={!isFormValid}>
                    {this.state.isUpdate ? "Update" : "Save"}
                  </Button>
                  <Col lg="12" className="p-0">
                    {navigationButtons(
                      "/permissions",
                      "/printed-citation-setup",
                      this.state.propertyIdToBePassed,
                      this.addActionRef,
                      "Ticket Setup",
                      this.state.isNextButtonDisable,
                    )}
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ SuperAdminDetails, APIResponseDetails, TicketDetails }) => {
  const { getAllProperties } = SuperAdminDetails;
  const { reasonSetupDetail } = TicketDetails;
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  return {
    getAllProperties,
    reasonSetupDetail,
    api_response,
    api_error_message,
    api_success_message,
  };
};

const mapStateToDispatch = {
  get_all_properties: SuperAdminAction.get_all_properties,
  createTicketReasons: TicketActions.createTicketReasons,
  getReasonDetails: TicketActions.getReasonDetails,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(CreateProperty);

import React, { useCallback, useState, useEffect } from "react";
import { Card, Container, Row, Col, Form, Button, Tooltip, OverlayTrigger, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { EnforcementActions, CurrentPermitActions, ValidationCouponsActions, SuperAdminAction } from "../../Actions";
import t from "../../Actions/ValidationCouponActions/type";
import timeCredit from "../../../Auth/config";
import moment from "moment";
const monthPrefix = new Date(new Date()).getMonth() + 1 < 10 ? 0 : "";
const month = new Date(new Date()).getMonth() + 1;
const datePrefix = new Date(new Date()).getDate() < 10 ? 0 : "";

const intiailFormData = {
  selectedLot: "",
  selectedUnit: "",
  title: "",
  line1: "",
  line2: "",
  line3: "",
  instruction1: timeCredit.instructionToBePrintedOnValidationCouponOne,
  instruction2: timeCredit.instructionToBePrintedOnValidationCouponTwo,
  merchant_code_id: "",
  fixedDiscount: "",
  fixedCost: "",
  timeCredit: "",
  numberOfCouponsToGen: 1,
  expiresIn: "1_YEAR",
  discountType: "TIME_CREDIT",
  startDate: new Date(new Date()),
  endDate: new Date(new Date()).setFullYear(new Date().getFullYear() + 1),
  endDateToBeShown:
    datePrefix +
    new Date(new Date(new Date()).setFullYear(new Date().getFullYear() + 1)).getDate() +
    "-" +
    monthPrefix +
    month +
    "-" +
    new Date(new Date(new Date()).setFullYear(new Date().getFullYear() + 1)).getFullYear(),
  startDateToBeShown: datePrefix + new Date(new Date()).getDate() + "-" + monthPrefix + month + "-" + new Date(new Date()).getFullYear(),
  unitPaymentType: "FULL_DISCOUNT_AMOUNT",
  unitPaymentRate: "",
  tracking_code: "",
};
const intialErrorData = {
  selectedLot: "",
  selectedUnit: "",
  title: "",
  instruction1: "",
  instruction2: "",
  merchant_code_id: "",
  numberOfCouponsToGen: "",
  fixedDiscount: "",
  fixedCost: "",
  timeCredit: "",
  startDate: "",
  endDate: "",
};

const GenerateValidationCoupons = () => {
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [formData, setformData] = useState(intiailFormData);
  const lots = useSelector((state) => state.EnforcementDetails.userlots);
  const units = useSelector((state) => state.CurrentPermitDetails.residentList);
  const todaysDate = useSelector((state) => state.CurrentPermitDetails.todaysDate);
  const merchantCodeList = useSelector((state) => state.ValidationDetails.merchantCodeList);
  const couponPDF = useSelector((state) => state.ValidationDetails?.couponPDF);
  const property_id = useSelector((state) => state.UserProfileDetails?.property?.id);
  const permission_id = useSelector((state) => state.UserProfileDetails?.permission_id);
  const [validations, setValidations] = useState(intialErrorData);
  const [isSubmit, setIsSubmit] = useState(false);
  const UserData = useSelector((userState) => userState.UserProfileDetails);
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      let obj = {};
      console.log("name,,,value", name, value);
      if (name === "fixedCost" || name === "fixedDiscount" || name === "unitPaymentRate") {
        const pattern = name === "fixedCost" || name === "unitPaymentRate" ? /^\d{1,3}(\.\d{0,2}|\.\d{0,2})?$/ : /^\d{1,2}(\.\d{0,2}|\.\d{0,2})?$/;
        if (pattern.test(value)) {
          obj = {
            ...formData,
            [name]: value,
            ...(name === "discountType" && {
              merchant_code_id: "",
              fixedCost: "",
              fixedDiscount: "",
              timeCredit: "",
            }),
          };
        } else {
          if (value === "") {
            obj = {
              ...formData,
              [name]: value,
              ...(name === "discountType" && {
                merchant_code_id: "",
                fixedCost: "",
                fixedDiscount: "",
                timeCredit: "",
              }),
            };
          } else {
            obj = {
              ...formData,
              [name]: formData[name],
              ...(name === "discountType" && {
                merchant_code_id: "",
                fixedCost: "",
                fixedDiscount: "",
                timeCredit: "",
              }),
            };
          }
        }
      } else if (name === "timeCredit") {
        const pattern = /^\d+$/;
        if (pattern.test(value) && value <= timeCredit.timeCreditMaxLimit * 60) {
          obj = {
            ...formData,
            [name]: value,
            ...(name === "discountType" && {
              merchant_code_id: "",
              fixedCost: "",
              fixedDiscount: "",
              timeCredit: "",
            }),
          };
        } else {
          if (value === "") {
            obj = {
              ...formData,
              [name]: value,
              ...(name === "discountType" && {
                merchant_code_id: "",
                fixedCost: "",
                fixedDiscount: "",
                timeCredit: "",
              }),
            };
          } else {
            obj = {
              ...formData,
              [name]: formData[name],
              ...(name === "discountType" && {
                merchant_code_id: "",
                fixedCost: "",
                fixedDiscount: "",
                timeCredit: "",
              }),
            };
          }
        }
      } else if (name === "numberOfCouponsToGen") {
        const pattern = /^\d+$/;
        if (pattern.test(value) && value <= timeCredit.maxLimitForNumberOfCouponsToGen) {
          obj = {
            ...formData,
            [name]: value,
            ...(name === "discountType" && {
              merchant_code_id: "",
              fixedCost: "",
              fixedDiscount: "",
              timeCredit: "",
            }),
          };
        } else {
          if (value === "") {
            obj = {
              ...formData,
              [name]: value,
              ...(name === "discountType" && {
                merchant_code_id: "",
                fixedCost: "",
                fixedDiscount: "",
                timeCredit: "",
              }),
            };
          } else {
            obj = {
              ...formData,
              [name]: formData[name],
              ...(name === "discountType" && {
                merchant_code_id: "",
                fixedCost: "",
                fixedDiscount: "",
                timeCredit: "",
              }),
            };
          }
        }
      } else if (name === "title") {
        obj = {
          ...formData,
          [name]: value.toUpperCase(),
          ...(name === "discountType" && {
            merchant_code_id: "",
            fixedCost: "",
            fixedDiscount: "",
            timeCredit: "",
          }),
        };
      } else {
        obj = {
          ...formData,
          [name]: value,
          ...(name === "discountType" && {
            merchant_code_id: "",
            fixedCost: "",
            fixedDiscount: "",
            timeCredit: "",
          }),
          ...(name === "unitPaymentType" && {
            unitPaymentRate: "",
          }),
        };
      }
      setformData(obj);
      isSubmit && validateFields(obj);
      setError("");
    },
    [formData, error, isSubmit],
  );

  useEffect(() => {
    const selectedPropertyName = UserData.property?.property_name;
    const selectedDomainName = UserData.property?.domain;
    dispatch(SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName));
  }, [UserData]);

  const handleDateChange = (e) => {
    console.log("es", e);
    const monthPrefix = e.getMonth() < 10 ? 0 : "";
    const month = e.getMonth() + 1;
    const datePrefix = e.getDate() < 10 ? 0 : "";
    const startDateToBeShown = datePrefix + e.getDate() + "-" + monthPrefix + month + "-" + e.getFullYear();
    const obj = {
      ...formData,
      startDate: e,
      startDateToBeShown: startDateToBeShown,
    };
    setformData(obj);
    validateFields(obj);
  };
  const handleEndDateChange = (e) => {
    console.log("en", e);
    const monthPrefix = e.getMonth() < 10 ? 0 : "";
    const month = e.getMonth() + 1;
    const datePrefix = e.getDate() < 10 ? 0 : "";
    const endDateToBeShown = datePrefix + e.getDate() + "-" + monthPrefix + month + "-" + e.getFullYear();
    const obj = {
      ...formData,
      endDate: e,
      endDateToBeShown: endDateToBeShown,
    };
    setformData(obj);
    validateFields(obj);
  };

  const saveDataAndCreatePDF = () => {
    setIsSubmit(true);
    if (validateFields(formData)) return;
    setLoader(true);
    setError("");
    const obj = {
      ...formData,
      property_id: property_id,
      ...(formData.fixedCost && {
        fixedCost: (+formData.fixedCost).toFixed(2),
      }),
      ...(formData.fixedDiscount && {
        fixedDiscount: (+formData.fixedDiscount).toFixed(2),
      }),
    };
    console.log("before action", obj);
    obj.startDate = moment(obj.startDate).format("YYYY-MM-DD");
    obj.endDate = moment(obj.endDate).format("YYYY-MM-DD");
    dispatch(ValidationCouponsActions.createValidationCouponPDF(obj));
  };

  const validateFields = (data) => {
    const obj = {
      selectedLot: !data.selectedLot ? "Lot can't be empty" : "",
      startDate: !data.startDate ? "Start Date can't be empty" : "",
      endDate: !data.endDate ? "End Date can't be empty" : "",
      selectedUnit: !data.selectedUnit ? "Unit can't be empty" : "",
      title: !data.title ? "Title can't be empty" : "",
      instruction1: !data.instruction1 ? "Instruction cannot be empty. If no instruction is provided, a default value will be used." : "",
      instruction2: !data.instruction2 ? "Instruction cannot be empty. If no instruction is provided, a default value will be used." : "",
      numberOfCouponsToGen: !data.numberOfCouponsToGen ? "This field can't be empty" : "",
      ...(data.discountType === "PERCENTAGE" && {
        merchant_code_id: !data.merchant_code_id ? "Please select atleast one discount value" : "",
      }),
      ...(data.discountType === "FIXED_DISCOUNT" && {
        merchant_code_id: !data.fixedDiscount ? "Please enter discount value" : "",
      }),
      ...(data.unitPaymentType === "FLAT_RATE" && {
        unit_payment_rate: !data.unitPaymentRate ? "Please enter rate value" : "",
      }),
      ...(data.unitPaymentType === "FULL_DISCOUNT_AMOUNT" && {
        unit_payment_rate: "",
      }),
      ...(data.discountType === "FIXED_COST" && {
        merchant_code_id: !data.fixedCost ? "Please enter cost value" : "",
      }),
      ...(data.discountType === "TIME_CREDIT" && {
        merchant_code_id: !data.timeCredit ? "Please enter time credit value" : "",
      }),
    };
    setValidations(obj);
    if (isSubmit) {
      if (!formData.instruction1) {
        const formdata = {
          ...formData,
          instruction1: timeCredit.instructionToBePrintedOnValidationCouponOne,
        };
        setformData(formdata);
      }
      if (!formData.instruction2) {
        const formdata = {
          ...formData,
          instruction2: timeCredit.instructionToBePrintedOnValidationCouponTwo,
        };
        setformData(formdata);
      }
    }
    return !!Object.keys(obj).find((x) => obj[x].length > 0);
  };

  useEffect(() => {
    dispatch(EnforcementActions.get_user_lots());
    dispatch(CurrentPermitActions.fetchResidentList());
    dispatch(ValidationCouponsActions.getMerchantCodes());
  }, []);
  useEffect(() => {
    setformData(intiailFormData);
    setLoader(false);
    setIsSubmit(false);
    if (Object.keys(couponPDF).length && isSubmit) {
      document.getElementById("coupon").click();
      dispatch({
        type: t.SET_COUPON_PDF,
        payload: {},
      });
    }
  }, [couponPDF]);

  return (
    <>
      <Container fluid className="manage-unit-list-page page-no-fliter">
        <Row>
          <Col md="12">
            <Card className="mb-0">
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <Card.Title as="h4">Create Parking Discount Coupons</Card.Title>
                </div>
              </Card.Header>
              <Card.Body>
                <a id="coupon" href={couponPDF} style={{ display: "none" }} download={`${new Date().getTime()}.pdf`}>
                  PDF
                </a>

                <Row>
                  <Col xl="6" lg="8" md="8" sm="12">
                    <label>Lot</label>
                    <span className="asterisk">*</span>
                    <Form.Control
                      as="select"
                      name="selectedLot"
                      placeholder="lot"
                      value={formData.selectedLot}
                      className="photo-verify-form-control"
                      onChange={handleChange}
                    >
                      <option value="">--Choose Lot--</option>
                      {(lots ?? []).map((e, key) => {
                        return (
                          <option key={key} value={e.id}>
                            {e.alias}
                          </option>
                        );
                      })}
                    </Form.Control>
                    {isSubmit && validations.selectedLot && <div className="help-block">{validations.selectedLot}</div>}
                  </Col>
                  <Col xl="6" lg="8" md="8" sm="12">
                    <label>Unit</label>
                    <span className="asterisk">*</span>
                    <Form.Control
                      as="select"
                      name="selectedUnit"
                      placeholder="unit"
                      value={formData.selectedUnit}
                      className="photo-verify-form-control"
                      onChange={handleChange}
                    >
                      <option value="">--Choose Unit--</option>
                      {(units ?? []).map((e, key) => {
                        return (
                          <option key={key} value={e.id}>
                            {e.suite_id}
                          </option>
                        );
                      })}
                    </Form.Control>
                    {isSubmit && validations.selectedUnit && <div className="help-block">{validations.selectedUnit}</div>}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xl="6" lg="8" md="8" sm="12">
                    <label>Title</label>
                    <span className="asterisk">*</span>
                    <Form.Control
                      placeholder="title"
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                      maxLength={19}
                    ></Form.Control>
                    {isSubmit && validations.title && <div className="help-block">{validations.title}</div>}
                  </Col>
                  <Col xl="6" lg="8" md="8" sm="12">
                    <label>Number of coupons</label>
                    <span className="asterisk">*</span>
                    <Form.Control
                      placeholder="Number to generate coupons"
                      type="text"
                      name="numberOfCouponsToGen"
                      value={formData.numberOfCouponsToGen}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    ></Form.Control>
                    {isSubmit && validations.numberOfCouponsToGen && <div className="help-block">{validations.numberOfCouponsToGen}</div>}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xl="4" lg="6" md="8" sm="12">
                    <label>Parking Benefit</label>
                    <Form.Control
                      placeholder="Line1"
                      type="text"
                      name="line1"
                      value={formData.line1}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                      maxLength={19}
                    ></Form.Control>
                  </Col>
                  <Col xl="4" lg="6" md="8" sm="12">
                    <label>Company/Event</label>
                    <Form.Control
                      placeholder="Line2"
                      type="text"
                      name="line2"
                      value={formData.line2}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                      maxLength={19}
                    ></Form.Control>
                  </Col>
                  <Col xl="4" lg="6" md="8" sm="12">
                    <label>Message</label>
                    <Form.Control
                      placeholder="Line3"
                      type="text"
                      name="line3"
                      value={formData.line3}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                      maxLength={19}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xl="4" lg="6" md="8" sm="12">
                    <label>Instruction 1</label>
                    <span className="asterisk">*</span>
                    <Form.Control
                      placeholder="Instructions"
                      type="text"
                      name="instruction1"
                      value={formData.instruction1}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                      maxLength={19}
                    ></Form.Control>
                    {isSubmit && validations.instruction1 && <div className="help-block">{validations.instruction1}</div>}
                  </Col>
                  <Col xl="4" lg="6" md="8" sm="12">
                    <label>Instruction 2</label>
                    <span className="asterisk">*</span>
                    <Form.Control
                      placeholder="Instruction2"
                      type="text"
                      name="instruction2"
                      value={formData.instruction2}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                      maxLength={19}
                    ></Form.Control>
                    {isSubmit && validations.instruction2 && <div className="help-block">{validations.instruction2}</div>}
                  </Col>
                  <Col xl="4" lg="8" md="8" sm="12">
                    <label>Tracking code</label>
                    <Form.Control
                      type="text"
                      name="tracking_code"
                      placeholder="Tracking code"
                      value={formData.tracking_code}
                      onChange={handleChange}
                    ></Form.Control>
                  </Col>
                </Row>
                <label className="mt-3">Discount Type</label>
                <span className="asterisk">*</span>
                <Row className="mt-3">
                  <Col xl="3" lg="5" md="8" sm="12" className="d-flex align-items-center">
                    <Form.Check
                      inline
                      label="Time credit (In minute)"
                      name="discountType"
                      type="radio"
                      value="TIME_CREDIT"
                      id="1"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      checked={formData.discountType === "TIME_CREDIT"}
                    />
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-qw`}>
                          Give driver time credit. If set to 60 minutes and the driver drove in at 9:00am, driver will pay rate as if they drove in at 10:00am.
                        </Tooltip>
                      }
                    >
                      <i className="fa fa-question-circle" style={{ marginLeft: -10 }} aria-hidden="true"></i>
                    </OverlayTrigger>
                  </Col>
                  <Col xl="6" lg="7" md="8" sm="12">
                    <Form.Control
                      type="text"
                      name="timeCredit"
                      placeholder="Minutes"
                      value={formData.timeCredit}
                      className="photo-verify-form-control"
                      onChange={handleChange}
                      disabled={!(formData.discountType === "TIME_CREDIT")}
                    ></Form.Control>
                  </Col>
                  <Col></Col>
                </Row>
                <Row className="mt-3">
                  <Col xl="3" lg="5" md="8" sm="12" className="d-flex align-items-center">
                    <Form.Check
                      inline
                      label="% Discount"
                      name="discountType"
                      type="radio"
                      value="PERCENTAGE"
                      id="2"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      checked={formData.discountType === "PERCENTAGE"}
                    />
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-qw`}>
                          Give driver % discount. If set to 50% off and driver was supposed to pay $10, they will pay $5 after using coupon.
                        </Tooltip>
                      }
                    >
                      <i className="fa fa-question-circle" style={{ marginLeft: -10 }} aria-hidden="true"></i>
                    </OverlayTrigger>
                  </Col>
                  <Col xl="6" lg="7" md="8" sm="12">
                    <Form.Control
                      as="select"
                      name="merchant_code_id"
                      placeholder="Select"
                      value={formData.merchant_code_id}
                      className="photo-verify-form-control"
                      onChange={handleChange}
                      disabled={!(formData.discountType === "PERCENTAGE")}
                    >
                      <option value="">Select</option>
                      {(merchantCodeList ?? [])
                        .filter((ele) => ele.type === "PERCENTAGE")
                        .map((e, key) => {
                          return (
                            <option key={key} value={e.id}>
                              {e.value}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Col>
                  <Col></Col>
                </Row>
                <Row className="mt-3">
                  <Col xl="3" lg="5" md="8" sm="12" className="d-flex align-items-center">
                    <Form.Check
                      inline
                      label="Fixed Cost In $"
                      name="discountType"
                      type="radio"
                      value="FIXED_COST"
                      id="3"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      checked={formData.discountType === "FIXED_COST"}
                    />
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-qw`}>
                          Max rate a driver will have to pay. If set to $10 and the driver was supposed to pay $25, they will only pay $10 after using coupon.
                        </Tooltip>
                      }
                    >
                      <i className="fa fa-question-circle" style={{ marginLeft: -10 }} aria-hidden="true"></i>
                    </OverlayTrigger>
                  </Col>
                  <Col xl="6" lg="7" md="8" sm="12">
                    <Form.Control
                      type="text"
                      name="fixedCost"
                      placeholder="Amount"
                      value={formData.fixedCost}
                      className="photo-verify-form-control"
                      onChange={handleChange}
                      disabled={!(formData.discountType === "FIXED_COST")}
                    ></Form.Control>
                  </Col>
                  <Col></Col>
                </Row>
                <Row className="mt-3">
                  <Col xl="3" lg="5" md="8" sm="12" className="d-flex align-items-center">
                    <Form.Check
                      inline
                      label="Fixed Discount In $"
                      name="discountType"
                      type="radio"
                      value="FIXED_DISCOUNT"
                      id="4"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      checked={formData.discountType === "FIXED_DISCOUNT"}
                    />
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-qw`}>
                          Reduce the driver’s parking change by a fixed amount. If the driver was supposed to pay $25 and the fixed discount amount is set to
                          $10, the driver will only pay $15
                        </Tooltip>
                      }
                    >
                      <i className="fa fa-question-circle" style={{ marginLeft: -10 }} aria-hidden="true"></i>
                    </OverlayTrigger>
                  </Col>
                  <Col xl="6" lg="7" md="8" sm="12">
                    <Form.Control
                      type="text"
                      name="fixedDiscount"
                      placeholder="Amount"
                      value={formData.fixedDiscount}
                      className="photo-verify-form-control"
                      onChange={handleChange}
                      disabled={!(formData.discountType === "FIXED_DISCOUNT")}
                    ></Form.Control>
                  </Col>
                  <Col></Col>
                </Row>
                {isSubmit && validations.merchant_code_id && <div className="help-block">{validations.merchant_code_id}</div>}
                <label className="mt-3">Unit Payment Type</label>
                <Row className="mt-3">
                  <Col xl="3" lg="5" md="8" sm="12">
                    <Form.Check
                      inline
                      label="Actual Discount Amount"
                      name="unitPaymentType"
                      type="radio"
                      value="FULL_DISCOUNT_AMOUNT"
                      id="11"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      disabled={permission_id === 2}
                      checked={formData.unitPaymentType === "FULL_DISCOUNT_AMOUNT"}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xl="3" lg="5" md="8" sm="12">
                    <Form.Check
                      inline
                      label="Flat Rate in $"
                      name="unitPaymentType"
                      type="radio"
                      value="FLAT_RATE"
                      id="22"
                      disabled={permission_id === 2}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xl="6" lg="7" md="8" sm="12">
                    <Form.Control
                      type="text"
                      name="unitPaymentRate"
                      placeholder="Flat Rate"
                      value={formData.unitPaymentRate}
                      className="photo-verify-form-control"
                      onChange={handleChange}
                      min={1}
                      disabled={!(formData.unitPaymentType === "FLAT_RATE")}
                    ></Form.Control>
                    {isSubmit && validations.unit_payment_rate && <div className="help-block">{validations.unit_payment_rate}</div>}
                  </Col>
                  <Col></Col>
                </Row>
                <Row className="mt-4">
                  <Col xl="4" lg="8" md="8" sm="12">
                    <label>Start date</label>
                    <span className="asterisk">*</span>
                    <DatePicker
                      id="example-datepicker"
                      name="startDate"
                      className="form-control"
                      placeholder="04/04/16"
                      selected={formData.startDate}
                      value={formData.startDate}
                      minDate={new Date(todaysDate)}
                      maxDate={new Date(formData.endDate)}
                      onSelect={handleDateChange}
                      dateFormat="MM/dd/yyyy"
                      autoComplete="off"
                      //  showYearDropdown
                    />
                    {isSubmit && validations.startDate && <div className="help-block">{validations.startDate}</div>}
                  </Col>
                  <Col xl="4" lg="8" md="8" sm="12">
                    <label>End date</label>
                    <span className="asterisk">*</span>
                    <DatePicker
                      id="example-datepicker"
                      name="endDate"
                      className="form-control"
                      placeholder="04/04/16"
                      selected={formData.endDate}
                      value={formData.endDate}
                      minDate={formData.startDate ? formData.startDate : new Date(todaysDate)}
                      onSelect={handleEndDateChange}
                      dateFormat="MM/dd/yyyy"
                      autoComplete="off"
                      //  showYearDropdown
                    />
                    {isSubmit && validations.endDate && <div className="help-block">{validations.endDate}</div>}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xl="6" lg="8" md="8" sm="12">
                    {loader ? (
                      <Spinner animation="border" variant="info" />
                    ) : (
                      <Button onClick={saveDataAndCreatePDF} className="my-3 alignItem:right">
                        Create PDF
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default GenerateValidationCoupons;

import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ResidentsActions } from "../../Actions";
import BulkImportModal from "../Modal/BulkImportModal";
import Import from "../../../assets/img/svg/importFile.svg";

const ImportSites = () => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);

  const UploadFile = React.useCallback(
    (data) => {
      dispatch(ResidentsActions.importUnitsPermitsSites(data, "Site"));
    },
    [dispatch]
  );

  const CloseModal = React.useCallback(() => {
    setShow(false);
  }, [dispatch]);

  return (
    <React.Fragment>
      <Button
        onClick={() => setShow(true)}
        type="button"
        variant="primary"
        className="btn-blue-bg"
      >
        <img src={Import} width="18" />
        <span className="ml-2"> Add Multiple Property</span>
      </Button>
      {show && (
        <BulkImportModal
          UploadFile={UploadFile}
          type={"Sites"}
          closeModal={CloseModal}
        />
      )}
    </React.Fragment>
  );
};

export default ImportSites;

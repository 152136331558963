import reducerHandler from "../Reducer.Helper";
import actionCreator from "../../Actions/Action.Helper";

const getGateList = actionCreator("GET_GATE_LIST");
const addUpdateDeleteResponse = actionCreator("ADD_UPDATE_DELETE_RES");
const gateSchedulerList = actionCreator("GATE_SCHEDULER_LIST");
const updateSchedulerList = actionCreator("UPDATE_LIST");
const initialAsyncState = {
  isLoading: false,
  loaded: false,
  data: null,
  error: null,
  message: "",
  success: null,
};

const initialState = {
  addUpdateDeleteResponse: initialAsyncState,
  clearAddUpdateDeleteResponse: initialAsyncState,
  gateList: initialAsyncState,
  gateSchedulerList: initialAsyncState,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case addUpdateDeleteResponse.REQUEST:
    case addUpdateDeleteResponse.SUCCESS:
    case addUpdateDeleteResponse.FAILURE:
      return {
        ...state,
        addUpdateDeleteResponse: reducerHandler(
          state.addUpdateDeleteResponse,
          action,
          addUpdateDeleteResponse
        ),
      };

    case getGateList.REQUEST:
    case getGateList.SUCCESS:
    case getGateList.FAILURE:
      return {
        ...state,
        gateList: reducerHandler(state.gateList, action, getGateList),
      };

    case gateSchedulerList.REQUEST:
    case gateSchedulerList.SUCCESS:
    case gateSchedulerList.FAILURE:
      return {
        ...state,
        gateSchedulerList: reducerHandler(
          state.gateSchedulerList,
          action,
          gateSchedulerList
        ),
      };
    case updateSchedulerList.REQUEST:
    case updateSchedulerList.SUCCESS:
    case updateSchedulerList.FAILURE: {
      const updatedScudulerList = state.gateSchedulerList?.data.map((item) => {
        console.log(item.group_id, action?.data);
        if (item.group_id === action?.data?.data) {
          item.active = item?.active === 1 ? 0 : 1;
          return item;
        } else {
          return item;
        }
      });
      action.data = updatedScudulerList;
      return {
        ...state,
        gateSchedulerList: reducerHandler(
          state.gateSchedulerList,
          action.data,
          gateSchedulerList
        ),
      };
    }

    default:
      return state;
  }
};
export default reducer;

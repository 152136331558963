import t from "./types";
import apiType from "../APIResponseActions/type";
import { AuthAPI } from "../../../Auth/requestHandler";

export const fetch_current_visitor_permit = (pageobj) => async (dispatch) => {
  try {
    const url =
      "permit/current_visitor/" +
      pageobj.PropertyId +
      "?page_size=" +
      pageobj.Norow +
      "&page=" +
      pageobj.page +
      "&search=" +
      pageobj.searchValue +
      "&order_by=" +
      pageobj.selectedColumn +
      "&sorting=" +
      pageobj.sort_direction;
    const result = await AuthAPI("domain", url, "GET");

    await dispatch({
      type: t.FETCH_CURRENT_VISITOR_PERMIT,
      payload: result,
    });
    // } else {
    await dispatch({
      type: t.FETCH_CURRENT_VISITOR_PERMIT,
      payload: result,
    });
    // }
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const createParkingPermitSetup =
  (body, isUpdate) => async (dispatch) => {
    try {
      const property_id = body.property_id;
      const url = isUpdate && "permit/parkingpermitsetup/" + property_id;

      const type = isUpdate ? "PATCH" : "POST";
      const response = await AuthAPI("domain", url, type, body);
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.msg,
      });
    } catch (error) {
      await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
    }
  };

export const getParkingPermitSetupDetails =
  (propertyId) => async (dispatch) => {
    try {
      const url = "permit/parkingpermit_setup_detail/" + propertyId;
      const response = await AuthAPI("domain", url, "GET");

      if (response.status === "failed") {
        dispatch({
          type: t.FETCH_PARKING_PERMIT_SETTING_DETAILS_BLANK,
          payload: {},
        });
      } else {
        await dispatch({
          type: t.FETCH_PARKING_PERMIT_SETTING_DETAILS,
          payload: response,
        });
      }
    } catch (error) {
      dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: t.FETCH_PARKING_PERMIT_SETTING_DETAILS_BLANK,
        payload: {},
      });
    }
  };

export const createQuickPermitSetup = (body, isUpdate) => async (dispatch) => {
  console.log("createQuickPermitSetup :::action== ", body, isUpdate);
  try {
    const property_id = body.property_id;
    const url = isUpdate
      ? "permit/quickpermitsetup/" + property_id
      : "permit/quickpermitsetup";
    const type = isUpdate ? "PATCH" : "POST";
    const response = await AuthAPI("domain", url, type, body);
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.msg,
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getQuickPermitSetupDetails = (propertyId) => async (dispatch) => {
  try {
    const url = "permit/quickpermit_setup_detail/" + propertyId;
    const response = await AuthAPI("domain", url, "GET");

    if (response.status === "failed") {
      dispatch({
        type: t.FETCH_QUICK_PERMIT_SETTING_DETAILS_BLANK,
        payload: {},
      });
    } else {
      await dispatch({
        type: t.FETCH_QUICK_PERMIT_SETTING_DETAILS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
    dispatch({
      type: t.FETCH_QUICK_PERMIT_SETTING_DETAILS_BLANK,
      payload: {},
    });
  }
};

export const getPinGenerate = () => async (dispatch) => {
  try {
    const url = "self-register/get_pingenerate";
    const response = await AuthAPI("domain", url, "GET");
    await dispatch({
      type: t.FETCH_QUICK_PERMIT_PIN_DETAILS,
      payload: response,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get_all_properties,
  setDomainHeader,
} from "../../Actions/SuperAdminAction/actions";

const useGetAllProperty = (id) => {
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState({});
  const { getAllProperties } = useSelector((state) => state.SuperAdminDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getAllProperties?.length === 0) {
      dispatch(get_all_properties());
    }
  }, []);

  useEffect(() => {
    if (getAllProperties.length > 0) {
      setProperties(getAllProperties);
      selectedPropertyUtil(getAllProperties, id);
    }
  }, [getAllProperties, id]);

  /* UTILS */
  const selectedPropertyUtil = (allProperty, propertyId) => {
    if (propertyId) {
      const property = allProperty.find((x) => x.id === id) || {};
      setSelectedProperty(property);
      dispatch(setDomainHeader(property?.site_name, property?.domain));
    }
  };

  return {
    properties,
    selectedProperty,
    propertyId: id,
  };
};

export default useGetAllProperty;

import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { Card, Col, Container, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { batch, useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as MeterDeviceActions from "../../Actions/MeterConfigActions/action";
import CustomContentAlert, { navigationButtons } from "../helper";
import t from "../../Actions/MeterConfigActions/types";
import Loader from "../Loader/Loader";

import useGetAllProperty from "../../Hooks/Property/useGetAllProperty";
import AddMeterConfigTypeFun from "./AddMeterConfigTypeFun";
import MeterdeviceDeleteModel from "../Modal/MeterdeviceDeleteModal";

export default function AddMeterConfigList() {
  const addActionRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const { propertyId } = useGetAllProperty(location.search?.slice(1));
  const modelOpenCLose = useSelector((state) => state.MeterConfigDetails.modelOpenClose);
  const apiLoader = useSelector((state) => state.MeterConfigDetails.loader);
  const meterDevices = useSelector((state) => state.MeterConfigDetails.meterDevices);
  const apiMessage = useSelector((state) => state.MeterConfigDetails.message);
  const [loader, setLoader] = useState(apiLoader);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [deleteRowData, setdeleteRowData] = useState({});
  const modelhandle = () => {
    dispatch({
      type: t.SET_METER_DEVICE_DATA,
      payload: [],
    });
    dispatch({
      type: t.CLOSE_OPEN_MODEL,
      payload: true,
    });
    dispatch({
      type: t.SET_METER_CREATE_FLAG,
      payload: true,
    });
  };

  useEffect(() => {
    if (meterDevices) {
      setLoader(false);
    }
  }, [meterDevices]);

  useEffect(() => {
    let timeout = null;
    if (apiMessage?.message) {
      timeout = setTimeout(() => {
        dispatch({
          type: t.SET_METER_DEVICE_ERROR,
          payload: "",
        });
      }, 5000);
    }
    return () => {
      if (timeout !== null) clearTimeout(timeout);
    };
  }, [apiMessage]);

  useEffect(() => {
    setLoader(true);
    batch(() => {
      dispatch(MeterDeviceActions.fetchAllMeterDevices());
    });
  }, []);

  const deleIcon = (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z" fill="black" />
    </svg>
  );

  const deleteTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete
    </Tooltip>
  );
  const editTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );

  const EditRow = (rowData) => {
    dispatch({
      type: t.SET_METER_DEVICE_DATA,
      payload: rowData?.row,
    });
    dispatch({
      type: t.CLOSE_OPEN_MODEL,
      payload: true,
    });
    dispatch({
      type: t.SET_METER_CREATE_FLAG,
      payload: false,
    });
  };

  const DeleteRow = async (rowData) => {
    setisModalOpen(true);
    setdeleteRowData(rowData);
  };

  const deleteMeterdeviceData = async (rowData) => {
    const { original_id, type } = rowData.row;
    dispatch(MeterDeviceActions.deleteSelectedMeterDevice(original_id, type, propertyId));
    setisModalOpen(false);
  };

  const columns = useRef([
    {
      flex: 1,
      minWidth: 200,
      headerName: "Config Type",
      field: "type",
      lookup: {},
      // validate: ValidateDeviceType,
    },
    {
      flex: 1,
      minWidth: 200,
      headerName: "Server Id",
      field: "server_id",
      lookup: {},
    },
    {
      flex: 1,
      minWidth: 200,
      headerName: "Description",
      field: "description",
    },
    {
      flex: 1,
      minWidth: 200,
      headerName: "Active",
      field: "active",
    },
    {
      flex: 1,
      minWidth: 120,
      maxWidth: 150,
      headerName: "Action",
      field: "-",
      renderCell: (rowData) =>
        !modelOpenCLose && (
          <>
            <OverlayTrigger placement="bottom" overlay={editTooltip}>
              <ActionIcon onClick={() => EditRow(rowData)}>
                <i className="fas fa-pen m-auto" />
              </ActionIcon>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={deleteTooltip}>
              <ActionIcon onClick={() => DeleteRow(rowData)}>
                <div className="m-auto text-center">{deleIcon}</div>
              </ActionIcon>
            </OverlayTrigger>
          </>
        ),
    },
  ]);

  return (
    <>
      <Container fluid>
        <Row className="h-100">
          <Col md="12">
            <Card className="h-100">
              <Card.Header className="d-flex align-items-start justify-content-between">
                <div>
                  <Card.Title as="h4">Manage Meter Configuration</Card.Title>
                  <p className="card-category">Fill out below form to add new meter configuration. </p>
                </div>
              </Card.Header>
              <Card.Body>
                {loader ? (
                  <Loader />
                ) : (
                  <StyledDiv style={{ width: "100%" }} className="edgeDevicesTable">
                    {meterDevices?.length === 0 ? (
                      <div style={{ fontSize: 15, textAlign: "center" }}>There are no records to display</div>
                    ) : (
                      <DataGrid
                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        getRowClassName="p-2"
                        rows={(meterDevices && Array.isArray(meterDevices) ? meterDevices : []).map((item, index) => {
                          return {
                            ...item,
                            original_id: item.id,
                            id: `${item.id}_${index}`,
                          };
                        })}
                        columns={columns.current}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                          },
                        }}
                        disableRowSelectionOnClick
                        disableMultipleRowSelection
                        experimentalFeatures={{
                          lazyLoading: true,
                        }}
                        pageSizeOptions={[5, 10, 20]}
                      />
                    )}
                  </StyledDiv>
                )}
                <Col lg="12" className="p-0">
                  {navigationButtons("/add-edge-device", "/quick-permit-setup", propertyId, addActionRef, "Add Meter Configuration", null, null, null, () => {
                    modelhandle();
                  })}
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {isModalOpen && (
        <MeterdeviceDeleteModel
          showBrowserModal={isModalOpen}
          closeModal={() => setisModalOpen(!isModalOpen)}
          deleteMeterdeviceData={deleteMeterdeviceData}
          rowData={deleteRowData}
        />
      )}
      {modelOpenCLose && <AddMeterConfigTypeFun show={modelOpenCLose} />}
      {apiMessage?.isSuccess && apiMessage.message !== "" && (
        <CustomContentAlert delay={4000} message={apiMessage.message} className={apiMessage.isSuccess ? "toast-success" : ""} />
      )}
    </>
  );
}

const ActionIcon = styled.div`
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 13px 16px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 50%;
  }
  // @media (max-width: 450px) {
  //   width: 32px;
  //   height: 32px;
  //   padding: 10px;
  // }
`;

const StyledDiv = styled.div`
  max-width: 100%;
  height: calc(100vh - 300px);
  table > thead {
    th:last-child {
      // display: none;
    }
  }
  table > tbody {
    td:last-child {
      // display: none;
    }
    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft:last-child {
      padding: 0 3px;
    }
  }
`;

import t from "../../Actions/ResidentsActions/types";
import actionCreator from "../../Actions/Action.Helper";
import reducerHandler from "../Reducer.Helper";

const addUpdateDeleteResponse = actionCreator("ADD_UPDATE_DELETE_RES");

const initialAsyncState = {
  isLoading: false,
  loaded: false,
  data: null,
  error: null,
  message: "",
  success: null,
};

const intitialState = {
  addUpdateDeleteResponse: initialAsyncState,
  residentList: [],
  editedResident: {},
  addNewResident: {},
  stall_list: [],
  allLots: [],
  lprReportLots: [],
  lprReportLanes: [],
  currentLPR: [],
  currentTR: [],
  importedData: [],
  currentLOT: [],
  currentLane: [],
  loader: false,
};

const ResidentReducer = (state = intitialState, action) => {
  switch (action.type) {
    case addUpdateDeleteResponse.REQUEST:
    case addUpdateDeleteResponse.SUCCESS:
    case addUpdateDeleteResponse.FAILURE:
      return {
        ...state,
        addUpdateDeleteResponse: reducerHandler(state.addUpdateDeleteResponse, action, addUpdateDeleteResponse),
      };
    case t.FETCH_RESIDENTS:
      return { ...state, residentList: action.payload };

    case t.REMOVE_SPCIFIC_RESIDENTS: {
      const removeId = action.payload;
      const oldList = state.residentList.rows;
      const newArray = oldList.filter((item) => item.id !== removeId);
      const count = state.residentList.count - 1;

      return {
        ...state,
        residentList: { ...state.residentList, rows: newArray, count: count },
      };
    }

    case t.FETCH_SPCIFIC_RESIDENTS:
      return {
        ...state,
        editedResident: action.payload,
      };
    case t.FETCH_NEW_RESIDENTS:
      return {
        ...state,
        addNewResident: action.payload,
      };
    case t.FETCH_ALL_LOTS:
      return {
        ...state,
        allLots: action.payload,
      };

    case t.FETCH_LPR_ALL_LOTS:
      return {
        ...state,
        lprReportLots: action.payload,
      };

    case t.FETCH_LPR_LANES:
      return {
        ...state,
        lprReportLanes: action.payload,
      };

    case t.FETCH_CURRENT_LPR:
      return {
        ...state,
        currentLPR: action.payload,
      };
    case t.FETCH_CURRENT_TR:
      return {
        ...state,
        currentTR: action.payload,
      };
    case t.FETCH_CURRENT_LOT:
      return {
        ...state,
        currentLOT: action.payload,
      };
    case t.FETCH_IMPORT_UNITS:
      return {
        ...state,
        importedData: action.payload,
      };
    case t.FETCH_UNIT_STALL:
      return {
        ...state,
        stall_list: action.payload.data,
      };
    case t.SHOW_HIDE_LOADER: {
      return { ...state, loader: action.payload };
    }
    case t.FETCH_CURRENT_LANE:
      return {
        ...state,
        currentLane: action.payload,
      };
    default:
      return state;
  }
};

export default ResidentReducer;

import React, { useMemo } from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { routing_using_history } from "../helper";

const ScanAgain = (props) => {
  const isLoggedIn = useMemo(() => {
    return props?.allPermissions?.includes("self_register");
  }, [props]);
  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <Card className="info-container" style={{ alignItems: "center" }}>
              <Card.Body className="mt-5 info-container">
                <p>Pin is invalid, Please scan again!!</p>
                <p className="text-center">
                  <Button
                    onClick={() => {
                      if (isLoggedIn) {
                        routing_using_history("/self-register");
                      } else {
                        routing_using_history("/qp");
                      }
                    }}
                  >
                    Enter pin again!
                  </Button>
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ScanAgain;

import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import { UnAuthAPI } from "../../../Auth/requestHandler";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";

export const SearchAutoComplete = (props) => {
  const { label, isRequired, value, name, type, handleChange, make, defaultOptions, fromResidentPermit } = props;
  const [inputText, setInputText] = React.useState(value);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const abortControl = useRef(null);
  const defaultOptionsRef = useRef(defaultOptions || []);
  const previousValueRef = useRef(value);
  const previousMakeRef = useRef(make);
  const timeoutRef = useRef(null);

  React.useEffect(() => {
    setInputText(value);
    if (make !== previousMakeRef.current) {
      previousValueRef.current = null;
    }
  }, [value, make]);
  React.useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
    };
  }, []);
  const changeText = React.useCallback(
    async (e) => {
      let value = e?.target?.value || "";
      if (e.nativeEvent.type === "focusin") {
        value = inputText;
      } else {
        if (previousValueRef.current !== value) {
          handleChange({ [type]: value });
        }
      }
      if (previousValueRef.current !== value || filteredSuggestions.length === 0) {
        if (defaultOptionsRef.current.length === 0) {
          if (abortControl.current !== null) abortControl.current.abort();
          if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
          abortControl.current = new AbortController();
          try {
            timeoutRef.current = setTimeout(() => {
              let url = `public/cars?search=${value}&limit=1000&type=${type}`;
              if (type === "model") url += `&make=${make}`;
              UnAuthAPI(url, "GET", null, abortControl.current.signal)
                .then((data) => {
                  setFilteredSuggestions(data.data);
                  setShowSuggestions(true);
                })
                .catch((e) => {
                  console.log("error on public car search", e);
                });
            }, 500);
          } catch (e) {
            console.log("error on public car search", e);
          }
        } else {
          setShowSuggestions(true);
          setFilteredSuggestions(
            defaultOptionsRef.current.filter((x) => x.toLowerCase().indexOf(value.toLowerCase().trim()) !== -1).map((x) => ({ [type]: x })),
          );
        }
      } else if (!showSuggestions) {
        setShowSuggestions(true);
      }
      previousValueRef.current = value;
    },
    [inputText, type, make, filteredSuggestions, showSuggestions],
  );

  const selectValue = React.useCallback(
    (value) => {
      handleChange(value);
      setInputText(value[type]);
    },
    [type],
  );

  const onBlur = React.useCallback((e) => {
    const timeout = setTimeout(() => {
      setShowSuggestions(false);
    }, 250);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div onBlur={onBlur}>
      <Form.Label>
        {label} {isRequired && !fromResidentPermit && <span className="asterisk">*</span>}
      </Form.Label>
      &nbsp;
      <Form.Control type="text" autoComplete="off" placeholder={label} value={inputText} name={name} onFocus={changeText} onChange={changeText} />
      {showSuggestions && (
        <List>
          {filteredSuggestions.map((data, index) => (
            <ContentDiv onClick={() => selectValue(data)} key={index}>
              {data[type]}
            </ContentDiv>
          ))}
          {filteredSuggestions.length === 0 && <ContentDiv disabled>Data not available</ContentDiv>}
        </List>
      )}
    </div>
  );
};
export default SearchAutoComplete;

const List = styled(PerfectScrollbar)`
  list-style-type: none;
  padding: 5px;
  position: absolute;
  z-index: 1000;
  background: #fff;
  width: 92%;
  height: auto;
  max-height: 200px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 2px 13px #e8e8e8;
  overflow-y: scroll;
`;
const ContentDiv = styled.div`
  padding: 10px;
  ${(props) => !props.disabled && "cursor: pointer;"}
  ${(props) => props.disabled && "pointer-events: none;"}
  ${(props) => props.disabled && "text-align: center;"}
  background-color: ${(props) => (props.isActive ? "#dfdfdf" : "transparent")};
  :hover {
    ${(props) => !props.disabled && "background-color: #dfdfdf;"}
  }
`;

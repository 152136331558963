import t from "../../Actions/PlateSearchActions/types";

const intitialState = {
  currentPlate: [],
  currentPlateSearch: [],
};

const PlateSearchReducer = (state = intitialState, action) => {
  switch (action.type) {
    case t.FETCH_PLATE:
      return {
        ...state,
        currentPlate: action.payload,
      };
    case t.FETCH_PERMIT_PLATE:
      return {
        ...state,
        currentPlateSearch: action.payload,
      };
    case t.FETCH_PERMIT_PLATE_CITATIONS:
      return {
        ...state,
        currentPlateCitation: action.payload,
      };
    default:
      return state;
  }
};

export default PlateSearchReducer;

import React, { Component } from "react";
import { Card, Row, Col, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import { SuperAdminAction, EnforcementActions } from "../../Actions";
import Loader from "../Loader/Loader";
import CustomContentAlert, { findBySearch, tableHeightScrollBars, scroolToTop } from "../helper";
import isEqual from "lodash/isEqual";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropertDetails from "../LandingPage/PropertySelection";
import moment from "moment";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
const moment_timezone = require("moment-timezone");
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";
class CitationIssuedByEnforcer extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.state = {
      searchedTicketSummary: [],
      PropertyId: this.props.PropertyId,
      loader: false,
      searchValue: "",
      api_response: false,
      show_alert: false,
      api_success_message: "",
      api_error_message: "",
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      state: "",
      selectedProperty: "",
      selectedPropertyObj: {},
      all_properties: [],
      childcheckbox: true,
      lot_id: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      month: "",
      year: "",
      activeTab: "1",
      currentSummaryDate: "",
      finalTableHeight: 0,
      issuedSummaryList: [],
      selectedDomainName: "",
      daysDifference: 30,
      startDateTime: "",
      endDateTime: "",
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "Domain",
        width: "130px",
        sortable: true,
        cell: (row) => this.showDomainField(row),
        selector: (row) => row?.domain,
      },
      {
        name: "ENFORCER",
        width: "150px",
        cell: (row) => this.showEnforcerField(row),
        selector: (row) => row?.Enforcer,
        sortable: true,
      },
      {
        name: "Site",
        width: "150px",
        cell: (row) => this.showSiteField(row),
        selector: (row) => row.domain,
      },
      {
        name: "Total Issued #",
        minWidth: "130px",
        maxWidth: "230px",
        cell: (row) => this.showTotalCountField(row),
        selector: (row) => row.TotalCount,
      },
      {
        name: "Total Issued $",
        minWidth: "130px",
        maxWidth: "230px",
        sortable: true,
        cell: (row) => this.showTotalAmountField(row),
        selector: (row) => row?.TotalAmount,
      },
    ];
  }

  showTotalAmountField = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{row?.TotalAmount ? row?.TotalAmount : "-"}</Tooltip>}
    >
      <div className="text-truncate text-nowrap pr-2">{row?.TotalAmount ? row?.TotalAmount : "-"}</div>
    </OverlayTrigger>
  );

  showTotalCountField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.TotalCount ? row.TotalCount : "-"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.TotalCount ? row.TotalCount : "-"}</div>
    </OverlayTrigger>
  );

  showSiteField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.domain ? row.domain : "-"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.domain ? row.domain : "-"}</div>
    </OverlayTrigger>
  );

  showEnforcerField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.Enforcer ? row?.Enforcer : "-"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row?.Enforcer ? row?.Enforcer : "-"}</div>
    </OverlayTrigger>
  );

  showDomainField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.Enforcer ? row?.Enforcer : "-"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row?.domain}</div>
    </OverlayTrigger>
  );

  componentDidMount = async () => {
    this.setState({ loader: true });
    const { PropertyId, timezone, childcheckbox, startDate, endDate } = this.state;
    const pageobj = { PropertyId, timezone, childcheckbox };
    if (startDate) {
      pageobj.startDate = startDate;
    }
    if (endDate) {
      pageobj.endDate = endDate;
    }
    await this.props.getCitationIssuedByEnforcerSummary(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    this.setState({
      issuedSummaryList: this.props?.ticketSummary?.rows?.length ? JSON.parse(JSON.stringify(this.props.ticketSummary.rows)) : [],
      api_response: this.props.api_response,
      count: this.props.ticketSummary.count,
    });
    this.setState({ loader: false });
    const height = tableHeightScrollBars();
    this.setState({
      finalTableHeight: height,
    });

    await this.props.get_all_properties();
    this.setState(
      {
        api_response: this.props?.api_response,
        all_properties: this.props?.getAllProperties,
      },
      async () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === this.state.PropertyId);
        const selectedPropertyName = selectedPropertyObj ? selectedPropertyObj.site_name : "";
        const selectedDomainName = selectedPropertyObj ? selectedPropertyObj.domain : "";
        await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
        await this.props.selected_Property(selectedPropertyObj);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );
  };

  GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj);
    this.setState({
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    });
  };

  changeProperty(event) {
    this.setState({
      selectedProperty: event.value,
      selectedPropertyObj: event,
      loader: false,
    });
  }

  handleCheckBox = () => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState({
      childcheckbox: !this.state.childcheckbox,
      year: "",
      currentSummaryDate: null,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.ticketSummary, this.props.ticketSummary)) {
      this.setState({
        issuedSummaryList: this.props?.ticketSummary?.rows?.length ? JSON.parse(JSON.stringify(this.props.ticketSummary.rows)) : [],
        count: this.props.ticketSummary.count,
      });
    }
  };

  handleChangeStartingDates = (date) => {
    if (date) {
      const { daysDifference } = this.state;
      const end = new Date(date);
      end.setDate(date.getDate() + daysDifference);
      let endDate = new Date();
      if (new Date() >= end) {
        endDate = end;
      }
      this.setState({
        startDate: date,
        year: "",
        month: null,
        endDate: endDate,
        currentSummaryDate: null,
        loader: false,
      });
    }
  };

  handleChangeEndDates = (date) => {
    if (date) {
      this.setState({
        endDate: date,
        year: "",
        month: null,
        currentSummaryDate: null,
        loader: false,
      });
    }
  };

  handleExport = async () => {
    const { timezone, PropertyId, currentSummaryDate, startDate, endDate, lot_id, selectedPropertyObj, year, childcheckbox, selectedDomainName } = this.state;
    const pageobj = { timezone, exportData: true };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (startDate) {
      pageobj.startDate = startDate;
    }
    if (endDate) {
      pageobj.endDate = endDate;
    }
    if (year) {
      pageobj.year = moment_timezone(year).format("YYYY");
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (lot_id) {
      pageobj.lot_id = lot_id;
    }

    if (childcheckbox) {
      pageobj.childcheckbox = childcheckbox;
    }
    const bufferData = await this.props.getCitationIssuedByEnforcerSummary(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `CITATION-ISSUED-BY-ENFORCER-${currentTimeDate}-${selectedDomainName}.xls`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  handleChangeYear = (date) => {
    this.setState({
      year: date,
      currentSummaryDate: null,
      startDate: "",
      endDate: "",
      month: null,
      loader: false,
    });
  };

  handleFilterByDate = (date) => {
    this.setState({
      currentSummaryDate: date,
      startDate: "",
      endDate: "",
      year: "",
      month: null,
      loader: false,
    });
  };

  handleSearchSummary = async () => {
    this.setState({ loader: true });
    const { timezone, PropertyId, currentSummaryDate, startDate, endDate, lot_id, selectedPropertyObj, year, childcheckbox } = this.state;
    const pageobj = { timezone };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (startDate) {
      pageobj.startDate = startDate;
    }
    if (endDate) {
      pageobj.endDate = endDate;
    }
    if (year) {
      pageobj.year = moment_timezone(year).format("YYYY");
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (lot_id) {
      pageobj.lot_id = lot_id;
    }
    if (childcheckbox) {
      pageobj.childcheckbox = childcheckbox;
    }
    await this.props.getCitationIssuedByEnforcerSummary(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    this.setState({
      api_response: this.props.api_response,
      count: this.props.ticketSummary.count,
    });
    this.setState({ loader: false });
    document.body.classList.remove("foc-open");
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleResetSummary = async () => {
    const { PropertyId, daysDifference, selectedDomainName } = this.state;
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - daysDifference);
    this.setState({
      currentSummaryDate: "",
      lot_id: "",
      loader: false,
      year: null,
      selectedPropertyObj: {
        label: selectedDomainName,
        value: PropertyId,
      },
      selectedProperty: PropertyId,
      childcheckbox: true,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ searchValue: val }, async () => {
      const columnList = ["domain", "Enforcer", "TotalCount", "TotalAmount"];
      if (this.state.searchValue.length) {
        this.setState(
          {
            searchedTicketSummary: findBySearch(
              this.state.searchValue,
              this.state.issuedSummaryList,
              this.state.issuedSummaryList,
              columnList,
              this.props.timezone,
            ),
          },
          () => {
            this.setState({ count: this.state.searchedTicketSummary.length });
          },
        );
      } else {
        this.setState({
          count: this.props.ticketSummary.count,
          issuedSummaryList: this.props.ticketSummary.rows,
          searchedTicketSummary: [],
        });
      }
    });
  };

  dataTableHandle = (ticketList, searchedTicketSummary, searchValue) => {
    return (
      <DataTable
        data={searchValue?.length ? searchedTicketSummary : ticketList}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  render() {
    const { loader, childcheckbox, startDate, endDate, currentSummaryDate, issuedSummaryList, searchValue, searchedTicketSummary, year } = this.state;

    return (
      <div>
        <Card className="mb-0">
          <Card.Body className="pos-rel-overflow-hide">
            <>
              <div className="sidebarFilter">
                <Card className="contact-container mb-0">
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title as="h5">Citation Issued Summary</Card.Title>
                      <a
                        onClick={this.hideSlider}
                        href="javascript:void(0);"
                        className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <label className="fancy-checkbox">
                            <input
                              type="checkbox"
                              name={"childcheckbox"}
                              checked={childcheckbox}
                              onChange={this.handleCheckBox}
                              className="custom-control-input"
                              id="childcheckbox1"
                            ></input>
                            <span>
                              <i></i>Include Children Sites
                            </span>
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Specific Year:</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Date"
                            selected={year}
                            value={year}
                            disabled={loader || childcheckbox}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleChangeYear}
                            showYearPicker
                            dateFormat="yyyy"
                            maxDate={new Date()}
                            minDate={new Date(2021, 0, 1)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Specific Date:</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Date"
                            selected={currentSummaryDate}
                            value={currentSummaryDate}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleFilterByDate}
                            dateFormat="MM/dd/yyyy"
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Start Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Start Date"
                            selected={startDate}
                            defaultHour={24}
                            value={startDate}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleChangeStartingDates}
                            dateFormat="MM/dd/yyyy"
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">End Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select End Date"
                            selected={endDate}
                            disabled={loader}
                            value={endDate}
                            selectsEnd
                            timeInputLabel="Time:"
                            onChange={this.handleChangeEndDates}
                            dateFormat="MM/dd/yyyy"
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-right justify-content-between d-flex">
                    <Button variant="info" className="h-40 custom-btn-secondary btn-sm ml-2" onClick={this.handleResetSummary} disabled={loader}>
                      Reset
                    </Button>
                    <Button variant="info" className="h-40 btn-fill btn-sm" onClick={this.handleSearchSummary} disabled={loader}>
                      Search
                    </Button>
                  </Card.Footer>
                </Card>
              </div>
              <header>
                <div className="active-permit-header sm-flex-column sm-align-items-start">
                  <h5 className="mb-2 mb-md-0">Citation Issued Summary</h5>
                  <div className="filter-button d-flex flex-wrap">
                    <div className="d-flex align-items-center search-mb-0">
                      <DatatableHeader className="mt-0 mb-0" searchValue={searchValue} HandleChange={this.handleSearchChange} />
                    </div>
                    <div className="ml-2">
                      <Button
                        variant="info"
                        className="btn-fill btn-sm"
                        onClick={this.handleExport}
                        size="medium"
                        disabled={issuedSummaryList.length > 0 ? 0 : 1}
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </header>
            </>
            {loader ? (
              <Loader />
            ) : (
              <>
                {this.state.show_alert && <CustomContentAlert delay={4000} message={this.state.api_success_message} className="toast-success" />}
                <div className="citation-list-table list-of-ravenue-table has-parent-tab">
                  {this.dataTableHandle(issuedSummaryList, searchedTicketSummary, searchValue)}
                </div>
                <DateRangeAndAmount
                  ReportList={this.props?.ticketSummary?.rows}
                  amountName="TotalAmount"
                  startDateTime={this.state.startDateTime}
                  endDateTime={this.state.endDateTime}
                />
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, Logindetails, SuperAdminDetails, EnforcementDetails }) => {
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;
  const { citationSummaryList: ticketSummary } = EnforcementDetails;
  const { backgroundColor } = Logindetails;
  const { getAllProperties } = SuperAdminDetails;
  const { MainBackground: main_body } = backgroundColor;
  return {
    api_response,
    api_error_message,
    api_success_message,
    PropertyId,
    timezone,
    property,
    main_body,
    getAllProperties,
    ticketSummary,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  getCitationIssuedByEnforcerSummary: EnforcementActions.getCitationIssuedByEnforcerSummary,
};

export default connect(mapStateToProps, mapStateToDispatch)(CitationIssuedByEnforcer);

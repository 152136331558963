import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";

export default function MeterConfigSection(props) {
  const { formData, showError, handleChange, togglePassword } = props;
  const meterCreateFlag = useSelector((state) => state.MeterConfigDetails.meterCreateflag);

  return (
    <div>
      <Form.Group>
        <Row className="px-2">
          <Col className="sm-12">
            <Form.Label className="mb-0">
              Door Password
              {meterCreateFlag && <span className="asterisk">*</span>}
            </Form.Label>
            <div className="input-mask-btn">
              <Form.Control
                placeholder="Door Password"
                type={formData.contentvisible ? "text" : "password"}
                className="form-control"
                name="door_password"
                value={formData.door_password}
                onChange={handleChange}
                // autoComplete="new-password"
                required
                maxLength={16}
              />
              <button className="mask-password" type="button" onClick={togglePassword}>
                {formData.contentvisible ? <i className="fas fa-eye" /> : <i className="fas fa-eye-slash" />}
              </button>
            </div>
            {showError("door_password")}
          </Col>
        </Row>
        <Row className="px-2">
          <Col className="sm-12">
            <Form.Label className="mb-0">
              Minimum Rate
              <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control
              placeholder="Minimum Rate"
              type="number"
              name="minimum_rate"
              value={formData.minimum_rate}
              onChange={handleChange}
              autoComplete="off"
              required
              maxLength={6}
              min={0}
            />
            {showError("minimum_rate")}
          </Col>
        </Row>
        <Row className="px-2">
          <Col className="sm-12">
            <Form.Label className="mb-0">
              Operate Time
              <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control
              placeholder="Operate Time"
              type="text"
              name="operate_time"
              value={formData.operate_time}
              onChange={handleChange}
              autoComplete="off"
              required
              maxLength={3}
            />
            {showError("operate_time")}
          </Col>
        </Row>
        <Row className="px-2">
          <Col className="sm-12">
            <Form.Label className="mb-0">
              Idle Time
              <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control
              placeholder="Idle Time"
              type="text"
              name="idle_time"
              value={formData.idle_time}
              onChange={handleChange}
              autoComplete="off"
              required
              maxLength={3}
            />
            {showError("idle_time")}
          </Col>
        </Row>
        <Row className="px-2">
          <Col className="sm-12">
            <Form.Label className="mb-0">
              Battery Low
              <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control
              placeholder="Battery Low"
              type="number"
              name="battery_low"
              value={formData.battery_low}
              onChange={handleChange}
              autoComplete="off"
              required
              maxLength={6}
              min={0}
            />
            {showError("battery_low")}
          </Col>
        </Row>
        <Row className="px-2">
          <Col className="sm-12">
            <Form.Label className="mb-0">
              Report Time
              <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control
              placeholder="Report Time"
              type="text"
              name="report_time"
              value={formData.report_time}
              onChange={handleChange}
              autoComplete="off"
              required
              maxLength={3}
            />
            {showError("report_time")}
          </Col>
        </Row>
        <Row className="px-2">
          <Col className="sm-12">
            <Form.Label className="mb-0">
              Minimum Charge Time
              <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control
              placeholder="Minimum Charge Time"
              type="text"
              name="minimum_charge_time"
              value={formData.minimum_charge_time}
              onChange={handleChange}
              autoComplete="off"
              required
              maxLength={3}
            />
            {showError("minimum_charge_time")}
          </Col>
        </Row>
        <Row className="px-2">
          <Col className="sm-12">
            <Form.Label className="mb-0">
              Minimum Charge Rate
              <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control
              placeholder="Minimum Charge Rate"
              type="number"
              name="minimum_charge_rate"
              value={formData.minimum_charge_rate}
              onChange={handleChange}
              autoComplete="off"
              required
              maxLength={6}
              min={0}
            />
            {showError("minimum_charge_rate")}
          </Col>
        </Row>
        <Row className="px-2">
          <Col className="sm-12">
            <Form.Label className="mb-0">
              Operating Hours(hh:mm-hh:mm)
              <span className="asterisk">*</span>
            </Form.Label>
            <InputMask mask="99:99-99:99" value={formData.operating_hours} onChange={handleChange} name="operating_hours">
              {(inputProps) => <Form.Control placeholder="Enter time in format 07:00-21:00" {...inputProps} />}
            </InputMask>
            {showError("operating_hours")}
          </Col>
        </Row>
        <Row className="px-2">
          <Col className="sm-12">
            <Form.Label className="mb-0">
              Sleep Time(hh:mm-hh:mm)
              <span className="asterisk">*</span>
            </Form.Label>
            <InputMask mask="99:99-99:99" value={formData.sleep_time} onChange={handleChange} name="sleep_time">
              {(inputProps) => <Form.Control placeholder="Enter time in format 07:00-21:00" {...inputProps} />}
            </InputMask>
            {showError("sleep_time")}
          </Col>
        </Row>
      </Form.Group>
    </div>
  );
}

import t from "../../Actions/CurrentPermitActions/types";
import moment from "moment";
import Config from "../../../Auth/config";

const intitialState = {
  currentPermits: [],
  currentPermitPackages: [],
  currentCredits: [],
  usedCredits: [],
  allocatedCredits: [],
  usedAllocatedCredits: [],
  credit_30days_reports: [],
  permit_slots: [],
  data_parked: [],
  residentsCreditsArr: [],
  residentsPastCreditsArr: [],
  credit: 0,
  globaleCredit: 0,
  availableSpots: 0,
  resident_permits: [],
  time_slot: [],
  date_slot: [],
  single_credit_availability: {},
  residentList: [],
  updatedPermit: {},
  message: {
    isSuccess: false,
    message: "",
  },
  allSharedLotData: [],
  permits_allowed_type: 1,
  todaysDate: "",
  guestData: {},
  updateUnitLanguage: "",
};

const CurrentPermitReducer = (state = intitialState, action) => {
  switch (action.type) {
    case t.FETCH_CURRENT_PERMIT: {
      return {
        ...state,
        currentPermits: action.payload,
      };
    }
    case t.FETCH_PERMIT_PACKAGES: {
      return {
        ...state,
        currentPermitPackages: action.payload,
      };
    }
    case t.FETCH_TIME_SLOT: {
      return {
        ...state,
        date_slot: action.payload.date_slot,
        time_slot: action.payload.time_slot,
        multiple_permits: action.payload.multiple_permits,
        date_range: action.payload.date_range,
        template_name: action.payload.template_name,
      };
    }
    case t.FETCH_RESIDENT_LIST: {
      return {
        ...state,
        residentList: JSON.parse(JSON.stringify(action.payload)),
      };
    }
    case t.UNIT_SELECT_LANG: {
      return { ...state, updateUnitLanguage: action.payload };
    }
    case t.FETCH_TODAYS_DATE: {
      return {
        ...state,
        todaysDate: action.payload,
      };
    }
    case t.UPDATE_CURRENT_PERMIT: {
      const oldCurrentPermits = state.currentPermits.data;
      const newCurrentPermits = oldCurrentPermits.filter((cp) => cp.id !== action.payload);
      return {
        ...state,
        currentPermits: {
          ...state.currentPermits,
          data: newCurrentPermits,
          count: state.currentPermits.count - 1,
        },
      };
    }
    case t.FETCH_CURRENT_CREDITS: {
      return {
        ...state,
        currentCredits: action.payload,
      };
    }
    case t.FETCH_USED_CREDITS:
      return {
        ...state,
        usedCredits: action.payload,
      };
    case t.FETCH_CREDITS_ALLOCATED: {
      return {
        ...state,
        allocatedCredits: action.payload,
      };
    }
    case t.FETCH_PAST_CREDITS_ALLOCATED: {
      return {
        ...state,
        usedAllocatedCredits: action.payload,
      };
    }
    case t.FETCH_CREDITS_30DAYS_REEPORTS: {
      return {
        ...state,
        credit_30days_reports: action.payload,
      };
    }
    case t.FETCH_SLOTS: {
      console.log("action.payload", action.payload);
      return {
        ...state,
        permit_slots: action.payload,
      };
    }
    case t.FETCH_CREDITS_USED_30DAYS: {
      return {
        ...state,
        credit_used_30days: action.payload,
      };
    }
    case t.FETCH_SINGLE_CREDIT_AVAILABILITY: {
      return {
        ...state,
        single_credit_availability: action.payload,
      };
    }
    case t.FETCH_DATE_LIST:
      return {
        ...state,
        data_parked: action.payload.data_parked
          ? enumerateDaysBetweenDates(
              action.payload.data_parked,
              action.payload.credit,
              action.payload.timeSlot,
              action.payload.dateSlot,
              action.payload.available_spot,
            )
          : [],
      };
    case t.FETCH_RESIDENTSCREDITSARR:
      return {
        ...state,
        residentsCreditsArr: action.payload,
      };
    case t.FETCH_PERMIT_ALLOWED_TYPE:
      return {
        ...state,
        permits_allowed_type: action.payload,
      };
    case t.FETCH_RESIDENTSPASTCREDITSARR:
      return {
        ...state,
        residentsPastCreditsArr: action.payload,
      };
    case t.FETCH_CREDITS: {
      const residentsCreditsArr = JSON.parse(JSON.stringify(state.residentsCreditsArr));
      const residentsPastCreditsArr = JSON.parse(JSON.stringify(state.residentsPastCreditsArr));
      const appConfig = action.payload;
      return {
        ...state,
        credit: setResidentCredits(residentsCreditsArr, residentsPastCreditsArr, appConfig),
        globaleCredit: setResidentCredits(residentsCreditsArr, residentsPastCreditsArr, appConfig),
      };
    }
    case t.SET_AVAILABLESPOTS:
      return {
        ...state,
        availableSpots: action.payload,
      };
    case t.FETCH_RESIDENTS_PERMIT:
      return {
        ...state,
        resident_permits: action.payload,
      };
    case t.REMOVE_CURRENT_PERMIT: {
      return {
        ...state,
        currentPermits: [],
        currentPermitPackages: [],
        currentCredits: [],
        usedCredits: [],
        allocatedCredits: [],
        usedAllocatedCredits: [],
        data_parked: [],
      };
    }
    case t.REMOVE_SPECIFIC_PERMIT: {
      const oldCurrentPermits = state.currentPermits;
      const permitId = +action.payload;
      const index = oldCurrentPermits.data.findIndex((item) => +item.Id === permitId);
      if (index > -1) {
        oldCurrentPermits.splice(index, 1);
      }
      return {
        ...state,
        currentPermits: { ...state.currentPermits, data: oldCurrentPermits },
      };
    }
    case t.CALL_WHEN_HERE_ACTIVATE_DEACTIVATE: {
      const datas = JSON.parse(JSON.stringify(state.currentPermits.data));
      const newMappedArray = datas.map((item) => {
        if (item.id === action.payload) {
          item.call_when_here = !item.call_when_here;
        }
        return item;
      });
      return {
        ...state,
        currentPermits: { ...state.currentPermits, data: newMappedArray },
      };
    }
    case t.AUTO_RENEW_ACTIVATE_DEACTIVATE: {
      const datas = JSON.parse(JSON.stringify(state.currentPermitPackages.data));
      const newMappedArray = datas.map((item) => {
        if (item.id === action.payload) {
          item.auto_renew = item.auto_renew === 1 ? 0 : 1;
        }
        return item;
      });
      return {
        ...state,
        currentPermitPackages: {
          ...state.currentPermitPackages,
          data: newMappedArray,
        },
      };
    }
    case t.AUTO_CANCEL_ACTIVATE_DEACTIVATE: {
      const datas = JSON.parse(JSON.stringify(state.currentPermitPackages.data));
      const newMappedArray = datas.map((item) => {
        if (item.id === action.payload) {
          item.auto_cancel = item.auto_cancel === 1 ? 0 : 1;
        }
        return item;
      });
      return {
        ...state,
        currentPermitPackages: {
          ...state.currentPermitPackages,
          data: newMappedArray,
        },
      };
    }
    case t.UPDATE_PERMIT: {
      const datas = JSON.parse(JSON.stringify(state.currentPermits.data));
      const newMappedArray = datas.map((item) => {
        if (item.id === action.payload.id) {
          item.card_number = action.payload.card_number;
          item.phone = action.payload.phone;
          item.email = action.payload.email;
          item.first_name = action.payload.first_name;
          item.last_name = action.payload.last_name;
          item.expire_time = moment(action.payload.expire_time).format("lll");
        }
        return item;
      });
      return {
        ...state,
        currentPermits: { ...state.currentPermits, data: newMappedArray },
        updatedPermit: action.payload,
      };
    }

    case t.UPDATE_PERMIT_SUCCESS: {
      return {
        ...state,
        message: {
          isSuccess: true,
          message: action.payload,
        },
      };
    }
    case t.PASSAGEPASS_REFUND: {
      const passagePassData = JSON.parse(JSON.stringify(state.currentPermits.data));
      const newMappedArray = passagePassData.map((item) => {
        if (item.id === action.payload.permit_id) {
          item.refunded_amount = action.payload?.amount;
        }
        return item;
      });
      return {
        ...state,
        currentPermits: { ...state.currentPermits, data: newMappedArray },
      };
    }
    case t.UPDATE_PERMIT_ERROR: {
      return {
        ...state,
        message: {
          isSuccess: false,
          message: action.payload,
        },
      };
    }
    case t.DEACTIVATE_VISITOR: {
      const oldAllocatedCredits = state.allocatedCredits;
      const deletedlist = oldAllocatedCredits.filter((permit) => permit.id !== action.payload);
      return {
        ...state,
        allocatedCredits: JSON.parse(JSON.stringify(deletedlist)),
      };
    }
    case t.GET_SHARED_LOT: {
      return {
        ...state,
        allSharedLotData: action.payload,
      };
    }
    case t.GET_GUEST: {
      return {
        ...state,
        guestData: action.payload,
      };
    }
    default:
      return state;
  }
};

const enumerateDaysBetweenDates = (data_parked, credits, timeSlot, dateSlot, available_spot) => {
  try {
    const startDate = moment();
    const endDate = moment().add(dateSlot.length - 1, "days"); // no of days from API
    const dayList = {};
    const count = timeSlot.length; // No of column from API
    //  for (const data of dateSlot) {
    const time = timeSlot.map((item, index) => {
      return {
        from: item.time_from,
        to: item.time_to,
        credits: item.credits,
        credit_group: item.credit_group,
        key: index + 1,
        bookFull: item.bookFull,
      };
    });
    // }
    const newTime = [];
    for (let j = 0; j < count; j++) {
      const y = time[j];
      newTime.push(y);
    }

    let i = 0;
    const dateFormate = "YYYY/MM/DD";
    while (startDate.isSameOrBefore(endDate)) {
      dayList[startDate.format(dateFormate)] = dayList[startDate.format(dateFormate)] || [];
      newTime.forEach((ele) => {
        const isBooked = false;
        let isDisabled = false;
        i++;
        const slotStartDate = moment(startDate.format(dateFormate) + " " + ele.from);
        let slotEndDate;
        if (+ele.to.split(":")[0] < +ele.from.split(":")[0]) {
          const endDate_ = moment(startDate).add(1, "days");
          slotEndDate = moment(endDate_.format(dateFormate) + " " + ele.to);
        } else {
          slotEndDate = moment(startDate.format(dateFormate) + " " + ele.to);
        }
        if (+credits === 0 && Config.user_type !== "resident") {
          isDisabled = true;
        }
        const dataObj = {
          id: i,
          isChecked: false,
          isBooked: isBooked,
          isDisabled: isDisabled,
          startDate: slotStartDate,
          endDate: slotEndDate,
          availableSpots: available_spot,
          credits: ele.credits,
          group_name: ele.credit_group,
        };
        dayList[startDate.format(dateFormate)].push(dataObj);
      });
      startDate.add(1, "days");
    }
    const newObj = {};
    let count1 = 0;
    for (const date of dateSlot) {
      const timeArray = [];

      for (const time of date.timeslot) {
        const newStartDate = date.date;
        const slotStartDate = newStartDate + " " + time.time_from;

        let slotEndDate;

        if (+time.time_to.split(":")[0] < +time.time_from.split(":")[0]) {
          const endDate_ = moment(newStartDate).add(1, "days");
          slotEndDate = moment(endDate_.format(dateFormate) + " " + time.time_to);
        } else if (+time.time_to.split(":")[0] === +time.time_from.split(":")[0]) {
          if (+time.time_to.split(":")[1] <= +time.time_from.split(":")[1]) {
            const endDate_ = moment(newStartDate).add(1, "days");
            slotEndDate = moment(endDate_.format(dateFormate) + " " + time.time_to);
          } else {
            slotEndDate = newStartDate + " " + time.time_to;
          }
        } else {
          slotEndDate = newStartDate + " " + time.time_to;
        }
        count1++;
        time.id = count1;
        const obj = {
          isDisabled: false,
          isChecked: false,
          isBooked: time.bookFull,
          availableSpots: available_spot,
          group_name: time.credit_group,
          startDate: slotStartDate,
          endDate: slotEndDate,
          ...time,
        };
        timeArray.push(obj);
      }
      const newDate = date.date;
      newObj[newDate] = timeArray;
    }
    return newObj;
  } catch (error) {
    console.log("issue in enumerateDaysBetweenDates", error);
  }
};

const setResidentCredits = async (residentsCreditsArr, residentsPastCreditsArr, appConfig) => {
  try {
    let credits = 0;
    const futureFound = residentsCreditsArr.find((ele) => {
      return +ele.SuiteId === +appConfig.suite_id;
    });

    const pastFound = residentsPastCreditsArr.find((ele) => {
      return +ele.SuiteId === +appConfig.suite_id;
    });

    let found = "";
    if (futureFound && pastFound) {
      if (parseInt(futureFound.Cost) > parseInt(pastFound.Cost)) {
        found = futureFound;
      } else {
        found = pastFound;
      }
    }
    if (!futureFound) {
      found = pastFound;
    }
    if (!pastFound) {
      found = futureFound;
    }
    if (found) {
      let isFound = false;
      for (let i = 0; i < Object.keys(Config.fixCredits).length; i++) {
        if (Number(found.SuiteId) === Number(Object.keys(Config.fixCredits)[i])) {
          isFound = true;
        }
      }
      if (isFound) {
        credits = Config.fixCredits[found.SuiteId] - parseInt(found.Cost);
      } else {
        credits = appConfig.credits - parseInt(found.Cost);
      }
    } else {
      if (Config.fixCredits[Config.suite_id]) {
        credits = Config.fixCredits[Config.suite_id];
      } else {
        credits = appConfig.credits;
      }
    }

    return credits;
  } catch (error) {}
};

export default CurrentPermitReducer;

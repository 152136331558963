const superAdminRoutes = [
  // {
  //   path: "/superadmindashboard",
  //   name: "Dashboard",
  //   icon: "fas fa-tachometer-alt",
  // },
  {
    path: "/landingpage",
    name: "Site Wizard",
    icon: "icon-site-wizard",
  },
  {
    path: "/copy-settings",
    name: "Copy Setting",
    icon: "fas fa-copy",
  },
  {
    path: "/properties",
    name: "All Properties",
    icon: "icon-building",
  },
  {
    path: "/archivedproperty",
    name: "Archived Properties",
    icon: "fas fa-archive",
  },
  {
    path: "/activity-logs-report",
    name: "Activity Logs Report",
    icon: "fa fa-history",
  },
  // {
  //   path: "/ticket-setup",
  //   name: "Ticket Setup",
  //   icon: "fas fa-receipt",
  // },

  // {
  //   path: "/addlot",
  //   name: "All Lots",
  //   icon: "fas fa-layer-group",
  // },
  // {
  //   path: "/addlane",
  //   name: "All Lanes",
  //   icon: "far fa-id-badge",
  // },
  // {
  //   path: "/addcamera",
  //   name: "All cameras",
  //   icon: "fas fa-camera",
  // },
  // {
  //   path: "/adduser",
  //   name: "All users",
  //   icon: "fas fa-user-plus",
  // },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   icon: "fas fa-sliders-h",
  // },
  // {
  //   path: "/contact",
  //   name: "Contact Support",
  //   icon: "fas fa-phone-square-alt",
  // },
  {
    path: "/user-search",
    name: "User Search",
    icon: "fa fa-search",
  },
  {
    path: "/user-profile",
    name: "My Account",
    icon: "fas fa-user-circle",
  },
  {
    path: "/user-types",
    name: "User Types",
    icon: "fas fa-users",
  },
  {
    name: "Logout",
    icon: "fas fa-sign-out-alt",
  },
];

export default superAdminRoutes;

import React, { useMemo, useState } from "react";
import { Form, Button, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { tzDate } from "../helper";
import styled from "styled-components";
import newlink from "../../../assets/img/newlink.svg";

const PropertyModal = ({ closeModal, userPropertyData }) => {
  const timezone = useSelector((state) => state.UserProfileDetails.timezone);
  const isSubdomain = React.useCallback((url) => {
    const splittedUrl = url.split(".");
    return splittedUrl[splittedUrl.length - 1];
  }, []);
  const [searchText, setSearchText] = useState("");

  const rowsPerPageOptions = [10, 25, 50, 100, 200];

  const propData = useMemo(() => {
    console.log("userPropertyData == ", userPropertyData);
    return userPropertyData?.filter((x) => x.domain.toLowerCase().indexOf(searchText) !== -1 || x.site_name.toLowerCase().indexOf(searchText) !== -1);
  }, [userPropertyData, searchText]);

  const showSiteName = (row) => (
    <>
      {row.site_name ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.site_name}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3" style={{ cursor: "pointer" }}>
            {row.site_name}
          </div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showDomain = (row, TLD, protocol, port) => (
    <div>
      <a className={"top-header--title"} href={`${protocol}//${row.domain}.worldstream.${TLD}:${port}`} target="_blank" rel="noreferrer">
        <div className="d-flex align-items-center cursor-pointer">
          <span>{row.domain}</span> <img src={newlink} width="18" className="ml-1" />
        </div>
      </a>
    </div>
  );

  const showCreateTime = (row) => <div>{tzDate(timezone, row.createdAt)}</div>;

  const showSiteLastUpdate = (row) => <div>{tzDate(timezone, row.updatedAt)}</div>;

  const showAddress = (row) => (
    <>
      {row.address ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.address}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row.address}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const columns = React.useMemo(() => {
    const TLD = isSubdomain(window.location.hostname);
    const { protocol, port } = window.location;
    return [
      {
        name: "Name",
        center: true,
        sortable: true,
        minWidth: "150px",
        selector: (row) => row.site_name,
        cell: showSiteName,
      },
      {
        name: "Domain",
        center: true,
        sortable: true,
        // onclick: this.setState({ a: "domain" }),
        selector: (row) => row.domain,
        cell: (row) => showDomain(row, TLD, protocol, port),
      },
      {
        name: "Create Time",
        sortable: true,
        width: "160px",
        selector: (row) => row.createdAt,
        cell: showCreateTime,
      },
      {
        name: "Site Last Update",
        sortable: true,
        selector: (row) => row.updatedAt,
        cell: showSiteLastUpdate,
      },
      {
        name: "Site Last Access",
        sortable: true,
        center: true,
        selector: (row) => row.last_acessed_at,
        cell: (row) => showSiteLastAccess(row),
      },
      {
        name: "Address",
        center: true,
        sortable: true,
        selector: (row) => row.address,
        cell: showAddress,
      },
    ];
  }, [isSubdomain]);

  const showSiteLastAccess = (row) => <div> {row.last_acessed_at ? tzDate(timezone, row.last_acessed_at) : "--"}</div>;

  return (
    <Modal
      id="ImportPermits"
      lg={"lg"}
      backdrop={"static"}
      show={true}
      onHide={closeModal}
      dialogClassName="modal-90w modal-xl modal-fullscreen p-1"
      fullscreen={"sm-down"}
      className="modalData p-0"
    >
      <Modal.Header id="import" className="d-flex justify-content-between bg-white align-items-center">
        <h5 className="m-0 font-weight-bold">
          <Modal.Title className="m-0">Property</Modal.Title>
        </h5>
        <Button id="modal" type="button" onClick={closeModal} className="close-btn py-0" data-dismiss="modal">
          <i className="nc-icon nc-simple-remove text-danger" />
        </Button>
      </Modal.Header>
      <ModalBody className="pt-0">
        <StyledDatatable
          data={propData}
          columns={columns}
          pagination={true}
          responsive={true}
          defaultSortAsc={false}
          fixedHeader={true}
          paginationPerPage={50}
          paginationRowsPerPageOptions={rowsPerPageOptions}
          subHeader
          subHeaderComponent={
            <FilterComponent
              onFilter={(e) => {
                setSearchText(e.target.value.toLowerCase());
              }}
              filterText={searchText}
            />
          }
          fixedHeaderScrollHeight="70vh"
        />
      </ModalBody>
    </Modal>
  );
};

export default PropertyModal;
const StyledDatatable = styled(DataTable)`
  div[role="row"] {
    div[role="gridcell"] {
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
      }
    }
  }
`;

const ModalBody = styled(Modal.Body)`
  header {
    border-bottom: none;
    padding: 0px;
  }
`;

const FilterComponent = ({ filterText, onFilter }) => (
  <Form.Control
    id="search"
    type="text"
    className={"mt-2"}
    placeholder="Filter By Name or Domain"
    aria-label="Search Input"
    value={filterText}
    onChange={onFilter}
  />
);

import React, { Component } from "react";
import { Card, Container, Row, Col, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import CustomContentAlert, { tzDate } from "../helper"; // findBySearch, // routing_using_history,
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import { SuperAdminAction } from "../../Actions";
import CreatePropertyForm from "../CreateProperty/CreatePropertyForm";
import PaginationforData from "../pagination/pagination";
import DeleteResidentModal from "../Modal/DeleteResidentModal";
import Setting from "../../../assets/img/svg/settings.svg";
import Link from "../../../assets/img/svg/link.svg";
import { saveAs } from "file-saver";
class CreateProperty extends Component {
  constructor(props) {
    super(props);
    const url = window.location.href;
    const TLD = this.isSubdomain(url);
    this.state = {
      searchValue: "",
      prevPage: "",
      prevSearch: "",
      prevNorow: "",
      pageChanged: false,
      filterdProperties: [],
      allPropertyList: [],
      newPropertyList: [],
      count: 0,
      loader: false,
      api_response: false,
      showPropertyModal: false,
      isModalOpen: false,
      page: 1,
      Norow: 100,
      selectedColumn: "site_name",
      asc: "false",
      sort_direction: "DESC",
      findMore: false,
      timezone: this.props.timezone,
      topLevelDomain: TLD,
      confirmArchiveProperty: false,
      confirmArchvePropertyData: null,
      uuid: "",
      show_alert: false,
      show_error: false,
      api_success_message: "",
      api_error_message: "",
    };
    this.customStyles = {
      rows: {
        style: {
          minHeight: "50px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "0", // override the cell padding for head cells
          paddingRight: "0",
        },
      },
      cells: {
        style: {
          paddingLeft: "0", // override the cell padding for data cells
          paddingRight: "0",
        },
      },
    };

    this.columns = [
      {
        name: "Name",
        center: false,
        sortable: true,
        minWidth: "150px",
        maxWidth: "250px",
        selector: (row) => row.site_name,
        cell: (row) => this.showNameField(row),
      },
      {
        name: "Domain",
        center: false,
        sortable: true,
        minWidth: "150px",
        maxWidth: "250px",
        onclick: this.setState({ a: "domain" }),
        selector: (row) => row.domain,
        cell: (row) => this.showDomainField(row),
      },
      {
        name: "Timezone",
        sortable: true,
        width: "160px",
        selector: (row) => row.timezone,
        cell: (row) => this.showTimeZoneField(row),
      },
      {
        name: "Create Time",
        sortable: true,
        width: "160px",
        selector: (row) => row.createdAt,
        cell: (row) => this.showCreateTimeField(row),
      },
      {
        name: "Parent Property",
        minWidth: "200px",
        maxWidth: "200px",
        sortable: true,
        selector: (row) => row.modifiedName,
        cell: (row) => this.showParentPropertyField(row),
      },
      {
        name: "Site Last Update",
        minWidth: "160px",
        maxWidth: "160px",
        sortable: true,
        selector: (row) => row.updatedAt,
        cell: (row) => this.showSiteLastUpdateField(row),
      },
      {
        name: "Site Last Access",
        sortable: false,
        center: false,
        minWidth: "160px",
        maxWidth: "160px",
        selector: (row) => row.last_acessed_at,
        cell: (row) => this.showSiteLastAccessField(row),
      },
      {
        name: "Address",
        center: false,
        sortable: true,
        width: "200px",
        selector: (row) => row.address,
        cell: (row) => this.showAddressField(row),
      },
      {
        name: "Action",
        center: true,
        sortable: true,
        width: "130px",
        cell: (row) => this.showActionField(row),
      },
    ];
  }

  showActionField = (row) => (
    <Button type="button" variant="primary" className="btn-blue-bg btn-xs" onClick={() => this.handleArchiveModal(row.id)}>
      Archive
    </Button>
  );

  showAddressField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.address}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2">{row.address}</div>
    </OverlayTrigger>
  );

  showSiteLastAccessField = (row) => (
    <>
      {row.last_acessed_at ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{tzDate(this.state.timezone, row.last_acessed_at)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2"> {tzDate(this.state.timezone, row.last_acessed_at)}</div>
        </OverlayTrigger>
      ) : (
        "--"
      )}
    </>
  );

  showSiteLastUpdateField = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{tzDate(this.state.timezone, row.updatedAt)}</Tooltip>}
    >
      <div className="text-nowrap text-truncate pr-2">{tzDate(this.state.timezone, row.updatedAt)}</div>
    </OverlayTrigger>
  );

  showParentPropertyField = (row) => (
    <div className="text-nowrap text-truncate pr-2 d-flex">
      {row?.parentPropertyArray?.length
        ? row.parentPropertyArray.map((ele, Idx) => {
            return (
              <span style={{ cursor: "pointer" }} key={Idx}>
                <a className={"top-header--title"} onClick={() => this.handlePropertyClick(ele.id)}>
                  {ele?.site_name ? (
                    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{ele.site_name}</Tooltip>}>
                      <div className="text-nowrap text-truncate pr-1">{ele.site_name}</div>
                    </OverlayTrigger>
                  ) : (
                    ""
                  )}
                </a>
                {row.parentPropertyArray.length > 1 && row.parentPropertyArray.length !== Idx + 1 ? " - " : ""}
              </span>
            );
          })
        : "--"}

      {row?.parentPropertyArray?.length ? <img style={{ marginLeft: 5 }} src={Setting} width="18" /> : ""}
    </div>
  );

  showCreateTimeField = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{tzDate(this.state.timezone, row.createdAt)}</Tooltip>}
    >
      <div className="text-nowrap text-truncate pr-2">{tzDate(this.state.timezone, row.createdAt)}</div>
    </OverlayTrigger>
  );

  showTimeZoneField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.timezone}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2">{row.timezone}</div>
    </OverlayTrigger>
  );

  showDomainField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.domain}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2">
        <div className="d-flex">
          <a
            className={"top-header--title text-truncate"}
            href={"https://" + row.domain + ".worldstream." + this.state.topLevelDomain}
            target="_blank"
            rel="noreferrer"
          >
            {row.domain}
            {"  "}
            <img src={Link} width="18" />
          </a>
        </div>
      </div>
    </OverlayTrigger>
  );

  showNameField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.site_name}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2" style={{ cursor: "pointer" }}>
        <a className={"top-header--title"} onClick={() => this.handlePropertyClick(row.id)}>
          {row.site_name}
          {"  "}
          <img src={Setting} width="18" />
        </a>
      </div>
    </OverlayTrigger>
  );

  componentDidMount() {
    this.setState({ loader: true }, async () => {
      const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
      const pageobj = {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
      };
      const type = "unarchived";
      await this.props.get_all_properties_paginated(pageobj, type);
      const modifiedList = this.getModifiedList(this.props.getallpropertiespaginated);

      this.setState({
        allPropertyList: modifiedList,
        count: this.props.count,
        api_response: this.props.api_response,
      });
      this.setState({ loader: false });
      const selectedPropertyName = "";
      this.props.setDomainHeader(selectedPropertyName);
    });
  }

  getParentPropetyArray = (parentProperty, array) => {
    if (parentProperty?.parentProperty) {
      array.push(parentProperty);
      return this.getParentPropetyArray(parentProperty.parentProperty, array);
    } else {
      array?.push(parentProperty);
      return array;
    }
  };

  getParentPropertyName = (property) => {
    const parentProperty = this.props.getAllProperties.find((a) => a.id === property.property_id);
    if (parentProperty) {
      if (parentProperty.property_id && property.property_id !== parentProperty.property_id) {
        return `${property.site_name} - ${this.getParentPropertyName(parentProperty)}`;
      } else {
        return `${property.site_name} - ${parentProperty.site_name}`;
      }
    }
    return property.site_name;
  };

  isSubdomain = (url) => {
    url = url.replace(/^\s+/, "");
    url = url.replace(/\s+$/, "");
    url = url.replace(/\\/g, "/");
    url = url.replace(/^http:\/\/|^https:\/\/|^ftp:\/\//i, "");
    url = url.replace(/^www\./i, "");
    url = url.replace(/\/(.*)/, "");
    let domain = "";
    if (url.match(/\.[a-z]{2,3}\.[a-z]{2}$/i)) {
      url = url.replace(/\.[a-z]{2,3}\.[a-z]{2}$/i, "");
    } else if (url.match(/\.[a-z]{2,4}$/i)) {
      domain = url.split(".")[1];
    }
    return domain;
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.getallpropertiespaginated) !== JSON.stringify(this.props.getallpropertiespaginated)) {
      const modifiedList = this.getModifiedList(this.props.getallpropertiespaginated);

      this.setState({
        newPropertyList: modifiedList,
        allPropertyList: modifiedList,
        count: this.props.count,
      });
    }
  }

  getModifiedList = () => {
    return (this.props.getallpropertiespaginated ?? []).map((item) => {
      const array = [];
      const ddd = item.parentProperty !== null ? this.getParentPropetyArray(item.parentProperty, array) : [];
      item.parentPropertyArray = ddd;
      return item;
    });
  };

  handlePropertyClick = (property_id) => {
    const history = require("../../history").default;
    history.push({
      pathname: "/settings",
      search: property_id,
    });
  };

  handleSearchChange = ({ target }) => {
    this.setState({
      prevSearch: this.state.searchValue,
      page: 1,
      pageChanged: true,
      searchValue: target.value,
    });
    if (this.searchTimeout !== undefined) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.setState({ loader: true, pageChanged: true }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        const type = "unarchived";
        await this.props.get_all_properties_paginated(pageobj, type);
        this.setState({
          pageChanged: true,
          page: 1,
          newPropertyList: this.props.getallpropertiespaginated,
          count: this.props.count,
        });
      });
      setTimeout(() => {
        this.setState({ findMore: false });
      }, 1000);
    }, 1000);
  };

  handleCreateProperty = () => {
    this.setState({
      showPropertyModal: true,
      isModalOpen: !this.state.isModalOpen,
    });
  };

  handlepage = ({ target }) => {
    console.log("tttttttttttt", target.value);

    this.setState({ prevPage: this.state.page, prevNorow: this.state.Norow });
    this.setState(
      {
        [target.name]: target.value,
      },
      async () => {
        switch (target.name) {
          case "page":
            this.setState({ page: target.value }, async () => {
              this.setState({ loader: true, pageChanged: true }, async () => {
                const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
                const pageobj = {
                  page,
                  Norow,
                  searchValue,
                  selectedColumn,
                  sort_direction,
                };
                const type = "unarchived";
                await this.props.get_all_properties_paginated(pageobj, type);
                this.setState({
                  allPropertyList: this.props.getallpropertiespaginated,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
          case "Norow":
            this.setState({ Norow: target.value }, async () => {
              this.setState({ loader: true, pageChanged: true }, async () => {
                this.setState(
                  {
                    page: 1,
                  },
                  async () => {
                    const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
                    const pageobj = {
                      page,
                      Norow,
                      searchValue,
                      selectedColumn,
                      sort_direction,
                    };
                    const type = "unarchived";
                    await this.props.get_all_properties_paginated(pageobj, type);
                  },
                );

                this.setState({
                  allPropertyList: this.props.getallpropertiespaginated,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
        }
        if (Number(this.state.page) !== Number(this.state.prevPage) || Number(this.state.Norow) !== Number(this.state.prevNorow)) {
          const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          this.setState({ loader: true }, async () => {
            const type = "unarchived";
            await this.props.get_all_properties_paginated(pageobj, type);
            this.setState({
              newPropertyList: this.props.getallpropertiespaginated,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        }
      },
    );
  };

  handleonsort = async (e) => {
    this.setState({ asc: !this.state.asc }, () => {
      const { asc } = this.state;
      if (!asc) {
        this.setState({ sort_direction: "ASC" }, async () => {
          this.setState({ selectedColumn: e.name }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            this.setState({ loader: true }, async () => {
              const type = "unarchived";
              await this.props.get_all_properties_paginated(pageobj, type);
              this.setState({
                propertiesAvailable: await this.props.getallpropertiespaginated,
                api_response: this.props.api_response,
              });
              this.setState({ loader: false });
            });
          });
        });
      } else {
        this.setState({ sort_direction: "DESC" }, async () => {
          this.setState({ selectedColumn: e.name }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            this.setState({ loader: true }, async () => {
              const type = "unarchived";
              await this.props.get_all_properties_paginated(pageobj, type);
              this.setState({
                propertiesAvailable: await this.props.getallpropertiespaginated,
                api_response: this.props.api_response,
              });
              this.setState({ loader: false });
            });
          });
        });
      }
    });
  };

  handleRowsPerPage = () => {
    const { Norow, page } = this.state;
    return { Norow, page };
  };

  handlepagenext = () => {
    const { page, Norow } = this.state;
    const count = this.props.count;
    const finalPage = Math.ceil(count / Norow);
    if (page + 1 <= finalPage) {
      this.setState({ page: page + 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true, pageChanged: true }, async () => {
          const type = "unarchived";
          await this.props.get_all_properties_paginated(pageobj, type);

          this.setState({
            newPropertyList: await this.props.getallpropertiespaginated,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepageprevious = () => {
    if (this.state.page !== 1) {
      this.setState({ page: this.state.page - 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true }, async () => {
          const type = "unarchived";
          await this.props.get_all_properties_paginated(pageobj, type);
          this.setState({
            newPropertyList: this.props.getallpropertiespaginated,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepageinitial = () => {
    if (this.state.page !== 1) {
      this.setState({ page: 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true }, async () => {
          const type = "unarchived";
          await this.props.get_all_properties_paginated(pageobj, type);
          this.setState({
            newPropertyList: this.props.getallpropertiespaginated,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepagefinal = async () => {
    const { page, Norow } = this.state;
    const count = this.props.count;
    let last_page = count / Norow;
    if (last_page === 0) {
      last_page = 1;
    }
    const rounded = Math.ceil(last_page);
    if (page < rounded) {
      this.setState({ page: rounded, pageChanged: true }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true, pageChanged: true }, async () => {
          const type = "unarchived";
          await this.props.get_all_properties_paginated(pageobj, type);
          this.setState({
            newPropertyList: this.props.getallpropertiespaginated,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  findMorePressed = () => {
    this.setState({ findMore: true }, () => {
      if (this.state.findMore) {
        if (this.state.searchValue) {
          this.setState({ loader: true, pageChanged: true }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            const type = "unarchived";
            await this.props.get_all_properties_paginated(pageobj, type);
            this.setState({
              pageChanged: true,
              page: 1,
              newPropertyList: this.props.getallpropertiespaginated,
            });
            this.setState({ loader: false });
          });
          setTimeout(() => {
            this.setState({ findMore: true });
          }, 1000);
        }
      } else {
        this.setState({ loader: true, pageChanged: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          const type = "unarchived";
          await this.props.get_all_properties_paginated(pageobj, type);
          this.setState({
            pageChanged: true,
            page: 1,
            newPropertyList: this.props.getallpropertiespaginated,
          });
          this.setState({ loader: false });
        });
        setTimeout(() => {
          this.setState({ findMore: true });
        }, 1000);
      }
    });
  };

  handleArchiveModal = async (id) => {
    const message = " Are you sure you want to archive this property?";
    const data = { title: "Archive Property", message: message };
    this.setState({
      confirmArchvePropertyData: data,
      confirmArchiveProperty: true,
      uuid: id,
    });
  };

  onConfrim = async () => {
    const id = this.state.uuid;
    this.setState({ confirmArchiveProperty: false }, async () => {
      await this.props.get_Archieve(id);
      if (this.props.api_response) {
        this.setState({
          show_alert: true,
          api_success_message: this.props.api_success_message,
        });
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        const type = "unarchived";
        await this.props.get_all_properties_paginated(pageobj, type);
        this.setState({
          allPropertyList: this.props.getallpropertiespaginated,
          count: this.props.count,
          api_response: this.props.api_response,
        });

        setTimeout(() => {
          this.setState({
            show_alert: false,
          });
        }, 4000);
      } else {
        this.setState({
          show_error: true,
          api_error_message: this.props.api_error_message,
        });
        setTimeout(() => {
          this.setState({ show_error: false });
        }, 4000);
      }
    });
  };

  onCancel = () => {
    this.setState({ confirmArchiveProperty: false });
  };

  handleExport = async () => {
    const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
    const pageobj = {
      page,
      Norow,
      searchValue,
      selectedColumn,
      sort_direction,
    };
    const type = "unarchived";
    pageobj.exportData = true;
    const bufferData = await this.props.get_all_properties_paginated(pageobj, type);
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        `Property.xls`,
      );
    }
  };

  render() {
    const {
      searchValue,
      allPropertyList,
      newPropertyList,
      pageChanged,
      loader,
      api_response,
      showPropertyModal,
      confirmArchvePropertyData,
      confirmArchiveProperty,
      isModalOpen,
      show_alert,
      show_error,
      page,
      Norow,
    } = this.state;

    return (
      <>
        <Container fluid>
          <Row className="h-100">
            <Col md="12">
              <Card className="h-100 mb-0">
                <Card.Header>
                  <div className="d-flex align-items-sm-center align-items-start justify-content-between sm-flex-column">
                    <div>
                      <Card.Title as="h4">All Properties</Card.Title>
                      {/* <p className="card-category">All properties</p> */}
                    </div>
                    <div>
                      <Button onClick={this.handleCreateProperty} type="button" variant="primary" className="btn-blue-bg btn-sm">
                        Add Property
                      </Button>
                      <Button
                        variant="primary"
                        className="btn-blue-bg btn-sm ml-2"
                        onClick={this.handleExport}
                        disabled={newPropertyList.length === 0}
                        size="medium"
                      >
                        Export Property
                      </Button>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide">
                  <header>
                    <div className="active-permit-header sm-flex-column sm-align-items-start">
                      <h5 className="mb-2 mb-md-0"></h5>
                      <div className="filter-button d-flex flex-wrap">
                        <div className="d-flex align-items-center search-mb-0 mr-2 loc-searchbox">
                          <DatatableHeader
                            // Header={"Properties"}
                            searchValue={this.state.searchValue}
                            HandleChange={this.handleSearchChange}
                            findMorePressed={this.findMorePressed}
                            findMore={true}
                            noShowFilter={true}
                          />
                        </div>
                      </div>
                    </div>
                  </header>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {!api_response && (
                        <CustomContentAlert delay={4000} message={"The server did not respond, Please try again after sometime"} className="toast-error" />
                      )}
                      <div className="citation-list-table list-of-ravenue-table sa-all-property-table" style={{ paddingLeft: "10px" }}>
                        <DataTable
                          data={searchValue.length || pageChanged ? newPropertyList : allPropertyList}
                          columns={this.columns}
                          customStyles={this.customStyles}
                          // pagination={true}
                          paginationServer={true}
                          responsive={true}
                          // subHeader
                          defaultSortAsc={false}
                          fixedHeader={true}
                          fixedHeaderScrollHeight="38vh"
                        />
                      </div>
                      <PaginationforData
                        count={this.state.count}
                        data={this.state.newPropertyList}
                        // data={this.props?.getallpropertiespaginated}
                        handlepageinitial={this.handlepageinitial}
                        handlepageprevious={this.handlepageprevious}
                        handlepagenext={this.handlepagenext}
                        handlepagefinal={this.handlepagefinal}
                        handlepage={this.handlepage}
                        page={page}
                        Norow={Norow}
                      />
                    </>
                  )}
                  {confirmArchiveProperty ? (
                    <DeleteResidentModal
                      showBrowserModal={confirmArchiveProperty}
                      details={confirmArchvePropertyData}
                      closeModal={() => this.setState({ confirmArchiveProperty: false })}
                      handleSave={(id) => this.onConfrim(id)}
                      handleCancel={this.onCancel}
                    />
                  ) : null}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {showPropertyModal ? <CreatePropertyForm show={isModalOpen} closeModal={() => this.setState({ isModalOpen: !this.state.isModalOpen })} /> : null}
        {show_alert ? <CustomContentAlert delay={4000} message={this.state.api_success_message} className="toast-success" /> : null}
        {show_error ? <CustomContentAlert delay={4000} message={this.state.api_error_message} className="toast-error" /> : null}
      </>
    );
  }
}

const mapStatetoProps = ({ SuperAdminDetails, APIResponseDetails, UserProfileDetails }) => {
  const { api_response, api_success_message, api_error_message } = APIResponseDetails;
  const { getallpropertiespaginated, propertyCount: count, getAllProperties } = SuperAdminDetails;
  const { timezone } = UserProfileDetails;
  return {
    api_response,
    getallpropertiespaginated,
    getAllProperties,
    count,
    timezone,
    api_success_message,
    api_error_message,
  };
};
const mapStateToDispatch = {
  get_all_properties: SuperAdminAction.get_all_properties,
  get_all_properties_paginated: SuperAdminAction.get_all_properties_paginated,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  get_Archieve: SuperAdminAction.get_Archieve,
};

export default connect(mapStatetoProps, mapStateToDispatch)(CreateProperty);

import React, { Component } from "react";
import { Card, Container, Row, Col, Dropdown, Tooltip, OverlayTrigger } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { connect } from "react-redux";
import { ResidentsActions, UserProfileActions, SuperAdminAction } from "../../Actions";
import Loader from "../Loader/Loader";
import DeleteResidentModal from "../Modal/DeleteResidentModal";
import CustomContentAlert, { routing_using_history, findBySearch, tableHeightScrollBars, utcToSpecificTimeZone, scroolToTop } from "../helper";
import BulkImportModal from "../Modal/BulkImportModal";

class Residents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PropertyId: this.props.PropertyId,
      residentList: [],
      loader: false,
      user_type: this.props.user_type,
      suite_id: this.props.suite_id,
      confirmRemoveResidence: false,
      confirmRemoveResidenceData: null,
      uuid: "",
      searchValue: "",
      prevSearch: "",
      prevPage: "",
      prevNorow: "",
      searchedResidentList: [],
      api_response: false,
      show_alert_on_remove_resident: false,
      show_error_on_remove_resident: false,
      api_success_message: "",
      page: 1,
      Norow: 100,
      selectedColumn: "created_at",
      asc: "false",
      sort_direction: "DESC",
      pageChanged: false,
      findMore: false,
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      showImportModal: false,
      responseTableData: [],
      importType: "",
      modelLoader: false,
      finalTableHeight: 0,
    };
    this.scrollRef = React.createRef(null);
    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    const showUnitId = (row) => (
      <>
        {row?.suite_id ? (
          <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-qw`}>{row?.suite_id ? row?.suite_id : "-"}</Tooltip>}
          >
            <div className="text-nowrap text-truncate pr-2">{row?.suite_id}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </>
    );

    const showUserName = (row) => (
      <>
        {row?.username ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.username}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{row?.username}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </>
    );

    const showFirstName = (row) => (
      <>
        {row?.first_name ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.first_name}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{row?.first_name}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </>
    );

    const showLastName = (row) => (
      <>
        {row?.last_name ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.last_name}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{row?.last_name}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </>
    );

    const showAction = (row) => {
      const user = row.id;
      const userData = row;
      const suiteId = row.suite_id;
      if (this.state.user_type === "unit") {
        return (
          <Dropdown>
            <Dropdown.Toggle variant="Primary" id="dropdown-basic" className="btn-xs">
              Action
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="" onClick={() => this.viewPermit(suiteId)}>
                View permit
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      } else {
        return (
          <Dropdown>
            <Dropdown.Toggle variant="Primary" id="dropdown-basic" className="btn-xs">
              Action
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item className="text-sm" href="" onClick={() => this.addNewPermit(user, userData)}>
                Add new permit
              </Dropdown.Item>
              <Dropdown.Item className="text-sm" href="" onClick={() => this.viewPermit(suiteId)}>
                View permit
              </Dropdown.Item>
              <Dropdown.Item className="text-sm" href="" onClick={() => this.editResident(user, userData)}>
                Edit Unit
              </Dropdown.Item>
              <Dropdown.Item className="text-sm" href="" onClick={() => this.deleteResident(user)}>
                Remove Unit
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    };

    const showNotes = (row) => (
      <div className="unit-list-notes text-nowrap text-truncate pr-2">
        {row?.parking_spot ? (
          <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-qw`}>{row?.parking_spot ? row?.parking_spot : "-"}</Tooltip>}
          >
            <div className="m-0 text-nowrap text-truncate">{row?.parking_spot}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </div>
    );

    const showEmailAddress = (row) => (
      <>
        {row?.email ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.email ? row?.email : "-"}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{row?.email}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </>
    );

    const showPhone = (row) => (
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement={"top"}
        overlay={<Tooltip id={`tooltip-qw`}>{row?.phone?.substring(0, 10) ? row?.phone?.substring(0, 10) : "-"}</Tooltip>}
      >
        <div className="text-nowrap text-truncate">{row?.phone?.substring(0, 10) ? row?.phone?.substring(0, 10) : "-"}</div>
      </OverlayTrigger>
    );

    const showCreatedDate = (row) => (
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement={"top"}
        overlay={<Tooltip id={`tooltip-qw`}>{row.createdAt ? utcToSpecificTimeZone(this.state.timezone, row.createdAt) : "-"}</Tooltip>}
      >
        <div className="text-nowrap text-truncate">{row.createdAt ? utcToSpecificTimeZone(this.state.timezone, row.createdAt) : "-"}</div>
      </OverlayTrigger>
    );

    this.columns = [
      {
        name: <div>Unit Id</div>,
        sortable: true,
        width: "120px",
        cell: showUnitId,
        selector: (row) => row?.suite_id,
      },
      {
        name: "Username",
        sortable: true,
        width: "200px",
        cell: showUserName,
        selector: (row) => row?.username,
      },
      {
        name: "First Name",
        sortable: true,
        width: "140px",
        cell: showFirstName,
        selector: (row) => row?.first_name,
      },
      {
        name: "Last Name",
        sortable: true,
        width: "140px",
        cell: showLastName,
        selector: (row) => row?.last_name,
      },
      {
        name: "Email Address",
        sortable: true,
        width: "200px",
        // cell: (row) => <div>{row?.email}</div>,
        cell: showEmailAddress,
        selector: (row) => row?.email,
      },
      {
        name: "Phone",
        width: "120px",
        sortable: true,
        cell: showPhone,
        selector: (row) => row?.phone,
      },
      {
        name: <div>Created Date</div>,
        sortable: true,
        width: "150px",
        cell: showCreatedDate,
        selector: (row) => row.createdAt,
      },
      {
        name: "Notes",
        sortable: true,
        width: "200px",
        // cell: (row) => <div>{row?.parking_spot}</div>,
        cell: showNotes,
        selector: (row) => row?.parking_spot,
      },
      {
        name: "Action",
        width: "100px",
        sortable: true,
        cell: showAction,
      },
    ];
  }

  UNSAFE_componentWillMount() {
    if (this.props.location?.state) {
      const search = this.props.location.state.search;
      this.setState(
        {
          searchValue: search,
        },
        async () => {
          this.setState({ loader: true }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
              isUnit: true,
            };
            await this.props.fetch_residents(pageobj);
            if (this.state.searchValue?.length) {
              this.setState({
                residentList: await this.props?.residentList?.rows,
                api_response: this.props.api_response,
                count: await this.props?.residentList?.count,
              });
            }
            const height = tableHeightScrollBars();
            this.setState({
              finalTableHeight: height,
            });
            this.setState({ loader: false });
          });
        },
      );
    } else {
      this.setState({ loader: true }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          isUnit: true,
        };
        await this.props.fetch_residents(pageobj);
        const selectedPropertyName = this.state.property?.property_name;
        const selectedDomainName = this.state.property?.domain;
        await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
        this.setState({
          residentList: await this.props?.residentList?.rows,
          api_response: this.props.api_response,
          count: await this.props?.residentList?.count,
        });
        const height = tableHeightScrollBars();
        this.setState({
          finalTableHeight: height,
        });
        this.setState({ loader: false });
      });
    }
  }

  componentDidMount = async () => {
    const exportUrl = `${process.env.REACT_APP_CONFIG_BASE_URL}`;
    if (exportUrl) {
      this.setState({
        exportUrl: `${exportUrl}/api/v1/user/export_csv/${this.state?.property?.id}/${this.props.id}/units`,
      });
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.residentList !== this.props.residentList) {
      this.setState({
        searchedResidentList: this.props?.residentList?.rows,
        residentList: this.props?.residentList?.rows,
        pageChanged: true,
        count: this.props?.residentList?.count,
      });
    }
  };

  handleSearchChange = ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ prevSearch: this.state.searchValue });
    this.setState({ searchValue: val }, () => {
      const columnList = ["suite_id", "username", "first_name", "last_name", "email", "phone", "created_at", "parking_spot"];
      let searchExportValue;
      let seachUrl;
      const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
      const pageobj = {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        isUnit: true,
      };
      this.props.fetch_residents(pageobj);
      if (this.state.searchValue.length) {
        seachUrl = this.state.exportUrl.split("/?")[0];
        searchExportValue = seachUrl + "/?search=" + this.state.searchValue;
        if (!this.state.findMore) {
          this.setState(
            {
              searchedResidentList: findBySearch(this.state.searchValue, this.state.residentList, this.state.residentList, columnList, this.state.timezone),
              exportUrl: searchExportValue,
            },
            () => {
              this.setState({ count: this.state.searchedResidentList.length });
            },
          );
        }
      } else {
        this.setState({ loader: true, pageChanged: true }, async () => {
          this.setState({
            residentList: this.state?.residentList,
            count: this.state.residentList?.length,
            searchedResidentList: [],
          });
          seachUrl = this.state.exportUrl.split("?")[0];
          searchExportValue = seachUrl + "?search=" + this.state.searchValue;
          this.setState({
            loader: false,
            exportUrl: searchExportValue,
          });
        });
      }
    });
  };

  addNewPermit = (user, userData) => {
    const history = require("../../history").default;
    history.push({
      pathname: "/unit-permit",
      search: `${user}`,
      residentDetail: userData,
    });
  };

  viewPermit = (suiteId) => {
    const history = require("../../history").default;
    history.push({
      pathname: "/current-permits",
      search: `${suiteId}`,
    });
  };

  editResident = (user, userData) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/edit-unit`,
      search: user,
      editResident: userData,
    });
  };

  deleteResident = async (id) => {
    this.confirmRemoveResidenceModal(id);
  };

  handleAddResidents = () => {
    routing_using_history("/add-unit");
  };

  confirmRemoveResidenceModal = (id) => {
    const message = `Note: All the parking permits of this unit will be permanently deactivated,
      Are you sure you want to remove unit?`;
    const data = { title: "Remove Unit", message: message };
    this.setState({
      confirmRemoveResidenceData: data,
      confirmRemoveResidence: true,
      uuid: id,
    });
  };

  removeResident = async (id) => {
    await this.props.remove_resident(id);
  };

  onConfrim = () => {
    const id = this.state.uuid;
    this.setState({ confirmRemoveResidence: false, pageChanged: true, loader: true }, () => {
      this.confirmRemoveResident(id);
    });
  };

  confirmRemoveResident = async (id) => {
    await this.props.confirm_remove_resident(id);
    if (this.props.api_response) {
      this.setState({
        show_alert_on_remove_resident: true,
        api_success_message: this.props.api_success_message,
        loader: false,
      });
      setTimeout(() => {
        this.setState({ show_alert_on_remove_resident: false });
      }, 4000);
    } else {
      this.setState({ show_error_on_remove_resident: true });
      setTimeout(() => {
        this.setState({ show_error_on_remove_resident: false });
      }, 4000);
    }
  };

  onCancel = () => {
    this.setState({ confirmRemoveResidence: false });
  };

  dataTableHandle = (
    searchValue,
    searchedResidentList,
    residentList,
    // pageChanged
  ) => {
    return (
      <DataTable
        className="table-responsive"
        data={searchValue?.length ? searchedResidentList : residentList}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        scrollX={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={this.state.Norow}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  UploadFile = async (jsonObject) => {
    this.setState({
      modelLoader: true,
    });

    await this.props.importUnitsPermitsSites(jsonObject, this.state.importType);
    if (this.props.api_response) {
      const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
      const pageobj = {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        isUnit: true,
      };
      await this.props.fetch_residents(pageobj);
      this.setState({
        showSuccess: true,
      });
      setTimeout(() => {
        this.setState({
          showSuccess: false,
        });
      }, 4000);
    } else {
      this.setState({
        showError: true,
      });
      setTimeout(() => {
        this.setState({
          showError: false,
        });
      }, 4000);
    }
    this.setState({
      modelLoader: false,
    });
  };

  CloseModal() {
    this.setState({ showImportModal: false, responseTableData: [] });
  }

  ShowModal(type) {
    this.setState({ showImportModal: true, importType: type });
  }

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  render() {
    const {
      residentList,
      loader,
      modelLoader,
      confirmRemoveResidence,
      confirmRemoveResidenceData,
      searchValue,
      api_response,
      show_alert_on_remove_resident,
      show_error_on_remove_resident,
      page,
      Norow,
      pageChanged,
      searchedResidentList,
    } = this.state;
    return (
      <div>
        <Container fluid className="manage-unit-list-page page-no-fliter">
          <Row>
            <Col md="12">
              <Card className="mb-0">
                <Card.Body>
                  <>
                    <div className="sidebarFilter">
                      <Card className="contact-container mb-0">
                        <Card.Header style={{ backgroundColor: this.props.main_body }}>
                          <div className="d-flex align-items-center justify-content-between">
                            <Card.Title as="h4">List of Units</Card.Title>
                            <a
                              onClick={this.hideSlider}
                              href="javascript:void(0);"
                              className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                          </div>
                        </Card.Header>
                        <Card.Body></Card.Body>
                        <Card.Footer className="text-right justify-content-between d-flex"></Card.Footer>
                      </Card>
                    </div>
                    <header>
                      <div className="active-permit-header sm-flex-column sm-align-items-start">
                        <h5 className="mb-2 mb-md-0">List of Units</h5>
                        <div className="filter-button d-flex flex-wrap">
                          <div className="d-flex align-items-center search-mb-0">
                            <DatatableHeader
                              className="mt-0 mb-0"
                              // Header={"List of Units"}
                              searchValue={searchValue}
                              HandleChange={this.handleSearchChange}
                            />
                          </div>
                          {/* <div className="ml-2">
                            <Button
                              variant="info"
                              className="btn-fill btn-sm"
                              onClick={this.handleExport}
                              disabled={exportButtonDisable}
                              size="medium"
                            >
                              Export
                            </Button>
                          </div> */}
                          <div className="buttons-dd">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="Primary"
                                id="dropdown-basic"
                                className="btn btn-sm border-0 bg-light  w-30px h-30px d-flex align-items-center justify-content-center ml-2"
                              >
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item className="text-sm" onClick={() => this.handleAddResidents()}>
                                  <span className="pl-2">Add Unit</span>
                                </Dropdown.Item>
                                {this.state.searchedResidentList?.length > 0 && (
                                  <a href={this.state.exportUrl} target="_blank" rel="noreferrer" className="text-sm dropdown-item">
                                    <span className="pl-2 text-dark">Export Units</span>
                                  </a>
                                )}
                                <Dropdown.Item className="text-sm" onClick={() => this.ShowModal("Units")}>
                                  <span className="pl-2"> Import Units</span>
                                </Dropdown.Item>
                                <Dropdown.Item className="text-sm" onClick={() => this.ShowModal("Permits")}>
                                  <span className="pl-2"> Import Permits</span>
                                </Dropdown.Item>
                                {this.state.showImportModal && (
                                  <BulkImportModal
                                    UploadFile={this.UploadFile.bind(this)}
                                    type={this.state.importType}
                                    injectedTableData={this.state.responseTableData}
                                    modelLoader={modelLoader}
                                    closeModal={this.CloseModal.bind(this)}
                                  />
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </header>
                  </>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {!api_response && (
                        <CustomContentAlert delay={4000} message={"The server did not respond, Please try again after sometime"} className="toast-error" />
                      )}
                      {residentList && (
                        <div className="citation-list-table manage-unit-table">
                          {this.dataTableHandle(searchValue, searchedResidentList, residentList, page, Norow, pageChanged)}
                        </div>
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {confirmRemoveResidence ? (
          <DeleteResidentModal
            showBrowserModal={confirmRemoveResidence}
            details={confirmRemoveResidenceData}
            closeModal={() => this.setState({ confirmRemoveResidence: false })}
            handleSave={this.onConfrim}
            handleCancel={this.onCancel}
          />
        ) : null}
        {show_alert_on_remove_resident && <CustomContentAlert delay={4000} message={"Unit removed successfully"} className="toast-success" />}
        {show_error_on_remove_resident && (
          <CustomContentAlert delay={4000} message={"The server did not respond, Please try again after sometime I Ran"} className="toast-error" />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, ResidenDetails, Logindetails }) => {
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { PropertyId, user_type, suite_id, timezone, property, id } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { residentList, importedData } = ResidenDetails;

  return {
    api_response,
    api_error_message,
    api_success_message,
    PropertyId,
    residentList,
    user_type,
    suite_id,
    timezone,
    property,
    main_body,
    importedData,
    id,
  };
};

const mapStateToDispatch = {
  fetch_residents: ResidentsActions.fetch_residents,
  changeSuiteId: UserProfileActions.changeSuiteId,
  remove_resident: ResidentsActions.remove_resident,
  confirm_remove_resident: ResidentsActions.confirm_remove_resident,
  edit_resident: ResidentsActions.edit_resident,
  importUnitsPermitsSites: ResidentsActions.importUnitsPermitsSites,
  exportUnits: ResidentsActions.exportUnits,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(Residents);

import { AddTimeInDate, convertDateTimeToUtc } from "../Components/helper";
import moment from "moment";

export const findStartDateAndEndDateForAllReports = async (pageObj, withTime) => {
  try {
    let startDate = "";
    let endDate = "";
    let start_date_without_utc = "";
    let end_date_without_utc = "";
    console.log("pageObje:::::::::::::::::::::::", pageObj);
    if (pageObj.year) {
      const startOfYear = moment(pageObj.year).clone().startOf("year").format("YYYY-MM-DD HH:mm:ss");
      const endOfYear = moment(pageObj.year).clone().endOf("year").format("YYYY-MM-DD HH:mm:ss");
      const { startcurrent_time, endcurrent_time } = convertDateTimeToUtc(pageObj.timezone, startOfYear, endOfYear);
      startDate = startcurrent_time;
      endDate = endcurrent_time;
      start_date_without_utc = startOfYear;
      end_date_without_utc = endOfYear;
    }

    if (pageObj.startDate && pageObj.endDate) {
      let { startTimeFormat, EndTimeFormat } = AddTimeInDate(pageObj.startDate, pageObj.endDate);
      if (withTime) {
        startTimeFormat = moment(pageObj.startDate).format("YYYY-MM-DD HH:mm:ss");
        EndTimeFormat = moment(pageObj.endDate).format("YYYY-MM-DD HH:mm:ss");
      }
      start_date_without_utc = startTimeFormat;
      end_date_without_utc = EndTimeFormat;
      const { startcurrent_time, endcurrent_time } = convertDateTimeToUtc(pageObj.timezone, startTimeFormat, EndTimeFormat);
      startDate = startcurrent_time;
      endDate = endcurrent_time;
    }

    if (pageObj.currentSummaryDate) {
      const { startTimeFormat, EndTimeFormat } = AddTimeInDate(pageObj.currentSummaryDate, pageObj.currentSummaryDate);
      start_date_without_utc = startTimeFormat;
      end_date_without_utc = EndTimeFormat;
      const { startcurrent_time, endcurrent_time } = convertDateTimeToUtc(pageObj.timezone, startTimeFormat, EndTimeFormat);
      startDate = startcurrent_time;
      endDate = endcurrent_time;
    }
    if (pageObj.month) {
      const startOfMonth = moment(pageObj.month).clone().startOf("month").format("YYYY-MM-DD HH:mm:ss");
      const endOfMonth = moment(pageObj.month).clone().endOf("month").format("YYYY-MM-DD HH:mm:ss");
      const { startcurrent_time, endcurrent_time } = convertDateTimeToUtc(pageObj.timezone, startOfMonth, endOfMonth);
      startDate = startcurrent_time;
      endDate = endcurrent_time;
      start_date_without_utc = startOfMonth;
      end_date_without_utc = endOfMonth;
    }
    return { startDate, endDate, start_date_without_utc, end_date_without_utc };
  } catch (error) {
    console.log("Error in findStartDateAndEndDateForAllReports", error);
  }
};

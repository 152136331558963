import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import LoginPage from "../Components/LoginPage/LoginPage";
import ConfirmPassword from "../Components/LoginPage/ConfirmPassword";
import Footer from "../Components/Footer";
import Privacy from "../Components/PrivacyPolicy/Privacy";
import RegisterWithPin from "../Components/SelfRegister/RegisterWithPin";
import PrivacyFrench from "../Components/PrivacyPolicy/PrivacyFrench";
import PhotoScanGlass from "../Components/PhotoVerifyRemote/PhotoScanGlass";
import PhotoVerifyRemote from "../Components/PhotoVerifyRemote/PhotoVerifyRemote";
import ChangeProfile from "../Components/UserProfile/ChangeProfile";
import Unauthorized from "../Components/LoginPage/Unauthorized";
import RegisterWithPhone from "../Components/SelfRegister/RegisterWithPhone";
import ScanAgain from "../Components/SelfRegister/ScanAgain";
import SuccessPage from "../Components/SelfRegister/SuccessPage";
import SelfRegisterMainHeader from "../Components/SelfRegister/SelfRegisterMainHeader";
import { PublicRouteInPrivateList } from "./PublicRouteInPrivate";

const PublicRoutes = (props) => {
  const isHeader = () => {
    return PublicRouteInPrivateList.find((x) => props.location.pathname.indexOf(x) !== -1);
  };
  return (
    <div>
      {isHeader() && <SelfRegisterMainHeader />}
      <Switch>
        <Route exact path="/login" render={() => <LoginPage {...props} history={history} />} />
        <Route exact path="/unautorized" render={() => <Unauthorized {...props} history={history} />} />
        <Route exact path="/redirect/:username/:password" render={() => <LoginPage {...props} history={history} />} />
        <Route path="/newpassword" render={(_props) => <ConfirmPassword {..._props} />} />
        <Route exact path="/change-profile/:id" render={() => <ChangeProfile {...props} history={history} />} />
        <Route exact path="/privacy-policy" render={() => <Privacy {...props} history={history} />} />
        <Route exact path="/privacy-policy-french" render={() => <PrivacyFrench {...props} history={history} />} />

        <Route exact path="/photo-scan-glass" render={() => <PhotoScanGlass {...props} history={history} />} />

        <Route exact path="/photo-verify-remote" render={() => <PhotoVerifyRemote {...props} history={history} />} />
        <Route exact path="/change-profile/:id" render={() => <ChangeProfile {...props} history={history} />} />
        <Route exact path="/qp" render={() => <RegisterWithPin {...props} history={history} />} />
        <Route exact path="/qp/:pin" render={() => <RegisterWithPhone {...props} history={history} />} />
        <Route exact path="/scan-again" render={() => <ScanAgain {...props} />} />
        <Route exact path="/success-page" render={() => <SuccessPage {...props} />} />
        <Route render={() => (!props.isSemiLogin ? <Redirect to="/login" /> : <Redirect to="/unautorized" />)} />
      </Switch>
      {props?.location && ["/photo-verify-remote", "/photo-scan-glass"].indexOf(props.location.pathname) < 0 && (
        <div className="full-page">
          <Footer />
        </div>
      )}
    </div>
  );
};
export default withRouter(PublicRoutes);

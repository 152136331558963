import Config from "./config";
import reduxStore from "../App/redux_store/index";

export const LoginAPI = async (url, type, body) => {
  try {
    type = type.toUpperCase();
    const responseData = await fetch(`${Config.baseURL}${url}`, {
      method: type,
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Basic " + auth,
      },
      body: JSON.stringify(body),
    });
    return await responseData.json();
  } catch (error) {
    console.log("ERROR in login API", error);
    throw error;
  }
};

export const BufferAuthAPI = async (_baseURL, url, type, body_details, authToken) => {
  const Bearer_Token = JSON.stringify(authToken) || localStorage.getItem("basicAuth");
  let API_details = {};
  type = type.toUpperCase();
  if (type === "GET") {
    API_details = {
      method: type,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(Bearer_Token),
      },
    };
  } else {
    if (body_details) {
      API_details = {
        method: type,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(Bearer_Token),
        },
        body: JSON.stringify(body_details),
      };
    } else {
      API_details = {
        method: type,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(Bearer_Token),
        },
      };
    }
  }
  try {
    const resourse = `${Config.baseURL}/${url}`;
    const res = await fetch(resourse, API_details);
    return await res.arrayBuffer();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const AuthAPI = async (baseURL, url, type, body_details, authToken) => {
  try {
    const info = reduxStore.store.getState().UserProfileDetails;
    const Bearer_Token = JSON.stringify(authToken) || localStorage.getItem("basicAuth");
    type = type.toUpperCase();
    let API_details = {
      method: type,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(Bearer_Token),
      },
    };
    if (type === "GET") {
      API_details = { ...API_details };
    } else {
      if (body_details) {
        API_details = {
          ...API_details,
          body: JSON.stringify(body_details),
        };
      } else {
        API_details = { ...API_details };
      }
    }
    const resourse = `${Config.baseURL}/${url}`;

    let res;
    try {
      res = await fetch(resourse, API_details);
    } catch (e) {
      console.log(e);
      throw e;
    }

    if (info?.property?.high_security === 1) {
      if (isResponseUnauthorized(res)) {
        const appDetails = { baseURL, url, type, body_details, authToken };
        return await RefreshAuthAPI(appDetails);
      }
    }

    if (res) {
      return await res.json();
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const UnAuthAPI = async (url, type, body_details, abortControl) => {
  type = type.toUpperCase();
  let API_details = {};
  if (type === "GET") {
    API_details = {
      method: type,
      headers: { "Content-Type": "application/json" },
    };
  } else {
    if (body_details) {
      API_details = {
        method: type,
        signal: abortControl || new AbortController().signal,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body_details),
      };
    } else {
      API_details = {
        method: type,
        signal: abortControl || new AbortController().signal,
        headers: { "Content-Type": "application/json" },
      };
    }
  }
  try {
    const resourse = `${Config.baseURL}/${url}`;

    const res = await fetch(resourse, API_details);
    return await res.json();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const get_config = async () => {
  const resp = [];
  const basicAuth = localStorage.getItem("basicAuth");
  const result = "localhost";
  const url = `${Config.baseURL}/config/${result}`;
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + basicAuth,
    },
  });
  const data = await res.json();
  resp.push(data);
  return resp;
};

export const UploadFormData = async (baseURL, url, type, body_details) => {
  const Bearer_Token = localStorage.getItem("basicAuth");
  type = type.toUpperCase();
  const API_details = {
    method: type,
    headers: {
      Authorization: "Bearer " + JSON.parse(Bearer_Token),
    },
    body: body_details,
  };
  try {
    const resourse = `${Config.baseURL}/${url}`;
    const res = await fetch(resourse, API_details);
    return await res.json();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const UnAuthUploadFormData = async (url, type, body_details) => {
  type = type.toUpperCase();
  const API_details = {
    method: type,
    // headers: {
    //   Authorization: "Bearer " + JSON.parse(Bearer_Token),
    // },
    body: body_details,
  };
  try {
    const resourse = `${Config.baseURL}/${url}`;
    const res = await fetch(resourse, API_details);
    return await res.json();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

function setCrossSubdomainCookie(name, value, days) {
  try {
    const assign = name + "=" + escape(value) + ";";
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString() + ";";
    const path = "path=/;";
    const documentDomain = document.domain.match(/[^.]*\.[^.]*$/) || "";
    const domain = "domain=" + documentDomain + ";";
    document.cookie = assign + expires + path + domain;
  } catch (e) {
    console.log(e);
  }
}

export const RefreshAuthAPI = async (apiDetails) => {
  try {
    const userDetails = getActiveUserSessionDetails();
    let API_details = {};
    API_details = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userDetails),
    };
    const resourse = `${Config.baseURL}/user/check-user-session`;
    let res = await fetch(resourse, API_details);
    res = await res.json();
    if (isResponseUnauthorized(res)) {
      manageLocalAndSessionStorageOnLogout();
      return false;
    } else {
      manageLocalAndSessionStorageDuringActiveSession(res);
      if (apiDetails) {
        const { baseURL, url, type, body_details, authToken } = apiDetails;
        let body;
        if (body_details) {
          body = body_details;
        } else {
          body = "";
        }
        return await AuthAPI(baseURL, url, type, body, authToken);
      }
    }
    return await res.json();
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

const isResponseUnauthorized = (res) => {
  let value = false;
  if (
    res.statusText === "Unauthorized" ||
    res.status === 401 ||
    res.message === "Unauthorized user" ||
    res.message === "authentication failed" ||
    res.success === false ||
    res.message === "jwt expired"
  ) {
    value = true;
  }
  return value;
};
const manageLocalAndSessionStorageOnLogout = () => {
  if (localStorage.getItem("basicAuth")) localStorage.removeItem("basicAuth");
  localStorage.clear();
  setCrossSubdomainCookie("basicAuth", null, -1);
  if (localStorage.getItem("basicRefreshAuth")) localStorage.removeItem("basicRefreshAuth");
  setCrossSubdomainCookie("basicRefreshAuth", null, -1);
  window.location.reload(true);
  return true;
};

const manageLocalAndSessionStorageDuringActiveSession = (res) => {
  localStorage.setItem("basicAuth", JSON.stringify(res.token));
  setCrossSubdomainCookie("basicAuth", res.token, 365);
  return true;
};

const getActiveUserSessionDetails = () => {
  const info = reduxStore.store.getState().UserProfileDetails;
  const sessi = localStorage.getItem("basicRefreshAuth");
  return {
    user_id: info.id,
    user_type: info.user_type,
    username: info.username,
    property_id: info.property_id,
    timezone: info.timezone,
    session_id: JSON.parse(sessi),
  };
};

import React from "react";
import ScanAgain from "../Components/SelfRegister/ScanAgain";
import RegisterWithPhone from "../Components/SelfRegister/RegisterWithPhone";
import RegisterWithPin from "../Components/SelfRegister/RegisterWithPin";
import SuccessPage from "../Components/SelfRegister/SuccessPage";
import { Route } from "react-router-dom";
import SelfRegister from "../Components/SelfRegister/SelfRegister";

export const PublicRouteInPrivateList = [
  "/qp",
  "/scan-again",
  "/success-page",
  "/self-register",
];

const PublicRouteInPrivate = (props) => {
  return (
    <>
      <Route exact path="/qp" render={() => <RegisterWithPin {...props} />} />
      <Route
        exact
        path="/qp/:pin"
        render={() => <RegisterWithPhone {...props} />}
      />
      <Route exact path="/scan-again" render={() => <ScanAgain {...props} />} />
      <Route
        exact
        path="/success-page"
        render={() => <SuccessPage {...props} />}
      />
      <Route
        exact
        path="/self-register"
        render={() => <SelfRegister {...props} />}
      />
    </>
  );
};

export default PublicRouteInPrivate;

import React, { Component } from "react";
import { Card, Container, Row, Col, Button, Tabs, Tab, Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Loader from "../Loader/Loader";
import { connect } from "react-redux";
import { PlateSearchActions, SuperAdminAction } from "../../Actions";
import { findBySearch } from "../helper";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { SRLWrapper } from "simple-react-lightbox";
import PaginationforData from "../pagination/pagination";
import moment from "moment";
import NoImage from "../../../assets/img/No-Image.svg";

class CurrentOccupancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPlate: [],
      currentPlateSearch: [],
      currentPlateCitation: [],
      loader: false,
      plate_holder: "",
      searchValue: "",
      searchValues: "",
      searchedPlateList: [],
      PlateList: [],
      errorMsg: true,
      api_response: false,
      prevSearch: "",
      prevPage: "",
      prevNorow: "",
      prevSearchTab2: "",
      prevPageTab2: "",
      prevNorowTab2: "",
      selectedTab: "Tab1",
      tab2Page: 1,
      tab2Norow: 100,
      page: 1,
      Norow: 100,
      selectedColumn: "created_at",
      asc: "false",
      sort_direction: "DESC",
      pageChanged: false,
      ariaSelectedA: false,
      findMore: false,
      count: 0,
      count2: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      lpn: "",
      isPlateApproved: "",
      image: "",
      plateData: "",
      scan_time: 0,
      lastEntry: "",
      property_id: this.props.property_id,
      units: [],
      unit_id: "",
      permit_type: "",
    };
    this.options = {
      buttons: {
        showAutoplayButton: false,
        showCloseButton: true,
        showDownloadButton: false,
        showFullscreenButton: false,
        showNextButton: true,
        showPrevButton: true,
        showThumbnailsButton: false,
      },
      ProgressBar: {
        showProgressBar: false,
      },
      thumbnails: {
        showThumbnails: false,
      },
    };
    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    const checkUnit = (suite_id) => {
      const history = require("../../history").default;
      history.push({
        pathname: `/units`,
        search: "search",
        state: {
          search: suite_id,
        },
      });
    };

    const currentStatusRow = (row) => <div className="text-nowrap" dangerouslySetInnerHTML={{ __html: row.active }}></div>;
    const permitClassRow = (row) => <div dangerouslySetInnerHTML={{ __html: row.permit_class }}></div>;
    const createTimeRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{moment(row.created_at).format("lll")}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{moment(row.created_at).format("lll")}</div>
      </OverlayTrigger>
    );
    const modifyTimeRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{moment(row.updated_at).format("lll")}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{moment(row.updated_at).format("lll")}</div>
      </OverlayTrigger>
    );
    const beginTimeRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.begin_time}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row.begin_time}</div>
      </OverlayTrigger>
    );
    const expiryTimeRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.expire_time}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row.expire_time}</div>
      </OverlayTrigger>
    );
    const untiIdRow = (row) => (
      <div className="curserPointer" onClick={() => checkUnit(row.suite_id)}>
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.suite_id}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-1" style={{ width: "100px" }}>
            {row.suite_id}
          </div>
        </OverlayTrigger>
      </div>
    );
    const lotNameRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.lot_name}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row.lot_name}</div>
      </OverlayTrigger>
    );

    const lpnRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.lpn}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.lpn}</div>
      </OverlayTrigger>
    );
    const dectivationTimeRow = (row) => (
      <>
        {row.deactivated_at ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.deactivated_at}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{row.deactivated_at}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </>
    );
    const deactivationReasonRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.deactivation_reason}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.deactivation_reason}</div>
      </OverlayTrigger>
    );
    const firstNameRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.first_name}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.first_name}</div>
      </OverlayTrigger>
    );
    const emailRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.email}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.email}</div>
      </OverlayTrigger>
    );
    const lastNameRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.last_name}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.last_name}</div>
      </OverlayTrigger>
    );
    const phoneRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.phone}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.phone}</div>
      </OverlayTrigger>
    );
    const permitAmountRow = (row) => (
      <>
        {row?.paid_amount ? (
          <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-qw`}>${parseFloat(row?.paid_amount).toFixed(2)}</Tooltip>}
          >
            <div className="text-nowrap text-truncate pr-2">${parseFloat(row?.paid_amount).toFixed(2)}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </>
    );
    this.columns2 = [
      {
        name: "Current Status",
        sortable: true,
        cell: currentStatusRow,
        selector: (row) => row.active,
      },
      {
        name: "Permit Class",
        sortable: true,
        cell: permitClassRow,
        selector: (row) => row.permit_class,
      },
      {
        name: "CreateTime",
        width: "160px",
        sortable: true,
        cell: createTimeRow,
        selector: (row) => row.created_at_date_format,
      },
      {
        name: "ModifyTime",
        width: "160px",
        sortable: true,
        cell: modifyTimeRow,
        selector: (row) => row.updated_at_date_format,
      },
      {
        name: "BeginTime",
        width: "160px",
        sortable: true,
        cell: beginTimeRow,
        selector: (row) => row.begin_time_date_format,
      },
      {
        name: "ExpiryTime",
        width: "160px",
        sortable: true,
        cell: expiryTimeRow,
        selector: (row) => row.expire_time_date_format,
      },
      {
        name: "Unit Id",
        width: "120px",
        sortable: true,
        cell: untiIdRow,
        selector: (row) => row.suite_id,
      },
      {
        name: "Lot Name",
        width: "120px",
        sortable: true,
        cell: lotNameRow,
        selector: (row) => row.lot_name,
      },
      {
        name: "LPN",
        width: "120px",
        sortable: true,
        cell: lpnRow,
        selector: (row) => row?.lpn,
      },
      {
        name: "Permit Amount ($)",
        sortable: true,
        width: "160px",
        cell: permitAmountRow,
        selector: (row) => row?.phone,
      },
      {
        name: "Deactivation Time",
        sortable: true,
        width: "150px",
        cell: dectivationTimeRow,
        selector: (row) => row?.deactivated_at_date_format,
      },
      {
        name: "Deactivation Reason",
        width: "160px",
        sortable: true,
        cell: deactivationReasonRow,
        selector: (row) => row?.deactivation_reason,
      },
      {
        name: "FirstName",
        width: "150px",
        sortable: true,
        cell: firstNameRow,
        selector: (row) => row?.first_name,
      },
      {
        name: "LastName",
        width: "150px",
        sortable: true,
        cell: lastNameRow,
        selector: (row) => row?.last_name,
      },
      {
        name: "Phone",
        width: "150px",
        sortable: true,
        cell: phoneRow,
        selector: (row) => row?.phone,
      },

      {
        name: "Email",
        width: "150px",
        sortable: true,
        cell: emailRow,
        selector: (row) => row?.email,
      },
    ];

    const imageRow = (row) => {
      const alt = this.extractContent(row.light_box_link);
      return (
        <a href={row.car_image_link || row.plate || NoImage}>
          <img
            src={row.plate || row.car_image_link ? row.plate || row.car_image_link : NoImage}
            className="img-fluid no-image-available"
            alt={alt}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = NoImage;
            }}
          />
        </a>
      );
    };

    const attemptRow = (row) => <div>{row?.id}</div>;
    const directionRow = (row) => <div dangerouslySetInnerHTML={{ __html: row?.entry_or_exit }}></div>;
    const resultRow = (row) => <div dangerouslySetInnerHTML={{ __html: row?.validation }}></div>;
    const onRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.created_at}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.created_at}</div>
      </OverlayTrigger>
    );
    const laneRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.lane_name || "-"}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.lane_name || "-"}</div>
      </OverlayTrigger>
    );
    const plateRow = (row) => (
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.camera_direction}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.camera_direction}</div>
      </OverlayTrigger>
    );
    this.columns1 = [
      {
        name: "IMAGE",
        sortable: true,
        width: "80px",
        cell: imageRow,
        selector: (row) => row.plate,
      },
      {
        name: "SEQUENCE",
        sortable: true,
        width: "100px",
        cell: attemptRow,
        selector: (row) => row?.id,
      },
      {
        name: "LPR TIME",
        sortable: true,
        width: "150px",
        cell: onRow,
        selector: (row) => moment(row?.created_at),
      },
      {
        name: "Plate",
        sortable: true,
        width: "120px",
        cell: plateRow,
        selector: (row) => row?.camera_direction,
      },
      {
        name: "Lane",
        sortable: true,
        width: "150px",
        cell: laneRow,
        selector: (row) => row?.lane_name,
      },
      {
        name: "Direction",
        sortable: true,
        width: "120px",
        cell: directionRow,
        selector: (row) => row?.entry_or_exit,
      },
      {
        name: "STATUS",
        sortable: true,
        width: "150px",
        cell: resultRow,
        selector: (row) => row?.validation,
      },
    ];
  }

  extractContent(s) {
    const span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  async componentDidMount() {
    const selectedPropertyName = this.state.property?.property_name;
    const selectedDomainName = this.state.property?.domain;
    this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    await this.props.get_all_units(this.props.property_id);
    if (this.props.location?.state) {
      const image = this.props.location.state.image;
      const plateData = this.props.location.state.plateData;
      const scan_time = this.props.location.state.scan_time;
      const isPlateApproved = this.props.location.state.isPlateApproved;
      const lastEntry = this.props.location.state.lastEntry;
      this.setState(
        {
          plate_holder: this.props.location.state.lpn,
          units: this.props.units,
          lpn: this.props.location.state.lpn,
          isPlateApproved: isPlateApproved,
          image: image,
          plateData: plateData,
          scan_time: scan_time,
          lastEntry: lastEntry,
        },
        () => {
          this.plateCheck();
        },
      );
    } else if (this.props?.location?.search) {
      const searchValue = this.props.location.search?.split("&");
      console.log("searchValue::::::::::::::::::::::", searchValue);
      if (searchValue[0] === "?audit-report") {
        this.setState(
          {
            plate_holder: searchValue[1],
            lpn: searchValue[1],
            units: this.props.units,
          },
          () => {
            this.plateCheck();
          },
        );
      }
    }
  }

  handleChange = (event) => {
    this.setState({
      plate_holder: event.target.value
        .trim()
        .toUpperCase()
        .replace(/[^\w\s]/gi, ""),
      errorMsg: false,
    });
    if (!event.target.value) {
      this.plateCheck();
    }
    this.setState({
      errorMsg: true,
      loader: false,
      count: this.props.currentPlateSearch.count,
      currentPlateSearch: [],
      currentPlateCitation: [],
    });
  };

  handleSubmit = () => {
    this.plateCheck();
  };

  plateCheck = () => {
    this.setState({ loader: true }, async () => {
      const { page, Norow, searchValue, selectedColumn, sort_direction, plate_holder, unit_id, permit_type } = this.state;
      const pageobj = {
        plate_holder,
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
      };
      if (unit_id) {
        pageobj.unit_id = unit_id;
      }
      if (permit_type) {
        pageobj.permit_type = permit_type;
      }
      await this.props.FetchingPlateCitations(plate_holder);
      await this.props.FetchingPlate(pageobj);
      await this.props.plateFinder(pageobj);
      this.setState({
        errorMsg: true,
        loader: false,
        count: this.props?.currentPlateSearch?.count,
        currentPlateSearch: this.props?.currentPlateSearch?.data,
        currentPlateCitation: this.props?.currentPlateCitation?.data,
        api_response: this.props?.api_response,
        currentPlate: this.props?.currentPlate?.data,
        count2: this.props?.currentPlate?.count,
      });
    });
    document.body.classList.remove("foc-open");
  };

  handleSearchChange = ({ target }) => {
    this.setState({ prevSearchTab2: this.state.searchValue });
    this.setState({ searchValue: target.value }, () => {
      if (!this.state.findMore) {
        if (this.state.searchValue.length) {
          this.setState(
            {
              pageChanged: true,

              searchedPlateList: findBySearch(this.state.searchValues, this.props.currentPlateSearch.data, this.props.currentPlateSearch.data),

              PlateList: findBySearch(this.state.searchValue, this.props.currentPlate.data, this.props.currentPlate.data),
            },
            () => {
              this.setState({
                count: this.state.searchedPlateList.length,
                count2: this.state.PlateList.length,
              });
            },
          );
        } else {
          this.setState({ loader: true, pageChanged: true }, async () => {
            const { tab2Page, tab2Norow, searchValue, page, Norow, searchValues, selectedColumn, sort_direction, plate_holder } = this.state;
            const pageobj = {
              plate_holder,
              page,
              Norow,
              searchValue: searchValues,
              selectedColumn,
              sort_direction,
            };
            const pageobjtab2 = {
              plate_holder,
              page: tab2Page,
              Norow: tab2Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            await this.props.FetchingPlate(pageobj);
            await this.props.plateFinder(pageobjtab2);
            this.setState({
              pageChanged: true,
              page: 1,
              searchedPlateList: this.props.currentPlateSearch.data,
              PlateList: this.props.currentPlate.data,
              loader: false,
            });
          });
          setTimeout(() => {
            this.setState({ findMore: false });
          }, 1500);
        }
      }
    });
  };

  handleSearchChanges = ({ target }) => {
    this.setState({ prevSearch: this.state.searchValues });
    this.setState({ searchValues: target.value }, () => {
      if (!this.state.findMore) {
        if (this.state.searchValues.length) {
          this.setState(
            {
              pageChanged: true,
              searchedPlateList: findBySearch(this.state.searchValues, this.props.currentPlateSearch.data, this.props.currentPlateSearch.data),
              PlateList: findBySearch(this.state.searchValue, this.props.currentPlate.data, this.props.currentPlate.data),
            },
            () => {
              this.setState({
                count: this.state.searchedPlateList.length,
                count2: this.state.PlateList.length,
              });
            },
          );
        } else {
          this.setState({ loader: true, pageChanged: true }, async () => {
            const { tab2Page, tab2Norow, searchValue, page, Norow, searchValues, selectedColumn, sort_direction, plate_holder } = this.state;
            const pageobj = {
              plate_holder,
              page,
              Norow,
              searchValue: searchValues,
              selectedColumn,
              sort_direction,
            };
            const pageobjtab2 = {
              plate_holder,
              page: tab2Page,
              Norow: tab2Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            await this.props.FetchingPlate(pageobj);
            await this.props.plateFinder(pageobjtab2);
            this.setState({
              pageChanged: true,
              page: 1,
              searchedPlateList: this.props.currentPlateSearch.data,
              PlateList: this.props.currentPlate.data,
              loader: false,
            });
          });
          setTimeout(() => {
            this.setState({ findMore: false });
          }, 1500);
        }
      }
    });
  };

  handlepage = ({ target }) => {
    if (this.state.selectedTab !== "Tab2") {
      this.setState({ prevPage: this.state.page, prevNorow: this.state.Norow });
      this.setState({ [target.name]: target.value }, async () => {
        switch (target.name) {
          case "page":
            this.setState({ page: target.value }, async () => {
              const { page, Norow, searchValues, selectedColumn, sort_direction, plate_holder } = this.state;
              const pageobj = {
                page,
                Norow,
                searchValue: searchValues,
                selectedColumn,
                sort_direction,
                plate_holder,
              };
              this.setState({ loader: true, pageChanged: true }, async () => {
                await this.props.FetchingPlate(pageobj);
                this.setState({
                  searchedPlateList: await this.props.currentPlateSearch.data,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
          case "Norow":
            this.setState({ Norow: target.value }, async () => {
              const { page, Norow, searchValues, selectedColumn, sort_direction, plate_holder } = this.state;
              const pageobj = {
                page,
                Norow,
                searchValue: searchValues,
                selectedColumn,
                sort_direction,
                plate_holder,
              };
              this.setState({ loader: true, pageChanged: true }, async () => {
                await this.props.FetchingPlate(pageobj);
                this.setState({
                  searchedPlateList: await this.props.currentPlateSearch.data,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
        }
        if (Number(this.state.page) !== Number(this.state.prevPage) || Number(this.state.Norow) !== Number(this.state.prevNorow)) {
          const { page, Norow, searchValues, selectedColumn, sort_direction, plate_holder } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue: searchValues,
            selectedColumn,
            sort_direction,
            plate_holder,
          };
          this.setState({ loader: true, pageChanged: true }, async () => {
            await this.props.FetchingPlate(pageobj);
            this.setState({
              searchedPlateList: await this.props.currentPlateSearch.data,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        }
      });
    } else {
      this.setState({
        prevPageTab2: this.state.tab2Page,
        prevNorowTab2: this.state.tab2Norow,
      });
      let targetName;
      if (target.name === "Norow") {
        targetName = "tab2Norow";
      } else {
        targetName = "tab2Page";
      }
      this.setState({ [targetName]: target.value }, async () => {
        switch (targetName) {
          case "tab2Page":
            this.setState({ tab2Page: target.value }, async () => {
              const { tab2Page, tab2Norow, searchValue, selectedColumn, sort_direction, plate_holder } = this.state;
              const pageobjTab2 = {
                page: tab2Page,
                Norow: tab2Norow,
                searchValue,
                selectedColumn,
                sort_direction,
                plate_holder,
              };
              this.setState({ loader: true, pageChanged: true }, async () => {
                await this.props.plateFinder(pageobjTab2);
                this.setState({
                  PlateList: await this.props.currentPlate.data,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
          case "tab2Norow":
            this.setState({ tab2Norow: target.value }, async () => {
              const { tab2Page, tab2Norow, searchValue, selectedColumn, sort_direction, plate_holder } = this.state;
              const pageobjTab2 = {
                page: tab2Page,
                Norow: tab2Norow,
                searchValue,
                selectedColumn,
                sort_direction,
                plate_holder,
              };
              this.setState({ loader: true, pageChanged: true }, async () => {
                await this.props.plateFinder(pageobjTab2);
                this.setState({
                  PlateList: await this.props.currentPlate.data,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
        }
        if (Number(this.state.tab2Page) !== Number(this.state.prevPageTab2) || Number(this.state.tab2Norow) !== Number(this.state.prevNorowTab2)) {
          const { tab2Page, tab2Norow, searchValue, selectedColumn, sort_direction, plate_holder } = this.state;
          const pageobjTab2 = {
            page: tab2Page,
            Norow: tab2Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            plate_holder,
          };
          this.setState({ loader: true, pageChanged: true }, async () => {
            await this.props.plateFinder(pageobjTab2);
            this.setState({
              PlateList: await this.props.currentPlate.data,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        }
      });
    }
  };

  handlepagenext = async () => {
    if (this.state.selectedTab !== "Tab2") {
      const { page, Norow } = this.state;
      const count = await this.props.currentPlateSearch.count;
      const finalPage = Math.ceil(count / Norow);
      if (page + 1 <= finalPage) {
        this.setState({ page: page + 1 }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction, plate_holder } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            plate_holder,
          };
          this.setState({ loader: true, pageChanged: true }, async () => {
            await this.props.FetchingPlate(pageobj);
            this.setState({
              searchedPlateList: await this.props.currentPlateSearch.data,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        });
      }
    } else {
      const { tab2Page, tab2Norow } = this.state;
      const count = await this.props.currentPlate.count;
      const finalPage = Math.ceil(count / tab2Norow);
      if (tab2Page + 1 <= finalPage) {
        console.log("nextpage click");
        this.setState({ tab2Page: tab2Page + 1 }, async () => {
          const { tab2Page, tab2Norow, searchValue, selectedColumn, sort_direction, plate_holder } = this.state;
          const pageobjTab2 = {
            page: tab2Page,
            Norow: tab2Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            plate_holder,
          };
          this.setState({ loader: true, pageChanged: true }, async () => {
            await this.props.plateFinder(pageobjTab2);
            this.setState({
              PlateList: await this.props.currentPlate.data,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        });
      }
    }
  };

  handlepageprevious = () => {
    if (this.state.selectedTab !== "Tab2") {
      const { page } = this.state;
      if (page !== 1) {
        this.setState({ page: page - 1, pageChanged: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction, plate_holder } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            plate_holder,
          };
          this.setState({ loader: true }, async () => {
            await this.props.FetchingPlate(pageobj);
            this.setState({
              searchedPlateList: await this.props.currentPlateSearch.data,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        });
      }
    } else {
      const { tab2Page } = this.state;
      if (tab2Page !== 1) {
        this.setState({ tab2Page: tab2Page - 1, pageChanged: true }, async () => {
          const { tab2Page, tab2Norow, searchValue, selectedColumn, sort_direction, plate_holder } = this.state;
          const pageobjTab2 = {
            page: tab2Page,
            Norow: tab2Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            plate_holder,
          };
          this.setState({ loader: true }, async () => {
            await this.props.plateFinder(pageobjTab2);
            this.setState({
              PlateList: await this.props.currentPlate.data,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        });
      }
    }
  };

  handlepageinitial = () => {
    if (this.state.selectedTab !== "Tab2") {
      if (this.state.page !== 1) {
        this.setState({ page: 1, pageChanged: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction, plate_holder } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            plate_holder,
          };
          this.setState({ loader: true }, async () => {
            await this.props.FetchingPlate(pageobj);
            this.setState({
              searchedPlateList: await this.props.currentPlateSearch.data,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        });
      }
    } else {
      if (this.state.tab2Page !== 1) {
        this.setState({ tab2Page: 1, pageChanged: true }, async () => {
          const { tab2Page, tab2Norow, searchValue, selectedColumn, sort_direction, plate_holder } = this.state;
          const pageobjTab2 = {
            page: tab2Page,
            Norow: tab2Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            plate_holder,
          };
          this.setState({ loader: true }, async () => {
            await this.props.plateFinder(pageobjTab2);
            this.setState({
              PlateList: await this.props.currentPlate.data,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        });
      }
    }
  };

  handlepagefinal = async () => {
    console.log("nextpagefinal click");

    if (this.state.selectedTab !== "Tab2") {
      const { page, Norow } = this.state;
      const count = await this.props.currentPlate.count;
      const last_page = count / Norow;
      const rounded = Math.ceil(last_page);
      if (page <= rounded) {
        this.setState({ page: rounded }, () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction, plate_holder } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            plate_holder,
          };
          this.setState({ loader: true, pageChanged: true }, async () => {
            await this.props.FetchingPlate(pageobj);
            this.setState({
              searchedPlateList: await this.props.currentPlateSearch.data,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        });
      }
    } else {
      const { tab2Page, tab2Norow } = this.state;
      const count = await this.props.currentPlate.count;
      const last_page = count / tab2Norow;
      const rounded = Math.ceil(last_page);
      if (tab2Page <= rounded) {
        this.setState({ tab2Page: rounded }, () => {
          const { tab2Page, tab2Norow, searchValue, selectedColumn, sort_direction, plate_holder } = this.state;
          const pageobjTab2 = {
            page: tab2Page,
            Norow: tab2Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            plate_holder,
          };
          this.setState({ loader: true, pageChanged: true }, async () => {
            await this.props.plateFinder(pageobjTab2);
            this.setState({
              PlateList: await this.props.currentPlate.data,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        });
      }
    }
  };

  resetAll = async () => {
    this.setState({
      unit_id: "",
      permit_type: "",
    });
  };

  findMorePressed = () => {
    this.setState({ findMore: true }, () => {
      if (this.state.findMore) {
        this.setState({ loader: true, pageChanged: true }, async () => {
          const { tab2Page, tab2Norow, searchValue, page, Norow, searchValues, selectedColumn, sort_direction, plate_holder } = this.state;
          const pageobj = {
            plate_holder,
            page,
            Norow,
            searchValue: searchValues,
            selectedColumn,
            sort_direction,
          };
          const pageobjtab2 = {
            plate_holder,
            page: tab2Page,
            Norow: tab2Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          await this.props.FetchingPlate(pageobj);
          await this.props.plateFinder(pageobjtab2);
          this.setState({
            pageChanged: true,
            page: 1,
            searchedPlateList: this.props.currentPlateSearch.data,
            PlateList: this.props.currentPlate.data,
            loader: false,
          });
        });
        setTimeout(() => {
          this.setState({ findMore: false });
        }, 1500);
      }
    });
  };

  redirectToPlatecheck = () => {
    const history = require("../../history").default;
    if (this.props.location.search.includes("?audit-report") || this.props.location.search === "") {
      history.push({
        pathname: `/audit-report`,
      });
    } else if (this.props.location.search === "?verify") {
      history.push({
        pathname: `/plate-check`,
        state: {
          lpn: this.state.lpn,
          isPlateApproved: this.state.isPlateApproved,
          image: this.state.image,
          plateData: this.state.plateData,
          scan_time: this.state.scan_time,
          lastEntry: this.state.lastEntry,
        },
      });
    }
  };

  handleTab = (tab) => {
    this.setState({
      selectedTab: tab === "permit" ? "Tab1" : "Tab2",
    });
  };

  handleChangeUnit = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  render() {
    const {
      plate_holder,
      loader,
      // errorMsg,
      searchValues,
      currentPlateSearch,
      searchedPlateList,
      page,
      Norow,
      pageChanged,
      // api_response,
      searchValue,
      PlateList,
      currentPlate,
      tab2Page,
      tab2Norow,
      unit_id,
      permit_type,
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="get-height">
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <Row className="align-items-center">
                    <Col md="4" xs="12">
                      <Card.Title as="h4">
                        <i
                          style={{ marginRight: 5, cursor: "pointer" }}
                          className="fa fa-arrow-left"
                          onClick={this.redirectToPlatecheck}
                          aria-hidden="true"
                        ></i>
                        <span> Plate Audit</span>
                      </Card.Title>
                      <p className="card-category">License Plate Audit history.</p>
                    </Col>
                    <Col md="8" xs="12">
                      <Row className="justify-content-md-end align-item-end">
                        <Col md="8" lg="9" xs="12" sm="12" className="d-md-flex align-items-center justify-content-end">
                          {/* <Form.Group> */}
                          <label className="text-nowrap mr-3">Enter License Plate Number</label>
                          <div className="search-container">
                            <div className="position-relative">
                              <input
                                type="text"
                                name="plate_holder"
                                placeholder={"Enter License Plate Number"}
                                value={plate_holder}
                                onChange={this.handleChange}
                                autoComplete="off"
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                          {/* </Form.Group> */}
                        </Col>
                        <Col md="4" lg="3" xs="12" sm="12" className="mt-md-0 mt-2">
                          <Button
                            className={"search-btn custom-btn-secondary text-nowrap btn-sm-new mt-md-0"}
                            onClick={this.handleSubmit}
                            disabled={!plate_holder?.trim().length}
                          >
                            Plate Check
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <div>
                    <Tabs defaultActiveKey="permit" id="controlled-tab-example" onSelect={this.handleTab}>
                      <Tab eventKey="permit" title="Permit" className="m-0">
                        <>
                          <div className="sidebarFilter">
                            <Card className="contact-container mb-0">
                              <Card.Header style={{ backgroundColor: this.props.main_body }}>
                                <div className="d-flex align-items-center justify-content-between">
                                  <Card.Title as="h4">Permits</Card.Title>
                                  <a
                                    onClick={this.hideSlider}
                                    href="javascript:void(0);"
                                    className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                                  >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                  </a>
                                </div>
                              </Card.Header>
                              <Card.Body>
                                <Row>
                                  <Col md="12">
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                      <Form.Label>Unit:</Form.Label>
                                      <Form.Control
                                        as="select"
                                        name="unit_id"
                                        value={unit_id}
                                        onChange={this.handleChangeUnit}
                                        disabled={this.props.units.length < 1}
                                        placeholder="Select Unit"
                                      >
                                        {this.props?.units?.length ? (
                                          <>
                                            <option value="">Any</option>
                                            <option value="ParkingPermit">Parking Permit</option>
                                            {this.props.units.map((item, index) => (
                                              <option key={item.id} value={item.suite_id}>
                                                {item.suite_id}
                                              </option>
                                            ))}
                                          </>
                                        ) : null}
                                      </Form.Control>
                                    </Form.Group>
                                  </Col>
                                  <Col md="12">
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                      <Form.Label>Permit Type</Form.Label>
                                      <Form.Control
                                        as="select"
                                        name="permit_type"
                                        value={permit_type}
                                        onChange={this.handleChangeUnit}
                                        placeholder="Select Permit Type"
                                      >
                                        <option value="">All</option>
                                        <option value="Resident">Standard</option>
                                        <option value="Visitor">Visitor</option>
                                        <option value="PassagePass">PassagePass</option>
                                        <option value="Kiosk">Kiosk</option>
                                        <option value="Grace">Grace</option>
                                        <option value="Validation">Validation</option>
                                        <option value="PermitPackage">PermitPackage</option>
                                      </Form.Control>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Card.Body>
                              <Card.Footer className="text-right justify-content-between d-flex">
                                <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                                  Reset
                                </Button>
                                <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSubmit} disabled={loader}>
                                  Search
                                </Button>
                              </Card.Footer>
                            </Card>
                          </div>
                        </>
                        {loader ? (
                          <Loader />
                        ) : (
                          <div className="citation-list-table">
                            <header className="border-0">
                              <div className="active-permit-header sm-flex-column sm-align-items-start py-2 px-3 mb-0">
                                <h5 className="mb-2 mb-md-0"></h5>
                                <div className="filter-button d-flex flex-wrap search-filter-hide">
                                  <div className="d-flex align-items-center search-mb-0">
                                    <DatatableHeader
                                      className="mt-0 mb-0"
                                      searchValue={searchValue}
                                      HandleChange={this.handleSearchChange}
                                      // findMorePressed={this.findMorePressed}
                                    />
                                  </div>
                                </div>
                              </div>
                            </header>
                            <div className="citation-list-table has-parent-tab plate-audit-table shadow-none border-0">
                              <DataTable
                                data={searchValues.length || pageChanged ? searchedPlateList : currentPlateSearch}
                                columns={this.columns2}
                                customStyles={this.customStyles}
                                // pagination={true}
                                paginationServer={true}
                                responsive={true}
                                // sortServer={true}
                                // onSort={handleonsort}
                                // subHeader
                                subHeaderComponent={
                                  <>
                                    <DatatableHeader
                                      searchValue={searchValues}
                                      HandleChange={this.handleSearchChanges}
                                      findMorePressed={this.findMorePressed}
                                    />
                                    {/* <Button onClick={this.findMorePressed}>
                                    Get All Result for {searchValues}
                                  </Button> */}
                                  </>
                                }
                                defaultSortAsc={true}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="40vh"
                              />
                              <PaginationforData
                                count={this.state.count}
                                data={this.state.currentPlateSearch}
                                handlepageinitial={this.handlepageinitial}
                                handlepageprevious={this.handlepageprevious}
                                handlepagenext={this.handlepagenext}
                                handlepagefinal={this.handlepagefinal}
                                handlepage={this.handlepage}
                                page={page}
                                Norow={Norow}
                                pageChanged={pageChanged}
                              />
                            </div>
                          </div>
                        )}
                      </Tab>
                      <Tab eventKey="lpn" title="LPR">
                        <>
                          {loader ? (
                            <Loader />
                          ) : (
                            <SRLWrapper options={this.options}>
                              <div className="citation-list-table list-of-ravenue-table has-parent-tab">
                                <DataTable
                                  data={searchValue.length || pageChanged ? PlateList : currentPlate}
                                  columns={this.columns1}
                                  customStyles={this.customStyles}
                                  // pagination={true}
                                  paginationServer={true}
                                  // sortServer={true}
                                  // onSort={handleonsort}
                                  responsive={true}
                                  // subHeader
                                  subHeaderComponent={
                                    <>
                                      <DatatableHeader searchValue={searchValue} HandleChange={this.handleSearchChange} />
                                      {/* <Button onClick={this.findMorePressed}>
                        Get All Result for {searchValue}
                      </Button> */}
                                    </>
                                  }
                                  defaultSortAsc={true}
                                  fixedHeader={true}
                                  fixedHeaderScrollHeight="40vh"
                                />
                                <PaginationforData
                                  count={this.state.count2}
                                  data={this.state.currentPlate}
                                  findMorePressed={this.findMorePressed}
                                  handlepageinitial={this.handlepageinitial}
                                  handlepageprevious={this.handlepageprevious}
                                  handlepagenext={this.handlepagenext}
                                  handlepagefinal={this.handlepagefinal}
                                  handlepage={this.handlepage}
                                  page={tab2Page}
                                  Norow={tab2Norow}
                                  pageChanged={pageChanged}
                                />
                              </div>
                            </SRLWrapper>
                          )}
                        </>
                      </Tab>
                    </Tabs>
                    {this.state.currentPlateCitation &&
                    this.state.currentPlateCitation.length > 0 &&
                    this.state.currentPlateCitation.filter((item) => item?.ticket_no !== null)?.length !== 0 ? (
                      <Row className="ml-2">
                        Citations Found
                        {this.state.currentPlateCitation
                          .filter((item) => item.ticket_no !== null)
                          .map((item) => {
                            return (
                              <div
                                key={item.ticket_no}
                                className="curserPointer"
                                onClick={() => {
                                  const history = require("../../history").default;
                                  history.push({
                                    pathname: `/citation-list`,
                                    search: "lpn_citation",
                                    state: {
                                      ticket_no: item.ticket_no,
                                    },
                                  });
                                }}
                              >
                                <div className="d-flex align-items-center ml-1">
                                  <span>{item.ticket_no}</span>
                                </div>
                              </div>
                            );
                          })}
                      </Row>
                    ) : null}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ PlateSearchDetails, APIResponseDetails, SuperAdminDetails, UserProfileDetails, Logindetails }) => {
  const { api_response, api_error_message } = APIResponseDetails;
  const { currentPlate, currentPlateSearch, currentPlateCitation } = PlateSearchDetails;
  const { user_type, timezone, property, property_id } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { getAllUserlist: units } = SuperAdminDetails;
  return {
    api_response,
    api_error_message,
    currentPlate,
    currentPlateSearch,
    currentPlateCitation,
    user_type,
    timezone,
    property,
    main_body,
    property_id,
    units,
  };
};
const mapStateToDispatch = {
  plateFinder: PlateSearchActions.plateFinder,
  FetchingPlate: PlateSearchActions.FetchingPlate,
  FetchingPlateCitations: PlateSearchActions.FetchingPlateCitations,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  get_all_units: SuperAdminAction.get_all_units,
};
export default connect(mapStateToProps, mapStateToDispatch)(CurrentOccupancy);

import React, { Component } from "react";
import { Card, Container, Row, Col, OverlayTrigger, Tooltip, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { SuperAdminAction } from "../../Actions";
import Loader from "../Loader/Loader";
import { findBySearch, scroolToTop, tableHeightScrollBars, utcToSpecificTimeZone } from "../helper";
import { highSecurityActionList } from "./highSecurityActionsList";
import { highSecurityPageList } from "./highSecurityPageList";
import PropertDetails from "../LandingPage/PropertySelection";
import DatePicker from "react-datepicker";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import isEqual from "lodash/isEqual";
import moment from "moment";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";

class ActivityLog extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.state = {
      loader: false,
      searchValue: "",
      count: 0,
      finalTableHeight: 0,
      activityList: [],
      childcheckbox: true,
      startDate: new Date(datebeforeThirtyDays),
      endDate: endDate,
      all_properties: [],
      searchedActivityList: [],
      selectedProperty: "",
      selectedPropertyObj: {},
      PropertyId: this.props.PropertyId,
      timezone: this.props.timezone,
      startDateTime: "",
      endDateTime: "",
    };
    this.customStyles = {
      table: {
        style: {
          maxHeight: "100% !important",
        },
      },
      rows: {
        style: {
          minHeight: "initial !important",
          maxHeight: "initial !important",
          height: "auto !important", // override the row height
          padding: "10px 0 !important",
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          height: "100%",
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };
    this.columns = [
      {
        name: "Time",
        sortable: true,
        width: "150px",
        cell: (row) => this.showTimeField(row),
        selector: (row) => row?.created_at,
      },
      {
        name: "User",
        sortable: true,
        width: "150px",
        cell: (row) => this.showUserField(row),
        selector: (row) => row?.user,
      },
      {
        name: "Site",
        sortable: true,
        width: "150px",
        cell: (row) => this.showSiteField(row),
        selector: (row) => row["properties.site_name"],
      },
      {
        name: "Page",
        sortable: true,
        width: "200px",
        cell: (row) => this.showPageField(row),
        selector: (row) => row?.page,
      },
      {
        name: "Action",
        width: "200px",
        cell: (row) => this.showActionField(row),
        selector: (row) => row?.action,
      },
      {
        name: "Status",
        sortable: true,
        width: "200px",
        cell: (row) => this.showStatusField(row),
        selector: (row) => row?.status,
      },
    ];
  }

  expandedComponent = ({ data }) => {
    return (
      <div className="extend-row">
        <div className="d-flex flex-column"> {data.action_additional_details ? this.showDetails(data) : "-"}</div>
      </div>
    );
  };

  showStatusField = (row) => {
    return row.status && row.status === "Success" ? <div className="text-success">{row.status}</div> : <div className="text-danger">{row.status}</div>;
  };

  showActionField = (row) => (
    <>
      {row.action ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{highSecurityActionList[row.action]}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{highSecurityActionList[row.action]}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showPageField = (row) => (
    <>
      {row.page ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{highSecurityPageList[row.page]}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{highSecurityPageList[row.page]}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showSiteField = (row) => (
    <>
      {" "}
      {row["properties.site_name"] ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row["properties.site_name"]}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row["properties.site_name"]}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showUserField = (row) => (
    <>
      {row.action_by_name ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.action_by_name}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.action_by_name}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showTimeField = (row) => (
    <>
      {" "}
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement={"top"}
        overlay={<Tooltip id={`tooltip-qw`}>{row.created_at ? utcToSpecificTimeZone(this.props.timezone, row.created_at, true) : "-"}</Tooltip>}
      >
        <div className="text-nowrap text-truncate pr-2">{row.created_at ? utcToSpecificTimeZone(this.props.timezone, row.created_at, true) : "-"}</div>
      </OverlayTrigger>
    </>
  );

  componentDidMount = async () => {
    await this.props.get_all_properties();
    this.setState(
      {
        loader: true,
      },
      async () => {
        const { PropertyId, childcheckbox, timezone, startDate, endDate } = this.state;

        const pageobj = {
          PropertyId,
          childcheckbox,
          timezone,
        };
        if (startDate) {
          pageobj.startDate = startDate;
        }
        if (endDate) {
          pageobj.endDate = endDate;
        }
        await this.props.getUsersActivityLogs(pageobj);
        await this.GetDateRangeWithFormat(pageobj);
        const selectedPropertyName = this.props.property?.property_name;
        const selectedDomainName = this.props.property?.domain;
        await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
        this.setState(
          {
            activityList: this.props?.user_activity_logs?.rows?.length ? JSON.parse(JSON.stringify(this.props.user_activity_logs.rows)) : [],
            all_properties: this.props?.getAllProperties,
            api_response: this.props.api_response,
            count: this.props.user_activity_logs.count,
          },
          () => {
            const selectedPropertyObj = (this.state.all_properties ?? []).find((item) => item.id === PropertyId);
            if (selectedPropertyObj) {
              this.setState({
                selectedPropertyObj: {
                  label: selectedPropertyObj.site_name,
                  value: selectedPropertyObj.id,
                },
                selectedProperty: PropertyId,
                selectedDomainName: selectedPropertyObj.site_name,
                selectedPropertyName: selectedPropertyObj.domain,
              });
            }
            const height = tableHeightScrollBars();
            this.setState({
              finalTableHeight: height,
              loader: false,
            });
          },
        );
      },
    );
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.user_activity_logs, this.props.user_activity_logs)) {
      try {
        const activityList = JSON.parse(JSON.stringify(this.props.user_activity_logs.rows));
        this.setState({
          activityList: activityList,
          count: this.props?.user_activity_logs?.rows?.length,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  convertKey = (key) => {
    const ketToValue = {
      parking_spot: "Parking Note",
      ip_addr: "Ip address",
      entry_cam: "Camera at entry",
      waitlist_order: "Wait List Position",
    };
    return ketToValue[key] ? ketToValue[key] : this.capitalizeFirstLetter(key);
  };

  capitalizeFirstLetter = (string) => {
    const str = string.replaceAll("_", " ");
    return str.replaceAll("_", " ").charAt(0).toUpperCase() + str.slice(1);
  };

  sessionDetailShow = (heading, obj) => {
    const rows = Object.entries(obj);
    return (
      <>
        {heading && <h5>{heading}</h5>}
        <table>
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {(rows ?? []).map(([key, value]) => (
              <tr key={key}>
                <td>{key.replace(/_/g, " ")}</td>
                <td>{key === "session_id" ? JSON.stringify(value) : utcToSpecificTimeZone(this.props.timezone, value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  addDetailsShow = (heading, obj) => {
    const rows = Object.entries(obj);
    return (
      <>
        {heading && <h5>{heading}</h5>}
        <table>
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {(rows ?? []).map(([key, value]) => (
              <tr key={key}>
                <td>{this.capitalizeFirstLetter(key).replace(/_/g, " ")}</td>
                <td>{value ? value : "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  updateDetailShow = (heading, obj) => {
    return (
      <>
        {heading && <h5>{heading}</h5>}
        {Object.keys(obj).length > 0 && (
          <table style={{ tableLayout: "auto", fontSize: "13px" }} className="table">
            <thead>
              <tr style={{ borderBottom: "1px solid black" }}>
                <th className="text-secondary font-weight-bold">Updated Columns</th>
                <th className="text-secondary font-weight-bold">Old</th>
                <th className="text-secondary font-weight-bold">New</th>
              </tr>
            </thead>
            {Object.keys(obj).map((x) => (
              <tr key={x}>
                <td>
                  <b>{this.convertKey(x)}</b>
                </td>
                <td>{this.handleActivityOldValue(obj, x)}</td>
                {/* <td>{obj[x].new_value?.toString()}</td> */}
                <td>{this.additionalDetailDescription(obj, x)}</td>
              </tr>
            ))}
          </table>
        )}
      </>
    );
  };

  handleActivityOldValue = (obj, x) => {
    if (Array.isArray(obj[x].old_value)) {
      if (obj[x].old_value?.length === 0) {
        return "-";
      } else {
        return JSON.stringify(obj[x].old_value);
      }
    } else {
      return obj[x].old_value?.toString() === "true" ? 1 : obj[x].old_value?.toString();
    }
  };

  additionalDetailDescription = (obj, x) => {
    if (Array.isArray(obj[x].new_value)) {
      if (obj[x]?.new_value?.length === 0) {
        return "-";
      } else {
        const obb = obj[x].new_value.map((item) =>
          Object.keys(item).map((value) => {
            return `${item[value]}`;
          }),
        );
        return obb.flat().join(",");
      }
    } else {
      if (x === "expire_time") {
        return utcToSpecificTimeZone(this.props.timezone, obj[x].new_value, true).toString();
      } else if (x === "status") {
        if (obj[x].new_value === "current") {
          return "Active";
        }
      } else {
        return obj[x].new_value?.toString() === "true" ? 1 : obj[x].new_value?.toString();
      }
    }
  };

  displayCopySettings = (heading, arr) => {
    return (
      <>
        {heading && <h5>{heading}</h5>}
        {arr.map((x) => this.capitalizeFirstLetter(x)).join(", ")}
      </>
    );
  };

  arr1 = [
    "LOGIN",
    "ADD_UNIT",
    "UPDATE_UNIT",
    "REMOVE_UNIT",
    "ADD_PERMIT",
    "ADD_QUICK_PERMIT",
    "UPDATE_PERMIT",
    "DEACTIVATE_PERMIT",
    "CWH_ACTIVATED",
    "CWH_DEACTIVATED",
    "SCAN_PLATE",
    "PLATE_CHECK",
    "PLATE_AUDIT_CHECK_LPR",
    "PLATE_AUDIT_CHECK_PERMITS",
    "PLATE_SEND_MAIL_PERMIT",
    "ADD_CITATION",
    "CLOSE_CITATION",
    "REOPEN_CITATION",
    "SAVE_CITATION_PDF",
    "SAVE_CITATION_PNG",
    "SEND_PDF_PNG_MAIL_CITATION",
    "VIEW_LAST_CITATION",
    "UPDATE_TICKET_STATUS",
    "ADD_LANE",
    "UPDATE_LANE",
    "SESSION_START",
    "SESSION_END",
    "START_END_SESSION",
    "GENERATE_COUPONS",
  ];

  arr2 = [
    "DELETE_LANE",
    "ADD_LOT",
    "UPDATE_LOT",
    "DELETE_LOT",
    "ADD_EDGE_DEVICE",
    "ADD_METER_DEVICE",
    "UPDATE_EDGE_DEVICE",
    "UPDATE_METER_DEVICE",
    "DELETE_EDGE_DEVICE",
    "DELETE_METER_DEVICE",
    "ADD_CAMERA",
    "UPDATE_CAMERA",
    "DELETE_CAMERA",
    "ADD_PROPERTY",
    "UPDATE_PROPERTY",
    "DELETE_PROPERTY",
    "ADD_USER",
    "UPDATE_USER",
    "DELETE_USER",
    "OPEN_STREAM",
    "CLOSE_STREAM",
    "COPY_SETTING",
    "UPDATE_PERMISSIONS",
    "SEND_CHAT_MSG",
    "PASSAGEPASS_PERMIT_HISTORY",
    "ADD_PASSAGEPASS_PAYMENT_TRANSACTION",
    "INSERT_PASSAGEPASS_PERMIT_HISTORY",
    "UPDATE_PASSAGEPASS_PERMIT_HISTORY",
    "UPDATE_PERMIT_PACKAGES",
  ];

  arr3 = ["WAITLIST_POSITION_CHANGE", "ADD_PERMIT_PACKAGES"];

  showDetails = (actionObj) => {
    let activityDetails;
    const action = actionObj.action;
    const actionDetailsData = actionObj.action_additional_details ? JSON.parse(actionObj.action_additional_details) : {};
    if (actionDetailsData && Object.keys(actionDetailsData).length) {
      if (this.arr1.includes(action)) {
        activityDetails = this.handleArrayOneActivitySwitch(action, actionDetailsData);
      }
      if (this.arr2.includes(action)) {
        activityDetails = this.handleArrayTwoActivitySwitch(action, actionDetailsData);
      }
      if (this.arr3.includes(action)) {
        activityDetails = this.handleArrayThreeActivitySwitch(action, actionDetailsData);
      }
    }
    return activityDetails;
  };

  handleArrayThreeActivitySwitch = (action, actionDetailsData) => {
    let activityDetails;
    switch (action) {
      case "WAITLIST_POSITION_CHANGE":
        activityDetails = this.updateDetailShow(
          `Update the waitlist position for username: ${actionDetailsData?.user_name} and LPN: ${actionDetailsData?.lpn}`,
          actionDetailsData.update_details,
        );
        break;
      case "ADD_PERMIT_PACKAGES":
        activityDetails = this.addDetailsShow("Permit Package", actionDetailsData);
        break;
      default:
        activityDetails = "-";
    }
    return activityDetails;
  };

  handleArrayTwoActivitySwitch = (action, actionDetailsData) => {
    let activityDetails;
    switch (action) {
      case "DELETE_LANE":
        activityDetails = `Delete Lane - ${actionDetailsData.alias} `;
        break;
      case "ADD_LOT":
        activityDetails = `Add Lot - Alias: ${actionDetailsData.alias}`;
        break;
      case "UPDATE_LOT": {
        activityDetails = this.updateDetailShow(`Updated lot - ${actionDetailsData.alias}`, actionDetailsData.update_details);
        break;
      }
      case "DELETE_LOT":
        activityDetails = `Delete Lot - ${actionDetailsData.alias} `;
        break;
      case "ADD_EDGE_DEVICE":
        activityDetails = `Add edge device - Device Name: ${actionDetailsData.device_name}, Mac Id: ${actionDetailsData.mac_id}`;
        break;
      case "ADD_METER_DEVICE":
        activityDetails = `Add meter device - ${actionDetailsData?.server_id} `;
        break;
      case "UPDATE_EDGE_DEVICE":
        activityDetails = this.updateDetailShow(`Updated edge device - ${actionDetailsData.device_name}`, actionDetailsData.update_details);
        break;
      case "UPDATE_METER_DEVICE":
        activityDetails = this.updateDetailShow(`Updated meter device - ${actionDetailsData?.server_id}`, actionDetailsData?.update_details);
        break;
      case "DELETE_EDGE_DEVICE":
        activityDetails = `Delete edge device - ${actionDetailsData.device_name} `;
        break;
      case "DELETE_METER_DEVICE":
        activityDetails = `Delete meter device - ${actionDetailsData.server_id} `;
        break;
      case "ADD_CAMERA":
        activityDetails = `Add Camera - ${actionDetailsData.ip_address} `;
        break;
      case "UPDATE_CAMERA":
        activityDetails = this.updateDetailShow(`Updated camera ${actionDetailsData.ip_address}`, actionDetailsData.update_details);
        break;
      case "DELETE_CAMERA":
        activityDetails = `Delete Camera - ${actionDetailsData.ip_address} `;
        break;
      case "ADD_PROPERTY":
        activityDetails = `Add Property - ${actionDetailsData.site_name} `;
        break;
      case "UPDATE_PROPERTY":
        activityDetails = `Update Settings - ${actionDetailsData.site_name} `;
        break;
      case "DELETE_PROPERTY":
        activityDetails = `Delete Property - ${actionDetailsData.site_name} `;
        break;
      case "ADD_USER":
        activityDetails = `Added User - ${actionDetailsData.username} `;
        break;
      case "UPDATE_USER":
        activityDetails = this.updateDetailShow("Updated user", actionDetailsData.update_details);
        break;
      case "DELETE_USER":
        activityDetails = `Deleted User - ${actionDetailsData.username} `;
        break;
      case "OPEN_STREAM":
        activityDetails = `Open Stream alias - ${actionDetailsData.alias} `;
        break;
      case "CLOSE_STREAM":
        activityDetails = `Close Stream alias - ${actionDetailsData.alias} `;
        break;
      case "COPY_SETTING":
        activityDetails = this.displayCopySettings(`Copy settings from  ${actionDetailsData.site_name}`, actionDetailsData.settings);
        break;
      case "UPDATE_PERMISSIONS":
        activityDetails = this.updateDetailShow("Updated Permissions", actionDetailsData);
        break;
      case "UPDATE_PERMIT_PACKAGES":
        activityDetails = this.updateDetailShow("Updated Permit Package", actionDetailsData.update_details);
        break;
      case "PASSAGEPASS_PERMIT_HISTORY":
        activityDetails = `PassagePass Permit History Insert Update - ${actionDetailsData.message}`;
        break;
      case "ADD_PASSAGEPASS_PAYMENT_TRANSACTION":
        activityDetails = `Add PassagePass Payment Transaction - ${actionDetailsData.message}`;
        break;
      case "INSERT_PASSAGEPASS_PERMIT_HISTORY":
        activityDetails = `Insert PassagePass Permit History - ${actionDetailsData.message}`;
        break;
      case "UPDATE_PASSAGEPASS_PERMIT_HISTORY":
        activityDetails = `Update PassagePass Permit History - ${actionDetailsData.message}`;
        break;
      case "SEND_CHAT_MSG":
        activityDetails = `Send chat message - ${actionDetailsData.ticket_no} `;
        break;
      default:
        activityDetails = "-";
    }

    return activityDetails;
  };

  handleArrayOneActivitySwitch = (action, actionDetailsData) => {
    let activityDetails;
    switch (action) {
      case "ADD_UNIT":
        activityDetails = `Added Unit - ${actionDetailsData.suite_id}`;
        break;
      case "LOGIN":
        activityDetails = `${actionDetailsData.message}`;
        break;
      case "UPDATE_UNIT":
        activityDetails = this.updateDetailShow(`Updated unit - ${actionDetailsData.suite_id}`, actionDetailsData.update_details);
        break;
      case "REMOVE_UNIT":
        activityDetails = `Deleted Unit - ${actionDetailsData.suite_id}`;
        break;
      case "ADD_PERMIT":
        activityDetails = `Added Permit - ${actionDetailsData.permit_tag}`;
        break;
      case "ADD_QUICK_PERMIT":
        activityDetails = `Added Quick Permit - ${actionDetailsData.permit_tag}`;
        break;
      case "UPDATE_PERMIT":
        activityDetails = this.updateDetailShow(`Updated Permit - ${actionDetailsData.permit_tag}`, actionDetailsData.update_details);
        break;
      case "DEACTIVATE_PERMIT":
        activityDetails = `Deactivated Permit - ${actionDetailsData.permit_tag}`;
        break;
      case "CWH_ACTIVATED":
        activityDetails = `CWH Activated - ${actionDetailsData.permit_tag}`;
        break;
      case "CWH_DEACTIVATED":
        activityDetails = `CWH Deactivated - ${actionDetailsData.permit_tag}`;
        break;
      case "SCAN_PLATE":
        activityDetails = `Scan Plate - ${actionDetailsData.lpn}`;
        break;
      case "PLATE_CHECK":
        activityDetails = `Plate Check - ${actionDetailsData.lpn}`;
        break;
      case "PLATE_AUDIT_CHECK_LPR":
        activityDetails = `Plate Audit - ${actionDetailsData.lpn}`;
        break;
      case "PLATE_AUDIT_CHECK_PERMITS":
        activityDetails = `Plate Audit - ${actionDetailsData.lpn}`;
        break;
      case "PLATE_SEND_MAIL_PERMIT":
        activityDetails = `Send Permit email for plate - ${actionDetailsData.lpn}`;
        break;
      case "ADD_CITATION":
        activityDetails = `Added Citation - ${actionDetailsData.ticket_no}`;
        break;
      case "CLOSE_CITATION":
        activityDetails = `Close Citation - ${actionDetailsData.ticket_no}`;
        break;
      case "REOPEN_CITATION":
        activityDetails = `Reopen Citation - ${actionDetailsData.ticket_no}`;
        break;
      case "SAVE_CITATION_PDF":
        activityDetails = `Save Citation Pdf - ${actionDetailsData.ticket_no}`;
        break;
      case "SAVE_CITATION_PNG":
        activityDetails = `Save Citation Png - ${actionDetailsData.ticket_no}`;
        break;
      case "SEND_PDF_PNG_MAIL_CITATION":
        activityDetails = `Send Citation Mail - ${actionDetailsData.ticket_no}`;
        break;
      case "VIEW_LAST_CITATION":
        activityDetails = `View Last Citation - ${actionDetailsData.ticket_no}`;
        break;
      case "SESSION_START":
        activityDetails = this.sessionDetailShow(`Offline Citation`, actionDetailsData);
        break;
      case "SESSION_END":
        activityDetails = this.sessionDetailShow(`Offline Citation`, actionDetailsData);
        break;
      case "START_END_SESSION":
        activityDetails = `Session Action Error- ${actionDetailsData.message}`;
        break;
      case "UPDATE_TICKET_STATUS":
        activityDetails = `Update Ticket Status For - ${actionDetailsData.ticket_no} `;
        break;
      case "ADD_LANE":
        activityDetails = `Add Lane - ${actionDetailsData.alias} `;
        break;
      case "UPDATE_LANE":
        activityDetails = this.updateDetailShow(`Updated lane : ${actionDetailsData.alias}`, actionDetailsData.update_details);
        break;
      case "ADD_PERMIT_OPTIONS":
        activityDetails = `Add Permit Options`;
        break;
      case "GENERATE_COUPONS":
        activityDetails = this.addDetailsShow("Generate Coupons", actionDetailsData);
        break;
      default:
        activityDetails = "-";
    }
    return activityDetails;
  };

  resetAll = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 59);
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState({
      childcheckbox: true,
      startDate: new Date(datebeforeThirtyDays),
      endDate: endDate,
      selectedPropertyObj: {
        label: this.props.property?.domain,
        value: this.props.PropertyId,
      },
    });
  };

  handleCheckBox = () => {
    this.setState({ childcheckbox: !this.state.childcheckbox });
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleSearch = () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, childcheckbox, timezone, startDate, endDate, selectedPropertyObj } = this.state;

      const pageobj = {
        childcheckbox,
        PropertyId,
        timezone,
      };
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }

      if (startDate && endDate) {
        pageobj.startDate = startDate;
        pageobj.endDate = endDate;
      }
      await this.props.getUsersActivityLogs(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      this.setState(
        {
          activityList: this.props?.user_activity_logs?.rows?.length ? JSON.parse(JSON.stringify(this.props.user_activity_logs.rows)) : [],
          api_response: this.props.api_response,
          count: this.props.user_activity_logs?.length,
        },
        () => {
          this.setState({ loader: false });
        },
      );
      document.body.classList.remove("foc-open");
    });
  };

  changeProperty(event) {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 90);
    this.setState(
      {
        selectedProperty: event.value,
        selectedPropertyObj: event,
        startDate: new Date(datebeforeThirtyDays),
        endDate: new Date(),
      },
      () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === this.state.selectedProperty);
        this.props.selected_Property(selectedPropertyObj);
      },
    );
  }

  GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj);
    this.setState({
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    });
  };

  handleChangeStartingDates = (date) => {
    const selectedStartDate = moment(date);
    const currentDate = moment();
    const calculatedEndDate = selectedStartDate.clone().add(3, "months");
    const endDate = calculatedEndDate.isAfter(currentDate) ? currentDate : calculatedEndDate;
    endDate.set({ hour: 23, minute: 59, second: 59 });
    this.setState({
      startDate: new Date(selectedStartDate),
      endDate: new Date(endDate),
      maxEndDate: new Date(endDate),
    });
  };

  handleChangeEndDates = (date) => {
    let endDate = date;
    this.setState({
      endDate: new Date(endDate),
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ searchValue: val }, async () => {
      const columnList = ["created_at", "page", "action", "status", "user"];
      if (this.state.searchValue.length) {
        this.setState(
          {
            searchedActivityList: findBySearch(this.state.searchValue, this.state.activityList, this.state.activityList, columnList, this.props.timezone),
          },
          () => {
            console.log("ssssssss", this.state.searchedActivityList, this.state.searchedActivityList.length);
            this.setState({ count: this.state.searchedActivityList.length });
          },
        );
      } else {
        this.setState({
          count: this.props.searchedActivityList?.length,
          searchedActivityList: [],
          activityList: this.props.user_activity_logs?.rows,
        });
      }
    });
  };

  dataTableHandle = (searchValue, searchedActivityList, activityList) => {
    return (
      <DataTable
        data={searchValue?.length ? searchedActivityList : activityList}
        expandableRows
        expandableRowsComponent={this.expandedComponent}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  render() {
    const { loader, searchValue, activityList, searchedActivityList, maxEndDate, startDate, endDate, childcheckbox } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="mb-0">
                <Card.Body className="pos-rel-overflow-hide">
                  <>
                    <div className="sidebarFilter">
                      <Card className="contact-container mb-0">
                        <Card.Header style={{ backgroundColor: this.props.main_body }}>
                          <div className="d-flex align-items-center justify-content-between">
                            <Card.Title as="h4">Activity Logs</Card.Title>
                            <a
                              onClick={this.hideSlider}
                              href="javascript:void(0);"
                              className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            <Col md="12">
                              <div className="mb-2">
                                <label className="fancy-checkbox">
                                  <input
                                    type="checkbox"
                                    name={"childcheckbox"}
                                    checked={childcheckbox}
                                    onChange={this.handleCheckBox}
                                    className="custom-control-input"
                                    id="childcheckbox1"
                                  ></input>
                                  <span>
                                    <i></i>Include Children Sites
                                  </span>
                                </label>
                              </div>
                            </Col>
                            <Col md="12">
                              <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">Start Date(Date Range):</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Start Date"
                                  selected={startDate}
                                  value={startDate}
                                  disabled={loader}
                                  selectsStart
                                  onChange={this.handleChangeStartingDates}
                                  dateFormat="MM/dd/yyyy"
                                  startDate={startDate}
                                  endDate={endDate}
                                  maxDate={new Date()}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <div class="d-flex align-items-center">
                                  <label className="mb-0 mr-2">End Date(Date Range):</label>
                                </div>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select End Date"
                                  selected={endDate}
                                  disabled={loader}
                                  value={endDate}
                                  selectsEnd
                                  onChange={this.handleChangeEndDates}
                                  dateFormat="MM/dd/yyyy"
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={startDate}
                                  maxDate={maxEndDate}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer className="text-right justify-content-between d-flex">
                          <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                            Reset
                          </Button>
                          <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                            Search
                          </Button>
                        </Card.Footer>
                      </Card>
                    </div>
                    <header>
                      <div className="active-permit-header sm-flex-column sm-align-items-start">
                        <h5 className="mb-2 mb-md-0">List of Activity Logs</h5>
                        <div className="filter-button d-flex flex-wrap">
                          <div className="d-flex align-items-center search-mb-0">
                            <DatatableHeader
                              className="mt-0 mb-0"
                              searchValue={searchValue}
                              HandleChange={this.handleSearchChange}
                              // findMorePressed={this.findMorePressed}
                            />
                          </div>
                          <div className="ml-2"></div>
                        </div>
                      </div>
                    </header>
                  </>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {activityList && <div className="activity-log-table citation-list-table">{this.dataTableHandle(searchValue, searchedActivityList, activityList)}</div>}
                      <DateRangeAndAmount startDateTime={this.state.startDateTime} endDateTime={this.state.endDateTime} />
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStatetoProps = ({ SuperAdminDetails, APIResponseDetails, UserProfileDetails }) => {
  const { user_activity_logs, getAllProperties } = SuperAdminDetails;

  const { property_id: PropertyId, timezone, property } = UserProfileDetails;
  const { api_response } = APIResponseDetails;
  return { api_response, PropertyId, user_activity_logs, timezone, property, getAllProperties };
};
const mapStateToDispatch = {
  getUsersActivityLogs: SuperAdminAction.getUsersActivityLogs,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  selected_Property: SuperAdminAction.selected_Property,
  get_all_properties: SuperAdminAction.get_all_properties,
};

export default connect(mapStatetoProps, mapStateToDispatch)(ActivityLog);

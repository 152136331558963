import React, { Component } from "react";
import { Button, Card, Container, Row, Col, Form, FormLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { ResidentsActions, SuperAdminAction } from "../../Actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomContentAlert, {
  routing_using_history,
  isEmailValid,
  email_validation_message,
  validation_message,
  validations,
  regExForPhone,
  PasswordValidation,
} from "../helper";
import moment from "moment";

class EditResident extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editedResident: null,
      ResidentId: this.props.location.search.split("?")[1] ? this.props.location.search.split("?")[1] : "",
      suite_id: "",
      parking_spot: "",
      permits_allowed: "",
      username: "",
      first_name: "",
      last_name: "",
      country_code: "",
      phone: "",
      // email: "",
      do_not_allow_login: false,
      password: "",
      user_type: "",
      isFormValid: false,
      validSuiteId: true,
      validParkingSpot: true,
      validFirstName: true,
      validLastName: true,
      validPhone: true,
      do_not_allow_visitor: false,
      default_credits: false,
      unlimited_credits: false,
      validPermitAllowed: true,
      validUser: true,
      show_alert: false,
      api_success_message: "",
      api_error_message: "",
      show_error: false,
      property: this.props.property,
      phoneErrorMessage: "",
      passwordErrorMsg: "",
      edit_unit_email: false,
      edit_unit_username: false,
      passwordVisible: false,
      allow_quick_permit: false,
      validationTimeType: "HOURS",
      validationTimeInHours: "",
      selectedTime: "",
      country_code_list: [
        { id: 1, text: "+1", value: "+1" },
        { id: 2, text: "+91", value: "+91" },
        { id: 3, text: "+44", value: "+44" },
        { id: 4, text: "+61", value: "+61" },
      ],
      // phone_options: [],
      deleted_phone_id: [],
      defalutLanguage: "",
      isSetMaxTime: false,
    };
  }

  componentDidMount = async () => {
    const { ResidentId } = this.state;
    await this.props.select_edit_resident(ResidentId);
    const selectedPropertyName = this.state.property?.property_name;
    const selectedDomainName = this.state.property?.domain;
    this.props.setDomainHeader(selectedPropertyName, selectedDomainName);

    this.setState({ editedResident: this.props.editedResident, isFormValid: true }, () => {
      this.settingData();
    });
  };

  settingData = async () => {
    const { editedResident } = this.state;
    if (editedResident) {
      const {
        suite_id,
        parking_spot,
        permits_allowed,
        username,
        first_name,
        last_name,
        country_code,
        phone,
        email,
        do_not_allow_login,
        user_type,
        phone_options,
        properties,
        language,
        invoice_visitor_credits,
        hst_applied,
        validation_time,
        allow_quick_permits,
        validation_time_value,
        cost,
      } = editedResident;
      this.setState({
        suite_id,
        parking_spot,
        permits_allowed,
        username,
        first_name,
        last_name,
        country_code,
        phone,
        email,
        do_not_allow_login,
        do_not_allow_visitor: permits_allowed === 0,
        default_credits: permits_allowed === 1,
        unlimited_credits: permits_allowed === 2,
        user_type,
        phone_options,
        edit_unit_email: properties?.edit_unit_email,
        edit_unit_username: properties?.edit_unit_username,
        defalutLanguage: language,
        invoice_visitor_credits: invoice_visitor_credits,
        hst_applied: hst_applied,
        validationTimeType: validation_time,
        allow_quick_permit: allow_quick_permits,
        cost: cost,
      });
      console.log("validation_timeaaa", validation_time);
      if (validation_time === "HOURS") {
        this.setState({
          validationTimeInHours: validation_time_value,
        });
      } else {
        let setMaxtime = false;
        const timeValue = validation_time_value && moment(new Date(validation_time_value)).format("HH:mm") || "";
        if (timeValue === "23:59") {
          setMaxtime = true;
        }
        this.setState({
          selectedTime: validation_time_value && new Date(validation_time_value) || "",
          isSetMaxTime: setMaxtime,
        });
      }
    }
  };

  handleMaxTime = (e) => {
    if (!this.state.isSetMaxTime) {
      this.setState({
        selectedTime: new Date(new Date().setHours(23, 59)),
        isSetMaxTime: !this.state.isSetMaxTime,
      });
    } else {
      this.setState({
        selectedTime: "",
        isSetMaxTime: !this.state.isSetMaxTime,
      });
    }
  };

  handlePhoneOptions = (index, value, type) => {
    const { phone_options } = this.state;
    let phone_values;
    let country_code_values;

    if (type === "phone_number_options") {
      phone_values = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      phone_options[index].phone = phone_values;
    }
    if (type === "country_code_options") {
      country_code_values = value;
      phone_options[index].country_code = country_code_values;
    }

    this.setState(
      {
        phone_options: [...phone_options],
      },
      async () => {
        this.handlephoneOptionValidation();
      },
    );
  };

  removePhoneOption = (index) => {
    const { phone_options, deleted_phone_id } = this.state;
    console.log("index", index);

    const deleted_ID = phone_options[index];

    console.log("deleted_ID:::", deleted_ID.id);

    this.setState(
      {
        phone_options: [...phone_options.filter((_x, i) => i !== index)],
      },
      async () => {
        if (deleted_ID.id) {
          this.setState({
            deleted_phone_id: [...deleted_phone_id, deleted_ID],
          });
        }
        this.handlephoneOptionValidation();
      },
    );
  };

  handleAddPhoneOptions = () => {
    console.log("handleAddPhoneOptions called::---");
    const { phone_options } = this.state;

    this.setState(
      {
        phone_options: [...phone_options, { country_code: "+1", phone: "" }],
      },
      async () => {
        this.handlephoneOptionValidation();
      },
    );
  };

  handlephoneOptionValidation = () => {
    const { first_name, last_name, phone, password, username, permits_allowed, email } = this.state;
    let isValidPhone = true;
    let passwordValue = true;
    const high_security = this.props.property.high_security;
    if ((phone && phone.length >= 11) || (phone && phone.length < 10)) {
      this.setState({
        phoneErrorMessage: "Please enter valid phone number",
      });
      isValidPhone = false;
    } else {
      this.setState({
        phoneErrorMessage: "",
      });
    }

    if (high_security) {
      const errMsg = PasswordValidation(password);
      this.setState({
        passwordErrorMsg: errMsg,
      });
      if (errMsg) {
        passwordValue = false;
        this.setState({
          passwordErrorMsg: errMsg,
        });
      } else {
        this.setState({
          passwordErrorMsg: "",
        });
      }
    }
    const name = { first_name, last_name };
    this.checkAllValidations(phone, username, name, isEmailValid(email), permits_allowed, isValidPhone, passwordValue);
  };

  isMultiplePhoneValid = () => {
    const { phone_options, phone, country_code } = this.state;
    const phoneListCount = {};
    const phoneList = (phone_options ?? []).map((item) => {
      return item.country_code + item.phone;
    });
    const phoneNumberCountry_code = country_code === "" ? "+1" : country_code;
    const phoneNumber = phone === "" ? "" : phone;
    phoneList.push(phoneNumberCountry_code + phoneNumber);
    phoneList.forEach(function (x) {
      phoneListCount[x] = (phoneListCount[x] || 0) + 1;
    });
    const phoneListStatus = Object.keys(phoneListCount).filter((item) => phoneListCount[item] > 1);

    const validPhone = (phone_options ?? []).map((options) => {
      if (options?.phone?.trim().length > 10 || options?.phone?.trim().length < 10 || options?.phone?.trim().length === 0 || phoneListStatus?.length) {
        return !(options?.phone?.trim().length > 10 || options?.phone?.trim().length < 10 || options?.phone?.trim().length === 0 || phoneListStatus?.length);
      }
      return true;
    });
    return !validPhone.includes(false);
  };

  handleChange = ({ target }) => {
    const prevstate = this.state.phone;
    const prevCost = this.state.cost;
    const prevValidationTime = this.state.validationTimeInHours;
    const high_security = this.props.property.high_security;
    let passwordValue = true;
    this.setState(
      {
        [target.name]: target.value,
      },
      async () => {
        const { country_code, phone, email, first_name, last_name, username, permits_allowed } = this.state;
        this.handleOnchangeCountryCodeAndPhone(target, prevstate, phone, country_code);

        const FirstName = validations(target.name, "first_name", this.state.first_name);
        this.setState({
          first_name: FirstName.value,
          validFirstName: FirstName.validator,
        });

        const LastName = validations(target.name, "last_name", this.state.last_name);
        this.setState({
          last_name: LastName.value,
          validLastName: LastName.validator,
        });

        const Phone = validations(target.name, "phone", this.state.phone);
        this.setState({
          phone: Phone.value,
          validPhone: Phone.validator,
        });

        const PermitsAllowed = validations(target.name, "permits_allowed", this.state.permits_allowed);
        this.setState({
          permits_allowed: PermitsAllowed.value.toString().replace(/[^0-9 ]/g, ""),
          validPermitAllowed: PermitsAllowed.validator,
        });

        const UserName = validations(target.name, "username", this.state.username);
        this.setState({
          username: UserName.value,
          validUser: UserName.validator,
        });

        if (isEmailValid(this.state.email)) {
          this.setState({
            email: this.state.email,
            validEmail: "",
          });
        } else {
          this.setState({
            email: this.state.email,
            validEmail: "Email is not correct",
          });
        }
        switch (target.name) {
          case "cost":
            {
              if (target.value && /^\d{1,2}(\.\d{0,2}|\.\d{0,2})?$/.test(target.value)) {
                this.setState({
                  cost: Number(target.value),
                  costErrorMessage: "", // Clear any previous error message
                  isFormValid: true,
                });
              } else {
                if (target.value === "") {
                  this.setState({
                    cost: 0,
                    costErrorMessage: "Cost is required",
                  });
                } else {
                  this.setState({
                    cost: prevCost,
                    costErrorMessage: "Please enter a valid numeric value",
                  });
                }
                this.setState({
                  isFormValid: false,
                });
              }
            }
            break;
          case "validationTimeInHours":
            {
              if (target?.value && /^(1?\d|2[0-4])(\.\d{0,2})?$/.test(target.value)) {
                this.setState({
                  validationTimeInHours: target.value,
                  validationTimeInHoursErrorMessage: "", // Clear any previous error message
                  isFormValid: true,
                });
              } else {
                if (target.value === "") {
                  this.setState({
                    validationTimeInHours: "",
                    validationTimeInHoursErrorMessage: "Time is required",
                  });
                } else {
                  this.setState({
                    validationTimeInHours: prevValidationTime,
                    validationTimeInHoursErrorMessage: "The value must be a numeric figure and should not exceed 24.",
                  });
                }
                this.setState({
                  isFormValid: false,
                });
              }
            }
            break;
          case "validationTimeType":
            {
              this.setState({
                validationTimeInHoursErrorMessage: "",
              });
            }
            break;
          default:
            break;
        }
        passwordValue = this.handleOnchangePassword(target, high_security, passwordValue);
        this.setState(() => {
          let isValidPhone = true;
          if ((phone && phone.length >= 11) || (phone && phone.length < 10)) {
            this.setState({
              phoneErrorMessage: "Please enter valid phone number",
            });
            isValidPhone = false;
          } else {
            this.setState({
              phoneErrorMessage: "",
            });
          }
          const name = { first_name, last_name };
          this.checkAllValidations(phone, username, name, isEmailValid(email), permits_allowed, isValidPhone, passwordValue);
        });
      },
    );
  };

  handleOnchangeCountryCodeAndPhone = (target, prevstate, phone, country_code) => {
    if (target.name === "phone" && phone?.trim().length) {
      if (regExForPhone.test(target.value)) {
        this.setState({ phone: target.value });
      } else {
        this.setState({ phone: prevstate });
      }
    }
    if (target.name === "country_code" && country_code?.trim().length) {
      this.setState({
        country_code: target.value.replace(/[a-zA-Z]/, ""),
      });
    }
  };

  handleOnchangePassword = (target, high_security, passwordValue) => {
    if (target.name === "password") {
      if (high_security) {
        const errMsg = PasswordValidation(target.value);
        this.setState({
          passwordErrorMsg: errMsg,
        });
        if (errMsg) {
          passwordValue = false;
          this.setState({
            passwordErrorMsg: errMsg,
          });
        } else {
          passwordValue = true;
          this.setState({
            passwordErrorMsg: "",
          });
        }
      }
    }
    return passwordValue;
  };

  handleValidationTimeChange = (time) => {
    this.setState({ selectedTime: time }, () => {
      const { phone, email, first_name, last_name, passwordValue, username, permits_allowed } = this.state;
      const isEmailValidate = isEmailValid(email);
      let isValidPhone = true;
      if ((phone && phone.length >= 11) || (phone && phone.length < 10)) {
        this.setState({
          phoneErrorMessage: "Please enter valid phone number",
        });
        isValidPhone = false;
      }
      const name = { first_name, last_name };
      this.checkAllValidations(phone, username, name, isEmailValidate, permits_allowed, isValidPhone, passwordValue);
    });
  };

  handleCheckBox = ({ target }) => {
    const { phone, email, first_name, last_name, username, permits_allowed } = this.state;
    let passwordValue = true;
    const isEmailValidate = isEmailValid(email);
    let isValidPhone = true;
    if ((phone && phone.length >= 11) || (phone && phone.length < 10)) {
      this.setState({
        phoneErrorMessage: "Please enter valid phone number",
      });
      isValidPhone = false;
    }
    this.setState({ [target.name]: target.checked }, () => {
      if (target.name === "allow_quick_permit") {
        if (!target.checked) {
          this.setState({
            validationTimeInHours: "",
            selectedTime: "",
            cost: 0,
          });
        } else {
          this.setState({
            validationTimeType: "HOURS",
          });
        }
      }
      const name = { first_name, last_name };
      this.checkAllValidations(phone, username, name, isEmailValidate, permits_allowed, isValidPhone, passwordValue);
    })
  };

  handleSubmit = async () => {
    const {
      suite_id,
      parking_spot,
      permits_allowed,
      username,
      first_name,
      last_name,
      country_code,
      phone,
      email,
      do_not_allow_login,
      password,
      user_type,
      ResidentId,
      phone_options,
      deleted_phone_id,
      defalutLanguage,
      invoice_visitor_credits,
      hst_applied,
      allow_quick_permit,
      validationTimeInHours,
      selectedTime,
      validationTimeType,
      cost,
    } = this.state;

    const Obj = {
      suite_id,
      parking_spot,
      permits_allowed,
      username,
      first_name,
      last_name,
      country_code,
      phone,
      email,
      do_not_allow_login,
      password,
      user_type,
      property_id: this.state.property?.id,
      old_data: this.props.editedResident,
      phone_options,
      deleted_phone_id,
      language: defalutLanguage,
      invoice_visitor_credits,
      hst_applied,
      allow_quick_permit,
      validation_time_value: validationTimeType === "HOURS" ? validationTimeInHours : selectedTime,
      validationTimeType,
      cost,
    };
    await this.props.edit_resident(ResidentId, Obj);
    if (this.props.api_response) {
      this.setState(
        {
          show_alert: true,
          api_success_message: this.props.api_success_message,
        },
        () => {
          setTimeout(() => {
            this.setState({ show_alert: false });
            routing_using_history("/units");
          }, 4000);
        },
      );
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  checkAllValidations = (phone, username, name, email, permits_allowed, isValidPhone, passwordValue) => {
    const { first_name, last_name } = name;
    let isValidationReq = true;
    let isCostValid = true;
    if (this.state.allow_quick_permit) {
      if (this.state.validationTimeType === "HOURS") {
        isValidationReq = !!this.state.validationTimeInHours && /^\d{1,2}(\.\d{0,2}|\.\d{0,2})?$/.test(this.state.validationTimeInHours);
        this.setState({
          selectedTime: "",
        });
      } else {
        this.setState({
          validationTimeInHours: "",
        });
        isValidationReq = !!this.state.selectedTime;
      }
      if (!this.state.cost) {
        isCostValid = false;
      }
      if (this.state.cost && !/^\d{1,2}(\.\d{0,2}|\.\d{0,2})?$/.test(this.state.cost)) {
        isCostValid = false;
      }
    }
    if (
      phone?.length &&
      username &&
      first_name &&
      last_name &&
      email &&
      // permits_allowed &&
      isValidPhone &&
      passwordValue &&
      this.isMultiplePhoneValid() &&
      isValidationReq &&
      isCostValid
    ) {
      this.setState({ isFormValid: true });
    } else {
      this.setState({ isFormValid: false });
    }
  };

  handleBack = () => {
    routing_using_history(`/units`);
  };

  TogglePasswordVisibility = () => {
    this.setState({
      passwordVisible: !this.state.passwordVisible,
    });
  };

  render() {
    const {
      suite_id,
      parking_spot,
      username,
      first_name,
      last_name,
      country_code,
      phone,
      email,
      do_not_allow_login,
      password,
      validFirstName,
      validLastName,
      validEmail,
      validPhone,
      validUser,
      isFormValid,
      show_alert,
      api_success_message,
      show_error,
      passwordErrorMsg,
      passwordVisible,
      country_code_list,
      phone_options,
      phoneErrorMessage,
      edit_unit_email,
      edit_unit_username,
      defalutLanguage,
      invoice_visitor_credits,
      hst_applied,
      allow_quick_permit,
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <Card.Title as="h4">Edit Unit</Card.Title>
                  <p className="card-category"></p>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide">
                  <Row>
                    <Col lg="3" className="pr-md-1">
                      <Form.Group>
                        <label>Unit Id</label>
                        <Form.Control name="suite_id" value={suite_id} readOnly />
                      </Form.Group>
                    </Col>
                    <Col lg="3" className="pr-md-1">
                      <Form.Group>
                        <label>
                          Username
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="2100user"
                          type="text"
                          name="username"
                          value={username}
                          autoComplete="off"
                          onChange={this.handleChange}
                          required
                          disabled={!edit_unit_username}
                        />
                        {validation_message(username, "Username", validUser)}
                      </Form.Group>
                    </Col>

                    <Col md="3">
                      <Form.Group>
                        <label>Password</label>
                        <div className="input-mask-btn">
                          <Form.Control
                            type={passwordVisible ? "text" : "password"}
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={this.handleChange}
                            autoComplete="new-password"
                          />
                          <button className="mask-password" type="button" onClick={this.TogglePasswordVisibility}>
                            <i className={`fas ${passwordVisible ? "fa-eye" : "fa-eye-slash"}`} />
                          </button>
                        </div>
                        <div style={{ color: "red", fontSize: 13 }} dangerouslySetInnerHTML={{ __html: passwordErrorMsg }}></div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="3" className="pr-md-1">
                      <Form.Group>
                        <label>
                          First Name
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="John"
                          type="text"
                          name="first_name"
                          value={first_name}
                          autoComplete="off"
                          onChange={this.handleChange}
                          required
                        ></Form.Control>
                        {validation_message(first_name, "First Name", validFirstName)}
                      </Form.Group>
                    </Col>
                    <Col lg="3" className="pr-md-1">
                      <Form.Group>
                        <label>
                          Last Name
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="Smith"
                          type="text"
                          name="last_name"
                          value={last_name}
                          autoComplete="off"
                          onChange={this.handleChange}
                          required
                        />
                        {validation_message(last_name, "Last Name", validLastName)}
                      </Form.Group>
                    </Col>

                    <Col lg="3">
                      <Form.Group>
                        <label>Notes / Reference</label>
                        <Form.Control
                          placeholder="20"
                          type="text"
                          name="parking_spot"
                          value={parking_spot}
                          autoComplete="off"
                          onChange={this.handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="3">
                      <Form.Group>
                        <label>
                          Email Address
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="example@gmail.com"
                          type="text"
                          name="email"
                          value={email}
                          autoComplete="off"
                          onChange={this.handleChange}
                          disabled={!edit_unit_email}
                          required
                        />
                        {email_validation_message(email, "Email", validEmail)}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" sm="6">
                      <Form.Group>
                        <label>Default Language for Unit</label>
                        <span className="asterisk">*</span>
                        <Form.Control as="select" name="defalutLanguage" value={defalutLanguage} onChange={this.handleChange} autoComplete="off">
                          <option value="en">English</option>
                          <option value="fn">French</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="4" sm="6" className="mt-sm-4 mb-sm-0 my-2">
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name={"allow_quick_permit"}
                          checked={allow_quick_permit}
                          onChange={this.handleCheckBox}
                          className="custom-control-input"
                          id="do_not_allow_login1"
                        ></input>
                        <span>
                          <i></i>Allow Quick Permit
                        </span>
                      </label>
                    </Col>
                  </Row>
                  {allow_quick_permit && (
                    <Row>
                      <Col md="4" sm="6">
                        <Form.Group>
                          <label>Validation Type</label>
                          <span className="asterisk">*</span>
                          <Form.Control
                            as="select"
                            name="validationTimeType"
                            value={this.state.validationTimeType}
                            onChange={this.handleChange}
                            autoComplete="off"
                          >
                            <option value="HOURS">Hours</option>
                            <option value="UNTIL">Until</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="4" sm="6">
                        <Form.Group>
                          {this.state.validationTimeType === "HOURS" && (
                            <>
                              {" "}
                              <label>Validation Time</label>
                              <span className="asterisk">*</span>
                              <Form.Control
                                placeholder="2"
                                type="text"
                                name="validationTimeInHours"
                                value={this.state.validationTimeInHours}
                                onChange={this.handleChange}
                                autoComplete="off"
                                required
                              ></Form.Control>
                              <span className="help-block">{this.state.validationTimeInHoursErrorMessage}</span>
                            </>
                          )}
                          {this.state.validationTimeType === "UNTIL" && (
                            <Form.Group>
                              <div class="d-flex align-items-center">
                                <label>
                                  Validation Time
                                  <span className="asterisk">*</span>{" "}
                                </label>
                                <FormLabel className="ml-auto d-flex align-items-center text-nowrap">
                                  <OverlayTrigger
                                    delay={{ show: 250, hide: 400 }}
                                    placement={"top"}
                                    overlay={<Tooltip>When checked, it sets max_time to 23:59, indicating end of day.</Tooltip>}
                                  >
                                    <input type="checkbox" className="mr-1" checked={this.state.isSetMaxTime} onChange={(e) => this.handleMaxTime(e)}></input>
                                  </OverlayTrigger>{" "}
                                  <span>Max Time </span>{" "}
                                </FormLabel>
                              </div>
                              <DatePicker
                                className="form-control"
                                selected={this.state.selectedTime}
                                onChange={this.handleValidationTimeChange}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                minTime={new Date(new Date().setHours(0, 0, 0, 0))}
                                maxTime={new Date().setHours(23, 59)}
                              />
                            </Form.Group>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg="2" md="4" sm="6">
                        <Form.Group>
                          <label>Cost</label>
                          <span className="asterisk">*</span>
                          <Form.Control
                            placeholder="Cost"
                            type="text"
                            name="cost"
                            value={this.state?.cost || 0}
                            onChange={this.handleChange}
                            autoComplete="off"
                            required
                          />
                          <span className="help-block">{this.state.costErrorMessage}</span>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  <Row className="m-w-480px">
                    <Col className="col-auto">
                      <Form.Group>
                        <label>Country Code</label>
                        <Form.Control className="w-85px" as="select" name="country_code" onChange={this.handleChange} value={country_code} required>
                          {country_code_list ? (
                            <>
                              {(country_code_list ?? []).map((item) => (
                                <option key={item?.id} value={item.value}>
                                  {item.text}
                                </option>
                              ))}
                            </>
                          ) : null}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="col p-0">
                      <Form.Group>
                        <label>
                          Phone
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="2145602323"
                          type="text"
                          name="phone"
                          value={phone}
                          autoComplete="off"
                          onChange={this.handleChange}
                          required
                          maxLength={10}
                        />
                        {validation_message(phone, "Phone Number", validPhone)}
                        <span className="help-block">{this.state.phoneErrorMessage}</span>
                      </Form.Group>
                    </Col>
                    <Col className="col-auto">
                      <div>
                        <label>&nbsp;</label>
                      </div>
                      <Button
                        size="sm"
                        className="h-40px"
                        disabled={
                          phone_options?.length > 9 || phoneErrorMessage?.trim().length !== 0 || phone?.trim().length === 0 || !this.isMultiplePhoneValid()
                        }
                        onClick={this.handleAddPhoneOptions}
                      >
                        <i className="fas fa-plus-circle"></i>
                      </Button>
                    </Col>
                  </Row>
                  {phone_options &&
                    typeof phone_options === "object" &&
                    Array.isArray(phone_options) &&
                    phone_options.map((option, index) => {
                      return (
                        <Row key={option?.country_code} className="my-0 m-w-480px">
                          <Col className="col-auto">
                            <Form.Group>
                              <Form.Control
                                className="w-85px"
                                as="select"
                                name="country_code_options"
                                onChange={(e) => this.handlePhoneOptions(index, e.target.value, "country_code_options")}
                                value={option?.country_code}
                                required
                              >
                                {country_code_list ? (
                                  <>
                                    {(country_code_list ?? []).map((item) => (
                                      <option key={item?.id} value={item.value}>
                                        {item.text}
                                      </option>
                                    ))}
                                  </>
                                ) : null}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="col p-0">
                            <Form.Group className="p-0 m-1">
                              {/* <Form.Label>Option {index + 1}</Form.Label> */}
                              {/* <span className="asterisk">*</span> */}
                              <Form.Control
                                placeholder="Phone Number"
                                type="text"
                                name="phone_number_options"
                                value={option?.phone}
                                // onBlur={(e) => e.target.value.trim() === "" && removeNoteOption(index)}
                                onChange={(e) => this.handlePhoneOptions(index, e.target.value, "phone_number_options")}
                                maxLength="10"
                                autoComplete="off"
                              />
                            </Form.Group>
                          </Col>
                          <Col className="col-auto">
                            <Button size="sm" className="h-40px" onClick={() => this.removePhoneOption(index)}>
                              <i className="fas fa-minus"></i>
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}

                  <Row>
                    <Col lg="9">
                      <p className="blue">**Note: The unit will receive an email to set their password.</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" className="mt-4">
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name={"do_not_allow_login"}
                          checked={do_not_allow_login}
                          onChange={this.handleCheckBox}
                          className="custom-control-input"
                          id="do_not_allow_login1"
                        ></input>
                        <span>
                          <i></i>Do not allow login
                        </span>
                      </label>
                    </Col>
                    <Col md="4" className="mt-4">
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name={"invoice_visitor_credits"}
                          checked={invoice_visitor_credits}
                          onChange={this.handleCheckBox}
                          className="custom-control-input"
                          id="invoice_visitor_credits1"
                        ></input>
                        <span>
                          <i></i>Invoice visitor credits
                        </span>
                      </label>
                    </Col>
                    <Col md="4" className="mt-4">
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name={"hst_applied"}
                          checked={hst_applied}
                          onChange={this.handleCheckBox}
                          className="custom-control-input"
                          id="hst_applied1"
                        ></input>
                        <span>
                          <i></i>HST Applied
                        </span>
                      </label>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col lg="4" md="4" sm="12" xs="12" className="d-flex align-items-center">
                      <Form.Check
                        inline
                        id="credit"
                        // label={`Don't allow visitors`}
                        type="radio"
                        name={"do_not_allow_visitor"}
                        disabled
                        checked={this.state.do_not_allow_visitor}
                        onChange={this.handlePermitCheckBox}
                        className="text-black"
                      ></Form.Check>
                      <label class="text-black mt-2">Don&apos;t allow visitors</label>
                    </Col>
                    <Col lg="4" md="4" sm="12" xs="12" className="d-flex align-items-center">
                      <Form.Check
                        inline
                        type="radio"
                        name="default_credits"
                        // label="Default credits"
                        checked={this.state.default_credits}
                        onChange={this.handlePermitCheckBox}
                        id="credit"
                        disabled
                      ></Form.Check>
                      <label class="text-black mt-2">Allow visitors per default site settings</label>
                    </Col>
                    <Col lg="4" md="4" sm="12" xs="12" className="d-flex align-items-center">
                      <Form.Check
                        inline
                        type="radio"
                        // label="Unlimited credits"
                        name={"unlimited_credits"}
                        checked={this.state.unlimited_credits}
                        onChange={this.handlePermitCheckBox}
                        id="credit"
                        disabled
                      ></Form.Check>
                      <label class="text-black mt-2">Unlimited visitors</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill" type="button" variant="danger" onClick={this.handleBack}>
                        Back
                      </Button>
                      <Button className="btn-fill ml-2" type="button" variant="info" disabled={!isFormValid} onClick={this.handleSubmit}>
                        Submit Changes
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {show_alert && (
          <CustomContentAlert
            delay={4000}
            message={api_success_message}
            // message={"Unit edited succesfully"}
            className="toast-success"
          />
        )}
        {show_error && <CustomContentAlert delay={5000} message={this.state.api_error_message} className="toast-error" />}
      </div>
    );
  }
}

const mapStateToProps = ({ ResidenDetails, APIResponseDetails, UserProfileDetails, Logindetails }) => {
  const { editedResident } = ResidenDetails;
  const { property } = UserProfileDetails;
  const { api_response, api_success_message, api_error_message } = APIResponseDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  return {
    editedResident,
    api_response,
    api_success_message,
    api_error_message,
    property,
    main_body,
  };
};

const mapStateToDispatch = {
  select_edit_resident: ResidentsActions.select_edit_resident,
  edit_resident: ResidentsActions.edit_resident,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(EditResident);

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import PropertDetails from "../LandingPage/PropertySelection";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import * as SuperAdminAction from "../../Actions/SuperAdminAction/actions";
import * as TicketActions from "../../Actions/TicketActions/actions";
import { saveAs } from "file-saver";
import Loader from "../Loader/Loader";
import { tableHeightScrollBars, findBySearch, utcToSpecificTimeZone, scroolToTop } from "../helper";
import moment from "moment";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";

const Refund = () => {
  const today = new Date();
  const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
  const dispatch = useDispatch();
  const refundList = useSelector((refundState) => refundState.TicketDetails.refundList);
  const exportList = useSelector((exportState) => exportState.TicketDetails.exportList);
  const UserData = useSelector((userState) => userState.UserProfileDetails);
  const selectAllProperty = useSelector((propertyState) => propertyState.SuperAdminDetails.getAllProperties);

  const [state, setState] = useState({
    selectedProperty: UserData.property.id,
    selectedPropertyObj: {},
    childcheckbox: true,
    startDate: new Date(datebeforeThirtyDays),
    endDate: new Date(),
    currentSummaryDate: null,
    timezone: UserData.timezone,
    exportValue: false,
    selectedDomainName: "",
    refundListData: [],
    exportButtonDisable: true,
    finalTableHeight: 0,
    searchValue: "",
    refundListCount: 0,
    loader: true,
    refundAllList: refundList,
    startDateTime: "",
    endDateTime: "",
  });

  const [apiCalled, setApiCalled] = useState(false);

  useEffect(() => {
    const height = tableHeightScrollBars();
    dispatch(SuperAdminAction.get_all_properties());
    setState((prev) => ({
      ...prev,
      finalTableHeight: height,
    }));
  }, []);

  useEffect(() => {
    if (state.searchedRefundList) {
      setState((prev) => ({
        ...prev,
        refundListCount: state.searchedRefundList.length,
      }));
    }
  }, [state.searchedRefundList]);

  useEffect(() => {
    const selectedProperty = selectAllProperty.length && selectAllProperty.find((item) => item.id === state.selectedProperty);
    const selectedPropertyName = UserData.property?.property_name;
    const selectedDomainName = UserData.property?.domain;
    if (selectedProperty) {
      setState((prev) => ({
        ...prev,
        selectedPropertyObj: {
          label: selectedProperty.site_name,
          value: selectedProperty.id,
        },
        selectedDomainName: selectedDomainName,
      }));
    }
    dispatch(SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName));
  }, [selectAllProperty]);

  useEffect(() => {
    const body = {
      property_id: state.selectedProperty,
      childcheckbox: state.childcheckbox,
      timezone: state.timezone,
      exportValue: state.exportValue,
      startDate: state.startDate,
      endDate: state.endDate,
      currentSummaryDate: state.currentSummaryDate,
    };

    dispatch(TicketActions.getRefundList(body));
    GetDateRangeWithFormat(body);
    setApiCalled(true);
  }, []);

  useEffect(() => {
    if (refundList && apiCalled === true) {
      setState((prev) => ({
        ...prev,
        refundListData: refundList.rows,
        refundListCount: refundList.count,
        loader: false,
      }));
    }
  }, [refundList]);

  useEffect(() => {
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `REFUND-${currentTimeDate}-${state.selectedDomainName}.xls`;
    if (apiCalled === true && state.exportValue) {
      setApiCalled(false);
      saveAs(
        new Blob([exportList], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
      setState((prev) => ({
        ...prev,
        exportValue: false,
      }));
    }
  }, [exportList]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Domain",
      width: "120px",
      sortable: true,
      cell: (row) => showDomainField(row),
      selector: (row) => row?.properties?.domain,
    },
    {
      name: "Date",
      width: "160px",
      sortable: true,
      cell: (row) => showDateField(row),
      selector: (row) => row.created_at,
    },
    {
      name: "Transaction Id",
      width: "150px",
      sortable: true,
      cell: (row) => showTransactionIdField(row),
      selector: (row) => row.transaction_id,
    },
    {
      name: "LPN",
      width: "120px",
      sortable: true,
      cell: (row) => showLPN(row),
      selector: (row) => row?.lpn,
    },
    {
      name: "Citation No",
      width: "120px",
      sortable: true,
      cell: (row) => showCitationNoField(row),
      selector: (row) => row.tickets.ticket_no,
    },
    {
      name: "Amount",
      sortable: true,
      width: "120px",
      cell: (row) => showAmountField(row),
      selector: (row) => row?.amount,
    },
    {
      name: "Refund Action",
      width: "140px",
      sortable: true,
      cell: (row) => showRefundAction(row),
      selector: (row) => row?.refund_action,
    },
    {
      name: "Type",
      width: "140px",
      sortable: true,
      cell: (row) => showTypeField(row),
      selector: (row) => row?.type,
    },
    {
      name: "Reason",
      sortable: true,
      width: "160px",
      cell: (row) => showReasonField(row),
      selector: (row) => row.reason,
    },
  ];

  const showReasonField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.reason}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row.reason}</div>
      </OverlayTrigger>
    </>
  );

  const showTypeField = (row) => (
    <>
      {row?.type ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.type}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.type}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showRefundAction = (row) => (
    <>
      {row?.refund_action ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.refund_action}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.refund_action}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showAmountField = (row) => (
    <>
      {row?.amount ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${parseFloat(row.amount).toFixed(2)}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">${parseFloat(row.amount).toFixed(2)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showCitationNoField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.tickets.ticket_no || ""}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row.tickets.ticket_no || ""}</div>
      </OverlayTrigger>
    </>
  );

  const showTransactionIdField = (row) => (
    <>
      {row?.transaction_id ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.transaction_id}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.transaction_id}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showDateField = (row) => (
    <>
      {row.created_at ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(state.timezone, row.created_at, true)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(state.timezone, row.created_at, true)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showDomainField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.properties?.domain || "-"}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.properties?.domain || "-"}</div>
      </OverlayTrigger>
    </>
  );

  const showLPN = (row) => {
    return (
      <div className="curserPointer text-nowrap text-truncate pr-2" onClick={() => handleRedirect(row.lpn)}>
        {row?.lpn ? (
          <>
            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.lpn}</Tooltip>}>
              <div className="text-nowrap text-truncate">{row?.lpn}</div>
            </OverlayTrigger>
          </>
        ) : (
          "-"
        )}
      </div>
    );
  };
  const dataTableHandle = (data) => {
    return (
      <DataTable
        data={state.searchValue?.length ? state.searchedRefundList : data}
        columns={columns}
        customStyles={customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={state.refundListCount}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  const handleSearchChange = async ({ target }) => {
    const val = target?.value?.toString().trim();

    setState((prev) => ({ ...prev, searchValue: val }));
    const columnList = ["lpn", "created_at", "refund_action", "amount", "transaction_id"];
    if (val?.length) {
      if (!state.findMore) {
        setState((prev) => ({
          ...prev,
          searchedRefundList: findBySearch(val, state.refundListData, state.refundListData, columnList, state.timezone),
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        count: refundList.count,
        searchedRefundList: [],
        refundListData: refundList.rows,
      }));
    }
  };

  const handleExport = async () => {
    setState({ ...state, exportValue: true });
    const { selectedProperty, childcheckbox, timezone, startDate, endDate, currentSummaryDate } = state;
    const pageobj = {
      childcheckbox,
      timezone,
      exportValue: true,
      startDate,
      endDate,
      currentSummaryDate,
    };
    if (Object.keys(state.selectedPropertyObj).length) {
      pageobj.property_id = state.selectedPropertyObj.value;
    } else {
      pageobj.property_id = selectedProperty;
    }

    if (state.currentSummaryDate) {
      pageobj.currentSummaryDate = state.currentSummaryDate;
    } else if (startDate && endDate) {
      pageobj.startDate = state.startDate;
      pageobj.endDate = state.endDate;
    }

    dispatch(TicketActions.getRefundList(pageobj));
    GetDateRangeWithFormat(pageobj);
  };

  const handleCurrentDate = (date) => {
    setState((prev) => ({
      ...prev,
      currentSummaryDate: date,
      startDate: null,
      endDate: null,
    }));
  };

  const handleChangeStartingDates = (date) => {
    const dt = new Date(date);
    const getEndDate = new Date(dt.setMonth(dt.getMonth() + 3));
    let selectEndDate;
    if (getEndDate > new Date()) {
      selectEndDate = new Date();
    } else {
      selectEndDate = getEndDate;
    }
    setState((prev) => ({
      ...prev,
      currentSummaryDate: null,
      startDate: date,
      endDate: selectEndDate,
    }));
  };

  const handleChangeEndDates = (date) => {
    setState((prev) => ({
      ...prev,
      currentSummaryDate: null,
      endDate: date,
    }));
  };

  const handleRedirect = (lpn) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/current-occupancy`,
      search: `audit-report&${lpn}`,
    });
  };

  const resetAll = async () => {
    setState((prev) => ({
      ...prev,
      currentSummaryDate: null,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      selectedProperty: UserData.property.id,
      selectedPropertyObj: {
        label: UserData.property.property_name,
        value: UserData.property.id,
      },
      childcheckbox: true,
    }));
    const body = {
      property_id: UserData.property.id,
      childcheckbox: true,
      timezone: state.timezone,
      exportValue: false,
      startDate: new Date(today.setDate(today.getDate() - 30)),
      endDate: new Date(),
      currentSummaryDate: null,
    };
    dispatch(TicketActions.getRefundList(body));
    document.body.classList.remove("foc-open");
  };

  const handleSearch = () => {
    setState((prev) => ({ ...prev, loader: true }));
    const { childcheckbox, currentSummaryDate, startDate, endDate, selectedPropertyObj, selectedProperty, timezone } = state;
    const pageobj = {
      childcheckbox,
      timezone,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.property_id = selectedPropertyObj.value;
    } else {
      pageobj.property_id = selectedProperty;
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    } else if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    dispatch(TicketActions.getRefundList(pageobj));
    GetDateRangeWithFormat(pageobj);
    document.body.classList.remove("foc-open");
  };

  const hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  const handleCheckBox = () => {
    setState((prev) => ({
      ...prev,
      childcheckbox: !state.childcheckbox,
    }));
  };

  const changeProperty = (event) => {
    setState((prev) => ({
      ...prev,
      selectedProperty: event.value,
      selectedPropertyObj: event,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentSummaryDate: null,
    }));
  };

  const GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj);
    setState(() => ({
      ...state,
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    }));
  };

  return (
    <div>
      <Card className="mb-0">
        <Card.Body className="pos-rel-overflow-hide">
          <>
            <div className="sidebarFilter">
              <Card className="contact-container mb-0">
                <Card.Header // style={{ backgroundColor: this.props.main_body }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <Card.Title as="h4">Refund Report</Card.Title>
                    <a
                      onClick={hideSlider}
                      href="javascript:void(0);"
                      className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md="12">
                      <div className="mb-2">
                        <label className="fancy-checkbox">
                          <input
                            type="checkbox"
                            name={"childcheckbox"}
                            checked={state.childcheckbox}
                            onChange={handleCheckBox}
                            className="custom-control-input"
                            id="childcheckbox1"
                          ></input>
                          <span>
                            <i></i>Include Children Sites
                          </span>
                        </label>
                      </div>
                    </Col>
                    <Col md="12">
                      <PropertDetails selectedPropertyObj={state.selectedPropertyObj} changeProperty={changeProperty.bind(this)} />
                    </Col>
                    <Col md="12">
                      <Form.Group>
                        <label className="mb-0 mr-2">Specific Date:</label>
                        <DatePicker
                          className="form-control"
                          placeholderText="Select Date"
                          selected={state.currentSummaryDate}
                          value={state.currentSummaryDate}
                          disabled={state.loader}
                          selectsStart
                          timeInputLabel="Time:"
                          onChange={handleCurrentDate}
                          dateFormat="MM/dd/yyyy"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group>
                        <label className="mb-0 mr-2">Start Date(Date Range):</label>
                        <DatePicker
                          className="form-control"
                          placeholderText="Select Start Date"
                          selected={state.startDate}
                          defaultHour={24}
                          value={state.startDate}
                          disabled={state.loader}
                          selectsStart
                          timeInputLabel="Time:"
                          onChange={handleChangeStartingDates}
                          dateFormat="MM/dd/yyyy"
                          startDate={state.startDate}
                          endDate={state.endDate}
                          maxDate={new Date()}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group>
                        <label className="mb-0 mr-2"> End Date(Date Range):</label>
                        <DatePicker
                          className="form-control"
                          placeholderText="Select End Date"
                          selected={state.endDate}
                          disabled={state.loader}
                          value={state.endDate}
                          selectsEnd
                          timeInputLabel="Time:"
                          onChange={handleChangeEndDates}
                          // dateFormat="MM/dd/yyyy hh:mm:ss aa"
                          dateFormat="MM/dd/yyyy"
                          // showTimeInput
                          startDate={state.startDate}
                          endDate={state.endDate}
                          minDate={state.startDate}
                          maxDate={new Date()}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-right justify-content-between d-flex">
                  <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={resetAll} disabled={state.loader}>
                    Reset
                  </Button>
                  <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={handleSearch} disabled={state.loader}>
                    Search
                  </Button>
                </Card.Footer>
              </Card>
            </div>
            <header>
              <div className="active-permit-header sm-flex-column sm-align-items-start">
                <h5 className="mb-2 mb-md-0">Refund</h5>
                <div className="filter-button d-flex flex-wrap">
                  <div className="d-flex align-items-center search-mb-0">
                    <DatatableHeader className="mt-0 mb-0" searchValue={state.searchValue} HandleChange={handleSearchChange} />
                  </div>
                  <div className="ml-2">
                    <Button variant="info" className="btn-fill btn-sm" onClick={handleExport} disabled={state.refundListData?.length > 0 ? 0 : 1} size="medium">
                      Export
                    </Button>
                  </div>
                </div>
              </div>
            </header>
          </>
          {state.loader ? (
            <Loader />
          ) : (
            <>
              {state.refundListData && (
                <>
                  <div className="citation-list-table has-parent-tab">{dataTableHandle(state.refundListData)}</div>
                  <DateRangeAndAmount
                    ReportList={state?.refundListData?.rows}
                    amountName="amount"
                    startDateTime={state.startDateTime}
                    endDateTime={state.endDateTime}
                  />
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Refund;

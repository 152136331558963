import React, { Component } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { connect } from "react-redux";
import { ResidentsActions, SuperAdminAction } from "../../Actions";
import Loader from "../Loader/Loader";
import CustomContentAlert, { findBySearch, tzDate } from "../helper";
import PaginationforData from "../pagination/pagination";

class ViewResidents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      residentList: [],
      loader: false,
      user_type: this.props.user_type,
      searchValue: "",
      newResidentList: [],
      api_response: false,
      prevSearch: "",
      prevPage: "",
      prevNorow: "",
      page: 1,
      Norow: 10,
      selectedColumn: "createdAt",
      asc: "false",
      sort_direction: "DESC",
      pageChanged: false,
      findMore: false,
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "Unit Id",
        sortable: true,
        cell: this.showUnitId,
        selector: (row) => row.suite_id,
      },
      {
        name: "Username",
        sortable: true,
        cell: this.showUserName,
        selector: (row) => row.username,
      },
      {
        name: "First Name",
        sortable: true,
        cell: this.showFirstName,
        selector: (row) => row.first_name,
      },
      {
        name: "Last Name",
        sortable: true,
        cell: this.showLastName,
        selector: (row) => row.last_name,
      },
      {
        name: "Email",
        sortable: true,
        cell: this.showEmail,
        selector: (row) => row.email,
      },
      {
        name: "Phone",
        sortable: true,
        cell: this.showPhone,
        selector: (row) => row.phone,
      },
      {
        name: "Created Date",
        sortable: true,
        // cell: (row) => <div>{moment(row.createdAt).format("L")}</div>,
        cell: this.showCreatedAt,
      },
      {
        name: "Notes",
        sortable: true,
        cell: this.parkingSpot,
        selector: (row) => row.parking_spot,
      },
    ];
  }

  showUnitId = (row) => <div>{row.suite_id}</div>;

  showUserName = (row) => <div>{row.username}</div>;

  showFirstName = (row) => <div>{row.first_name}</div>;

  showLastName = (row) => <div>{row.last_name}</div>;

  showEmail = (row) => <div>{row.email}</div>;

  showPhone = (row) => <div>{row.phone}</div>;

  showCreatedAt = (row) => <div>{tzDate(this.state.timezone, row.createdAt)}</div>;

  parkingSpot = (row) => <div>{row.parking_spot}</div>;

  componentDidMount() {
    this.setState({ loader: true }, async () => {
      const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
      const pageobj = {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
      };
      await this.props.fetch_residents(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        residentList: await this.props.residentList.rows,
        api_response: this.props.api_response,
        count: await this.props.residentList.count,
      });
      this.setState({
        residentList: await this.props.residentList.rows,
        count: await this.props.residentList.count,
        loader: false,
        api_response: await this.props.api_response,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.residentList !== this.props.residentList) {
      this.setState({
        newResidentList: this.props.residentList.rows,
        count: this.props.residentList.count,
      });
    }
  }

  handleSearchChange = ({ target }) => {
    this.setState({
      prevSearch: this.state.searchValue,
      page: 1,
      pageChanged: true,
    });
    this.setState({ searchValue: target.value }, () => {
      if (this.state.searchValue.length) {
        if (!this.state.findMore) {
          this.setState(
            {
              newResidentList: findBySearch(this.state.searchValue, this.state.residentList, this.state.residentList),
            },
            () => {
              this.setState({ count: this.state.newResidentList.length });
            },
          );
        }
      } else {
        this.setState({ loader: true, pageChanged: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          await this.props.fetch_residents(pageobj);
          this.setState({
            pageChanged: true,
            page: 1,
            newResidentList: await this.props.residentList.rows,
          });
          this.setState({ loader: false });
        });
      }
      setTimeout(() => {
        this.setState({ findMore: false });
      }, 1000);
    });
  };

  handlepage = ({ target }) => {
    this.setState({ prevPage: this.state.page, prevNorow: this.state.Norow });
    this.setState({ [target.name]: target.value }, async () => {
      switch (target.name) {
        case "page":
          this.setState({ page: target.value }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            this.setState({ loader: true, pageChanged: true }, async () => {
              await this.props.fetch_residents(pageobj);
              this.setState({
                newResidentList: await this.props.residentList.rows,
                api_response: this.props.api_response,
              });
              this.setState({ loader: false });
            });
          });
          break;
        case "Norow":
          this.setState({ Norow: target.value }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            this.setState({ loader: true, pageChanged: true }, async () => {
              await this.props.fetch_residents(pageobj);
              this.setState({
                newResidentList: await this.props.residentList.rows,
                api_response: this.props.api_response,
              });
              this.setState({ loader: false });
            });
          });
          break;
      }
      if (Number(this.state.page) !== Number(this.state.prevPage) || Number(this.state.Norow) !== Number(this.state.prevNorow)) {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true, pageChanged: true }, async () => {
          await this.props.fetch_residents(pageobj);
          this.setState({
            newResidentList: await this.props.residentList.rows,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      }
    });
  };

  handleRowsPerPage = () => {
    const { Norow, page } = this.state;
    return { Norow, page };
  };

  handlepagenext = async () => {
    const { page, Norow } = this.state;
    const count = await this.props.residentList.count;
    const finalPage = Math.ceil(count / Norow);
    if (page + 1 <= finalPage) {
      this.setState({ page: page + 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true, pageChanged: true }, async () => {
          await this.props.fetch_residents(pageobj);
          this.setState({
            newResidentList: await this.props.residentList.rows,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepageprevious = () => {
    const { page } = this.state;
    if (page !== 1) {
      this.setState({ page: page - 1, pageChanged: true }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true }, async () => {
          await this.props.fetch_residents(pageobj);
          this.setState({
            newResidentList: await this.props.residentList.rows,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepageinitial = () => {
    if (this.state.page !== 1) {
      this.setState({ page: 1, pageChanged: true }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true, pageChanged: true }, async () => {
          await this.props.fetch_residents(pageobj);
          this.setState({
            newResidentList: await this.props.residentList.rows,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepagefinal = async () => {
    const { page, Norow } = this.state;
    const count = await this.props.residentList.count;
    let last_page = count / Norow;
    if (last_page === 0) {
      last_page = 1;
    }
    const rounded = Math.ceil(last_page);
    if (page < rounded) {
      this.setState({ page: rounded, pageChanged: true }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true, pageChanged: true }, async () => {
          await this.props.fetch_residents(pageobj);
          this.setState({
            newResidentList: await this.props.residentList.rows,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  findMorePressed = () => {
    this.setState({ findMore: true }, () => {
      if (this.state.findMore) {
        if (this.state.searchValue) {
          this.setState({ loader: true, pageChanged: true }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            await this.props.fetch_residents(pageobj);
            this.setState({
              pageChanged: true,
              page: 1,
              newResidentList: await this.props.residentList.rows,
            });
            this.setState({ loader: false });
          });
          setTimeout(() => {
            this.setState({ findMore: true });
          }, 1000);
        }
      } else {
        this.setState({ loader: true, pageChanged: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          await this.props.fetch_residents(pageobj);
          this.setState({
            pageChanged: true,
            page: 1,
            newResidentList: await this.props.residentList.rows,
          });
          this.setState({ loader: false });
        });
        setTimeout(() => {
          this.setState({ findMore: true });
        }, 1000);
      }
    });
  };

  dataTable = (searchValue, newResidentList, residentList, pageChanged) => {
    return (
      <DataTable
        data={searchValue.length || pageChanged ? newResidentList : residentList}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        // subHeader
        defaultSortAsc={false}
        fixedHeader={true}
        fixedHeaderScrollHeight="40vh"
      />
    );
  };

  render() {
    const { loader, api_response, page, Norow, searchValue, newResidentList, residentList, pageChanged } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <Card.Title as="h4">Units</Card.Title>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide">
                  <>
                    <DatatableHeader
                      Header={"List of residents"}
                      searchValue={searchValue}
                      HandleChange={this.handleSearchChange}
                      findMorePressed={this.findMorePressed}
                    />
                  </>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {!api_response && (
                        <CustomContentAlert delay={4000} message={"The server did not respond, Please try again after sometime"} className="toast-error" />
                      )}
                      <>
                        {this.dataTable(searchValue, newResidentList, residentList, pageChanged)}
                        <PaginationforData
                          count={this.state.count}
                          data={this.props.residentList?.rows}
                          handlepageinitial={this.handlepageinitial}
                          handlepageprevious={this.handlepageprevious}
                          handlepagenext={this.handlepagenext}
                          handlepagefinal={this.handlepagefinal}
                          handlepage={this.handlepage}
                          page={page}
                          Norow={Norow}
                        />
                      </>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, ResidenDetails, Logindetails }) => {
  const { api_response, api_error_message } = APIResponseDetails;
  const { user_type, timezone, property } = UserProfileDetails;
  const { residentList } = ResidenDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  return {
    api_response,
    api_error_message,
    residentList,
    user_type,
    timezone,
    property,
    main_body,
  };
};

const mapStateToDispatch = {
  fetch_residents: ResidentsActions.fetch_residents,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(ViewResidents);

import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useSelector, useDispatch } from "react-redux";
import * as UserProfileActions from "../Actions/UserProfileActions/actions";

const IdleTimer = () => {
  const timeout = process.env.REACT_APP_AUTOLOGOUT_TIME;
  const [isIdle, setIsIdle] = useState(false);
  const dispatch = useDispatch();
  const handleOnActive = () => setIsIdle(false);
  const login_id = useSelector((state) => state.UserProfileDetails?.login_id);
  const user_id = useSelector((state) => state.UserProfileDetails?.id);
  const high_security = useSelector((state) => state.UserProfileDetails?.property?.high_security);
  const permission_id = useSelector((state) => state.UserProfileDetails?.permission_id);
  const admin_login = useSelector((state) => state.UserProfileDetails?.admin_login);
  const property_id = useSelector((state) => state.UserProfileDetails?.property?.id);
  const user_offline = useSelector((state) => state.UserProfileDetails?.user_offline);

  const handleOnIdle = async () => {
    console.log("Now I am idle", isIdle, "=======", process.env.REACT_APP_AUTOLOGOUT_TIME);
    setIsIdle(true);
    if (permission_id === 1 && !admin_login) {
      await dispatch(
        UserProfileActions.userLogout({
          userid: user_id,
          permission_id: permission_id,
          highSecureProperty: high_security,
          property_id: property_id,
          login_id: login_id,
          user_offline: user_offline,
        }),
      );
    }
  };

  useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  return <div></div>;
};

export default IdleTimer;

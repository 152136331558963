import t from "../../Actions/ResidentOccupancyActions/types";

const intitialState = {
  residentOccupancy: [],
};

const ResidentOccupancyReducer = (state = intitialState, action) => {
  switch (action.type) {
    case t.FETCH_RESIDENT_OCCUPANCY:
      return {
        ...state,
        residentOccupancy: action.payload,
      };
    default:
      return state;
  }
};

export default ResidentOccupancyReducer;

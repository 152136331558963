const highSecurityPageList = {
  UNIT: "UNIT",
  PERMITS: "PERMITS",
  QUICK_PERMITS: "QUICK PERMITS",
  CURRENT_VISITORS: "CURRENT VISITORS",
  OCCUPANCY: "OCCUPANCY",
  PHOTO_VERIFY: "PHOTO VERIFY",
  VIEW_LAST_CITATION: "VIEW LAST CITATION",
  LOT_SHARING: "LOT SHARING",
  LPR_REPORT: "LPR REPORT",
  CITATION: "CITATION",
  CITATION_PAID_REPORT: "CITATION PAID REPORT- BY SITE",
  CITATION_PAID_REPORT_BY_PAYMENT: "CITATION PAID REPORT- BY PAYMENT",
  CITATION_ISSUED_REPORT: "CITATION ISSUED REPORT- BY SITE",
  CITATION_ISSUED_REPORT_BY_ENFORCER: "CITATION ISSUED REPORT- BY ENFORCER",
  AUDIT_REPORT: "AUDIT REPORT",
  REVENUE_REPORT: "REVENUE REPORT",
  DISPENSER_REPORT: "DISPENSER REPORT",
  VALIDATION_REPORT: "VALIDATION REPORT",
  RATE_TRACKING_REPORT: "RATE TRACKING REPORT",
  TRANSACTION_REPORT: "TRANSACTION REPORT",
  VEND_CONTROL_REPORT: "VEND CONTROL REPORT",
  CITATION_ANALYSIS_REPORT: "CITATION ANALYSIS REPORT",
  REFUND_REPORT: "REFUND_REPORT",
  UPDATE_PROFILE: "MY ACCOUNT",
  CHANGE_PASSWORD: "CHANGE PASSWORD",
  PARKING_LOG: "PARKING LOG",
  CONTACT_SUPPORT: "CONTACT SUPPORT",
  LIVE_FEED: "LIVE FEED",
  ONPREMISE: "LIVE FEED- ON PREMISE",
  REMOTE: "LIVE FEED- REMOTE",
  SMART_GATE: "SMART GATE",
  LOGOUT: "LOGOUT",
  LOGIN: "LOGIN",
  DISPUTE: "DISPUTE",
  VIEW_DASHBOARD: "DASHBOARD",
  ARCHIVED_PROPERTIES: "ARCHIVED PROPERTIES",
  ALL_PROPERTIES: "ALL PROPERTIES",
  USER_SEARCH: "USER SEARCH",
  SITE_WIZARD: "SITE WIZARD",
  COPY_SETTING: "COPY SETTING",
  USER_TYPES: "USER TYPES",
  SITE_SETUP_PERMISSIONS: "SITE SETUP - PERMISSIONS",
  SITE_SETUP_ADD_USERS: "SITE SETUP - ADD USERS",
  SITE_SETUP_TEMPLATE: "SITE SETUP - TEMPLATE",
  SITE_SETUP_CITATION_SETUP: "SITE SETUP - CITATION SETUP",
  SITE_SETUP_ADD_LOT: "SITE SETUP - ADD LOT",
  SITE_SETUP_ADD_LANE: "SITE SETUP- ADD LANE",
  SITE_SETUP_ADD_CAMERA: "SITE SETUP - ADD CAMERA",
  SITE_SETUP_ADD_EDGE_DEVICE: "SITE SETUP - ADD EDGE DEVICE",
  SITE_SETUP_ADD_METER_DEVICE: "SITE SETUP - ADD METER DEVICE",

  SITE_SET_UP_SLOTS: "SITE SETUP - SLOTS",
  SITE_SETUP_SETTINGS: "SITE SETUP - SETTINGS",
  SITE_SETUP_SELF_QUICK_PERMIT_SETUP: "SITE SETUP - SELF SERVE QUICK PERMIT SETUP",
  SITE_SETUP_PARKING_PERMIT_SETUP: "SITE SETUP - PARKING PERMIT SETUP",
  SITE_SETUP_MANAGE_PERMIT_PACKAGES: "SITE SETUP- MANAGE PERMIT PACKAGES",
  SITE_SETUP_ADD_UNIT_PERMIT_PACKAGES: "SITE SETUP- ADD UNIT PERMIT PACKAGES",
  PAID_PERMIT_HISTORY_SHOW: "DRIVER - PAID PERMIT HISTORY_SHOW",
  DRIVER_PAID_PERMIT_CREATE: "DRIVER - PAID PERMIT CREATE",
  UNIT_SETUP: "UNIT SETUP",
  DISCOUNT_COUPON_SUMMARY_REPORT: "DISCOUNT COUPON SUMMARY REPORT",
  BATCH_VALIDATION_REPORT: "BATCH VALIDATION REPORT",
  GATE_SCHEDULERS: "GATE SCHEDULERS",
  DISCOUNT_COUPONS: "DISCOUNT COUPONS",
  PARKING_RATE_CHECK: "PARKING RATE CHECK",
  OFFLINE_CITATION: "OFFLINE CITATION",
  PASSAGEPASS_PAYMENT_TRANSACTION: "PASSAGEPASS PAYMENT TRANSACTION",
  PASSAGEPASS_PERMIT_HISTORY: "PASSAGEPASS PERMIT HISTORY",
  BATTERY_REPORT: "BATTERY REPORT",
  LENGTH_OF_STAY_REPORT: "LENGTH OF STAY REPORT",
  METER_STATUS_REPORT: "METER STATUS REPORT",
  LAST_TRANSACTION_REPORT: "LAST TRANSACTION REPORT",
  WAITLIST: "WAITLIST",
};

exports.highSecurityPageList = highSecurityPageList;

import React from "react";
import {
  Modal,
  Card,
  Button,
  ModalBody,
  Form,
  // Row,
  // Col,
} from "react-bootstrap";
import {
  useDispatch, // useSelector
} from "react-redux";
import _ from "lodash";
import { SuperAdminAction } from "../../Actions";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useLocation } from "react-router";

const SetCashierPermission = ({ modelOpenClose, handleClose, propertyId, handleCashierPermission, cashierDataToBeEdited }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [
    pages, // setPages
  ] = React.useState();
  const [isEdit, setEdit] = React.useState(false);

  const [validation, setValidation] = React.useState({
    cashier_name: "",
    user_name: "",
    password: "",
  });

  const [validation1, setValidation1] = React.useState({
    iscashiername: true,
    isusername: true,
    ispassword: true,
  });
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [state, setState] = React.useState({
    cashier_name: "",
    user_name: "",
    password: "",
  });


  React.useEffect(() => {
    dispatch(SuperAdminAction.get_all_properties());
    if (propertyId) {
      dispatch(SuperAdminAction.get_permissions(propertyId));
    }
  }, [dispatch, propertyId]);


  React.useEffect(() => {
    if (!_.isEmpty(cashierDataToBeEdited)) {
      setState({
        cashier_name: cashierDataToBeEdited.cashier_name,
        user_name: cashierDataToBeEdited.user_name,
        password: cashierDataToBeEdited.password,
        id: cashierDataToBeEdited.id,
      });
      setIsFormValid(true);
      setValidation1({
        iscashiername: false,
        isusername: false,
        ispassword: false,
      });
      setEdit(true);
    } else {
      setState({
        cashier_name: "",
        user_name: "",
        password: "",
      });
    }
  }, [cashierDataToBeEdited]);


  React.useEffect(() => {
    if (isEdit) {
      setIsFormValid(true);
    }
  }, [state.cashier_name, state.user_name, isEdit, state.password]);

  const togglePassword = () => {
    setState((prev) => ({
      ...prev,
      contentvisible: !state.contentvisible,
    }));
  };

  const handleSave = React.useCallback(() => {
       handleCashierPermission(pages, state, isEdit);
  }, [state, pages]);


  const handleChange = React.useCallback((e) => {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      setValidations(e);
  }, []);

  const setValidations = React.useCallback(
    (e) => {
    const regex_cashname = /^[A-Za-z\s]{3,30}$/;
    const regex_username = /^[A-Za-z\s]{3,30}$/;
    const regex_password = /^[a-zA-Z0-9]{5,20}$/;
    const value = e.target.value;
    console.log(value);
      if (!e.target.value) {
        console.log("no value::::::: setValidations");
        if (e.target.name === "password" || e.target.name === "cashier_name" || e.target.name === "user_name") {
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
          console.log("password", validation1.ispassword);
        } else {
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.name + " is required",
          }));
          setIsFormValid(true);
        }
        setValidation1((prevState) => ({
          ...prevState,
          iscashiername: false,
        }));
        setValidation((prevState) => ({
          ...prevState,
          [e.target.name]: "",
        }));

        setValidation1((prevState) => ({
          ...prevState,
          isusername: false,
        }));
        setValidation((prevState) => ({
          ...prevState,
          [e.target.name]: "",
        }));
      } else {
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
      }
    if(regex_password.test(value) == false && e.target.name === "password"){
        setValidation((prevState) => ({
          ...prevState,
          [e.target.name]:"Password required to have 5 to 20 characters also Alphanumeric only.",
        }));
        setIsFormValid(false);
    }else if(regex_username.test(value) == false && e.target.name === "user_name"){
            setValidation((prevState) => ({
          ...prevState,
          [e.target.name]:"All username fields required to have 3 to 30 characters it has to be alphabet only.",
        }));
        setIsFormValid(false);
    }else if(regex_cashname.test(value) == false && e.target.name === "cashier_name"){
        setValidation((prevState) => ({
          ...prevState,
          [e.target.name]:"Name required to have 3 to 30 characters also it has to be alphabet only.",
        }));
        setIsFormValid(false);
    }else{
      setIsFormValid(true);
    }
    },
    [validation],
  );

  return (
    <div>
      <Modal lg={"medium"} show={modelOpenClose} backdrop={"static"} onHide={handleClose} dialogClassName="modal-90w" fullscreen={"sm-down"}>
        <Card.Header className="d-flex justify-content-between bg-white align-items-center">
          <h4 className="m-0 font-weight-bold">
            <Card.Title className="m-0">
              {isEdit && "Edit"}
              {!isEdit && "Add"} {location.pathname === "/manage-admin" ? "Cashier" : "User"}
            </Card.Title>
          </h4>
          <Button title={"Deactivate"} type="button" onClick={handleClose} className="close-btn py-0" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove text-danger" />
          </Button>
        </Card.Header>
        <PerfectScrollbar style={{ height: "70vh" }}>
          <ModalBody>
            <Form.Group>
              <label>
                Name <span className="asterisk">*</span>
              </label>
              <Form.Control
                placeholder="Name"
                type="text"
                name="cashier_name"
                value={state?.cashier_name}
                autoComplete="off"
                required
                onChange={handleChange}
                disabled={isEdit}
              />
              <div className="help-block">{!_.isEmpty(validation) ? validation.cashier_name : "" || state.cashier_name }</div>
               <label>
                    Username <span className="asterisk">*</span>
                  </label>
                  <Form.Control
                    placeholder="Username"
                    type="text"
                    name="user_name"
                    value={state?.user_name}
                    autoComplete="off"
                    onChange={handleChange}
                    required
                    disabled={isEdit}
                  />
                  <div className="help-block">{!_.isEmpty(validation) ? validation.user_name : ""}</div>
              {((
                <div>
                  <label className="field-lbl">Password<span className="asterisk">*</span></label>
                  <div className="input-mask-btn">
                    <Form.Control
                      placeholder="Password"
                      type={state.contentvisible ? "text" : "password"}
                      className="form-control"
                      name="password"
                      value={state?.password}
                      onChange={handleChange}
                      required
                      autoComplete="new-password"
                    />
                    <button className="mask-password" type="button" onClick={togglePassword}>
                      {state.contentvisible ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                    </button>
                  </div>
                  <div className="help-block">
                    {!_.isEmpty(validation) ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: validation.password,
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )) ||
                ""}
            </Form.Group>
          </ModalBody>
        </PerfectScrollbar>
        <Modal.Footer>
          <Button disabled={!isFormValid} onClick={handleSave}>
            {"Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SetCashierPermission;

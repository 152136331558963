import React, { Component } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import CustomContentAlert, { findBySearch } from "../helper"; // findBySearch, // routing_using_history,
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import { SuperAdminAction } from "../../Actions";
import moment from "moment";
import CreatePropertyForm from "../CreateProperty/CreatePropertyForm";
import PaginationforData from "../pagination/pagination";
class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      prevPage: "",
      prevSearch: "",
      prevNorow: "",
      pageChanged: false,
      filterdProperties: [],
      userList: [],
      newPropertyList: [],
      count: 0,
      loader: false,
      api_response: false,
      showPropertyModal: false,
      isModalOpen: false,
      page: 1,
      Norow: 10,
      selectedColumn: "created_at",
      asc: "false",
      sort_direction: "DESC",
      findMore: false,
    };
    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "0", // override the cell padding for head cells
          paddingRight: "0",
        },
      },
      cells: {
        style: {
          paddingLeft: "0", // override the cell padding for data cells
          paddingRight: "0",
        },
      },
    };

    this.columns = [
      {
        name: "Email",
        sortable: true,
        width: "120px",
        selector: (row) => row.email,
        cell: this.emailRow,
        // cell: (row) => <div>{moment(row.createdAt).format("lll")}</div>,
      },
      {
        name: "First Name",
        center: true,
        sortable: true,
        selector: (row) => row.first_name,
        cell: this.firstNameRow,
      },
      {
        name: "Last Name",
        center: true,
        sortable: true,
        selector: (row) => row.last_name,
        cell: this.lastNameRow,
      },
      {
        name: "Username",
        center: true,
        sortable: true,
        selector: (row) => row.username,
        cell: this.UseNameRow,
      },
      {
        name: "Phone",
        center: true,
        sortable: true,
        selector: (row) => row.phone,
        cell: this.phoneRow,
      },
      {
        name: "Usertype",
        center: true,
        sortable: true,
        selector: (row) => row.user_type,
        cell: this.UserTypeRow,
      },
      {
        name: "Property",
        center: true,
        sortable: true,
        selector: (row) => row.user_type,
        cell: this.PropertyRow,
      },
      {
        name: "Created At",
        center: true,
        sortable: true,
        selector: (row) => row.created_at,
        cell: this.createdAtRow,
      },
    ];
  }

  emailRow = (row) => <div>{row.email}</div>;

  firstNameRow = (row) => <div>{row.first_name}</div>;

  lastNameRow = (row) => <div>{row.last_name}</div>;

  UseNameRow = (row) => <div>{row.username}</div>;

  phoneRow = (row) => <div>{row.phone}</div>;

  UserTypeRow = (row) => <div>{row.user_type}</div>;

  PropertyRow = (row) => <div>{row.user_type}</div>;

  createdAtRow = (row) => <div>{moment(row.created_at).format("lll")}</div>;

  componentDidMount() {
    this.setState({ loader: true }, async () => {
      const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
      const pageobj = {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        all: 1,
      };
      await this.props.fetchUsersPaginate(pageobj);
      this.setState({
        userList: this.props.userList,
        count: this.props.count,
        api_response: this.props.api_response,
      });
      this.setState({ loader: false });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userList.length !== this.props.userList.length) {
      this.setState({
        newPropertyList: this.props.userList,
        count: this.props.count,
      });
    }
  }

  handleSearchChange = ({ target }) => {
    this.setState({
      prevSearch: this.state.searchValue,
      page: 1,
      pageChanged: true,
    });
    this.setState({ searchValue: target.value }, () => {
      if (this.state.searchValue.length) {
        if (!this.state.findMore) {
          this.setState(
            {
              newPropertyList: findBySearch(this.state.searchValue, this.state.userList, this.state.userList),
            },
            () => {
              this.setState({ count: this.state.newPropertyList.length });
            },
          );
        }
      } else {
        this.setState({ loader: true, pageChanged: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            all: 1,
          };
          await this.props.fetchUsersPaginate(pageobj);
          this.setState({
            pageChanged: true,
            page: 1,
            newPropertyList: this.state.newPropertyList,
          });
          this.setState({ loader: false });
        });
      }
      setTimeout(() => {
        this.setState({ findMore: false });
      }, 1000);
    });
  };

  handleCreateProperty = () => {
    this.setState({
      showPropertyModal: true,
      isModalOpen: !this.state.isModalOpen,
    });
  };

  handlepage = ({ target }) => {
    this.setState({ prevPage: this.state.page, prevNorow: this.state.Norow });
    this.setState(
      {
        [target.name]: target.value,
      },
      async () => {
        switch (target.name) {
          case "page":
            this.setState({ page: target.value }, async () => {
              this.setState({ loader: true, pageChanged: true }, async () => {
                const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
                const pageobj = {
                  page,
                  Norow,
                  searchValue,
                  selectedColumn,
                  sort_direction,
                  all: 1,
                };
                await this.props.fetchUsersPaginate(pageobj);
                this.setState({
                  userList: this.props.userList,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
          case "Norow":
            this.setState({ Norow: target.value }, async () => {
              this.setState({ loader: true, pageChanged: true }, async () => {
                if (this.state.Norow >= this.state.count) {
                  this.setState(
                    {
                      page: 1,
                    },
                    async () => {
                      const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
                      const pageobj = {
                        page,
                        Norow,
                        searchValue,
                        selectedColumn,
                        sort_direction,
                        all: 1,
                      };
                      await this.props.fetchUsersPaginate(pageobj);
                    },
                  );
                } else {
                  const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
                  const pageobj = {
                    page,
                    Norow,
                    searchValue,
                    selectedColumn,
                    sort_direction,
                    all: 1,
                  };
                  await this.props.fetchUsersPaginate(pageobj);
                }
                this.setState({
                  userList: this.props.userList,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
        }
        if (Number(this.state.page) !== Number(this.state.prevPage) || Number(this.state.Norow) !== Number(this.state.prevNorow)) {
          const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            all: 1,
          };
          this.setState({ loader: true }, async () => {
            await this.props.fetchUsersPaginate(pageobj);
            this.setState({
              newPropertyList: this.props.userList,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        }
      },
    );
  };

  handleonsort = async (e) => {
    this.setState({ asc: !this.state.asc }, () => {
      const { asc } = this.state;
      if (!asc) {
        this.setState({ sort_direction: "ASC" }, async () => {
          this.setState({ selectedColumn: e.name }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
              all: 1,
            };
            this.setState({ loader: true }, async () => {
              await this.props.fetchUsersPaginate(pageobj);
              this.setState({
                propertiesAvailable: await this.props.userList,
                api_response: this.props.api_response,
              });
              this.setState({ loader: false });
            });
          });
        });
      } else {
        this.setState({ sort_direction: "DESC" }, async () => {
          this.setState({ selectedColumn: e.name }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
              all: 1,
            };
            this.setState({ loader: true }, async () => {
              await this.props.fetchUsersPaginate(pageobj);
              this.setState({
                propertiesAvailable: await this.props.userList,
                api_response: this.props.api_response,
              });
              this.setState({ loader: false });
            });
          });
        });
      }
    });
  };

  handleRowsPerPage = () => {
    const { Norow, page } = this.state;
    return { Norow, page };
  };

  handlepagenext = () => {
    const { page, Norow } = this.state;
    const count = this.props.count;
    const finalPage = Math.ceil(count / Norow);
    if (page + 1 <= finalPage) {
      this.setState({ page: page + 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          all: 1,
        };
        this.setState({ loader: true, pageChanged: true }, async () => {
          await this.props.fetchUsersPaginate(pageobj);
          this.setState({
            newPropertyList: await this.props.userList,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepageprevious = () => {
    const { page } = this.state;
    if (page !== 1) {
      this.setState({ page: page - 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          all: 1,
        };
        this.setState({ loader: true }, async () => {
          await this.props.fetchUsersPaginate(pageobj);
          this.setState({
            newPropertyList: this.props.userList,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepageinitial = () => {
    if (this.state.page !== 1) {
      this.setState({ page: 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          all: 1,
        };
        this.setState({ loader: true }, async () => {
          await this.props.fetchUsersPaginate(pageobj);
          this.setState({
            newPropertyList: this.props.userList,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepagefinal = async () => {
    const { page, Norow } = this.state;
    const count = this.props.count;
    let last_page = count / Norow;
    if (last_page === 0) {
      last_page = 1;
    }
    const rounded = Math.ceil(last_page);
    if (page < rounded) {
      this.setState({ page: rounded, pageChanged: true }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          all: 1,
        };
        this.setState({ loader: true, pageChanged: true }, async () => {
          await this.props.fetchUsersPaginate(pageobj);
          this.setState({
            newPropertyList: this.props.userList,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  findMorePressed = () => {
    this.setState({ findMore: true }, () => {
      if (this.state.findMore) {
        if (this.state.searchValue) {
          this.setState({ loader: true, pageChanged: true }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
              all: 1,
            };
            await this.props.fetchUsersPaginate(pageobj);
            this.setState({
              pageChanged: true,
              page: 1,
              newPropertyList: this.props.userList,
            });
            this.setState({ loader: false });
          });
          setTimeout(() => {
            this.setState({ findMore: true });
          }, 1000);
        }
      } else {
        this.setState({ loader: true, pageChanged: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            all: 1,
          };
          await this.props.fetchUsersPaginate(pageobj);
          this.setState({
            pageChanged: true,
            page: 1,
            newPropertyList: this.props.userList,
          });
          this.setState({ loader: false });
        });
        setTimeout(() => {
          this.setState({ findMore: true });
        }, 1000);
      }
    });
  };

  render() {
    const { searchValue, userList, newPropertyList, pageChanged, loader, api_response, showPropertyModal, isModalOpen, page, Norow } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">All Users</Card.Title>
                      <p className="card-category">All Users</p>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide">
                  <>
                    <DatatableHeader
                      Header={"Users"}
                      searchValue={this.state.searchValue}
                      HandleChange={this.handleSearchChange}
                      findMorePressed={this.findMorePressed}
                    />
                  </>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {!api_response && (
                        <CustomContentAlert delay={2000} message={"The server did not respond, Please try again after sometime"} className="toast-error" />
                      )}
                      <DataTable
                        data={searchValue.length || pageChanged ? newPropertyList : userList}
                        columns={this.columns}
                        customStyles={this.customStyles}
                        // pagination={true}
                        paginationServer={true}
                        responsive={true}
                        subHeader
                        defaultSortAsc={false}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="38vh"
                      />
                      <PaginationforData
                        count={this.state.count}
                        data={this.props?.userList}
                        handlepageinitial={this.handlepageinitial}
                        handlepageprevious={this.handlepageprevious}
                        handlepagenext={this.handlepagenext}
                        handlepagefinal={this.handlepagefinal}
                        handlepage={this.handlepage}
                        page={page}
                        Norow={Norow}
                      />
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {showPropertyModal ? <CreatePropertyForm show={isModalOpen} closeModal={() => this.setState({ isModalOpen: !this.state.isModalOpen })} /> : null}
      </div>
    );
  }
}

const mapStatetoProps = ({ SuperAdminDetails, APIResponseDetails }) => {
  const { userList, userCount: count } = SuperAdminDetails;
  const { api_response } = APIResponseDetails;
  return { api_response, userList, count };
};
const mapStateToDispatch = {
  get_all_properties: SuperAdminAction.get_all_properties,
  fetchUsersPaginate: SuperAdminAction.fetchUsersPaginate,
};

export default connect(mapStatetoProps, mapStateToDispatch)(AddUser);

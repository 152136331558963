import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Button, Form, FormLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ReportsAction, SuperAdminAction } from "../../Actions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
const history = require("../../history").default;

const AddRateForm = () => {
  const dispatch = useDispatch();
  const apiResponse = useSelector(getAPIResponse);
  const rateTypeList = useSelector((state) => state.ReportsDetails.passagepassRateTypeList);
  const { property } = useSelector((userState) => userState.UserProfileDetails);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { pathname } = location;
  const location_id = queryParams.get("location_id");
  const [isEdit, setIsEdit] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [editData, setEditData] = useState({});
  const [form, setForm] = useState({
    display_name: "",
    rate_description: "",
    entry_window_start: "",
    entry_window_stop: "",
    day: "",
    max_amount: "",
    effective_start: "",
    effective_stop: "",
    rate_category: "holiday",
    rate_type: "",
    isSetMaxTime: 0,
    isSetWindowMaxTime: 0,
    isSetDurationMaxtime: 0,
  });
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedRadioOption, setSelectedRadioOption] = useState("definiteTime");
  const optionList = [
    { value: "0", label: "Sunday" },
    { value: "1", label: "Monday" },
    { value: "2", label: "Tuesday" },
    { value: "3", label: "Wednesday" },
    { value: "4", label: "Thursday" },
    { value: "5", label: "Friday" },
    { value: "6", label: "Saturday" },
  ];
  const [definiteDay, setDefiniteDay] = useState("0");
  const [definiteTime, setDefiniteTime] = useState("");
  const [durationDays, setDurationDays] = useState(0);
  const [durationHours, setDurationHours] = useState(0);
  const [durationMinutes, setDurationMinutes] = useState(0);

  const daysOptions = [
    { value: "0", label: "Same Day" },
    { value: "1", label: "+1 Day" },
    { value: "2", label: "+2 Days" },
    { value: "3", label: "+3 Days" },
    { value: "4", label: "+4 Days" },
    { value: "5", label: "+5 Days" },
    { value: "6", label: "+6 Days" },
    { value: "7", label: "+7 Days" },
  ];

  useEffect(() => {
    dispatch(ReportsAction.getPassagePassRateType());
    const data = location?.state;
    if (data) {
      setEditData(data);
    }
  }, []);

  useEffect(() => {
    dispatch(SuperAdminAction.setDomainHeader(property?.property_name, property?.domain));
    if (pathname === "/edit-parking-rate") {
      setIsEdit(true);

      const data = location?.state;
      console.log("data:::::::", data);
      if (data.day) {
        const selectedValues = data.day?.split(","); // e.g., ["1", "2"]
        const selected = optionList.filter((option) => selectedValues?.includes(option.value));
        setSelectedOptions(selected);
      }

      setForm({
        display_name: data.display_name || "",
        rate_description: data.rate_description || "",
        rate_type: data.rate_type_id,
        max_amount: data.max_amount,
        rate_category: data.day ? "regular" : "holiday",
      });

      if (data.rate_expires) {
        if (data.rate_expires?.includes("P")) {
          setSelectedRadioOption("duration");
          const durationMatch = data.rate_expires?.match(/P(\d+)DT(\d+)H(\d+)M/) || "";
          console.log("durationMatch::::", durationMatch);
          if (durationMatch) {
            setDurationDays(durationMatch?.[1]);
            setDurationHours(durationMatch?.[2]);
            setDurationMinutes(durationMatch?.[3]);
          }
        } else {
          setSelectedRadioOption("definiteTime");
          setDefiniteDay(data.rate_expires?.split("DT")[0] || "Same Day");
          setDefiniteTime(convertTimeStringToDate(data.rate_expires?.split("DT")[1]));
        }
      }

      if (data?.formatted_effective_start && moment(data?.formatted_effective_start).isValid()) {
        setForm((prevForm) => ({
          ...prevForm,
          effective_start: new Date(data.formatted_effective_start),
        }));
      }
      if (data?.formatted_effective_stop && moment(data?.formatted_effective_stop).isValid()) {
        const effectiveStopTime = moment(data.formatted_effective_stop).format("HH:mm");
        const isSetTime = effectiveStopTime === "23:59";
        setForm((prevForm) => ({
          ...prevForm,
          effective_stop: new Date(data.formatted_effective_stop),
          isSetMaxTime: isSetTime,
        }));
      }

      if (data.entry_window_start && data.entry_window_stop) {
        const isSetTime = data.entry_window_stop === "23:59:59";
        setForm((prevForm) => ({
          ...prevForm,
          entry_window_start: data.entry_window_start ? convertTimeStringToDate(data.entry_window_start) : null,
          entry_window_stop: data.entry_window_stop ? convertTimeStringToDate(data.entry_window_stop) : null,
          isSetWindowMaxTime: isSetTime,
        }));
      }
    }
  }, [pathname]);

  const convertTimeStringToDate = (timeString) => {
    if (!timeString || typeof timeString !== "string") {
      return null;
    }

    const parts = timeString.split(":").map(Number);
    const date = new Date();

    if (parts.length >= 1) {
      date.setHours(parts[0]);
    }
    if (parts.length >= 2) {
      date.setMinutes(parts[1]);
    }
    if (parts.length >= 3) {
      date.setSeconds(parts[2]);
    }
    return date;
  };

  const formatFirstOption = () => {
    return definiteDay ? `${definiteDay}DT${moment(definiteTime).format("HH:mm:ss")}` : moment(definiteTime).format("HH:mm:ss");
  };

  const formatSecondOption = () => {
    return `P${durationDays}DT${durationHours}H${durationMinutes}M`;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const handleMaxTime = () => {
    if (!form.isSetMaxTime) {
      let endDate = form.effective_stop ? new Date(form.effective_stop) : new Date();
      endDate = moment(endDate).set({ hour: 23, minute: 59, second: 59 });
      setForm((prevForm) => ({ ...prevForm, effective_stop: new Date(endDate) }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        effective_stop: "",
      }));
    }
    setForm((prevForm) => ({ ...prevForm, isSetMaxTime: !form.isSetMaxTime }));
  };
  const handleWindowMaxTime = () => {
    if (!form.isSetWindowMaxTime) {
      const endDate = moment(new Date()).set({ hour: 23, minute: 59, second: 59 });
      setForm((prevForm) => ({ ...prevForm, entry_window_stop: new Date(endDate) }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        entry_window_stop: "",
      }));
    } else {
      setForm((prevForm) => ({ ...prevForm, entry_window_stop: null }));
    }
    setForm((prevForm) => ({ ...prevForm, isSetWindowMaxTime: !form.isSetWindowMaxTime }));
  };
  const handleDurationMaxTime = () => {
    if (!form.isSetDurationMaxtime) {
      const endDate = moment(new Date()).set({ hour: 23, minute: 59, second: 59 });
      setDefiniteTime(new Date(endDate));
    } else {
      setDefiniteTime(null);
    }
    setForm((prevForm) => ({ ...prevForm, isSetDurationMaxtime: !form.isSetDurationMaxtime }));
  };

  const validate = () => {
    let errors1 = { ...errors }; // Keep existing errors
    const requiredFields =
      form.rate_category === "holiday"
        ? ["display_name", "rate_type", "rate_description", "max_amount", "effective_start", "effective_stop"]
        : ["display_name", "rate_type", "rate_description", "max_amount", "entry_window_start", "entry_window_stop"];

    requiredFields.forEach((field) => {
      if (!form[field]) {
        errors1[field] = `${capitalizeFirstLetter(field.replace(/_/g, " "))} is required`;
      }
    });

    if (selectedRadioOption === "definiteTime" && !definiteTime) {
      errors1["definite_time"] = "Definite time is required";
    } else if (selectedRadioOption === "duration" && !durationDays && !durationHours && !durationMinutes) {
      errors1["duration"] = "Duration is required";
    }
    if (!selectedOptions.length && form.rate_category === "regular") {
      errors1.day = "Day(s) of week is required";
    }

    errors1 = Object.fromEntries(Object.entries(errors1).filter(([key, value]) => value));

    setErrors(errors1);
    return Object.keys(errors1).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const isValidNumber = (/^\d+(\.\d{0,2})?$/.test(value) && parseFloat(value) > 0) || value === "";

    if (name === "max_amount") {
      if (isValidNumber) {
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Amount must be a positive number greater than 0 with up to two decimal places",
        }));
      }
    } else {
      setForm((prevForm) => ({ ...prevForm, [name]: value }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleDateChange = (date, name) => {
    let newDate = moment(date);

    if (name === "effective_start") {
      // Set time to 00:00 if it's a new selection
      if (!form.effective_start || !moment(form.effective_start).isSame(date, "day")) {
        newDate = newDate.startOf("day");
      }
      setForm({ ...form, effective_start: newDate.toDate(), effective_stop: null });
    } else if (name === "effective_stop") {
      if (form.isSetMaxTime) {
        newDate = moment(newDate).set({ hour: 23, minute: 59, second: 59 });
      }
      setForm({ ...form, effective_stop: new Date(newDate) });
      if (form.effective_start) {
        const start = moment(form.effective_start);
        if (start.isSame(newDate, "day") && !start.isBefore(newDate)) {
          setErrors({
            ...errors,
            effective_stop: "Effective stop time must be after effective start time.",
          });
        } else {
          setErrors({
            ...errors,
            effective_stop: "",
          });
        }
      }
    } else if (name === "entry_window_start") {
      setForm({ ...form, entry_window_start: date, entry_window_stop: null, isSetWindowMaxTime: false });
    } else if (name === "entry_window_stop") {
      if (!form.isSetWindowMaxTime) {
      setForm({ ...form, entry_window_stop: date });
      }
      if (form.entry_window_start) {
        const start = moment(form.entry_window_start);
        const stop = moment(date);
        if (start.isSameOrAfter(stop)) {
          setErrors({
            ...errors,
            entry_window_stop: "Entry Window Stop time must be after Entry Window Start time.",
          });
        } else {
          setErrors({
            ...errors,
            entry_window_stop: "",
          });
        }
      }
    } else {
      setForm({ ...form, [name]: date });
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleSelect = (data) => {
    setSelectedOptions(data);
    const selectedValues = data.map((option) => option.value).join(", ");
    setForm({ ...form, day: selectedValues });
    setErrors({ ...errors, day: "" });
  };

  const handleRateCategoryChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let result;
    if (selectedRadioOption === "definiteTime") {
      result = formatFirstOption();
    } else {
      result = formatSecondOption();
    }

    if (validate()) {
      const selectedDays = selectedOptions.map((option) => option.value).join(",");
      const data = {
        ...form,
        rate_expires: result,
        location_id: location_id,
        day: selectedDays,
        rate_category: form.rate_category,
        entry_window_start: form.entry_window_start ? moment(form.entry_window_start).format("HH:mm:ss") : "",
        entry_window_stop: form.entry_window_stop ? moment(form.entry_window_stop).format("HH:mm:ss") : "",
        effective_start: form.effective_start ? moment(form.effective_start).format("YYYY-MM-DD HH:mm:ss") : "",
        effective_stop: form.effective_stop ? moment(form.effective_stop).format("YYYY-MM-DD HH:mm:ss") : "",
        exportValue: false,
      };
      if (isEdit) {
        data.id = editData.id;
        dispatch(ReportsAction.UpdatePassagePassRate(data));
      } else {
        dispatch(ReportsAction.CreatePassagePassRate(data));
      }
      setApiCall(true);
    }
  };

  useEffect(() => {
    if (apiResponse.api_response && apiCall) {
      setApiCall(false);
      history.push(`/parking-rate?lot_id=${editData.lot_id}&location_id=${editData.location_id}`);
    }
  }, [apiResponse]);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title as="h4">{isEdit ? "Update" : "Add"} Parking Rate</Card.Title>
                <p className="card-category">Fill out the below form to {isEdit ? "update" : "add"} a parking rate.</p>
              </Card.Header>
              <Card.Body className="pos-rel-overflow-hide">
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="rateType">
                        <Form.Label>
                          Rate Type
                          <span className="asterisk">*</span>
                        </Form.Label>
                        <Form.Control as="select" name="rate_type" value={form.rate_type} onChange={handleRateCategoryChange}>
                          <option value="">--Select Rate Type--</option>
                          {(rateTypeList ?? []).map((i) => (
                            <option key={i.id} value={i.id}>
                              {i.label}
                            </option>
                          ))}
                        </Form.Control>
                        <div className="help-block">{errors.rate_type}</div>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>
                          Rate Category
                          <span className="asterisk">*</span>
                        </Form.Label>
                        <Form.Control disabled={isEdit} as="select" name="rate_category" value={form.rate_category} onChange={handleRateCategoryChange}>
                          <option value="holiday">Holiday/Event Rate</option>
                          <option value="regular">Regular Rate</option>
                        </Form.Control>
                        <div className="help-block">{errors.rate_category}</div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>
                          Rate Title
                          <span className="asterisk">*</span>
                        </Form.Label>
                        <Form.Control type="text" name="display_name" value={form.display_name} onChange={handleChange} />
                        <div className="help-block">{errors.display_name}</div>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>
                          Rate Description
                          <span className="asterisk">*</span>
                        </Form.Label>
                        <Form.Control type="text" name="rate_description" value={form.rate_description} onChange={handleChange} />
                        <div className="help-block">{errors.rate_description}</div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>
                          Amount
                          <span className="asterisk">*</span>
                        </Form.Label>
                        <Form.Control type="text" name="max_amount" value={form.max_amount} min={1} onChange={handleChange} />
                        <div className="help-block">{errors.max_amount}</div>
                      </Form.Group>
                    </Col>
                  </Row>
                  {form.rate_category === "holiday" ? (
                    <>
                      <Row>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>
                              Effective Start
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <DatePicker
                              className="form-control w-100"
                              placeholderText="Select Date"
                              selected={form.effective_start}
                              onChange={(date) => handleDateChange(date, "effective_start")}
                              dateFormat="yyyy-MM-dd HH:mm"
                              showTimeSelect
                              timeIntervals={30}
                              timeFormat="HH:mm"
                              minDate={new Date()}
                            />
                            <div className="help-block">{errors.effective_start}</div>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>
                              Effective Stop
                              <span className="asterisk">*</span>
                              <OverlayTrigger
                                delay={{ show: 250, hide: 400 }}
                                placement={"top"}
                                overlay={<Tooltip style={{ zIndex: "20000" }}>When checked, it sets max_time to 23:59, indicating end of day.</Tooltip>}
                              >
                                <FormLabel className="ml-auto d-flex align-items-center text-nowrap">
                                  <input type="checkbox" className="mr-1" checked={form.isSetMaxTime} onChange={handleMaxTime}></input>
                                  <span>Max Time</span>{" "}
                                </FormLabel>
                              </OverlayTrigger>{" "}
                            </Form.Label>
                            <DatePicker
                              className="form-control w-100"
                              placeholderText="Select Date"
                              selected={form.effective_stop}
                              onChange={(date) => handleDateChange(date, "effective_stop")}
                              dateFormat="yyyy-MM-dd HH:mm"
                              showTimeSelect
                              timeIntervals={30}
                              timeFormat="HH:mm"
                              minDate={form.effective_start}
                            />
                            <div className="help-block">{errors.effective_stop}</div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col md={6}>
                          <Form.Group>
                            <FormLabel>
                              Day(s) of Week
                              <span className="asterisk">*</span>
                            </FormLabel>
                            <Select options={optionList} placeholder="Select Day(s) of Week" value={selectedOptions} onChange={handleSelect} isMulti />
                            <div className="help-block">{errors.day}</div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>
                              Entry Window Start
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <DatePicker
                              className="form-control w-100"
                              placeholderText="Select Date"
                              selected={form.entry_window_start}
                              onChange={(date) => handleDateChange(date, "entry_window_start")}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              timeFormat="HH:mm"
                              dateFormat="HH:mm"
                            />
                            <div className="help-block">{errors.entry_window_start}</div>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>
                              Entry Window Stop
                              <span className="asterisk">*</span>
                              <OverlayTrigger
                                delay={{ show: 250, hide: 400 }}
                                placement={"top"}
                                overlay={<Tooltip>When checked, it sets max_time to 23:59, indicating end of day.</Tooltip>}
                              >
                                <FormLabel className="ml-auto d-flex align-items-center text-nowrap">
                                  <input type="checkbox" className="mr-1" checked={form.isSetWindowMaxTime} onChange={handleWindowMaxTime}></input>
                                  <span>Max Time </span>{" "}
                                </FormLabel>
                              </OverlayTrigger>
                            </Form.Label>
                            <DatePicker
                              className="form-control w-100"
                              placeholderText="Select Time"
                              selected={form.entry_window_stop}
                              onChange={(date) => handleDateChange(date, "entry_window_stop")}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              timeFormat="HH:mm"
                              dateFormat="HH:mm"
                              minDate={form.entry_window_start}
                            />
                            <div className="help-block">{errors.entry_window_stop}</div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col md={12}>
                      <Form.Group as={Row}>
                        <Col sm={4}>
                          <Form.Check
                            type="radio"
                            name="expiryOption"
                            id="definiteTime"
                            value="definiteTime"
                            checked={selectedRadioOption === "definiteTime"}
                            onChange={(e) => {
                              setSelectedRadioOption(e.target.value);
                              if (e.target.value === "definiteTime") {
                                setDurationDays("");
                                setDurationHours("");
                                setDurationMinutes("");
                              }
                            }}
                            label={
                              <>
                                At a definite time: Sessions expire
                                {<span className="asterisk">*</span>}
                              </>
                            }
                          />
                        </Col>
                        <Col sm={4}>
                          <Form.Control
                            disabled={selectedRadioOption === "duration"}
                            as="select"
                            value={definiteDay}
                            onChange={(e) => {
                              setDefiniteDay(e.target.value);
                              setErrors({ ...errors, definite_time: "" });
                            }}
                          >
                            {daysOptions.map((day, index) => (
                              <option key={day.value} value={day.value}>
                                {day.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col sm={4} className="mt-sm-0 mt-3">
                          <OverlayTrigger
                            delay={{ show: 250, hide: 400 }}
                            placement={"top"}
                            overlay={<Tooltip>When checked, it sets max_time to 23:59, indicating end of day.</Tooltip>}
                          >
                            <FormLabel className="ml-auto d-flex align-items-center text-nowrap">
                              <input type="checkbox" className="mr-1" checked={form.isSetDurationMaxtime} onChange={handleDurationMaxTime}></input>
                              <span>Max Time </span>{" "}
                            </FormLabel>
                          </OverlayTrigger>
                          <DatePicker
                            className="form-control w-100"
                            placeholderText="Select Time"
                            selected={definiteTime}
                            onChange={(date) => {
                              setDefiniteTime(date);
                              setErrors({ ...errors, definite_time: "" });
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption="Time"
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            disabled={selectedRadioOption === "duration"}
                          />
                        </Col>
                        <div className="help-block  mx-3">{errors.definite_time}</div>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group as={Row}>
                        <Col sm={3}>
                          <Form.Check
                            type="radio"
                            name="expiryOption"
                            value="duration"
                            checked={selectedRadioOption === "duration"}
                            onChange={(e) => {
                              setSelectedRadioOption(e.target.value);
                              if (e.target.value === "duration") {
                                setDefiniteDay("");
                                setDefiniteTime(null);
                              }
                            }}
                            label={
                              <>
                                After duration: Sessions expire after
                                {<span className="asterisk">*</span>}
                              </>
                            }
                          />
                        </Col>
                        <Col sm={3}>
                          <Form.Label>Days</Form.Label>
                          <Form.Control
                            disabled={selectedRadioOption === "definiteTime"}
                            type="number"
                            value={durationDays}
                            onChange={(e) => {
                              setDurationDays(e.target.value);
                              setErrors({ ...errors, duration: "" });
                            }}
                            placeholder="0"
                          />
                        </Col>
                        <Col sm={3}>
                          <Form.Label>Hours</Form.Label>
                          <Form.Control
                            disabled={selectedRadioOption === "definiteTime"}
                            type="number"
                            value={durationHours}
                            onChange={(e) => {
                              setDurationHours(e.target.value);
                              setErrors({ ...errors, duration: "" });
                            }}
                            placeholder="0"
                          />
                        </Col>
                        <Col sm={3}>
                          <Form.Label>Minutes</Form.Label>
                          <Form.Control
                            disabled={selectedRadioOption === "definiteTime"}
                            type="number"
                            value={durationMinutes}
                            onChange={(e) => {
                              setDurationMinutes(e.target.value);
                              setErrors({ ...errors, duration: "" });
                            }}
                            placeholder="0"
                          />
                        </Col>
                        <div className="help-block mx-3">{errors.duration}</div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button onClick={handleSubmit} type="button" variant="primary" className="btn-blue-bg">
                    {isEdit ? "Update" : "Create"}
                  </Button>
                  <Button
                    className="btn-fill ml-2"
                    type="button"
                    variant="danger"
                    onClick={() => history.push(`/parking-rate?lot_id=${editData.lot_id}&location_id=${editData.location_id}`)}
                  >
                    Back
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddRateForm;

import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import DataTable from "react-data-table-component";
import { Row, Col, Card, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as SuperAdminAction from "../../Actions/SuperAdminAction/actions";
import * as ReportsAction from "../../Actions/ReportsAction/action";
import PropertDetails from "../LandingPage/PropertySelection";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import Loader from "../Loader/Loader";
import { tableHeightScrollBars, findBySearch, scroolToTop, utcToSpecificTimeZone } from "../helper";
import t from "../../Actions/ReportsAction/types";

const MeterStatus = () => {
  const dispatch = useDispatch();
  const batteryList = useSelector((state) => state.ReportsDetails.batteryList);
  const exportList = useSelector((exportState) => exportState.ReportsDetails.batteryExportList);
  const UserData = useSelector((userState) => userState.UserProfileDetails);
  const selectAllProperty = useSelector((propertyState) => propertyState.SuperAdminDetails.getAllProperties);
  const loader = useSelector((loader) => loader.ReportsDetails.loader);

  const [state, setState] = useState({
    selectedProperty: UserData.property.id,
    selectedPropertyObj: {},
    childcheckbox: true,
    timezone: UserData.timezone,
    exportValue: false,
    selectedDomainName: "",
    meterListData: [],
    exportButtonDisable: true,
    finalTableHeight: 0,
    searchValue: "",
    batteryListCount: 0,
  });

  const [apiCalled, setApiCalled] = useState(false);

  useEffect(() => {
    const height = tableHeightScrollBars();
    dispatch(SuperAdminAction.get_all_properties());
    setState((prev) => ({
      ...prev,
      finalTableHeight: height,
    }));
  }, []);

  useEffect(() => {
    if (state.searchedBatteryList) {
      setState((prev) => ({
        ...prev,
        batteryListCount: state.searchedBatteryList.length,
      }));
    }
  }, [state.searchedBatteryList]);

  useEffect(() => {
    const selectedProperty = selectAllProperty.length && selectAllProperty.find((item) => item.id === state.selectedProperty);
    const selectedPropertyName = UserData.property?.property_name;
    const selectedDomainName = UserData.property?.domain;
    if (selectedProperty) {
      setState((prev) => ({
        ...prev,
        selectedPropertyObj: {
          label: selectedProperty.site_name,
          value: selectedProperty.id,
        },
        selectedDomainName: selectedDomainName,
      }));
    }
    dispatch(SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName));
  }, [selectAllProperty]);

  useEffect(() => {
    const body = {
      property_id: state.selectedProperty,
      childcheckbox: state.childcheckbox,
      timezone: state.timezone,
      exportValue: state.exportValue,
    };
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    dispatch(ReportsAction.meterStatusList(body));
    setApiCalled(true);
  }, []);

  useEffect(() => {
    if (batteryList && apiCalled === true) {
      setState((prev) => ({
        ...prev,
        meterListData: batteryList.rows,
        batteryListCount: batteryList.count,
      }));
    }
  }, [batteryList, apiCalled]);

  useEffect(() => {
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `METER_BATTERY-${currentTimeDate}-${state.selectedDomainName}.xls`;
    if (apiCalled === true && state.exportValue) {
      setApiCalled(false);
      saveAs(
        new Blob([exportList], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
      setState((prev) => ({
        ...prev,
        exportValue: false,
      }));
    }
  }, [exportList]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const showAgentId = (row) => (
    <>
      {row ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showDomainField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.domain || "-"}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.domain || "-"}</div>
      </OverlayTrigger>
    </>
  );

  const showBatteryField = (name) => {
    return (
      <>
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{name}</Tooltip>}>
          <div className="text-nowrap text-truncate">{name}</div>
        </OverlayTrigger>
      </>
    );
  };

  const showCoinPrinterField = (name) => {
    return (
      <>
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{name}</Tooltip>}>
          <div className="text-nowrap text-truncate">{name}</div>
        </OverlayTrigger>
      </>
    );
  };

  const showDeviceName = (name) => {
    return (
      <>
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{name}</Tooltip>}>
          <div className="text-nowrap text-truncate">{name}</div>
        </OverlayTrigger>
      </>
    );
  };

  const showReportedTimeField = (row) => (
    <>
      {row ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(state.timezone, row, true)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(state.timezone, row, true)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const columns = [
    {
      name: "Domain",
      width: "120px",
      sortable: true,
      cell: (row) => showDomainField(row),
      selector: (row) => row?.domain,
    },
    {
      name: "Address",
      width: "150px",
      sortable: true,
      cell: (row) => showDeviceName(row.address),
      selector: (row) => row.address,
    },
    {
      name: "Lat",
      width: "100px",
      sortable: true,
      cell: (row) => showAgentId(row.lat),
      selector: (row) => row.lat,
    },
    {
      name: "Lon",
      width: "100px",
      sortable: true,
      cell: (row) => showAgentId(row.lon),
      selector: (row) => row.lon,
    },
    {
      name: "Zone Id",
      width: "120px",
      sortable: true,
      cell: (row) => showAgentId(row.zone_id),
      selector: (row) => row.zone_id,
    },
    {
      name: "Server Id",
      width: "120px",
      sortable: true,
      cell: (row) => showAgentId(row.server_id),
      selector: (row) => row.server_id,
    },
    {
      name: "First Check In",
      sortable: true,
      width: "160px",
      cell: (row) => showReportedTimeField(row.first_check_in),
      selector: (row) => row?.first_check_in,
    },
    {
      name: "Last Check In",
      sortable: true,
      width: "160px",
      cell: (row) => showReportedTimeField(row.last_check_in),
      selector: (row) => row?.last_check_in,
    },
    {
      name: "Last Checking Ago",
      width: "200px",
      sortable: true,
      cell: (row) => showDeviceName(row.last_checking_ago),
      selector: (row) => row.last_checking_ago,
    },
    {
      name: "Mode",
      width: "100px",
      sortable: true,
      cell: (row) => showCoinPrinterField(row.mode),
      selector: (row) => row.mode,
    },
    {
      name: "Device Name",
      width: "200px",
      sortable: true,
      cell: (row) => showDeviceName(row.device_name),
      selector: (row) => row.device_name,
    },
    {
      name: "Coin Box",
      width: "120px",
      sortable: true,
      cell: (row) => showCoinPrinterField(row.coin_box),
      selector: (row) => row.coin_box,
    },
    {
      name: "Printer Unit",
      width: "120px",
      sortable: true,
      cell: (row) => showCoinPrinterField(row.printer_unit),
      selector: (row) => row.printer_unit,
    },
    {
      name: "Printer Paper",
      width: "120px",
      sortable: true,
      cell: (row) => showCoinPrinterField(row.printer_unit),
      selector: (row) => row.printer_unit,
    },
    {
      name: "Bill Acceptor",
      width: "130px",
      sortable: true,
      cell: (row) => showCoinPrinterField(row.bill_acceptor),
      selector: (row) => row.bill_acceptor,
    },
    {
      name: "Card Terminal",
      width: "130px",
      sortable: true,
      cell: (row) => showCoinPrinterField(row.card_terminal),
      selector: (row) => row.card_terminal,
    },
    {
      name: "Coin Acceptor",
      width: "130px",
      sortable: true,
      cell: (row) => showCoinPrinterField(row.coin_acceptor),
      selector: (row) => row.coin_acceptor,
    },
    {
      name: "Battery Voltage",
      width: "130px",
      sortable: true,
      cell: (row) => showBatteryField(row.battery_voltage),
      selector: (row) => row.battery_voltage,
    },
    {
      name: "Battery State",
      width: "130px",
      sortable: true,
      cell: (row) => showBatteryField(row.battery_state),
      selector: (row) => row.bettery_state,
    },
    {
      name: "Battery Temp",
      width: "130px",
      sortable: true,
      cell: (row) => showBatteryField(row.battery_temp),
      selector: (row) => row.battery_temp,
    },
    {
      name: "Battery Power",
      width: "130px",
      sortable: true,
      cell: (row) => showBatteryField(row.battery_power),
      selector: (row) => row.battery_power,
    },
    {
      name: "Battery Charger",
      width: "140px",
      sortable: true,
      cell: (row) => showBatteryField(row.battery_charger),
      selector: (row) => row.battery_charger,
    },
  ];

  const dataTableHandle = (data) => {
    return (
      <DataTable
        data={state.searchValue?.length ? state.searchedBatteryList : data}
        columns={columns}
        customStyles={customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={state.batteryListCount}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  const handleSearchChange = async ({ target }) => {
    const val = target?.value?.toString().trim();

    setState((prev) => ({ ...prev, searchValue: val }));
    const columnList = [
      "domain",
      "address",
      "lat",
      "lon",
      "zone_id",
      "server_id",
      "first_check_in",
      "last_check_in",
      "last_checking_ago",
      "mode",
      "device_name",
      "coin_box",
      "printer_unit",
      "printer_paper",
      "bill_acceptor",
      "card_terminal",
      "coin_acceptor",
      "bettery_voltage",
      "bettery_state",
      "bettery_temp",
      "bettery_power",
      "bettery_charger",
    ];
    if (val?.length) {
      if (!state.findMore) {
        setState((prev) => ({
          ...prev,
          searchedBatteryList: findBySearch(val, state.meterListData, state.meterListData, columnList, state.timezone),
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        count: batteryList.count,
        searchedBatteryList: [],
        meterListData: batteryList.rows,
      }));
    }
  };

  const handleExport = async () => {
    setState({ ...state, exportValue: true });
    const { selectedProperty, childcheckbox, timezone } = state;
    const pageobj = {
      childcheckbox,
      timezone,
      exportValue: true,
    };
    if (Object.keys(state.selectedPropertyObj).length) {
      pageobj.property_id = state.selectedPropertyObj.value;
    } else {
      pageobj.property_id = selectedProperty;
    }
    dispatch(ReportsAction.meterStatusList(pageobj));
  };

  const resetAll = async () => {
    setState((prev) => ({
      ...prev,
      selectedProperty: UserData.property.id,
      selectedPropertyObj: {
        label: UserData.property.property_name,
        value: UserData.property.id,
      },
      childcheckbox: true,
    }));
  };

  const handleSearch = () => {
    setState((prev) => ({ ...prev, loader: true }));
    const { childcheckbox, selectedPropertyObj, selectedProperty, timezone } = state;
    const pageobj = {
      childcheckbox,
      timezone,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.property_id = selectedPropertyObj.value;
    } else {
      pageobj.property_id = selectedProperty;
    }

    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    dispatch(ReportsAction.meterStatusList(pageobj));
    document.body.classList.remove("foc-open");
  };

  const hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  const handleCheckBox = () => {
    setState((prev) => ({
      ...prev,
      childcheckbox: !state.childcheckbox,
    }));
  };

  const changeProperty = (event) => {
    setState((prev) => ({
      ...prev,
      selectedProperty: event.value,
      selectedPropertyObj: event,
    }));
  };

  return (
    <div className="container-fluid">
      <Row>
        <Col xs="12">
          <Card className="mb-0">
            <Card.Body className="pos-rel-overflow-hide">
              <>
                <div className="sidebarFilter">
                  <Card className="contact-container mb-0">
                    <Card.Header // style={{ backgroundColor: this.props.main_body }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <Card.Title as="h4">Meter Status Report</Card.Title>
                        <a
                          onClick={hideSlider}
                          href="javascript:void(0);"
                          className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </a>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md="12">
                          <div className="mb-2">
                            <label className="fancy-checkbox">
                              <input
                                type="checkbox"
                                name={"childcheckbox"}
                                checked={state.childcheckbox}
                                onChange={handleCheckBox}
                                className="custom-control-input"
                                id="childcheckbox1"
                              ></input>
                              <span>
                                <i></i>Include Children Sites
                              </span>
                            </label>
                          </div>
                        </Col>
                        <Col md="12">
                          <PropertDetails selectedPropertyObj={state.selectedPropertyObj} changeProperty={changeProperty.bind(this)} />
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer className="text-right justify-content-between d-flex">
                      <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={resetAll} disabled={loader}>
                        Reset
                      </Button>
                      <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={handleSearch} disabled={loader}>
                        Search
                      </Button>
                    </Card.Footer>
                  </Card>
                </div>
                <header>
                  <div className="active-permit-header sm-flex-column sm-align-items-start">
                    <h5 className="mb-2 mb-md-0">Meter Status Report</h5>
                    <div className="filter-button d-flex flex-wrap">
                      <div className="d-flex align-items-center search-mb-0">
                        <DatatableHeader className="mt-0 mb-0" searchValue={state.searchValue} HandleChange={handleSearchChange} />
                      </div>
                      <div className="ml-2">
                        <Button
                          variant="info"
                          className="btn-fill btn-sm"
                          onClick={handleExport}
                          disabled={state.meterListData?.length > 0 ? 0 : 1}
                          size="medium"
                        >
                          Export
                        </Button>
                      </div>
                    </div>
                  </div>
                </header>
              </>
              {loader ? (
                <Loader />
              ) : (
                <>
                  {state.meterListData && (
                    <>
                      <div className="citation-list-table meter-list-table">{dataTableHandle(state.meterListData)}</div>
                    </>
                  )}
                </>
              )}

              <div className="px-1 pt-3" style={{ fontSize: 13 }}>
                <Row>
                  <Col lg={12}>
                    <span className="font-weight-600">Coin Box</span>
                    <span className="mr-1"> :</span> Normal Operation = 0, Box Removed = 1
                  </Col>
                  <Col lg={12}>
                    <span className="font-weight-600">Printer Unit</span>
                    <span className="mr-1"> :</span> Normal Operation = 0, Communication Error = 1, Unit Error = 2, Low Paper = 3, Printer No Paper = 4
                  </Col>
                  <Col lg={12}>
                    <span className="font-weight-600">Printer Paper</span>
                    <span className="mr-1"> :</span> Normal Operation = 0, Communication Error = 1, Unit Error = 2, Low Paper = 3, Printer No Paper = 4
                  </Col>
                  <Col lg={12}>
                    <span className="font-weight-600">Bill Acceptor</span>
                    <span className="mr-1">:</span> Normal Operation = 0, Communication Error = 1, Unit Error = 2, Cash Box Full = 3, Cash Box Blocked = 4
                  </Col>
                  <Col lg={12}>
                    <span className="font-weight-600">Card Terminal</span>
                    <span className="mr-1">:</span> Normal Operation = 0, Communication Error = 1, Unit Error = 2, Offline = 3
                  </Col>
                  <Col lg={12}>
                    <span className="font-weight-600">Coin Acceptor</span>
                    <span className="mr-1">:</span> Normal Operation = 0, Unit Error = 1
                  </Col>
                  <Col lg={12}>
                    <span className="font-weight-600">Battery State</span>
                    <span className="mr-1">:</span> Normal = 0, High Battery = 1, Low Battery = 2
                  </Col>
                  <Col lg={12}>
                    <span className="font-weight-600">Battery Charger</span>
                    <span className="mr-1">:</span> Low Voltage = 0-11, Normal Voltage = 11 - 15, High Voltage = 15 - Above
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MeterStatus;

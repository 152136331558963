import React, { Component } from "react";
import {
  Modal,
  Button,
  // Badge,
  Card,
  Form,
  // Navbar,
  // Nav,
  //   Container,
  Row,
  Col,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class AddSuiteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permit_start_date: "",
      permit_end_date: "",
      lpn: "",
      suite: "",
      first_name: "",
      last_name: "",
      contact: "",
      email: "",
      stallNumber: "",
    };
  }

  componentDidMount() {
    const details = this.props.details;
    this.setState({
      lpn: details.lpn.split(" ")[0] ? details.lpn.split(" ")[0] : "",
      permit_start_date: moment().format("L"),
      permit_end_date: moment().add(10, "years").format("L"),
    });
  }

  handleChangeStartingDates = (date) =>
    this.setState({ permit_start_date: date });

  handleChangeEndingDates = (date) => this.setState({ permit_end_date: date });

  handleChange = ({ target }) => this.setState({ [target.name]: target.value });

  save = () => {
    const {
      permit_start_date,
      permit_end_date,
      lpn,
      suite,
      first_name,
      last_name,
      contact,
      email,
      stallNumber,
    } = this.state;
    const obj = {
      permit_start_date,
      permit_end_date,
      lpn,
      suite,
      first_name,
      last_name,
      contact,
      email,
      stallNumber,
    };
    this.props.handleSave(obj);
  };

  render() {
    const { showBrowserModal, closeModal } = this.props;
    const {
      permit_start_date,
      permit_end_date,
      lpn,
      suite,
      first_name,
      last_name,
      contact,
      email,
      stallNumber,
    } = this.state;
    return (
      <Modal lg={"medium"} show={showBrowserModal} onHide={closeModal}>
        <Modal.Header className="no-border">
          <h4 className="m-0 font-weight-bold">This is my LPN {lpn}</h4>
          <Button
            title={"Warninig"}
            type="button"
            onClick={closeModal}
            className="close-btn"
            data-dismiss="modal"
          >
            <i className="nc-icon nc-simple-remove"></i>
          </Button>
        </Modal.Header>

        <Modal.Body className="modal-data-body">
          {/* <p className="theme-color">This API has failed.</p> */}
          {/* <p className="theme-color">{details.message}</p> */}
          <Row>
            <Col md="12">
              <Card className="contact-container mb-0">
                <Card.Header>
                  <Card.Title as="h4">Driver Permits</Card.Title>
                  <p className="card-category">
                    Fill out the form below to issue a permit.
                  </p>
                </Card.Header>
                <Card.Body>
                  <h4>Driver Information</h4>
                  <p>All fields are required</p>
                  <Row>
                    <Form.Group>
                      <label>
                        <span className="asterisk">*</span>
                      </label>
                      <Form.Control
                        // defaultValue="Mike"
                        placeholder="Search by Suite #"
                        type="text"
                        value={suite}
                        name="suite"
                        required
                        onChange={this.handleChange}
                        autoComplete="off"
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label>Unit Email:</label>
                      <Form.Control
                        // defaultValue="Mike"
                        type="email"
                        // value={resident_email}
                        name="resident_email"
                        required
                        // onChange={this.handleChange}
                        autoComplete="off"
                        readOnly
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                      <label>Call When Here:</label>
                      <Form.Check type="checkbox" defaultChecked={true} />
                    </Form.Group>

                    <Form.Group controlId="formBasicCheckbox">
                      <label>Lot:</label>
                      <Form.Check type="checkbox" defaultChecked={true} />
                    </Form.Group>

                    <Form.Group>
                      <label>Permit Name:</label>
                      <Form.Control
                        // defaultValue="Mike"
                        placeholder="First name"
                        type="text"
                        value={first_name}
                        name="first_name"
                        required
                        onChange={this.handleChange}
                        autoComplete="off"
                      ></Form.Control>
                      <Form.Control
                        // defaultValue="Mike"
                        placeholder="Last name"
                        type="text"
                        value={last_name}
                        name="last_name"
                        required
                        onChange={this.handleChange}
                        autoComplete="off"
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label>Permit Contact Number:</label>
                      <Form.Control
                        // defaultValue="Mike"
                        placeholder="333-222-2323"
                        type="text"
                        value={contact}
                        name="contact"
                        required
                        onChange={this.handleChange}
                        autoComplete="off"
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label>Permit Email Address:</label>
                      <Form.Control
                        // defaultValue="Mike"
                        placeholder="resident@gmail.com"
                        type="email"
                        value={email}
                        name="email"
                        required
                        onChange={this.handleChange}
                        autoComplete="off"
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label>Permit Stall Number:</label>
                      <Form.Control
                        // defaultValue="Mike"
                        placeholder="P1-10"
                        type="text"
                        value={stallNumber}
                        name="stallNumber"
                        required
                        onChange={this.handleChange}
                        autoComplete="off"
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label>License Plate Number:</label>
                      <Form.Control
                        // defaultValue="Mike"
                        placeholder="ccccccc"
                        type="text"
                        value={lpn}
                        name="lpn"
                        required
                        onChange={this.handleChange}
                        autoComplete="off"
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group>
                      <label>Permit Dates:</label>
                      <div className="custom-time">
                        <label>Start Date:</label>
                        <DatePicker
                          placeholder="04/04/16"
                          selected={
                            permit_start_date
                              ? new Date(permit_start_date)
                              : new Date()
                          }
                          onChange={this.handleChangeStartingDates}
                        />
                        <label className="m-l-l m-t-s">End Date:</label>
                        <DatePicker
                          placeholder="04/05/16"
                          selected={
                            permit_end_date
                              ? new Date(permit_end_date)
                              : new Date()
                          }
                          onChange={this.handleChangeEndingDates}
                        />
                      </div>
                    </Form.Group>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} type="button" className="btn btn-danger">
            Cancel
          </Button>
          <Button
            onClick={this.save}
            type="button"
            className="btn btn-fill btn-info"
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddSuiteModal;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Button, Modal, ModalBody, Form, Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import { SuperAdminAction } from "../../Actions";
import { groupBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import isEqual from "lodash/isEqual";
import { saveAs } from "file-saver";
import CustomContentAlert from "../helper";

class UserTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headersData: [],
      userTypesData: [],
      allUserSubType: [],
      showModal: false,
      newUserType: "",
      error: "",
      edit: false,
      rowAdded: false,
      dynamicHeader: [],
      loader: false,
      subError: "",
      duplicates: [],
      userType: "superadmin",
      userSubType: "",
      api_success_message: "",
      api_error_message: "",
      show_alert: false,
    };
  }

  componentDidMount = async () => {
    await this.props.get_all_user_types();
    if (this.props.allUserTypes?.length) {
      await this.getUserList(this.props.allUserTypes);
      const allTypes = [];
      for (const item of this.props.allUserTypes) {
        const data = allTypes.filter((i) => i.user_type === item.user_type);
        if (!data.length) {
          allTypes.push(item);
        }
      }
      this.setState({
        allUserType: allTypes,
      });
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.allUserTypes, this.props.allUserTypes)) {
      await this.getUserList(this.props.allUserTypes);
    }
  };

  getUserList = async (allUserTypes) => {
    const dynamicHeader = [];
    const superData = allUserTypes?.filter((da) => da.permission_id === 1);
    Object.keys(superData[0]).forEach((dt) => {
      if (dt !== "permission_id" && dt !== "user_type" && dt !== "user_sub_type" && dt !== "editable" && dt !== "editable_sub") {
        dynamicHeader.push(dt);
      }
    });

    const userArray = allUserTypes.map((user) => {
      user.editable = false;
      user.editable_sub = false;
      return user;
    });
    const doc = await groupBy(userArray, "user_type");

    // eslint-disable-next-line
    let headers = [doc.superadmin].sort(function (a, b) {
      return dynamicHeader.indexOf(a.type) - dynamicHeader.indexOf(b.type);
    });
    headers = headers[0];
    const datas = Object.keys(headers[0])
      .map((header) => {
        if (dynamicHeader.includes(header)) {
          return {
            [header]: header.replace(/_/g, " ").charAt(0).toUpperCase() + header.slice(1).replace(/_/g, " "),
          };
        }
        return undefined;
      })
      .filter((he) => he !== undefined);
    this.setState({
      headersData: datas,
      userTypesData: doc,
      dynamicHeader: dynamicHeader,
    });
  };

  handleUserTypeName = () => {
    let { userTypesData, index, newUserType } = this.state;
    if (newUserType.length) {
      Object.keys(userTypesData).forEach((data, i) => {
        if (i === index) {
          const oldData = userTypesData[data];
          oldData[0].user_type = newUserType;
          delete userTypesData[data];
          userTypesData = Object.assign({ [newUserType]: oldData }, userTypesData);
        }
      });
      this.setState({ userTypesData, showModal: false });
    } else {
      this.setState({ error: "Please add User Type" });
    }
  };

  handleFieldsChange = (e, id, type) => {
    const { userTypesData } = this.state;
    if (e.target.type === "checkbox") {
      let value = 0;
      if (e.target.checked) {
        value = 1;
      }
      Object.keys(userTypesData).forEach((data) => {
        if (userTypesData[data].length) {
          userTypesData[data].forEach((item) => {
            if (item.permission_id === id) {
              item[type] = value;
            }
          });
        }
      });
    } else {
      const value = e.target.value;
      Object.keys(userTypesData).forEach((data) => {
        if (userTypesData[data].length) {
          userTypesData[data].forEach((item) => {
            if (item.permission_id === id) {
              item.user_sub_type = value;
              item.editedSubType = true;
            }
          });
        }
      });
    }
    if (this.state.duplicates[0] === type) {
      this.setState({ subError: "" });
    }
    this.setState({ userTypesData });
  };

  handleSubmit = async () => {
    this.setState({ loader: true });
    const { userTypesData } = this.state;
    const updatedData = Object.keys(userTypesData)
      .map((item) => {
        return [].concat(userTypesData[item]);
      })
      .flat();

    const duplicateData = [];
    Object.keys(userTypesData).forEach((data) => {
      userTypesData[data].forEach((item) => {
        if (item.error) {
          item.error = "";
        }
      });
      const arr = [];
      const subTypeData = userTypesData[data];
      if (userTypesData[data][0].user_sub_type) {
        subTypeData.forEach((item) => {
          arr.push(item.user_sub_type);
        });
        let duplicates = [...arr];
        const yourArrayWithoutDuplicates = [...new Set(arr)];
        yourArrayWithoutDuplicates.forEach((item) => {
          const i = duplicates.indexOf(item);
          duplicates = duplicates.slice(0, i).concat(duplicates.slice(i + 1, duplicates.length));
        });
        duplicates.forEach((data2) => {
          userTypesData[data].forEach((item) => {
            this.sameSubTypeCheck(item, data2);
          });
        });
        duplicateData.push(...duplicates);
      }
    });

    if (duplicateData[0] !== undefined) {
      this.setState({
        loader: false,
        rowAdded: false,
        userTypesData: userTypesData,
      });
    } else {
      const res = await this.props.update_user_types(updatedData);
      if (res && this.props.api_success_message) {
        this.setState(
          {
            loader: false,
            rowAdded: false,
            userTypesData: userTypesData,
            show_alert: true,
            api_success_message: this.props.api_success_message,
          },
          () => {
            setTimeout(() => {
              this.setState({
                show_alert: false,
              });
            }, 4000);
          },
        );
      } else {
        this.setState(
          {
            loader: false,
            rowAdded: false,
            userTypesData: userTypesData,
            show_alert: true,
            api_error_message: this.props.api_error_message,
          },
          () => {
            setTimeout(() => {
              this.setState({
                show_alert: false,
              });
            }, 4000);
          },
        );
      }
    }
  };

  sameSubTypeCheck = (item, data2) => {
    if (item.user_sub_type === data2) {
      item.error = "Please use another sub type";
    }
  };

  addUserSubtype = (user_type) => {
    const { userTypesData } = this.state;
    const newRow = {
      manage_units: 1,
      current_visitors: 1,
      view_all_permits: 1,
      add_visitor: 1,
      photo_verify: 1,
      lpr_report: 1,
      parking_log: 1,
      citations: 1,
      report: 1,
      user_sub_type: "sub type",
      user_type: user_type,
      editable_sub: true,
      editable: false,
      permission_id: uuidv4(),
    };
    userTypesData[user_type].push(newRow);
    this.setState({ userTypesData });
  };

  addUserType = () => {
    let { userTypesData, newUserType, showModal } = this.state;
    const typeInLowerCase = newUserType.toLowerCase();
    if (newUserType.length) {
      if (typeInLowerCase in userTypesData) {
        this.setState({ error: "User Type already exists" });
      } else {
        const newRow = [
          {
            manage_units: 1,
            current_visitors: 1,
            view_all_permits: 1,
            add_visitor: 1,
            photo_verify: 1,
            lpr_report: 1,
            parking_log: 1,
            citations: 1,
            report: 1,
            user_sub_type: "",
            user_type: typeInLowerCase,
            editable: true,
            permission_id: uuidv4(),
            editable_sub: false,
          },
        ];
        userTypesData = Object.assign({ [typeInLowerCase]: newRow }, userTypesData);
        this.setState({ userTypesData, showModal: !showModal, rowAdded: true });
      }
    } else {
      this.setState({ error: "Please add User Type" });
    }
  };

  showTopHeaderWithUsers = (userTypesData) => {
    const table = Object.keys(userTypesData).map((item, i) => {
      if (userTypesData[item].length) {
        return userTypesData[item].map((data, index) => {
          return (
            <>
              <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                placement={"top"}
                overlay={<Tooltip id={`tooltip-qw`}>{!data.editable && <>{data.user_type}</>}</Tooltip>}
              >
                <Col className="border-left border-bottom border-top fs-14px fw-500 p-2 d-flex flex-column data-col bg-white">
                  {" "}
                  {data?.editable && (
                    <div className="text-center">
                      <span>{data.user_type}</span>
                      <i title="Edit User Sub Type" className="fa fa-pen cursor-pointer" onClick={() => this.showUserTypeModal("edit", i)}></i>
                    </div>
                  )}
                  {!data.editable && <div className="text-center">{data.user_type}</div>}
                  <div className="d-flex flex-column align-items-center">
                    {data.user_sub_type !== null && (
                      <>
                        <Form.Control
                          size="sm"
                          autoFocus={false}
                          type="text"
                          value={data.user_sub_type}
                          className="user-type-input my-2 py-1 fw-500 bg-light"
                          onChange={(e) => this.handleFieldsChange(e, data.permission_id, data.user_sub_type)}
                          disabled={data.user_sub_type === "owner" ? true : false}
                        />
                      </>
                    )}
                    <div className="help-block text-wrap">{data.error ? data.error : null}</div>
                    {!data.editable && data.user_sub_type && index === userTypesData[item].length - 1 ? (
                      <i title="Add User Sub Type" className="fas fa-plus-circle cursor-pointer" onClick={() => this.addUserSubtype(data.user_type)}></i>
                    ) : null}
                  </div>
                </Col>
              </OverlayTrigger>
            </>
          );
        });
      }
    });

    return table;
  };

  showCheckBoxAccordingUser = (userTypesData) => {
    const table = Object.keys(userTypesData).map((item, i) => {
      if (userTypesData[item].length) {
        return userTypesData[item].map((data, index) => {
          return (
            <>
              <Col className="px-0 data-col">
                <div className="d-flex flex-column">
                  {this.state.dynamicHeader.map((header, b) => {
                    const checkId = uuidv4();
                    return (
                      <div key={checkId} className="text-center border-left border-bottom py-2 px-3 data-div">
                        <div className="custom-control custom-checkbox py-0">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={checkId}
                            name={header}
                            checked={data[header] === 1}
                            onChange={(e) => this.handleFieldsChange(e, data.permission_id, header)}
                            disabled={data.permission_id === 1 || data.permission_id === 2 || data.user_type == "driver"}
                          />
                          <label className="custom-control-label" htmlFor={checkId}></label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </>
          );
        });
      }
    });

    return table;
  };

  showUserTypeModal = (edit, i) => {
    if (edit) {
      this.setState({
        showModal: !this.state.showModal,
        edit: true,
        index: i,
        error: "",
      });
    } else {
      this.setState({
        showModal: !this.state.showModal,
        error: "",
        newUserType: "",
      });
    }
  };

  handleReportSearch = async () => {
    const ele = document.querySelector(".datatable-scroll");
    if (ele) {
      ele.scrollTo(0, 0);
    }
    await this.getUserList(this.props.allUserTypes);
    const { userTypesData, userType, userSubType } = this.state;
    if (userType && userSubType) {
      const filteredData = userTypesData[userType].filter((data) => data.user_sub_type === userSubType);
      this.setState({ userTypesData: { [userType]: filteredData } });
    } else {
      if (userType) {
        this.setState({ userTypesData: { [userType]: userTypesData[userType] } });
      }
    }
  };

  handleExport = async () => {
    const bufferData = await this.props.export_usertypes();
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        `UserTypes.xls`,
      );
    }
  };

  handleChange = (e) => {
    const { value, name } = e.target;
    const allUserSubTypes = [];
    this.setState(
      {
        [name]: value,
      },
      async () => {
        if (name === "userType") {
          const { userType, allUserType } = this.state;
          const filterData = this.props.allUserTypes.filter((item) => item.user_sub_type && userType === item.user_type);
          if (filterData?.length > 0) {
            for (const i of filterData) {
              allUserSubTypes.push(i);
            }
          }
          const { defaultValue, defaultSubValue } = this.defaultValueForSubtype(allUserType, allUserSubTypes);
          this.setState((prevState) => ({
            ...prevState,
            userType: userType ? userType : defaultValue,
            userSubType: defaultSubValue,
            allUserSubType: allUserSubTypes,
          }));
        }
      },
    );
  };

  defaultValueForSubtype = (allUserType, allUserSubTypes) => {
    let defaultValue = "";
    let defaultSubValue = "";
    if (allUserType?.length > 0) {
      for (const type of allUserType) {
        if (type.permission_id === 3) {
          defaultValue = type.user_type;
        }
      }
    }
    if (allUserSubTypes?.length > 0) {
      for (const subType of allUserSubTypes) {
        if (subType.permission_id === 3) {
          defaultSubValue = subType.user_sub_type;
        }
      }
    }
    return { defaultValue, defaultSubValue };
  };

  render() {
    const {
      userTypesData,
      headersData,
      newUserType,
      error,
      edit,
      rowAdded,
      loader,
      userType,
      allUserType,
      allUserSubType,
      userSubType,
      show_alert,
      api_success_message,
      api_error_message,
    } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <div className="mr-4 my-md-0 my-1">
                    <Card.Title as="h4">Add/Edit User Types</Card.Title>
                  </div>
                  <div className="d-flex align-items-center ml-sm-auto mr-3 my-md-0 flex-wrap">
                    <div className="d-flex align-items-center mr-3 my-1">
                      <label className="text-nowrap mr-2">
                        User Type
                        <span className="asterisk">*</span>
                      </label>
                      <Form.Control size="sm" className="p-1" as="select" value={userType} name="userType" onChange={this.handleChange}>
                        {allUserType?.length &&
                          allUserType.map((e, key) => {
                            return (
                              <option key={e.user_type} value={e.user_type}>
                                {e.user_type}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </div>
                    <div>
                      {allUserSubType?.length ? (
                        <div className="d-flex align-items-center mr-3 my-1">
                          <label className="text-nowrap mr-2">User Sub-Type</label>
                          <Form.Control size="sm" className="p-1" as="select" value={userSubType} name="userSubType" onChange={this.handleChange}>
                            {(allUserSubType ?? []).map((e) => {
                              return (
                                <option key={e.permission_id} value={e.user_sub_type}>
                                  {e.user_sub_type}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <Button variant="info" className="btn-fill btn-sm" onClick={this.handleReportSearch} size="medium">
                        Find
                      </Button>
                    </div>
                  </div>
                  <div className="my-md-0 my-1">
                    <Button variant="info" className="btn-fill btn-sm" onClick={this.handleExport} size="medium">
                      Export
                    </Button>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="pos-rel-overflow-hide">
                <div className="datatable-scroll usertype-table border-right position-relative">
                  <Row className="usertype-table-header flex-nowrap mx-0 align-items-stretch position-sticky bg-white" style={{ top: "0", zIndex: "2" }}>
                    <Col className="border border-right-0 px-0 left-col"></Col>
                    {this.showTopHeaderWithUsers(userTypesData)}
                  </Row>
                  <Row className="flex-nowrap mx-0">
                    <Col className="px-0 left-col">
                      {headersData.map((data, index) => {
                        return (
                          <>
                            <OverlayTrigger
                              delay={{ show: 250, hide: 400 }}
                              placement={"top"}
                              overlay={<Tooltip id={`tooltip-qw`}>{Object.values(data)[0]}</Tooltip>}
                            >
                              <div className="border-left border-bottom py-2 px-3 fs-14px text-nowrap data-div" key={Object.keys(data)[0]}>
                                <span className="fw-500">{Object.values(data)[0]}</span>
                              </div>
                            </OverlayTrigger>
                          </>
                        );
                      })}
                    </Col>
                    {this.showCheckBoxAccordingUser(userTypesData)}
                  </Row>
                </div>
              </Card.Body>
              <Col className="d-flex justify-content-between pb-3">
                {!rowAdded && (
                  <Button className="btn-fill btn-sm" type="button" variant="info" onClick={() => this.showUserTypeModal()}>
                    Add User Type
                  </Button>
                )}
                <div className="">
                  {loader && <Spinner animation="border" variant="info" />}
                  {!loader && (
                    <Button className="btn-fill btn-sm" type="button" variant="info" onClick={this.handleSubmit}>
                      Update
                    </Button>
                  )}
                </div>
              </Col>
              <Row></Row>
            </Card>
          </Col>
        </Row>
        <Modal lg={"small"} show={this.state.showModal} backdrop={"static"} dialogClassName="modal-90w" fullscreen={"sm-down"}>
          <Card.Header className="d-flex justify-content-between bg-white align-items-center">
            <h4 className="m-0 font-weight-bold">
              <Card.Title className="m-0">
                {edit && "Edit "}
                {!edit && "Add "}
                User Type
              </Card.Title>
            </h4>
            <Button title={"Deactivate"} type="button" onClick={() => this.showUserTypeModal()} className="close-btn py-0" data-dismiss="modal">
              <i className="nc-icon nc-simple-remove text-danger" />
            </Button>
          </Card.Header>
          <ModalBody>
            <label>
              User Type
              <span className="asterisk">*</span>
            </label>
            <Form.Control placeholder="User Type" type="text" name="newUserType" value={newUserType} onChange={this.handleChange} autoComplete="off" required />
            <div className="help-block">{error.length ? error : null}</div>
          </ModalBody>
          <Modal.Footer>
            {edit && <Button onClick={() => this.handleUserTypeName()}>Edit</Button>}
            {!edit && <Button onClick={() => this.addUserType()}>Add</Button>}
          </Modal.Footer>
        </Modal>
        {show_alert && api_success_message && <CustomContentAlert delay={4000} message={api_success_message} className={"toast-success"} />}
        {show_alert && api_error_message && <CustomContentAlert delay={4000} message={api_error_message} className={"toast-success"} />}
      </Container>
    );
  }
}
const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, Logindetails, SuperAdminDetails }) => {
  const { api_response, api_success_message, api_error_message } = APIResponseDetails;
  const { property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { allUserTypes } = SuperAdminDetails;
  return {
    UserProfileDetails,
    api_response,
    api_success_message,
    api_error_message,
    property,
    main_body,
    allUserTypes,
  };
};

const mapStateToDispatch = {
  get_all_user_types: SuperAdminAction.get_all_user_types,
  update_user_types: SuperAdminAction.update_user_types,
  export_usertypes: SuperAdminAction.export_usertypes,
};

export default connect(mapStateToProps, mapStateToDispatch)(UserTypes);

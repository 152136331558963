import t from "../../Actions/APIResponseActions/type";

const intitialState = {
  api_response: true,
  api_error_message: "",
  api_success_message: "",
  api_failed_attempt_count: 0,
};

const APIResponseReducer = (state = intitialState, action) => {
  switch (action.type) {
    case t.API_RESPONSE_SUCCESS: {
      return {
        ...state,
        api_response: true,
        api_error_message: "",
        api_success_message: action.payload,
      };
    }
    case t.API_RESPONSE_FAILURE: {
      return {
        ...state,
        api_response: false,
        api_error_message: action.payload,
        api_success_message: "",
      };
    }
    case t.API_RESPONSE_FAILURE_ATTEMPTS: {
      return {
        ...state,
        // api_response: false,
        // api_error_message: "",
        // api_success_message: "",
        api_failed_attempt_count: action.payload,
      };
    }
    // case t.API_RESPONSE_FAILURE_ATTEMPTS_MINUTES: {
    //   console.log(
    //     ":::::::API_RESPONSE_API_RESPONSE_FAILURE_ATTEMPTS_MINUTES",
    //     action.payload
    //   );
    //   return {
    //     ...state,
    //     // api_response: false,
    //     // api_error_message: "",
    //     // api_success_message: "",
    //     minutes_remaining: action.payload,
    //   };
    // }
    // case t.API_RESPONSE_FAILURE_ATTEMPTS_SECONDS: {
    //   console.log(
    //     ":::::::API_RESPONSE_API_RESPONSE_FAILURE_ATTEMPTS_SECONDS",
    //     action.payload
    //   );
    //   return {
    //     ...state,
    //     // api_response: false,
    //     // api_error_message: "",
    //     // api_success_message: "",
    //     final_remain_second: action.payload,
    //   };
    // }
    case t.API_RESPONSE_RESET: {
      return intitialState;
    }
    default:
      return state;
  }
};

export default APIResponseReducer;

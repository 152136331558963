import React, { Component } from "react";
import { Card, Row, Col, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import DataTable from "react-data-table-component";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { connect } from "react-redux";
import { SuperAdminAction, TicketActions } from "../../Actions";
import Loader from "../Loader/Loader";
import { tableHeightScrollBars, findBySearch, utcToSpecificTimeZone, scroolToTop } from "../helper";
import isEqual from "lodash/isEqual";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropertDetails from "../LandingPage/PropertySelection";
import { saveAs } from "file-saver";
import moment from "moment";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";

class CitationTransaction extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.state = {
      PropertyId: this.props.PropertyId,
      transactionsList: [],
      loader: false,
      searchValue: "",
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      selectedPropertyObj: {},
      all_properties: [],
      childcheckbox: true,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentSummaryDate: "",
      finalTableHeight: 0,
      daysDifference: 30,
      selectedProperty: "",
      filterStatus: "",
      startDateTime: "",
      endDateTime: "",
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "Transaction Time",
        width: "160px",
        sortable: true,
        cell: (row) => this.showTransactionTime(row),
        selector: (row) => row.transaction_time,
      },
      {
        name: "Transaction Id",
        width: "150px",
        sortable: true,
        cell: (row) => this.showTransactionIdField(row),
        selector: (row) => row?.transaction_id,
      },
      {
        name: "Payment Status",
        sortable: true,
        width: "140px",
        cell: (row) => this.showPaymentStatusField(row),
        selector: (row) => row.payment_status,
      },
      {
        name: "Ticket No",
        width: "140px",
        sortable: true,
        cell: (row) => this.showTicketNoField(row),
        selector: (row) => row?.ticket_no,
      },
      {
        name: "LPN",
        width: "140px",
        sortable: true,
        cell: (row) => this.showLPN(row),
        selector: (row) => row?.lpn,
      },
      {
        name: "Card Number",
        width: "120px",
        sortable: true,
        cell: (row) => this.showCardNumber(row),
        selector: (row) => row?.card_number,
      },
      {
        name: "Amount",
        sortable: true,
        width: "120px",
        cell: (row) => this.showAmountField(row),
        selector: (row) => row.amount,
      },
      {
        name: "Domain",
        sortable: true,
        width: "140px",
        cell: (row) => this.showDomainField(row),
        selector: (row) => row.domain,
      },
      {
        name: "Status",
        sortable: true,
        width: "100px",
        cell: (row) => this.showStatusField(row),
        selector: (row) => row.status,
      },
      {
        name: "Message Response",
        sortable: true,
        width: "160px",
        cell: (row) => this.showMeesageField(row),
        selector: (row) => row.message_response,
      },
    ];
  }

  expandedComponent = ({ data }) => {
    return (
      <div className="extend-row">
        <Row className="expandedView mb-1">
          <Col className="min-w-150px">
            <Row>
              <Col xs="4">
                <b>Card Expiry</b>
              </Col>
              <Col xs="8">{data?.card_expiry || "-"}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b>Currency</b>
              </Col>
              <Col xs="8">
                <span>{data.currency || "-"}</span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b>Processor Id</b>
              </Col>
              <Col xs="8">
                <span>{data.processor_id || "-"}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  showMeesageField = (row) => (
    <>
      {row.message_response ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.message_response}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.message_response}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showStatusField = (row) => (
    <>
      {row.status ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.status}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.status}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showDomainField = (row) => (
    <>
      {row.domain ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.domain}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.domain}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showAmountField = (row) => {
    if (row.amount) {
      const formattedAmount = parseFloat(row.amount).toFixed(2);
      return (
        <>
          <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement={"top"}
            overlay={
              <Tooltip id={`tooltip-qw`}>{row.status === "Partial Refund" || row.status === "Full Refund" ? `-${formattedAmount}` : formattedAmount}</Tooltip>
            }
          >
            <div className="text-nowrap text-truncate pr-2">
              {row.status === "Partial Refund" || row.status === "Full Refund" ? `-${formattedAmount}` : formattedAmount}
            </div>
          </OverlayTrigger>
        </>
      );
    } else {
      return "-";
    }
  };

  showCardNumber = (row) => (
    <>
      {row?.card_number ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.card_number}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.card_number}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showTicketNoField = (row) => (
    <>
      {row?.ticket_no ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.ticket_no}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.ticket_no}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showPaymentStatusField = (row) => (
    <div>
      <div style={{ color: "green" }}>{row?.payment_status === "Approved" && row.payment_status}</div>
      <div style={{ color: "red" }}>{row?.payment_status === "Failed" && row.payment_status}</div>
      {!row.payment_status && "-"}
    </div>
  );

  showTransactionIdField = (row) => (
    <>
      {row?.transaction_id ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.transaction_id}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.transaction_id}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showLPN = (row) => {
    return (
      <div className="curserPointer text-nowrap text-truncate pr-2" onClick={() => this.handleRedirect(row.lpn)}>
        {row?.lpn ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.lpn}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{row?.lpn}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </div>
    );
  };

  showTransactionTime = (row) => (
    <>
      {row.transaction_time ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(this.props.timezone, row.transaction_time, true)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(this.props.timezone, row.transaction_time, true)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  handleRedirect = (lpn) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/current-occupancy`,
      search: `audit-report&${lpn}`,
    });
  };

  changeProperty(event) {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState(
      {
        selectedProperty: event.value,
        selectedPropertyObj: event,
        startDate: new Date(datebeforeThirtyDays),
        endDate: new Date(),
        currentSummaryDate: null,
      },
      () => {
        const selectedPropertyObj = this.state.all_properties.length && this.state.all_properties.find((item) => item.id === this.state.selectedProperty);
        this.props.selected_Property(selectedPropertyObj);
      },
    );
  }

  handleCheckBox = () => {
    this.setState({ childcheckbox: !this.state.childcheckbox });
  };

  componentDidMount = async () => {
    await this.getTickets();
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    this.setState(
      {
        all_properties: this.props?.getAllProperties,
      },
      async () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );

    const height = tableHeightScrollBars();
    this.setState({
      finalTableHeight: height,
    });
  };

  getTickets = async () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, childcheckbox, timezone, startDate, endDate } = this.state;
      const pageobj = {
        PropertyId,
        timezone,
        childcheckbox,
      };
      if (startDate) {
        pageobj.startDate = startDate;
      }
      if (endDate) {
        pageobj.endDate = endDate;
      }
      await this.props.getReportList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        transactionsList:
          this.props?.transactionsList?.rows && this.props.transactionsList.rows.length ? JSON.parse(JSON.stringify(this.props.transactionsList.rows)) : [],
        count: this.props.transactionsList.count,
      });
      this.setState({ loader: false });
    });
  };

  GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj);
    this.setState({
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.transactionsList, this.props.transactionsList)) {
      this.setState({
        transactionsList: JSON.parse(JSON.stringify(this.props.transactionsList.rows)),
        pageChanged: true,
        count: this.props.transactionsList.count,
      });
    }
  };

  dataTableHandle = (transactionsList) => {
    return (
      <DataTable
        data={this.state.searchValue.length ? this.state.searchedtransactionsList : transactionsList}
        columns={this.columns}
        expandableRows
        expandableRowsComponent={this.expandedComponent}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleChange = async (e) => {
    this.setState({
      selectedOption: e.target.value,
    });
    await this.getTickets();
  };

  handleCurrentDate = (date) => {
    this.setState({
      currentSummaryDate: date,
      startDate: null,
      endDate: null,
    });
  };

  handleChangeStartingDates = (date) => {
    const dt = new Date(date);
    const getEndDate = new Date(dt.setMonth(dt.getMonth() + 3));
    let selectEndDate;
    if (getEndDate > new Date()) {
      selectEndDate = new Date();
    } else {
      selectEndDate = getEndDate;
    }
    this.setState({
      startDate: date,
      endDate: selectEndDate,
      currentSummaryDate: null,
    });
  };

  handleChangeEndDates = (date) => {
    this.setState({
      endDate: date,
      currentSummaryDate: null,
    });
  };

  handleSearch = () => {
    this.setState({ loader: true }, async () => {
      const { childcheckbox, currentSummaryDate, startDate, endDate, selectedPropertyObj, PropertyId, timezone, filterStatus } = this.state;
      const pageobj = {
        childcheckbox,
        PropertyId,
        timezone,
      };
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }
      if (currentSummaryDate) {
        pageobj.currentSummaryDate = currentSummaryDate;
      }
      pageobj.status = filterStatus;
      if (startDate && endDate) {
        pageobj.startDate = startDate;
        pageobj.endDate = endDate;
      }
      await this.props.getReportList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      this.setState({
        transactionsList: JSON.parse(JSON.stringify(this.props.transactionsList.rows)),
        count: this.props.transactionsList.count,
      });
      this.setState({ loader: false });
      document.body.classList.remove("foc-open");
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ searchValue: val }, async () => {
      const columnList = [
        "transaction_time",
        "transaction_id",
        "ticket_no",
        "lpn",
        "card_number",
        "card_expiry",
        "currency",
        "amount",
        "processor_id",
        "domain",
        "status",
        "message_response",
      ];
      if (this.state.searchValue.length) {
        if (!this.state.findMore) {
          this.setState(
            {
              searchedtransactionsList: findBySearch(
                this.state.searchValue,
                this.state.transactionsList,
                this.state.transactionsList,
                columnList,
                this.props.timezone,
              ),
            },
            () => {
              this.setState({
                count: this.state.searchedtransactionsList.length,
              });
            },
          );
        }
      } else {
        this.setState({
          count: this.props.transactionsList.count,
          searchedtransactionsList: [],
          transactionsList: this.props.transactionsList.rows,
        });
      }
    });
  };

  resetAll = async () => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - this.state.daysDifference);
    this.setState({
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentSummaryDate: null,
      selectedPropertyObj: {},
      childcheckbox: true,
      filterStatus: "",
    });
    const { PropertyId, startDate, endDate, selectedDomainName, timezone } = this.state;
    const pageobj = {
      PropertyId,
      timezone,
    };
    if (startDate) {
      pageobj.startDate = new Date(datebeforeThirtyDays);
    }
    if (endDate) {
      pageobj.endDate = new Date();
    }
    await this.props.getReportList(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    this.setState({
      transactionsList: this.props.transactionsList.rows,
      count: this.props.transactionsList.count,
      selectedPropertyObj: {
        label: selectedDomainName,
        value: PropertyId,
      },
    });
    setTimeout(() => {
      this.setState({ findMore: false });
    }, 2000);
    this.setState({ loader: false });
    document.body.classList.remove("foc-open");
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleStatusFilter = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  handleExport = async () => {
    const { childcheckbox, currentSummaryDate, startDate, endDate, selectedPropertyObj, PropertyId, selectedDomainName, timezone } = this.state;
    const pageobj = {
      childcheckbox,
      PropertyId,
      timezone,
      exportData: true,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    const bufferData = await this.props.getReportList(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `TRANSACTIONS-${currentTimeDate}-${selectedDomainName}.xls`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  render() {
    const { transactionsList, loader, childcheckbox, startDate, endDate, currentSummaryDate, searchValue, filterStatus } = this.state;

    return (
      <div>
        <Card className="mb-0">
          <Card.Body className="pos-rel-overflow-hide">
            <>
              <div className="sidebarFilter">
                <Card className="contact-container mb-0">
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title as="h4">Transactions Report</Card.Title>
                      <a
                        onClick={this.hideSlider}
                        href="javascript:void(0);"
                        className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <label className="fancy-checkbox">
                            <input
                              type="checkbox"
                              name={"childcheckbox"}
                              checked={childcheckbox}
                              onChange={this.handleCheckBox}
                              className="custom-control-input"
                              id="childcheckbox1"
                            ></input>
                            <span>
                              <i></i>Include Children Sites
                            </span>
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>Status:</Form.Label>
                          <Form.Control as="select" name="filterStatus" onChange={this.handleStatusFilter} value={filterStatus}>
                            <>
                              <option value="">Any</option>
                              <option value="Paid">Paid</option>
                              <option value="Unpaid">Unpaid</option>
                              <option value="Refunded">Refunded</option>
                            </>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Specific Date:</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Date"
                            selected={currentSummaryDate}
                            value={currentSummaryDate}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleCurrentDate}
                            dateFormat="MM/dd/yyyy"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Start Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Start Date"
                            selected={startDate}
                            defaultHour={24}
                            value={startDate}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleChangeStartingDates}
                            dateFormat="MM/dd/yyyy"
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">End Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select End Date"
                            selected={endDate}
                            disabled={loader}
                            value={endDate}
                            selectsEnd
                            timeInputLabel="Time:"
                            onChange={this.handleChangeEndDates}
                            // dateFormat="MM/dd/yyyy hh:mm:ss aa"
                            dateFormat="MM/dd/yyyy"
                            // showTimeInput
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-right justify-content-between d-flex">
                    <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                      Reset
                    </Button>
                    <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                      Search
                    </Button>
                  </Card.Footer>
                </Card>
              </div>
              <header>
                <div className="active-permit-header sm-flex-column sm-align-items-start">
                  <h5 className="mb-2 mb-md-0">Transactions</h5>
                  <div className="filter-button d-flex flex-wrap">
                    <div className="d-flex align-items-center search-mb-0">
                      <DatatableHeader
                        className="mt-0 mb-0"
                        searchValue={searchValue}
                        HandleChange={this.handleSearchChange}
                        // findMorePressed={this.findMorePressed}
                      />
                    </div>
                    <div className="ml-2">
                      <Button
                        variant="info"
                        className="btn-fill btn-sm"
                        onClick={this.handleExport}
                        disabled={transactionsList.length > 0 ? 0 : 1}
                        size="medium"
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </header>
            </>
            {loader ? (
              <Loader />
            ) : (
              <>
                {transactionsList && (
                  <>
                    <div className="citation-list-table has-parent-tab">{this.dataTableHandle(transactionsList)}</div>
                    <DateRangeAndAmount
                      ReportList={this.props.transactionsList.rows}
                      amountName="amount"
                      startDateTime={this.state.startDateTime}
                      endDateTime={this.state.endDateTime}
                    />
                  </>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails, SuperAdminDetails, CurrentPermitDetails, TicketDetails }) => {
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;

  const { backgroundColor } = Logindetails;
  const { residentList } = CurrentPermitDetails;
  const { getAllProperties } = SuperAdminDetails;
  const { MainBackground: main_body } = backgroundColor;
  const { transactionsList } = TicketDetails;
  return {
    PropertyId,
    timezone,
    property,
    transactionsList,
    main_body,
    getAllProperties,
    residentList,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  getBlobPdf: TicketActions.getBlobPdf,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  getReportList: TicketActions.getTransactionsList,
};

export default connect(mapStateToProps, mapStateToDispatch)(CitationTransaction);

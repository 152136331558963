import t from "./types";
import apiType from "../APIResponseActions/type";
import { AuthAPI } from "../../../Auth/requestHandler";

import ti from "../../Actions/ResidentsActions/types";

export const addPermitOptions = (body) => async (dispatch) => {
  try {
    const response = await await AuthAPI("domain", "permit_options", "POST", body);
    if (response?.success === 1) {
      await dispatch({
        type: t.ADD_PAID_PERMIT_OPTION,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const updatePermitOptions = (body, id) => async (dispatch) => {
  await dispatch({
    type: apiType.API_RESPONSE_FAILURE,
    payload: "",
  });
  try {
    const url = "permit_options/update/" + id;
    const response = await AuthAPI("domain", url, "POST", body);
    if (response?.success) {
      await dispatch({
        type: t.UPDATE_PERMIT_OPTION_DATA,
        payload: { old_id: id, new_row: response.data },
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getAllPermitOptions = (property_id, ifUnit) => async (dispatch) => {
  try {
    const propertyId = property_id.length > 0 ? property_id : null;
    const response = await await AuthAPI("domain", "permit_options/get-all-permit-options/" + propertyId + "/" + ifUnit, "GET");
    if (response) {
      await dispatch({
        type: t.GET_PAID_PERMIT_OPTIONS_LIST,
        payload: response,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getAllPermitOptionsByLots = (property_id, ifUnit) => async (dispatch) => {
  try {
    const response = await await AuthAPI("domain", "permit_options/get-all-permit-options-lot/" + property_id + "/" + ifUnit, "GET");
    if (response) {
      await dispatch({
        type: t.GET_PAID_PERMIT_OPTIONS_LIST,
        payload: response,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getAllPermitTermOptionsByLots = (property_id, ifUnit, end_time) => async (dispatch) => {
  try {
    const response = await await AuthAPI("domain", "permit_options/get-all-permit-options-lot-by-term/" + property_id + "/" + ifUnit + "/" + end_time, "GET");
    if (response) {
      await dispatch({
        type: t.GET_TERM_PERMIT_OPTIONS_LIST,
        payload: response,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};


export const addUnitPermitOptions = (body) => async (dispatch) => {
  try {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "",
    });
    const response = await await AuthAPI("domain", "permit_options/add-unit-options", "POST", body);
    if (response?.success === 1) {
      await dispatch({
        type: t.ADD_UNIT_PERMIT_OPTION,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else if (response?.success === 2) {
      await dispatch({
        type: t.GET_UNIT_OPTIONS_LIST,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getCardDetails = (property_id, suite_id) => async (dispatch) => {
  try {
    const response = await AuthAPI("domain", "permit_options/get-card-details/" + property_id + "/" + suite_id, "GET");
    if (response) {
      await dispatch({
        type: t.GET_CARD_DETAILS,
        payload: response.data,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const DeleteCreditCardDetails = (card_id) => async (dispatch) => {
  try {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "",
    });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });

    const url = `permit_options/delete-credit-card/${card_id}`;

    const response = await AuthAPI("domain", url, "DELETE");
    console.log("Driver card delete response::::", response);
    if (response?.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: err.message || "The server did not respond, Please try again after sometime",
    });
  }
};

export const saveCreditCardDetails = (data) => async (dispatch) => {
  console.log("card save data on actions::::", data);
  try {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "",
    });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
    const url = `permit_options/save-credit-card`;

    const response = await AuthAPI("domain", url, "POST", data);
    console.log("Unit card save response::::", response);
    if (response?.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (err) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: err.message || "The server did not respond, Please try again after sometime",
    });
  }
};

export const unsetCardDetail = () => async (dispatch) => {
  await dispatch({
    type: t.GET_CARD_DETAILS,
    payload: {},
  });
};

export const closePermitMaxLimitWarningModal = () => async (dispatch) => {
  await dispatch({
    type: t.SHOW_MAX_PERMIT_LIMIT_MODAL,
    payload: { data: false, msg: "" },
  });
};

export const updateUnitPermitOptions = (body, id) => async (dispatch) => {
  try {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "",
    });
    const url = "permit_options/update-unit-options/" + id;
    const response = await AuthAPI("domain", url, "PATCH", body);
    if (response?.success === 1) {
      await dispatch({
        type: t.UPDATE_UNIT_PERMIT_DATA,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      await dispatch({
        type: t.SHOW_MAX_PERMIT_LIMIT_MODAL,
        payload: { data: false, msg: "" },
      });
    } else if (response?.success === 2) {
      await dispatch({
        type: t.SHOW_MAX_PERMIT_LIMIT_MODAL,
        payload: { data: true, msg: response.message },
      });
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: t.SHOW_MAX_PERMIT_LIMIT_MODAL,
        payload: { data: false, msg: "" },
      });
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
    return response;
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const updateUnitPermitOptionsLotPackage = (body, id) => async (dispatch) => {
  try {
    const url = "permit_options/update-unit-options/" + id;
    const response = await AuthAPI("domain", url, "PATCH", body);
    return response;
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getAllUnitPermitList = (property_id) => async (dispatch) => {
  try {
    const propertyId = property_id.length > 0 ? property_id : null;
    const response = await AuthAPI("domain", "permit_options/get-all-unit-permit/" + propertyId, "GET");
    if (response) {
      await dispatch({
        type: t.GET_UNIT_OPTIONS_LIST,
        payload: response,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getAllPermitGroupList = (property_id, unit_id, lot_id, package_id) => async (dispatch) => {
  try {
    const response = await AuthAPI(
      "domain",
      "permit_options/get-all-permit-group?property_id=" + property_id + "&unit_id=" + unit_id + "&lot_id=" + lot_id + "&package_id=" + package_id,
      "GET",
    );
    if (response) {
      await dispatch({
        type: t.GET_PERMIT_GROUP_LIST,
        payload: response,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const deleteUnitPermit = (id) => async (dispatch) => {
  try {
    const url = "permit_options/" + id;
    const response = await AuthAPI("domain", url, "DELETE");
    if (response?.success) {
      await dispatch({ type: t.DELETE_UNIT_PERMIT, payload: id });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const deleteUnitPermitByLotAndPackage = (property_id, suite_id, lot_id, package_name) => async (dispatch) => {
  try {
    const url = "permit_options/unit/" + property_id + "/" + suite_id + "/" + lot_id + "/" + package_name;
    const response = await AuthAPI("domain", url, "DELETE");
    if (response?.success) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const deletePermitByLotAndPackage = (property_id, lot_id, package_name) => async (dispatch) => {
  try {
    const url = "permit_options/" + property_id + "/" + lot_id + "/" + package_name;
    const response = await AuthAPI("domain", url, "DELETE");
    if (response?.success) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response?.message || "Error occured while deleting data",
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const addPermitsByUnit = (body) => async (dispatch) => {
  try {
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "",
    });

    await dispatch({
      type: ti.SHOW_HIDE_LOADER,
      payload: true,
    });

    const response = await AuthAPI("domain", "permit_options/add-permits-by-unit/" + body.isImport, "POST", body);

    console.log("addPermitsByUnit::", response);

    if (body.isImport === false) {
      if (response?.success === 1) {
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: response.message,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    } else {
      await dispatch({
        type: ti.FETCH_IMPORT_UNITS,
        payload: response,
      });

      if (response[0].status === "Failed") {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: "Import Failed",
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: "Data imported sucessfully",
        });
      }
      await dispatch({
        type: ti.SHOW_HIDE_LOADER,
        payload: false,
      });
      console.log("import response called::: caleed:::");
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getAllUnitStall = (property_id, lot_id) => async (dispatch) => {
  try {
    let response;
    const url = `permit_options/get-stall/${property_id}/${lot_id}`;
    if (property_id && lot_id) {
      response = await AuthAPI("domain", url, "GET");
    }
    if (response) {
      await dispatch({
        type: ti.FETCH_UNIT_STALL,
        payload: response,
      });
    }
  } catch (err) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: err.message || "The server did not respond, Please try again after sometime",
    });
  }
};

export const findUnitSettings = (property_id, suite_id) => async (dispatch) => {
  try {
    const response = await AuthAPI("domain", "permit_options/get-unit-setting/" + property_id + "/" + suite_id, "GET");
    if (response.success === 1) {
      await dispatch({
        type: t.GET_UNIT_PAYMENT_SETTING,
        payload: response?.data,
      });
    } else {
      await dispatch({
        type: t.GET_UNIT_PAYMENT_SETTING,
        payload: {},
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const unSetToasterMessage = () => async (dispatch) => {
  await dispatch({
    type: apiType.API_RESPONSE_SUCCESS,
    payload: "",
  });
  await dispatch({
    type: apiType.API_RESPONSE_FAILURE,
    payload: "",
  });
};

export const unsetPermitOptionsList = () => async (dispatch) => {
  await dispatch({
    type: t.GET_PAID_PERMIT_OPTIONS_LIST,
    payload: [],
  });
  await dispatch({
    type: t.GET_UNIT_OPTIONS_LIST,
    payload: [],
  });
};

export const createGroupShare = (body) => async (dispatch) => {
  try {
    const response = await AuthAPI("domain", "permit_options/add-permit-share-group", "POST", body);
    if (response?.success === 1) {
      await dispatch({
        type: t.SET_PERMIT_GROUP_LIST,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

import React, { Component } from "react";
import { Container, Row, Col, Card, Form, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { SuperAdminAction } from "../../Actions";
import Loader from "../Loader/Loader";
import CustomContentAlert, { utcToSpecificTimeZone, scroolToTop } from "../helper";

class UserSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      searchValue: "",
      selectedOption: "",
      prevSearch: "",
      page: 1,
      pageChanged: false,
      loader: false,
      api_response: false,
      Norow: 100,
      selectedColumn: "created_at",
      sort_direction: "DESC",
      newPropertyList: [],
      allPropertyList: [],
      timezone: this.props.timezone,
      getAllUserlist: [],
      isSearchInputDisabled: true,
      isSearchButtonDisabled: true,
      lpn: "",
    };
    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "0", // override the cell padding for head cells
          paddingRight: "0",
        },
      },
      cells: {
        style: {
          paddingLeft: "0", // override the cell padding for data cells
          paddingRight: "0",
        },
      },
    };

    this.columns = [
      {
        name: "Email",
        sortable: true,
        minWidth: "200px",
        maxWidth: "250px",
        selector: (row) => row.email,
        cell: (row) => this.emailRow(row),
      },
      {
        name: "First Name",
        // center: true,
        minWidth: "150px",
        maxWidth: "250px",
        sortable: true,
        selector: (row) => row.first_name,
        cell: (row) => this.firstNameRow(row),
      },
      {
        name: "Last Name",
        // center: true,
        sortable: true,
        minWidth: "150px",
        maxWidth: "250px",
        selector: (row) => row.last_name,
        cell: (row) => this.lastNameRow(row),
      },
      {
        name: "Phone",
        // center: true,
        sortable: true,
        minWidth: "150px",
        maxWidth: "250px",
        selector: (row) => row.phone,
        cell: (row) => this.phoneRow(row),
      },
      {
        name: "LPN",
        // center: true,
        sortable: true,
        minWidth: "150px",
        maxWidth: "250px",
        selector: (row) => row?.lpn,
        cell: (row) => this.lpnRow(row),
      },
      {
        name: "Package Name",
        // center: true,
        sortable: true,
        minWidth: "150px",
        maxWidth: "250px",
        selector: (row) => row?.package_name,
        cell: (row) => this.packageRow(row),
      },
      {
        name: "Unit Id",
        // center: true,
        sortable: true,
        minWidth: "150px",
        maxWidth: "250px",
        selector: (row) => row?.suite_id,
        cell: (row) => this.unitRow(row),
      },
      {
        name: "Site Name",
        // center: true,
        sortable: true,
        minWidth: "140px",
        maxWidth: "250px",
        selector: (row) => row?.properties?.site_name,
        cell: (row) => this.siteNameRow(row),
      },
      {
        name: "Site Domain",
        minWidth: "140px",
        maxWidth: "250px",
        // center: true,
        sortable: true,
        selector: (row) => row?.properties?.domain,
        cell: (row) => this.domainNameRow(row),
      },
      {
        name: "User Type",
        minWidth: "140px",
        maxWidth: "250px",
        // center: true,
        sortable: true,
        selector: (row) => row.user_type,
        cell: (row) => this.userTypeRow(row),
      },
      {
        name: "Created At",
        minWidth: "160px",
        maxWidth: "250px",
        // center: true,
        sortable: true,
        selector: (row) => row.createdAt,
        cell: (row) => this.createdAtRow(row),
      },
      {
        name: "Last Login",
        minWidth: "160px",
        // center: true,
        sortable: true,
        selector: (row) => row?.last_acessed_at,
        cell: (row) => this.lastLoginRow(row),
      },
    ];
  }

  unitRow = (row) => (
    <>
      {row?.suite_id ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.suite_id}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.suite_id}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  emailRow = (row) => (
    <>
      {row.email ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.email}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.email}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showCellValue = (cellValue, valueType) => {
    switch (valueType) {
      case "text":
        return <div className="text-nowrap text-truncate pr-2">{cellValue ? cellValue : "-"}</div>;
      case "amount":
        return <div className="text-nowrap text-truncate pr-2">{cellValue ? Math.max(0, parseFloat(cellValue)).toFixed(2) : "-"}</div>;
      case "time":
        return <div className="text-nowrap text-truncate pr-2">{cellValue ? utcToSpecificTimeZone(this.props.timezone, cellValue, true) : "-"}</div>;
      default:
        break;
    }
  };

  firstNameRow = (row) => (
    <>
      {row.first_name ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.first_name}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.first_name}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  lastNameRow = (row) => (
    <>
      {row.last_name ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.last_name}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.last_name}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  siteNameRow = (row) => (
    <>
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement={"top"}
        overlay={<Tooltip id={`tooltip-qw`}>{row?.properties?.site_name ? row?.properties?.site_name : row?.site_name}</Tooltip>}
      >
        <div className="text-nowrap text-truncate pr-2">{row?.properties?.site_name ? row?.properties?.site_name : row?.site_name}</div>
      </OverlayTrigger>
    </>
  );

  domainNameRow = (row) => {
    return (
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement={"top"}
        overlay={<Tooltip id={`tooltip-qw`}>{row?.properties?.domain ? row?.properties?.domain : row?.domain}</Tooltip>}
      >
        <div className="text-nowrap text-truncate pr-2">{row?.properties?.domain ? row?.properties?.domain : row?.domain}</div>
      </OverlayTrigger>
    );
  };
  createdAtRow = (row) => (
    <>
      {row.createdAt ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{row.createdAt ? utcToSpecificTimeZone(this.props.timezone, row.createdAt) : "-"}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(this.props.timezone, row.createdAt)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  userTypeRow = (row) => (
    <>
      {row?.user_type ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.user_type}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.user_type}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  phoneRow = (row) => (
    <>
      {row?.phone ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.phone}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.phone}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  lpnRow = (row) => {
    if (this.state.selectedOption === "lpn") {
      return (
        <>
          <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-qw`}>{this.state.searchValue.toUpperCase()}</Tooltip>}
          >
            <div className="text-nowrap text-truncate pr-2">{this.state.searchValue.toUpperCase()}</div>
          </OverlayTrigger>
        </>
      );
    } else {
      return (
        <>
          {row?.lpn ? (
            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.lpn}</Tooltip>}>
              <div className="text-nowrap text-truncate pr-2">{row.lpn}</div>
            </OverlayTrigger>
          ) : (
            "-"
          )}
        </>
      );
    }
  };

  packageRow = (row) => (
    <>
      {row?.package_name ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.package_name}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.package_name}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  lastLoginRow = (row) => {
    this.showCellValue(row?.last_acessed_at, "time");
  };

  componentDidMount = async () => {
    this.setState({ loader: true }, async () => {
      const { page, Norow, searchValue, selectedColumn, sort_direction, lpn, selectedOption } = this.state;
      const pageobj = {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        lpn,
        selectedOption,
      };
      await this.props.get_allUser(pageobj);
      if (this.props.api_response) {
        this.setState({
          getAllUserlist: this.props.getAllUserlist?.rows,
          count: this.props.count,
          api_response: this.props.api_response,
        });
        this.setState({ loader: false });
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.getAllUserlist.length !== this.props.getAllUserlist.length) {
      this.setState({
        newPropertyList: this.props.getAllUserlist,
        getAllUserlist: this.props.getAllUserlist,
        count: this.props.count,
      });
    }
  }

  handleSearchChange = async ({ target }) => {
    this.setState({ searchValue: target.value }, async () => {
      if (this.state.searchValue) {
        this.setState({ isSearchButtonDisabled: false });
      } else {
        this.setState({ isSearchButtonDisabled: true, loader: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction, lpn, selectedOption } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            lpn,
            selectedOption,
          };
          await this.props.get_allUser(pageobj);
          if (this.props.api_response) {
            this.setState({
              getAllUserlist: this.props.getAllUserlist.rows,
              count: this.props.count,
              api_response: this.props.api_response,
              loader: false,
            });
          }
        });
        this.setState({
          loader: false,
        });
      }
    });
  };

  handleSearchClick = async () => {
    this.setState({
      getAllUserlist: [],
    });
    if (
      this.state.selectedOption === "flname" ||
      this.state.selectedOption === "phone" ||
      this.state.selectedOption === "email" ||
      this.state.selectedOption === "lpn" ||
      this.state.selectedOption === "suite_id" ||
      this.state.selectedOption === "package_name"
    ) {
      this.getFilteredUsers();
    }
  };

  getFilteredUsers = async () => {
    const { Norow, searchValue, selectedColumn, sort_direction, lpn, selectedOption } = this.state;
    const pageobj = {
      page: 1,
      Norow,
      searchValue,
      selectedColumn,
      sort_direction,
      lpn,
      selectedOption,
    };
    this.setState({
      loader: true,
    });
    await this.props.get_allUser(pageobj);
    if (this.props.getAllUserlist?.rows) {
      this.setState({
        getAllUserlist: JSON.parse(JSON.stringify(this.props.getAllUserlist?.rows)),
        count: this.props.count,
        loader: false,
        page: 1,
      });
    }
  };

  handleUpdateChange = (e) => {
    this.setState(
      {
        selectedOption: e.target.value,
        isSearchInputDisabled: false,
        searchValue: "",
      },
      async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction, lpn, selectedOption } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          lpn,
          selectedOption,
        };
        await this.props.get_allUser(pageobj);
        if (this.props.getAllUserlist?.rows) {
          this.setState({
            getAllUserlist: JSON.parse(JSON.stringify(this.props.getAllUserlist.rows)),
          });
        }
      },
    );
  };

  dataTableHandle = (ticketList) => {
    return (
      <DataTable
        data={ticketList}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight="38vh"
        pagination={true}
        paginationPerPage={this.state.Norow}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  render() {
    const { selectedOption, loader, api_response, getAllUserlist } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="mb-0">
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <Card.Title as="h4">User Search</Card.Title>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="pos-rel-overflow-hide">
                <Row>
                  <Col md="12">
                    <div className="light-bg2 d-flex flex-column justify-content-center align-items-left h-100 flex-wrap sa-people-searchbar-filter">
                      <div className="w-100 ">
                        <Form.Group className="ml-3 mt-2 mb-2 mr-3 d-flex flex-wrap">
                          <div className="d-flex mb-1">
                            <Form.Check
                              inline
                              label="Email"
                              name="email"
                              type="radio"
                              value="email"
                              id="1"
                              onChange={(e) => {
                                this.handleUpdateChange(e);
                              }}
                              checked={selectedOption === "email"}
                            />
                          </div>
                          <div className="d-flex mb-1">
                            <Form.Check
                              inline
                              label="First Name / Last Name"
                              name="flname"
                              type="radio"
                              value="flname"
                              id="2"
                              onChange={(e) => {
                                this.handleUpdateChange(e);
                              }}
                              checked={selectedOption === "flname"}
                            />
                          </div>
                          <div className="d-flex mb-1">
                            <Form.Check
                              inline
                              label="Phone"
                              name="phone"
                              type="radio"
                              value="phone"
                              id="3"
                              onChange={(e) => {
                                this.handleUpdateChange(e);
                              }}
                              checked={selectedOption === "phone"}
                            />
                          </div>
                          <div className="d-flex mb-1">
                            <Form.Check
                              inline
                              label="LPN"
                              name="lpn"
                              type="radio"
                              value="lpn"
                              id="4"
                              onChange={(e) => {
                                this.handleUpdateChange(e);
                              }}
                              checked={selectedOption === "lpn"}
                            />
                          </div>
                          <div className="d-flex mb-1">
                            <Form.Check
                              inline
                              label="UNIT ID"
                              name="suite_id"
                              type="radio"
                              value="suite_id"
                              id="5"
                              onChange={(e) => {
                                this.handleUpdateChange(e);
                              }}
                              checked={selectedOption === "suite_id"}
                            />
                          </div>
                          <div className="d-flex mb-1">
                            <Form.Check
                              inline
                              label="PACKAGE NAME"
                              name="package_name"
                              type="radio"
                              value="package_name"
                              id="6"
                              onChange={(e) => {
                                this.handleUpdateChange(e);
                              }}
                              checked={selectedOption === "package_name"}
                            />
                          </div>
                          <div className="d-flex header-search-button active-permit-header w-auto mb-0">
                            <div className="search-container d-flex">
                              <div className="position-relative">
                                <i class="fas fa-search"></i>
                                <input
                                  autoFocus={false}
                                  type="text"
                                  placeholder="Search"
                                  value={this.state.searchValue}
                                  onChange={this.handleSearchChange}
                                  disabled={this.state.isSearchInputDisabled}
                                  className="form-control custom-input-search form-control-sm"
                                />
                              </div>
                              <Button
                                className={"search-btn custom-btn-secondary custom-btn2 text-nowrap form-control-sm"}
                                size="sm"
                                onClick={this.handleSearchClick}
                                disabled={this.state.isSearchButtonDisabled}
                              >
                                {" "}
                                Search
                              </Button>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col md="12" className="people-search-table"> */}
                {loader ? (
                  <Loader />
                ) : (
                  <>
                    {!api_response && (
                      <CustomContentAlert delay={4000} message={"The server did not respond, Please try again after sometime"} className="toast-error" />
                    )}
                    {getAllUserlist && (
                      <>
                        <div className="people-search-table citation-list-table list-of-ravenue-table sa-people-search-table" style={{ paddingLeft: "10px" }}>
                          {this.dataTableHandle(getAllUserlist)}
                        </div>
                      </>
                    )}
                  </>
                )}
                <Row>
                  <Col md="12"></Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStatetoProps = ({ SuperAdminDetails, APIResponseDetails, UserProfileDetails }) => {
  const { userList, userCount: count, getAllUserlist } = SuperAdminDetails;
  const { timezone } = UserProfileDetails;

  const { api_response } = APIResponseDetails;
  return { api_response, userList, count, getAllUserlist, timezone };
};
const mapStateToDispatch = {
  get_all_properties: SuperAdminAction.get_all_properties,
  fetchUsersPaginate: SuperAdminAction.fetchUsersPaginate,
  get_allUser: SuperAdminAction.get_allUser,
};

export default connect(mapStatetoProps, mapStateToDispatch)(UserSearch);

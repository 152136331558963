import React, { Component } from "react";
import { Card, Row, Col, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import DataTable from "react-data-table-component";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { connect } from "react-redux";
import { SuperAdminAction, TicketActions } from "../../Actions";
import Loader from "../Loader/Loader";
import { tableHeightScrollBars, findBySearch, scroolToTop } from "../helper";
import "react-datepicker/dist/react-datepicker.css";
import PropertDetails from "../LandingPage/PropertySelection";
import { saveAs } from "file-saver";
import moment from "moment";

class Transaction extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.state = {
      PropertyId: this.props.PropertyId,
      totalCitationList: [],
      loader: false,
      user_type: this.props.user_type,
      searchValue: "",
      api_response: false,
      show_alert: false,
      api_success_message: "",
      api_error_message: "",
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      ticketDetails: {},
      selectedPropertyObj: {},
      all_properties: [],
      childcheckbox: true,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentDate: "",
      month: "",
      year: "",
      activeTab: "1",
      apiResponse: "",
      exportButtonDisable: true,
      finalTableHeight: 0,
      daysDifference: 30,
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "Domain",
        width: "130px",
        sortable: true,
        cell: (row) => this.showDomainField(row),
        selector: (row) => row?.domain,
      },
      {
        name: "Mon",
        width: "100px",
        sortable: true,
        cell: (row) => this.showMonField(row),
        selector: (row) => row.mon,
      },
      {
        name: "Month",
        width: "130px",
        sortable: true,
        cell: (row) => this.showMonthField(row),
        selector: (row) => row?.month,
      },
      {
        name: "Issued #",
        width: "100px",
        sortable: true,
        cell: (row) => this.showIssuedCountField(row),
        selector: (row) => row?.issued,
      },
      {
        name: "Issued $",
        width: "100px",
        sortable: true,
        cell: (row) => this.showIssuedFeeField(row),
        selector: (row) => row?.issuedFee,
      },
      {
        name: "Paid #",
        width: "100px",
        sortable: true,
        cell: (row) => this.showPaidField(row),
        selector: (row) => row?.paid,
      },
      {
        name: "Paid $",
        width: "100px",
        sortable: true,
        cell: (row) => this.showPaidFeeField(row),
        selector: (row) => row.paidFee,
      },
      {
        name: "Paid # %",
        width: "100px",
        sortable: true,
        cell: (row) => this.showPaidPerField(row),
        selector: (row) => row.paidPer,
      },
      {
        name: "Unpaid #",
        sortable: true,
        width: "100px",
        cell: (row) => this.showUnpaidField(row),
        selector: (row) => row.unpaid,
      },
      {
        name: "Unpaid $",
        sortable: true,
        width: "100px",
        cell: (row) => this.showUnpaidFeeField(row),
        selector: (row) => row.unpaidFee,
      },
      {
        name: "Unpaid # %",
        sortable: true,
        width: "110px",
        cell: (row) => this.showUnpaidPerField(row),
        selector: (row) => row.UnpaidPer,
      },
      {
        name: "InDispute #",
        sortable: true,
        width: "110px",
        cell: (row) => this.showInDisputeField(row),
        selector: (row) => row.inDispute,
      },
      {
        name: "InDispute $",
        sortable: true,
        width: "110px",
        cell: (row) => this.showInDisputeFeeField(row),
        selector: (row) => row.inDisputeFee,
      },
      {
        name: "InDispute # %",
        sortable: true,
        width: "130px",
        cell: (row) => this.showInDisputePerField(row),
        selector: (row) => row.inDisputePer,
      },
      {
        name: "Invalid #",
        sortable: true,
        width: "100px",
        cell: (row) => this.showInValidField(row),
        selector: (row) => row.invalid,
      },
      {
        name: "Invalid $",
        sortable: true,
        width: "100px",
        cell: (row) => this.showInValidFeeField(row),
        selector: (row) => row.invalidFee,
      },
      {
        name: "Invalid # %",
        sortable: true,
        width: "120px",
        cell: (row) => this.showInvalidPerField(row),
        selector: (row) => row.invalidPer,
      },
      {
        name: "Testing #",
        sortable: true,
        width: "100px",
        cell: (row) => this.showTestingField(row),
        selector: (row) => row.testing,
      },
      {
        name: "Testing $",
        sortable: true,
        width: "100px",
        cell: (row) => this.showTestingFeeField(row),
        selector: (row) => row.testingFee,
      },
      {
        name: "Testing # %",
        sortable: true,
        width: "120px",
        cell: (row) => this.showTestingPerField(row),
        selector: (row) => row.testingPer,
      },
      {
        name: "Refund #",
        sortable: true,
        width: "100px",
        cell: (row) => this.showRefundField(row),
        selector: (row) => row.refund,
      },
      {
        name: "Refund $",
        sortable: true,
        width: "100px",
        cell: (row) => this.showRefundFeeField(row),
        selector: (row) => row.refundFee,
      },
      {
        name: "Refund # %",
        sortable: true,
        width: "120px",
        cell: (row) => this.showRefundPerField(row),
        selector: (row) => row.refundPer,
      },
    ];
  }

  showRefundPerField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.refundPer ? row.refundPer : "0"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.refundPer ? row.refundPer : "0"}</div>
    </OverlayTrigger>
  );

  showRefundFeeField = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{row.refundFee ? `$${row.refundFee}` : "0"}</Tooltip>}
    >
      <div className="text-truncate text-nowrap pr-2">{row.refundFee ? `$${row.refundFee}` : "0"}</div>
    </OverlayTrigger>
  );

  showRefundField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.refund || "0"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.refund || "0"}</div>
    </OverlayTrigger>
  );

  showTestingPerField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.testingPer ? row.testingPer : "0"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.testingPer ? row.testingPer : "0"}</div>
    </OverlayTrigger>
  );

  showTestingFeeField = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{row.testingFee ? `$${row.testingFee}` : "0"}</Tooltip>}
    >
      <div className="text-truncate text-nowrap pr-2">{row.testingFee ? `$${row.testingFee}` : "0"}</div>
    </OverlayTrigger>
  );

  showTestingField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.testing ? row.testing : "0"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.testing ? row.testing : "0"}</div>
    </OverlayTrigger>
  );

  showInvalidPerField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.invalidPer ? row.invalidPer : "0"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.invalidPer ? row.invalidPer : "0"}</div>
    </OverlayTrigger>
  );

  showInValidFeeField = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{row.invalidFee ? `$${row.invalidFee}` : "0"}</Tooltip>}
    >
      <div className="text-truncate text-nowrap pr-2">{row.invalidFee ? `$${row.invalidFee}` : "0"}</div>
    </OverlayTrigger>
  );

  showInValidField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.invalid ? row.invalid : "0"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.invalid ? row.invalid : "0"}</div>
    </OverlayTrigger>
  );

  showInDisputePerField = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{row.inDisputePer ? row.inDisputePer : "0"}</Tooltip>}
    >
      <div className="text-truncate text-nowrap pr-2">{row.inDisputePer ? row.inDisputePer : "0"}</div>
    </OverlayTrigger>
  );

  showInDisputeFeeField = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{row.inDisputeFee ? `$${row.inDisputeFee}` : "0"}</Tooltip>}
    >
      <div className="text-truncate text-nowrap pr-2">{row.inDisputeFee ? `$${row.inDisputeFee}` : "0"}</div>
    </OverlayTrigger>
  );

  showInDisputeField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.inDispute ? row.inDispute : "0"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.inDispute ? row.inDispute : "0"}</div>
    </OverlayTrigger>
  );

  showUnpaidPerField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.UnpaidPer ? row.UnpaidPer : "0"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.UnpaidPer ? row.UnpaidPer : "0"}</div>
    </OverlayTrigger>
  );

  showUnpaidFeeField = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{row.unpaidFee ? `$${row.unpaidFee}` : "0"}</Tooltip>}
    >
      <div className="text-truncate text-nowrap pr-2">{row.unpaidFee ? `$${row.unpaidFee}` : "0"}</div>
    </OverlayTrigger>
  );

  showUnpaidField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.unpaid ? row.unpaid : "0"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.unpaid ? row.unpaid : "0"}</div>
    </OverlayTrigger>
  );

  showPaidPerField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.paidPer ? row.paidPer : "0"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.paidPer ? row.paidPer : "0"}</div>
    </OverlayTrigger>
  );

  showPaidFeeField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.paidFee ? `$${row.paidFee}` : "0"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.paidFee ? `$${row.paidFee}` : "0"}</div>
    </OverlayTrigger>
  );

  showPaidField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.paid ? row?.paid : "0"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row?.paid ? row?.paid : "0"}</div>
    </OverlayTrigger>
  );

  showIssuedFeeField = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{row?.issuedFee ? `$${row?.issuedFee}` : "0"}</Tooltip>}
    >
      <div className="text-truncate text-nowrap pr-2">{row?.issuedFee ? `$${row?.issuedFee}` : "0"}</div>
    </OverlayTrigger>
  );

  showIssuedCountField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.issued ? row?.issued : "0"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row?.issued ? row?.issued : "0"}</div>
    </OverlayTrigger>
  );

  showMonthField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.month ? row?.month : "-"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row?.month ? row?.month : "-"}</div>
    </OverlayTrigger>
  );

  showMonField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.mon ? row.mon : "-"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.mon ? row.mon : "-"}</div>
    </OverlayTrigger>
  );

  showDomainField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.domain}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row?.domain}</div>
    </OverlayTrigger>
  );

  changeProperty(event) {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState({
      selectedProperty: event.value,
      selectedPropertyObj: event,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      month: null,
      year: null,
    });
  }

  handleCheckBox = () => {
    this.setState({ childcheckbox: !this.state.childcheckbox });
  };

  componentDidMount = async () => {
    await this.getTickets();
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    this.setState(
      {
        api_response: this.props?.api_response,
        all_properties: this.props?.getAllProperties,
      },
      async () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );

    const height = tableHeightScrollBars();
    this.setState({
      finalTableHeight: height,
    });
  };

  getTickets = async () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, childcheckbox, timezone, startDate, endDate } = this.state;
      const pageobj = {
        PropertyId,
        timezone,
        childcheckbox,
      };
      if (startDate) {
        pageobj.startDate = startDate;
      }
      if (endDate) {
        pageobj.endDate = endDate;
      }
      await this.props.getReportList(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        totalCitationList: this.props?.totalCitationList?.rows?.length ? JSON.parse(JSON.stringify(this.props.totalCitationList.rows)) : [],
        api_response: this.props.api_response,
        count: this.props.totalCitationList.count,
      });
      this.setState({ loader: false });
    });
  };

  dataTableHandle = (totalCitationList) => {
    return (
      <DataTable
        data={this.state.searchValue.length ? this.state.searchedtransactionsList : totalCitationList}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        onChangePage={scroolToTop}
        fixedHeader={true}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleSearch = () => {
    this.setState({ loader: true }, async () => {
      const { childcheckbox, selectedPropertyObj, PropertyId, timezone } = this.state;
      const pageobj = {
        childcheckbox,
        PropertyId,
        timezone,
      };
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }

      await this.props.getReportList(pageobj);

      this.setState({
        totalCitationList: JSON.parse(JSON.stringify(this.props.totalCitationList.rows)),
        api_response: this.props.api_response,
        count: this.props.totalCitationList.count,
        exportButtonDisable: false,
      });
      this.setState({ loader: false });
      document.body.classList.remove("foc-open");
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();

    this.setState({ searchValue: val }, async () => {
      const columnList = [
        "mon",
        "month",
        "issued",
        "issuedFee",
        "paid",
        "paidFee",
        "paidPer",
        "unpaid",
        "unpaidFee",
        "unpaidPer",
        "inDispute",
        "inDisputeFee",
        "inDisputePer",
        "invalid",
        "invalidFee",
        "invalidPer",
        "testing",
        "testingFee",
        "testingPer",
      ];
      if (this.state.searchValue.length) {
        this.setState(
          {
            searchedtransactionsList: findBySearch(
              this.state.searchValue,
              this.state.totalCitationList,
              this.state.totalCitationList,
              columnList,
              this.props.timezone,
            ),
          },
          () => {
            this.setState({
              count: this.state.searchedtransactionsList.length,
            });
          },
        );
      } else {
        this.setState({
          count: this.props.totalCitationList.count,
          searchedtransactionsList: [],
          totalCitationList: this.props.totalCitationList.rows,
        });
      }
    });
  };

  resetAll = async () => {
    this.setState({
      selectedPropertyObj: {},
      childcheckbox: true,
      exportButtonDisable: true,
    });
    const { PropertyId, selectedDomainName, timezone } = this.state;
    const pageobj = {
      PropertyId,
      timezone,
    };

    await this.props.getReportList(pageobj);
    this.setState({
      pageChanged: true,
      page: 1,
      totalCitationList: this.props.totalCitationList.rows,
      count: this.props.totalCitationList.count,
      selectedPropertyObj: {
        label: selectedDomainName,
        value: PropertyId,
      },
    });
    this.setState({ loader: false });
    document.body.classList.remove("foc-open");
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleExport = async () => {
    const { childcheckbox, selectedPropertyObj, PropertyId, selectedDomainName, timezone } = this.state;
    const pageobj = {
      childcheckbox,
      PropertyId,
      timezone,
      exportData: true,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    const bufferData = await this.props.getReportList(pageobj);
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `TOTAL_CITATIONS-${currentTimeDate}-${selectedDomainName}.xls`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  render() {
    const { totalCitationList, loader, childcheckbox, searchValue } = this.state;

    return (
      <div>
        <Card className="mb-0">
          <Card.Body className="pos-rel-overflow-hide">
            <>
              <div className="sidebarFilter">
                <Card className="contact-container mb-0">
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title as="h4">Total Citations</Card.Title>
                      <a
                        onClick={this.hideSlider}
                        href="javascript:void(0);"
                        className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <label className="fancy-checkbox">
                            <input
                              type="checkbox"
                              name={"childcheckbox"}
                              checked={childcheckbox}
                              onChange={this.handleCheckBox}
                              className="custom-control-input"
                              id="childcheckbox1"
                            ></input>
                            <span>
                              <i></i>Include Children Sites
                            </span>
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-right justify-content-between d-flex">
                    <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                      Reset
                    </Button>
                    <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                      Search
                    </Button>
                  </Card.Footer>
                </Card>
              </div>
              <header>
                <div className="active-permit-header sm-flex-column sm-align-items-start">
                  <h5 className="mb-2 mb-md-0"></h5>
                  <div className="filter-button d-flex flex-wrap">
                    <div className="d-flex align-items-center search-mb-0">
                      <DatatableHeader
                        className="mt-0 mb-0"
                        searchValue={searchValue}
                        HandleChange={this.handleSearchChange}
                        // findMorePressed={this.findMorePressed}
                      />
                    </div>
                    <div className="ml-2">
                      <Button
                        variant="info"
                        className="btn-fill btn-sm"
                        onClick={this.handleExport}
                        disabled={totalCitationList.length > 0 ? 0 : 1}
                        size="medium"
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </header>
            </>
            {loader ? (
              <Loader />
            ) : (
              <>
                {totalCitationList && (
                  <>
                    <div className="citation-list-table citation-analysis-total">{this.dataTableHandle(totalCitationList)}</div>
                    <div className="px-1 pt-3" style={{ fontSize: 13 }}>
                      <Row>
                        <Col lg={12}>
                          <span className="font-weight-600">Paid</span>
                          <span className="mr-1"> :</span> Paid, Paid Cash, Paid Check, Paid Credit Card Manually, Paid Interac
                        </Col>
                        <Col lg={12}>
                          <span className="font-weight-600">Unpaid</span>
                          <span className="mr-1"> :</span> Unpaid, Reduced
                        </Col>
                        <Col lg={12}>
                          <span className="font-weight-600">In Dispute</span>
                          <span className="mr-1"> :</span> In Dispute, In-Dispute2, Dispute Rejected, Dispute Rejected 2, Dispute Approved
                        </Col>
                        <Col lg={12}>
                          <span className="font-weight-600">Invalid</span>
                          <span className="mr-1">:</span> Invalid, Warning
                        </Col>
                        <Col lg={12}>
                          <span className="font-weight-600">Testing</span> <span className="mr-1">:</span> Testing
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, Logindetails, SuperAdminDetails, TicketDetails }) => {
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;

  const { backgroundColor } = Logindetails;
  const { getAllProperties } = SuperAdminDetails;
  const { MainBackground: main_body } = backgroundColor;
  const { totalCitationList } = TicketDetails;
  return {
    api_response,
    api_error_message,
    api_success_message,
    PropertyId,
    timezone,
    property,
    totalCitationList,
    main_body,
    getAllProperties,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  getReportList: TicketActions.getTotalAnalysisList,
};

export default connect(mapStateToProps, mapStateToDispatch)(Transaction);

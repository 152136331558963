import React, { Component } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { connect } from "react-redux";

import { SuperAdminAction } from "../../Actions";
import MerchantValidation from "./MerchantValidation";
import BetchValidation from "./BetchValidation";

class Validations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
    };
  }

  onTabClick = async (k) => {
    this.setState({ activeTab: k });
  };

  render() {
    const { activeTab } = this.state;
    return (
      <Container fluid>
        <Tabs activeKey={this.state.activeTab} id="controlled-tab-example" onSelect={(k) => this.onTabClick(k)} transition={false}>
          <Tab eventKey="1" title="Discount Coupons" className="m-0">
            <BetchValidation />
          </Tab>
          <Tab eventKey="2" title="Merchant Validator" className="m-0">
            {activeTab === "2" && <MerchantValidation reportName={"Merchant Validator"} reportType={activeTab} />}
          </Tab>
          <Tab eventKey="3" title="Discount summary" className="m-0">
            {activeTab === "3" && <MerchantValidation reportName={"Discount Summary"} reportType={activeTab} />}
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails }) => {
  const { property_id: PropertyId, user_type, suite_id, timezone, property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;

  const { MainBackground: main_body } = backgroundColor;
  return {
    PropertyId,
    user_type,
    suite_id,
    timezone,
    property,
    main_body,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(Validations);

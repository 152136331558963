import t from "../../Actions/CurrentVisitorPermitActions/types";

const intitialState = {
  currentVisitorPermit: [],
  QuickPermitSetupDetail: {},
  ParkingPermitSetupDetail: {},
  QuickPermitPinDetail: {},
};

const CurrentVisitorPermitReducer = (state = intitialState, action) => {
  switch (action.type) {
    case t.FETCH_CURRENT_VISITOR_PERMIT:
      return {
        ...state,
        currentVisitorPermit: action.payload,
      };
    case t.FETCH_QUICK_PERMIT_SETTING_DETAILS:
      return {
        ...state,
        QuickPermitSetupDetail: action.payload,
      };
    case t.FETCH_PARKING_PERMIT_SETTING_DETAILS:
      return {
        ...state,
        ParkingPermitSetupDetail: action.payload,
      };
    case "FETCH_QUICK_PERMIT_SETTING_DETAILS_BLANK":
      return {
        ...state,
        QuickPermitSetupDetail: {},
      };
    case "FETCH_PARKING_PERMIT_SETTING_DETAILS_BLANK":
      return {
        ...state,
        ParkingPermitSetupDetail: {},
      };
    case t.FETCH_QUICK_PERMIT_PIN_DETAILS:
      return {
        ...state,
        QuickPermitPinDetail: action.payload,
      };
    default:
      return state;
  }
};

export default CurrentVisitorPermitReducer;

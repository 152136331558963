import React, { Component } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import CustomContentAlert, { findBySearch } from "../helper";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { SuperAdminAction } from "../../Actions";
import Loader from "../Loader/Loader";
import PaginationforData from "../pagination/pagination";

class AddCamera extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      filterdCameraList: [],
      allCameraList: [],
      newCameraList: [],
      loader: false,
      api_response: false,
      prevSearch: "",
      prevPage: 0,
      prevNorow: 0,
      page: 1,
      count: 0,
      Norow: 10,
      selectedColumn: "createdAt",
      asc: "false",
      sort_direction: "DESC",
      pageChanged: false,
      findMore: false,
    };
    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "0", // override the cell padding for head cells
          paddingRight: "0",
        },
      },
      cells: {
        style: {
          paddingLeft: "0", // override the cell padding for data cells
          paddingRight: "0",
        },
      },
    };

    this.columns = [
      // {
      //   name: "Create Time",
      //   sortable: true,
      //   width: "120px",
      //   selector: (row) => row.createdAt,
      //   cell: (row) => <div>{moment(row.createdAt).format("lll")}</div>,
      // },
      {
        name: "I.P.",
        sortable: true,
        selector: (row) => row.ip_addr,
        cell: this.showIpAddress,
      },
      {
        name: "Plate",
        sortable: true,
        selector: (row) => row.plate,
        cell: this.showPlate,
      },
      {
        name: "Lane",
        sortable: true,
        selector: (row) => row.lane,
        cell: this.showLane,
      },
      {
        name: "Type",
        sortable: true,
        selector: (row) => row.type,
        cell: this.showType,
      },
    ];
  }

  showIpAddress = (row) => <div>{row.ip_addr}</div>;

  showPlate = (row) => <div>{row.plate}</div>;

  showLane = (row) => <div>{row?.lane?.alias}</div>;

  showType = (row) => <div>{row.type}</div>;

  componentDidMount() {
    const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
    const pageobj = {
      page,
      Norow,
      searchValue,
      selectedColumn,
      sort_direction,
    };
    this.setState({ loader: true }, async () => {
      await this.props.get_all_cameras_paginated(pageobj);
      this.setState({
        allCameraList: this.props.camera_list,
        count: this.props.count,
        api_response: this.props.api_response,
      });
      this.setState({ loader: false });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.camera_list !== this.props.camera_list) {
      this.setState({
        newCameraList: this.props.camera_list,
        count: this.props.count,
      });
    }
  }

  handleSearchChange = ({ target }) => {
    this.setState({
      prevSearch: this.state.searchValue,
      page: 1,
      pageChanged: true,
    });
    this.setState({ searchValue: target.value }, () => {
      if (this.state.searchValue.length) {
        if (!this.state.findMore) {
          this.setState(
            {
              newCameraList: findBySearch(this.state.searchValue, this.state.allCameraList, this.state.allCameraList),
            },
            () => {
              this.setState({ count: this.state.newCameraList.length });
            },
          );
        }
      } else {
        this.setState({ loader: true, pageChanged: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          await this.props.get_all_cameras_paginated(pageobj);
          this.setState({
            pageChanged: true,
            page: 1,
            newCameraList: this.state.newCameraList,
          });
          this.setState({ loader: false });
        });
      }
      setTimeout(() => {
        this.setState({ findMore: false });
      }, 1000);
    });
  };

  handlepage = ({ target }) => {
    this.setState({ prevPage: this.state.page, prevNorow: this.state.Norow });
    this.setState(
      {
        [target.name]: target.value,
      },
      async () => {
        switch (target.name) {
          case "page":
            this.setState({ page: target.value }, async () => {
              const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
              const pageobj = {
                page,
                Norow,
                searchValue,
                selectedColumn,
                sort_direction,
              };
              this.setState({ loader: true, pageChanged: true }, async () => {
                await this.props.get_all_cameras_paginated(pageobj);
                this.setState({
                  allCameraList: this.props.camera_list,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
          case "Norow":
            this.setState({ Norow: target.value }, async () => {
              this.setState({ loader: true, pageChanged: true }, async () => {
                if (this.state.Norow >= this.state.count) {
                  this.setState(
                    {
                      page: 1,
                    },
                    async () => {
                      const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
                      const pageobj = {
                        page,
                        Norow,
                        searchValue,
                        selectedColumn,
                        sort_direction,
                      };
                      await this.props.get_all_cameras_paginated(pageobj);
                    },
                  );
                } else {
                  const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
                  const pageobj = {
                    page,
                    Norow,
                    searchValue,
                    selectedColumn,
                    sort_direction,
                  };
                  await this.props.get_all_cameras_paginated(pageobj);
                }
                this.setState({
                  allCameraList: this.props.camera_list,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
        }
        if (this.state.page !== this.state.prevPage || this.state.Norow !== this.state.prevNorow) {
          const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          this.setState({ loader: true }, async () => {
            await this.props.get_all_cameras_paginated(pageobj);
            this.setState({
              newCameraList: this.props.camera_list,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        }
      },
    );
  };

  handlepagenext = async () => {
    const { Norow: noOfRow } = this.state;
    const count = this.props.count;
    const finalPage = Math.ceil(count / noOfRow);
    if (this.state.page + 1 <= finalPage) {
      this.setState({ page: this.state.page + 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true, pageChanged: true }, this.getAllCameras(pageobj));
      });
    }
  };

  handlepageprevious = () => {
    if (this.state.page !== 1) {
      this.setState({ page: this.state.page - 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true }, this.getAllCameras(pageobj));
      });
    }
  };

  getAllCameras = async (pageobj) => {
    await this.props.get_all_cameras_paginated(pageobj);
    this.setState(
      {
        newCameraList: this.props.camera_list,
        api_response: this.props.api_response,
      },
      // console.log("previous", this.state.newCameraList)
    );
    this.setState({ loader: false });
  };

  handlepageinitial = () => {
    if (this.state.page !== 1) {
      this.setState({ page: 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true }, async () => {
          await this.props.get_all_cameras_paginated(pageobj);
          this.setState(
            {
              newCameraList: this.props.camera_list,
              api_response: this.props.api_response,
            },
            // () => console.log("initial page", this.state.newCameraList)
          );
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepagefinal = async () => {
    const { page, Norow } = this.state;
    const count = this.props.count;
    let last_page = count / Norow;
    if (last_page === 0) {
      last_page = 1;
    }
    const rounded = Math.ceil(last_page);
    if (page < rounded) {
      this.setState({ page: rounded, pageChanged: true }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true, pageChanged: true }, async () => {
          await this.props.get_all_cameras_paginated(pageobj);
          this.setState({
            newCameraList: this.props.camera_list,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  findMorePressed = () => {
    this.setState({ findMore: true }, () => {
      if (this.state.findMore) {
        if (this.state.searchValue) {
          this.setState({ loader: true, pageChanged: true }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            await this.props.get_all_cameras_paginated(pageobj);
            this.setState({
              pageChanged: true,
              page: 1,
              newCameraList: this.props.camera_list,
            });
            this.setState({ loader: false });
          });
          setTimeout(() => {
            this.setState({ findMore: true });
          }, 1000);
        }
      } else {
        this.setState({ loader: true, pageChanged: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          await this.props.get_all_cameras_paginated(pageobj);
          this.setState({
            pageChanged: true,
            page: 1,
            newCameraList: this.props.camera_list,
          });
          this.setState({ loader: false });
        });
        setTimeout(() => {
          this.setState({ findMore: true });
        }, 1000);
      }
    });
  };

  render() {
    const { searchValue, newCameraList, pageChanged, allCameraList, loader, api_response, Norow, page } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Cameras</Card.Title>
                      <p className="card-category">List of camera</p>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide">
                  <DatatableHeader
                    Header={"Cameras"}
                    searchValue={this.state.searchValue}
                    HandleChange={this.handleSearchChange}
                    findMorePressed={this.findMorePressed}
                  />
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {!api_response && (
                        <CustomContentAlert delay={2000} message={"The server did not respond, Please try again after sometime"} className="toast-error" />
                      )}
                      <DataTable
                        data={searchValue.length || pageChanged ? newCameraList : allCameraList}
                        columns={this.columns}
                        customStyles={this.customStyles}
                        paginationServer={true}
                        responsive={true}
                        subHeader
                        defaultSortAsc={false}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="38vh"
                      />
                      <PaginationforData
                        count={this.state.count}
                        data={this.props?.camera_list}
                        handlepageinitial={this.handlepageinitial}
                        handlepageprevious={this.handlepageprevious}
                        handlepagenext={this.handlepagenext}
                        handlepagefinal={this.handlepagefinal}
                        handlepage={this.handlepage}
                        page={page}
                        Norow={Norow}
                      />
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStatetoProps = ({ SuperAdminDetails, APIResponseDetails, CameraDetails }) => {
  const { camera_list, cameraCount: count } = SuperAdminDetails;
  const { api_response } = APIResponseDetails;
  return { api_response, camera_list, count };
};
const mapStateToDispatch = {
  get_all_cameras_paginated: SuperAdminAction.get_all_cameras_paginated,
};

export default connect(mapStatetoProps, mapStateToDispatch)(AddCamera);

import React, { useCallback } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  // Spinner,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as SuperAdminAction from "../../Actions/SuperAdminAction/actions";
import Select from "react-select";
import CustomContentAlert from "../helper";
import Loader from "../Loader/Loader";

const CopySettings = () => {
  const [selectedPropertyObj, setSelectedPropertyObj] = React.useState({});
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const [childPropertymsg, setchildPropertymsg] = React.useState(false);

  const [selectedMultiPropertyObj, setSelectedMultiPropertyObj] = React.useState([]);
  const [state, setState] = React.useState({
    slot: false,
    support: false,
    loginCaptions: false,
    branding: false,
    processorID: false,
    lot: false,
    lane: false,
    camera: false,
    ticketConfig: false,
    selectAll: false,
    searchProperty: "",
    selectAllHandle: [],
    CopyselectAll: false,
  });
  const [propertyList, setPropertyList] = React.useState([]);
  const [delayMessage, setDelayMessage] = React.useState("");
  const [childPropertyList, setChildPropertyList] = React.useState([]);
  const [searchchildPropertyList, setsearchChildPropertyList] = React.useState([]);
  const [handlecheckboxState, sethandlecheckboxstate] = React.useState([]);
  const dispatch = useDispatch();
  const properties = useSelector((state_) => state_.SuperAdminDetails.getAllProperties);
  const api_success_message = useSelector((state_) => state_.APIResponseDetails.api_success_message);
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,

        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  const changeProperty = (event) => {
    setSelectedPropertyObj(event);
    const oldPropertyList = alteredProperties;
    const childProperty = properties;
    setchildPropertymsg(true);
    const alteredList = (oldPropertyList ?? []).map((item) => {
      if (item.value === event.value) {
        item.isDisabled = true;
        return item;
      }
      item.isDisabled = false;
      return item;
    });
    setPropertyList(alteredList);
    const childPropertyfilter = (childProperty ?? []).map((item) => {
      if (item.property_id === event.value) {
        return item;
      }
      return false;
    });
    const childPropertyfilter1 = childPropertyfilter.filter((el) => el !== false);
    const childPropertyfilter2 = (childPropertyfilter1 ?? []).map((item) => {
      return {
        label: item.site_name,
        value: item.id,
      };
    });
    setChildPropertyList(childPropertyfilter2);
    setsearchChildPropertyList(childPropertyfilter2);
    sethandlecheckboxstate([]);
    setState((prev) => ({
      ...prev,
      searchProperty: "",
    }));
  };

  const ChangeChildProperty = React.useCallback(
    (event, label, val) => {
      sethandlecheckboxstate((prev) => ({
        ...prev,
        [val]: event.target.checked,
      }));
      const isPropertyExist = selectedMultiPropertyObj.filter((item) => item.value === val);
      if (event.target.checked && !isPropertyExist.length) {
        const obj = {
          label: label,
          value: val,
          isDisabled: event.target.checked,
        };
        setSelectedMultiPropertyObj((prev) => [...prev, obj]);
      } else {
        const prevChildPropList = selectedMultiPropertyObj;
        prevChildPropList.map((item) => {
          if (item.value === val) {
            item.isDisabled = false;
          }
          return item;
        });
      }
    },
    [selectedMultiPropertyObj],
  );

  const selectAllChildProperty = (event, property) => {
    setState((prev) => ({
      ...prev,
      selectAll: event.target.checked,
    }));
    setSelectedMultiPropertyObj([]);
    for (const checked of property) {
      let obj = {};
      sethandlecheckboxstate((prev) => ({
        ...prev,
        [checked.value]: event.target.checked,
      }));
      if (event.target.checked) {
        obj = {
          label: checked.label,
          value: checked.value,
          isDisabled: event.target.checked,
        };
        setSelectedMultiPropertyObj((prev) => [...prev, obj]);
      } else {
        setSelectedMultiPropertyObj([]);
      }
    }
  };

  React.useEffect(() => {
    if (
      !Object.values(handlecheckboxState).filter((item) => item === false).length &&
      Object.keys(handlecheckboxState).length === searchchildPropertyList.length
    ) {
      setState((prev) => ({
        ...prev,
        selectAll: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        selectAll: false,
      }));
    }
  }, [searchchildPropertyList, handlecheckboxState]);

  React.useEffect(() => {
    dispatch(SuperAdminAction.get_all_properties());
  }, [dispatch]);

  const selectCopy = useCallback((e) => {
    setState((prevState) => ({
      ...prevState,
      CopyselectAll: e.target.checked,
    }));
    if (e.target.checked) {
      setState((prevState) => ({
        ...prevState,
        slot: true,
        support: true,
        loginCaptions: true,
        branding: true,
        processorID: true,
        lot: true,
        lane: true,
        camera: true,
        ticketConfig: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        slot: false,
        support: false,
        loginCaptions: false,
        branding: false,
        processorID: false,
        lot: false,
        lane: false,
        camera: false,
        ticketConfig: false,
      }));
    }
  }, []);

  React.useEffect(() => {
    const data = [state.slot, state.support, state.loginCaptions, state.branding, state.processorID, state.lot, state.lane, state.camera, state.ticketConfig];
    const falseValue = data.includes(false);
    if (falseValue) {
      setState((prevState) => ({
        ...prevState,
        CopyselectAll: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        CopyselectAll: true,
      }));
    }
  }, [state.slot, state.support, state.loginCaptions, state.branding, state.processorID, state.lot, state.lane, state.camera, state.ticketConfig]);

  const alteredProperties = React.useMemo(() => {
    return properties
      .map((a) => ({
        value: a.id,
        label: a.site_name + " (" + a.domain + ")",
        isDisabled: false,
      }))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }, [properties]);

  React.useMemo(() => {
    setPropertyList(alteredProperties);
  }, [alteredProperties]);

  const handleCheckBox = (e) => {
    if (e.target.name === "lane") {
      if (!state.lane) {
        setState((prevState) => ({
          ...prevState,
          lot: e.target.checked,
        }));
      }
      if (state.camera) {
        setState((prevState) => ({
          ...prevState,
          lot: e.target.checked,
          camera: e.target.checked,
        }));
      }
    } else if (e.target.name === "camera") {
      if (!state.camera) {
        setState((prevState) => ({
          ...prevState,
          lot: e.target.checked,
          lane: e.target.checked,
        }));
      }
    } else if (e.target.name === "lot" && state.lane) {
      setState((prevState) => ({
        ...prevState,
        lot: e.target.checked,
        lane: e.target.checked,
      }));
      if (state.camera) {
        setState((prevState) => ({
          ...prevState,
          camera: e.target.checked,
        }));
      }
    }
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
  };

  React.useEffect(() => {
    const settingsToBeCopied = JSON.parse(JSON.stringify(state));
    delete settingsToBeCopied.selectAll;
    delete settingsToBeCopied.searchProperty;
    delete settingsToBeCopied.selectAllHandle;
    const keys = Object.keys(settingsToBeCopied);
    const isKeySelected = keys.filter(function (key) {
      return settingsToBeCopied[key];
    });
    const isChildPropertySelected = Object.keys(handlecheckboxState).filter(function (key) {
      return handlecheckboxState[key];
    });
    if (selectedPropertyObj && isChildPropertySelected?.length && isKeySelected?.length) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [selectedPropertyObj, handlecheckboxState, state]);

  const handleButtonClick = React.useCallback(() => {
    setSpinner(true);
    setIsButtonDisabled(false);
    const data = {
      from_site: selectedPropertyObj.value,
      to_site: selectedMultiPropertyObj.filter((item) => item.isDisabled),
      settings_tobe_copied: {
        slots: state.slot,
        support: state.support,
        login_caption: state.loginCaptions,
        branding: state.branding,
        lots: state.lot,
        lanes: state.lane,
        cameras: state.camera,
        ticket_config: state.ticketConfig,
        processor_id: state.processorID,
      },
    };
    const res = dispatch(SuperAdminAction.copySettings({ data }));
    res && setShowAlert(true);
    setTimeout(() => {
      setDelayMessage("Please wait Copying is in progress.");
    }, 5000);
  }, [state, dispatch, selectedPropertyObj, selectedMultiPropertyObj]);

  React.useEffect(() => {
    if (api_success_message) {
      setDelayMessage("");
      setSpinner(false);
      setSelectedPropertyObj({});
      setSelectedMultiPropertyObj([]);
      setChildPropertyList([]);
      setsearchChildPropertyList([]);
      setIsButtonDisabled(false);
      sethandlecheckboxstate([]);

      setState({
        slot: false,
        support: false,
        loginCaptions: false,
        branding: false,
        lot: false,
        lane: false,
        camera: false,
        ticketConfig: false,
        searchProperty: "",
      });

      const oldPropertyList = alteredProperties;
      const alteredList = (oldPropertyList ?? []).map((item) => {
        item.isDisabled = false;
        return item;
      });
      setPropertyList(alteredList);
    }
    if (delayMessage) {
      setTimeout(() => {
        setDelayMessage("");
      }, 4000);
    }
  }, [api_success_message, alteredProperties, delayMessage]);

  const searchProperty = (e) => {
    const lowerCase = e.target.value.toLowerCase();
    setState({
      searchProperty: e.target.value,
    });
    if (e.target.value) {
      const filterData = childPropertyList.filter((el) => {
        if (lowerCase === "") {
          return el;
        } else {
          return el.label.toLowerCase().includes(lowerCase);
        }
      });
      setsearchChildPropertyList(filterData);
    } else {
      setsearchChildPropertyList(childPropertyList);
    }
  };

  const handleAllChildPropertiesTobeCopied = (childPropertymessage) => {
    let content = null;
    if (childPropertymessage) {
      content = searchchildPropertyList.length ? (
        searchchildPropertyList.map((property, index) => {
          return (
            <div key={index} className="custom-control custom-checkbox">
              {index === 0 && (
                <div>
                  <input
                    type="checkbox"
                    value={state.selectAll}
                    checked={state.selectAll}
                    onChange={(e) => selectAllChildProperty(e, searchchildPropertyList)}
                    className={"custom-control-input"}
                    style={{ zIndex: 1 }}
                  />
                  <label className="custom-control-label">Select All</label>
                </div>
              )}
              <input
                type="checkbox"
                value={property.value}
                checked={handlecheckboxState[property.value]}
                onChange={(e) => ChangeChildProperty(e, property.label, property.value)}
                className={"custom-control-input"}
                style={{ zIndex: 1 }}
              />
              <label className="custom-control-label">{property.label}</label>
            </div>
          );
        })
      ) : (
        <div>
          <b> No Child property found.</b>
        </div>
      );
    } else {
      content = (
        <div>
          <b> Please Select from site</b>
        </div>
      );
    }
    return content;
  };

  return (
    <Container fluid>
      <Row className="h-100">
        <Col md="12">
          <Card className="mb-0 h-100">
            <Card.Header>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <Card.Title as="h4">Copy Setting tool</Card.Title>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="sm-flex-column">
                <Col className="mb-3">
                  <div className="light-bg2 d-flex flex-column justify-content-center align-items-center h-100 p-2">
                    <div className="w-75">
                      <label>From Site</label>
                      <Form.Group>
                        <Select
                          value={selectedPropertyObj}
                          onChange={changeProperty}
                          options={propertyList}
                          placeholder="Select property"
                          styles={colourStyles}
                          className="w-100"
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Col>
                <Col className="mb-3">
                  <label>Copy</label>
                  <span className="custom-control custom-checkbox" style={{ zIndex: 0 }}>
                    <input type="checkbox" checked={state.CopyselectAll} onChange={selectCopy} className="custom-control-input" id="copySelect"></input>
                    <label className="custom-control-label" htmlFor="copySelect">
                      Select All
                    </label>
                  </span>

                  <div className="border copy-tool-section">
                    <Row>
                      <div className="col pr-0">
                        <div className="">
                          <div className="fw-600 border-bottom bg-light2 py-1 px-2 fs-14 fs-14px"> Settings:</div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name={"slot"}
                              checked={state.slot}
                              onChange={handleCheckBox}
                              className="custom-control-input"
                              id="slotscheck"
                            ></input>
                            <label className="custom-control-label" htmlFor="slotscheck">
                              Slots
                            </label>
                          </div>

                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name={"support"}
                              checked={state.support}
                              onChange={handleCheckBox}
                              className="custom-control-input"
                              id="supportcheck"
                            ></input>
                            <label className="custom-control-label" htmlFor="supportcheck">
                              Support
                            </label>
                          </div>

                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name={"loginCaptions"}
                              checked={state.loginCaptions}
                              onChange={handleCheckBox}
                              className="custom-control-input"
                              id="captioncheck"
                            ></input>
                            <label className="custom-control-label" htmlFor="captioncheck">
                              Login Captions
                            </label>
                          </div>

                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name={"branding"}
                              checked={state.branding}
                              onChange={handleCheckBox}
                              className="custom-control-input"
                              id="brandingcheck"
                            ></input>
                            <label className="custom-control-label" htmlFor="brandingcheck">
                              Branding
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name={"processorID"}
                              checked={state.processorID}
                              onChange={handleCheckBox}
                              className="custom-control-input"
                              id="processorIdcheck"
                            ></input>
                            <label className="custom-control-label" htmlFor="processorIdcheck">
                              Processor Id
                            </label>
                          </div>
                        </div>
                        <div className="">
                          <div className="fw-600 border-bottom bg-light2 py-1 px-2 fs-14 me-3">Add Lot:</div>

                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name={"lot"}
                              checked={state.lot}
                              onChange={handleCheckBox}
                              className="custom-control-input"
                              id="lotscheck"
                            ></input>
                            <label className="custom-control-label" htmlFor="lotscheck">
                              Lots
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col pl-0 border-left">
                        <div className="mb-3">
                          <div className="fw-600 border-bottom bg-light2 py-1 px-2 fs-14">Add Lane:</div>

                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name={"lane"}
                              checked={state.lane}
                              onChange={handleCheckBox}
                              className="custom-control-input"
                              id="lanescheck"
                            ></input>
                            <label className="custom-control-label" htmlFor="lanescheck">
                              Lanes
                            </label>
                          </div>
                        </div>

                        <div className="mb-3">
                          <div className="fw-600 border-bottom bg-light2 py-1 px-2 fs-14">Add Camera:</div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name={"camera"}
                              checked={state.camera}
                              onChange={handleCheckBox}
                              className="custom-control-input"
                              id="cameracheck"
                            ></input>
                            <label className="custom-control-label" htmlFor="cameracheck">
                              Cameras
                            </label>
                          </div>
                        </div>

                        <div className="">
                          <div className="fw-600 border-bottom bg-light2 py-1 px-2 fs-14">Citation Setup:</div>

                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name={"ticketConfig"}
                              checked={state.ticketConfig}
                              onChange={handleCheckBox}
                              className="custom-control-input"
                              id="tickitconfigcheck"
                            ></input>
                            <label className="custom-control-label" htmlFor="tickitconfigcheck">
                              Citation Config
                            </label>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
                <Col className="mb-3">
                  <div className="light-bg2 d-flex flex-column justify-content-center align-items-center h-100 p-2">
                    <div className="w-75">
                      <div>
                        <label>To Site</label>{" "}
                        {childPropertymsg && childPropertyList.length ? (
                          <input
                            type="search"
                            id="form1"
                            className="form-control"
                            placeholder="Search"
                            aria-label="Search"
                            value={state.searchProperty}
                            onChange={(e) => searchProperty(e)}
                            style={{ height: 38 }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      {handleAllChildPropertiesTobeCopied(childPropertymsg)}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="text-right">
                  <Button disabled={!isButtonDisabled} onClick={handleButtonClick}>
                    Copy Setting
                  </Button>
                  {spinner && <Loader />}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        id="CitationModal"
        size="sm"
        backdrop={"static"}
        show={delayMessage !== ""}
        onHide={delayMessage !== ""}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="pt-0">
          <div style={{ padding: "10px 0px 0px 0px", textAlign: "center" }}>Please wait copying is in progress.</div>
        </Modal.Body>
      </Modal>
      {showAlert && api_success_message && <CustomContentAlert delay={4000} message={api_success_message} className={"toast-success"} />}
    </Container>
  );
};

export default CopySettings;

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import AddPermitOptionsModal from "./AddPermitOptionsModal";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPermitOptions,
  deletePermitByLotAndPackage,
  unSetToasterMessage,
  updatePermitOptions,
  addPermitOptions,
  unsetPermitOptionsList,
} from "../../Actions/PermitOptionsActions/actions";
import CustomContentAlert, { tableHeightScrollBars } from "../helper";
import Edit from "@material-ui/icons/Edit";
import Loader from "../Loader/Loader";
import useGetAllProperty from "../../Hooks/Property/useGetAllProperty";
import Delete from "@material-ui/icons/Delete";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import ConfirmationModal from "../Common/ConfirmationModal";
import { customRow, linkRow, overlayTriggerRow } from "../Common/Rows";

const PaidPermit = () => {
  const dispatch = useDispatch();

  const property_id = useSelector((state) => state.UserProfileDetails?.property?.id);

  const { propertyId } = useGetAllProperty(property_id);

  const [formData, setFormData] = useState({
    showOptionModal: false,
    permitOptionsList: [],
    ifEdit: false,
    selectedRowData: "",
    selectedRowId: "",
    show_alert: false,
    show_error: false,
    page: 1,
    noRow: 100,
    count: 0,
    loader: true,
    showConfirmationModal: false,
  });

  const paidPermitOptionListData = useSelector((state) => state.PermitOptionsDetails.allPermitOptionsList);

  const { api_response, api_error_message, api_success_message } = useSelector((state) => state.APIResponseDetails);

  useEffect(() => {
    dispatch(getAllPermitOptions(propertyId, false));
  }, [propertyId]);

  useEffect(() => {
    if (paidPermitOptionListData) {
      setFormData((prevState) => ({
        ...prevState,
        permitOptionsList: paidPermitOptionListData,
        loader: false,
      }));
    }
  }, [paidPermitOptionListData]);

  useEffect(() => {
    if (api_response) {
      setFormData((prevState) => ({
        ...prevState,
        show_alert: true,
        loader: false,
      }));
      setTimeout(() => {
        setFormData((prevState) => ({
          ...prevState,
          show_alert: false,
          loader: false,
        }));
      }, 4000);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        show_error: true,
        loader: false,
      }));
      setTimeout(() => {
        setFormData((prevState) => ({
          ...prevState,
          show_error: false,
          loader: false,
        }));
      }, 4000);
    }
  }, [paidPermitOptionListData, api_response, api_error_message]);

  useEffect(() => {
    return () => {
      dispatch(unSetToasterMessage());
      dispatch(unsetPermitOptionsList());
    };
  }, []);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const ActionField = (row) => {
    return (
      <Row>
        <Button type="button" variant="primary" className="btn-blue-bg btn-xs" onClick={() => togglePlayPause(row, "edit")}>
          {row.sell_to_drivers ? <PauseIcon /> : <PlayArrowIcon />}
        </Button>
        <Button title="Edit" type="button" variant="primary" className="btn-blue-bg btn-xs" onClick={() => showPermitFormModal(row, "edit")}>
          <Edit />
        </Button>
        <Button type="button" title="Delete" variant="primary" className="btn-blue-bg btn-xs" onClick={() => openConfirmationModal(row.id)}>
          <Delete />
        </Button>
      </Row>
    );
  };

  const showErrorToaster = () => {
    setFormData((prevState) => ({
      ...prevState,
      show_error: true,
    }));
    setTimeout(() => {
      dispatch(getAllPermitOptions(propertyId, false));
      setFormData((prevState) => ({
        ...prevState,
        show_error: false,
      }));
    }, 4000);
  };
  const showSuccessToaster = () => {
    setFormData((prevState) => ({
      ...prevState,
      show_alert: true,
    }));
    const package_data = formData.permitOptionsList.filter((item) => item.id !== formData.selectedRowId);
    setFormData((prevState) => ({
      ...prevState,
      permitOptionsList: [...package_data],
    }));
    setTimeout(() => {
      setFormData((prevState) => ({
        ...prevState,
        show_alert: false,
      }));
    }, 4000);
  };
  const deleteRow = () => {
    const package_data = formData.permitOptionsList.filter((item) => item.id === formData.selectedRowId);
    if (package_data) {
      dispatch(deletePermitByLotAndPackage(propertyId, package_data[0].lot_id, package_data[0].package_name));
      openConfirmationModal();
      if (api_response) {
        showSuccessToaster();
      } else {
        showErrorToaster();
      }
    }
  };

  const openConfirmationModal = (id) => {
    let ID = "";
    if (id) {
      ID = id;
    }
    setFormData((prevState) => ({
      ...prevState,
      showConfirmationModal: !formData.showConfirmationModal,
      selectedRowId: ID,
    }));
  };

  const handleWaitlistPermitRedirect = (row) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/current-permits`,
      data: {
        lot_id: row?.lot_id,
        package_name: row?.package_name,
        suite_id: "ParkingPermit",
        activeTab: "waitlist",
        status: "current",
      },
    });
  };

  const handleDriverActivePermitRedirect = (row) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/current-permits`,
      data: {
        lot_id: row?.lot_id,
        package_name: row?.package_name,
        suite_id: "ParkingPermit",
        activeTab: "permitpackages",
        status: "current",
      },
    });
  };

  // const url = "https://aparna.worldstream.dev/";  This is for local
  const url = window.location.href;
  const subDomain = url.split(".")[0].split("//")[1];
  const href = `http://${subDomain}.${process.env.REACT_APP_PARKING_DOMAIN}/signup?`;

  // const url = window.location.href;
  // const subDomain = url.split(".")[0].split("//")[1];
  // let href = '';
  // const url_result = url.replace(/^https?:\/\//, '');
  // const splitURL = url_result.split(".");
  // const final_url = splitURL[1] + "." + splitURL[2];

  // if (final_url === process.env.REACT_APP_PARKING_DOMAIN) {
  //   href = `http://${subDomain}.${process.env.REACT_APP_PARKING_DOMAIN}/signup?`;
  // } else if (final_url === process.env.REACT_APP_CAR_WALLET_URL) {
  //   href = `http://${subDomain}.${process.env.REACT_APP_CAR_WALLET_URL}/signup?`;
  // }

  const columns = [
    {
      name: "Package Name",
      sortable: true,
      minWidth: "140px",
      maxWidth: "250px",
      selector: (row) => row.package_name,
      cell: (row) =>
        overlayTriggerRow({
          value: row?.package_name ? row?.package_name : "-",
        }),
    },
    {
      name: "Lots",
      sortable: true,
      minWidth: "100px",
      maxWidth: "250px",
      selector: (row) => row?.lots?.alias,
      cell: (row) =>
        overlayTriggerRow({
          value: row?.lots?.alias ? row?.lots?.alias : "-",
        }),
    },
    {
      name: "Month Max Rate ($)",
      sortable: true,
      minWidth: "120px",
      maxWidth: "250px",
      cell: (row) =>
        customRow({
          value: row.max_amount ? `$${row.max_amount}` : "-",
        }),
      selector: (row) => row.max_amount,
    },
    {
      name: "Daily Rate ($)",
      sortable: true,
      minWidth: "120px",
      maxWidth: "250px",
      cell: (row) =>
        customRow({
          value: row.daily_amount ? `$${row.daily_amount}` : "-",
        }),
      selector: (row) => row.daily_amount,
    },
    {
      name: "Cost ($)",
      sortable: true,
      minWidth: "120px",
      maxWidth: "250px",
      cell: (row) =>
        customRow({
          value: row.cost ? `$${row.cost}` : "-",
        }),
      selector: (row) => row.cost,
    },
    {
      name: "Sold to Driver",
      sortable: true,
      minWidth: "150px",
      maxWidth: "250px",
      cell: (row) => handleSoldToDriverLink(row),
      selector: (row) => row.driver_active_permit_count,
    },
    {
      name: "Max Permit Count",
      sortable: true,
      minWidth: "150px",
      maxWidth: "250px",
      cell: (row) =>
        customRow({
          value: row?.max_permit_count ? row?.max_permit_count : "-",
        }),
      selector: (row) => row.max_permit_count,
    },

    {
      name: "Wait Permit Count",
      sortable: true,
      minWidth: "150px",
      maxWidth: "250px",
      cell: (row) => handleWaitPermitCountLink(row),
      selector: (row) => row.driver_waitlist_active_permit_count,
    },
    {
      name: "Allow Waitlist",
      sortable: true,
      minWidth: "120px",
      maxWidth: "250px",
      cell: (row) => customRow({ value: row.allow_waitlist === 1 ? "Yes" : "No" }),
      selector: (row) => row.allow_waitlist,
    },
    {
      name: "Stall Type",
      sortable: true,
      minWidth: "120px",
      maxWidth: "250px",
      cell: (row) =>
        overlayTriggerRow({
          value: row?.stall_type ? _.startCase(row.stall_type).replace(/_/g, " ") : "-",
        }),
      selector: (row) => row.stall_type,
    },
    {
      name: "Sell To Units",
      sortable: true,
      minWidth: "120px",
      maxWidth: "250px",
      cell: (row) => customRow({ value: row.sell_to_units === 1 ? "Yes" : "No" }),
      selector: (row) => row.sell_to_units,
    },
    {
      name: "Sell To Drivers",
      sortable: true,
      minWidth: "120px",
      maxWidth: "250px",
      cell: (row) =>
        linkRow({
          active: row.sell_to_drivers === 1,
          link: href + row?.lots?.id,
          className: "top-header--title text-truncate text-nowrap pr-2",
          value: row.sell_to_drivers === 1 ? "Yes" : "No",
        }),
      selector: (row) => row.sell_to_drivers,
    },
    {
      name: "Description",
      sortable: true,
      minWidth: "140px",
      maxWidth: "250px",
      selector: (row) => row.description,
      cell: (row) =>
        overlayTriggerRow({
          value: row?.description ? row?.description : "-",
        }),
    },
    {
      name: "Actions",
      width: "140px",
      align: "center",
      cell: ActionField,
    },
  ];

  const handleSoldToDriverLink = (row) => {
    if (row.driver_active_permit_count > 0) {
      return (
        <div className="cursorPointer">
          <button
            onClick={() => {
              handleDriverActivePermitRedirect(row);
            }}
            style={{ background: "none", border: "none", padding: 0, cursor: "pointer", color: "#24a5ca" }}
          >
            {row.driver_active_permit_count}
          </button>
        </div>
      );
    } else {
      return <>{"-"}</>;
    }
  };

  const handleWaitPermitCountLink = (row) => {
    if (row.driver_waitlist_active_permit_count > 0) {
      return (
        <div className="cursorPointer">
          <button
            onClick={() => {
              handleWaitlistPermitRedirect(row);
            }}
            style={{ background: "none", border: "none", padding: 0, cursor: "pointer", color: "#24a5ca" }}
          >
            {row.driver_waitlist_active_permit_count}
          </button>
        </div>
      );
    } else {
      return <>{"-"}</>;
    }
  };

  const showPermitFormModal = (row, type) => {
    if (type === "edit") {
      setFormData((prevState) => ({
        ...prevState,
        showOptionModal: !formData.showOptionModal,
        ifEdit: true,
        selectedRowId: row.id,
        selectedRowData: row,
        old_data: row,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        showOptionModal: !formData.showOptionModal,
        ifEdit: false,
        selectedRowId: "",
        selectedRowData: "",
      }));
    }
  };

  const togglePlayPause = (row, type) => {
    if (type === "edit") {
      row.sell_to_drivers = !row.sell_to_drivers;
      dispatch(updatePermitOptions(row, row.id));
    }
  };

  const dataTableHandle = (list) => {
    const height = tableHeightScrollBars();
    return (
      <div className="citation-list-table manage-permit-packages">
        <DataTable
          data={list}
          columns={columns}
          customStyles={customStyles}
          responsive={true}
          defaultSortAsc={true}
          fixedHeader={true}
          fixedHeaderScrollHeight={height}
          pagination={true}
          paginationPerPage={formData.noRow}
          paginationTotalRows={formData.count}
          paginationRowsPerPageOptions={[100, 200, 300, 400]}
        />
      </div>
    );
  };

  const addUpdatePermitOptions = (data, editId) => {
    setFormData((prevState) => ({
      ...prevState,
      loader: true,
    }));
    if (editId) {
      dispatch(updatePermitOptions(data, editId));
    } else {
      dispatch(addPermitOptions(data));
    }
    showPermitFormModal();
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="mb-0">
              <Card.Header>
                <div className="d-flex align-items-sm-center align-items-start justify-content-between">
                  <div>
                    <Card.Title as="h4">Manage Permit Packages</Card.Title>
                  </div>
                  <div>
                    <Button onClick={showPermitFormModal} type="button" variant="primary" className="btn-blue-bg btn-sm">
                      Add Permit Packages
                    </Button>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                {!formData.permitOptionsList?.length && !formData.loader && (
                  <div className="text-center d-block">
                    <div>{`There are no records to display`}</div>
                  </div>
                )}
                {formData.loader ? (
                  <Loader />
                ) : (
                  <>
                    {formData?.permitOptionsList?.length > 0 && (
                      <div>
                        <div className="">{dataTableHandle(formData.permitOptionsList)}</div>
                      </div>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {formData.showOptionModal ? (
        <AddPermitOptionsModal
          show={formData.showOptionModal}
          closeModal={() => showPermitFormModal()}
          property_id={propertyId}
          ifEdit={formData.ifEdit}
          selectedRowId={formData.selectedRowId}
          selectedRowData={formData.selectedRowData}
          addUpdatePermitOptions={addUpdatePermitOptions}
        />
      ) : null}
      {formData.showConfirmationModal ? (
        <ConfirmationModal
          onOpen={formData.showConfirmationModal}
          onConfirm={deleteRow}
          message="All Permits for this package/account (Active, Auto-Renewal) will be cancelled and Refund would be issued as applicable. Do you want to DELETE this permit package ?"
          onClose={openConfirmationModal}
          loading={false}
          title={"Delete Permit Option for Package"}
          showSuccessToaster={showSuccessToaster}
          showErrorToaster={showErrorToaster}
        />
      ) : null}
      {formData.show_alert && api_success_message && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
      {formData.show_error && api_error_message && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}
    </div>
  );
};
export default PaidPermit;

import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap"; // Form
import { Rejected } from "../Components/AuditReport/AuditReport";
import Approved from "../../assets/img/svg/approved.svg";

export default function OfflinePlateCheck(data) {
  const { offlineData, plateData, platePageHideShow } = data;
  const { lpn, main_body: mainBody, selectedLotName, isVisitorLot } = offlineData;
  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header style={{ backgroundColor: mainBody }}>
              <div className="d-flex align-items-center">
                <i className="fa fa-times mr-2 cursor-pointer" aria-hidden="true" onClick={platePageHideShow} />
                <h4 className="card-title">
                  <b>Plate:</b> {lpn}
                </h4>
              </div>
              {plateData?.isPlateApproved ? (
                <Row>
                  <Col lg={4}>
                    <div className="text-success sucess-content-section">
                      <img src={Approved} alt="" width="22" />
                      <span className="ml-2">Approved</span>
                    </div>
                    <span>Lot: {selectedLotName}</span>
                    {/* <div>Lot Capacity: {selectedLotCapacity}</div> */}
                    <div>Visitor Lot: {isVisitorLot ? "Yes" : "No"}</div>
                  </Col>
                  <Col lg={6}>
                    <span>Permit Class: {plateData?.permit_class}</span>
                    <div>Source: {plateData?.source}</div>
                    <div>End date: {plateData?.end}</div>
                    <div>Time in lot: {plateData?.time_elapsed}</div>
                  </Col>
                </Row>
              ) : (
                <>
                  <div className="text-danger error-content-section">
                    <Rejected />
                    <span className="ml-2">{plateData.result || "Not Approved"}</span>
                  </div>
                  <Row>
                    <Col lg={3} md={3} sm={6} xs={12}>
                      <span>Lot: {selectedLotName}</span>
                      {/* <div>Lot Capacity: {selectedLotCapacity}</div> */}
                      <div>Visitor Lot : {isVisitorLot ? "Yes" : "No"}</div>
                    </Col>
                    <Col lg={6}>
                      <span>Permit Class: {plateData?.permit_class}</span>
                      <div>Source: {plateData?.source}</div>
                      <div>End date: {plateData?.end}</div>
                      <div>Time in lot: {plateData?.time_elapsed}</div>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Header>
            {/* <Card.Body></Card.Body> */}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export const validateEmpty = (data, errorStr) =>
  data?.trim()?.length === 0 ? errorStr : "";

export const validateIdFields = (obj, err) => {
  let errObj = { ...err };
  if (obj?.show_id) {
    errObj.name_of_id = validateEmpty(
      obj?.name_of_id,
      "Name of ID field is required"
    );
    errObj.parking_message = validateEmpty(
      obj?.parking_message,
      "Success message is required"
    );
    errObj.mincharacter = validateEmpty(
      obj?.mincharacter?.toString(),
      "Min field is required"
    );
    errObj.maxcharacter = validateEmpty(
      obj?.maxcharacter?.toString(),
      "Max field is required"
    );
    const min = Number(obj?.mincharacter);
    const max = Number(obj?.maxcharacter);
    if (errObj.maxcharacter === "" && errObj.mincharacter === "") {
      if (min > max) {
        errObj.mincharacter =
          "Min characters should be less than Max characters.";
      } else {
        errObj.mincharacter = "";
      }
    }
  } else {
    errObj = {
      ...errObj,
      name_of_id: "",
      maxcharacter: "",
      mincharacter: "",
    };
  }
  return errObj;
};

export const validateTitleField = (obj, err) => {
  const errObj = { ...err };
  errObj.quick_permit_form_title = validateEmpty(
    obj?.quick_permit_form_title,
    "Title is required"
  );
  return errObj;
};

export const validateNoteField = (obj, err) => {
  const errObj = { ...err };
  if (obj?.self_server || obj?.regular) {
    errObj.name_of_note = obj?.note_options
      ? validateEmpty(obj?.name_of_note, "Name of Note")
      : "";
  } else {
    errObj.name_of_note = "";
  }
  return errObj;
};

export const validateNoteOptions = (obj, err) => {
  const errObj = { ...err };
  const isOptionsValid =
    obj.note_options?.filter((x) => x?.trim()?.length === 0).length === 0;
  errObj.note_options = isOptionsValid ? "" : "Note option can't be empty";
  return errObj;
};

export const getOptions = (str) => {
  if (str) {
    if (typeof JSON.parse(str) === "string") {
      return JSON.parse(JSON.parse(str));
    } else {
      return JSON.parse(str);
    }
  } else {
    return [];
  }
};

import t from "../../Actions/ChangePasswordAction/type";

const initialState = {
  newpassword: "",
};

const ChangePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.NEW_PASSWORD: {
      const newPass = {
        ...state,
        newpassword: action.payload,
      };
      return newPass;
    }
    default:
      return state;
  }
};
export default ChangePasswordReducer;

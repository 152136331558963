import t from "../../Actions/SuperAdminAction/types";

const initialState = {
  create_property: {},
  getAllProperties: [],
  getAllTimezones: [],
  camera_list: [],
  cameras: [],
  lanes: [],
  lots: [],
  getallpropertiespaginated: [],
  getAllLanesPaginated: [],
  selected_Property: [],
  userList: [],
  settings: {},
  lotCount: 0,
  propertyCount: 0,
  dateSlots: {},
  siteSetting: {},
  domainHeader: {},
  property_id: "",
  backgroundColor: {},
  archivedPropertyList: [],
  ticketTemplates: [],
  archivedPropertyCount: 0,
  getAllUserlist: [],
  userCount: 0,
  modulePermissions: {},
  userModulePermissions: [],
  modelOpenClose: false,
  dropDownState: false,
  processorId: "",
  templateCreditGroup: [],
  getAllLotsPaginated: [],
  permissions: [],
  allUserTypes: [],
  semiAuthorizedUserProfile: {},
  getRtspData: {},
  highSecurity: 0,
  user_activity_logs: [],
  receiptPDFData: {},
};

const SuperAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.SELECTEDPROPERTY: {
      return { ...state, selected_Property: action.payload };
    }
    case t.GET_RECEIPT_PDF_DATA: {
      return { ...state, receiptPDFData: action.payload };
    }
    case t.CREATEPROPERTY: {
      return { ...state, create_property: action.payload };
    }
    case t.SET_DROPDOWN_STATE: {
      return { ...state, dropDownState: action.payload };
    }
    case t.GETALLPROPERTYPAGINATED: {
      return Object.assign({}, state, {
        getallpropertiespaginated: JSON.parse(JSON.stringify(action.payload.rows)),
        propertyCount: action.payload.count,
      });
    }

    case t.GET_ALL_ARCHIVED_PROPERTY: {
      return Object.assign({}, state, {
        archivedPropertyList: JSON.parse(JSON.stringify(action.payload.rows)),
        archivedPropertyCount: action.payload.count,
      });
    }

    case t.GETALLPROPERTY: {
      return Object.assign({}, state, {
        getAllProperties: action.payload,
      });
    }
    case t.FETCHTIMEZONE: {
      return Object.assign({}, state, {
        getAllTimezones: action.payload,
      });
    }
    case t.UPDATE_PROPERTY_LIST: {
      const properties = JSON.parse(JSON.stringify(sortData(state.getAllProperties)));
      properties.push(action.payload);
      const sortedPropertyList = sortData(properties);
      return Object.assign({}, state, {
        getAllProperties: properties,
        getallpropertiespaginated: sortedPropertyList,
        propertyCount: properties.length,
      });
    }
    case t.GET_PROPERTY_ID: {
      return {
        ...state,
        property_id: action.payload,
      };
    }
    case t.GETALLLOTSPAGINATE: {
      return {
        ...state,
        getAllLotsPaginated: action.payload.rows,
        lotCount: action.payload.count,
      };
    }
    case t.GETALLLANESPAGINATED: {
      return {
        ...state,
        getAllLanesPaginated: action.payload.rows,
        laneCount: action.payload.count,
      };
    }
    case t.FETCHCAMERAS: {
      return {
        ...state,
        camera_list: action.payload.rows,
        cameraCount: action.payload.count,
      };
    }
    case t.GETALLLOTS: {
      const allLots = { ...state, lots: action.payload };
      return allLots;
    }
    case t.ADDLOT: {
      const oldLots = state.lots;
      oldLots.push(action.payload);
      const allLots = {
        ...state,
        lots: JSON.parse(JSON.stringify(sortData(oldLots))),
      };
      return allLots;
    }
    case t.EDIT_LOTS: {
      let oldLots = state.lots;
      oldLots = oldLots.filter((lot) => lot.id !== action.payload.id);
      oldLots.push(action.payload);
      const allLots = {
        ...state,
        lots: JSON.parse(JSON.stringify(sortData(oldLots))),
      };
      return allLots;
    }
    case t.DELETE_LOT: {
      let oldLots = state.lots;
      oldLots = oldLots.filter((lot) => lot.id !== action.payload);
      const allLots = {
        ...state,
        lots: JSON.parse(JSON.stringify(sortData(oldLots))),
      };
      return allLots;
    }
    case t.DELETE_TICKET_TEMPLATE: {
      let oldTemplates = state.ticketTemplates;
      oldTemplates = oldTemplates.filter((template) => template.id !== action.payload);
      return {
        ...state,
        ticketTemplates: JSON.parse(JSON.stringify(sortData(oldTemplates))),
      };
    }
    case t.FETCHCAMERALIST: {
      return {
        ...state,
        cameras: action.payload,
      };
    }
    case t.FETCHLANE: {
      return {
        ...state,
        lanes: action.payload,
      };
    }
    case t.FETCHUSERLIST: {
      return {
        ...state,
        userList: action.payload.data.rows,
        highSecurity: action.payload.highSecurity,
      };
    }
    case t.FETCH_USER_LIST_PAGINATE: {
      return Object.assign({}, state, {
        userList: action.payload.rows,
        userCount: action.payload.count,
      });
    }
    case t.ADDLANE: {
      const lanes = JSON.parse(JSON.stringify(state.lanes));
      lanes.push(action.payload);
      return Object.assign({}, state, {
        lanes: sortData(lanes),
      });
    }
    case t.EDIT_LANE: {
      const lanes = JSON.parse(JSON.stringify(state.lanes));
      const index = lanes.findIndex((lot) => lot.id === action.payload.id);
      lanes[index] = action.payload;
      return {
        ...state,
        lanes: JSON.parse(JSON.stringify(sortData(lanes))),
      };
    }
    case t.DELETE_LANE: {
      let lanes = JSON.parse(JSON.stringify(state.lanes));
      lanes = lanes.filter((lot) => lot.id !== action.payload);
      return {
        ...state,
        lanes: JSON.parse(JSON.stringify(sortData(lanes))),
      };
    }

    case t.GET_SETTINGS: {
      return {
        ...state,
        settings: action.payload,
      };
    }
    case t.ADD_NEW_USER: {
      const userList = JSON.parse(JSON.stringify(state.userList));
      userList.push(action.payload);
      return {
        ...state,
        userList: JSON.parse(JSON.stringify(sortData(userList))),
      };
    }
    case t.EDIT_USER: {
      const userList = JSON.parse(JSON.stringify(state.userList));
      const index = userList.findIndex((user) => user.id === action.payload.id);
      userList[index] = action.payload;
      return {
        ...state,
        userList: JSON.parse(JSON.stringify(sortData(userList))),
      };
    }
    case t.DELETE_USER: {
      let userList = JSON.parse(JSON.stringify(state.userList));
      userList = userList.filter((user) => user.id !== action.payload);
      return {
        ...state,
        userList: JSON.parse(JSON.stringify(sortData(userList))),
      };
    }
    case t.UPDATE_SITE_SETTING: {
      return {
        ...state,
        siteSetting: action.payload,
        Day: action.payload.day,
        Evening: action.payload.evening,
        Night: action.payload.night,
        available_spot: action.payload.available_spot,
        support_visitor: action.payload.support_visitor,
      };
    }
    case t.UPDATE_LOGIN_CAPTION: {
      return {
        ...state,
        login_header: action.payload.login_header,
        login_body: action.payload.login_body,
        backgroundColor: {
          menuHeader: "",
          menuBackground: "",
          mainBodyBackground: "",
          MainBackground: "",
          headerLogo: "",
          footerLogo: "",
          footerText: "",
        },
      };
    }
    case t.UPDATE_SUPPORT: {
      return {
        ...state,
        support_contact: action.payload.support_contact,
        support_email: action.payload.support_email,
      };
    }
    case t.CREATE_DATE_SLOT: {
      return {
        ...state,
        dateSlots: JSON.parse(JSON.stringify(action.payload)),
      };
    }
    case t.SETDOMAINHEADER: {
      return {
        ...state,
        domainHeader: action.payload,
      };
    }
    case t.ARCHIVE_PROPERTY_LIST: {
      const oldPropertyList = JSON.parse(JSON.stringify(state.getallpropertiespaginated));
      const archievelist = oldPropertyList.filter((property) => property.id !== action.payload);
      return {
        ...state,
        getallpropertiespaginated: JSON.parse(JSON.stringify(sortData(archievelist))),
      };
    }
    case t.RESTORE_PROPERTY_LIST: {
      const oldPropertyList = JSON.parse(JSON.stringify(state.archivedPropertyList));
      const filteredList = oldPropertyList.filter((property) => property.id !== action.payload);
      return {
        ...state,
        archivedPropertyList: JSON.parse(JSON.stringify(sortData(filteredList))),
      };
    }

    case t.DELETE_PROPERTY: {
      const oldPropertyList = state.archivedPropertyList;
      const deletedlist = oldPropertyList.filter((property) => property.id !== action.payload);

      return {
        ...state,
        archivedPropertyList: JSON.parse(JSON.stringify(sortData(deletedlist))),
      };
    }
    case t.SETBACKGROUNDCOLOR: {
      return {
        ...state,
        backgroundColor: action.payload,
      };
    }
    case t.GET_ALL_TICKET_TEMPLATE: {
      return {
        ...state,
        ticketTemplates: action.payload,
      };
    }
    case t.GET_CREDIT: {
      return {
        ...state,
        templateCreditGroup: action.payload,
      };
    }
    case t.UPDATE_TICKET_TEMPLATE_LIST: {
      let templates = JSON.parse(JSON.stringify(sortData(state.ticketTemplates)));
      if (action.payload.is_selected) {
        const alteredList = alterTemplateList(templates);
        templates = alteredList;
      }
      templates.push(action.payload);

      const sortedList = sortData(templates);

      return Object.assign({}, state, {
        ticketTemplates: sortedList,
      });
    }
    case t.EDIT_TICKET_TEMPLATES: {
      let templates = JSON.parse(JSON.stringify(state.ticketTemplates));
      if (action.payload.is_selected) {
        const alterList = alterTemplateList(templates);
        templates = alterList;
      }
      const index = templates.findIndex((template) => template.id === action.payload.id);
      templates[index] = action.payload;
      return {
        ...state,
        ticketTemplates: JSON.parse(JSON.stringify(sortData(templates))),
      };
    }

    case t.ALLUSERLIST: {
      return {
        ...state,
        getAllUserlist: action.payload,
        userCount: action.payload.count,
      };
    }

    case t.FETCH_PERMISSIONS: {
      return {
        ...state,
        modulePermissions: action.payload,
      };
    }
    case t.FETCH_USER_PERMISSIONS: {
      return {
        ...state,
        userModulePermissions: action.payload,
      };
    }
    case t.FETCH_ALL_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload,
      };
    }
    case t.FETCH_PROCESSORID: {
      return {
        ...state,
        processorId: action.payload,
      };
    }
    case t.GET_ALL_USER_TYPES: {
      return {
        ...state,
        allUserTypes: action.payload,
      };
    }
    case t.GET_RTSP_DATA: {
      return {
        ...state,
        getRtspData: action.payload,
      };
    }
    case t.ACTIVITY_LOGS: {
      return { ...state, user_activity_logs: action.payload };
    }
    default:
      return state;
  }
};

const alterTemplateList = (templates) => {
  templates.map((template) => {
    if (template.is_selected) {
      template.is_selected = !template.is_selected;
    }
    return template;
  });
  return templates;
};

const sortData = (data) => {
  data.sort((a, b) => {
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  });
  return data;
};

export default SuperAdminReducer;

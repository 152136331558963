import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import CustomContentAlert, { navigationButtons, removeSpecialChar, validation_message } from "../helper";

import { getQuickPermitSetupDetails, createQuickPermitSetup } from "../../Actions/CurrentVisitorPermitActions/actions";
import NoteSectionInSetting from "./SettingComponents/NoteSectionInSetting";
import IdFieldSectionSetting from "./SettingComponents/IdFieldSectionSetting";
import RegisterOnTerminalSectionInSetting from "./SettingComponents/RegisterOnTerminalSectionInSetting";
import TitleSectionInSetting from "./SettingComponents/TitleSectionInSetting";
import { getQuickPermitSetupDetailForProperty } from "../../Selectors/CurrentVisitorPermitSelector";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import { useLocation } from "react-router";
import useGetAllProperty from "../../Hooks/Property/useGetAllProperty";
import { validateIdFields, validateTitleField, validateNoteField, validateNoteOptions, getOptions } from "./QuickPermitComman";

const initialFormData = {
  quick_permit_form_title: "",
  instruction: "",
  name_of_note: "",
  name_of_id: "",
  mincharacter: 2,
  maxcharacter: 10,
  parking_message: "",
  id_validation: "",
  register_on_terminal: "",
  self_server: false,
  regular: false,
  show_id: false,
  note_options: [],
};

const intialError = {
  quick_permit_form_title: "",
  mincharacter: "",
  maxcharacter: "",
  name_of_id: "",
  name_of_note: "",
  note_options: "",
  parking_message: "",
};

const QuickPermitSetup = (_props) => {
  const [formData, setFormData] = useState({ ...initialFormData });
  const [errorState, setErrorState] = useState({ ...intialError });
  const [isFormValid, setIsFormValid] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  const QuickPermitSetupDetail = useSelector(getQuickPermitSetupDetailForProperty);
  const { api_response, api_error_message, api_success_message } = useSelector(getAPIResponse);

  const dispatch = useDispatch();
  const location = useLocation();
  const { propertyId } = useGetAllProperty(location.search?.slice(1));

  useEffect(() => {
    dispatch(getQuickPermitSetupDetails(propertyId));
    return () => {
      dispatch({ type: "FETCH_QUICK_PERMIT_SETTING_DETAILS_BLANK" });
    };
  }, []);

  useEffect(() => {
    if (api_response) {
      if (QuickPermitSetupDetail && Object.keys(QuickPermitSetupDetail).length) {
        setIsUpdate(true);
        setFormData({
          ...formData,
          quick_permit_form_title: QuickPermitSetupDetail?.quick_permit_form_title,
          instruction: QuickPermitSetupDetail?.instruction,
          name_of_note: QuickPermitSetupDetail?.name_of_note,
          name_of_id: QuickPermitSetupDetail?.name_of_id,
          self_server: QuickPermitSetupDetail?.self_server,
          regular: QuickPermitSetupDetail?.regular,
          show_id: QuickPermitSetupDetail?.show_id,
          parking_message: QuickPermitSetupDetail?.parking_message,
          register_on_terminal: QuickPermitSetupDetail?.register_on_terminal,
          id_validation: QuickPermitSetupDetail?.id_validation,
          note_options: getOptions(QuickPermitSetupDetail?.note_options),
          mincharacter: QuickPermitSetupDetail?.id_min_char,
          maxcharacter: QuickPermitSetupDetail?.id_max_char,
        });
      } else {
        setIsUpdate(false);
      }
    }
  }, [api_response, QuickPermitSetupDetail]);

  const handleValidation = (obj) => {
    let errObj = { ...errorState };
    errObj = validateTitleField(obj, errObj);
    errObj = validateNoteField(obj, errObj);
    errObj = validateIdFields(obj, errObj);
    errObj = validateNoteOptions(obj, errObj);

    setErrorState({ ...errObj });
    setIsFormValid(!Object.keys(errObj).find((x) => errObj[x].length !== 0));
    return !Object.keys(errObj).find((x) => errObj[x].length === 0);
  };

  const handleChange = async ({ target }) => {
    const { name, value } = target;
    let newValue = !["instruction", "parking_message"].includes(name) ? removeSpecialChar(value) : value;
    let obj = { ...formData };
    if (["mincharacter", "maxcharacter"].includes(name)) {
      newValue = newValue.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    }
    obj = { ...obj, [name]: newValue };
    handleValidation(obj);
    setFormData({
      ...formData,
      ...obj,
    });
  };

  const handleCheckBox = async (e) => {
    const { name, checked } = e.target;
    let obj = { ...formData, [name]: checked };
    if (name === "show_id" && checked === false) {
      obj = {
        ...obj,
        name_of_id: "ID",
        maxcharacter: 8,
        mincharacter: 2,
      };
    }
    setFormData({ ...obj });
    handleValidation(obj);
  };

  const handleRadioChange = (name, e) => {
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };

  const isNotallow = useMemo(
    () => formData.self_server === true || formData.regular === true || formData.self_server === 1 || formData.regular === 1,
    [formData.self_server, formData.regular],
  );

  const handleCreateQuickPermitSetup = async () => {
    const data = {
      title: formData.quick_permit_form_title,
      min_character: formData.mincharacter,
      max_character: formData.maxcharacter,
      allow_notes: isNotallow,
      property_id: propertyId,
      note_options: JSON.stringify(formData.note_options?.filter((x) => x.trim().length !== 0)),
      instruction: formData.instruction,
      name_of_note: formData.name_of_note,
      name_of_id: formData.name_of_id,
      self_server: formData.self_server,
      regular: formData.regular,
      show_id: formData.show_id,
      id_validation: formData.id_validation,
      register_on_terminal: formData.register_on_terminal,
      parking_message: formData.parking_message,
    };
    dispatch(createQuickPermitSetup(data, isUpdate));
    if (api_response) {
      setShowAlert(true);
      setIsUpdate(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(true);
      }, 4000);
    }
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <Card.Title as="h4">Quick Permit Setup</Card.Title>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="pos-rel-overflow-hide">
                <Row>
                  <Col lg="12">
                    <Form.Group>
                      <TitleSectionInSetting formData={formData} handleChange={handleChange} errorState={errorState} validation_message={validation_message} />
                      <hr />
                      <NoteSectionInSetting
                        handleCheckBox={handleCheckBox}
                        formData={formData}
                        isNotallow={isNotallow}
                        errorState={errorState}
                        handleChange={handleChange}
                        setFormData={setFormData}
                        handleValidation={handleValidation}
                      />
                      <hr />
                      <IdFieldSectionSetting
                        formData={formData}
                        errorState={errorState}
                        handleCheckBox={handleCheckBox}
                        handleChange={handleChange}
                        handleUpdateChange={(e) => handleRadioChange("id_validation", e)}
                      />
                      <hr />
                      <RegisterOnTerminalSectionInSetting
                        handleTerminalUpdateChange={(e) => handleRadioChange("register_on_terminal", e)}
                        formData={formData}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button className="" onClick={handleCreateQuickPermitSetup} disabled={!isFormValid}>
                  {isUpdate ? "Update" : "Save"}
                </Button>

                <Col lg="12" className="p-0">
                  {navigationButtons("/manage-meter-config", "/parking-permit-setup", propertyId, "", "QuickPermit Setup", true)}
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {showAlert && api_success_message && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
      {showError && api_error_message && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}
    </div>
  );
};

export default QuickPermitSetup;

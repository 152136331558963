const type = {
  FETCH_MERCHANT_CODE: "FETCH_MERCHANT_CODE",
  SET_COUPON_PDF: "SET_COUPON_PDF",
  SET_COUPON_VALIDATION_RES: "SET_COUPON_VALIDATION_RES",
  RESET_COUPON_VALIDATION_RES: "RESET_COUPON_VALIDATION_RES",
  GET_DISCOUNT_RATE: "GET_DISCOUNT_RATE",
  SHOW_HIDE_LOADER: "SHOW_HIDE_LOADER",
};

export default type;

import t from "../../Actions/DashBoardActions/types";

const intitialState = {
  dashboardData: [],
  days: [],
};

const DashBoardReducer = (state = intitialState, action) => {
  switch (action.type) {
    case t.FETCH_DASHBOARD: {
      return {
        ...state,
        dashboardData: action.payload.response,
        days: action.payload.days,
        currentPermits: action.payload.current_permit,
        visitorPermits: action.payload.visitor_permit,
      };
    }
    default:
      return state;
  }
};

export default DashBoardReducer;

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Select from "react-select";
import SearchAutoComplete from "../Ticket/SearchAutocomplete";
import CustomContentAlert, { validation_message, validations } from "../helper";
import db from "./dixie";
import defaultImage from "../../../assets/img/defaultImage.png";

export default function OfflineCitation(props) {
  const { redirectOfflineCitation, GetcitationData } = props;
  const ticketCountry = [
    {
      value: "USA",
      label: "USA",
      states: [
        { label: "Alabama", value: "Alabama" },
        { label: "Alaska", value: "Alaska" },
        { label: "Arizona", value: "Arizona" },
        { label: "Arkansas", value: "Arkansas" },
        { label: "California", value: "California" },
        { label: "Colorado", value: "Colorado" },
        { label: "Connecticut", value: "Connecticut" },
        { label: "Delaware", value: "Delaware" },
        { label: "Florida", value: "Florida" },
        { label: "Georgia", value: "Georgia" },
        { label: "Hawaii", value: "Hawaii" },
        { label: "Idaho", value: "Idaho" },
        { label: "Illinois", value: "Illinois" },
        { label: "Indiana", value: "Indiana" },
        { label: "Iowa", value: "Iowa" },
        { label: "Kansas", value: "Kansas" },
        { label: "Kentucky[D]", value: "Kentucky[D]" },
        { label: "Louisiana", value: "Louisiana" },
        { label: "Maine", value: "Maine" },
        { label: "Maryland", value: "Maryland" },
        { label: "Massachusetts[D]", value: "Massachusetts[D]" },
        { label: "Michigan", value: "Michigan" },
        { label: "Minnesota", value: "Minnesota" },
        { label: "Mississippi", value: "Mississippi" },
        { label: "Missouri", value: "Missouri" },
        { label: "Montana", value: "Montana" },
        { label: "Nebraska", value: "Nebraska" },
        { label: "Nevada", value: "Nevada" },
        { label: "New Hampshire", value: "New Hampshire" },
        { label: "New Jersey", value: "New Jersey" },
        { label: "New Mexico", value: "New Mexico" },
        { label: "New York", value: "New York" },
        { label: "North Carolina", value: "North Carolina" },
        { label: "North Dakota", value: "North Dakota" },
        { label: "Ohio", value: "Ohio" },
        { label: "Oklahoma", value: "Oklahoma" },
        { label: "Oregon", value: "Oregon" },
        { label: "Pennsylvania[D]", value: "Pennsylvania[D]" },
        { label: "Rhode Island", value: "Rhode Island" },
        { label: "South Carolina", value: "South Carolina" },
        { label: "South Dakota", value: "South Dakota" },
        { label: "Tennessee", value: "Tennessee" },
        { label: "Texas", value: "Texas" },
        { label: "Utah", value: "Utah" },
        { label: "Vermont", value: "Vermont" },
        { label: "Virginia[D]", value: "Virginia[D]" },
        { label: "Washington", value: "Washington" },
        { label: "West Virginia", value: "West Virginia" },
        { label: "Wisconsin", value: "Wisconsin" },
        { label: "Wyoming", value: "Wyoming" },
      ],
    },
    {
      value: "Canada",
      label: "Canada",
      states: [
        { label: "Alberta", value: "Alberta" },
        { label: "British Columbia", value: "British Columbia" },
        { label: "Manitoba", value: "Manitoba" },
        { label: "New Brunswick", value: "New Brunswick" },
        {
          label: "Newfoundland and Labrador",
          value: "Newfoundland and Labrador",
        },
        { label: "Northwest Territories", value: "Northwest Territories" },
        { label: "Nova Scotia", value: "Nova Scotia" },
        { label: "Nunavut", value: "Nunavut" },
        { label: "Ontario", value: "Ontario" },
        { label: "Prince Edward Island", value: "Prince Edward Island" },
        { label: "Quebec", value: "Quebec" },
        { label: "Saskatchewan", value: "Saskatchewan" },
        { label: "Yukon", value: "Yukon" },
      ],
    },
    {
      value: "Other",
      label: "Other",
      states: [
        {
          value: "Other",
          label: "Other",
        },
      ],
    },
  ];
  const [localReasons, setLocalReasons] = useState([]);
  const [localPropertiesData, setLocalPropertiesData] = useState([]);
  const [state, setState] = React.useState({
    selectedCountry: null,
    otherCountry: null,
    selectedState: null,
    otherState: null,
    ticketStates: [],
    lpn: "",
    printNotes: "",
    internalNotes: "",
    language: "",
    reasons: "",
    reasons2: "",
    make: "",
    model: "",
    color: "",
    VIN: "",
    validVIN: true,
    noCharge: false,
    validPlate: true,
    validReasons: true,
    validMake: true,
    validColor: true,
    validModal: true,
    show_error: false,
    api_error_message: "",
    images: [],
    imageURL: "",
    isFormValid: true,
    hide_vin: true,
  });

  useEffect(async () => {
    const { reasons, properties } = db;
    const reason_data = await reasons.toArray().then((reasons) => reasons);
    const propertiesData = await properties.toArray().then((property) => property);
    setLocalReasons(reason_data);
    setLocalPropertiesData(propertiesData);
    setState((prev) => ({
      ...prev,
      language: propertiesData.length > 0 ? propertiesData[0].ticket_default_language : "",
      hide_vin: propertiesData.length > 0 ? propertiesData[0]?.hide_vin : true,
    }));
  }, []);

  useEffect(async () => {
    const { lpn, language, reasons, make, model, color, images, hide_vin, VIN } = state;
    console.log(
      "state.lpn, state.language, state.reasons, state.make, state.model, state.color, state.images, state.selectedCountry, state.selectedState",
      state.lpn,
      state.language,
      state.reasons,
      state.make,
      state.model,
      state.color,
      state.images,
      state.selectedCountry?.value,
      state.selectedState?.value,
      hide_vin,
      VIN,
    );
    if (lpn && language && reasons && make && model && color && images.length > 0 && state.selectedCountry?.value && state.selectedState?.value) {
      if ((!hide_vin && VIN) || hide_vin) {
        setState((prevState) => ({
          ...prevState,
          isFormValid: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isFormValid: true,
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        isFormValid: true,
      }));
    }
  }, [state.lpn, state.language, state.reasons, state.make, state.model, state.color, state.images, state.selectedCountry, state.selectedState, state.VIN]);

  const handleChange = async ({ target }) => {
    if (target.name === "country") {
      setState((prevState) => ({
        ...prevState,
        otherCountry: target.value,
      }));

      localStorage.setItem("ticket_country", target.value);
    }
    if (target.name === "state") {
      setState((prevState) => ({
        ...prevState,
        otherState: target.value,
      }));
      localStorage.setItem("ticket_state", target.value);
    }

    setState((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));

    switch (target.name) {
      case "lpn":
        {
          const LPN = validations(target.name, "lpn", target.value);
          setState((prevState) => ({
            ...prevState,
            lpn: LPN.value.toUpperCase().replace(/[^\w\s]/gi, ""),
            validPlate: LPN.validator,
          }));
        }
        break;
      case "make":
        {
          const Make = validations(target.name, "make", target.value);
          setState((prevState) => ({
            ...prevState,
            make: Make.value,
            validMake: Make.validator,
          }));
        }
        break;
      case "modal":
        {
          const Modal_ = validations(target.name, "modal", target.value);
          setState((prevState) => ({
            ...prevState,
            modal: Modal_.value,
            validModal: Modal_.validator,
          }));
        }
        break;
      case "color":
        {
          const Color = validations(target.name, "color", target.value);
          setState((prevState) => ({
            ...prevState,
            color: Color.value,
            validColor: Color.validator,
          }));
        }
        break;
      case "reasons":
        {
          const Reasons = validations(target.name, "reasons", target.value);
          setState((prevState) => ({
            ...prevState,
            reasons: Reasons.value,
            validReasons: Reasons.validator,
          }));
        }
        break;
      case "VIN":
        {
          const VIN_ = validations(target.name, "VIN", target.value);
          setState((prevState) => ({
            ...prevState,
            VIN: VIN_.value.toUpperCase().replace(/[^\w\s]/gi, ""),
            validVIN: VIN_.validator,
          }));
        }
        break;
      default:
        break;
    }

    if (target.name === "reasons" || target.name === "reasons2") {
      let Reasons = state.reasons;
      let Reasons2 = state.reasons2;
      if (target.name === "reasons") {
        Reasons = target.value;
      }
      if (target.name === "reasons2") {
        Reasons2 = target.value;
      }

      if (Reasons === Reasons2) {
        setState((prevState) => ({
          ...prevState,
          show_error: true,
          api_error_message: "Reason & Reason 2 should be unique.",
        }));

        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            show_error: false,
          }));
        }, 4000);
      } else if (state.show_error) {
        setState((prevState) => ({
          ...prevState,
          show_error: false,
          api_error_message: "",
        }));
      }
    }
  };
  const onToggleChange = (event) => {
    setState((state) => ({
      ...state,
      noCharge: event.target.checked,
    }));
  };

  const onCountryChange = (country) => {
    setState((prev) => ({
      ...prev,
      selectedCountry: country,
      selectedState: null,
      ticketStates: country.states,
    }));
  };

  const onStateChange = (states) => {
    setState((prev) => ({
      ...prev,
      selectedState: states,
    }));
  };

  const handleSubmit = async () => {
    const { tickets } = db;
    const ticket_data = await tickets.toArray().then((reasons) => reasons);
    console.log("tickets", ticket_data);
    if (ticket_data.length >= 25) {
      setState((prevState) => ({
        ...prevState,
        show_error: true,
        api_error_message: "Maximum ticket creation limit reached: 25 tickets allowed.",
      }));
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          show_error: false,
          api_error_message: "",
        }));
      }, 4000);
    } else {
      console.log("state", state);
      const { lpn, printNotes, noCharge, internalNotes, language, reasons, reasons2, make, model, color, images, VIN } = state;
      const data = {
        type: "citation",
        lpn: lpn,
        printNotes: printNotes,
        internalNotes: internalNotes,
        language: language,
        reasons: reasons,
        reasons2: reasons2,
        make: make,
        model: model,
        color: color,
        images: images,
        noCharge: noCharge,
        vin: VIN
      };
      if (state?.selectedCountry?.value) {
        if (state.selectedCountry && state.selectedCountry.value === "Other") {
          if (state.otherCountry?.length) {
            data.country_name = state.otherCountry;
          }
        } else {
          data.country_name = state.selectedCountry.value;
        }
      }
      if (state?.selectedState?.value) {
        if (state.selectedState && state.selectedState.value === "Other") {
          if (state.otherState) {
            data.state_name = state.otherState;
          }
        } else {
          data.state_name = state.selectedState.value;
        }
      }
      try {
        db.tickets.add(data);
        GetcitationData(false);
      } catch (error) {
        console.error("citation create error", error);
      }
    }
  };

  const handleAddPhoto = (files) => {
    const file = files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }
    const extension = file.name.split(".").pop();
    const validExtensions = ["jpg", "jpeg", "png", "jfif"];
    if (validExtensions.includes(extension)) {
      if (state.images.length < 5) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
          const base64data = reader.result;
          const mimeType = (reader.result.match(/^data:([^;]+);/) || "")[1];
          console.log("mimeType", mimeType);
          setState((prevState) => ({
            ...prevState,
            images: [...state.images, { id: state.images.length, image: base64data, filename: file.name, type: mimeType }],
          }));
        };
      } else {
        setState((prevState) => ({
          ...prevState,
          show_error: true,
          api_error_message: "Please select valid image",
        }));

        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            show_error: false,
          }));
        }, 4000);
      }
    } else {
      console.log("");
    }
  };

  const removeImage = (index) => {
    state.images.splice(index, 1); // Remove image from the state
    setState((prevState) => ({
      ...prevState,
      images: state.images,
    }));
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <div className="d-flex align-items-center">
                <i className="fa fa-times mr-2 cursor-pointer" aria-hidden="true" onClick={redirectOfflineCitation}></i>{" "}
                <h4 className="card-title">Citation </h4>
              </div>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col lg="3" md="4" xs="12" className="mb-3">
                    <Form.Label>
                      Plate
                      <span className="asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Plate"
                      value={state.lpn}
                      name="lpn"
                      // value={this.state.value}
                      onChange={handleChange}
                      className="fs-14 h-40"
                      maxLength="11"
                    />
                    {validation_message(state.lpn, "Lpn", state.validPlate)}
                  </Col>
                  <Col md={"4"}>
                    <Form.Group>
                      <label>Plate Issued Country</label>
                      <span className="asterisk">*</span>
                      <Select name="country" value={state.selectedCountry} onChange={onCountryChange} options={ticketCountry} />
                    </Form.Group>
                  </Col>
                  {state.selectedCountry?.value === "Other" && (
                    <Col md="4">
                      <Form.Group>
                        <label>Country</label>
                        <span className="asterisk">*</span>
                        <Form.Control placeholder="Country" type="text" name="country" value={state.otherCountry} onChange={handleChange} autoComplete="off" />
                      </Form.Group>
                    </Col>
                  )}
                  <Col md={"4"}>
                    <Form.Group>
                      <label>Plate Issued State / Province</label>
                      <span className="asterisk">*</span>
                      <Select name="state" value={state.selectedState} onChange={onStateChange} options={state.ticketStates} />
                    </Form.Group>
                  </Col>
                  {state.selectedState?.value === "Other" && (
                    <Col md="4">
                      <Form.Group>
                        <label>State</label>
                        <span className="asterisk">*</span>
                        <Form.Control placeholder="State" type="text" name="state" value={state.otherState} onChange={handleChange} autoComplete="off" />
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col lg="3" md="4" xs="12" className="mb-3">
                    <Form.Label c>
                      Print Ticket in <span className="asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      value={state.language}
                      name="language"
                      onChange={handleChange}
                      className="fs-14 h-40"
                      disabled={localPropertiesData.length > 0}
                    >
                      <option value="">-- Choose Language --</option>
                      <option value="en">English</option>
                      <option value="fn">French</option>
                      <option value="sp">Spanish</option>
                    </Form.Control>
                  </Col>
                  <Col lg="3" md="4" xs="12" className="mb-3">
                    <Form.Label>
                      Reason <span className="asterisk">*</span>
                    </Form.Label>
                    <Form.Control as="select" custom value={state.reasons} name="reasons" onChange={handleChange} className="fs-14 h-40">
                      <option value="">-- Choose Reason --</option>
                      {localReasons?.length > 0 &&
                        localReasons.map((e, key) => {
                          return (
                            <option key={e.id} value={e.id}>
                              {e.title}
                            </option>
                          );
                        })}
                    </Form.Control>
                    {validation_message(state.reasons, "Reasons", state.validReasons)}
                  </Col>
                  <Col lg="3" md="4" xs="12" className="mb-3">
                    <Form.Label>Reason2</Form.Label>
                    <Form.Control as="select" custom value={state.reasons2} name="reasons2" onChange={handleChange} className="fs-14 h-40">
                      <option value="">-- Choose Reason --</option>
                      {(localReasons ?? []).map((e, key) => {
                        return (
                          <option key={e.id} value={e.id}>
                            {e.title}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  <Col lg="3" md="4" xs="12" className="mb-3">
                    <Form.Label>
                      <label className="fancy-checkbox">Charge for 2nd reason</label>
                    </Form.Label>
                    <div className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        name={"noCharge"}
                        onChange={onToggleChange}
                        checked={state.noCharge}
                        value={state.noCharge}
                        disabled={!state.reasons2.length}
                      />
                    </div>
                  </Col>
                </Row>
                <h5 className="mt-3">Car Description</h5>
                <Row>
                  <Col md="6" lg="4" xl="3" className="mb-3">
                    <Form.Label>
                      Make
                      <span className="asterisk">*</span>
                    </Form.Label>
                    <Form.Control type="text" placeholder="make" onChange={handleChange} name={"make"} value={state.make} />
                    {validation_message(state.make, "Make", state.validMake)}
                  </Col>
                  <Col md="6" lg="4" xl="3" className="mb-3">
                    <Form.Label>
                      Modal
                      <span className="asterisk">*</span>
                    </Form.Label>
                    <Form.Control type="text" placeholder="modal" onChange={handleChange} name={"model"} value={state.model} />
                    {validation_message(state.model, "Modal", state.validModal)}
                  </Col>
                  <Col md="6" lg="4" xl="2" className="mb-3">
                    <SearchAutoComplete
                      isRequired={true}
                      label={"Color"}
                      name={"color"}
                      value={state.color}
                      type={"color"}
                      handleChange={(value) => {
                        handleChange({
                          target: {
                            name: "color",
                            value: value.color,
                          },
                        });
                      }}
                      defaultOptions={["White", "Black", "Gray", "Silver", "Red", "Blue", "Brown", "Green", "Beige", "Orange", "Gold", "Yellow", "Purple"]}
                    />
                    {validation_message(state.color, "Color", state.validColor)}
                  </Col>
                  {!state.hide_vin ? (
                    <>
                      <Col md="6" lg="4" xl="2" className="mb-3">
                        <Form.Label>
                          VIN <span className="asterisk">*</span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="VIN" value={state.VIN} name="VIN" onChange={handleChange} maxLength="17" />
                        {/* <p className="help-block">{this.props.viewVinWarningNumberDetails ? vinWarn : ""}</p> */}
                        {validation_message(state.VIN, "VIN", state.validVIN)}
                      </Col>
                    </>
                  ) : null}
                </Row>
                <Row className="photo-choose my-3">
                  <div className="images-block col-lg-12 d-flex align-items-end flex-wrap">
                    {state.images?.length ? (
                      state.images.map((item, index) => (
                        <div key={item.id} className="photo-choose-items position-relative mr-2 mb-2">
                          <img
                            src={item.image ? item.image : ""}
                            alt=""
                            height="140"
                            width="140"
                            name="image"
                            className="image-preview"
                            accept="image/png, image/jpeg"
                          />
                          {item.image !== defaultImage && (
                            <Button
                              onClick={() => removeImage(index)}
                              className="position-absolute btn btn-danger btn-sm"
                              style={{ top: "0", right: "0" }}
                              variant="danger"
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </Button>
                          )}
                        </div>
                      ))
                    ) : (
                      <img src={defaultImage} width="140" height="140" />
                    )}
                    <div className="upload-image-button ml-2">
                      <div className="input-file-upload-btn">
                        <Form.Control
                          className="btn-bottom d-none"
                          type="file"
                          name="image"
                          id="file-input-choose"
                          accept="image/png, image/jpeg"
                          onChange={(e) => handleAddPhoto(e.target.files)}
                          disabled={state.images.length === 5}
                        //  onClick={handleClick}
                        ></Form.Control>
                        {/* <span className={"help-block"}>{this.state.invalidMessage}</span> */}

                        <img src={state.imageURL} alt="" className="image-preview" accept="image/*" />

                        <div className="d-flex flex-column">
                          <label className="form-label">
                            {" "}
                            Notes: Image Required <span className="asterisk">*</span>
                          </label>
                          <div className="">
                            <a className="mr-2 p-0 btn btn-primary" title="Upload Photo">
                              <label htmlFor="file-input-choose" className="mb-0 btn-icon fs-1rem">
                                <i className="fa fa-upload cursor-pointer" aria-hidden="true"></i>{" "}
                              </label>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row>
                  <Col lg="4">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label> Notes: Internal use only</Form.Label>
                      <Form.Control as="textarea" value={state.printNotes} name="printNotes" onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col lg="4">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label> Notes: Print on Citation </Form.Label>
                      <Form.Control as="textarea" value={state.internalNotes} name="internalNotes" onChange={handleChange} maxLength="120" />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-end mt-5">
                  <Button type="button" className="check-plate-input mr-2 text-nowrap ml-3" onClick={handleSubmit} disabled={state.isFormValid}>
                    Generate
                  </Button>
                </div>
              </Form>
            </Card.Body>
            {state.show_error && state.api_error_message && <CustomContentAlert delay={4000} message={state.api_error_message} className="toast-error" />}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const types = {
  ADD_PAID_PERMIT_OPTION: "ADD_PAID_PERMIT_OPTION",
  GET_PAID_PERMIT_OPTIONS_LIST: "GET_PAID_PERMIT_OPTIONS_LIST",
  UPDATE_PERMIT_OPTION_DATA: "UPDATE_PERMIT_OPTION_DATA",
  ADD_UNIT_PERMIT_OPTION: "ADD_UNIT_PERMIT_OPTION",
  GET_UNIT_OPTIONS_LIST: "GET_UNIT_OPTIONS_LIST",
  DELETE_UNIT_PERMIT: "DELETE_UNIT_PERMIT",
  GET_UNIT_PAYMENT_SETTING: "GET_UNIT_PAYMENT_SETTING",
  UPDATE_UNIT_PERMIT_DATA: "UPDATE_UNIT_PERMIT_DATA",
  GET_CARD_DETAILS: "GET_CARD_DETAILS",
  SHOW_MAX_PERMIT_LIMIT_MODAL: "SHOW_MAX_PERMIT_LIMIT_MODAL",
  GET_PERMIT_GROUP_LIST: "GET_PERMIT_GROUP_LIST",
  SET_PERMIT_GROUP_LIST: "SET_PERMIT_GROUP_LIST",
  GET_TERM_PERMIT_OPTIONS_LIST: "GET_TERM_PERMIT_OPTIONS_LIST",
};

export default types;

import t from "./types";
import { AuthAPI } from "../../../Auth/requestHandler";
import apiType from "../APIResponseActions/type";

export const select_edit_lotcounting = (property_id, lot_id) => async (dispatch) => {
  try {
    lot_id = lot_id || null;
    const url = "lotcounter/lot_counter_by_lot_id_gui" + "/property_id/" + property_id + "/lot_id/" + lot_id;
    const result = await AuthAPI("domain", url, "GET");
    console.log("Result: ", result);
    await dispatch({ type: t.FETCHLOTCOUNTING, payload: result });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: result.msg,
    });
    return result;
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};
export const edit_lotcounting = (data, property_id, lot_id) => async (dispatch) => {
  try {
    const url = `lotcounter/update_counter/property_id/${property_id}/lot_id/${lot_id}`;
    const body = {
      total_stalls: parseInt(data.total_stalls),
      show_full_above: parseInt(data.show_full_above),
      show_not_full_above: parseInt(data.show_full_above),
      current_occupancy: parseInt(data.current_occupancy),
      show_not_full_below: parseInt(data.show_not_full_below),
      lot_id: lot_id,
      property_id: property_id,
    };
    const response = await AuthAPI("domain", url, "PATCH", body);
    if (response?.data && response?.success) {
      await dispatch({ type: t.EDITLOTCOUNTING, payload: response.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

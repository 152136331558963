import React, { Component } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import CitationPaidBySite from "./CitationPaidBySite";
import CitationPaidByPayment from "./CitationPaidByPayment";

class EnforcementReportCitationPaidSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
  }

  onTabClick = async (k) => {
    this.setState({ activeTab: k });
  };

  render() {
    const { activeTab } = this.state;
    return (
      <>
        <Container fluid>
          <Tabs
            activeKey={this.state.activeTab}
            id="controlled-tab-example"
            onSelect={(k) => this.onTabClick(k)}
            transition={false}
          >
            <Tab eventKey="1" title="By Site" className="m-0">
              {activeTab === "1" && <CitationPaidBySite />}
            </Tab>
            <Tab eventKey="2" title="By Payment Mode" className="m-0">
              {activeTab === "2" && <CitationPaidByPayment />}
            </Tab>
          </Tabs>
        </Container>
      </>
    );
  }
}

export default EnforcementReportCitationPaidSummary;

import React, { useEffect, useState, useRef } from "react";
import { Card, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector, batch } from "react-redux";
import t from "../../Actions/EdgeDeviceActions/types";
import * as EdgeDeviceActions from "../../Actions/EdgeDeviceActions/action";
import styled from "styled-components";
import Select from "react-select";
import _ from "lodash";

const AddEdgeDeviceTypeFunc = () => {
  const dispatch = useDispatch();
  const macRegex = React.useRef(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$|^[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}$/).current;
  const [validations, setValidations] = useState({});
  const [passwordVisible, setpasswordVisible] = useState({
    ws1_password: false,
    ws2_password: false,
    ws3_password: false,
    camera_password: false,
  });
  const [config, setConfig] = useState([]);
  const [cameraOptions, setCameraOptions] = useState([]);
  const [lotOptions, setLotOptions] = useState({});
  const [laneOptions, setLaneOptions] = useState({});
  const [lotValue, setLotValue] = useState({});
  const [laneValue, setLaneValue] = useState({});
  const [dropdownModeValue, setDropdownModeValue] = useState({});
  const [dropdownTransactionTypeValue, setDropdownTransactionTypeValue] = useState({});
  const [dropdownMaxchargeValue, setDropdownMaxchargeValue] = useState({});
  const [dropdownRateValue, setDropdownRateValue] = useState({});
  const [cameraValue, setCameraValue] = useState({});

  const modalRef = useRef(null);
  const configData = useSelector((state) => state.EdgeDeviceDetails.deviceData);

  console.log("configData::::::::::11111111111", configData);

  const edgeDevices = useSelector((state) => state.EdgeDeviceDetails.edgeDevices);
  const lotData = useSelector((state) => state.EdgeDeviceDetails.lotData);
  const laneData = useSelector((state) => state.EdgeDeviceDetails.laneData);
  const cameraData = useSelector((state) => state.EdgeDeviceDetails.cameraData);
  const apiMessage = useSelector((state) => state.EdgeDeviceDetails.message);
  const modelOpenCLose = useSelector((state) => state.EdgeDeviceDetails.modelOpenClose);
  const searchParams = window.document.location.href.split("?")[1];
  const prevData = useRef("");
  const prevExec = useRef(false);
  const selectedDeviceType = useSelector((state) => state.EdgeDeviceDetails.selectedDeviceType);

  const TogglePasswordVisibility = (fname) => {
    setpasswordVisible({
      ...passwordVisible,
      [fname]: !passwordVisible[fname],
    });
  };

  const IpChanges = (e) => {
    const whiteListIP = e.target.value;
    let errorMessage = "";
    if (whiteListIP) {
      const whiteListIPRegex = /^((?:\d|\d\d|1\d\d|2[0-4]\d|25[0-5])[.]){3}(?:\d|\d\d|1\d\d|2[0-4]\d|25[0-5])$/;

      errorMessage = !whiteListIPRegex.test(whiteListIP) ? `Please enter proper ${e.target.name.split("_").join(" ")}.` : "";
    }
    setValidations((validation) => ({
      ...validation,
      [e.target.name]: errorMessage,
    }));
    if (e.nativeEvent.data === "." || !isNaN(Number(e.nativeEvent.data))) {
      dispatch({
        type: t.SET_EDGE_DEVICE_DATA,
        payload: {
          ...configData,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.value.length <= 1 && isNaN(Number(e.nativeEvent.data))) {
      dispatch({
        type: t.SET_EDGE_DEVICE_DATA,
        payload: {
          ...configData,
          [e.target.name]: "",
        },
      });
    }
  };

  const PortChanges = (e) => {
    const whiteListPort = e.target.value;
    let errorMessage = "";
    if (whiteListPort) {
      const whiteListPortRegEx = /^\d*$/;
      if (!whiteListPortRegEx.test(whiteListPort)) {
        errorMessage = "Characters not allowed";
      }
      if (!_.inRange(whiteListPort, 0, 65353)) {
        /* whiteListPort varies from 0 to 65353 */
        errorMessage = `Please enter proper ${e.target.name.split("_").join(" ")}.`;
      }
    }

    setValidations((validation) => ({
      ...validation,
      [e.target.name]: errorMessage,
    }));
    if (!isNaN(Number(e.nativeEvent.data))) {
      dispatch({
        type: t.SET_EDGE_DEVICE_DATA,
        payload: {
          ...configData,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.value.length <= 1 && isNaN(Number(e.nativeEvent.data))) {
      dispatch({
        type: t.SET_EDGE_DEVICE_DATA,
        payload: {
          ...configData,
          [e.target.name]: "",
        },
      });
    }
  };

  const MacIdValidate = (e) => {
    const macID = e.target.value;
    let errorMessage = "";
    if (macID) {
      errorMessage = macID.replace(/[^a-zA-Z0-9:-]/g, "").length > 17 ? `${e.target.name.split("_").join(" ").toLowerCase()} is invalid.` : "";
    }
    if (!macID) {
      errorMessage = `${e.target.name.split("_").join(" ")} is required.`;
    } else if (!errorMessage) {
      if (!macRegex.test(e.target.value.replace(/[^a-zA-Z0-9:-]/g, ""))) {
        errorMessage = "mac id is invalid.";
      }
    }
    setValidations((validation) => ({
      ...validation,
      [e.target.name]: errorMessage,
    }));
    dispatch({
      type: t.SET_EDGE_DEVICE_DATA,
      payload: {
        ...configData,
        [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9:-]/g, "").toLowerCase(),
      },
    });
  };

  const ModeValidate = (e) => {
    const ModeNumber = e.target.value;
    let errorMessage = "";
    if (!ModeNumber) {
      errorMessage = `${e.target.name.split("_").join(" ")} is required.`;
    }
    setValidations((validation) => ({
      ...validation,
      [e.target.name]: errorMessage,
    }));
  };

  const TransactionTypeValidate = (e) => {
    const TransactionType = e.target.value;
    let errorMessage = "";
    if (!TransactionType) {
      errorMessage = `${e.target.name.split("_").join(" ")} is required.`;
    }
    setValidations((validation) => ({
      ...validation,
      [e.target.name]: errorMessage,
    }));
  };

  const SerialValidate = (e) => {
    const serialNumber = e.target.value;
    let errorMessage = "";
    if (serialNumber) {
      errorMessage = serialNumber.replace(/[^a-zA-Z0-9]/g, "").length > 20 ? `${e.target.name.split("_").join(" ")} is invalid.` : "";
    }
    if (!serialNumber) {
      errorMessage = `${e.target.name.split("_").join(" ")} is required.`;
    }
    setValidations((validation) => ({
      ...validation,
      [e.target.name]: errorMessage,
    }));
    dispatch({
      type: t.SET_EDGE_DEVICE_DATA,
      payload: {
        ...configData,
        [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/g, ""),
      },
    });
  };

  const handleChange = (e) => {
    if (e.target.type === "number" || e.target.type === "tel") {
      if (!isNaN(Number(e.nativeEvent.data))) {
        dispatch({
          type: t.SET_EDGE_DEVICE_DATA,
          payload: {
            ...configData,
            [e.target.name]: +e.target.value.replace(/[^*\d\s]/gi, ""),
          },
        });
      } else if (e.target.value.length <= 1 && isNaN(Number(e.nativeEvent.data))) {
        dispatch({
          type: t.SET_EDGE_DEVICE_DATA,
          payload: {
            ...configData,
            [e.target.name]: "",
          },
        });
      }
    } else if (e.target.name === "mac_id") {
      MacIdValidate(e);
    } else if (e.target.name === "serial_number") {
      SerialValidate(e);
    } else if (e.target.name === "mode") {
      ModeValidate(e);
    } else if (e.target.name === "transaction_type") {
      TransactionTypeValidate(e);
    } else if (["white_list_ip", "server_ip", "camera_ip", "axis_server_ip", "axis_camera_ip", "lmps_ip"].indexOf(e.target.name) !== -1) {
      IpChanges(e);
    } else if (["white_list_port", "server_port", "lmps_port"].indexOf(e.target.name) !== -1) {
      PortChanges(e);
    } else if (e.target.name === "passage_pass_location" || e.target.name === "passage_pass_key") {
      dispatch({
        type: t.SET_EDGE_DEVICE_DATA,
        payload: {
          ...configData,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setValidations((validation) => ({
        ...validation,
        [e.target.name]: e.target.value ? "" : `${e.target.name.split("_").join(" ")} is required.`,
      }));
      dispatch({
        type: t.SET_EDGE_DEVICE_DATA,
        payload: {
          ...configData,
          [e.target.name]: e.target.name === "device_name" ? e.target.value.replace(/[^\w\s]/gi, "") : e.target.value,
        },
      });
    }
  };

  const handleCheckboxChange = (e) => {
    dispatch({
      type: t.SET_EDGE_DEVICE_DATA,
      payload: { ...configData, [e.target.name]: e.target.checked },
    });
  };

  const handleClose = () => {
    dispatch({
      type: t.CLOSE_OPEN_MODEL,
      payload: false,
    });
    batch(() => {
      dispatch({
        type: t.SET_EDGE_DEVICE_DATA,
        payload: {},
      });
      dispatch({
        type: t.SET_DEVICE_TYPE,
        payload: [],
      });
      dispatch({
        type: t.CLOSE_OPEN_MODEL,
        payload: false,
      });
    });
  };

  const ValidateIPaddress = (ipaddress) => {
    return !!/^(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)$/.test(ipaddress);
  };

  const CommonValidation = (validation) => {
    if (configData.server_ip && !ValidateIPaddress(configData.server_ip)) {
      validation.server_ip = "Please enter proper IP Address.";
    }
    if (configData.server_port && isNaN(configData.server_port)) {
      validation.server_port = "Please enter proper Port.";
    }
  };

  const ILCValidation = (validation) => {
    CommonValidation(validation);
    if (configData.camera_ip && !ValidateIPaddress(configData.camera_ip)) {
      validation.camera_ip = "Please enter proper IP Address.";
    }
    return validation;
  };

  const SILCValidation = (validation) => {
    CommonValidation(validation);
    if (configData.axis_server_ip && !ValidateIPaddress(configData.axis_server_ip)) {
      validation.axis_server_ip = "Please enter proper IP Address.";
    }
    if (configData.lpms_ip && !ValidateIPaddress(configData.lpms_ip)) {
      validation.lpms_ip = "Please enter proper IP Address.";
    }
    if (configData.lpms_port && isNaN(configData.lpms_port)) {
      validation.lpms_port = "Please enter proper Port.";
    }
    return validation;
  };

  const FeedbackAndTicketDispenserValidation = (validation) => {
    CommonValidation(validation);
    if (configData.camera1_ip && !ValidateIPaddress(configData.camera1_ip)) {
      validation.camera1_ip = "Please enter proper IP Address.";
    }
    if (configData.camera2_ip && !ValidateIPaddress(configData.camera2_ip)) {
      validation.camera2_ip = "Please enter proper IP Address.";
    }
    return validation;
  };

  useEffect(() => {
    if (selectedDeviceType) {
      setConfig(
        (selectedDeviceType || [])
          .filter((item) => item) // Filter out any potential undefined or null values
          .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
      );
    }
    if (configData.lane_id && cameraData) {
      const camera = (cameraData ?? []).filter((lane) => lane.lane.id === configData.lane_id).map((lane) => ({ value: lane.id, label: lane.ip_addr }));
      setCameraOptions(camera);
    }
    if (lotData) {
      setLotOptions((lotData ?? []).map((lot) => ({ value: lot.id, label: lot.alias })));
    }
    if (lotData && configData.lot_id) {
      const lot = (lotData ?? []).find((x) => x.id === configData.lot_id);
      if (lot) {
        setLotValue({ value: lot.id, label: lot.alias });
      } else {
        setLotValue(null);
      }
    } else {
      setLotValue(null);
    }
    if (configData.lot_id && laneData) {
      setLaneOptions(
        (Array.isArray(laneData) ? laneData : []).filter((lane) => lane.lot.id === configData.lot_id).map((lane) => ({ value: lane.id, label: lane.alias })),
      );
    }

    if (laneData && configData.lane_id) {
      const lane = (laneData ?? []).find((x) => x.id === configData.lane_id);
      if (lane) {
        setLaneValue({ value: lane.id, label: lane.alias });
      } else {
        setLaneValue(null);
      }
    } else {
      setLaneValue(null);
    }
    if (cameraData && configData.camera_id) {
      const camera = (cameraData ?? []).find((x) => x.id === configData.camera_id);
      if (camera) {
        setCameraValue({ value: camera.id, label: camera.ip_addr });
      } else {
        setCameraValue(null);
      }
    } else {
      setCameraValue(null);
    }
    if (config && configData.mode) {
      if (configData?.mode) {
        setDropdownModeValue(configData.mode);
      }
    }
    if (config && configData.transaction_type) {
      if (configData?.transaction_type) {
        setDropdownTransactionTypeValue(configData.transaction_type);
      }
    }
    if (config && configData.maxcharge) {
      const maxchargeValue = config.filter((item) => {
        return item.data;
      });
      if (config.length > 0 && maxchargeValue.length > 0) {
        const dropdownmaxcharge = (maxchargeValue[0].data ?? []).find((x) => x.alias === configData.maxcharge);
        if (dropdownmaxcharge) {
          setDropdownMaxchargeValue(dropdownmaxcharge.alias);
        } else {
          setDropdownMaxchargeValue(null);
        }
      }
    }
    if (config && configData.rate) {
      const rateValue = config.filter((item) => {
        return item.data;
      });
      if (config.length > 0 && rateValue.length > 0) {
        const dropdownrate = (config[9].data ?? []).find((x) => x.alias === configData.rate);
        if (dropdownrate) {
          setDropdownRateValue(dropdownrate.alias);
        } else {
          setDropdownRateValue(null);
        }
      }
    }
  }, [
    selectedDeviceType,
    // config,
    configData,
    lotData,
    configData.lot_id,
    laneData,
    configData.lane_id,
    cameraData,
    // configData.camera_id,
    // configData.mode,
    // configData.transaction_type,
    // configData.maxcharge,
    // configData.rate,
  ]);

  const CheckValidation = () => {
    const basicData =
      configData.type === "VPC" ||
      configData.type === "ILC" ||
      configData.type === "SILC" ||
      configData.type === "Feedback" ||
      configData.type === "TicketDispenser" ||
      configData.type === "PaymentKiosk"
        ? ["type", "device_name", "lot_id", "lane_id", "mac_id", "camera_id"]
        : ["type", "device_name", "mac_id"];
    let allvalidations = {};
    if (configData.type === "PaymentKiosk") {
      basicData.pop();
      basicData.push("transaction_type");
      basicData.push("serial_number");
      basicData.push("mode");
    }
    if (configData.type === "TicketDispenser") {
      basicData.push("serial_number");
    }
    basicData.forEach((bd) => {
      if (
        !configData[bd] ||
        (bd === "camera_id" && !cameraOptions.some((x) => x.value === configData[bd])) ||
        (bd === "lot_id" && !lotOptions.some((x) => x.value === configData[bd])) ||
        (bd === "lane_id" && !laneOptions.some((x) => x.value === configData[bd]))
      ) {
        allvalidations[bd] = `${bd.replace("_", " ").split("id").join("")} is required.`;

        console.log("allvalidations[bd]:::11111", allvalidations[bd]);

        if (allvalidations[bd] === "type is required.") {
          allvalidations[bd] = "Device Type is required.";
        }
        if (allvalidations[bd] === "device name is required.") {
          allvalidations[bd] = "Device Name is required.";
        }
        if (allvalidations[bd] === "lot  is required.") {
          allvalidations[bd] = "Lot is required.";
        }
        if (allvalidations[bd] === "lane  is required.") {
          allvalidations[bd] = "Lane is required.";
        }
        if (allvalidations[bd] === "camera  is required.") {
          allvalidations[bd] = "Camera is required.";
        }
        if (allvalidations[bd] === "mac  is required.") {
          allvalidations[bd] = "MAC ID is required.";
        }
        if (allvalidations[bd] === "serial number is required.") {
          allvalidations[bd] = "Serial Number is required.";
        }
        if (allvalidations[bd] === "mode is required.") {
          allvalidations[bd] = "Mode is required.";
        }
        if (allvalidations[bd] === "transaction type is required.") {
          allvalidations[bd] = "Transaction Type is required.";
        }
      }
    });
    if (configData.mac_id && !allvalidations.mac_id) {
      if (!macRegex.test(configData.mac_id || configData.mac_id.length === 17)) {
        allvalidations.mac_id = "Please enter proper mac address...";
      }
    }
    if (configData.white_list_ip) {
      const whiteListIPRegex = /^((?:\d|\d\d|1\d\d|2[0-4]\d|25[0-5])[.]){3}(?:\d|\d\d|1\d\d|2[0-4]\d|25[0-5])$/;
      if (!whiteListIPRegex.test(configData.white_list_ip)) {
        allvalidations.white_list_ip = "Please enter proper whitelist ip address.";
      }
    }
    if (configData.white_list_port) {
      const whiteListPortRegEx = /^\d*$/;
      if (!whiteListPortRegEx.test(configData.white_list_port)) {
        allvalidations.white_list_port = "Please enter proper whitelist port.";
      }
    }
    if (configData.axis_retry_count) {
      if (isNaN(configData.axis_retry_count)) {
        allvalidations.axis_retry_count = "Please enter proper value";
      }
    }
    for (const cfg of config) {
      if (cfg.field_validation_regex) {
        if (configData[cfg.field_name]) {
          const regex = new RegExp(cfg.field_validation_regex);
          if (!regex.test(configData[cfg.field_name])) {
            allvalidations[cfg.field_name] = `${cfg.field_label.toLowerCase()} is invalid.`;
          }
        }
      }
    }
    if (configData.type === "ILC") {
      allvalidations = ILCValidation(allvalidations);
    } else if (configData.type === "SILC") {
      allvalidations = SILCValidation(allvalidations);
    } else if (configData.type === "Feedback" || configData.type === "TicketDispenser") {
      allvalidations = FeedbackAndTicketDispenserValidation(allvalidations);
    }
    setValidations(allvalidations);
    return Object.keys(allvalidations).length > 0;
  };

  const handleAddFields = () => {
    if (CheckValidation()) {
      return;
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    batch(() => {
      dispatch(
        EdgeDeviceActions.addEdgeDevice({
          ...configData,
          property_id: searchParams,
          old_data: Array.isArray(edgeDevices) ? edgeDevices.find((x) => x.id === configData?.id) : undefined,
        }),
      );
    });
  };

  useEffect(() => {
    console.log("selectedDeviceType, configData ----> ");
    if (selectedDeviceType && !configData?.id && !prevExec?.current) {
      const data = {};
      prevExec.current = true;
      selectedDeviceType.forEach((deviceData) => {
        if (deviceData.field_type === "text") {
          data[deviceData.field_name] = "";
        } else if (deviceData.field_type === "number") {
          data[deviceData.field_name] = 0;
        } else if (deviceData.field_type === "switch") {
          data[deviceData.field_name] = false;
        } else if (deviceData.field_type === "dropdown") {
          data[deviceData.field_name] = "";
        }
      });
      dispatch({
        type: t.SET_EDGE_DEVICE_DATA,
        payload: data,
      });
    }
  }, [selectedDeviceType]);

  useEffect(() => {
    console.log("configData ----> ");
    if (configData?.id && prevData.current !== configData.type) {
      prevData.current = configData.type;
      dispatch(EdgeDeviceActions.fetchFieldsForDeviceType(configData.type));
    }
  }, [configData]);

  const deviceTypes = useSelector((state) => state.EdgeDeviceDetails.deviceTypes);

  const changeDeviceType = (e) => {
    console.log("changeDeviceType calleD::::", e.target.value);
    setLotValue({});
    setLaneValue({});
    setCameraValue({});
    batch(() => {
      dispatch(EdgeDeviceActions.fetchFieldsForDeviceType(e.target.value));
      dispatch({
        type: t.SET_EDGE_DEVICE_DATA,
        payload: {
          type: e.target.value,
          device_name: "",
        },
      });
      setValidations((validation) => ({ type: null }));
    });
  };

  const changeDeviceTypeDropdown = (e) => {
    dispatch({
      type: t.SET_EDGE_DEVICE_DATA,
      payload: { ...configData, [e.target.name]: e.target.value },
    });
    setValidations((validation) => ({ ...validation, dropdownField: null }));
  };

  const handleDevicetypeValue = (device) => {
    if (device.field_name === "mode") {
      return dropdownModeValue;
    } else if (device.field_name === "maxcharge") {
      return dropdownMaxchargeValue;
    } else if (device.field_name === "rate") {
      return dropdownRateValue;
    } else if (device.field_name === "transaction_type") {
      return dropdownTransactionTypeValue;
    } else {
      return "";
    }
  };

  const changeLane = (e) => {
    dispatch({
      type: t.SET_EDGE_DEVICE_DATA,
      payload: { ...configData, lane_id: e.value, camera_id: null },
    });
    setValidations((validation) => ({ ...validation, lane_id: null }));
  };

  const changeLot = (e) => {
    dispatch({
      type: t.SET_EDGE_DEVICE_DATA,
      payload: { ...configData, lot_id: e.value, lane_id: null },
    });
    setValidations((validation) => ({ ...validation, lot_id: null }));
  };

  const changeCamera = (e) => {
    console.log("changeCamera:::::", e.value);
    dispatch({
      type: t.SET_EDGE_DEVICE_DATA,
      payload: { ...configData, camera_id: e.value },
    });
    setValidations((validation) => ({ ...validation, camera_id: null }));
  };

  const changeCameraType = (e) => {
    dispatch({
      type: t.SET_EDGE_DEVICE_DATA,
      payload: { ...configData, camera_type: e.value },
    });
    setValidations((validation) => ({ ...validation, camera_type: null }));
  };

  useEffect(() => {
    console.log("dispatch ----> ");
    batch(() => {
      dispatch(EdgeDeviceActions.fetchAllLots());
      dispatch(EdgeDeviceActions.fetchAllLanes());
      dispatch(EdgeDeviceActions.fetchAllCameras());
    });
  }, []);

  const cameraTypeOptions = React.useMemo(() => {
    return [
      {
        label: "None",
        value: "None",
      },
      {
        label: "WS1",
        value: "WS1",
      },
      {
        label: "WS2",
        value: "WS2",
      },
      {
        label: "WS3",
        value: "WS3",
      },
    ];
  }, []);

  return (
    <div>
      <Modal lg={"medium"} show={modelOpenCLose} backdrop={"static"} onHide={handleClose} dialogClassName="modal-90w" fullscreen={"sm-down"}>
        <Card.Header className="d-flex justify-content-between bg-white align-items-center">
          <h4 className="m-0 font-weight-bold">
            <Card.Title className="m-0">Add Edge Device</Card.Title>
          </h4>
          <Button title={"Close"} type="button" onClick={handleClose} className="close-btn py-0" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove text-danger" />
          </Button>
        </Card.Header>

        <ModalBody>
          <Form.Group>
            <Row className="px-4">
              <Col>
                <FieldWrapper className="form-group mb-2" hasError={!!validations.type}>
                  <Form.Label className="mb-0">
                    Device Type <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control as="select" name="type" placeholder="Device Type" value={configData.type} onChange={changeDeviceType}>
                    <option value="">--Select Device Type--</option>
                    {(deviceTypes ?? []).map((e, key) => {
                      return (
                        <option key={key} value={e.type}>
                          {e.type}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {validations.type && <ErrorSpan>{validations.type}</ErrorSpan>}
                </FieldWrapper>
                <FieldWrapper className="form-group mb-2" hasError={!!validations.device_name}>
                  <Form.Label className="mb-0">
                    Device Name <span className="asterisk">*</span>
                  </Form.Label>
                  <FormInput
                    type="text"
                    placeholder="device_name"
                    value={configData.device_name}
                    name="device_name"
                    // value={this.state.value}
                    onChange={handleChange}
                  />
                  {validations.device_name && <ErrorSpan>{validations.device_name}</ErrorSpan>}
                </FieldWrapper>
                <SelectFieldWrapper className="form-group mb-2" hasError={!!validations.lot_id}>
                  <Form.Label className="mb-0">
                    Select Lot
                    {(configData.type === "VPC" ||
                      configData.type === "ILC" ||
                      configData.type === "SILC" ||
                      configData.type === "Feedback" ||
                      configData.type === "TicketDispenser" ||
                      configData.type === "PaymentKiosk") && <span className="asterisk">*</span>}
                  </Form.Label>
                  <Select name="lot_id" value={lotValue} menuPortalTarget={modalRef.current} onChange={changeLot} options={lotOptions} />
                  {validations.lot_id && <ErrorSpan>{validations.lot_id}</ErrorSpan>}
                </SelectFieldWrapper>
                <SelectFieldWrapper className="form-group mb-2" hasError={!!validations.lane_id}>
                  <Form.Label className="mb-0">
                    Select Lane
                    {(configData.type === "VPC" ||
                      configData.type === "ILC" ||
                      configData.type === "SILC" ||
                      configData.type === "Feedback" ||
                      configData.type === "TicketDispenser" ||
                      configData.type === "PaymentKiosk") && <span className="asterisk">*</span>}
                  </Form.Label>
                  <Select name="lane_id" menuPortalTarget={modalRef.current} value={laneValue} onChange={changeLane} options={laneOptions} />
                  {validations.lane_id && <ErrorSpan>{validations.lane_id}</ErrorSpan>}
                </SelectFieldWrapper>
                <SelectFieldWrapper className="form-group mb-2" hasError={!!validations.camera_id}>
                  <Form.Label className="mb-0">
                    Select Camera
                    {(configData.type === "VPC" ||
                      configData.type === "ILC" ||
                      configData.type === "SILC" ||
                      configData.type === "Feedback" ||
                      configData.type === "TicketDispenser") && <span className="asterisk">*</span>}
                  </Form.Label>
                  <Select name="camera_id" value={cameraValue} menuPortalTarget={modalRef.current} onChange={changeCamera} options={cameraOptions} />
                  {validations.camera_id && <ErrorSpan>{validations.camera_id}</ErrorSpan>}
                </SelectFieldWrapper>
                <FieldWrapper className="form-group mb-2" hasError={!!validations.white_list_ip}>
                  <label className="mb-0">{"Whiltelist ip"}</label>
                  <FormInput
                    placeholder={"white_list_ip"}
                    type={"text"}
                    name={"white_list_ip"}
                    value={configData.white_list_ip}
                    onChange={handleChange}
                    className="form-group"
                    autoComplete="off"
                    required
                  />
                  {validations.white_list_ip && <ErrorSpan>{validations.white_list_ip}</ErrorSpan>}
                </FieldWrapper>
                <FieldWrapper className="form-group mb-2" hasError={!!validations.white_list_port}>
                  <label className="mb-0">{"Whiltelist port"}</label>
                  <FormInput
                    placeholder={"white_list_port"}
                    type={"tel"}
                    name={"white_list_port"}
                    value={configData.white_list_port}
                    onChange={handleChange}
                    className="form-group"
                    autoComplete="off"
                    required
                  />
                  {validations.white_list_port && <ErrorSpan>{validations.white_list_port}</ErrorSpan>}
                </FieldWrapper>
                {(config ?? []).map((device, index) => {
                  if (
                    device.field_name !== "camera_type" &&
                    (device.field_type === "text" || device.field_type === "password" || device.field_type === "tel" || device.field_type === "number")
                  ) {
                    return (
                      <FieldWrapper key={`${device?.id}`} className="form-group mb-2" hasError={!!validations[device.field_name]}>
                        <label className="mb-0">{device.field_label}</label>
                        {(device.field_name === "mac_id" || device.field_name === "serial_number") && <span className="asterisk">*</span>}
                        <div className="input-mask-btn">
                          <FormInput
                            placeholder={device.field_label}
                            type={device.field_type === "password" && passwordVisible[device.field_name] ? "text" : device.field_type}
                            name={device.field_name}
                            value={configData[device.field_name]}
                            onChange={handleChange}
                            className="form-group"
                            autoComplete="new-password"
                            min={device.field_type === "number" ? 0 : undefined}
                            // max={device.field_type === "number" && 24}
                            maxLength={device.field_name === "serial_number" ? "20" : undefined}
                            required
                          />
                          {device.field_type === "password" && (
                            <button className="mask-password" type="button" onClick={() => TogglePasswordVisibility(device?.field_name)}>
                              <i className={`fas ${passwordVisible[device.field_name] ? "fa-eye" : "fa-eye-slash"}`} />
                            </button>
                          )}
                        </div>

                        {apiMessage?.message !== "" && apiMessage?.isSuccess === false && device.field_name === "mac_id" && (
                          <Row>
                            <ErrorCol>
                              <span className="help-block">{apiMessage.message}</span>
                            </ErrorCol>
                          </Row>
                        )}
                        {validations[device.field_name] && <ErrorSpan>{validations[device.field_name]}</ErrorSpan>}
                      </FieldWrapper>
                    );
                  } else if (device.field_name === "camera_type") {
                    const value = cameraTypeOptions.find((x) => x.value === configData[device.field_name]);
                    return (
                      <FieldWrapper key={`${device.id}`} className="form-group mb-2" hasError={!!validations.camera_type}>
                        <Form.Label className="mb-0">
                          {device.field_label}
                          {device.field_required && !configData.id ? <span className="asterisk">*</span> : null}
                        </Form.Label>
                        <Select
                          name={device.field_name}
                          value={value}
                          menuPortalTarget={modalRef.current}
                          onChange={changeCameraType}
                          options={cameraTypeOptions}
                        />
                        {validations.camera_type && <ErrorSpan>{validations.camera_type}</ErrorSpan>}
                      </FieldWrapper>
                    );
                  } else if (device.field_type === "switch") {
                    return (
                      <div className="form-group mb-2 d-flex align-items-center" key={`${device?.id}`}>
                        <React.Fragment key={device.id}>
                          <Form.Check
                            placeholder={device.field_label}
                            id={device.field_name}
                            type={device.field_type}
                            name={device.field_name}
                            label={device.field_label}
                            onChange={handleCheckboxChange}
                            checked={configData[device.field_name]}
                            value={configData[device.field_name]}
                            className="mb-1"
                            // className="form-group"
                            autoComplete="new-password"
                            min={device.field_type === "number" ? 0 : undefined}
                            // max={device.field_type === "number" && 24}
                            required
                          />
                        </React.Fragment>
                      </div>
                    );
                  } else if (device.field_type === "dropdown") {
                    return (
                      <FieldWrapper className="form-group mb-2" hasError={!!validations.type} key={`${device?.id}`}>
                        <Form.Label className="mb-0">{device.field_label}</Form.Label>
                        {device.field_name === "mode" || (device.field_name === "transaction_type" && !configData.id) ? (
                          <span className="asterisk">*</span>
                        ) : null}
                        <Form.Control
                          as="select"
                          name={device.field_name}
                          placeholder={device.field_label}
                          value={handleDevicetypeValue(device)}
                          onChange={(e) => changeDeviceTypeDropdown(e)}
                        >
                          <option value="">{device.default_field_value}</option>
                          {(device?.data ?? []).map((e, key) => {
                            return (
                              <option key={key} value={e.alias} label={e.alias}>
                                {e.alias}
                              </option>
                            );
                          })}
                        </Form.Control>
                        {validations.mode && device.field_name === "mode" && <ErrorSpan>{validations.mode}</ErrorSpan>}
                        {validations.transaction_type && device.field_name === "transaction_type" && <ErrorSpan>{validations.transaction_type}</ErrorSpan>}
                      </FieldWrapper>
                    );
                  } else {
                    return <React.Fragment key={`${device?.id}`} />;
                  }
                })}
              </Col>
            </Row>
            {/* </ScrollBar> */}
          </Form.Group>
        </ModalBody>
        <Modal.Footer>
          <Button className="btn-fill" type="button" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="btn-fill" type="button" variant="info" onClick={handleAddFields}>
            {configData?.id ? "Edit" : "Add"} Edge Device
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddEdgeDeviceTypeFunc;
const ErrorCol = styled(Col)`
  margin-left: 0px;
  margin-bottom: 14px;
`;
const ModalBody = styled(Modal.Body)`
  padding-right: 0px !important;
  padding-left: 0px !important;
`;
const ErrorSpan = styled.span`
  color: #ff3939;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const FormInput = styled(Form.Control)`
  margin-bottom: 0px;
`;

const FieldWrapper = styled.div`
  ${(props) => (props.hasError ? " label { color: #f44141 !important; } input, select { border-color: #f44141 !important; }" : "")}
`;
const SelectFieldWrapper = styled.div`
  ${(props) => (props.hasError ? " label { color: #f44141; } div > div { border-color: #f44141; }" : "")}
`;

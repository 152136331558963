import React, { Component } from "react";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import * as UserProfileActions from "../Actions/UserProfileActions/actions";
import PleaseWaitComponent from "./PleaseWaitComponent";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: false,
      UserProfileDetails: null,
      isRender: false,
      dynamicTitle: "",
      cookieCheck: false,
      isSemiLogin: false,
      menu: false,
    };
  }

  componentDidMount() {
    const isSemiLogin = window.location.href.split("/").includes("false") || window.location.href.split("/").includes("true");
    const menu = window.location.href.split("/")[4] !== "false";
    const userName = window.location.href.split("/")[window.location.href.split("/").length - 3];
    const epochTime = window.location.href.split("/")[window.location.href.split("/").length - 2];
    const domain = window.location.href.split("/")[window.location.href.split("/").length - 4];
    const key = window.location.href.split("/")[window.location.href.split("/").length - 1];

    this.setState({
      isSemiLogin: isSemiLogin,
      menu: menu,
    });
    this.CheckLogin(isSemiLogin, userName, epochTime, domain, key);
  }

  async CheckAuthToken(authToken) {
    if (authToken && this.props.UserProfileDetails.basicAuth !== authToken) {
      await this.props.userLogin(null, true, authToken);
    }
  }

  async SemiLogin(userName, epochTime, domain, key) {
    const menu = window.location.href.split("/")[4] !== "false";
    await this.props.semiLogin(userName, epochTime, domain, menu, key);
  }

  async CheckLogin(isSemiLogin, userName, epochTime, domain, key) {
    const url = window.location.href;
    const subDomainDetail = this.isSubdomain(url);
    const isSubDomain = subDomainDetail.isSubDomain;
    const dynamicTitle = isSubDomain ? `: ${subDomainDetail.subDomain}` : "";
    const authToken = this.getCookie("basicAuth");
    if (!isSemiLogin) {
      await this.CheckAuthToken(authToken, isSemiLogin);
    }
    if (isSemiLogin) {
      await this.SemiLogin(userName, epochTime, domain, key);
      this.setState({
        isRender: true,
        isSemiLogin: isSemiLogin,
        cookieCheck: true,
      });
    } else {
      if (this.props.UserProfileDetails && Object.keys(this.props.UserProfileDetails).length && authToken) {
        this.setState({
          auth: true,
          UserProfileDetails: this.props.UserProfileDetails,
          isRender: true,
          dynamicTitle: dynamicTitle,
          cookieCheck: true,
        });
      } else {
        this.setState({
          auth: false,
          UserProfileDetails: this.props.UserProfileDetails,
          isRender: true,
          dynamicTitle: dynamicTitle,
          cookieCheck: true,
        });
      }
    }
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  }

  isSubdomain = (url) => {
    url = url.replace(/^\s+/, "");
    url = url.replace(/\s+$/, "");

    url = url.replace(/\\/g, "/");
    url = url.replace(/^http:\/\/|^https:\/\/|^ftp:\/\//i, "");
    url = url.replace(/^www\./i, "");
    url = url.replace(/\/(.*)/, "");
    let subDomain = "";
    if (url.match(/\.[a-z]{2,3}\.[a-z]{2}$/i)) {
      url = url.replace(/\.[a-z]{2,3}\.[a-z]{2}$/i, "");
    } else if (url.match(/\.[a-z]{2,4}$/i)) {
      url = url.replace(/\.[a-z]{2,4}$/i, "");
      subDomain = url.split(".")[0];
    }
    const isSubDomain = !!url.match(/\./g);
    return { isSubDomain, subDomain };
  };

  componentDidUpdate(prevProps) {
    if (prevProps.UserProfileDetails !== this.props.UserProfileDetails) {
      const url = window.location.href;
      const subDomainDetail = this.isSubdomain(url);
      const isSubDomain = subDomainDetail.isSubDomain;
      const dynamicTitle = isSubDomain ? `: ${subDomainDetail.subDomain}` : "";
      this.setState({
        auth: !!(this.props.UserProfileDetails && Object.keys(this.props.UserProfileDetails).length),
        UserProfileDetails: this.props.UserProfileDetails,
        isRender: true,
        dynamicTitle: dynamicTitle,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }

  onFocus = () => {
    const history = require("../history").default;
    const basicAuth = localStorage.getItem("basicAuth");
    if (
      history.location.pathname !== "/login" &&
      history.location.pathname !== "/dispute" &&
      history.location.pathname !== "/driver-ticket" &&
      history.location.pathname !== "/ticket-payment" &&
      history.location.pathname !== "/ticket-payment/payment/step1" &&
      history.location.pathname !== "/ticket-payment/payment/step2" &&
      history.location.pathname !== "/ticket-payment/payment/success" &&
      basicAuth === null
    ) {
      return window.location.reload();
    }
  };

  renderRoutes = () => {
    const { auth, UserProfileDetails, isRender, cookieCheck, isSemiLogin, dynamicTitle } = this.state;
    const childProps = {
      UserProfileDetails,
      auth,
      isSemiLogin,
      SelfRegisterDetails: this.props.SelfRegisterDetails,
    };

    if (isRender) {
      return (
        <>
          <Helmet>
            <title>WorldStream {dynamicTitle}</title>
          </Helmet>
          {this.renderPrivateAndPublicRoutes(cookieCheck, auth, childProps)}
        </>
      );
    } else {
      return <PleaseWaitComponent />;
    }
  };

  renderPrivateAndPublicRoutes = (cookieCheck, auth, childProps) => {
    if (cookieCheck) {
      if (auth) {
        return <PrivateRoutes {...childProps} />;
      } else {
        return <PublicRoutes {...childProps} />;
      }
    } else {
      return <PleaseWaitComponent msg="Please wait...." />;
    }
  };

  render() {
    return <>{this.renderRoutes()}</>;
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails, SelfRegisterDetails }) => {
  const { semiAuthorizedUserProfile } = Logindetails;
  return {
    UserProfileDetails,
    SelfRegisterDetails,
    semiAuthorizedUserProfile,
  };
};

export default connect(mapStateToProps, {
  userLogin: UserProfileActions.userLogin,
  semiLogin: UserProfileActions.semiLogin,
})(Routes);

import React, { Component } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { connect } from "react-redux";
import { ResidentOccupancyActions, SuperAdminAction } from "../../Actions";
import CustomContentAlert, { findBySearch, tzDate } from "../helper";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import PaginationforData from "../pagination/pagination";

class ResidentOccupancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PropertyId: this.props.property_id,
      loader: false,
      residentOccupancy: [],
      newResidentOccupancy: [],
      api_response: false,
      searchValue: "",
      page: 1,
      Norow: 10,
      selectedColumn: "created_at",
      asc: "false",
      sort_direction: "DESC",
      prevSearch: "",
      prevPage: "",
      prevNorow: "",
      SuiteId: "",
      pageChanged: false,
      findMore: false,
      timezone: this.props.timezone,
      count: 0,
      systemresidentOccupancy: [],
      property: this.props.property,
    };
    this.columns = [
      {
        name: "LPN",
        sortable: true,
        selector: (row) => row.lpn,
        cell: (row) => <div>{row.lpn}</div>,
      },
      {
        name: "Time",
        sortable: true,
        selector: (row) => row.created_at,
        cell: (row) => (
          // <div>{moment(row.created_at).format("MMM Do YYYY, h:mm")}</div>
          <div>{tzDate(this.state.timezone, row.created_at)}</div>
        ),
      },
      // {
      //   name: "Status",
      //   sortable: true,
      //   selector: (row) => row.Status,
      //   cell: (row) => <div>{row.Status}</div>,
      // },
      {
        name: "Permit Valid From",
        sortable: true,
        selector: (row) => row.begin_time,
        cell: (row) => (
          // <div>{moment(row.begin_time).format("MMM Do YYYY, h:mm")}</div>
          <div>{tzDate(this.state.timezone, row.begin_time)}</div>
        ),
      },
      {
        name: "Permit Valid To",
        sortable: true,
        selector: (row) => row.expire_time,
        cell: (row) => (
          // <div>{moment(row.expire_time).format("MMM Do YYYY, h:mm")}</div>
          <div>{tzDate(this.state.timezone, row.expire_time)}</div>
        ),
      },
      {
        name: "Lot",
        sortable: true,
        selector: (row) => row.alias,
        cell: (row) => <div>{row.alias}</div>,
      },
    ];
  }

  componentDidMount() {
    this.setState({ loader: true }, async () => {
      const {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        PropertyId,
      } = this.state;
      const pageobj = {
        PropertyId,
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
      };
      await this.props.fetch_resident_occupancy(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        residentOccupancy: this.props.residentOccupancy.data,
        loader: false,
        api_response: this.props.api_response,
        count: this.props.residentOccupancy.count,
      });
    });
  }

  handleSearchChange = ({ target }) => {
    const val = target
      ? target.value.toString().trim()
      : this.state.searchValue.toString().trim();

    this.setState({ prevSearch: this.state.searchValue });
    this.setState({ searchValue: val }, () => {
      if (!this.state.findMore) {
        if (this.state.searchValue.length) {
          this.setState(
            {
              systemresidentOccupancy: findBySearch(
                this.state.searchValue,
                this.state.residentOccupancy,
                this.state.residentOccupancy
              ),
            },
            () => {
              this.setState({
                count: this.state.systemresidentOccupancy.length,
              });
            }
          );
        } else {
          this.setState({ loader: true, pageChanged: true }, async () => {
            const {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
              PropertyId,
            } = this.state;
            const pageobj = {
              PropertyId,
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            await this.props.fetch_resident_occupancy(pageobj);
            this.setState({
              pageChanged: true,
              page: 1,
              count: this.props.residentOccupancy.count,
              systemresidentOccupancy: this.props.residentOccupancy.data,
              loader: false,
              api_response: this.props.api_response,
            });
          });
        }
      }
    });
  };

  handlepage = ({ target }) => {
    this.setState({ prevPage: this.state.page, prevNorow: this.state.Norow });
    this.setState(
      {
        [target.name]: target.value,
      },
      async () => {
        switch (target.name) {
          case "page":
            this.setState(
              { page: target.value, pageChanged: true },
              async () => {
                const {
                  page,
                  Norow,
                  searchValue,
                  selectedColumn,
                  sort_direction,
                  PropertyId,
                  user_type,
                } = this.state;
                const pageobj = {
                  PropertyId,
                  user_type,
                  page,
                  Norow,
                  searchValue,
                  selectedColumn,
                  sort_direction,
                };
                this.setState({ loader: true }, async () => {
                  await this.props.fetch_resident_occupancy(pageobj);
                  this.setState({
                    systemresidentOccupancy: await this.props.residentOccupancy
                      .data,
                    api_response: this.props.api_response,
                  });
                  this.setState({ loader: false });
                });
              }
            );
            break;
          case "Norow":
            this.setState(
              { Norow: target.value, pageChanged: true },
              async () => {
                const {
                  page,
                  Norow,
                  searchValue,
                  selectedColumn,
                  sort_direction,
                  PropertyId,
                  // user_type,
                } = this.state;
                const pageobj = {
                  PropertyId,
                  // user_type,
                  page,
                  Norow,
                  searchValue,
                  selectedColumn,
                  sort_direction,
                };
                this.setState({ loader: true }, async () => {
                  await this.props.fetch_resident_occupancy(pageobj);
                  this.setState({
                    systemresidentOccupancy: await this.props.residentOccupancy
                      .data,
                    api_response: this.props.api_response,
                  });
                  this.setState({ loader: false });
                });
              }
            );
            break;
        }
        if (
          Number(this.state.page) !== Number(this.state.prevPage) ||
          Number(this.state.Norow) !== Number(this.state.prevNorow)
        ) {
          const {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            PropertyId,
            // user_type,
          } = this.state;
          const pageobj = {
            PropertyId,
            // user_type,
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          this.setState({ loader: true, pageChanged: true }, async () => {
            await this.props.fetch_resident_occupancy(pageobj);
            this.setState({
              systemresidentOccupancy: await this.props.residentOccupancy.data,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        }
      }
    );
  };

  handleRowsPerPage = () => {
    const { Norow, page } = this.state;
    return { Norow, page };
  };

  handlepagenext = async () => {
    const { page, Norow } = this.state;
    const count = this.props.residentOccupancy.count;

    const finalPage = Math.ceil(count / Norow);
    if (page + 1 <= finalPage) {
      this.setState({ page: page + 1, pageChanged: true }, async () => {
        const {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          PropertyId,
          // user_type,
        } = this.state;
        const pageobj = {
          PropertyId,
          // user_type,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true }, async () => {
          await this.props.fetch_resident_occupancy(pageobj);
          this.setState({
            systemresidentOccupancy: await this.props.residentOccupancy.data,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepageprevious = () => {
    const { page } = this.state;
    if (page !== 1) {
      this.setState({ page: page - 1, pageChanged: true }, async () => {
        const {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          PropertyId,
          // user_type,
        } = this.state;
        const pageobj = {
          PropertyId,
          // user_type,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true }, async () => {
          await this.props.fetch_resident_occupancy(pageobj);
          this.setState({
            systemresidentOccupancy: await this.props.residentOccupancy.data,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepageinitial = () => {
    if (this.state.page !== 1) {
      this.setState({ page: 1, pageChanged: true }, async () => {
        const {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          PropertyId,
          // user_type,
        } = this.state;
        const pageobj = {
          PropertyId,
          // user_type,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true }, async () => {
          await this.props.fetch_resident_occupancy(pageobj);
          this.setState({
            systemresidentOccupancy: await this.props.residentOccupancy.data,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepagefinal = async () => {
    const { page, Norow } = this.state;
    let count = await this.props.residentOccupancy.count;
    if (count === 0) {
      count = 1;
    }
    let last_page = count / Norow;
    if (last_page === 0) {
      last_page = 1;
    }
    const rounded = Math.ceil(last_page);
    if (page < rounded) {
      this.setState({ page: rounded, pageChanged: true }, async () => {
        const {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          PropertyId,
          // user_type,
        } = this.state;
        const pageobj = {
          PropertyId,
          // user_type,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true }, async () => {
          await this.props.fetch_resident_occupancy(pageobj);
          this.setState({
            systemresidentOccupancy: await this.props.residentOccupancy.data,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  findMorePressed = () => {
    this.setState({ findMore: true }, () => {
      if (this.state.findMore) {
        if (this.state.searchValue.length) {
          this.setState({ loader: true, pageChanged: true }, async () => {
            const {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
              PropertyId,
            } = this.state;
            const pageobj = {
              PropertyId,
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            await this.props.fetch_resident_occupancy(pageobj);
            this.setState({
              pageChanged: true,
              page: 1,
              systemresidentOccupancy: this.props.residentOccupancy.data,
              loader: false,
              api_response: this.props.api_response,
            });
          });
          setTimeout(() => {
            this.setState({ findMore: false });
          }, 1500);
        }
      }
    });
  };

  render() {
    const {
      loader,
      residentOccupancy,
      api_response,
      searchValue,
      systemresidentOccupancy,
      page,
      pageChanged,
      Norow,
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <Card.Title as="h4">Parking Log</Card.Title>
                </Card.Header>
                <Card.Body>
                  <>
                    <DatatableHeader
                      // Header={"Resident Parking Log"}
                      searchValue={this.state.searchValue}
                      HandleChange={this.handleSearchChange}
                      findMorePressed={this.findMorePressed}
                    />
                  </>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {!api_response && (
                        <CustomContentAlert
                          className="toast-error"
                          delay={4000}
                          message={
                            "The server did not respond, Please try again after sometime"
                          }
                        />
                      )}
                      <div className="citation-list-table">
                        <DataTable
                          data={
                            searchValue.length || pageChanged
                              ? systemresidentOccupancy
                              : residentOccupancy
                          }
                          columns={this.columns}
                          customStyles={this.customStyles}
                          // pagination={true}
                          responsive={true}
                          // subHeader
                          defaultSortAsc={true}
                          fixedHeader={true}
                          fixedHeaderScrollHeight="40vh"
                        />
                        <PaginationforData
                          count={this.state.count}
                          data={this.state.systemresidentOccupancy}
                          handlepageinitial={this.handlepageinitial}
                          handlepageprevious={this.handlepageprevious}
                          handlepagenext={this.handlepagenext}
                          handlepagefinal={this.handlepagefinal}
                          handlepage={this.handlepage}
                          page={page}
                          Norow={Norow}
                          pageChanged={pageChanged}
                        />
                      </div>
                      {/* ) : null} */}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({
  UserProfileDetails,
  APIResponseDetails,
  ResidentOccupancyDetails,
  Logindetails,
}) => {
  const { api_response, api_error_message } = APIResponseDetails;
  const { property_id, user_type, timezone, property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { residentOccupancy } = ResidentOccupancyDetails;
  return {
    api_response,
    api_error_message,
    property_id,
    user_type,
    residentOccupancy,
    timezone,
    property,
    main_body,
  };
};

const mapStateToDispatch = {
  fetch_resident_occupancy: ResidentOccupancyActions.fetch_resident_occupancy,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(ResidentOccupancy);

import React from "react";
import { Form } from "react-bootstrap";
import { Translation } from "react-i18next";
import { useSelector } from "react-redux";
import Select from "react-select";

const PropertDetails = ({ selectedPropertyObj, changeProperty, propertyDisabled }) => {
  const allProperties_ = useSelector((state) => state.SuperAdminDetails.getAllProperties);
  const allProperties = allProperties_.length > 0 ? allProperties_.filter((id) => id.is_archived === false) : [];

  const getParentPropertyName = React.useCallback(
    (property) => {
      const parentProperty = allProperties.find((a) => a.id === property.property_id);
      if (parentProperty) {
        if (parentProperty.property_id && property.property_id !== parentProperty.property_id) {
          return `${property.site_name} - ${getParentPropertyName(parentProperty)}`;
        } else {
          return `${property.site_name} - ${parentProperty.site_name}`;
        }
      }
      return property.site_name;
    },
    [allProperties],
  );
  const properties = React.useMemo(() => {
    return (allProperties ?? [])
      .map((a) => ({ ...a, modified_site_name: getParentPropertyName(a) }))
      .map((a) => ({ value: a.id, label: a.modified_site_name }))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }, [allProperties]);
  console.log("disabledaaaa", propertyDisabled);
  return (
    <Form.Group>
      <label>
        <Translation>{(t) => <>{t(`Select Property`)}</>}</Translation>
        <span className="asterisk">*</span>
      </label>
      {console.log("before select", propertyDisabled, selectedPropertyObj)}
      <Select
        value={selectedPropertyObj}
        onChange={changeProperty}
        options={properties}
        placeholder="Select property"
        isDisabled={propertyDisabled}
        name={selectedPropertyObj.label}
      />
    </Form.Group>
  );
};

export default PropertDetails;

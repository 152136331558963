import React, { Component } from "react";
import { Card, Row, Col, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import DataTable from "react-data-table-component";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { connect } from "react-redux";
import { SuperAdminAction, TicketActions } from "../../Actions";
import Loader from "../Loader/Loader";
import { tableHeightScrollBars, findBySearch, scroolToTop } from "../helper";
import "react-datepicker/dist/react-datepicker.css";
import PropertDetails from "../LandingPage/PropertySelection";
import { saveAs } from "file-saver";
import moment from "moment";

class TotalStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PropertyId: this.props.PropertyId,
      totalStatusList: [],
      loader: false,
      searchValue: "",
      api_response: false,
      show_alert: false,
      api_success_message: "",
      api_error_message: "",
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      selectedPropertyObj: {},
      all_properties: [],
      childcheckbox: true,
      exportButtonDisable: true,
      finalTableHeight: 0,
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "Domain",
        width: "150px",
        sortable: true,
        cell: (row) => this.showDomainField(row),
        selector: (row) => row?.domain,
      },
      {
        name: "Ticket Status",
        sortable: true,
        width: "250px",
        cell: (row) => this.showTicketStatusField(row),
        selector: (row) => row?.ticketStatus,
      },
      {
        name: "Tickets #",
        width: "150px",
        sortable: true,
        cell: (row) => this.showTicketCountField(row),
        selector: (row) => row?.tickets,
      },
      {
        name: "Tickets $",
        width: "150px",
        sortable: true,
        cell: (row) => this.showTicketFeeField(row),
        selector: (row) => row?.fee,
      },
    ];
  }

  showTicketFeeField = (row) => (
    <>
      {row?.fee ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${row?.fee}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">${row?.fee}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showTicketCountField = (row) => (
    <>
      {row?.tickets ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.tickets}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row?.tickets}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showTicketStatusField = (row) => (
    <>
      {row?.ticketStatus ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.ticketStatus}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row?.ticketStatus}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showDomainField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.domain}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-3">{row?.domain}</div>
    </OverlayTrigger>
  );

  changeProperty(event) {
    this.setState({
      selectedProperty: event.value,
      selectedPropertyObj: event,
    });
  }

  handleCheckBox = () => {
    this.setState({ childcheckbox: !this.state.childcheckbox });
  };

  componentDidMount = async () => {
    await this.getTickets();
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    this.setState(
      {
        api_response: this.props?.api_response,
        all_properties: this.props?.getAllProperties,
      },
      async () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );

    const height = tableHeightScrollBars();
    this.setState({
      finalTableHeight: height,
    });
  };

  getTickets = async () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, childcheckbox, timezone } = this.state;
      const pageobj = {
        PropertyId,
        timezone,
        childcheckbox,
      };

      await this.props.getReportList(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        totalStatusList:
          this.props?.totalStatusList?.rows && this.props.totalStatusList.rows.length ? JSON.parse(JSON.stringify(this.props.totalStatusList.rows)) : [],
        api_response: this.props.api_response,
        count: this.props.totalStatusList.count,
      });
      this.setState({ loader: false });
    });
  };

  dataTableHandle = (totalStatusList) => {
    return (
      <DataTable
        data={this.state.searchValue.length ? this.state.searchedtransactionsList : totalStatusList}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        onChangePage={scroolToTop}
        fixedHeader={true}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleSearch = () => {
    this.setState({ loader: true }, async () => {
      const { childcheckbox, selectedPropertyObj, PropertyId, timezone } = this.state;
      const pageobj = {
        childcheckbox,
        PropertyId,
        timezone,
      };
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }
      await this.props.getReportList(pageobj);
      this.setState({
        totalStatusList: JSON.parse(JSON.stringify(this.props.totalStatusList.rows)),
        api_response: this.props.api_response,
        count: this.props.totalStatusList.count,
        exportButtonDisable: false,
      });
      this.setState({ loader: false });
      document.body.classList.remove("foc-open");
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();

    this.setState({ searchValue: val }, async () => {
      const columnList = ["ticketStatus", "tickets", "fee"];
      if (this.state.searchValue.length) {
        this.setState(
          {
            searchedtransactionsList: findBySearch(
              this.state.searchValue,
              this.state.totalStatusList,
              this.state.totalStatusList,
              columnList,
              this.props.timezone,
            ),
          },
          () => {
            this.setState({
              count: this.state.searchedtransactionsList.length,
            });
          },
        );
      } else {
        this.setState({
          count: this.props.totalStatusList.count,
          searchedtransactionsList: [],
          totalStatusList: this.props.totalStatusList.rows,
        });
      }
    });
  };

  resetAll = async () => {
    this.setState({
      selectedPropertyObj: {},
      childcheckbox: true,
      exportButtonDisable: true,
    });
    const { PropertyId, selectedDomainName, timezone } = this.state;
    const pageobj = {
      PropertyId,
      timezone,
    };
    await this.props.getReportList(pageobj);
    this.setState({
      totalStatusList: this.props.totalStatusList.rows,
      count: this.props.totalStatusList.count,
      selectedPropertyObj: {
        label: selectedDomainName,
        value: PropertyId,
      },
    });
    this.setState({ loader: false });
    document.body.classList.remove("foc-open");
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleExport = async () => {
    const { childcheckbox, selectedPropertyObj, PropertyId, selectedDomainName, timezone } = this.state;
    const pageobj = {
      childcheckbox,
      PropertyId,
      timezone,
      exportData: true,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    const bufferData = await this.props.getReportList(pageobj);
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `TICKET-STATUS-${currentTimeDate}-${selectedDomainName}.xls`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  render() {
    const { totalStatusList, loader, childcheckbox, searchValue } = this.state;

    return (
      <div>
        <Card className="mb-0">
          <Card.Body className="pos-rel-overflow-hide">
            <>
              <div className="sidebarFilter">
                <Card className="contact-container mb-0">
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title as="h4">Total Status</Card.Title>
                      <a
                        onClick={this.hideSlider}
                        href="javascript:void(0);"
                        className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <label className="fancy-checkbox">
                            <input
                              type="checkbox"
                              name={"childcheckbox"}
                              checked={childcheckbox}
                              onChange={this.handleCheckBox}
                              className="custom-control-input"
                              id="childcheckbox1"
                            ></input>
                            <span>
                              <i></i>Include Children Sites
                            </span>
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-right justify-content-between d-flex">
                    <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                      Reset
                    </Button>
                    <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                      Search
                    </Button>
                  </Card.Footer>
                </Card>
              </div>
              <header>
                <div className="active-permit-header sm-flex-column sm-align-items-start">
                  <h5 className="mb-2 mb-md-0"></h5>
                  <div className="filter-button d-flex flex-wrap">
                    <div className="d-flex align-items-center search-mb-0">
                      <DatatableHeader
                        className="mt-0 mb-0"
                        searchValue={searchValue}
                        HandleChange={this.handleSearchChange}
                        // findMorePressed={this.findMorePressed}
                      />
                    </div>
                    <div className="ml-2">
                      <Button
                        variant="info"
                        className="btn-fill btn-sm"
                        onClick={this.handleExport}
                        disabled={totalStatusList.length > 0 ? 0 : 1}
                        size="medium"
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </header>
            </>
            {loader ? (
              <Loader />
            ) : (
              <>
                {totalStatusList && (
                  <>
                    <div className="citation-list-table has-parent-tab">{this.dataTableHandle(totalStatusList)}</div>
                  </>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, Logindetails, SuperAdminDetails, CurrentPermitDetails, TicketDetails }) => {
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;

  const { backgroundColor } = Logindetails;
  const { getAllProperties } = SuperAdminDetails;
  const { MainBackground: main_body } = backgroundColor;
  const { totalStatusList } = TicketDetails;
  return {
    api_response,
    api_error_message,
    api_success_message,
    PropertyId,
    timezone,
    property,
    totalStatusList,
    main_body,
    getAllProperties,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  get_all_lots: SuperAdminAction.get_all_lots,
  getReportList: TicketActions.getTotalStatusList,
};

export default connect(mapStateToProps, mapStateToDispatch)(TotalStatus);

import React, { useEffect, useState, useRef, useMemo } from "react";
import { Row, Col, Container, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import { getPinGenerate } from "../../Actions/CurrentVisitorPermitActions/actions";
import { SuperAdminAction } from "../../Actions";
import Loader from "../Loader/Loader";
import { routing_using_history } from "../helper";
import useQuickPermit from "../../Hooks/QuickPermit/useQuickPermit";
import { registerEnum } from "../../../utils/selfServeConfig";
import { lightOrDark } from "./common";

const baseUrl = window.location.origin + "/qp";

const SelfRegister = () => {
  const [pin, setPin] = useState("");
  const [age, setPinAge] = useState(-1);
  const [test, setDefault] = useState(false);
  const [instuctions, setInstuctions] = useState("");
  const [showQR, setShowQR] = useState(true);
  const [isTap, setIsTap] = useState(false);
  const [showUrl, setShowUrl] = useState(true);
  const [noQr, setNoQr] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const timeout = useRef();
  const { QuickPermitPinDetail } = useSelector(
    (state) => state.CurrentVisitorPermitDetails
  );
  const { quickPermitData: QuickPermitSetupDetail } = useQuickPermit();
  const getPropertyData = useSelector(
    (state) => state.UserProfileDetails.property
  );
  const getPin = () => dispatch(getPinGenerate());

  useEffect(() => {
    getPin();
  }, []);

  useEffect(() => {
    if (QuickPermitSetupDetail?.instruction) {
      setInstuctions(QuickPermitSetupDetail?.instruction || "");
    }
    if (QuickPermitSetupDetail?.register_on_terminal) {
      setIsTap(
        QuickPermitSetupDetail?.register_on_terminal === registerEnum.tap
      );
      setShowUrl(
        QuickPermitSetupDetail?.register_on_terminal === registerEnum.url
      );
      setNoQr(
        QuickPermitSetupDetail?.register_on_terminal === registerEnum.noQr
      );
      setDefault(true);
    }
  }, [QuickPermitSetupDetail]);

  const clickTime = useRef(0);
  const clickcount = useRef(0);
  const handleTrippleClick = () => {
    if (isTap !== true) return;
    const currTime = new Date().getTime();
    clickcount.current =
      currTime - clickTime.current < 500 ? clickcount.current + 1 : 1;
    clickTime.current = currTime;
    if (clickcount.current === 3) {
      routing_using_history("/qp/" + pin);
    }
  };

  useEffect(() => {
    setShowQR(
      !(
        Object.keys(QuickPermitPinDetail).includes("message") ||
        Object.keys(QuickPermitPinDetail).length === 0
      )
    );
    if (Object.keys(QuickPermitPinDetail)?.length > 0) {
      setPin(QuickPermitPinDetail?.pin);
      setPinAge(QuickPermitPinDetail?.pin_life);
      setLoading(false);
    }
  }, [QuickPermitPinDetail]);

  useEffect(() => {
    if (age !== -1) {
      age === 0 && setPinAge(-1);
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => getPin(), age === 0 ? 1 * 1000 : age);
    }
  }, [pin, age]);

  const qrHeading = useMemo(
    () =>
      noQr
        ? "Register on this device"
        : "Scan the QR code to register a parking permit on your phone",
    [noQr]
  );

  React.useEffect(() => {
    const selectedPropertyName = getPropertyData?.property_name;
    const selectedDomainName = getPropertyData?.domain;
    dispatch(
      SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName)
    );
  }, [dispatch]);
  const getColumn = (ins) => (ins ? "6" : "12");
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title style={{ fontSize: "29px" }}>
                  <b>
                    {QuickPermitSetupDetail?.quick_permit_form_title ||
                      "PARKING PERMIT"}
                  </b>
                </Card.Title>
              </Card.Header>
              <Card.Body className="text-center">
                {showQR ? (
                  <Row>
                    <Istrunctions
                      instuctions={instuctions}
                      menu_background={QuickPermitSetupDetail?.menu_background}
                    />
                    <Col
                      md={getColumn(instuctions)}
                      lg={getColumn(instuctions)}
                      className="scanbarbox"
                    >
                      <Row>
                        <Col>
                          <Form.Group className="mb-2">
                            <label style={{ fontSize: "29px" }}>
                              {qrHeading}
                            </label>
                          </Form.Group>
                          {!noQr && (
                            <div className="my-3 text-center">
                              <div
                                id="my-qr"
                                className="d-inline-block"
                                onClick={handleTrippleClick}
                              >
                                <QRCode
                                  size={150}
                                  value={`${baseUrl}/${pin}`}
                                />
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {(showUrl || noQr) && test === true && (
                            <Form.Group className="mb-3">
                              {!noQr && (
                                <label className="w-100">
                                  <div
                                    className="mt-2 mb-3 fw-bold"
                                    style={{ fontSize: "29px" }}
                                  >
                                    OR
                                  </div>
                                </label>
                              )}
                              <button
                                onClick={() =>
                                  routing_using_history(`/qp/${pin}`)
                                }
                                className="pushable"
                              >
                                <span className="front">
                                  Register parking permit on this device
                                </span>
                              </button>
                            </Form.Group>
                          )}
                          {!(showUrl || noQr) && test === true && (
                            <Form.Group className="mb-3">
                              <label className="w-100">
                                <div
                                  className="mt-2 mb-3 fw-bold"
                                  style={{ fontSize: "29px" }}
                                >
                                  OR
                                </div>
                                <p className="fs-18px mb-2">
                                  Go to link : {baseUrl}
                                </p>
                              </label>
                              <div className="fs-18px">
                                {pin && (
                                  <>
                                    {" "}
                                    PIN: <strong>{pin}</strong>
                                  </>
                                )}
                              </div>
                            </Form.Group>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  "No Quick Permit Lot Exist"
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Istrunctions = ({ instuctions, menu_background }) => {
  return (
    <>
      {instuctions && instuctions.length > 0 && (
        <Col
          md="6"
          className="border-right"
          style={{
            fontSize: "29px",
            fontWeight: "bold",
            backgroundColor: menu_background,
            color:
              lightOrDark(menu_background || "#fff") === "dark"
                ? "#fff"
                : "#000",
          }}
        >
          <Row>
            <Col>
              <div className="text-left fs-25px">
                {instuctions.split("\n").map((item, key) => {
                  return (
                    <>
                      <span className="mb-1 mt-2" key={key}>
                        {item}
                      </span>
                      <br />
                    </>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
};

export default SelfRegister;

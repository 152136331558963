import React from "react";
import { Form } from "react-bootstrap";

const QuickPermitRadioButton = (props) => {
  return (
    <div className="d-flex mb-1">
      <Form.Check
        inline
        label={props.label}
        name={props.name}
        type="radio"
        value={props.name}
        id={props.value}
        onChange={props.onChange}
        checked={props.checked}
      />
    </div>
  );
};

export default QuickPermitRadioButton;

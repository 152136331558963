const types = {
  FETCH_CURRENT_PERMIT: "FETCH_CURRENT_PERMIT",
  FETCH_PERMIT_PACKAGES: "FETCH_PERMIT_PACKAGES",
  REMOVE_CURRENT_PERMIT: "REMOVE_CURRENT_PERMIT",
  ADD_PERMIT: "ADD_PERMIT",
  FETCH_CURRENT_CREDITS: "FETCH_CURRENT_CREDITS",
  FETCH_USED_CREDITS: "FETCH_USED_CREDITS",
  FETCH_CREDITS_ALLOCATED: "FETCH_CREDITS_ALLOCATED",
  FETCH_PAST_CREDITS_ALLOCATED: "FETCH_PAST_CREDITS_ALLOCATED",
  FETCH_CREDITS_30DAYS_REEPORTS: "FETCH_CREDITS_30DAYS_REEPORTS",
  FETCH_DATE_LIST: "FETCH_DATE_LIST",
  FETCH_RESIDENTSCREDITSARR: "FETCH_RESIDENTSCREDITSARR",
  FETCH_PERMIT_ALLOWED_TYPE: "FETCH_PERMIT_ALLOWED_TYPE",
  FETCH_RESIDENTSPASTCREDITSARR: "FETCH_RESIDENTSPASTCREDITSARR",
  FETCH_CREDITS: "FETCH_CREDITS",
  SET_AVAILABLESPOTS: "SET_AVAILABLESPOTS",
  FETCH_RESIDENTS_PERMIT: "FETCH_RESIDENTS_PERMIT",
  REMOVE_SPECIFIC_PERMIT: "REMOVE_SPECIFIC_PERMIT",
  CALL_WHEN_HERE_ACTIVATE_DEACTIVATE: "CALL_WHEN_HERE_ACTIVATE_DEACTIVATE",
  UPDATE_CURRENT_PERMIT: "UPDATE_CURRENT_PERMIT",
  FETCH_TIME_SLOT: "FETCH_TIME_SLOT",
  FETCH_CREDITS_USED_30DAYS: "FETCH_CREDITS_USED_30DAYS",
  FETCH_RESIDENT_LIST: "FETCH_RESIDENT_LIST",
  UNIT_SELECT_LANG: "UNIT_SELECT_LANG",
  FETCH_TODAYS_DATE: "FETCH_TODAYS_DATE",
  FETCH_SINGLE_CREDIT_AVAILABILITY: "FETCH_SINGLE_CREDIT_AVAILABILITY",
  UPDATE_PERMIT: "UPDATE_PERMIT",
  UPDATE_PERMIT_SUCCESS: "UPDATE_PERMIT_SUCCESS",
  UPDATE_PERMIT_ERROR: "UPDATE_PERMIT_ERROR",
  DEACTIVATE_VISITOR: "DEACTIVATE_VISITOR",
  GET_SHARED_LOT: "GET_SHARED_LOT",
  AUTO_RENEW_ACTIVATE_DEACTIVATE: "AUTO_RENEW_ACTIVATE_DEACTIVATE",
  AUTO_CANCEL_ACTIVATE_DEACTIVATE: "AUTO_CANCEL_ACTIVATE_DEACTIVATE",
  CANCEL_PERMIT_AND_REFUND: "CANCEL_PERMIT_AND_REFUND",
  GET_GUEST: "GET_GUEST",
  PASSAGEPASS_REFUND: "PASSAGEPASS_REFUND",
  FETCH_SLOTS: "FETCH_SLOTS",
};

export default types;

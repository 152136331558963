import { AuthAPI } from "../../../Auth/requestHandler";
import apiType from "../APIResponseActions/type";
import t from "./types";

export const plateFinder = (pageobj) => async (dispatch) => {
  try {
    const url =
      "lpr/search/" +
      pageobj.plate_holder +
      "?page_size=" +
      pageobj.Norow +
      "&page=" +
      pageobj.page +
      "&search=" +
      pageobj.searchValue +
      "&order_by=" +
      pageobj.selectedColumn +
      "&sorting=" +
      pageobj.sort_direction;
    // const url = "lpr/search/" + pageobj.plate_holder;
    // const url = "permit/lpn/" + plateNumber;
    const Result = await AuthAPI("domain", url, "GET");
    await dispatch({ type: t.FETCH_PLATE, payload: Result });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "Error retrieving data. Please retry in sometime.",
    });
  }
};

export const FetchingPlate = (pageobj) => async (dispatch) => {
  try {
    const url =
      "lpr/permit_search/" +
      pageobj.plate_holder +
      "?page_size=" +
      pageobj.Norow +
      "&page=" +
      pageobj.page +
      "&search=" +
      pageobj.searchValue +
      "&order_by=" +
      pageobj.selectedColumn +
      "&sorting=" +
      pageobj.sort_direction +
      "&permit_type=" +
      (pageobj.permit_type ?? "") +
      "&unit_id=" +
      (pageobj.unit_id ?? "");

    const Second_Result = await AuthAPI("domain", url, "GET");
    await dispatch({ type: t.FETCH_PERMIT_PLATE, payload: Second_Result });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "Server is facing some issue please try again in sometime",
    });
  }
};

export const FetchingPlateCitations = (lpn) => async (dispatch) => {
  try {
    const url = "ticket/plate_citation/" + lpn;
    const Second_Result = await AuthAPI("domain", url, "GET");
    await dispatch({ type: t.FETCH_PERMIT_PLATE_CITATIONS, payload: Second_Result });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "Server is facing some issue please try again in sometime",
    });
  }
};

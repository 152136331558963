import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { QrReader } from "react-qr-reader";
const cameraFacingMode = { facingMode: "environment" };
class PhotoScanGlass extends Component {
  constructor(_props) {
    super();
    this.state = {
      isOpenCamera: true,
      loader: false,
      showScanningModal: false,
      delay: 100,
      result: "No result",
    };
  }

  componentDidMount = () => {
    const useThis = this;
    document.addEventListener("click", function () {
      useThis.openScanningCamera();
    });
  };

  componentWillUnmount = () => {
    document.removeEventListener("click");
  };

  openScanningCamera = () => {
    this.setState({ isOpenCamera: true });
  };

  handleScan = (data) => {
    this.setState({
      result: data,
    });
  };

  handleError = (err) => {
    console.error(err);
  };

  render() {
    const { isOpenCamera, loader } = this.state;
    return (
      <div>
        <Container className="scan-container">
          <Row>
            <Col lg="12" className="pr-md-1 center scanned-image mb-2 mt-2">
              <>
                <Row className="justify-content-center">
                  <Col lg="6" md="8" sm="12">
                    <div className="scanner-corners scanner-corners-photo-glass">
                      <div className="top left"></div>
                      <div className="top right"></div>
                      <div className="bottom right"></div>
                      <div className="bottom left"></div>
                      {!isOpenCamera && (
                        <img
                          src="https://qrscanneronline.com/images/qrscanneronline.png"
                          alt=""
                          className="image-preview"
                          accept="image/*"
                        />
                      )}
                      {isOpenCamera && (
                        <div>
                          {!loader && (
                            <>
                              <QrReader
                                constraints={cameraFacingMode}
                                onResult={(result, error) => {
                                  if (result) {
                                    window.location = result.text;
                                  }

                                  if (error) {
                                    console.info(
                                      error,
                                      "Nothing to scan. Please open in front of a QA code"
                                    );
                                  }
                                }}
                                style={{ width: "20%", height: "20%" }}
                              />
                            </>
                          )}
                          {loader && (
                            <div className="loader">
                              <Loader />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            </Col>
          </Row>
          <Row className="justify-content-center mb-1">
            {/* <Col lg="6" md="8" sm="12">
              <Button
                onClick={() => this.openScanningCamera()}
                className="text-nowrap mr-2 w-100 scan-plate-button"
              >
                <img src={scan} width="18" />
                <span className="pl-2">Scan QR Code</span>
              </Button>
            </Col> */}
          </Row>
          <div className=" d-flex justify-content-center">
            <div className="qr-code-text">
              <p className="mb-1">
                Kindly scan QR code from photo verify screen on worldstreem.
              </p>
              <a href="">( https://locomobi.worldstream.live/photo-verify )</a>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default PhotoScanGlass;

const superAdminActiontype = {
  LANDINGPAGE: "LANDINGPAGE",
  SELECTEDPROPERTY: "SELECTEDPROPERTY",
  GETALLPROPERTY: "GETALLPROPERTY",
  GETALLPROPERTYPAGINATED: "GETALLPROPERTYPAGINATED",
  CREATEPROPERTY: "CREATEPROPERTY",
  ADDLOT: "ADDLOT",
  DELETE_LOT: "DELETE_LOT",
  GETALLLOTSPAGINATE: "GETALLLOTSPAGINATE",
  GETALLLOTS: "GETALLLOTS",
  ADDLANE: "ADDLANE",
  GETALLLANESPAGINATED: "GETALLLANESPAGINATED",
  ADDCAMERA: "ADDCAMERA",
  FETCHCAMERAS: "FETCHCAMERAS",
  FETCHCAMERALIST: "FETCHCAMERALIST",
  FETCHLANE: "FETCHLANE",
  FETCHUSERLIST: "FETCHUSERLIST",
  UPDATE_PROPERTY_LIST: "UPDATE_PROPERTY_LIST",
  EDIT_LOTS: "EDIT_LOTS",
  EDIT_LANE: "EDIT_LANE",
  DELETE_LANE: "DELETE_LANE",
  ADD_USER: "ADD_USER",
  ADD_NEW_USER: "ADD_NEW_USER",
  EDIT_USER: "EDIT_USER",
  DELETE_USER: "DELETE_USER",
  GET_SETTINGS: "GET_SETTINGS",
  FETCH_USER_LIST_PAGINATE: "FETCH_USER_LIST_PAGINATE",
  UPDATE_SITE_SETTING: "UPDATE_SITE_SETTING",
  UPDATE_LOGIN_CAPTION: "UPDATE_LOGIN_CAPTION",
  UPDATE_SUPPORT: "UPDATE_SUPPORT",
  CREATE_DATE_SLOT: "CREATE_DATE_SLOT",
  FETCHTIMEZONE: "FETCHTIMEZONE",
  SETDOMAINHEADER: "SETDOMAINHEADER",
  GET_PROPERTY_ID: "GET_PROPERTY_ID",
  SETBACKGROUNDCOLOR: "SETBACKGROUNDCOLOR",
  ARCHIVE_PROPERTY_LIST: "ARCHIVE_PROPERTY_LIST",
  RESTORE_PROPERTY_LIST: "RESTORE_PROPERTY_LIST",
  DELETE_PROPERTY: "DELETE_PROPERTY",
  GET_ALL_ARCHIVED_PROPERTY: "GET_ALL_ARCHIVED_PROPERTY",
  GET_ALL_TICKET_TEMPLATE: "GET_ALL_TICKET_TEMPLATE",
  EDIT_TICKET_TEMPLATES: "EDIT_TICKET_TEMPLATES",
  DELETE_TICKET_TEMPLATE: "DELETE_TICKET_TEMPLATE",
  UPDATE_TICKET_TEMPLATE_LIST: "UPDATE_TICKET_TEMPLATE_LIST",
  ALLUSERLIST: "ALLUSERLIST",
  FETCH_PERMISSIONS: "FETCH_PERMISSIONS",
  SET_PERMISSIONS: "SET_PERMISSIONS",
  CLOSE_OPEN_MODEL: "CLOSE_OPEN_MODEL",
  FETCH_USER_PERMISSIONS: "FETCH_USER_PERMISSIONS",
  SET_DROPDOWN_STATE: "SET_DROPDOWN_STATE",
  FETCH_PROCESSORID: "FETCH_PROCESSORID",
  GET_CREDIT: "GET_CREDIT",
  FETCH_ALL_PERMISSIONS: "FETCH_ALL_PERMISSIONS",
  GET_ALL_USER_TYPES: "GET_ALL_USER_TYPES",
  GET_RTSP_DATA: "GET_RTSP_DATA",
  GOOGLE_GLASS: "GOOGLE_GLASS",
  CALL_WHEN_HERE: "CALL_WHEN_HERE",
  EXTENDED_LPN_INFO: "EXTENDED_LPN_INFO",
  SECURITY_EMAIL: "SECURITY_EMAIL",
  PERMIT_DATE_DIFFERENCE: "PERMIT_DATE_DIFFERENCE",
  ACCOUNT_REFERENCE: "ACCOUNT_REFERENCE",
  MONTHLY_REFERENCE: "MONTHLY_REFERENCE",
  HOURLY_REFERENCE: "HOURLY_REFERENCE",
  HARMONIZED_SALES_TAX: "HARMONIZED_SALES_TAX",
  HST_PERCENTAGE: "HST_PERCENTAGE",
  SESSION_TIME: "SESSION_TIME",
  ACTIVITY_LOGS: "ACTIVITY_LOGS",
  GETRTSPDATA: "GETRTSPDATA",
  GET_RECEIPT_PDF_DATA: "GET_RECEIPT_PDF_DATA",
  MOBILE_VIEW: "MOBILE_VIEW",
  FETCHCASHIER: "FETCH_CASHIER",
  EDITCASHIER: "EDIT_CASHIER",
  DELETECASHIER: "DELETE_CASHIER",
  CREATECASHIER: "CREATE_CASHIER",
  EXISTCASHIER: "EXIST_CASHIER",
};

export default superAdminActiontype;

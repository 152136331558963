import React, { useEffect, useMemo } from "react";
import { Row, Col, Container, Card, Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as SelfRegisterActions from "../../Actions/SelfRegisterActions/actions";
import AddQuickPermit from "./AddQuickPermit";
import { useParams } from "react-router-dom";
import { getDomain, routing_using_history } from "../helper";
import { getSelfRegisterDetails } from "../../Selectors/SelfRegisterDetails";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import Loader from "../Loader/Loader";
import SelfRegisterHeader from "./SelfRegisterHeader";

const RegisterWithPhone = (propsData) => {
  const country_code_list = [
    { id: 1, text: "+1", value: "+1" },
    { id: 2, text: "+91", value: "+91" },
    { id: 3, text: "+44", value: "+44" },
    { id: 4, text: "+61", value: "+61" },
  ];
  const [memberId, setMemberId] = React.useState();
  const [phone, setPhone] = React.useState();
  const [country_code, setCountry_code] = React.useState("+1");
  const [showForm, setShowForm] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [props, setProps] = React.useState({});
  const [disable, setDisable] = React.useState(true);
  const [isApiCalled, setIsApiCalled] = React.useState(false);
  const [startQuickSetup, setStartQuickSetup] = React.useState(true);
  const { property_id, quickPermitSetupDetail, isPinValid } = useSelector(getSelfRegisterDetails);
  const apiResponse = useSelector(getAPIResponse);
  const [validation, setValidation] = React.useState(false);

  const params = useParams();
  const dispatch = useDispatch();

  useEffect(async () => {
    setLoading(true);
    dispatch(SelfRegisterActions.validatePin(getDomain(), params.pin));
    setIsApiCalled(true);
  }, []);

  useEffect(() => {
    if (property_id && isApiCalled) {
      setIsApiCalled(false);
      if (isPinValid === 1) {
        setStartQuickSetup(false);
        dispatch(SelfRegisterActions.getQuickPermitSetup(property_id));
      } else {
        routing_using_history(`/scan-again`);
      }
    }
  }, [apiResponse]);

  useEffect(() => {
    if (startQuickSetup === false && Object.keys(quickPermitSetupDetail).length > 0 && apiResponse.api_response === true) {
      setLoading(false);
    }
  }, [quickPermitSetupDetail]);

  const handleChange = React.useCallback(
    (e) => {
      if (e.target.name === "memberId") {
        let valueMember;
        switch (quickPermitSetupDetail.id_validation) {
          case "alphanumeric":
            valueMember = e.target.value.replace(/[^a-z0-9]/gi, "");
            break;
          case "alphabets":
            valueMember = e.target.value.replace(/[^a-zA-Z]/g, "");
            break;
          case "numeric":
            valueMember = e.target.value.replace(/\D/gi, "");
            break;
          default:
            break;
        }
        setValidation(true);
        setMemberId(valueMember);
        setDisable(!(valueMember.length >= quickPermitSetupDetail.id_min_char && valueMember.length <= quickPermitSetupDetail.id_max_char));
      }
      if (e.target.name === "phone") {
        const valuePhone = e.target.value.replace(/\D/gi, "");
        setPhone(valuePhone);
        setDisable(valuePhone.length !== 10);
      }
      if (e.target.name === "country_code") {
        setCountry_code(e.target.value);
      }
    },
    [memberId, phone, quickPermitSetupDetail, country_code],
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const properties = {
      country_code: country_code,
      phone: phone,
      memberId: memberId,
      property_id: quickPermitSetupDetail.property_id,
      lot_id: quickPermitSetupDetail.lot_id,
      timezone: quickPermitSetupDetail.timezone,
      propsData: propsData,
    };
    setShowForm(true);
    setProps(properties);
  };

  const propmptName = useMemo(() => {
    let msg = "";
    switch (quickPermitSetupDetail?.id_validation) {
      case "alphabets":
        msg = "letter Only";
        break;
      case "alphanumeric":
        msg = "numbers and letters";
        break;
      case "numeric":
        msg = "number Only";
        break;
      default:
        break;
    }
    return msg;
  }, [quickPermitSetupDetail?.id_validation]);

  return (
    <div>
      {loading ? (
        <Loader center={true} />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <SelfRegisterHeader />
                <Card.Body>
                  {!showForm && (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        {!loading && (
                          <Col xl="6" lg="8" md="8" sm="12">
                            {quickPermitSetupDetail?.show_id === 1 && (
                              <Form.Group className="mb-3">
                                <label style={{ fontSize: "15px" }}>{quickPermitSetupDetail?.name_of_id || "Member ID"}</label>
                                <span className="fs-12px"> ({propmptName})</span>
                                <span className="asterisk">*</span>
                                <Form.Control type="text" value={memberId} name="memberId" onChange={handleChange}></Form.Control>
                                {disable && validation && (
                                  <div className="help-block">
                                    {quickPermitSetupDetail?.name_of_id || "Member ID"} must be :{" "}
                                    <span className="text-capitalize">{quickPermitSetupDetail?.id_validation}</span>
                                    <br />
                                    Min length : {quickPermitSetupDetail?.id_min_char}
                                    <br />
                                    Max length : {quickPermitSetupDetail?.id_max_char}
                                    <br />
                                  </div>
                                )}
                              </Form.Group>
                            )}
                            {quickPermitSetupDetail?.show_id === 0 && (
                              <>
                                <Row>
                                  <Col className="col-auto">
                                    <Form.Group className="mb-3">
                                      <label>Country Code:</label>
                                      <Form.Control as="select" name="country_code" onChange={handleChange} value={country_code}>
                                        {country_code_list ? (
                                          <>
                                            {(country_code_list ?? []).map((item) => (
                                              <option key={item?.id} value={item.value}>
                                                {item.text}
                                              </option>
                                            ))}
                                          </>
                                        ) : null}
                                      </Form.Control>
                                    </Form.Group>
                                  </Col>
                                  <Col className="">
                                    <Form.Group className="mb-3">
                                      <label style={{ fontSize: "15px" }}>
                                        Phone<span className="asterisk">*</span>
                                      </label>
                                      <Form.Control type="text" maxLength={"10"} value={phone} name="phone" onChange={handleChange}></Form.Control>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col xl="6" lg="8" md="8" sm="12" style={{}}>
                          <Form.Group className="mb-3">
                            <Button name="submit" type="submit" disabled={disable}>
                              NEXT
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </form>
                  )}
                  {showForm && <AddQuickPermit props={props} />}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default RegisterWithPhone;

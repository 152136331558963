import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class DeleteResidentModal extends Component {
  render() {
    const { showBrowserModal, closeModal, details, handleSave, handleCancel } =
      this.props;
    return (
      <Modal lg={"medium"} show={showBrowserModal} onHide={closeModal}>
        <Modal.Header className="no-border">
          <h4 className="m-0 font-weight-bold">{details.title}</h4>
          <Button
            title={"Warninig"}
            type="button"
            onClick={closeModal}
            className="close-btn"
            data-dismiss="modal"
          >
            <i className="nc-icon nc-simple-remove"></i>
          </Button>
        </Modal.Header>

        <Modal.Body className="modal-data-body">
          {/* <p className="theme-color">This API has failed.</p> */}
          <p className="theme-color">{details.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCancel}
            type="button"
            className="btn btn-fill btn-danger"
          >
            Cancel
          </Button>
          <Button
            style={{ minWidth: "93px" }}
            onClick={handleSave}
            type="button"
            className="btn btn-fill btn-info"
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeleteResidentModal;

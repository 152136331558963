/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import { Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { UserProfileActions, SuperAdminAction } from "../../Actions";
import LogoutModal from "../Modal/LogoutModal";
import CustomContentAlert, { routing_using_history } from "../helper";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropertyModal from "../Modal/PropertyModal.jsx";
import DropDownReport from "./SidebarReport.jsx";
import _ from "lodash";
import Loader from "../Loader/Loader";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isSidebarHidden: false,
      footer_logo: "",
      header_logo: "",
      main_body: "",
      main_body_background: "",
      menu_background: "",
      menu_header: "",
      permissions: [],
      route: [],
      reportRoutes: [],
      user_type: "",
      isDropdownClose: false,
      navigateUrl: "",
      showHideSideBarClass: "",
    };
  }

  componentDidMount = async () => {
    await this.props.getBrandingData();
    const { MainBackground, mainBodyBackground, menuBackground, menuHeader, headerLogo, footerLogo, userType } = this.props.backgroundColor;
    this.setState({
      footer_logo: this.props.footer_logo ? this.props.footer_logo : footerLogo,
      header_logo: this.props.header_logo ? this.props.header_logo : headerLogo,
      main_body: this.props.main_body ? this.props.main_body : MainBackground,
      main_body_background: this.props.main_body_background ? this.props.main_body_background : mainBodyBackground,
      menu_background: this.props.menu_background ? this.props.menu_background : menuBackground,
      menu_header: this.props.menu_header ? this.props.menu_header : menuHeader,
      admin_login: this.props.admin_login,
      user_type: userType,
    });
    if (userType === "admin" || userType === "superadmin") {
      await this.props.propertyData();
      if (this.props.api_response) {
        this.setState({
          userPropertyData: this.props.userPropertyData,
          show_alert: true,
          api_success_message: "Email successfully sent.",
        });
      }
    }
    await this.props.getPermission();
    if (this.props.api_response) {
      this.setPermission();
    } else {
      localStorage.removeItem("basicAuth");
      localStorage.clear();
      this.setCrossSubdomainCookie("basicAuth", null, -1);
      localStorage.removeItem("basicRefreshAuth");
      this.setCrossSubdomainCookie("basicRefreshAuth", null, -1);
      const logoutUrl = "/";
      window.location.href = logoutUrl;
      console.log("component did mount::::::::::::::::::::");
    }
    const showHideSideBarClass = this.props.isSidebarHidden ? " collapsed" : "";
    this.setState({
      isSidebarHidden: this.props.isSidebarHidden,
      showHideSideBarClass,
    });
  };

  setCrossSubdomainCookie(name, value, days) {
    try {
      const assign = name + "=" + escape(value) + ";";
      const d = new Date();
      d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = "expires=" + d.toUTCString() + ";";
      const path = "path=/;";
      const documentDomain = document.domain.match(/[^.]*\.[^.]*$/) || "";
      const domain = "domain=" + documentDomain + ";";
      document.cookie = assign + expires + path + domain;
    } catch (e) {
      console.log(e);
    }
  }

  setPermission = () => {
    const newModifiedPermissionList = [];
    this.props.allPermissions.forEach((element) => {
      newModifiedPermissionList.push(element.toLowerCase().replace(/[^a-z]/g, ""));
    });
    let setup = "";
    if (this.props.admin_login && !this.props.isSemiLogin) {
      setup = "SITE SETUP";
    }
    newModifiedPermissionList.push("DASHBOARD", "CONTACT SUPPORT", "MY ACCOUNT", "LOGOUT", setup);
    const latestRoute = this.getModifiedPermissionList(this.props.routes, newModifiedPermissionList);

    if (this.state.route.length && !_.isEqual(latestRoute, this.state.route)) {
      // when permimssions for main menu will get updated this part will run
      window.location.reload();
    } else {
      this.setState({
        route: latestRoute,
      });
    }
  };

  getModifiedPermissionList = (routes, newModifiedPermissionList) => {
    const latestRoute = [];
    let reportRoutes = [];
    let modifiedPermissionList = [];
    let subMenuReport = [];
    modifiedPermissionList = newModifiedPermissionList.filter(
      (item) =>
        !item
          .toLowerCase()
          .replace(/[^a-z]/g, "")
          .includes("report"),
    );
    const mainMenuReport = newModifiedPermissionList.filter((item) => item.toLowerCase().replace(/[^a-z]/g, "") === "report");
    modifiedPermissionList = modifiedPermissionList.concat(mainMenuReport);
    const tempSubMenuReport = newModifiedPermissionList.filter((itemName) =>
      itemName
        .toLowerCase()
        .replace(/[^a-z]/g, "")
        .includes("report"),
    );
    subMenuReport = tempSubMenuReport.filter((_itemName) => _itemName.toLowerCase().replace(/[^a-z]/g, "") !== "report");
    if (this.props.property_id && this.props.permission_id === 1) {
      // This is for usertype, which is for only superadmin with property id null
      modifiedPermissionList = modifiedPermissionList.filter((item) => item !== "usertypes");
    }
    if (!this.props.property_id && this.props.permission_id === 1) {
      modifiedPermissionList.push("activitylogsreport");
    }
    for (const item of routes) {
      // this loop is for filtering the route list according to permissions
      const isExits = modifiedPermissionList.find((name) => name.toLowerCase().replace(/[^a-z]/g, "") === item.name.toLowerCase().replace(/[^a-z]/g, ""));

      const isReportExist = subMenuReport.find((name) => name.toLowerCase().replace(/[^a-z]/g, "") === item.name.toLowerCase().replace(/[^a-z]/g, ""));
      if (modifiedPermissionList.find((name) => name.toLowerCase().replace(/[^a-z]/g, "") === "report")) {
        const reportRouteArray = this.setReportRoutes(isReportExist, item) || [];
        reportRoutes = [...reportRoutes, ...reportRouteArray];
      }
      if (isExits) {
        // For main pages
        latestRoute.push(item);
      }
    }
    if (this.state.reportRoutes.length && !_.isEqual(this.state.reportRoutes, reportRoutes)) {
      // when permimssions for report will get updated this part will run
      window.location.reload();
    } else {
      this.setState({
        reportRoutes: reportRoutes,
      });
    }
    return latestRoute;
  };

  setReportRoutes = (isReportExist, item) => {
    const reportRoutes = [];
    if (isReportExist) {
      reportRoutes.push(item);
      return reportRoutes;
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.backgroundColor !== this.props.backgroundColor) {
      const { MainBackground, mainBodyBackground, menuBackground, menuHeader, headerLogo, footerLogo } = this.props.backgroundColor;
      this.setState({
        footer_logo: footerLogo,
        header_logo: headerLogo,
        main_body: MainBackground,
        main_body_background: mainBodyBackground,
        menu_background: menuBackground,
        menu_header: menuHeader,
      });
    }
    if (prevProps.isSidebarHidden !== this.props.isSidebarHidden) {
      const showHideSideBarClass = this.props.isSidebarHidden ? " collapsed" : "";
      this.setState({
        isSidebarHidden: this.props.isSidebarHidden,
        showHideSideBarClass,
      });
    }
  };

  isSubdomain = (url) => {
    const splittedUrl = url.split(".");
    return splittedUrl[splittedUrl.length - 1];
  };

  handleClick = async (name) => {
    if (window.innerWidth <= 990) {
      this.handleClose();
    }
    await this.props.getPermission();
    if (this.props.api_response) {
      this.setPermission();
    }

    if (name === "Logout") {
      const session_data = {
        user_id: this.props.id,
        property_id: this.props.property_id,
      };
      await this.props.checkStartedOfflineSession(session_data);
      this.setState({ isModalOpen: !this.state.isModalOpen });
    } else if (name === "Site Setup") {
      const TLD = this.isSubdomain(window.location.hostname);
      const { protocol, port } = window.location;

      this.setState(
        {
          navigateUrl: `${protocol}//worldstream.${TLD}:${port}/landingpage/${this.props.property_id}`,
        },
        () => {
          if (isMobile || isTablet) {
            window.location = this.state.navigateUrl;
          } else {
            document.getElementById("navigator").click();
          }
        },
      );
    } else {
      if (isMobile || isTablet) {
        this.handleClose();
        this.props.handleSidebar(this.state.isSidebarHidden);
      }
      if (name !== "Verify" && this.props.dropDownState === true) {
        this.setState({
          isDropdownClose: true,
        });
      } else {
        this.setState({
          isDropdownClose: false,
        });
      }
      if (this.props.api_response) {
        routing_using_history(name);
      } else {
        this.setState({ logoutUrl: "unautorized" });
        const logoutElement = document.getElementById("logout");
        if (logoutElement !== null) {
          logoutElement.click();
        } else {
          console.log("element not found.");
        }
      }
    }
  };

  handleClose = () => {
    this.setState({ isSidebarHidden: false }, () => {
      this.props.handleSidebar(false);
    });
  };

  sideBarTitleShow = (name) => {
    if (name === "TICKET") {
      return "Citation";
    } else if (name === "VALIDATION COUPON") {
      return "Parking Discount Coupons";
    } else if (name === "PASSAGE PASS") {
      return "PARKING";
    } else {
      return name;
    }
  };

  render() {
    const { isModalOpen, header_logo, admin_login, user_type, route: routes, reportRoutes, navigateUrl, showHideSideBarClass } = this.state;
    const { image, color, showPropertyModal, showHidePropertyModal, backgroundColor } = this.props;
    const { menuBackground, menuHeader } = backgroundColor;
    const isUnitUserWithId = user_type === "unit" && this.props.id;
    const hasUserPropertyDataWithId = this.props.userPropertyData.length > 0 && this.props.id;
    const result = isUnitUserWithId || hasUserPropertyDataWithId;
    return (
      <>
        {this.state.showAlert && <CustomContentAlert delay={2000} message={this.props.api_error_message} className="toast-error bottom" />}
        <div className={`${"sidebar" + showHideSideBarClass}`} data-image={image} data-color={color}>
          <div
            className="sidebar-background"
            style={{ backgroundColor: menuBackground }} // sidebar background color
          />
          {result ? (
            <>
              <PerfectScrollbar>
                <div className="sidebar-wrapper">
                  <div
                    className="logo d-flex align-items-center justify-content-start"
                    style={{
                      backgroundColor: menuHeader,
                    }} // sidebar header logo background
                  >
                    <div className="logo-img">
                      <img
                        src={this.props.header_logo || header_logo || require("../../../assets/img/WorldSteamBox.svg").default}
                        alt="..."
                        onClick={user_type === "admin" || admin_login ? () => showHidePropertyModal(true) : null}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <button className="d-lg-none btn-close-nav" onClick={this.handleClose}>
                      <i className="far fa-times-circle" />{" "}
                    </button>
                  </div>
                  <Nav variant="pills" activeKey="1" className="sidebarnav ml-0">
                    {routes.map((prop) => {
                      if (!prop.redirect) {
                        return (
                          <>
                            {prop.name === "Report" ? (
                              <DropDownReport
                                key={prop.name}
                                {...prop}
                                handleClick={this.handleClick}
                                color={menuBackground}
                                percent={20}
                                permissions={reportRoutes}
                              />
                            ) : (
                              <li key={prop.name}>
                                <NavLink
                                  to={prop.name === "Logout" || prop.name === "Site Setup" ? "" : prop.path}
                                  className={prop.name === "Logout" || prop.name === "Site Setup" ? "nav-link hide_active ml-0" : "nav-link ml-0"}
                                  onClick={(e) => {
                                    isMobile && this.handleClose();
                                    e.preventDefault();
                                    this.handleClick(prop.name === "Logout" || prop.name === "Site Setup" ? prop.name : prop.path);
                                  }}
                                >
                                  <i className={prop.name === "Site Setup" ? "fa fa-cog" : prop.icon} />
                                  <p>{this.sideBarTitleShow(prop.name)}</p>
                                </NavLink>
                              </li>
                            )}
                          </>
                        );
                      }
                      return null;
                    })}
                  </Nav>

                  <a id="navigator" className={"top-header--title"} href={navigateUrl} target="_blank" rel="noreferrer" />
                  <a id="logout" className={"top-header--title"} href={this.state.logoutUrl} target="_self" rel="noreferrer" />
                </div>
              </PerfectScrollbar>
            </>
          ) : (
            <>
              <Loader />
            </>
          )}
        </div>
        {isModalOpen ? (
          <LogoutModal
            showBrowserModal={isModalOpen}
            closeModal={() => this.setState({ isModalOpen: !this.state.isModalOpen })}
            userLogout={this.props.userLogout}
            userid={this.props.id}
            permission_id={this.props.permission_id}
            highSecureProperty={this.props.property?.high_security}
            property_id={this.props.property_id}
            login_id={this.props.login_id}
            user_offline={this.props.user_offline}
          />
        ) : null}
        {showPropertyModal && (
          <PropertyModal showPropertyModal={showPropertyModal} userPropertyData={this.state.userPropertyData} closeModal={() => showHidePropertyModal(false)} />
        )}
      </>
    );
  }
}
const mapStateToProps = ({ SuperAdminDetails, UserProfileDetails, Logindetails, APIResponseDetails }) => {
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { backgroundColor, userModulePermissions, dropDownState, permissions: allPermissions } = SuperAdminDetails;
  const { userPropertyData, showPropertyModal, changePassword, high_security } = Logindetails;

  const {
    footer_logo,
    header_logo,
    main_body,
    main_body_background,
    menu_background,
    menu_header,
    admin_login,
    user_type,
    property_id,
    property,
    user_property_id,
    permission_id,
    id,
    login_id,
    user_offline,
  } = UserProfileDetails;
  return {
    permission_id,
    allPermissions,
    dropDownState,
    backgroundColor,
    footer_logo,
    header_logo,
    main_body,
    main_body_background,
    menu_background,
    menu_header,
    admin_login,
    property_id,
    showPropertyModal,
    userPropertyData,
    api_response,
    api_success_message,
    api_error_message,
    user_type,
    userModulePermissions,
    property,
    user_property_id,
    id,
    high_security,
    changePassword,
    login_id,
    user_offline,
  };
};
const mapStateToDispatch = {
  userLogout: UserProfileActions.userLogout,
  checkStartedOfflineSession: UserProfileActions.checkStartedOfflineSession,
  propertyData: UserProfileActions.propertyData,
  get_user_permissions: SuperAdminAction.get_user_permissions,
  getPermission: SuperAdminAction.getPermission,
  checkLastPasswordChangedDate: SuperAdminAction.checkLastPasswordChangedDate,
  toggleSidebar: SuperAdminAction.toggleSidebar,
  showHidePropertyModal: UserProfileActions.showHidePropertyModal,
  getBrandingData: UserProfileActions.getBrandingData,
};

export default connect(mapStateToProps, mapStateToDispatch)(Sidebar);

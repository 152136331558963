import React from "react";
import {
  Modal,
  Card,
  Button,
  ModalBody,
  Form,
  // Row,
  // Col,
} from "react-bootstrap";
import {
  useDispatch, // useSelector
} from "react-redux";
import _ from "lodash";
import { SuperAdminAction } from "../../Actions";
import PerfectScrollbar from "react-perfect-scrollbar";
import { phoneValidation } from "../helper";
import { useLocation } from "react-router";

const SetPermission = ({ modelOpenClose, handleClose, propertyId, highSecurity, handleUserPermission, userDataToBeEdited, userTypes }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [
    pages, // setPages
  ] = React.useState();
  const [isEdit, setEdit] = React.useState(false);
  const [validation, setValidation] = React.useState({
    Email: "",
    Firstname: "",
    Lastname: "",
    Phone: "",
    userType: "",
    password: "",
  });
  const [validation1, setValidation1] = React.useState({
    isemail: true,
    isfirstName: true,
    islastName: true,
    isphone: true,
    ispassword: true,
  });
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [state, setState] = React.useState({
    Email: "",
    Firstname: "",
    Lastname: "",
    Username: "",
    country_code: "",
    Phone: "",
    password: "",
    userType: "",
    userSubType: "",
    allUserType: [],
    allUserSubType: [],
    contentvisible: false,
    country_code_list: [
      { id: 1, text: "+1", value: "+1" },
      { id: 2, text: "+91", value: "+91" },
      { id: 3, text: "+44", value: "+44" },
      { id: 4, text: "+61", value: "+61" },
    ],
  });

  React.useEffect(() => {
    const allTypes = [];
    const allUserSubTypes = [];
    const filterData = userTypes.filter((item) => item.user_sub_type && state.userType === item.user_type);
    if (filterData?.length > 0) {
      for (const i of filterData) {
        allUserSubTypes.push(i);
      }
    }

    for (const item of userTypes) {
      const data = allTypes.filter((i) => i.user_type === item.user_type);
      if (!data.length && item.permission_id !== 2) {
        allTypes.push(item);
      }
    }
    setState((prevState) => ({
      ...prevState,
      allUserType: allTypes,
      allUserSubType: allUserSubTypes,
    }));
  }, [userTypes, state.userType, state.userSubType]);

  React.useEffect(() => {
    let defaultValue = "";
    let defaultSubValue = "";
    if (state.allUserType && state.allUserType.length > 0) {
      for (const type of state.allUserType) {
        if (type.permission_id === 3) {
          defaultValue = type.user_type;
        }
      }
    }
    if (state.allUserSubType && state.allUserSubType.length > 0) {
      for (const subType of state.allUserSubType) {
        if (subType.permission_id === 3) {
          defaultSubValue = subType.user_sub_type;
        }
      }
    }
    setState((prevState) => ({
      ...prevState,
      userType: state.userType ? state.userType : defaultValue,
      userSubType: state.userSubType ? state.userSubType : defaultSubValue,
    }));
  }, [state.userType, state.userSubType, state.allUserType, state.allUserSubType]);

  React.useEffect(() => {
    dispatch(SuperAdminAction.get_all_properties());
    if (propertyId) {
      dispatch(SuperAdminAction.get_permissions(propertyId));
    }
  }, [dispatch, propertyId]);

  React.useEffect(() => {
    if (state.allUserSubType && !state.allUserSubType.length) {
      setState((prevState) => ({
        ...prevState,
        userSubType: "",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        userSubType: state.userSubType || state?.allUserSubType?.[0]?.user_sub_type,
      }));
    }
  }, [state.allUserSubType]);

  React.useEffect(() => {
    if (!_.isEmpty(userDataToBeEdited)) {
      setState({
        Email: userDataToBeEdited.email,
        Firstname: userDataToBeEdited.first_name,
        Lastname: userDataToBeEdited.last_name,
        country_code: userDataToBeEdited.country_code,
        Phone: userDataToBeEdited.phone,
        userType: userDataToBeEdited.user_type,
        userSubType: userDataToBeEdited.user_sub_type,
        Username: userDataToBeEdited.username,
        password: userDataToBeEdited.password,
        id: userDataToBeEdited.id,
        country_code_list: [
          { id: 1, text: "+1", value: "+1" },
          { id: 2, text: "+91", value: "+91" },
          { id: 3, text: "+44", value: "+44" },
          { id: 4, text: "+61", value: "+61" },
        ],
      });
      setIsFormValid(true);
      setValidation1({
        isemail: false,
        isfirstName: false,
        islastName: false,
        isphone: false,
        ispassword: false,
      });
      setEdit(true);
    } else {
      setState({
        Email: "",
        Firstname: "",
        Lastname: "",
        country_code: "",
        Phone: "",
        password: "",
        Username: "",
        country_code_list: [
          { id: 1, text: "+1", value: "+1" },
          { id: 2, text: "+91", value: "+91" },
          { id: 3, text: "+44", value: "+44" },
          { id: 4, text: "+61", value: "+61" },
        ],
      });
    }
  }, [userDataToBeEdited]);
  const handleChange = React.useCallback((e) => {
    if (e.target.name === "Phone") {
      if (e.target.value.length < 11) {
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value.replace(/[^0-9]/g, ""),
        }));
      }
    } else if (e.target.name === "country_code") {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value.replace(/[a-zA-Z]/, ""),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
    if (e.target.name !== "country_code") {
      setValidations(e);
    }
  }, []);

  React.useEffect(() => {
    const Phone = state.Phone;
    const Password = state.password;
    if (isEdit) {
      setIsFormValid(false);
    }
    if (state.Email !== "" && state.Firstname !== "" && state.Lastname !== "" && Phone !== "" && Phone.length === 10 && validation.password === "" && !isEdit) {
      setIsFormValid(true);
    } else if (
      state.Email !== "" &&
      state.Firstname !== "" &&
      state.Lastname !== "" &&
      state.Username !== "" &&
      Phone !== "" &&
      Phone.length === 10 &&
      isEdit &&
      validation.password === ""
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }

    if (validation.password === "" && highSecurity && !_.isEmpty(Password)) {
      setIsFormValid(true);
    } else if (validation.password !== "" && highSecurity) {
      setIsFormValid(false);
    }
    if (validation.password !== "" && !highSecurity) {
      setIsFormValid(true);
    }
    if (
      state.Email === userDataToBeEdited.email &&
      state.Firstname === userDataToBeEdited.first_name &&
      state.Lastname === userDataToBeEdited.last_name &&
      state.Username === userDataToBeEdited.username &&
      Phone === userDataToBeEdited.phone &&
      state.userType === userDataToBeEdited.user_type &&
      ((!state.userSubType && !userDataToBeEdited.user_sub_type) || state.userSubType === userDataToBeEdited.user_sub_type) &&
      !state.password
    ) {
      setIsFormValid(false);
    }
  }, [state.Firstname, state.Lastname, state.Username, state.Phone, state.Email, isEdit, state.password, state.userType, state.userSubType]);

  const setValidations = React.useCallback(
    (e) => {
      if (!e.target.value) {
        console.log("no value:::::::");
        if (e.target.name === "password") {
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
          console.log("password", validation1.ispassword);
        } else {
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.name + " is required",
          }));
          setIsFormValid(true);
        }
      } else {
        console.log("with value:::::::", e.target.value);
        if (e.target.name === "Lastname") {
          setValidation1((prevState) => ({
            ...prevState,
            islastName: false,
          }));
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
        } else if (e.target.name === "Firstname") {
          setValidation1((prevState) => ({
            ...prevState,
            isfirstName: false,
          }));
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
        } else if (e.target.name === "Email") {
          const regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value && !regex.test(e.target.value) ? "Please enter valid Email" : "",
          }));
          if (e.target.value && regex.test(e.target.value)) {
            setValidation1((prevState) => ({
              ...prevState,
              isemail: false,
            }));
          } else {
            setValidation1((prevState) => ({
              ...prevState,
              isemail: true,
            }));
          }
        } else if (e.target.name === "Phone" && e.target.value.length < 11) {
          const errMsg = phoneValidation({ phone: e.target.value });
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: errMsg,
          }));

          if (e.target.value && errMsg === "") {
            setValidation1((prevState) => ({
              ...prevState,
              isphone: false,
            }));
          } else {
            setValidation1((prevState) => ({
              ...prevState,
              isphone: true,
            }));
          }
        } else if (e.target.name === "password") {
          setValidation1((prevState) => ({
            ...prevState,
            ispassword: false,
          }));
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
        } else {
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
        }
      }
    },
    [validation],
  );

  const togglePassword = () => {
    setState((prev) => ({
      ...prev,
      contentvisible: !state.contentvisible,
    }));
  };

  const handleSave = React.useCallback(() => {
    handleUserPermission(pages, state, isEdit);
  }, [state, pages]);

  return (
    <div>
      <Modal lg={"medium"} show={modelOpenClose} backdrop={"static"} onHide={handleClose} dialogClassName="modal-90w" fullscreen={"sm-down"}>
        <Card.Header className="d-flex justify-content-between bg-white align-items-center">
          <h4 className="m-0 font-weight-bold">
            <Card.Title className="m-0">
              {isEdit && "Edit"}
              {!isEdit && "Add"} {location.pathname === "/manage-admin" ? "Admin" : "User"}
            </Card.Title>
          </h4>
          <Button title={"Deactivate"} type="button" onClick={handleClose} className="close-btn py-0" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove text-danger" />
          </Button>
        </Card.Header>
        <PerfectScrollbar style={{ height: "70vh" }}>
          <ModalBody>
            <Form.Group>
              <label>
                Email <span className="asterisk">*</span>
              </label>
              <Form.Control
                placeholder="email"
                type="email"
                name="Email"
                value={state?.Email}
                onChange={handleChange}
                autoComplete="off"
                required
                disabled={isEdit}
              />
              <div className="help-block">{!_.isEmpty(validation) ? validation.Email : ""}</div>
              <label>
                First Name <span className="asterisk">*</span>
              </label>
              <Form.Control
                placeholder="First Name"
                type="text"
                name="Firstname"
                value={state?.Firstname}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <div className="help-block">{!_.isEmpty(validation) ? validation.Firstname : ""}</div>
              <label>
                Last Name <span className="asterisk">*</span>
              </label>
              <Form.Control placeholder="Last Name" type="text" name="Lastname" value={state?.Lastname} onChange={handleChange} autoComplete="off" required />
              <div className="help-block">{!_.isEmpty(validation) ? validation.Lastname : ""}</div>
              {isEdit && (
                <>
                  <label>
                    User Name <span className="asterisk">*</span>
                  </label>
                  <Form.Control
                    placeholder="User Name"
                    type="text"
                    name="Username"
                    value={state?.Username}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                  <div className="help-block">{!_.isEmpty(validation) ? validation.Username : ""}</div>
                </>
              )}
              <label>Country Code</label>
              <Form.Control as="select" name="country_code" onChange={handleChange} value={state?.country_code}>
                {state.country_code_list ? (
                  <>
                    {(state.country_code_list ?? []).map((item) => (
                      <option key={item?.id} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </>
                ) : null}
              </Form.Control>
              {/* <div className="help-block">
                {!_.isEmpty(validation) ? validation.country_code : ""}
              </div> */}
              <label>
                Phone <span className="asterisk">*</span>
              </label>
              <Form.Control placeholder="phone" type="text" name="Phone" value={state?.Phone} onChange={handleChange} autoComplete="off" required />
              <div className="help-block">{!_.isEmpty(validation) ? validation.Phone : ""}</div>
              {(!highSecurity && (
                <div>
                  <label className="field-lbl">Password</label>
                  <div className="input-mask-btn">
                    <Form.Control
                      placeholder="password"
                      type={state.contentvisible ? "text" : "password"}
                      className="form-control"
                      name="password"
                      value={state?.password}
                      onChange={handleChange}
                      autoComplete="new-password"
                      required
                    />
                    <button className="mask-password" type="button" onClick={togglePassword}>
                      {state.contentvisible ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                    </button>
                  </div>
                  <div className="help-block">
                    {!_.isEmpty(validation) ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: validation.password,
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )) ||
                ""}
              <label>
                User Type <span className="asterisk">*</span>
              </label>
              <Form.Control as="select" value={state.userType} name="userType" onChange={handleChange}>
                {state?.allUserType?.length &&
                  state.allUserType.map((e, key) => {
                    const user_type_admin = [];
                    if (location.pathname === "/manage-admin") {
                      if (e.user_type === "admin" || e.user_type === "superadmin") {
                        user_type_admin.push(e.user_type);
                        return (
                          <option key={e.user_type} value={user_type_admin}>
                            {user_type_admin}
                          </option>
                        );
                      } else {
                        return false;
                      }
                    } else {
                      return (
                        <option key={e.user_type} value={e.user_type}>
                          {e.user_type}
                        </option>
                      );
                    }
                  })}
              </Form.Control>
              {state?.allUserSubType?.length ? (
                <div>
                  <label>User Sub-Type</label>
                  <Form.Control as="select" value={state.userSubType} name="userSubType" onChange={handleChange}>
                    {(state.allUserSubType ?? []).map((e, key) => {
                      return (
                        <option key={key} value={e.user_sub_type}>
                          {e.user_sub_type}
                        </option>
                      );
                    })}
                  </Form.Control>
                </div>
              ) : (
                ""
              )}
            </Form.Group>
          </ModalBody>
        </PerfectScrollbar>
        <Modal.Footer>
          <Button disabled={!isFormValid} onClick={handleSave}>
            {"Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SetPermission;

import React, { useRef, useState } from "react";
import { Button, Modal, Table, Alert, Row, Col, Form } from "react-bootstrap";
import CSVReader from "react-csv-reader";
import PerfectScrollbar from "react-perfect-scrollbar";
import styled from "styled-components";
import Loader from "../Loader/ModalLoader";
import { useSelector, useDispatch } from "react-redux";
import CustomContentAlert, { removeAccent, removeSpecialChar } from "../helper";
import Upload from "../../../assets/img/svg/cloud-computing.svg";
import Import from "../../../assets/img/svg/export.svg";
import Export from "../../../assets/img/svg/import.svg";
import Error from "../../../assets/img/svg/error.svg";
import PaginationComponent from "./PaginationComponent";
import apiType from "../../Actions/APIResponseActions/type";

const BulkImportModal = ({ UploadFile, type, closeModal, responseTableData, modelLoader, propDomain }) => {
  const [selectedOption, setselectedOption] = React.useState("addnewpermits");
  const unitData = React.useRef([
    "unit_id",
    "email_address",
    "first_name",
    "last_name",
    "phone",
    "send_mail",
    "notes",
    "do_not_allow_login",
    "default_language",
    "visitor_credit",
  ]);

  const permitData = React.useRef([
    "unit_id",
    "lot_name",
    "driver_first_name",
    "driver_last_name",
    "driver_contact_number",
    "driver_email_address",
    "license_plate_number",
    "startdate_yyyy_mm_dd_hh_mm_ss_",
    "enddate_yyyy_mm_dd_hh_mm_ss_",
  ]);

  const PaidpermitData = React.useRef([
    "unit_id",
    "lot_name",
    "cwh_call_when_here",
    "auto_renew",
    "send_mail",
    "driver_first_name",
    "driver_last_name",
    "driver_contact_number",
    "driver_email_address",
    "driver_stall_number",
    "license_plate_number",
    "license_plate_number2",
    "license_plate_number3",
    "access_card_tag",
    "startdate_yyyy_mm_dd_hh_mm_ss_",
    "package_name",
    "employee_reference",
    "group_name",
  ]);

  const siteData = React.useRef(["subdomain", "site_name", "user", "address", "timezone"]);

  const downloadTemplate = React.useRef({
    PaidPermit: "/import-paid-permit-sample.csv",
    Permits: "/import-permits-sample.csv",
    Units: "/import-units-sample.csv",
    Sites: "/import-sites-sample.csv",
  });

  const [tableState, setTableState] = React.useState({
    show: false,
    data: [],
  });

  const [showError, setShowError] = React.useState({
    showSiteError: false,
    api_error_message: "",
    uploadButton: false,
  });

  const [showAlert, setShowAlert] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);

  const allImportData = useSelector((state) => state.ResidenDetails.importedData);

  const errorMessageObj = useSelector((state) => state.APIResponseDetails);

  const isValidComma = useRef(true);
  const [isCommaError, setIsCommaError] = useState(false);
  const handleForce = React.useCallback(
    (tableData) => {
      console.log("tableData:::: paidpermit model", tableData);
      if (isValidComma.current === false) return;
      tableData = tableData.map((item) => {
        console.log("item: handleForce", item);
        handleAssentAndSpecialChar(item);
        if (!item.email_address) {
          if (!item.unit_id) {
            return item;
          }
          if (!item.lot_name) {
            return {
              ...item,
              email_address: item.email_address || `${item.unit_id}@${propDomain}.com`,
            };
          }
        }
        return item;
      });
      let flag = false;
      const CsvFile = document.querySelector(".csv-input").files[0];
      const size = CsvFile.size / 1024 / 1024;
      if (size > 2) {
        setShowError({
          showSiteError: true,
          uploadButton: true,
          api_error_message: "File size should be less then 2mb",
        });
        return;
      }
      let headers = [];

      if (tableData && tableData.length > 0) {
        headers = Object.keys(tableData[0]);
      }
      setCurrentPage(1);
      if (type === "Units") {
        unitData.current.forEach((field) => {
          flag = !headers.includes(field);
        });
      } else if (type === "Permits") {
        permitData.current.forEach((field) => {
          flag = !headers.includes(field);
        });
      } else if (type === "Sites") {
        siteData.current.forEach((field) => {
          flag = !headers.includes(field);
        });
      } else if (type === "PaidPermit") {
        PaidpermitData.current.forEach((field) => {
          console.log("field:::", field);
          flag = !headers.includes(field);
        });
      }
      if (!flag) {
        setTableState({
          show: true,
          data: tableData,
        });
        setShowError(
          {
            showSiteError: false,
            uploadButton: true,
            api_error_message: "",
          },
          () => {
            closeModal(false);
          },
        );
      } else {
        setShowError(
          {
            showSiteError: true,
            uploadButton: true,
            api_error_message: "Please upload file correctly",
          },
          () => {
            closeModal(false);
          },
        );
      }
      console.log("tableData:::::::::=====", tableData);
    },
    [type, isValidComma.current],
  );

  const handleAssentAndSpecialChar = React.useCallback((item) => {
    if (item.sub_domain && typeof item.sub_domain === "string") {
      item.sub_domain = item?.sub_domain?.toLowerCase().replace(/ /g, "");
      item.sub_domain = removeAccent(item.sub_domain);
      item.sub_domain = removeSpecialChar(item.sub_domain);
    }

    if (item.unit_id && typeof item.unit_id === "string") {
      item.unit_id = item?.unit_id?.replace(/ /g, "");
      item.unit_id = removeAccent(item.unit_id);
      item.unit_id = removeSpecialChar(item.unit_id);
    }

    if (item.visitor_credit && typeof item.visitor_credit === "string") {
      item.visitor_credit = item.visitor_credit?.trim();
    }

    if (item.license_plate_number && typeof item.license_plate_number === "string") {
      item.license_plate_number = item?.license_plate_number?.replace(/ /g, "").toUpperCase();
      item.license_plate_number = removeAccent(item.license_plate_number);
      item.license_plate_number = removeSpecialChar(item.license_plate_number);
    }

    if (item.license_plate_number2 && typeof item.license_plate_number2 === "string") {
      item.license_plate_number2 = item?.license_plate_number2?.replace(/ /g, "").toUpperCase();
      item.license_plate_number2 = removeAccent(item.license_plate_number2);
      item.license_plate_number2 = removeSpecialChar(item.license_plate_number2);
    }

    if (item.license_plate_number3 && typeof item.license_plate_number3 === "string") {
      item.license_plate_number3 = item?.license_plate_number3?.replace(/ /g, "").toUpperCase();
      item.license_plate_number3 = removeAccent(item.license_plate_number3);
      item.license_plate_number3 = removeSpecialChar(item.license_plate_number3);
    }

    if (item?.notes && typeof item.notes === "string") {
      item.notes = removeAccent(item.notes);
    }

    if (item?.first_name && typeof item.first_name === "string") {
      item.first_name = removeAccent(item.first_name);
      item.first_name = removeSpecialChar(item.first_name);
      item.first_name = item.first_name?.trim();
    }

    if (item?.last_name && typeof item.last_name === "string") {
      item.last_name = removeAccent(item.last_name);
      item.last_name = removeSpecialChar(item.last_name);
      item.last_name = item.last_name?.trim();
    }

    if (item?.driver_first_name && typeof item.driver_first_name === "string") {
      item.driver_first_name = removeAccent(item.driver_first_name);
      item.driver_first_name = removeSpecialChar(item.driver_first_name);
      item.driver_first_name = item.driver_first_name?.trim();
    }

    if (item?.driver_last_name && typeof item.driver_last_name === "string") {
      item.driver_last_name = removeAccent(item.driver_last_name);
      item.driver_last_name = removeSpecialChar(item.driver_last_name);
      item.driver_last_name = item.driver_last_name?.trim();
    }

    if (item?.email_address && typeof item?.email_address === "string") {
      item.email_address = removeAccent(item.email_address);
    }

    if (item?.driver_email_address && typeof item?.driver_email_address === "string") {
      item.driver_email_address = removeAccent(item.driver_email_address);
    }

    if (item.parent_subdomain && typeof item.parent_subdomain === "string") {
      item.parent_subdomain = item?.parent_subdomain?.toLowerCase().replace(/ /g, "");
      item.parent_subdomain = removeAccent(item.parent_subdomain);
      item.parent_subdomain = removeSpecialChar(item.parent_subdomain);
    }

    if (item.site_name && typeof item.site_name === "string") {
      item.site_name = removeAccent(item.site_name);
      item.site_name = removeSpecialChar(item.site_name);
    }

    if (item?.employee_reference && typeof item?.employee_reference === "string") {
      item.employee_reference = removeAccent(item.employee_reference);
      item.employee_reference = removeSpecialChar(item.employee_reference);
    }

    if (item.welcome_description && typeof item.welcome_description === "string") {
      item.welcome_description = removeAccent(item.welcome_description);
      item.welcome_description = removeSpecialChar(item.welcome_description);
    }
    if (item.welcome_title && typeof item.welcome_title === "string") {
      item.welcome_title = removeAccent(item.welcome_title);
      item.welcome_title = removeSpecialChar(item.welcome_title);
    }
    if (item.address && typeof item.address === "string") {
      item.address = removeAccent(item.address);
      item.address = removeSpecialChar(item.address);
    }
  }, []);

  React.useEffect(() => {
    if (responseTableData?.length > 0) {
      setTableState({
        show: true,
        data: responseTableData,
      });
    }
  }, [responseTableData]);

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    trim: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
    beforeFirstChunk: (chunk) => {
      const i = !!chunk.split("\n").find((item) => {
        if (item[0] === '"') item = `,${item}`;
        if (item[item.length - 1] === '"') item = `${item},`;
        return item.split(',"').length !== item.split('",').length;
      });
      if (i) {
        setIsCommaError(true);
        isValidComma.current = false;
        return "";
      }
      return chunk;
    },
  };
  const dispatch = useDispatch();

  const ResetTableState = React.useCallback(() => {
    setTableState({ show: false, data: [] });
    setShowError({ showSiteError: false });
    dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "",
    });
    dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
  }, []);

  React.useEffect(() => {
    dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "",
    });
  }, []);

  const tableHeader = React.useMemo(() => {
    if (tableState.data && tableState.data.length > 0) {
      return Object.keys(tableState.data[0]);
    }
    return [];
  }, [tableState.data]);

  React.useEffect(() => {
    const filteredImportedData = (allImportData ?? []).filter((importedData) => "status" in importedData && importedData.status === "Failed");

    if (filteredImportedData?.length) {
      setTableState({
        show: true,
        data: filteredImportedData,
      });
    }
  }, [allImportData]);

  React.useEffect(() => {
    setTableState({
      show: false,
      data: [],
    });
  }, []);

  const data = React.useMemo(() => {
    return tableState.data.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10);
  }, [currentPage, tableState.data]);

  const UploadFileFunc = React.useCallback(() => {
    const dataToBeSent = JSON.parse(JSON.stringify(tableState.data));
    if (type === "Permits") {
      (dataToBeSent ?? []).map((x) => {
        x.startdate = x.startdate_yyyy_mm_dd_hh_mm_ss_ ? x.startdate_yyyy_mm_dd_hh_mm_ss_ : x.startdate;
        x.enddate = x.enddate_yyyy_mm_dd_hh_mm_ss_ ? x.enddate_yyyy_mm_dd_hh_mm_ss_ : x.enddate;
        x.importpermits = selectedOption;
        delete x.startdate_yyyy_mm_dd_hh_mm_ss_;
        delete x.enddate_yyyy_mm_dd_hh_mm_ss_;
        return x;
      });
    }
    if (type === "PaidPermit") {
      (dataToBeSent ?? []).map((x) => {
        x.startdate = x.startdate_yyyy_mm_dd_hh_mm_ss_ ? x.startdate_yyyy_mm_dd_hh_mm_ss_ : x.startdate;
        delete x.startdate_yyyy_mm_dd_hh_mm_ss_;
        return x;
      });
    }
    console.log("dataToBeSent:-----", dataToBeSent);
    UploadFile(dataToBeSent);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 10000);
  }, [tableState]);

  const handleUpdateChange = React.useCallback((e) => {
    setselectedOption(e.target.value);
  });

  const handleHeader = (header) => {
    if (header.includes("startdate")) {
      return "StartDate(YYYY-MM-DD hh:mm:ss)";
    } else if (header.includes("enddate")) {
      return "EndDate(YYYY-MM-DD hh:mm:ss)";
    } else {
      return header.replace(/_/g, " ");
    }
  };

  return (
    <Modal
      id="ImportPermits"
      lg={"lg"}
      backdrop={"static"}
      show={true}
      onHide={closeModal}
      dialogClassName="modal-90w modal-xl opacity: 0.8"
      fullscreen={"sm-down"}
      className="modalData"
    >
      <Modal.Header id="import" className="d-flex justify-content-between bg-white align-items-center pb-3">
        <h5 className="m-0 font-weight-bold">
          <Modal.Title className="m-0">Import {type}</Modal.Title>
        </h5>
        {type === "Permits" ? (
          <Row>
            <Col md="12">
              <div className="light-bg2 d-flex flex-column justify-content-center align-items-left h-100 flex-wrap">
                <div className="w-100 ">
                  <Form.Group className="ml-3 mt-2 mb-2 mr-3 d-flex flex-wrap">
                    <div className="d-flex mb-1">
                      <Form.Check
                        inline
                        label="Add New Permits"
                        name="addnewpermits"
                        type="radio"
                        value="addnewpermits"
                        id="1"
                        onChange={(e) => {
                          handleUpdateChange(e);
                        }}
                        checked={selectedOption === "addnewpermits"}
                      />
                    </div>
                    <div className="d-flex mb-1">
                      <Form.Check
                        inline
                        label="Add / Deactivate Permits"
                        name="addordeactivate"
                        type="radio"
                        value="addordeactivate"
                        id="2"
                        onChange={(e) => {
                          handleUpdateChange(e);
                        }}
                        checked={selectedOption === "addordeactivate"}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <p></p>
        )}

        <Button id="modal" title={"Import " + type} type="button" onClick={closeModal} className="close-btn py-0" data-dismiss="modal">
          <i className="nc-icon nc-simple-remove text-danger" />
        </Button>
      </Modal.Header>
      <div style={{ marginLeft: 10, marginRight: 10, textAlign: "center" }}>
        {!errorMessageObj.api_response && showAlert && errorMessageObj.api_error_message !== undefined && errorMessageObj.api_error_message ? (
          <span>
            <Alert variant={"danger"} onClose={() => setShowAlert(false)} dismissible>
              {errorMessageObj.api_error_message}
            </Alert>
          </span>
        ) : (
          <div></div>
        )}
        {errorMessageObj.api_response && showAlert && errorMessageObj.api_success_message !== undefined && errorMessageObj.api_success_message ? (
          <span>
            <Alert variant={"success"} onClose={() => setShowAlert(false)} dismissible>
              {errorMessageObj.api_success_message}
            </Alert>
          </span>
        ) : (
          <div></div>
        )}
      </div>
      <ModalBody className="pt-0">
        {modelLoader ? <Loader /> : null}

        <ScrollBar className={`${!tableState.show ? "d-flex justify-content-center align-items-center pt-5 pb-5 browse-btn-area" : "mt-4 mb-4"}`}>
          {tableState.show ? (
            <div className="table-responsive import-units">
              <Table>
                <thead>
                  <tr>
                    {tableHeader
                      ? tableHeader.map((header, headIndex) => {
                          return (
                            <th className={"table-cell"} key={headIndex}>
                              {handleHeader(header)}
                            </th>
                          );
                        })
                      : null}
                  </tr>
                </thead>
                <tbody>
                  {(data ?? []).map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex} className="text-left">
                        {tableHeader.map((col, colIndex) => (
                          <TableData col={col} row={row} key={colIndex} />
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className="w-100">
              <div className="d-flex justify-content-center mt-2 align-items-center browse-btn-area">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  {showError.showSiteError === false && (
                    <div className="custom-browse-btn mb-0 form-group ">
                      <img src={Upload} height={50} width={50} style={{ marginTop: 5 }} />
                      <div className="upload-text">Upload a file</div>
                      <CSVReader cssClass="react-csv-input" label="" onFileLoaded={handleForce} parserOptions={papaparseOptions} />
                    </div>
                  )}
                  {showError.showSiteError !== false && <img src={Error} height={100} width={100} />}

                  {isCommaError && (
                    <div className="text-muted text-danger fs-30px mt-1">
                      <strong>Error :</strong> Wrong use of &quot; or , in file
                    </div>
                  )}
                  {!isCommaError && (
                    <div className="text-muted fs-12px mt-1">
                      <strong>Note :</strong>
                      <b>Only .csv format allowed to a max size of 2 MB.And Max data limit is 500 for import at once.!</b>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {showError.showSiteError ? <CustomContentAlert delay={4000} message={showError.api_error_message} className="toast-error" /> : null}
        </ScrollBar>
        {tableState.data.length > 0 && (
          <PaginationComponent
            itemsCount={tableState.data.length}
            itemsPerPage={10}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            alwaysShown={false}
          />
        )}
      </ModalBody>
      <Modal.Footer>
        <a href={downloadTemplate.current[type]}>
          <Button className="mr-2" disabled={modelLoader}>
            <img src={Export} width="18" />
            <span className="pl-2"> Download Sample</span>
          </Button>
        </a>
        <div>
          {showError.uploadButton && (
            <Button variant="primary" className={"mr-2"} onClick={ResetTableState} disabled={modelLoader}>
              <img src={Import} width="18" />
              <span className="pl-2"> Upload again</span>
            </Button>
          )}
          <Button variant="primary" disabled={tableState.data.length === 0 || modelLoader} onClick={() => UploadFileFunc()}>
            <img src={Import} width="18" />
            <span className="pl-2"> Import</span>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkImportModal;
const ScrollBar = styled(PerfectScrollbar)`
  max-height: calc(100vh - 300px);
  padding-right: 24px;
  padding-left: 24px;
  .ps__rail-x {
    margin-top: 10px;
    z-index: 1000;
    > div {
      padding-top: 10px;
    }
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
`;

const ModalBody = styled(Modal.Body)`
  padding-right: 0px !important;
  padding-left: 0px !important;
`;
const TableData = (props) => {
  const { col, row } = props;
  const icon = React.useMemo(() => {
    let statusColor, failedIcon;
    if (row[col] === "Failed") {
      statusColor = "importResponseFailed";
      failedIcon = <img src={Error} height={20} width={20} style={{ marginRight: 5 }} />;
    } else {
      statusColor = "table-cell";
    }
    return { statusColor, failedIcon };
  }, [row, col]);

  return (
    <td className={icon.statusColor}>
      <div>
        {icon.failedIcon} {`${row[col] === null ? "-" : row[col]}`}
      </div>
    </td>
  );
};

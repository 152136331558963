import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class LogoutModal extends Component {
  save = () => {
    this.props.userLogout({
      userid: this.props.userid,
      permission_id: this.props.permission_id,
      highSecureProperty: this.props.highSecureProperty,
      property_id: this.props.property_id,
      login_id: this.props.login_id,
      user_offline: this.props.user_offline,
    });
    localStorage.setItem("language", "");
  };

  render() {
    const { showBrowserModal, closeModal } = this.props;
    return (
      <Modal lg={"medium"} show={showBrowserModal} onHide={closeModal}>
        <Modal.Header className="no-border">
          <h4 className="m-0 font-weight-bold">Logout</h4>
          <Button title={"Logout"} type="button" onClick={closeModal} className="close-btn" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove"></i>
          </Button>
        </Modal.Header>

        <Modal.Body className="modal-data-body">
          <p className="theme-color">Are you sure you want to logout ?</p>
          {Object.keys(this.props?.user_offline).length > 0 && (
            <div className="help-block">
              Warning: An offline session is already in progress. Please conclude the session to prevent automatic termination and potential data loss.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} type="button" className="btn btn-danger btn-cancel">
            Cancel
          </Button>
          <Button style={{ minWidth: "93px" }} onClick={this.save} type="button" className="btn btn-fill btn-info">
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default LogoutModal;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { SuperAdminAction, CurrentPermitActions } from "../../Actions";
import { v4 as uuidv4 } from "uuid";
import isEqual from "lodash/isEqual";
import Loader from "../Loader/Loader";
class LotSharing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      lotsArray: [],
      PropertyId: this.props.PropertyId,
      property: this.props.property,
      lotSharingData: [],
    };
  }

  componentDidMount = async () => {
    await this.props.get_all_lots(this.props.PropertyId, "lot_sharing");
    await this.props.get_shared_lots(this.props.PropertyId);
    const selectedPropertyName = this.state.property?.property_name;
    const selectedDomainName = this.state.property?.domain;
    this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    const { lots, allSharedLotData } = this.props;
    this.setState({
      lotsArray: lots,
      lotSharingData: allSharedLotData,
      loader: false,
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.allSharedLotData, this.props.allSharedLotData)) {
      this.setState({
        lotSharingData: this.props.allSharedLotData,
      });
    }
  };

  isLotChecked = (rowId, colId) => {
    if (this.state.lotSharingData?.length) {
      const result = this.state.lotSharingData.filter((item) => item.configured_lot === rowId && item.additional_lot === colId);
      return result.length;
    }
  };

  handleFieldsChange = async (e, configured_lot, additional_lot) => {
    this.setState({ loader: true });
    if (e.target.checked) {
      const obj = {
        property_id: this.props.PropertyId,
        configured_lot: configured_lot,
        additional_lot: additional_lot,
        domain: this.props.property.domain,
        table_id: 1,
      };
      await this.props.addSharingLots(obj);
    } else {
      if (this.state.lotSharingData?.length) {
        const result = this.state.lotSharingData.filter((item) => item.configured_lot === configured_lot && item.additional_lot === additional_lot);
        if (result) {
          const item = result[0];
          await this.props.deleteSharingLots(this.props.PropertyId, item.id);
        }
      }
    }
    this.setState({ loader: false });
  };

  showLotsSharingTable = (lotsArray) => {
    return lotsArray.map((rowLot, index) => {
      return (
        <Row key={index} className="lot-sharing-rows pb-0 flex-nowrap">
          <Col className="lot-sharing-left-column">
            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{rowLot.alias}</Tooltip>}>
              <p className="m-0">{rowLot.alias}</p>
            </OverlayTrigger>
          </Col>
          {lotsArray.map((columnLot, p) => {
            const checkId = uuidv4();
            return (
              <Col className="checkbox-column" key={p}>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={checkId}
                    name={`${rowLot.id}_${columnLot.id}`}
                    onChange={(e) => this.handleFieldsChange(e, rowLot.id, columnLot.id)}
                    checked={this.isLotChecked(rowLot.id, columnLot.id) || rowLot.id === columnLot.id}
                    disabled={rowLot.id === columnLot.id}
                  />
                  <label className="custom-control-label" htmlFor={checkId}></label>
                </div>
              </Col>
            );
          })}
        </Row>
      );
    });
  };

  render() {
    const { lotsArray, loader } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <Card.Title as="h4">Lot Sharing</Card.Title>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="pos-rel-overflow-hide">
                <div className="datatable-scroll lot-sharing-table-scroll">
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {lotsArray?.length > 0 && (
                        <>
                          <Row className="usertype-table-header flex-nowrap">
                            <Col className="ac-lots">Configured Lot/ Additional Lot</Col>
                            {lotsArray.map((data, index) => {
                              return (
                                <Col className="lot-sharing-column" key={index}>
                                  <OverlayTrigger
                                    delay={{ show: 250, hide: 400 }}
                                    placement={"top"}
                                    overlay={<Tooltip id={`tooltip-qw`}>{data.alias}</Tooltip>}
                                  >
                                    <p className="m-0">{data.alias}</p>
                                  </OverlayTrigger>
                                </Col>
                              );
                            })}
                          </Row>
                          {this.showLotsSharingTable(lotsArray)}
                        </>
                      )}
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, SuperAdminDetails, CurrentPermitDetails }) => {
  const { api_response, api_success_message, api_error_message } = APIResponseDetails;
  const { property, property_id: PropertyId } = UserProfileDetails;
  const { lots } = SuperAdminDetails;
  const { allSharedLotData } = CurrentPermitDetails;
  return {
    api_response,
    api_success_message,
    api_error_message,
    property,
    lots,
    PropertyId,
    allSharedLotData,
  };
};

const mapStateToDispatch = {
  get_all_lots: SuperAdminAction.get_all_lots,
  addSharingLots: CurrentPermitActions.addSharingLots,
  get_shared_lots: CurrentPermitActions.get_shared_lots,
  deleteSharingLots: CurrentPermitActions.deleteSharingLots,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(LotSharing);

import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Form, Dropdown, Tooltip, OverlayTrigger } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import PropertDetails from "../LandingPage/PropertySelection";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import * as SuperAdminAction from "../../Actions/SuperAdminAction/actions";
import { fetchResidentList } from "../../Actions/CurrentPermitActions/actions";
import SendMailModal from "../Ticket/SendMailModal";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import { getOverUsageInvoiceReport, OverUsagePdfDownload } from "../../Actions/ReportsAction/action";
import { saveAs } from "file-saver";
import Loader from "../Loader/Loader";
import CustomContentAlert, {
  tableHeightScrollBars,
  findBySearch,
  scroolToTop,
  isEmailValid,
  // utcToSpecificTimeZone,
} from "../helper";
import moment from "moment";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
const history = require("../../history").default;

const OverUsageInvoiceReport = () => {
  const dispatch = useDispatch();
  const usageInvoiceList = useSelector((state) => state.ReportsDetails.usageInvoiceList);
  const apiResponse = useSelector(getAPIResponse);
  const user_type = useSelector((state) => state.UserProfileDetails.user_type);
  const SuiteId = useSelector((state) => state.UserProfileDetails.suite_id);
  const exportListDetails = useSelector((exportState) => exportState.ReportsDetails.usageInvoiceExportList);
  const exportPdfList = useSelector((exportState) => exportState.ReportsDetails.overusageinvoiceExportPdfList);
  const UserData = useSelector((userState) => userState.UserProfileDetails);
  const selectAllProperty = useSelector((propertyState) => propertyState.SuperAdminDetails.getAllProperties);
  const exportList = useSelector((exportState) => exportState.ReportsDetails.invoiceExportList);
  const residentListData = useSelector((state) => state.CurrentPermitDetails.residentList);
  const [isSuccess, setIsSuccess] = useState(false);

  const [state, setState] = useState({
    selectedProperty: UserData.property.id,
    selectedPropertyObj: {},
    childcheckbox: true,
    month: new Date(),
    timezone: UserData.timezone,
    exportValue: false,
    selectedDomainName: "",
    exportButtonDisable: true,
    finalTableHeight: 0,
    searchValue: "",
    count: 0,
    loader: false,
    unit_id: user_type === "unit" ? SuiteId : "ALL",
    residentList: [],
    invoiceList: [],
    searchedInvoiceList: [],
    pdfData: null,
    emailToSend: "",
    isSendMailModalOpen: false,
    emailError: "",
    isMailFormValid: false,
    selectedData: {},
    type: "unit",
    startDateTime: "",
    endDateTime: "",
  });

  const [apiCalled, setApiCalled] = useState(false);
  const [apiMailCalled, setMailApiCalled] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [pdfApiCalled, setpdfApiCalled] = useState(false);

  React.useEffect(() => {
    const height = tableHeightScrollBars();
    dispatch(SuperAdminAction.get_all_properties());
    setState((prev) => ({
      ...prev,
      finalTableHeight: height,
    }));
    history.replace({
      search: ``,
    });
  }, []);

  useEffect(() => {
    const propertyId = state.selectedPropertyObj.value || UserData?.property_id;
    dispatch(fetchResidentList(propertyId));
  }, [state.selectedPropertyObj, UserData?.property_id]);

  useEffect(() => {
    const body = {
      property_id: state.selectedProperty ? state.selectedProperty : UserData.property_id,
      childcheckbox: state.childcheckbox,
      timezone: state.timezone,
      exportValue: state.exportValue,
      month: new Date(),
      unit_id: state.unit_id,
    };
    dispatch(getOverUsageInvoiceReport(body));
    GetDateRangeWithFormat(body);
    setApiCalled(true);
  }, [UserData?.property_id]);

  useEffect(() => {
    if (residentListData) {
      setState((prevState) => ({
        ...prevState,
        residentList: residentListData,
      }));
    }
  }, [residentListData]);

  useEffect(() => {
    const selectedProperty = selectAllProperty.find((item) => item.id === state.selectedProperty);
    const selectedPropertyName = UserData.property?.property_name;
    const selectedDomainName = UserData.property?.domain;
    if (selectedProperty) {
      setState((prev) => ({
        ...prev,
        selectedPropertyObj: {
          label: selectedProperty.site_name,
          value: selectedProperty.id,
        },
        selectedDomainName: selectedDomainName,
      }));
    }
    dispatch(SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName));
  }, [selectAllProperty]);

  useEffect(() => {
    if (usageInvoiceList?.rows?.length) {
      setState((prev) => ({
        ...prev,
        invoiceList: usageInvoiceList.rows,
        count: usageInvoiceList.count,
        loader: false,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        invoiceList: [],
        count: 0,
        loader: false,
      }));
    }
  }, [usageInvoiceList]);

  useEffect(() => {
    if (state?.searchedInvoiceList?.length) {
      setState((prev) => ({
        ...prev,
        count: state.searchedInvoiceList.length,
      }));
    }
  }, [state.searchedInvoiceList]);

  useEffect(() => {
    if (exportList) {
      const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
      const exportDataName = `ADMIN-INVOICE-REPORT-${currentTimeDate}-${state.selectedDomainName}.xls`;
      if (apiCalled === true) {
        setApiCalled(false);
        saveAs(
          new Blob([exportList], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
          exportDataName,
        );
      }
    }
  }, [exportList]);

  useEffect(() => {
    if (exportListDetails) {
      const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
      const exportDataName = `INVOICE-${currentTimeDate}-${state.selectedDomainName}.xls`;
      if (apiCalled === true) {
        setApiCalled(false);
        saveAs(
          new Blob([exportListDetails], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
          exportDataName,
        );
      }
    }
  }, [exportListDetails]);

  useEffect(() => {
    if (exportPdfList) {
      const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
      if (pdfApiCalled === true) {
        setpdfApiCalled(false);
        saveAs(new Blob([exportPdfList], { type: "application/pdf" }), `Invoice-${currentTimeDate}.pdf`);
      }
    }
  }, [exportPdfList]);

  const GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj);
    console.log("object:::::::::::::::::::::", pageobj, start_date_without_utc, end_date_without_utc);
    setState(() => ({
      ...state,
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    }));
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Domain",
      width: "150px",
      sortable: true,
      cell: (row) => showDomainField(row),
      selector: (row) => row?.domain,
    },
    {
      name: "Year-Month",
      width: "160px",
      sortable: true,
      cell: (row) => showYearMonthField(row),
      selector: (row) => row.show_month,
    },
    {
      name: "Unit",
      width: "150px",
      sortable: true,
      cell: (row) => showUnitField(row),
      selector: (row) => row.suite_id,
    },
    {
      name: "Username",
      width: "150px",
      sortable: true,
      cell: (row) => showUsernameField(row),
      selector: (row) => row.username,
    },
    {
      name: "Total Amount",
      width: "140px",
      sortable: true,
      cell: (row) => showTotalAmount(row),
      selector: (row) => row.total_amount,
    },
    {
      name: "Action",
      // width: "140px",
      sortable: true,
      cell: (row) => showActionField(row),
    },
  ];

  const showActionField = (row) => {
    {
      return (
        <Dropdown>
          <Dropdown.Toggle
            variant="Primary"
            id="dropdown-basic"
            className="btn-xs" // disabled={!+row.amount > 0}
          >
            Action
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item className="text-sm" href="" onClick={() => downloadPdf(row, "single")}>
              Invoice
            </Dropdown.Item>
            <Dropdown.Item className="text-sm" href="" onClick={() => sendMailClick(row)}>
              Send Mail
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  };

  const downloadPdf = (row, type) => {
    const data = {
      property_id: row.property_id,
      suite_id: row.suite_id,
      date: state.month,
      type: type,
      timezone: state.timezone,
      mail_sent: false,
    };
    dispatch(OverUsagePdfDownload(data));
    setpdfApiCalled(true);
  };

  const showTotalAmount = (row) => (
    <>
      {row.total_amount ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${row.total_amount}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">${row.total_amount}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showUnitField = (row) => (
    <>
      <div>
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.suite_id}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row?.suite_id}</div>
        </OverlayTrigger>
      </div>
    </>
  );

  const showUsernameField = (row) => (
    <>
      <div>
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.username}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row?.username}</div>
        </OverlayTrigger>
      </div>
    </>
  );

  const showYearMonthField = (row) => (
    <>
      {row.show_month ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.show_month}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row.show_month}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showDomainField = (row) => (
    <>
      {row?.domain ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.domain}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row?.domain}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const dataTableHandle = (data) => {
    return (
      <DataTable
        data={state.searchValue?.length ? state.searchedInvoiceList : data}
        columns={columns}
        customStyles={customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  const sendMailClick = (row) => {
    setState({
      ...state,
      showInvoiceModal: false,
      isSendMailModalOpen: true,
      selectedData: row,
    });
  };

  const handleMailChange = (e) => {
    setState({
      ...state,
      emailToSend: e.target.value,
    });
  };

  useEffect(() => {
    if (apiMailCalled === true) {
      setState({
        ...state,
        isSendMailModalOpen: false,
        emailToSend: "",
        emailError: "",
      });
      console.log("api message", apiResponse);
      setMailApiCalled(false);
      setIsSuccess(apiResponse?.api_response === true);
      setIsFailed(apiResponse?.api_response === false);
      setTimeout(() => {
        setIsSuccess(false);
        setIsFailed(false);
      }, 3000);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (state.isSendMailModalOpen) {
      if (!state.emailToSend.trim().length) {
        setState({
          ...state,
          emailError: "Please Enter email!",
        });
      }
      if (state.emailToSend.trim().length) {
        setState({
          ...state,
          emailError: !isEmailValid(state.emailToSend) ? "Email is invalid!" : "",
          isMailFormValid: isEmailValid(state.emailToSend),
        });
      }
    }
  }, [state.emailToSend]);

  const sendPermitInvoicePdf = async (e) => {
    e.preventDefault();
    const sendMailData = {
      ...state.selectedData,
      emailToSend: state.emailToSend,
      type: "pdf",
      mail_sent: true,
      timezone: state.timezone,
      date: state.month,
    };
    dispatch(OverUsagePdfDownload(sendMailData));
    setMailApiCalled(true);
  };

  const handleSearchChange = async ({ target }) => {
    const val = target?.value?.toString().trim();

    setState((prev) => ({ ...prev, searchValue: val }));
    const columnList = ["id", "suite_id", "total_permit", "total_amount", "username", "first_name", "last_name"];
    if (val?.length) {
      if (!state.findMore) {
        setState((prev) => ({
          ...prev,
          searchedInvoiceList: findBySearch(val, state.invoiceList, state.invoiceList, columnList, state.timezone),
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        count: usageInvoiceList.count,
        searchedInvoiceList: [],
        invoiceList: usageInvoiceList.rows,
      }));
    }
  };

  const handleExport = async () => {
    setState({ ...state, exportValue: true });
    const { selectedProperty, childcheckbox, type, timezone, month, unit_id } = state;
    const pageobj = {
      childcheckbox,
      timezone,
      exportValue: true,
      month,
      type,
    };
    if (Object.keys(state.selectedPropertyObj).length) {
      pageobj.property_id = state.selectedPropertyObj.value;
    } else {
      pageobj.property_id = selectedProperty;
    }

    if (state.month) {
      pageobj.month = month;
    }
    if (unit_id) {
      pageobj.unit_id = state.unit_id;
    }
    dispatch(getOverUsageInvoiceReport(pageobj));
    GetDateRangeWithFormat(pageobj);
    setApiCalled(true);
  };

  const handleCurrentDate = (date) => {
    setState((prev) => ({
      ...prev,
      month: date,
    }));
  };

  const resetAll = async () => {
    setState((prev) => ({
      ...prev,
      month: new Date(),
      selectedProperty: UserData.property.id,
      selectedPropertyObj: {
        label: UserData.property.property_name,
        value: UserData.property.id,
      },
      childcheckbox: true,
      unit_id: "ALL",
      type: "unit",
    }));
  };

  const handleSearch = () => {
    setState((prev) => ({ ...prev, loader: true }));
    const { childcheckbox, month, selectedPropertyObj, selectedProperty, timezone, unit_id } = state;
    const pageobj = {
      childcheckbox,
      timezone,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.property_id = selectedPropertyObj.value;
    } else {
      pageobj.property_id = selectedProperty;
    }
    if (month) {
      pageobj.month = month;
    }
    if (unit_id) {
      pageobj.unit_id = unit_id;
    }
    dispatch(getOverUsageInvoiceReport(pageobj));
    GetDateRangeWithFormat(pageobj);
    document.body.classList.remove("foc-open");
  };

  const hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  const handleCheckBox = () => {
    setState((prev) => ({
      ...prev,
      childcheckbox: !state.childcheckbox,
    }));
  };

  const changeProperty = (event) => {
    setState((prev) => ({
      ...prev,
      selectedProperty: event.value,
      selectedPropertyObj: event,
      month: new Date(),
    }));
  };

  const handleChangeUnit = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === "type" && e.target.value === "driver") {
      setState((prevState) => ({
        ...prevState,
        unit_id: "ParkingPermit",
      }));
    }
  };

  const renderOptions = () => {
    if (state.type === "unit") {
      if (state.residentList?.length) {
        return (
          <>
            <option value="ALL">ALL</option>
            {state.residentList.map((item) => (
              <option key={item?.id} value={item.suite_id}>
                {item.suite_id}
              </option>
            ))}
          </>
        );
      }
      return null;
    } else {
      return (
        <>
          <option value="ParkingPermit">Parking Permit</option>
        </>
      );
    }
  };

  return (
    <div>
      <Card className="mb-0">
        <Card.Body className="pos-rel-overflow-hide">
          <>
            <div className="sidebarFilter">
              <Card className="contact-container mb-0">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between">
                    <Card.Title as="h4">Overusage Of Paid Permit Reports</Card.Title>
                    <a
                      onClick={hideSlider}
                      href="javascript:void(0);"
                      className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md="12">
                      <div className="mb-2">
                        <label className="fancy-checkbox">
                          <input
                            type="checkbox"
                            name={"childcheckbox"}
                            checked={state.childcheckbox}
                            onChange={handleCheckBox}
                            className="custom-control-input"
                            id="childcheckbox1"
                          ></input>
                          <span>
                            <i></i>Include Children Sites
                          </span>
                        </label>
                      </div>
                    </Col>

                    <Col md="12">
                      <PropertDetails
                        selectedPropertyObj={state.selectedPropertyObj}
                        changeProperty={changeProperty.bind(this)}
                        propertyDisabled={state.childcheckbox}
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                        <Form.Label className="mb-0">Type:</Form.Label>
                        <span className="asterisk">*</span>
                        <Form.Control as="select" name="type" value={state.type} onChange={handleChangeUnit} placeholder="Select Unit">
                          <>
                            <option value="unit">Unit</option>
                            <option value="driver">Driver</option>
                          </>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    {user_type !== "unit" && (
                      <Col md="12" className="mb-3">
                        <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                          <Form.Label className="mb-0">Units:</Form.Label>
                          <span className="asterisk">*</span>
                          <Form.Control
                            as="select"
                            name="unit_id"
                            value={state.unit_id}
                            disabled={state.childcheckbox || state.type === "driver"}
                            onChange={handleChangeUnit}
                            placeholder="Select Unit"
                          >
                            {renderOptions()}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    )}
                    <Col md="12">
                      <Form.Group>
                        <label className="mb-0 mr-2">Month/Year:</label>
                        <DatePicker
                          className="form-control"
                          placeholderText="Select Date"
                          selected={state.month}
                          value={state.month}
                          disabled={state.loader}
                          selectsStart
                          timeInputLabel="Time:"
                          onChange={handleCurrentDate}
                          showMonthYearPicker
                          dateFormat="MM/yyyy"
                          maxDate={new Date()}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-right justify-content-between d-flex">
                  <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={resetAll} disabled={state.loader}>
                    Reset
                  </Button>
                  <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={handleSearch} disabled={state.loader}>
                    Search
                  </Button>
                </Card.Footer>
              </Card>
            </div>
            <header>
              <div className="active-permit-header sm-flex-column sm-align-items-start">
                <h5 className="mb-2 mb-md-0">Overusage Of Paid Permit Reports</h5>
                <div className="filter-button d-flex flex-wrap">
                  <div className="d-flex align-items-center search-mb-0">
                    <DatatableHeader className="mt-0 mb-0" searchValue={state.searchValue} HandleChange={handleSearchChange} />
                  </div>
                  <div className="buttons-dd">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="Primary"
                        id="dropdown-basic"
                        className="btn btn-sm border-0 bg-light  w-30px h-30px d-flex align-items-center justify-content-center ml-2"
                      >
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="min-w-100px">
                        <Dropdown.Item className="text-sm" onClick={handleExport} disabled={state.invoiceList?.length > 0 ? 0 : 1}>
                          <span>Export</span>{" "}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </header>
          </>
          {state.loader ? (
            <Loader />
          ) : (
            <>
              {state.invoiceList && (
                <>
                  <div className="citation-list-table">{dataTableHandle(state.invoiceList)}</div>
                  <DateRangeAndAmount
                    ReportList={usageInvoiceList?.rows}
                    amountName="total_amount"
                    startDateTime={state.startDateTime}
                    endDateTime={state.endDateTime}
                  />
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
      {state.isSendMailModalOpen && (
        <SendMailModal
          closeModal={() =>
            setState({
              ...state,
              isSendMailModalOpen: false,
              emailToSend: "",
              emailError: "",
            })
          }
          handleMailChange={(e) => handleMailChange(e)}
          emailToSend={state.emailToSend}
          emailError={state.emailError}
          ticketDataPdf={(e) => sendPermitInvoicePdf(e)}
          isMailFormValid={state.isMailFormValid}
          tital="Send Mail as Permit Invoice PDF"
        />
      )}
      {isSuccess && <CustomContentAlert delay={2000} message={apiResponse?.api_success_message} className="toast-success" />}
      {isFailed && <CustomContentAlert delay={2000} message={apiResponse?.api_error_message} className="toast-error" />}
    </div>
  );
};

export default OverUsageInvoiceReport;

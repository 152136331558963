import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export default function MeterRateSection(props) {
  const { formData, showError, handleChange } = props;

  return (
    <div>
      <Form.Group>
        <Row className="px-2">
          <Col className="sm-12">
            <Form.Label className="mb-0">
              Rate List(time1:cost1,time2:cost2)
              <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter rate list in format 5:0.15,15:0.25"
              type="text"
              name="rate_list"
              value={formData?.rate_list}
              onChange={handleChange}
              autoComplete="off"
              required
              maxLength={512}
            />
            {showError("rate_list")}
          </Col>
        </Row>
        <Row className="px-2">
          <Col className="sm-12">
            <Form.Label className="mb-0">
              Rate Max
              <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control placeholder="Rate Max" type="number" name="rate_max" value={formData.rate_max} onChange={handleChange} autoComplete="off" required />
            {showError("rate_max")}
          </Col>
        </Row>
      </Form.Group>
    </div>
  );
}

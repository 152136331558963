import React, { Component } from "react";
import { Card, Container, Row, Col, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { ResidentsActions, SuperAdminAction, EnforcementActions } from "../../Actions";
import Config from "../../../Auth/config";
import moment from "moment";
import CustomContentAlert, {
  isEmailValid,
  regExForPhone,
  validation_message,
  validations,
  // lotselection,
} from "../helper";
class AddUnitPermit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addNewResident: null,
      id: "",
      permit_start_date: "",
      permit_end_date: "",
      allLots: this.props.allLots,
      vehicle_license: "",
      stallNumber: "",
      isPermitDatevalid: true,
      isFormValid: false,
      isvehicleLicenseValid: true,
      isStallNumberValid: true,
      wrongEmail: false,
      api_success_message: "",
      show_alert: false,
      tag_number: "",
      isTagNumberValid: true,
      call_when_here: true,
      mainoffice: true,
      backoffice: true,
      visitor: true,
      utcOffset: "",
      prevmainoffice: true,
      prevbackoffice: true,
      prevVisitor: true,
      prevSearch: "",
      prevPage: "",
      prevNorow: "",
      show_error: false,
      selectedColumn: "suite_id",
      asc: "false",
      sort_direction: "DESC",
      findMore: false,
      isChecked: true,
      isLotSelected: true,
      suite_id: "",
      property: this.props.property,
      units: [],
      selectedUser: "",
      startDate: "",
      country_code: "",
      country_code_list: [
        { id: 1, text: "+1", value: "+1" },
        { id: 2, text: "+91", value: "+91" },
        { id: 3, text: "+44", value: "+44" },
        { id: 4, text: "+61", value: "+61" },
      ],
      phone_options: [],
    };
  }

  async componentDidMount() {
    // For user details
    await this.props.getUserDetails();
    console.log("userlist", this.props.allUsers);
    let allLotsDetails = this.props.userlots;
    allLotsDetails = (allLotsDetails ?? []).map((item) => {
      if (item.visitor_lot || item.quick_permits) {
        item.isChecked = false;
      } else {
        item.isChecked = true;
      }
      return item;
    });
    const selectedPropertyName = this.state.property?.property_name;
    const selectedDomainName = this.state.property?.domain;
    this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    this.setState(
      {
        units: this.props.allUsers,
        allLots: allLotsDetails,
      },
      () => {
        this.settingData();
        this.setState({
          suite_id: this.state.addNewResident?.user?.suite_id,
        });
      },
    );
  }

  settingData = () => {
    const { addNewResident } = this.state;
    if (addNewResident) {
      let showVehicleDetail = true;
      if (!Config.residentVehicleDetail) {
        showVehicleDetail = false;
      }
      this.setState({
        ...addNewResident,
        showVehicleDetail,
        vehicle_make: "-",
        vehicle_model: "-",
        vehicle_color: "-",
        permit_start_date: moment().format("L"),
        permit_end_date: moment().add(10, "years").format("L"),
      });
    }
  };

  handleBack = () => {
    const history = require("../../history").default;
    const params = this.props.location?.state;
    history.push({
      pathname: `/plate-check`,
      state: {
        image: params?.image,
        lpn: params?.lpn,
        email: params?.email,
        scan_time: params?.scan_time,
        isPlateApproved: params?.isPlateApproved,
      },
    });
  };

  handleChangeStartingDates = (date) => {
    date = moment(date).format("L");
    this.setState({ permit_start_date: date }, () => {
      if (Date.parse(this.state.permit_start_date) >= Date.parse(this.state.permit_end_date)) {
        this.setState({
          isPermitDatevalid: false,
          isFormValid: false,
        });
      } else {
        this.setState({
          isPermitDatevalid: true,
          isFormValid: this.validateForm() ? this.validateForm() : false,
          startDate: date,
        });
      }
    });
  };

  handleChangeEndingDates = (date) => {
    date = moment(date).format("L");
    this.setState({ permit_end_date: date }, () => {
      if (Date.parse(this.state.permit_start_date) >= Date.parse(this.state.permit_end_date)) {
        this.setState({
          isPermitDatevalid: false,
          isFormValid: false,
        });
      } else {
        this.setState({
          isPermitDatevalid: true,
          isFormValid: this.validateForm() ? this.validateForm() : false,
        });
      }
    });
  };

  handleUserChange = (event) => {
    this.setState({ selectedUser: event.target.value }, () => {
      const vehicle_license = this.props?.location?.state?.lpn ? this.props.location.state.lpn : "";
      const selectedUserData = this.state?.units?.length && this.state.units?.filter((item) => item.id === this.state.selectedUser);
      this.setState(
        {
          addNewResident: selectedUserData[0],
          vehicle_license: vehicle_license,
        },
        () => {
          this.settingData();
          this.setState({ isFormValid: true });
        },
      );
    });
  };

  handlePhoneOptions = (index, value, type) => {
    const { phone_options } = this.state;
    let phone_values;
    let country_code_values;

    if (type === "phone_number_options") {
      phone_values = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      phone_options[index].phone = phone_values;
    }
    if (type === "country_code_options") {
      country_code_values = value;
      phone_options[index].country_code = country_code_values;
    }

    this.setState(
      {
        phone_options: [...phone_options],
      },
      async () => {
        this.checkAllValidations();
      },
    );

    console.log("phone_options::::", phone_options);
  };

  removePhoneOption = (index) => {
    const { phone_options } = this.state;
    console.log("index", index);

    this.setState(
      {
        phone_options: [...phone_options.filter((_x, i) => i !== index)],
      },
      async () => {
        this.checkAllValidations();
      },
    );
  };

  handleAddPhoneOptions = () => {
    console.log("handleAddPhoneOptions called::---");
    const { phone_options } = this.state;

    this.setState(
      {
        phone_options: [...phone_options, { country_code: "+1", phone: "" }],
      },
      async () => {
        this.checkAllValidations();
      },
    );
  };

  handleChange = ({ target }) => {
    const prevstate = this.state.phone;
    this.setState({ [target.name]: target.value }, () => {
      const {
        phone,
        email,
        // stallNumber,
        // tag_number,
      } = this.state;
      if (target.name === "phone" && phone.trim().length) {
        if (regExForPhone.test(target.value)) {
          this.setState({ phone: target.value });
        } else {
          this.setState({ phone: prevstate });
        }
      }
      if (email?.trim()?.length) {
        this.validateForm();
      }

      this.checkAllValidations();

      const FirstName = validations(target.name, "first_name", this.state.first_name);
      this.setState({
        first_name: FirstName.value,
      });

      const LastName = validations(target.name, "last_name", this.state.last_name);
      this.setState({
        last_name: LastName.value,
      });

      const Phone = validations(target.name, "phone", this.state.phone);
      this.setState({
        phone: Phone.value,
      });

      const Email = validations(target.name, "email", this.state.email);
      this.setState({
        email: Email.value,
      });

      const VehicalLicense = validations(target.name, "vehicle_license", this.state.vehicle_license);

      this.setState({
        vehicle_license: VehicalLicense.value,
        isvehicleLicenseValid: VehicalLicense.validator,
      });
    });
  };

  validateForm = () => {
    const {
      first_name,
      last_name,
      phone,
      email,
      // stallNumber,
      vehicle_license,
      // tag_number,
      isLotSelected,
    } = this.state;

    const isEmailValidate = isEmailValid(email);

    this.setState({
      wrongEmail: !isEmailValidate,
    });

    return !!(
      isEmailValidate &&
      phone.trim().length &&
      first_name.trim().length &&
      last_name.trim().length &&
      email.trim().length &&
      isLotSelected &&
      vehicle_license.trim().length
    );
  };

  checkAllValidations = () => {
    const {
      first_name,
      last_name,
      phone,
      email,
      vehicle_license,
      // tag_number,
      isPermitDatevalid,
      isLotSelected,
    } = this.state;

    if (
      first_name?.trim().length &&
      last_name?.trim().length &&
      phone?.trim().length &&
      email?.trim().length &&
      vehicle_license?.trim().length &&
      this.validateForm() &&
      isPermitDatevalid &&
      isLotSelected &&
      this.isMultiplePhoneValid() &&
      !(phone?.trim()?.length > 10 || phone?.trim()?.length < 10)
    ) {
      this.setState({
        isFormValid: true,
      });
    } else {
      this.setState({
        isFormValid: false,
      });
    }
  };

  isMultiplePhoneValid = () => {
    const { phone_options, phone, country_code } = this.state;
    const phoneListCount = {};
    const phoneList = phone_options.map((item) => {
      return item.country_code + item.phone;
    });
    const phoneNumberCountry_code = country_code === "" ? "+1" : country_code;
    const phoneNumber = phone === "" ? "" : phone;
    phoneList.push(phoneNumberCountry_code + phoneNumber);
    phoneList.forEach(function (x) {
      phoneListCount[x] = (phoneListCount[x] || 0) + 1;
    });
    const phoneListStatus = Object.keys(phoneListCount).filter((item) => phoneListCount[item] > 1);

    const validPhone = phone_options.map((options) => {
      if (options.phone?.trim().length > 10 || options.phone?.trim().length < 10 || options.phone?.trim().length === 0 || phoneListStatus.length) {
        return !(options.phone.trim().length > 10 || options.phone.trim().length < 10 || options.phone.trim().length === 0 || phoneListStatus.length);
      }
      return true;
    });
    console.log("validPhone:::", validPhone);
    return !validPhone.includes(false);
  };

  multiplePhoneRender = (phone_options, country_code_list) => {
    return (
      phone_options &&
      typeof phone_options === "object" &&
      Array.isArray(phone_options) &&
      phone_options.map((option, index) => {
        return (
          <Row key={option?.country_code} className="my-0 m-w-480px">
            <Col className="col-auto">
              <Form.Group>
                <Form.Control
                  className="w-85px"
                  as="select"
                  name="country_code_options"
                  onChange={(e) => this.handlePhoneOptions(index, e.target.value, "country_code_options")}
                  value={option?.country_code}
                  required
                >
                  {country_code_list ? (
                    <>
                      {(country_code_list ?? []).map((item) => (
                        <option key={item?.id} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    </>
                  ) : null}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="col p-0">
              <Form.Group className="p-0 m-1">
                {/* <Form.Label>Option {index + 1}</Form.Label> */}
                {/* <span className="asterisk">*</span> */}
                <Form.Control
                  placeholder="Phone Number"
                  type="text"
                  name="phone_number_options"
                  value={option?.phone}
                  // onBlur={(e) => e.target.value.trim() === "" && removeNoteOption(index)}
                  onChange={(e) => this.handlePhoneOptions(index, e.target.value, "phone_number_options")}
                  maxLength="10"
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
            <Col className="col-auto">
              <Button className="h-40px" size="sm" onClick={() => this.removePhoneOption(index)}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
          </Row>
        );
      })
    );
  };

  handleSubmit = async () => {
    const { addNewResident, vehicle_license, stallNumber, tag_number, call_when_here, allLots, first_name, last_name, email, phone, phone_options } =
      this.state;
    const residentData = {
      first_name,
      last_name,
      email,
      phone,
      suite_id: addNewResident.suite_id,
      phone_options,
    };
    const PropertyId = this.props.property_id;
    const obj = {
      ...residentData,
      vehicle_license,
      stallNumber,
      tag_number,
      call_when_here: call_when_here ? 1 : 0,
    };
    const lotIdToPass = [];
    this.setState({ isFormValid: false });
    const checkedLots = (allLots ?? []).filter((item) => item.isChecked === true);
    (checkedLots ?? []).map((item) => lotIdToPass.push(item.id));
    await this.props.visitorCreate(
      PropertyId,
      this.state.permit_start_date,
      this.state.permit_end_date,
      this.props.id,
      obj,
      lotIdToPass,
      false,
      this.props.timezone,
    );

    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      setTimeout(() => {
        this.setState({ show_alert: false });
        this.handleBack();
      }, 4000);
    } else {
      this.setState({ show_error: true });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  handleCheckBox = (lotId) => {
    let allLotsDetails = this.state.allLots;
    allLotsDetails = (allLotsDetails ?? []).map((item) => {
      if (item.id === lotId) {
        if (item && "isChecked" in item) {
          item.isChecked = !item.isChecked;
        } else {
          item.isChecked = true;
        }
      }
      return item;
    });
    this.setState({ allLots: allLotsDetails }, () => {
      const isLotSelected = !!this.state.allLots.filter((lot) => lot.isChecked === true).length;
      this.setState(
        {
          isLotSelected: isLotSelected,
        },
        () => {
          if (isLotSelected && this.validateForm()) {
            this.setState({ isFormValid: true });
          } else {
            this.setState({ isFormValid: false });
          }
        },
      );
    });
  };

  handleCWH = (type) => {
    this.setState({ [type]: !this.state[type] });
  };

  handleAlertShow = (show_alert, show_error, api_success_message) => {
    if (show_alert) {
      return <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />;
    }
    if (show_error) {
      return <CustomContentAlert delay={4000} message={this.props.api_error_message} className="toast-error" />;
    }
  };

  render() {
    const {
      addNewResident,
      first_name,
      last_name,
      phone,
      email,
      stallNumber,
      vehicle_license,
      tag_number,
      showVehicleDetail,
      vehicle_make,
      vehicle_model,
      vehicle_color,
      permit_start_date,
      permit_end_date,
      isPermitDatevalid,
      isFormValid,
      isvehicleLicenseValid,
      wrongEmail,
      call_when_here,
      show_alert,
      api_success_message,
      show_error,
      allLots,
      country_code,
      country_code_list,
      phone_options,
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <Card.Title as="h4">Driver Permits</Card.Title>
                  <p className="card-category">Fill out the below form to issue a permit.</p>
                </Card.Header>
                {this.handleAlertShow(show_alert, show_error, api_success_message)}
                <Card.Body className="pos-rel-overflow-hide">
                  {/* <h4>Resident Information </h4>
                  <span> All fields are required</span> */}

                  <Row className="m-w-480px">
                    <Col>
                      <div className="visitor-info">
                        <h4 className="inline mt-0">Driver Information</h4>
                        <Form.Group>
                          <label>Unit:</label>
                          <Form.Control as="select" name="selectedUser" placeholder="example@gmail.com" onChange={this.handleUserChange}>
                            <option value="">--Select Unit--</option>
                            {(this.state.units ?? []).map((e) => {
                              return (
                                <option key={e.id} value={e.id}>
                                  {e.suite_id}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-0" controlId="formBasicCheckbox">
                          <label className="fancy-checkbox mt-2">
                            <input
                              type="checkbox"
                              checked={call_when_here}
                              onChange={() => {
                                this.handleCWH("call_when_here");
                              }}
                            ></input>
                            <span>
                              <i></i>Call when here
                            </span>
                          </label>
                          {/* <Form.Check type="checkbox" label="Call when here" /> */}
                        </Form.Group>

                        <Form.Group controlId="formBasicCheckbox">
                          <label className="fancy-checkbox mt-2">
                            <span>
                              <i></i>Lot:
                            </span>
                          </label>
                          <br />
                          {(allLots ?? []).map((lot, index) => {
                            return (
                              <label className="fancy-checkbox mt-2 mr-3" key={lot?.id}>
                                <input
                                  type="checkbox"
                                  name={lot.alias}
                                  checked={!!lot?.isChecked}
                                  onChange={() => {
                                    this.handleCheckBox(lot.id);
                                  }}
                                ></input>
                                <span>
                                  <i></i>
                                  {lot.alias}
                                </span>
                              </label>
                            );
                          })}
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>

                  {addNewResident && (
                    <Row>
                      <Col md="12">
                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>
                                Driver Name:
                                {/* <span className="asterisk">*</span> */}
                              </label>
                              <Form.Control
                                type="text"
                                name="first_name"
                                value={first_name}
                                placeholder="First Name"
                                autoComplete="off"
                                // disabled
                                onChange={this.handleChange}
                                required
                              ></Form.Control>
                              {validation_message(first_name, "First Name", isFormValid)}
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>&nbsp;</label>
                              <Form.Control
                                type="text"
                                name="last_name"
                                value={last_name}
                                placeholder="Last Name"
                                autoComplete="off"
                                // disabled
                                onChange={this.handleChange}
                                required
                              ></Form.Control>
                              {validation_message(last_name, "LastName", isFormValid)}
                            </Form.Group>
                          </Col>

                          <Col md="6">
                            <Form.Group>
                              <label>
                                Driver Email Address:
                                {/* <span className="asterisk">*</span> */}
                              </label>
                              <Form.Control
                                placeholder="example@gmail.com"
                                type="email"
                                name="email"
                                autoComplete="off"
                                // disabled
                                onChange={this.handleChange}
                                value={email}
                              ></Form.Control>
                              {!email && <div className="help-block">Permit Email Address is required</div>}
                              {email && wrongEmail ? <div className="help-block">Invalid Email Address</div> : null}
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>
                                Driver Stall Number:(Optional)
                                {/* <span className="asterisk">*</span> */}
                              </label>
                              <Form.Control
                                placeholder="P1-10"
                                type="text"
                                name="stallNumber"
                                autoComplete="off"
                                onChange={this.handleChange}
                                value={stallNumber}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>
                                License Plate Number:
                                <span className="asterisk">*</span>
                              </label>
                              <Form.Control
                                placeholder="ABC123"
                                type="text"
                                name="vehicle_license"
                                autoComplete="off"
                                value={vehicle_license}
                                onChange={this.handleChange}
                              ></Form.Control>
                              {validation_message(vehicle_license, "Licence", isvehicleLicenseValid)}
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>
                                Tag Number:(Optional)
                                {/* <span className="asterisk">*</span> */}
                              </label>
                              <Form.Control
                                placeholder="113123"
                                type="text"
                                name="tag_number"
                                autoComplete="off"
                                value={tag_number}
                                onChange={this.handleChange}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}

                  {showVehicleDetail && (
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label>Make:</label>
                          <Form.Control
                            placeholder="ABC123"
                            type="text"
                            name="vehicle_make"
                            autoComplete="off"
                            value={vehicle_make}
                            onChange={this.handleChange}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <label>Model:</label>
                          <Form.Control
                            placeholder="ABC123"
                            type="text"
                            name="vehicle_model"
                            autoComplete="off"
                            value={vehicle_model}
                            onChange={this.handleChange}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <label>Color:</label>
                          <Form.Control
                            placeholder="ABC123"
                            type="text"
                            name="vehicle_color"
                            autoComplete="off"
                            value={vehicle_color}
                            onChange={this.handleChange}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col md="12">
                      <label>Permit Dates:</label>
                      <div className="custom-time d-flex align-items-center mb-4">
                        <Row className="m-w-480px">
                          <Col>
                            <label className="mr-1 text-nowrap">Start Date:</label>
                            <DatePicker
                              // id="example-datepicker"
                              className="w-auto"
                              placeholder="04/04/16"
                              selected={permit_start_date ? new Date(permit_start_date) : new Date()}
                              selectsStart
                              onChange={this.handleChangeStartingDates}
                              minDate={new Date()}
                            />
                          </Col>
                          <Col>
                            <label className="ml-2 mr-1 text-nowrap">End Date:</label>
                            <DatePicker
                              placeholder="04/05/16"
                              selected={permit_end_date ? new Date(permit_end_date) : new Date()}
                              selectsEnd
                              onChange={this.handleChangeEndingDates}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div>{isPermitDatevalid ? null : <div className="help-block">End Date should be greater than start date</div>}</div>
                    </Col>
                  </Row>
                  <Row className="m-w-480px">
                    <Col className="col-auto">
                      <Form.Group>
                        <label>Country Code</label>
                        <Form.Control className="w-85px" as="select" name="country_code" onChange={this.handleChange} value={country_code} required>
                          {country_code_list ? (
                            <>
                              {(country_code_list ?? []).map((item) => (
                                <option key={item?.id} value={item.value}>
                                  {item.text}
                                </option>
                              ))}
                            </>
                          ) : null}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="col p-0">
                      <Form.Group>
                        <label>
                          Driver Contact Number:
                          {/* <span className="asterisk">*</span> */}
                        </label>
                        <Form.Control
                          placeholder="0000000000000"
                          type="text"
                          name="phone"
                          autoComplete="off"
                          minLength={10}
                          // disabled
                          onChange={this.handleChange}
                          value={phone}
                        ></Form.Control>
                        {validation_message(phone, "Contact Number")}
                      </Form.Group>
                    </Col>
                    <Col className="col-auto">
                      <div>
                        <label>&nbsp;</label>
                      </div>
                      <Button
                        size="sm"
                        className="h-40px"
                        disabled={
                          phone_options?.length > 9 ||
                          phone?.trim()?.length === 0 ||
                          phone?.trim()?.length > 10 ||
                          phone?.trim()?.length < 10 ||
                          !this.isMultiplePhoneValid()
                        }
                        onClick={this.handleAddPhoneOptions}
                      >
                        <i className="fas fa-plus-circle"></i>
                      </Button>
                    </Col>
                  </Row>

                  {
                    this.multiplePhoneRender(phone_options, country_code_list)
                    // Multiple phone render
                  }

                  {allLots?.length ? (
                    <Button className="btn-fill" type="button" variant="info" onClick={this.handleSubmit} disabled={!isFormValid}>
                      Create
                    </Button>
                  ) : null}
                  <Button className="btn-fill ml-2" type="button" variant="danger" onClick={this.handleBack}>
                    Back
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, ResidenDetails, APIResponseDetails, EnforcementDetails, Logindetails }) => {
  const { id, property_id, property, timezone } = UserProfileDetails;
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { addNewResident, allLots } = ResidenDetails;
  const { allUsers, userlots } = EnforcementDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  return {
    timezone,
    api_response,
    api_error_message,
    api_success_message,
    addNewResident,
    allLots,
    id,
    property_id,
    property,
    allUsers,
    userlots,
    main_body,
  };
};

const mapStateToDispatch = {
  getResidentDetails: ResidentsActions.getResidentDetails,
  visitorCreate: ResidentsActions.visitorCreate,
  getUserLots: EnforcementActions.get_user_lots,
  getUserDetails: EnforcementActions.get_user_lots,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(AddUnitPermit);

import React, { Component } from "react";
import { Card, Container, Row, Col, InputGroup, Button } from "react-bootstrap";
import MaterialTable, { MTableAction } from "material-table";
import { connect } from "react-redux";
import { SuperAdminAction } from "../../Actions";
import CustomContentAlert, { navigationButtons } from "../helper";
import Loader from "../Loader/Loader";

class AddLotForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validAddLotName: true,
      action: "Update/Delete",
      formIsValid: false,
      lotName: [],
      addLot: false,
      addNewLot: "",
      lots_: [],
      quick_permits: false,
      show_lot: true,
      visitor_lot: false,
      property_id: "",
      show_alert: false,
      show_error: false,
      api_success_message: "",
      api_error_message: "",
      isNextButtonDisable: false,
      isThereVisitorLot: false,
      show_visitor_alert: false,
      all_properties: [],
      columnsDataRender: 0,
      loader: false,
    };
    this.addActionRef = React.createRef();
  }

  componentDidMount = async () => {
    const id = this.props.location?.search?.split("?")?.[1] || "";
    await this.props.get_all_properties();
    this.setState(
      {
        property_id: id,
        all_properties: this.props?.getAllProperties,
        loader: true,
      },
      () => {
        const selectedPropertyObj = (this.state.all_properties ?? []).filter((item) => item.id === this.state.property_id);
        const selectedPropertyName = selectedPropertyObj?.[0]?.site_name;
        const selectedDomainName = selectedPropertyObj?.[0]?.domain;
        this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      },
    );
    await this.props.get_all_lots(id, undefined, true);
    const allLots = this.props.lots;
    const temp_array = [];
    this.setState(
      {
        lots_: this.props.lots,
        loader: false,
      },
      () => {
        const visitorLot = (this.state.lots_ ?? []).filter((item) => item.visitor_lot === true);
        const isNextButtonDisable = !(this.state.lots_.length > 0);
        const isThereVisitorLot = !!(visitorLot.length > 0);
        this.setState({
          isNextButtonDisable: isNextButtonDisable,
          isThereVisitorLot: isThereVisitorLot,
        });
      },
    );
    (allLots ?? []).map((ele, i) => {
      temp_array.push(ele.alias);
      return ele;
    });
    this.setState({ lotName: temp_array });
  };

  componentDidUpdate = (prevProps) => {
    if (JSON.stringify(this.props.lots) !== JSON.stringify(prevProps.lots)) {
      this.setState({ lots_: this.props.lots }, () => {
        const isNextButtonDisable = !(this.state.lots_.length > 0);
        const visitorLot = (this.state.lots_ ?? []).filter((item) => item.visitor_lot === true);
        const isThereVisitorLot = !!(visitorLot.length > 0);
        this.setState({
          isNextButtonDisable: isNextButtonDisable,
          isThereVisitorLot: isThereVisitorLot,
        });
      });
    }
  };

  handleVisitorLotChange = async (e, editData) => {
    e.preventDefault();
    if (editData.id) {
      editData.visitor_lot = !editData.visitor_lot;
      await this.props.editLot({
        ...editData,
        old_data: this.props.lots.find((x) => x.id === editData.id),
      });
      if (this.props.api_response) {
        this.setState({
          show_alert: true,
          api_success_message: this.props.api_success_message,
        });
        this.hideAlert();
      } else {
        this.setState({
          show_error: true,
          api_error_message: this.props.api_error_message,
        });
        setTimeout(() => {
          this.setState({ show_error: false });
        }, 4000);
      }
    } else {
      this.setState({
        visitor_lot: !this.state.visitor_lot,
        quick_permits: false,
      });
    }
  };

  hideAlert = () => {
    setTimeout(() => {
      this.setState({
        show_alert: false,
      });
    }, 4000);
  };

  handleQuickPermitChange = async (e, editData) => {
    e.preventDefault();
    if (editData.id) {
      editData.quick_permits = !editData.quick_permits;
      await this.props.editLot({
        ...editData,
        old_data: this.props.lots.find((x) => x.id === editData.id),
      });
      if (this.props.api_response) {
        this.setState({
          show_alert: true,
          api_success_message: this.props.api_success_message,
          columnsDataRender: 1,
        });
        this.hideAlert();
      } else {
        this.setState(
          {
            show_error: true,
            api_error_message: this.props.api_error_message,
          },
          () =>
            this.setState({
              columnsDataRender: 1,
            }),
        );
        setTimeout(() => {
          this.setState({ show_error: false });
        }, 4000);
      }
    } else {
      this.setState({
        quick_permits: !this.state.quick_permits,
        visitor_lot: false,
      });
    }
  };

  handleshowLotChange = async (e, editData) => {
    e.preventDefault();
    if (editData.id) {
      editData.show_lot = !editData.show_lot;
      await this.props.editLot({
        ...editData,
        old_data: this.props.lots.find((x) => x.id === editData.id),
      });
      if (this.props.api_response) {
        this.setState({
          show_alert: true,
          api_success_message: this.props.api_success_message,
          columnsDataRender: 1,
        });
        this.hideAlert();
      } else {
        this.setState(
          {
            show_error: true,
            api_error_message: this.props.api_error_message,
          },
          () =>
            this.setState({
              columnsDataRender: 1,
            }),
        );
        setTimeout(() => {
          this.setState({ show_error: false });
        }, 4000);
      }
    } else {
      this.setState({
        show_lot: !this.state.show_lot,
      });
    }
  };

  HandleErrorSuccess = (resolve, reject) => {
    if (resolve && this.props.api_response) {
      resolve();
    } else {
      if (reject && this.props.api_error_message) {
        reject(new Error(this.props.api_error_message));
      }
    }
  };

  handleAddLot = async (newData, resolve, reject) => {
    const lotData = {
      alias: newData.alias,
      visitor_lot: this.state.visitor_lot,
      quick_permits: this.state.quick_permits,
      show_lot: this.state.show_lot,
      property_id: this.state.property_id,
    };

    await this.props.createLot(lotData);
    this.HandleErrorSuccess(resolve, reject);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        visitor_lot: false,
        columnsDataRender: 1,
        quick_permits: false,
        show_lot: false,
      });
      setTimeout(() => {
        this.setState({
          show_alert: false,
        });
      }, 4000);
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
        visitor_lot: false,
        columnsDataRender: 1,
        quick_permits: false,
        show_lot: false,
      });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  handleEditLot = async (editData, resolve, reject) => {
    await this.props.editLot({
      ...editData,
      old_data: this.props.lots.find((x) => x.id === editData.id),
    });
    this.HandleErrorSuccess(resolve, reject);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        columnsDataRender: 1,
      });
      setTimeout(() => {
        this.setState({
          show_alert: false,
        });
      }, 4000);
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
        columnsDataRender: 1,
      });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  handleDeleteLot = async (lotId) => {
    await this.props.deleteLot({
      id: lotId,
      propertyId: this.state.property_id,
    });
    if (this.props?.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props?.api_success_message,
      });
      setTimeout(() => {
        this.setState({
          show_alert: false,
        });
      }, 4000);
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props?.api_error_message,
      });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  handleAlertOnVisitorLot = () => {
    const { isThereVisitorLot } = this.state;
    if (!isThereVisitorLot) {
      this.setState({
        show_visitor_alert: true,
      });
    }
    setTimeout(() => {
      this.setState({
        show_visitor_alert: false,
      });
    }, 4000);
  };

  editVisitorLot = (props) => (
    <InputGroup>
      <InputGroup.Checkbox
        name="visitor_lot"
        checked={Object.keys(props.rowData).length && "visitor_lot" in props.rowData ? props.rowData.visitor_lot : this.state.visitor_lot}
        onClick={(e) => this.handleVisitorLotChange(e, props.rowData)}
        aria-label="visitor_lot"
      />
    </InputGroup>
  );

  editQuickPermits = (props) => (
    <InputGroup>
      <InputGroup.Checkbox
        name="quick_permits"
        checked={Object.keys(props.rowData).length && "quick_permits" in props.rowData ? props.rowData.quick_permits : this.state.quick_permits}
        onClick={(e) => this.handleQuickPermitChange(e, props.rowData)}
        aria-label="quick_permit"
      />
    </InputGroup>
  );

  showLot = (props) => {
    const checkedValue = Object.keys(props?.rowData).length && "show_lot" in props.rowData ? props.rowData.show_lot : this.state.show_lot;
    return (
      <InputGroup>
        <input type="checkbox" name="show_lot" checked={checkedValue} onChange={(e) => this.handleshowLotChange(e, props.rowData)} aria-label="show_lot" />
      </InputGroup>
    );
  };

  handleComponentsActionField = (props) => {
    if (typeof props.action === typeof Function || props.action.tooltip !== "Add") {
      return <MTableAction {...props} />;
    } else {
      return <div ref={this.addActionRef} onClick={props.action.onClick} />;
    }
  };

  handleIconAddField = (props) => (
    <Button className="btn-blue-bg" data-mycustomid={"add-icon-handler"}>
      Add Lot
    </Button>
  );

  onLotRowAdd = (newData) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (newData.alias) {
          this.handleAddLot(newData, resolve, reject);
          resolve();
        } else {
          reject(Error);
          this.setState({
            show_error: true,
            api_error_message: "Name is mandatory",
          });
          setTimeout(() => {
            this.setState({ show_error: false });
          }, 4000);
        }
      }, 1000);
    });
  };

  onLotRowUpdate = (newData) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.handleEditLot(newData, resolve, reject);
        resolve();
      }, 1000);
    });
  };

  onLotRowDelete = (oldData) => {
    const { lots_ } = this.state;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const dataDelete = [...lots_];
        const index = oldData.tableData.id;
        const lotId = oldData.id;
        dataDelete.splice(index, 1);
        this.setState({ lots_: dataDelete });
        this.handleDeleteLot(lotId, resolve, reject);
        resolve();
      }, 1000);
    });
  };

  render() {
    const { lots_, show_alert, show_error, isNextButtonDisable, isThereVisitorLot, show_visitor_alert, columnsDataRender, loader } = this.state;

    if (!columnsDataRender) {
      this.columns = [
        {
          title: "Name",
          field: "alias",
          validate: (rowData) => (rowData.alias === "" ? "Name can not be empty" : ""),
        },
        {
          title: "Visitor Lot?",
          field: "visitor_lot",
          editComponent: this.editVisitorLot,
        },
        {
          title: "Quick Permit?",
          field: "quick_permits",
          editComponent: this.editQuickPermits,
        },
        {
          title: "Show Lot?",
          field: "show_lot",
          editComponent: this.showLot,
        },
      ];
    }
    return (
      <>
        <Container fluid>
          <Row className="h-100">
            <Col md="12">
              <Card className="h-100">
                <Card.Header className="d-flex align-items-start justify-content-between">
                  <div clasName="">
                    <Card.Title as="h4">Manage Parking Area</Card.Title>
                    <p className="card-category">
                      Fill out below form to create new Parking area.{" "}
                      <i className="fas fa-info-circle text-warning" title="There can be only one visitor Parking area."></i>
                    </p>
                  </div>
                </Card.Header>
                {console.log("lots", lots_)}

                <Card.Body className="pos-rel-overflow-hide">
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      <div style={{ maxWidth: "100%" }}>
                        <MaterialTable
                          title=""
                          options={{
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            draggable: false,
                          }}
                          components={{
                            Action: this.handleComponentsActionField,
                          }}
                          icons={{
                            Add: this.handleIconAddField,
                          }}
                          action={[
                            {
                              icon: "save",
                              isFreeIcon: true,
                              iconProps: <Button>Save</Button>,
                            },
                          ]}
                          columns={this.columns}
                          data={lots_}
                          editable={{
                            onRowAddCancelled: (rowData) => console.log("Row adding cancelled"),
                            onRowUpdateCancelled: (rowData) => console.log("Row editing cancelled"),
                            onRowAdd: this.onLotRowAdd,
                            onRowUpdate: this.onLotRowUpdate,
                            onRowDelete: this.onLotRowDelete,
                          }}
                        />
                      </div>
                      <Col lg="12" className="p-0">
                        {navigationButtons(
                          "/printed-citation-setup",
                          "/addlaneform",
                          this.state.property_id,
                          this.addActionRef,
                          "Add Lot",
                          isNextButtonDisable,
                          isThereVisitorLot,
                          this.handleAlertOnVisitorLot,
                        )}
                      </Col>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {show_alert ? <CustomContentAlert delay={4000} message={this.state.api_success_message} className="toast-success" /> : null}
        {show_error ? <CustomContentAlert delay={4000} message={this.state.api_error_message} className="toast-error" /> : null}
        {show_visitor_alert ? <CustomContentAlert delay={4000} message={"Please make atleast one visitor lot true"} className="toast-error" /> : null}
      </>
    );
  }
}

const mapStateToProps = ({ SuperAdminDetails, APIResponseDetails }) => {
  const { lots, selected_Property, getAllProperties } = SuperAdminDetails;
  const { api_response, api_success_message, api_error_message } = APIResponseDetails;
  return {
    lots,
    getAllProperties,
    selected_Property,
    api_response,
    api_success_message,
    api_error_message,
  };
};

const mapStateToDispatch = {
  get_all_lots: SuperAdminAction.get_all_lots,
  selected_Property: SuperAdminAction.selected_Property,
  createLot: SuperAdminAction.createLot,
  editLot: SuperAdminAction.editLot,
  deleteLot: SuperAdminAction.deleteLot,
  get_all_properties: SuperAdminAction.get_all_properties,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(AddLotForm);

import React, { Component } from "react";
import { Container, Row, Col, Card, Table, Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { TicketActions, SuperAdminAction } from "../../Actions";
import CustomContentAlert from "../helper";
import adminDispute from "../../../assets/img/admin-dispute.svg";
import driverDispute from "../../../assets/img/driver-dispute.svg";
import cancelTicket from "../../../assets/img/svg/ticket-update-icons/cancel-ticket.svg";
import closeDispute from "../../../assets/img/svg/ticket-update-icons/close-dispute.svg";
import paidCash from "../../../assets/img/svg/ticket-update-icons/paid-cash.svg";
import paidCheck from "../../../assets/img/svg/ticket-update-icons/paid-check.svg";
import paidCreditcard from "../../../assets/img/svg/ticket-update-icons/paid-creditcard.svg";
import paidInterac from "../../../assets/img/svg/ticket-update-icons/paid-interac.svg";
import reduceAmount from "../../../assets/img/svg/ticket-update-icons/reduce-amount.svg";
import { Translation } from "react-i18next";
import _i18n from "../Language/i18n";
import momentTimeZone from "moment-timezone";
class Dispute extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      message: "",
      messageList: [],
      show_alert: false,
      api_success_message: "",
      ticket_status: "",
      reduce_amount: "",
      amount: "",
      isValidStatusUpdateForm: false,
      ticketId: "",
      chargable_fee: 0,
      isDriver: false,
      created_at: "",
      isSendTextDisabled: false,
      paidCashDisabled: true,
      paidCardDisabled: true,
      paidCheckDisabled: true,
      paidInteracDisabled: true,
      reduceAmountDisabled: true,
      amountDisabled: true,
      notes_internal: "",
      notes_external: "",
      photos: [],
      costErrorMessage: "",
      amountErrorMessage: "",
      zone: "",
      uploadErrorMsg: "",
      isUpload: false,
      imageValue: "",
      fileValue: null,
      filename: "",
      ticketData: {},
      timezone: "",
      feeStructure: [],
      date: "",
      color: "",
      feesString: "",
      fee: "",
    };
  }

  componentDidUpdate() {
    const ele = document.querySelector(".chat-msg-scroll");
    if (ele) {
      ele.scrollTo(0, ele.scrollHeight);
    }
  }

  async componentDidMount() {
    const redirect_from = this.props?.location?.state?.redirect_from ? this.props.location.state.redirect_from : "";
    const isDriver = !!(redirect_from === "ticket-payment");
    let ticketId, language;
    if (this.props.location?.state?.ticketId) {
      ticketId = this.props.location.state.ticketId;
    } else {
      const searchData = this.props.location?.search?.split("?")[1];
      ticketId = searchData?.split("&")?.[0];
      language = searchData?.split("&")?.[1];
    }

    const ticketLang = this.props.location?.state?.language || language || "en";
    if (!isDriver) {
      const selectedPropertyName = this.props?.property?.property_name ? this.props.property.property_name : "";
      const selectedDomainName = this.props?.property?.domain ? this.props.property.domain : "";
      this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    }

    let ticketDetails = "";
    await this.props.getMessageList(ticketId, isDriver, true, ticketLang);
    let driverMessageList;
    if (this.props.api_response) {
      driverMessageList = this.props.driverMessageList;
      driverMessageList.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      driverMessageList = await this.imageShowInChatBox(driverMessageList);
      const reversedDriverMessageList = driverMessageList.reverse();
      if (driverMessageList?.length) {
        this.setState({
          messageList: reversedDriverMessageList,
        });
      }
      ticketDetails = this.props?.ticketDetails?.data ? this.props.ticketDetails.data[0] : {};
    }
    const notes_internal = ticketDetails?.notes_internal || "-";
    const notes_external = ticketDetails?.notes_external || "-";
    const violationsArray = ticketDetails?.reasons?.length && ticketDetails?.reasons.map((item) => item.title);
    const violations = violationsArray?.length && violationsArray.join(", ");

    const updateStatusPermisstion = ticketDetails.dispute_info ? ticketDetails.dispute_info : false;
    const domain = ticketDetails?.property?.domain || ticketDetails?.property?.subdomain;

    const stateAbbreviation = this.props?.ticketDetails?.languageData?.stateAbbreviation;
    const feesString = this.props?.ticketDetails?.languageData?.feesString;
    const createdAt = this.props?.ticketDetails?.languageData?.created_at;
    const carColor = this.props?.ticketDetails?.languageData?.car_color;
    const plate = `${ticketDetails.lpn} ${ticketDetails.country_name || ""} ${stateAbbreviation || ""}`;

    const ticketDetailsLanguage = this.props?.ticketDetails;
    const languageData = ticketDetailsLanguage?.languageData;
    const headerData = languageData?.headerData;

    const {
      date,
      plate: headerPlate,
      citationNo,
      enforcer,
      address,
      violation,
      fee,
      internalNote,
      externalNote,
      status,
      vin,
      car_make,
      car_model,
      car_color,
      zone,
      state,
    } = headerData || {};
    this.setState({
      ticketId: ticketId,
      lpn: ticketDetails?.lpn ? plate : "-",
      plate_number: ticketDetails?.lpn || "-",
      chargable_fee: ticketDetails?.fee ? ticketDetails.fee : 0,
      notes_internal: notes_internal,
      notes_external: notes_external,
      redirect_from: redirect_from,
      isDriver: isDriver,
      created_at: createdAt,
      ticket_no: ticketDetails?.ticket_no || "-",
      status: ticketDetails?.status || "-",
      state: ticketDetails?.state || "-",
      address: ticketDetails?.property?.address || "-",
      violations: violations,
      enfocer: ticketDetails?.user?.last_name || "-",
      car_model: ticketDetails?.car_model || "-",
      car_make: ticketDetails?.car_make || "-",
      car_color: ticketDetails?.car_color || "-",
      VIN: ticketDetails?.vin || "-",
      photos: ticketDetails?.ticket_photos || [],
      ticketData: ticketDetails,
      updateStatusPermisstion: updateStatusPermisstion,
      zone: domain,
      timezone: ticketDetails?.property?.timezone,
      feesString: feesString,
      fee: ticketDetails?.language === "fn" ? `${ticketDetails?.fee}$` : `$${ticketDetails?.fee}`,
      date: createdAt,
      color: carColor,
      headerDate: date,
      headerPlate: headerPlate,
      headerCitationNo: citationNo,
      headerEnforcer: enforcer,
      headerAdderess: address,
      headerViolation: violation,
      headerFee: fee,
      headerInternalNote: internalNote,
      headerExternalNote: externalNote,
      headerStatus: status,
      headerVIN: vin,
      headerMake: car_make,
      headerModel: car_model,
      headerColor: car_color,
      headerZone: zone,
      headerState: state,
    });
    await this.props.selectedLanguage();
    _i18n.changeLanguage(ticketLang);
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  imageShowInChatBox = async (driverMessageList) => {
    const data = driverMessageList.map((item) => {
      if (item.type.toLowerCase() === "files") {
        const type = item?.mimetype?.split("/")[1];
        const allExtension = ["jpg", "png", "JPG", "JPEG", "jpeg", "PNG"];
        const filterData = allExtension.filter((i) => i === type);
        if (filterData.length) {
          item.imageValue = true;
        } else {
          item.imageValue = false;
        }
      }
      return item;
    });
    return data;
  };

  handleRedirectTicketList = () => {
    const history = require("../../history").default;
    history.push({
      pathname: "/citation-list",
      state: {
        plate: this.state.plate_number,
      },
    });
  };

  handleChange = (event) => {
    this.setState(
      {
        message: event.target.value,
      },
      () => {
        if (this.state?.message?.length) {
          this.setState({
            isSendTextDisabled: true,
          });
        } else {
          this.setState({
            isSendTextDisabled: false,
          });
        }
      },
    );
  };

  handleUpdateChange = (event) => {
    const status = event.target.value;
    this.setState(
      {
        ticket_status: status,
        selectStatus: event.target.value,
      },
      () => {
        if (this.state.selectStatus === "Paid Cash") {
          this.setState({
            reduce_amount: "",
            amount: "",
            paid_check: "",
            paid_creditcard: "",
            paid_interac: "",
            reduceAmountDisabled: true,
            paidCashDisabled: false,
            paidCheckDisabled: true,
            paidInteracDisabled: true,
            paidCardDisabled: true,
            amountDisabled: false,
          });
          if (!this.state.paid_cash) {
            this.setState({
              isValidStatusUpdateForm: false,
            });
          }
        } else if (this.state.selectStatus === "Paid Check") {
          this.setState({
            reduce_amount: "",
            amount: "",
            paid_cash: "",
            paid_creditcard: "",
            paid_interac: "",
            reduceAmountDisabled: true,
            paidCashDisabled: true,
            paidCheckDisabled: false,
            paidInteracDisabled: true,
            paidCardDisabled: true,
            amountDisabled: false,
          });
          if (!this.state.paid_check) {
            this.setState({
              isValidStatusUpdateForm: false,
            });
          }
        } else if (this.state.selectStatus === "Paid Credit Card Manually") {
          this.setState({
            reduce_amount: "",
            amount: "",
            paid_cash: "",
            paid_check: "",
            paid_interac: "",
            reduceAmountDisabled: true,
            paidCashDisabled: true,
            paidCheckDisabled: true,
            paidInteracDisabled: true,
            paidCardDisabled: false,
            amountDisabled: false,
          });
          if (!this.state.paid_creditcard) {
            this.setState({
              isValidStatusUpdateForm: false,
            });
          }
        } else if (this.state.selectStatus === "Paid Interac") {
          this.setState({
            reduce_amount: "",
            paid_cash: "",
            paid_check: "",
            paid_creditcard: "",
            amount: "",
            reduceAmountDisabled: true,
            paidCashDisabled: true,
            paidCheckDisabled: true,
            paidInteracDisabled: false,
            paidCardDisabled: true,
            amountDisabled: false,
          });
          if (!this.state.paid_interac) {
            this.setState({
              isValidStatusUpdateForm: false,
            });
          }
        } else if (this.state.selectStatus === "Reduce_Amount") {
          this.setState({
            paid_cash: "",
            paid_check: "",
            paid_creditcard: "",
            paid_interac: "",
            amount: "",
            reduceAmountDisabled: false,
            paidCashDisabled: true,
            paidCheckDisabled: true,
            paidInteracDisabled: true,
            paidCardDisabled: true,
            amountDisabled: true,
          });
          if (!this.state.reduce_amount) {
            this.setState({
              isValidStatusUpdateForm: false,
            });
          }
        } else {
          this.setState({
            isValidStatusUpdateForm: true,
            reduceAmountDisabled: true,
            paidCashDisabled: true,
            paidCheckDisabled: true,
            paidInteracDisabled: true,
            paidCardDisabled: true,
            amountDisabled: false,
            reduce_amount: "",
            amount: "",
            paid_cash: "",
            paid_check: "",
            paid_creditcard: "",
            paid_interac: "",
          });
        }
      },
    );
  };

  handleRemarkChange = ({ target }) => {
    this.setState({ [target.name]: target.value }, async () => {
      const { ticket_status, reduce_amount } = this.state;
      if (ticket_status === "Reduce_Amount") {
        if (reduce_amount) {
          this.setState({
            isValidStatusUpdateForm: true,
            costErrorMessage: "",
          });
          if (reduce_amount <= 0) {
            this.setState({
              costErrorMessage: "Cost cann't be less than 1",
              isValidStatusUpdateForm: false,
            });
          }
        } else {
          this.setState({
            isValidStatusUpdateForm: false,
          });
        }
      } else {
        this.setState({
          isValidStatusUpdateForm: true,
        });
      }
    });
  };

  handleSendText = async () => {
    const { message, ticketId, isDriver, ticket_no, fileValue } = this.state;
    const messageObjectToBeSent = {
      ticketNo: ticketId,
      message: message,
      ticket_no: ticket_no,
      files: fileValue,
    };
    await this.props.sendText(messageObjectToBeSent, isDriver);
    if (this.props.api_response) {
      let messageList_ = this.state.messageList;
      messageList_.push(this.props.driverMessage);
      messageList_ = await this.imageShowInChatBox(messageList_);
      this.setState({
        show_alert: this.props.api_response,
        api_success_message: this.props.api_success_message,
        message: "",
        messageList: messageList_,
        isSendTextDisabled: false,
        isUpload: false,
        fileValue: "",
        filename: "",
      });
      setTimeout(
        () =>
          this.setState({
            show_alert: false,
          }),
        4000,
      );
    }
  };

  handleUpdatedata = async () => {
    const { selectStatus, ticketId, reduce_amount, paid_cash, paid_check, paid_creditcard, paid_interac, amount } = this.state;
    const updateData = {
      status: selectStatus,
      ticket_id: ticketId,
    };
    if (selectStatus === "Paid Cash") {
      updateData.remark = paid_cash;
    } else if (selectStatus === "Paid Check") {
      updateData.remark = paid_check;
    } else if (selectStatus === "Paid Credit Card Manually") {
      updateData.remark = paid_creditcard;
    } else if (selectStatus === "Paid Interac") {
      updateData.remark = paid_interac;
    } else if (selectStatus === "Reduce_Amount") {
      updateData.remark = reduce_amount;
    }
    updateData.amount = amount;
    await this.props.ticketDisputeStatusChange(updateData);
    if (this.props.api_response) {
      this.setState(
        {
          show_alert: true,
          api_success_message: this.props.api_success_message,
          isValidStatusUpdateForm: false,
        },
        () => {
          setTimeout(() => {
            this.setState({ show_alert: true });
          }, 500);
          const history = require("../../history").default;
          history.push({
            pathname: "/citation-list",
          });
        },
      );
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
    }
  };

  handleBackAction = () => {
    const history = require("../../history").default;
    history.push({
      pathname: "/citation-list",
    });
  };

  handleShowTicketDetails = async () => {
    this.setState({ show: true });
  };

  handleClick = (e) => {
    const { target = {} } = e || {};
    target.value = "";
  };

  convertFile = (event) => {
    const MAX_FILE_SIZE = 5120; // 5MB
    const files = event.target.files;

    if (event.target.value === "") {
      this.setState({
        uploadErrorMsg: "Not Selected file ERR",
        isUpload: false,
      });
    } else {
      this.setState({
        uploadErrorMsg: "",
        isUpload: true,
        isSendTextDisabled: true,
        imageValue: event.target.value,
      });
    }

    if (files) {
      const fileRef = files[0] || "";
      const fileSizeKiloBytes = fileRef.size / 1024;

      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        this.setState({
          uploadErrorMsg: "File size is greater than maximum limit 5 MB",
          isUpload: false,
          imageValue: "",
          isSendTextDisabled: false,
          message: "",
          filename: "",
        });
        return;
      }
      const validExtensions = ["pdf", "doc", "docx", "jpg", "png", "zip", "arj", "JPG", "txt", "JPEG", "jpeg", "PNG"];
      const fileref_type = fileRef.name.split(".").pop() || "";
      const name = fileRef.name.substr(0, fileRef.name.lastIndexOf("."));
      console.log(name);
      const epoch = new Date().getTime();
      const file_name_msg = "File " + '"' + name + "-" + epoch + "." + fileref_type + '"' + " was successfully sent.";
      this.setState({
        filename: "File Selected:" + " " + name + "." + fileref_type,
      });

      if (validExtensions.includes(fileref_type)) {
        this.setState({
          // uploadErrorMsg: "File size is greater than maximum limit 5 MB",
          isUpload: true,
          imageValue: "",
          message: file_name_msg,
          fileValue: fileRef,
        });
      } else {
        this.setState({
          uploadErrorMsg: "Invalid file format. Allowed formats : jpg, jpeg, png, txt, pdf, doc, docx, zip, arj.",
          isUpload: false,
          imageValue: "",
          isSendTextDisabled: false,
          message: "",
          filename: "",
        });
      }
    }
  };

  render() {
    const {
      show,
      message,
      messageList,
      show_alert,
      api_success_message,
      ticket_status,
      isValidStatusUpdateForm,
      reduce_amount,
      amountDisabled,
      amount,
      notes_internal,
      notes_external,
      ticket_no,
      address,
      status,
      violations,
      lpn,
      enfocer,
      car_model,
      car_make,
      VIN,
      state,
      isSendTextDisabled,
      reduceAmountDisabled,
      paidCardDisabled,
      paidCashDisabled,
      paidCheckDisabled,
      paidInteracDisabled,
      photos,
      paid_cash,
      paid_check,
      paid_creditcard,
      paid_interac,
      zone,
      imageValue,
      uploadErrorMsg,
      isUpload,
      filename,
      timezone,
      feesString,
      color,
      fee,
      date,
      headerDate,
      headerPlate,
      headerCitationNo,
      headerEnforcer,
      headerAdderess,
      headerViolation,
      headerFee,
      headerInternalNote,
      headerExternalNote,
      headerStatus,
      headerVIN,
      headerMake,
      headerModel,
      headerColor,
      headerZone,
      headerState,
    } = this.state;
    return (
      <div style={{ flex: 1 }}>
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex">
                    <i className="fa fa-arrow-left mt-1 mr-2 Pointer" aria-hidden="true" onClick={this.handleBackAction}></i> Dispute
                    <h4 className="card-title"></h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col lg="12" md="12" sm="12">
                      <div className="table-responsive overflow-auto">
                        <Table className="table table-bordered table-striped table-hover fs-14 citation-table">
                          <thead>
                            <tr>
                              <th>{headerDate}</th>
                              <th>{headerPlate}</th>
                              <th>{headerCitationNo}</th>
                              <th>{headerEnforcer}</th>
                              <th>{headerAdderess}</th>
                              <th>{headerViolation}</th>
                              <th>{headerFee}</th>
                              <th>{headerInternalNote}</th>
                              <th>{headerExternalNote}</th>
                              <th>{headerStatus}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-nowrap">{date}</td>
                              <td>{lpn}</td>
                              <td>
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    cursor: "pointer",
                                    color: "#303b93",
                                  }}
                                  onClick={() => this.handleShowTicketDetails()}
                                >
                                  <span className="icon-tickit2 fs-20px mr-1"></span>
                                  {ticket_no}
                                </div>
                              </td>
                              <td>{enfocer}</td>
                              <td>{address}</td>
                              <td>{violations}</td>
                              <td>{fee}</td>
                              <td>
                                {notes_internal ? (
                                  <OverlayTrigger
                                    delay={{ show: 250, hide: 400 }}
                                    placement={"top"}
                                    overlay={<Tooltip id={`tooltip-qw`}>{notes_internal}</Tooltip>}
                                  >
                                    <div className="text-nowrap text-truncate pr-2">{`${notes_internal.substring(0, 10)} ${
                                      notes_internal.toString().length > 10 ? "...." : ""
                                    }`}</div>
                                  </OverlayTrigger>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {notes_external ? (
                                  <OverlayTrigger
                                    delay={{ show: 250, hide: 400 }}
                                    placement={"top"}
                                    overlay={<Tooltip id={`tooltip-qw`}>{notes_external}</Tooltip>}
                                  >
                                    <div className="text-nowrap text-truncate pr-2">{`${notes_external.substring(0, 10)}${
                                      notes_external.toString().length > 10 ? "...." : ""
                                    }`}</div>
                                  </OverlayTrigger>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td className="text-nowrap">{status ? <Translation>{(t) => <> {t(`${status}`)}</>}</Translation> : ""}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="6">
                      <div className="chat-section">
                        <div>
                          <div className="text-right chatbox">
                            <PerfectScrollbar className="chat-msg-scroll">
                              {messageList?.length
                                ? messageList.map((item, index) => {
                                    return (
                                      <div
                                        className={item.role === "Support" ? "d-flex justify-content-end mb-3" : "d-flex justify-content-start mb-3"}
                                        key={index}
                                      >
                                        <div
                                          className={
                                            item.role === "Support"
                                              ? "d-flex justify-content-end flex-column"
                                              : "d-flex justify-content-start flex-column text-left"
                                          }
                                        >
                                          <div className="chat-msg">
                                            {item.type.toLowerCase() === "files" ? (
                                              item.imageValue ? (
                                                <div className="chatImagBox">
                                                  <img src={item.fileUrl} width="100" alt="" className="chat-imgSrc" />
                                                  <Button
                                                    id="ignoreButton"
                                                    title={item.filename}
                                                    type="button"
                                                    style={{ fontSize: 16 }}
                                                    onClick={() => {
                                                      document.getElementById(item.id).click();
                                                    }}
                                                    className="close-btn py-0 chatBtnsrc"
                                                    data-dismiss="modal"
                                                  >
                                                    <i className="fa fa-download" aria-hidden="true"></i>
                                                  </Button>
                                                </div>
                                              ) : (
                                                <div className="chatImagBox-w-img">
                                                  {item.filename}
                                                  <Button
                                                    id="ignoreButton"
                                                    title={item.filename}
                                                    type="button"
                                                    onClick={() => {
                                                      document.getElementById(item.id).click();
                                                    }}
                                                    style={{ fontSize: 16 }}
                                                    className="close-btn py-0 chatBtnsrc"
                                                    data-dismiss="modal"
                                                  >
                                                    <i className="fa fa-download" aria-hidden="true"></i>
                                                  </Button>
                                                </div>
                                              )
                                            ) : (
                                              item.message
                                            )}
                                          </div>
                                          <div className={"d-flex justify-content-center align-items-center"}>
                                            <div className="fs-12px mr-2 mt-1">
                                              {timezone && momentTimeZone.tz(item?.createdAt, "UTC").tz(timezone).format("lll")}
                                            </div>
                                            {item.role === "Driver" ? (
                                              <div className="text-right">
                                                <img src={driverDispute} width={12} />
                                              </div>
                                            ) : (
                                              <div className="text-right">
                                                <img src={adminDispute} width={12} />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <a id={item.id} href={item.fileUrl} style={{ display: "none" }}>
                                          link
                                        </a>
                                      </div>
                                    );
                                  })
                                : null}
                            </PerfectScrollbar>
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="position-relative">
                            <Translation>
                              {(t) => (
                                <Form.Control
                                  as="textarea"
                                  rows="1"
                                  type="text"
                                  placeholder={t("EnterMessage")}
                                  name="message"
                                  className="chattypearea border"
                                  value={message}
                                  onChange={this.handleChange}
                                />
                              )}
                            </Translation>

                            <Button
                              variant="primary"
                              type="button"
                              className="mr-3 c-pointer send-msg"
                              disabled={!isSendTextDisabled}
                              onClick={this.handleSendText}
                            >
                              <i className="fa fa-paper-plane" aria-hidden="true"></i>
                            </Button>
                          </div>
                        </div>
                        <br />

                        <div>
                          <Form.Label htmlFor="file-input" className="mr-3 c-pointer custom-file-upload uploadCss btn btn-primary">
                            <i className="fa fa-upload " aria-hidden="true"></i>
                            {"Select File"}
                          </Form.Label>

                          <Form.Control
                            type="file"
                            value={imageValue}
                            id="file-input"
                            onChange={this.convertFile}
                            onClick={this.handleClick}
                            title={"No File Selected"}
                            placeholder={"Select File"}
                            style={{ display: "none" }}
                          ></Form.Control>

                          <p className="info-message uploadMbSize mb-1">Max Upload : 5MB</p>

                          <p className="help-block">{uploadErrorMsg}</p>
                        </div>
                        <div className="uploadMbSize">{filename}</div>
                        <hr />

                        <Button disabled={!isUpload} onClick={this.handleSendText}>
                          Upload File
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <Button variant="primary" type="button" onClick={() => this.handleRedirectTicketList()}>
                        View ticket history for this plate
                      </Button>

                      <div className="border rounded mt-4">
                        <h4 className="mt-0 p-3 mb-0 pb-0">
                          <div as="h4" className="m-0">
                            Update
                          </div>
                        </h4>
                        <Form className="upfate-form">
                          <div className="px-3">
                            <Row>
                              <Col>
                                <div className="d-flex mb-1">
                                  <img src={cancelTicket} width="14" className="mr-2 tiketupdate-icon" />
                                  <Form.Check
                                    inline
                                    label="Cancel Ticket"
                                    name="Close_Ticket"
                                    type="radio"
                                    value="Close_Ticket"
                                    id="1"
                                    checked={ticket_status === "Close_Ticket"}
                                    onChange={(e) => {
                                      this.handleUpdateChange(e);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="d-flex align-items-center col-sm">
                                <img src={reduceAmount} width="14" className="mr-2 tiketupdate-icon" />
                                <Form.Check
                                  inline
                                  label="Reduce Ticket"
                                  name="Reduce_Amount"
                                  type="radio"
                                  value="Reduce_Amount"
                                  id="2"
                                  checked={ticket_status === "Reduce_Amount"}
                                  onChange={(e) => {
                                    this.handleUpdateChange(e);
                                  }}
                                />{" "}
                              </Col>
                              <Col className="col-auto">
                                <input
                                  type="number"
                                  placeholder="Reduce to: ex: 10"
                                  className="form-control form-control-sm w-150px form-control-sm ml-3"
                                  name="reduce_amount"
                                  value={reduce_amount}
                                  disabled={reduceAmountDisabled}
                                  min={1}
                                  onChange={(e) => {
                                    this.handleRemarkChange(e);
                                  }}
                                ></input>
                                <span className="help-block">{this.state.costErrorMessage}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="d-flex mb-1">
                                  <img src={closeDispute} width="14" className="mr-2 tiketupdate-icon ticket-round-icon" />
                                  <Form.Check
                                    inline
                                    label="Reject Dispute - Driver must pay full"
                                    name="Close_Dispute"
                                    type="radio"
                                    value="Close_Dispute"
                                    id="3"
                                    checked={ticket_status === "Close_Dispute"}
                                    onChange={(e) => {
                                      this.handleUpdateChange(e);
                                    }}
                                  />{" "}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="d-flex mb-1">
                                  <img src={closeDispute} width="14" className="mr-2 tiketupdate-icon ticket-round-icon" />
                                  <Form.Check
                                    inline
                                    label="Reject Dispute - Driver must pay full. Lock disputes"
                                    name="Close_Dispute_Lock"
                                    type="radio"
                                    value="Close_Dispute_Lock"
                                    id="4"
                                    checked={ticket_status === "Close_Dispute_Lock"}
                                    onChange={(e) => {
                                      this.handleUpdateChange(e);
                                    }}
                                  />{" "}
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="px-3 bg-light-gray">
                            <Row>
                              <Col className="d-flex align-items-center col-sm">
                                <img src={paidCash} width="18" className="mr-2 tiketupdate-icon" />
                                <Form.Check
                                  inline
                                  label="Paid - Cash :"
                                  name="paid_cash"
                                  type="radio"
                                  value="Paid Cash"
                                  id="5"
                                  checked={ticket_status === "Paid Cash"}
                                  onChange={(e) => {
                                    this.handleUpdateChange(e);
                                  }}
                                />
                              </Col>
                              <Col className="col-auto">
                                <input
                                  type="text"
                                  name="paid_cash"
                                  className="form-control w-150px form-control-sm"
                                  value={paid_cash}
                                  disabled={paidCashDisabled}
                                  onChange={(e) => {
                                    this.handleRemarkChange(e);
                                  }}
                                  placeholder="Notes"
                                ></input>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="d-flex align-items-center col-sm">
                                <img src={paidInterac} width="14" className="mr-2 tiketupdate-icon" />
                                <Form.Check
                                  inline
                                  label="Paid - Interac :"
                                  name="paid_interac"
                                  type="radio"
                                  value="Paid Interac"
                                  id="6"
                                  checked={ticket_status === "Paid Interac"}
                                  onChange={(e) => {
                                    this.handleUpdateChange(e);
                                  }}
                                />
                              </Col>
                              <Col className="col-auto">
                                <input
                                  type="text"
                                  name="paid_interac"
                                  className="form-control w-150px form-control-sm"
                                  value={paid_interac}
                                  disabled={paidInteracDisabled}
                                  onChange={(e) => {
                                    this.handleRemarkChange(e);
                                  }}
                                  placeholder="Notes"
                                ></input>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="d-flex align-items-center col-sm">
                                <img src={paidCreditcard} width="14" className="mr-2 tiketupdate-icon" />
                                <Form.Check
                                  inline
                                  label="Paid - Credit Card Manually :"
                                  name="paid_creditcard"
                                  className="mr-0"
                                  type="radio"
                                  id="7"
                                  value="Paid Credit Card Manually"
                                  checked={ticket_status === "Paid Credit Card Manually"}
                                  onChange={(e) => {
                                    this.handleUpdateChange(e);
                                  }}
                                />
                              </Col>
                              <Col className="col-auto">
                                <input
                                  type="text"
                                  name="paid_creditcard"
                                  className="form-control w-150px form-control-sm"
                                  value={paid_creditcard}
                                  disabled={paidCardDisabled}
                                  onChange={(e) => {
                                    this.handleRemarkChange(e);
                                  }}
                                  placeholder="Notes"
                                ></input>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="d-flex align-items-center col-sm">
                                <img src={paidCheck} width="14" className="mr-2 tiketupdate-icon" />
                                <Form.Check
                                  inline
                                  label="Paid - Check :"
                                  name="paid_check"
                                  type="radio"
                                  id="8"
                                  value="Paid Check"
                                  checked={ticket_status === "Paid Check"}
                                  onChange={(e) => {
                                    this.handleUpdateChange(e);
                                  }}
                                />
                              </Col>
                              <Col className="col-auto">
                                <input
                                  type="text"
                                  name="paid_check"
                                  className="form-control w-150px form-control-sm"
                                  value={paid_check}
                                  disabled={paidCheckDisabled}
                                  onChange={(e) => {
                                    this.handleRemarkChange(e);
                                  }}
                                  placeholder="Notes"
                                ></input>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="d-flex align-items-center col-sm">
                                <label className="pl-0 pl-md-5">Amount: </label>
                              </Col>
                              <Col className="col-auto">
                                <input
                                  type="number"
                                  placeholder="Amount"
                                  className="form-control w-150px form-control-sm"
                                  name="amount"
                                  value={amount}
                                  disabled={amountDisabled}
                                  min={0}
                                  onChange={(e) => {
                                    this.handleRemarkChange(e);
                                  }}
                                ></input>
                                <span className="help-block">{this.state.amountErrorMessage}</span>
                              </Col>
                            </Row>
                          </div>
                          <div className="d-flex justify-content-end pt-2 pb-2 pr-3">
                            <Button
                              id="ignoreButton3"
                              className="btn-fill"
                              type="button"
                              variant="info"
                              size="sm"
                              disabled={!isValidStatusUpdateForm}
                              onClick={this.handleUpdatedata}
                            >
                              Submit
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                  <div></div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* ======================MODAL HISTORY================================ */}
          <Modal
            id="ticketDetails"
            lg={"medium"}
            show={show}
            onHide={this.handleClose}
            dialogClassName="modal-90w"
            fullscreen={"sm-down"}
            className="modalData"
          >
            <Modal.Header id="ignoreHeader" className="d-flex justify-content-between bg-white align-items-center">
              <h4 className="m-0 font-weight-bold">
                <Modal.Title as="h4" className="m-0">
                  <Translation>{(t) => <p>{t("TicketDetails")}</p>}</Translation>
                </Modal.Title>
              </h4>
              <Button id="ignoreButton" title={"Close"} type="button" onClick={this.handleClose} className="close-btn py-0" data-dismiss="modal">
                <i className="nc-icon nc-simple-remove text-danger"></i>
              </Button>
            </Modal.Header>
            <Modal.Body>
              {" "}
              <Table className="table table-bordered table-striped table-hover tickit-details-table">
                <thead></thead>
                <tbody>
                  <tr>
                    <th>{headerCitationNo}</th>
                    <td>{ticket_no}</td>
                  </tr>
                  <tr>
                    <th>{headerDate}</th>
                    <td>{date}</td>
                  </tr>
                  <tr>
                    <th>{headerPlate}</th>
                    <td>{lpn}</td>
                  </tr>
                  <tr>
                    <th>{headerVIN}</th>
                    <td>{VIN}</td>
                  </tr>
                  <tr>
                    <th>{headerMake}</th>
                    <td>{car_make}</td>
                  </tr>
                  <tr>
                    <th>{headerModel}</th>
                    <td>{car_model}</td>
                  </tr>
                  <tr>
                    <th>{headerColor}</th>
                    <td>{color}</td>
                  </tr>
                  <tr>
                    <th>{headerEnforcer}</th>
                    <td>{enfocer}</td>
                  </tr>
                  <tr>
                    <th>{headerAdderess}</th>
                    <td>{address}</td>
                  </tr>
                  <tr>
                    <th>{headerZone}</th>
                    <td>{zone}</td>
                  </tr>
                  <tr>
                    <th className="mb-0"> {headerViolation}</th>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: feesString,
                      }}
                    />
                  </tr>
                  <tr>
                    <th>{headerFee}</th>
                    <td>{fee}</td>
                  </tr>

                  <tr>
                    <th>{headerStatus}</th>
                    <td>
                      <Translation>{(t) => <>{t(String(status))}</>}</Translation>
                    </td>
                  </tr>
                  <tr>
                    <th>{headerState}</th>
                    <td>
                      <Translation>{(t) => <>{t(String(state))}</>}</Translation>
                    </td>
                  </tr>
                  <tr>
                    <th>{headerInternalNote}</th>
                    <td>
                      {notes_internal ? (
                        <OverlayTrigger
                          delay={{ show: 250, hide: 400 }}
                          placement={"top-start"}
                          overlay={<Tooltip id={`tooltip-qw`}>{notes_internal}</Tooltip>}
                        >
                          <div className="text-nowrap text-truncate pr-2">{`${notes_internal.substring(0, 10)}${
                            notes_internal.toString().length > 10 ? "...." : ""
                          }`}</div>
                        </OverlayTrigger>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>{headerExternalNote}</th>
                    <td>
                      {notes_external ? (
                        <OverlayTrigger
                          delay={{ show: 250, hide: 400 }}
                          placement={"top-start"}
                          overlay={<Tooltip id={`tooltip-qw`}>{notes_external}</Tooltip>}
                        >
                          <div className="text-nowrap text-truncate pr-2">{`${notes_external.substring(0, 10)}${
                            notes_external.toString().length > 10 ? "...." : ""
                          }`}</div>
                        </OverlayTrigger>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Translation>{(t) => <>{t("Image")}</>}</Translation>
                    </th>
                    <td>
                      {(photos ?? []).map((e, key) => {
                        return (
                          <img
                            key={key}
                            style={{ marginBottom: 0, marginRight: 5 }}
                            src={e.photo}
                            alt=""
                            height="80"
                            width="80"
                            className="image-preview"
                            accept="image/png, image/jpeg"
                          />
                        );
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Modal.Body>
          </Modal>
          {show_alert && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, Logindetails, TicketDetails }) => {
  const { timezone, property } = UserProfileDetails;
  const { userProfileDetails, backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { api_response, api_success_message } = APIResponseDetails;
  const { driverMessage, driverMessageList, ticketDetails, updateLanguage } = TicketDetails;
  return {
    property,
    timezone,
    api_response,
    driverMessage,
    driverMessageList,
    userProfileDetails,
    api_success_message,
    ticketDetails,
    main_body,
    updateLanguage,
  };
};

const mapStateToDispatch = {
  sendText: TicketActions.sendText,
  getMessageList: TicketActions.getMessageList,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  ticketDisputeStatusChange: TicketActions.statusChange,
  selectedLanguage: TicketActions.selectedLanguage,
};

export default connect(mapStateToProps, mapStateToDispatch)(Dispute);

import React, { Component } from "react";
import { connect } from "react-redux";
import { SuperAdminAction } from "../../Actions";

import { Card, Container, Row, Col } from "react-bootstrap";
import { Translation } from "react-i18next";
import _i18n from "../Language/i18n";
import isEqual from "lodash/isEqual";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      support_contact: "647-111-2222",
      support_email: "jefferson@locomobi.com",
      property: this.props.property,
      unit_language: this.props?.selectedLang || this.props?.language,
    };
  }

  componentDidMount() {
    _i18n.changeLanguage(this.state.unit_language);

    this.props.getContactSupport(this.props.PropertyId).then((data) => {
      if (data && Object.keys(data).length) {
        this.setState({
          support_contact: data.support_contact,
          support_email: data.support_email,
        });
      }
    });
    const selectedPropertyName = this.state.property?.property_name;
    const selectedDomainName = this.state.property?.domain;
    this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.selectedLang, this.props.selectedLang)) {
      this.setState({
        unit_language: this.props.selectedLang,
      });
      _i18n.changeLanguage(this.props.selectedLang);
    }
  }

  render() {
    const { support_contact, support_email } = this.state;

    const email_href =
      this.props.user_type === "admin"
        ? "mailto: support@locomobiworld.com"
        : "mailto: " + support_email;
    return (
      <>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="contact-container">
                <Card.Header
                  className="text-center"
                  style={{ backgroundColor: this.props.main_body }}
                >
                  <Card.Title as="h4"><Translation>{(t) => <>{t("Support")}</>}</Translation></Card.Title>
                  <p className="card-category"><Translation>{(t) => <>{t("Need Help?")}</>}</Translation></p>
                </Card.Header>
                <Card.Body>
                  <div className="text-center">
                    <p><Translation>{(t) => <>{t("For support, contact")}</>}</Translation></p>
                    <p>
                      {/* Contact */}
                      <a
                        className="contact-link"
                        href={`tel: ${support_contact}`}
                      >
                        <i className="nc-icon nc-mobile" />
                        {support_contact}
                      </a>
                      <Translation>{(t) => <>{t("or")}</>}</Translation>
                      <a className="contact-link" href={email_href}>
                        <i className="nc-icon nc-email-85" />
                        {this.props.user_type === "admin"
                          ? "support@locomobiworld.com"
                          : support_email}
                      </a>
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails, CurrentPermitDetails }) => {
  const { property_id, property, user_type, language } = UserProfileDetails;
  const { updateUnitLanguage: selectedLang } = CurrentPermitDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  return {
    PropertyId: property_id,
    property,
    user_type,
    main_body,
    selectedLang,
    language
  };
};

const mapStateToDispatch = {
  getContactSupport: SuperAdminAction.getContactSupport,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(Contact);

import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

const otherLabel = "Other";
const otherValue = "Add note here...";

const getOptions = (str) => {
  try {
    if (str) {
      if (typeof JSON.parse(str) === "string") {
        return JSON.parse(JSON.parse(str)).map((x) => ({ label: x, value: x }));
      } else {
        return JSON.parse(str).map((x) => ({ label: x, value: x }));
      }
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getOther = (val) => ({ label: otherLabel, value: val });

const NoteSelector = ({
  setNote,
  quickPermitSetupDetail,
  note,
  labelFontSize,
}) => {
  const [noteOptions, setNoteOptions] = useState([]);
  const [other, setOther] = useState(false);
  const [initialNote, setInitialNote] = useState(otherValue);
  const [start, setStart] = useState(true);
  const getValue = useCallback(
    (val, allNoteOptions, isOt = false) => {
      if (val !== "" && !val) {
        return null;
      }
      if (isOt && val === "") return null;
      return {
        label:
          allNoteOptions?.find((x) => x.value === val)?.label || otherLabel,
        value:
          allNoteOptions?.find((x) => x.value === val)?.value || otherValue,
      };
    },
    [noteOptions, otherLabel, otherValue]
  );
  useEffect(() => {
    setInitialNote(note || otherValue);
  }, []);

  useEffect(() => {
    setNoteOptions([
      ...getOptions(quickPermitSetupDetail?.note_options),
      getOther(initialNote),
    ]);
  }, [setNote, quickPermitSetupDetail, labelFontSize]);

  useEffect(() => {
    const isOther = getValue(note, noteOptions, start)?.label === otherLabel;
    setOther(isOther);
  }, [note, start]);

  const changeNote = ({ value }) => {
    setStart(false);
    setNote(getValue(value, noteOptions)?.label === otherLabel ? "" : value);
  };

  const isOptions = useMemo(() => {
    return getOptions(quickPermitSetupDetail?.note_options)?.length > 0;
  }, [quickPermitSetupDetail?.note_options]);

  const [open, setOpen] = useState(false);

  return (
    <div style={{ height: open ? "200px" : "auto" }}>
      <label style={labelFontSize}>
        {quickPermitSetupDetail?.name_of_note || "Notes"}
      </label>
      {isOptions && (
        <Select
          onMenuOpen={() => setOpen(true)}
          onMenuClose={() => setOpen(false)}
          value={getValue(note, noteOptions, start)}
          onChange={changeNote}
          options={noteOptions}
          placeholder="Select Note"
        />
      )}
      {(other === true || isOptions === false) && (
        <>
          <Form.Control
            as="textarea"
            className="mt-2"
            value={note}
            placeholder="Add notes here..."
            name="note"
            onChange={(e) => {
              setStart(false);
              setNote(e.target.value);
            }}
          ></Form.Control>
        </>
      )}
    </div>
  );
};

export default NoteSelector;

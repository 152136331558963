import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App/App";
import { Provider } from "react-redux";
import reduxStore from "./App/redux_store";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from "./reportWebVitals";
import Loader from "./App/Components/Loader/Loader";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "react-datetime/css/react-datetime.css";
import "./assets/css/demo.css";
import SimpleReactLightbox from "simple-react-lightbox";
import * as serviceWorker from "./serviceWorkerRegistration";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";

if (process.env.REACT_APP_DOMAIN === "local") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

if (process.env.REACT_APP_FRONT_ENV === "prod") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
if (process.env.REACT_APP_FRONT_ENV === "dev") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={reduxStore.store}>
    <PersistGate loading={<Loader />} persistor={reduxStore.persistor}>
      <SimpleReactLightbox>
        <App />
      </SimpleReactLightbox>
    </PersistGate>
  </Provider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

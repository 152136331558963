const reducerHandler = (state, action, actionHandler) => {
  switch (action.type) {
    case actionHandler.REQUEST:
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    case actionHandler.SUCCESS:
      return {
        ...state,
        isLoading: false,
        loaded: true,
        data: action.data?.data,
        error: null,
        message: action.data?.message,
        success: true,
      };
    case actionHandler.FAILURE:
      return {
        ...state,
        isLoading: false,
        loaded: true,
        error: action.data,
        data: null,
        message: action.data?.message,
        success: false,
      };
    default:
      return state;
  }
};

export default reducerHandler;

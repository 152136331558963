import React, { Component } from "react";
import { Card, Row, Col, Button, Form, Dropdown, Modal, FormLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { saveAs } from "file-saver";
import { connect } from "react-redux";
import styled from "styled-components";
import ModalLoader from "../Loader/ModalLoader";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import download from "../../../assets/img/svg/ticket-icons/citation-download.svg";
import { SuperAdminAction, TicketActions, EnforcementActions } from "../../Actions";
import Loader from "../Loader/Loader";
import { tableHeightScrollBarsTab, findBySearch, utcToSpecificTimeZone, scroolToTop, isEmailValid } from "../helper";
import isEqual from "lodash/isEqual";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropertDetails from "../LandingPage/PropertySelection";
import moment from "moment";
import SendMailModal from "../Ticket/SendMailModal";
import { isMobile } from "react-device-detect";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";
class RevenueReport extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.state = {
      PropertyId: this.props.PropertyId,
      receiptPropertyId: "",
      ticketList: [],
      loader: false,
      searchValue: "",
      api_response: false,
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      status: "",
      ticketPdfUrl: "",
      isSendMailModalOpen: false,
      emailToSend: "",
      isMailFormValid: false,
      selectedReceiptId: "",
      selectedProperty: "",
      selectedPropertyObj: {},
      all_properties: [],
      childcheckbox: true,
      ticketReportLots: [],
      lot_id: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: endDate,
      month: "",
      detectedDevice: "",
      currentSummaryDate: "",
      pdfLoader: false,
      searchedticketList: [],
      exportLink: false,
      finalTableHeight: 0,
      agent_id_type: "",
      is_meter: null,
      payment_type: "",
      isSetMaxTime: 1,
      startDateTime: "",
      endDateTime: "",
      completed: "",
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "Domain",
        width: "160px",
        sortable: true,
        cell: (row) => this.showDomainField(row),
        selector: (row) => row?.domain,
      },
      {
        name: "Meter",
        sortable: true,
        width: "180px",
        cell: (row) => this.meterData(row),
        selector: (row) => row.agent_id,
      },
      {
        name: "Entry Time",
        sortable: true,
        width: "160px",
        cell: (row) => this.showEntryTimeField(row),
        selector: (row) => row.entry_time,
      },
      {
        name: "Transaction Time",
        sortable: true,
        width: "160px",
        cell: (row) => this.showTransactionTimeField(row),
        selector: (row) => row.transaction_time,
      },
      {
        name: "Amount",
        sortable: true,
        width: "140px",
        cell: (row) => this.showAmountField(row),
        selector: (row) => row.amount,
      },
      {
        name: "Ticket Barcode Number",
        width: "200px",
        sortable: true,
        cell: (row) => this.showBarCodeNumber(row),
        selector: (row) => row.barcode_no,
      },
      {
        name: "Receipt Number",
        sortable: true,
        width: "160px",
        cell: (row) => this.receiptNumber(row),
        selector: (row) => row.invoice_no,
      },
      {
        name: "Transaction Number",
        sortable: true,
        width: "160px",
        cell: (row) => this.showTransactionNumberField(row),
        selector: (row) => row.transaction_id,
      },
      {
        name: "LPN",
        width: "160px",
        sortable: true,
        cell: (row) => this.showLPN(row),
        selector: (row) => row.lpn,
      },
      {
        name: "Merchant code",
        width: "160px",
        sortable: true,
        cell: (row) => this.showCell(row?.merchant_code),
        selector: (row) => row.merchant_code,
      },
      {
        name: "Username",
        width: "160px",
        sortable: true,
        cell: (row) => this.showUserNameField(row),
        selector: (row) => row?.username,
      },
      {
        name: "Status",
        width: "160px",
        sortable: true,
        cell: (row) => this.showStatusField(row),
        selector: (row) => row.status,
      },
    ];
  }

  expandedComponent = ({ data }) => {
    return (
      <div className="extend-row">
        <Row className="expandedView mb-1">
          <Col>
            <Row>
              <Col xs="4">
                <b>Permit Start Time</b>
              </Col>
              <Col xs="8">{(data?.begin_time && utcToSpecificTimeZone(this.props.timezone, data?.begin_time, true)) || "-"}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b>Permit End Time</b>
              </Col>
              <Col xs="8"> {(data?.expire_time && utcToSpecificTimeZone(this.props.timezone, data?.expire_time, true)) || "-"}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b>Permit Id</b>
              </Col>
              <Col xs="8">
                <span>{data.permit_id || "-"}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="expandedView mb-1">
          <Col>
            <Row>
              <Col xs="4">
                <b>Location</b>
              </Col>
              <Col xs="8">{data.meter_location || "-"}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b>Card</b>
              </Col>
              <Col xs="8">{data.card || "-"}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b>Card Type</b>
              </Col>
              <Col xs="8">{data.card_type || "-"}</Col>
            </Row>
          </Col>
        </Row>
        <Row className="expandedView mb-1">
          <Col className="min-w-150px">
            <Row>
              <Col xs="4">
                <b>Result</b>
              </Col>
              <Col xs="8">{data.completed ? "Approved" : "Not Approved"}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b>Info</b>
              </Col>
              <Col xs="8">{data.stall || "-"}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b>XREF1</b>
              </Col>
              <Col xs="8">{data.xref1 || "-"}</Col>
            </Row>
          </Col>
        </Row>
        <Row className="expandedView mb-1">
          <Col className="min-w-150px">
            <Row>
              <Col xs="4">
                <b>XREF2</b>
              </Col>
              <Col xs="8">{data.xref2 || "-"}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b>XID1</b>
              </Col>
              <Col xs="8">{data.xid1 || "-"}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4"></Col>
              <Col xs="8"></Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  showStatusField = (row) => (
    <>
      {row?.status ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.status}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.status}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showUserNameField = (row) => (
    <>
      {row?.username ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.username}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.username}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showTransactionNumberField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.transaction_id}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row.transaction_id}</div>
      </OverlayTrigger>
    </>
  );

  showBarCodeNumber = (row) => (
    <>
      {row.barcode_no ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.barcode_no}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.barcode_no}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showAmountField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${parseFloat(row.amount).toFixed(2)}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">${parseFloat(row.amount).toFixed(2)}</div>
      </OverlayTrigger>
    </>
  );

  showCell = (cellValue) => {
    return (
      <>
        {cellValue ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{cellValue}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{cellValue}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </>
    );
  };

  showLPN = (row) => {
    return (
      <div className="curserPointer text-nowrap text-truncate pr-2" onClick={() => this.handleRedirect(row.lpn)}>
        {row?.lpn ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.lpn}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{row?.lpn}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </div>
    );
  };

  showTransactionTimeField = (row) => (
    <>
      {row.transaction_time ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(this.props.timezone, row.transaction_time, true)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(this.props.timezone, row.transaction_time, true)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showDomainField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.domain}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.domain}</div>
      </OverlayTrigger>
    </>
  );

  handleRedirect = (lpn) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/current-occupancy`,
      search: `audit-report&${lpn}`,
    });
  };

  showEntryTimeField = (row) => (
    <>
      {row?.entry_time ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(this.props.timezone, row.entry_time, true)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(this.props.timezone, row.entry_time, true)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  meterData = (row) => {
    const agent_id = row?.agent_id;
    if (row.agent_id === "PermitPackage") {
      return (
        <>
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>PermitPackage(Unit)</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">PermitPackage(Unit)</div>
          </OverlayTrigger>
        </>
      );
    } else if (row.agent_id === "ParkingPermit") {
      return (
        <>
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>ParkingPermit(Driver)</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">ParkingPermit(Driver)</div>
          </OverlayTrigger>
        </>
      );
    } else {
      return (
        <>
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{agent_id}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{agent_id}</div>
          </OverlayTrigger>
        </>
      );
    }
  };

  handleShowReceiptDetails = async (row, is_meter) => {
    if (row.completed) {
      row.is_meter = is_meter;
      console.log("this is the row", row);
      this.setState(
        {
          showReceiptModal: true,
          pdfLoader: true,
          is_meter: is_meter,
        },
        async () => {
          if (row) {
            const data = await this.props.getReceiptPDF(row);
            if (this.props.api_response) {
              this.setState({
                pdfUrl: data,
                selectedReceiptId: row.id,
                receiptPropertyId: row.property_id,
                is_meter: is_meter,
                pdfLoader: false,
              });
            }
          }
        },
      );
    }
  };

  sendMailClick = (e) => {
    e.preventDefault();
    const { emailToSend } = this.state;
    const type = "pdf";
    const sendMailData = {
      type,
      emailToSend,
      receiptId: this.state.selectedReceiptId,
      is_meter: this.state.is_meter,
      propertyId: this.state.receiptPropertyId,
    };
    this.ticketDataPdf(sendMailData);
  };

  closeModal = () => {
    this.setState({
      showReceiptModal: false,
    });
  };

  handleChangeType = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  changeProperty(event) {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 90);
    this.setState(
      {
        selectedProperty: event.value,
        selectedPropertyObj: event,
        startDate: new Date(datebeforeThirtyDays),
        endDate: new Date(),
        month: null,
      },
      () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === this.state.selectedProperty);
        this.props.selected_Property(selectedPropertyObj);
        this.props.get_all_lots(event.value);
        const current_lot = this.props.lots;
        this.setState({
          ticketReportLots: current_lot,
        });
      },
    );
  }

  handleCheckBox = () => {
    this.setState({ childcheckbox: !this.state.childcheckbox });
  };

  receiptNumber = (row) => {
    if (row.agent_id === "PassagePass" || row.agent_id === "ParkingPermit" || row.agent_id === "PermitPackage") {
      return (
        <div
          className="curserPointer text-nowrap text-truncate pr-2"
          onClick={() => {
            this.handleShowReceiptDetails(row, 0);
          }}
        >
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.transaction_id}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{row.transaction_id}</div>
          </OverlayTrigger>
        </div>
      );
    } else {
      return (
        <div
          className="curserPointer text-nowrap text-truncate pr-2"
          onClick={() => {
            this.handleShowReceiptDetails(row, 1);
          }}
        >
          {row.invoice_no ? (
            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.invoice_no}</Tooltip>}>
              <div className="text-nowrap text-truncate pr-2">{row.invoice_no}</div>
            </OverlayTrigger>
          ) : (
            "-"
          )}
        </div>
      );
    }
  };

  componentDidMount = async () => {
    await this.getTickets();
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    await this.props.get_all_lots(PropertyId);
    const current_lot = this.props.lots;
    this.setState(
      {
        api_response: this.props?.api_response,
        all_properties: this.props?.getAllProperties,
        ticketReportLots: current_lot,
      },
      async () => {
        const selectedPropertyObj = this.state.all_properties.length && this.state.all_properties.find((item) => item.id === PropertyId);
        console.log("selectedPropertyObjaaa", selectedPropertyObj);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
            selectedPropertyName: selectedPropertyObj.domain,
          });
        }
      },
    );
    const height = tableHeightScrollBarsTab();
    const detectedDevice = this.getMobileOperatingSystem();
    this.setState(
      {
        detectedDevice: detectedDevice,
        finalTableHeight: height,
      },
      () => {
        console.log("showReceiptModal: true,showReceiptModal: true,", this.state.detectedDevice);
      },
    );
  };

  GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj, true);
    this.setState({
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    });
  };

  getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  getTickets = async () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, childcheckbox, timezone, startDate, endDate, agent_id_type, status } = this.state;
      const pageobj = {
        PropertyId,
        childcheckbox,
        timezone,
        agent_id_type,
        status,
      };
      if (startDate) {
        pageobj.startDate = startDate;
      }
      if (endDate) {
        pageobj.endDate = endDate;
      }
      await this.props.getRevenueList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      try {
        this.setState(
          {
            ticketList: this.props?.ticketList?.rows?.length ? JSON.parse(JSON.stringify(this.props.ticketList?.rows)) : [],
            api_response: this.props?.api_response,
            count: this.props.ticketList?.count,
          },
          () => {
            this.setState({ loader: false });
          },
        );
      } catch (error) {
        console.error("error on set data revenue", error);
      }
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.ticketList, this.props.ticketList)) {
      this.setState({
        searchedticketList: JSON.parse(JSON.stringify(this.props.ticketList.rows)),
        count: this.props.ticketList.count,
      });
    }
    if (!isEqual(prevProps.lots, this.props.lots)) {
      this.setState({
        ticketReportLots: this.props.lots,
      });
    }
  };

  conditionalRowStyles = [
    {
      when: (row) => !row.completed,
      style: {
        backgroundColor: "#F2F2F2",
      },
    },
  ];

  dataTableHandle = (ticketList) => {
    return (
      <DataTable
        data={this.state.searchValue.length ? this.state.searchedticketList : ticketList}
        columns={this.columns}
        conditionalRowStyles={this.conditionalRowStyles}
        expandableRows
        expandableRowsComponent={this.expandedComponent}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleChangeLot = ({ target }) => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 90);
    this.setState({
      [target.name]: target.value,
      month: null,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
    });
  };

  handleChangeStatus = ({ target }) => {
    this.setState({
      status: target.value,
    });
  };

  handleChangeStartingDates = (date) => {
    const selectedStartDate = moment(date);
    const month = moment();
    const calculatedEndDate = selectedStartDate.clone().add(3, "months");
    const endDate = calculatedEndDate.isAfter(month) ? month : calculatedEndDate;
    const selectedStartDateFormatted = selectedStartDate.format("YYYY-MM-DD");
    const stateSelectedStartDateFormatted = moment(this.state.startDate).format("YYYY-MM-DD");
    if (selectedStartDateFormatted !== stateSelectedStartDateFormatted) {
      selectedStartDate.startOf("day");
    }
    endDate.set({ hour: 23, minute: 59, second: 59 });
    this.setState({
      startDate: new Date(selectedStartDate),
      endDate: new Date(endDate),
      maxEndDate: new Date(endDate),
      month: null,
    });
  };

  handleChangeEndDates = (date) => {
    let endDate = date;
    if (this.state.isSetMaxTime) {
      endDate = moment(date).set({ hour: 23, minute: 59, second: 59 });
    }
    this.setState({
      endDate: new Date(endDate),
      month: null,
    });
  };

  handleCurrentDate = (date) => {
    this.setState({
      month: date,
      startDate: null,
      endDate: null,
    });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    console.log("numPages", numPages);
  };

  handleSearch = () => {
    this.setState({ loader: true }, async () => {
      const {
        lot_id,
        childcheckbox,
        month,
        startDate,
        endDate,
        selectedPropertyObj,
        PropertyId,
        exportLink,
        timezone,
        agent_id_type,
        status,
        payment_type,
        completed,
      } = this.state;
      const pageobj = {
        childcheckbox,
        PropertyId,
        timezone,
        exportLink,
        agent_id_type,
        status,
        payment_type,
      };
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }
      if (lot_id) {
        pageobj.lot_id = lot_id;
      }

      if (month) {
        pageobj.month = month;
      }
      if (completed) {
        pageobj.completed = completed;
      }
      if (startDate && endDate) {
        pageobj.startDate = startDate;
        pageobj.endDate = endDate;
      }
      await this.props.getRevenueList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      console.log("this.props.api_response", this.props.api_response);
      this.setState(
        {
          ticketList: JSON.parse(JSON.stringify(this.props.ticketList.rows)),
          count: this.props.ticketList.count,
        },
        () => {
          this.setState({ loader: false });
        },
      );
      document.body.classList.remove("foc-open");
    });
  };

  resetAll = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 59);
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState({
      month: null,
      lot_id: "",
      agent_id_type: "",
      childcheckbox: true,
      startDate: new Date(datebeforeThirtyDays),
      endDate: endDate,
      selectedPropertyObj: {
        label: this.state.selectedDomainName,
        value: this.state.PropertyId,
      },
      status: "",
      payment_type: "",
      isSetMaxTime: 1,
      completed: "",
    });
  };

  handleExport = async (type) => {
    const {
      lot_id,
      childcheckbox,
      month,
      startDate,
      endDate,
      selectedPropertyObj,
      PropertyId,
      exportLink,
      selectedDomainName,
      timezone,
      agent_id_type,
      status,
      payment_type,
      completed,
    } = this.state;
    const pageobj = {
      childcheckbox,
      PropertyId,
      timezone,
      exportLink,
      agent_id_type,
      status,
      payment_type,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (lot_id) {
      pageobj.lot_id = lot_id;
    }
    if (month) {
      pageobj.month = month;
    }
    if (completed) {
      pageobj.completed = completed;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }

    if (type === "excel") {
      pageobj.exportLink = true;
      pageobj.pdfLink = false;
      const bufferData = await this.props.getRevenueList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
      const exportDataName = `REVENUE-${currentTimeDate}-${selectedDomainName}.xls`;
      if (bufferData) {
        saveAs(
          new Blob([bufferData], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
          exportDataName,
        );
      }
    } else if (type === "pdf") {
      pageobj.pdfLink = true;
      pageobj.exportLink = false;
      const pdfdata = await this.props.getRevenueList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      if (pdfdata) {
        saveAs(new Blob([pdfdata], { type: "application/pdf" }), `Revenue_Reports.pdf`);
      }
    }
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ searchValue: val }, async () => {
      const columnList = [
        "agent_id",
        "meter_location",
        "created_at",
        "transaction_time",
        "barcode_no",
        "id",
        "card_type",
        "transaction_id",
        "lpn",
        "card",
        "completed",
        "amount",
        "invoice_no",
      ];
      if (this.state.searchValue.length) {
        this.setState(
          {
            searchedticketList: findBySearch(this.state.searchValue, this.state.ticketList, this.state.ticketList, columnList, this.props.timezone),
          },
          () => {
            this.setState({ count: this.state.searchedticketList.length });
          },
        );
      } else {
        this.setState({
          count: this.props.ticketList.count,
          searchedticketList: [],
          ticketList: this.props.ticketList.rows,
        });
      }
    });
  };

  handleMailChange = (e) => {
    this.setState(
      {
        emailToSend: e.target.value,
      },
      () => {
        if (!this.state.emailToSend.trim().length) {
          this.setState({
            emailError: "Please Enter email!",
          });
        }
        if (this.state.emailToSend.trim().length) {
          this.setState({
            emailError: !isEmailValid(this.state.emailToSend) ? "Email is invalid!" : "",
            isMailFormValid: isEmailValid(this.state.emailToSend),
          });
        }
      },
    );
  };

  handleOpenPdf = async () => {
    document.getElementById("pdf").click();
  };

  handleSendMail = () => {
    this.setState({
      isSendMailModalOpen: true,
      showReceiptModal: false,
    });
  };

  manageisSendMailModalOpen = () => {
    this.setState({ isSendMailModalOpen: false });
  };

  ticketDataPdf = async (sendMailData) => {
    await this.props.pdfSendToMail(sendMailData);
    if (this.props.api_response) {
      this.setState({
        isSendMailModalOpen: false,
        showReceiptModal: false,
        emailToSend: "",
      });
    }
  };

  handleMaxTime = () => {
    if (!this.state.isSetMaxTime) {
      const endDate = moment(this.state.endDate).set({ hour: 23, minute: 59, second: 59 });
      this.setState({
        endDate: new Date(endDate),
      });
    }
    this.setState({
      isSetMaxTime: !this.state.isSetMaxTime,
    });
  };

  render() {
    const {
      payment_type,
      ticketList,
      loader,
      month,
      maxEndDate,
      childcheckbox,
      ticketReportLots,
      lot_id,
      startDate,
      endDate,
      agent_id_type,
      searchValue,
      status,
      completed,
    } = this.state;
    return (
      <div>
        <div fluid>
          <Card className="mb-0">
            <Card.Body className="pos-rel-overflow-hide">
              <>
                <div className="sidebarFilter">
                  <Card className="contact-container mb-0">
                    <Card.Header style={{ backgroundColor: this.props.main_body }}>
                      <div className="d-flex align-items-center justify-content-between">
                        <Card.Title as="h4">Revenue Details</Card.Title>
                        <a
                          onClick={this.hideSlider}
                          href="javascript:void(0);"
                          className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </a>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md="12">
                          <div className="mb-2">
                            <label className="fancy-checkbox">
                              <input
                                type="checkbox"
                                name={"childcheckbox"}
                                checked={childcheckbox}
                                onChange={this.handleCheckBox}
                                className="custom-control-input"
                                id="childcheckbox1"
                              ></input>
                              <span>
                                <i></i>Include Children Sites
                              </span>
                            </label>
                          </div>
                        </Col>
                        <Col md="12">
                          <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                        </Col>
                        <Col md="12">
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>LOTS:</Form.Label>
                            <Form.Control
                              as="select"
                              name="lot_id"
                              value={lot_id}
                              onChange={this.handleChangeLot}
                              disabled={ticketReportLots?.length < 1 || childcheckbox}
                              placeholder="Select Lot"
                            >
                              {ticketReportLots?.length ? (
                                <>
                                  <option value="">Any</option>
                                  {ticketReportLots.map((item, index) => (
                                    <option key={item.id} value={item.id}>
                                      {item.alias}
                                    </option>
                                  ))}
                                </>
                              ) : null}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <Form.Label>Status:</Form.Label>
                            <Form.Control as="select" name="status" value={status} onChange={this.handleChangeStatus}>
                              <>
                                <option value="">Any</option>
                                <option value="Paid">Paid</option>
                                <option value="Refunded">Refunded</option>
                              </>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Type:</Form.Label>
                            <Form.Control as="select" name="agent_id_type" value={agent_id_type} onChange={this.handleChangeType} placeholder="Select Lot">
                              <>
                                <option value="">Any</option>
                                <option value="Meter">Meter</option>
                                <option value="ParkingPermit">ParkingPermit(Driver)</option>
                                <option value="PermitPackage">PermitPackage(Unit)</option>
                                <option value="PassagePass">PassagePass</option>
                                <option value="Visitor">Visitor</option>
                              </>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Payment Type:</Form.Label>
                            <Form.Control as="select" name="payment_type" value={payment_type} onChange={this.handleChangeType} placeholder="Payment Type">
                              <>
                                <option value="">Any</option>
                                <option value="card">Card</option>
                                <option value="cash">Cash</option>
                              </>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Result:</Form.Label>
                            <Form.Control as="select" name="completed" value={completed} onChange={this.handleChangeType} placeholder="Payment Type">
                              <>
                                <option value="">All</option>
                                <option value="true">Approved</option>
                                <option value="false">Not Approved</option>
                              </>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <label className="mb-0 mr-2">Month/Year:</label>
                            <DatePicker
                              className="form-control"
                              placeholderText="Select Date"
                              selected={month}
                              value={month}
                              disabled={loader}
                              selectsStart
                              timeInputLabel="Time:"
                              onChange={this.handleCurrentDate}
                              showMonthYearPicker
                              dateFormat="MM/yyyy"
                            />
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <label className="mb-0 mr-2">Start Date(Date Range):</label>
                            <DatePicker
                              className="form-control"
                              placeholderText="Select Start Date"
                              selected={startDate}
                              value={startDate}
                              disabled={loader}
                              selectsStart
                              onChange={this.handleChangeStartingDates}
                              dateFormat="MM/dd/yyyy h:mm aa"
                              startDate={startDate}
                              endDate={endDate}
                              maxDate={new Date()}
                              showTimeSelect
                            />
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <div class="d-flex align-items-center">
                              <label className="mb-0 mr-2">End Date(Date Range):</label>
                              <OverlayTrigger
                                delay={{ show: 250, hide: 400 }}
                                placement={"top"}
                                overlay={<Tooltip style={{ zIndex: "20000" }}>When checked, it sets max_time to 23:59, indicating end of day.</Tooltip>}
                              >
                                <FormLabel className="ml-auto d-flex align-items-center text-nowrap">
                                  <input type="checkbox" className="mr-1" checked={this.state.isSetMaxTime} onChange={this.handleMaxTime}></input>
                                  <span>Max Time</span>{" "}
                                </FormLabel>
                              </OverlayTrigger>{" "}
                            </div>
                            <DatePicker
                              className="form-control"
                              placeholderText="Select End Date"
                              selected={endDate}
                              disabled={loader}
                              value={endDate}
                              selectsEnd
                              timeInputLabel="Time:"
                              onChange={this.handleChangeEndDates}
                              dateFormat="MM/dd/yyyy h:mm aa"
                              startDate={startDate}
                              endDate={endDate}
                              minDate={startDate}
                              maxDate={maxEndDate}
                              showTimeSelect
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer className="text-right justify-content-between d-flex">
                      <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                        Reset
                      </Button>
                      <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                        Search
                      </Button>
                    </Card.Footer>
                  </Card>
                </div>
                <header>
                  <div className="active-permit-header sm-flex-column sm-align-items-start">
                    <h5 className="mb-2 mb-md-0">{status} Details</h5>
                    <div className="filter-button d-flex flex-wrap">
                      <div className="d-flex align-items-center search-mb-0">
                        <DatatableHeader
                          className="mt-0 mb-0"
                          searchValue={searchValue}
                          HandleChange={this.handleSearchChange}
                          // findMorePressed={this.findMorePressed}
                        />
                      </div>
                      <div className="buttons-dd">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="Primary"
                            id="dropdown-basic"
                            className="btn btn-sm border-0 bg-light  w-30px h-30px d-flex align-items-center justify-content-center ml-2"
                          >
                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="min-w-100px">
                            <Dropdown.Item className="text-sm" onClick={() => this.handleExport("excel")} disabled={this.state.ticketList.length > 0 ? 0 : 1}>
                              <span>Export</span>{" "}
                            </Dropdown.Item>
                            <Dropdown.Item className="text-sm" onClick={() => this.handleExport("pdf")} disabled={this.state.ticketList.length > 0 ? 0 : 1}>
                              <span>Pdf</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </header>
              </>
              {loader ? (
                <Loader />
              ) : (
                <>
                  {ticketList && (
                    <>
                      <div className="citation-list-table list-of-ravenue-table has-parent-tab revenue-report-table">{this.dataTableHandle(ticketList)}</div>
                      <DateRangeAndAmount
                        ReportList={this.props?.ticketList?.rows}
                        amountName="amount"
                        startDateTime={this.state.startDateTime}
                        endDateTime={this.state.endDateTime}
                      />
                    </>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </div>
        {
          <Modal show={this.state.showReceiptModal} onHide={this.closeModal}>
            <Modal.Header className="no-border">
              <Modal.Title as="h4" className="m-0">
                <h4 className="m-0 font-weight-bold">Parking Receipt</h4>
              </Modal.Title>
              <Button title={"Close"} type="button" onClick={this.closeModal} className="close-btn py-0" data-dismiss="modal">
                <i className="nc-icon nc-simple-remove"></i>
              </Button>
            </Modal.Header>
            <Modal.Body className="modal-data-body">
              {this.state.pdfLoader ? (
                <PdfContainer>
                  <ModalLoader />
                </PdfContainer>
              ) : (
                <div>
                  <>
                    {this.state.pdfUrl && !isMobile && (
                      <>
                        <EmbedIframe className={"pdf-frame"} src={this.state.pdfUrl} rotate={180} />
                      </>
                    )}
                    {this.state.pdfUrl && isMobile && (
                      <>
                        {this.state.detectedDevice === "iOS" && <EmbedIframe className={"pdf-frame"} src={this.state.pdfUrl} rotate={180} />}
                        {this.state.detectedDevice !== "iOS" && (
                          <div>
                            <Document file={this.state.pdfUrl} onLoadSuccess={this.onDocumentLoadSuccess}>
                              <Page pageNumber={1} />
                            </Document>
                          </div>
                        )}
                      </>
                    )}
                  </>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <a id="pdf" href={this.state.pdfUrl} target={"_blank"} rel="noopener noreferrer" style={{ display: "none" }}>
                pdf
              </a>
              <Row>
                <Dropdown className="m-0" id="ignoreButton2">
                  <Dropdown.Toggle>
                    <img className="ml-0" src={download} width={18} /> Save as
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.handleOpenPdf}>PDF</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="m-0 ml-2" id="ignoreButton2">
                  <Dropdown.Toggle>
                    {/* <img className="" src={email} width={18} /> */}
                    Send mail as
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.handleSendMail}>PDF</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button id="ignoreButton3" className="btn-fill ml-0 mt-2 mt-sm-0 ml-sm-2" type="button" variant="secondary" onClick={this.closeModal}>
                  {/* <img className="ml-0 mr-1" src={cancel} width={18} /> */}
                  Cancel
                </Button>
              </Row>
            </Modal.Footer>
          </Modal>
        }
        {this.state.isSendMailModalOpen && (
          <SendMailModal
            closeModal={this.manageisSendMailModalOpen}
            handleMailChange={this.handleMailChange}
            emailToSend={this.state.emailToSend}
            emailError={this.state.emailError}
            ticketDataPdf={this.sendMailClick}
            isMailFormValid={this.state.isMailFormValid}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, Logindetails, SuperAdminDetails, CurrentPermitDetails, EnforcementDetails }) => {
  const { api_response } = APIResponseDetails;
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;
  const { revenueList: ticketList } = EnforcementDetails;
  const { backgroundColor } = Logindetails;
  const { residentList } = CurrentPermitDetails;
  const { getAllProperties, lots, receiptPDFData } = SuperAdminDetails;
  const { MainBackground: main_body } = backgroundColor;
  return {
    PropertyId,
    receiptPDFData,
    api_response,
    timezone,
    property,
    ticketList,
    main_body,
    getAllProperties,
    residentList,
    lots,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  getReceiptPDF: SuperAdminAction.getReceiptPDF,
  getRevenueList: EnforcementActions.getRevenueList,
  doCloseTicket: TicketActions.doCloseTicket,
  ticketStatusChange: TicketActions.ticketStatusChange,
  pdfSendToMail: SuperAdminAction.pdfSendToMail,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  get_all_lots: SuperAdminAction.get_all_lots,
};

export default connect(mapStateToProps, mapStateToDispatch)(RevenueReport);
const EmbedIframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 200px);
  rotate: degrees(-45);
`;
const PdfContainer = styled.div`
  width: 100%;
  height: calc(100vh - 200px);
`;

import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const BillTypeField = (row) => {
    return (
        <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-qw`}>{showPaymentType(row)}</Tooltip>}
        >
            <div className="text-nowrap text-truncate pr-3">
                {showPaymentType(row)}
            </div>
        </OverlayTrigger>
    );
}

const showPaymentType = (row) => {
    let type = "Main card";
    if (row.payment_type === "unit_card") {
        type = "Unit card";
    }
    if (row.payment_type === "free") {
        type = "Free";
    }
    if (row.payment_type === "invoice") {
        type = "Invoice";
    }
    return type;
};

export default BillTypeField
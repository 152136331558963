import React, { Component } from "react";
import { Card, Container, Row, Col, Dropdown, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { connect } from "react-redux";
import SendMailModal from "./SendMailModal";
import CitationPrintModal from "./CitationPrintModal";
import { ResidentsActions, UserProfileActions, SuperAdminAction, TicketActions, ReportsAction } from "../../Actions";
import Loader from "../Loader/Loader";
import { saveAs } from "file-saver";
import CloseTicketModal from "../Modal/CloseTicketModal";
import DatePicker from "react-datepicker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datepicker/dist/react-datepicker.css";
import { isMobile } from "react-device-detect";
import CustomContentAlert, {
  findBySearch,
  validations,
  isEmailValid,
  generateZplFile,
  getBlobUrl,
  sendFileToZebra,
  utcToSpecificTimeZone,
  tableHeightScrollBars,
  scroolToTop,
} from "../helper";
import isEqual from "lodash/isEqual";
import paid from "../../../assets/img/svg/ticket-icons/paid.svg";
import dispute from "../../../assets/img/svg/ticket-icons/dispute.svg";
import padlock from "../../../assets/img/svg/ticket-icons/padlock.svg";
import unpaid from "../../../assets/img/svg/ticket-icons/unpaid.svg";
import paid_cash from "../../../assets/img/svg/paid-cash.svg";
import paid_check from "../../../assets/img/svg/paid-check.svg";
import paid_creditcard from "../../../assets/img/svg/paid-creditcard.svg";
import paid_interac from "../../../assets/img/svg/paid-interac.svg";
import PropertDetails from "../LandingPage/PropertySelection";
import ConfirmationModal from "../Common/ConfirmationModal";
import SendRefundModal from "./SendRefundModal";
import SendTransactionResentMailModal from "./sendTransactionMailModal";
import ViewPaymentDetails from "./ViewPaymentDetails";
const moment_timezone = require("moment-timezone");

class Citation extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.state = {
      PropertyId: this.props.PropertyId,
      ticketList: [],
      loader: false,
      confirmRemoveResidence: false,
      confirmRemoveResidenceData: null,
      uuid: "",
      searchValue: "",
      searchedticketList: [],
      api_response: false,
      show_alert: false,
      show_alert_on_remove_resident: false,
      show_error_on_remove_resident: false,
      api_success_message: "",
      api_error_message: "",
      page: 1,
      Norow: 100,
      selectedColumn: "created_at",
      sort_direction: "DESC",
      pageChanged: false,
      findMore: false,
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      selectedOption: "self",
      showModal: false,
      isFormValid: true,
      status: "",
      remark: "",
      validStatus: true,
      validRemark: true,
      show: false,
      showTicketDetails: false,
      ticketDetails: {},
      state: "",
      isSendMailModalOpen: false,
      emailToSend: "",
      isMailFormValid: false,
      selectedTicketId: "",
      isMailModal: false,
      ticketid: "",
      devices: [],
      selected_device: "",
      zplUrl: "",
      finalTableHeight: 0,
      printButtonDisable: true,
      all_properties: [],
      selectedPropertyObj: {},
      selectedProperty: "",
      selectedDomainName: "",
      childcheckbox: true,
      filterStatus: "Any",
      exportButtonDisable: false,
      currentSummaryDate: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      daysDifference: 30,
      detectedDevice: "",
      openConfirmationModal: false,
      modalLoading: false,
      citation_footer: "",
      vin_visibility: false,
      rowId: "",
      selectedSession: "",
      statusFilter: [
        { text: "Any", value: "Any" },
        { text: "All Open", value: "Open" },
        { text: "All Closed", value: "Closed" },
        { text: "Unpaid (Open)", value: "Unpaid_Open" },
        { text: "Reduced (Open)", value: "Reduced_Open" },
        { text: "In Dispute (Open)", value: "In Dispute_Open" },
        { text: "In-Dispute 2 (Open)", value: "In-Dispute 2_Open" },
        { text: "Dispute Rejected (Open)", value: "Dispute Rejected_Open" },
        { text: "Dispute Rejected 2 (Open)", value: "Dispute Rejected 2_Open" },
        { text: "Paid (Closed)", value: "Paid_Closed" },
        { text: "Paid Cash (Closed)", value: "Paid Cash_Closed" },
        { text: "Paid Check (Closed)", value: "Paid Check_Closed" },
        {
          text: "Paid Credit Card Manually (Closed)",
          value: "Paid Credit Card Manually_Closed",
        },
        { text: "Paid Interac (Closed)", value: "Paid Interac_Closed" },
        { text: "Dispute Approved (Closed)", value: "Dispute Approved_Closed" },
        { text: "Warning (Closed)", value: "Warning_Closed" },
        { text: "Invalid (Closed)", value: "Invalid_Closed" },
      ],
      pdfLoader: false,
      modalOpen: false,
      selectTicketData: {},
      resendMailmodalOpen: false,
      viewMailmodalOpen: false,
      handleMailModal: false,
      session_data: [],
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "Issued Date",
        sortable: true,
        width: "160px",
        cell: (row) => this.showIssuedDateField(row),
        selector: (row) => row.created_at,
      },
      {
        name: "Plate",
        sortable: true,
        width: "180px",
        cell: (row) => this.showPlate(row),
        selector: (row) => row.plate,
      },
      {
        name: "Enforcer",
        sortable: true,
        width: "110px",
        cell: (row) => this.showEnforcer(row),
        selector: (row) => row?.enforcer,
      },
      {
        name: "Site",
        sortable: true,
        width: "140px",
        cell: (row) => this.showSite(row),
        selector: (row) => row.site,
      },
      {
        name: "Violation",
        sortable: true,
        width: "130px",
        cell: (row) => this.showViolation(row),
        selector: (row) => row.violation,
      },
      {
        name: "Fee",
        sortable: true,
        width: "120px",
        cell: (row) => this.showFeeField(row),
        selector: (row) => row.fee,
      },
      {
        name: "Citation No",
        sortable: true,
        width: "120px",
        cell: (row) => this.showCitationNo(row),
        selector: (row) => row.ticket_no,
      },
      {
        name: "Status",
        sortable: true,
        width: "150px",
        cell: (row) => this.showStatus(row),
        selector: (row) => row.status,
      },
      {
        name: "Refunded",
        sortable: true,
        width: "130px",
        cell: (row) => this.showRefunded(row),
        selector: (row) => row.refunded,
      },
      {
        name: "Refund Amount",
        sortable: true,
        width: "130px",
        cell: (row) => this.showRefundAmountField(row),
        selector: (row) => row.refund_amount,
      },
      {
        name: "Charged for 2nd reason",
        sortable: true,
        width: "180px",
        cell: (row) => this.showChargedForSecondReason(row),
        selector: (row) => row.status,
      },
      {
        name: "Cancellation Note",
        sortable: true,
        width: "200px",
        cell: (row) => this.showCancellationNote(row),
        selector: (row) => row.cancellation_note,
      },
      {
        name: "Internal Note",
        sortable: true,
        width: "160px",
        cell: (row) => this.showInternalNote(row),
        selector: (row) => row.notes_internal,
      },
      {
        name: "State",
        sortable: true,
        width: "120px",
        cell: (row) => this.showState(row),
        selector: (row) => row.state,
      },
      {
        name: "Is Offline",
        sortable: true,
        width: "120px",
        cell: (row) => this.showIsOffline(row),
        selector: (row) => row.is_offline,
      },
      {
        name: "Action",
        className: "text-center",
        sortable: true,
        cell: (row) => this.showAction(row),
      },
    ];
  }

  showAction = (row) => {
    const ticket_id = row.id;
    if (row.state !== "Close") {
      return (
        <Dropdown>
          <Dropdown.Toggle variant="Primary" id="dropdown-basic" className="btn-xs">
            Action
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item className="text-sm" href="" onClick={() => this.closeTicket(ticket_id, row.fee)}>
              Close
            </Dropdown.Item>
            {(row.status && row.status === "In Dispute") || row.status === "In-Dispute 2" ? (
              <Dropdown.Item className="text-sm" href="" onClick={() => this.viewTicketDispute(row)}>
                View Dispute
              </Dropdown.Item>
            ) : null}
            <Dropdown.Item
              className="text-sm"
              // href={pdfUrl}
              onClick={() => {
                this.genearateZplAndPdf(row);
              }}
              target={"_blank"}
            >
              Save Pdf
            </Dropdown.Item>
            <Dropdown.Item className="text-sm" href="" onClick={() => this.openSendMailModal(row)}>
              Send Mail
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else if (row.state === "Close") {
      return (
        <Dropdown>
          <Dropdown.Toggle variant="Primary" id="dropdown-basic" className="btn-xs">
            Action
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item className="text-sm" href="" onClick={() => this.reopenTicketModal(row.id)}>
              Reopen
            </Dropdown.Item>
            {this.props.admin_login && row.status === "Paid" && !row.refunded && (
              <Dropdown.Item className="text-sm" href="" onClick={() => this.openSendRefundModal(row)}>
                Send Refund
              </Dropdown.Item>
            )}
            {row.status === "Paid" && (
              <div>
                <Dropdown.Item className="text-sm" href="" onClick={() => this.openViewMailModal(row)}>
                  View Receipt
                </Dropdown.Item>
                <Dropdown.Item className="text-sm" href="" onClick={() => this.openResendMailModal(row)}>
                  Resend Receipt
                </Dropdown.Item>
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return (
        <div className="text-center">
          {" "}
          <span>-</span>{" "}
        </div>
      );
    }
  };

  showState = (row) => (
    <div className="d-flex text-nowrap text-truncate pr-2">
      {row.state === "Close" && <img src={padlock} width={18} />}
      <span>
        {row.state ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.state}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{row.state}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </span>
    </div>
  );

  showIsOffline = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.is_offline ? "True" : "False"}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2">{row.is_offline ? "True" : "False"}</div>
    </OverlayTrigger>
  );

  showInternalNote = (row) => (
    <>
      {row.notes_internal ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.notes_internal}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.notes_internal}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showCancellationNote = (row) => (
    <>
      {row.cancellation_note ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{row.cancellation_note.toString("20")}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{row.cancellation_note.toString("20")}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showChargedForSecondReason = (row) => <div>{row.no_charge ? "Yes" : "No"}</div>;

  showRefundAmountField = (row) => (
    <>
      {row?.refund_amount > 0 ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>${Number(row.refund_amount).toFixed(2)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">${Number(row.refund_amount).toFixed(2)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showRefunded = (row) => <div>{row.refunded ? "Yes" : "No"}</div>;

  showStatus = (row) => (
    <div className="d-flex text-nowrap text-truncate px-2">
      <StatusIcon status={row.status} />
      <>
        {row.status ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.status}</Tooltip>}>
            <div className="text-nowrap text-truncate px-2">{row.status}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </>
    </div>
  );

  showCitationNo = (row) => (
    <div
      className="curserPointer text-nowrap text-truncate pr-2"
      onClick={() => {
        this.handleShowTicketDetails(row);
      }}
    >
      <div className="d-flex align-items-center">
        <span className="icon-tickit2 fs-20px mr-1"></span>
        <>
          {row.ticket_no ? (
            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.ticket_no}</Tooltip>}>
              <div className="text-nowrap text-truncate pr-2">{row.ticket_no}</div>
            </OverlayTrigger>
          ) : (
            "-"
          )}
        </>
      </div>
    </div>
  );

  showFeeField = (row) => (
    <>
      {row?.fee ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${Number(row.fee).toFixed(2)}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">${Number(row.fee).toFixed(2)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showViolation = (row) => {
    return (
      <>
        {row.violation ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}> {row.violation}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{row.violation}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </>
    );
  };

  showSite = (row) => (
    <>
      {row.site ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.site}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.site}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showEnforcer = (row) => (
    <>
      {row.enforcer ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.enforcer}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.enforcer}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  checkLpn = (lpn) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/current-occupancy`,
      search: `audit-report&${lpn}`,
    });
  };

  showPlate = (row) => (
    <div className="curserPointer text-nowrap text-truncate pr-2" onClick={() => this.checkLpn(row.lpn)}>
      {row.plate ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.plate}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.plate}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </div>
  );

  showIssuedDateField = (row) => (
    <>
      {row.created_at ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(row.timezone, row.created_at)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(row.timezone, row.created_at)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  openSendMailModal = async (ticketDetails) => {
    const ticket_id = ticketDetails.id;
    const language = ticketDetails.language;
    const { ticketList } = this.state;
    const ticketData = ticketList.filter((item) => item.id === ticket_id);
    const ticketRow = await this.props.getMessageList(ticket_id, false, 0, language);
    let zplData = "";
    if (ticketRow?.data?.length) {
      const encodedImage = await this.props.getEncodedFileFromLabelaryApi(ticketRow.data[0].ticket_photos[0].photo);
      const generateTicketData = ticketRow.data[0];
      zplData = await generateZplFile(generateTicketData, encodedImage.data);
    }
    this.setState({
      isSendMailModalOpen: true,
      selectedTicketId: ticket_id,
      show: false,
      ticketDetails: { ...ticketData[0] },
      zplData: zplData,
    });
  };

  openViewMailModal = async (row) => {
    this.setState({
      viewMailmodalOpen: true,
      selectTicketData: row,
    });
  };

  openSendRefundModal = async (row) => {
    this.setState({
      modalOpen: true,
      selectTicketData: row,
    });
  };

  openResendMailModal = async (row) => {
    this.setState({
      resendMailmodalOpen: true,
      selectTicketData: row,
    });
  };

  closeSendMailModal = async () => {
    this.setState({
      isSendMailModalOpen: false,
      resendMailmodalOpen: false,
      emailToSend: "",
    });
  };

  closeRefundModel = async () => {
    this.setState({
      modalOpen: false,
    });
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        refund_success_message: this.props.api_success_message,
      });
      setTimeout(() => {
        this.setState({ show_alert: false, refund_success_message: "" });
      }, 4000);
    } else {
      this.setState({
        show_error: true,
        refund_error_message: this.props.api_error_message,
      });
      setTimeout(() => {
        this.setState({ show_error: false, refund_error_message: "" });
      }, 4000);
    }
  };

  sendMailClick = (e) => {
    e.preventDefault();
    const { selectedTicketId, emailToSend, ticketDetails } = this.state;
    const type = "pdf";
    const sendMailData = {
      selectedTicketId,
      type,
      emailToSend,
      zplData: this.state.zplData,
      lpn: ticketDetails.lpn,
      ticket_no: ticketDetails.ticket_no,
    };
    this.ticketDataPdf(sendMailData);
  };

  sendTransactionMailClick = async () => {
    const { emailToSend, selectTicketData } = this.state;
    const sendTransactionMailData = {
      emailToSend,
      ticket_no: selectTicketData.id,
      language: "null",
    };
    await this.props.sendTransactionMail(sendTransactionMailData);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        resendMailmodalOpen: false,
        emailToSend: "",
      });
      setTimeout(() => {
        this.setState({ show_alert: false });
      }, 4000);
    }
  };

  handleMailChange = (e) => {
    this.setState(
      {
        emailToSend: e.target.value,
      },
      () => {
        if (!this.state.emailToSend.trim().length) {
          this.setState({
            emailError: "Please Enter email!",
          });
        }
        if (this.state.emailToSend.trim().length) {
          this.setState({
            emailError: !isEmailValid(this.state.emailToSend) ? "Email is invalid!" : "",
            isMailFormValid: isEmailValid(this.state.emailToSend),
          });
        }
      },
    );
  };

  handleTicketDetailModalClose = () => {
    this.setState({ show: false });
  };

  handleShowTicketDetails = async (details) => {
    const ticketRow = await this.props.getMessageList(details.id, false, 0, details.language);
    this.setState({
      show: true,
      pdfLoader: true,
      ticketDetails: details,
    });
    let blobUrl = "";
    const form_data = new FormData();
    if (ticketRow?.data?.length) {
      form_data.append("file", ticketRow.data[0]?.ticket_photos[0]?.photo);
      const encodedImage = await this.props.getEncodedFileFromLabelaryApi(ticketRow?.data[0]?.ticket_photos[0]?.photo);
      const generateTicketData = ticketRow.data[0];
      const zplData = await generateZplFile(generateTicketData, encodedImage.data);
      const blobPdf = await this.saveAndGeneratePdf(zplData, false);
      blobUrl = getBlobUrl(zplData, "zpl");
      console.log("Zpl blob url", blobUrl);
      console.log("Pdf blob url", blobPdf);
      this.setState({
        pdfLoader: false,
        pdfUrl: blobPdf,
        ticketId: details.id,
        zplData: zplData,
      });
    }
    let ifSelected = true;
    if (this.state.selected_device) {
      ifSelected = false;
    }
    this.setState({
      zplUrl: blobUrl,
      printButtonDisable: ifSelected,
    });
  };

  saveAndGeneratePdf = async (zplData, iflog) => {
    return this.props.getPDFandPNGfromZpl(zplData, "pdf", iflog);
  };

  genearateZplAndPdf = async (row) => {
    const ticketId = row.id;
    const language = row.language;
    const ticketRow = await this.props.getMessageList(ticketId, false, 0, language);
    if (ticketRow?.data?.length) {
      const encodedImage = await this.props.getEncodedFileFromLabelaryApi(ticketRow.data[0].ticket_photos[0].photo);
      const generateTicketData = ticketRow.data[0];
      const zplData = await generateZplFile(generateTicketData, encodedImage.data);
      const blobPdf = await this.saveAndGeneratePdf(zplData, true);
      this.setState({
        pdfUrl: blobPdf,
        zplData: zplData,
      });
      const pdf = document.getElementById("pdfList");
      pdf.href = blobPdf;
      pdf.click();
    }
  };

  componentDidMount = async () => {
    if (this.props.location?.state) {
      let search = "";
      if (this.props.location.state.plate) {
        search = this.props.location.state.plate;
      }
      if (this.props.location.state.ticket_no) {
        search = this.props.location.state.ticket_no;
      }
      this.setState(
        {
          loader: true,
          searchValue: search,
        },
        async () => {
          const {
            searchValue,
            selectedColumn,
            sort_direction,
            selectedOption,
            childcheckbox,
            currentSummaryDate,
            startDate,
            endDate,
            timezone,
            filterStatus,
            selectedPropertyObj,
            PropertyId,
          } = this.state;
          const pageobj = {
            searchValue,
            selectedColumn,
            sort_direction,
            selectedOption,
            childcheckbox,
            timezone,
          };
          if (Object.keys(selectedPropertyObj).length) {
            pageobj.PropertyId = selectedPropertyObj.value;
          } else {
            pageobj.PropertyId = PropertyId;
          }
          if (childcheckbox) {
            pageobj.childcheckbox = childcheckbox;
          }
          if (filterStatus) {
            pageobj.status = filterStatus;
          }
          if (currentSummaryDate) {
            pageobj.currentSummaryDate = currentSummaryDate;
          }
          if (startDate && endDate) {
            pageobj.startDate = startDate;
            pageobj.endDate = endDate;
          }
          pageobj.selectedSession = this.state.selectedSession;
          await this.props.getTicketList(pageobj);

          this.setState(
            {
              page: 1,
              ticketList: this.props.ticketList.rows,
              searchedticketList: this.props.ticketList.rows,
            },
            () => {
              this.getOfflineData();
              this.setState({
                count: this.state.searchedticketList.length,
                loader: false,
              });
            },
          );
        },
      );
    } else {
      await this.getTickets();
    }
    const height = tableHeightScrollBars();
    this.setState({
      finalTableHeight: height,
    });
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    this.setState(
      {
        api_response: this.props?.api_response,
        all_properties: this.props?.getAllProperties,
      },
      async () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );
    this.findDevicesOnPrinter();
    const detectedDevice = this.getMobileOperatingSystem();
    this.setState({
      detectedDevice: detectedDevice,
    });
  };

  findDevicesOnPrinter = () => {
    const usethis = this;
    const devices = [];
    window.BrowserPrint?.getDefaultDevice(
      "printer",
      function (device) {
        // Add device to list of devices and to html select element
        const selected_device = device;
        devices.push(device);
        usethis.setState({
          devices: devices,
          selected_device: selected_device,
        });

        // Discover any other devices available to the application
        window.BrowserPrint?.getLocalDevices(
          function (device_list) {
            for (const element of device_list) {
              // Add device to list of devices and to html select element
              const device_data = element;
              devices.push(device_data);
            }
            usethis.setState({
              devices: devices,
              selected_device: devices?.[0],
            });
          },
          function () {
            console.log("in print");
          },
          "printer",
        );
      },
      function (error) {
        console.log("error in component did mount", error);
      },
    );
  };

  sendFile = (fileUrl) => {
    const { selected_device } = this.state;
    if (selected_device) {
      this.setState({
        printButtonDisable: true,
      });
      sendFileToZebra(selected_device, fileUrl);
      setTimeout(() => {
        this.setState({ printButtonDisable: false });
      }, 10000);
    }
  };

  getTickets = async () => {
    this.setState({ loader: true }, async () => {
      const {
        timezone,
        searchValue,
        selectedColumn,
        sort_direction,
        selectedOption,
        PropertyId,
        selectedPropertyObj,
        childcheckbox,
        filterStatus,
        currentSummaryDate,
        startDate,
        endDate,
      } = this.state;
      const pageobj = {
        timezone,
        searchValue,
        selectedColumn,
        sort_direction,
        selectedOption,
      };
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }
      if (childcheckbox) {
        pageobj.childcheckbox = childcheckbox;
      }
      if (filterStatus) {
        pageobj.status = filterStatus;
      }
      if (currentSummaryDate) {
        pageobj.currentSummaryDate = currentSummaryDate;
      }
      if (startDate && endDate) {
        pageobj.startDate = startDate;
        pageobj.endDate = endDate;
      }
      pageobj.selectedSession = this.state.selectedSession;
      await this.props.getTicketList(pageobj);
      this.getOfflineData();

      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);

      this.setState({
        ticketList: this.props.ticketList.rows && JSON.parse(JSON.stringify(this.props.ticketList.rows)),
        api_response: this.props.api_response,
        count: this.props.ticketList.count,
        searchedticketList: this.props.ticketList.rows && JSON.parse(JSON.stringify(this.props.ticketList.rows)),
      });
      this.setState({ loader: false });
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.ticketList, this.props.ticketList)) {
      this.setState({
        searchedticketList: JSON.parse(JSON.stringify(this.props.ticketList.rows)),
        ticketList: JSON.parse(JSON.stringify(this.props.ticketList.rows)),
        pageChanged: true,
        count: this.props.ticketList.count,
      });
    }
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ searchValue: val }, async () => {
      const columnList = ["created_at", "plate", "enforcer", "site", "violation", "fee", "ticket_no", "status", "cancellation_note", "notes_internal", "state"];
      if (this.state.searchValue.length) {
        if (!this.state.findMore) {
          this.setState(
            {
              searchedticketList: findBySearch(this.state.searchValue, this.state.ticketList, this.state.ticketList, columnList, this.state.timezone),
            },
            () => {
              this.setState({ count: this.state.searchedticketList.length });
            },
          );
        }
      } else {
        this.getTickets();
      }
    });
  };

  closeTicket = async (ticket_id, fee) => {
    this.confirmCloseTicketModal(ticket_id, fee);
  };

  viewTicketDispute = async (row) => {
    const ticket_id = row.id;
    const language = row.language;
    const history = require("../../history").default;
    history.push({
      pathname: "/dispute",
      state: {
        ticketId: ticket_id,
        language: language,
        redirect_from: "citation-list",
      },
    });
  };

  confirmCloseTicketModal = (id, fee) => {
    const message = `Note: Are you sure you want to close citation?`;
    const data = { title: "Cancel Citation", message: message, fee: fee };
    this.setState({
      confirmRemoveResidenceData: data,
      confirmRemoveResidence: true,
      uuid: id,
    });
  };

  onConfrim = async (status, note, amount) => {
    const id = this.state.uuid;
    const data = {
      selectReasonValue: status,
      reasonNote: note,
      amount: +amount,
    };
    this.setState(
      {
        confirmRemoveResidence: false,
        pageChanged: true,
        closeTicketData: data,
      },
      async () => {
        await this.confirmCancelTicket(id, data);
      },
    );
  };

  ticketDataPdf = async (sendMailData) => {
    await this.props.pdfSendToMail(sendMailData);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        isSendMailModalOpen: false,
        emailToSend: "",
      });
      setTimeout(() => {
        this.setState({ show_alert: false });
      }, 4000);
    }
  };

  confirmCancelTicket = async (ticket_id, data) => {
    await this.props.doCloseTicket(ticket_id, data);
    if (this.props.api_response) {
      this.setState({
        show_alert_on_remove_resident: true,
        api_success_message: this.props.api_success_message,
      });
      setTimeout(() => {
        this.setState({
          show_alert_on_remove_resident: false,
          api_success_message: "",
        });
      }, 4000);
    } else {
      this.setState({
        show_error_on_remove_resident: true,
        api_error_message: this.props.api_error_message,
      });
      setTimeout(() => {
        this.setState({
          show_error_on_remove_resident: false,
          api_error_message: "",
        });
      }, 4000);
    }
  };

  onCancel = () => {
    this.setState({ confirmRemoveResidence: false });
  };

  expandedComponent = ({ data }) => {
    return (
      <div className="extend-row">
        <Row className="expandedView mb-1">
          <Col>
            <Row>
              <Col xs="4">
                <b>Session Id</b>
              </Col>
              <Col xs="8">{data?.session_id || "-"}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b>Session Start Time</b>
              </Col>
              <Col xs="8"> {(data?.session_start_time && utcToSpecificTimeZone(this.props.timezone, data?.session_start_time, true)) || "-"}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b>Session End Time</b>
              </Col>
              <Col xs="8"> {(data?.session_end_time && utcToSpecificTimeZone(this.props.timezone, data?.session_end_time, true)) || "-"}</Col>
            </Row>
          </Col>
        </Row>
        <Row className="expandedView mb-1">
          <Col>
            <Row>
              <Col xs="4">
                <b>External Notes</b>
              </Col>
              <Col xs="8">
                {data.notes_external ? (
                  <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top-start"} overlay={<Tooltip id={`tooltip-qw`}>{data.notes_external}</Tooltip>}>
                    <div className="text-nowrap text-truncate pr-2">{`${data.notes_external.substring(0, 15)}...`}</div>
                  </OverlayTrigger>
                ) : (
                  "-"
                )}
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b></b>
              </Col>
              <Col xs="8"></Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b></b>
              </Col>
              <Col xs="8"></Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  dataTableHandle = (searchValue, searchedticketList, ticketList) => {
    return (
      <DataTable
        data={searchValue?.length ? searchedticketList : ticketList}
        columns={this.columns}
        expandableRows
        expandableRowsComponent={this.expandedComponent}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        onChangePage={scroolToTop}
        fixedHeader={true}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={this.state.Norow}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleChange = async ({ target }) => {
    this.setState({ [target.name]: target.value }, async () => {
      const { status } = this.state;
      switch (target.name) {
        case "status":
          {
            const statusData = validations(target.name, "status", this.state.status);
            this.setState({
              status: statusData.value,
              validStatus: statusData.validator,
            });
          }
          break;
        case "remark":
          {
            const remark = validations(target.name, "remark", this.state.remark);
            this.setState({
              remark: remark.value,
              validRemark: remark.validator,
            });
          }
          break;

        default:
          break;
      }

      if (status) {
        this.setState({ isFormValid: false });
      } else {
        this.setState({ isFormValid: true });
      }
    });
  };

  handleChangeStatus = () => {
    this.setState({ isFormValid: false }, async () => {
      const { remark, status, ticketId } = this.state;
      const form_data = new FormData();
      form_data.append("remark", remark);
      form_data.append("status", status);
      form_data.append("ticket_id", ticketId);
      const updateData = {
        remark: remark,
        status: status,
        ticket_id: ticketId,
      };
      await this.props.ticketStatusChange(updateData);
      if (this.props.api_response) {
        this.setState({
          status: "",
          remark: "",
          showModal: false,
          show_alert: true,
          api_success_message: this.props.api_success_message,
        });
        setTimeout(() => {
          this.setState({ show_alert: false, api_success_message: "" });
        }, 4000);
      } else {
        this.setState({
          show_error: true,
          api_error_message: this.props.api_error_message,
        });
        setTimeout(() => {
          this.setState({ show_error: false });
        }, 4000);
      }
    });
  };

  printTicketDetails = () => {
    const iframe = document.querySelector(".pdf-frame");
    iframe?.contentWindow.print();
  };

  handleCheckBox = () => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState({
      childcheckbox: !this.state.childcheckbox,
      year: "",
      currentSummaryDate: null,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
    });
  };

  changeProperty(event) {
    this.setState({
      selectedProperty: event.value,
      selectedPropertyObj: event,
      loader: false,
    });
  }

  handleSearchTickets = async () => {
    this.setState({ loader: true });
    const {
      timezone,
      searchValue,
      selectedColumn,
      sort_direction,
      selectedOption,
      PropertyId,
      selectedPropertyObj,
      childcheckbox,
      filterStatus,
      currentSummaryDate,
      startDate,
      endDate,
      year,
    } = this.state;
    const pageobj = {
      timezone,
      searchValue,
      selectedColumn,
      sort_direction,
      selectedOption,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (childcheckbox) {
      pageobj.childcheckbox = childcheckbox;
    }
    if (filterStatus) {
      pageobj.status = filterStatus;
    }
    if (year) {
      pageobj.year = moment_timezone(year).format("YYYY");
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    pageobj.selectedSession = this.state.selectedSession;
    await this.props.getTicketList(pageobj);
    const selectedPropertyName = this.state.property?.property_name;
    const selectedDomainName = this.state.property?.domain;
    await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    this.setState({
      ticketList: this.props.ticketList.rows && JSON.parse(JSON.stringify(this.props.ticketList.rows)),
      api_response: this.props.api_response,
      count: this.props.ticketList.count,
    });
    this.setState({ loader: false });
    document.body.classList.remove("foc-open");
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleResetAll = async () => {
    const { PropertyId, selectedDomainName, daysDifference } = this.state;
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - daysDifference);
    this.setState(
      {
        loader: false,
        selectedPropertyObj: {
          label: selectedDomainName,
          value: PropertyId,
        },
        selectedProperty: PropertyId,
        childcheckbox: true,
        filterStatus: "Any",
        searchValue: "",
        page: 1,
        Norow: 100,
        currentSummaryDate: "",
        startDate: new Date(datebeforeThirtyDays),
        endDate: new Date(),
        selectedSession: "",
      },
      () => {
        this.getOfflineData();
      },
    );
  };

  handleStatusFilter = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleChangeStartingDates = (date) => {
    if (date) {
      const { daysDifference } = this.state;
      const end = new Date(date);
      end.setDate(date.getDate() + daysDifference);
      let endDate = new Date();
      if (new Date() >= end) {
        endDate = end;
      }
      this.setState(
        {
          startDate: date,
          year: "",
          endDate: endDate,
          currentSummaryDate: "",
          selectedRadio: null,
          loader: false,
        },
        () => {
          this.getOfflineData();
        },
      );
    }
  };

  handleChangeEndDates = (date) => {
    if (date) {
      const { daysDifference, startDate } = this.state;
      const start = new Date(date);
      start.setDate(date.getDate() - daysDifference);
      let startDateSelection;
      if (startDate) {
        startDateSelection = startDate;
      } else {
        startDateSelection = start;
      }
      this.setState(
        {
          endDate: date,
          year: "",
          startDate: startDateSelection,
          currentSummaryDate: "",
          selectedRadio: null,
          loader: false,
        },
        () => {
          this.getOfflineData();
        },
      );
    }
  };

  handleChangeYear = (date) => {
    this.setState(
      {
        year: date,
        currentSummaryDate: null,
        startDate: null,
        endDate: null,
        selectedRadio: null,
        loader: false,
      },
      () => {
        this.getOfflineData();
      },
    );
  };

  handleFilterByDate = (date) => {
    if (date) {
      this.setState(
        {
          currentSummaryDate: date,
          startDate: null,
          year: "",
          endDate: null,
          selectedRadio: null,
          loader: false,
        },
        () => {
          this.getOfflineData();
        },
      );
    }
  };

  handleExport = async () => {
    const {
      searchValue,
      state,
      selectedColumn,
      sort_direction,
      selectedOption,
      PropertyId,
      selectedPropertyObj,
      childcheckbox,
      filterStatus,
      currentSummaryDate,
      startDate,
      endDate,
      year,
    } = this.state;
    const pageobj = {
      page: 1,
      Norow: 10,
      searchValue,
      state,
      selectedColumn,
      sort_direction,
      selectedOption,
      exportData: true,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (childcheckbox) {
      pageobj.childcheckbox = childcheckbox;
    }
    if (filterStatus) {
      pageobj.status = filterStatus;
    }
    if (year) {
      pageobj.year = moment_timezone(year).format("YYYY");
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    pageobj.selectedSession = this.state.selectedSession;
    const bufferData = await this.props.getTicketList(pageobj);
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        `Citations.xls`,
      );
    }
  };

  addDays = (date, days) => {
    let endDate = new Date();
    if (date) {
      const end = new Date(date);
      end.setDate(date.getDate() + days);
      if (new Date() >= end) {
        endDate = end;
      }
    }
    return endDate;
  };

  getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  reopenTicketModal = (ticketId) => {
    this.setState({
      openConfirmationModal: true,
      rowId: ticketId,
    });
  };

  callCloseViewPaymentDetailsModal = async () => {
    this.setState({ viewMailmodalOpen: false });
  };

  callCloseTicketModal = async () => {
    this.setState({ confirmRemoveResidence: false });
  };

  handleMailModal = async () => {
    this.setState({
      resendMailmodalOpen: true,
      viewMailmodalOpen: false,
    });
  };

  onCallCloseOpenConfirmationModal = async () => {
    const { openConfirmationModal } = this.state;
    this.setState({ openConfirmationModal: !openConfirmationModal });
  };

  handleReopenTicketStatus = async () => {
    this.setState({
      modalLoading: true,
    });
    await this.props.changeTicketStateAndStatus(this.state.rowId);
    this.setState({
      openConfirmationModal: false,
      rowId: "",
      modalLoading: false,
    });
  };

  getOfflineData = async () => {
    const { childcheckbox, PropertyId, year, currentSummaryDate, startDate, endDate } = this.state;
    let pageobj = {
      PropertyId: PropertyId,
      childcheckbox: childcheckbox,
    };
    if (year) {
      pageobj.year = moment_timezone(year).format("YYYY");
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    await this.props.getSessionList(pageobj);
    if (this.props.api_response) {
      this.setState({
        session_data: this.props.getSessionData,
      });
    }
  };

  render() {
    const {
      ticketList,
      loader,
      confirmRemoveResidence,
      confirmRemoveResidenceData,
      searchValue,
      show_alert_on_remove_resident,
      show_error_on_remove_resident,
      page,
      Norow,
      pageChanged,
      searchedticketList,
      show,
      zplUrl,
      printButtonDisable,
      childcheckbox,
      selectedPropertyObj,
      filterStatus,
      statusFilter,
      exportButtonDisable,
      startDate,
      endDate,
      currentSummaryDate,
      daysDifference,
      detectedDevice,
      openConfirmationModal,
      zplUrlForAndroid,
      selectTicketData,
      year,
      selectedSession,
      session_data,
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <Card className="mb-0">
                <Card.Body className="pos-rel-overflow-hide">
                  <a id="pdfList" href={this.state.pdfUrl} target={"_blank"} rel="noopener noreferrer" style={{ display: "none" }}>
                    pdf
                  </a>
                  <>
                    <div className="sidebarFilter">
                      <Card className="contact-container mb-0">
                        <Card.Header style={{ backgroundColor: this.props.main_body }}>
                          <div className="d-flex align-items-center justify-content-between input-fix-width">
                            <Card.Title as="h4">List of Citation</Card.Title>
                            <a
                              onClick={this.hideSlider}
                              href="javascript:void(0);"
                              className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            <Col md="12">
                              <div className="mb-2">
                                <label className="fancy-checkbox mt-2">
                                  <input
                                    type="checkbox"
                                    name={"childcheckbox"}
                                    checked={childcheckbox}
                                    onChange={this.handleCheckBox}
                                    className="custom-control-input"
                                    id="childcheckbox1"
                                  ></input>
                                  <span>
                                    <i></i>Include Children Sites
                                  </span>
                                </label>
                              </div>
                            </Col>
                            <Col md="12">
                              <PropertDetails selectedPropertyObj={selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Status:</Form.Label>
                                <Form.Control as="select" name="filterStatus" disabled={loader} onChange={this.handleStatusFilter} value={filterStatus}>
                                  {statusFilter ? (
                                    <>
                                      {statusFilter.map((item) => (
                                        <option key={item.text} value={item.value}>
                                          {item.text}
                                        </option>
                                      ))}
                                    </>
                                  ) : null}
                                </Form.Control>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">Specific Year:</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Date"
                                  selected={year}
                                  value={year}
                                  disabled={loader || childcheckbox}
                                  selectsStart
                                  timeInputLabel="Time:"
                                  onChange={this.handleChangeYear}
                                  showYearPicker
                                  dateFormat="yyyy"
                                  maxDate={new Date()}
                                  minDate={new Date(2021, 0, 1)}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">Specific Date:</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Date"
                                  selected={currentSummaryDate}
                                  value={currentSummaryDate}
                                  disabled={loader}
                                  selectsStart
                                  onChange={this.handleFilterByDate}
                                  dateFormat="MM/dd/yyyy"
                                  maxDate={new Date()}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">Start Date(Date Range):</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Start Date"
                                  selected={startDate}
                                  defaultHour={24}
                                  value={startDate}
                                  disabled={loader}
                                  selectsStart
                                  timeInputLabel="Time:"
                                  onChange={this.handleChangeStartingDates}
                                  dateFormat="MM/dd/yyyy"
                                  startDate={startDate}
                                  endDate={endDate}
                                  maxDate={new Date()}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">End Date(Date Range):</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select End Date"
                                  selected={endDate}
                                  disabled={loader}
                                  value={endDate}
                                  selectsEnd
                                  timeInputLabel="Time:"
                                  onChange={this.handleChangeEndDates}
                                  dateFormat="MM/dd/yyyy"
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={startDate}
                                  maxDate={this.addDays(startDate, daysDifference)}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            {session_data?.length > 0 && (
                              <Col md="12">
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                  <Form.Label>Sessions</Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="selectedSession"
                                    placeholder="stall"
                                    value={selectedSession}
                                    className="photo-verify-form-control"
                                    onChange={this.handleStatusFilter}
                                  >
                                    <option value="">--Select Session--</option>
                                    <option value="All">--All--</option>

                                    {(session_data ?? []).map((e, key) => {
                                      return (
                                        e.session_id !== null &&
                                        e.session_id && (
                                          <option key={e.session_id} value={e.session_id}>
                                            {`${utcToSpecificTimeZone(e.timezone, e?.session_start_time)} -
                                              ${utcToSpecificTimeZone(e.timezone, e?.session_end_time)}`}
                                          </option>
                                        )
                                      );
                                    })}
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                            )}
                          </Row>
                        </Card.Body>
                        <Card.Footer className="text-right justify-content-between d-flex">
                          <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.handleResetAll} disabled={loader}>
                            Reset
                          </Button>
                          <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearchTickets} disabled={loader}>
                            Search
                          </Button>
                        </Card.Footer>
                      </Card>
                    </div>
                    <header>
                      <div className="active-permit-header sm-flex-column sm-align-items-start">
                        <h5 className="mb-2 mb-md-0">List of Citation</h5>
                        <div className="filter-button d-flex flex-wrap">
                          <div className="d-flex align-items-center search-mb-0 mr-2 loc-searchbox">
                            <DatatableHeader
                              className="mt-0 mb-0"
                              searchValue={searchValue}
                              HandleChange={this.handleSearchChange}
                              findMore={true}
                              findMorePressed={this.getTickets}
                              citations={true}
                            />
                          </div>
                          <div className="export-btn">
                            <Button variant="info" className="btn-fill btn-sm" onClick={this.handleExport} disabled={exportButtonDisable} size="medium">
                              Export
                            </Button>
                          </div>
                        </div>
                      </div>
                    </header>
                  </>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {this.state.show_alert && this.state.api_success_message && (
                        <CustomContentAlert delay={4000} message={this.state.api_success_message} className="toast-success" />
                      )}
                      {ticketList && (
                        <>
                          <div className="citation-list-table">
                            {this.dataTableHandle(searchValue, searchedticketList, ticketList, page, Norow, pageChanged)}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {this.state.show && (
          <CitationPrintModal
            onOpen={show}
            onClose={this.handleTicketDetailModalClose}
            modalTitle="Citation details"
            pdfLoader={this.state.pdfLoader}
            pdfUrl={this.state.pdfUrl}
            zplData={this.state.zplData}
            isMobile={isMobile}
            detectedDevice={detectedDevice}
            printTicketDetails={this.printTicketDetails}
            printToZebra={() => this.sendFile(zplUrl)}
            pngurl={this.state.pngurl}
            sendPdfPngMail={() => this.openSendMailModal(this.state.ticketDetails)}
            printButtonDisable={printButtonDisable}
            zplUrlForAndroid={zplUrlForAndroid}
            isCitationList={true}
          />
        )}
        {this.state.isSendMailModalOpen && (
          <SendMailModal
            closeModal={this.closeSendMailModal}
            handleMailChange={this.handleMailChange}
            emailToSend={this.state.emailToSend}
            emailError={this.state.emailError}
            ticketDataPdf={this.sendMailClick}
            isMailFormValid={this.state.isMailFormValid}
          />
        )}
        {this.state.resendMailmodalOpen && (
          <SendTransactionResentMailModal
            closeModal={this.closeSendMailModal}
            handleMailChange={this.handleMailChange}
            emailToSend={this.state.emailToSend}
            emailError={this.state.emailError}
            mailSend={this.sendTransactionMailClick}
            isMailFormValid={this.state.isMailFormValid}
          />
        )}

        {confirmRemoveResidence ? (
          <CloseTicketModal
            showBrowserModal={confirmRemoveResidence}
            details={confirmRemoveResidenceData}
            closeModal={this.callCloseTicketModal}
            handleSave={this.onConfrim}
            handleCancel={this.onCancel}
          />
        ) : null}
        {openConfirmationModal ? (
          <ConfirmationModal
            onOpen={openConfirmationModal}
            onConfirm={this.handleReopenTicketStatus}
            message="Are You sure you want to reopen citation?"
            onClose={this.onCallCloseOpenConfirmationModal}
            loading={this.state.modalLoading}
            title={"Reopen Ciatation"}
          />
        ) : null}
        {this.state.modalOpen && (
          <SendRefundModal closeModal={this.closeRefundModel} handleSave={this.onConfrim} data={selectTicketData} handleCancel={this.onCancel} />
        )}
        {this.state.viewMailmodalOpen && (
          <ViewPaymentDetails closeModal={this.callCloseViewPaymentDetailsModal} data={this.state.selectTicketData.id} handleMailModal={this.handleMailModal} />
        )}
        {show_alert_on_remove_resident && this.state.api_success_message && (
          <CustomContentAlert delay={4000} message={this.state.api_success_message} className="toast-success" />
        )}
        {show_error_on_remove_resident && this.state.api_error_message && (
          <CustomContentAlert delay={4000} message={this.state.api_error_message} className="toast-error" />
        )}
        {this.state.show_alert && this.state.refund_success_message && (
          <CustomContentAlert delay={4000} message={this.state.refund_success_message} className="toast-success" />
        )}
        {this.state.show_error && this.state.refund_error_message && (
          <CustomContentAlert delay={4000} message={this.state.refund_error_message} className="toast-error" />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, TicketDetails, Logindetails, SuperAdminDetails }) => {
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { property_id: PropertyId, user_type, suite_id, timezone, property, admin_login, id } = UserProfileDetails;
  const { ticketList, updateTicketStatus, getSessionData } = TicketDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { getAllProperties } = SuperAdminDetails;
  return {
    api_response,
    api_error_message,
    api_success_message,
    PropertyId,
    user_type,
    suite_id,
    timezone,
    property,
    ticketList,
    updateTicketStatus,
    main_body,
    getAllProperties,
    admin_login,
    id,
    getSessionData,
  };
};

const mapStateToDispatch = {
  changeSuiteId: UserProfileActions.changeSuiteId,
  remove_resident: ResidentsActions.remove_resident,
  confirm_remove_resident: ResidentsActions.confirm_remove_resident,
  edit_resident: ResidentsActions.edit_resident,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  getTicketList: TicketActions.getTicketList,
  doCloseTicket: TicketActions.doCloseTicket,
  ticketStatusChange: TicketActions.ticketStatusChange,
  ticketDisputeStatusChange: TicketActions.statusChange,
  pdfSendToMail: TicketActions.pdfSendToMail,
  getBlobPdf: TicketActions.getBlobPdf,
  getPng: TicketActions.getPng,
  get_all_properties: SuperAdminAction.get_all_properties,
  findTicketRow: TicketActions.findTicketRow,
  changeTicketStateAndStatus: TicketActions.changeTicketStateAndStatus,
  getPDFandPNGfromZpl: TicketActions.getPDFandPNGfromZpl,
  getMessageList: TicketActions.getMessageList,
  sendTransactionMail: ReportsAction.sendPaymentMail,
  getEncodedFileFromLabelaryApi: TicketActions.getEncodedFileFromLabelaryApi,
  getSessionList: TicketActions.getSessionList,
};

export default connect(mapStateToProps, mapStateToDispatch)(Citation);

const StatusIcon = (status) => {
  let src = "";
  switch (status.status) {
    case "In Dispute":
      src = dispute;
      break;
    case "Unpaid":
      src = unpaid;
      break;
    case "Paid":
      src = paid;
      break;
    case "In-Dispute 2":
      src = dispute;
      break;
    case "Dispute Rejected":
      src = unpaid;
      break;
    case "Dispute Rejected 2":
      src = unpaid;
      break;
    case "Reduced":
      src = unpaid;
      break;
    case "Invalid":
      src = unpaid;
      break;
    case "Dispute Approved":
      src = paid;
      break;
    case "Warning":
      src = paid;
      break;
    case "Testing":
      src = paid;
      break;
    case "Paid Cash":
      src = paid_cash;
      break;
    case "Paid Check":
      src = paid_check;
      break;
    case "Paid Credit Card Manually":
      src = paid_creditcard;
      break;
    case "Paid Interac":
      src = paid_interac;
      break;
  }
  return <img src={src} width={18} />;
};

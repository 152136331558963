import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import CreateProperty from "../Components/CreateProperty/CreateProperty";
import AddCamera from "../Components/AddCamera/AddCamera";
import AddLane from "../Components/AddLane/AddLane";
import AddLot from "../Components/AddLot/AddLot";
import AddUser from "../Components/AddUser/AddUser";
import Contact from "../Components/Contact/Contact";
import CreatePropertyForm from "../Components/CreateProperty/CreatePropertyForm";
import AddCameraForm from "../Components/AddCamera/AddCameraForm";
import AddUserForm from "../Components/AddUser/AddUserForm";
import AddLotForm from "../Components/AddLot/AddLotForm";
import AddLaneForm from "../Components/AddLane/AddLaneForm";
import Settings from "../Components/Settings/Settings";
import Sidebar from "../Components/Sidebar/Sidebar";
import AdminNavbar from "../Components/Navbars/AdminNavbar";
import sidebarImage from "../../assets/img/sidebar-5.jpg";
import superAdmindashboardRoutes from "../../superAdminRoutes";
import "react-perfect-scrollbar/dist/css/styles.css";
import LandingPage from "../Components/LandingPage/LandingPage";
import UserProfile from "../Components/UserProfile/UserProfile";
import UserSearch from "../Components/UserSearch/UserSearch";
import CitationSetup from "../Components/CitationSetup/CitationSetup";
import QuickPermitSetup from "../Components/QuickPermitSetup/QuickPermitSetup";
import Dispute from "../Components/Ticket/Dispute";
import CopySettings from "../Components/CopySettings/CopySettings";
import AddEdgeDeviceFunc from "../Components/AddEdgeDevice/AddEdgeDeviceFunc";
import ArchivedProperty from "../Components/ArchivedProperty/ArchivedProperty";
import Footer from "../Components/Footer";
import Privacy from "../Components/PrivacyPolicy/Privacy";
import PrintedCitationSetup from "../Components/PrintedCitationSetup/printedCitationSetup";
import PrivacyFrench from "../Components/PrivacyPolicy/PrivacyFrench";
import Permissions from "../Components/Permissions/Permissions";
import UserTypes from "../Components/UserTypes/UserTypes";
import LotSharing from "../Components/LotSharing/LotSharing";
import ActivityLog from "../Components/ActivityLog/ActivityLog";
import ParkingPermitSetup from "../Components/ParkingPermitSetup/ParkingPermitSetup";
import { connect } from "react-redux";
import { SuperAdminAction } from "../Actions";
import AddMeterConfigList from "../Components/AddMeterConfig/AddMeterConfigList";

const ValidPages = [
  "/landingpage",
  "/properties",
  "/creatpropertyform",
  "/addcameraform",
  "/adduserform",
  "/addlotform",
  "/addlaneform",
  "/settings",
  "/user-profile",
  "/citation-setup",
  "/quick-permit-setup",
  "/parking-permit-setup",
  // "/driver-ticket",
  "/dt",
  "/ticket-payment",
  "/dispute",
  "/add-edge-device",
  "/manage-meter-config",
  "/privacy-policy",
  "/privacy-policy-french",
  "/archivedproperty",
  "/printed-citation-setup",
  "/permissions",
  "/copy-settings",
  "/user-search",
  "/user-types",
  "/lot-sharing",
  "/activity-logs-report",
  "/unit-permit-setup",
  "/paid-permit-setup",
];

class SuperAdminRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserProfileDetails: null,
      user_type: "",
      image: sidebarImage,
      color: "black",
      isSidebarHidden: false,
      footerSize: false,
      mainRoutes: [],
      AllValidPages: ValidPages,
    };
    this.mainPanel = React.createRef(null);
  }

  async componentDidMount() {
    this.setState({
      isSidebarHidden: this.props.isSidebarHidden,
      isShowSidebar: this.props.isShowSidebar,
    });
    const selectedRoutes = [];
    await this.props.getPermission();
    if (this.props.api_response) {
      if (this.props.allPermissions) {
        let modifiedPermissionList = [];
        this.props.allPermissions.forEach((element) => {
          modifiedPermissionList.push(element.toLowerCase().replace(/[^a-z]/g, ""));
        });
        modifiedPermissionList.push("DASHBOARD", "CONTACT SUPPORT", "MY ACCOUNT");
        for (const item of this.state.AllValidPages) {
          const isExits = modifiedPermissionList.filter((name) => name.toLowerCase().replace(/[^a-z]/g, "") === item.toLowerCase().replace(/[^a-z/]/g, ""));
          if (isExits) {
            selectedRoutes.push(item);
          }
        }
      }
      this.setState({
        mainRoutes: selectedRoutes,
      });
    }
  }

  handleToogle = () => {
    if (this.state.isSidebarHidden === false) {
      this.setState({ footerSize: true });
    } else {
      this.setState({ footerSize: false });
    }
    this.setState({ isSidebarHidden: !this.state.isSidebarHidden });
  };

  handleSidebarChange = (xyz) => {
    this.setState({ isSidebarHidden: xyz });
  };
  checkRoutes = (mainRoutes, url) => {
    return mainRoutes.includes(url);
  };
  render() {
    const { color, image, isSidebarHidden, isShowSidebar, footerSize } = this.state;
    const isValid = ValidPages.indexOf(this.props.location.pathname) !== -1 || this.props.location.pathname.indexOf("landingpage") !== -1;
    return (
      <>
        <div className="wrapper">
          {isShowSidebar && (
            <Sidebar
              color={color}
              image={image}
              isSidebarHidden={isSidebarHidden}
              routes={superAdmindashboardRoutes}
              handleSidebar={this.handleSidebarChange}
            />
          )}
          <div className={"main-panel" + (isSidebarHidden ? " expanded" : "")} ref={this.mainPanel}>
            <AdminNavbar
              handleSiderHide={this.handleToogle}
              domainHeader={this.props.domainHeader}
              isShowSidebar={isShowSidebar}
              admin={true}
              userType={this.props.user_type}
              adminLogin={this.props.admin_login}
              permission_id={this.props.permission_id}
            />
            <div className="content pt-3 pb-3 h-100">
              {isValid ? (
                <>
                  <Route exact path="/landingpage" render={() => <LandingPage {...this.props} />} />
                  <Route exact path="/landingpage/:domain" render={() => <LandingPage {...this.props} />} />
                  <Route exact path="/properties" render={() => <CreateProperty {...this.props} />} />
                  <Route exact path="/createpropertyform" render={() => <CreatePropertyForm {...this.props} />} />
                  <Route exact path="/addlot" render={() => <AddLot {...this.props} />} />
                  <Route exact path="/addlotform" render={() => <AddLotForm {...this.props} />} />
                  <Route exact path="/addlane" render={() => <AddLane {...this.props} />} />
                  <Route
                    exact
                    path="/activity-logs-report"
                    render={() => {
                      if (this.checkRoutes(this.state.mainRoutes, "/activity-logs-report")) {
                        return <ActivityLog {...this.props} />;
                      } else {
                        return <Redirect to={{ pathname: "/landingpage" }} />;
                      }
                    }}
                  />
                  <Route exact path="/addlaneform" render={() => <AddLaneForm {...this.props} />} />
                  <Route exact path="/addcamera" render={() => <AddCamera {...this.props} />} />
                  <Route exact path="/addcameraform" render={() => <AddCameraForm {...this.props} />} />
                  <Route exact path="/adduser" render={() => <AddUser {...this.props} />} />
                  <Route exact path="/adduserform" render={() => <AddUserForm {...this.props} />} />
                  <Route exact path="/settings" render={() => <Settings {...this.props} />} />
                  <Route exact path="/contact" render={() => <Contact {...this.props} />} />
                  <Route exact path="/user-search" render={() => <UserSearch {...this.props} />} />
                  <Route exact path="/user-profile" render={() => <UserProfile {...this.props} />} />
                  <Route exact path="/citation-setup" render={() => <CitationSetup {...this.props} />} />
                  <Route exact path="/quick-permit-setup" render={() => <QuickPermitSetup {...this.props} />} />
                  <Route exact path="/parking-permit-setup" render={() => <ParkingPermitSetup {...this.props} />} />
                  <Route exact path="/dispute" render={() => <Dispute {...this.props} history={history} />} />
                  <Route exact path="/privacy-policy" render={() => <Privacy {...this.props} history={history} />} />
                  <Route exact path="/privacy-policy-french" render={() => <PrivacyFrench {...this.props} history={history} />} />
                  <Route exact path="/add-edge-device" render={() => <AddEdgeDeviceFunc {...this.props} history={history} />} />
                  <Route exact path="/manage-meter-config" render={() => <AddMeterConfigList {...this.props} history={history} />} />
                  <Route exact path="/archivedproperty" render={() => <ArchivedProperty {...this.props} history={history} />} />
                  <Route exact path="/printed-citation-setup" render={() => <PrintedCitationSetup {...this.props} history={history} />} />
                  <Route exact path="/permissions" render={() => <Permissions {...this.props} history={history} />} />
                  <Route exact path="/copy-settings" render={() => <CopySettings {...this.props} history={history} />} />
                  <Route exact path="/user-types" render={() => <UserTypes {...this.props} history={history} />} />
                  <Route exact path="/lot-sharing" render={() => <LotSharing {...this.props} history={history} />} />
                </>
              ) : (
                <Route render={() => <Redirect to={{ pathname: "/landingpage" }} />} />
              )}
            </div>
            {footerSize ? (
              <div className="footer-full-page">
                <Footer />
              </div>
            ) : (
              <Footer />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ SuperAdminDetails, UserProfileDetails, APIResponseDetails }) => {
  const { userModulePermissions, permissions: allPermissions } = SuperAdminDetails;
  const { user_type, property_id, permission_id, timezone } = UserProfileDetails;
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  return {
    timezone,
    userModulePermissions,
    allPermissions,
    property_id,
    api_response,
    api_error_message,
    api_success_message,
    user_type,
    permission_id,
  };
};
const mapStateToDispatch = {
  getPermission: SuperAdminAction.getPermission,
};

export default connect(mapStateToProps, mapStateToDispatch)(SuperAdminRoutes);

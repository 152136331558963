import t from "./types";
import { AuthAPI, UploadFormData, UnAuthAPI, BufferAuthAPI } from "../../../Auth/requestHandler";
import apiType from "../APIResponseActions/type";
import loginType from "../LoginActions/types";
import Config from "../../../Auth/config";
import { findStartDateAndEndDateForAllReports } from "../common";

// export const getPermission
export const setDomainHeader = (propertyName, domainName) => async (dispatch) => {
  const headerData = {
    propertyName,
    domainName,
  };
  await dispatch({ type: t.SETDOMAINHEADER, payload: headerData });
};

export const getReceiptPDF = (row) => async (dispatch) => {
  try {
    console.log("check this::::::::::::::::::", row);
    const url = "get_pdf/" + row.property_id + "/" + row.id + "/" + row.is_meter;
    return await new Promise((resolve) => {
      fetch(`${Config.baseURL}/${url}`)
        .then((blobData) => blobData.blob())
        .then((response) => {
          console.log("==== response", response);
          resolve(URL.createObjectURL(response));
        })
        .catch((error) => {
          console.log("==== error", error);
          resolve(error);
        });
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const pdfSendToMail = (sendMailData) => async (dispatch) => {
  try {
    const url = "report/send-receipt-mail";
    const body = sendMailData;
    const response = await AuthAPI("domain", url, "POST", body);
    console.log("Mail send Response success::::::", response);
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    console.log(error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const export_usertypes = () => async (dispatch) => {
  try {
    try {
      return await BufferAuthAPI("domain", "usertypes/exportcsv", "GET");
    } catch (error) {
      await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const checkLastPasswordChangedDate = () => async (dispatch) => {
  try {
    const res = await AuthAPI("domain", "user/check/password/last/change", "GET");
    await dispatch({
      type: loginType.CHANGE_PASSWORD,
      payload: res.changePassword,
    });
  } catch (err) {
    console.log("err", err);
  }
};

export const setBackgroundColor = (menuHeader, menuBackground, mainBodyBackground, MainBackground, headerLogo, footerLogo, footerText) => async (dispatch) => {
  const backgroundColor = {
    menuHeader,
    menuBackground,
    mainBodyBackground,
    MainBackground,
    headerLogo,
    footerLogo,
    footerText,
  };
  await dispatch({ type: t.SETBACKGROUNDCOLOR, payload: backgroundColor });
};

export const selected_Property = (data) => async (dispatch) => {
  await dispatch({ type: t.SELECTEDPROPERTY, payload: data });
};

export const create_property = (Property_obj) => async (dispatch) => {
  try {
    const body = {
      ...Property_obj,
      login_header: null,
      login_body: null,
    };
    const response = await AuthAPI("domain", "property", "POST", body);
    if (!response?.success) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
      return;
    }
    if (response?.data) {
      await dispatch({ type: t.UPDATE_PROPERTY_LIST, payload: response.data });
      await dispatch({ type: t.GET_PROPERTY_ID, payload: response?.data?.id });
    }
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.msg,
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};
export const createTicketTemplate = (ticketTemplateConfig) => async (dispatch) => {
  try {
    const response = await AuthAPI("domain", "ticket/create-template", "POST", ticketTemplateConfig);
    console.log("Action createTicketTemplate Response::::", response);
    if (!response?.status) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
      return;
    }
    if (response?.data) {
      await dispatch({
        type: t.UPDATE_TICKET_TEMPLATE_LIST,
        payload: response.data,
      });
    }
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const get_all_properties = () => async (dispatch) => {
  try {
    const result = await AuthAPI("domain", "property", "GET");
    await dispatch({ type: t.GETALLPROPERTY, payload: result });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const get_all_ticket_templates = (propertyId) => async (dispatch) => {
  try {
    const url = "ticket/list-template/" + propertyId;
    const result = await AuthAPI("domain", url, "GET");
    await dispatch({ type: t.GET_ALL_TICKET_TEMPLATE, payload: result.data });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: result.msg,
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getContactSupport = (property_id) => async (dispatch) => {
  try {
    const result = await AuthAPI("domain", `property/${property_id}`, "GET");
    return result;
  } catch (error) {
    console.error("Error: getContactSupport", error);
  }
};

export const get_all_properties_paginated = (pageobj, type) => async (dispatch) => {
  try {
    const { page, Norow, searchValue, selectedColumn, sort_direction } = pageobj;
    const exportData = pageobj.exportData || false;

    const url =
      "property/paginate/" +
      type +
      "/?page_size=" +
      Norow +
      "&page=" +
      page +
      "&search=" +
      searchValue +
      "&order_by=" +
      selectedColumn +
      "&sorting=" +
      sort_direction +
      "&exportData=" +
      exportData;

    if (exportData) {
      try {
        return await BufferAuthAPI("domain", url, "GET");
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const result = await AuthAPI("domain", url, "GET");
      if (type === "archived") {
        await dispatch({
          type: t.GET_ALL_ARCHIVED_PROPERTY,
          payload: result,
        });
      } else {
        await dispatch({ type: t.GETALLPROPERTYPAGINATED, payload: result });
      }
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: "",
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "failure during fetch",
    });
  }
};

export const get_all_cameras_paginated = (pageobj) => async (dispatch) => {
  const { page, Norow, searchValue, selectedColumn, sort_direction } = pageobj;
  try {
    const url =
      "camera/paginate?page_size=" + Norow + "&page=" + page + "&search=" + searchValue + "&order_by=" + selectedColumn + "&sorting=" + sort_direction;
    const result = await AuthAPI("domain", url, "GET");
    await dispatch({ type: t.FETCHCAMERAS, payload: result });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: result.msg,
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const get_all_lots =
  (property_id, type, from_lots = false, hidemsg) =>
  async (dispatch) => {
    try {
      let url;
      if (type) {
        url = "lot?" + "property_id=" + property_id;
      } else {
        url = "lot?" + "property_id=" + property_id + "&type=" + type;
      }
      if (from_lots) {
        url = url + "&need_log=" + from_lots;
      }
      const response = await AuthAPI("domain", url, "GET");
      await dispatch({ type: t.GETALLLOTS, payload: response });
      if (!hidemsg) {
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: "fetch successfully",
        });
      }
    } catch (error) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: "failed to fetch",
      });
    }
  };

export const get_all_units = () => async (dispatch) => {
  try {
    const url = "user/list";
    const response = await AuthAPI("domain", url, "GET");
    await dispatch({ type: t.ALLUSERLIST, payload: response.users });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "Units fetched successfully",
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "failed to fetch",
    });
  }
};

export const get_all_lots_pagination = (pageobj) => async (dispatch) => {
  const { page, Norow, searchValue, selectedColumn, sort_direction } = pageobj;
  try {
    const url =
      "lot/paginate?page_size=" +
      Norow +
      "&page=" +
      page +
      "&search=" +
      searchValue +
      "&order_by=" +
      selectedColumn +
      "&sorting=" +
      sort_direction +
      "&property_id" +
      "";
    const response = await AuthAPI("domain", url, "GET");
    await dispatch({ type: t.GETALLLOTSPAGINATE, payload: response });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.msg,
    });
  } catch (error) {
    console.error("get_all_lots_pagination", error);
  }
};

export const fetch_lane =
  (property_id, need_log = false) =>
  async (dispatch) => {
    try {
      const url = "lane?" + "property_id=" + property_id + "&order_by=" + "created_at" + "&sorting=" + "ASC" + `${need_log ? "&need_log=true" : ""}`;
      const response = await AuthAPI("domain", url, "GET");
      await dispatch({ type: t.FETCHLANE, payload: response });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.msg,
      });
    } catch (error) {
      await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
    }
  };

export const get_all_timezones = () => async (dispatch) => {
  try {
    const response = await AuthAPI("domain", "timezones", "GET");
    await dispatch({ type: t.FETCHTIMEZONE, payload: response });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const get_all_lanes_pagination = (pageobj) => async (dispatch) => {
  const { page, Norow, searchValue, selectedColumn, sort_direction } = pageobj;
  try {
    const url =
      "lane/paginate?page_size=" +
      Norow +
      "&page=" +
      page +
      "&search=" +
      searchValue +
      "&order_by=" +
      selectedColumn +
      "&sorting=" +
      sort_direction +
      "&property_id" +
      "";
    const response = await AuthAPI("domain", url, "GET");
    await dispatch({ type: t.GETALLLANESPAGINATED, payload: response });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.msg,
    });
  } catch (error) {
    console.error("get_all_lanes_pagination Error", error);
  }
};

export const createLane = (data) => async (dispatch) => {
  console.log("data", data);
  try {
    if (!data.alias || !data.lot || !data.entry_lane) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: "Required fields can not be empty",
      });
    } else {
      const body = {
        alias: data.alias,
        lot_id: data.lot.id,
        lat: process.env.REACT_APP_LATITUDE, // "41.40338",
        lon: process.env.REACT_APP_LONGITUDE, // "2.17403",
        entry: data.entry_lane === "1" ? 1 : 0,
        exit: data.entry_lane === "1" ? 0 : 1,
        property_id: data.propertyId,
      };
      const response = await AuthAPI("domain", "lane", "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.ADDLANE,
          payload: response.data,
        });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: "lane created successfully",
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const createLot = (data) => async (dispatch) => {
  try {
    if (!data.alias) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: "Required fields can not be empty",
      });
    } else {
      const body = {
        alias: data.alias,
        lat: process.env.REACT_APP_LATITUDE, // "41.40338",
        lon: process.env.REACT_APP_LONGITUDE, // "2.17403",
        lot_capacity: 24,
        quick_permits: data.quick_permits,
        visitor_lot: data.visitor_lot,
        property_id: data.property_id,
      };
      const response = await AuthAPI("domain", "lot", "POST", body);
      if (response.action === "add") {
        await dispatch({
          type: t.ADDLOT,
          payload: response.data,
        });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: response.msg,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const editLot = (data) => async (dispatch) => {
  if (!data?.id) {
    return;
  }
  try {
    const url = "lot/" + data.id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (!response?.success) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
    if (response?.data) {
      await dispatch({
        type: t.EDIT_LOTS,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};
export const editTicketTemplate = (data) => async (dispatch) => {
  try {
    const url = "ticket/update-template/" + data.id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (!response?.success) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
    if (response?.status) {
      await dispatch({
        type: t.EDIT_TICKET_TEMPLATES,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const deleteLot =
  ({ id, propertyId }) =>
  async (dispatch) => {
    try {
      const lotId = {
        id: id,
        property_id: propertyId,
      };
      const response = await AuthAPI("domain", "lot", "DELETE", lotId);
      await dispatch({ type: t.DELETE_LOT, payload: lotId.id });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } catch (error) {
      await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
    }
  };

export const addUser = (info) => async (dispatch) => {
  try {
    console.log("info", info);
    if (!info.email || !info.first_name || !info.last_name || !info.phone || !info.user_type) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: "Required fields can not be empty",
      });
    } else {
      const url = "user";
      const response = await AuthAPI("domain", url, "POST", info);
      if (response?.success === 1) {
        await dispatch({ type: t.ADD_NEW_USER, payload: response.data });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: response.msg,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchUsers = (property_id) => async (dispatch) => {
  try {
    const url = "user?page_size=" + 10 + "&page=" + 1 + "&search=" + "" + "&order_by=" + "created_at" + "&sorting=" + "DESC" + "&property_id=" + property_id;
    const result = await AuthAPI("domain", url, "GET");
    await dispatch({ type: t.FETCHUSERLIST, payload: result });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: result.msg,
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};
export const fetchUsersPaginate = (obj) => async (dispatch) => {
  try {
    const { page, Norow, searchValue, selectedColumn, sort_direction, all } = obj;
    const url =
      "user?page_size=" + Norow + "&page=" + page + "&search=" + searchValue + "&order_by=" + selectedColumn + "&sorting=" + sort_direction + "&all=" + all;
    const result = await AuthAPI("domain", url, "GET");
    await dispatch({ type: t.FETCH_USER_LIST_PAGINATE, payload: result.data });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: result.msg,
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const editUser = (data) => async (dispatch) => {
  try {
    const url = "user/" + data.id;
    data.isUser = true;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({ type: t.EDIT_USER, payload: response.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const deleteUser = (id, property_id) => async (dispatch) => {
  try {
    const userId = {
      id: id,
      property_id,
    };
    const response = await AuthAPI("domain", "user", "DELETE", userId);
    if (response?.success) {
      await dispatch({ type: t.DELETE_USER, payload: userId.id });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};
export const deleteTicketTemplate =
  ({ id, property_id }) =>
  async (dispatch) => {
    try {
      const url = "ticket/delete-template/" + id;
      const response = await AuthAPI("domain", url, "DELETE", { property_id });
      if (response?.status) {
        await dispatch({ type: t.DELETE_TICKET_TEMPLATE, payload: id });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: response.message,
        });
      }
    } catch (error) {
      await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
    }
  };

export const getSettings = (propertyId) => async (dispatch) => {
  try {
    const url = "site_setting/settings/" + propertyId;
    const result = await AuthAPI("domain", url, "GET");

    await dispatch({ type: t.GET_SETTINGS, payload: result });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: result.msg,
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getCreditTemplateGroups = (template_id) => async (dispatch) => {
  try {
    const url = "permit/template_group/" + template_id;
    const result = await AuthAPI("domain", url, "GET");
    await dispatch({ type: t.GET_CREDIT, payload: result });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const updateSiteSetting = (data) => async (dispatch) => {
  try {
    const url = "site_setting/" + data.id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({ type: t.UPDATE_SITE_SETTING, payload: response.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const updateLoginCaption = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({ type: t.UPDATE_LOGIN_CAPTION, payload: response.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const updateSupport = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({ type: t.UPDATE_SUPPORT, payload: response.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const createDateSlot = (data) => async (dispatch) => {
  try {
    const url = "timeslot/" + data.date_range_id;

    const newDateRange = {
      range: parseInt(data.range),
      property_id: data.property_id,
      template_id: data.template,
      credit_type: data.credit_type,
      available_spot: parseInt(data.available_spot),
      multiple_permits: data.multiple_permits,
      max_restriction_per_plate: data.max_restriction_per_plate,
    };

    if (!data.group.length) {
      newDateRange.credits = parseInt(data.credits);
    } else {
      newDateRange.multipleCreditGroup = data.multipleCreditGroup;
    }

    const response = await AuthAPI("domain", url, "PATCH", newDateRange);
    if (response?.success === 1) {
      await dispatch({ type: t.CREATE_DATE_SLOT, payload: response.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.msg,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const uploadFooterLogo = (footer, id) => async (dispatch) => {
  try {
    const footerUrl = "property/upload/footer/" + id;

    const footerResponse = await UploadFormData("domain", footerUrl, "POST", footer);

    if (footerResponse?.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: footerResponse.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const uploadHeaderLogo = (header, id) => async (dispatch) => {
  try {
    const headerUrl = "property/upload/header/" + id;

    const headerResponse = await UploadFormData("domain", headerUrl, "POST", header);

    if (headerResponse?.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: headerResponse.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: headerResponse.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const get_Archieve = (id) => async (dispatch) => {
  try {
    const url = "property/archive/" + id;
    const response = await AuthAPI("domain", url, "GET");
    if (response.success) {
      await dispatch({ type: t.ARCHIVE_PROPERTY_LIST, payload: id });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const get_allUser = (obj) => async (dispatch) => {
  try {
    const { page, Norow, searchValue, selectedColumn, sort_direction, lpn, selectedOption } = obj;
    const url =
      "user/alluserdata?page_size=" +
      Norow +
      "&page=" +
      page +
      "&search=" +
      searchValue +
      "&searchKey=" +
      selectedOption +
      "&order_by=" +
      selectedColumn +
      "&sorting=" +
      sort_direction +
      "&lpn=" +
      lpn;
    const response = await AuthAPI("domain", url, "GET");
    await dispatch({ type: t.ALLUSERLIST, payload: response });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const restoreProperty = (id) => async (dispatch) => {
  try {
    const url = "property/archive/" + id;
    const response = await AuthAPI("domain", url, "GET");
    if (response.success) {
      await dispatch({ type: t.RESTORE_PROPERTY_LIST, payload: id });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const deleteProperty = (id) => async (dispatch) => {
  try {
    const url = "property/" + id;
    const response = await AuthAPI("domain", url, "DELETE");
    if (response?.success) {
      await dispatch({ type: t.DELETE_PROPERTY, payload: id });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const get_permissions =
  (id, need_log = false) =>
  async (dispatch) => {
    try {
      const url = "permission/" + id + `${need_log ? "?need_log=true" : ""}`;
      const response = await AuthAPI("domain", url, "GET");
      if (response?.success) {
        await dispatch({
          type: t.FETCH_PERMISSIONS,
          payload: response.data,
        });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: "",
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response?.message,
        });
      }
    } catch (error) {
      await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
    }
  };

export const get_user_permissions = (id) => async (dispatch) => {
  try {
    const url = "user/permission/" + id;
    const response = await AuthAPI("domain", url, "GET");
    if (response?.success) {
      await dispatch({
        type: t.FETCH_USER_PERMISSIONS,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: "",
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getPermission = () => async (dispatch) => {
  try {
    const url = "user/get/permissions";
    const response = await AuthAPI("domain", url, "GET");
    if (response?.success) {
      await dispatch({
        type: t.FETCH_ALL_PERMISSIONS,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: "",
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response?.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const set_permissions = (permissionData) => async (dispatch) => {
  try {
    const url = "permission/" + permissionData.property_id;
    const module_permissions = {
      module_permissions: permissionData.module_permissions,
      old_data: permissionData.old_data,
    };

    const response = await AuthAPI("domain", url, "PATCH", module_permissions);
    // } else {
    //   url = "permission";
    //   response = await AuthAPI("domain", url, "POST", permissionData);
    // }
    if (response?.success) {
      await dispatch({ type: t.FETCH_PERMISSIONS, payload: response.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.msg,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const copySettings = (data) => async (dispatch) => {
  try {
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
    const res = await AuthAPI("domain", "copy_setting/", "POST", data.data);
    console.log("res", res);
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: res.msg,
    });
    return true;
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};
/** WORLD-713 */

export const googleGlass = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: t.GOOGLE_GLASS,
        payload: response.data.qr_code_scanner,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};
export const mobileView = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: t.MOBILE_VIEW,
        payload: response.data.mobile_view_option,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const callWhenHere = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: t.CALL_WHEN_HERE,
        payload: response.data.call_when_here,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const extended_LPN_Info = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: t.EXTENDED_LPN_INFO,
        payload: response.data.is_extended_lpn_info,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const invoice_cron_enabled = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const visitor_payment_credit_enabled = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const allow_permits_with_citation = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const highSecurityEmail = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    console.log("response highSecurityEmail", response);
    if (response?.data) {
      await dispatch({
        type: t.SECURITY_EMAIL,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const addDateDifference = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: t.PERMIT_DATE_DIFFERENCE,
        payload: response.data.permit_date_difference,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const addAccountReference = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: t.ACCOUNT_REFERENCE,
        payload: response.data.account_reference_code,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const addMonthlyReference = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: t.MONTHLY_REFERENCE,
        payload: response.data.transaction_account_monthly,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const addHourlyReference = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: t.HOURLY_REFERENCE,
        payload: response.data.transaction_account_hourly,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const addHarmonizedSalesTax = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: t.HARMONIZED_SALES_TAX,
        payload: response.data.harmonized_sales_tax,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const addHSTpercentage = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: t.HST_PERCENTAGE,
        payload: response.data.harmonized_sales_tax,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const addSessionTime = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: t.SESSION_TIME,
        payload: response.data.session_time,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const addProcessorId = (data) => async (dispatch) => {
  try {
    const url = "property/" + data.property_id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response?.data) {
      await dispatch({
        type: t.FETCH_PROCESSORID,
        payload: response.data.processor_id,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const get_all_lots_by_lanes = (pageObj) => async (dispatch) => {
  try {
    const url = "lpr/get_lot";
    const body = {
      lane_id: pageObj.lane_id,
      property_id: pageObj.property_id,
    };
    const response = await AuthAPI("domain", url, "POST", body);
    await dispatch({ type: t.GETALLLOTS, payload: response });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "fetch successfully",
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "failed to fetch",
    });
  }
};

export const get_all_user_types = () => async (dispatch) => {
  try {
    const response = await AuthAPI("domain", "usertypes", "GET");
    await dispatch({ type: t.GET_ALL_USER_TYPES, payload: response.data });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "fetch successfully",
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "failed to fetch",
    });
  }
};

export const update_user_types = (updatedData) => async (dispatch) => {
  try {
    const url = "usertypes/update";
    const body = { userTypedata: updatedData };
    const response = await AuthAPI("domain", url, "PATCH", body);
    await dispatch({ type: t.GET_ALL_USER_TYPES, payload: response.data });
    if (response?.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response?.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response?.message,
      });
    }

    return response;
  } catch (error) {
    console.log("eeeeee", error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error?.message,
    });
  }
};

export const getPropertyNameById = (proId) => async () => {
  try {
    return await UnAuthAPI(`public/property/${proId}`, "GET");
  } catch (error) {
    console.error(error);
  }
};

export const getLotNameById = (lotId) => async () => {
  try {
    return await UnAuthAPI(`public/lot/${lotId}`, "GET");
  } catch (error) {
    console.error(error);
  }
};

export const setRtsp = (data) => async (dispatch) => {
  try {
    const url = "user/get-rtsp";
    const result = await AuthAPI("domain", url, "POST", data);
    await dispatch({ type: t.GET_RTSP_DATA, payload: result });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const destroyStreamActivityLog = (data) => async (dispatch) => {
  try {
    const url = "user/destory_stream_activity_log";
    await AuthAPI("domain", url, "POST", data);
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getLiveFeed = (propertyId, type) => async (_dispatch) => {
  try {
    return AuthAPI("domain", `user/get-live-feed/${propertyId}?type=${type}`, "GET");
  } catch (error) {
    console.error("getLiveFeed Error:--", error);
  }
};

export const getUsersActivityLogs = (pageObj) => async (dispatch) => {
  console.log("pageObj on Activity logs:::", pageObj);
  try {
    const body = { ...pageObj };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;

    const url = "user/get-user-activity-logs";

    const result = await AuthAPI("domain", url, "POST", body);
    await dispatch({ type: t.ACTIVITY_LOGS, payload: result });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: result.message,
    });
    return result;
  } catch (error) {
    console.error("getUsersActivityLogs:Error", error);
  }
};

export const fetchCashier = (PropertyId) => async (dispatch) => {
  try {
    console.log(PropertyId);
    const url = "view_cashiers/property_id/" + PropertyId;
    console.log(url);
    const result = await AuthAPI("domain", url, "GET");
    console.log("Result: ", result);
    await dispatch({ type: t.FETCHCASHIER, payload: result });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: result.msg,
    });
    return result;
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const exist_cashier = (PropertyId, user_name) => async (dispatch) => {
  try {
    console.log(PropertyId);
    const url = "exist_cashier/property_id/" + PropertyId + "/user_name/" + user_name;
    console.log(url);
    const result = await AuthAPI("domain", url, "GET");
    console.log("Result: ", result);
    await dispatch({ type: t.EXISTCASHIER, payload: result });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: result.msg,
    });
    return result;
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const editCashier = (data) => async (dispatch) => {
  try {
    const url = "update_cashier/" + data.id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    if (response) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      return response;
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const addCashier = (info) => async (dispatch) => {
  try {
    if (!info.cashier_name || !info.user_name || !info.password) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: "Required fields can not be empty",
      });
    } else {
      const url = "add_cashier/id/" + info.property_id;
      const response = await AuthAPI("domain", url, "POST", info);
      if (response) {
        await dispatch({ type: t.CREATECASHIER, payload: response.data });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: response.message,
        });
        return response;
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const deleteCashier = (id) => async (dispatch) => {
  try {
    const cashierId = {
      id: id,
    };
    const url = "delete_cashier/" + id;
    const response = await AuthAPI("domain", url, "DELETE", cashierId);
    if (response?.success) {
      await dispatch({ type: t.DELETECASHIER, payload: cashierId.id });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

import React, { useState, useEffect, useRef, useCallback } from "react";
import { secondAndMinute } from "../helper";

const Timer = (props) => {
  const [minutes, setMinutes] = useState(props.minutes);
  const [seconds, setSeconds] = useState(props.seconds);
  const myInterval = useRef();
  useEffect(() => {
    clearInterval(myInterval.current);

    myInterval.current = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval.current);
          localStorage.removeItem("otp_expiry_time");
          props.handleResend();
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
  });

  useEffect(() => {
    if (localStorage.getItem("otp_expiry_time")) {
      const data = secondAndMinute(localStorage.getItem("otp_expiry_time"));
      if (data.minutes >= 0 && data.seconds >= 0) {
        setMinutes(data.minutes);
        setSeconds(data.seconds);
      } else {
        clearInterval(myInterval.current);
        localStorage.removeItem("otp_expiry_time");
      }
    }
    return () => {
      clearInterval(myInterval.current);
    };
  });

  const secondTime = useCallback(() => {
    if (seconds < 10) {
      return `0${seconds}`;
    } else {
      return seconds;
    }
  }, [seconds]);

  return (
    <div>
      {minutes === 0 && seconds === 0 ? (
        <h6></h6>
      ) : (
        <h6>
          {" "}
          {minutes}:{secondTime()}
        </h6>
      )}
    </div>
  );
};

export default Timer;

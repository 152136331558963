import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import t from "../../Actions/PermitOptionsActions/types";
import * as SuperAdminAction from "../../Actions/SuperAdminAction/actions";
import * as PermitActions from "../../Actions/PermitActions/actions";
import { saveAs } from "file-saver";
import Loader from "../Loader/Loader";
import CustomContentAlert, { tableHeightScrollBars } from "../helper";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ConfirmationModal from "../Common/ConfirmationWaitListPermitModal";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import { Translation } from "react-i18next";
import { PermitOptionsActions } from "../../Actions";

const WaitList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { api_success_message, api_error_message } = useSelector(getAPIResponse);
  const waitList = useSelector((state) => state.PermitReducer.waitList);
  const { allPermitOptionsList } = useSelector((state) => state.PermitOptionsDetails);
  const exportList = useSelector((exportState) => exportState.PermitReducer.exportWaitList);
  const UserData = useSelector((userState) => userState.UserProfileDetails);
  const { getAllProperties: selectAllProperty, lots } = useSelector((propertyState) => propertyState.SuperAdminDetails);
  const [apiCalled, setApiCalled] = useState(false);
  console.log("waitList::::::::::::::::::::::::::::::::::::", waitList);
  const [state, setState] = useState({
    selectedProperty: UserData.property.id,
    selectedPropertyObj: {},
    childcheckbox: true,
    timezone: UserData.timezone,
    exportValue: false,
    selectedDomainName: "",
    waitListData: [],
    exportButtonDisable: true,
    finalTableHeight: 0,
    searchValue: "",
    waitListCount: 0,
    loader: false,
    selected_waitlist_data: {},
    show_cancel_modal: false,
    package_name: "all",
    lot_id: "all",
  });

  const [shouldCallSearch, setShouldCallSearch] = useState(false);

  const [items, setItems] = useState([]);

  const handleDragEnd = (result) => {
    if (!result.destination) return; // dropped outside the list

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    reorderedItems.forEach((item, index) => {
      item.waitlist_order = index + 1; // Assuming 'order' field starts from 1
    });
    console.log("reorderedItems:::::::::::::::::::::::", reorderedItems);
    setItems(reorderedItems);
  };

  useEffect(() => {
    const height = tableHeightScrollBars();
    dispatch(SuperAdminAction.get_all_properties());
    setState((prev) => ({
      ...prev,
      finalTableHeight: height,
    }));
    if (!location?.data) handleSearch();
  }, []);

  useEffect(() => {
    if (location?.data) {
      setState({
        ...state,
        lot_id: location?.data?.lot_id,
        package_name: location?.data?.package_name,
      });
      dispatch(PermitOptionsActions.getAllPermitOptionsByLots(UserData.property.id, location?.data?.lot_id));
      setShouldCallSearch(true);
    }
  }, [location?.data]);

  useEffect(() => {
    if (shouldCallSearch) {
      handleSearch();
      setShouldCallSearch(false); // Reset the flag
    }
  }, [state, shouldCallSearch]);

  useEffect(() => {
    if (state.searchedRefundList) {
      setState((prev) => ({
        ...prev,
        waitListCount: state.searchedRefundList.length,
      }));
    }
  }, [state.searchedRefundList]);

  useEffect(() => {
    const selectedProperty = selectAllProperty.length && selectAllProperty.find((item) => item.id === state.selectedProperty);
    const selectedPropertyName = UserData.property?.property_name;
    const selectedDomainName = UserData.property?.domain;
    if (selectedProperty) {
      setState((prev) => ({
        ...prev,
        selectedPropertyObj: {
          label: selectedProperty.site_name,
          value: selectedProperty.id,
        },
        selectedDomainName: selectedDomainName,
      }));
    }
    dispatch(SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName));
  }, [selectAllProperty]);

  useEffect(() => {
    if (waitList) {
      setItems(waitList);
      setState((prev) => ({
        ...prev,
        waitListData: waitList,
        waitListCount: waitList?.length,
        loader: false,
      }));
    }
  }, [waitList]);

  useEffect(() => {
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `WAITLIST-${currentTimeDate}-${state.selectedDomainName}.xls`;
    if (apiCalled && exportList) {
      setApiCalled(false);
      saveAs(
        new Blob([exportList], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  }, [exportList]);

  const handleSearch = () => {
    setState((prev) => ({ ...prev, loader: true }));
    const { childcheckbox, selectedPropertyObj, selectedProperty, timezone, lot_id, package_name } = state;
    const pageobj = {
      childcheckbox,
      timezone,
      lot_id,
      package_name,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.property_id = selectedPropertyObj.value;
    } else {
      pageobj.property_id = selectedProperty;
    }
    dispatch(PermitActions.getWaitList(pageobj));
    if (state.lot_id === "all" && state.package_name === "all") {
      dispatch({
        type: t.GET_PAID_PERMIT_OPTIONS_LIST,
        payload: [],
      });
    }
    setApiCalled(true);
    document.body.classList.remove("foc-open");
    history.replace({
      pathname: history.location.pathname,
      state: {},
    });
  };

  const handleShowCancelPermitModal = (row) => {
    setState((prev) => ({
      ...prev,
      show_cancel_modal: !state.show_cancel_modal,
      selected_waitlist_data: row,
    }));
  };

  const handleCancelPermit = () => {
    const body = {
      property_id: UserData.property.id,
      childcheckbox: true,
      lot_id: state.lot_id,
      package_name: state.package_name,
      timezone: state.timezone,
      exportValue: false,
    };
    dispatch(PermitActions.cancelWaitList(state.selected_waitlist_data.id, "admin", "cancel", body));
    handleShowCancelPermitModal();
  };

  const autoRenewButton = (row) => {
    if (row.active) {
      return (
        <div>
          <input
            type="checkbox"
            checked={row?.auto_renew}
            onChange={() => {
              handleAutoRenewActivateDeactivate(row);
            }}
          />
        </div>
      );
    } else {
      return "-";
    }
  };

  const handleAutoRenewActivateDeactivate = async (row) => {
    const body = {
      property_id: UserData.property.id,
      childcheckbox: true,
      lot_id: state.lot_id,
      package_name: state.package_name,
      timezone: state.timezone,
      exportValue: false,
    };
    dispatch(PermitActions.cancelWaitList(row.id, "admin", "auto_renew", body));
  };

  const onConfirm = async () => {
    const body = {
      property_id: UserData.property.id,
      childcheckbox: true,
      lot_id: state.lot_id,
      package_name: state.package_name,
      timezone: state.timezone,
      exportValue: false,
    };
    dispatch(PermitActions.setWaitList(items, body));
  };

  const onCancel = async () => {
    setItems([]);
    setState((prevState) => ({
      ...prevState,
      lot_id: "all",
      package_name: "all",
    }));
    dispatch({
      type: t.GET_PAID_PERMIT_OPTIONS_LIST,
      payload: [],
    });
  };

  const handleLotAndPackageFilter = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "lot_id") {
      if (value === "all") {
        setState((prevState) => ({
          ...prevState,
          package_name: "all",
        }));
      }
      dispatch(PermitOptionsActions.getAllPermitOptionsByLots(UserData.property.id, value));
    }
  };

  console.log("waitListData:::::::::::::::::::::::", state.waitListData);
  return (
    <div className="pos-rel-overflow-hide p-0">
      <header>
        <div className="active-permit-header sm-flex-column sm-align-items-start">
          <h5 className="mb-2 mb-md-0">
            <Translation>{(t) => <>{t("WaitList")}</>}</Translation>:
          </h5>
        </div>
      </header>
      {state.loader ? (
        <Loader />
      ) : (
        <>
          <Row className="align-items-end">
            <Col md="4">
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>
                  <Translation>{(t) => <>{t("Lot Name")}</>}</Translation>:
                </Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  className="px-2 py-1"
                  name="lot_id"
                  value={state.lot_id}
                  onChange={handleLotAndPackageFilter}
                  placeholder="Select Lot Name"
                >
                  <option value="all">--Select Lot--</option>
                  {(lots ?? []).map((i) => (
                    <option key={i.id} value={i.id}>
                      {i?.alias}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            {console.log("package::::", allPermitOptionsList)}
            <Col md="4">
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>
                  <Translation>{(t) => <>{t("Package Name")}</>}</Translation>:
                </Form.Label>
                <Form.Control
                  className="px-2 py-1"
                  size="sm"
                  as="select"
                  name="package_name"
                  value={state.package_name}
                  onChange={handleLotAndPackageFilter}
                  placeholder="Select Package Name"
                  disabled={!allPermitOptionsList?.length > 0}
                >
                  <option value="all">-- Select Package --</option>
                  {(allPermitOptionsList ?? []).map((i) => (
                    <option key={i.id}>{i?.package_name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md="4">
              <Button
                className="btn-fill btn-sm mb-3"
                type="Submit"
                variant="primary"
                onClick={handleSearch}
                disabled={state.lot_id === "all" || state.package_name === "all"}
              >
                <Translation>{(t) => <>{t("Find")}</>}</Translation>
              </Button>
            </Col>
          </Row>
          {items?.length > 0 ? (
            <>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="waitlist">
                  {(provided) => (
                    <div className="table-responsive overflow-auto border rounded mb-3">
                      <table className="table fs-12px mb-0" {...provided.droppableProps} ref={provided.innerRef}>
                        <thead>
                          <tr className="bg-light">
                            <th className="text-dark font-weight-bold align-top text-nowrap py-2">
                              <Translation>{(t) => <>{t("Domain")}</>}</Translation>
                            </th>
                            <th className="text-dark font-weight-bold align-top text-nowrap py-2">
                              <Translation>{(t) => <>{t("Username")}</>}</Translation>
                            </th>
                            <th className="text-dark font-weight-bold align-top text-nowrap py-2">LPN</th>
                            <th className="text-dark font-weight-bold align-top text-nowrap py-2">
                              <Translation>{(t) => <>{t("Lot Name")}</>}</Translation>
                            </th>
                            <th className="text-dark font-weight-bold align-top text-nowrap py-2">
                              <Translation>{(t) => <>{t("Package Name")}</>}</Translation>
                            </th>
                            <th className="text-dark font-weight-bold align-top text-nowrap py-2">
                              <Translation>{(t) => <>{t("Wait List Position")}</>}</Translation>
                            </th>
                            <th className="text-dark font-weight-bold align-top text-nowrap py-2">
                              <Translation>{(t) => <>{t("Unit Id")}</>}</Translation>
                            </th>
                            <th className="text-dark font-weight-bold align-top text-nowrap py-2">
                              <Translation>{(t) => <>{t("First Name")}</>}</Translation>
                            </th>
                            <th className="text-dark font-weight-bold align-top text-nowrap py-2">
                              <Translation>{(t) => <>{t("Last Name")}</>}</Translation>
                            </th>
                            <th className="text-dark font-weight-bold align-top text-nowrap py-2">
                              <Translation>{(t) => <>{t("Cancel")}</>}</Translation>
                            </th>
                            <th className="text-dark font-weight-bold align-top text-nowrap py-2">
                              <Translation>{(t) => <>{t("Auto Renew")}</>}</Translation>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {console.log("waitlist data::::::1111", items)}
                          {(items ?? []).map((item, index) => (
                            <Draggable key={item.waitlist_order} draggableId={item.waitlist_order.toString()} index={index}>
                              {(provided) => (
                                <tr className="" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <td className="py-2">{item.domain}</td>
                                  <td className="py-2">{`${item.user_name}`}</td>
                                  <td className="py-2">{item.lpn}</td>
                                  <td className="py-2">{item.lot_name}</td>
                                  <td className="py-2">{item.package_name}</td>
                                  <td className="py-2">{item.waitlist_order}</td>
                                  <td className="py-2">{item.suite_id}</td>
                                  <td className="py-2">{item.first_name}</td>
                                  <td className="py-2">{item.last_name}</td>
                                  <td className="py-2">
                                    <Button className="btn-fill text-nowrap" variant="danger" size="xs" onClick={() => handleShowCancelPermitModal(item)}>
                                      <Translation>{(t) => <>{t("Cancel")}</>}</Translation>
                                    </Button>
                                  </td>
                                  <td>{autoRenewButton(item)}</td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      </table>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="d-flex align-items-center justify-content-between">
                <p className="mr-3 mb-0 small">Note: you can change the wait list position by drag and drop.</p>
                <div className="d-flex gap-3 my-2 justify-content-end">
                  <Button className="btn-fill btn-sm" type="button" variant="danger" onClick={onCancel}>
                    <Translation>{(t) => <>{t("Cancel")}</>}</Translation>
                  </Button>
                  <Button className="btn-fill btn-sm ml-2" type="Submit" variant="primary" onClick={onConfirm}>
                    <Translation>{(t) => <>{t("Save")}</>}</Translation>
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <div className="text-center waitlist-table">
              <div className="py-5 font-14">
                <Translation>{(t) => <>{t("There_are_no_record_to_display")}</>}</Translation>
              </div>
            </div>
          )}
        </>
      )}
      {state.show_cancel_modal ? (
        <ConfirmationModal
          onOpen={state.show_cancel_modal}
          onConfirm={handleCancelPermit}
          message="Are You sure you want to cancel waitlist permit?"
          onClose={handleShowCancelPermitModal}
          loading={false}
          title={"Cancel Permit"}
          permitData={state.selected_waitlist_data}
          userType={UserData?.user_type}
        />
      ) : null}
      {console.log("api_success_message", api_success_message)}
      {api_success_message && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
      {api_error_message && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}{" "}
    </div>
  );
};

export default WaitList;

import React, { Component } from "react";
import { Card, Row, Col, Button, Dropdown, Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import CustomContentAlert, { findBySearch, tableHeightScrollBars, scroolToTop } from "../helper";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import { CurrentPermitActions, APIResponseActions, SuperAdminAction, PermitActions } from "../../Actions";
import Loader from "../Loader/Loader";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import moment from "moment";
import EditCurrentPermit from "../Modal/EditCurrentPermit";
import { saveAs } from "file-saver";
import PropertDetails from "../LandingPage/PropertySelection";
import ConfirmModal from "../Modal/ConfirmCurrent-permit";
import SendPermitRefundModal from "./SendPermitRefundModal";

class PassagePassPermit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _currentPermits: [],
      _currentPermitPackages: [],
      count: 0,
      countPermitPackages: 0,
      loader: false,
      deactivateLoader: false,
      filterdPermits: [],
      filterdPermitPackages: [],
      permitType: "PassagePass",
      status: "current",
      showError: true,
      suite_id: "",
      propertyId: this.props.property_id,
      user_type: this.props.user_type,
      searchValue: "",
      searchPermitPackages: "",
      isModalOpen: false,
      Id: "",
      call_when_here: "",
      show_modal: false,
      confirmDelete: false,
      show_alert: false,
      show_error_alert: true,
      api_success_message: "",
      api_error_message: "",
      temp_deactivation: true,
      permit_class: "",
      page: 1,
      Norow: 100,
      prevSearch: "",
      prevSearchPermitPackage: "",
      prevPage: "",
      prevNorow: "",
      selectedColumn: "created_at",
      asc: "false",
      sort_direction: "DESC",
      pageChanged: false,
      findMore: false,
      beforeState: "",
      timezone: this.props.timezone,
      property: this.props.property,
      standard: "",
      visitor_current: "",
      visitor_future: "",
      modelOpen: false,
      finalTableHeight: 0,
      exportLink: false,
      listHeading: "current",
      refundPopupOpen: false,
      show_cancel_modal: false,
      selectedPermitId: "",
      activeTab: "viewallpermits",
      selectedPropertyObj: {
        value: this.props?.property_id,
        label: this.props?.property?.property_name,
      },
      childcheckbox: true,
      emailToSend: "",
      getPassagePass: {},
    };
    this.customStyles = {
      data: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "0", // override the cell padding for head cells
          paddingRight: "0",
        },
      },
      cells: {
        style: {
          paddingLeft: "0", // override the cell padding for data cells
          paddingRight: "0",
        },
      },
    };

    const actionButton = (row) => {
      return (
        <Dropdown>
          <Dropdown.Toggle
            variant="Primary"
            className="btn-xs"
            id="dropdown-basic"
            //  disabled={row.status === "Inactive"}
          >
            Action
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="" onClick={() => this.modelOpen(row)} disabled={row.status === "Inactive"}>
              Edit Permit
            </Dropdown.Item>
            {row.fee > 0 && row.permit_class === "PassagePass" && +row.refunded_amount <= 0 && (
              <Dropdown.Item href="" onClick={() => this.refundModelOpen(row)}>
                Refund
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      );
    };

    const checkLpn = (lpn) => {
      const history = require("../../history").default;
      history.push({
        pathname: `/current-occupancy`,
        search: `audit-report&${lpn}`,
      });
    };

    const checkUnit = (suite_id) => {
      const history = require("../../history").default;
      history.push({
        pathname: `/units`,
        search: "search",
        state: {
          search: suite_id,
        },
      });
    };

    const lpnRow = (row) => {
      return row.groupid && row.permit_class === "PermitPackage" ? (
        <div className="curserPointer lpnIcon" onClick={() => checkLpn(row.lpn)}>
          {!row.primary_permit ? (
            <span style={{ color: "#359ed8" }}>{row?.lpn ? row?.lpn : "-"}</span>
          ) : (
            <span>{row?.lpn ? row?.lpn + `+(${row?.lpn_options?.length})` : "-"}</span>
          )}
        </div>
      ) : (
        <div className="curserPointer" onClick={() => checkLpn(row.lpn)}>
          {row?.lpn ? row?.lpn : "-"}
        </div>
      );
    };
    const beginTimeRow = (row) => <div>{row?.begin_time ? row.begin_time : "-"}</div>;
    const expiryTimeRow = (row) => <div>{row?.expire_time ? row.expire_time : "-"}</div>;
    const unitIdRow = (row) => (
      <div className="curserPointer" onClick={() => checkUnit(row.suite_id)}>
        {row?.suite_id ? row?.suite_id : "-"}
      </div>
    );

    const packageNameRow = (row) => {
      if (this.state.user_type === "admin" || this.state.user_type === "superadmin") {
        return (
          <>
            {row?.package_name ? (
              <a className="curserPointer" href="/managepermit-packages">
                {row?.package_name}
              </a>
            ) : (
              "-"
            )}
          </>
        );
      } else if (row?.package_name) {
        return row?.package_name;
      } else {
        return "-";
      }
    };
    const firstName = (row) => (
      <div>
        {" "}
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.first_name}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3" style={{ maxWidth: "140px" }}>
            {row?.first_name}
          </div>
        </OverlayTrigger>
      </div>
    );
    const lastNameRow = (row) => (
      <div>
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.last_name}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3" style={{ maxWidth: "140px" }}>
            {row?.last_name}
          </div>
        </OverlayTrigger>
      </div>
    );
    const phoneRow = (row) => <div className="text-nowrap">{row?.phone ? (row?.country_code || "") + row?.phone?.substring(0, 10) : "-"}</div>;
    const refundedAmount = (row) => <div className="text-nowrap">{row?.refunded_amount && `$${row?.refunded_amount}`}</div>;
    const statusRow = (row) => (
      <div className={row.status.toLowerCase() === "active" ? "text-nowrap text-green" : "text-nowrap text-danger"}>{row?.status ? row?.status : "-"}</div>
    );
    const cwhRow = (row) => {
      return (
        <div>
          <input
            type="checkbox"
            checked={row.call_when_here}
            onChange={() => {
              this.handleCall_when_here_activate_deactive(row.id);
            }}
            disabled={(this.props.user_type !== "superadmin" && this.props.user_type !== "admin") || row.status === "Inactive"}
          />
        </div>
      );
    };
    this.columns = [
      {
        name: "LPN",
        sortable: true,
        minWidth: "120px",
        maxWidth: "120px",
        selector: (row) => row?.lpn,
        cell: lpnRow,
      },
      {
        name: "Begin Time",
        sortable: true,
        minWidth: "150px",
        selector: (row) => row?.begin_time,
        cell: beginTimeRow,
      },
      {
        name: "Expiry Time",
        sortable: true,
        minWidth: "150px",
        selector: (row) => row?.expire_time,
        cell: expiryTimeRow,
      },
      {
        name: "Unit Id",
        sortable: true,
        cell: unitIdRow,
        selector: (row) => row?.suite_id,
      },
      {
        name: "Package Name",
        sortable: true,
        cell: packageNameRow,
        selector: (row) => row?.package_name,
      },
      {
        name: "First Name",
        sortable: true,
        minWidth: "140px",
        cell: firstName,
        selector: (row) => row?.first_name,
      },
      {
        name: "Last Name",
        sortable: true,
        minWidth: "140px",
        cell: lastNameRow,
        selector: (row) => row.last_name,
      },
      {
        name: "Phone",
        sortable: true,
        minWidth: "120px",
        cell: phoneRow,
        selector: (row) => row?.phone,
      },
      {
        name: "Refunded Amount",
        sortable: true,
        minWidth: "130px",
        maxWidth: "160px",
        cell: refundedAmount,
        selector: (row) => row?.refunded_amount,
      },
      {
        name: "Status",
        sortable: true,
        minWidth: "80px",
        maxWidth: "80px",
        cell: statusRow,
        selector: (row) => row?.phone,
      },
      {
        name: "Action",
        width: "100px",
        cell: actionButton,
      },
    ];

    const deactiveButton = (row) => {
      if (row.permit_class === "Visitor") {
        if (moment(row.begin_time).format("YYYY/MM/DD hh:mm:ss a") > moment().format("YYYY/MM/DD hh:mm:ss a")) {
          return (
            <Button
              className="btn-fill text-nowrap"
              variant="danger"
              size="xs"
              disabled={row.status === "Inactive" || !row.active}
              onClick={() => this.handleDeActivatePermit(row.id)}
            >
              Deactivate
            </Button>
          );
        } else if (row.deactivated === 1) {
          return "-";
        } else {
          return "Permit started";
        }
      } else {
        if (this.state.user_type === "admin" || this.state.user_type === "superadmin") {
          return (
            <Button
              className="btn-fill text-nowrap"
              variant="danger"
              size="xs"
              disabled={row.status === "Inactive" || !row.active}
              onClick={() => this.handleDeActivatePermit(row.id)}
            >
              Deactivate
            </Button>
          );
        } else if (row.deactivated === 1) {
          return "-";
        } else {
          return "Permit started";
        }
      }
    };
    if (this.props.permission_id === 2 || this.state.activeTab === "viewallpermits") {
      this.columns.splice(8, 0, {
        name: "CWH",
        sortable: true,
        center: true,
        cell: cwhRow,
        selector: (row) => row.call_when_here,
      });
      this.columns.splice(9, 0, {
        name: "Deactivate",
        sortable: true,
        cell: deactiveButton,
      });
    }

    this.expandedComponent = ({ data }) => {
      return (
        <div className="extend-row">
          <Row className="expandedView mb-1">
            <Col>
              <Row>
                <Col xs="4">
                  <b>Email</b>
                </Col>
                <Col xs="8">
                  <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{data.email}</Tooltip>}>
                    <span>{data.email}</span>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs="4">
                  <b>Invited By</b>
                </Col>
                <Col xs="8">
                  <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{data.resident_name}</Tooltip>}>
                    <span>{data.resident_name ? data.resident_name : "-"}</span>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs="4">
                  <b>Fee</b>
                </Col>
                <Col xs="8">
                  <span>{data.fee ? data.fee : "-"}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="expandedView mb-1">
            <Col className="min-w-150px">
              <Row>
                <Col xs="4">
                  <b>Create Time</b>
                </Col>
                <Col xs="8">
                  <span>{data.permit_created_at ? data.permit_created_at : "-"}</span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs="4">
                  <b>Invited Suite</b>
                </Col>
                <Col xs="8">
                  <span>{data.suite_id ? data.suite_id : "-"}</span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs="4">
                  <b>Discount</b>
                </Col>
                <Col xs="8">
                  <span>{data.discount_amount ? data.discount_amount : "-"}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="expandedView mb-1">
            <Col>
              <Row>
                <Col xs="4">
                  <b>Permit Tag</b>
                </Col>
                <Col xs="8">
                  <span>{data.permit_tag ? data.permit_tag : "-"}</span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs="4">
                  <b>Lot Name</b>
                </Col>
                <Col xs="8">
                  <OverlayTrigger
                    delay={{ show: 250, hide: 400 }}
                    placement={"top"}
                    overlay={<Tooltip id={`tooltip-qw`}>{data.lot_name ? data.lot_name : "-"}</Tooltip>}
                  >
                    <span>{data.lot_name ? data.lot_name : "-"}</span>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs="4">
                  <b>Paid Amount</b>
                </Col>
                <Col xs="8">
                  <span>{data.paid_amount && data.paid_amount > 0 ? data.paid_amount : "-"}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="expandedView mb-1">
            <Col>
              <Row>
                <Col xs="4">
                  <b>Stall Id</b>
                </Col>
                <Col xs="8">
                  <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{data.stall_id}</Tooltip>}>
                    <span>{data.stall_id ? data.stall_id : "-"}</span>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs="4">
                  <b>Group Id</b>
                </Col>
                <Col xs="8">
                  <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{data.groupid}</Tooltip>}>
                    <span>{data.groupid ? data.groupid : "-"}</span>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs="4">
                  <b>Paid By</b>
                </Col>
                <Col xs="8">
                  <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{data.groupid}</Tooltip>}>
                    <span>{data.payment_type ? data.payment_type : "-"}</span>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="expandedView mb-1">
            {data.groupid && data.lpn_options ? (
              <Col>
                <Row>
                  <Col xs="4">
                    <b>Group LPN</b>
                  </Col>
                  <Col xs="8">
                    <span style={{ color: "#359ed8" }}>{data.groupid && data.lpn_options ? data.lpn_options.join(", ") : "-"}</span>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col></Col>
            )}
            <Col>
              <Row>
                <Col xs="4">
                  <b>Group Name</b>
                </Col>
                <Col xs="8">
                  <OverlayTrigger
                    delay={{ show: 250, hide: 400 }}
                    placement={"top"}
                    overlay={<Tooltip id={`tooltip-qw`}>{data?.permit_share_group?.group_name}</Tooltip>}
                  >
                    <span>{data?.permit_share_group?.group_name}</span>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs="4">
                  <b> Access Card/ Tag</b>
                </Col>
                <Col xs="8">
                  <span>{data.card_number ? data.card_number : "-"}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    };
  }

  async componentDidMount() {
    const { user_type } = this.state;
    const selectedPropertyName = this.state.property?.property_name;
    const selectedDomainName = this.state.property?.domain;
    this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    if (this.props.location.search === "") {
      // for admin view all permits
      this.setState({ loader: true }, async () => {
        this.setState({ beforeState: this.state.permit_class });
        let suite_id = this.state.suite_id;
        if (this.props.permission_id === 2) {
          suite_id = this.props.suite_id;
          this.setState({
            suite_id: this.props.suite_id,
          });
        }
        const { page, Norow, searchValue, selectedColumn, sort_direction, permitType, status, selectedPropertyObj, PropertyId, childcheckbox } = this.state;
        let pageobj = {
          suite_id: suite_id,
          userType: user_type,
          permitType,
          status,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        if (Object.keys(selectedPropertyObj).length) {
          pageobj.PropertyId = selectedPropertyObj.value;
        } else {
          pageobj.PropertyId = PropertyId;
        }
        if (childcheckbox) {
          pageobj.childcheckbox = childcheckbox;
        }
        await this.props.fetchCurrentPermits(pageobj);
        pageobj = {
          suite_id: suite_id,
          userType: user_type,
          permitType,
          status,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        if (Object.keys(selectedPropertyObj).length) {
          pageobj.PropertyId = selectedPropertyObj.value;
        } else {
          pageobj.PropertyId = PropertyId;
        }
        if (childcheckbox) {
          pageobj.childcheckbox = childcheckbox;
        }
        await this.props.get_all_properties();

        this.setState({
          api_response: this.props?.api_response,
          all_properties: this.props?.getAllProperties,
        });
        const currentPermitData = this.props?.currentPermits?.data ? JSON.parse(JSON.stringify(this.props?.currentPermits?.data)) : [];
        this.setState({
          _currentPermits: currentPermitData,
          count: currentPermitData.length,
          filterdPermits: currentPermitData,
          loader: false,
          showError: this.props?.api_response,
          show_alert: false,
        });
      });
    } else {
      const { user_type: userType } = this.state;
      // for admin looking for resident
      const suite_id_ = this.props.location.search.split("?")[1] ? this.props.location.search.split("?")[1] : this.props.suite_id;
      this.setState({ loader: true, suite_id: suite_id_ }, async () => {
        this.setState({ beforeState: this.state.permit_class });
        const { page, Norow, searchValue, selectedColumn, sort_direction, permitType, status, selectedPropertyObj, PropertyId, childcheckbox } = this.state;
        let pageobj = {
          suite_id: suite_id_,
          userType,
          permitType,
          status,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        if (Object.keys(selectedPropertyObj).length) {
          pageobj.PropertyId = selectedPropertyObj.value;
        } else {
          pageobj.PropertyId = PropertyId;
        }
        if (childcheckbox) {
          pageobj.childcheckbox = childcheckbox;
        }
        await this.props.fetchCurrentPermits(pageobj);
        pageobj = {
          suite_id: suite_id_,
          userType,
          permitType,
          status,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        if (Object.keys(selectedPropertyObj).length) {
          pageobj.PropertyId = selectedPropertyObj.value;
        } else {
          pageobj.PropertyId = PropertyId;
        }
        if (childcheckbox) {
          pageobj.childcheckbox = childcheckbox;
        }
        //
        await this.props.get_all_properties();

        const currentPermitData = this.props?.currentPermits?.data ? JSON.parse(JSON.stringify(this.props?.currentPermits?.data)) : [];
        this.setState({
          _currentPermits: currentPermitData,
          count: currentPermitData.length,
          filterdPermits: currentPermitData,

          loader: false,
          showError: this.props?.api_response,
          show_alert: false,
        });
      });
    }
    const exportUrl = process.env.REACT_APP_CONFIG_BASE_URL;
    const height = tableHeightScrollBars();
    this.setState({
      finalTableHeight: height,
    });
    if (exportUrl) {
      this.setState({
        exportUrl: `${exportUrl}/api/v1/user/export_csv/${this.state.property.id}/permits`,
        newPermitUrl: `${exportUrl}/api/v1/user/export_csv/${this.state.property.id}/permits`,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState({ suite_id: "" });
    }
    if (!isEqual(prevProps?.currentPermitPackages, this?.props?.currentPermitPackages)) {
      const currentPermitPackagesData = this.props?.currentPermitPackages?.data ? JSON.parse(JSON.stringify(this.props?.currentPermitPackages?.data)) : [];

      const package_unpaid_data_tobe_join = currentPermitPackagesData.filter((item) => item.groupid && !item.primary_permit);

      currentPermitPackagesData.forEach((item) => {
        if (item.groupid && item.primary_permit) {
          const l = package_unpaid_data_tobe_join.filter((item2) => item2.groupid === item.groupid);
          const lpns = [];
          l.forEach((lpn) => {
            lpns.push(lpn.lpn);
          });
          item.lpn_options = lpns;
        }
      });

      this.setState(
        {
          showError: this.props?.api_response,
          temp_deactivation: true,
        },
        () => {
          if (this.state.filterdPermitPackages.length === 0) {
            this.setState({ page: 1 }, async () => {
              this.setState({});
            });
          }
          if (this.state.searchValue?.length) {
            this.handleSearchChange(this.state.searchValue);
          }
        },
      );
    }
    if (!isEqual(prevProps.currentPermits, this.props.currentPermits)) {
      const currentPermitData = this.props?.currentPermits?.data;
      this.setState(
        {
          _currentPermits: currentPermitData,
          filterdPermits: currentPermitData,
          count: currentPermitData.length,
          showError: this.props?.api_response,
          temp_deactivation: true,
        },
        () => {
          if (this.state.filterdPermits?.length === 0) {
            this.setState({ page: 1 }, async () => {
              this.setState({
                filterdPermits: currentPermitData,
                count: currentPermitData.length,
              });
            });
          }
          if (this.state.searchValue?.length) {
            this.handleSearchChange(this.state.searchValue);
          }
        },
      );
    }
  }

  modelOpen = (row) => {
    this.setState({
      modelOpen: !this.state.modelOpen,
      permitData: row,
    });
  };

  exportUrlCall = () => {
    const unix_time = Math.round(new Date().getTime() / 1000);
    const current_time = unix_time - 14400;
    const { permitType, status, searchValue, exportUrl } = this.state;
    const newPermitUrl = `${exportUrl}/?permit_type=${permitType}&status=${status}&search=${searchValue}&current_time=${current_time}`;
    this.setState({
      newPermitUrl: newPermitUrl,
    });
  };

  handleDeActivatePermit = (permitId) => {
    this.setState({
      show_modal: true,
      Id: permitId,
      confirmDelete: true,
      isModalOpen: !this.state.isModalOpen,
      temp_deactivation: false,
    });
  };

  SearchBarViewAllPermit = (target, columnList) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({
      prevSearch: this.state.searchValue,
      page: 1,
      pageChanged: true,
    });
    this.setState({ searchValue: val }, () => {
      if (this.state.searchValue.length) {
        this.exportUrlCall();
        const { searchValue } = this.state;
        if (!this.state.findMore) {
          this.setState(
            {
              pageChanged: true,
              filterdPermits: findBySearch(searchValue, this.state._currentPermits, this.state._currentPermits, columnList, this.props.timezone),
            },
            () => {
              this.setState({ count: this.state.filterdPermits.length });
            },
          );
        }
        this.setState({
          searchValue,
        });
      } else {
        this.setState({
          _currentPermits: this.state._currentPermits,
          count: this.state._currentPermits.length,
          filterdPermits: [],
        });
      }
    });
  };

  handleSearchChange = ({ target }) => {
    const columnList = [
      "lpn",
      "begin_time",
      "expire_time",
      "suite_id",
      "permit_class",
      "first_name",
      "last_name",
      "phone",
      "phone_options",
      "email",
      "permit_created_at",
      "permit_tag",
      "usage",
      "stall_id",
      "lot_name",
      "card_number",
    ];
    this.SearchBarViewAllPermit(target, columnList);
  };

  refundModelOpen = async (row) => {
    await this.props.fetchPassagePassTransactionData(row);
    if (this.props.api_response) {
      this.setState({
        getPassagePass: this.props.getPassagePass,
        refundPopupOpen: true,
        selectRowData: row,
      });
    } else {
      this.setState({
        show_error_alert: false,
        api_error_message: this.props.api_error_message,
      });
    }
    setTimeout(() => {
      this.setState({
        show_error_alert: true,
        api_error_message: "",
      });
    }, 2000);
  };

  closeRefundModel = async () => {
    this.setState({
      refundPopupOpen: false,
    });
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      setTimeout(() => {
        this.setState({ show_alert: false, api_success_message: "" });
      }, 4000);
    } else {
      this.setState({
        show_error_alert: false,
        api_error_message: this.props.api_error_message,
      });
      setTimeout(() => {
        this.setState({
          show_error_alert: true,
          api_error_message: "",
          selectRowData: {},
        });
      }, 4000);
    }
  };

  handleCall_when_here_activate_deactive = async (id) => {
    await this.props.call_when_here_activate_deactive(id);
    this.setState({
      Id: id,
    });
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
    } else {
      this.setState({
        show_error_alert: false,
        api_error_message: "CWH did not change",
      });
    }
    setTimeout(() => {
      this.setState({ show_alert: false, show_error_alert: true });
    }, 2000);
  };

  datatableHandle = (searchValue, currentPermits, filterdPermits) => {
    let column = [];
    if (this.state.activeTab === "viewallpermits") {
      column = this.columns.filter((item) => item.name !== "Cancel" && item.name !== "Auto Renew" && item.name !== "View Receipt");
    } else {
      column = this.columns.filter((item) => item.name !== "CWH" && item.name !== "Deactivate");
    }
    return (
      <DataTable
        data={searchValue?.length ? filterdPermits : currentPermits}
        columns={column}
        customStyles={this.customStyles}
        expandableRows
        expandableRowsComponent={this.expandedComponent}
        responsive={true}
        // subHeader
        onChangePage={scroolToTop}
        defaultSortAsc={false}
        fixedHeader={true}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={this.state.Norow}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  CloseModal() {
    this.setState({ modelOpen: false });
  }

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleFilterChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  resetPermitFilters = () => {
    this.setState({
      permitType: "PassagePass",
      status: "current",
    });
  };

  handleSearch = async () => {
    this.setState({ loader: true }, async () => {
      const {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        permitType,
        status,
        suite_id,
        user_type,
        selectedPropertyObj,
        childcheckbox,
        PropertyId,
      } = this.state;
      const pageObj = {
        suite_id,
        user_type,
        permitType,
        status,
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
      };
      pageObj.exportLink = false;
      if (Object.keys(selectedPropertyObj).length) {
        pageObj.PropertyId = selectedPropertyObj.value;
      } else {
        pageObj.PropertyId = PropertyId;
      }
      if (childcheckbox) {
        pageObj.childcheckbox = childcheckbox;
      }
      await this.props.fetchCurrentPermits(pageObj);
      this.setState({ loader: false, listHeading: status });
      document.body.classList.remove("foc-open");
    });
  };

  exportPemrits = () => {
    this.setState({ loader: true }, async () => {
      const {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        permitType,
        status,
        suite_id,
        user_type,
        property,
        selectedPropertyObj,
        PropertyId,
        childcheckbox,
      } = this.state;
      const pageObj = {
        suite_id,
        user_type,
        permitType: permitType,
        status,
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
      };

      pageObj.exportLink = true;

      if (Object.keys(selectedPropertyObj).length) {
        pageObj.PropertyId = selectedPropertyObj.value;
      } else {
        pageObj.PropertyId = PropertyId;
      }
      if (childcheckbox) {
        pageObj.childcheckbox = childcheckbox;
      }

      const bufferData = await this.props.fetchCurrentPermits(pageObj);

      const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
      const exportDataName = `PERMITS-${currentTimeDate}-${property?.domain}.xls`;
      if (bufferData) {
        saveAs(
          new Blob([bufferData], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
          exportDataName,
        );
      }

      this.setState({ loader: false });
    });
  };

  saveit = () => {
    const Id = this.state.Id;
    this.setState({ deactivateLoader: true }, async () => {
      await this.props.deActivatePermit(Id);
      if (this.props.api_response) {
        this.setState({
          show_alert: true,
          api_success_message: this.props.api_success_message,
          id: "",
          call_when_here: "",
          confirmDelete: false,
        });
      } else {
        this.setState({
          show_error_alert: false,
          Id: "",
          call_when_here: "",
          confirmDelete: false,
        });
      }
      this.setState({
        deactivateLoader: false,
        isModalOpen: !this.state.isModalOpen,
      });
      setTimeout(() => {
        this.setState({
          show_alert: false,
          temp_deactivation: true,
          show_error_alert: true,
        });
      }, 1500);
    });
  };

  render() {
    const {
      status,
      loader,
      confirmDelete,
      isModalOpen,
      show_alert,
      show_error_alert,
      api_success_message,
      api_error_message,
      searchValue,
      filterdPermits,
      _currentPermits,
      modelOpen,
      listHeading,
      selectedPropertyObj,
      childcheckbox,
      refundPopupOpen,
      selectRowData,
    } = this.state;
    return (
      <Row className="mx-0">
        <Col xs="12">
          <Card className="mb-0">
            <Card.Body className="pos-rel-overflow-hide">
              <>
                <div className="sidebarFilter">
                  <Card className="contact-container mb-0">
                    <Card.Header style={{ backgroundColor: this.props.main_body }}>
                      <div className="d-flex align-items-center justify-content-between">
                        <Card.Title as="h4">{`List of ${listHeading} Permit`}</Card.Title>
                        <a
                          onClick={this.hideSlider}
                          // href="javascript:void(0);"
                          className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </a>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md="12">
                          <div className="mb-2">
                            <label className="fancy-checkbox">
                              <input
                                type="checkbox"
                                name={"childcheckbox"}
                                checked={childcheckbox}
                                onChange={(e) => {
                                  this.setState({
                                    childcheckbox: !this.state.childcheckbox,
                                  });
                                }}
                                className="custom-control-input"
                                id="childcheckbox1"
                              ></input>
                              <span>
                                <i></i>Include Children Sites
                              </span>
                            </label>
                          </div>
                        </Col>
                        <Col md="12">
                          <PropertDetails
                            selectedPropertyObj={selectedPropertyObj}
                            changeProperty={(e) => {
                              this.setState({
                                selectedPropertyObj: e,
                              });
                            }}
                          />
                        </Col>
                        <Col md="12">
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>STATUS:</Form.Label>
                            <Form.Control as="select" name="status" value={status} onChange={this.handleFilterChange} placeholder="Select Status">
                              <option value="current">Current</option>
                              <option value="future">Future</option>
                              <option value="deactivated">{`Deactivated (past 30 days)`}</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer className="text-right justify-content-between d-flex">
                      <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetPermitFilters} disabled={loader}>
                        Reset
                      </Button>
                      <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                        Search
                      </Button>
                    </Card.Footer>
                  </Card>
                </div>
                <header>
                  <div className="active-permit-header sm-flex-column sm-align-items-start">
                    <h5 className="mb-2 mb-md-0">PassagePass Permits</h5>
                    <div className="d-flex align-items-center"></div>
                    <div className="filter-button d-flex flex-wrap">
                      <div className="d-flex align-items-center search-mb-0">
                        <DatatableHeader className="mt-0 mb-0" searchValue={this.state.searchValue} HandleChange={this.handleSearchChange} />
                        {filterdPermits.length || _currentPermits.length > 0 ? (
                          <div className="ml-2">
                            <span onClick={this.exportPemrits} className="btn-fill btn-sm btn btn-info btn-medium">
                              Export
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </header>
                {loader ? (
                  <Loader />
                ) : (
                  <>
                    <div className="citation-list-table view-all-permit-table">{this.datatableHandle(searchValue, _currentPermits, filterdPermits)}</div>
                    {show_alert && api_success_message && <CustomContentAlert delay={2000} message={api_success_message} className="toast-success" />}
                    {!show_error_alert && api_error_message && <CustomContentAlert delay={2000} message={api_error_message} className="toast-error" />}
                  </>
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
        {modelOpen && (
          <EditCurrentPermit
            closeModal={this.CloseModal.bind(this)}
            modelOpen={modelOpen}
            updatePermit={this.updatePermit}
            permitData={this.state.permitData}
            status={status}
          />
        )}
        {refundPopupOpen && (
          <SendPermitRefundModal
            closeModal={this.closeRefundModel}
            // handleSave={this.onConfrim}
            data={selectRowData}
            permitData={this.state.getPassagePass}
          />
        )}
        <ConfirmModal
          showBrowserModal={isModalOpen}
          deactivateLoader={this.state.deactivateLoader}
          closeModal={() =>
            this.setState({
              isModalOpen: !this.state.isModalOpen,
              Id: "",
              call_when_here: "",
              confirmDelete: false,
            })
          }
          {...(confirmDelete && {
            deActivatePermit: this.handleDeActivatePermit,
            Id: this.state.Id,
            saveit: this.saveit,
          })}
        />
      </Row>
    );
  }
}

const mapStateToProps = ({ CurrentPermitDetails, APIResponseDetails, UserProfileDetails, Logindetails, SuperAdminDetails, PermitReducer }) => {
  const { currentPermits, currentPermitPackages } = CurrentPermitDetails;
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { suite_id, PropertyId, user_type, timezone, property, permission_id } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { getAllProperties } = SuperAdminDetails;
  const { getPassagePass } = PermitReducer;
  return {
    currentPermits,
    currentPermitPackages,
    api_response,
    api_error_message,
    suite_id,
    PropertyId,
    user_type,
    api_success_message,
    timezone,
    property,
    main_body,
    permission_id,
    getAllProperties,
    getPassagePass,
  };
};

const mapStateToDispatch = {
  fetchCurrentPermits: CurrentPermitActions.fetchCurrentPermits,
  get_all_properties: SuperAdminAction.get_all_properties,
  APIResponseSuccess: APIResponseActions.APIResponseSuccess,
  deActivatePermit: CurrentPermitActions.deActivatePermit,
  call_when_here_activate_deactive: CurrentPermitActions.call_when_here_activate_deactive,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  fetchPassagePassTransactionData: PermitActions.fetchPassagePassTransactionData,
};

export default connect(mapStateToProps, mapStateToDispatch)(PassagePassPermit);

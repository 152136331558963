import t from "../../Actions/MeterConfigActions/types";

const intitialState = {
  meterDevices: [],
  meterdeviceData: [],
  allServerId: [],
  modelOpenClose: false,
  loader: false,
  meterCreateflag: false,
  message: {
    isSuccess: false,
    message: "",
  },
};

const reducer = (state = intitialState, action) => {
  switch (action.type) {
    case t.SET_METER_DEVICE_SUCCESS: {
      return {
        ...state,
        message: {
          isSuccess: true,
          message: action.payload,
        },
      };
    }
    case t.FETCH_ALL_SERVER_ID: {
      return {
        ...state,
        allServerId: action.payload,
      };
    }
    case t.GET_ALL_METER_DEVICES: {
      return {
        ...state,
        meterDevices: action.payload,
      };
    }
    case t.SET_METER_DEVICE_DATA: {
      return {
        ...state,
        meterdeviceData: action.payload,
      };
    }
    case t.SET_METER_CREATE_FLAG: {
      return {
        ...state,
        meterCreateflag: action.payload,
      };
    }
    case t.SET_METER_DEVICE_ERROR: {
      return {
        ...state,
        message: {
          isSuccess: false,
          message: action.payload,
        },
      };
    }
    case t.CLOSE_OPEN_MODEL: {
      return {
        ...state,
        modelOpenClose: action.payload,
      };
    }
    case t.SHOW_HIDE_LOADER: {
      return { ...state, loader: action.payload };
    }
    default:
      return state;
  }
};
export default reducer;

import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { TicketActions } from "../../Actions";
import { useDispatch, useSelector } from "react-redux";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";

const SendRefundModal = ({ closeModal, data }) => {
  const [apiCalled, setApiCalled] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [state, setState] = React.useState({
    selectAction: "Partial Refund",
    amount: null,
    reason: "",
    reasonError: "",
    amountError: "",
    paidFee: data.fee ? data.fee : null,
  });
  const dispatch = useDispatch();
  const [refundFormValid, setRefundFormValid] = useState(true);
  const apiResponse = useSelector(getAPIResponse);

  const refundSubmit = async () => {
    const refundData = {
      amount: state.amount ? state.amount : state.paidFee,
      reason: state.reason,
      refundAction: state.selectAction,
      property_id: data.property_id,
      ticket_id: data.id,
      type: "citation",
    };
    dispatch(TicketActions.createRefund(refundData));
    setApiCalled(true);
  };

  const handleChange = useCallback(
    (e) => {
      if (e.target.name === "amount") {
        setState((prevState) => ({
          ...prevState,
          [e.target.name]:
            +e.target.value > 0 ? e.target.value.replace(/\D/g, "") : "",
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      }
      if (e.target.name === "reason" && !e.target.value) {
        setState((prevState) => ({
          ...prevState,
          reasonError: "Reason is required",
        }));
      } else if (e.target.name === "reason" && e.target.value) {
        setState((prevState) => ({
          ...prevState,
          reasonError: "",
        }));
      }

      if (e.target.name === "amount" && !e.target.value) {
        setState((prevState) => ({
          ...prevState,
          amountError: "Amount is required",
        }));
      } else if (e.target.name === "amount" && e.target.value) {
        setState((prevState) => ({
          ...prevState,
          amountError: "",
        }));
      }
      if (e.target.value === "Full Refund") {
        setState((prevState) => ({
          ...prevState,
          amount: null,
        }));
      }
    },

    [state.amount, state.amountError, state.reasonError]
  );

  useEffect(() => {
    if (apiCalled === true) {
      setApiCalled(false);
      setIsSuccess(apiResponse?.api_response === true);
      setIsFailed(apiResponse?.api_response === false);
      setTimeout(() => {
        closeModal();
        setIsSuccess(false);
        setIsFailed(false);
        console.log(isFailed, isSuccess);
      }, 3000);
    }
  }, [apiResponse]);

  React.useEffect(() => {
    if (
      state.selectAction === "Partial Refund"
        ? state.amount && state.reason
        : state.reason
    ) {
      setRefundFormValid(false);
    } else {
      setRefundFormValid(true);
    }

    if (+state.amount === +state.paidFee || +state.amount > +state.paidFee) {
      setState((prevState) => ({
        ...prevState,
        amountError: `Please enter valid amount(less than ${state.paidFee}).`,
      }));
      setRefundFormValid(true);
    }
  }, [state.amount, state.reason, state.amountError, state.paidFee]);

  return (
    <React.Fragment>
      <Modal
        id="ticketdetails"
        lg={"medium"}
        show={true}
        // onHide={closeModal}
        dialogClassName="modal-90w"
        fullscreen={"sm-down"}
        className="modalData"
      >
        <Modal.Header
          id="import"
          className="d-flex justify-content-between bg-white align-items-center"
        >
          <h5 className="m-0 font-weight-bold">
            <Modal.Title className="m-0">Send Refund</Modal.Title>
          </h5>
          <Button
            id="modal"
            title={"Ticket"}
            type="button"
            onClick={closeModal}
            className="close-btn py-0"
            data-dismiss="modal"
          >
            <i className="nc-icon nc-simple-remove text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form>
            <Form.Label>
              Refund Action <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              custom
              value={state.selectAction}
              name="selectAction"
              onChange={handleChange}
              className="fs-14 h-40"
              //  disabled={languageSelectDisable}
            >
              <option value="Partial Refund">Partial Refund</option>
              <option value="Full Refund">Full Refund</option>
            </Form.Control>
            {state.selectAction === "Partial Refund" && (
              <div>
                <Form.Label>
                  Amount
                  <span className="asterisk">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="amount"
                  value={state.amount}
                  onChange={handleChange}
                  //  max={state.paidFee}
                  autoComplete="off"
                  required
                />
                <span className="help-block">{state.amountError}</span>
              </div>
            )}
            <Form.Label>
              Reason
              <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="reason"
              value={state.reason}
              onChange={handleChange}
              autoComplete="off"
              required
            />
            <span className="help-block">{state.reasonError}</span>
          </Form>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button disabled={refundFormValid} onClick={refundSubmit}>
            Submit
          </Button>
          <Button onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default SendRefundModal;

import React, { Component } from "react";
import { connect } from "react-redux";
import { APIResponseActions, UserProfileActions } from "../../Actions";
import loginBG from "../../../assets/img/condopark_bg.svg";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import CustomContentAlert, { routing_using_history, validation_message } from "../helper";
import Timer from "./Timer";
import TimerFailedAttempt from "./TimerFailedAttempt";
import ConfirmationModal from "../Common/ConfirmationModal";
class LoginPage extends Component {
  constructor(props) {
    super(props);
    let site = "";
    const domainList = window.location.hostname.split(".");
    if (domainList.length >= 3) {
      site = domainList.slice(1, domainList.length).join(".");
    } else {
      site = window.location.hostname;
    }
    this.state = {
      show_alert: false,
      show_error: false,
      username: "",
      password: "",
      submitted: false,
      loader: false,
      backgroung: loginBG || "",
      title: "World Stream",
      login_body: `WorldStream.${process.env.REACT_APP_ENV} is a parking management solution designed with ease of access and security in mind.  When used with LPR Express, gain seamless access to your parking areas without having to push any buttons.  Security is enhanced by taking photos of the license plates of all vehicles entering and exiting. CallWhenHere, when supported, allows access with a simple phone call or SMS. Visitor permits allow for quick registration of drivers, ensuring access and preventing ticketing by enforcement.`,
      wrongCreds: false,
      showMsg: false,
      showError: false,
      forgetPass: false,
      otpPass: false,
      otp: "",
      email: "",
      invalemail: false,
      validUsername: true,
      validpassword: true,
      emailValidate: false,
      contentvisible: false,
      show_err: false,
      api_error_message: "",
      error_message_for_subdomain_not_exist: "",
      currect_link: "",
      isDomianExist: true,
      reassignStorage: {},
      site,
      minutes: 0,
      seconds: 59,
      isResend: false,
      isHighSecurity: false,
      api_success_message: "",
      isFailedAttempt: false,
      openConfirmationModal: false,
      secondDeviceLogin: false,
      countResend: 0,
    };
  }

  componentDidMount = async () => {
    const usr = decodeURIComponent(this.props.location.pathname.split("/")[2]);
    const pwd = decodeURIComponent(this.props.location.pathname.split("/")[3]);
    const loginObj = {
      username: usr,
      password: pwd,
    };
    if (this.props.location?.pathname?.split("/")?.[2] && this.props.location?.pathname?.split("/")?.[3]) {
      this.setState({
        loader: true,
      });
      await this.props.userLogin(loginObj, false, null);
      if (this.props.api_response) {
        window.location.reload();
      }
    }
    await this.props.getLoginInfo();
    if (this.props.login_info && Object.keys(this.props.login_info).length) {
      this.setState({
        title: this.props.login_info.login_header,
        login_body: this.props.login_info.login_body,
      });
    }
    if (
      !this.props.is_sub_domain_not_exist &&
      (window.location.href !== `https://worldstream.${process.env.REACT_APP_ENV}/login` ||
        window.location.href !== `https://worldstream.${process.env.REACT_APP_ENV}`)
    ) {
      this.setState({
        error_message_for_subdomain_not_exist: this.props.error_message_for_subdomain_not_exist,
        currect_link: this.props.currect_link,
        isDomianExist: this.props.is_sub_domain_not_exist,
      });
    }
    try {
      if (localStorage?.getItem("ticket_state")) localStorage.removeItem("ticket_state");
      if (localStorage?.getItem("ticket_country")) localStorage.removeItem("ticket_country");
    } catch (err) {
      console.error("Error accessing localStorage of ticket_state and ticket_country:", err);
    }
    this.login_page();
  };

  componentDidUpdate = (pervProps) => {
    console.log(pervProps.high_security, this.props.high_security);
  };

  hydrateStateWithLocalStorage() {
    const basicAuth = localStorage.getItem("basicAuth");
    const user = localStorage.getItem("user");
    this.setState({ reassignStorage: { basicAuth: basicAuth, user: user } });
  }

  saveStateToLocalStorage() {
    // for every item in React state
    // for (const key in this.state) {
    //   // save to localStorage
    // }
    const { reassignStorage } = this.state;
    localStorage.setItem("basicAuth", JSON.stringify(reassignStorage.basicAuth));
    localStorage.setItem("user", JSON.stringify(reassignStorage.user));
  }

  hideAlert = () => {
    setTimeout(() => {
      this.setState({
        show_alert: false,
      });
    }, 4000);
  };

  handleEnterPress = (event) => {
    if (event.key === "Enter") {
      this.handleSubmit(event);
    }
  };

  handleEnterPressOtp = (event) => {
    if (event.key === "Enter") {
      this.handleOtpSubmit(event);
    }
  };

  handleChange = ({ target }) => {
    this.setState(
      {
        [target.name]: target.value.includes(" ") ? target.value.replace(/\s/g, "") : target.value,
        wrongCreds: this.state.wrongCreds && !this.state.wrongCreds,
        isFailedAttempt: false,
      },
      () => {
        if (target.name === "username" && this.state.username.trim().length === 0) {
          this.setState({
            username: "",
            validUsername: false,
          });
        } else if (target.name === "password" && this.state.password.trim().length === 0) {
          this.setState({
            password: "",
            validpassword: false,
          });
        }
      },
    );
  };

  AddMinutesToDate = async (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
  };

  handleSubmitOnClick = (e) => {
    e.preventDefault();
    const { isHighSecurity, forgetPass } = this.state;
    if (isHighSecurity) {
      this.handleOtpSubmit(e);
    } else {
      if (!forgetPass) {
        this.handleSubmit(e);
      } else {
        this.handleEmailSubmit(e);
      }
    }
  };

  handleSubmitDisabled = () => {
    const { otp, isHighSecurity, forgetPass, username, password, showError, email } = this.state;
    if (!otp.trim().length && isHighSecurity) {
      return true;
    } else {
      if (!forgetPass) {
        return !(username.trim().length && password.trim().length);
      } else {
        return showError || !email.trim().length;
      }
    }
  };

  handleSubmitWithShowmsgAndLoder = () => {
    const { showMsg, loader, forgetPass } = this.state;
    if (showMsg) {
      return <div></div>;
    } else {
      if (!loader) {
        return (
          <Button
            className="btn-fill pull-right w-100"
            type="button"
            variant="info"
            onClick={(e) => this.handleSubmitOnClick(e)}
            disabled={this.handleSubmitDisabled()}
          >
            {!forgetPass ? "Login" : "Submit"}
          </Button>
        );
      } else {
        return <Spinner animation="border" variant="info" />;
      }
    }
  };

  handleTextLinkOnLogin = () => {
    const { showMsg, forgetPass } = this.state;
    if (!forgetPass) {
      return "Forgot Password?";
    } else {
      if (!showMsg) {
        return "Back";
      } else {
        return "Log in back";
      }
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { username: enteredUsername, password, secondDeviceLogin } = this.state;
    const newPassword = this.props.newpassword;
    this.setState({ submitted: true }, () => {
      this.setState({ isFailedAttempt: true });
      if (enteredUsername.trim().length && password.trim().length) {
        this.setState({ loader: true, wrongCreds: false }, async () => {
          const username = enteredUsername.includes(" ") || enteredUsername.includes("\t") ? enteredUsername.trim() : enteredUsername;
          const userLogin = await this.props.userLogin({
            username,
            password,
            newPassword,
            secondDeviceLogin,
          });
          if (userLogin) {
            if (userLogin.already_active_user) {
              this.setState({
                openConfirmationModal: true,
                secondDeviceLogin: true,
              });
            } else {
              this.setState({
                openConfirmationModal: false,
              });
            }
            if (this.props.userProfileDetails?.link) {
              window.location.replace(this.props.userProfileDetails?.link);
            }
            if (this.props.high_security && !this.state.openConfirmationModal) {
              if (this.props.changePassword) {
                routing_using_history("/change-profile/" + userLogin.userId);
              } else {
                const now = new Date();
                const expiration_time = await this.AddMinutesToDate(now, 1);
                localStorage.setItem("otp_expiry_time", expiration_time);
                await this.props.generateOtp(username, expiration_time);

                if (this.props.api_response) {
                  this.setState({
                    show_alert: true,
                    api_success_message: this.props.api_success_message,
                    isHighSecurity: true,
                    minutes: 0,
                    seconds: 59,
                  });
                  this.hideAlert();
                } else {
                  this.setState({
                    show_error: true,
                    api_error_message: this.props.api_error_message,
                  });
                  setTimeout(() => {
                    this.setState({ show_error: false });
                  }, 4000);
                }
                this.setState({ otpPass: true, loader: false });
              }
            } else {
              if (
                this.props.auth &&
                this.props.userProfileDetails &&
                Object.keys(this.props.userProfileDetails).length &&
                !this.props.userProfileDetails?.link &&
                !this.props.userProfileDetails?.link.length
              ) {
                if (this.props.userProfileDetails && this.props.userProfileDetails.user_type === "unit") {
                  if (this.props.userProfileDetails?.permits_allowed_type === 0) {
                    routing_using_history("/dashboard");
                  } else {
                    routing_using_history("/visitor-permit");
                  }
                } else if (this.props.userProfileDetails && this.props.userProfileDetails.user_type === "admin") {
                  routing_using_history("/dashboard");
                } else {
                  routing_using_history("/landingpage");
                }
              } else if (
                this.props.auth &&
                this.props.userProfileDetails &&
                Object.keys(this.props.userProfileDetails).length &&
                this.props.userProfileDetails?.link &&
                this.props.userProfileDetails?.link.length
              ) {
                if (this.props.userProfileDetails && this.props.userProfileDetails.user_type === "unit") {
                  routing_using_history("/visitor-permit");
                } else if (this.props.userProfileDetails && this.props.userProfileDetails.user_type === "admin") {
                  routing_using_history("/dashboard");
                }
              } else {
                if (this.props.high_security) {
                  const {
                    showMsg,
                    forgetPass,
                    otpPass,
                    submitted,
                    email,
                    otp,
                    showError,
                    // invalemail,
                  } = this.state;
                  this.setState({
                    loader: false,
                  });

                  this.switchCase(
                    showMsg,
                    forgetPass,
                    otpPass,
                    submitted,
                    email,
                    otp,
                    showError,
                    // invalemail
                  );
                } else {
                  this.setState({
                    loader: false,
                    wrongCreds: true,
                    api_error_message: this.props.api_error_message,
                  });

                  if (this.props.api_failed_attempt_count >= 3 && this.props.api_failed_attempt_count) {
                    const now = new Date();
                    const blocked_time = await this.AddMinutesToDate(now, 10);
                    localStorage.setItem("blocked_time", blocked_time);
                    this.setState({
                      minutes: 9,
                      seconds: 59,
                    });
                  } else {
                    try {
                      if (localStorage.getItem("blocked_time")) {
                        localStorage.removeItem("blocked_time");
                      }
                    } catch (err) {
                      console.error("Error on accessing localStorage of blocked_time:", err);
                    }
                  }
                }
              }
            }
          }
        });
      }
    });
  };

  toggleForm = () => {
    this.setState({
      forgetPass: !this.state.forgetPass,
      submitted: false,
      email: "",
      showError: false,
      invalemail: false,
      showMsg: false,
      username: "",
      password: "",
      validUsername: true,
      validpassword: true,
      contentvisible: false,
    });
  };

  togglePassword = () => {
    this.setState({ contentvisible: !this.state.contentvisible });
  };

  handleEmailChange = (event) => {
    const email = event.target.value.trim();
    if (email.length === 0) {
      this.setState({ showError: true, wrongCreds: false });
    } else {
      const emailValidators =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.setState(
        {
          emailValidate: emailValidators.test(String(email).toLowerCase()),
        },
        () => {
          this.setState({ email });
          if (this.state.emailValidate === false) {
            this.setState({ invalemail: true, showError: true });
          } else {
            this.setState({ invalemail: false, showError: false });
          }
        },
      );
    }
    this.setState({ email });
  };

  handleOtpChange = (event) => {
    const otp = event.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    if (otp.length <= 6) {
      this.setState({ otp: otp });
    }
  };

  handleEmailSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;
    if (this.state.emailValidate === true) {
      this.setState({ showError: false, loader: true }, async () => {
        await this.props.forgetPassword(email).then(() => {
          if (this.props.api_success_message) {
            this.setState({
              showMsg: this.props.api_success_message,
              forgetPass: true,
            });
          } else {
            this.setState({
              show_err: true,
              api_error_message: this.props.api_error_message,
            });
            setTimeout(() => {
              this.setState({ show_err: false });
            }, 2000);
          }
          this.setState({
            loader: false,
            showError: false,
            email: "",
            invalemail: false,
          });
        });
      });
    } else {
      this.setState({
        invalemail: true,
        email: "",
      });
    }
  };

  handleOtpSubmit = async (e) => {
    e.preventDefault();
    const { otp, username } = this.state;
    const passed_username = username.toString().trim();
    const result = this.props.highsecurity_result;
    const loginObj = this.props.highsecurity_loginobj;
    if (this.state.otp.length > 0) {
      this.setState({ showError: false, loader: true }, async () => {
        await this.props.verifyOtp(otp, passed_username, result, loginObj).then(async () => {
          if (this.props.api_success_message) {
            if (this.props.userProfileDetails?.link) {
              window.location.replace(this.props.userProfileDetails?.link);
            }
            localStorage.removeItem("blocked_time");
            localStorage.removeItem("otp_expiry_time");
            this.setState({
              showMsg: true,
              otpPass: false,
              isHighSecurity: false,
            });
          } else {
            await this.failedAttempt();
            this.setState({
              show_error: true,
              api_error_message: this.props.api_error_message,
            });
            setTimeout(() => {
              this.setState({ show_error: false });
            }, 2000);
          }
          this.setState({
            loader: false,
            showError: false,
            otp: "",
          });
        });
      });
    }
  };

  failedAttempt = async () => {
    if (this.props.api_failed_attempt_count >= 3) {
      this.setState({
        forgetPass: false,
        showMsg: false,
        isHighSecurity: false,
      });
      const now = new Date();
      const blocked_time = await this.AddMinutesToDate(now, 10);
      localStorage.setItem("blocked_time", blocked_time);
    }
  };

  handleEmailblur = () => {
    const email = this.state.email;
    if (email.trim().length === 0) {
      this.setState({ invalemail: false });
    } else {
      const emailValidators =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.setState(
        {
          emailValidate: emailValidators.test(String(email).toLowerCase()),
        },
        () => {
          if (this.state.emailValidate) {
            this.setState({ invalemail: false, showError: false });
          } else {
            this.setState({ invalemail: true, showError: true });
          }
        },
      );
    }
  };

  resendOtp = async () => {
    const { username } = this.state;
    const now = new Date();
    const passed_username = username.toString().trim();
    const expiration_time = await this.AddMinutesToDate(now, 1);
    localStorage.setItem("otp_expiry_time", expiration_time);
    // setTimeout(async () => {
    await this.props.generateOtp(passed_username, expiration_time);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        isResend: !this.state.isResend,
      });

      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
    // }, 100);
  };

  login_page = () => {
    const {
      submitted,
      username,
      validUsername,
      password,
      validpassword,
      // isFailedAttempt,
    } = this.state;
    return (
      <>
        <h2 className="title">Login</h2>
        <Row>
          <Col className={submitted && !username ? " has-error" : ""} md="12">
            <Form.Group>
              <label className="field-lbl">Username</label>
              <Form.Control
                type="text"
                className="form-control"
                name="username"
                value={username}
                onChange={this.handleChange}
                autoComplete="off"
              ></Form.Control>
              {validation_message(username, "Username", validUsername)}
            </Form.Group>
          </Col>
          <Col className={submitted && !password ? " has-error" : ""} md="12">
            <Form.Group>
              <label className="field-lbl">Password</label>
              <div className="input-mask-btn">
                <Form.Control
                  type={this.state.contentvisible ? "text" : "password"}
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                  autoComplete="off"
                  onKeyDown={this.handleEnterPress}
                />
                <button className="mask-password" type="button" onClick={this.togglePassword}>
                  {this.state.contentvisible ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                </button>
              </div>
              {validation_message(password, "Password", validpassword)}
            </Form.Group>
          </Col>
          <Col md="12">
            {this.props.api_failed_attempt_count >= 3 && (
              <TimerFailedAttempt minutes={this.state.minutes} seconds={this.state.seconds} handeleError={this.handeleError} />
            )}
          </Col>
        </Row>
      </>
    );
  };

  handeleError = () => {
    this.setState({
      api_error_message: "",
    });
  };

  forgetPassword = (submitted, email, showError, invalemail) => {
    return (
      <>
        <h2 className="title">Forgot Password?</h2>
        <Row>
          <Col className={submitted && !email ? " has-error" : ""} md="12">
            <Form.Group>
              <label className="field-lbl">Email</label>
              <Form.Control
                type="email"
                className="form-control"
                name="email"
                value={email}
                onChange={this.handleEmailChange}
                onBlur={this.handleEmailblur}
                autoComplete="off"
              ></Form.Control>
              {showError && !this.state.email.trim().length ? (
                <div className="help-block">email is required</div>
              ) : invalemail ? (
                <div className="help-block">Invalid email</div>
              ) : (
                <div></div>
              )}
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  };

  otpPassword = (submitted, otp, showError) => {
    return (
      <>
        <h2 className="title">OTP VERIFICATION</h2>
        <Row>
          <Col className={submitted && !otp ? " has-error" : ""} md="12">
            <Form.Group>
              <label className="field-lbl">OTP</label>
              <Form.Control
                type="text"
                className="form-control"
                name="otp"
                value={otp}
                onChange={this.handleOtpChange}
                // onBlur={this.handleEmailblur}
                maxlength="6"
                autoComplete="off"
                onKeyDown={this.handleEnterPressOtp}
              ></Form.Control>

              {showError && !this.state.otp.trim().length ? <div className="help-block">otp is required</div> : <div className="help-block"></div>}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.isResend ? (
              <button className="btn-link mr-5" type="button" onClick={this.resendOtp} disabled={!this.state.isResend}>
                Resend OTP
              </button>
            ) : (
              <Timer handleResend={this.resendLink} minutes={this.state.minutes} seconds={this.state.seconds} />
            )}
          </Col>
        </Row>
      </>
    );
  };

  resendLink = async () => {
    this.setState((prevstate) => ({
      countResend: prevstate.countResend + 1,
      isResend: !prevstate.isResend,
    }));
    if (this.state.countResend === 3) {
      this.setState({
        forgetPass: false,
        showMsg: false,
        isHighSecurity: false,
        countResend: 0,
        isResend: !this.state.isResend,
      });
    }
  };

  emailSent = (submitted, email) => {
    return (
      <>
        <h2 className="title">Email sent</h2>
        <Row>
          <Col className={submitted && !email ? " has-error" : " "} md="12">
            <div className="info-container">
              <p>An email has been sent with a link to reset your password.</p>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  subDomainNotExist = () => {
    const { error_message_for_subdomain_not_exist, currect_link } = this.state;
    return (
      <>
        <h2 className="title text-center">Sub Domain Not Exist</h2>
        <Row>
          <Col md="12">
            <div className="info-container">
              <p>
                {`${error_message_for_subdomain_not_exist} `}
                <a className="text-light top-header--title" href={currect_link}>
                  {currect_link}
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  switchCase = (
    showMsg,
    forgetPass,
    otpPass,
    submitted,
    email,
    otp,
    showError,
    // invalemail
  ) => {
    const { invalemail } = this.state;
    if (!forgetPass && showMsg) {
      return this.login_page();
    } else if (!showMsg && forgetPass && !otpPass) {
      return this.forgetPassword(submitted, email, showError, invalemail);
    } else if (!showMsg && this.state.isHighSecurity) {
      return this.otpPassword(submitted, otp, showError);
    } else if (showMsg && forgetPass && !this.state.isHighSecurity) {
      return this.emailSent(submitted, email);
    } else {
      return this.login_page();
    }
  };

  buildLink(api_error_message) {
    return (
      <p>
        <small>Please login with</small> <a href={api_error_message}>{api_error_message}</a>
      </p>
    );
  }

  closeConfirmationModal = () => {
    this.setState({
      openConfirmationModal: false,
      secondDeviceLogin: false,
    });
  };

  render() {
    const { loggingIn } = this.props;
    const {
      show_alert,
      show_error,
      show_err,
      submitted,
      // loader,
      backgroung,
      title,
      wrongCreds,
      showError,
      forgetPass,
      otpPass,
      email,
      otp,
      // invalemail,
      showMsg,
      api_error_message,
      api_success_message,
      login_body,
      isDomianExist,
      openConfirmationModal,
    } = this.state;
    return (
      <>
        {!isDomianExist ? (
          <div className="login-container" style={{ backgroundImage: `url(${backgroung})` }}>
            <div className="login-card">
              <div>{this.subDomainNotExist()}</div>
            </div>
          </div>
        ) : (
          <div className="login-container" style={{ backgroundImage: `url(${backgroung})` }}>
            <div className="login-card">
              <div className="info-container">
                <h2 className="title">
                  <span className={"text-capitalize"}>{this.state.site}</span>: <b>{title}</b>
                </h2>
                <p>{login_body}</p>
              </div>
              <div className="login-form">
                {this.switchCase(
                  showMsg,
                  forgetPass,
                  otpPass,
                  submitted,
                  email,
                  otp,
                  showError,
                  // invalemail
                )}
                <form name="form" onSubmit={(e) => e.preventDefault()}>
                  <Row>
                    <Col>
                      {submitted && wrongCreds && (
                        <div className="help-block">{api_error_message.startsWith("http") ? this.buildLink(api_error_message) : api_error_message}</div>
                      )}
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col md="5">
                      {
                        this.handleSubmitWithShowmsgAndLoder() // Main submit button handle function
                      }
                    </Col>
                    <Col md="7" className="text-center text-md-right mt-2 mt-md-0">
                      {!this.state.isHighSecurity && (
                        <button className="btn-link" type="button" onClick={this.toggleForm}>
                          {
                            this.handleTextLinkOnLogin() // Login page text link
                          }
                        </button>
                      )}
                    </Col>
                  </Row>

                  {loggingIn && (
                    <img
                      alt=""
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                    />
                  )}
                </form>
              </div>
            </div>
            {show_err && <CustomContentAlert delay={2000} message={api_error_message} className="toast-error bottom" />}
          </div>
        )}
        {show_alert ? <CustomContentAlert delay={4000} message={api_success_message} className="toast-success bottom" /> : null}
        {show_error ? <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" /> : null}
        {openConfirmationModal ? (
          <ConfirmationModal
            onOpen={openConfirmationModal}
            onConfirm={this.handleSubmit}
            message="Your account is requesting more than one login. Do you want to log out from other devices to continue login."
            onClose={() => this.closeConfirmationModal()}
            loading={this.state.modalLoading}
            title={"Login"}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ APIResponseDetails, ChangePasswordDetails, UserProfileDetails, Logindetails }) => {
  const { api_response, api_error_message, api_success_message, api_failed_attempt_count } = APIResponseDetails;
  const { newpassword } = ChangePasswordDetails;
  const {
    auth,
    login_info,
    userProfileDetails,
    error_message_for_subdomain_not_exist,
    currect_link,
    is_sub_domain_not_exist,
    high_security,
    highsecurity_result,
    highsecurity_loginobj,
    changePassword,
    permits_allowed_type,
  } = Logindetails;

  return {
    auth,
    high_security,
    changePassword,
    highsecurity_result,
    highsecurity_loginobj,
    login_info,
    api_response,
    api_error_message,
    api_success_message,
    UserProfileDetails,
    userProfileDetails,
    newpassword,
    currect_link,
    error_message_for_subdomain_not_exist,
    is_sub_domain_not_exist,
    api_failed_attempt_count,
    permits_allowed_type,
  };
};

export default connect(mapStateToProps, {
  userLogin: UserProfileActions.userLogin,
  checkLink: UserProfileActions.checkLink,
  APIResponseSuccess: APIResponseActions.APIResponseSuccess,
  forgetPassword: UserProfileActions.forgetPassword,
  generateOtp: UserProfileActions.generateOtp,
  verifyOtp: UserProfileActions.verifyOtp,
  getLoginInfo: UserProfileActions.getLoginInfo,
})(LoginPage);

import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import PermitNotes from "./PermitNotes";

const NoteSectionInSetting = (props) => {
  const {
    handleCheckBox,
    formData,
    isNotallow,
    handleChange,
    setFormData,
    handleValidation,
    errorState,
  } = props;

  const setNoteOptions = (options) => {
    setFormData({ ...formData, note_options: options });
    handleValidation({ ...formData, note_options: options });
  };

  return (
    <>
      <Row className="mt-3 mb-2">
        {/* WORLD-677 */}

        <Col md="auto" className="pr-0">
          <Form.Group className="d-flex align-items-center">
            <Form.Label> Allow notes</Form.Label>
            <div className="ml-2 d-flex">
              <input
                type="checkbox"
                name={"self_server"}
                onChange={handleCheckBox}
                checked={formData.self_server}
                value={formData.self_server}
              />
              <label className="ml-2 mb-0">Self serve</label>
            </div>
            <div className="ml-2 d-flex">
              <input
                type="checkbox"
                name={"regular"}
                onChange={handleCheckBox}
                checked={formData.regular}
                value={formData.regular}
              />
              <label className="ml-2 mb-0">Regular</label>
            </div>
          </Form.Group>
        </Col>
      </Row>
      {isNotallow && (
        <>
          <Row>
            <Col md="4">
              <Form.Group className="p-0 m-1">
                <label>Name of Note field</label>
                <span className="asterisk">*</span>
                <Form.Control
                  placeholder="Purpose of visit"
                  type="text"
                  name="name_of_note"
                  value={formData.name_of_note}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {errorState && errorState.name_of_note.length > 0 && (
                  <div className="help-block">{errorState.name_of_note}</div>
                )}
              </Form.Group>
            </Col>
          </Row>
          <PermitNotes
            setNoteOptions={setNoteOptions}
            options={formData.note_options}
            errorState={errorState}
          />
        </>
      )}
    </>
  );
};

export default NoteSectionInSetting;

import t from "./types";
import apiType from "../APIResponseActions/type";
import { AuthAPI } from "../../../Auth/requestHandler";

export const fetch_resident_occupancy = (pageobj) => async (dispatch) => {
  try {
    const url =
      "permit/resident_log/" +
      pageobj.PropertyId +
      "?page_size=" +
      pageobj.Norow +
      "&page=" +
      pageobj.page +
      "&search=" +
      pageobj.searchValue +
      "&order_by=" +
      pageobj.selectedColumn +
      "&sorting=" +
      pageobj.sort_direction;
    const result = await AuthAPI("domain", url, "GET");
    await dispatch({
      type: t.FETCH_RESIDENT_OCCUPANCY,
      payload: result,
    });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "fetch successful ",
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

import React, { Component } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import CitationIssuedBySite from "./CitationIssuedBySite";
import CitationIssuedByEnforcer from "./CitationIssuedByEnforcer";

class EnforcementReportCitationIssued extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
  }

  onTabClick = async (k) => {
    this.setState({ activeTab: k });
  };

  render() {
    const { activeTab } = this.state;
    return (
      <Container fluid>
        <Tabs activeKey={this.state.activeTab} id="controlled-tab-example" onSelect={(k) => this.onTabClick(k)} transition={false}>
          <Tab eventKey="1" title="By Site" className="m-0">
            {activeTab === "1" && <CitationIssuedBySite />}
          </Tab>
          <Tab eventKey="2" title="By Enforcer" className="m-0">
            {activeTab === "2" && <CitationIssuedByEnforcer />}
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

export default EnforcementReportCitationIssued;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, withRouter } from "react-router-dom";
import AdminRoutes from "./AdminRoutes";
import SuperAdminRoutes from "./SuperAdminRoutes";
import { routing_using_history } from "../Components/helper";
import { UserProfileActions } from "../Actions";
import IdleTimer from "./IdleTimer";
import { PublicRouteInPrivateList } from "./PublicRouteInPrivate";
class PrivateRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_type: "",
      admin_login: false,
      link: "",
      isSidebarHidden: false,
      isShowSidebar: true,
      mainBodyBackground_: "",
      footerLogo_: "",
      main_body: "",
      main_body_background: "",
      menu_background: "",
      menu_header: "",
    };
    this.mainPanel = React.createRef(null);
    document.addEventListener("keydown", function (event) {
      if (event.key === "Escape") {
        document.body.classList.remove("screen-zoomin-out");
      }
    });
  }

  UNSAFE_componentWillMount() {
    if (this.props.auth) {
      window.scrollTo(0, 0);
      this.props.getBrandingData(); // self api call
      this.setState({
        main_body: this.props.main_body,
        main_body_background: this.props.main_body_background,
        menu_background: this.props.menu_background,
        menu_header: this.props.menu_header,
        footer_logo: this.props.footer_logo,
        header_logo: this.props.header_logo,
      });
      const user_type = this.props?.UserProfileDetails?.user_type ? this.props?.UserProfileDetails?.user_type : "";
      const admin_login = this.props?.UserProfileDetails?.admin_login ? this.props.UserProfileDetails?.admin_login : undefined;
      const link = this.props?.UserProfileDetails?.link ? this.props.UserProfileDetails.link : "";
      const url = window.location.href;
      const splitedurl = url.split("/");
      const subDirectory = splitedurl[3];
      const isDriver = this.props.location?.state?.redirect_from === "ticket-payment";
      if (subDirectory === "driver-ticket" || subDirectory === "ticket-payment" || isDriver) {
        this.setState({
          isShowSidebar: false,
          isSidebarHidden: true,
        });
      }
      const { mainBodyBackground, footerLogo } = this.props.backgroundColor;
      this.setState({
        user_type,
        admin_login,
        link,
        mainBodyBackground_: this.props.main_body_background ? this.props.main_body_background : mainBodyBackground,
        footerLogo_: this.props.footer_logo ? this.props.footer_logo : footerLogo,
      });
    } else {
      routing_using_history("/login");
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.backgroundColor !== this.props.backgroundColor) {
      const { mainBodyBackground } = this.props.backgroundColor;
      this.setState({
        mainBodyBackground_: mainBodyBackground,
      });
    }
  };

  decideRoute = (user_type, admin_login, _link, _property_id, permission_id, menu) => {
    const allDomains = window.location.hostname.split(".");
    const { domainHeader, isSemiLogin } = this.props;
    const { isSidebarHidden, isShowSidebar } = this.state;
    const isSuperAdmin = permission_id === 1 && !admin_login;

    if ((allDomains.length === 1 && isSuperAdmin) || (allDomains.length === 2 && isSuperAdmin)) {
      return (
        <SuperAdminRoutes
          domainHeader={domainHeader}
          isSidebarHidden={isSidebarHidden}
          isShowSidebar={isShowSidebar}
          admin_login={admin_login}
          permission_id={permission_id}
        />
      );
    } else {
      return (
        <AdminRoutes
          domainHeader={domainHeader}
          isSidebarHidden={isSidebarHidden}
          isShowSidebar={isShowSidebar}
          user_type={user_type}
          admin_login={admin_login}
          permission_id={permission_id}
          isSemiLogin={isSemiLogin}
          menu={menu === undefined ? true : menu}
        />
      );
    }
  };

  isPublicRoute = () => {
    return PublicRouteInPrivateList.find((x) => this.props.location.pathname.indexOf(x) !== -1);
  };

  render() {
    const { user_type, link, mainBodyBackground_ } = this.state;
    const { admin_login, permission_id, property_id, menu } = this.props.UserProfileDetails;
    return (
      <div className={this.state.isSidebarHidden ? "wrapper full-page" : "wrapper"}>
        <div
          className="content"
          style={{
            backgroundColor: this.isPublicRoute() ? "" : mainBodyBackground_,
          }}
        >
          <IdleTimer />
          <Switch>{this.decideRoute(user_type, admin_login, link, property_id, permission_id, menu)}</Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ SuperAdminDetails, UserProfileDetails }) => {
  const { domainHeader, backgroundColor } = SuperAdminDetails;
  const { main_body_background, footer_logo, main_body, menu_background, header_logo, menu_header, property_id, permission_id, property } = UserProfileDetails;
  return {
    property_id,
    permission_id,
    domainHeader,
    backgroundColor,
    footer_logo,
    main_body_background,
    main_body,
    menu_background,
    menu_header,
    header_logo,
    property,
  };
};
const mapDispatchToProps = {
  getBrandingData: UserProfileActions.getBrandingData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoutes));

import Dexie from 'dexie';

const createOfflineCitationDatabase = () => {
    const db = new Dexie('offline_citation');
    db.version(1).stores({
        properties: "++id,property_id,grace_time",
        permits: "++id,property_id,active,lot_id,lpn,begin_time,expire_time,[property_id+active+lot_id+lpn]",
        reasons: "++id,property_id",
        tickets: "++id",
        historic_scanned_plates: "++id,property_id,lot_id,lpn,created_at,[property_id+lot_id+lpn+created_at]",
        scan_plates: "++id,property_id,lot_id,lpn,created_at,[property_id+lot_id+lpn+created_at]",
    });
    return db;
};

const db = createOfflineCitationDatabase();
export default db;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuickPermitSetupDetail } from "../../Selectors/SelfRegisterDetails";
import * as SelfRegisterActions from "../../Actions/SelfRegisterActions/actions";
import * as UserProfileActions from "../../Actions/UserProfileActions/actions";

const useQuickPermit = () => {
  const [loader, setLoader] = useState(true);
  const property_id = useSelector(
    (state) => state?.Logindetails?.login_info?.id
  );
  const dispatch = useDispatch();
  const quickPermitData = useSelector(getQuickPermitSetupDetail);
  useEffect(() => {
    if (Object.keys(quickPermitData).length === 0 && !property_id) {
      dispatch(UserProfileActions.getLoginInfo());
    } else if (Object.keys(quickPermitData).length === 0) {
      dispatch(SelfRegisterActions.getQuickPermitSetup(property_id));
    }
    if (Object.keys(quickPermitData).length > 0) {
      setLoader(false);
    }
  }, [property_id, quickPermitData]);

  return { property_id, loader, quickPermitData };
};

export default useQuickPermit;

import React, { Component } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import CustomContentAlert, { findBySearch } from "../helper";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { SuperAdminAction } from "../../Actions";
import Loader from "../Loader/Loader";
import PaginationforData from "../pagination/pagination";

class AddLot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      prevPage: "",
      prevSearch: "",
      prevNorow: "",
      filterdLots: [],
      allLotList: [],
      newLotList: [],
      loader: false,
      api_response: false,
      showPropertyModal: false,
      isModalOpen: false,
      page: 1,
      Norow: 10,
      selectedColumn: "alias",
      asc: "false",
      sort_direction: "DESC",
      count: 0,
      findMore: false,
    };
    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "0", // override the cell padding for head cells
          paddingRight: "0",
        },
      },
      cells: {
        style: {
          paddingLeft: "0", // override the cell padding for data cells
          paddingRight: "0",
        },
      },
    };
    this.columns = [
      {
        name: "Property",
        sortable: true,
        width: "120px",
        selector: (row) => row.property,
        cell: this.showProperty,
      },
      {
        name: "Lot Name",
        sortable: true,
        selector: (row) => row.alias,
        cell: this.showLotName,
      },
      {
        name: "Visitor Lot?",
        sortable: true,
        selector: (row) => row.visitor_lot,
        cell: this.showVisitorLot,
      },
      {
        name: "Lot capacity",
        sortable: true,
        selector: (row) => row.lot_capacity,
        cell: this.showLotCapacity,
      },
    ];
  }

  showProperty = (row) => <div>{row?.property?.site_name}</div>;

  showLotName = (row) => <div>{row.alias}</div>;

  showVisitorLot = (row) => <div>{row.visitor_lot ? "Yes" : "No"}</div>;

  showLotCapacity = (row) => <div>{row.lot_capacity}</div>;

  componentDidMount() {
    const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
    const pageobj = {
      page,
      Norow,
      searchValue,
      selectedColumn,
      sort_direction,
    };
    this.setState({ loader: true }, async () => {
      await this.props.get_all_lots_pagination(pageobj);
      this.setState({
        allLotList: this.props.getAllLotsPaginated,
        count: this.props.count,
        api_response: this.props.api_response,
      });
      this.setState({ loader: false });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.getAllLotsPaginated !== this.props.getAllLotsPaginated) {
      this.setState({
        newLotList: this.props.getAllLotsPaginated,
        count: this.props.count,
      });
    }
  }

  handleSearchChange = ({ target }) => {
    this.setState({
      prevSearch: this.state.searchValue,
      page: 1,
      pageChanged: true,
    });
    this.setState({ searchValue: target.value }, () => {
      if (this.state.searchValue.length) {
        if (!this.state.findMore) {
          this.setState(
            {
              newLotList: findBySearch(this.state.searchValue, this.state.allLotList, this.state.allLotList),
            },
            () => {
              this.setState({ count: this.state.newLotList.length });
            },
          );
        }
      } else {
        this.setState({ loader: true, pageChanged: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          await this.props.get_all_lots_pagination(pageobj);
          this.setState({
            pageChanged: true,
            page: 1,
            newLotList: this.state.newLotList,
          });
          this.setState({ loader: false });
        });
      }
      setTimeout(() => {
        this.setState({ findMore: false });
      }, 1000);
    });
  };

  handlepage = ({ target }) => {
    this.setState({ prevPage: this.state.page, prevNorow: this.state.Norow });
    this.setState(
      {
        [target.name]: target.value,
      },
      async () => {
        switch (target.name) {
          case "page":
            this.setState({ page: target.value }, async () => {
              this.setState({ loader: true, pageChanged: true }, async () => {
                const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
                const pageobj = {
                  page,
                  Norow,
                  searchValue,
                  selectedColumn,
                  sort_direction,
                };
                await this.props.get_all_lots_pagination(pageobj);
                this.setState({
                  allLotList: this.props.getAllLotsPaginated,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
          case "Norow":
            this.setState({ Norow: target.value }, async () => {
              this.setState({ loader: true, pageChanged: true }, async () => {
                if (this.state.Norow >= this.state.count) {
                  this.setState(
                    {
                      page: 1,
                    },
                    async () => {
                      const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
                      const pageobj = {
                        page,
                        Norow,
                        searchValue,
                        selectedColumn,
                        sort_direction,
                      };
                      await this.props.get_all_lots_pagination(pageobj);
                    },
                  );
                } else {
                  const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
                  const pageobj = {
                    page,
                    Norow,
                    searchValue,
                    selectedColumn,
                    sort_direction,
                  };
                  await this.props.get_all_lots_pagination(pageobj);
                }
                this.setState({
                  allLotList: this.props.getAllLotsPaginated,
                  api_response: this.props.api_response,
                });
                this.setState({ loader: false });
              });
            });
            break;
        }
        if (Number(this.state.page) !== Number(this.state.prevPage) || Number(this.state.Norow) !== Number(this.state.prevNorow)) {
          const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          this.setState({ loader: true }, async () => {
            await this.props.get_all_lots_pagination(pageobj);
            this.setState({
              newLotList: this.props.getAllLotsPaginated,
              api_response: this.props.api_response,
            });
            this.setState({ loader: false });
          });
        }
      },
    );
  };

  handlepagenext = async () => {
    const { page, Norow } = this.state;
    const count = this.props.count;
    const finalPage = Math.ceil(count / Norow);
    if (page + 1 <= finalPage) {
      this.setState({ page: page + 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true, pageChanged: true }, async () => {
          await this.props.get_all_lots_pagination(pageobj);
          this.setState({
            newLotList: await this.props.getAllLotsPaginated,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepageprevious = () => {
    const { page } = this.state;
    if (page !== 1) {
      this.setState({ page: page - 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true }, async () => {
          await this.props.get_all_lots_pagination(pageobj);
          this.setState({
            newLotList: await this.props.getAllLotsPaginated,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepageinitial = () => {
    if (this.state.page !== 1) {
      this.setState({ page: 1 }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true }, async () => {
          await this.props.get_all_lots_pagination(pageobj);
          this.setState({
            newLotList: this.props.getAllLotsPaginated,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  handlepagefinal = async () => {
    const { page, Norow } = this.state;
    const count = this.props.count;
    let last_page = count / Norow;
    if (last_page === 0) {
      last_page = 1;
    }
    const rounded = Math.ceil(last_page);
    if (page < rounded) {
      this.setState({ page: rounded, pageChanged: true }, async () => {
        const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        this.setState({ loader: true, pageChanged: true }, async () => {
          await this.props.get_all_lots_pagination(pageobj);
          this.setState({
            newLotList: this.props.getAllLotsPaginated,
            api_response: this.props.api_response,
          });
          this.setState({ loader: false });
        });
      });
    }
  };

  findMorePressed = () => {
    this.setState({ findMore: true }, () => {
      if (this.state.findMore) {
        if (this.state.searchValue) {
          this.setState({ loader: true, pageChanged: true }, async () => {
            const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
            const pageobj = {
              page,
              Norow,
              searchValue,
              selectedColumn,
              sort_direction,
            };
            await this.props.get_all_lots_pagination(pageobj);
            this.setState({
              pageChanged: true,
              page: 1,
              newLotList: this.props.getAllLotsPaginated,
            });
            this.setState({ loader: false });
          });
          setTimeout(() => {
            this.setState({ findMore: true });
          }, 1000);
        }
      } else {
        this.setState({ loader: true, pageChanged: true }, async () => {
          const { page, Norow, searchValue, selectedColumn, sort_direction } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          await this.props.get_all_lots_pagination(pageobj);
          this.setState({
            pageChanged: true,
            page: 1,
            newLotList: this.props.getAllLotsPaginated,
          });
          this.setState({ loader: false });
        });
        setTimeout(() => {
          this.setState({ findMore: true });
        }, 1000);
      }
    });
  };

  render() {
    const { searchValue, pageChanged, newLotList, allLotList, loader, api_response, page, Norow } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Lots</Card.Title>
                      <p className="card-category">List of Lot</p>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide">
                  <>
                    <DatatableHeader
                      Header={"Lots"}
                      searchValue={this.state.searchValue}
                      HandleChange={this.handleSearchChange}
                      findMorePressed={this.findMorePressed}
                    />
                  </>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {!api_response && (
                        <CustomContentAlert delay={2000} message={"The server did not respond, Please try again after sometime"} className="toast-error" />
                      )}
                      <DataTable
                        data={searchValue.length || pageChanged ? newLotList : allLotList}
                        columns={this.columns}
                        customStyles={this.customStyles}
                        // pagination={true}
                        paginationServer={true}
                        responsive={true}
                        subHeader
                        defaultSortAsc={false}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="38vh"
                      />
                      <PaginationforData
                        count={this.state.count}
                        data={this.props?.getAllLotsPaginated}
                        handlepageinitial={this.handlepageinitial}
                        handlepageprevious={this.handlepageprevious}
                        handlepagenext={this.handlepagenext}
                        handlepagefinal={this.handlepagefinal}
                        handlepage={this.handlepage}
                        page={page}
                        Norow={Norow}
                      />
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ SuperAdminDetails, APIResponseDetails }) => {
  const { getAllLotsPaginated, lotCount: count } = SuperAdminDetails;
  const { api_response } = APIResponseDetails;
  return { api_response, getAllLotsPaginated, count };
};
const mapStateToDispatch = {
  get_all_lots_pagination: SuperAdminAction.get_all_lots_pagination,
};
export default connect(mapStateToProps, mapStateToDispatch)(AddLot);

import React, { useState } from "react";
import { Modal, Button, Spinner, Form, Row, Col } from "react-bootstrap";
import { Translation } from "react-i18next";

const ConfirmationModal = ({ onOpen, onClose, loading, message, title, permitData, userType, onConfirm }) => {
  const [state, setState] = useState({
    selectedOption: "based_on_days",
    amount: null,
    amountError: "",
  });
  console.log("permidata::::1111", permitData);
  const [refundFormValid, setRefundFormValid] = useState(false);

  const handleUpdateChange = (e) => {
    const value = e.target.value;
    if (value === "full_amount") {
      setState((prevState) => ({
        ...prevState,
        amount: permitData?.paid_amount,
        selectedOption: value,
      }));
    } else if (value === "based_on_days") {
      setState((prevState) => ({
        ...prevState,
        amount: null,
        selectedOption: value,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedOption: value,
        amount: null,
        amountError: "",
      }));
    }
  };

  const blockInvalidChar = (e) => {
    return ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    console.log("handleChange value:::::::", value);
    if (state.selectedOption === "manual_amount" && value === "") {
      setState((prevState) => ({
        ...prevState,
        amountError: "Amount is required",
        amount: value.replace(/\D/g, ""),
      }));
    } else if (name === "amount" && e.target.value) {
      setState((prevState) => ({
        ...prevState,
        amount: value >= 0 ? value.replace(/[^\d.]/g, "") : "",
      }));
    }
  };

  const localConfirm = (e) => {
    onConfirm(e, state.selectedOption, state.amount);
  };

  React.useEffect(() => {
    if (state.selectedOption === "based_on_days" || state.selectedOption === "full_amount") {
      setRefundFormValid(false);
    } else {
      if (state.selectedOption === "manual_amount" && +state.amount >= +permitData?.paid_amount) {
        console.log("if called:::::");
        setState((prevState) => ({
          ...prevState,
          amountError: `Please enter valid amount(less than ${+permitData?.paid_amount}).`,
        }));
        setRefundFormValid(true);
      } else {
        if (!+state.amount) {
          setRefundFormValid(true);
        } else {
          setState((prevState) => ({
            ...prevState,
            amountError: ``,
          }));
          setRefundFormValid(false);
        }
      }
    }
  }, [state.amount, state.amountError, state.selectedOption]);

  return (
    <Modal lg={"medium"} show={onOpen} onHide={onClose}>
      <Modal.Header className="no-border">
        <h4 className="m-0 font-weight-bold">
          <Translation>{(t) => <>{t(`${title}`)}</>}</Translation>
        </h4>
        <Translation>
          {(t) => (
            <Button title={t(title)} type="button" onClick={onClose} className="close-btn" data-dismiss="modal">
              <i className="nc-icon nc-simple-remove"></i>
            </Button>
          )}
        </Translation>
      </Modal.Header>

      <Modal.Body className="modal-data-body">
        <p className="theme-color">
          <Translation>{(t) => <>{t(`${message}`)}</>}</Translation>
        </p>
        {(userType === "superadmin" || userType === "admin") && permitData.paid_permit_type !== "invoice" && (
          <p className="pos-rel-overflow-hide">
            <Row>
              <Col md="12">
                <Form.Group className="ml-3 mt-2 mb-2 mr-3 d-flex flex-wrap">
                  <div className="d-flex mb-1">
                    <Form.Check
                      inline
                      label="Refund partial amount based on days used"
                      name="based_on_days"
                      type="radio"
                      value="based_on_days"
                      id="1"
                      onChange={handleUpdateChange}
                      checked={state.selectedOption === "based_on_days"}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="ml-3 mt-2 mb-2 mr-3 d-flex flex-wrap">
                  <div className="d-flex mb-1">
                    <Form.Check
                      inline
                      label={`Refund full amount $${permitData?.paid_amount}`}
                      name="full_amount"
                      type="radio"
                      value="full_amount"
                      id="2"
                      onChange={handleUpdateChange}
                      checked={state.selectedOption === "full_amount"}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form.Group className="ml-3 mt-2 mb-2 mr-3 d-flex flex-wrap">
                  <div className="d-flex mb-1">
                    <Form.Check
                      inline
                      label="Refund manual Amount"
                      name="manual_amount"
                      type="radio"
                      value="manual_amount"
                      id="3"
                      onChange={handleUpdateChange}
                      checked={state.selectedOption === "manual_amount"}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="ml-1">
              <Col md="6">
                {state.selectedOption === "manual_amount" && (
                  <div>
                    <Form.Label>
                      <Translation>{(t) => <>{t(`Amount`)}</>}</Translation>
                      <span className="asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="amount"
                      value={state.amount}
                      onChange={handleChange}
                      onKeyDown={blockInvalidChar}
                      min={0}
                      autoComplete="off"
                      required
                    />
                    {console.log("state.amountError:::::::", state.amountError)}
                    <span className="help-block">{state.amountError}</span>
                  </div>
                )}
              </Col>
            </Row>
          </p>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button style={{ minWidth: "93px" }} onClick={onClose} type="button" className="btn btn-danger btn-cancel">
          <Translation>{(t) => <>{t("No")}</>}</Translation>
        </Button>
        {console.log("refundFormValid:::::::", refundFormValid)}
        {!loading ? (
          <Button onClick={localConfirm} type="button" className="btn btn-fill btn-info" disabled={refundFormValid}>
            <Translation>{(t) => <>{t("Submit")}</>}</Translation>
          </Button>
        ) : (
          <Spinner animation="border" variant="info" />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;

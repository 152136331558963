import React, { Component } from "react";
import MaterialTable, { MTableAction } from "material-table";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { SuperAdminAction, CameraActions } from "../../Actions";
import { connect } from "react-redux";
import CustomContentAlert, { navigationButtons } from "../helper";
import Loader from "../Loader/Loader";

class AddCameraForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_alert: false,
      show_error: false,
      api_success_message: "",
      api_error_message: "",
      cameraIP: "",
      cameraPlate: "Front/Rear",
      lane: "Lane",
      type: "WS-2/WS-1/WS-3",
      validCameraIP: true,
      validCameraType: true,
      validLane: true,
      formIsValid: false,
      searchValue: "",
      searchedCamera: [],
      cameraList: [],
      cameras: [],
      lanes: [],
      property_id: "",
      all_properties: [],
      columnsDataRender: 0,
      loader: false,
    };

    this.columns = [
      {
        title: "Name",
        field: "alias",
      },
      {
        title: "Visitor Lot?",
        field: "visitor_lot",
      },
      {
        title: "Visitor Lot?",
        field: "visitor_lot",
      },
      {
        title: "Visitor Lot?",
        field: "visitor_lot",
      },
    ];
    this.addActionRef = React.createRef();
  }

  componentDidMount = async () => {
    this.setState(
      {
        loader: true,
      },
      async () => {
        const property_id = this.props.location?.search?.split("?")[1] ? this.props.location.search.split("?")[1] : "";
        await this.props.get_all_properties();
        this.setState(
          {
            property_id: property_id,
            all_properties: this.props?.getAllProperties,
          },
          () => {
            const selectedPropertyObj = this.state.all_properties?.length && this.state.all_properties.filter((item) => item.id === this.state.property_id);
            const selectedPropertyName = selectedPropertyObj?.length && selectedPropertyObj[0].site_name;
            const selectedDomainName = selectedPropertyObj?.length && selectedPropertyObj[0].domain;
            this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
          },
        );
        await this.props.fetch_cameras(property_id, true);
        await this.props.fetch_lane(property_id);
        this.setState({
          cameraList: this.props.camera_list,
          lanes: this.props.lanes,
          api_response: this.props.api_response,
          loader: false,
        });
      },
    );
  };

  componentDidUpdate = (prevProps) => {
    if (JSON.stringify(this.props.camera_list) !== JSON.stringify(prevProps.camera_list)) {
      this.setState({ cameraList: this.props.camera_list });
    }
  };

  handleAddCamera = async (newData, resolver, reject) => {
    const camera_list = this.props.lanes;
    console.log("camera_list::::::", camera_list);
    const obj = camera_list.find((item) => item.id === newData.lane.id);

    newData.lane.entry_lane = obj.entry_lane;
    newData.lane.exit_lane = obj.exit_lane;

    // console.log("NewData:::::::::", newData)

    await this.props.addCamera({ ...newData });
    this.HandleErrorSuccess(resolver, reject);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        columnsDataRender: 1,
      });
      setTimeout(() => {
        this.setState({
          show_alert: false,
        });
      }, 4000);
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
        columnsDataRender: 1,
      });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  handleEditCamera = async (newData, resolve, reject) => {
    const camera_list = this.props.lanes;
    console.log("camera_list::::::", camera_list);
    const obj = camera_list.find((item) => item.id === newData.lane.id);

    newData.lane.entry_lane = obj.entry_lane;
    newData.lane.exit_lane = obj.exit_lane;
    const cameraData = {
      id: newData.id,
      lane_id: newData.lane.id,
      entry: newData.lane.entry_lane,
      exit: newData.lane.exit_lane,
      ip_addr: newData.ip_addr,
      type: newData.type,
      plate: newData.plate,
      property_id: this.state.property_id,
      old_data: this.props.camera_list.find((x) => x.id === newData.id),
    };
    await this.props.editCamera(cameraData);
    this.HandleErrorSuccess(resolve, reject);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        columnsDataRender: 1,
      });
      setTimeout(() => {
        this.setState({
          show_alert: false,
        });
      }, 4000);
    } else {
      this.setState(
        {
          show_error: true,
          api_error_message: this.props.api_error_message,
        },
        () => {
          this.setState({
            columnsDataRender: 1,
          });
        },
      );
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  handleDeleteCamera = async (cameraId, resolve, reject) => {
    await this.props.deleteCamera({
      id: cameraId,
      propertyId: this.state.property_id,
    });
    this.HandleErrorSuccess(resolve, reject);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      setTimeout(() => {
        this.setState({
          show_alert: false,
        });
      }, 4000);
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      if (reject) {
        reject(new Error(this.props.api_error_message));
      }
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  HandleErrorSuccess = (resolve, reject) => {
    if (resolve && this.props.api_response) {
      resolve();
    } else {
      if (reject && this.props.api_error_message) {
        reject(new Error(this.props.api_error_message));
      }
    }
  };

  ValidateForm = (newData, reject) => {
    const requiredFields = ["ip_addr", "lane", "plate", "type"];
    if (requiredFields.some((x) => !newData[x])) {
      this.setState({
        show_alert: true,
        api_error_message: "All fields are required.",
      });
      reject(new Error("All fields are required"));
      return true;
    }
    return false;
  };

  cameraActionField = (props) => {
    if (typeof props.action === typeof Function || props.action.tooltip !== "Add") {
      return <MTableAction {...props} />;
    } else {
      return <div ref={this.addActionRef} onClick={props.action.onClick} />;
    }
  };

  onCameraIcon = () => (
    <Button className="btn-blue-bg" data-mycustomid={"add-icon-handler"}>
      Add Camera
    </Button>
  );

  onRowAddCamera = (newData) => {
    return new Promise((resolve, reject) => {
      if (this.ValidateForm(newData, reject)) {
        return;
      }
      setTimeout(() => {
        this.handleAddCamera(newData, resolve, reject);
      }, 1000);
    });
  };

  onRowUpdateCamera = (newData) => {
    return new Promise((resolve, reject) => {
      if (this.ValidateForm(newData, reject)) {
        return;
      }
      // setTimeout(() => {
      this.handleEditCamera(newData, resolve, reject);
      // }, 1000);
    });
  };

  onRowDeleteCamera = (oldData) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.handleDeleteCamera(oldData.id, resolve, reject);
      }, 1000);
    });
  };

  render() {
    const { cameraList, lanes, show_alert, show_error, columnsDataRender, loader } = this.state;
    const laneDropdownObj = Array.isArray(lanes)
      ? lanes.reduce(function (acc, cur) {
          acc[cur.id] = cur.alias;
          return acc;
        }, {})
      : {};

    const validateIpAddress = (rowData) => {
      const regex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
      if (rowData.ip_addr === "") {
        return "IP cannot be empty";
      } else {
        if (rowData.ip_addr && !regex.test(rowData.ip_addr)) {
          return "Please enter proper ip address.";
        } else {
          return "";
        }
      }
    };

    if (!columnsDataRender) {
      this.columns = [
        {
          title: "IP",
          field: "ip_addr",
          validate: validateIpAddress,
        },
        {
          title: "Plate(front/rear)",
          field: "plate",
          lookup: { rear: "Rear", front: "Front" },
          validate: (rowData) => (rowData.plate === "" ? "Please select plate" : ""),
        },
        {
          title: "Lane",
          field: "lane.id",
          lookup: laneDropdownObj,
          validate: (rowData) => (rowData.plate === "" ? "Please select lane" : ""),
        },
        {
          title: "Type",
          field: "type",
          lookup: { WS1: "WS1", WS2: "WS2", WS3: "WS3" },
          validate: (rowData) => (rowData.plate === "" ? "Please select type" : ""),
        },
      ];
    }
    return (
      <>
        <Container fluid>
          <Row className="h-100">
            <Col md="12">
              <Card className="h-100">
                <Card.Header>
                  <Card.Title as="h4">Manage Cameras</Card.Title>
                  <p className="card-category">Fill out below to manage cameras.</p>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide">
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      <div style={{ maxWidth: "100%" }}>
                        <MaterialTable
                          title=""
                          components={{
                            Action: this.cameraActionField,
                          }}
                          options={{
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            draggable: false,
                          }}
                          columns={this.columns}
                          data={cameraList}
                          icons={{ Add: this.onCameraIcon }}
                          editable={{
                            onRowAddCancelled: (rowData) => console.log("Row adding cancelled"),
                            onRowUpdateCancelled: (rowData) => console.log("Row editing cancelled"),
                            onRowAdd: this.onRowAddCamera,
                            onRowUpdate: this.onRowUpdateCamera,
                            onRowDelete: this.onRowDeleteCamera,
                          }}
                        />
                      </div>
                      <Col lg="12" className="p-0">
                        {navigationButtons("/addlaneform", "/add-edge-device", this.state.property_id, this.addActionRef, "Add Camera")}
                      </Col>
                    </>
                  )}
                  {/* <h4>Add Camera Details</h4> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {show_alert ? <CustomContentAlert delay={4000} message={this.state.api_success_message} className="toast-success" /> : null}
        {show_error ? <CustomContentAlert delay={4000} message={this.state.api_error_message} className="toast-error" /> : null}
      </>
    );
  }
}

const mapStateToProps = ({ SuperAdminDetails, CameraDetails, APIResponseDetails }) => {
  const { cameras, lanes, getAllProperties, getAllLotsPaginated } = SuperAdminDetails;
  const { camera_list } = CameraDetails;
  const { api_response, api_success_message, api_error_message } = APIResponseDetails;
  return {
    camera_list,
    cameras,
    lanes,
    api_response,
    getAllProperties,
    api_success_message,
    api_error_message,
    getAllLotsPaginated,
  };
};

const mapStateToDispatch = {
  fetch_cameras: CameraActions.fetch_cameras,
  fetch_lane: SuperAdminAction.fetch_lane,
  addCamera: CameraActions.addCamera,
  editCamera: CameraActions.editCamera,
  deleteCamera: CameraActions.deleteCamera,
  get_all_properties: SuperAdminAction.get_all_properties,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(AddCameraForm);

import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { Translation } from "react-i18next";

const DeleteCardModal = (props) => {
  const {
    onOpen,
    onClose,
    message = "",
    onConfirm,
    isDeleteCarddisabled,
    loading,
  } = props;
  return (
    <Modal lg={"medium"} show={onOpen} onHide={onClose}>
      <Modal.Header className="no-border">
        <h4 className="m-0 font-weight-bold"><Translation>{(t) => <>{t("Delete Card")}</>}</Translation></h4>
        <Translation>{(t) => (<Button
          title={t("Delete Card")}
          type="button"
          onClick={onClose}
          className="close-btn"
          data-dismiss="modal"
        >
          <i className="nc-icon nc-simple-remove"></i>
        </Button>)}</Translation>

      </Modal.Header>

      <Modal.Body className="modal-data-body">
        <p className="theme-color"><Translation>{(t) => <>{t(message)}</>}</Translation></p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ minWidth: "93px" }}
          onClick={onClose}
          type="button"
          className="btn btn-danger btn-cancel"
        >
          <Translation>{(t) => <>{t("No")}</>}</Translation>
        </Button>
        {!loading ? (
          <Button
            onClick={onConfirm}
            type="button"
            className="btn btn-fill btn-info"
            disabled={isDeleteCarddisabled}
          >
            <Translation>{(t) => <>{t("Yes")}</>}</Translation>
          </Button>
        ) : (
          <Spinner animation="border" variant="info" />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCardModal;

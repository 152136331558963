import React from "react";
import { useSelector } from "react-redux";

const Unauthorized = () => {
  const errorMessage = useSelector(
    (state) => state.APIResponseDetails.api_error_message
  );
  return <div>{errorMessage}</div>;
};

export default Unauthorized;

import React, { Component } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { EnforcementActions, SuperAdminAction } from "../../Actions";
import { connect } from "react-redux";
import CustomContentAlert, { isEmailValid, validations, email_validation_message, validation_message } from "../helper";
import defaultImage from "../../../assets/img/plate-check.png";
const moment = require("moment-timezone");
const history = require("../../history").default;

class SendEmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_alert: false,
      api_success_message: "",
      api_error_message: "",
      show_error: false,
      photos: [],
      plate: "",
      validPlate: true,
      email: "",
      validEmail: true,
      note: "",
      validNote: true,
      isFormValid: false,
      validImage: true,
      property: "",
      domain: "",
    };
  }

  componentDidMount = async () => {
    const plate = this.props.location?.state?.lpn || "";
    const image = this.props.location?.state?.image || "";
    const plateData = this.props.location?.state?.plateData || null;
    const email = this.props.location?.state?.email || "";
    const _scan_time = this.props.location?.state?.scan_time || "";
    const isPlateApproved = this.props.location?.state?.isPlateApproved || "";
    let lastEntry =
      plateData &&
      Object.keys(plateData).length &&
      plateData.permit_logs &&
      Object.keys(plateData.permit_logs).length &&
      plateData.permit_logs?.createdAt?.length
        ? plateData.permit_logs.createdAt
        : "Not available";
    let scan_time = "-";

    if (_scan_time !== "" && parseInt(_scan_time) > 1) {
      const date = moment.unix(_scan_time).tz(this.props.timezone).format("lll");
      scan_time = date;
    }
    this.setState({
      lpn: plate,
      isPlateApproved: isPlateApproved,
      image: image,
      plateData: plate,
      scan_time: scan_time,
      lastEntry: lastEntry,
    });

    if (lastEntry !== "Not available") {
      lastEntry = moment(lastEntry).tz(this.props.timezone).format("lll");
    }

    const note = `No permit found\nPlate No: ${plate}\nLast Entry: ${lastEntry}\nScanned on: ${scan_time}`;
    console.log("initial commit" + scan_time);
    this.setState({
      photos: [
        {
          image: this.props.location?.state?.image || defaultImage,
          filename: "plate.jpg",
          id: 1,
        },
      ],
      plate: plate,
      email: email,
      note: note,
    });
    this.setState(
      {
        property: this.props.property?.property_name,
        domain: this.props.property?.domain,
      },
      () => {
        const selectedPropertyName = this.state.property;
        const selectedDomainName = this.state.domain;
        this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      },
    );
    if (plate && email && note && image) {
      this.setState({
        isFormValid: true,
      });
    }
  };

  handleAddPhoto = (selectorFile) => {
    const { photos } = this.state;
    if (selectorFile.length > 0) {
      let index;
      let id;
      if (photos && photos.length < 3) {
        const reader = new FileReader();
        reader.readAsDataURL(selectorFile[0]);
        let old_photos = [];
        if (photos[0].image === defaultImage) {
          id = 0;
          old_photos = [];
        } else {
          index = photos.length - 1;
          id = photos[index].id + 2;
          old_photos = photos;
        }
        reader.onloadend = (e) => {
          this.setState({
            photos: [
              ...old_photos,
              {
                image: reader.result,
                filename: selectorFile[0].name,
                id: id,
              },
            ],
          });
          if (this.state.plate && this.state.email && this.state.note) {
            this.setState({ isFormValid: true });
          } else {
            this.setState({ isFormValid: false });
          }
        };
      } else {
        this.setState({
          show_error: true,
          api_error_message: "Can not add more than 3 photos",
        });
        setTimeout(() => {
          this.setState({ show_error: false });
        }, 4000);
      }
    }
  };

  handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value }, async () => {
      const { plate, email, photos, note } = this.state;
      const isEmailValidate = isEmailValid(email);
      switch (target.name) {
        case "plate":
          {
            const Plate = validations(target.name, "plate", this.state.plate);
            this.setState({
              plate: Plate.value,
              validPlate: Plate.validator,
            });
          }
          break;
        case "email":
          {
            const Email = validations(target.name, "email", this.state.email);
            this.setState({
              email: Email.value,
              validEmail: Email.validator,
            });
          }
          break;
        case "note":
          {
            const Note = validations(target.name, "note", this.state.note);
            this.setState({
              note: Note.value,
              validNote: Note.validator,
            });
          }
          break;
        default:
          break;
      }
      this.setState({ wrongEmail: !isEmailValidate }, () => {
        if (plate && isEmailValidate && note && photos[0].image !== defaultImage) {
          this.setState({ isFormValid: true });
        } else {
          this.setState({ isFormValid: false });
        }
      });
    });
  };

  handleSubmit = () => {
    this.setState({ isFormValid: false }, async () => {
      const { plate, email, photos, note } = this.state;
      const form_data = new FormData();
      form_data.append("lpn", plate);
      form_data.append("email", email);
      form_data.append("note", note);
      for (const item of photos) {
        const file = await this.urltoFile(item.image, item.filename);
        form_data.append("files", file);
      }
      await this.props.sendEmail(form_data);
      if (this.props.api_response) {
        this.setState({
          show_alert: true,
          api_success_message: "Email successfully sent.",
        });
        setTimeout(() => {
          this.setState({ show_alert: false });
          history.push({
            pathname: `/photo-verify`,
          });
        }, 4000);
      } else {
        this.setState({
          show_error: true,
          api_error_message: this.props.api_error_message,
        });
        setTimeout(() => {
          this.setState({ show_error: false });
        }, 4000);
      }
    });
  };

  handleRemovePhoto = (id) => {
    const { photos } = this.state;
    const inputPhotos = [...photos];
    inputPhotos.splice(id, 1);
    this.setState({
      photos: inputPhotos,
    });
  };

  urltoFile = async (url, filename) => {
    const mimeType = (url.match(/^data:([^;]+);/) || "")[1];
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  redirectToPlatecheck = () => {
    const history = require("../../history").default;
    history.push({
      pathname: `/plate-check`,
      state: {
        lpn: this.state.lpn,
        isPlateApproved: this.state.isPlateApproved,
        image: this.state.image,
        plateData: this.state.plateData,
        scan_time: this.state.scan_time,
        lastEntry: this.state.lastEntry,
      },
    });
  };

  render() {
    const {
      plate,
      email,
      photos,
      note,
      isFormValid,
      validEmail,
      wrongEmail,
      show_alert,
      api_success_message,
      api_error_message,
      show_error,
      validPlate,
      validNote,
      validImage,
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <i style={{ marginRight: 5, cursor: "pointer" }} className="fa fa-arrow-left" onClick={this.redirectToPlatecheck} aria-hidden="true"></i>
                  <span>Send Email</span>
                </Card.Header>
                <Card.Body>
                  <Form className="send-mail-form">
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPlate">
                      <Form.Label column lg="1">
                        Plate <span className="asterisk">*</span>
                      </Form.Label>
                      <Col lg="4" className="col-item">
                        <Form.Control type="text" name="plate" value={plate} onChange={this.handleChange} placeholder="Plate Number" required />
                        {validation_message(plate, "Plate Number", validPlate)}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                      <Form.Label column lg="1">
                        Email <span className="asterisk">*</span>
                      </Form.Label>
                      <Col lg="4" className="col-item">
                        <Form.Control type="email" name="email" value={email} onChange={this.handleChange} placeholder="Email" required />
                        {email_validation_message(email, validEmail, wrongEmail)}
                      </Col>
                    </Form.Group>
                    <Row className="photo-choose mb-3">
                      <Col lg="1"></Col>
                      <div className="images-block col-lg-11">
                        {photos?.length > 0 &&
                          photos.map((item, index) => (
                            <div key={index} className="photo-choose-items">
                              <img
                                src={this.state.photos ? item.image : ""}
                                alt=""
                                height="140"
                                width="140"
                                className="image-preview"
                                accept="image/png , image/jpeg"
                              />
                              {item.id > 1 ? (
                                <Button onClick={() => this.handleRemovePhoto(index)} className="btn-fill" variant="danger">
                                  <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                              ) : null}
                            </div>
                          ))}

                        <div className="upload-image-button">
                          <div className="input-file-upload-btn">
                            <label htmlFor="file-input-choose">
                              <i className="fa fa-upload" aria-hidden="true"></i>
                            </label>
                            <Form.Control
                              className="btn-bottom"
                              type="file"
                              id="file-input-choose"
                              accept="image/png, image/jpeg"
                              onChange={(e) => this.handleAddPhoto(e.target.files)}
                              onClick={this.handleClick}
                            ></Form.Control>
                          </div>
                          {validation_message(this.state.photos, "Image", validImage)}
                        </div>
                      </div>
                    </Row>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextNote">
                      <Form.Label column lg="1">
                        Note <span className="asterisk">*</span>
                      </Form.Label>
                      <Col lg="4">
                        <Form.Control as="textarea" name="note" rows={5} value={note} onChange={this.handleChange} placeholder="Note" required />
                        {validation_message(note, "Note", validNote)}
                      </Col>
                    </Form.Group>
                    <Row>
                      <Col lg="5" className="text-right">
                        <Button type="button" onClick={this.handleSubmit} disabled={!isFormValid} className="btn-fill" variant="info">
                          Send
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {show_alert && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
        {show_error && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}
      </div>
    );
  }
}

const mapStateToProps = ({ APIResponseDetails, UserProfileDetails, Logindetails }) => {
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { timezone, property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  return {
    api_response,
    api_success_message,
    api_error_message,
    timezone,
    property,
    main_body,
  };
};

const mapStateToDispatch = {
  sendEmail: EnforcementActions.sendEmail,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(SendEmailForm);

import React, { Component } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { SuperAdminAction } from "../../Actions";
import CitationTransaction from "./citationRefund";
import PermitTransaction from "../TransactionReport/PermitTransaction";

class TransactionReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
  }

  onTabClick = async (k) => {
    this.setState({ activeTab: k });
  };

  render() {
    return (
      <>
        <Container fluid>
          <Tabs activeKey={this.state.activeTab} id="controlled-tab-example" onSelect={(k) => this.onTabClick(k)} transition={false}>
            <Tab eventKey="1" title="Citation Refund Transaction" className="m-0">
              <CitationTransaction />
            </Tab>
            <Tab eventKey="2" title="Permit Refund Transaction" className="m-0">
              <PermitTransaction data_show_type="Refunded" transaction_title="Refund" />
            </Tab>
          </Tabs>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails }) => {
  const { property_id: PropertyId, user_type, suite_id, timezone, property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;

  const { MainBackground: main_body } = backgroundColor;
  return {
    PropertyId,
    user_type,
    suite_id,
    timezone,
    property,
    main_body,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(TransactionReport);

import React from "react";
import { Card } from "react-bootstrap";
import { getTodayDate } from "../helper";
import Loader from "../Loader/Loader";
import useQuickPermit from "../../Hooks/QuickPermit/useQuickPermit";

const SelfRegisterHeader = () => {
  const { quickPermitData, loader } = useQuickPermit();

  return (
    <>
      {loader ? (
        <Loader center={true} />
      ) : (
        <Card.Header>
          <Card.Title>
            {quickPermitData?.quick_permit_form_title || "Permit Registration"}
          </Card.Title>
          <label>Lot - {quickPermitData?.lot_name || "Quick lot"}</label>
          <div>Daily Permit for {getTodayDate()}</div>
        </Card.Header>
      )}
    </>
  );
};

export default SelfRegisterHeader;

import React, { Component } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import warning from "../../../assets/img/svg/warning.svg";

class SmartGateConfirmModal extends Component {
  render() {
    const { showBrowserModal, closeModal, saveit, smartGateLoader, action, buttonDisable } = this.props;
    return (
      <Modal lg={"medium"} show={showBrowserModal} onHide={closeModal}>
        <Modal.Header className="no-border">
          <h4 className="m-0 font-weight-bold">
            <img className="ml-0 mr-1" src={warning} width={28} />
            Smart Gate Operation
          </h4>
          <Button title={"smartGate"} type="button" onClick={closeModal} className="close-btn" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="modal-data-body">
          {action === "OPEN" && <p className="theme-color">Do you want to continue with Open?</p>}
          {action === "CLOS" && <p className="theme-color">Do you want to continue with Close?</p>}
          {action === "LOPN" && (
            <p className="theme-color">
              This will open the keep and keep it open until you click “Regular operation”. <br />
              The gate will not go down until then Use “Open” to open it just once for a car. <br />
              <br />
              Do you want to continue with Lock Open?
            </p>
          )}
          {action === "LCLS" && (
            <p className="theme-color">
              This will close the keep and keep it closed until you click “Regular operation”. <br />
              The gate will not go up until then <br />
              Use “Close” to close it just once. <br />
              <br />
              Do you want to continue with Lock Close?
            </p>
          )}
          {action === "LPVN" && (
            <p className="theme-color">
              The will automatically open the gate each time a car is detected on the sensor. <br />
              No payment or other check will be done. The gate will open for all cars <br />
              It will stay in this mode until you click Regular Operations. <br />
              <br />
              Do you want to continue with Loop Vend?
            </p>
          )}
          {action === "RGLR" && (
            <p className="theme-color">
              If this gate was in lock open, lock close, or vend gate mode, it will go back to regular operations where it will open / close based on its normal
              rules. <br /> <br /> Do you want to continue with Regular Operations?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ minWidth: "93px" }} onClick={closeModal} type="button" className="btn btn-danger btn-cancel">
            No
          </Button>
          {!smartGateLoader ? (
            <Button onClick={saveit} type="button" className="btn btn-fill btn-info" disabled={buttonDisable}>
              Yes
            </Button>
          ) : (
            <Spinner animation="border" variant="info" />
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SmartGateConfirmModal;

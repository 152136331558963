import React, { Component } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { SuperAdminAction } from "../../Actions";
import CustomContentAlert, { isEmailValid, validations, regExForPhone, validation_message, email_validation_message } from "../helper";
import CKEditor from "react-ckeditor-component";
import CropImage from "./CropImage";
import defaultHeaderImage from "../../../assets/img/WorldSteamBox.svg";
import Config from "../../../Auth/config";
import ToggleButton from "react-toggle-button";
import { timeOut } from "../Common/SetTimeout";

const history = require("../../history").default;
class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_alert: false,
      show_error: false,
      api_success_message: "",
      api_error_message: "",
      properties: [],
      pages: [],
      selectedProperty: "",
      pageDropdownDisabled: true,
      all_properties: [],
      selectedPage: "",
      showPropertyModal: false,
      isModalOpen: false,
      loader: false,
      api_response: false,
      searchValue: "",
      propertiesAvailable: [],
      settings: {},
      site_id: null,
      day: null,
      dayErrorMessage: "",
      evening: null,
      eveningErrorMessage: "",
      night: null,
      nightErrorMessage: "",
      credits: null,
      credit_type: "single",
      available_spot: null,
      max_restriction_per_plate: null,
      support_visitor: null,
      login_header: "",
      login_body: "",
      support_email: "",
      support_contact: "",
      site_visibility: true,
      login_visibility: true,
      support_visibility: true,
      multiple_counter_visibility: true,
      single_counter_visibility: true,
      property_id: "",
      date_from: "",
      date_to: "",
      validDateFrom: true,
      validDateTo: true,
      isDateFormValid: false,
      isSiteFormValid: false,
      isLoginFormValid: false,
      isSupportFormValid: false,
      isFormValid: false,
      validDay: true,
      validEvening: true,
      validNight: true,
      validAvailableSpot: true,
      validMaxPerPlate: true,
      validSupportVisitor: true,
      date_range: 1,
      validDateRange: true,
      dateRangeErrorMessage: "",
      creditErrorMessage: "",
      dateRange_visibility: true,
      isDateRangeFormValid: false,
      validSupportEmail: true,
      date_range_id: "",
      siteSetting: {},
      multipleCreditGroup: {},
      template: "",
      templateList: [],
      tempraryTemplateList: [],
      validCredits: true,
      creditsErrorMessage: "",
      isSiteEditButtonDisabled: false,
      isDateRangeEditButtonDisabled: false,
      isLoginCapEditButtonDisabled: false,
      isSupportEditButtonDisabled: false,
      wrongSupportEmail: false,
      validEmail: true,
      validSupportContact: true,
      group: [],
      validation: {},
      footerText: "",
      menuHeader: "#d6d8e9",
      menuBackground: "#303b93",
      mainBodyBackground: "#efeff1",
      MainBackground: "#ffffff",
      phoneErrorMsg: "",
      isBrandingEditButtonDisabled: false,
      isBrandingFormValid: true,
      branding_visibility: true,
      isShowPreview: false,
      headerLogoApi: false,
      footerLogoApi: false,
      brandigChangeApi: false,
      show: false,
      headerCrop: false,
      footerCrop: false,
      headerLogo: "",
      footerLogo: "",
      is_reset: false,
      isProcessorButtonFormValid: false,
      isProcessorFormValid: true,
      processor_visibility: true,
      multiple_permits: false,
      currency: "CAD",
      qr_code_scanner: false,
      mobile_view_option: false,
      is_optional: false,
      call_when_here: false,
      is_extended_lpn_Info: false,
      edit_unit_username: false,
      edit_unit_edit_selection: false,
      edit_unit_email: false,
      isGoogleGlassValid: true,
      isSecurityEmailValid: true,
      date_diff: "",
      account_ref: "",
      monthly_ref: "",
      hourly_ref: "",
      harmonized_sales_tax: "",
      hst_percentage: "",
      date_diff_visibility: true,
      account_reference_visibility: true,
      monthly_reference_visibility: true,
      hourly_reference_visibility: true,
      harmonized_sales_tax_visibility: true,
      hst_percentage_visibility: true,
      isDateDiffValid: false,
      isAccountReferenceValid: false,
      isMonthlyReferenceValid: false,
      isHourlyReferenceValid: false,
      isHarmonizedSalesTaxValid: false,
      isHSTPercentageValid: false,

      isDateDiffFormValid: true,
      isAccountReferenceFormValid: true,
      isMonthlyReferenceFormValid: true,
      isHourlyReferenceFormValid: true,
      isHarmonizedSalesTaxFormValid: true,
      isHSTPercentageFormValid: true,

      session_time: "",
      session_visibility: true,
      isSessionEditValid: false,
      isSessionUpdateValid: true,
      invoice_enabled: false,
      invoice_enabled_Valid: true,
      isExtended_LPN_InfoValid: true,
      visitor_credit_payment: false,
      allow_citation_permits: false,
      visitor_credit_payment_Valid: true,
      allow_citation_permits_Valid: true,
    };
  }

  componentDidMount = async () => {
    const selectedProperty_id = this.props?.location?.search && this.props.location.search.split("?")[1] ? this.props.location.search.split("?")[1] : "";
    if (selectedProperty_id) {
      this.setState(
        {
          property_id: selectedProperty_id,
        },
        async () => {
          await this.props.getSettings(this.state.property_id);
          this.setState(
            {
              highsecurity: this.props?.settings?.domain?.high_security,
              highsecurity_email: this.props?.settings?.domain?.security_email,
              qr_code_scanner: this.props?.settings?.domain?.qr_code_scanner,
              mobile_view_option: this.props?.settings?.domain?.mobile_view_option,
              is_optional: this.props?.settings?.domain?.is_optional,
              call_when_here: this.props?.settings?.domain?.messaging_enabled,
              is_extended_lpn_Info: this.props?.settings?.login_caption?.is_extended_lpn_info,
              edit_unit_username: this.props?.settings?.domain?.edit_unit_username ? this.props?.settings?.domain?.edit_unit_username : false,
              edit_unit_email: this.props?.settings?.domain?.edit_unit_email ? this.props?.settings?.domain?.edit_unit_email : false,
              api_response: this.props?.api_response,
              all_properties: this.props?.getAllProperties,
              property_id: this.state.property_id,
              site_id: this.props?.settings?.site_setting?.id,
              day: this.props?.settings?.site_setting?.credit_type === "multiple" ? this.props?.settings?.site_setting?.day : "",
              evening: this.props?.settings?.site_setting?.credit_type === "multiple" ? this.props?.settings?.site_setting?.evening : "",
              night: this.props?.settings?.site_setting?.credit_type === "multiple" ? this.props?.settings?.site_setting?.night : "",
              max_restriction_per_plate: this.props?.settings?.site_setting?.max_restriction_per_plate,
              available_spot: this.props?.settings?.site_setting?.available_spot,
              multiple_permits: this.props?.settings && this.props.settings.login_caption ? this.props.settings.login_caption?.multiple_permits : false,
              credit_type: this.props?.settings?.site_setting?.credit_type,
              credits: this.props?.settings?.site_setting?.credit_type === "single" ? this.props?.settings?.site_setting?.credits : "",
              support_visitor: this.props?.settings?.site_setting?.support_visitor,
              login_header: this.props?.settings?.login_caption?.login_header,
              login_body: this.props?.settings?.login_caption?.login_body,
              support_email: this.props?.settings?.login_caption?.support_email ? this.props.settings.login_caption.support_email : "",
              support_contact: this.props?.settings?.login_caption?.support_contact ? this.props?.settings?.login_caption?.support_contact : "",
              dateSlots: this.props?.settings?.date_slots,
              date_range: this.props?.settings?.date_slots?.range,
              date_range_id: this.props?.settings?.date_slots?.id,
              templateList: this.props?.settings?.time_slot_templates,
              footerText: this.props?.settings?.login_caption?.footer_text,
              headerLogo: this.props?.settings?.login_caption?.header_logo ? this.props?.settings?.login_caption?.header_logo : defaultHeaderImage,
              footerLogo: this.props?.settings?.login_caption?.footer_logo,
              menuBackground: this.props?.settings?.login_caption?.menu_background
                ? this.props.settings.login_caption.menu_background
                : Config.defaultBrandingValues.menuBackground,
              mainBodyBackground: this.props?.settings?.login_caption?.main_body_background
                ? this.props?.settings?.login_caption?.main_body_background
                : Config?.defaultBrandingValues?.mainBodyBackground,
              menuHeader: this.props?.settings?.login_caption?.menu_header
                ? this.props?.settings?.login_caption?.menu_header
                : Config?.defaultBrandingValues?.menuHeader,
              MainBackground: this.props?.settings?.login_caption?.main_body
                ? this.props?.settings?.login_caption?.main_body
                : Config?.defaultBrandingValues?.MainBackground,
              template: this.props?.settings?.login_caption?.template_id,
              processor_id: this.props?.settings?.login_caption?.processor_id ? this.props?.settings?.login_caption?.processor_id : "",
              currency: this.props?.settings?.login_caption?.currency ? this.props?.settings?.login_caption?.currency : "",
              date_diff: this.props?.settings?.domain?.permit_date_difference ? this.props?.settings?.domain?.permit_date_difference : "",
              account_ref: this.props?.settings?.domain?.account_reference_code ? this.props?.settings?.domain?.account_reference_code : "",
              monthly_ref: this.props.settings.domain?.transaction_account_monthly ? this.props.settings.domain.transaction_account_monthly : "",

              hourly_ref: this.props?.settings?.domain?.transaction_account_hourly ? this.props?.settings?.domain?.transaction_account_hourly : "",
              harmonized_sales_tax: this.props?.settings?.domain?.harmonized_sales_tax ? this.props?.settings?.domain?.harmonized_sales_tax : "",
              hst_percentage: this.props?.settings?.domain?.hst_percentage ? this.props?.settings?.domain?.hst_percentage : "",
              session_time: this.props?.settings?.domain?.session_time ? this.props?.settings?.domain?.session_time : 0,
              invoice_enabled: this.props?.settings?.login_caption?.invoice_enabled ? this.props?.settings?.login_caption?.invoice_enabled : false,
              visitor_credit_payment: this.props?.settings?.login_caption?.visitor_credit_payment
                ? this.props?.settings?.login_caption?.visitor_credit_payment
                : false,
              allow_citation_permits: this.props?.settings?.login_caption?.allow_citation_permits
                ? this.props?.settings?.login_caption?.allow_citation_permits
                : false,
            },
            async () => {
              this.setState({
                tempraryTemplateList: this.state.templateList,
              });
              await this.props.getCreditTemplateGroups(this.state.template);
              this.setState(
                {
                  group: this.props.templateCreditGroup,
                },
                () => {
                  console.log("grp name", this.state.group);
                  if (this.state.group.length > 0) {
                    let obj = {};
                    this.state.group.forEach((grp) => {
                      obj = {
                        ...obj,
                        [grp]: this.state[grp],
                      };
                      console.log("grp val", grp);
                    });
                    this.setState({
                      multipleCreditGroup: obj,
                    });
                  }
                },
              );
            },
          );
          if (this.state.support_email && this.state.support_contact) {
            this.setState({
              isFormValid: true,
            });
          }
        },
      );
      await this.props.get_all_properties();
      this.setState({
        api_response: this.props?.api_response,
        all_properties: this.props?.getAllProperties,
        dateSlots: this.props?.dateSlots,
      });
      const selectedPropertyObj = this.state?.all_properties?.length ? this.state.all_properties.filter((item) => item.id === this.state.property_id) : "";
      const selectedPropertyName = selectedPropertyObj?.length && selectedPropertyObj && selectedPropertyObj[0].site_name;
      const selectedDomainName = selectedPropertyObj?.length && selectedPropertyObj && selectedPropertyObj[0].domain;
      this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    }
    const temp_name = [];
    this.state?.all_properties?.length &&
      this.state?.all_properties?.map((ele, i) => {
        temp_name.push({
          id: ele.id,
          name: ele.site_name,
          pages: ["Add Lot", "Add Lane", "Add Camera", "Add User"],
        });
        return { name: ele.site_name };
      });
    this.setState({ properties: temp_name });
  };

  componentDidUpdate(prevProps) {
    const { selectedProperty, selectedPage } = this.state;
    if (this.props.dateSlots !== prevProps.dateSlots) {
      this.setState({
        dateSlots: this.props.dateSlots,
        date_range: this.props.dateSlots?.range,
        date_range_id: this.props?.settings?.date_slots?.id,
      });
    }
    if (selectedProperty !== "") {
      if (selectedPage !== "") {
        this.props.selected_Property({ selectedProperty, selectedPage });
      }
    }
    if (prevProps.login_header !== this.props.login_header) {
      this.setState({
        login_header: this.props.login_header,
      });
    }
    if (prevProps.login_body !== this.props.login_body) {
      this.setState({
        login_body: this.props.login_body,
      });
    }
    if (prevProps.login_caption !== this.props.login_caption) {
      this.setState({
        login_caption: this.props.login_caption,
      });
    }

    if (prevProps.support_email !== this.props.support_email) {
      this.setState({
        support_email: this.props.support_email ? this.props.support_email : "",
      });
    }

    if (prevProps.siteSetting !== this.props.siteSetting) {
      this.setState({
        siteSetting: this.props.siteSetting,
        day: this.props.siteSetting?.credit_type === "multiple" ? this.props.siteSetting?.day : "",
        evening: this.props.siteSetting?.credit_type === "multiple" ? this.props.siteSetting?.evening : "",
        night: this.props.siteSetting?.credit_type === "multiple" ? this.props.siteSetting?.night : "",
        max_restriction_per_plate: this.props.siteSetting?.max_restriction_per_plate,
        credits: this.props.siteSetting?.credit_type === "single" ? this.props.siteSetting?.credits : "",
        available_spot: this.props.siteSetting?.available_spot,
        support_visitor: this.props.siteSetting?.support_visitor,
        multiple_permits: this.props.settings?.login_caption?.multiple_permits,
      });
    }
  }

  changeProperty = async (event) => {
    this.setState({ property_id: event.target.value });
    await this.props.getSettings(event.target.value);
    this.setState(
      {
        api_response: this.props?.api_response,
        all_properties: this.props?.getAllProperties,
        property_id: event.target.value,
        site_id: this.props?.settings?.site_setting?.id,
        day: this.props?.settings?.site_setting?.day,
        evening: this.props?.settings?.site_setting?.evening,
        night: this.props?.settings?.site_setting?.night,
        max_restriction_per_plate: this.props?.settings?.site_setting?.max_restriction_per_plate,
        available_spot: this.props?.settings?.site_setting?.available_spot,
        credit_type: this.props?.settings?.site_setting?.credit_type,
        credits: this.props?.settings?.site_setting?.credits,
        support_visitor: this.props?.settings?.site_setting?.support_visitor,
        login_header: this.props?.settings?.login_caption?.login_header,
        login_body: this.props?.settings?.login_caption?.login_body,
        support_email: this.props?.settings?.support_email ? this.props?.setting?.support_email : "",
        support_contact: this.props?.settings?.login_caption?.support_contact ? this.props?.settings?.login_caption?.support_contact : "",
        dateSlots: this.props?.settings?.date_slots,
        date_range: this.props?.settings?.date_slots?.range ? this.props?.settings?.date_slots?.range : 0,
        date_range_id: this.props?.settings?.date_slots?.id ? this.props?.settings?.date_slots?.id : "",
        templateList: this.props?.settings?.time_slot_templates,
        footerText: this.props.settings.login_caption ? this.props.settings.login_caption.footer_text : "",
        headerLogo: this.props?.settings?.header_logo ? this.props?.settings?.login_caption?.header_logo : defaultHeaderImage,
        footerLogo: this.props?.settings?.login_caption ? this.props?.settings?.login_caption?.footer_logo : "",
        menuBackground: this.props?.settings?.login_caption ? this.props?.settings?.login_caption?.menu_background : "#303b93",
        mainBodyBackground: this.props?.settings?.login_caption ? this.props?.settings?.login_caption?.main_body_background : "#efeff1",
        menuHeader: this.props?.settings?.login_caption ? this.props?.settings?.login_caption?.menu_header : "#d6d8e9",
        MainBackground: this.props?.settings?.login_caption ? this.props?.settings?.login_caption?.main_body : "#ffffff",
        template: this.props?.settings?.login_caption?.template_id,
        processor_id: this.props?.settings?.login_caption ? this.props?.settings?.login_caption?.processor_id : "",
        currency: this.props?.settings?.login_caption ? this.props?.settings?.login_caption?.currency : "",
        invoice_enabled: this.props?.settings?.login_caption ? this.props?.settings?.login_caption?.invoice_enabled : false,
        visitor_credit_payment: this.props?.settings?.login_caption ? this.props?.settings?.login_caption?.visitor_credit_payment : false,
        allow_citation_permits: this.props?.settings?.login_caption ? this.props?.settings?.login_caption?.allow_citation_permits : false,
        multiple_permits: this.props.settings.login_caption?.multiple_permits,
        highsecurity_email: this.props?.settings?.domain?.security_email,
        qr_code_scanner: this.props?.settings?.domain?.qr_code_scanner,
        mobile_view_option: this.props?.settings?.domain?.mobile_view_option,
        is_optional: this.props?.settings?.domain?.is_optional,
        call_when_here: this.props?.settings?.domain?.messaging_enabled,
        is_extended_lpn_Info: this.props?.settings?.login_caption?.is_extended_lpn_info ? this.props?.settings?.login_caption?.is_extended_lpn_info : false,
        edit_unit_username: this.props?.settings?.domain?.edit_unit_username ? this.props?.settings?.domain?.edit_unit_username : false,
        edit_unit_email: this.props?.settings?.domain?.edit_unit_email ? this.props?.settings?.domain?.edit_unit_email : false,
      },
      async () => {
        await this.props.getCreditTemplateGroups(this.state.template);
        this.setState(
          {
            group: this.props.templateCreditGroup,
          },
          () => {
            if (this.state.group.length > 0) {
              let obj = {};
              this.state.group.forEach((grp) => {
                obj = {
                  ...obj,
                  [grp]: this.state[grp],
                };
              });
              this.setState({
                multipleCreditGroup: obj,
              });
            }
          },
        );
      },
    );
    const selectedPropertyObj = this.state?.all_properties?.length && this.state?.all_properties?.filter((item) => item.id === this.state.property_id);
    const selectedPropertyName = selectedPropertyObj?.length && selectedPropertyObj[0]?.site_name;
    const selectedDomainName = selectedPropertyObj?.length && selectedPropertyObj[0]?.domain;
    this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
  };

  componentWillUnmount = () => {
    if (this.state.isShowPreview) {
      const menuHeader = Config.defaultBrandingValues.menuHeader;
      const menuBackground = Config.defaultBrandingValues.menuBackground;
      const mainBodyBackground = Config.defaultBrandingValues.mainBodyBackground;
      const MainBackground = Config.defaultBrandingValues.MainBackground;
      const headerLogo = "";
      const footerLogo = "";
      const footerText = "";
      this.props.setBackgroundColor(menuHeader, menuBackground, mainBodyBackground, MainBackground, headerLogo?.image, footerLogo?.image, footerText);
    }
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value.includes("") ? target.value.trim() : target.value,
    });

    if (target.name === "highsecurity_email") {
      console.log("highsecurity_email called");
      if (isEmailValid(target.value)) {
        console.log("If called isEmailValid");
        this.setState({
          highsecurity_email: target.value,
          isSecurityEmailValid: false,
        });
      } else {
        console.log("else called isEmailValid");

        this.setState({
          validSecurityEmail: false,
          securityEmailErrorMessage: "Please enter a valid email address.",
          isSecurityEmailValid: true,
        });
      }
    }
  };

  handleLoginChange = ({ target }) => {
    this.setState({ [target.name]: target.value }, () => {
      const { login_header, login_body } = this.state;
      switch (target.name) {
        case "login_header":
          if (login_header) {
            this.setState({
              login_header: target.value,
            });
          }
          break;
        case "login_body":
          if (login_body) {
            this.setState({
              login_body: target.value,
            });
          }
          break;
      }
    });
  };

  handleSupportChange = ({ target }) => {
    const prevstate = this.state.support_contact;
    this.setState({ [target.name]: target.value }, async () => {
      const { support_email, support_contact } = this.state;
      const isEmailValidate = isEmailValid(support_email);
      switch (target.name) {
        case "support_email":
          if (support_email) {
            const Email = validations(target.name, "email", this.state.support_email);
            this.setState({
              support_email: Email.value,
              validEmail: Email.validator,
            });
          }
          break;
        case "support_contact":
          if (support_contact.trim().length) {
            if (regExForPhone.test(target.value)) {
              this.setState({ support_contact: target.value });
            } else {
              this.setState({ support_contact: prevstate });
            }
          } else {
            const Phone = validations(target.name, "phone", this.state.support_contact);
            this.setState({
              support_contact: Phone.value,
              validPhone: Phone.validator,
            });
          }
          break;
      }

      this.setState({ wrongEmail: !isEmailValidate }, () => {
        let isValidPhone = true;
        if (support_contact && support_contact.length >= 11) {
          this.setState({
            phoneErrorMsg: "Please enter valid phone number",
          });
          isValidPhone = false;
        } else {
          this.setState({
            phoneErrorMsg: "",
          });
        }
        if (isEmailValidate && support_contact && this.state.support_contact.length && isValidPhone) {
          this.setState({ isSupportFormValid: true });
        } else {
          this.setState({ isFormValid: false, isSupportFormValid: false });
        }
      });
    });
  };

  hideAlert = () => {
    timeOut({
      fun: () => {
        this.setState({
          show_alert: false,
        });
      },
    });
  };

  handleLoginSubmit = ({ target }) => {
    this.setState({ isDateFormValid: false }, async () => {
      const { property_id, login_header, login_body } = this.state;
      const form_data = {
        id: property_id,
        login_header: login_header,
        login_body: login_body,
        update: "login_caption",
      };
      await this.props.updateLoginCaption(form_data);
      if (this.props.api_response) {
        this.loginVisibility();
        this.setState({
          isLoginCapEditButtonDisabled: false,
          show_alert: true,
          api_success_message: this.props.api_success_message,
        });
        this.hideAlert();
      } else {
        this.setState({
          show_error: true,
          api_error_message: this.props.api_error_message,
        });
        timeOut({
          fun: () => {
            this.setState({ show_error: false });
          },
        });
      }
      //  }
    });
  };

  handleSupportSubmit = ({ target }) => {
    this.setState({ isDateFormValid: false }, async () => {
      const { property_id, support_contact, support_email } = this.state;
      const isSiteFromValid = true;
      if (isSiteFromValid) {
        const form_data = {
          id: property_id,
          support_contact: support_contact,
          support_email: support_email,
          update: "support",
        };
        await this.props.updateSupport(form_data);
        if (this.props.api_response) {
          this.supportVisibility();
          this.setState({
            isSupportEditButtonDisabled: false,
            show_alert: true,
            api_success_message: this.props.api_success_message,
          });
          this.hideAlert();
        } else {
          this.setState({
            show_error: true,
            api_error_message: this.props.api_error_message,
          });
          timeOut({
            fun: () => {
              this.setState({ show_error: false });
            },
          });
        }
      }
    });
  };

  loginVisibility = () => {
    this.setState({
      isLoginEditButtonDisabled: true,
      isLoginCapEditButtonDisabled: true,
      login_visibility: !this.state.login_visibility,
      isLoginFormValid: !this.state.isLoginFormValid,
    });
  };

  supportVisibility = () => {
    if (this.state.support_email && this.state.support_contact) {
      this.setState({
        isSupportEditButtonDisabled: true,
        support_visibility: !this.state.support_visibility,
        isSupportFormValid: !this.state.isSupportFormValid,
        isFormValid: !this.state.isFormValid,
      });
    } else {
      this.setState({
        isSupportEditButtonDisabled: true,
        support_visibility: !this.state.support_visibility,
        isSupportFormValid: false,
        //  isFormValid:!this.state.isFormValid,
      });
    }
  };

  dateRangeVisibility = () => {
    this.validateCredit();
    this.setState({
      isDateRangeEditButtonDisabled: true,
      dateRange_visibility: !this.state.dateRange_visibility,
    });
  };

  handleDateRangeChange = ({ target }) => {
    this.setState(
      {
        [target.name]: Math.floor(target.value),
      },
      () => {
        const date_range = parseInt(this.state.date_range, 10);
        if (isNaN(date_range)) {
          this.setState({
            validDateRange: false,
            dateRangeErrorMessage: "Date range can't be empty",
            isDateRangeFormValid: false,
          });
        }
        if (!isNaN(date_range) && this.state.date_range <= 0) {
          this.setState({
            validDateRange: false,
            dateRangeErrorMessage: "Date range can't be less then 1",
            isDateRangeFormValid: false,
          });
        }
        if (this.state.date_range > 31) {
          this.setState({
            validDateRange: false,
            dateRangeErrorMessage: "Date range can't be greater then 31",
            isDateRangeFormValid: false,
          });
        }
        const _ = require("lodash");
        if (!isNaN(date_range) && _.inRange(this.state.date_range, 1, 31)) {
          this.setState(
            {
              validDateRange: true,
              dateRangeErrorMessage: "",
              isDateRangeFormValid: true,
            },
            () => this.validateCredit(),
          );
        }
      },
    );
  };
  /* To save slot data */

  addDateRange = async () => {
    const {
      property_id,
      date_range: range,
      date_range_id,
      template,
      group,
      available_spot,
      day,
      evening,
      night,
      max_restriction_per_plate,
      credits,
      isDateRangeFormValid,
      multiple_permits,
      multipleCreditGroup,
    } = this.state;
    const credit_type = !group.length ? "single" : "multiple";
    const form_data = {
      property_id,
      range,
      date_range_id,
      template,
      credit_type,
      available_spot,
      day,
      evening,
      night,
      max_restriction_per_plate,
      credits,
      group,
      multiple_permits,
      multipleCreditGroup,
    };
    console.log("batao credit", form_data);
    await this.props.createDateSlot(form_data);

    if (this.props.api_response) {
      this.dateRangeVisibility();
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      timeOut({
        fun: () => {
          this.setState({
            show_alert: false,
            isDateRangeEditButtonDisabled: false,
            isDateRangeFormValid: !isDateRangeFormValid,
          });
        },
      });
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  handleTemplateChange = (e) => {
    const target = e.target;
    const prevSelectedValue = this.state.template;

    console.log("prevSelectedValue=", prevSelectedValue, "===", target.value);
    this.setState({ template: target.value }, async () => {
      await this.props.getCreditTemplateGroups(target.value);
      this.setState(
        {
          group: this.props.templateCreditGroup,
        },
        () => {
          if (this.state.group.length > 0) {
            let obj = {};
            this.state.group.forEach((grp) => {
              obj = {
                ...obj,
                [grp]: this.state[grp],
              };
            });
            this.setState({
              multipleCreditGroup: obj,
            });
          }
          this.validateCredit();
        },
      );
    });
  };

  handleSlotChange = ({ target }) => {
    if (target.name === "credits" || target.name === "available_spot" || target.name === "support_visitor" || target.name === "max_restriction_per_plate") {
      this.setState({ [target.name]: Math.floor(target.value) }, () => {
        const {
          // day,
          // evening,
          // night,
          available_spot,
          max_restriction_per_plate,
          support_visitor,
          credits,
          // group,
        } = this.state;
        console.log("day", target.name);
        switch (target.name) {
          case "credits":
            {
              const checkCredits = parseInt(this.state.credits, 10);
              if (isNaN(checkCredits)) {
                this.setState({
                  validCredits: false,
                  creditsErrorMessage: "Credits can't be empty",
                  isDateRangeFormValid: false,
                });
              }
              if (!isNaN(checkCredits) && credits <= 0) {
                this.setState({
                  validCredits: false,
                  creditsErrorMessage: "Credits can't be less then 1",
                  isDateRangeFormValid: false,
                });
              }
              if (!isNaN(checkCredits) && credits > 0) {
                this.setState(
                  {
                    validCredits: true,
                    creditsErrorMessage: "",
                    isDateRangeFormValid: true,
                  },
                  () => {
                    this.validateCredit();
                  },
                );
              }
            }
            break;
          case "available_spot":
            {
              const checkAvailableSpot = parseInt(this.state.available_spot, 10);
              if (isNaN(checkAvailableSpot)) {
                this.setState({
                  validAvailableSpot: false,
                  availableSpotErrorMessage: "Field can't be empty",
                  isDateRangeFormValid: false,
                });
              }
              if (!isNaN(checkAvailableSpot) && available_spot <= 0) {
                this.setState({
                  validAvailableSpot: false,
                  availableSpotErrorMessage: "Field can't be less then 1",
                  isDateRangeFormValid: false,
                });
              }
              if (!isNaN(checkAvailableSpot) && available_spot > 0) {
                this.setState(
                  {
                    validAvailableSpot: true,
                    availableSpotErrorMessage: "",
                    isDateRangeFormValid: true,
                  },
                  () => this.validateCredit(),
                );
              }
            }
            break;
          case "max_restriction_per_plate":
            {
              const checkMaxPerPlate = parseInt(this.state.max_restriction_per_plate, 10);
              if (isNaN(checkMaxPerPlate)) {
                this.setState({
                  validMaxPerPlate: false,
                  maxPerPlateErrorMessage: "Field can't be empty",
                  isDateRangeFormValid: false,
                });
              }
              if (!isNaN(checkMaxPerPlate) && max_restriction_per_plate < 0) {
                this.setState({
                  validMaxPerPlate: false,
                  maxPerPlateErrorMessage: "Field can't be less then 0",
                  isDateRangeFormValid: false,
                });
              }
              if (!isNaN(checkMaxPerPlate) && max_restriction_per_plate >= 0) {
                this.setState(
                  {
                    validMaxPerPlate: true,
                    maxPerPlateErrorMessage: "",
                    isDateRangeFormValid: true,
                  },
                  () => this.validateCredit(),
                );
              }
            }
            break;
          case "support_visitor":
            if (support_visitor) {
              this.setState({
                support_visitor: target.value,
                validSupportVisitor: true,
                isDateRangeFormValid: true,
              });
            } else {
              this.setState({
                validSupportVisitor: false,
                isDateRangeFormValid: false,
              });
            }
            break;
          default:
        }
      });
    } else if (target.name !== "available_spot" && target.name !== "support_visitor") {
      const prevVal = JSON.parse(JSON.stringify(this.state.multipleCreditGroup));
      prevVal[target.name] = Math.floor(target.value);
      console.log("prevvvvv val", prevVal);
      this.setState(
        {
          multipleCreditGroup: prevVal,
        },
        () => {
          if (+target.value <= 0) {
            this.setState({
              validation: {
                ...this.state.validation,
                [target.name]: "Field can't be less then 1",
              },
              creditErrorMessage: "Field can't be less then 1",
              isDateRangeFormValid: true,
            });
          }
          if (+target.value > 0) {
            this.setState({
              validation: {
                ...this.state.validation,
                [target.name]: "",
              },
              creditErrorMessage: "",
              isDateRangeFormValid: true,
            });
          }
          this.validateCredit();
        },
      );
    }
  };
  /* to handle multiple permits checkobx */

  handleCheckboxChange = (e) => {
    this.setState(
      {
        multiple_permits: e.target.checked,
      },
      () => {
        console.log("after setting in update", this.state.multiple_permits);
      },
    );
  };

  validateCredit = () => {
    const { available_spot, date_range, credits } = this.state;
    if (!this.state.group.length) {
      if (this.state?.template?.length && available_spot && date_range && credits) {
        this.setState({
          isDateFormValid: true,
          isDateRangeFormValid: true,
        });
      } else {
        this.setState({
          isDateFormValid: false,
          isDateRangeFormValid: false,
        });
      }
    } else {
      if (this.state?.template?.length && available_spot && date_range) {
        const multipleCreditGroupValues = Object.values(this.state.multipleCreditGroup);
        const foundValue = multipleCreditGroupValues.find((ele) => ele === 0);

        const isNotEmpty = foundValue === undefined;
        if (isNotEmpty) {
          this.setState({
            isDateFormValid: true,
            isDateRangeFormValid: true,
          });
        } else {
          this.setState({
            isDateFormValid: false,
            isDateRangeFormValid: false,
          });
        }
      }
    }
  };

  handleBrandingChange = (event) => {
    console.log(event.target.value);

    this.setState(
      {
        [event.target.name]: event.target.value,
        brandigChangeApi: true,
      },
      () => {
        if (this.state.isShowPreview) {
          const { menuHeader, menuBackground, mainBodyBackground, MainBackground, headerLogo, footerLogo, footerText } = this.state;
          this.props.setBackgroundColor(
            menuHeader,
            menuBackground,
            mainBodyBackground,
            MainBackground,
            headerLogo?.image ? headerLogo?.image : headerLogo,
            footerLogo?.image ? footerLogo?.image : footerLogo,
            footerText,
          );
        }
      },
    );
  };

  /* To preview branding data accordingly */

  setBrandingData = () => {
    this.setState(
      {
        isShowPreview: !this.state.isShowPreview,
      },
      () => {
        if (this.state.isShowPreview) {
          const { menuHeader, menuBackground, mainBodyBackground, MainBackground, headerLogo, footerLogo, footerText } = this.state;
          console.log("headerLogoheaderLogoheaderLogo", headerLogo);
          this.props.setBackgroundColor(
            menuHeader,
            menuBackground,
            mainBodyBackground,
            MainBackground,
            headerLogo?.image ? headerLogo?.image : headerLogo,
            footerLogo?.image ? footerLogo?.image : footerLogo,
            footerText,
          );
        } else {
          const menuHeader = "";
          const menuBackground = "";
          const mainBodyBackground = "";
          const MainBackground = "";
          const headerLogo = "";
          const footerLogo = "";
          const footerText = "";
          this.props.setBackgroundColor(menuHeader, menuBackground, mainBodyBackground, MainBackground, headerLogo?.image, footerLogo?.image, footerText);
        }
      },
    );
  };

  handleAddPhoto = (selectorFile, imageName, name) => {
    if (!selectorFile || !imageName) {
      this.setState({
        headerLogoApi: true,
        show: false,
      });
      return;
    }
    if (name === "header") {
      this.setState(
        {
          headerLogo: {
            image: selectorFile,
            filename: imageName,
          },
          headerLogoApi: true,
          show: false,
        },
        () => {
          this.setAllBrandingChanges();
        },
      );
    } else if (name === "footer") {
      this.setState(
        {
          footerLogo: {
            image: selectorFile,
            filename: imageName,
          },
          footerLogoApi: true,
          show: false,
        },
        () => {
          this.setAllBrandingChanges();
        },
      );
    }
  };

  brandingVisibility = () => {
    this.setState({
      isBrandingEditButtonDisabled: true,
      isBrandingFormValid: false,
      branding_visibility: false,
    });
  };

  urltoFile = async (url, filename) => {
    const mimeType = (url.match(/^data:([^;]+);/) || "")[1];
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  handleBrandingSubmit = (reset) => {
    this.setState({ isBrandingFormValid: false }, async () => {
      const {
        footerText,
        mainBodyBackground,
        MainBackground,
        menuBackground,
        menuHeader,
        property_id,
        headerLogo,
        footerLogo,
        headerLogoApi,
        footerLogoApi,
        brandigChangeApi,
        is_reset,
      } = this.state;

      const isSiteFromValid = true;
      if (isSiteFromValid) {
        const formData = {
          id: property_id,
          footer_text: footerText,
          menu_header: menuHeader,
          menu_background: menuBackground,
          main_body_background: mainBodyBackground,
          main_body: MainBackground,
          is_reset: is_reset,
        };

        const form_data = new FormData();
        const form_dataFooter = new FormData();
        if (headerLogoApi) {
          if (headerLogo?.image) {
            const file = await this.urltoFile(headerLogo.image, headerLogo.filename);
            form_data.append("files", file);
            console.log("header logo", file);
            await this.props.uploadHeaderLogo(form_data, property_id);
          }
        }

        if (footerLogoApi) {
          if (footerLogo?.image) {
            const footerData = await this.urltoFile(footerLogo.image, footerLogo.filename);

            form_dataFooter.append("files", footerData);
            await this.props.uploadFooterLogo(form_dataFooter, property_id);
          }
        }
        if (this.props.api_response) {
          this.brandingVisibility();
          this.setState({
            isBrandingEditButtonDisabled: false,
            api_success_message: this.props.api_success_message,
            headerLogoApi: false,
            footerLogoApi: false,
            is_reset: false,
          });
          if (reset === "reset") {
            this.setState({
              headerLogo: defaultHeaderImage,
            });
          }
        } else {
          this.setState({
            show_error: true,
            api_error_message: this.props.api_error_message,
            headerLogoApi: false,
            footerLogoApi: false,
          });
          timeOut({
            fun: () => {
              this.setState({ show_error: false });
            },
          });
        }

        if (brandigChangeApi) {
          if (footerText || mainBodyBackground || MainBackground || menuBackground || menuHeader) {
            await this.props.updateLoginCaption(formData);
            if (this.props.api_response) {
              this.brandingVisibility();
              this.setState({
                isBrandingEditButtonDisabled: false,
                show_alert: true,
                api_success_message: this.props.api_success_message,
                brandigChangeApi: false,
                isBrandingFormValid: true,
              });
              this.hideAlert();
            } else {
              this.setState({
                show_error: true,
                api_error_message: this.props.api_error_message,
                brandigChangeApi: false,
              });
              timeOut({
                fun: () => {
                  this.setState({ show_error: false });
                },
              });
            }
          }
        }
      }
    });
  };

  resetBranding = () => {
    const text = "Do you want to reset?";

    if (confirm(text) === true) {
      this.setState(
        {
          menuHeader: Config.defaultBrandingValues.menuHeader,
          menuBackground: Config.defaultBrandingValues.menuBackground,
          mainBodyBackground: Config.defaultBrandingValues.mainBodyBackground,
          MainBackground: Config.defaultBrandingValues.MainBackground,
          headerLogo: "",
          footerLogo: "",
          footerText: "",
          headerLogoApi: true,
          footerLogoApi: true,
          brandigChangeApi: true,
          is_reset: true,
        },
        () => {
          this.handleBrandingSubmit("reset");
        },
      );
    }
  };

  footerTextonChange = (evt) => {
    const text = evt.editor.getData();
    this.setState(
      {
        footerText: text,
        brandigChangeApi: true,
      },
      () => {
        this.setAllBrandingChanges();
      },
    );
  };

  handleModelClose = () => {
    this.setState({ show: false });
  };

  showModel = (type) => {
    this.setState({
      show: true,
      selectLogoType: type,
    });
  };

  setAllBrandingChanges = () => {
    if (this.state.isShowPreview) {
      const { menuHeader, menuBackground, mainBodyBackground, MainBackground, headerLogo, footerLogo, footerText } = this.state;
      this.props.setBackgroundColor(
        menuHeader,
        menuBackground,
        mainBodyBackground,
        MainBackground,
        headerLogo?.image ? headerLogo?.image : headerLogo,
        footerLogo?.image ? footerLogo?.image : footerLogo,
        footerText,
      );
    }
  };

  processorVisibility = () => {
    this.setState({
      isProcessorButtonFormValid: true,
      processor_visibility: false,
    });
    if (this.state.processor_id) {
      this.setState({
        isProcessorFormValid: false,
      });
    }
  };

  handleProcessor = (e) => {
    this.setState(
      {
        processor_id: e.target.value,
      },
      () => {
        if (this.state.processor_id) {
          this.setState({
            isProcessorButtonFormValid: true,
            isProcessorFormValid: false,
            processor_visibility: false,
          });
        }
      },
    );
  };

  handleProcessorSubmit = async () => {
    const data = {
      property_id: this.state.property_id,
      processor_id: this.state.processor_id,
      currency: this.state.currency,
    };
    await this.props.addProcessorId(data);
    if (this.props.api_response) {
      this.setState({
        isProcessorButtonFormValid: false,
        isProcessorFormValid: true,
        show_alert: true,
        api_success_message: this.props.api_success_message,
        processor_id: this.props.propertyId,
        processor_visibility: true,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  /** WORLD-713 */
  handleGoogleGlassSubmit = async () => {
    const data = {
      property_id: this.state.property_id,
      qr_code_scanner: this.state.qr_code_scanner,
    };
    await this.props.googleGlass(data);
    if (this.props.api_response) {
      this.setState({
        isGoogleGlassValid: true,
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  handleCallWhenHereSubmit = async () => {
    const data = {
      property_id: this.state?.property_id,
      call_when_here: this.state?.call_when_here,
    };

    await this.props.callWhenHere(data);
    if (this.props.api_response) {
      this.setState({
        iscallWhenHereValid: true,
        show_alert: true,
        api_success_message: this.props?.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props?.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  editIsOptionalSetting = async () => {
    const data = {
      property_id: this.state.property_id,
      is_optional: this.state.is_optional,
    };
    await this.props.extended_LPN_Info(data);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        is_optional_selection: false,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  handleExtended_LPN_InfoSubmit = async () => {
    const data = {
      property_id: this.state.property_id,
      is_extended_lpn_Info: this.state.is_extended_lpn_Info,
    };

    await this.props.extended_LPN_Info(data);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        isExtended_LPN_InfoValid: true,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  handle_invoice_Submit = async () => {
    const data = {
      property_id: this.state.property_id,
      invoice_enabled: this.state.invoice_enabled,
    };

    await this.props.invoice_cron_enabled(data);
    if (this.props.api_response) {
      this.setState({
        invoice_enabled_Valid: true,
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  handle_visitor_credit_paymemnt_Submit = async () => {
    const data = {
      property_id: this.state.property_id,
      visitor_credit_payment: this.state.visitor_credit_payment,
    };

    await this.props.visitor_payment_credit_enabled(data);
    if (this.props.api_response) {
      this.setState({
        visitor_credit_payment_Valid: true,
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  handleAllowPermitsWithCitationSubmit = async () => {
    const data = {
      property_id: this.state.property_id,
      allow_citation_permits: this.state.allow_citation_permits,
    };

    await this.props.allow_permits_with_citation(data);
    if (this.props.api_response) {
      this.setState({
        allow_citation_permits_Valid: true,
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  handleSecurityEmailSubmit = async () => {
    const data = {
      property_id: this.state.property_id,
      security_email: this.state.highsecurity_email,
    };
    await this.props.highSecurityEmail(data);
    if (this.props.api_response) {
      this.setState({
        isSecurityEmailValid: true,
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState(
        {
          show_error: true,
          api_error_message: this.props.api_error_message,
          isSecurityEmailValid: true,
        },
        () => {
          timeOut({
            fun: () => {
              this.setState({ show_error: false });
            },
          });
        },
      );
    }
  };

  handleSelect = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  /** WORLD-713 */
  onToggleChange(value) {
    this.setState({
      qr_code_scanner: !value,
      isGoogleGlassValid: false,
    });
  }

  onToggleChangeCallWhenHere = (value) => {
    this.setState({
      call_when_here: !value,
      iscallWhenHereValid: false,
    });
  };

  onToggleChangeExtended_LPN_Info = (value) => {
    this.setState({
      is_extended_lpn_Info: !value,
      isExtended_LPN_InfoValid: false,
    });
  };

  onToggleChangeInvoice = (value) => {
    this.setState({
      invoice_enabled: !value,
      invoice_enabled_Valid: false,
    });
  };

  onToggleChangeVisitorCreditPay = (value) => {
    this.setState({
      visitor_credit_payment: !value,
      visitor_credit_payment_Valid: false,
    });
  };

  onToggleChangeAllowPermitWithCitation = (value) => {
    this.setState({
      allow_citation_permits: !value,
      allow_citation_permits_Valid: false,
    });
  };

  onToggleChangeEditUsername = (value) => {
    this.setState({
      edit_unit_username: !value,
      edit_unit_edit_selection: true,
    });
  };
  onToggleChangeIsOptional = (value) => {
    this.setState({
      is_optional: !value,
      is_optional_selection: true,
    });
  };

  handleDateDifference = ({ target }) => {
    const prevstate = this.state.date_diff;
    if (regExForPhone.test(target.value) || target.value === "") {
      this.setState({ date_diff: target.value });
    } else {
      this.setState({ date_diff: prevstate });
    }
  };

  handleAccountingReference = ({ target }) => {
    const account_reference = target.value.replace(/[^a-z0-9]/gi, "");
    this.setState({
      account_ref: account_reference,
    });
  };

  handleMonthlyReference = ({ target }) => {
    const monthly_reference = target.value.replace(/[^a-z0-9]/gi, "");
    this.setState({
      monthly_ref: monthly_reference,
    });
  };

  handleHourlyReference = ({ target }) => {
    const hourly_reference = target.value.replace(/[^a-z0-9]/gi, "");
    this.setState({
      hourly_ref: hourly_reference,
    });
  };

  handleHarmonizedSalesTax = ({ target }) => {
    const harmonized_sales_tax = target.value.replace(/\D/, "");
    this.setState({
      harmonized_sales_tax: harmonized_sales_tax,
    });
  };

  handleHSTPercentage = ({ target }) => {
    const hst_percentage = target.value.replace(/\D/, "");
    this.setState({
      hst_percentage: hst_percentage,
    });
  };

  DateDiffVisibility = () => {
    this.setState({
      date_diff_visibility: false,
      isDateDiffValid: true,
      isDateDiffFormValid: false,
    });
  };

  AccountingReferenceVisibility = () => {
    this.setState({
      account_reference_visibility: false,
      isAccountReferenceValid: true,
      isAccountReferenceFormValid: false,
    });
  };

  MonthlyReferenceVisibility = () => {
    this.setState({
      monthly_reference_visibility: false,
      isMonthlyReferenceValid: true,
      isMonthlyReferenceFormValid: false,
    });
  };

  HourlyReferenceVisibility = () => {
    this.setState({
      hourly_reference_visibility: false,
      isHourlyReferenceValid: true,
      isHourlyReferenceFormValid: false,
    });
  };

  HarmonizedSalesTaxVisibility = () => {
    this.setState({
      harmonized_sales_tax_visibility: false,
      isHarmonizedSalesTaxValid: true,
      isHarmonizedSalesTaxFormValid: false,
    });
  };

  HSTPercentageVisibility = () => {
    this.setState({
      hst_percentage_visibility: false,
      isHSTPercentageValid: true,
      isHSTPercentageFormValid: false,
    });
  };

  handleDateDiffSubmit = async () => {
    const data = {
      property_id: this.state.property_id,
      date_diff: this.state.date_diff,
    };
    await this.props.addDateDifference(data);
    if (this.props.api_response) {
      this.setState({
        date_diff_visibility: true,
        isDateDiffValid: false,
        isDateDiffFormValid: true,
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  handleAccountingReferenceSubmit = async () => {
    const data = {
      property_id: this.state.property_id,
      account_ref: this.state.account_ref,
    };
    await this.props.addAccountReference(data);
    if (this.props.api_response) {
      this.setState({
        account_reference_visibility: true,
        isAccountReferenceValid: false,
        isAccountReferenceFormValid: true,
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  handleMonthlyReferenceSubmit = async () => {
    const data = {
      property_id: this.state.property_id,
      monthly_ref: this.state.monthly_ref,
    };
    await this.props.addMonthlyReference(data);
    if (this.props.api_response) {
      this.setState({
        monthly_reference_visibility: true,
        isMonthlyReferenceValid: false,
        isMonthlyReferenceFormValid: true,
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  handleHourlyReferenceSubmit = async () => {
    const data = {
      property_id: this.state.property_id,
      hourly_ref: this.state.hourly_ref,
    };
    await this.props.addHourlyReference(data);
    if (this.props.api_response) {
      this.setState({
        hourly_reference_visibility: true,
        isHourlyReferenceValid: false,
        isHourlyReferenceFormValid: true,
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  handleHarmonizedSalesTaxSubmit = async () => {
    const data = {
      property_id: this.state.property_id,
      harmonized_sales_tax: this.state.harmonized_sales_tax,
    };
    await this.props.addHarmonizedSalesTax(data);
    if (this.props.api_response) {
      this.setState({
        harmonized_sales_tax_visibility: true,
        isHarmonizedSalesTaxValid: false,
        isHarmonizedSalesTaxFormValid: true,
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  handleHSTPercentageSubmit = async () => {
    const data = {
      property_id: this.state.property_id,
      hst_percentage: this.state.hst_percentage,
    };
    await this.props.addHSTPercentage(data);
    if (this.props.api_response) {
      this.setState({
        hst_percentage_visibility: true,
        isHSTPercentageValid: false,
        isHSTPercentageFormValid: true,
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  handleSessionChange = ({ target }) => {
    const prevstate = this.state.session_time;
    if (regExForPhone.test(target.value) || target.value === "") {
      this.setState({ session_time: target.value });
    } else {
      this.setState({ session_time: prevstate });
    }
  };

  sessionVisibility = () => {
    this.setState({
      session_visibility: false,
      isSessionUpdateValid: false,
      isSessionEditValid: true,
    });
  };

  sessionSubmit = async () => {
    const data = {
      property_id: this.state.property_id,
      session_time: this.state.session_time,
    };
    await this.props.addSessionTime(data);
    if (this.props.api_response) {
      this.setState({
        session_visibility: true,
        isSessionUpdateValid: true,
        isSessionEditValid: false,
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  editUnitSetting = async () => {
    const data = {
      property_id: this.state.property_id,
      edit_unit_username: this.state.edit_unit_username,
      edit_unit_email: this.state.edit_unit_email,
    };
    await this.props.addDateDifference(data);
    if (this.props.api_response) {
      this.setState({
        date_diff_visibility: true,
        isDateDiffValid: false,
        isDateDiffFormValid: true,
        show_alert: true,
        api_success_message: this.props.api_success_message,
        edit_unit_edit_selection: false,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({ show_error: false });
        },
      });
    }
  };

  render() {
    const {
      processor_id,
      processor_visibility,
      property_id,
      validDateRange,
      dateRangeErrorMessage,
      isDateRangeEditButtonDisabled,
      isLoginCapEditButtonDisabled,
      isSupportEditButtonDisabled,
      available_spot,
      credits,
      isDateRangeFormValid,
      login_header,
      login_body,
      support_email,
      support_contact,
      login_visibility,
      dateRange_visibility,
      isLoginFormValid,
      isSupportFormValid,
      isFormValid,
      phoneErrorMsg,
      support_visibility,
      show_alert,
      show_error,
      date_range,
      creditsErrorMessage,
      validCredits,
      validAvailableSpot,
      validMaxPerPlate,
      availableSpotErrorMessage,
      maxPerPlateErrorMessage,
      validEmail,
      emailErrorMessage,
      validSupportContact,
      group,
      footerText,
      menuHeader,
      menuBackground,
      mainBodyBackground,
      MainBackground,
      headerLogo,
      footerLogo,
      branding_visibility,
      show,
      wrongEmail,
      isProcessorButtonFormValid,
      isProcessorFormValid,
      multiple_permits,
      edit_unit_username,
      max_restriction_per_plate,
      is_optional,
      is_optional_selection,
      edit_unit_edit_selection,
      currency,
      qr_code_scanner,
      call_when_here,
      is_extended_lpn_Info,
      isExtended_LPN_InfoValid,
      isGoogleGlassValid,
      iscallWhenHereValid,
      highsecurity_email,
      highsecurity,
      isSecurityEmailValid,
      date_diff,
      account_ref,
      monthly_ref,
      hourly_ref,
      harmonized_sales_tax,
      hst_percentage,
      date_diff_visibility,
      account_reference_visibility,
      monthly_reference_visibility,
      hourly_reference_visibility,
      harmonized_sales_tax_visibility,
      hst_percentage_visibility,
      isDateDiffValid,
      isAccountReferenceValid,
      isMonthlyReferenceValid,
      isHourlyReferenceValid,
      isHarmonizedSalesTaxValid,
      isHSTPercentageValid,
      isDateDiffFormValid,
      isAccountReferenceFormValid,
      isMonthlyReferenceFormValid,
      isHourlyReferenceFormValid,
      isHarmonizedSalesTaxFormValid,
      isHSTPercentageFormValid,
      session_time,
      session_visibility,
      isSessionEditValid,
      isSessionUpdateValid,
      invoice_enabled_Valid,
      invoice_enabled,
      visitor_credit_payment,
      allow_citation_permits,
      visitor_credit_payment_Valid,
      allow_citation_permits_Valid,
    } = this.state;

    return (
      <div>
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <Card.Body style={{ backgroundColor: this.props.main_body }}>
                  <Row>
                    <Col md="6">
                      <label>
                        Select Property
                        <span className="asterisk">*</span>
                      </label>
                      <Form.Control as="select" name="selectedProperty" placeholder="properties" value={this.state.property_id} onChange={this.changeProperty}>
                        <option>--Choose Property--</option>
                        {this.state?.properties?.length > 0 &&
                          this.state.properties.map((e, key) => {
                            return (
                              <option key={key} value={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="setting-screen">
            <Col sm="12" md="12" lg="12" className="mb-4">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Slots</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide-setting">
                  <Row>
                    <Col lg="6">
                      <Form.Group>
                        <label>
                          Template
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          as="select"
                          name="selectedTemplate"
                          placeholder="Select Template"
                          value={this.state.template}
                          disabled={dateRange_visibility}
                          onChange={(e) => this.handleTemplateChange(e)}
                        >
                          <option value={""}>--Choose Template--</option>
                          {this.state?.tempraryTemplateList?.length > 0 &&
                            this.state.tempraryTemplateList.map((e, key) => {
                              return (
                                <option key={key} value={e.id}>
                                  {e.template_name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    {!group.length && (
                      <Col lg="6">
                        <Form.Group>
                          <label>
                            Single
                            <span className="asterisk">*</span>
                          </label>
                          <Form.Control
                            placeholder="eg: 10"
                            type="number"
                            min={0}
                            name="credits"
                            value={credits}
                            onChange={this.handleSlotChange}
                            autoComplete="off"
                            disabled={dateRange_visibility}
                            required
                          />
                          {!validCredits && <div className="help-block">{creditsErrorMessage}</div>}
                        </Form.Group>
                      </Col>
                    )}
                    {group &&
                      group.length > 0 &&
                      group.map((grp, Idx) => {
                        return (
                          <Col key={Idx} lg="2">
                            <Form.Group>
                              <label>
                                {grp.charAt(0).toUpperCase() + grp.slice(1)}
                                <span className="asterisk">*</span>
                              </label>
                              <Form.Control
                                placeholder="eg: 10"
                                type="number"
                                name={grp}
                                min={0}
                                value={this.state.multipleCreditGroup[grp]}
                                onChange={this.handleSlotChange}
                                autoComplete="off"
                                disabled={dateRange_visibility}
                                required
                              />
                              {this.state.validation[grp] && <div className="help-block">{this.state.validation[grp]}</div>}
                            </Form.Group>
                          </Col>
                        );
                      })}
                  </Row>
                  <Row>
                    <Col lg="3">
                      <Form.Group>
                        <label>
                          Date Range
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="Add the range from 1-31"
                          type="number"
                          name="date_range"
                          min={0}
                          value={date_range}
                          onChange={this.handleDateRangeChange}
                          disabled={dateRange_visibility}
                          autoComplete="off"
                          required
                        />
                        {!validDateRange && <div className="help-block">{dateRangeErrorMessage}</div>}
                      </Form.Group>
                    </Col>
                    <Col lg="3">
                      <Form.Group>
                        <label>
                          Available spot
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="eg: 1"
                          type="number"
                          name="available_spot"
                          value={available_spot}
                          min={0}
                          onChange={this.handleSlotChange}
                          autoComplete="off"
                          disabled={dateRange_visibility}
                          required
                        />
                        {!validAvailableSpot && <div className="help-block">{availableSpotErrorMessage}</div>}
                      </Form.Group>
                    </Col>

                    <Col lg="3">
                      <Form.Group>
                        <label>
                          Maximum consecutive allowed LPN count
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="Eg: 3"
                          type="number"
                          name="max_restriction_per_plate"
                          min={0}
                          value={max_restriction_per_plate}
                          onChange={this.handleSlotChange}
                          disabled={dateRange_visibility}
                          autoComplete="off"
                          required
                        />
                        {!validMaxPerPlate && <div className="help-block">{maxPerPlateErrorMessage}</div>}
                      </Form.Group>
                    </Col>

                    <Col lg="3" className="d-flex">
                      <Form.Group>
                        <label className="fancy-checkbox mt-2 mr-3">Multiple permits</label>
                        <div>
                          <input
                            type="checkbox"
                            name={"multiple_permits"}
                            checked={multiple_permits}
                            value={multiple_permits}
                            onChange={this.handleCheckboxChange}
                            disabled={dateRange_visibility}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill mr-2" type="button" variant="" disabled={isDateRangeEditButtonDisabled} onClick={this.dateRangeVisibility}>
                        Edit
                      </Button>
                      <Button className="btn-fill" type="button" variant="info" disabled={!isDateRangeFormValid} onClick={this.addDateRange}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12" md="6" lg="6" className="mb-4">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Login Captions</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide-setting">
                  <Row>
                    <Col lg="12">
                      <Form.Group>
                        <label>Welcome Title</label>
                        <Form.Control
                          placeholder="Title"
                          type="text"
                          name="login_header"
                          value={login_header}
                          onChange={this.handleLoginChange}
                          disabled={login_visibility}
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Welcome Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Description"
                          name="login_body"
                          value={login_body}
                          onChange={this.handleLoginChange.bind(this)}
                          disabled={login_visibility}
                          rows={5}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill mr-2" type="button" variant="" disabled={isLoginCapEditButtonDisabled} onClick={this.loginVisibility}>
                        Edit
                      </Button>
                      <Button className="btn-fill" type="button" variant="info" disabled={!isLoginFormValid} onClick={this.handleLoginSubmit}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12" md="6" lg="6" className="mb-4">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Support</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide-setting">
                  <Row>
                    <Col lg="12">
                      <Form.Group>
                        <label>
                          Email
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="Email"
                          type="email"
                          name="support_email"
                          value={support_email}
                          onChange={this.handleSupportChange}
                          disabled={support_visibility}
                          autoComplete="off"
                          required
                        />
                        {email_validation_message(support_email, validEmail, wrongEmail)}
                        <div className="help-block">{emailErrorMessage}</div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <Form.Group>
                        <label>
                          Contact
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="Contact"
                          type="text"
                          name="support_contact"
                          value={support_contact}
                          onChange={this.handleSupportChange}
                          disabled={support_visibility}
                          autoComplete="off"
                          required
                        />
                        {validation_message(support_contact, "Phone Number", validSupportContact)}
                        <span className="help-block">{phoneErrorMsg}</span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill mr-2" type="button" variant="" disabled={isSupportEditButtonDisabled} onClick={this.supportVisibility}>
                        Edit
                      </Button>
                      <Button className="btn-fill" type="button" variant="info" disabled={!isSupportFormValid} onClick={this.handleSupportSubmit}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* <Col lg="12" className="p-0">
                {navigationButtons(
                  "/landingpage",
                  "/addlotform",
                  this. state.property_id
                )}
              </Col> */}
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Branding</Card.Title>
                    </div>
                    <div>
                      <button onClick={this.setBrandingData}>
                        {this.state.isShowPreview ? <i className="fa fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                      </button>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide-setting">
                  <Row>
                    <Col md="6" lg="6" xl="4">
                      <label>Header Logo</label>
                      <div className="d-flex align-items-center mb-3">
                        <div className="image-preview-box mr-3">
                          {headerLogo && (
                            <img
                              alt="No image"
                              style={{ maxWidth: "100%" }}
                              src={headerLogo?.image ? headerLogo?.image : headerLogo}
                              className="image-preview"
                            />
                          )}
                        </div>
                        <Button className="btn-fill mr-2" type="button" variant="" disabled={branding_visibility} onClick={() => this.showModel("header")}>
                          Select
                        </Button>
                      </div>
                    </Col>
                    <Col md="6" lg="6" xl="4">
                      <label>Footer Logo</label>
                      <div className="d-flex align-items-center mb-3">
                        <div className="image-preview-box mr-3">
                          {footerLogo && (
                            <img
                              alt="No Image"
                              style={{ maxWidth: "100%" }}
                              src={footerLogo?.image ? footerLogo?.image : footerLogo}
                              className="image-preview"
                            />
                          )}
                        </div>
                        <Button className="btn-fill mr-2" type="button" variant="" disabled={branding_visibility} onClick={() => this.showModel("footer")}>
                          Select
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md="12" lg="12" xl="8">
                      <Form.Group disabled={branding_visibility} row>
                        <label sm="5" htmlFor="input-large">
                          Footer Text
                        </label>
                        <div
                          style={{
                            pointerEvents: branding_visibility ? "none" : "auto",
                          }}
                        >
                          <CKEditor
                            activeClass="p10"
                            config={{ removePlugins: "resize" }}
                            content={footerText}
                            // onChange={this.onChange}
                            events={{
                              change: this.footerTextonChange,
                            }}
                            editor={{ disabled: { branding_visibility } }}
                            disabled={branding_visibility}
                            resizeEnabled={false}
                            toolbarCanCollapse={true}
                            id="ckeditor"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="12" lg="12" xl="4">
                      <Form.Group>
                        <label>Color Change</label>
                        <div className="row">
                          <Col xs="12" md="6" lg="3" xl="12" className="mb-2">
                            <label>Menu Header</label>
                            <Form.Control
                              placeholder="Menu header"
                              type="color"
                              value={menuHeader}
                              name="menuHeader"
                              onChange={this.handleBrandingChange}
                              disabled={branding_visibility}
                            />
                          </Col>
                          <Col xs="12" md="6" lg="3" xl="12" className="mb-2">
                            <label>Menu Background</label>
                            <Form.Control
                              placeholder="Menu Background"
                              type="color"
                              value={menuBackground}
                              name="menuBackground"
                              onChange={this.handleBrandingChange}
                              disabled={branding_visibility}
                            />
                          </Col>
                          <Col xs="12" md="6" lg="3" xl="12" className="mb-2">
                            <label>Main Body Background</label>
                            <Form.Control
                              placeholder="Main Body Background"
                              type="color"
                              value={mainBodyBackground}
                              name="mainBodyBackground"
                              onChange={this.handleBrandingChange}
                              disabled={branding_visibility}
                            />
                          </Col>
                          <Col xs="12" md="6" lg="3" xl="12" className="mb-2">
                            <label>Main Background</label>
                            <Form.Control
                              placeholder="Main Background"
                              type="color"
                              value={MainBackground}
                              name="MainBackground"
                              onChange={this.handleBrandingChange}
                              disabled={branding_visibility}
                            />
                          </Col>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="12" className="text-right">
                      <Button
                        className="btn-fill mr-2"
                        type="button"
                        variant=""
                        disabled={this.state.isBrandingEditButtonDisabled}
                        onClick={this.brandingVisibility}
                      >
                        Edit
                      </Button>
                      <Button className="btn-fill mr-2" type="button" variant="" disabled={this.state.isBrandingFormValid} onClick={this.resetBranding}>
                        Reset
                      </Button>
                      <Button className="btn-fill" type="button" variant="info" disabled={this.state.isBrandingFormValid} onClick={this.handleBrandingSubmit}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            {/* <Col lg="12" className="p-0">
              {navigationButtons(
                "/landingpage",
                "/addlotform",
                this.state.property_id
              )}
            </Col> */}
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Processor Gateway</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Processor Id</label>
                        <Form.Control
                          placeholder="Processor Id"
                          type="text"
                          name="processor_id"
                          value={processor_id}
                          onChange={this.handleProcessor}
                          disabled={processor_visibility}
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group>
                        <Form.Label c>
                          Currency <span className="asterisk">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          value={currency}
                          name="currency"
                          onChange={this.handleSelect}
                          className="fs-14 h-40"
                          disabled={processor_visibility}
                        >
                          <option value="CAD">CAD</option>
                          <option value="USD">USD</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill mr-2" type="button" variant="" disabled={isProcessorButtonFormValid} onClick={this.processorVisibility}>
                        Edit
                      </Button>
                      <Button className="btn-fill" type="button" variant="info" disabled={isProcessorFormValid} onClick={this.handleProcessorSubmit}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Accounting Reference</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Accounting Reference</label>
                        <Form.Control
                          placeholder="Accounting Reference Code"
                          type="text"
                          name="account_ref"
                          value={account_ref}
                          onChange={this.handleAccountingReference}
                          disabled={account_reference_visibility}
                          maxLength="32"
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="12" className="text-right">
                      <Button
                        className="btn-fill mr-2"
                        type="button"
                        variant=""
                        disabled={isAccountReferenceValid}
                        onClick={this.AccountingReferenceVisibility}
                      >
                        Edit
                      </Button>
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="info"
                        disabled={isAccountReferenceFormValid}
                        onClick={this.handleAccountingReferenceSubmit}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Monthly Transaction</label>
                        <Form.Control
                          placeholder="Monthly Transaction Reference Code"
                          type="text"
                          name="monthly_ref"
                          value={monthly_ref}
                          onChange={this.handleMonthlyReference}
                          disabled={monthly_reference_visibility}
                          maxLength="32"
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill mr-2" type="button" variant="" disabled={isMonthlyReferenceValid} onClick={this.MonthlyReferenceVisibility}>
                        Edit
                      </Button>
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="info"
                        disabled={isMonthlyReferenceFormValid}
                        onClick={this.handleMonthlyReferenceSubmit}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>{" "}
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Hourly Transaction</label>
                        <Form.Control
                          placeholder="Hourly Transaction Reference Code"
                          type="text"
                          name="hourly_ref"
                          value={hourly_ref}
                          onChange={this.handleHourlyReference}
                          disabled={hourly_reference_visibility}
                          maxLength="32"
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill mr-2" type="button" variant="" disabled={isHourlyReferenceValid} onClick={this.HourlyReferenceVisibility}>
                        Edit
                      </Button>
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="info"
                        disabled={isHourlyReferenceFormValid}
                        onClick={this.handleHourlyReferenceSubmit}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Harmonized Sales Tax Number</label>
                        <Form.Control
                          placeholder="Harmonized Sales Tax"
                          type="text"
                          name="harmonized_sales_tax"
                          value={harmonized_sales_tax}
                          onChange={this.handleHarmonizedSalesTax}
                          disabled={harmonized_sales_tax_visibility}
                          maxLength="13"
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="12" className="text-right">
                      <Button
                        className="btn-fill mr-2"
                        type="button"
                        variant=""
                        disabled={isHarmonizedSalesTaxValid}
                        onClick={this.HarmonizedSalesTaxVisibility}
                      >
                        Edit
                      </Button>
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="info"
                        disabled={isHarmonizedSalesTaxFormValid}
                        onClick={this.handleHarmonizedSalesTaxSubmit}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>HST(%)</label>
                        <Form.Control
                          placeholder="HST(%)"
                          type="text"
                          name="hst_percentage"
                          value={hst_percentage}
                          onChange={this.handleHSTPercentage}
                          disabled={hst_percentage_visibility}
                          maxLength="3"
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill mr-2" type="button" variant="" disabled={isHSTPercentageValid} onClick={this.HSTPercentageVisibility}>
                        Edit
                      </Button>
                      <Button className="btn-fill" type="button" variant="info" disabled={isHSTPercentageFormValid} onClick={this.handleHSTPercentageSubmit}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/** WORLD-713 */}
          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Mobi Vision</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Enable/Disable</label>
                        <ToggleButton value={qr_code_scanner} onToggle={this.onToggleChange.bind(this)} />
                      </Form.Group>
                    </Col>
                    <Col lg="6"></Col>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill" type="button" variant="info" disabled={isGoogleGlassValid} onClick={this.handleGoogleGlassSubmit}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Mobile View Option</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>On/Off</label>
                        <ToggleButton value={mobile_view_option} onToggle={this.onVisitorMobileViewToggleChange.bind(this)} />
                      </Form.Group>
                    </Col>
                    <Col lg="6"></Col>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill" type="button" variant="info" disabled={isVisitorMobileValid} onClick={this.handleMobileViewSubmit}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Call When Here</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Enable/Disable</label>
                        <ToggleButton value={call_when_here} onToggle={this.onToggleChangeCallWhenHere} />
                      </Form.Group>
                    </Col>
                    <Col lg="6"></Col>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill" type="button" variant="info" disabled={iscallWhenHereValid} onClick={this.handleCallWhenHereSubmit}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Extended LPN Info</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Enable/Disable</label>
                        <ToggleButton value={is_extended_lpn_Info} onToggle={this.onToggleChangeExtended_LPN_Info} />
                      </Form.Group>
                    </Col>
                    <Col lg="6"></Col>
                    <Col lg="12" className="text-right">
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="info"
                        disabled={isExtended_LPN_InfoValid}
                        onClick={this.handleExtended_LPN_InfoSubmit}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Resident Permits Duration</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Default (in Days)</label>
                        <Form.Control
                          placeholder="Difference in years"
                          type="text"
                          name="date_diff"
                          value={date_diff}
                          onChange={this.handleDateDifference}
                          disabled={date_diff_visibility}
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill mr-2" type="button" variant="" disabled={isDateDiffValid} onClick={this.DateDiffVisibility}>
                        Edit
                      </Button>
                      <Button className="btn-fill" type="button" variant="info" disabled={isDateDiffFormValid} onClick={this.handleDateDiffSubmit}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Unit Edit Setting</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="checkgrops-box">
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Edit Username</label>
                        <ToggleButton value={edit_unit_username} onToggle={this.onToggleChangeEditUsername} />
                      </Form.Group>
                    </Col>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill" type="button" variant="info" disabled={!edit_unit_edit_selection} onClick={this.editUnitSetting}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Optional Field For Visitor Management (Name,Phone and Email)</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="checkgrops-box">
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Is Optional</label>
                        <ToggleButton value={is_optional} onToggle={this.onToggleChangeIsOptional} />
                      </Form.Group>
                    </Col>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill" type="button" variant="info" disabled={!is_optional_selection} onClick={this.editIsOptionalSetting}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Allowed visitor to payment for credit</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Enable/Disable</label>
                        <ToggleButton value={visitor_credit_payment} onToggle={this.onToggleChangeVisitorCreditPay} />
                      </Form.Group>
                    </Col>
                    <Col lg="6"></Col>
                    <Col lg="12" className="text-right">
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="info"
                        disabled={visitor_credit_payment_Valid}
                        onClick={this.handle_visitor_credit_paymemnt_Submit}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Permits allowed with plates that have citations</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Enable/Disable</label>
                        <ToggleButton value={allow_citation_permits} onToggle={this.onToggleChangeAllowPermitWithCitation} />
                      </Form.Group>
                    </Col>
                    <Col lg="6"></Col>
                    <Col lg="12" className="text-right">
                      <Button
                        className="btn-fill"
                        type="button"
                        variant="info"
                        disabled={allow_citation_permits_Valid}
                        onClick={this.handleAllowPermitsWithCitationSubmit}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Card.Title as="h4">Invoice</Card.Title>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className="mt-3">
                    <Col lg="6">
                      <Form.Group>
                        <label>Enable/Disable</label>
                        <ToggleButton value={invoice_enabled} onToggle={this.onToggleChangeInvoice} />
                      </Form.Group>
                    </Col>
                    <Col lg="6"></Col>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill" type="button" variant="info" disabled={invoice_enabled_Valid} onClick={this.handle_invoice_Submit}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {highsecurity === 1 && (
            <Row>
              <Col sm="12" md="12" lg="12">
                <Card>
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <Card.Title as="h4">User Session Time</Card.Title>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row className="mt-3">
                      <Col lg="6">
                        <Form.Group>
                          <label>Default (in hours)</label>
                          <Form.Control
                            placeholder="Session Time"
                            type="text"
                            name="processor_id"
                            value={session_time}
                            onChange={this.handleSessionChange}
                            disabled={session_visibility}
                            autoComplete="off"
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="12" className="text-right">
                        <Button className="btn-fill mr-2" type="button" variant="" disabled={isSessionEditValid} onClick={this.sessionVisibility}>
                          Edit
                        </Button>
                        <Button className="btn-fill" type="button" variant="info" disabled={isSessionUpdateValid} onClick={this.sessionSubmit}>
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          <Row>
            {highsecurity === 1 && (
              <Col sm="12" md="12" lg="12">
                <Card>
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <Card.Title as="h4">Security Officer Email</Card.Title>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row className="mt-3">
                      <Col sm="12" lg="9">
                        {
                          <div className="form-group">
                            <label className="mb-0">
                              Security Officer Email
                              <span className="asterisk">*</span>
                            </label>
                            <Form.Control
                              placeholder="Enter email"
                              type="text"
                              name="highsecurity_email"
                              value={highsecurity_email}
                              onChange={this.handleChange}
                              autoComplete="off"
                              required
                            />

                            {email_validation_message(highsecurity_email, validEmail, wrongEmail)}
                          </div>
                        }
                      </Col>
                      <Col lg="12" className="text-right">
                        <Button className="btn-fill" type="button" variant="info" disabled={isSecurityEmailValid} onClick={this.handleSecurityEmailSubmit}>
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>

          <Col md="auto" className="py-1 d-flex justify-content-end pb-4">
            <Button
              type="button"
              className="btn-fill mr-2"
              variant="danger"
              onClick={() => {
                history.push({
                  pathname: "/landingpage",
                  search: property_id,
                });
              }}
            >
              Back
            </Button>

            <Button
              className="btn-fill btn btn-info"
              type="button"
              variant="info"
              disabled={!isFormValid}
              // style={{ margin: 15 }}
              onClick={() => {
                history.push({
                  pathname: "/adduserform",
                  search: property_id,
                });
              }}
              title={isFormValid ? "" : "Please fill out the support details"}
            >
              Next
            </Button>
          </Col>
          <CropImage
            show={show}
            handleModelClose={this.handleModelClose}
            onSaveLogo={(base64File, imageName) => {
              if (this.state.selectLogoType) {
                this.handleAddPhoto(base64File, imageName, this.state.selectLogoType);
              }
            }}
          />
        </Container>
        {show_alert ? <CustomContentAlert delay={4000} message={this.state.api_success_message} className="toast-success" /> : null}
        {show_error ? <CustomContentAlert delay={4000} message={this.state.api_error_message} className="toast-error" /> : null}
      </div>
    );
  }
}

const mapStateToProps = ({ APIResponseDetails, SuperAdminDetails, UserProfileDetails, Logindetails }) => {
  const { api_response, api_success_message, api_error_message } = APIResponseDetails;
  const {
    getAllProperties,
    settings,
    dateSlots,
    Day,
    Evening,
    Night,
    available_spot,
    support_visitor,
    siteSetting,
    login_header,
    login_body,
    support_contact,
    support_email,
    processorId,
    templateCreditGroup,
  } = SuperAdminDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { userData } = UserProfileDetails;
  return {
    siteSetting,
    Day,
    Evening,
    Night,
    available_spot,
    support_visitor,
    getAllProperties,
    templateCreditGroup,
    settings,
    userData,
    dateSlots,
    login_header,
    login_body,
    support_contact,
    support_email,
    api_response,
    api_success_message,
    api_error_message,
    main_body,
    processorId,
  };
};

const mapStateToDispatch = {
  get_all_properties: SuperAdminAction.get_all_properties,
  getSettings: SuperAdminAction.getSettings,
  getCreditTemplateGroups: SuperAdminAction.getCreditTemplateGroups,
  selected_Property: SuperAdminAction.selected_Property,
  updateSupport: SuperAdminAction.updateSupport,
  updateLoginCaption: SuperAdminAction.updateLoginCaption,
  updateSiteSetting: SuperAdminAction.updateSiteSetting,
  createDateSlot: SuperAdminAction.createDateSlot,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  uploadHeaderLogo: SuperAdminAction.uploadHeaderLogo,
  uploadFooterLogo: SuperAdminAction.uploadFooterLogo,
  setBackgroundColor: SuperAdminAction.setBackgroundColor,
  addProcessorId: SuperAdminAction.addProcessorId,
  googleGlass: SuperAdminAction.googleGlass,
  mobileView: SuperAdminAction.mobileView,
  callWhenHere: SuperAdminAction.callWhenHere,
  extended_LPN_Info: SuperAdminAction.extended_LPN_Info,
  invoice_cron_enabled: SuperAdminAction.invoice_cron_enabled,
  visitor_payment_credit_enabled: SuperAdminAction.visitor_payment_credit_enabled,
  allow_permits_with_citation: SuperAdminAction.allow_permits_with_citation,
  highSecurityEmail: SuperAdminAction.highSecurityEmail,
  addDateDifference: SuperAdminAction.addDateDifference,
  addAccountReference: SuperAdminAction.addAccountReference,
  addMonthlyReference: SuperAdminAction.addMonthlyReference,
  addHourlyReference: SuperAdminAction.addHourlyReference,
  addHarmonizedSalesTax: SuperAdminAction.addHarmonizedSalesTax,
  addHSTPercentage: SuperAdminAction.addHSTpercentage,
  addSessionTime: SuperAdminAction.addSessionTime,
  updateUnitSelectionSetting: SuperAdminAction.updateUnitSelectionSetting,
};

export default connect(mapStateToProps, mapStateToDispatch)(LandingPage);

import React from "react";
import { useSelector } from "react-redux";
import { getQuickPermitSetupDetail } from "../../Selectors/SelfRegisterDetails";
import { routing_using_history } from "../helper";

const SelfRegisterMainHeader = () => {
  const { menu_header, header_logo } = useSelector(getQuickPermitSetupDetail);
  return (
    <>
      <div
        style={{
          backgroundColor: menu_header,
          height: "56px",
        }}
        className="d-flex justify-content-between"
      >
        <div>
          <img
            onClick={() => routing_using_history("/dashboard")}
            src={
              header_logo ||
              require("../../../assets/img/WorldSteamBox.svg").default
            }
            alt="..."
            style={{ cursor: "pointer" }}
            className="selfRegisterHeader"
          />
        </div>
        <div className="px-2">
          <img
            src={require("../../../assets/img/parked_car.svg").default}
            style={{ height: "53px" }}
            alt="no img"
          />
        </div>
      </div>
    </>
  );
};

export default SelfRegisterMainHeader;

export const selfServeConsts = {
  min: 2,
  max: 10,
  memberId: "ID",
  noteField: "Permit Registration",
  succesMessage: "Permit Created Successfully.",
};

export const registerEnum = {
  nope: "nope",
  tap: "tap",
  url: "url",
  noQr: "no-qr",
};

export const idValidationEnum = {
  numeric: "numeric",
  alphabets: "alphabets",
  alphanumeric: "alphanumeric",
};

import React, { Component } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

class ConfirmationModal extends Component {
  render() {
    const { onOpen, onClose, onConfirm, loading, message, title, isCanceldisabled } = this.props;
    return (
      <Modal lg={"medium"} show={onOpen} onHide={onClose}>
        <Modal.Header className="no-border">
          <h4 className="m-0 font-weight-bold">{title}</h4>
          <Button title={title} type="button" onClick={onClose} className="close-btn" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove"></i>
          </Button>
        </Modal.Header>

        <Modal.Body className="modal-data-body">
          <p className="theme-color">{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ minWidth: "93px" }} onClick={onClose} type="button" className="btn btn-danger btn-cancel">
            No
          </Button>
          {!loading ? (
            <Button style={{ minWidth: "93px" }} onClick={onConfirm} type="button" className="btn btn-fill btn-info" disabled={isCanceldisabled}>
              Yes
            </Button>
          ) : (
            <Spinner animation="border" variant="info" />
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmationModal;

import React, { Component } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { connect } from "react-redux";
import {
  // CurrentVisitorPermitActions,
  SuperAdminAction,
  CurrentPermitActions,
} from "../../Actions";
import Loader from "../Loader/Loader";
import CustomContentAlert, { findBySearch, scroolToTop, tableHeightScrollBars } from "../helper";
import { customRow, overlayTriggerRow } from "../Common/Rows";
class CurrentVisitorPermit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PropertyId: this.props.PropertyId,
      currentVisitorPermit: [],
      loader: false,
      searchedCurrentVisitorPermitList: [],
      user_type: this.props.user_type,
      searchValue: "",
      api_response: false,
      page: 1,
      Norow: 100,
      selectedColumn: "created_at",
      asc: "false",
      sort_direction: "DESC",
      prevSearch: "",
      prevPage: "",
      prevNorow: "",
      SuiteId: "",
      pageChanged: false,
      findMore: false,
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      finalTableHeight: 0,
    };
    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    const checkLpn = (lpn) => {
      const history = require("../../history").default;
      history.push({
        pathname: `/current-occupancy`,
        search: `audit-report&${lpn}`,
      });
    };

    const checkUnit = (suite_id) => {
      const history = require("../../history").default;
      history.push({
        pathname: `/units`,
        search: "search",
        state: {
          search: suite_id,
        },
      });
    };

    this.columns = [
      {
        name: "Visitor's Name",
        sortable: true,
        width: "160px",
        cell: (row) =>
          overlayTriggerRow({
            value: row?.visitor_name ? row?.visitor_name : "-",
          }),
        selector: (row) => row?.visitor_name,
      },
      {
        name: "Visitor's Phone",
        sortable: true,
        width: "130px",
        cell: (row) =>
          customRow({
            value: row?.visitor_phone ? row?.visitor_phone : "-",
          }),
        selector: (row) => row?.visitor_phone,
      },
      {
        name: "Plate",
        sortable: true,
        width: "120px",
        cell: (row) =>
          customRow({
            value: row?.lpn ? row?.lpn : "-",
            className: "curserPointer text-truncate text-nowrap pr-2",
            onClick: () => checkLpn(row.lpn),
          }),
        selector: (row) => row?.lpn,
      },
      {
        name: "Permit Start",
        sortable: true,
        minWidth: "150px",
        maxWidth: "250px",
        cell: (row) =>
          customRow({
            value: row?.begin_time ? row?.begin_time : "-",
          }),
        selector: (row) => row?.begin_time,
      },
      {
        name: "Permit End",
        sortable: true,
        minWidth: "150px",
        maxWidth: "250px",
        selector: (row) => row?.expire_time,
        cell: (row) =>
          customRow({
            value: row?.expire_time ? row?.expire_time : "-",
          }),
      },
      {
        name: "Invited by Name",
        sortable: true,
        minWidth: "160px",
        maxWidth: "250px",
        cell: (row) =>
          customRow({
            value: row?.resident_name ? row?.resident_name : "-",
          }),
        selector: (row) => row?.resident_name,
      },
      {
        name: "Invited by Suite",
        sortable: true,
        minWidth: "160px",
        maxWidth: "250px",
        cell: (row) =>
          customRow({
            value: row?.suite_id ? row?.suite_id : "-",
            className: "curserPointer text-truncate text-nowrap pr-2",
            onClick: () => checkUnit(row.suite_id),
          }),
        selector: (row) => row?.suite_id,
      },
      {
        name: "Invited Phone",
        sortable: true,
        minWidth: "160px",
        maxWidth: "250px",
        cell: (row) =>
          customRow({
            value: row?.resident_phone ? row?.resident_phone : "-",
          }),
        selector: (row) => row?.resident_phone,
      },
    ];
  }

  componentDidMount() {
    this.setState({ loader: true }, async () => {
      const { page, Norow, searchValue, selectedColumn, sort_direction, PropertyId, user_type } = this.state;

      const pageobj = {
        PropertyId,
        user_type,
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        permitType: "Visitor",
        status: "current",
        suite_id: "",
      };
      await this.props.fetch_current_visitor_permit(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        currentVisitorPermit: this.props.currentVisitorPermit.data,
        count: this.props.currentVisitorPermit.count,
        loader: false,
        api_response: this.props.api_response,
      });
      const height = tableHeightScrollBars();
      this.setState({
        finalTableHeight: height,
      });
    });
  }

  formatAMPM = () => {
    const d = new Date();
    const minutes = d.getMinutes().toString().length === 1 ? "0" + d.getMinutes() : d.getMinutes();
    const hours = d.getHours().toString().length === 1 ? "0" + d.getHours() : d.getHours();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days[d.getDay()] + " " + months[d.getMonth()] + " " + d.getDate() + " " + d.getFullYear() + " " + hours + ":" + minutes;
  };

  handleSearchChange = ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();

    this.setState({ prevSearch: this.state.searchValue });
    this.setState({ searchValue: val }, () => {
      const columnList = ["visitor_name", "visitor_phone", "lpn", "resident_name", "suite_id", "resident_phone", "begin_time", "expire_time"];
      if (!this.state.findMore) {
        if (this.state.searchValue.length) {
          this.setState(
            {
              searchedCurrentVisitorPermitList: findBySearch(
                this.state.searchValue,
                this.state.currentVisitorPermit,
                this.state.currentVisitorPermit,
                columnList,
                this.props.timezone,
              ),
            },
            () => {
              this.setState({
                count: this.state.searchedCurrentVisitorPermitList.length,
              });
            },
          );
        }
      } else {
        this.setState({
          currentVisitorPermit: this.state.currentVisitorPermit,
          count: this.state.currentVisitorPermit.length,
          searchedCurrentVisitorPermitList: [],
        });
      }
    });
  };

  dataTableHandle = (searchValue, searchedCurrentVisitorPermitList, currentVisitorPermit) => {
    return (
      <DataTable
        data={searchValue?.length ? searchedCurrentVisitorPermitList : currentVisitorPermit}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={this.state.Norow}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  render() {
    const { currentVisitorPermit, loader, searchValue, searchedCurrentVisitorPermitList, api_response, pageChanged } = this.state;
    const current_date_time = this.formatAMPM();
    return (
      <div>
        <Container fluid className="page-no-fliter">
          <Row>
            <Col md="12">
              <Card className="mb-0">
                <Card.Body className="pos-rel-overflow-hide">
                  <>
                    <div className="sidebarFilter">
                      <Card className="contact-container mb-0">
                        <Card.Header style={{ backgroundColor: this.props.main_body }}>
                          <div className="d-flex align-items-center justify-content-between">
                            <Card.Title as="h4">List of current visitor permits</Card.Title>
                            <a
                              onClick={this.hideSlider}
                              href="javascript:void(0);"
                              className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                          </div>
                        </Card.Header>
                        <Card.Body></Card.Body>
                        <Card.Footer className="text-right justify-content-between d-flex">
                          {/* <Button
                          variant="info"
                          className="h-40 custom-btn-secondary btn-sm"
                          onClick={this.handleResetAll}
                          disabled={loader}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="info"
                          className="h-40 btn-fill btn-sm ml-2"
                          onClick={this.handleSearchTickets}
                          disabled={loader}
                        >
                          Search
                        </Button> */}
                        </Card.Footer>
                      </Card>
                    </div>
                    <header>
                      <div className="active-permit-header sm-flex-column sm-align-items-start">
                        <h5 className="mb-2 mb-md-0">List of current visitor permits</h5>
                        <div className="filter-button d-flex flex-wrap filter-btn-bottom">
                          <div className="d-flex align-items-center search-mb-0">
                            <DatatableHeader
                              className="mt-0 mb-0"
                              searchValue={searchValue}
                              HandleChange={this.handleSearchChange}
                              curentPermitTime={true}
                              current_date_time={current_date_time}
                            />
                          </div>
                        </div>
                      </div>
                    </header>
                  </>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {!api_response && (
                        <CustomContentAlert delay={4000} message={"Server failed to respond please try again in sometime"} className="toast-error" />
                      )}
                      {currentVisitorPermit && (
                        <>
                          <div className="citation-list-table current-visitor-table">
                            {this.dataTableHandle(searchValue, searchedCurrentVisitorPermitList, currentVisitorPermit, pageChanged)}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, CurrentPermitDetails, Logindetails }) => {
  const { api_response, api_error_message } = APIResponseDetails;
  const { property_id, user_type, timezone, property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { currentPermits: currentVisitorPermit } = CurrentPermitDetails;
  return {
    api_response,
    api_error_message,
    PropertyId: property_id,
    user_type,
    currentVisitorPermit,
    timezone,
    property,
    main_body,
  };
};

const mapStateToDispatch = {
  //  fetch_current_visitor_permit:
  //   CurrentVisitorPermitActions.fetch_current_visitor_permit,
  fetch_current_visitor_permit: CurrentPermitActions.fetchCurrentPermits,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(CurrentVisitorPermit);

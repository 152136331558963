import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ResidentsActions, SelfRegisterActions } from "../../Actions";
import CustomContentAlert, { regExForPhone, removeSpecialChar, validations } from "../helper";
import { timeOut } from "../Common/SetTimeout";
import NoteSelector from "../SelfRegister/NoteSelector";
import moment from "moment";

function QuickPermitForm() {
  const country_code_list = [
    { id: 1, text: "+1", value: "+1" },
    { id: 2, text: "+91", value: "+91" },
    { id: 3, text: "+44", value: "+44" },
    { id: 4, text: "+61", value: "+61" },
  ];
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    memberId: "",
    phone: "",
    vehicle_license: "",
    first_name: "",
    last_name: "",
    notes: "",
  });
  const permit_start_date = "";
  const [permit_end_date, setPermit_end_date] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [country_code, setCountry_code] = useState("+1");
  const [phone_options, setPhone_options] = useState([]);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [show_alert, setShow_alert] = useState(false);
  const [Api_success_message, setApi_success_message] = useState("");
  const [show_error, setShow_error] = useState(false);
  const [Api_error_message, setApi_error_message] = useState("");

  const { id, property_id, timezone, cost } = useSelector((state) => state.UserProfileDetails);
  const { quickPermitSetupDetail, quickPermitDetails } = useSelector((state) => state.SelfRegisterDetails);
  const { permissions: allPermissions } = useSelector((state) => state.SuperAdminDetails);
  const { addUpdateDeleteResponse } = useSelector((state) => state.ResidenDetails);

  useEffect(() => {
    const currentdate = new Date().toLocaleString("en-US", {
      timeZone: timezone,
    });
    const formatEndDate = moment(currentdate).format("YYYY-MM-DD");
    const endDateWithTime = `${formatEndDate} 23:59:59`;
    const formatedET = moment(endDateWithTime).format("YYYY-MM-DD HH:mm:SS");
    const convertedExpireTime = moment.tz(formatedET, timezone).format();
    const UTCET = moment.tz(convertedExpireTime, "UTC").format();
    setPermit_end_date(UTCET);
    dispatch(SelfRegisterActions.getQuickPermitSetup(property_id));
  }, [property_id]);
  const handleEnter = (event) => {
    if (event.key === "Enter" && isFormValid) {
      handleSubmit();
    }
  };
  const blockInvalidChar = (e) => {
    if (e.key === "Enter" && isFormValid) {
      handleSubmit();
    } else {
      return ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
    }
  };
  const isMultiplePhoneValid = () => {
    const phoneListCount = {};
    const phoneList = phone_options?.map((item) => {
      return item.country_code + item.phone;
    });
    const phoneNumberCountry_code = country_code === "" ? "+1" : country_code;
    const phoneNumber = formData?.phone === "" ? "" : formData?.phone;
    phoneList?.push(phoneNumberCountry_code + phoneNumber);
    phoneList?.forEach(function (x) {
      phoneListCount[x] = (phoneListCount[x] || 0) + 1;
    });
    const phoneListStatus = Object.keys(phoneListCount).filter((item) => phoneListCount[item] > 1);
    const validPhone = phone_options?.map((options) => {
      if (options.phone?.trim().length > 10 || options.phone?.trim().length < 10 || options.phone?.trim().length === 0 || phoneListStatus.length) {
        return !(options.phone?.trim().length > 10 || options.phone?.trim().length < 10 || options.phone?.trim().length === 0 || phoneListStatus.length);
      }
      return true;
    });
    return !validPhone?.includes(false);
  };
  const validateContactNumber = (value) => {
    const phone = value;
    const regExForContactNumber = regExForPhone;
    const isContactNumberValidate = regExForContactNumber.test(String(phone));

    if (isContactNumberValidate) {
      if ((phone && phone.length >= 11) || (phone && phone.length < 10)) {
        setPhoneErrorMessage("Please enter valid phone number");
        return false;
      } else {
        setPhoneErrorMessage("");
        return true;
      }
    } else {
      return false;
    }
  };
  const validateForm = () => {
    const { first_name, last_name, phone, vehicle_license } = formData;

    if (quickPermitSetupDetail?.show_id === 0) {
      if (
        first_name?.trim()?.length &&
        last_name?.trim()?.length &&
        phone?.trim()?.length &&
        vehicle_license?.trim()?.length &&
        validateContactNumber(phone) &&
        isMultiplePhoneValid()
      ) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      if (
        first_name?.trim()?.length &&
        last_name?.trim()?.length &&
        vehicle_license?.trim()?.length &&
        phone?.trim()?.length === 10 &&
        isMultiplePhoneValid()
      ) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  };
  const handleAddPhoneOptions = () => {
    setPhone_options([...phone_options, { country_code: "+1", phone: "" }]);
    validateForm();
  };
  const handlePhoneOptions = (index, value, type) => {
    if (type === "phone") {
      const updatedPhoneOption = phone_options.map((item, ind) => {
        if (ind === index) {
          return {
            ...item,
            phone: value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"),
          };
        } else {
          return item;
        }
      });
      setPhone_options(updatedPhoneOption);
    }
    if (type === "country_code") {
      const updatedPhoneOption = phone_options.map((item, ind) => {
        if (ind === index) {
          return { ...item, country_code: value };
        } else {
          return item;
        }
      });
      setPhone_options(updatedPhoneOption);
    }
  };
  const removePhoneOption = (index) => {
    const updated_phoneOption = phone_options.filter((_x, i) => i !== index);
    setPhone_options(updated_phoneOption);
    validateForm();
  };
  const findPermit = async () => {
    const { memberId, phone } = formData;
    if (memberId || phone) {
      setFormData((prevState) => ({
        ...prevState,
        phone: "",
        first_name: "",
        last_name: "",
        vehicle_license: "",
        notes: "",
      }));
      dispatch(SelfRegisterActions.getPermitDataWithPhoneOrMemberID(phone, memberId, property_id));
    }
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      phone: quickPermitDetails.data?.phone || "",
      first_name: quickPermitDetails.data?.firstName || "",
      last_name: quickPermitDetails.data?.lastName || "",
      vehicle_license: quickPermitDetails.data?.plate || "",
      notes: quickPermitDetails.data?.note || "",
    }));
    setPhone_options(quickPermitDetails.data?.phone_options || []);
    setIsFormValid(true);
    if (quickPermitDetails?.success !== null) {
      if (quickPermitDetails?.success) {
        setShow_alert(true);
        setApi_success_message(quickPermitDetails?.message);
        timeOut({
          fun: () => {
            setShow_alert(false);
          },
        });
      } else {
        setShow_error(true);
        setApi_error_message(quickPermitDetails?.message);
        timeOut({
          fun: () => {
            setShow_error(false);
          },
        });
      }
    }
  }, [quickPermitDetails]);

  const handleChange = ({ target }) => {
    if (target.name === "phone" && target.value?.trim().length) {
      validateContactNumber(target?.value);
      if (regExForPhone.test(target.value)) {
        setFormData((prevState) => ({ ...prevState, phone: target.value }));
      } else {
        setFormData((prevState) => ({ ...prevState, phone: "" }));
      }
    }
    if (target.name === "first_name") {
      const FirstName = validations(target.name, "first_name", target.value);
      setFormData((prevState) => ({
        ...prevState,
        first_name: removeSpecialChar(FirstName.value),
      }));
    }
    if (target.name === "last_name") {
      const LastName = validations(target.name, "last_name", target.value);
      setFormData((prevState) => ({
        ...prevState,
        last_name: removeSpecialChar(LastName?.value),
      }));
    }
    if (target.name === "country_code") {
      const CountryCode = validations(target.name, "country_code", target.value);
      setCountry_code(CountryCode?.value?.replace(/[a-zA-Z]/, ""));
    }
    if (target.name === "phone") {
      const Phone = validations(target.name, "phone", target.value);
      setFormData((prevState) => ({
        ...prevState,
        phone: Phone?.value?.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"),
      }));
    }
    if (target.name === "memberId") {
      const MemberId = validations(target.name, "memberId", target.value);
      setFormData((prevState) => ({
        ...prevState,
        memberId: removeSpecialChar(MemberId?.value),
      }));
    }
    if (target.name === "notes") {
      const Notes = validations(target.name, "notes", target.value);
      setFormData((prevState) => ({ ...prevState, notes: Notes?.value }));
    }
    if (target.name === "vehicle_license") {
      const VehicalLicense = validations(target.name, "vehicle_license", target.value);
      if (VehicalLicense?.value !== undefined) {
        setFormData((prevState) => ({
          ...prevState,
          vehicle_license: VehicalLicense?.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, ""),
        }));
      }
    }
  };
  useEffect(() => {
    validateForm();
  }, [formData]);
  const handleSubmit = async () => {
    const residentData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone: formData.phone,
      suite_id: "Quick Permit",
      memberId: formData?.memberId,
      country_code,
      phone_options,
      cost: cost,
    };
    const PropertyId = property_id;
    setIsFormValid(false);
    let selfServe;
    if (allPermissions.length > 0) {
      selfServe = false;
    } else {
      selfServe = true;
    }
    const obj = {
      ...residentData,
      vehicle_license: formData?.vehicle_license,
      self_server: selfServe,
      notes: formData?.notes,
      lot_id: quickPermitSetupDetail?.lot_id,
      call_when_here: 0,
    };
    dispatch(ResidentsActions.visitorCreate(PropertyId, permit_start_date, permit_end_date, id, obj, ["lotid"], true, timezone));
  };
  useEffect(() => {
    if (addUpdateDeleteResponse.success !== null) {
      if (addUpdateDeleteResponse.success) {
        setFormData((prevState) => ({
          ...prevState,
          memberId: "",
          phone: "",
          vehicle_license: "",
          first_name: "",
          last_name: "",
          notes: "",
        }));
        setShow_alert(true);
        setApi_success_message(addUpdateDeleteResponse?.message);
        timeOut({
          fun: () => {
            setShow_alert(false);
            setPhone_options([]);
          },
        });
      } else {
        setShow_error(true);
        setApi_error_message(addUpdateDeleteResponse?.message);
        timeOut({
          fun: () => {
            setShow_error(false);
          },
        });
      }
    }
  }, [addUpdateDeleteResponse]);
  useEffect(() => {
    setShow_alert(false);
    setShow_error(false);
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="contact-container mb-3">
              <Card.Header>
                <Card.Title as="h4">Quick permits</Card.Title>
                <p className="card-category"></p>
              </Card.Header>
              <Card.Body>
                <Row className="m-w-480px">
                  {quickPermitSetupDetail?.show_id === 1 && (
                    <Col className="mb-3">
                      <Form.Label>Member ID:</Form.Label>
                      <Form.Control
                        value={formData?.memberId}
                        type="string"
                        name="memberId"
                        placeholder="Member ID"
                        onChange={handleChange}
                        onKeyDown={handleEnter}
                      ></Form.Control>
                    </Col>
                  )}
                  {quickPermitSetupDetail?.show_id === 0 && (
                    <>
                      <Col className="col-auto">
                        <Form.Group>
                          <label>Country Code:</label>
                          <Form.Control as="select" name="country_code" onChange={handleChange} value={formData?.country_code} onKeyDown={handleEnter}>
                            {country_code_list ? (
                              <>
                                {(country_code_list ?? []).map((item) => (
                                  <option key={item?.id} value={item.value}>
                                    {item.text}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="mb-3">
                        <Form.Label>Mobile:</Form.Label>
                        <span className="asterisk">*</span>
                        <Form.Control
                          value={formData?.phone}
                          type="text"
                          name="phone"
                          pattern="[0-9]*"
                          placeholder="333-222-2323"
                          notes
                          maxlength="10"
                          onChange={handleChange}
                          onKeyDown={handleEnter}
                        />
                      </Col>
                    </>
                  )}
                  <Col className="col-auto mb-3 align-self-end">
                    <Button onClick={findPermit}>Find</Button>
                  </Col>
                </Row>
                <Row className="m-w-480px">
                  <Col className="mb-3">
                    <Form.Label>License Plate Number:</Form.Label>
                    <span className="asterisk">*</span>
                    <Form.Control
                      type="text"
                      name="vehicle_license"
                      value={formData?.vehicle_license}
                      placeholder="ABC123"
                      onChange={handleChange}
                      maxlength="11"
                      onKeyDown={handleEnter}
                    />
                  </Col>
                </Row>
                <Row className="m-w-480px">
                  <Col className="mb-3">
                    <Form.Label>Permit Name:</Form.Label>
                    <span className="asterisk">*</span>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: " 1fr 1fr",
                        gridColumnGap: "15px",
                      }}
                    >
                      <Col style={{ padding: 0 }}>
                        <Form.Label>First Name:</Form.Label>
                        <Form.Control
                          value={formData?.first_name}
                          type="string"
                          name="first_name"
                          placeholder="First Name"
                          onChange={handleChange}
                          onKeyDown={handleEnter}
                        />
                      </Col>
                      <Col style={{ padding: 0 }}>
                        <Form.Label>Last Name:</Form.Label>
                        <Form.Control
                          value={formData?.last_name}
                          type="string"
                          name="last_name"
                          placeholder="Last Name"
                          onChange={handleChange}
                          onKeyDown={handleEnter}
                        />
                      </Col>
                    </div>
                  </Col>
                </Row>
                {quickPermitSetupDetail?.regular === 1 && (
                  <Row>
                    <Col md={6} xs={6}>
                      <NoteSelector
                        setNote={(note) =>
                          setFormData((prevState) => ({
                            ...prevState,
                            notes: note,
                          }))
                        }
                        note={formData?.notes}
                        quickPermitSetupDetail={quickPermitSetupDetail}
                      />
                    </Col>
                  </Row>
                )}
                {quickPermitSetupDetail?.show_id === 1 && (
                  <>
                    {" "}
                    <Row className="m-w-480px">
                      <Col className="col-auto">
                        <Form.Group>
                          <label>Country Code:</label>
                          <Form.Control as="select" name="country_code" onChange={handleChange} value={formData?.country_code} onKeyDown={handleEnter}>
                            {country_code_list ? (
                              <>
                                {(country_code_list ?? []).map((item) => (
                                  <option key={item?.id} value={item.value}>
                                    {item.text}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="col p-0">
                        <Form.Label>Mobile:</Form.Label>
                        <span className="asterisk">*</span>
                        <Form.Control
                          value={formData?.phone}
                          type="text"
                          name="phone"
                          // pattern="[0-9]*"
                          placeholder="333-222-2323"
                          notes
                          onKeyDown={blockInvalidChar}
                          onChange={handleChange}
                          maxlength="10"
                          autoComplete="off"
                        />
                        <span className="help-block">{phoneErrorMessage}</span>
                      </Col>
                      <Col className="col-auto">
                        <div>
                          <label>&nbsp;</label>
                        </div>
                        <Button
                          size="sm"
                          className="h-40px"
                          disabled={
                            phone_options?.length > 9 ||
                            formData?.phone?.trim()?.length === 0 ||
                            formData?.phone?.trim()?.length > 10 ||
                            formData?.phone?.trim()?.length < 10 ||
                            !isMultiplePhoneValid()
                          }
                          onClick={handleAddPhoneOptions}
                        >
                          <i className="fas fa-plus-circle"></i>
                        </Button>
                      </Col>
                    </Row>
                    {phone_options &&
                      typeof phone_options === "object" &&
                      Array.isArray(phone_options) &&
                      phone_options.map((option, index) => {
                        return (
                          <Row key={option?.country_code} className="my-0 m-w-480px">
                            <Col className="col-auto">
                              <Form.Group>
                                <Form.Control
                                  className="w-85px"
                                  as="select"
                                  name="country_code_options"
                                  onChange={(e) => handlePhoneOptions(index, e.target.value, "country_code")}
                                  value={option?.country_code}
                                  required
                                  onKeyDown={handleEnter}
                                >
                                  {country_code_list ? (
                                    <>
                                      {(country_code_list ?? []).map((item) => (
                                        <option key={item?.id} value={item.value}>
                                          {item.text}
                                        </option>
                                      ))}
                                    </>
                                  ) : null}
                                </Form.Control>
                              </Form.Group>
                            </Col>
                            <Col className="col p-0">
                              <Form.Group className="p-0">
                                {/* <Form.Label>Option {index + 1}</Form.Label> */}
                                {/* <span className="asterisk">*</span> */}
                                <Form.Control
                                  placeholder="Phone Number"
                                  type="text"
                                  name="phone_number_options"
                                  value={option?.phone}
                                  // onBlur={(e) => e.target.value.trim() === "" && removeNoteOption(index)}
                                  onChange={(e) => handlePhoneOptions(index, e.target.value, "phone")}
                                  maxLength="10"
                                  autoComplete="off"
                                  onKeyDown={handleEnter}
                                />
                              </Form.Group>
                            </Col>
                            <Col className="col-auto">
                              <Button className="h-40px" size="sm" onClick={() => removePhoneOption(index)}>
                                <i className="fas fa-minus"></i>
                              </Button>
                            </Col>
                          </Row>
                        );
                      })}
                  </>
                )}

                <Row>
                  <Col className="pt-2" md={6} xs={6}>
                    <Button disabled={!isFormValid} onClick={handleSubmit}>
                      Create
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col></Col>
        </Row>
        {show_alert && <CustomContentAlert delay={4000} message={Api_success_message} className="toast-success" />}
        {show_error && <CustomContentAlert delay={4000} message={Api_error_message} className="toast-error" />}
      </Container>
    </div>
  );
}

export default QuickPermitForm;

const type = {
  API_RESPONSE_SUCCESS: "API_RESPONSE_SUCCESS",
  API_RESPONSE_FAILURE: "API_RESPONSE_FAILURE",
  API_RESPONSE_RESET: "API_RESPONSE_RESET",
  API_RESPONSE_FAILURE_ATTEMPTS: "API_RESPONSE_FAILURE_ATTEMPTS",
  API_RESPONSE_FAILURE_ATTEMPTS_MINUTES:
    "API_RESPONSE_FAILURE_ATTEMPTS_MINUTES",
  API_RESPONSE_FAILURE_ATTEMPTS_SECONDS:
    "API_RESPONSE_FAILURE_ATTEMPTS_SECONDS",
};

export default type;

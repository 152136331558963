import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Card, Container, Dropdown, Tooltip, OverlayTrigger, Form, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { useSelector, useDispatch } from "react-redux";
import { ReportsAction, SuperAdminAction } from "../../Actions";
// import { saveAs } from "file-saver";
import CustomContentAlert, { tableHeightScrollBars, findBySearch, scroolToTop, utcToSpecificTimeZone } from "../helper";
import moment from "moment";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";
import DeleteResidentModal from "../Modal/DeleteResidentModal";

function Rates() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const location_id = queryParams.get("location_id");
  const lot_id = queryParams.get("lot_id");
  const { property_id, timezone, property } = useSelector((userState) => userState.UserProfileDetails);
  const passagepassRateList = useSelector((state) => state.ReportsDetails.passagepassRateList);
  const ratebufferData = useSelector((state) => state.ReportsDetails.ratebufferData);
  const parkingLocation = useSelector((state) => state.ReportsDetails.parkingLocationList);
  const { lots } = useSelector((propertyState) => propertyState.SuperAdminDetails);
  const [RateListData, setRateListData] = useState([]);
  const [searchRateListData, setSearchRateListData] = useState([]);
  const [tableHeight, setTableHeight] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [findMore, setFindMore] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [rateDeleteModel, setRateDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showErrAlert, setShowErrAlert] = useState(false);
  const apiResponse = useSelector(getAPIResponse);
  // const [isDeleteUpdate, setIsdeleteUpdate] = useState(false);

  const [state, setState] = useState({
    lot_id: lot_id || "",
    location_id: location_id || "",
  });

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const handleDelete = (row) => {
    setRateDeleteModel(true);
    setDeleteId(row?.id);
  };

  const handleDeleteRate = () => {
    if (deleteId) {
      dispatch(ReportsAction.DeletePassagePassRate(deleteId)); // Make the API call with the stored ID
      setRateDeleteModel(false); // Close the modal
      setDeleteId(null); // Clear the stored ID
    }
  };

  // const handleActive = (e, row) => {
  //   dispatch(
  //     ReportsAction.createGateScheduler({
  //       type: "updateActive",
  //       active: e.target?.checked,
  //       group_id: row?.group_id,
  //       property_id: property_id,
  //     }),
  //   );
  //   // setTimeout(() => {
  //   //   dispatch(GateSchedulerActions.clearAddUpdateDeleteRes());
  //   // }, 2500);
  // };

  const actionButton = (row) => {
    return (
      <Dropdown disabled={!state.location_id && !state.lot_id}>
        <Dropdown.Toggle variant="Primary" className="btn-xs" id="dropdown-basic">
          Action
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() =>
              history.push({
                pathname: "/edit-parking-rate",
                search: `?location_id=${state.location_id}`,
                state: { ...row, lot_id: state.lot_id },
              })
            }
          >
            Edit Rate
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDelete(row)}>Delete Rate</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const showFields = (name) => {
    return (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{name}</Tooltip>}>
          <div className="text-nowrap text-truncate">{name}</div>
        </OverlayTrigger>
    );
  };

  const showTimeField = (date) => (
    <>
      {date && moment(date).isValid() ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss")}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss")}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const columns = [
    {
      name: "Rate Title",
      //  width: "100px",
      sortable: true,
      cell: (row) => showFields(row.display_name),
      selector: (row) => row.display_name,
    },
    {
      name: "Rate Description",
      //  width: "140px",
      sortable: true,
      cell: (row) => showFields(row.rate_description),
      selector: (row) => row.rate_description,
    },
    {
      name: "Max Amount",
      // width: "120px",
      sortable: true,
      cell: (row) => showFields(row.max_amount),
      selector: (row) => row.max_amount,
    },
    {
      name: "Effective Start",
      //  width: "130px",
      sortable: true,
      cell: (row) => showTimeField(row.formatted_effective_start),
      selector: (row) => row.formatted_effective_start,
    },
    {
      name: "Effective Stop",
      ///  width: "130px",
      sortable: true,
      cell: (row) => showTimeField(row.formatted_effective_stop),
      selector: (row) => row.formatted_effective_stop,
    },
    {
      name: "Action",
      //  width: "100px",
      cell: actionButton, // Define your actionButton component or function
    },
  ];

  const dataTableHandle = (data) => {
    return (
      <DataTable
        data={searchValue?.length ? searchRateListData : RateListData}
        columns={columns}
        customStyles={customStyles}
        // expandableRows
        //  expandableRowsComponent={expandedComponent}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={tableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={RateListData?.length}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  const handleSearchChange = async ({ target }) => {
    setSearchValue(target?.value);
    const columnList = ["display_name", "rate_description", "max_amount"];
    if (target.value.length) {
      if (!findMore) {
        setSearchRateListData(findBySearch(target.value, RateListData, RateListData, columnList, timezone));
      }
    }
  };

  useEffect(() => {
    console.log("setShowErrAlert::::::", apiResponse);
    setShowAlert(apiResponse?.api_response === true);
    setShowErrAlert(apiResponse?.api_response === false);
    setTimeout(() => {
      setShowAlert(false);
      setShowErrAlert(false);
    }, 3000);
  }, [apiResponse]);

  // const handleExport = async () => {
  //   const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
  //   const exportDataName = `RATE-${currentTimeDate}-${property?.domain}.xls`;
  //   const bufferData = dispatch(
  //     ReportsAction.GetPassagePassRate({
  //       exports: true,
  //       location_id: location_id,
  //     }),
  //   );
  //   if (bufferData) {
  //     saveAs(
  //       new Blob([await bufferData], {
  //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       }),
  //       exportDataName,
  //     );
  //   }
  // };

  useEffect(() => {
    setFindMore(false);
    const height = tableHeightScrollBars();
    dispatch(SuperAdminAction.setDomainHeader(property?.property_name, property?.domain));
    dispatch(SuperAdminAction.get_all_lots(property_id, "", false, true));
    setTableHeight(height);
  }, [property]);

  useEffect(() => {
    if (location_id && lot_id && parkingLocation.length) {
      setTimeout(() => {
        dispatch(
          ReportsAction.GetPassagePassRate({
            exports: false,
            property_id: property_id,
            location_id: state.location_id,
          }),
        );
      }, 2500);
      history.push({
        pathname: "/parking-rate",
        search: ``,
      });
      setState((prevState) => ({
        ...prevState,
        loader: true,
      }));
    }
  }, [location_id, lot_id, parkingLocation]);

  useEffect(() => {
    if (passagepassRateList.success !== null) {
      if (passagepassRateList && state.lot_id && state.location_id) {
        setRateListData(passagepassRateList?.data);
      }
      setState((prevState) => ({
        ...prevState,
        loader: false,
      }));
    }
  }, [passagepassRateList]);

  const handleSearch = useCallback(() => {
    console.log("handleSearch: state.location && state.lot_id:::::::", state.location, state.lot_id);
    if (state.location_id && state.lot_id) {
      dispatch(
        ReportsAction.GetPassagePassRate({
          exports: false,
          property_id: property_id,
          location_id: state.location_id,
        }),
      );
      setState((prevState) => ({
        ...prevState,
        loader: true,
      }));
    }
  }, [state.location_id, state.lot_id, dispatch]);

  const handleReset = () => {
    setState((prevState) => ({
      ...prevState,
      lot_id: "",
      location_id: "",
    }));
    history.push({
      pathname: "/parking-rate",
      search: ``,
    });
    setRateListData([]);
  };

  const handleLot = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (value === "") {
      setRateListData([]);
    }
    if (name === "lot_id") {
      setState((prevState) => ({
        ...prevState,
        location_id: "",
      }));
      setRateListData([]);
    }
    history.push({
      pathname: "/parking-rate",
      search: ``,
    });
  };

  useEffect(() => {
    if (state.lot_id || lot_id) {
      console.log("lot_id:::::::::", lot_id);
      dispatch(ReportsAction.GetAllParkingLocation(state.lot_id));
    }
  }, [state.lot_id, lot_id]);

  console.log("state.location_id && !state.lot_id:", state.location_id, state.lot_id);
  return (
    <div>
      <Container fluid className="manage-unit-list-page page-no-fliter">
        <Card className="mb-0">
          <Card.Body className="pos-rel-overflow-hide">
            <>
              <header>
                <div className="active-permit-header sm-flex-column sm-align-items-start">
                  <h5 className="mb-2 mb-md-0">Parking Rate</h5>
                  <div className="filter-button d-flex flex-wrap">
                    <div className="d-flex align-items-center search-mb-0">
                      <DatatableHeader className="mt-0 mb-0" searchValue={searchValue} HandleChange={handleSearchChange} />
                    </div>
                    <div className="buttons-dd">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="Primary"
                          id="dropdown-basic"
                          className="btn btn-sm border-0 bg-light  w-30px h-30px d-flex align-items-center justify-content-center ml-2"
                        >
                          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            disabled={!state.lot_id || !state.location_id}
                            className="text-sm"
                            onClick={() =>
                              history.push({
                                pathname: "/add-parking-rate",
                                search: `?location_id=${state.location_id}`,
                                state: { lot_id: state.lot_id, location_id: state.location_id },
                              })
                            }
                          >
                            <span className="pl-2">Add Rate</span>
                          </Dropdown.Item>
                          {/* <Dropdown.Item className="text-sm" onClick={handleExport} disabled={RateListData?.length > 0 ? 0 : 1}>
                            <span className="pl-2"> Export</span>
                          </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </header>
            </>
            <>
              <Row className="align-items-end mt-0">
                <Col md="4">
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Lot Name</Form.Label>
                    <Form.Control
                      as="select"
                      size="sm"
                      className="px-2 py-1"
                      name="lot_id"
                      value={state.lot_id}
                      onChange={handleLot}
                      placeholder="Select Lot Name"
                    >
                      <option value="">--Select Lot--</option>
                      {(lots ?? []).map((i) => (
                        <option key={i.id} value={i.id}>
                          {i?.alias}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      as="select"
                      size="sm"
                      className="px-2 py-1"
                      name="location_id"
                      value={state.location_id}
                      onChange={handleLot}
                      placeholder="Select Location Name"
                    >
                      <option value="">--Select Location--</option>
                      {(parkingLocation ?? []).map((i) => (
                        <option key={i.id} value={i.id}>
                          {i?.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Button
                    className="btn-fill btn-sm mb-3"
                    type="Submit"
                    variant="primary"
                    onClick={handleSearch}
                    disabled={!state.lot_id || !state.location_id}
                  >
                    Find
                  </Button>
                  <Button className="btn-fill btn-sm mb-3 ml-2" type="Submit" variant="primary" onClick={handleReset}>
                    Reset
                  </Button>
                </Col>
              </Row>
              {state.loader ? (
                <Loader />
              ) : (
                <>
                  {RateListData && (
                    <>
                      <div className="citation-list-table">{dataTableHandle(RateListData)}</div>
                    </>
                  )}
                </>
              )}
            </>
            {rateDeleteModel && (
              <DeleteResidentModal
                showBrowserModal={rateDeleteModel}
                onOpen={rateDeleteModel}
                handleSave={handleDeleteRate}
                closeModal={() => setRateDeleteModel(false)}
                handleCancel={() => setRateDeleteModel(false)}
                details={{
                  title: "Parking Rate",
                  message: "Are you sure you want to delete this parking rate?",
                }}
                //  isDeleteCarddisabled={isDeleteCard}
                loading={false}
              />
            )}
          </Card.Body>
        </Card>
        <>
          {showAlert && apiResponse?.api_success_message && (
            <CustomContentAlert delay={2000} message={apiResponse?.api_success_message} className="toast-success" />
          )}
          {showErrAlert && apiResponse?.api_error_message && (
            <CustomContentAlert delay={2000} message={apiResponse?.api_error_message} className="toast-error" />
          )}
        </>
      </Container>
    </div>
  );
}

export default Rates;

import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import { PdfDownload } from "../../Actions/ReportsAction/action";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

const InvoiceReport = (props) => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(location?.search?.split("?")[1]);
  const UnitId = urlParams.get("unit_id");
  const date = urlParams.get("month_year");
  const property_id = urlParams.get("property_id");
  const apiResponse = useSelector(getAPIResponse);
  const UserData = useSelector((userState) => userState.UserProfileDetails);
  const exportPdfList = useSelector((exportState) => exportState.ReportsDetails.invoiceExportPdfList);
  const [pdfUrl, setPdfUrl] = useState({});
  const [apiCalled, setApiCalled] = useState(false);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    const passData = { unit_id: UnitId, date: date, property_id: property_id };
    passData.type = "pdf";
    passData.mail_sent = false;
    passData.timezone = UserData.timezone;
    dispatch(PdfDownload(passData));
    setApiCalled(true);
    const device = getMobileOperatingSystem();
    console.log("device", device);
  }, []);

  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  };

  useEffect(() => {
    if (apiCalled && apiResponse) {
      if (exportPdfList) {
        try {
          const blobObject = new Blob([exportPdfList], { type: "application/pdf" });
          const blobUrl = URL.createObjectURL(blobObject);
          setPdfUrl(blobUrl);
        } catch (error) {
          console.error("Error creating object URL:", error);
        }
      }
      setApiCalled(false);
    }
  }, [exportPdfList]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Card className="mb-0">
        <Card.Body className="pos-rel-overflow-hide">
          <></>
          {apiCalled ? (
            <Loader />
          ) : (
            <div>
              {pdfUrl && !isMobile && (
                <>
                  <EmbedIframe className={"pdf-frame"} src={pdfUrl} rotate={180} />
                </>
              )}
              {pdfUrl && isMobile && (
                <>
                  <div>
                    <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                      ))}
                    </Document>
                  </div>
                </>
              )}
            </div>
          )}{" "}
        </Card.Body>
      </Card>
    </div>
  );
};

export default InvoiceReport;

const EmbedIframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 200px);
  rotate: degrees(-45);
`;

import React, { Component } from "react";
import { connect } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import styled from "styled-components";
import { Translation } from "react-i18next";
import _i18n from "./Language/i18n";
import Tooltip from "react-bootstrap/Tooltip";
import Versions from "../Components/WorldStreamVersions/Version.json";

export class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerLogo_: "",
      footer_text: "",
      currentYear: new Date().getFullYear(),
    };
  }

  redirectToPolicy = async () => {
    const history = require("../history").default;
    _i18n.changeLanguage(this.props.updateLanguage);
    if (this.props.updateLanguage === "") {
      history.push("/privacy-policy");
    } else {
      history.push("/privacy-policy-french");
    }
  };

  componentDidMount = () => {
    const { footerLogo, footerText } = this.props.backgroundColor;
    this.setState({
      footerLogo_: this.props.footer_logo ? this.props.footer_logo : footerLogo,
      footer_text: this.props.footer_text ? this.props.footer_text : footerText,
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.backgroundColor !== this.props.backgroundColor) {
      const { footerText, footerLogo } = this.props.backgroundColor;
      this.setState({
        footerLogo_: footerLogo,
        footer_text: footerText,
      });
    }
  };

  render() {
    return (
      <div className="fixed-bottom">
        <footer className="footer d-flex justify-content-between align-items-center py-2 px-3">
          {this.state.footerLogo_ ? (
            <div className="d-flex align-items-center">
              <img
                style={{ height: 25, width: 25 }}
                src={this.state.footerLogo_}
              />
              {this.state.footer_text && (
                <FooterText
                  dangerouslySetInnerHTML={{
                    __html: this.state.footer_text
                      ? this.state.footer_text
                      : "",
                  }}
                />
              )}
            </div>
          ) : null}
          {!this.state.footer_text && !this.state.footerLogo_ && <div />}
          <div>
            ©<Translation>{(t) => <>{t("COPYRIGHT")}</>}</Translation>
            {this.state.currentYear}{" "}
            <a
              href=" https://locomobiworld.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Translation>{(t) => <>{t("LOCOMOBI.WORLD")}</>}</Translation>{" "}
            </a>{" "}
            -{" "}
            <Translation>{(t) => <>{t("ALL.RIGHTS.RESERVED")}</>}</Translation>
          </div>
          <div className="footer-link">
            <a
              href="https://locomobiworld.com/terms-and-conditions/"
              className="mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Translation>{(t) => <>{t("Terms.conditions")}</>}</Translation>
            </a>
            <span
              onClick={this.redirectToPolicy}
              style={{ cursor: "pointer", color: "#24a5ca" }}
            >
              <Translation>{(t) => <>{t("privacyPolicy")}</>}</Translation>
            </span>

            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id={"tooltip-top"} {...props}>
                  Version: {Versions.Version}
                </Tooltip>
              )}
            >
              <i className="fa fa-snowflake ml-3 snowFlake"></i>
            </OverlayTrigger>
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = ({
  SuperAdminDetails,
  UserProfileDetails,
  TicketDetails,
}) => {
  const { backgroundColor } = SuperAdminDetails;
  const { footer_logo, footer_text } = UserProfileDetails;
  const { updateLanguage } = TicketDetails;
  return {
    footer_logo,
    footer_text,
    backgroundColor,
    updateLanguage,
  };
};

export default connect(mapStateToProps, null)(Footer);
const FooterText = styled.div`
  p {
    margin-bottom: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    white-space: nowrap;
  }
`;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import UnitPermitOptionsModal from "./UnitPermitOptionsModal";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUnitPermitList,
  deleteUnitPermit,
  unSetToasterMessage,
  updateUnitPermitOptions,
  addUnitPermitOptions,
  unsetPermitOptionsList,
} from "../../Actions/PermitOptionsActions/actions";
import CustomContentAlert from "../helper";
import ConfirmationModal from "../Common/ConfirmationModal";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Loader from "../Loader/Loader";
import useGetAllProperty from "../../Hooks/Property/useGetAllProperty";
import BillTypeField from "./BillTypeField";

const UnitPermit = () => {
  const property_id = useSelector((state) => state.UserProfileDetails?.property?.id);

  const { propertyId } = useGetAllProperty(property_id);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    showOptionModal: false,
    unitPermitsList: [],
    showConfirmationModal: false,
    show_alert: false,
    show_error: false,
    page: 1,
    noRow: 100,
    count: 0,
    loader: true,
  });

  const unitOptionsListData = useSelector((state) => state.PermitOptionsDetails.unitOptionsList);

  const { api_response, api_error_message, api_success_message } = useSelector((state) => state.APIResponseDetails);

  useEffect(() => {
    if (api_response) {
      setFormData((prevState) => ({
        ...prevState,
        show_alert: true,
      }));
      setTimeout(() => {
        setFormData((prevState) => ({
          ...prevState,
          show_alert: false,
        }));
      }, 4000);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        show_error: true,
      }));
      setTimeout(() => {
        setFormData((prevState) => ({
          ...prevState,
          show_error: false,
        }));
      }, 4000);
    }
  }, [unitOptionsListData, api_response, api_error_message, api_success_message]);

  useEffect(() => {
    dispatch(getAllUnitPermitList(propertyId));
  }, [formData.showOptionModal]);

  useEffect(() => {
    return () => {
      dispatch(unSetToasterMessage());
      dispatch(unsetPermitOptionsList());
    };
  }, []);

  useEffect(() => {
    if (unitOptionsListData) {
      setFormData((prevState) => ({
        ...prevState,
        unitPermitsList: unitOptionsListData,
        loader: false,
      }));
    }
  }, [unitOptionsListData]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const ActionField = (row) => {
    return (
      <>
        <Button type="button" title="Edit" variant="primary" className="btn-blue-bg btn-xs" onClick={() => showUnitFormModal(row, "edit")}>
          <Edit />
        </Button>
        <Button type="button" title="Delete" variant="primary" className="btn-blue-bg btn-xs" onClick={() => openConfirmationModal(row.id)}>
          <Delete />
        </Button>
      </>
    );
  };

  const showUnits = (row) => (
    <>
      {row?.suite_id ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.suite_id}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.suite_id}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const columns = [
    {
      name: "Units",
      sortable: true,
      minWidth: "160px",
      maxWidth: "350px",
      selector: (row) => row?.suite_id,
      cell: showUnits,
    },
    {
      name: "Bill Type",
      sortable: true,
      minWidth: "160px",
      maxWidth: "350px",
      selector: (row) => row.payment_type,
      cell: BillTypeField,
    },
    {
      name: "Actions",
      width: "100px",
      cell: ActionField,
    },
  ];

  const showUnitFormModal = async (row, type) => {
    if (type === "edit") {
      setFormData((prevState) => ({
        ...prevState,
        showOptionModal: !formData.showOptionModal,
        ifEdit: true,
        selectedRowId: row.id,
        selectedRowData: row,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        showOptionModal: !formData.showOptionModal,
        ifEdit: false,
        selectedRowId: "",
        selectedRowData: "",
      }));
    }
  };

  const dataTableHandle = (list) => {
    return (
      <div className="citation-list-table unit-permit-packages">
        <DataTable
          data={list}
          columns={columns}
          customStyles={customStyles}
          responsive={true}
          defaultSortAsc={true}
          fixedHeader={true}
          pagination={true}
          paginationPerPage={formData.noRow}
          paginationTotalRows={formData.count}
          paginationRowsPerPageOptions={[100, 200, 300, 400]}
        />
      </div>
    );
  };

  const showErrorToaster = () => {
    setFormData((prevState) => ({
      ...prevState,
      show_error: true,
    }));
    setTimeout(() => {
      setFormData((prevState) => ({
        ...prevState,
        show_error: false,
      }));
    }, 4000);
  };
  const showSuccessToaster = () => {
    setFormData((prevState) => ({
      ...prevState,
      show_alert: true,
    }));
    setTimeout(() => {
      setFormData((prevState) => ({
        ...prevState,
        show_alert: false,
      }));
    }, 4000);
  };
  const deleteRow = () => {
    dispatch(deleteUnitPermit(formData.selected_row_id));
    openConfirmationModal();
    if (api_response) {
      showSuccessToaster();
    } else {
      showErrorToaster();
    }
  };

  const openConfirmationModal = (id) => {
    let ID = "";
    if (id) {
      ID = id;
    }
    setFormData((prevState) => ({
      ...prevState,
      showConfirmationModal: !formData.showConfirmationModal,
      selected_row_id: ID,
    }));
  };

  const addUpdateUnitPermitOptions = (data, editId) => {
    if (editId) {
      dispatch(updateUnitPermitOptions(data, editId));
    } else {
      dispatch(addUnitPermitOptions(data));
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="mb-0">
              <Card.Header>
                <div className="d-flex align-items-sm-center align-items-start justify-content-between">
                  <div>
                    <Card.Title as="h4">Unit Permit Packages</Card.Title>
                  </div>

                  <div>
                    <Button onClick={showUnitFormModal} type="button" variant="primary" className="btn-blue-bg btn-sm">
                      Add Unit Permit Packages
                    </Button>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="pos-rel-overflow-hide">
                {!formData.unitPermitsList?.length && !formData.loader && (
                  <div className="text-center d-block unit-permit-table">
                    <div>{`There are no records to display`}</div>
                  </div>
                )}
                {formData.loader ? (
                  <Loader />
                ) : (
                  <>
                    {formData.unitPermitsList.length > 0 && (
                      <div className="">
                        <div className="unit-permit-table">{dataTableHandle(formData.unitPermitsList)}</div>
                      </div>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {formData.showConfirmationModal ? (
        <ConfirmationModal
          onOpen={formData.showConfirmationModal}
          onConfirm={deleteRow}
          message="Are You sure you want to delete permit options for this unit.?  If you will delete this then all active permits and auto renewal will be cancelled and refund will be initiated to the account?"
          onClose={openConfirmationModal}
          loading={false}
          title={"Delete Permit Option for Unit"}
          showSuccessToaster={showSuccessToaster}
          showErrorToaster={showErrorToaster}
        />
      ) : null}
      {formData.show_alert && api_success_message ? <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" /> : null}
      {formData.showOptionModal ? (
        <UnitPermitOptionsModal
          show={formData.showOptionModal}
          closeModal={() => showUnitFormModal()}
          property_id={propertyId}
          ifEdit={formData.ifEdit}
          selectedRowId={formData.selectedRowId}
          selectedRowData={formData.selectedRowData}
          addUpdateUnitPermitOptions={addUpdateUnitPermitOptions}
        />
      ) : null}
      {formData.show_error && api_error_message ? <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" /> : null}
    </>
  );
};

export default UnitPermit;

import t from "../../Actions/TicketActions/types";

const intitialState = {
  reasonList: [],
  viewLastTicket: [],
  reasonSetupDetail: {},
  generateTicketData: {},
  ticketInfo: [],
  ticketList: [],
  paymentStep1Data: "",
  driverMessage: {},
  driverMessageList: [],
  ticketDetails: {},
  updateTicketStatus: [],
  paymentSuccessData: [],
  paymentStatusData: {},
  updateLanguage: "",
  viewLastPlateNumberDetails: {},
  viewVinWarningNumberDetails: [],
  viewVinInformation: {},
  ratePaymentList: [],
  vendControlList: [],
  transactionsList: [],
  permitTransactionsList: [],
  totalCitationList: [],
  paidUnpaidList: [],
  totalStatusList: [],
  enforcerList: [],
  enforcerPaidList: [],
  refundList: [],
  exportList: "",
  sequencedReasons: [],
  offlineCitationList: [],
  getSessionData: [],
};

const ReasonReducer = (state = intitialState, action) => {
  switch (action.type) {
    case t.FETCH_NEW_REASON:
      return {
        ...state,
        reasonList: action.payload.data.reasons,
        reasonSetupDetail: action.payload.data,
      };
    case t.FETCH_VIEW_LAST_TICKET:
      return { ...state, viewLastTicket: action.payload.data };

    case t.FETCH_VIEW_LAST_PLATE_NUMBER:
      return { ...state, viewLastPlateNumberDetails: action.payload.data };
    case t.FETCH_VIN_WARNING_NUMBER:
      return { ...state, viewVinWarningNumberDetails: action.payload.data };
    case t.FETCH_VIN_INFO:
      return { ...state, viewVinInformation: action.payload.data };

    case t.FETCH_GENERATE_TICKET:
      return { ...state, generateTicketData: action.payload.data };
    case t.FETCH_TICKET_INFO: {
      return { ...state, ticketInfo: action.payload.data };
    }
    case t.FETCH_TICKET_LIST: {
      return { ...state, ticketList: action.payload };
    }
    case t.UPDATE_TICKET_LIST: {
      const prevTicketList = state.ticketList;
      const updatedTicketList = prevTicketList?.rows.map((item) => {
        if (item.id === action.payload?.data?.id) {
          item.state = action.payload?.data.state;
          item.status = action.payload?.data.status;
          item.cancellation_note = action.payload?.data.cancellation_note;
        }
        return item;
      });
      return {
        ...state,
        ticketList: {
          ...state,
          rows: JSON.parse(JSON.stringify(updatedTicketList)),
        },
      };
    }
    case t.UPDATE_TICKET_DATA: {
      const prevTicketList = state.ticketList;
      const updatedTicketList = prevTicketList?.rows.map((item) => {
        if (item.id === action.payload.id) {
          item.refunded = "Yes";
          item.refund_amount = action.payload.refund_amount;
        }
        return item;
      });
      return {
        ...state,
        ticketList: {
          ...state,
          rows: JSON.parse(JSON.stringify(updatedTicketList)),
        },
      };
    }
    case t.FETCH_SESSION_DATA: {
      return {
        ...state,
        getSessionData: action.payload,
      };
    }
    case t.PAYMENT_STEP1: {
      return {
        ...state,
        paymentStep1Data: action.payload.elements[0].elements[4].elements[0].text,
      };
    }
    case t.PAYMENT_STEP3: {
      return {
        ...state,
        paymentSuccessData: action.payload.elements[0].elements,
      };
    }
    case t.UPDATE_MESSAGE_LIST: {
      return { ...state, driverMessage: action.payload };
    }
    case t.FETCH_MESSAGE_LIST: {
      return { ...state, driverMessageList: action.payload };
    }
    case t.GET_TICKET_DETAILS: {
      return { ...state, ticketDetails: action.payload };
    }
    case t.UPDATE_STATUS: {
      return { ...state, updateTicketStatus: action.payload };
    }

    case t.SELECT_LANG: {
      const language = localStorage.getItem("language");
      return { ...state, updateLanguage: language };
    }
    case t.FETCH_PAYMENT_STATUS: {
      return {
        ...state,
        paymentStatusData: action.payload.data,
      };
    }
    case t.FETCH_VEND_CONTROL_LIST: {
      return { ...state, vendControlList: action.payload };
    }
    case t.FETCH_RATE_PAYMENT_LIST: {
      return { ...state, ratePaymentList: action.payload };
    }
    case t.FETCH_TRANSACTIONS_LIST: {
      return { ...state, transactionsList: action.payload };
    }
    case t.FETCH_PERMIT_TRANSACTIONS_LIST: {
      return { ...state, permitTransactionsList: action.payload };
    }
    case t.FETCH_TOTAL_CITATIONS_LIST: {
      return { ...state, totalCitationList: action.payload };
    }
    case t.FETCH_TOTAL_PAID_UNPAID_LIST: {
      return { ...state, paidUnpaidList: action.payload };
    }
    case t.FETCH_TOTAL_STATUS_LIST: {
      return { ...state, totalStatusList: action.payload };
    }
    case t.FETCH_TOTAL_ENFOCER_LIST: {
      return { ...state, enforcerList: action.payload };
    }
    case t.FETCH_TOTAL_ENFOCER_PAID_LIST: {
      return { ...state, enforcerPaidList: action.payload };
    }
    case t.FETCH_QR_CODE: {
      return { ...state, qr_code: action.payload };
    }
    case t.UPDATE_TICKET_STATUS: {
      const prevTicketList = JSON.parse(JSON.stringify(state.ticketList));
      const updatedTicketList = prevTicketList?.rows.map((item) => {
        if (item.id === action.payload?.id) {
          item.state = action.payload.state;
          item.status = action.payload.status;
        }
        return item;
      });
      return {
        ...state,
        ticketList: {
          ...state.ticketList,
          rows: JSON.parse(JSON.stringify(updatedTicketList)),
        },
      };
    }
    case t.FETCH_REFUND_LIST: {
      return { ...state, refundList: action.payload };
    }
    case t.FETCH_EXPORT_LIST: {
      return { ...state, exportList: action.payload };
    }
    case t.FETCH_REASON_LIST: {
      return { ...state, sequencedReasons: action.payload };
    }
    case t.FETCH_OFFLINE_CITATION_LIST: {
      return { ...state, offlineCitationList: action.payload };
    }
    default:
      return state;
  }
};

export default ReasonReducer;

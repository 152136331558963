import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Tooltip, OverlayTrigger, Card, Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import db from "./dixie";

const OfflineCitationList = () => {
    const [allTicktes, setAllTickets] = useState([]);

    const columns = useRef([]);

    useEffect(async () => {
        const { tickets } = db;
        const offline_ticktes = await tickets.toArray().then((tickets) => tickets);
        setAllTickets(offline_ticktes);
    }, [])


    const customStyles = useRef({
        rows: {
            style: {
                minHeight: "72px",
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
            },
        },
        cells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
            },
        },
    });

    useEffect(() => {
        const init = async () => {
            columns.current = [
                {
                    name: "LPN",
                    sortable: true,
                    minWidth: "180px",
                    cell: ShowCell,
                    selector: (row) => row.lpn,
                },
                {
                    name: "Make",
                    sortable: true,
                    minWidth: "180px",
                    cell: ShowCell,
                    selector: (row) => row.make,
                },
                {
                    name: "Model",
                    sortable: true,
                    minWidth: "180px",
                    cell: ShowCell,
                    selector: (row) => row.model,
                },
                {
                    name: "Color",
                    sortable: true,
                    minWidth: "180px",
                    cell: ShowCell,
                    selector: (row) => row.color,
                },
                {
                    name: "Print Notes",
                    sortable: true,
                    cell: ShowCell,
                    selector: (row) => row.printNotes,
                },
                {
                    name: "Internal Notes",
                    sortable: true,
                    cell: ShowCell,
                    selector: (row) => row.internalNotes,
                }
            ];
        };
        init();
    }, []);


    const ShowCell = useCallback((row, _, column) => {
        let data = "";
        switch (column.name) {
            case "LPN":
                data = (
                    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.lpn}</Tooltip>}>
                        <div className="text-nowrap text-truncate pr-3">
                            {row?.lpn}
                        </div>
                    </OverlayTrigger>
                );
                break;
            case "Make":
                data = (
                    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.make}</Tooltip>}>
                        <div className="text-nowrap text-truncate pr-3">
                            {row?.make}
                        </div>
                    </OverlayTrigger>
                );
                break;
            case "Model":
                data = (
                    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.model}</Tooltip>}>
                        <div className="text-nowrap text-truncate pr-3">
                            {row?.model}
                        </div>
                    </OverlayTrigger>
                );
                break;
            case "Color":
                data = (
                    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.color}</Tooltip>}>
                        <div className="text-nowrap text-truncate pr-3">
                            {row?.color}
                        </div>
                    </OverlayTrigger>
                );
                break;
            case "Print Notes":
                data =
                    row?.printNotes ? (
                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.printNotes}</Tooltip>}>
                            <div className="text-nowrap text-truncate pr-3">
                                {row.printNotes}
                            </div>
                        </OverlayTrigger>
                    ) : (
                        ""
                    );
                break;
            case "Internal Notes":
                data = (
                    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.internalNotes}</Tooltip>}>
                        <div className="text-nowrap text-truncate pr-3">{row?.internalNotes}</div>
                    </OverlayTrigger>
                );
                break;
            default:
                break;
        }
        return <CellDisplay data={data} />;
    }, []);


    return (
        <Container fluid>
            <Card className="mb-0">
                <Card.Body className="pos-rel-overflow-hide">
                    <DataTable
                        data={allTicktes}
                        columns={columns.current}
                        customStyles={customStyles.current}
                        responsive={true}
                        defaultSortAsc={true}
                        fixedHeader={true}
                        paginationPerPage="5"
                        paginationTotalRows={allTicktes?.length}
                        pagination={true}
                        paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    />
                </Card.Body>

            </Card >
        </Container>
    );
}

export default OfflineCitationList;
const CellDisplay = ({ data }) => {
    return <div>{data}</div>;
};
import React, { useState } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import InvoiceDetailsView from "./InvoiceDetailsView";
import InvoicePDFView from "./InvoicePdfView";

const InvoiceReport = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const detailsData = props.location.state;
  const onTabClick = async (k) => {
    setActiveTab(k);
    console.log("activeTab", activeTab);
  };
  return (
    <>
      <Container fluid>
        <Tabs activeKey={activeTab} id="controlled-tab-example" onSelect={(k) => onTabClick(k)} transition={false}>
          <Tab eventKey="1" title="Invoice Details" className="m-0">
            <InvoiceDetailsView data={detailsData} />
          </Tab>
          <Tab eventKey="2" title="PDF Details" className="m-0">
            <InvoicePDFView data={detailsData} />
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};
export default InvoiceReport;

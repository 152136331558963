import React, { Component } from "react";
import { Card, Row, Col, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import DataTable from "react-data-table-component";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { connect } from "react-redux";
import { SuperAdminAction, ReportsAction } from "../../Actions";
import Loader from "../Loader/Loader";
import { tableHeightScrollBars, findBySearch, utcToSpecificTimeZone, scroolToTop } from "../helper";
import isEqual from "lodash/isEqual";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropertDetails from "../LandingPage/PropertySelection";
import { saveAs } from "file-saver";
import moment from "moment";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";
const today = new Date();
const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
const firstDateOfPreviousMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0, 0);
let getStartDate;
class MerchantValidation extends Component {
  constructor(props) {
    super(props);

    getStartDate = this.props.reportType === "2" ? new Date(datebeforeThirtyDays) : new Date(firstDateOfPreviousMonth);
    this.state = {
      PropertyId: this.props.PropertyId,
      validationList: [],
      loader: false,
      searchValue: "",
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      selectedProperty: "",
      selectedPropertyObj: {},
      all_properties: [],
      childcheckbox: true,
      ticketReportLots: [],
      lot_id: "",
      startDate: getStartDate,
      endDate: new Date(),
      currentSummaryDate: "",
      finalTableHeight: 0,
      daysDifference: 30,
      month: null,
      units: [],
      unit_id: "",
      merchant_id: "",
      startDateTime: "",
      endDateTime: "",
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };
    this.columns = [
      {
        name: "Domain",
        sortable: true,
        width: "120px",
        cell: (row) => this.showDomainField(row?.properties),
      },
      {
        name: "Merchant Id",
        sortable: true,
        width: "200px",
        cell: (row) => this.showMerchantIdField(row),
        selector: (row) => row?.agent_id,
      },
      {
        name: "Barcode No",
        sortable: true,
        width: "160px",
        cell: (row) => this.showBarcodeNoField(row),
        selector: (row) => row.barcode_no,
      },
      {
        name: "LPN",
        sortable: true,
        width: "140px",
        cell: (row) => this.showLPN(row),
        selector: (row) => row.lpn,
      },
      {
        name: "Entry Time",
        width: "200px",
        cell: (row) => this.showCellValue(row.entry_time, "time"),
        selector: (row) => row.entry_time,
      },

      {
        name: "Validation Time",
        width: "160px",
        sortable: true,
        cell: (row) => this.showCellValue(row?.created_at, "time"),
        selector: (row) => row.created_at,
      },
      {
        name: "Amount Validated",
        width: "160px",
        sortable: true,
        cell: (row) => this.showCellValue(row?.discount_amount, "amount"),
        selector: (row) => row.discount_amount,
      },
      {
        name: "Total Paid",
        width: "160px",
        sortable: true,
        cell: (row) => this.showCellValue(row?.paid_amount, "amount"),
        selector: (row) => row.paid_amount,
      },
    ];

    if (this.props.reportType === "3") {
      this.columns = [
        {
          name: "Domain",
          sortable: true,
          width: "120px",
          cell: (row) => this.showDomainField(row),
        },
        {
          name: "Month",
          sortable: true,
          width: "200px",
          cell: (row) => this.showMonthField(row),
          selector: (row) => row?.month,
        },
        {
          name: "Unit",
          sortable: true,
          width: "160px",
          cell: (row) => this.showCellValue(row.Unit, "text"),
          selector: (row) => row.Unit,
        },
        {
          name: "Number Of PDC: #PDC",
          sortable: true,
          width: "140px",
          cell: (row) => this.showNumberOfPdc(row),
          selector: (row) => row.number_of_PDC,
        },
        {
          name: "$Total Actual Charge",
          width: "160px",
          sortable: true,
          cell: (row) => this.showTotalFullRate(row),
          selector: (row) => row.total_full_rate,
        },
        {
          name: "$Total Discount Applied",
          width: "200px",
          sortable: true,
          cell: (row) => this.showTotalDiscountAppliedField(row),
          selector: (row) => row.total_full_discounted,
        },

        {
          name: "$Total Customer Paid ",
          width: "160px",
          sortable: true,
          cell: (row) => this.showCustomerPaidField(row),
          selector: (row) => row.total_full_paid,
        },
        {
          name: "$Total Unit Paid ",
          width: "160px",
          sortable: true,
          cell: (row) => this.showTotalUnitPaidFied(row),
          selector: (row) => row.total_unit_payable_amount,
        },
      ];
    }
  }

  showTotalUnitPaidFied = (row) => (
    <>
      {row.total_unit_payable_amount ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>${Math.max(0, parseFloat(row.total_unit_payable_amount)).toFixed(2)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">${Math.max(0, parseFloat(row.total_unit_payable_amount)).toFixed(2)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showCustomerPaidField = (row) => (
    <>
      {row.total_full_paid ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>${Math.max(0, parseFloat(row.total_full_paid)).toFixed(2)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">${Math.max(0, parseFloat(row.total_full_paid)).toFixed(2)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showTotalDiscountAppliedField = (row) => (
    <>
      {row.total_full_discounted ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>${Math.max(0, parseFloat(row.total_full_discounted)).toFixed(2)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">${Math.max(0, parseFloat(row.total_full_discounted)).toFixed(2)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showTotalFullRate = (row) => (
    <>
      {row.total_full_rate ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>${Math.max(0, parseFloat(row.total_full_rate)).toFixed(2)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">${Math.max(0, parseFloat(row.total_full_rate)).toFixed(2)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showNumberOfPdc = (row) => (
    <>
      {row.number_of_PDC ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.number_of_PDC}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.number_of_PDC}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showMonthField = (row) => (
    <>
      {row.month ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.month}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.month}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showLPN = (row) => {
    return (
      <div className="curserPointer text-nowrap text-truncate" onClick={() => this.handleRedirect(row.lpn)}>
        {row?.lpn ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.lpn}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{row?.lpn}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </div>
    );
  };

  showBarcodeNoField = (row) => (
    <>
      {row.barcode_no ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.barcode_no}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.barcode_no}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showMerchantIdField = (row) => (
    <>
      {row.agent_id ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.agent_id}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.agent_id}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showDomainField = (row) => (
    <>
      {row?.domain ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.domain}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.domain}</div>
        </OverlayTrigger>
      ) : (
        ""
      )}
    </>
  );

  showCellValue = (cellValue, valueType) => {
    switch (valueType) {
      case "text":
        return (
          <>
            {cellValue ? (
              <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{cellValue}</Tooltip>}>
                <div className="text-nowrap text-truncate pr-2">{cellValue}</div>
              </OverlayTrigger>
            ) : (
              "-"
            )}
          </>
        );
      case "amount":
        return (
          <>
            {cellValue ? (
              <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                placement={"top"}
                overlay={<Tooltip id={`tooltip-qw`}>{Math.max(0, parseFloat(cellValue)).toFixed(2)}</Tooltip>}
              >
                <div className="text-nowrap text-truncate pr-2">{Math.max(0, parseFloat(cellValue)).toFixed(2)}</div>
              </OverlayTrigger>
            ) : (
              "-"
            )}
          </>
        );
      case "time":
        return (
          <>
            {cellValue ? (
              <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                placement={"top"}
                overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(this.props.timezone, cellValue, true)}</Tooltip>}
              >
                <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(this.props.timezone, cellValue, true)}</div>
              </OverlayTrigger>
            ) : (
              "-"
            )}
          </>
        );
      default:
        break;
    }
  };

  handleRedirect = (lpn) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/current-occupancy`,
      search: `audit-report&${lpn}`,
    });
  };

  changeProperty(event) {
    this.setState(
      {
        selectedProperty: event.value,
        selectedPropertyObj: event,
        startDate: getStartDate,
        endDate: new Date(),
        month: null,
        currentSummaryDate: null,
        unit_id: "",
      },
      () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === this.state.selectedProperty);
        this.props.selected_Property(selectedPropertyObj);
        this.props.get_all_lots(event.value);
        const current_lot = this.props.lots;
        this.setState({
          ticketReportLots: current_lot,
        });
      },
    );
  }

  handleCheckBox = () => {
    this.setState({ childcheckbox: !this.state.childcheckbox });
  };

  componentDidMount = async () => {
    await this.getTickets();
    await this.props.get_all_properties();
    await this.props.getAgentIdList();
    const { PropertyId } = this.state;
    await this.props.get_all_lots(PropertyId);
    await this.props.get_all_units(PropertyId);
    const current_lot = this.props.lots;
    this.setState(
      {
        all_properties: this.props?.getAllProperties,
        ticketReportLots: current_lot,
        units: this.props.units,
      },
      async () => {
        const selectedPropertyObj = (this.state.all_properties ?? []).find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );

    const exportUrl = process.env.REACT_APP_CONFIG_BASE_URL;
    if (exportUrl) {
      this.setState({
        exportUrl: `${exportUrl}/api/v1/revenue_report/export/${this.state?.property?.id}`,
        exportPdfUrl: `${exportUrl}/api/v1/revenue_report/exportPdf`,
      });
    }
    const height = tableHeightScrollBars();
    this.setState({
      finalTableHeight: height,
    });
  };

  getTickets = async () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, childcheckbox, timezone, startDate, endDate } = this.state;
      const pageobj = {
        PropertyId,
        timezone,
        childcheckbox,
      };
      if (startDate) {
        pageobj.startDate = startDate;
      }
      if (endDate) {
        pageobj.endDate = endDate;
      }
      if (this.props.reportType === "2") {
        await this.props.getReportList(pageobj);
        await this.GetDateRangeWithFormat(pageobj);
        this.setState({
          validationList: this.props?.validationList?.rows?.length ? JSON.parse(JSON.stringify(this.props.validationList.rows)) : [],
        });
      }
      if (this.props.reportType === "3") {
        await this.props.getDiscountSummaryByUnit(pageobj);
        await this.GetDateRangeWithFormat(pageobj);
        this.setState({
          validationList: this.props?.ticketValidationSummary?.rows?.length ? JSON.parse(JSON.stringify(this.props.ticketValidationSummary?.rows)) : [],
        });
      }
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);

      this.setState({ loader: false });
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.validationList, this.props.validationList)) {
      this.setState({
        validationList: JSON.parse(JSON.stringify(this.props.validationList.rows)),
        count: this.props.validationList.count,
      });
    }
    if (!isEqual(prevProps.lots, this.props.lots)) {
      this.setState({
        ticketReportLots: this.props.lots,
      });
    }
  };

  GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj);
    this.setState({
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    });
  };

  dataTableHandle = (validationList) => {
    return (
      <DataTable
        data={this.state.searchValue.length ? this.state.searchedvalidationList : validationList}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleChangeLot = ({ target }) => {
    this.setState({
      [target.name]: target.value,
      month: null,
      startDate: getStartDate,
      endDate: new Date(),
      currentSummaryDate: null,
    });
  };

  handleCurrentDate = (date) => {
    this.setState({
      currentSummaryDate: date,
      month: null,
      startDate: null,
      endDate: null,
    });
  };

  handleMonthYear = (date) => {
    this.setState({
      month: date,
      startDate: null,
      endDate: null,
    });
  };

  handleChangeStartingDates = (date) => {
    const dt = new Date(date);
    const getEndDate = new Date(dt.setMonth(dt.getMonth() + 3));
    let selectEndDate;
    if (getEndDate > new Date()) {
      selectEndDate = new Date();
    } else {
      selectEndDate = getEndDate;
    }
    this.setState({
      startDate: date,
      endDate: selectEndDate,
      month: null,
      currentSummaryDate: null,
    });
  };

  handleChangeEndDates = (date) => {
    this.setState({
      endDate: date,
      month: null,
      currentSummaryDate: null,
    });
  };

  handleSearch = () => {
    this.setState({ loader: true }, async () => {
      const { lot_id, month, childcheckbox, currentSummaryDate, startDate, endDate, selectedPropertyObj, PropertyId, timezone, unit_id, merchant_id } =
        this.state;
      const pageobj = {
        childcheckbox,
        PropertyId,
        timezone,
      };
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }
      if (lot_id) {
        pageobj.lot_id = lot_id;
      }
      if (merchant_id) {
        pageobj.merchant_id = merchant_id;
      }
      if (currentSummaryDate) {
        pageobj.currentSummaryDate = currentSummaryDate;
      }
      if (month) {
        pageobj.month = month;
      }
      if (unit_id) {
        pageobj.unit_id = unit_id;
      }
      if (startDate && endDate) {
        pageobj.startDate = startDate;
        pageobj.endDate = endDate;
      }
      if (this.props.reportType === "2") {
        await this.props.getReportList(pageobj);
        await this.GetDateRangeWithFormat(pageobj);
        this.setState({
          validationList: JSON.parse(JSON.stringify(this.props.validationList.rows)),
          count: this.props.validationList.count,
        });
      }
      if (this.props.reportType === "3") {
        await this.props.getDiscountSummaryByUnit(pageobj);
        await this.GetDateRangeWithFormat(pageobj);
        this.setState({
          validationList: JSON.parse(JSON.stringify(this.props.ticketValidationSummary.rows)),
          count: this.props.ticketValidationSummary.count,
        });
      }
      this.setState({ loader: false });
      document.body.classList.remove("foc-open");
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ searchValue: val }, async () => {
      let columnList = [];
      if (this.props.reportType === "2") {
        columnList = ["merchant_codename", "barcode_no", "lpn", "created_at", "discount_amount", "paid_amount", "agent_id"];
      }
      if (this.props.reportType === "3") {
        columnList = ["Unit", "month", "number_of_PDC", "total_full_discounted", "total_full_paid", "total_full_rate", "total_unit_payable_amount"];
      }
      if (this.state.searchValue.length) {
        this.setState(
          {
            searchedvalidationList: findBySearch(this.state.searchValue, this.state.validationList, this.state.validationList, columnList, this.props.timezone),
          },
          () => {
            this.setState({
              count: this.state.searchedvalidationList.length,
            });
          },
        );
      } else {
        if (this.props.reportType === "2") {
          this.setState({
            count: this.props.validationList.count,
            searchedvalidationList: [],
            validationList: this.props.validationList.rows,
          });
        }
        if (this.props.reportType === "3") {
          this.setState({
            count: this.props.ticketValidationSummary.count,
            searchedvalidationList: [],
            validationList: this.props.ticketValidationSummary.rows,
          });
        }
      }
    });
  };

  resetAll = async () => {
    this.setState({
      month: null,
      startDate: getStartDate,
      endDate: new Date(),
      currentSummaryDate: null,
      selectedPropertyObj: {},
      lot_id: "",
      merchant_id: "",
      childcheckbox: true,
      unit_id: "",
      selectedPropertyObj: {
        label: this.state.selectedDomainName,
        value: this.state.PropertyId,
      },
    });
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleExport = async () => {
    const {
      lot_id,
      childcheckbox,
      month,
      currentSummaryDate,
      startDate,
      endDate,
      selectedPropertyObj,
      PropertyId,
      selectedDomainName,
      timezone,
      unit_id,
      merchant_id,
    } = this.state;
    const pageobj = {
      childcheckbox,
      PropertyId: selectedPropertyObj.value || PropertyId,
      timezone,
      exportData: true,
      ...(lot_id && { lot_id }),
      ...(merchant_id && { merchant_id }),
      ...(currentSummaryDate && { currentSummaryDate }),
      ...(unit_id && { unit_id }),
      ...(month && { month }),
      ...(startDate && endDate && { startDate, endDate }),
    };
    let bufferData = {};
    if (this.props.reportType === "2") {
      bufferData = await this.props.getReportList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
    }
    if (this.props.reportType === "3") {
      bufferData = await this.props.getDiscountSummaryByUnit(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
    }
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const fileNamePrefix = this.props.reportType === "2" ? "MERCHANT-VALIDATION" : "DISCOUNT-COUPON-SUMMARY";
    const exportDataName = `${fileNamePrefix}-${currentTimeDate}-${selectedDomainName}.xls`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  render() {
    const {
      validationList,
      loader,
      childcheckbox,
      ticketReportLots,
      lot_id,
      startDate,
      endDate,
      currentSummaryDate,
      searchValue,
      month,
      unit_id,
      merchant_id,
    } = this.state;

    return (
      <div>
        <Card className="mb-0">
          <Card.Body className="pos-rel-overflow-hide">
            <>
              <div className="sidebarFilter">
                <Card className="contact-container mb-0">
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title as="h4">{this.props.reportType === "2" ? "Merchant Validator" : "Discount Summary"}</Card.Title>
                      <a
                        onClick={this.hideSlider}
                        href="javascript:void(0);"
                        className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <label className="fancy-checkbox">
                            <input
                              type="checkbox"
                              name={"childcheckbox"}
                              checked={childcheckbox}
                              onChange={this.handleCheckBox}
                              className="custom-control-input"
                              id="childcheckbox1"
                            ></input>
                            <span>
                              <i></i>Include Children Sites
                            </span>
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>LOTS:</Form.Label>
                          <Form.Control
                            as="select"
                            name="lot_id"
                            value={lot_id}
                            onChange={this.handleChangeLot}
                            disabled={ticketReportLots?.length < 1}
                            placeholder="Select Lot"
                          >
                            {ticketReportLots?.length ? (
                              <>
                                <option value="">Any</option>
                                {ticketReportLots.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.alias}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>Merchant Id:</Form.Label>
                          <Form.Control
                            as="select"
                            name="merchant_id"
                            value={merchant_id}
                            onChange={this.handleChangeLot}
                            disabled={this.props.merchant_agentId_List?.length < 1}
                            placeholder="Select Merchant Id"
                          >
                            {this.props.merchant_agentId_List?.length ? (
                              <>
                                <option value="">Any</option>
                                {this.props.merchant_agentId_List.map((item, index) => (
                                  <option key={item.id} value={item.agent_id}>
                                    {item.agent_id}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      {this.props.reportType === "2" && (
                        <Col md="12">
                          <Form.Group>
                            <label className="mb-0 mr-2">Specific Date:</label>
                            <DatePicker
                              className="form-control"
                              placeholderText="Select Date"
                              selected={currentSummaryDate}
                              value={currentSummaryDate}
                              disabled={loader}
                              selectsStart
                              timeInputLabel="Time:"
                              onChange={this.handleCurrentDate}
                              dateFormat="MM/dd/yyyy"
                            />
                          </Form.Group>
                        </Col>
                      )}
                      {this.props.reportType !== "2" && (
                        <>
                          <Col>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>Unit:</Form.Label>
                              <Form.Control
                                as="select"
                                name="unit_id"
                                value={unit_id}
                                onChange={this.handleChangeLot}
                                //  disabled={this.state?.units.length < 1}
                                placeholder="Select Unit"
                              >
                                {this.state?.units && this.state?.units.length ? (
                                  <>
                                    <option value="">Any</option>
                                    {this.state?.units.map((item, index) => (
                                      <option key={index} value={item.id}>
                                        {item.suite_id}
                                      </option>
                                    ))}
                                  </>
                                ) : null}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="12">
                            <Form.Group>
                              <label className="mb-0 mr-2">Month/Year:</label>
                              <DatePicker
                                className="form-control"
                                placeholderText="Select Date"
                                selected={month}
                                value={month}
                                disabled={loader}
                                selectsStart
                                timeInputLabel="Time:"
                                onChange={this.handleMonthYear}
                                showMonthYearPicker
                                dateFormat="MM/yyyy"
                              />
                            </Form.Group>
                          </Col>
                        </>
                      )}
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Start Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Start Date"
                            selected={startDate}
                            defaultHour={24}
                            value={startDate}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleChangeStartingDates}
                            dateFormat="MM/dd/yyyy"
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">End Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select End Date"
                            selected={endDate}
                            disabled={loader}
                            value={endDate}
                            selectsEnd
                            timeInputLabel="Time:"
                            onChange={this.handleChangeEndDates}
                            dateFormat="MM/dd/yyyy"
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-right justify-content-between d-flex">
                    <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                      Reset
                    </Button>
                    <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                      Search
                    </Button>
                  </Card.Footer>
                </Card>
              </div>
              <header>
                <div className="active-permit-header sm-flex-column sm-align-items-start">
                  <h5 className="mb-2 mb-md-0">{this.props.reportName}</h5>
                  <div className="filter-button d-flex flex-wrap">
                    <div className="d-flex align-items-center search-mb-0">
                      <DatatableHeader
                        className="mt-0 mb-0"
                        searchValue={searchValue}
                        HandleChange={this.handleSearchChange}
                        // findMorePressed={this.findMorePressed}
                      />
                    </div>
                    <div className="ml-2">
                      <Button variant="info" className="btn-fill btn-sm" onClick={this.handleExport} disabled={validationList.length > 0 ? 0 : 1} size="medium">
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </header>
            </>
            {loader ? (
              <Loader />
            ) : (
              <>
                {validationList && <div className="citation-list-table has-parent-tab">{this.dataTableHandle(validationList)}</div>}
                <DateRangeAndAmount startDateTime={this.state.startDateTime} endDateTime={this.state.endDateTime} />
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails, SuperAdminDetails, CurrentPermitDetails, ReportsDetails }) => {
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;

  const { backgroundColor } = Logindetails;
  const { residentList } = CurrentPermitDetails;
  const { getAllProperties, lots, getAllUserlist: units } = SuperAdminDetails;
  const { MainBackground: main_body } = backgroundColor;
  const { ticketValidationList: validationList, ticketValidationSummary, merchant_agentId_List } = ReportsDetails;
  return {
    PropertyId,
    timezone,
    property,
    validationList,
    main_body,
    getAllProperties,
    residentList,
    lots,
    ticketValidationSummary,
    units,
    merchant_agentId_List,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  get_all_lots: SuperAdminAction.get_all_lots,
  get_all_units: SuperAdminAction.get_all_units,
  getReportList: ReportsAction.getValidationList,
  getAgentIdList: ReportsAction.getAgentIds,
  getDiscountSummaryByUnit: ReportsAction.getDiscountSummaryByUnit,
};

export default connect(mapStateToProps, mapStateToDispatch)(MerchantValidation);

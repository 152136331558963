import React, { Component } from "react";
import { Button, Card, Form, Container, Row, Col, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { UserProfileActions, SuperAdminAction } from "../../Actions";
import CustomContentAlert, { regExForPhone, isEmailValid, validations } from "../helper";
import isEqual from "lodash/isEqual";
import ChangeProfile from "./ChangeProfile";
import UpdateCreditCard from "./UpdateCreditCard";
import { Translation } from "react-i18next";
import _i18n from "../Language/i18n";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      new_first_name: "",
      last_name: "",
      email: "",
      phone: "",
      suiteId: "",
      username_alias: "",
      user_type: "",
      admin_login: false,
      loader: false,
      newPassword: "",
      newPasswordLoader: false,
      wrongEmail: false,
      isFormValid: false,
      emptyPass: false,
      property: this.props.property,
      isDisabledForSuperadmin: true,
      api_error_message: "",
      api_success_message: "",
      show_alert: false,
      show_error: false,
      unit_language: this.props?.selectedLang || this.props?.language,
    };
  }

  componentDidMount() {
    const userInfo = this.props.UserProfileDetails;
    const data = {
      ...(userInfo && {
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        email: userInfo.email,
        phone: userInfo.phone,
        suiteId: userInfo.user,
        user_type: userInfo.user_type,
        admin_login: userInfo.admin_login,
        username: userInfo.username,
        username_alias: userInfo.username,
      }),
    };
    const selectedPropertyName = this.state.property?.property_name;
    const selectedDomainName = this.state.property?.domain;
    this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    const isDisabledForSuperadmin = !!(userInfo.username === "superadmin" && userInfo.admin_login === true);
    _i18n.changeLanguage(this.state.unit_language);
    this.setState(data);
    this.setState({ isDisabledForSuperadmin: isDisabledForSuperadmin });
  }

  componentDidUpdate = (prevProps) => {
    if (!isEqual(prevProps.UserProfileDetails, this.props.UserProfileDetails)) {
      const userInfo = this.props.UserProfileDetails;
      const data = {
        ...(userInfo && {
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          email: userInfo.email,
          phone: userInfo.phone,
          suiteId: userInfo.user,
          user_type: userInfo.user_type,
          username: userInfo.username,
          username_alias: userInfo.username,
        }),
      };
      this.setState(data);
    }
    if (!isEqual(prevProps.selectedLang, this.props.selectedLang)) {
      this.setState({
        unit_language: this.props.selectedLang,
      });
      _i18n.changeLanguage(this.props.selectedLang);
    }
  };

  handleChange = ({ target }) => {
    const prevState = this.state.phone;
    const userInfo = this.props.UserProfileDetails;
    const data = {
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      email: userInfo.email,
      phone: userInfo.phone,
      username_alias: userInfo.user,
    };
    this.setState({ [target.name]: target.value }, async () => {
      switch (target.name) {
        case "username_alias": {
          const username_alias = validations(target.name, "username_alias", this.state.username_alias);
          this.setState({
            username_alias: username_alias.value,
          });
          break;
        }
        case "first_name": {
          const FirstName = validations(target.name, "first_name", this.state.first_name);
          this.setState({
            first_name: FirstName.value,
          });
          break;
        }
        case "last_name": {
          const LastName = validations(target.name, "last_name", this.state.last_name);
          this.setState({
            last_name: LastName.value,
          });

          break;
        }
        case "email": {
          const Emails = validations(target.name, "email", this.state.email);
          this.setState({
            email: Emails.value,
          });

          break;
        }
        case "phone": {
          const Phone = validations(target.name, "phone", this.state.phone);
          this.setState({
            phone: Phone.value,
          });

          break;
        }
        default:
          break;
      }
      if (this.state.first_name && this.state.last_name && this.state.email && this.state.phone && this.state.username_alias) {
        if (target.name === "phone") {
          if (regExForPhone.test(target.value)) {
            this.setState({ phone: target.value });
          } else {
            this.setState({ phone: prevState });
          }
        }
        if (
          this.state.first_name === data.first_name &&
          this.state.last_name === data.last_name &&
          this.state.email === data.email &&
          this.state.phone === data.phone &&
          this.state.username_alias === data.username_alias &&
          this.state.phone.length
        ) {
          this.setState(
            {
              isFormValid: false,
            },
            this.validateForm(),
          );
        } else {
          this.validateForm();
        }
      } else {
        this.setState({
          isFormValid: false,
        });
      }
    });
  };

  validateForm = () => {
    const { email, phone } = this.state;
    const isEmailValidate = isEmailValid(email);
    this.setState({ wrongEmail: !isEmailValidate }, () => {
      let isValidPhone = true;
      if ((phone && phone.length > 10) || (phone && phone.length < 10)) {
        this.setState({
          phoneErrorMessage: <Translation>{(t) => <>{t("Please enter valid phone number")}</>}</Translation>,
        });
        isValidPhone = false;
      } else {
        this.setState({
          phoneErrorMessage: "",
        });
      }
      if (isEmailValidate && this.state.phone.length && isValidPhone) {
        this.setState({ isFormValid: true });
      } else {
        this.setState({ isFormValid: false });
      }
    });
  };

  handleSubmit = async () => {
    const userInfo = this.props.UserProfileDetails;
    const { first_name, last_name, email, phone, username_alias: username } = this.state;
    const obj = {
      ...userInfo,
      first_name,
      last_name,
      email,
      phone,
      username,
      isProfile: true,
    };
    await this.props.userUpdate(obj);
    if (this.props.api_response) {
      this.setState(
        {
          show_alert: true,
          api_success_message: this.props.api_success_message,
        },
        () => {
          setTimeout(() => {
            this.setState({
              show_alert: false,
            });
          }, 4000);
        },
      );
    } else {
      const userInfo = this.props.UserProfileDetails;
      const data = {
        ...(userInfo && {
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          email: userInfo.email,
          phone: userInfo.phone,
          suiteId: userInfo.user,
          user_type: userInfo.user_type,
          username: userInfo.username,
          username_alias: userInfo.username,
        }),
      };
      this.setState(
        {
          show_error: true,
          api_error_message: this.props.api_error_message,
          ...data,
        },
        () => {
          setTimeout(() => {
            this.setState({ show_error: false });
          }, 4000);
        },
      );
    }

    this.setState({
      isFormValid: false,
    });
  };

  emailValidation = (email, wrongEmail) => {
    if (email && wrongEmail) {
      return <div className="help-block">Invalid Email</div>;
    } else if (!email) {
      return <div className="help-block">Email is required</div>;
    } else {
      return <div> </div>;
    }
  };

  render() {
    const {
      first_name,
      last_name,
      email,
      phone,
      user_type,
      loader,
      wrongEmail,
      username_alias,
      isDisabledForSuperadmin,
      api_success_message,
      api_error_message,
      show_alert,
      show_error,
    } = this.state;
    return (
      <>
        <Container fluid>
          <Row>
            <Col lg="4" className="order-lg-2">
              <Card className="card-user">
                <div className="card-image">
                  <img alt="..." src={require("../../../assets/img/photo-1431578500526-4d9613015464.jpeg").default}></img>
                </div>
                <Card.Body>
                  <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="..." className="avatar border-gray" src={require("../../../assets/img/default-avatar.png").default}></img>
                      {/* <h3>User Profile</h3> */}
                      <h5 className="title">{` ${first_name} ${last_name}`}</h5>
                    </a>
                    <p className="description d-flex align-items-center justify-content-center">
                      <i className="nc-icon nc-circle-09 font-weight-bold mr-1" />
                      {user_type}
                    </p>
                  </div>
                  <p className="description  d-flex align-items-center justify-content-center">
                    <i className="nc-icon nc-email-85 font-weight-bold mr-1" />
                    {email}
                  </p>
                  <p className="description  d-flex align-items-center justify-content-center">
                    <i className="nc-icon nc-mobile font-weight-bold mr-1" />
                    {phone}
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="8">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <Card.Title as="h4">
                    <Translation>{(t) => <>{t("Edit Profile")}</>}</Translation>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <Form.Group>
                          <label>
                            <Translation>{(t) => <>{t("Username")}</>}</Translation>
                            <span className="asterisk">*</span>
                          </label>
                          <Translation>
                            {(t) => (
                              <Form.Control
                                // defaultValue="Creative Code Inc."
                                disabled={isDisabledForSuperadmin}
                                placeholder={t("Username")}
                                type="text"
                                name="username_alias"
                                value={username_alias}
                                autoComplete="off"
                                onChange={this.handleChange}
                              ></Form.Control>
                            )}
                          </Translation>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <Form.Group>
                          <label>
                            <Translation>{(t) => <>{t("First Name")}</>}</Translation>
                            <span className="asterisk">*</span>
                          </label>
                          <Translation>
                            {(t) => (
                              <Form.Control
                                // defaultValue="Mike"
                                disabled={isDisabledForSuperadmin}
                                placeholder={t("First Name")}
                                type="text"
                                value={first_name}
                                name="first_name"
                                required
                                onChange={this.handleChange}
                                autoComplete="off"
                              ></Form.Control>
                            )}
                          </Translation>

                          {!first_name ? (
                            <div className="help-block">
                              <Translation>{(t) => <>{t("First Name is required")}</>}</Translation>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col className="pl-md-1" md="6">
                        <Form.Group>
                          <label>
                            <Translation>{(t) => <>{t("Last Name")}</>}</Translation>
                            <span className="asterisk">*</span>
                          </label>
                          <Translation>
                            {(t) => (
                              <Form.Control
                                disabled={isDisabledForSuperadmin}
                                placeholder={t("Last Name")}
                                type="text"
                                value={last_name}
                                name="last_name"
                                required
                                onChange={this.handleChange}
                                autoComplete="off"
                              ></Form.Control>
                            )}
                          </Translation>

                          {!last_name ? (
                            <div className="help-block">
                              <Translation>{(t) => <>{t("Last Name is required")}</>}</Translation>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <Form.Group>
                          <label htmlFor="exampleInputEmail1">
                            <Translation>{(t) => <>{t("Email address")}</>}</Translation>
                            {/* <span className="asterisk">*</span> */}
                          </label>
                          <Translation>
                            {(t) => (
                              <Form.Control
                                placeholder={t("Email")}
                                type="email"
                                value={email}
                                name="email"
                                disabled
                                onChange={this.handleChange}
                                autoComplete="off"
                              ></Form.Control>
                            )}
                          </Translation>

                          {this.emailValidation(email, wrongEmail)}
                        </Form.Group>
                      </Col>
                      <Col className="pl-md-1" md="6">
                        <Form.Group>
                          <label>
                            <Translation>{(t) => <>{t("Phone")}</>}</Translation> <span className="asterisk">*</span>
                          </label>
                          <Translation>
                            {(t) => (
                              <Form.Control
                                // defaultValue="123456789"
                                disabled={isDisabledForSuperadmin}
                                placeholder={t("Phone")}
                                type="text"
                                value={phone}
                                name="phone"
                                required
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength={10}
                              ></Form.Control>
                            )}
                          </Translation>

                          {!phone ? (
                            <div className="help-block">
                              <Translation>{(t) => <>{t("PhoneRequired.")}</>}</Translation>
                            </div>
                          ) : (
                            <div> </div>
                          )}
                          <span className="help-block">{this.state.phoneErrorMessage}</span>
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end">
                      {!loader ? (
                        <Button className="btn-fill" type="button" variant="info" onClick={this.handleSubmit} disabled={!this.state.isFormValid}>
                          <Translation>{(t) => <>{t("Update Profile")}</>}</Translation>
                        </Button>
                      ) : (
                        <Spinner animation="border" variant="info" />
                      )}
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <UpdateCreditCard language={this.state.unit_language} />
          <ChangeProfile language={this.state.unit_language} />
        </Container>
        {show_alert && api_success_message && (
          <Translation>{(t) => <CustomContentAlert delay={4000} message={t(api_success_message)} className="toast-success" />}</Translation>
        )}
        {show_error && api_error_message && (
          <Translation>{(t) => <CustomContentAlert delay={4000} message={t(api_error_message)} className="toast-error" />}</Translation>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, Logindetails, CurrentPermitDetails }) => {
  const { api_response, api_success_message, api_error_message } = APIResponseDetails;
  const { property, language } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { updateUnitLanguage: selectedLang } = CurrentPermitDetails;
  return {
    UserProfileDetails,
    api_response,
    api_success_message,
    api_error_message,
    property,
    main_body,
    selectedLang,
    language,
  };
};

const mapStateToDispatch = {
  userUpdate: UserProfileActions.userUpdate,
  resetPassword: UserProfileActions.resetPassword,
  userLogout: UserProfileActions.userLogout,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(UserProfile);

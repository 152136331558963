import t from "../../Actions/LotCountingActions/types";
const initialState = {
  lotcounting_list: {},
};

const LotCounterReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.FETCHLOTCOUNTING: {
      return { ...state, lotcounting_list: action.payload };
    }
    case t.EDITLOTCOUNTING: {
      return {
        ...state,
        lotcounting_list: action.payload,
      };
    }
    case t.CREATELOTCOUNTING: {
      return {
        ...state,
        lotcounting_list: action.payload,
      };
    }
    default:
      return state;
  }
};

export default LotCounterReducer;

import React, { useState } from "react";
import { saveAs } from "file-saver";
import DataTable from "react-data-table-component";
import { Row, Col, Card, Button, Tooltip, OverlayTrigger, Dropdown, Modal } from "react-bootstrap";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as SuperAdminAction from "../../Actions/SuperAdminAction/actions";
import * as ReportsAction from "../../Actions/ReportsAction/action";
import PropertDetails from "../LandingPage/PropertySelection";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import Loader from "../Loader/Loader";
import CustomContentAlert, { tableHeightScrollBars, findBySearch, scroolToTop, utcToSpecificTimeZone } from "../helper";
import UpdateCashoutModal from "./UpdateCashoutModal";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import t from "../../Actions/ReportsAction/types";

const Cashouts = () => {
  const dispatch = useDispatch();
  const cashoutList = useSelector((state) => state.ReportsDetails.cashoutList);
  const exportList = useSelector((exportState) => exportState.ReportsDetails.cashoutExportList);
  const UserData = useSelector((userState) => userState.UserProfileDetails);
  const selectAllProperty = useSelector((propertyState) => propertyState.SuperAdminDetails.getAllProperties);
  const loader = useSelector((loader) => loader.ReportsDetails.loader);

  const apiResponse = useSelector(getAPIResponse);
  const api_success_message = useSelector((state) => state.APIResponseDetails.api_success_message);
  const api_error_message = useSelector((state) => state.APIResponseDetails.api_error_message);

  const [state, setState] = useState({
    selectedProperty: UserData.property.id,
    selectedPropertyObj: {},
    childcheckbox: true,
    timezone: UserData.timezone,
    exportValue: false,
    selectedDomainName: "",
    cashoutListData: [],
    exportButtonDisable: true,
    finalTableHeight: 0,
    searchValue: "",
    cashoutListCount: 0,
  });

  const [apiCalled, setApiCalled] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);
  const [cashoutData, setCashoutData] = useState({});

  React.useEffect(() => {
    const height = tableHeightScrollBars();
    dispatch(SuperAdminAction.get_all_properties());
    setState((prev) => ({
      ...prev,
      finalTableHeight: height,
    }));
  }, []);

  React.useEffect(() => {
    if (state.searchedCashoutList) {
      setState((prev) => ({
        ...prev,
        cashoutListCount: state.searchedCashoutList.length,
      }));
    }
  }, [state.searchedCashoutList]);

  React.useEffect(() => {
    const selectedProperty = selectAllProperty.length && selectAllProperty.find((item) => item.id === state.selectedProperty);
    const selectedPropertyName = UserData.property?.property_name;
    const selectedDomainName = UserData.property?.domain;
    if (selectedProperty) {
      setState((prev) => ({
        ...prev,
        selectedPropertyObj: {
          label: selectedProperty.site_name,
          value: selectedProperty.id,
        },
        selectedDomainName: selectedDomainName,
      }));
    }
    dispatch(SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName));
  }, [selectAllProperty]);

  React.useEffect(() => {
    const body = {
      property_id: state.selectedProperty,
      childcheckbox: state.childcheckbox,
      timezone: state.timezone,
      exportValue: state.exportValue,
      type: "cashout_amount",
    };
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    dispatch(ReportsAction.cashoutList(body));
    setApiCalled(true);
  }, []);

  React.useEffect(() => {
    if (cashoutList && apiCalled === true) {
      setState((prev) => ({
        ...prev,
        cashoutListData: cashoutList.rows,
        cashoutListCount: cashoutList.count,
      }));
    }
  }, [cashoutList, apiCalled]);

  React.useEffect(() => {
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `CASHOUTS-${currentTimeDate}-${state.selectedDomainName}.xls`;
    if (apiCalled === true && state.exportValue) {
      setApiCalled(false);
      saveAs(
        new Blob([exportList], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  }, [exportList]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const showCashoutByField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.username}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row.username}</div>
      </OverlayTrigger>
    </>
  );

  const showCashFloatField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.cash_float}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row.cash_float}</div>
      </OverlayTrigger>
    </>
  );

  const showAuditedByField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.audited_username}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row.audited_username}</div>
      </OverlayTrigger>
    </>
  );

  const showAuditedCashoutField = (row) => (
    <>
      {row?.last_audited_cashout ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.last_audited_cashout}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.last_audited_cashout}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showCalculatedCashoutField = (row) => (
    <>
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement={"top"}
        overlay={<Tooltip id={`tooltip-qw`}>${parseFloat(row.last_calculated_cashout || 0).toFixed(2)}</Tooltip>}
      >
        <div className="text-nowrap text-truncate pr-2">${parseFloat(row.last_calculated_cashout || 0).toFixed(2)}</div>
      </OverlayTrigger>
    </>
  );

  const showCollectedTimeField = (row) => (
    <>
      {row?.last_collected_time ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(state.timezone, row.last_collected_time, true)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(state.timezone, row.last_collected_time, true)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showCashDeltaField = (row) => (
    <>
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement={"top"}
        overlay={<Tooltip id={`tooltip-qw`}>${parseFloat(row.cash_delta).toFixed(2)}</Tooltip>}
      >
        <div className="text-nowrap text-truncate pr-2">${parseFloat(row.cash_delta).toFixed(2)}</div>
      </OverlayTrigger>
    </>
  );

  const showAgentId = (row) => (
    <>
      {row?.agent_id ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.agent_id}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.agent_id}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showDomainField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.domain || "-"}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.domain || "-"}</div>
      </OverlayTrigger>
    </>
  );

  const showDeviceName = (row) => {
    return (
      <>
        {row?.device_name ? (
          <>
            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.device_name}</Tooltip>}>
              <div className="text-nowrap text-truncate">{row?.device_name}</div>
            </OverlayTrigger>
          </>
        ) : (
          "-"
        )}
      </>
    );
  };

  const columns = [
    {
      name: "Domain",
      width: "120px",
      sortable: true,
      cell: (row) => showDomainField(row),
      selector: (row) => row?.domain,
    },
    {
      name: "Agent Id",
      width: "150px",
      sortable: true,
      cell: (row) => showAgentId(row),
      selector: (row) => row.agent_id,
    },
    {
      name: "Device Label",
      width: "120px",
      sortable: true,
      cell: (row) => showDeviceName(row),
      selector: (row) => row?.device_name,
    },
    {
      name: "Cash Delta",
      width: "120px",
      sortable: true,
      cell: (row) => showCashDeltaField(row),
      selector: (row) => row?.cash_delta,
    },
    {
      name: "Last Collected Time",
      sortable: true,
      width: "160px",
      cell: (row) => showCollectedTimeField(row),
      selector: (row) => row?.last_collected_time,
    },
    {
      name: "Last Calculated Cashout",
      width: "200px",
      sortable: true,
      cell: (row) => showCalculatedCashoutField(row),
      selector: (row) => row?.calculated_cashout,
    },
    {
      name: "Last Audited Cashout",
      width: "200px",
      sortable: true,
      cell: (row) => showAuditedCashoutField(row),
      selector: (row) => row?.audited_cashout,
    },
    {
      name: "Cashout By",
      sortable: true,
      width: "160px",
      cell: (row) => showCashoutByField(row),
      selector: (row) => row.username,
    },
    {
      name: "Audited By",
      sortable: true,
      width: "160px",
      cell: (row) => showAuditedByField(row),
      selector: (row) => row.audited_username,
    },
    {
      name: "Cash Float",
      sortable: true,
      width: "160px",
      cell: (row) => showCashFloatField(row),
      selector: (row) => row.cash_float,
    },
    {
      name: "Action",
      className: "text-center",
      sortable: true,
      cell: (row) => showAction(row),
    },
  ];

  const showAction = (row) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="Primary" id="dropdown-basic" className="btn-xs">
          Action
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item className="text-sm" onClick={() => openModel(row, "")} disabled={!row.cash_delta > 0}>
            Cashout
          </Dropdown.Item>

          <Dropdown.Item className="text-sm" onClick={() => openModel(row, "float")}>
            Set Float
          </Dropdown.Item>
          <Dropdown.Item className="text-sm" onClick={() => openModel(row, "audited_amount")}>
            Set Audited Amount
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const openModel = async (row, type) => {
    setCashoutData(row);
    if (type) {
      const data = {
        ...row,
        type: type,
      };
      setCashoutData(data);
      setShowModel(true);
    } else {
      setConfirmModel(true);
    }
  };

  const dataTableHandle = (data) => {
    return (
      <DataTable
        data={state.searchValue?.length ? state.searchedCashoutList : data}
        columns={columns}
        customStyles={customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={state.cashoutListCount}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  const handleSearchChange = async ({ target }) => {
    const val = target?.value?.toString().trim();

    setState((prev) => ({ ...prev, searchValue: val }));
    const columnList = [
      "device_name",
      "agent_id",
      "domain",
      "calculated_cashout",
      "last_collected_time",
      "cash_float",
      "cashout_by",
      "audited_by",
      "last_audited_cashout",
      "last_calculated_cashout",
      "cash_delta",
    ];
    if (val?.length) {
      if (!state.findMore) {
        setState((prev) => ({
          ...prev,
          searchedCashoutList: findBySearch(val, state.cashoutListData, state.cashoutListData, columnList, state.timezone),
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        count: cashoutList.count,
        searchedCashoutList: [],
        cashoutListData: cashoutList.rows,
      }));
    }
  };

  const handleExport = async () => {
    setState({ ...state, exportValue: true });
    const { selectedProperty, childcheckbox, timezone } = state;
    const pageobj = {
      childcheckbox,
      timezone,
      exportValue: true,
      type: "cashout_amount",
    };
    if (Object.keys(state.selectedPropertyObj).length) {
      pageobj.property_id = state.selectedPropertyObj.value;
    } else {
      pageobj.property_id = selectedProperty;
    }

    dispatch(ReportsAction.cashoutList(pageobj));
  };

  const resetAll = async () => {
    setState((prev) => ({
      ...prev,
      selectedProperty: UserData.property.id,
      selectedPropertyObj: {
        label: UserData.property.property_name,
        value: UserData.property.id,
      },
      childcheckbox: true,
    }));
    const body = {
      property_id: UserData.property.id,
      childcheckbox: true,
      timezone: state.timezone,
      exportValue: false,
      type: "cashout_amount",
    };
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    dispatch(ReportsAction.cashoutList(body));
    document.body.classList.remove("foc-open");
  };

  const handleSearch = () => {
    setState((prev) => ({ ...prev, loader: true }));
    const { childcheckbox, selectedPropertyObj, selectedProperty, timezone } = state;
    const pageobj = {
      childcheckbox,
      timezone,
      type: "cashout_amount",
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.property_id = selectedPropertyObj.value;
    } else {
      pageobj.property_id = selectedProperty;
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    dispatch(ReportsAction.cashoutList(pageobj));
    document.body.classList.remove("foc-open");
  };

  const hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  const handleCheckBox = () => {
    setState((prev) => ({
      ...prev,
      childcheckbox: !state.childcheckbox,
    }));
  };

  const changeProperty = (event) => {
    setState((prev) => ({
      ...prev,
      selectedProperty: event.value,
      selectedPropertyObj: event,
    }));
  };

  const closeModal = () => {
    setConfirmModel(false);
  };

  const save = () => {
    const body = {
      ...cashoutData,
      action: "insert",
    };
    dispatch(ReportsAction.createUpdateCashout(body));
    setConfirmModel(false);
  };

  return (
    <div>
      <Card className="mb-0">
        <Card.Body className="pos-rel-overflow-hide">
          <>
            <div className="sidebarFilter">
              <Card className="contact-container mb-0">
                <Card.Header // style={{ backgroundColor: this.props.main_body }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <Card.Title as="h4">Cashouts</Card.Title>
                    <a
                      onClick={hideSlider}
                      href="javascript:void(0);"
                      className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md="12">
                      <div className="mb-2">
                        <label className="fancy-checkbox">
                          <input
                            type="checkbox"
                            name={"childcheckbox"}
                            checked={state.childcheckbox}
                            onChange={handleCheckBox}
                            className="custom-control-input"
                            id="childcheckbox1"
                          ></input>
                          <span>
                            <i></i>Include Children Sites
                          </span>
                        </label>
                      </div>
                    </Col>
                    <Col md="12">
                      <PropertDetails selectedPropertyObj={state.selectedPropertyObj} changeProperty={changeProperty.bind(this)} />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-right justify-content-between d-flex">
                  <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={resetAll} disabled={loader}>
                    Reset
                  </Button>
                  <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={handleSearch} disabled={loader}>
                    Search
                  </Button>
                </Card.Footer>
              </Card>
            </div>
            <header>
              <div className="active-permit-header sm-flex-column sm-align-items-start">
                <h5 className="mb-2 mb-md-0">Cashouts</h5>
                <div className="filter-button d-flex flex-wrap">
                  <div className="d-flex align-items-center search-mb-0">
                    <DatatableHeader className="mt-0 mb-0" searchValue={state.searchValue} HandleChange={handleSearchChange} />
                  </div>
                  <div className="ml-2">
                    <Button
                      variant="info"
                      className="btn-fill btn-sm"
                      onClick={handleExport}
                      disabled={state.cashoutListData?.length > 0 ? 0 : 1}
                      size="medium"
                    >
                      Export
                    </Button>
                  </div>
                </div>
              </div>
            </header>
          </>
          {loader ? (
            <Loader />
          ) : (
            <>
              {state.cashoutListData && (
                <>
                  <div className="citation-list-table has-parent-tab">{dataTableHandle(state.cashoutListData)}</div>
                </>
              )}
            </>
          )}
        </Card.Body>
        {showModel && <UpdateCashoutModal showModal={showModel} closeModal={() => setShowModel(false)} rowData={cashoutData} />}
        {api_success_message && apiResponse && <CustomContentAlert delay={4000} message={api_success_message} className={"toast-success"} />}
        {api_error_message && apiResponse && <CustomContentAlert delay={4000} message={api_error_message} className={"toast-success"} />}
      </Card>
      <Modal lg={"medium"} show={confirmModel} onHide={closeModal}>
        <Modal.Header className="no-border">
          <h4 className="m-0 font-weight-bold">Cashouts</h4>
          <Button title={"close"} type="button" onClick={closeModal} className="close-btn" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="modal-data-body">
          <p className="theme-color">Are you sure want to cashouts?</p>
          <p className="theme-color">${parseFloat(cashoutData.cash_delta || 0).toFixed(2)} will be logged</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} type="button" className="btn btn-danger btn-cancel">
            Cancel
          </Button>
          <Button style={{ minWidth: "93px" }} onClick={save} type="button" className="btn btn-fill btn-info">
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Cashouts;

import apiType from "../APIResponseActions/type";
import { AuthAPI } from "../../../Auth/requestHandler";
import t from "./types";

export const addMeterDevice = (meterDeviceConfig) => async (dispatch) => {
  try {
    const url = meterDeviceConfig?.original_id ? `meter_device/${meterDeviceConfig.original_id}` : "meter_device";
    try {
      try {
        const response = await AuthAPI("domain", url, !meterDeviceConfig.original_id ? "POST" : "PATCH", meterDeviceConfig);
        console.log("response on meterDeice add/edit::::::::", response);
        if (response.success === 1) {
          dispatch({
            type: t.SET_METER_DEVICE_SUCCESS,
            payload: meterDeviceConfig.id ? "Meter device updated Successfully" : "Meter device added Successfully",
          });
          dispatch({
            type: t.CLOSE_OPEN_MODEL,
            payload: false,
          });
          dispatch({
            type: t.SET_METER_DEVICE_DATA,
            payload: {},
          });
          dispatch({
            type: t.SET_METER_CREATE_FLAG,
            payload: false,
          });
        } else {
          dispatch({
            type: t.SET_METER_DEVICE_ERROR,
            payload: response.message,
          });
        }
        await dispatch(fetchAllMeterDevices());
      } catch (e) {
        dispatch({
          type: t.SET_METER_DEVICE_ERROR,
          payload: "An error occured while adding Edge device.",
        });
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: false,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
    console.log("ERROR!", error);
  }
};

export const fetchAllServerId = (property_id) => async (dispatch) => {
  try {
    const url = `meter_device/find_all_server_id/${property_id}`;
    const response = await AuthAPI("domain", url, "GET");
    await dispatch({
      type: t.FETCH_ALL_SERVER_ID,
      payload: response,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
    console.log("ERROR!", error);
  }
};

export const deleteSelectedMeterDevice = (deviceId, type, propertyId) => async (dispatch) => {
  try {
    const url = `meter_device/${deviceId}?property_id=${propertyId}&type=${type}`;
    await AuthAPI("domain", url, "DELETE");
    await dispatch({
      type: t.SET_METER_DEVICE_SUCCESS,
      payload: "Meter Device successfully deleted.",
    });
    dispatch(fetchAllMeterDevices());
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
    console.log("ERROR!", error);
  }
};

export const fetchAllMeterDevices = () => async (dispatch) => {
  try {
    const propertyId = window.document.location.href.split("?")[1];
    const url = `meter_device/${propertyId}`;
    const response = await AuthAPI("domain", url, "GET");

    await dispatch({
      type: t.GET_ALL_METER_DEVICES,
      payload: response,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
    console.log("ERROR!", error);
  }
};

const Config = {
  // rootScope: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).info[0] : null,
  // basicAuth: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).basicAuth : '',
  // baseURL: "https://partner-10york.locomobiworld.com",
  baseURL: `${process.env.REACT_APP_CONFIG_BASE_URL}/api/v1`,
  // baseURL: "http://localhost:8011/api/v1",
  api: "api",
  reports: "reports",
  // Need to be changed.
  // domain: "partner-10york.locomobiworld.com",
  // domain: "partner-lechoix.locomobi.com",
  domain: `${process.env.REACT_APP_CONFIG_DOMAIN}/api/v1`,
  // domain: "http://localhost:8011/api/v1",
  fixCredits: {
    // Tridel: 9990,
    loco: 20,
  },
  timeCreditMaxLimit: 240, // This value is max limit for time credit field for validation coupon and it is in hours
  maxLimitForNumberOfCouponsToGen: 1000, // This value is max limit for number of coupon to generate for validation coupon
  instructionToBePrintedOnValidationCouponOne: "Step 1: Scan entry ticket",
  instructionToBePrintedOnValidationCouponTwo: "Step 2: Scan this coupon",
  messagePopTime: 4000,
  allowCredits: 9,
  residentVehicleDetail: false,
  visitorVehicleDetail: false,
  globaleCredit: 30,
  availableSpots: 3,
  defaultBrandingValues: {
    menuBackground: "#303b93",
    mainBodyBackground: "#efeff1",
    menuHeader: "#d6d8e9",
    MainBackground: "#ffffff",
  },
  // ...(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).info && JSON.parse(localStorage.getItem('user')).info[0] && {
  //     uid: JSON.parse(localStorage.getItem('user')).info[0].hasOwnProperty('uid') ? JSON.parse(localStorage.getItem('user')).info[0].uid : '-',
  //     LotVisitorsId: JSON.parse(localStorage.getItem('user')).info[0].hasOwnProperty('LotVisitorsId') ? JSON.parse(localStorage.getItem('user')).info[0].LotVisitorsId : '-',
  //     email: JSON.parse(localStorage.getItem('user')).info[0].hasOwnProperty('email') ? JSON.parse(localStorage.getItem('user')).info[0].email : '-',
  //     user_type: JSON.parse(localStorage.getItem('user')).info[0].hasOwnProperty('user_type') ? JSON.parse(localStorage.getItem('user')).info[0].user_type : '-'
  // }),
  // appConfig: get_config(),
  // ...(get_config && get_config[0] && get_config[0][0] && {
  //     companyId: get_config[0][0].CompanyId,
  //     lotId: get_config[0][0].LotVisitorsId,
  //     propertyId: get_config[0][0].PropertyId,
  //     credits: get_config[0][0].Credits,
  //     availableSpots: get_config[0][0].availableSpots,
  //     title: get_config[0][0].title,
  //     supportEmail: get_config[0][0].supportEmail,
  //     supportContact: get_config[0][0].supportContact,
  // })
};

export default Config;

import React from "react";
import { Button, Card, Modal, ModalBody } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

const PermissionCashierModel = ({ modelPermissionOpenClose, handleClose, cashier_permission }) => {
  const dataEntries = Object.entries(cashier_permission ?? []).slice(3);
  return (
    <div>
      <Modal show={modelPermissionOpenClose} backdrop={"static"} onHide={handleClose} dialogClassName="modal-90w" fullscreen={"sm-down"}>
        <Card.Header className="d-flex justify-content-between bg-white align-items-center">
          <h4 className="m-0 font-weight-bold">
            <Card.Title className="m-0">Permission of Pages</Card.Title>
          </h4>
          <Button title={"Close"} type="button" onClick={handleClose} className="close-btn py-0" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove text-danger" />
          </Button>
        </Card.Header>
        {cashier_permission && (
          <PerfectScrollbar style={{ height: "70vh" }}>
            <ModalBody>
              <div class="table-responsive position-relative border-top">
                <table className="table table-bordered fs-14px" style={{ lineHeight: "0.8" }}>
                  <thead className="position-sticky bg-white" style={{ top: "0px", zIndex: "2", boxShadow: "1px 0 1px rgba(0,0,0,0.3)" }}>
                    <tr className="">
                      <th className="fs-15px py-2 font-weight-bold text-dark">Pages</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(dataEntries ?? []).map(([item, value]) => {
                      return (
                        <>
                          <tr key={item}>
                            <td className="py-1">
                              {item
                                .split("_")
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(" ")}
                            </td>
                            <td>{value}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </ModalBody>
          </PerfectScrollbar>
        )}

        <Modal.Footer>
          <Button onClick={handleClose} className="ml-auto">
            {"Close"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default PermissionCashierModel;

import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";

class DeleteResidentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      notes: "",
      isDisableButton: false,
      amount: "",
    };
  }

  handleChange = async (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.status) {
          this.setState({
            isDisableButton: true,
          });
        } else {
          this.setState({
            isDisableButton: false,
          });
        }
      }
    );
  };

  render() {
    const { showBrowserModal, closeModal, details, handleSave, handleCancel } =
      this.props;
    return (
      <Modal lg={"medium"} show={showBrowserModal} onHide={closeModal}>
        <Modal.Header className="no-border">
          <h4 className="m-0 font-weight-bold">{details.title}</h4>
          <Button
            title={"Warninig"}
            type="button"
            onClick={closeModal}
            className="close-btn"
            data-dismiss="modal"
          >
            <i className="nc-icon nc-simple-remove"></i>
          </Button>
        </Modal.Header>

        <Modal.Body className="modal-data-body">
          {/* <p className="theme-color">This API has failed.</p> */}
          <p className="theme-color">{details.message}</p>
          <Form.Label>
            Select Reason <span className="asterisk">*</span>
          </Form.Label>
          <Form.Control
            as="select"
            custom
            value={this.state.status}
            name="status"
            onChange={this.handleChange}
          >
            <option value="">-- Choose Reason --</option>
            <option value="Invalid">Invalid </option>
            <option value="Warning">Warning</option>
            <option value="Testing">Testing</option>
            <option value="Paid Cash">Paid - Cash </option>
            <option value="Paid Interac">Paid - Interac</option>
            <option value="Paid Credit Card Manually">
              Paid- Credit card manually
            </option>
            <option value="Paid Check">Paid - Check</option>
          </Form.Control>
          <br />
          <Form.Label>Amount:</Form.Label>
          <Form.Control
            type="number"
            placeholder="Amount"
            className="form-control w-200px"
            name="amount"
            value={this.state.amount}
            min={0}
            onChange={this.handleChange}
          />
          <Form.Label>Notes</Form.Label>
          <Form.Control
            type="text"
            placeholder="Note"
            value={this.state.notes}
            name="notes"
            onChange={this.handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCancel}
            type="button"
            className="btn btn-fill btn-danger"
          >
            Cancel
          </Button>
          <Button
            style={{ minWidth: "93px" }}
            onClick={() =>
              handleSave(this.state.status, this.state.notes, this.state.amount)
            }
            type="button"
            className="btn btn-fill btn-info"
            disabled={!this.state.isDisableButton}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeleteResidentModal;

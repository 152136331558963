import t from "./types";
import apiType from "../APIResponseActions/type";
import { AuthAPI, UnAuthAPI, BufferAuthAPI } from "../../../Auth/requestHandler";
import { AddTimeInDate, convertDateTimeToUtc } from "../../Components/helper";
import moment from "moment";
import actionCreator from "../Action.Helper";

const addUpdateDeleteResponse = actionCreator("ADD_UPDATE_DELETE_RES");

export const fetch_residents = (pageObj) => async (dispatch) => {
  try {
    const url =
      "user?page_size=" +
      pageObj.Norow +
      "&page=" +
      pageObj.page +
      "&search=" +
      pageObj.searchValue +
      "&order_by=" +
      pageObj.selectedColumn +
      "&sorting=" +
      pageObj.sort_direction +
      `${pageObj.isUnit ? "&isUnit=true" : ""}`;
    const result = await AuthAPI("domain", url, "GET");
    await dispatch({ type: t.FETCH_RESIDENTS, payload: result.data });
    await dispatch({ type: apiType.API_RESPONSE_SUCCESS, payload: "Success" });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const remove_resident = (uid) => async (dispatch) => {
  try {
    const url = "user/" + uid + "/remove";
    const result = await AuthAPI("domain", url, "PATCH");
    if (result) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: "Unit removed successfully",
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const confirm_remove_resident = (uid) => async (dispatch) => {
  try {
    const isDeactivate = {
      deactivate: true,
    };
    const url = "user/" + uid + "/remove";
    const result = await AuthAPI("domain", url, "PATCH", isDeactivate);
    if (result) {
      await dispatch({ type: t.REMOVE_SPCIFIC_RESIDENTS, payload: uid });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: "Unit removed successfully",
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const select_edit_resident = (id) => async (dispatch) => {
  try {
    const url = "user/" + id;
    const result = await AuthAPI("domain", url, "GET");
    console.log("select_edit_resident::::", result);
    await dispatch({
      type: t.FETCH_SPCIFIC_RESIDENTS,
      payload: {
        ...result,
        permits_allowed: result.permits_allowed ? Number(result.permits_allowed) : 0,
      },
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const edit_resident = (id, info) => async (dispatch) => {
  try {
    const url = "user/" + id;
    const response = await AuthAPI("domain", url, "PATCH", info);
    if (response.success) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const add_resident = (info) => async (dispatch) => {
  try {
    const url = "user";
    const body = {
      ...info,
    };
    const response = await AuthAPI("domain", url, "POST", body);
    if (response && response.action === "add") {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.msg,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const getResidentDetails = (id, propertyId) => async (dispatch) => {
  try {
    const url = "user/" + id + "/lots/" + propertyId;
    const response = await AuthAPI("domain", url, "GET");
    await dispatch({
      type: t.FETCH_NEW_RESIDENTS,
      payload: response,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const importUnitsPermitsSites = (importedData, type) => async (dispatch) => {
  try {
    let url;
    if (type === "Units") {
      url = "user/unit";
    } else if (type === "Site") {
      url = "property/bulk-import";
    } else {
      url = "permit/bulk-permit";
    }

    const data = {
      data: importedData,
    };
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "",
    });
    await dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    const response = await AuthAPI("domain", url, "POST", data);
    await dispatch({
      type: t.FETCH_IMPORT_UNITS,
      payload: response,
    });

    if (response[0].status === "Failed") {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: "Import Failed.",
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: "Data imported sucessfully",
      });
    }
    await dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: false,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const visitorCreate =
  (propertyId, permit_start_date, permit_end_date, uid, info, lotIdToPass, quick_permits, timezone, isPublic) => async (dispatch) => {
    try {
      if (!quick_permits) {
        const begin_date = convertBeginDate(permit_start_date, timezone);
        const formatedET = convertDate(permit_end_date) + " 23:59:00";
        const end_date = getUTCT(formatedET, timezone);
        const data = [];
        for (const n of lotIdToPass) {
          data.push({
            creator_uid: uid,
            property_id: propertyId,
            lot_id: n,
            suite_id: info.suite_id,
            first_name: info.first_name,
            last_name: info.last_name,
            country_code: info.country_code || "+1",
            phone: info.phone,
            phone_options: info.phone_options,
            email: info.email,
            stall_id: info.stallNumber,
            lpn: info.vehicle_license,
            car_make: info.make || "-",
            car_model: info.modal || "-",
            car_color: info.color || "-",
            tag_number: info.tag_number,
            begin_time: begin_date,
            expire_time: end_date,
            role: "Resident",
            permit_class: "Resident",
            call_when_here: info.call_when_here,
            quick_permits: false,
            notes: info.note,
            memberId: info.memberId,
            self_server: info.self_server,
          });
        }
        const dataToSend = {
          credit_type: "single",
          permit: data,
          permit_credit_group: null,
        };
        const result = await AuthAPI("domain", "permit", "POST", dataToSend);
        if (result?.success) {
          await dispatch({
            type: apiType.API_RESPONSE_SUCCESS,
            payload: result.msg,
          });
        } else {
          await dispatch({
            type: apiType.API_RESPONSE_FAILURE,
            payload: result.message,
          });
        }
      } else {
        const data = [];
        data.push({
          creator_uid: uid,
          property_id: propertyId,
          lot_id: info.lot_id,
          suite_id: info.suite_id,
          first_name: info.first_name,
          last_name: info.last_name,
          country_code: info.country_code,
          phone: info.phone,
          phone_options: info.phone_options,
          email: info.email,
          stall_id: info.stallNumber,
          lpn: info.vehicle_license,
          car_make: "-",
          car_model: "-",
          car_color: "-",
          tag_number: info.tag_number,
          begin_time: permit_start_date.toLocaleString().replace(",", ""),
          expire_time: permit_end_date,
          role: "Visitor",
          permit_class: "Visitor",
          call_when_here: info.call_when_here,
          quick_permits: true,
          cost: info.cost,
          memberId: info.memberId,
          notes: info.note,
          self_server: info.self_server,
        });
        const token = localStorage.getItem("selfServeToken");
        const dataToSend = {
          credit_type: "single",
          permit: data,
          permit_credit_group: null,
          property_id: propertyId,
          token: token,
          quick_permits: true,
        };
        let result;
        if (isPublic) {
          result = await UnAuthAPI("public/permit/create_public_permit", "POST", dataToSend);
        } else {
          result = await AuthAPI("domain", "permit", "POST", dataToSend);
        }
        if (result.success) {
          await dispatch(addUpdateDeleteResponse.success({ message: result.msg }));
          await dispatch({
            type: apiType.API_RESPONSE_SUCCESS,
            payload: result.msg,
          });
          return result.success;
        } else {
          await dispatch(addUpdateDeleteResponse.failure({ message: result.message }));
          await dispatch({
            type: apiType.API_RESPONSE_FAILURE,
            payload: result.message,
          });
        }
      }
    } catch (error) {
      await dispatch(addUpdateDeleteResponse.failure({ message: error?.message }));
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: error.toString(),
      });
    }
  };

const convertDate = (usDate) => {
  const dateParts = usDate.split(/(\d{1,2})\/(\d{1,2})\/(\d{4})/);
  return dateParts[3] + "-" + dateParts[1] + "-" + dateParts[2];
};

const convertBeginDate = (begin_date, timezone) => {
  const today = new Date(
    new Date().toLocaleString("en-US", {
      timeZone: timezone,
    }),
  );
  let final_begin_date = convertDate(begin_date) + " 00:00:00";
  const year = convertDate(begin_date).split("-")[0];
  const month = convertDate(begin_date).split("-")[1];
  const date = convertDate(begin_date).split("-")[2];

  if (today.getFullYear() === +year && today.getMonth() + 1 === +month && today.getDate() === +date) {
    const hours = today.getHours() < 10 ? `0${today.getHours()}` : today.getHours();

    const minutes = today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes();
    const seconds = today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds();
    final_begin_date = convertDate(begin_date) + " " + hours + ":" + minutes + ":" + seconds;
  }
  return getUTCT(final_begin_date, timezone);
};

const getUTCT = (formatedET, timezone) => {
  const convertedTime = moment.tz(formatedET, timezone).format();
  return moment.tz(convertedTime, "UTC").format();
};

export const LprReportListAPI =
  (property_id, lot_id, lane_id, start_time, stop_time, timezone, lpn, yellow_or_white, direction, pageobj, currentDate, currentStartTime, currentEndTIme) =>
  async (dispatch) => {
    try {
      const url = "lpr/lpr_report";
      const body = {
        property_id,
        lot_id,
        lane_id,
        // start_time: startDateInUtc,
        // stop_time: endDateInUtc,
        timezone,
        lpn,
        yellow_or_white,
        direction,
        page_size: parseInt(pageobj.Norow),
        page: pageobj.page,
        search: pageobj.searchValue,
        order_by: pageobj.selectedColumn,
        sorting: pageobj.sort_direction,
        isAllRecord: pageobj?.exportValue ? 1 : pageobj.isAllRecord,
        exportValue: pageobj?.exportValue ? 1 : 0,
      };

      if (start_time && stop_time) {
        const { startTimeFormat, EndTimeFormat } = AddTimeInDate(start_time, stop_time);
        const { startcurrent_time, endcurrent_time } = convertDateTimeToUtc(timezone, startTimeFormat, EndTimeFormat);
        body.start_time = startcurrent_time;
        body.stop_time = endcurrent_time;
      }
      if (currentDate) {
        const { startTimeFormat, EndTimeFormat } = AddTimeInDate(currentDate, currentDate, currentStartTime, currentEndTIme);
        const { startcurrent_time, endcurrent_time } = convertDateTimeToUtc(timezone, startTimeFormat, EndTimeFormat);
        body.currentDateStartTime = startcurrent_time;
        body.currentDateEndTime = endcurrent_time;
      }
      if (pageobj?.exportValue) {
        return await BufferAuthAPI("domain", url, "POST", body);
      } else {
        const response = await AuthAPI("domain", url, "POST", body);
        await dispatch({
          type: t.FETCH_CURRENT_LPR,
          payload: response,
        });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: "Success",
        });
      }
    } catch (error) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: error.toString(),
      });
    }
  };

export const TicketReportListAPI =
  (property_id, lot_id, unit_id, childcheckbox, month, year, currentDate, startDate, endDate, pageobj, timezone) => async (dispatch) => {
    const timeFormat = moment(startDate).format("YYYY-MM-DD");
    const startDateWithTime = `${timeFormat} 00:00:00`;

    const timeEndFormat = moment(endDate).format("YYYY-MM-DD");
    const endDateWithTime = `${timeEndFormat} 23:59:59`;

    const { startcurrent_time, endcurrent_time } = convertDateTimeToUtc(timezone, startDateWithTime, endDateWithTime);
    const body = {
      property_id,
      lot_id,
      unit_id,
      childcheckbox,
      month,
      year,
      currentDate,
      start_time: startcurrent_time,
      stop_time: endcurrent_time,
      pageobj,
    };
    try {
      // rgad4nk4j0uef8q09akj
      // (lots, lanes, start_time, stop_time, lpn, whitelist, direction, propertyId) =>
      // const start_time_format = "ANY";

      const url = "lpr/gettrreport";

      const response = await AuthAPI("domain", url, "POST", body);
      await dispatch({
        type: t.FETCH_CURRENT_TR,
        payload: response,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: "Success",
      });
    } catch (error) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: error.toString(),
      });
    }
  };

export const LotReportListAPI = (property_id) => async (dispatch) => {
  try {
    const url = "lpr/" + property_id;

    const response = await AuthAPI("domain", url, "GET");
    console.log("allLot::::::::::", response);
    await dispatch({
      type: t.FETCH_CURRENT_LOT,
      payload: response,
    });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "Success",
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const get_all_lanes = (lot_id) => async (dispatch) => {
  try {
    const url = "lpr/get_lane";
    const body = {
      lot_id,
    };
    const response = await AuthAPI("domain", url, "POST", body);
    await dispatch({
      type: t.FETCH_CURRENT_LANE,
      payload: response,
    });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "Success",
    });
  } catch (error) {
    console.error("eeeeeeeeeeeeeeeeeee", error);
  }
};

import React, { Component } from "react";
import { Card, Container, Row, Col, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { connect } from "react-redux";
import { SuperAdminAction, TicketActions } from "../../Actions";
import Loader from "../Loader/Loader";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datepicker/dist/react-datepicker.css";
import CustomContentAlert, { findBySearch, sendFileToZebra, utcToSpecificTimeZone, tableHeightScrollBars, scroolToTop } from "../helper";
import isEqual from "lodash/isEqual";
import paid from "../../../assets/img/svg/ticket-icons/paid.svg";
import dispute from "../../../assets/img/svg/ticket-icons/dispute.svg";
import padlock from "../../../assets/img/svg/ticket-icons/padlock.svg";
import unpaid from "../../../assets/img/svg/ticket-icons/unpaid.svg";
import paid_cash from "../../../assets/img/svg/paid-cash.svg";
import paid_check from "../../../assets/img/svg/paid-check.svg";
import paid_creditcard from "../../../assets/img/svg/paid-creditcard.svg";
import paid_interac from "../../../assets/img/svg/paid-interac.svg";
import PropertDetails from "../LandingPage/PropertySelection";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";
const moment_timezone = require("moment-timezone");

class TicketReport extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.state = {
      PropertyId: this.props.PropertyId,
      ticketList: [],
      loader: false,
      searchValue: "",
      searchedticketList: [],
      api_response: false,
      show_alert: false,
      api_success_message: "",
      api_error_message: "",
      page: 1,
      Norow: 100,
      selectedColumn: "created_at",
      sort_direction: "DESC",
      pageChanged: false,
      findMore: false,
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      selectedOption: "self",
      status: "",
      devices: [],
      selected_device: "",
      finalTableHeight: 0,
      printButtonDisable: true,
      all_properties: [],
      selectedPropertyObj: {},
      selectedProperty: "",
      selectedDomainName: "",
      childcheckbox: true,
      filterStatus: "Any",
      exportButtonDisable: false,
      currentSummaryDate: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      daysDifference: 30,
      detectedDevice: "",
      statusFilter: [
        { text: "Any", value: "Any" },
        { text: "All Open", value: "Open" },
        { text: "All Closed", value: "Closed" },
        { text: "Unpaid (Open)", value: "Unpaid_Open" },
        { text: "Reduced (Open)", value: "Reduced_Open" },
        { text: "In Dispute (Open)", value: "In Dispute_Open" },
        { text: "In-Dispute 2 (Open)", value: "In-Dispute 2_Open" },
        { text: "Dispute Rejected (Open)", value: "Dispute Rejected_Open" },
        { text: "Dispute Rejected 2 (Open)", value: "Dispute Rejected 2_Open" },
        { text: "Paid (Closed)", value: "Paid_Closed" },
        { text: "Paid Cash (Closed)", value: "Paid Cash_Closed" },
        { text: "Paid Check (Closed)", value: "Paid Check_Closed" },
        {
          text: "Paid Credit Card Manually (Closed)",
          value: "Paid Credit Card Manually_Closed",
        },
        { text: "Paid Interac (Closed)", value: "Paid Interac_Closed" },
        { text: "Dispute Approved (Closed)", value: "Dispute Approved_Closed" },
        { text: "Warning (Closed)", value: "Warning_Closed" },
        { text: "Invalid (Closed)", value: "Invalid_Closed" },
      ],
      startDateTime: "",
      endDateTime: "",
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "Issued Date",
        sortable: true,
        width: "160px",
        cell: (row) => this.showCellValue(row.created_at, "time"),
        selector: (row) => row.created_at,
      },
      {
        name: "Plate",
        sortable: true,
        width: "180px",
        cell: (row) => this.showPlateField(row),
        selector: (row) => row.plate,
      },
      {
        name: "Enforcer",
        sortable: true,
        width: "110px",
        cell: (row) => this.showEnforcerField(row),
        selector: (row) => row?.enforcer,
      },
      {
        name: "Site",
        sortable: true,
        width: "140px",
        cell: (row) => this.showSiteField(row),
        selector: (row) => row.site,
      },
      {
        name: "Violation",
        sortable: true,
        width: "130px",
        cell: (row) => this.showViolationField(row),
        selector: (row) => row.violation,
      },
      {
        name: "Fee",
        sortable: true,
        width: "150px",
        cell: (row) => this.showFeeField(row),
        selector: (row) => row.fee,
      },
      {
        name: "Payment Transaction Time",
        sortable: true,
        width: "150px",
        cell: (row) => this.showPaymentTimeField(row),
        selector: (row) => row.transaction_time,
      },
      {
        name: "Citation No",
        sortable: true,
        width: "120px",
        cell: (row) => this.showCitationNoField(row),
        selector: (row) => row.ticket_no,
      },
      {
        name: "Status",
        sortable: true,
        width: "120px",
        cell: (row) => this.showStatusField(row),
        selector: (row) => row.status,
      },
      {
        name: "Refunded",
        sortable: true,
        width: "130px",
        cell: (row) => this.showRefundedField(row),
        selector: (row) => row.refunded,
      },
      {
        name: "Refund Amount",
        sortable: true,
        width: "130px",
        cell: (row) => this.showRefundAmountField(row),
        selector: (row) => row.refund_amount,
      },
      {
        name: "Refund Transaction Time",
        sortable: true,
        width: "190px",
        cell: (row) => this.showRefundTimeField(row),
        selector: (row) => row.refund_transaction_time,
      },
      {
        name: "Charged for 2nd reason",
        sortable: true,
        width: "180px",
        cell: (row) => this.showChargeSecondReasonField(row),
        selector: (row) => row.status,
      },
      {
        name: "Cancellation Note",
        sortable: true,
        width: "200px",
        cell: (row) => this.showCancellationField(row),
        selector: (row) => row.cancellation_note,
      },
      {
        name: "Internal Note",
        sortable: true,
        width: "160px",
        cell: (row) => this.showInternalNoteField(row),
        selector: (row) => row.notes_internal,
      },
      {
        name: "State",
        sortable: true,
        width: "150px",
        cell: (row) => this.showStateField(row),
        selector: (row) => row.state,
      },
    ];
  }

  showInternalNoteField = (row) => (
    <>
      {row.notes_internal ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.notes_internal}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.notes_internal}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showStateField = (row) => (
    <div className="d-flex text-nowrap text-truncate pr-2">
      {row.state === "Close" && <img src={padlock} width={18} />}
      {row.state ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.state}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.state}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </div>
  );

  showCancellationField = (row) => (
    <>
      {row.cancellation_note ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{row.cancellation_note.toString("20")}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{row.cancellation_note.toString("20")}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showChargeSecondReasonField = (row) => <div>{row.no_charge ? "Yes" : "No"}</div>;

  showRefundTimeField = (row) => (
    <>
      {row.refund_transaction_time ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.refund_transaction_time}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.refund_transaction_time}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showRefundAmountField = (row) => (
    <>
      {row.refund_amount > 0 ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>${Number(row.refund_amount).toFixed(2)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">${Number(row.refund_amount).toFixed(2)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showRefundedField = (row) => <div>{row.refunded ? "Yes" : "No"}</div>;

  showStatusField = (row) => (
    <div className="d-flex text-nowrap text-truncate">
      <StatusIcon status={row.status} />
      {"  "}
      {row.status ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.status}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2 pl-1">{row.status}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </div>
  );

  showCitationNoField = (row) => (
    <div className="d-flex align-items-center text-nowrap text-truncate pr-2">
      <span className="icon-tickit2 fs-20px mr-1"></span>
      {row.ticket_no ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.ticket_no}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.ticket_no}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </div>
  );

  showPaymentTimeField = (row) => (
    <>
      {row?.transaction_time ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.transaction_time}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.transaction_time}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showFeeField = (row) => (
    <>
      {row?.fee ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>$${Number(row.fee).toFixed(2)}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">${Number(row.fee).toFixed(2)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showCellValue = (cellValue, valueType) => {
    switch (valueType) {
      case "text":
        return (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{cellValue ? cellValue : "-"}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{cellValue ? cellValue : "-"}</div>
          </OverlayTrigger>
        );
      case "amount":
        return (
          <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-qw`}>{cellValue ? Math.max(0, parseFloat(cellValue)).toFixed(2) : "-"}</Tooltip>}
          >
            <div className="text-nowrap text-truncate pr-2">{cellValue ? Math.max(0, parseFloat(cellValue)).toFixed(2) : "-"}</div>
          </OverlayTrigger>
        );
      case "time":
        return (
          <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement={"top"}
            overlay={<Tooltip id={`tooltip-qw`}>{cellValue ? utcToSpecificTimeZone(this.props.timezone, cellValue, true) : "-"}</Tooltip>}
          >
            <div className="text-nowrap text-truncate pr-2">{cellValue ? utcToSpecificTimeZone(this.props.timezone, cellValue, true) : "-"}</div>
          </OverlayTrigger>
        );
      default:
        break;
    }
  };

  showViolationField = (row) => (
    <>
      {row.violation ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}> {row.violation}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.violation}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showSiteField = (row) => (
    <>
      {row.site ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.site}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.site}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showEnforcerField = (row) => (
    <>
      {" "}
      {row.enforcer ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.enforcer}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.enforcer}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  checkLpn = (lpn) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/current-occupancy`,
      search: `audit-report&${lpn}`,
    });
  };

  showPlateField = (row) => (
    <div className="curserPointer text-nowrap text-truncate pr-2" onClick={() => this.checkLpn(row.lpn)}>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.plate}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row.plate}</div>
      </OverlayTrigger>
    </div>
  );

  componentDidMount = async () => {
    if (this.props.location?.state) {
      let search = "";
      if (this.props.location.state.plate) {
        search = this.props.location.state.plate;
      }
      if (this.props.location.state.ticket_no) {
        search = this.props.location.state.ticket_no;
      }
      this.setState(
        {
          loader: true,
          searchValue: search,
        },
        async () => {
          const {
            searchValue,
            selectedColumn,
            sort_direction,
            selectedOption,
            childcheckbox,
            currentSummaryDate,
            startDate,
            endDate,
            timezone,
            filterStatus,
            selectedPropertyObj,
            PropertyId,
          } = this.state;
          const pageobj = {
            searchValue,
            selectedColumn,
            sort_direction,
            selectedOption,
            childcheckbox,
            timezone,
          };
          if (Object.keys(selectedPropertyObj).length) {
            pageobj.PropertyId = selectedPropertyObj.value;
          } else {
            pageobj.PropertyId = PropertyId;
          }
          if (childcheckbox) {
            pageobj.childcheckbox = childcheckbox;
          }
          if (filterStatus) {
            pageobj.status = filterStatus;
          }
          if (currentSummaryDate) {
            pageobj.currentSummaryDate = currentSummaryDate;
          }
          if (startDate && endDate) {
            pageobj.startDate = startDate;
            pageobj.endDate = endDate;
          }
          await this.props.getTicketList(pageobj);
          await this.GetDateRangeWithFormat(pageobj);
          this.setState(
            {
              page: 1,
              ticketList: this.props.ticketList.rows,
              searchedticketList: this.props.ticketList.rows,
            },
            () => {
              this.setState({
                count: this.state.searchedticketList.length,
                loader: false,
              });
            },
          );
        },
      );
    } else {
      await this.getTickets();
    }
    const height = tableHeightScrollBars();
    this.setState({
      finalTableHeight: height,
    });
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    this.setState(
      {
        api_response: this.props?.api_response,
        all_properties: this.props?.getAllProperties,
      },
      async () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );
    this.findDevicesOnPrinter();
    const detectedDevice = this.getMobileOperatingSystem();
    this.setState({
      detectedDevice: detectedDevice,
    });
  };

  GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj);
    this.setState({
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    });
  };

  findDevicesOnPrinter = () => {
    const usethis = this;
    const devices = [];
    window.BrowserPrint?.getDefaultDevice(
      "printer",
      function (device) {
        // Add device to list of devices and to html select element
        const selected_device = device;
        devices.push(device);
        usethis.setState({
          devices: devices,
          selected_device: selected_device,
        });

        // Discover any other devices available to the application
        window.BrowserPrint?.getLocalDevices(
          function (device_list) {
            for (const element of device_list) {
              // Add device to list of devices and to html select element
              const device_data = element;
              devices.push(device_data);
            }
            usethis.setState({
              devices: devices,
              selected_device: devices?.[0],
            });
          },
          function () {
            console.log("in print");
          },
          "printer",
        );
      },
      function (error) {
        console.log("error in component did mount", error);
      },
    );
  };

  sendFile = (fileUrl) => {
    const { selected_device } = this.state;
    if (selected_device) {
      this.setState({
        printButtonDisable: true,
      });
      sendFileToZebra(selected_device, fileUrl);
      setTimeout(() => {
        this.setState({ printButtonDisable: false });
      }, 10000);
    }
  };

  getTickets = async () => {
    this.setState({ loader: true }, async () => {
      const {
        timezone,
        searchValue,
        selectedColumn,
        sort_direction,
        selectedOption,
        PropertyId,
        selectedPropertyObj,
        childcheckbox,
        filterStatus,
        currentSummaryDate,
        startDate,
        endDate,
      } = this.state;
      const pageobj = {
        timezone,
        searchValue,
        selectedColumn,
        sort_direction,
        selectedOption,
      };
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }
      if (childcheckbox) {
        pageobj.childcheckbox = childcheckbox;
      }
      if (filterStatus) {
        pageobj.status = filterStatus;
      }
      if (currentSummaryDate) {
        pageobj.currentSummaryDate = currentSummaryDate;
      }
      if (startDate && endDate) {
        pageobj.startDate = startDate;
        pageobj.endDate = endDate;
      }
      await this.props.getTicketList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);

      this.setState({
        ticketList: this.props.ticketList.rows && JSON.parse(JSON.stringify(this.props.ticketList.rows)),
        api_response: this.props.api_response,
        count: this.props.ticketList.count,
        searchedticketList: this.props.ticketList.rows && JSON.parse(JSON.stringify(this.props.ticketList.rows)),
      });
      this.setState({ loader: false });
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.ticketList, this.props.ticketList)) {
      this.setState({
        searchedticketList: this.props.ticketList.rows,
        ticketList: JSON.parse(JSON.stringify(this.props.ticketList.rows)),
        pageChanged: true,
        count: this.props.ticketList.count,
      });
    }
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ searchValue: val }, async () => {
      const columnList = ["created_at", "plate", "enforcer", "site", "violation", "fee", "ticket_no", "status", "cancellation_note", "notes_internal", "state"];
      if (this.state.searchValue.length) {
        if (!this.state.findMore) {
          this.setState(
            {
              searchedticketList: findBySearch(this.state.searchValue, this.state.ticketList, this.state.ticketList, columnList, this.state.timezone),
            },
            () => {
              this.setState({ count: this.state.searchedticketList.length });
            },
          );
        }
      } else {
        this.getTickets();
      }
    });
  };

  dataTableHandle = (searchValue, searchedticketList, ticketList) => {
    return (
      <DataTable
        data={searchValue?.length ? searchedticketList : ticketList}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        onChangePage={scroolToTop}
        fixedHeader={true}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={this.state.Norow}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleCheckBox = () => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState({
      childcheckbox: !this.state.childcheckbox,
      year: "",
      currentSummaryDate: null,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
    });
  };

  changeProperty(event) {
    this.setState({
      selectedProperty: event.value,
      selectedPropertyObj: event,
      loader: false,
    });
  }

  handleSearchTickets = async () => {
    this.setState({ loader: true });
    const {
      timezone,
      searchValue,
      selectedColumn,
      sort_direction,
      selectedOption,
      PropertyId,
      selectedPropertyObj,
      childcheckbox,
      filterStatus,
      currentSummaryDate,
      startDate,
      endDate,
      year,
    } = this.state;
    const pageobj = {
      timezone,
      searchValue,
      selectedColumn,
      sort_direction,
      selectedOption,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (childcheckbox) {
      pageobj.childcheckbox = childcheckbox;
    }
    if (filterStatus) {
      pageobj.status = filterStatus;
    }
    if (year) {
      pageobj.year = moment_timezone(year).format("YYYY");
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    await this.props.getTicketList(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    const selectedPropertyName = this.state.property?.property_name;
    const selectedDomainName = this.state.property?.domain;
    await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    this.setState({
      ticketList: this.props.ticketList.rows && JSON.parse(JSON.stringify(this.props.ticketList.rows)),
      api_response: this.props.api_response,
      count: this.props.ticketList.count,
    });
    this.setState({ loader: false });
    document.body.classList.remove("foc-open");
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleResetAll = async () => {
    const { PropertyId, selectedDomainName, daysDifference } = this.state;
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - daysDifference);
    this.setState({
      loader: false,
      selectedPropertyObj: {
        label: selectedDomainName,
        value: PropertyId,
      },
      selectedProperty: PropertyId,
      childcheckbox: true,
      filterStatus: "Any",
      searchValue: "",
      page: 1,
      Norow: 100,
      currentSummaryDate: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
    });
  };

  handleStatusFilter = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleChangeStartingDates = (date) => {
    if (date) {
      const { daysDifference } = this.state;
      const end = new Date(date);
      end.setDate(date.getDate() + daysDifference);
      let endDate = new Date();
      if (new Date() >= end) {
        endDate = end;
      }
      this.setState({
        startDate: date,
        year: "",
        endDate: endDate,
        currentSummaryDate: "",
        selectedRadio: null,
        loader: false,
      });
    }
  };

  handleChangeEndDates = (date) => {
    if (date) {
      this.setState({
        endDate: date,
        year: "",
        currentSummaryDate: "",
        selectedRadio: null,
        loader: false,
      });
    }
  };

  handleChangeYear = (date) => {
    this.setState({
      year: date,
      currentSummaryDate: null,
      startDate: null,
      endDate: null,
      selectedRadio: null,
      loader: false,
    });
  };

  handleFilterByDate = (date) => {
    if (date) {
      this.setState({
        currentSummaryDate: date,
        year: "",
        startDate: null,
        endDate: null,
        selectedRadio: null,
        loader: false,
      });
    }
  };

  handleExport = async () => {
    const {
      searchValue,
      state,
      selectedColumn,
      sort_direction,
      selectedOption,
      PropertyId,
      selectedPropertyObj,
      childcheckbox,
      filterStatus,
      currentSummaryDate,
      startDate,
      endDate,
      year,
    } = this.state;
    const pageobj = {
      page: 1,
      Norow: 10,
      searchValue,
      state,
      selectedColumn,
      sort_direction,
      selectedOption,
      exportData: true,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (childcheckbox) {
      pageobj.childcheckbox = childcheckbox;
    }
    if (filterStatus) {
      pageobj.status = filterStatus;
    }
    if (year) {
      pageobj.year = moment_timezone(year).format("YYYY");
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    const bufferData = await this.props.getTicketList(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        `Citations.xls`,
      );
    }
  };

  getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  render() {
    const {
      ticketList,
      loader,
      searchValue,
      page,
      Norow,
      pageChanged,
      searchedticketList,
      childcheckbox,
      selectedPropertyObj,
      filterStatus,
      statusFilter,
      exportButtonDisable,
      startDate,
      endDate,
      currentSummaryDate,
      year,
    } = this.state;

    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="mb-0">
                <Card.Body className="pos-rel-overflow-hide">
                  <a id="pdfList" href={this.state.pdfUrl} target={"_blank"} rel="noopener noreferrer" style={{ display: "none" }}>
                    pdf
                  </a>
                  <>
                    <div className="sidebarFilter">
                      <Card className="contact-container mb-0">
                        <Card.Header style={{ backgroundColor: this.props.main_body }}>
                          <div className="d-flex align-items-center justify-content-between input-fix-width">
                            <Card.Title as="h4">List of Citation</Card.Title>
                            <a
                              onClick={this.hideSlider}
                              href="javascript:void(0);"
                              className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            <Col md="12">
                              <div className="mb-2">
                                <label className="fancy-checkbox mt-2">
                                  <input
                                    type="checkbox"
                                    name={"childcheckbox"}
                                    checked={childcheckbox}
                                    onChange={this.handleCheckBox}
                                    className="custom-control-input"
                                    id="childcheckbox1"
                                  ></input>
                                  <span>
                                    <i></i>Include Children Sites
                                  </span>
                                </label>
                              </div>
                            </Col>
                            <Col md="12">
                              <PropertDetails selectedPropertyObj={selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">Specific Year:</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Date"
                                  selected={year}
                                  value={year}
                                  disabled={loader || childcheckbox}
                                  selectsStart
                                  timeInputLabel="Time:"
                                  onChange={this.handleChangeYear}
                                  showYearPicker
                                  dateFormat="yyyy"
                                  maxDate={new Date()}
                                  minDate={new Date(2021, 0, 1)}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">Specific Date:</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Date"
                                  selected={currentSummaryDate}
                                  value={currentSummaryDate}
                                  disabled={loader}
                                  selectsStart
                                  onChange={this.handleFilterByDate}
                                  dateFormat="MM/dd/yyyy"
                                  maxDate={new Date()}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">Start Date(Date Range):</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Start Date"
                                  selected={startDate}
                                  defaultHour={24}
                                  value={startDate}
                                  disabled={loader}
                                  selectsStart
                                  timeInputLabel="Time:"
                                  onChange={this.handleChangeStartingDates}
                                  dateFormat="MM/dd/yyyy"
                                  startDate={startDate}
                                  endDate={endDate}
                                  maxDate={new Date()}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="12">
                              <Form.Group>
                                <label className="mb-0 mr-2">End Date(Date Range):</label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select End Date"
                                  selected={endDate}
                                  disabled={loader}
                                  value={endDate}
                                  selectsEnd
                                  timeInputLabel="Time:"
                                  onChange={this.handleChangeEndDates}
                                  // dateFormat="MM/dd/yyyy hh:mm:ss aa"
                                  dateFormat="MM/dd/yyyy"
                                  // showTimeInput
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={startDate}
                                  maxDate={new Date()}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Status:</Form.Label>
                                <Form.Control as="select" name="filterStatus" disabled={loader} onChange={this.handleStatusFilter} value={filterStatus}>
                                  {statusFilter ? (
                                    <>
                                      {statusFilter.map((item) => (
                                        <option key={item.text} value={item.value}>
                                          {item.text}
                                        </option>
                                      ))}
                                    </>
                                  ) : null}
                                </Form.Control>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer className="text-right justify-content-between d-flex">
                          <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.handleResetAll} disabled={loader}>
                            Reset
                          </Button>
                          <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearchTickets} disabled={loader}>
                            Search
                          </Button>
                        </Card.Footer>
                      </Card>
                    </div>
                    <header>
                      <div className="active-permit-header sm-flex-column sm-align-items-start">
                        <h5 className="mb-2 mb-md-0">List of Citation</h5>
                        <div className="filter-button d-flex flex-wrap">
                          <div className="d-flex align-items-center search-mb-0 mr-2 loc-searchbox">
                            <DatatableHeader
                              className="mt-0 mb-0"
                              searchValue={searchValue}
                              HandleChange={this.handleSearchChange}
                              findMore={true}
                              findMorePressed={this.getTickets}
                              citations={true}
                            />
                          </div>
                          <div className="export-btn">
                            <Button variant="info" className="btn-fill btn-sm" onClick={this.handleExport} disabled={exportButtonDisable} size="medium">
                              Export
                            </Button>
                          </div>
                        </div>
                      </div>
                    </header>
                  </>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {this.state.show_alert && this.state.api_success_message && (
                        <CustomContentAlert delay={4000} message={this.state.api_success_message} className="toast-success" />
                      )}
                      {ticketList && (
                        <div className="citation-list-table">{this.dataTableHandle(searchValue, searchedticketList, ticketList, page, Norow, pageChanged)}</div>
                      )}
                      <DateRangeAndAmount
                        ReportList={this.props?.ticketList?.rows}
                        amountName="fee"
                        startDateTime={this.state.startDateTime}
                        endDateTime={this.state.endDateTime}
                      />
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, TicketDetails, Logindetails, SuperAdminDetails }) => {
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { property_id: PropertyId, user_type, suite_id, timezone, property, admin_login, id } = UserProfileDetails;
  const { ticketList, updateTicketStatus } = TicketDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { getAllProperties } = SuperAdminDetails;
  return {
    api_response,
    api_error_message,
    api_success_message,
    PropertyId,
    user_type,
    suite_id,
    timezone,
    property,
    ticketList,
    updateTicketStatus,
    main_body,
    getAllProperties,
    admin_login,
    id,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  getTicketList: TicketActions.getTicketList,
  get_all_properties: SuperAdminAction.get_all_properties,
  changeTicketStateAndStatus: TicketActions.changeTicketStateAndStatus,
};

export default connect(mapStateToProps, mapStateToDispatch)(TicketReport);
const StatusIcon = (status) => {
  let src = "";
  switch (status.status) {
    case "In Dispute":
      src = dispute;
      break;
    case "Unpaid":
      src = unpaid;
      break;
    case "Paid":
      src = paid;
      break;
    case "In-Dispute 2":
      src = dispute;
      break;
    case "Dispute Rejected":
      src = unpaid;
      break;
    case "Dispute Rejected 2":
      src = unpaid;
      break;
    case "Reduced":
      src = unpaid;
      break;
    case "Invalid":
      src = unpaid;
      break;
    case "Dispute Approved":
      src = paid;
      break;
    case "Warning":
      src = paid;
      break;
    case "Testing":
      src = paid;
      break;
    case "Paid Cash":
      src = paid_cash;
      break;
    case "Paid Check":
      src = paid_check;
      break;
    case "Paid Credit Card Manually":
      src = paid_creditcard;
      break;
    case "Paid Interac":
      src = paid_interac;
      break;
  }
  return <img src={src} width={18} />;
};

import React, { Component } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { SuperAdminAction } from "../../Actions";
import CitationTransaction from "./CitationTransaction";
import PermitTransaction from "./PermitTransaction";

class TransactionReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
  }

  onTabClick = async (k) => {
    this.setState({ activeTab: k });
  };

  render() {
    const { activeTab } = this.state;
    return (
      <Container fluid>
        <Tabs activeKey={this.state.activeTab} id="controlled-tab-example" onSelect={(k) => this.onTabClick(k)} transition={false}>
          <Tab eventKey="1" title="Citation" className="m-0">
            {activeTab === "1" && <CitationTransaction />}
          </Tab>
          <Tab eventKey="2" title="PassagePass" className="m-0">
            {activeTab === "2" && <PermitTransaction data_show_type="Paid" transaction_title="Transactions" location_type="PassagePass" />}
          </Tab>
          <Tab eventKey="3" title="Permit Package(Units)" className="m-0">
            {activeTab === "3" && <PermitTransaction data_show_type="Paid" transaction_title="Transactions" location_type="Unit" />}
          </Tab>
          <Tab eventKey="4" title="Parking Permit(Drivers)" className="m-0">
            {activeTab === "4" && <PermitTransaction data_show_type="Paid" transaction_title="Transactions" location_type="Driver" />}
          </Tab>
          <Tab eventKey="5" title="Visitor" className="m-0">
            {activeTab === "5" && <PermitTransaction data_show_type="Paid" transaction_title="Transactions" location_type="Visitor" />}
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails }) => {
  const { property_id: PropertyId, user_type, suite_id, timezone, property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;

  const { MainBackground: main_body } = backgroundColor;
  return {
    PropertyId,
    user_type,
    suite_id,
    timezone,
    property,
    main_body,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(TransactionReport);

import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Button, Card, Col, Form, Modal, ModalBody, Row } from "react-bootstrap";
import { batch, useDispatch, useSelector } from "react-redux";
import * as MeterDeviceActions from "../../Actions/MeterConfigActions/action";
import t from "../../Actions/MeterConfigActions/types";
import useGetAllProperty from "../../Hooks/Property/useGetAllProperty";
import MeterConfigSection from "./MeterConfigSection";
import MeterRateSection from "./MeterRateSection";

export default function AddMeterConfigTypeFun() {
  const dispatch = useDispatch();
  const location = useLocation();

  const modelOpenClose = useSelector((state) => state.MeterConfigDetails.modelOpenClose);
  const meterDevices = useSelector((state) => state.MeterConfigDetails.meterDevices);

  const meterDeviceData = useSelector((state) => state.MeterConfigDetails.meterdeviceData);
  const allServerId = useSelector((state) => state.MeterConfigDetails.allServerId);
  const meterCreateFlag = useSelector((state) => state.MeterConfigDetails.meterCreateflag);
  const { propertyId } = useGetAllProperty(location.search?.slice(1));

  const configTypeList = [
    { id: 1, text: "Settings", value: "Settings" },
    { id: 2, text: "Rate", value: "Rate" },
  ];

  const [formData, setFormData] = useState({
    type: "Settings",
    server_id: "",
    bill_enable: 0,
    door_password: "",
    rate_type: "",
    rate_list: "",
    rate_max: "",
    minimum_rate: "",
    operate_time: "",
    idle_time: 0,
    time_sleep: 0,
    sleep_time: "",
    battery_low: "",
    report_time: 0,
    minimum_charge_time: "",
    minimum_charge_rate: "",
    operating_hours: "",
    active: 0,
    description: "",
    contentvisible: false,
    disableType: false,
  });

  useEffect(() => {
    dispatch(MeterDeviceActions.fetchAllServerId(propertyId));
  }, [propertyId]);

  useEffect(() => {
    console.log("MeterDevice Data on Edit ::::", meterDeviceData);
    if (meterDeviceData.type === "Settings") {
      const {
        server_id,
        minimum_rate,
        operate_time,
        idle_time,
        time_sleep,
        battery_low,
        report_time,
        minimum_charge_time,
        minimum_charge_rate,
        operating_hours,
        sleep_time,
        rate_type,
        description,
        type,
        active,
        bill_enable,
        id,
        original_id,
      } = meterDeviceData;
      setFormData({
        ...formData,
        disableType: true,
        server_id,
        minimum_rate,
        operate_time,
        idle_time,
        time_sleep,
        battery_low,
        report_time,
        minimum_charge_time,
        minimum_charge_rate,
        operating_hours,
        sleep_time,
        rate_type,
        description,
        type,
        active,
        bill_enable,
        id,
        original_id,
      });
    } else if (meterDeviceData.type === "Rate") {
      const { server_id, rate_max, rate_list, rate_type, description, type, active, id, original_id } = meterDeviceData;
      setFormData({
        ...formData,
        disableType: true,
        server_id,
        rate_max,
        rate_list,
        rate_type,
        description,
        type,
        active,
        id,
        original_id,
      });
    }
  }, [meterDeviceData]);

  const handleClose = () => {
    batch(() => {
      dispatch({
        type: t.CLOSE_OPEN_MODEL,
        payload: false,
      });
    });
  };

  const checkValidations = () => {
    const { type, door_password, rate_list, sleep_time, operating_hours } = formData;

    const errors = {};
    let is_form_valid = true;

    const validateField = (fieldName, errorMessage, additionalCondition = true) => {
      if (!formData[fieldName]?.toString().length || !additionalCondition) {
        is_form_valid = false;
        errors[fieldName] = errorMessage;
      }
    };

    // Common validations
    validateField("server_id", "Server Id can't be empty");
    validateField("description", "Description can't be empty");
    validateField("rate_type", "Rate Type can't be empty");

    // Type-specific validations
    if (type === "Settings") {
      validateField("minimum_rate", "Minimum Rate can't be empty");
      validateField("operate_time", "Operate Time can't be empty");
      validateField("idle_time", "Idle Time can't be empty");

      if (meterCreateFlag && (!door_password.toString().length || door_password.toString().length < 8)) {
        validateField("door_password", "Door Password can't be empty or length must be at least 8 characters", false);
      } else {
        if (door_password.toString().length && door_password.toString().length < 8) {
          validateField("door_password", "Door Password can't be empty or length must be at least 8 characters", false);
        }
      }

      validateField("battery_low", "Battery Low can't be empty");
      validateField("report_time", "Report Time can't be empty");
      validateField("minimum_charge_time", "Minimum Charge Time can't be empty");
      validateField("minimum_charge_rate", "Minimum Charge Rate can't be empty");

      const timeFormatRegex = /^(?:[01]\d|2[0-3]):[0-5]\d-(?:[01]\d|2[0-3]):[0-5]\d$/;
      validateField("operating_hours", "Operating Hours can't be empty or invalid", timeFormatRegex.test(operating_hours));
      validateField("sleep_time", "Sleep Time can't be empty or invalid", timeFormatRegex.test(sleep_time));
    }

    if (type === "Rate") {
      const rateListPattern = /^\d+:\d+(\.\d+)?(?:,\d+:\d+(\.\d+)?)*$/;
      validateField("rate_list", "Rate List can't be empty or invalid", rateListPattern.test(rate_list));
      validateField("rate_max", "Rate Max can't be empty");
    }

    setFormData({ ...formData, is_form_valid, errors });
    return is_form_valid;
  };

  const handleAddFields = () => {
    if (checkValidations()) {
      dispatch({
        type: t.SHOW_HIDE_LOADER,
        payload: true,
      });
      batch(() => {
        dispatch(
          MeterDeviceActions.addMeterDevice({
            ...formData,
            property_id: propertyId,
            old_data: Array.isArray(meterDevices) ? meterDevices.find((x) => x.id === meterDeviceData?.original_id) : [],
          }),
        );
      });
    }
  };

  const handleChange = async ({ target }) => {
    const { name, value, type } = target;
    if (type === "checkbox") {
      const updatedValue = !formData[name];
      setFormData((prevState) => ({
        ...prevState,
        [name]: updatedValue,
      }));
    } else {
      console.log("name::::", name);
      const newValue = handleAllFieldsExceptCheckBox(name, value);
      const updatedFormData = { ...formData, [name]: newValue };
      if (newValue || value === "") {
        setFormData({
          ...formData,
          ...updatedFormData,
        });
      }
    }
    checkValidationsOnChange(target);
  };

  const handleAllFieldsExceptCheckBox = (name, value) => {
    let newValue;
    if (["minimum_rate", "battery_low", "minimum_charge_rate", "rate_max"].includes(name)) {
      newValue = value >= 0 && value.replace(/(\.\d{2})\d+$/, "$1");
      const isAdding = value.length > formData[name].length;
      if (formData[name]?.length === 3 && isAdding) {
        if (![...value].includes(".")) {
          newValue = false;
        } else {
          newValue = value;
        }
      }
    } else if (["operate_time", "idle_time", "report_time", "minimum_charge_time", "rate_type"].includes(name)) {
      newValue = value >= 0 ? value.replace(/\D/gi, "") : "";
    } else if (["rate_list"].includes(name)) {
      newValue = value.replace(/[^\d:.,]/g, "");
    } else {
      newValue = value;
    }
    return newValue;
  };

  const checkValidationsOnChange = useCallback(
    (target) => {
      let is_form_valid = true;
      const errors = {};
      if (!target?.value?.toString()?.length) {
        is_form_valid = false;
        errors[target.name] = `${_.startCase(target.name).split("_").join(" ")} can't be empty`;
      } else {
        is_form_valid = true;
        errors[target.name] = "";
      }

      setFormData((prevState) => ({
        ...prevState,
        is_form_valid,
        errors: { ...prevState.errors, ...errors },
      }));
      return is_form_valid;
    },
    [formData],
  );

  const showError = (error_type) => {
    const { type, errors } = formData;
    let valid = true;
    let errorMsg = "";

    if (errors?.[error_type]) {
      valid = false;
      errorMsg = errors[error_type];
    }

    if (type === "Settings") {
      const settingsErrors = [
        "minimum_rate",
        "operate_time",
        "idle_time",
        "door_password",
        "battery_low",
        "report_time",
        "minimum_charge_time",
        "minimum_charge_rate",
        "operating_hours",
        "sleep_time",
      ];
      if (settingsErrors.includes(error_type) && errors && errors[error_type]) {
        valid = false;
        errorMsg = errors[error_type];
      }
    }

    if (type === "Rate") {
      const rateErrors = ["rate_list", "rate_max"];
      if (rateErrors.includes(error_type) && errors && errors[error_type]) {
        valid = false;
        errorMsg = errors[error_type];
      }
    }

    if (!valid) {
      return <div className="help-block">{errorMsg}</div>;
    }

    return true;
  };

  const togglePassword = () => {
    setFormData((prev) => ({
      ...prev,
      contentvisible: !formData.contentvisible,
    }));
  };

  return (
    <div>
      <Modal lg="medium" show={modelOpenClose} backdrop="static" onHide={handleClose} dialogClassName="modal-90w" fullscreen="sm-down">
        <Card.Header className="d-flex justify-content-between bg-white align-items-center">
          <h4 className="m-0 font-weight-bold">
            <Card.Title className="m-0">Add Meter Configuration</Card.Title>
          </h4>
          <Button title="Deactivate" type="button" onClick={handleClose} className="close-btn py-0" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove text-danger" />
          </Button>
        </Card.Header>
        <ModalBody>
          <Row className="px-2">
            <Col className="sm-12">
              <Form.Label className="mb-0">
                Config Type
                <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control as="select" disabled={formData.disableType} name="type" placeholder="Device Type" value={formData.type} onChange={handleChange}>
                {(configTypeList ?? []).map((e, key) => {
                  return (
                    <option key={e.id} value={e.value}>
                      {e.text}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Row>
          <Row className="px-2">
            <Col className="sm-12">
              <Form.Label className="mb-0">
                Server Id
                <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control as="select" name="server_id" placeholder="Server Id" value={formData.server_id} onChange={handleChange}>
                <option value="">--Select Server Id--</option>
                {(Array.isArray(allServerId) ? allServerId : []).map((e, key) => {
                  return (
                    <option key={e.id} value={e.serial_number}>
                      {e.serial_number}
                    </option>
                  );
                })}
              </Form.Control>
              {showError("server_id")}
            </Col>
          </Row>
          {formData.type === "Settings" ? (
            <MeterConfigSection formData={formData} showError={showError} handleChange={handleChange} togglePassword={togglePassword} />
          ) : (
            <MeterRateSection formData={formData} showError={showError} handleChange={handleChange} />
          )}
          <Row className="px-2">
            <Col className="sm-12">
              <Form.Label className="mb-0">
                Rate Type
                <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                placeholder="Rate Type"
                type="text"
                name="rate_type"
                value={formData.rate_type}
                onChange={handleChange}
                autoComplete="off"
                required
                maxLength={2}
              />
              {showError("rate_type")}
            </Col>
          </Row>
          <Row className="px-2">
            <Col className="sm-12">
              <Form.Label className="mb-0">
                Description
                <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                placeholder="description"
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                autoComplete="off"
                required
                maxLength={30}
              />
              {showError("description")}
            </Col>
          </Row>
          <Row className="px-2 mt-2 m">
            <Col md="auto" className="pr-0">
              <Form.Group className="d-flex align-items-center">
                <Form.Check
                  type="switch"
                  name="active"
                  onChange={handleChange}
                  id="active"
                  checked={formData.active}
                  value={formData.active}
                  className="ml-2 mt-3"
                  label={"Active"}
                  required
                />
              </Form.Group>
            </Col>

            {formData.type === "Settings" && (
              <>
                {" "}
                <Col md="auto" className="pr-0">
                  <Form.Group className="d-flex align-items-center">
                    <Form.Check
                      type="switch"
                      name="time_sleep"
                      onChange={handleChange}
                      id="time_sleep"
                      checked={formData.time_sleep}
                      value={formData.time_sleep}
                      className="ml-2 mt-3"
                      label={"Time Sleep"}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md="auto" className="pr-0">
                  <Form.Group className="d-flex align-items-center">
                    <Form.Check
                      type="switch"
                      name="bill_enable"
                      onChange={handleChange}
                      id="bill_enable"
                      checked={formData.bill_enable}
                      value={formData.bill_enable}
                      className="ml-2 mt-3"
                      label={"Bill Enable"}
                      required
                    />
                  </Form.Group>
                </Col>
              </>
            )}
          </Row>
        </ModalBody>
        <Modal.Footer>
          <Button className="btn-fill" type="button" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="btn-fill" disabled={!formData.is_form_valid} disab type="button" variant="info" onClick={handleAddFields}>
            {meterDeviceData?.id ? "Edit" : "Add"} Meter Configuration
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

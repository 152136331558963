import React, { Component } from "react";
import { Button, Card, Form, Container, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import { ResidentsActions, SuperAdminAction } from "../../Actions";
import Loader from "../Loader/Loader";
import AddSuiteModal from "../Modal/AddSuiteModal";
import styled from "styled-components";
import { SRLWrapper } from "simple-react-lightbox";
import CustomContentAlert, { tableHeightScrollBars, findBySearch, utcToSpecificTimeZone, AddTimeInDate } from "../helper";
import PaginationforData from "../pagination/pagination";
import NoImage from "../../../assets/img/No-Image.svg";
import { saveAs } from "file-saver";
import moment from "moment";

class LRPReport extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const datebeforeSevenDays = today.setDate(today.getDate() - 3);
    this.state = {
      searchValue: "",
      permit_start_date: new Date(datebeforeSevenDays),
      permit_end_date: new Date(),
      startDate: moment(new Date(datebeforeSevenDays)).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      PropertyId: this.props.PropertyId,
      lprReportLots: [],
      stateList: [],
      lprReportLanes: [],
      loader: false,
      lot_id: "ANY",
      yellow_or_white: "ANY",
      lpn: "LPN",
      lane_id: "ANY",
      plate: "both",
      currentLPR: [],
      isModalOpen: false,
      modalData: {},
      awarenessLPR: [],
      systemLPRList: [],
      plate_sidesList: [],
      api_response: true,
      lpnList: [],
      prevSearch: "",
      prevPage: "",
      prevNorow: "",
      page: 1,
      Norow: 100,
      selectedColumn: "agent_time",
      asc: "false",
      sort_direction: "DESC",
      pageChanged: false,
      newLpr: [],
      findMore: false,
      count: 0,
      count2: 0,
      finalTableHeight: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      selectedDays: 0,
      daysDifference: 7,
      plate_sides: [
        { text: "Any", value: "both" },
        { text: "Front", value: "front" },
        { text: "Rear", value: "rear" },
      ],
      currentDate: "",
      currentStartTime: "",
      currentEndTIme: "",
    };

    let sortableCol = true;
    if (this.state.loader) {
      sortableCol = false;
    }

    this.options = {
      buttons: {
        showAutoplayButton: false,
        showCloseButton: true,
        showDownloadButton: false,
        showFullscreenButton: false,
        showNextButton: true,
        showPrevButton: true,
        showThumbnailsButton: false,
      },
      ProgressBar: {
        showProgressBar: false,
      },
      thumbnails: {
        showThumbnails: false,
      },
    };
    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "IMAGE",
        width: "80px",
        cell: (row) => this.showImageField(row),
        selector: (row) => row.plate,
      },
      {
        name: "LPR TIME",
        width: "160px",
        sortable: sortableCol,
        columnName: "agent_time",
        // cell: (row) => <div>{row.created_at}</div>,
        cell: (row) => this.showCellValue(row.created_at, "time"),
        selector: (row) => row.created_at,
      },
      {
        name: "LPN",
        width: "100px",
        sortable: sortableCol,
        columnName: "lpn",
        // cell: (row) => <div>{row.lpn}</div>,
        cell: (row) => this.showLpnField(row),
        selector: (row) => row.lpn,
      },
      {
        name: "PLATE",
        width: "80px",
        sortable: sortableCol,
        columnName: "cam_plate",
        cell: (row) => this.showPlateValue(row.cam_plate),
        selector: (row) => row.cam_plate,
      },
      {
        name: "LANE",
        width: "160px",
        sortable: sortableCol,
        columnName: "lane_name",
        // cell: (row) => <div>{row.lane_name}</div>,
        cell: (row) => this.showLaneNameField(row),
        selector: (row) => row.lane_name,
      },
      {
        name: "DIRECTION",
        width: "100px",
        sortable: sortableCol,
        columnName: "entry_car",
        // cell: (row) => <div>{row.entry_car}</div>,
        cell: (row) => this.showDiractionField(row),
        selector: (row) => row.entry_car,
      },
      {
        name: "STATUS",
        width: "130px",
        sortable: sortableCol,
        columnName: "white",
        cell: (row) => this.showStatusField(row),
        selector: (row) => row.validation,
      },
      {
        name: "NOTE",
        columnName: "note",
        cell: (row) => this.showNoteField(row),
        selector: (row) => row.note,
      },
    ];

    this.columnsAwareness = [
      // {
      //   name: "LOG TIME",
      //   sortable: true,
      //   cell: (row) => (
      //     <div>{tzDate(this.state.timezone, row.created_at)}</div>
      //   ),
      //   selector: (row) => row.created_at,
      // },
      // {
      //   name: "LPN",
      //   sortable: true,
      //   cell: (row) => <div>{row.lponly}</div>,
      //   selector: (row) => row.lponly,
      // },
      // {
      //   name: "DIRECTION",
      //   sortable: true,
      //   cell: (row) => {
      //     if (row.camera_direction == null) {
      //       return "blank";
      //     } else {
      //       return <div>{row.camera_direction}</div>;
      //     }
      //   },
      //   selector: (row) => row.camera_direction,
      // },
      // {
      //   name: "OBJECT",
      //   sortable: true,
      //   cell: (row) => {
      //     if (row.object_type == null) {
      //       return "blank";
      //     } else {
      //       return <div>{row.object_type}</div>;
      //     }
      //   },
      //   selector: (row) => row.object_type,
      // },
      // {
      //   name: "SPEED",
      //   sortable: true,
      //   cell: (row) => {
      //     if (row.speed == null) {
      //       return "blank";
      //     } else {
      //       return row.speed + " (KM/H)";
      //     }
      //   },
      //   selector: (row) => row.speed,
      // },
      // {
      //   name: "VEND TYPE",
      //   sortable: true,
      //   cell: (row) => {
      //     if (row.vendtype == null) {
      //       return "blank";
      //     } else {
      //       return <div>{row.vendtype}</div>;
      //     }
      //   },
      //   selector: (row) => row.vendtype,
      // },
      // {
      //   name: "VEND REASON",
      //   sortable: true,
      //   cell: (row) => {
      //     if (row.vendreason == null) {
      //       return "blank";
      //     } else {
      //       return <div>{row.vendreason}</div>;
      //     }
      //   },
      //   selector: (row) => row.vendreason,
      // },
      // {
      //   name: "NOTE",
      //   sortable: true,
      //   cell: (row) => <div>{row.note}</div>,
      //   selector: (row) => row.note,
      // },
      // {
      //   name: "STATUS",
      //   sortable: true,
      //   cell: (row) => (
      //     <div dangerouslySetInnerHTML={{ __html: row.validation }} />
      //   ),
      //   selector: (row) => row.validation,
      // },
    ];
  }

  checkLpn = (lpn) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/current-occupancy`,
      search: `audit-report&${lpn}`,
    });
  };

  showNoteField = (row) => (
    <div className="text-truncate-2">
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.note ? row.note : "-"}</Tooltip>}>
        <div className="text-truncate-2">{row.note ? row.note : "-"}</div>
      </OverlayTrigger>
    </div>
  );

  showStatusField = (row) => {
    if (row.validation === "<font color='red'>&#128683; No Permit </font>") {
      return (
        <div className="text-truncate text-nowrap">
          <label dangerouslySetInnerHTML={{ __html: row.validation }} onClick={() => this.AddSuite(row)} />
        </div>
      );
    } else {
      return <div className="text-truncate text-nowrap" dangerouslySetInnerHTML={{ __html: row.validation }} />;
    }
  };

  showDiractionField = (row) => (
    <div className="text-truncate">
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.entry_car ? row.entry_car : "-"}</Tooltip>}>
        <div className="text-truncate text-nowrap">{row.entry_car ? row.entry_car : "-"}</div>
      </OverlayTrigger>
    </div>
  );

  showLaneNameField = (row) => (
    <div className="text-truncate-2">
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.lane_name ? row.lane_name : "-"}</Tooltip>}>
        <div className="text-truncate text-nowrap">{row.lane_name ? row.lane_name : "-"}</div>
      </OverlayTrigger>
    </div>
  );

  showLpnField = (row) => (
    <div className="curserPointer text-truncate text-nowrap" onClick={() => this.checkLpn(row.lpn)}>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.lpn ? row.lpn : "-"}</Tooltip>}>
        <div className="text-truncate text-nowrap">{row.lpn}</div>
      </OverlayTrigger>
    </div>
  );

  showImageField = (row) => {
    const alt = this.extractContent(row.lightbox_link);
    return (
      <a href={row.car_image_link || row.plate || NoImage}>
        <img
          src={row.plate || row.car_image_link ? row.plate || row.car_image_link : NoImage}
          className="img-fluid no-image-available"
          alt={alt}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = NoImage;
          }}
        />
      </a>
    );
  };

  showPlateValue = (cam_plate) => {
    let finalValue = "Front";
    if (cam_plate === "rear") {
      finalValue = "Rear";
    }
    return (
      <>
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{finalValue}</Tooltip>}>
          <div className="text-truncate text-nowrap">{finalValue}</div>
        </OverlayTrigger>
      </>
    );
  };

  showCellValue = (cellValue, valueType) => {
    switch (valueType) {
      case "text":
        return (
          <>
            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{cellValue ? cellValue : "-"}</Tooltip>}>
              <div className="text-truncate text-nowrap">{cellValue ? cellValue : "-"}</div>
            </OverlayTrigger>
          </>
        );
      case "amount":
        return (
          <>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement={"top"}
              overlay={<Tooltip id={`tooltip-qw`}>{cellValue ? Math.max(0, parseFloat(cellValue)).toFixed(2) : "-"}</Tooltip>}
            >
              <div className="text-truncate text-nowrap">{cellValue ? Math.max(0, parseFloat(cellValue)).toFixed(2) : "-"}</div>
            </OverlayTrigger>
          </>
        );
      case "time":
        return (
          <>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement={"top"}
              overlay={<Tooltip id={`tooltip-qw`}>{cellValue ? utcToSpecificTimeZone(this.props.timezone, cellValue, true) : "-"}</Tooltip>}
            >
              <div className="text-truncate text-nowrap">{cellValue ? utcToSpecificTimeZone(this.props.timezone, cellValue, true) : "-"}</div>
            </OverlayTrigger>
          </>
        );
      default:
        break;
    }
  };

  extractContent(s) {
    const span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  componentDidMount = () => {
    const { startTimeFormat, EndTimeFormat } = AddTimeInDate(this.state.startDate, this.state.endDate);
    this.setState({ loader: true, startDate: startTimeFormat, endDate: EndTimeFormat }, async () => {
      const {
        PropertyId,
        lot_id,
        lane_id,
        permit_start_date,
        permit_end_date,
        yellow_or_white,
        lpn,
        plate,
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        timezone,
        currentDate,
      } = this.state;
      const pageobj = {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
      };
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        selectedDomainName: selectedDomainName,
      });
      const current_lot = await this.lotLaneApiCall();
      const current_lanes = this.props.currentLane;
      await this.props.LprReportListAPI(
        PropertyId,
        lot_id,
        lane_id,
        permit_start_date,
        permit_end_date,
        timezone,
        lpn,
        yellow_or_white,
        plate,
        pageobj,
        currentDate,
      );
      console.log("this.props.timezone:::", this.props.timezone);
      const compare = "AddedAwareness";
      const current_lpr = this.props.currentLPR;
      if (lpn.localeCompare(compare) > 0) {
        this.setState({
          currentLPR: current_lpr.lpr?.data,
          api_response: this.props.api_response,
          count: current_lpr.lpr?.count,
        });
      } else {
        this.setState({
          awarenessLPR: current_lpr.lpr?.data,
          api_response: this.props.api_response,
          count2: current_lpr.lpr?.count,
        });
      }
      this.setState({
        loader: false,
        stateList: current_lpr.status,
        plate_sidesList: current_lpr.plate_sides,
        // lprReportLots: this.props.lprReportLots,
        lprReportLots: current_lot,
        // lprReportLanes: this.props.lprReportLanes,
        lprReportLanes: current_lanes,
        lpnList: current_lpr.plates,
      });
    });
    const height = tableHeightScrollBars();

    this.setState({
      finalTableHeight: height,
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.currentLane, this.props.currentLane)) {
      this.setState({
        lprReportLanes: this.props.currentLane,
      });
    }
    if (!isEqual(prevProps.lots, this.props.lots)) {
      this.setState({
        lprReportLots: this.props.lots,
      });
    }
  };

  handleSearchChange = ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();

    this.setState({ prevSearch: this.state.searchValue });
    this.setState({ searchValue: val }, () => {
      const columnList = ["created_at", "lpn", "cam_plate", "lane_name", "entry_car", "validation"];
      if (!this.state.findMore) {
        this.setState(
          {
            pageChanged: true,
            newLpr: findBySearch(this.state.searchValue, this.props.currentLPR?.lpr?.data, this.props.currentLPR?.lpr?.data, columnList, this.props.timezone),
          },
          () => {
            if (target.value !== "") {
              this.setState({
                count: this.state.newLpr?.length,
              });
            } else if (target.value === "") {
              this.handleSearch();
            }
          },
        );
      }
    });
  };

  handlepage = ({ target }) => {
    this.setState({
      prevPage: this.state.page,
      prevNorow: this.state.Norow,
      page: 1,
    });
    this.setState(
      {
        [target.name]: target.value,
      },
      async () => {
        switch (target.name) {
          case "page":
            this.setState({ page: this.state.page }, async () => {
              const {
                PropertyId,
                lot_id,
                lane_id,
                permit_start_date,
                permit_end_date,
                lpn,
                yellow_or_white,
                plate,
                page,
                Norow,
                searchValue,
                selectedColumn,
                sort_direction,
                timezone,
                currentDate,
              } = this.state;
              const pageobj = {
                page,
                Norow,
                searchValue,
                selectedColumn,
                sort_direction,
              };
              const direction = plate;
              this.setState({ dataTableLoading: true, pageChanged: true }, async () => {
                await this.props.LprReportListAPI(
                  PropertyId,
                  lot_id,
                  lane_id,
                  permit_start_date,
                  permit_end_date,
                  timezone,
                  lpn,
                  yellow_or_white,
                  direction,
                  pageobj,
                  currentDate,
                );
                this.setState({
                  newLpr: await this.props.currentLPR.lpr.data,
                  api_response: this.props.api_response,
                });
                this.setState({ dataTableLoading: false });
              });
            });
            break;
          case "Norow":
            this.setState({ Norow: target.value, pageChanged: true }, async () => {
              const {
                PropertyId,
                lot_id,
                lane_id,
                permit_start_date,
                permit_end_date,
                lpn,
                yellow_or_white,
                plate,
                page,
                Norow,
                searchValue,
                selectedColumn,
                sort_direction,
                timezone,
                currentDate,
              } = this.state;
              const pageobj = {
                page,
                Norow,
                searchValue,
                selectedColumn,
                sort_direction,
              };
              const direction = plate;
              this.setState({ dataTableLoading: true }, async () => {
                await this.props.LprReportListAPI(
                  PropertyId,
                  lot_id,
                  lane_id,
                  permit_start_date,
                  permit_end_date,
                  timezone,
                  lpn,
                  yellow_or_white,
                  direction,
                  pageobj,
                  currentDate,
                );
                this.setState({
                  newLpr: await this.props.currentLPR.lpr.data,
                  api_response: this.props.api_response,
                  dataTableLoading: false,
                });
              });
            });
            break;
        }
        if (Number(this.state.page) !== Number(this.state.prevPage) || Number(this.state.Norow) !== Number(this.state.prevNorow)) {
          const {
            PropertyId,
            lot_id,
            lane_id,
            permit_start_date,
            permit_end_date,
            lpn,
            yellow_or_white,
            plate,
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            timezone,
            currentDate,
          } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          const direction = plate;
          this.setState({ dataTableLoading: true, pageChanged: true }, async () => {
            await this.props.LprReportListAPI(
              PropertyId,
              lot_id,
              lane_id,
              permit_start_date,
              permit_end_date,
              timezone,
              lpn,
              yellow_or_white,
              direction,
              pageobj,
              currentDate,
            );
            this.setState({
              newLpr: await this.props.currentLPR.lpr.data,
              api_response: this.props.api_response,
            });
            this.setState({ dataTableLoading: false });
          });
        }
      },
    );
  };

  handlepagenext = async () => {
    const { page, Norow } = this.state;
    const count = await this.props.currentLPR.lpr.count;
    const finalPage = Math.ceil(count / Norow);
    if (page + 1 <= finalPage) {
      this.setState({ page: page + 1 }, async () => {
        const {
          PropertyId,
          lot_id,
          lane_id,
          permit_start_date,
          permit_end_date,
          lpn,
          yellow_or_white,
          plate,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          timezone,
          currentDate,
        } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        const direction = plate;
        this.setState({ dataTableLoading: true, pageChanged: true }, async () => {
          await this.props.LprReportListAPI(
            PropertyId,
            lot_id,
            lane_id,
            permit_start_date,
            permit_end_date,
            timezone,
            lpn,
            yellow_or_white,
            direction,
            pageobj,
            currentDate,
          );
          this.setState({
            newLpr: await this.props.currentLPR.lpr.data,
            api_response: this.props.api_response,
            dataTableLoading: false,
          });
        });
      });
    }
  };

  handlepageprevious = () => {
    const { page } = this.state;
    if (page !== 1) {
      this.setState({ page: page - 1 }, async () => {
        const {
          PropertyId,
          lot_id,
          lane_id,
          permit_start_date,
          permit_end_date,
          lpn,
          yellow_or_white,
          plate,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          timezone,
          currentDate,
        } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        const direction = plate;
        this.setState({ dataTableLoading: true, pageChanged: true }, async () => {
          await this.props.LprReportListAPI(
            PropertyId,
            lot_id,
            lane_id,
            permit_start_date,
            permit_end_date,
            timezone,
            lpn,
            yellow_or_white,
            direction,
            pageobj,
            currentDate,
          );
          this.setState({
            newLpr: await this.props.currentLPR.lpr.data,
            api_response: this.props.api_response,
            dataTableLoading: false,
          });
        });
      });
    }
  };

  handlepageinitial = () => {
    if (this.state.page !== 1) {
      this.setState({ page: 1 }, async () => {
        const {
          PropertyId,
          lot_id,
          lane_id,
          permit_start_date,
          permit_end_date,
          lpn,
          yellow_or_white,
          plate,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          timezone,
          currentDate,
        } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        const direction = plate;
        this.setState({ dataTableLoading: true, pageChanged: true }, async () => {
          await this.props.LprReportListAPI(
            PropertyId,
            lot_id,
            lane_id,
            permit_start_date,
            permit_end_date,
            timezone,
            lpn,
            yellow_or_white,
            direction,
            pageobj,
            currentDate,
          );
          this.setState({
            newLpr: await this.props.currentLPR.lpr.data,
            api_response: this.props.api_response,
          });
          this.setState({ dataTableLoading: false });
        });
      });
    }
  };

  handlepagefinal = async () => {
    const { page, Norow } = this.state;
    const count = await this.props.currentLPR.lpr.count;
    this.setState({
      count: count,
    });
    const last_page = count / Norow;
    const rounded = Math.round(last_page);
    if (page < rounded) {
      this.setState({ page: rounded }, async () => {
        const {
          PropertyId,
          lot_id,
          lane_id,
          permit_start_date,
          permit_end_date,
          lpn,
          yellow_or_white,
          plate,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          timezone,
          currentDate,
        } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        const direction = plate;
        this.setState({ dataTableLoading: true, pageChanged: true }, async () => {
          await this.props.LprReportListAPI(
            PropertyId,
            lot_id,
            lane_id,
            permit_start_date,
            permit_end_date,
            timezone,
            lpn,
            yellow_or_white,
            direction,
            pageobj,
            currentDate,
          );
          this.setState({
            newLpr: await this.props.currentLPR.lpr.data,
            api_response: this.props.api_response,
            dataTableLoading: false,
          });
        });
      });
    }
  };

  handleChangeStartingDates = (date) => {
    if (date) {
      const { daysDifference } = this.state;
      const end = new Date(date);
      end.setDate(date.getDate() + daysDifference);
      let permit_end_date = new Date();
      if (new Date() >= end) {
        permit_end_date = end;
      }
      this.setState({
        permit_start_date: date,
        permit_end_date: permit_end_date,
        currentDate: null,
        currentStartTime: null,
        currentEndTIme: null,
        loader: false,
      });
    }
  };

  handleChangeEndDates = (date) => {
    if (date) {
      const { daysDifference, permit_start_date } = this.state;
      const start = new Date(date);
      start.setDate(date.getDate() - daysDifference);
      let startDateSelection;
      if (permit_start_date) {
        startDateSelection = permit_start_date;
      } else {
        startDateSelection = start;
      }
      this.setState({
        permit_end_date: date,
        permit_start_date: startDateSelection,
        currentDate: null,
        currentStartTime: null,
        currentEndTIme: null,
        loader: false,
      });
    }
  };

  addDays = (date, days) => {
    let endDate = new Date();
    if (date) {
      const end = new Date(date);
      end.setDate(date.getDate() + days);
      if (new Date() >= end) {
        endDate = end;
      }
    }
    return endDate;
  };

  handleFilterByDate = (date) => {
    this.setState({
      currentDate: date,
      permit_end_date: "",
      permit_start_date: "",
      currentStartTime: new Date(new Date().setHours(0, 0, 0, 0)),
      currentEndTIme: new Date(new Date().setHours(23, 59)),
    });
  };

  handleCurrentTimeDate = (date) => {
    console.log("handleCurrentTimeDatee", date);
    this.setState({
      currentStartTime: date,
      currentEndTIme: new Date(new Date().setHours(23, 59)),
    });
  };

  handleCurrentEndTimeDate = (date) => {
    console.log("handleCurrentEndTimeDate", date);
    this.setState({
      currentEndTIme: date,
    });
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleChangeLot = async ({ target }) => {
    this.setState({ [target.name]: target.value });
    if (target.value !== "ANY") {
      await this.props.get_all_lanes([target.value]);
    } else {
      await this.lotLaneApiCall();
      const current_lanes = this.props.currentLane;
      this.setState({
        lprReportLanes: current_lanes,
        lane_id: "ANY",
      });
    }
  };

  handleChangeLanes = async ({ target }) => {
    this.setState({ [target.name]: target.value });
    const params = {
      lane_id: target.value,
      property_id: this.props.PropertyId,
    };
    await this.props.get_all_lots_by_lanes(params);
    if (target.value === "ANY") {
      this.setState({
        lot_id: "ANY",
      });
      await this.lotLaneApiCall();
      const current_lanes = this.props.currentLane;
      this.setState({
        lprReportLanes: current_lanes,
        lane_id: "ANY",
      });
    } else {
      const currenLot = this.props.lots;
      this.setState(
        {
          lot_id: currenLot[0].id,
        },
        async () => {
          await this.props.get_all_lanes([currenLot[0].id]);
        },
      );
    }
  };

  handleSearch = () => {
    this.setState({ dataTableLoading: true, pageChanged: true, page: 1, Norow: 100 }, async () => {
      const {
        PropertyId,
        permit_start_date,
        permit_end_date,
        lot_id,
        lpn,
        yellow_or_white,
        lane_id,
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        plate,
        timezone,
        currentDate,
        currentStartTime,
        currentEndTIme,
      } = this.state;
      const pageobj = {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
      };
      const direction = plate;
      await this.props.LprReportListAPI(
        PropertyId,
        lot_id,
        lane_id,
        permit_start_date,
        permit_end_date,
        timezone,
        lpn,
        yellow_or_white,
        direction,
        pageobj,
        currentDate,
        currentStartTime,
        currentEndTIme,
      );
      this.valueSetStartEndDate(this.state);
      const compare = "AddedAwareness";
      const current_lpr = this.props.currentLPR;
      if (lpn.localeCompare(compare) > 0) {
        this.setState({
          newLpr: current_lpr?.lpr?.data,
          loader: false,
          dataTableLoading: false,
          api_response: this.props.api_response,
          count: current_lpr?.lpr?.count,
        });
      } else {
        this.setState({
          awarenessLPR: current_lpr?.lpr?.data,
          loader: false,
          dataTableLoading: false,
          count2: current_lpr?.lpr?.count,
          api_response: this.props.api_response,
        });
      }
    });
    document.body.classList.remove("foc-open");
  };

  valueSetStartEndDate = (state) => {
    let startDate, endDate;
    const { currentDate, currentStartTime, currentEndTIme, permit_start_date, permit_end_date } = state;
    if (currentDate) {
      const { startTimeFormat, EndTimeFormat } = AddTimeInDate(currentDate, currentDate, currentStartTime, currentEndTIme);
      startDate = startTimeFormat;
      endDate = EndTimeFormat;
    } else if (permit_start_date && permit_end_date) {
      const { startTimeFormat, EndTimeFormat } = AddTimeInDate(permit_start_date, permit_end_date);
      startDate = startTimeFormat;
      endDate = EndTimeFormat;
    }
    this.setState({
      startDate: startDate,
      endDate: endDate,
    });
  };

  AddSuite = (item) => {
    this.setState({ modalData: item }, () => this.handleModalOpenClose());
  };

  handleModalOpenClose = () => this.setState({ isModalOpen: !this.state.isModalOpen });

  handleDone = async (obj) => {
    const data = {
      ...obj,
      vehicle_make: "-",
      vehicle_model: "-",
      vehicle_color: "-",
      vehicle_license: obj.lpn,
      phone: obj.contact,
      suite_number: obj.suite,
    };
    await this.props.visitorCreate(
      this.state.PropertyId,
      obj.permit_start_date,
      obj.permit_end_date,
      this.props.allLots,
      this.props.uid,
      this.props.company_id,
      data,
    );
    this.handleModalOpenClose();
  };

  findMorePressed = () => {
    this.setState({ findMore: true, page: 1 }, () => {
      if (this.state.findMore) {
        if (this.state.searchValue.length) {
          const {
            PropertyId,
            lot_id,
            lane_id,
            permit_start_date,
            permit_end_date,
            lpn,
            yellow_or_white,
            plate,
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            timezone,
            currentDate,
          } = this.state;

          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          this.setState({ dataTableLoading: true, pageChanged: true }, async () => {
            await this.props.LprReportListAPI(
              PropertyId,
              lot_id,
              lane_id,
              permit_start_date,
              permit_end_date,
              timezone,
              lpn,
              yellow_or_white,
              plate,
              pageobj,
              currentDate,
            );
            const compare = "AddedAwareness";
            const current_lpr = this.props.currentLPR;
            if (lpn.localeCompare(compare) > 0) {
              this.setState({
                pageChanged: true,
                newLpr: current_lpr?.lpr?.data,
                loader: false,
                dataTableLoading: false,
                api_response: this.props.api_response,
                count: current_lpr?.lpr?.count,
              });
            } else {
              this.setState({
                pageChanged: true,
                awarenessLPR: current_lpr?.lpr?.data,
                loader: false,
                dataTableLoading: false,
                count2: current_lpr?.lpr?.count,
                api_response: this.props.api_response,
              });
            }
            this.setState({ loader: false });
          });
          setTimeout(() => {
            this.setState({ findMore: false });
          }, 1000);
        } else {
          const {
            PropertyId,
            lot_id,
            lane_id,
            permit_start_date,
            permit_end_date,
            lpn,
            yellow_or_white,
            plate,
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
            timezone,
            currentDate,
          } = this.state;
          const pageobj = {
            page,
            Norow,
            searchValue,
            selectedColumn,
            sort_direction,
          };
          this.setState({ dataTableLoading: true, pageChanged: true }, async () => {
            await this.props.LprReportListAPI(
              PropertyId,
              lot_id,
              lane_id,
              permit_start_date,
              permit_end_date,
              timezone,
              lpn,
              yellow_or_white,
              plate,
              pageobj,
              currentDate,
            );
            const compare = "AddedAwareness";
            const current_lpr = this.props.currentLPR;
            if (lpn.localeCompare(compare) > 0) {
              this.setState({
                pageChanged: true,
                page: 1,
                tab2Page: 1,
                newLpr: current_lpr?.lpr?.data,
                loader: false,
                dataTableLoading: false,
                api_response: this.props.api_response,
                count: current_lpr?.lpr?.count,
              });
            } else {
              this.setState({
                pageChanged: true,
                page: 1,
                tab2Page: 1,
                awarenessLPR: current_lpr?.lpr?.data,
                loader: false,
                dataTableLoading: false,
                count2: current_lpr?.lpr?.count,
                api_response: this.props.api_response,
              });
            }
            this.setState({ dataTableLoading: false });
          });
          setTimeout(() => {
            this.setState({ findMore: false });
          }, 1000);
        }
      }
    });
  };

  SortLPRReportData(column, sortDirection) {
    const columnSort = column.columnName;
    console.log("----column", columnSort, sortDirection);
    if (columnSort) {
      const stateValue = {
        selectedColumn: columnSort,
        sort_direction: sortDirection,
        dataTableLoading: true,
      };
      this.setState(stateValue, async () => {
        const {
          PropertyId,
          permit_start_date,
          permit_end_date,
          lot_id,
          lpn,
          yellow_or_white,
          lane_id,
          page,
          Norow,
          searchValue,
          plate,
          timezone,
          currentDate,
        } = this.state;
        const pageobj = {
          page,
          Norow,
          searchValue,
          selectedColumn: columnSort,
          sort_direction: sortDirection,
        };
        const direction = plate;
        await this.props.LprReportListAPI(
          PropertyId,
          lot_id,
          lane_id,
          permit_start_date,
          permit_end_date,
          timezone,
          lpn,
          yellow_or_white,
          direction,
          pageobj,
          currentDate,
        );
        this.setState({
          newLpr: await this.props.currentLPR.lpr.data,
          api_response: this.props.api_response,
          dataTableLoading: false,
        });
      });
    }
  }

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  lotLaneApiCall = async () => {
    await this.props.get_all_lots(this.state.PropertyId);
    const current_lot = this.props.lots;
    if (current_lot?.length) {
      const lot_ids = current_lot.map((el) => {
        return el.id;
      });
      await this.props.get_all_lanes(lot_ids);
    }
    return current_lot;
  };

  handleResetAll = async () => {
    const { daysDifference } = this.state;
    const today = new Date();
    const datebeforeSevenDays = today.setDate(today.getDate() - daysDifference);
    await this.lotLaneApiCall();
    const current_lanes = this.props.currentLane;
    this.setState({
      loader: false,
      lot_id: "ANY",
      yellow_or_white: "ANY",
      lpn: "LPN",
      lane_id: "ANY",
      plate: "both",
      searchValue: "",
      page: 1,
      Norow: 100,
      currentDate: "",
      currentStartTime: "",
      lprReportLanes: current_lanes,
      currentEndTIme: "",
      permit_start_date: new Date(datebeforeSevenDays),
      permit_end_date: new Date(),
    });
  };

  handleExport = async () => {
    const {
      PropertyId,
      permit_start_date,
      permit_end_date,
      lot_id,
      lpn,
      yellow_or_white,
      lane_id,
      page,
      Norow,
      searchValue,
      selectedColumn,
      sort_direction,
      plate,
      timezone,
      currentDate,
      currentStartTime,
      currentEndTIme,
    } = this.state;
    const pageobj = {
      page,
      Norow,
      searchValue,
      selectedColumn,
      sort_direction,
      exportValue: true,
    };
    const direction = plate;
    const bufferData = await this.props.LprReportListAPI(
      PropertyId,
      lot_id,
      lane_id,
      permit_start_date,
      permit_end_date,
      timezone,
      lpn,
      yellow_or_white,
      direction,
      pageobj,
      currentDate,
      currentStartTime,
      currentEndTIme,
    );
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `LPR-REPORT-${currentTimeDate}-${this.state.selectedDomainName}.xls`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  getLprResult = (searchValue, pageChanged, awarenessLPR, newLpr, currentLPR) => {
    if (searchValue?.length || pageChanged) {
      if (awarenessLPR?.length > 0) {
        return awarenessLPR;
      } else {
        return newLpr;
      }
    } else {
      if (awarenessLPR?.length > 0) {
        return awarenessLPR;
      } else {
        return currentLPR;
      }
    }
  };

  render() {
    const {
      searchValue,
      permit_start_date,
      permit_end_date,
      lprReportLots,
      lprReportLanes,
      currentLPR,
      newLpr,
      pageChanged,
      loader,
      isModalOpen,
      modalData,
      stateList,
      awarenessLPR,
      api_response,
      // plate_sidesList,
      lpnList,
      page,
      dataTableLoading,
      Norow,
      lot_id,
      lane_id,
      plate_sides,
      lpn,
      currentStartTime,
      currentEndTIme,
      currentDate,
      daysDifference,
      yellow_or_white,
      plate,
      startDate,
      endDate,
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              {/* Sidebar Start */}
              <div className="sidebarFilter">
                <Card className="contact-container mb-0">
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title as="h4">LPR Report</Card.Title>
                      <a
                        onClick={this.hideSlider}
                        href="javascript:void(0);"
                        className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Specific Date:</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Date"
                            selected={currentDate}
                            value={currentDate}
                            disabled={loader}
                            selectsStart
                            onChange={this.handleFilterByDate}
                            dateFormat="MM/dd/yyyy"
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Start Time:</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Date"
                            selected={currentStartTime}
                            value={currentStartTime}
                            disabled={loader || !currentDate}
                            selectsStart
                            onChange={this.handleCurrentTimeDate}
                            // dateFormat="MM/dd/yyyy"
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            selectsEnd={true}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            minTime={new Date(new Date().setHours(0, 0, 0, 0))}
                            maxTime={new Date().setHours(23, 59)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <label className="mb-0 mr-2">End Time:</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Date"
                            selected={currentEndTIme}
                            value={currentEndTIme}
                            disabled={loader || !currentDate}
                            selectsStart
                            onChange={this.handleCurrentEndTimeDate}
                            // dateFormat="MM/dd/yyyy"
                            // maxDate={new Date()}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            selectsEnd={true}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            minTime={new Date(currentStartTime)}
                            maxTime={new Date().setHours(23, 59)}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group>
                          <label className="mb-0 mr-2">Start Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Start Date"
                            selected={permit_start_date}
                            defaultHour={24}
                            value={permit_start_date}
                            disabled={loader}
                            selectsStart
                            onChange={this.handleChangeStartingDates}
                            dateFormat="MM/dd/yyyy"
                            startDate={permit_start_date}
                            endDate={permit_end_date}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group>
                          <label className="mb-0 mr-2">End Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select End Date"
                            selected={permit_end_date}
                            disabled={loader}
                            value={permit_end_date}
                            selectsEnd
                            onChange={this.handleChangeEndDates}
                            dateFormat="MM/dd/yyyy"
                            startDate={permit_start_date}
                            endDate={permit_end_date}
                            minDate={permit_start_date}
                            maxDate={this.addDays(permit_start_date, daysDifference)}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>LOTS:</Form.Label>
                          <Form.Control as="select" name="lot_id" disabled={loader} onChange={this.handleChangeLot} value={lot_id}>
                            {/* {lprReportLots && lprReportLots.length ? ( */}
                            <>
                              <option value="ANY">ANY</option>
                              {(Array.from(lprReportLots) ?? []).map((item) => (
                                <option key={"lpr_" + item.id} value={item.id}>
                                  {item.alias}
                                </option>
                              ))}
                            </>
                            {/* ) : null} */}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>LANES:</Form.Label>
                          <Form.Control as="select" name="lane_id" disabled={loader} onChange={this.handleChangeLanes} value={lane_id}>
                            {lprReportLanes ? (
                              <>
                                <option value="ANY">ANY</option>
                                {lprReportLanes.map((item, index) => (
                                  <option key={"lpr_lanes_" + item.id} value={item.id}>
                                    {item.alias}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>LPR RECORDS:</Form.Label>
                          <Form.Control as="select" name="lpn" disabled={loader} onChange={this.handleChange} value={lpn}>
                            {lpnList ? (
                              <>
                                {lpnList.map((item) => (
                                  <option key={"lpn_" + item?.id} value={item.value}>
                                    {item.text}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>STATUS:</Form.Label>
                          <Form.Control as="select" name="yellow_or_white" disabled={loader} onChange={this.handleChange} value={yellow_or_white}>
                            {stateList ? (
                              <>
                                {stateList.map((item) => (
                                  <option key={"state_" + item?.id} value={item.value}>
                                    {item.text}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>PLATE:</Form.Label>
                          <Form.Control as="select" name="plate" disabled={loader} onChange={this.handleChange} value={plate}>
                            {plate_sides ? (
                              <>
                                {plate_sides.map((item) => (
                                  <option key={"plt_side_" + item?.id} value={item.value}>
                                    {item.text}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-right justify-content-between d-flex">
                    <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.handleResetAll}>
                      Reset
                    </Button>
                    <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                      Search
                    </Button>
                  </Card.Footer>
                </Card>
              </div>
            </Col>
          </Row>

          {loader ? (
            <Loader />
          ) : (
            <>
              {!api_response ? (
                <CustomContentAlert delay={4000} message={"The server did not respond, Please try again after sometime"} className="toast-error" />
              ) : null}
              <Card className="lpr-report-table get-height">
                <CardBody>
                  <div className="lpr-report">
                    <SRLWrapper options={this.options}>
                      <DataTable
                        className="lpr-report-table-height"
                        data={this.getLprResult(searchValue, pageChanged, awarenessLPR, newLpr, currentLPR)}
                        columns={awarenessLPR?.length ? this.columnsAwareness : this.columns}
                        customStyles={this.customStyles}
                        // pagination={true}
                        responsive={true}
                        subHeader
                        paginationPerPage={100}
                        subHeaderComponent={
                          <div className="active-permit-header sm-flex-column sm-align-items-start">
                            <h5 className="mb-2 mb-md-0">LPR Report</h5>
                            <div className="filter-button d-flex flex-wrap">
                              <div className="d-flex align-items-center search-mb-0 mr-2 loc-searchbox">
                                <DatatableHeader
                                  // Header={"LPR Report"}
                                  searchValue={searchValue}
                                  HandleChange={this.handleSearchChange}
                                  findMorePressed={this.findMorePressed}
                                  findMore={true}
                                />
                              </div>
                              {/* <Button onClick={this.findMorePressed}>
                              Get All Result for {searchValue}
                            </Button> */}

                              <div className="export-btn">
                                <Button
                                  variant="info"
                                  className="btn-fill btn-sm"
                                  onClick={this.handleExport}
                                  disabled={!this.props.currentLPR?.lpr?.data?.length > 0}
                                  size="medium"
                                >
                                  Export
                                </Button>
                              </div>
                            </div>
                          </div>
                        }
                        onSort={this.SortLPRReportData.bind(this)}
                        progressPending={!!dataTableLoading}
                        defaultSortField={this.state?.selectedColumn || "LOG TIME"}
                        defaultSortAsc={this.state.sort_direction.toLowerCase() !== "desc"}
                        // persistTableHead
                        fixedHeader={true}
                        fixedHeaderScrollHeight={this.state.finalTableHeight}
                      />
                      {!dataTableLoading && (
                        <PaginationforData
                          count={awarenessLPR && awarenessLPR.length > 0 ? this.state.count2 : this.state.count}
                          data={this.props.currentLPR?.lpr?.data}
                          handlepageinitial={this.handlepageinitial}
                          handlepageprevious={this.handlepageprevious}
                          handlepagenext={this.handlepagenext}
                          handlepagefinal={this.handlepagefinal}
                          handlepage={this.handlepage}
                          page={page}
                          Norow={Norow}
                        />
                      )}
                    </SRLWrapper>
                  </div>
                  <div style={{ fontSize: 13 }} className="pt-3">
                    <Row>
                      <Col lg={12}>
                        <span className="font-weight-600">Period</span>
                        <span className="mr-1"> :</span>
                        {startDate} - {endDate}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
        {isModalOpen ? (
          <AddSuiteModal showBrowserModal={isModalOpen} closeModal={this.handleModalOpenClose} handleSave={this.handleDone} details={modalData} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, ResidenDetails, APIResponseDetails, Logindetails, SuperAdminDetails }) => {
  const { api_response, api_error_message } = APIResponseDetails;
  const {
    property_id: PropertyId,
    company_id,
    uid,
    timezone,
    property,
    // main_body,
  } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { lprReportLots, lprReportLanes, currentLPR, allLots, currentLane } = ResidenDetails;
  const { lots } = SuperAdminDetails;
  return {
    main_body,
    PropertyId,
    lprReportLots,
    company_id,
    uid,
    lprReportLanes,
    currentLPR,
    allLots,
    api_response,
    api_error_message,
    timezone,
    property,
    // backgroundColor,
    lots,
    currentLane,
  };
};

const mapStateToDispatch = {
  LprReportListAPI: ResidentsActions.LprReportListAPI,
  visitorCreate: ResidentsActions.visitorCreate,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  get_all_lots: SuperAdminAction.get_all_lots,
  get_all_lanes: ResidentsActions.get_all_lanes,
  get_all_lots_by_lanes: SuperAdminAction.get_all_lots_by_lanes,
};

export default connect(mapStateToProps, mapStateToDispatch)(LRPReport);
const CardBody = styled(Card.Body)``;

import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import { Button, Col, Row, Card, Spinner, Container } from "react-bootstrap";
import _ from "lodash";
import { DeleteCreditCardDetails, findUnitSettings, getCardDetails, saveCreditCardDetails } from "../../Actions/PermitOptionsActions/actions";
import DeleteCardModal from "../Modal/DeleteCardModal";
import CustomContentAlert, { validateCardNumber } from "../helper";
import moment from "moment";
import { Translation } from "react-i18next";

const initialFormData = {
  user_id: "",

  card_number: null,
  card_expiry: "",
  card_cvv: "",
  card_name: "",
  email: "",

  is_form_valid: "",
  errors: {},
  disable_card_options: false,
  unit_credit_card: false,

  card_details_id: "",
  submitLoader: false,
};

const UpdateCreditCard = (props) => {
  const { language } = props;
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    ...initialFormData,
  });

  const [show_delete_card_model, setshow_delete_card_model] = useState(false);
  const [isDeleteCard, setisDeleteCard] = useState(false);

  const mainBody = useSelector((state) => state.Logindetails.backgroundColor);

  const { api_success_message, api_error_message } = useSelector((state) => state.APIResponseDetails);

  const userType = useSelector((state) => state.UserProfileDetails.user_type);

  const suite_id = useSelector((state) => state.UserProfileDetails?.suite_id);

  const UnitId = useSelector((state) => state.UserProfileDetails?.id);

  console.log("ssssssssssss", suite_id, UnitId);

  const unitPaymentSetting = useSelector((state) => state.PermitOptionsDetails?.unitPaymentSetting);

  console.log("unitPaymentSetting:::::", unitPaymentSetting);

  const property_id = useSelector((state) => state.UserProfileDetails?.property?.id);

  useEffect(() => {
    if (api_success_message === "Card Details deleted successfully!" || api_success_message === "Card Details Saved successfully!") {
      setshow_delete_card_model(false);
      dispatch(getCardDetails(property_id, suite_id));
      setFormData((prevState) => ({
        ...prevState,
        submitLoader: false,
      }));
    }
    if (api_error_message) {
      setFormData({
        ...formData,
        submitLoader: false,
      });
    }
  }, [api_success_message, api_error_message]);

  useEffect(() => {
    if (property_id && suite_id) {
      dispatch(findUnitSettings(property_id, suite_id));
      dispatch(getCardDetails(property_id, suite_id));
    }
  }, [suite_id]);

  const cardDetailData = useSelector((state) => state.PermitOptionsDetails?.cardDetails);
  console.log("cardDetailData:::::", cardDetailData, formData.unit_credit_card);

  useEffect(() => {
    if (cardDetailData && Object.keys(cardDetailData).length) {
      setFormData((prevState) => ({
        ...prevState,
        disable_card_options: true,
        unit_credit_card: false,
        card_name: cardDetailData?.name,
        email: cardDetailData?.email,
        card_number: cardDetailData.card_number, // `************${cardDetailData?.last_four_card_number}`,
        card_expiry: cardDetailData?.expiry_date,
        card_details_id: cardDetailData?.id,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        unit_credit_card: true,
        disable_card_options: false,
        credit_card_name: "",
        email: "",
        card_number: "",
        card_expiry: "",
        card_cvv: "",
        card_details_id: "",
      }));
    }
  }, [cardDetailData]);

  const handleChange = async ({ target }) => {
    const { name, value } = target;

    let newValue = value;

    let Obj = { ...formData };

    if (["card_number", "card_cvv"].includes(name)) {
      newValue = newValue.replace(/[^0-9.]/g, "");
    }

    Obj = { ...Obj, [name]: newValue };

    const mainObj = { ...formData, ...Obj };
    setFormData(mainObj);
    checkValidationsOnChange(target);
  };

  const handleCalender = (date) => {
    const errors = {};
    errors.card_expiry = "";
    if (date) {
      setFormData((prevState) => ({
        ...prevState,
        card_expiry: date,
        errors: { ...prevState.errors, ...errors },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        card_expiry: "",
      }));
    }
  };

  const handleDeleteCard = () => {
    setisDeleteCard(true);
    dispatch(DeleteCreditCardDetails(formData.card_details_id));
  };

  const showError = (type) => {
    const { unit_credit_card } = formData;

    let valid = true;
    let errorMsg = "";

    if (type === "card_name" && formData.errors?.card_name) {
      valid = false;
      errorMsg = formData.errors.card_name;
    }

    if (type === "email" && formData.errors?.email) {
      valid = false;
      errorMsg = formData.errors.email;
    }
    if (type === "card_number" && formData.errors?.card_number) {
      valid = false;
      errorMsg = formData.errors.card_number;
    }
    if (type === "card_expiry" && formData.errors?.card_expiry) {
      valid = false;
      errorMsg = formData.errors.card_expiry;
    }
    if (unit_credit_card) {
      if (type === "card_cvv" && formData.errors?.card_cvv) {
        valid = false;
        errorMsg = formData.errors.card_cvv;
      }
    }
    if (!valid) {
      return <div className="help-block">{errorMsg}</div>;
    }
    return true;
  };

  const checkValidationsOnChange = useCallback(
    async (target) => {
      let is_form_valid = true;
      let errors = {};
      if (!target.value.toString().length) {
        is_form_valid = false;
        errors[target.name] = <Translation>{(t) => <>{t(`${_.startCase(target.name).split("_").join(" ")} can't be empty`)}</>}</Translation>;
      } else {
        is_form_valid = true;
        errors[target.name] = "";
      }

      if (target.name === "card_number") {
        const { error, valid } = await card_number_validation(target);
        errors = {
          ...errors,
          ...error,
        };
        is_form_valid = valid;
      }

      if (target.name === "card_cvv") {
        const { error, valid } = await card_cvv_validation(target);
        errors = {
          ...errors,
          ...error,
        };
        is_form_valid = valid;
      }

      setFormData((prevState) => ({
        ...prevState,
        is_form_valid: is_form_valid,
        errors: { ...prevState.errors, ...errors },
      }));
      return is_form_valid;
    },
    [formData],
  );

  const editCardDetails = () => {
    setFormData((prevState) => ({
      ...prevState,
      disable_card_options: !formData.disable_card_options,
      card_name: formData.card_name,
      card_number: "",
      card_expiry: "",
      card_cvv: "",
      unit_credit_card: true,
    }));
  };

  const deleteCardDetails = () => {
    setshow_delete_card_model(true);
    setisDeleteCard(false);
  };

  const handleShowDeleteCardModal = () => {
    setisDeleteCard(false);
    setshow_delete_card_model(!show_delete_card_model);
  };

  const saveCardDetails = () => {
    const { card_name, card_number, card_expiry, unit_credit_card, card_cvv, email } = formData;

    const card_expiry_ = moment(new Date(card_expiry)).format("YYYY-MM-DD hh:mm:ss");

    if (checkValidations()) {
      const data = {
        email: email,
        user_id: UnitId,
        unit_id: suite_id,
        user_type: userType,
        property_id: property_id,
        card_number: card_number,
        card_expiry: card_expiry_,
        card_cvv: card_cvv,
        card_name: card_name,
        is_card_edit: unit_credit_card,
        language: language,
      };

      setFormData((prevState) => ({
        ...prevState,
        submitLoader: true,
      }));

      dispatch(saveCreditCardDetails(data));

      console.log("data on card save::::1", data);
    }
  };

  const checkValidations = () => {
    const { card_number, card_expiry, card_cvv, card_name, unit_credit_card, email } = formData;
    let is_form_valid = true;
    let errors = {};

    const { error, valid } = cardValidation(card_number, card_expiry, card_cvv, card_name, email, unit_credit_card);
    errors = {
      ...errors,
      ...error,
    };
    is_form_valid = valid;

    setFormData({
      ...formData,
      is_form_valid: is_form_valid,
      errors: errors,
    });
    return is_form_valid;
  };

  const cardValidation = (card_number, card_expiry, card_cvv, card_name, email, unit_credit_card) => {
    const errors = {};
    let is_form_valid = true;
    if (!card_name?.toString().length) {
      is_form_valid = false;
      errors.card_name = <Translation>{(t) => <>{t(`Card Holder Name can't be empty`)}</>}</Translation>;
    }

    if (!card_number?.toString().length) {
      is_form_valid = false;
      errors.card_number = <Translation>{(t) => <>{t(`Card Number can't be empty`)}</>}</Translation>;
    }
    if (!email?.toString().length) {
      is_form_valid = false;
      errors.email = <Translation>{(t) => <>{t(`Email can't be empty`)}</>}</Translation>;
    }
    if (!card_expiry.toString().length) {
      is_form_valid = false;
      errors.card_expiry = <Translation>{(t) => <>{t(`Card Expiry date can't be empty`)}</>}</Translation>;
    }
    if (unit_credit_card) {
      if (!card_cvv.toString().length) {
        is_form_valid = false;
        errors.card_cvv = <Translation>{(t) => <>{t(`CVV can't be empty`)}</>}</Translation>;
      }
    }

    return {
      error: errors,
      valid: is_form_valid,
    };
  };

  const card_number_validation = async (target) => {
    let is_form_valid = true;
    const errors = {};
    const cardData = !/^\d+$/.test(target.value);
    if (!cardData && target.value.length <= 16) {
      if (target.value.length > 14) {
        const validCard = await validateCardNumber(target.value);
        if (validCard) {
          errors.card_number = "";
        } else {
          is_form_valid = false;
          errors.card_number = <Translation>{(t) => <>{t("Invalid Card Number")}</>}</Translation>;
        }
      } else {
        is_form_valid = false;
        errors.card_number = <Translation>{(t) => <>{t("Invalid Card Number. Enter at least 16 digits.")}</>}</Translation>;
      }
    }
    return {
      error: errors,
      valid: is_form_valid,
    };
  };

  const card_cvv_validation = async (target) => {
    const cvvdata = !/^\d+$/.test(target.value);
    let is_form_valid = true;
    const errors = {};
    if ((!cvvdata && target.value.length <= 4) || target.value.length === 0) {
      if (target.value.length >= 3) {
        errors.card_cvv = "";
      } else if (target.value.length > 1) {
        errors.card_cvv = <Translation>{(t) => <>{t("Invalid CVV. Enter at least 3 digits.")}</>}</Translation>;
        is_form_valid = false;
      } else {
        errors.card_cvv = "";
      }
    }
    return {
      error: errors,
      valid: is_form_valid,
    };
  };

  const showSubmitButton = () => {
    const buttonName = "Save Card";

    if (!formData.submitLoader) {
      return (
        <Button className="btn-fill mb-2" type="button" variant="info" onClick={() => saveCardDetails()} disabled={!formData.is_form_valid}>
          <Translation>{(t) => <>{t(buttonName)}</>}</Translation>
        </Button>
      );
    } else {
      return <Spinner animation="border" variant="info" />;
    }
  };

  return (
    userType === "unit" && (
      <>
        <Card className={window.location.href.includes("user-profile") ? "" : "change-profile-card"}>
          <Card.Header style={{ backgroundColor: mainBody }}>
            <Card.Title as="h4">
              <Translation>{(t) => <>{t("Credit Card Details")}</>}</Translation>
            </Card.Title>
          </Card.Header>

          <Container fluid>
            <Row>
              <Col xs={12} lg={12}>
                {formData.unit_credit_card ? (
                  <div>
                    <div className="mt-2">
                      <Row>
                        <Col lg="6">
                          <div className="form-group mb-3">
                            <label>
                              <Translation>{(t) => <>{t("Name")}</>}</Translation>
                              <span>*</span>
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <input required={true} type="text" className="form-control" name="card_name" value={formData.card_name} onChange={handleChange} />
                              <div className="payment-formicon">
                                <span className="fa fa-user" aria-hidden="true" />
                              </div>
                              {showError("card_name")}
                            </div>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="form-group mb-3">
                            <label>
                              <Translation>{(t) => <>{t("Email")}</>}</Translation>
                              <span>*</span>
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <input required={true} type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} />
                              <div className="payment-formicon">
                                <span className="fa fa-envelope" aria-hidden="true" />
                              </div>
                            </div>
                            {showError("email")}
                          </div>
                        </Col>
                      </Row>
                      <div className="form-group mb-3">
                        <label>
                          <Translation>{(t) => <>{t("Credit Card number")}</>}</Translation>
                          <span>*</span>
                        </label>
                        <div className="d-flex align-items-center position-relative">
                          <input
                            type="text"
                            required={true}
                            className="form-control"
                            name="card_number"
                            value={formData.card_number}
                            onChange={handleChange}
                            maxLength={16}
                          />
                          <div className="payment-formicon">
                            <span className="fa fa-credit-card" aria-hidden="true" />
                          </div>
                        </div>
                        {showError("card_number")}
                      </div>
                      <Row>
                        <Col lg="6">
                          <div className="form-group mb-3">
                            <label>
                              <Translation>{(t) => <>{t("Expiration Date")}</>}</Translation>
                              <span>*</span>
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <DatePicker
                                required
                                autoComplete="new-password"
                                placeholderText="MM/YY"
                                className="form-control datePickerPayment"
                                name="card_expiry"
                                selected={formData.card_expiry}
                                onChange={handleCalender}
                                dateFormat="MM/yy"
                                showMonthYearPicker
                                showFullMonthYearPicker
                                minDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
                                customInput={
                                  <InputMask
                                    mask="99/99"
                                    maskChar={null}
                                    // placeholder="MM/YY"
                                    className="form-control datePickerPayment pe-5"
                                    name="card_expiry"
                                    value={formData?.card_expiry}
                                    onChange={handleCalender}
                                    showMask
                                  />
                                }
                              ></DatePicker>
                              <div className="payment-formicon">
                                <span
                                  className="fa fa-calendar"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                            {showError("card_expiry")}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="form-group mb-3">
                            <label>
                              CVV
                              <span>*</span>
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <input
                                maxLength={4}
                                required={true}
                                type="password"
                                className="form-control"
                                name="card_cvv"
                                value={formData.card_cvv}
                                onChange={handleChange}
                              />
                              <div className="payment-formicon">
                                <span className="fa fa-key" aria-hidden="true" />
                              </div>
                            </div>
                            {showError("card_cvv")}
                          </div>
                          <div class="d-flex justify-content-end gap-3">{showSubmitButton()}</div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : (
                  userType === "unit" && (
                    <div className="ml-1 mr-1">
                      <div className="mb-2 mt-3 change-credit-card-box">
                        <div className="mt-2">
                          <Row>
                            <Col lg="6">
                              <div className="form-group mb-3">
                                <label>
                                  <Translation>{(t) => <>{t("Card Holder Name")}</>}</Translation>
                                  <span class="asterisk">*</span>
                                </label>
                                <div className="d-flex align-items-center position-relative">
                                  <input
                                    required={true}
                                    type="text"
                                    className="form-control pe-5"
                                    name="credit_card_name"
                                    value={formData.card_name}
                                    onChange={handleChange}
                                    disabled={formData.disable_card_options}
                                  />
                                  <div className="payment-formicon">
                                    <span className="fa fa-user" aria-hidden="true" />
                                  </div>
                                </div>
                                {showError("card_name")}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="form-group mb-3">
                                <label>
                                  <Translation>{(t) => <>{t("Email")}</>}</Translation>
                                  <span>*</span>
                                </label>
                                <div className="d-flex align-items-center position-relative">
                                  <input
                                    required={true}
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    disabled={formData.disable_card_options}
                                  />
                                  <div className="payment-formicon">
                                    <span className="fa fa-envelope" aria-hidden="true" />
                                  </div>
                                </div>
                                {showError("email")}
                              </div>
                            </Col>
                          </Row>

                          <div className="form-group mb-3">
                            <label>
                              <Translation>{(t) => <>{t("Credit Card number")}</>}</Translation>
                              <span class="asterisk">*</span>
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <input
                                type="text"
                                required={true}
                                className="form-control pe-5"
                                name="card_number"
                                value={formData.card_number}
                                onChange={handleChange}
                                maxLength={16}
                                disabled={formData.disable_card_options}
                              />
                              <div className="payment-formicon">
                                <span className="fa fa-credit-card" aria-hidden="true" />
                              </div>
                            </div>
                            {showError("card_number")}
                          </div>
                          <div className="form-group mb-4">
                            <Row>
                              <Col lg="12">
                                <div className="form-group mb-3">
                                  <label>
                                    <Translation>{(t) => <>{t("Expiration Date")}</>}</Translation>
                                    <span class="asterisk">*</span>
                                  </label>
                                  <div className="d-flex align-items-center position-relative">
                                    <input
                                      type="text"
                                      required={true}
                                      className="form-control"
                                      name="card_expiry"
                                      value={formData.card_expiry}
                                      onChange={handleChange}
                                      disabled={formData.disable_card_options}
                                    />
                                    <div className="payment-formicon">
                                      <span
                                        className="fa fa-calendar"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </div>
                                  {showError("card_expiry")}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div class="d-flex justify-content-end gap-4">
                          <div className="my-2 mr-3">
                            <Button className="btn-fill btn-sm mb-2" type="button" variant="info" onClick={() => editCardDetails()}>
                              <span className="pr-2">
                                <i class="far fa-edit"></i>
                              </span>
                              <Translation>{(t) => <>{t("Edit Card")}</>}</Translation>
                            </Button>
                          </div>
                          <div className="my-2">
                            <Button className="btn-fill btn-sm mb-2" type="button" variant="danger" onClick={() => deleteCardDetails()}>
                              <span className="pr-2">
                                <i class="far fa-trash-alt"></i>
                              </span>
                              <Translation>{(t) => <>{t("Delete Card")}</>}</Translation>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Col>
            </Row>
          </Container>
        </Card>
        {show_delete_card_model ? (
          <DeleteCardModal
            onOpen={show_delete_card_model}
            onConfirm={handleDeleteCard}
            message="Are You sure you want to Delete this card? It will not auto renew permits."
            onClose={handleShowDeleteCardModal}
            title={"Delete Card"}
            isDeleteCarddisabled={isDeleteCard}
            loading={false}
          />
        ) : null}

        {api_success_message && (
          <Translation>{(t) => <CustomContentAlert delay={4000} message={t(api_success_message)} className="toast-success" />}</Translation>
        )}
        {api_error_message && <Translation>{(t) => <CustomContentAlert delay={10000} message={t(api_error_message)} className="toast-error" />}</Translation>}
      </>
    )
  );
};
export default UpdateCreditCard;

import React, { Component } from "react";
import { Card, Container, Row, Col, Button, Form, Tabs, Tab } from "react-bootstrap";
import { findBySearch } from "../helper";
import DataTable from "react-data-table-component";
import Loader from "../Loader/Loader";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { connect } from "react-redux";
import { SuperAdminAction, EnforcementActions } from "../../Actions";
import Approved from "../../../assets/img/svg/approved.svg";
import Unknown from "../../../assets/img/svg/unknown.svg";
import PaginationforData from "../pagination/pagination";
import moment from "moment";

class EnforcementReportPhotoVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      searchValue: "",
      searchValues: "",
      errorMsg: true,
      api_response: false,
      prevSearch: "",
      prevPage: "",
      prevNorow: "",
      prevSearchTab2: "",
      prevPageTab2: "",
      prevNorowTab2: "",
      selectedTab: "Tab1",
      tab2Page: 1,
      tab2Norow: 10,
      page: 1,
      Norow: 10,
      selectedColumn: "created_at",
      asc: "false",
      sort_direction: "DESC",
      pageChanged: false,
      findMore: false,
      count: 0,
      count2: 0,
      timezone: this.props.timezone,
      filtered: "",
      auditReportUser: [],
      user_id: "ANY",
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };
    this.columns2 = [
      {
        name: "Date Time",
        sortable: true,
        cell: (row) => this.showDateTime(row),
        selector: (row) => row?.created_at,
      },
      {
        name: "Site",
        sortable: true,
        cell: (row) => this.showSiteName(row),
        selector: (row) => row?.users?.site_name,
      },
      {
        name: "Checked by",
        sortable: true,
        cell: (row) => this.showCheckedBy(row),
        selector: (row) => `${row?.users?.first_name} ${row?.users?.last_name}`,
      },
      {
        name: "LPN",
        sortable: true,
        cell: (row) => this.showLpn(row),
        selector: (row) => row?.lpn,
      },
      {
        name: "LOT",
        sortable: true,
        cell: (row) => this.showLotName(row),
        selector: (row) => row?.lots?.alias,
      },
      {
        name: "Result",
        sortable: true,
        cell: (row) => this.showResults(row),
        selector: (row) => row.result,
      },
      {
        name: "Image",
        sortable: true,
        cell: (row) => this.showImages(row),
        selector: (row) => row.scan_plate_image,
      },
    ];
  }

  showLotName = (row) => <div>{row?.lots?.alias}</div>;

  showLpn = (row) => <div>{row?.lpn}</div>;

  showCheckedBy = (row) => (
    <div>
      {row?.users?.first_name} {row?.users?.last_name}
    </div>
  );

  showSiteName = (row) => <div>{row?.property?.site_name}</div>;

  showDateTime = (row) => <div>{row?.created_at ? moment(row?.created_at).format("lll") : row?.created_at}</div>;

  showImages = (row) => (
    <div>
      {" "}
      {row.user && row.scan_plate_image ? (
        <a href={row.scan_plate_image}>
          <img style={{ height: "35px", width: "80px" }} src={row.scan_plate_image} />
        </a>
      ) : (
        "N/A"
      )}
    </div>
  );

  showResults = (row) => {
    if (row?.result?.toLowerCase() === "not approved") {
      return (
        <div>
          <Rejected />
          <span className={"ml-2"}>{row?.result}</span>
        </div>
      );
    } else if (row?.result?.toLowerCase() === "approved") {
      return (
        <div>
          <img src={Approved} width="22" alt="Approved" />
          <span className={"ml-2"}>{row?.result}</span>
        </div>
      );
    } else {
      return (
        <div>
          <img src={Unknown} width="22" alt="Unknown" />
          <span className={"ml-2"}>{row?.result}</span>
        </div>
      );
    }
  };

  componentDidMount = async () => {
    this.setState({ loader: true }, async () => {
      await this.props.getAuditReoprts();
      if (this.props.api_response) {
        console.log("auditReportList:::::::::::;", this.props.auditReportList);
      }
      await this.props.get_user_type();

      this.setState({
        auditReportList: this.props.auditReportList,
        auditReportUser: this.props.usertypes,
        api_response: this.props.api_response,
        loader: false,
      });
    });
  };

  handleSearchChanges = ({ target }) => {
    this.setState({ prevSearch: this.state.searchValues });
    this.setState({ searchValues: target.value }, () => {
      if (!this.state.findMore) {
        if (this.state.searchValues.length) {
          this.setState(
            {
              pageChanged: true,
              searchedAuditReport: findBySearch(this.state.searchValues, this.props.auditReportList, this.props.auditReportList),
            },
            () => {
              this.setState({
                count: this.state.searchedAuditReport.length,
              });
            },
          );
        } else {
          this.setState({ loader: true, pageChanged: true }, async () => {
            await this.props.getAuditReoprts();
            this.setState({
              pageChanged: true,
              page: 1,
              searchedAuditReport: this.props.auditReportList,
              loader: false,
            });
          });
          setTimeout(() => {
            this.setState({ findMore: false });
          }, 1500);
        }
      }
    });
  };

  findMorePressed = () => {
    this.setState({ findMore: true }, () => {
      if (this.state.findMore) {
        if (this.state.searchValues.length || this.state.searchValue.length) {
          this.setState({ loader: true, pageChanged: true }, async () => {
            await this.props.getAuditReoprts();
            this.setState({
              pageChanged: true,
              page: 1,
              searchedAuditReport: this.props.auditReportList,
              loader: false,
            });
          });
          setTimeout(() => {
            this.setState({ findMore: false });
          }, 1500);
        } else {
          this.setState({ loader: true, pageChanged: true }, async () => {
            this.setState({
              pageChanged: true,
              page: 1,
              searchedAuditReport: this.props.auditReportList,
              loader: false,
            });
          });
          setTimeout(() => {
            this.setState({ findMore: false });
          }, 1500);
        }
      }
    });
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleSearch = () => {
    console.log("handleSearch called..");
  };

  render() {
    const {
      loader,
      searchValues,
      searchValue,
      //  page,
      // Norow,
      pageChanged,
      //  tab2Norow,
      //  tab2Page,
      auditReportList,
      searchedAuditReport,
      auditReportUser,
      user_id,
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">
                    <span>Photo Verify</span>
                  </Card.Title>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide">
                  <div className="">
                    <Tabs defaultActiveKey="photoverifydetail" id="controlled-tab-example">
                      <Tab eventKey="photoverifydetail" title="Photo Verify Detail" className="m-0 active">
                        <Row style={{ marginTop: 20 }}>
                          <Col lg={4} md={4} sm={12} xs={12}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>Cheked by</Form.Label>
                              <div className="d-flex align-items-center">
                                <Form.Control as="select" name="user_id" disabled={loader} value={user_id} onChange={this.handleChange}>
                                  {auditReportUser?.length ? (
                                    <>
                                      <option value="ANY">ANY</option>
                                      {auditReportUser.map((item, index) => (
                                        <option key={index} value={item.user_id}>
                                          {item.user_type.toUpperCase()}
                                        </option>
                                      ))}
                                    </>
                                  ) : null}
                                </Form.Control>
                                <Button variant="info" className="h-40 btn-fill ml-2" onClick={this.handleSearch} disabled={loader}>
                                  Search
                                </Button>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={4} md={4} sm={4} xs={12}>
                            <label className="form-label mo-none">&nbsp;</label>
                            <DatatableHeader searchValue={searchValues} HandleChange={this.handleSearchChanges} findMorePressed={this.findMorePressed} />
                            {/* <Button onClick={this.findMorePressed}>
                                    Get All Result for {searchValues}
                                  </Button> */}
                          </Col>
                        </Row>

                        {loader ? (
                          <Loader />
                        ) : (
                          <div className="photo-verify-details-table">
                            <DataTable
                              data={searchValues.length || pageChanged ? searchedAuditReport : auditReportList}
                              columns={this.columns2}
                              customStyles={this.customStyles}
                              pagination={true}
                              responsive={true}
                              defaultSortAsc={true}
                              fixedHeader={true}
                              fixedHeaderScrollHeight="40vh"
                            />
                          </div>
                        )}
                      </Tab>
                      <Tab eventKey="photoverifysummary" title="Photo Verify Summary">
                        <>
                          {loader ? (
                            <Loader />
                          ) : (
                            <div>
                              <DataTable
                                data={""}
                                columns={this.columns1}
                                customStyles={this.customStyles}
                                pagination={true}
                                responsive={true}
                                subHeaderComponent={<DatatableHeader searchValue={searchValue} HandleChange={this.handleSearchChange} />}
                                defaultSortAsc={true}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="40vh"
                              />
                              <PaginationforData count={this.state.count2} data={this.state.currentPlate} findMorePressed={this.findMorePressed} />
                            </div>
                          )}
                        </>
                      </Tab>
                    </Tabs>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const Rejected = () => {
  return (
    <svg height="25px" viewBox="-24 0 512 512.20773" width="25px" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m459.902344 256.007812c0 123.160157-99.839844 223-223 223-123.15625 0-223-99.839843-223-223 0-123.160156 99.84375-223 223-223 123.160156 0 223 99.839844 223 223zm0 0"
        fill="#fc7783"
      />
      <path
        d="m439.902344 256.007812c0 112.113282-90.882813 203-203 203-112.113282 0-203-90.886718-203-203 0-112.113281 90.886718-203 203-203 112.117187 0 203 90.886719 203 203zm0 0"
        fill="#ea4647"
      />
      <path
        d="m379.703125 256.007812c0 78.867188-63.933594 142.800782-142.800781 142.800782-78.863282 0-142.796875-63.933594-142.796875-142.800782 0-78.867187 63.933593-142.800781 142.796875-142.800781 78.867187 0 142.800781 63.933594 142.800781 142.800781zm0 0"
        fill="#e9e9ea"
      />
      <path
        d="m267.503906 258.40625c-1.398437-1.398438-1.398437-3.597656 0-5l38.800782-38.796875c7.800781-7.800781 7.800781-20.402344 0-28.203125-7.800782-7.796875-20.402344-7.796875-28.199219 0l-38.800781 38.800781c-1.402344 1.402344-3.601563 1.402344-5 0l-38.402344-38.398437c-7.597656-7.800782-20-8.199219-28-.800782-8 7.601563-8.597656 20.199219-1 28.199219.203125.199219.402344.402344.601562.601563l38.800782 38.800781c1.398437 1.398437 1.398437 3.597656 0 5l-38.800782 38.796875c-7.800781 7.800781-7.800781 20.402344 0 28.203125 7.800782 7.796875 20.398438 7.796875 28.199219 0l38.800781-38.800781c1.398438-1.402344 3.601563-1.402344 5 0l38.398438 38.398437c7.601562 7.800781 20 8.199219 28 .800781 8-7.601562 8.601562-20.199218 1-28.199218-.199219-.199219-.398438-.402344-.597656-.601563zm0 0"
        fill="#ea4647"
      />
      <path
        d="m112.105469 493.40625c-9-1.796875-15.800781-8.597656-17.601563-17.597656 0-.199219-.199218-.402344-.398437-.402344-.203125 0-.203125.203125-.402344.402344-1.800781 9-8.597656 15.800781-17.597656 17.597656-.203125 0-.402344.203125-.402344.402344 0 .199218.199219.199218.402344.398437 9 1.800781 15.796875 8.601563 17.597656 17.601563 0 .199218.199219.398437.402344.398437.199219 0 .199219-.199219.398437-.398437 1.800782-9 8.601563-15.800782 17.601563-17.601563.199219 0 .398437-.199219.398437-.398437-.199218-.402344-.398437-.402344-.398437-.402344zm0 0"
        fill="#60bae2"
      />
      <path
        d="m401.503906 43.007812h-17.398437c-2.800781 0-5-2.199218-5-5 0-2.800781 2.199219-5 5-5h17.398437c2.800782 0 5 2.199219 5 5 0 2.800782-2.199218 5-5 5zm0 0"
        fill="#fcd770"
      />
      <path
        d="m392.902344 51.609375c-2.796875 0-5-2.203125-5-5v-17.402344c0-2.800781 2.203125-5 5-5 2.800781 0 5 2.199219 5 5v17.402344c0 2.796875-2.398438 5-5 5zm0 0"
        fill="#fcd770"
      />
      <path
        d="m22.503906 411.007812h-17.398437c-2.800781 0-5-2.199218-5-5 0-2.800781 2.199219-5 5-5h17.398437c2.800782 0 5 2.199219 5 5 0 2.800782-2.199218 5-5 5zm0 0"
        fill="#f75c64"
      />
      <path
        d="m13.902344 419.808594c-2.796875 0-5-2.199219-5-5v-17.402344c0-2.796875 2.203125-5 5-5 2.800781 0 5 2.203125 5 5v17.402344c0 2.597656-2.398438 5-5 5zm0 0"
        fill="#f75c64"
      />
      <path
        d="m332.703125 10.007812c-1.398437 0-2.597656-.601562-3.597656-1.398437-.203125-.203125-.402344-.402344-.601563-.800781-.199218-.199219-.398437-.601563-.398437-.800782-.203125-.398437-.203125-.601562-.203125-1 0-.398437-.199219-.601562-.199219-1 0-.398437 0-.601562.199219-1 0-.398437.203125-.601562.203125-1 .199219-.398437.199219-.601562.398437-.800781.199219-.199219.398438-.597656.601563-.800781 1.398437-1.3984375 3.597656-1.796875 5.398437-1 .199219.203125.601563.203125.800782.402344.199218.199218.597656.398437.800781.597656.199219.203125.398437.402344.597656.800781.199219.199219.402344.601563.402344.800781.199219.199219.199219.601563.199219 1 0 .398438 0 .601563.199218 1 0 .398438 0 .601563-.199218 1 0 .398438-.199219.601563-.199219 1-.203125.398438-.203125.601563-.402344.800782-.199219.199218-.398437.597656-.597656.800781-.203125.199219-.402344.398437-.800781.597656-.199219.199219-.601563.402344-.800782.402344-.398437.199219-.601562.199219-1 .199219-.199218.199218-.398437.199218-.800781.199218zm0 0"
        fill="#60bae2"
      />
      <path
        d="m79.105469 452.007812c-1.402344 0-2.601563-.601562-3.601563-1.398437-.199218-.203125-.398437-.402344-.601562-.800781-.199219-.199219-.398438-.601563-.398438-.800782-.199218-.398437-.199218-.601562-.199218-1 0-.398437-.199219-.601562 0-1 0-.398437 0-.601562 0-1 0-.398437.199218-.601562.199218-1 .199219-.398437.199219-.601562.398438-.800781.203125-.199219.402344-.597656.601562-.800781 1.398438-1.398438 3.601563-1.796875 5.398438-1 .203125.203125.601562.203125.800781.402344.199219.199218.601563.398437.800781.597656.199219.203125.398438.402344.601563.800781.199219.199219.398437.601563.398437.800781.199219.199219.199219.601563.199219 1v1 1c0 .398438-.199219.601563-.199219 1-.199218.398438-.199218.601563-.398437.800782-.203125.199218-.402344.597656-.601563.800781-.199218.199219-.398437.398437-.800781.597656-.199219.199219-.597656.402344-.800781.402344-.398438.199219-.597656.199219-1 .199219-.199219.199218-.398438.199218-.796875.199218zm0 0"
        fill="#6dcc6d"
      />
      <g fill="#231f20">
        <path d="m236.902344 484.007812c-126 0-228-102-228-228s102-228 228-228 228 102 228 228c-.199219 125.800782-102.199219 227.800782-228 228zm0-446c-120.398438 0-218 97.601563-218 218 0 120.398438 97.601562 218 218 218 120.402344 0 218-97.601562 218-218-.199219-120.398437-97.796875-217.800781-218-218zm0 0" />
        <path d="m236.902344 464.007812c-114.796875 0-208-93.199218-208-208 0-114.800781 93.203125-208 208-208 114.800781 0 208 93.199219 208 208-.199219 114.800782-93.199219 207.800782-208 208zm0-406c-109.398438 0-198 88.601563-198 198 0 109.398438 88.601562 198 198 198 109.402344 0 198-88.601562 198-198-.199219-109.398437-88.796875-197.800781-198-198zm0 0" />
        <path d="m236.902344 403.808594c-81.597656 0-147.796875-66.199219-147.796875-147.800782 0-81.601562 66.199219-147.800781 147.796875-147.800781 81.601562 0 147.800781 66.199219 147.800781 147.800781-.199219 81.601563-66.199219 147.601563-147.800781 147.800782zm0-285.601563c-76 0-137.796875 61.601563-137.796875 137.800781 0 76.199219 61.597656 137.800782 137.796875 137.800782 76.203125 0 137.800781-61.601563 137.800781-137.800782-.199219-76-61.800781-137.601562-137.800781-137.800781zm0 0" />
        <path d="m181.503906 336.40625c-13.800781 0-25-11.199219-25-25 0-6.597656 2.601563-13 7.398438-17.597656l37.601562-37.601563-37.601562-37.597656c-9.796875-9.800781-9.796875-25.601563 0-35.402344.203125-.199219.402344-.398437.601562-.597656 10-9 25.398438-8.601563 35 1l37.398438 37.398437 37.601562-38c9.800782-9.800781 25.601563-9.800781 35.398438 0 9.800781 9.800782 9.800781 25.601563 0 35.398438l-37.597656 37.601562 37.597656 37.601563c9.800781 9.796875 9.800781 25.597656 0 35.398437-.199219.199219-.398438.398438-.597656.601563-10 9-25.402344 8.597656-35-1l-37.402344-37.402344-37.597656 37.601563c-4.800782 5-11.199219 7.597656-17.800782 7.597656zm-.199218-150.597656c-3.800782 0-7.402344 1.398437-10 3.800781-6 5.597656-6.402344 15.199219-.800782 21.199219.199219.199218.199219.199218.398438.398437l38.800781 38.800781c3.402344 3.398438 3.402344 8.601563 0 12l-38.800781 38.800782c-5.796875 5.800781-5.796875 15.398437 0 21.199218 5.800781 5.800782 15.402344 5.800782 21.203125 0l38.796875-38.800781c3.402344-3.398437 8.601562-3.398437 12 0l38.402344 38.402344c5.800781 5.796875 15 6.199219 21.199218.796875 6-5.597656 6.398438-15.199219.800782-21.199219-.199219-.199219-.199219-.199219-.402344-.398437l-38.796875-38.800782c-3.402344-3.398437-3.402344-8.601562 0-12l38.796875-38.800781c5.800781-5.800781 6-15.398437 0-21.199219-5.796875-5.800781-15.398438-6-21.199219 0l-38.800781 38.800782c-3.398438 3.398437-8.597656 3.398437-12 0l-38.398438-38.402344c-3-3-7-4.597656-11.199218-4.597656zm89.800781 69.199218" />
      </g>
    </svg>
  );
};

const mapStateToProps = ({ APIResponseDetails, UserProfileDetails, EnforcementDetails }) => {
  const { api_response, api_error_message } = APIResponseDetails;
  const { user_type, timezone, property } = UserProfileDetails;
  const { auditReportList, usertypes } = EnforcementDetails;

  return {
    api_response,
    api_error_message,
    user_type,
    timezone,
    property,
    auditReportList,
    usertypes,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  getAuditReoprts: EnforcementActions.getAuditReoprts,
  get_user_type: EnforcementActions.get_user_type,
};

export default connect(mapStateToProps, mapStateToDispatch)(EnforcementReportPhotoVerify);

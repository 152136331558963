import { combineReducers } from "redux";
import UserProfileDetailsReducer from "./UserProfileReducer/reducer";
import CurrentPermitReducer from "./CurrentPermitReducer/reducer";
import APIResponseReducer from "./APIResponseReducer/reducer";
import DashBoardReducer from "./DashBoardReducer/reducer";
import ResidentReducer from "./ResidentReducer/reducer";
import CurrentVisitorPermitReducer from "./CurrentVisitorPermitReducer/reducer";
import ResidentOccupancyReducer from "./ResidentOccupancyReducer/reducer";
import PlateSearchReducer from "./PlateSearchReducer/reducer";
import ChangePasswordReducer from "./ChangePasswordReducer/reducer";
import SuperAdminReducer from "./SuperAdminReducer/reducer";
import CameraReducer from "./CameraReducer/reducer";
import UserLoginReducer from "./UserLoginReducer/reducer";
import EnforcementReducer from "./EnforcementReducer/reducer";
import TicketReducer from "./TicketReducer/reducer";
import EdgeDeviceReducer from "./EdgeDeviceReducer/reducer";
import LotCountingReducer from "./LotCountingReducer/reducer";
import SelfRegisterReducer from "./SelfRegisterReducer/reducer";
import ReportsReducer from "./ReportsReducer/reducer";
import PermitOptionsReducer from "./PermitOptionsReducer/reducer";
import ValidationReducer from "./ValidationReducer/reducer";
import PermitReducer from "./PermitReducer/reducer";
import GateSchedulerReducer from "./GateSchedulerReducer/reducer";
import MeterConfigReducer from "./MeterConfigReducer/reducer";
import { connectRouter } from "connected-react-router";

const allReducer = (history) => {
  return {
    router: connectRouter(history),
    UserProfileDetails: UserProfileDetailsReducer,
    CurrentPermitDetails: CurrentPermitReducer,
    DashBoardDetails: DashBoardReducer,
    APIResponseDetails: APIResponseReducer,
    ResidenDetails: ResidentReducer,
    CurrentVisitorPermitDetails: CurrentVisitorPermitReducer,
    ResidentOccupancyDetails: ResidentOccupancyReducer,
    PlateSearchDetails: PlateSearchReducer,
    ChangePasswordDetails: ChangePasswordReducer,
    SuperAdminDetails: SuperAdminReducer,
    Logindetails: UserLoginReducer,
    CameraDetails: CameraReducer,
    EnforcementDetails: EnforcementReducer,
    TicketDetails: TicketReducer,
    EdgeDeviceDetails: EdgeDeviceReducer,
    MeterConfigDetails: MeterConfigReducer,
    LotCountingDetails: LotCountingReducer,
    SelfRegisterDetails: SelfRegisterReducer,
    ReportsDetails: ReportsReducer,
    PermitOptionsDetails: PermitOptionsReducer,
    ValidationDetails: ValidationReducer,
    PermitReducer: PermitReducer,
    GateSchedulerDetails: GateSchedulerReducer,
  };
};

const rootReducer = (history) =>
  combineReducers({
    ...allReducer(history),
  });

const exportReducers = { rootReducer, allReducer };

export default exportReducers;

import React, { Component } from "react";
import { connect } from "react-redux";
import { SuperAdminAction } from "../../Actions";
import { Card, Row, Col, Form, Button, Modal } from "react-bootstrap";
import CustomContentAlert, { isEmailValid } from "../helper";
import Select from "react-select";
import ToggleButton from "react-toggle-button";

class CreatPropertyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      propertyName: "",
      domain: "",
      site_name: "",
      timezone: "",
      show_alert: false,
      show_error: false,
      high_security: false,
      highsecurity_email: "",
      api_success_message: "",
      api_error_message: "",
      validAddress: true,
      validPropertyName: true,
      validSubdomain: true,
      validSecurityEmail: true,
      validProperty: true,
      validParentProperty: true,
      isFormValid: false,
      parentPropertyDropDownMenu: "Parent Property",
      parentPropertyList: [],
      allTimezones: [],
      property_id: "",
      addProperty: false,
      subDomainErrorMessage: "",
      selected_property_id: "",
      values: [{ value: null }],
    };
  }

  componentDidMount = async () => {
    await this.props.get_all_properties();
    await this.props.get_all_timezones();
    const allTimeZoneOptions = this.props.getAllTimezones?.length
      ? this.props.getAllTimezones.map((timezone) => {
          return {
            value: timezone.name,
            label: `(${timezone.value}) ${timezone.name}`,
          };
        })
      : [];
    this.setState({
      parentPropertyList: this.props.getAllProperties,
      allTimezones: allTimeZoneOptions,
    });
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.getAllProperties) !== JSON.stringify(this.props.getAllProperties)) {
      this.setState({
        parentPropertyList: this.props.getAllProperties,
      });
    }
  }

  handleTimezoneChange = (timezoneValue) => {
    this.setState(
      {
        timezone: timezoneValue,
      },
      () => {
        const { propertyName, address, domain, property_id, timezone, high_security, highsecurity_email } = this.state;

        this.checkAllValidations(address, propertyName, domain, timezone, property_id, high_security, highsecurity_email);
      },
    );
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value }, () => {
      const { propertyName, address, domain, property_id, timezone, highsecurity_email, high_security } = this.state;
      switch (target.name) {
        case "propertyName":
          if (propertyName.trim().length) {
            this.setState({
              propertyName: target.value,
              validPropertyName: true,
            });
          } else {
            this.setState({ validPropertyName: false, isFormValid: false });
          }
          break;
        case "address":
          if (address.trim().length) {
            this.setState({
              address: target.value,
              validAddress: true,
            });
          } else {
            this.setState({ validAddress: false, isFormValid: false });
          }
          break;
        case "domain":
          if (domain.trim().length) {
            this.setState({
              domain: target.value.toLowerCase().replace(/[^\w\s]/gi, ""),
              validSubdomain: true,
              subDomainErrorMessage: "",
            });
          } else {
            this.setState({
              validSubdomain: false,
              isFormValid: false,
              subDomainErrorMessage: "Subdomain is required",
            });
          }
          break;

        case "highsecurity_email":
          if (highsecurity_email.trim().length) {
            if (isEmailValid(highsecurity_email)) {
              console.log("If called isEmailValid");
              this.setState({
                highsecurity_email: target.value,
                validSecurityEmail: true,
                securityEmailErrorMessage: "",
              });
            } else {
              console.log("else called isEmailValid");

              this.setState({
                validSecurityEmail: false,
                isFormValid: false,
                securityEmailErrorMessage: "Please enter a valid email address.",
              });
              this.checkAllValidations(address, propertyName, domain, timezone, property_id, high_security, highsecurity_email);
            }
          } else {
            this.setState({
              validSecurityEmail: false,
              isFormValid: false,
              securityEmailErrorMessage: "Security Officer Email is required",
            });
            this.checkAllValidations(address, propertyName, domain, timezone, property_id, high_security, highsecurity_email);
          }
          break;
        case "property_id":
          if (property_id) {
            this.setState({
              property_id: target.value,
            });
          } else {
            this.setState({ validProperty: false, isFormValid: false });
          }
      }

      this.checkAllValidations(address, propertyName, domain, timezone, property_id, high_security, highsecurity_email);
    });
  };

  handleAddProperty = async () => {
    const { address, domain, propertyName, property_id, timezone: timezoneValue, high_security, highsecurity_email } = this.state;
    const timezone = timezoneValue.value;
    const property_obj = {
      address,
      domain,
      site_name: propertyName,
      property_id,
      timezone,
      high_security,
      highsecurity_email,
    };
    await this.props.create_property(property_obj);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        selected_property_id: this.props.selected_property_id,
        isFormValid: false,
      });
      setTimeout(() => {
        this.setState({
          show_alert: false,
          address: "",
          domain: "",
          propertyName: "",
          timezone: "",
        });
        this.props.closeModal();
        const history = require("../../history").default;
        history.push({
          pathname: "/settings",
          search: this.state.selected_property_id,
        });
      }, 4000);
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  onToggleChange(value) {
    this.setState({
      high_security: !value,
      highsecurity_email: "",
      securityEmailErrorMessage: "",
    });
    const { address, propertyName, domain, timezone, property_id, high_security, highsecurity_email } = this.state;
    this.checkAllValidations(address, propertyName, domain, timezone, property_id, !high_security, highsecurity_email);
  }

  checkAllValidations = (address, propertyName, domain, timezone, property_id, high_security, highsecurity_email) => {
    console.log("checkAllValidations::::::", high_security, highsecurity_email);

    if (high_security === false) {
      this.validationWithoutHighSecurity(address, propertyName, domain, timezone, property_id);
    } else {
      this.vaalidationWithHighSecurity(highsecurity_email);
    }
  };

  vaalidationWithHighSecurity = (highsecurity_email) => {
    if (isEmailValid(highsecurity_email) && this.state.securityEmailErrorMessage.trim().length === 0) {
      this.setState({
        isFormValid: true,
      });
    } else {
      this.setState({
        isFormValid: false,
      });
    }
  };

  validationWithoutHighSecurity = (address, propertyName, domain, timezone, property_id) => {
    if (address.trim().length && propertyName.trim().length && domain.trim().length && timezone.value) {
      if (this.props.PropertyId && !property_id.trim().length) {
        this.setState({ isFormValid: false });
      } else {
        this.setState({ isFormValid: true });
      }
    } else {
      if (this.props.PropertyId && !property_id.trim().length) {
        this.setState({ isFormValid: false });
      }
    }
  };

  handleClose = () => {
    this.setState({
      propertyName: "",
      address: "",
      domain: "",
      timezone: "",
      validAddress: true,
      validPropertyName: true,
      validSubdomain: true,
      validSecurityEmail: true,
      validProperty: true,
      validParentProperty: true,
    });
    this.props.closeModal();
  };

  render() {
    const {
      address,
      propertyName,
      domain,
      property_id,
      validAddress,
      validPropertyName,
      isFormValid,
      subDomainErrorMessage,
      securityEmailErrorMessage,
      validProperty,
      parentPropertyList,
      show_alert,
      show_error,
      allTimezones,
      high_security,
      highsecurity_email,
    } = this.state;
    const { show } = this.props;
    return (
      <div>
        <Modal lg={"medium"} show={show} onHide={this.handleClose} dialogClassName="modal-90w" fullscreen={"sm-down"}>
          <Card.Header className="d-flex justify-content-between bg-white align-items-center">
            <h4 className="m-0 font-weight-bold">
              <Card.Title as="h4" className="m-0">
                Create Property
              </Card.Title>
            </h4>
            <Button title={"Deactivate"} type="button" onClick={this.handleClose} className="close-btn py-0" data-dismiss="modal">
              <i className="nc-icon nc-simple-remove text-danger"></i>
            </Button>
          </Card.Header>
          <Modal.Body>
            <Form.Group>
              <Row>
                <Col sm="12" lg="6">
                  <div className="form-group">
                    <label className="mb-0">
                      Property Name
                      <span className="asterisk">*</span>
                    </label>
                    <Form.Control
                      placeholder="propertyName"
                      type="text"
                      name="propertyName"
                      value={propertyName}
                      onChange={this.handleChange}
                      autoComplete="off"
                      required
                    />
                    {!propertyName.trim().length && !validPropertyName ? <div className="help-block">Property Name is required</div> : <div></div>}
                  </div>
                </Col>
                <Col sm="12" lg="6">
                  <div className="form-group">
                    <label className="mb-0">
                      Address
                      <span className="asterisk">*</span>
                    </label>
                    <Form.Control
                      placeholder="address"
                      type="text"
                      name="address"
                      value={address}
                      onChange={this.handleChange}
                      autoComplete="off"
                      required
                      maxlength="80"
                    />
                    {!address.trim().length && !validAddress ? <div className="help-block">Address is required</div> : <div></div>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="12" lg="6">
                  <div className="form-group">
                    <label className="mb-0">
                      Subdomain
                      <span className="asterisk">*</span>
                    </label>
                    <Form.Control placeholder="domain" type="text" name="domain" value={domain} onChange={this.handleChange} autoComplete="off" required />
                    {<div className="help-block">{subDomainErrorMessage}</div>}
                  </div>
                </Col>
                <Col sm="12" lg="6">
                  <div className="form-group">
                    <label className="mb-0">Parent Property</label>
                    {this.props.PropertyId && <span className="asterisk">*</span>}
                    <Form.Control as="select" name="property_id" value={this.state.property_id} onChange={this.handleChange}>
                      <option value={""}>{"Select Parent Property"}</option>
                      {(parentPropertyList ?? []).map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.site_name}
                        </option>
                      ))}
                      {this.props.PropertyId && (
                        <>{!property_id.trim().length && !validProperty ? <div className="help-block">select parent property</div> : <div></div>}</>
                      )}
                    </Form.Control>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group mb-0">
                    <label className="mb-0">
                      TimeZone
                      <span className="asterisk">*</span>
                    </label>

                    <Select name="timezone" value={this.state.timezone} onChange={this.handleTimezoneChange} options={allTimezones} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="12" lg="3">
                  <Form.Group>
                    <label>High Security</label>
                    <ToggleButton value={high_security} onToggle={this.onToggleChange.bind(this)} />
                  </Form.Group>
                </Col>
                <Col sm="12" lg="9">
                  {high_security && (
                    <div className="form-group">
                      <label className="mb-0">
                        Security Officer Email
                        <span className="asterisk">*</span>
                      </label>
                      <Form.Control
                        placeholder="Enter email"
                        type="text"
                        name="highsecurity_email"
                        value={highsecurity_email}
                        onChange={this.handleChange}
                        autoComplete="off"
                        required
                      />
                      {<div className="help-block">{securityEmailErrorMessage}</div>}
                    </div>
                  )}
                </Col>
              </Row>
              {/* <Row>

              </Row> */}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-fill" type="button" variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button className="btn-fill" type="button" variant="info" disabled={!isFormValid} onClick={this.handleAddProperty}>
              Create Property
            </Button>
          </Modal.Footer>
        </Modal>
        {show_alert ? <CustomContentAlert delay={4000} message={this.state.api_success_message} className="toast-success" /> : null}
        {show_error ? <CustomContentAlert delay={4000} message={this.state.api_error_message} className="toast-error" /> : null}
      </div>
    );
  }
}

const mapStatetoProps = ({ SuperAdminDetails, APIResponseDetails, UserProfileDetails }) => {
  const { api_response, api_success_message, api_error_message } = APIResponseDetails;
  const { property_id } = UserProfileDetails;
  const { getAllProperties, getAllTimezones, property_id: selected_property_id } = SuperAdminDetails;
  return {
    PropertyId: property_id,
    api_response,
    api_success_message,
    api_error_message,
    getAllProperties,
    getAllTimezones,
    selected_property_id: selected_property_id,
  };
};
const mapStateToDispatch = {
  create_property: SuperAdminAction.create_property,
  selected_Property: SuperAdminAction.selected_Property,
  get_all_properties: SuperAdminAction.get_all_properties,
  get_all_timezones: SuperAdminAction.get_all_timezones,
};
export default connect(mapStatetoProps, mapStateToDispatch)(CreatPropertyForm);

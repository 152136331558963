import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import ReactCrop from "react-image-crop";

const CropImage = ({ show, handleModelClose, onSaveLogo }) => {
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageName, setImageName] = React.useState("");
  const [crop, setCrop] = React.useState({ aspect: 16 / 9 });
  const imgRef = React.useRef(null);
  const [isValid, setIsValid] = React.useState(false);

  const getCroppedImg = React.useCallback((image, cropsize, _fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = cropsize.width;
    canvas.height = cropsize.height;
    const ctx = canvas.getContext("2d");

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = cropsize.width * pixelRatio;
    canvas.height = cropsize.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      cropsize.x * scaleX,
      cropsize.y * scaleY,
      cropsize.width * scaleX,
      cropsize.height * scaleY,
      0,
      0,
      cropsize.width,
      cropsize.height
    );

    return canvas.toDataURL("image/jpeg");
  }, []);

  const onSelectFile = React.useCallback((e) => {
    if (e.target.files && e.target.files.length > 0) {
      const extension = e.target.files[0].name.split(".").pop();
      const validExtensions = ["jpg", "jpeg", "png", "jfif"];
      if (validExtensions.includes(extension)) {
        const reader = new FileReader();
        setImageName(e.target.files[0].name.split(".").pop());
        reader.onloadend = () => setSelectedImage(reader.result);
        reader.readAsDataURL(e.target.files[0]);
        setIsValid(true);
      } else {
        alert("File type not supported.");
      }
    }
  }, []);

  const onLoad = React.useCallback((img) => {
    imgRef.current = img;
  }, []);

  const UploadImage = React.useCallback(() => {
    if (crop.width > 0 && crop.height > 0) {
      setIsValid(true);
      onSaveLogo(getCroppedImg(imgRef.current, crop, imageName), imageName);
    } else {
      onSaveLogo(selectedImage, imageName);
      setIsValid(false);
    }
  }, [crop, imageName, onSaveLogo]);

  React.useEffect(() => {
    if (!show) {
      setSelectedImage(null);
      setImageName(null);
      setImageName({ aspect: 16 / 9 });
      imgRef.current = null;
    }
  }, [show]);
  return (
    <Modal
      id="ticketDetails"
      lg={"medium"}
      show={show}
      onHide={handleModelClose}
      dialogClassName="modal-90w"
      fullscreen={"sm-down"}
      className="modalData"
    >
      <Modal.Header
        id="ignoreHeader"
        className="d-flex justify-content-between bg-white align-items-center"
      >
        <h4 className="m-0 font-weight-bold">
          <Modal.Title as="h4" className="m-0">
            Choose Logo
          </Modal.Title>
        </h4>
        <Button
          id="ignoreButton"
          title={"Deactivate"}
          type="button"
          onClick={handleModelClose}
          className="close-btn py-0"
          data-dismiss="modal"
        >
          <i className="nc-icon nc-simple-remove text-danger" />
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <WrapperDiv className="croparea">
          {!selectedImage ? (
            <div>
              <Form.Group className="custom-browse-btn mb-0">
                <div className="upload-text">Upload a file</div>
                <Form.Control
                  placeholder="Logo"
                  type="file"
                  accept="image/*"
                  name="headerLogo"
                  onChange={onSelectFile}
                />
              </Form.Group>
            </div>
          ) : (
            <ReactCrop
              src={selectedImage}
              crop={crop}
              onImageLoaded={onLoad}
              ruleOfThirds
              onChange={(newCrop) => setCrop(newCrop)}
            />
          )}
        </WrapperDiv>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModelClose}>
          Close
        </Button>
        <Button variant="primary" onClick={UploadImage} disabled={!isValid}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default CropImage;
const WrapperDiv = styled.div`
  height: 250px;
  margin: auto;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #eee;
  background: #eee;
  margin-top: 10px;
`;

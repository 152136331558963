import React from "react";
import { Row, Col, Container, Card, Form, Button } from "react-bootstrap";
import { routing_using_history } from "../helper";
import SelfRegisterHeader from "./SelfRegisterHeader";

const RegisterWithPin = () => {
  const [pin, setPin] = React.useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    routing_using_history(`/qp/${pin}`);
  };

  const pinValidation = (e) => {
    const pinValue = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");
    if (pinValue.length <= 6) {
      setPin(pinValue);
    }
  };

  return (
    <div>
      <Container fluid>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <Card>
                <SelfRegisterHeader />
                <Card.Body>
                  <Row>
                    <Col xl="6" lg="8" md="8" sm="12">
                      <Form.Group className="mb-3">
                        <label style={{ fontSize: "15px" }}>PIN</label>
                        <Form.Control
                          value={pin}
                          name="pin"
                          onChange={pinValidation}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6" lg="8" md="8" sm="12" style={{}}>
                      <Form.Group className="mb-3">
                        <Button name="submit" type="submit" disabled={!pin}>
                          NEXT
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default RegisterWithPin;

import React from "react";
import { Modal, Button } from "react-bootstrap";

const EdgedeviceDeleteModel = (props) => {
  const { showBrowserModal, closeModal, deleteEdgedeviceData, rowData } = props;
  return (
    <>
      <Modal lg={"medium"} show={showBrowserModal} onHide={closeModal}>
        <Modal.Header className="no-border">
          <h4 className="m-0 font-weight-bold">Delete EdgeDevice</h4>
          <Button title={"Close"} type="button" onClick={closeModal} className="close-btn" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove"></i>
          </Button>
        </Modal.Header>

        <Modal.Body className="modal-data-body">
          <p className="theme-color">Are you sure you want to delete ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} type="button" className="btn btn-danger btn-cancel">
            Cancel
          </Button>
          <Button
            style={{ minWidth: "93px" }}
            onClick={() => {
              deleteEdgedeviceData(rowData);
            }}
            type="button"
            className="btn btn-fill btn-info"
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EdgedeviceDeleteModel;

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import db from "./dixie";
import { utcToSpecificTimeZone } from "../helper";
import OfflineSessionTimer from "./OfflineSessionTimer";
import t from "../../Actions/EnforcementActions/types";

export default function GetOfflineData(props) {
  db.open()
    .catch((error) => {
      console.error('Error on GetOfflineData opening the database:', error);
    });

  const { properties, permits, reasons, historic_scanned_plates, tickets, scan_plates } = db;

  const { selectedLot, PropertyId, timeZone, CreateOrUpdateOfflineUser, changeState, lotSelectionError, handleSessionEnd } = props;

  const [offline, setOffline] = useState(false);

  const [OfflineSessionStartTime, setOfflineSessionStartTime] = useState({});

  const [label, setLabel] = useState("Online");

  const [loading, setLoading] = useState(false);

  const [switchDisable, setSwitchDisable] = useState(false);

  const [offline_session_start_time, setoffline_session_start_time] = useState(null);

  const [overLapError, setOverLapError] = useState({ show_error: false, message: "" });

  const { id: user_id } = useSelector((state) => state.UserProfileDetails);

  const dispatch = useDispatch();

  const { GetOfflinePermitData, GetOfflineReasonData, GraceTime, VinVisibility, TicketDefaultLanguage, GetOfflineAuditData, GetOfflineUserData, cloud_to_offline_sync, offline_to_cloud_sync, user_offline } = useSelector(
    (state) => state.EnforcementDetails,
  );


  useEffect(async () => {
    if (GetOfflineUserData?.start_time && user_offline) {
      const start_time = new Date(GetOfflineUserData?.start_time);
      const currentTime = new Date();
      const timeDifference = currentTime - start_time;
      const minutesDifference = Math.floor(timeDifference / 60000);
      const hoursDifference = Math.floor(minutesDifference / 60);

      const offline_start_time = await AddMinutesToDate(start_time, 60);
      setoffline_session_start_time(offline_start_time);
      console.log("minutes: diff:::", minutesDifference);
      if (hoursDifference >= 1 && user_offline.is_offline === 1) {
        handleSessionEnd();
        setOverLapError({
          ...overLapError,
          show_error: true,
          message: "Session time has elapsed. kindly end the session.",
        });
      }
      setOfflineSessionStartTime(GetOfflineUserData?.start_time);
    }
  }, [GetOfflineUserData, user_offline]);

  useEffect(() => {
    if (user_offline?.is_offline === 1) {
      setOffline(true);
      setLabel('Offline');
      changeState(true);
    }
  }, [user_offline?.is_offline])

  useEffect(() => {
    if (offline) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [offline]);

  const AddMinutesToDate = async (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
  };

  const getOfflineData1 = async (e) => {
    if (!navigator.onLine) {
      alert("Please check your internet connection and try again.");
      return;
    }
    setOverLapError({ ...overLapError, show_error: false, message: "" });
    setOffline(!offline);
    changeState(!offline);

    const offline_data = {
      property_id: PropertyId,
      lot_id: selectedLot,
      timezone: timeZone,
      user_id: user_id,
      is_offline: !offline,
    };

    let data = {
      session_data: offline_data
    }

    if (!offline_data.is_offline) {
      const offline_ticktes = await tickets.toArray().then((tickets) => tickets);
      const offline_scan_plates = await scan_plates.toArray().then((scan_plates) => scan_plates);

      dispatch({
        type: t.GET_OFFLINE_TO_CLOUD_SYNC,
        payload: false,
      });
      data = {
        ...data,
        tickets: offline_ticktes,
        scan_plates: offline_scan_plates,
      }
    } else {
      dispatch({
        type: t.GET_CLOUD_TO_OFFLINE_SYNC,
        payload: false,
      });
      data = {
        ...data,
        tickets: [],
        scan_plates: [],
      }
    }

    CreateOrUpdateOfflineUser(data);
  };

  useEffect(() => {
    if (offline_to_cloud_sync) {
      clearAllTables();
    }
  }, [offline_to_cloud_sync]);

  const clearAllTables = async () => {
    const tableNames = db.tables.map((table) => table.name);
    await Promise.all(tableNames.map((tableName) => db[tableName].clear()));
  };

  useEffect(() => {
    if (lotSelectionError) {
      setSwitchDisable(true);
    } else {
      setSwitchDisable(false);
    }
  }, [lotSelectionError]);


  useEffect(() => {
    setLoading(true);
    if (!cloud_to_offline_sync && cloud_to_offline_sync !== null) {
      setLoading(true);
    } else if (!offline_to_cloud_sync && offline_to_cloud_sync !== null) {
      setLoading(true);
    } else {
      setLoading(false);
      setLabel(offline ? "Offline" : "Online");
    }
  }, [cloud_to_offline_sync, offline_to_cloud_sync, offline]);


  useEffect(() => {
    if (GetOfflineAuditData?.length && offline && cloud_to_offline_sync) {
      historic_scanned_plates
        .bulkPut(GetOfflineAuditData)
        .then(() => {
          console.log("scanned_plates  Records inserted or updated successfully.");
        })
        .catch((error) => {
          console.error("Error on add permit data:", error);
        });
    }
  }, [GetOfflineAuditData, offline, cloud_to_offline_sync]);

  useEffect(() => {
    if (GetOfflinePermitData?.length && offline && permits && cloud_to_offline_sync) {
      permits
        .bulkPut(GetOfflinePermitData)
        .then(() => {
          console.log("Permits Records inserted or updated successfully.");
        })
        .catch((error) => {
          console.error("Error on add permit data:", error);
        });
    }
  }, [GetOfflinePermitData, offline, cloud_to_offline_sync]);

  useEffect(() => {
    if (+GraceTime >= 0 && offline && properties && TicketDefaultLanguage && cloud_to_offline_sync) {
      const data = {
        property_id: PropertyId,
        ticket_default_language: TicketDefaultLanguage,
        grace_time: GraceTime,
        hide_vin: VinVisibility,
      };
      properties
        .where("property_id")
        .equals(PropertyId)
        .first()
        .then((existingData) => {
          if (existingData) {
            // If data exists, update it
            return properties.where("property_id").equals(PropertyId).modify(data);
          } else {
            // If data doesn't exist, insert it
            return properties.put(data);
          }
        })
        .then(() => {
          console.log("grace time inserted or updated");
        })
        .catch((error) => {
          console.error("error on grace time", error);
        });
    }
  }, [GraceTime, offline, cloud_to_offline_sync]);

  useEffect(() => {
    if (GetOfflineReasonData?.length && offline && reasons && cloud_to_offline_sync) {
      reasons
        .bulkPut(GetOfflineReasonData)
        .then(() => {
          console.log("Reasons Records inserted or updated successfully.");
        })
        .catch((error) => {
          console.error("Error on add reason data:", error);
        });
    }
  }, [GetOfflineReasonData, offline, cloud_to_offline_sync]);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
  };

  const handleSessionEndDueToTimeOverLapped = async () => {
    handleSessionEnd();
    setOverLapError({
      ...overLapError,
      show_error: true,
      message: "Session time has elapsed. kindly end the session.",
    });
  };

  return (
    <>
      {offline && OfflineSessionStartTime && (
        <>
          <div className="d-flex align-items-start">
            <div>Session Start time : {utcToSpecificTimeZone(timeZone, OfflineSessionStartTime)}</div>
          </div>
          {overLapError.show_error ? (
            <span className={"help-block"}>{overLapError.message}</span>
          ) : (
            <div className="ml-auto d-flex align-items-center">
              Time:
              <OfflineSessionTimer startTime={OfflineSessionStartTime} offline_session_start_time={offline_session_start_time} isOffline={offline} handleSessionEnd={handleSessionEndDueToTimeOverLapped} />
            </div>
          )}
        </>
      )}
      <Form.Check
        type="switch"
        id="custom-switch"
        className="ml-2 mt-3"
        label={loading ? "Loading..." : label}
        checked={!offline}
        onChange={getOfflineData1}
        disabled={loading || switchDisable}
      />
    </>
  );
}

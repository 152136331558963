import React, { Component } from "react";
import { connect } from "react-redux";
import { APIResponseActions, ChangePasswordAction } from "../../Actions";
import CustomContentAlert, { routing_using_history, PasswordValidation } from "../helper";
import loginBG from "../../../assets/img/parking-management-system.jpg";
import { Button, Form, Row, Col } from "react-bootstrap";

class ConfirmPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      title: "LocomobiWorld",
      backgroung: loginBG,
      success: false,
      msg: "",
      show_alert: false,
      show_err: false,
      api_success_message: "",
      api_error_message: "",
      reset_id: "",
      passwordErrorMsg: false,
      passwordVisible: false,
      resetPasswordVisible: false,
      passwordErrorMsgDisable: false,
    };
  }

  componentDidMount = () => {
    const getsearchValue = this.props.location.search.split("&");
    const query_Id = getsearchValue[0].split("=")[1];
    const highSecurity = getsearchValue[1].split("=")[1];
    this.setState({ reset_id: query_Id, high_security: highSecurity });
    const windowUrl = window.location?.href?.split("/");
    const baseUrl = windowUrl[0];
    if (baseUrl === "https:") {
      const newurl = "." + windowUrl[2].split(".");
      this.setState({
        url: "." + newurl.split(",")[2],
      });
    } else {
      this.setState({
        url: "dev",
      });
    }
  };

  TogglePasswordVisibility = () => {
    this.setState({
      passwordVisible: !this.state.passwordVisible,
    });
  };

  ToggleResetPasswordVisibility = () => {
    this.setState({
      resetPasswordVisible: !this.state.resetPasswordVisible,
    });
  };

  passwordHandler = (event) => {
    this.setState({ password: event.target.value, show_alert: false });
    const confirmPassword = this.state.confirmPassword;
    if (this.state.high_security === "1") {
      const errMsg = PasswordValidation(event.target.value);
      if (errMsg) {
        this.setState({
          passwordErrorMsg: false,
          passwordErrorMsgDisable: false,
        });
      } else {
        this.setState({
          passwordErrorMsg: true,
        });
        if (confirmPassword === event.target.value) {
          this.setState({
            success: true,
            passwordErrorMsg: true,
            msg: "Password matched",
            passwordErrorMsgDisable: true,
          });
        } else {
          this.setState({
            passwordErrorMsgDisable: false,
          });
        }
      }
      this.setState({ success: false, msg: errMsg });
    } else {
      if (confirmPassword !== event.target.value && confirmPassword) {
        this.setState({
          success: false,
          msg: "Password didn't match",
          passwordErrorMsg: false,
          passwordErrorMsgDisable: false,
        });
      } else if (confirmPassword === event.target.value) {
        this.setState({
          success: true,
          passwordErrorMsg: true,
          passwordErrorMsgDisable: true,
          msg: "Password matched",
        });
      }
    }
  };

  confirmPasswordHandler = (event) => {
    this.setState({ confirmPassword: event.target.value, show_alert: false });
    const password = this.state.password;
    if (this.state.passwordErrorMsg || this.state.high_security) {
      if (password !== event.target.value) {
        this.setState({
          success: false,
          msg: "Password didn't match",
          passwordErrorMsgDisable: false,
        });
      } else {
        this.setState({
          success: true,
          msg: "Password matched",
          passwordErrorMsgDisable: true,
        });
      }
    }
  };

  validatePassword = async () => {
    const password = this.state.password;
    const confirmPassword = this.state.confirmPassword;
    if (password.trim().length && confirmPassword.trim().length) {
      const body = {
        reset_id: this.state.reset_id,
        password_string: this.state.password,
      };
      await this.props.setNewPassword(body);
      if (this.props.api_response) {
        this.setState({
          show_alert: true,
          api_success_message: this.props.api_success_message,
        });
        setTimeout(() => {
          routing_using_history("/login");
          this.setState({ show_alert: false });
        }, 4000);
      } else {
        this.setState({
          show_err: true,
          api_error_message: this.props.api_error_message,
        });
        setTimeout(() => {
          this.setState({ show_err: false });
        }, 4000);
      }
    }
  };

  resetValidationMessage = () => {
    const { success, msg, confirmPassword, password } = this.state;

    if (!success) {
      return <div className="help-block newpassword" dangerouslySetInnerHTML={{ __html: msg }}></div>;
    } else if (password.trim().length && confirmPassword.trim().length) {
      return <div className="success-block newpassword" dangerouslySetInnerHTML={{ __html: msg }}></div>;
    } else {
      return "";
    }
  };

  render() {
    const {
      password,
      confirmPassword,
      title,
      backgroung,
      // msg,
      success,
      show_alert,
      show_err,
      api_success_message,
      api_error_message,
      url,
      passwordErrorMsgDisable,
      passwordVisible,
      resetPasswordVisible,
    } = this.state;
    return (
      <div className="login-container" style={{ backgroundImage: `url(${backgroung})` }}>
        <div className="login-card">
          {show_alert && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
          {show_err && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}
          <div className="info-container">
            <h2 className="title">
              ParkingPermit{url}: <b>{title}</b>
            </h2>
            <p>
              ParkingPermit{url} is a parking management solution designed with ease of access and security in mind. When used with LPR Express, gain seamless
              access to your parking areas without having to push any buttons. Security is enhanced by taking photos of the license plates of all vehicles
              entering and exiting. CallWhenHere, when supported, allows access with a simple phone call or SMS. Visitor permits allow for quick registration of
              drivers, ensuring access and preventing ticketing by enforcement
            </p>
          </div>
          <div className="login-form">
            <form>
              <h2 className="title">Reset Password</h2>
              <Row>
                <Col lg="12">
                  <Form.Group>
                    <label>Password</label>
                    <div className="input-mask-btn">
                      <Form.Control
                        type={passwordVisible ? "text" : "password"}
                        placeholder="password"
                        name="password"
                        value={password}
                        onChange={this.passwordHandler}
                        autoComplete="off"
                      />
                      <button className="mask-password" type="button" onClick={this.TogglePasswordVisibility}>
                        <i className={`fas ${passwordVisible ? "fa-eye" : "fa-eye-slash"}`} />
                      </button>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-0">
                    <label>Confirm Password</label>
                    <div className="input-mask-btn">
                      <Form.Control
                        type={resetPasswordVisible ? "text" : "password"}
                        placeholder="Confirm password"
                        name="password"
                        value={confirmPassword}
                        onChange={this.confirmPasswordHandler}
                        autoComplete="off"
                      />
                      <button className="mask-password" type="button" onClick={this.ToggleResetPasswordVisibility}>
                        <i className={`fas ${resetPasswordVisible ? "fa-eye" : "fa-eye-slash"}`} />
                      </button>
                    </div>
                  </Form.Group>
                  <div className="mb-3">
                    {success && !password.trim().length && !confirmPassword.trim().length && (
                      <div className="help-block newpassword">Password / confirm password cannot be empty</div>
                    )}
                    {this.resetValidationMessage()}
                  </div>
                </Col>
                <Col lg="12">
                  <Button className="btn-fill" variant="info" disabled={!passwordErrorMsgDisable} onClick={() => this.validatePassword()}>
                    Reset Password
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ APIResponseDetails }) => {
  const { api_success_message, api_error_message, api_response } = APIResponseDetails;
  return {
    api_error_message,
    api_success_message,
    api_response,
  };
};
const mapStateToDispatch = {
  setNewPassword: ChangePasswordAction.setNewPassword,
  APIResponseSuccess: APIResponseActions.APIResponseSuccess,
};

export default connect(mapStateToProps, mapStateToDispatch)(ConfirmPassword);

import t from "../../Actions/LoginActions/types";

const initialState = {
  auth: 0,
  userProfileDetails: {},
  error_message_for_subdomain_not_exist: "",
  currect_link: "",
  is_sub_domain_not_exist: true,
  backgroundColor: {},
  userPropertyData: [],
  high_security: 0,
  highsecurity_result: {},
  highsecurity_loginobj: {},
  changePassword: 0,
};

const UserLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.USER_LOGIN_INFO: {
      const returnValue = {
        ...state,
        login_info: action.payload,
      };
      return returnValue;
    }
    case t.USER_LOGIN: {
      return {
        ...state,
        auth: action.payload,
      };
    }
    case t.SUBDOMIAN_NOT_EXIT_ERROR: {
      return {
        ...state,
        error_message_for_subdomain_not_exist: action.payload.message,
        currect_link: action.payload.link,
      };
    }
    case t.IS_SUBDOMIAN_NOT_EXIT: {
      return {
        ...state,
        is_sub_domain_not_exist: action.payload,
      };
    }
    case t.USER_PROFILE_INFO: {
      return {
        ...state,
        userProfileDetails: action.payload,
      };
    }
    case t.SETBACKGROUNDCOLOR: {
      return {
        ...state,
        backgroundColor: action.payload,
      };
    }
    case t.USER_PROPERTY_INFO: {
      return {
        ...state,
        userPropertyData: action.payload,
      };
    }
    case t.SEMI_AUTHENTICATION: {
      return {
        ...state,
        semiAuthorizedUserProfile: action.payload,
      };
    }
    case t.HIGH_SECURITY: {
      return {
        ...state,
        high_security: action.payload,
      };
    }
    case t.CHANGE_PASSWORD: {
      return {
        ...state,
        changePassword: action.payload,
      };
    }
    case t.HIGH_SECURITY_RESULT: {
      return {
        ...state,
        highsecurity_result: action.payload,
      };
    }
    case t.HIGH_SECURITY_LOGINOBJ: {
      return {
        ...state,
        highsecurity_loginobj: action.payload,
      };
    }
    case t.TOGGLE_PROPERTY_MODAL:
      return {
        ...state,
        showPropertyModal: action.payload,
      };
    default:
      return state;
  }
};

export default UserLoginReducer;

import React, { Suspense, Component } from "react";
import { connect } from "react-redux"; // Tools
import { withTranslation } from "react-i18next";
import Routes from "./AppRoutes";
import "../assets/icon.css";
import "../assets/icon_new.css";
import "../assets/iconmoon-new.css";
import { ConnectedRouter } from "connected-react-router";
import history from "./history";
import Loader from "../App/Components/Loader/Loader";
import { ErrorBoundary } from "./errorBoundary";
import SimpleReactLightbox from "simple-react-lightbox";

class App extends Component {
  render() {
    return (
      <React.Fragment>
          <ConnectedRouter history={history}>
          <ErrorBoundary>
            <SimpleReactLightbox>
              <Suspense fallback={<Loader />}>
                <Routes />
              </Suspense>
            </SimpleReactLightbox>
          </ErrorBoundary>
        </ConnectedRouter>
      </React.Fragment>
    );
  }
}

export default withTranslation()(connect(null, null)(App));

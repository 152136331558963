import React, { Component } from "react";
import { Card, Row, Col, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { connect } from "react-redux";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import { EnforcementActions, SuperAdminAction } from "../../Actions";
import Loader from "../Loader/Loader";
import { saveAs } from "file-saver";
import moment from "moment";
import CustomContentAlert, { findBySearch, tableHeightScrollBars, scroolToTop } from "../helper";
import isEqual from "lodash/isEqual";
import PropertDetails from "../LandingPage/PropertySelection";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
const moment_timezone = require("moment-timezone");
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";

class CitationPaidByPayment extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.state = {
      PropertyId: this.props.PropertyId,
      loader: false,
      api_response: false,
      show_alert: false,
      api_success_message: "",
      api_error_message: "",
      count: 0,
      finalTableHeight: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      all_properties: [],
      selectedPropertyObj: {},
      selectedProperty: "",
      selectedDomainName: "",
      childcheckbox: true,
      currentSummaryDate: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      searchValue: "",
      daysDifference: 30,
      citationByPaymentList: [],
      searchedTicketSummary: [],
      startDateTime: "",
      endDateTime: "",
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "Domain",
        width: "120px",
        sortable: true,
        cell: (row) => this.showDomainField(row),
        selector: (row) => row?.domain,
      },
      {
        name: "Status",
        width: "160px",
        cell: (row) => this.showStatusField(row),
        selector: (row) => row.status,
      },
      {
        name: "Site",
        width: "160px",
        cell: (row) => this.showSiteField(row),
        selector: (row) => row.domain,
      },
      {
        name: "Total Paid #",
        width: "120px",
        cell: (row) => this.showTotalCountField(row),
        selector: (row) => row.TotalCount,
      },
      {
        name: "Total Paid $",
        width: "120px",
        sortable: true,
        cell: (row) => this.showTotalAmountField(row),
        selector: (row) => row?.TotalAmount,
      },
    ];
  }

  showTotalAmountField = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{row?.TotalAmount ? row?.TotalAmount : "-"}</Tooltip>}
    >
      <div className="text-truncate text-nowrap pr-2">{row?.TotalAmount ? row?.TotalAmount : "-"}</div>
    </OverlayTrigger>
  );

  showTotalCountField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.TotalCount ? row.TotalCount : "-"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.TotalCount ? row.TotalCount : "-"}</div>
    </OverlayTrigger>
  );

  showSiteField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.domain ? row.domain : "-"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.domain ? row.domain : "-"}</div>
    </OverlayTrigger>
  );

  showStatusField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.status ? row.status : "-"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row.status ? row.status : "-"}</div>
    </OverlayTrigger>
  );

  showDomainField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.status ? row.status : "-"}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-2">{row?.domain}</div>
    </OverlayTrigger>
  );

  componentDidMount = async () => {
    await this.getTickets();
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    this.setState(
      {
        api_response: this.props?.api_response,
        all_properties: this.props?.getAllProperties,
      },
      async () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.ticketList, this.props.ticketList)) {
      this.setState({
        count: this.props.ticketList.count,
        citationByPaymentList: this.props?.ticketList?.rows?.length ? JSON.parse(JSON.stringify(this.props.ticketList.rows)) : [],
      });
    }
  };

  getTickets = async () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, timezone, childcheckbox, startDate, endDate } = this.state;
      const pageobj = {
        PropertyId,
        timezone,
        childcheckbox,
      };
      if (startDate) {
        pageobj.startDate = startDate;
      }
      if (endDate) {
        pageobj.endDate = endDate;
      }
      await this.props.getCitationPaidPaymentSummary(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      this.setState({
        citationByPaymentList: this.props?.ticketList?.rows?.length ? JSON.parse(JSON.stringify(this.props.ticketList.rows)) : [],
        api_response: this.props.api_response,
        count: this.props.ticketList.count,
      });
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({ loader: false });
      const height = tableHeightScrollBars();
      this.setState({
        finalTableHeight: height,
      });
    });
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  dataTableHandle = (ticketList, searchedTicketSummary, searchValue) => {
    return (
      <DataTable
        data={searchValue?.length ? searchedTicketSummary : ticketList}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleCheckBox = () => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState({
      childcheckbox: !this.state.childcheckbox,
      year: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentSummaryDate: null,
    });
  };

  changeProperty(event) {
    this.setState({
      selectedProperty: event.value,
      selectedPropertyObj: event,
      loader: false,
    });
  }

  handleSearchSummary = async () => {
    this.setState({ loader: true });
    const { PropertyId, selectedPropertyObj, childcheckbox, startDate, endDate, currentSummaryDate, timezone, year } = this.state;
    const pageobj = { timezone };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (childcheckbox) {
      pageobj.childcheckbox = childcheckbox;
    }
    if (startDate) {
      pageobj.startDate = startDate;
    }
    if (endDate) {
      pageobj.endDate = endDate;
    }
    if (year) {
      pageobj.year = moment_timezone(year).format("YYYY");
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    await this.props.getCitationPaidPaymentSummary(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    this.setState({
      citationByPaymentList: this.props?.ticketList?.rows && JSON.parse(JSON.stringify(this.props.ticketList.rows)),
      api_response: this.props.api_response,
      count: this.props.ticketList.count,
      loader: false,
    });
    document.body.classList.remove("foc-open");
  };

  handleResetSummary = async () => {
    const { PropertyId, selectedDomainName, daysDifference } = this.state;
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - daysDifference);
    this.setState({
      loader: false,
      selectedPropertyObj: {
        label: selectedDomainName,
        value: PropertyId,
      },
      selectedProperty: PropertyId,
      childcheckbox: true,
      currentSummaryDate: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      year: "",
    });
  };

  handleChangeYear = (date) => {
    this.setState({
      year: date,
      currentSummaryDate: "",
      startDate: "",
      endDate: "",
      month: null,
      currentDate: null,
      loader: false,
    });
  };

  handleFilterByDate = (date) => {
    this.setState({
      currentSummaryDate: date,
      year: "",
      startDate: "",
      endDate: "",
      currentDate: null,
      loader: false,
    });
  };

  handleChangeStartingDates = (date) => {
    if (date) {
      const { daysDifference } = this.state;
      const end = new Date(date);
      end.setDate(date.getDate() + daysDifference);
      let endDate = new Date();
      if (new Date() >= end) {
        endDate = end;
      }
      this.setState({
        startDate: date,
        year: "",
        month: null,
        endDate: endDate,
        currentSummaryDate: "",
        loader: false,
      });
    }
  };

  handleChangeEndDates = (date) => {
    if (date) {
      this.setState({
        endDate: date,
        year: "",
        month: null,
        currentSummaryDate: "",
        loader: false,
      });
    }
  };

  handleExport = async () => {
    const { PropertyId, selectedPropertyObj, childcheckbox, startDate, endDate, currentSummaryDate, selectedDomainName, timezone, year } = this.state;
    const pageobj = { timezone, exportData: true };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (childcheckbox) {
      pageobj.childcheckbox = childcheckbox;
    }
    if (startDate) {
      pageobj.startDate = startDate;
    }
    if (year) {
      pageobj.year = moment_timezone(year).format("YYYY");
    }
    if (endDate) {
      pageobj.endDate = endDate;
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    const bufferData = await this.props.getCitationPaidPaymentSummary(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `CITATTION-PAID-BY-PAYMENT-MODE-${currentTimeDate}-${selectedDomainName}.xls`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj);
    this.setState({
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();

    this.setState({ searchValue: val }, async () => {
      const columnList = ["IssuedDate", "domain", "TotalCount", "TotalAmount"];
      if (this.state.searchValue.length) {
        if (!this.state.findMore) {
          this.setState(
            {
              searchedTicketSummary: findBySearch(
                this.state.searchValue,
                this.state.citationByPaymentList,
                this.state.citationByPaymentList,
                columnList,
                this.props.timezone,
              ),
            },
            () => {
              this.setState({ count: this.state.searchedTicketSummary.length });
            },
          );
        }
      } else {
        this.setState({
          count: this.props.ticketList.count,
          citationByPaymentList: this.props.ticketList.rows,
          searchedTicketSummary: [],
        });
      }
    });
  };

  render() {
    const {
      searchValue,
      loader,
      childcheckbox,
      selectedPropertyObj,
      citationByPaymentList,
      currentSummaryDate,
      startDate,
      endDate,
      searchedTicketSummary,
      year,
    } = this.state;
    return (
      <div>
        <Card className="mb-0">
          <Card.Body className="pos-rel-overflow-hide">
            <>
              <div className="sidebarFilter">
                <Card className="contact-container mb-0">
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title as="h5">Citation Paid</Card.Title>
                      <a
                        onClick={this.hideSlider}
                        href="javascript:void(0);"
                        className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="mb-2">
                          <label className="fancy-checkbox mt-2">
                            <input
                              type="checkbox"
                              name={"childcheckbox"}
                              checked={childcheckbox}
                              onChange={this.handleCheckBox}
                              className="custom-control-input"
                              id="childcheckbox1"
                            ></input>
                            <span>
                              <i></i>Include Children Sites
                            </span>
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <PropertDetails selectedPropertyObj={selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Specific Year:</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Date"
                            selected={year}
                            value={year}
                            disabled={loader || childcheckbox}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleChangeYear}
                            showYearPicker
                            dateFormat="yyyy"
                            maxDate={new Date()}
                            minDate={new Date(2021, 0, 1)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Specific Date:</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Date"
                            selected={currentSummaryDate}
                            value={currentSummaryDate}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleFilterByDate}
                            dateFormat="MM/dd/yyyy"
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Start Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Start Date"
                            selected={startDate}
                            defaultHour={24}
                            value={startDate}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleChangeStartingDates}
                            dateFormat="MM/dd/yyyy"
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">End Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select End Date"
                            selected={endDate}
                            disabled={loader}
                            value={endDate}
                            selectsEnd
                            timeInputLabel="Time:"
                            onChange={this.handleChangeEndDates}
                            dateFormat="MM/dd/yyyy"
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-right justify-content-between d-flex">
                    <Button variant="info" className="h-40 custom-btn-secondary btn-sm ml-2" onClick={this.handleResetSummary} disabled={loader}>
                      Reset
                    </Button>
                    <Button variant="info" className="h-40 btn-fill btn-sm" onClick={this.handleSearchSummary} disabled={loader}>
                      Search
                    </Button>
                  </Card.Footer>
                </Card>
              </div>
              <header>
                <div className="active-permit-header sm-flex-column sm-align-items-start">
                  <h5 className="mb-2 mb-md-0">Citation Paid By Payment Mode</h5>

                  <div className="filter-button d-flex flex-wrap">
                    <div className="d-flex align-items-center search-mb-0">
                      <DatatableHeader className="mt-0 mb-0" searchValue={searchValue} HandleChange={this.handleSearchChange} />
                    </div>
                    <div className="ml-2">
                      <Button
                        variant="info"
                        className="btn-fill btn-sm"
                        onClick={this.handleExport}
                        disabled={this.state.citationByPaymentList && this.state.citationByPaymentList.length > 0 ? 0 : 1}
                        size="medium"
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </header>
            </>
            {loader ? (
              <Loader />
            ) : (
              <>
                {this.state.show_alert && <CustomContentAlert delay={4000} message={this.state.api_success_message} className="toast-success" />}
                {citationByPaymentList && (
                  <>
                    <div className="citation-list-table list-of-ravenue-table has-parent-tab">
                      {this.dataTableHandle(citationByPaymentList, searchedTicketSummary, searchValue)}
                    </div>
                    <DateRangeAndAmount
                      ReportList={this.props.ticketList?.rows}
                      startDateTime={this.state.startDateTime}
                      endDateTime={this.state.endDateTime}
                      amountName="TotalAmount"
                    />
                  </>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, Logindetails, EnforcementDetails, SuperAdminDetails }) => {
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;
  const { citationPaidList: ticketList } = EnforcementDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { getAllProperties } = SuperAdminDetails;
  return {
    api_response,
    api_error_message,
    api_success_message,
    PropertyId,
    timezone,
    property,
    ticketList,
    main_body,
    getAllProperties,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  getCitationPaidPaymentSummary: EnforcementActions.getCitationPaidPaymentSummary,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
};

export default connect(mapStateToProps, mapStateToDispatch)(CitationPaidByPayment);

import React from "react";
const PleaseWaitComponent = (msg) => {
  return (
    <React.Fragment>
      <div
        className={
          "d-flex justify-content-center align-items-center flex-grow-1 flex-column h4"
        }
      >
        <span>{msg.msg}</span>
      </div>
    </React.Fragment>
  );
};
export default React.memo(PleaseWaitComponent);

import React, { Component } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { connect } from "react-redux";

import { SuperAdminAction } from "../../Actions";
import TotalReport from "./TotalReport";
import PaidUnpaidCitations from "./PaidUnpaidCitations";
import TotalStatus from "./TotalStatus";
import ByEnforcer from "./ByEnforcer";
import ByEnforcerPaid from "./ByEnforcerPaid";

class Validations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
    };
  }

  onTabClick = async (k) => {
    this.setState({ activeTab: k });
  };

  render() {
    return (
      <Container fluid>
        <Tabs activeKey={this.state.activeTab} id="controlled-tab-example" onSelect={(k) => this.onTabClick(k)} transition={false}>
          <Tab eventKey="1" title="Total" className="m-0">
            {this.state.activeTab === "1" && <TotalReport />}
          </Tab>
          <Tab eventKey="2" title="Payable" className="m-0">
            {this.state.activeTab === "2" && <PaidUnpaidCitations />}
          </Tab>
          <Tab eventKey="3" title="By Status" className="m-0">
            {this.state.activeTab === "3" && <TotalStatus />}
          </Tab>
          <Tab eventKey="4" title="By Enforcer" className="m-0">
            {this.state.activeTab === "4" && <ByEnforcer />}
          </Tab>
          <Tab eventKey="5" title="By Enforcer Paid" className="m-0">
            {this.state.activeTab === "5" && <ByEnforcerPaid />}
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails }) => {
  const { property_id: PropertyId, user_type, suite_id, timezone, property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;

  const { MainBackground: main_body } = backgroundColor;
  return {
    PropertyId,
    user_type,
    suite_id,
    timezone,
    property,
    main_body,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(Validations);

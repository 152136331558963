const types = {
  SET_METER_DEVICE_DATA: "SET_METER_DEVICE_DATA",
  SET_METER_CREATE_FLAG: "SET_METER_CREATE_FLAG",
  SET_METER_DEVICE_SUCCESS: "SET_METER_DEVICE_SUCCESS",
  SET_METER_DEVICE_ERROR: "SET_METER_DEVICE_ERROR",
  GET_ALL_METER_DEVICES: "GET_ALL_METER_DEVICES",
  FETCH_ALL_SERVER_ID: "FETCH_ALL_SERVER_ID",
  CLOSE_OPEN_MODEL: "CLOSE_OPEN_MODEL",
  SHOW_HIDE_LOADER: "SHOW_HIDE_LOADER",
};
export default types;

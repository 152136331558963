const adminDashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "Site Setup",
    // path: "",
    icon: "fas fa-sign-out-alt",
  },
  {
    path: "/units",
    name: "Units And Permits",
    icon: "icon-manage_residents",
  },
  {
    path: "/view-occupancy",
    name: "OCCUPANCY",
    icon: "icon-occupancy",
  },
  {
    path: "/passagepass-validation",
    name: "Passagepass Validation",
    icon: "icon-passagepassvalidation",
  },
  {
    path: "/visitor-permit",
    name: "Add Visitor",
    icon: "icon-add-visitor",
  },
  {
    path: "/current-visitor-permit",
    name: "Current Visitors",
    icon: "icon-current-visitor",
  },
  {
    path: "/current-permits",
    name: "View All Permits",
    icon: "icon-view-all-permit",
  },
  {
    path: "/lpr-report",
    name: "LPR Report",
    icon: "icon-lpr-report",
  },
  {
    path: "/unit-occupancy",
    name: "Parking Log",
    icon: "fas fa-clipboard-check",
  },
  {
    path: "/photo-verify",
    name: "Photo Verify",
    icon: "icon-photo-verify",
  },
  {
    path: "/current-occupancy",
    name: "Plate Audit",
    icon: "icon-plate-audit",
  },
  {
    path: "/report",
    name: "Report",
    icon: "fas fa-file",
  },
  {
    path: "/report-enforcement-citation-issued",
    name: "Citation Issued Report",
    icon: "fas fa-file",
  },
  {
    path: "/report-enforcement-citation-paid-summary",
    name: "Citation Paid Report",
    icon: "fas fa-file",
  },
  {
    path: "/audit-report",
    name: "Photo Verify Report",
    icon: "fas fa-file",
  },
  {
    path: "/report-enforcement-citation-analysis",
    name: "Citation Analysis Report",
    icon: "fas fa-file",
  },
  {
    path: "/report-citation",
    name: "Citation Report",
    icon: "fas fa-file",
  },
  {
    path: "/report-revenue",
    name: "Revenue Report",
    icon: "fas fa-file",
  },
  {
    path: "/report-health",
    name: "Health Report",
    icon: "fas fa-file",
  },
  {
    path: "/report-dispenser",
    name: "Ticket Dispenser Report",
    icon: "fas fa-file",
  },
  {
    path: "/report-vend-control",
    name: "Vend Control",
    icon: "fas fa-file",
  },
  {
    path: "/report-validation",
    name: "Validation report",
    icon: "fas fa-file",
  },
  {
    path: "/report-rate-tracking",
    name: "Rate Tracking report",
    icon: "fas fa-file",
  },
  {
    path: "/report-transactions",
    name: "Transactions report",
    icon: "fas fa-file",
  },
  {
    path: "/report-vend-control",
    name: "Vend Control report",
    icon: "fas fa-file",
  },
  {
    path: "/refund",
    name: "Refund Report",
    icon: "fas fa-file",
  },

  {
    path: "/report-invoice",
    name: "Invoice Report",
    icon: "fas fa-file",
  },
  {
    path: "/battery-report",
    name: "Meter Battery Report",
    icon: "fas fa-file",
  },
  {
    path: "/length_of_stay_report",
    name: "Length Of Stay Report",
    icon: "fas fa-file",
  },
  {
    path: "/meter_status_report",
    name: "Meter Status Report",
    icon: "fas fa-file",
  },
  {
    path: "/last_transaction_report",
    name: "Last Transaction Report",
    icon: "fas fa-file",
  },
  {
    path: "/access_log_report",
    name: "Access Log Report",
    icon: "fas fa-file",
  },
  {
    path: "/session-or-stats-report",
    name: "Session or Stats Report",
    icon: "fas fa-file",
  },
  {
    path: "/live-feed",
    name: "Live Feed",
    icon: "icon-live-Feed",
  },
  {
    path: "/smart-gate",
    name: "Smart Gate",
    icon: "icon-smart-gate",
  },
  {
    path: "/gate-scheduler",
    name: "Gate Scheduler",
    icon: "icon-gate-scheduler",
  },
  {
    path: "/citation-list",
    name: "CITATIONS",
    icon: "icon-ticket-list",
  },
  {
    path: "/passage-pass",
    name: "PASSAGE PASS",
    icon: "icon-passagepass",
  },
  {
    path: "/parking-rate",
    name: "MANAGE PARKING RATE",
    icon: "icon-manage-rate",
  },
  {
    path: "/lot-sharing",
    name: "LOT SHARING",
    icon: "icon-lot-sharing",
  },
  {
    path: "/add-quick-permit",
    name: "QUICK PERMITS",
    icon: "icon-quick-permit",
  },
  {
    path: "/self-register",
    name: "SELF REGISTER",
    icon: "icon-self-register",
  },
  {
    path: "/managepermit-packages",
    name: "MANAGE PERMIT PACKAGES",
    icon: "icon-manage-permit-packages",
  },
  {
    path: "/unitpermit-packages",
    name: "UNIT PERMIT PACKAGES",
    icon: "icon-paid-permit-unit-packages",
  },
  {
    path: "/issue-permits-packages",
    name: "ISSUE PERMIT PACKAGES",
    icon: "icon-issue-permits-packages",
  },
  {
    path: "/manage-admin",
    name: "MANAGE ADMIN",
    icon: "icon-manage-users",
  },
  {
    path: "/user-search",
    name: "USER SEARCH",
    icon: "fa fa-search",
  },
  {
    path: "/create-validation-coupons",
    name: "VALIDATION COUPON",
    icon: "icon-coupon",
  },
  {
    path: "/check-discount-coupon-validity",
    name: "PARKING DISCOUNT AUDIT",
    icon: "icon-parking-discount-audit",
  },
  {
    path: "/parking-rate-check",
    name: "PARKING RATE CHECK",
    icon: "icon-parking-rate-check",
  },
  {
    path: "/cashout",
    name: "Cashouts",
    icon: "icon-cashouts",
  },
  {
    path: "/contact",
    name: "Contact Support",
    icon: "fas fa-phone-square-alt",
  },
  {
    path: "/user-profile",
    name: "My Account",
    icon: "fas fa-user-circle",
  },
  {
    path: "/activity-logs-report",
    name: "Activity Logs Report",
    icon: "fas fa-user-circle",
  },
  {
    name: "Logout",
    icon: "fas fa-sign-out-alt",
  },
];

export default adminDashboardRoutes;

import React, { Component } from "react";
import { Card, Container, Row, Col, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { ResidentsActions, SuperAdminAction } from "../../Actions";
import Config from "../../../Auth/config";
import moment from "moment";
import CustomContentAlert, { routing_using_history, isEmailValid, regExForPhone, validation_message, validations } from "../helper";
import SearchAutocomplete from "../Ticket/SearchAutocomplete";
class ResidentPermit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addNewResident: null,
      id: "",
      permit_start_date: "",
      permit_end_date: "",
      allLots: this.props.allLots,
      vehicle_license: "",
      stallNumber: "",
      isPermitDatevalid: true,
      isFormValid: true,
      isvehicleLicenseValid: true,
      isStallNumberValid: true,
      wrongEmail: false,
      api_success_message: "",
      show_alert: false,
      tag_number: "",
      isTagNumberValid: true,
      call_when_here: this.props?.property?.call_when_here,
      mainoffice: true,
      backoffice: true,
      visitor: true,
      make: "",
      modal: "",
      color: "",
      utcOffset: "",
      prevmainoffice: true,
      prevbackoffice: true,
      prevVisitor: true,
      prevSearch: "",
      prevPage: "",
      prevNorow: "",
      show_error: false,
      selectedColumn: "suite_id",
      asc: "false",
      sort_direction: "DESC",
      findMore: false,
      isChecked: true,
      isLotSelected: true,
      suite_id: "",
      property: this.props.property,
      lotSelectionError: "",
      defaultDateDifference: "",
      is_extended_lpn_info: false,
      atleastOne: "",
      country_code_list: [
        { id: 1, text: "+1", value: "+1" },
        { id: 2, text: "+91", value: "+91" },
        { id: 3, text: "+44", value: "+44" },
        { id: 4, text: "+61", value: "+61" },
      ],
      phone_options: [],
    };
  }

  componentDidMount() {
    this.setState({
      prevmainoffice: this.state.mainoffice,
      prevbackoffice: this.state.backoffice,
      prevVisitor: this.state.visitor,
    });
    const id = this.props?.location?.search?.split("?")[1] ? this.props.location.search.split("?")[1] : "";
    this.setState({ id }, async () => {
      const PropertyId = this.props.property_id;
      // For user details
      console.log("PropertyIda", PropertyId, id);
      await this.props.getResidentDetails(id, PropertyId);

      const allLotsDetails = await this.getAllLotDetails();

      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      if (this.props.addNewResident.user) {
        this.setState(
          {
            defaultDateDifference: this.props.addNewResident.defaultDateDifference ? this.props.addNewResident.defaultDateDifference : "",
            is_extended_lpn_info: this.props.addNewResident.is_extended_lpn_info,
            addNewResident: this.props.addNewResident.user,
            allLots: allLotsDetails,
            utcOffset: this.props.addNewResident.utc_offset,
          },
          () => {
            this.settingData();
            this.setState({
              suite_id: this.state.addNewResident?.user?.suite_id,
            });
          },
        );
      } else {
        this.handleBack();
      }
    });
  }

  getAllLotDetails = async () => {
    let allLotsDetails = this.props.addNewResident.lots;
    allLotsDetails = (allLotsDetails ?? []).map((item) => {
      if (item.visitor_lot || item.quick_permits) {
        item.isChecked = false;
      } else {
        if (item.show_lot === true) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      }
      return item;
    });
    return allLotsDetails;
  };

  settingData = () => {
    const { addNewResident, defaultDateDifference } = this.state;
    if (addNewResident) {
      let showVehicleDetail = true;
      if (!Config.residentVehicleDetail) {
        showVehicleDetail = false;
      }
      this.setState({
        ...addNewResident,
        showVehicleDetail,
        vehicle_make: "-",
        vehicle_model: "-",
        vehicle_color: "-",
        permit_start_date: moment().format("L"),
        permit_end_date: moment().add(defaultDateDifference, "days").format("L"),
        // permit_end_date: moment().format("L"),
      });
    }
  };

  handleBack = () => {
    routing_using_history(`/units`);
  };

  handleChangeStartingDates = (date) => {
    const { defaultDateDifference } = this.state;
    const newDate = date !== null ? date : this.state.permit_start_date;
    date = moment(newDate).format("L");
    this.setState({ permit_start_date: date }, () => {
      const endDate = moment(date).add(defaultDateDifference, "days").format("L");
      this.setState({
        permit_end_date: endDate,
      });
    });
  };

  handleChangeEndingDates = (date) => {
    const newDate = date !== null ? date : this.state.permit_end_date;
    date = moment(newDate).format("L");
    this.setState({ permit_end_date: date });
  };

  handlePhoneOptions = (index, value, type) => {
    console.log("index, value :::::", index, value, type);

    const { phone_options } = this.state;

    let phone_values;
    let country_code_values;

    if (type === "phone_number_options") {
      phone_values = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      phone_options[index].phone = phone_values;
    }
    if (type === "country_code_options") {
      country_code_values = value;
      phone_options[index].country_code = country_code_values;
    }

    this.setState(
      {
        phone_options: [...phone_options],
      },
      async () => {
        this.checkAllValidations();
      },
    );
  };

  removePhoneOption = (index) => {
    const { phone_options } = this.state;
    console.log("index", index);
    this.setState(
      {
        phone_options: [...phone_options.filter((_x, i) => i !== index)],
      },
      async () => {
        this.checkAllValidations();
      },
    );
  };

  handleAddPhoneOptions = () => {
    const { phone_options } = this.state;
    this.setState(
      {
        phone_options: [...phone_options, { country_code: "+1", phone: "" }],
      },
      async () => {
        this.checkAllValidations();
      },
    );
  };

  isMultiplePhoneValid = () => {
    const { phone_options, country_code, phone } = this.state;
    const phoneListCount = {};
    const phoneList = phone_options.map((item) => {
      return item.country_code + item.phone;
    });
    const phoneNumberCountry_code = country_code === "" ? "+1" : country_code;
    const phoneNumber = phone === "" ? "" : phone;
    phoneList.push(phoneNumberCountry_code + phoneNumber);
    phoneList.forEach(function (x) {
      phoneListCount[x] = (phoneListCount[x] || 0) + 1;
    });
    const phoneListStatus = Object.keys(phoneListCount).filter((item) => phoneListCount[item] > 1);
    const validPhone = phone_options.map((options) => {
      if (options.phone?.trim().length > 10 || options.phone?.trim().length < 10 || options.phone?.trim().length === 0 || phoneListStatus.length) {
        return !(options.phone.trim().length > 10 || options.phone.trim().length < 10 || options.phone.trim().length === 0 || phoneListStatus.length);
      }
      return true;
    });
    return !validPhone.includes(false);
  };

  multiplePhoneRender = (phone_options, country_code_list) => {
    return (
      phone_options &&
      typeof phone_options === "object" &&
      Array.isArray(phone_options) &&
      phone_options.map((option, index) => {
        return (
          <Row key={option?.country_code} className="my-0 m-w-480px">
            <Col className="col-auto">
              <Form.Group>
                <Form.Control
                  className="w-85px"
                  as="select"
                  name="country_code_options"
                  onChange={(e) => this.handlePhoneOptions(index, e.target.value, "country_code_options")}
                  value={option?.country_code}
                  required
                >
                  {country_code_list ? (
                    <>
                      {(country_code_list ?? []).map((item) => (
                        <option key={item?.id} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    </>
                  ) : null}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="col p-0">
              <Form.Group className="p-0">
                {/* <Form.Label>Option {index + 1}</Form.Label> */}
                {/* <span className="asterisk">*</span> */}
                <Form.Control
                  placeholder="Phone Number"
                  type="text"
                  name="phone_number_options"
                  value={option?.phone}
                  // onBlur={(e) => e.target.value.trim() === "" && removeNoteOption(index)}
                  onChange={(e) => this.handlePhoneOptions(index, e.target.value, "phone_number_options")}
                  maxLength="10"
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
            <Col className="col-auto">
              <Button className="h-40px" size="sm" onClick={() => this.removePhoneOption(index)}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
          </Row>
        );
      })
    );
  };

  handleChange = ({ target }) => {
    const prevstate = this.state.phone;
    this.setState({ [target.name]: target.value }, () => {
      const { country_code, phone, email, vehicle_license, tag_number } = this.state;
      if (target.name === "vehicle_license" || target.name === "tag_number") {
        this.setState({
          atleastOne: "",
        });
      }
      if (!vehicle_license.trim().length && !tag_number.trim().length) {
        this.setState({
          atleastOne: "Atleast License Plate Number or Access Card field is required",
        });
      }
      if (target.name === "phone" && phone.trim().length) {
        if (regExForPhone.test(target.value)) {
          this.setState({ phone: target.value });
        } else {
          this.setState({ phone: prevstate });
        }
      }
      if (target.name === "tag_number" && tag_number.trim().length) {
        this.setState({
          tag_number: target.value.replace(/\D/g, ""),
        });
      }
      if (email?.trim().length) {
        this.validateForm();
      }
      this.checkAllValidations();

      const FirstName = validations(target.name, "first_name", this.state.first_name);
      this.setState({
        first_name: FirstName.value,
      });

      const LastName = validations(target.name, "last_name", this.state.last_name);
      this.setState({
        last_name: LastName.value,
      });

      if (target.name === "phone" && this.state.phone.trim().length) {
        if (regExForPhone.test(this.state.phone)) {
          this.setState({ phone: target.value });
        } else {
          this.setState({ phone: prevstate });
        }
      } else {
        const Phone = validations(target.name, "phone", this.state.phone);
        this.setState({
          phone: Phone.value,
        });
      }
      const VehicalLicense = validations(target.name, "vehicle_license", this.state.vehicle_license);

      this.setState({
        vehicle_license: VehicalLicense.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, ""),
        isvehicleLicenseValid: VehicalLicense.validator,
      });
      if (target.name === "country_code" && country_code.trim().length) {
        this.setState({
          country_code: target.value.replace(/[a-zA-Z]/, ""),
        });
      }
    });
  };

  validateForm = () => {
    const {
      first_name,
      last_name,
      phone,
      email,
      // stallNumber,
      // vehicle_license,
      // tag_number,
      isLotSelected,
    } = this.state;
    let isEmailValidate = true;
    if (email) {
      isEmailValidate = isEmailValid(email);
      this.setState({
        wrongEmail: !isEmailValidate,
      });
    }

    return isEmailValidate && phone.trim().length && first_name.trim().length && last_name.trim().length && isLotSelected;
  };

  checkAllValidations = () => {
    const { first_name, last_name, phone, isPermitDatevalid, isLotSelected } = this.state;
    if (
      first_name?.trim().length &&
      last_name?.trim().length &&
      phone?.trim().length &&
      this.validateForm() &&
      isPermitDatevalid &&
      isLotSelected &&
      this.isMultiplePhoneValid() &&
      !(phone?.trim()?.length > 10 || phone?.trim()?.length < 10)
    ) {
      this.setState({
        isFormValid: true,
      });
    } else {
      this.setState({
        isFormValid: false,
      });
    }
  };

  handleSubmit = async () => {
    const {
      addNewResident,
      vehicle_license,
      stallNumber,
      tag_number,
      call_when_here,
      allLots,
      first_name,
      last_name,
      email,
      country_code,
      phone,
      phone_options,
      color,
      make,
      modal,
    } = this.state;
    const residentData = {
      first_name,
      last_name,
      email,
      country_code,
      phone,
      phone_options,
      color,
      make,
      modal,
      suite_id: addNewResident.suite_id,
    };
    const PropertyId = this.props.property_id;
    const obj = {
      ...residentData,
      vehicle_license,
      stallNumber,
      tag_number,
      call_when_here: call_when_here ? 1 : 0,
    };
    if (vehicle_license === "" && tag_number === "") {
      this.setState({
        atleastOne: "Atleast License Plate Number or Access Card field is required",
      });
      return true;
    }

    if (vehicle_license === "") {
      obj.vehicle_license = "NA";
    }
    const lotIdToPass = [];
    this.setState({ isFormValid: false });
    const checkedLots = allLots.filter((item) => item.isChecked === true);
    if (checkedLots?.length === 0) {
      this.setState({
        isLotSelected: false,
        lotSelectionError: "Lot is Required.",
      });
      return true;
    }
    checkedLots.map((item) => lotIdToPass.push(item.id));
    await this.props.visitorCreate(
      PropertyId,
      this.state.permit_start_date,
      this.state.permit_end_date,
      this.props.id,
      obj,
      lotIdToPass,
      false,
      this.props.timezone,
    );
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      setTimeout(() => {
        this.setState({ show_alert: false });
        this.handleBack();
      }, 4000);
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  handleCheckBox = (lotId) => {
    let allLotsDetails = this.state.allLots;
    allLotsDetails = allLotsDetails.map((item) => {
      if (item.id === lotId) {
        if (item && "isChecked" in item) {
          item.isChecked = !item.isChecked;
        } else {
          item.isChecked = true;
        }
      }
      return item;
    });
    this.setState({ allLots: allLotsDetails }, () => {
      const isLotSelected = !!this.state.allLots.filter((lot) => lot.isChecked === true).length;
      this.setState(
        {
          isLotSelected: isLotSelected,
        },
        () => {
          if (isLotSelected && this.validateForm()) {
            this.setState({ isFormValid: true });
          } else {
            this.setState({
              isFormValid: false,
            });
          }
          if (isLotSelected) {
            this.setState({
              lotSelectionError: "",
            });
          } else {
            this.setState({
              lotSelectionError: "Please select at least one lot",
            });
          }
        },
      );
    });
  };

  handleCWH = (type) => {
    this.setState({ [type]: !this.state[type] });
  };

  handleAlertShow = (show_alert, show_error, api_success_message, api_error_message) => {
    if (show_alert) {
      return <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />;
    }
    if (show_error) {
      return <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />;
    }
  };

  render() {
    const {
      addNewResident,
      username,
      first_name,
      last_name,
      country_code,
      phone,
      email,
      stallNumber,
      vehicle_license,
      tag_number,
      showVehicleDetail,
      vehicle_make,
      vehicle_model,
      vehicle_color,
      permit_start_date,
      permit_end_date,
      isFormValid,
      make,
      modal,
      color,
      wrongEmail,
      call_when_here,
      show_alert,
      api_success_message,
      api_error_message,
      show_error,
      allLots,
      atleastOne,
      country_code_list,
      phone_options,
      phoneErrorMessage,
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <Card.Title as="h4">Driver Permits</Card.Title>
                  <p className="card-category">Fill out the below form to issue a permit.</p>
                </Card.Header>
                {this.handleAlertShow(show_alert, show_error, api_success_message, api_error_message)}
                <Card.Body className="pos-rel-overflow-hide">
                  <div className="visitor-info">
                    <h5 className="inline mt-0">Driver Information</h5>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label>Unit:</label>
                          <Form.Control placeholder="example@gmail.com" type="email" name="username" value={username} readOnly></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <label>&nbsp;</label>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                          <label className="fancy-checkbox mt-2">
                            <input
                              type="checkbox"
                              checked={call_when_here}
                              onChange={() => {
                                this.handleCWH("call_when_here");
                              }}
                            ></input>
                            <span>
                              <i></i>Call when here
                            </span>
                          </label>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="formBasicCheckbox">
                          <label className="fancy-checkbox">
                            <span>
                              <i></i>Lot:
                            </span>
                            <span className="asterisk">*</span>
                          </label>
                          <br />
                          {(allLots ?? [])
                            .filter((item) => item.show_lot)
                            .map((lot) => {
                              return (
                                <label className="fancy-checkbox mt-2 mr-3" key={lot.id}>
                                  <input
                                    type="checkbox"
                                    name={lot.alias}
                                    checked={!!lot?.isChecked}
                                    onChange={() => {
                                      this.handleCheckBox(lot.id);
                                    }}
                                  ></input>
                                  <span>
                                    <i></i>
                                    {lot.alias}
                                  </span>
                                </label>
                              );
                            })}
                        </Form.Group>
                        <span className="help-block">{this.state.lotSelectionError}</span>
                      </Col>
                    </Row>
                  </div>
                  {addNewResident && (
                    <Row>
                      <Col md="12">
                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>
                                Driver Name:
                                <span className="asterisk">*</span>
                              </label>
                              <Form.Control
                                type="text"
                                name="first_name"
                                value={first_name}
                                placeholder="First Name"
                                autoComplete="off"
                                onChange={this.handleChange}
                                required
                              ></Form.Control>
                              {validation_message(first_name, "First Name", isFormValid)}
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>&nbsp;</label>
                              <Form.Control
                                type="text"
                                name="last_name"
                                value={last_name}
                                placeholder="Last Name"
                                autoComplete="off"
                                onChange={this.handleChange}
                                required
                              ></Form.Control>
                              {validation_message(last_name, "LastName", isFormValid)}
                            </Form.Group>
                          </Col>

                          <Col md="6">
                            <Form.Group>
                              <label>
                                Driver Email Address:
                                {/* <span className="asterisk"></span> */}
                              </label>
                              <Form.Control
                                placeholder="example@gmail.com"
                                type="email"
                                name="email"
                                autoComplete="off"
                                onChange={this.handleChange}
                                value={email}
                              ></Form.Control>
                              {email && wrongEmail ? <div className="help-block">Invalid Email Address</div> : null}
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>
                                Driver Stall Number:
                                {/* <span className="asterisk">*</span> */}
                              </label>
                              <Form.Control
                                placeholder="P1-10"
                                type="text"
                                name="stallNumber"
                                autoComplete="off"
                                onChange={this.handleChange}
                                value={stallNumber}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>License Plate Number:</label>
                              <Form.Control
                                placeholder="ABC123"
                                type="text"
                                name="vehicle_license"
                                autoComplete="off"
                                value={vehicle_license}
                                onChange={this.handleChange}
                              ></Form.Control>
                              {atleastOne ? <div className="help-block">{atleastOne}</div> : null}
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>Access Card:</label>
                              <Form.Control
                                placeholder="113123"
                                type="text"
                                name="tag_number"
                                autoComplete="off"
                                value={tag_number}
                                onChange={this.handleChange}
                              ></Form.Control>
                              {atleastOne ? <div className="help-block">{atleastOne}</div> : null}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}

                  {showVehicleDetail && (
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label>Make:</label>
                          <Form.Control
                            placeholder="ABC123"
                            type="text"
                            name="vehicle_make"
                            autoComplete="off"
                            value={vehicle_make}
                            onChange={this.handleChange}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <label>Model:</label>
                          <Form.Control
                            placeholder="ABC123"
                            type="text"
                            name="vehicle_model"
                            autoComplete="off"
                            value={vehicle_model}
                            onChange={this.handleChange}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <label>Color:</label>
                          <Form.Control
                            placeholder="ABC123"
                            type="text"
                            name="vehicle_color"
                            autoComplete="off"
                            value={vehicle_color}
                            onChange={this.handleChange}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  {this.state.is_extended_lpn_info && (
                    <Row>
                      <Col md="4" lg="4" xl="4" className="mb-3">
                        <SearchAutocomplete
                          isRequired
                          label={"Make"}
                          name={"make"}
                          value={make}
                          type={"make"}
                          fromResidentPermit
                          handleChange={(value) => {
                            this.handleChange({
                              target: {
                                name: "make",
                                value: value.make,
                              },
                            });
                          }}
                        />
                      </Col>
                      <Col md="4" lg="4" xl="4" className="mb-3">
                        <SearchAutocomplete
                          isRequired={true}
                          label={"Model"}
                          name={"modal"}
                          value={modal}
                          type={"model"}
                          fromResidentPermit
                          make={make}
                          handleChange={(value) => {
                            if (!make && value.make) {
                              setTimeout(() => {
                                this.handleChange({
                                  target: {
                                    name: "make",
                                    value: value.make,
                                  },
                                });
                              }, 500);
                            }
                            this.handleChange({
                              target: {
                                name: "modal",
                                value: value.model,
                              },
                            });
                          }}
                        />
                      </Col>
                      <Col md="4" lg="4" xl="4" className="mb-3">
                        <SearchAutocomplete
                          isRequired={true}
                          label={"Color"}
                          name={"color"}
                          value={color}
                          type={"color"}
                          fromResidentPermit
                          defaultOptions={["White", "Black", "Gray", "Silver", "Red", "Blue", "Brown", "Green", "Beige", "Orange", "Gold", "Yellow", "Purple"]}
                          handleChange={(value) => {
                            this.handleChange({
                              target: {
                                name: "color",
                                value: value.color,
                              },
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row className="m-w-480px">
                    <Col md="12">
                      <label>Permit Dates:</label>
                      <div className="custom-time d-flex align-items-center mb-1">
                        <div className="d-flex align-items-center sm-flex-column">
                          <div className="mb-3">
                            <label className="mr-1">Start Date:</label>
                            <DatePicker
                              placeholder="04/04/16"
                              minDate={new Date()}
                              maxDate={new Date(permit_end_date)}
                              selected={permit_start_date ? new Date(permit_start_date) : new Date()}
                              onChange={this.handleChangeStartingDates}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="ml-2 mr-1">End Date:</label>
                            <DatePicker
                              placeholder="04/05/16"
                              selected={permit_end_date ? new Date(permit_end_date) : new Date()}
                              onChange={this.handleChangeEndingDates}
                              minDate={new Date(permit_start_date)}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="m-w-480px">
                    <Col className="col-auto">
                      <Form.Group>
                        <label>Country Code:</label>
                        {/* <Form.Control
                                placeholder="0000000000000"
                                type="text"
                                name="country_code"
                                autoComplete="off"
                                onChange={this.handleChange}
                                value={country_code}
                              ></Form.Control> */}
                        <Form.Control className="w-85px" as="select" name="country_code" onChange={this.handleChange} value={country_code}>
                          {country_code_list ? (
                            <>
                              {(country_code_list ?? []).map((item) => (
                                <option key={item?.id} value={item.value}>
                                  {item.text}
                                </option>
                              ))}
                            </>
                          ) : null}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="col p-0">
                      <Form.Group>
                        <label>
                          Driver Contact Number:
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="0000000000000"
                          type="text"
                          name="phone"
                          autoComplete="off"
                          maxLength={10}
                          onChange={this.handleChange}
                          value={phone}
                        ></Form.Control>
                        {validation_message(phone, "Contact Number", isFormValid)}
                        <span className="help-block">{phoneErrorMessage}</span>
                      </Form.Group>
                    </Col>
                    <Col className="col-auto">
                      <div>
                        <label>&nbsp;</label>
                      </div>
                      <Button
                        className="h-40px"
                        size="sm"
                        disabled={
                          phone_options?.length > 9 ||
                          phone?.trim()?.length === 0 ||
                          phone?.trim()?.length > 10 ||
                          phone?.trim()?.length < 10 ||
                          !this.isMultiplePhoneValid()
                        }
                        onClick={this.handleAddPhoneOptions}
                      >
                        <i className="fas fa-plus-circle"></i>
                      </Button>
                    </Col>
                  </Row>
                  {
                    this.multiplePhoneRender(phone_options, country_code_list)
                    // Multiple phone render
                  }

                  <div className="d-flex justify-content-end mt-2">
                    <Button className="btn-fill" type="button" variant="danger" onClick={this.handleBack}>
                      Back
                    </Button>
                    {allLots?.length ? (
                      <Button className="btn-fill ml-2" type="button" variant="info" onClick={this.handleSubmit} disabled={!isFormValid}>
                        Create
                      </Button>
                    ) : null}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, ResidenDetails, APIResponseDetails, Logindetails }) => {
  const { id, property_id, property, timezone } = UserProfileDetails;
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { addNewResident, allLots } = ResidenDetails;
  return {
    timezone,
    api_response,
    api_error_message,
    api_success_message,
    addNewResident,
    allLots,
    id,
    property_id,
    property,
    main_body,
  };
};

const mapStateToDispatch = {
  getResidentDetails: ResidentsActions.getResidentDetails,
  visitorCreate: ResidentsActions.visitorCreate,
  getAlllots: ResidentsActions.getAlllots,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(ResidentPermit);

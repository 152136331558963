import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ChartistGraph from "react-chartist";
import { connect } from "react-redux";
import { APIResponseActions, CurrentPermitActions, DashBoardActions, CurrentVisitorPermitActions, SuperAdminAction } from "../../Actions";
import CustomContentAlert, { routing_using_history, contentLoader } from "../helper";
import ContentLoader from "react-content-loader";
import _i18n from "../Language/i18n";
import { Translation } from "react-i18next";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      options: {},
      type: "",
      dashboardData: [],
      loader: false,
      days: [],
      responsiveOptions: {},
      currentPermits: [],
      visitorPermits: [],
      api_response: true,
      suite_id: this.props.suite_id,
      propertyId: this.props.PropertyId,
      uid: this.props.uid,
      user_type: this.props.user_type,
      property: this.props.property,
      showAllPermit: false,
      showAddVisitor: false,
      unit_language: this.props?.selectedLang || this.props?.language,
    };
  }

  componentDidMount() {
    this.setState({ loader: true }, async () => {
      await this.props.fetch_dashboard(this.state.uid, this.state.propertyId, this.props.timezone);
      _i18n.changeLanguage(this.state.unit_language);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        dashboardData: this.props.dashboardData.reverse(),
        days: this.props.days.reverse(),
        currentPermits: this.props.currentPermits,
        visitorPermits: this.props.visitorPermits,
      });
      this.setState({ loader: false, api_response: this.props.api_response });
      const newModifiedPermissionList = [];
      this.props.allPermissions.forEach((element) => {
        newModifiedPermissionList.push(element.toLowerCase().replace(/[^a-z]/g, ""));
      });
      const isViewAllPermitExist = newModifiedPermissionList.includes("viewallpermits");
      const isAddVisitorExist = newModifiedPermissionList.includes("addvisitor");
      this.setState({
        showAllPermit: isViewAllPermitExist,
        showAddVisitor: isAddVisitorExist,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedLang !== this.props.selectedLang) {
      this.setState({
        unit_language: this.props.selectedLang,
      });
      _i18n.changeLanguage(this.props.selectedLang);
    }
  }

  handleToogleError = () => {
    this.props.APIResponseSuccess();
    this.setState({ showError: !this.state.showError });
  };

  handleQuickLinks = (route) => {
    routing_using_history(route);
  };

  contentLoader = () => (
    <Col lg={12}>
      <Row>
        <Col lg={3}>
          <Card className="card-stats">
            <Card.Body>{contentLoader()}</Card.Body>
          </Card>
        </Col>
        <Col lg={3}>
          <Card className="card-stats">
            <Card.Body>{contentLoader()}</Card.Body>
          </Card>
        </Col>
        <Col lg={3}>
          <Card className="card-stats">
            <Card.Body>{contentLoader()}</Card.Body>
          </Card>
        </Col>
        <Col lg={3}>
          <Card className="card-stats">
            <Card.Body>{contentLoader()}</Card.Body>
          </Card>
        </Col>
      </Row>
    </Col>
  );

  render() {
    const { dashboardData, days, loader, currentPermits, api_response, visitorPermits } = this.state;
    return (
      <Container fluid={true} className="dashboard-container">
        <Row>
          {loader ? (
            <>{this.contentLoader()}</>
          ) : (
            <>
              <Col lg={12}>
                <Row>
                  <Col md={6} lg={6} xl={3}>
                    <Card className="card-stats">
                      <Card.Body>
                        <Row>
                          <Col lg={4} className="col-3">
                            <div className="icon-big text-center icon-warning">
                              <i className="nc-icon nc-square-pin text-warning"></i>
                            </div>
                          </Col>
                          <Col lg={8} className="col-9">
                            <div className="numbers">
                              <p className="card-category">
                                <Translation>{(t) => <>{t("Active Permits")}</>}</Translation>
                              </p>
                              <h4 className="card-title">{currentPermits}</h4>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr />
                        <div className="stats my-2">
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={() => this.handleQuickLinks("/current-permits")}
                            disabled={!this.state.showAllPermit}
                          >
                            <Translation>{(t) => <>{t("View All Permits")}</>}</Translation>
                          </button>
                        </div>
                      </Card.Footer>
                    </Card>
                  </Col>

                  <Col md={6} lg={6} xl={3}>
                    <Card className="card-stats">
                      <Card.Body>
                        <Row>
                          <Col lg={4} className="col-3">
                            <div className="icon-big text-center icon-success">
                              <i className="nc-icon nc-badge text-success"></i>
                            </div>
                          </Col>
                          <Col lg={8} className="col-9">
                            <div className="numbers">
                              <p className="card-category">
                                <Translation>{(t) => <>{t("Active Visitor Permits")}</>}</Translation>
                              </p>
                              <h4 className="card-title">{visitorPermits}</h4>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr />
                        <div className="stats my-2">
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => this.handleQuickLinks("/visitor-permit")}
                            disabled={!this.state.showAddVisitor}
                          >
                            <Translation>{(t) => <>{t("Create Visitor Permit")}</>}</Translation>
                          </button>
                        </div>
                      </Card.Footer>
                    </Card>
                  </Col>
                  <Col md={6} lg={6} xl={3}>
                    <Card className="card-stats">
                      <Card.Body>
                        <Row>
                          <Col lg={4} className="col-3">
                            <div className="icon-big text-center icon-danger">
                              <i className="nc-icon nc-circle-09 text-danger"></i>
                            </div>
                          </Col>
                          <Col lg={8} className="col-9">
                            <div className="numbers">
                              <p className="card-category">
                                <Translation>{(t) => <>{t("Profile")}</>}</Translation>
                              </p>
                              <h4 className="card-title">
                                {this.props?.first_name?.slice(0, 10)}
                                {""}
                                {this.props?.last_name?.slice(0, 10)}
                                {""}
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr />
                        <div className="stats my-2">
                          <button className="btn btn-sm btn-danger" onClick={() => this.handleQuickLinks("/user-profile")}>
                            <Translation>{(t) => <>{t("Update User Profile")}</>}</Translation>
                          </button>
                        </div>
                      </Card.Footer>
                    </Card>
                  </Col>
                  <Col md={6} lg={6} xl={3}>
                    <Card className="card-stats">
                      <Card.Body>
                        <Row>
                          <Col lg={4} className="col-3">
                            <div className="icon-big text-center icon-primary">
                              <i className="nc-icon nc-email-83 text-primary"></i>
                            </div>
                          </Col>
                          <Col lg={8} className="col-9">
                            <div className="numbers">
                              <p className="card-category">
                                <Translation>{(t) => <>{t("Support")}</>}</Translation>
                              </p>
                              <h4 className="card-title">24*7</h4>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr />
                        <div className="stats my-2">
                          <button className="btn btn-sm btn-primary" onClick={() => this.handleQuickLinks("/contact")}>
                            <Translation>{(t) => <>{t("Contact Support")}</>}</Translation>
                          </button>
                        </div>
                      </Card.Footer>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
        <Row>
          {!loader ? (
            <>
              {!api_response && (
                <CustomContentAlert delay={4000} message={"The server did not respond, Please try again after sometime"} className="toast-error" />
              )}
              <Col lg={12}>
                {/* {dashboardData && dashboardData.length ? ( */}
                <Card>
                  <Card.Body className="days-chart">
                    <h4 className="m-0">
                      <Translation>{(t) => <>{t("Permits Created Last 7 Days")}</>}</Translation>
                    </h4>
                    <div className="ct-chart" id="chartActivity">
                      <ChartistGraph
                        data={{
                          labels: days,
                          series: [dashboardData],
                        }}
                        type="Bar"
                        options={{
                          seriesBarDistance: 3,
                          axisX: {
                            showGrid: false,
                            labelInterpolationFnc: (value) => {
                              return value;
                            },
                          },
                          axisY: {
                            showGrid: true,
                            labelInterpolationFnc: (value) => {
                              return value % 1 !== 0 ? null : value;
                            },
                          },
                          height: "245px",
                        }}
                        responsiveOptions={[
                          [
                            "screen and (max-width: 640px)",
                            {
                              seriesBarDistance: 10,
                              axisX: {
                                labelInterpolationFnc: (value) => {
                                  return value;
                                },
                              },
                            },
                          ],
                        ]}
                      />
                    </div>
                  </Card.Body>
                </Card>
                {/* ) : null} */}
              </Col>
            </>
          ) : (
            <Col lg="12">
              <Card>
                <Card.Body>
                  <ContentLoader backgroundColor={"#b6b6b6"} foregroundColor={"#898989"} viewBox="0 0 380 125">
                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="500" />
                  </ContentLoader>
                </Card.Body>
              </Card>
              <div className="ct-chart" id="chartActivity"></div>
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({
  DashBoardDetails,
  CurrentPermitDetails,
  CurrentVisitorPermitDetails,
  APIResponseDetails,
  UserProfileDetails,
  SuperAdminDetails,
}) => {
  const { dashboardData, days, currentPermits, visitorPermits } = DashBoardDetails;
  const { allocatedCredits, updateUnitLanguage: selectedLang } = CurrentPermitDetails;
  const { currentVisitorPermit } = CurrentVisitorPermitDetails;
  const { api_response, api_error_message } = APIResponseDetails;
  const { first_name, last_name, suite_id, property_id: PropertyId, uid, user_type, property, timezone, language } = UserProfileDetails;
  const { permissions: allPermissions } = SuperAdminDetails;
  return {
    dashboardData,
    allPermissions,
    days,
    currentPermits,
    visitorPermits,
    currentVisitorPermit,
    api_response,
    api_error_message,
    first_name,
    last_name,
    allocatedCredits,
    suite_id,
    PropertyId,
    property,
    uid,
    user_type,
    timezone,
    selectedLang,
    language,
  };
};

const mapStateToDispatch = {
  fetch_dashboard: DashBoardActions.fetch_dashboard,
  fetch_current_visitor_permit: CurrentVisitorPermitActions.fetch_current_visitor_permit,
  fetchCurrentPermits: CurrentPermitActions.fetchCurrentPermits,
  APIResponseSuccess: APIResponseActions.APIResponseSuccess,
  getCreditsIndividualDetails: CurrentPermitActions.getCreditsIndividualDetails,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(Dashboard);

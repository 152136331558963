import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Form, Button, Modal, Table } from "react-bootstrap";
import { SuperAdminAction, TicketActions } from "../../Actions";
import CustomContentAlert, { validation_message, validations, tzDate, isEmailValid, generateZplFile, getBlobUrl, sendFileToZebra } from "../helper";
import { isMobile } from "react-device-detect";
import defaultImage from "../../../assets/img/defaultImage.png";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import Loader from "../Loader/Loader";
import SearchAutocomplete from "./SearchAutocomplete";
import SendMailModal from "./SendMailModal";
import CitationPrintModal from "./CitationPrintModal";
import Select from "react-select";

class Ticket extends Component {
  constructor(props) {
    super();
    this.state = {
      photos: [],
      audioDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0,
        },
      },
      show: false,
      showvin: false,
      property_id: "",
      reasonList: [],
      isFormValid: true,
      isVinInfoValid: true,
      language: [],
      VIN: "",
      lpn: "",
      reasons: [],
      reasons2: [],
      resID: [],
      make: "",
      modal: "",
      color: "",
      template: "Standard",
      validTemplate: true,
      validReasons: true,
      validReasons2: true,
      validPlate: true,
      validMake: true,
      validModal: true,
      validColor: true,
      validVIN: true,
      ValidLanguage: true,
      validNotesPrint: true,
      internalNotes: "",
      validNotesInternal: true,
      printNotes: "",
      show_error: false,
      generateTicketData: {},
      old_ticket_id: "",
      old_audio_url: "",
      violations: "",
      timezone: "",
      recordState: null,
      audioData: null,
      startTime: "",
      endTime: "",
      pauseRecord: "",
      duration: "",
      audioBlob: {},
      seconds: 0,
      isOpenCamera: false,
      loader: false,
      imageURL: "",
      totalFee: 0,
      invalidMessage: "",
      isRecording: true,
      isPause: false,
      topLevelDomain: "",
      driverTicketUrl: "",
      isSendMailModalOpen: false,
      selectedTicketId: "",
      cameraError: "",
      selectedCountry: null,
      otherCountry: null,
      selectedState: null,
      otherState: null,
      ticketCountry: [
        {
          value: "USA",
          label: "USA",
          states: [
            { label: "Alabama", value: "Alabama" },
            { label: "Alaska", value: "Alaska" },
            { label: "Arizona", value: "Arizona" },
            { label: "Arkansas", value: "Arkansas" },
            { label: "California", value: "California" },
            { label: "Colorado", value: "Colorado" },
            { label: "Connecticut", value: "Connecticut" },
            { label: "Delaware", value: "Delaware" },
            { label: "Florida", value: "Florida" },
            { label: "Georgia", value: "Georgia" },
            { label: "Hawaii", value: "Hawaii" },
            { label: "Idaho", value: "Idaho" },
            { label: "Illinois", value: "Illinois" },
            { label: "Indiana", value: "Indiana" },
            { label: "Iowa", value: "Iowa" },
            { label: "Kansas", value: "Kansas" },
            { label: "Kentucky[D]", value: "Kentucky[D]" },
            { label: "Louisiana", value: "Louisiana" },
            { label: "Maine", value: "Maine" },
            { label: "Maryland", value: "Maryland" },
            { label: "Massachusetts[D]", value: "Massachusetts[D]" },
            { label: "Michigan", value: "Michigan" },
            { label: "Minnesota", value: "Minnesota" },
            { label: "Mississippi", value: "Mississippi" },
            { label: "Missouri", value: "Missouri" },
            { label: "Montana", value: "Montana" },
            { label: "Nebraska", value: "Nebraska" },
            { label: "Nevada", value: "Nevada" },
            { label: "New Hampshire", value: "New Hampshire" },
            { label: "New Jersey", value: "New Jersey" },
            { label: "New Mexico", value: "New Mexico" },
            { label: "New York", value: "New York" },
            { label: "North Carolina", value: "North Carolina" },
            { label: "North Dakota", value: "North Dakota" },
            { label: "Ohio", value: "Ohio" },
            { label: "Oklahoma", value: "Oklahoma" },
            { label: "Oregon", value: "Oregon" },
            { label: "Pennsylvania[D]", value: "Pennsylvania[D]" },
            { label: "Rhode Island", value: "Rhode Island" },
            { label: "South Carolina", value: "South Carolina" },
            { label: "South Dakota", value: "South Dakota" },
            { label: "Tennessee", value: "Tennessee" },
            { label: "Texas", value: "Texas" },
            { label: "Utah", value: "Utah" },
            { label: "Vermont", value: "Vermont" },
            { label: "Virginia[D]", value: "Virginia[D]" },
            { label: "Washington", value: "Washington" },
            { label: "West Virginia", value: "West Virginia" },
            { label: "Wisconsin", value: "Wisconsin" },
            { label: "Wyoming", value: "Wyoming" },
          ],
        },
        {
          value: "Canada",
          label: "Canada",
          states: [
            { label: "Alberta", value: "Alberta" },
            { label: "British Columbia", value: "British Columbia" },
            { label: "Manitoba", value: "Manitoba" },
            { label: "New Brunswick", value: "New Brunswick" },
            {
              label: "Newfoundland and Labrador",
              value: "Newfoundland and Labrador",
            },
            { label: "Northwest Territories", value: "Northwest Territories" },
            { label: "Nova Scotia", value: "Nova Scotia" },
            { label: "Nunavut", value: "Nunavut" },
            { label: "Ontario", value: "Ontario" },
            { label: "Prince Edward Island", value: "Prince Edward Island" },
            { label: "Quebec", value: "Quebec" },
            { label: "Saskatchewan", value: "Saskatchewan" },
            { label: "Yukon", value: "Yukon" },
          ],
        },
        {
          value: "Other",
          label: "Other",
          states: [
            {
              value: "Other",
              label: "Other",
            },
          ],
        },
      ],
      ticketStates: [],
      ticketReportLots: [],
      lot_id: "",
      devices: [],
      selected_device: "",
      zplUrl: "",
      printButtonDisable: true,
      languageSelectDisable: false,
      noCharge: false,
      vin_visibility: false,
      pdfLoader: false,
      detectedDevice: "",
      listLoader: false,
    };
  }

  componentDidMount = async () => {
    if (this.props?.location?.state) {
      const lpn = this.props.location.state.lpn;
      this.setState({
        lpn: lpn,
      });
    }
    const country = localStorage.getItem("ticket_country");
    const tstate = localStorage.getItem("ticket_state");
    let ticketCountry = null;
    let otherCountry = null;
    let otherState = null;
    let selectedState = null;
    let ticketStates = [];
    if (country) {
      if (["USA", "Canada"].includes(country)) {
        ticketCountry = this.state.ticketCountry.find((x) => x.value === country);
      } else {
        ticketCountry = this.state.ticketCountry.find((x) => x.value === "Other");
        otherCountry = country;
      }
      ticketStates = ticketCountry.states;
      if (ticketStates?.length > 0) {
        selectedState = ticketStates.find((x) => x.value === tstate);
        if (!selectedState) {
          selectedState = {
            value: "Other",
            label: "Other",
          };
          otherState = tstate;
        }
      } else {
        otherState = tstate;
      }
    }
    this.setState(
      {
        selectedCountry: ticketCountry,
        otherCountry,
        otherState,
        selectedState,
        ticketStates,
        photos: [
          {
            image: this.props?.location?.state?.image?.length ? this.props.location.state.image : defaultImage,
            filename: "plate.jpg",
            id: 1,
          },
        ],
        property: this.props.property?.property_name,
        domain: this.props.property?.domain,
        property_id: this.props.property_id,
        timezone: this.props.timezone,
        isFormValid: false,
      },
      () => {
        const selectedPropertyName = this.state.property;
        const selectedDomainName = this.state.domain;
        this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      },
    );

    await this.props.getReasonDetails(this.props.property_id);
    if (this.props.api_response) {
      this.setState({
        reasonList: this.props.reasonList,
        noCharge: this.props.reasonSetupDetail.no_charge === 1,
        vin_visibility: this.props.reasonSetupDetail.vin_visibility === 1,
        reasons: this.props.reasonList[0]?.id,
      });
    }
    const driverUrl = `${process.env.REACT_APP_DRIVER_URL}/driver-ticket`;
    const ticketPdfUrl = `${process.env.REACT_APP_CONFIG_BASE_URL}/api/v1/check/`;
    if (driverUrl) {
      this.setState(
        {
          driverTicketUrl: driverUrl,
          ticketPdfUrl: ticketPdfUrl,
          language: this.props?.reasonSetupDetail?.ticket_default_language,
        },
        () => {
          if (this.state.language) {
            this.setState({
              languageSelectDisable: true,
            });
          }
        },
      );
    }
    await this.props.get_all_lots(this.props.property_id);
    const currentLot = this.props.lots;
    this.setState({
      ticketReportLots: currentLot,
    });
    this.findDevicesOnPrinter();
    const detectedDevice = this.getMobileOperatingSystem();
    this.setState({
      detectedDevice: detectedDevice,
    });
  };

  findDevicesOnPrinter = () => {
    const usethis = this;
    const devices = [];

    window.BrowserPrint?.getDefaultDevice(
      "printer",
      function (device) {
        // Add device to list of devices and to html select element
        const selected_device = device;
        devices.push(device);
        usethis.setState({
          devices: devices,
          selected_device: selected_device,
        });

        // Discover any other devices available to the application
        window.BrowserPrint?.getLocalDevices(
          function (device_list) {
            for (let i = 0; i < device_list?.length; i++) {
              // Add device to list of devices and to html select element
              const device_data = device_list[i];
              devices.push(device_data);
            }
            usethis.setState({
              devices: devices,
              selected_device: devices?.[0],
            });
          },
          function () {
            console.log("in print");
          },
          "printer",
        );
      },
      function (error) {
        console.log("error in component did mount", error);
      },
    );
  };

  sendFile = (fileUrl) => {
    const { selected_device } = this.state;
    if (selected_device) {
      this.setState({
        printButtonDisable: true,
      });
      sendFileToZebra(selected_device, fileUrl);
      setTimeout(() => {
        this.setState({ printButtonDisable: false });
      }, 10000);
    }
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleCloseVin = () => {
    this.setState({ showvin: false, isVinInfoValid: true });
  };

  openSendMailModal = (ticket_id) => {
    this.setState({
      isSendMailModalOpen: true,
      selectedTicketId: ticket_id,
      show: false,
    });
  };

  closeSendMailModal = () => {
    this.setState({
      isSendMailModalOpen: false,
      emailToSend: "",
    });
  };

  sendMailClick = (e) => {
    e.preventDefault();
    const { selectedTicketId, emailToSend, generateTicketData } = this.state;
    const type = "pdf";
    const sendMailData = {
      selectedTicketId,
      type,
      emailToSend,
      zplData: this.state.zplData,
      lpn: generateTicketData.lpn,
      ticket_no: generateTicketData.ticket_no,
    };
    this.ticketDataPdfToMail(sendMailData);
  };

  handleMailChange = (e) => {
    this.setState(
      {
        emailToSend: e.target.value,
      },
      () => {
        if (!this.state.emailToSend.trim().length) {
          this.setState({
            emailError: "email required!",
          });
        }
        if (this.state.emailToSend.trim().length) {
          this.setState({
            emailError: !isEmailValid(this.state.emailToSend) ? "email is invalid!" : "",
            isMailFormValid: isEmailValid(this.state.emailToSend),
          });
        }
      },
    );
  };

  handleLPNUpdate = async (value) => {
    await this.props.viewLastPlateNumber(value);
    if (this.props.api_response) {
      if (this.props.viewLastPlateNumberDetails) {
        this.setState({
          color: this.props.viewLastPlateNumberDetails.car_color,
          make: this.props.viewLastPlateNumberDetails.car_make,
          modal: this.props.viewLastPlateNumberDetails.car_model,
        });
      }
    }
  };

  handleChange = async ({ target }) => {
    if (target.name === "lpn" && target.value.length > 4) {
      this.handleLPNUpdate(target.value);
    }

    if (target.name === "country") {
      this.setState({
        otherCountry: target.value,
      });
      localStorage.setItem("ticket_country", target.value);
    }
    if (target.name === "state") {
      this.setState({
        otherState: target.value,
      });
      localStorage.setItem("ticket_state", target.value);
    }

    if (target.name === "lpn" && target.value.length > 0) {
      setTimeout(async () => {
        await this.props.viewVinWarningNumber(target.value);

        if (this.props.viewVinWarningNumberDetails && this.props.viewVinWarningNumberDetails.length > 0) {
          for (const i of this.props.viewVinWarningNumberDetails) {
            if (this.state.VIN === i.vin) {
              this.setState({
                vinWarn: "",
              });
              break;
            } else {
              if (this.state.VIN.length > 0) {
                this.setState({
                  vinWarn: "Warning: This VIN is wrong as per history",
                });
              }
            }
          }
          if (target.value.length === 0) {
            this.setState({
              vinWarn: "",
            });
          }
        } else {
          this.setState({
            vinWarn: "",
          });
        }
      }, 1000);
    } else {
      if (target.name === "lpn" && target.value.length === 0) {
        this.setState({
          vinWarn: "",
        });
      }
    }

    if (target.name === "VIN") {
      if (this.props.viewVinWarningNumberDetails && this.props.viewVinWarningNumberDetails.length > 0) {
        for (const i of this.props.viewVinWarningNumberDetails) {
          if (target.value === i.vin) {
            this.setState({
              vinWarn: "",
            });
            break;
          } else {
            this.setState({
              vinWarn: "Warning: This VIN is wrong as per history",
            });
          }
        }
      }
      if (target.value.length === 0) {
        this.setState({
          vinWarn: "",
        });
      }
    }
    this.setState(
      {
        [target.name]: target.name === "lpn" || target.name === "VIN" ? target.value.replace(/\s/g, "") : target.value,
      },
      async () => {
        const { photos, VIN, lpn, reasons, make, modal, color, language, template } = this.state;

        switch (target.name) {
          case "lpn":
            {
              const LPN = validations(target.name, "lpn", this.state.lpn);
              //  const timer = setTimeout(() => {
              this.setState({
                lpn: LPN.value.toUpperCase().replace(/[^\w\s]/gi, ""),
                validPlate: LPN.validator,
              });
              //  }, 1000);
              //  clearTimeout(timer);
            }
            break;
          case "make":
            {
              const Make = validations(target.name, "make", this.state.make);
              this.setState({
                make: Make.value,
                validMake: Make.validator,
              });
            }
            break;
          case "modal":
            {
              const Modal_ = validations(target.name, "modal", this.state.modal);
              this.setState({
                modal: Modal_.value,
                validModal: Modal_.validator,
              });
            }
            break;
          case "color":
            {
              const Color = validations(target.name, "color", this.state.color);
              this.setState({
                color: Color.value,
                validColor: Color.validator,
              });
            }
            break;
          case "VIN":
            {
              const VIN_ = validations(target.name, "VIN", this.state.VIN);
              this.setState({
                VIN: VIN_.value.toUpperCase().replace(/[^\w\s]/gi, ""),
                validVIN: VIN_.validator,
              });
            }
            break;

          default:
            break;
        }
        if (this.state.reasons === this.state.reasons2) {
          this.setState({
            show_error: true,
            api_error_message: "Reason & Reason 2 should be unique.",
            isFormValid: false,
          });
          setTimeout(() => {
            this.setState({ show_error: false });
          }, 4000);
        } else if (
          lpn &&
          make &&
          color &&
          modal &&
          template &&
          language?.length &&
          reasons?.length &&
          photos?.length &&
          photos[0].image !== defaultImage &&
          this.state.selectedCountry?.value &&
          this.state.selectedState?.value
        ) {
          this.checkAllValidations(language, reasons, lpn, make, color, modal);
        } else {
          this.setState({ isFormValid: false });
        }

        if (lpn && VIN) {
          this.setState({
            isVinInfoValid: false,
          });
        } else {
          this.setState({
            isVinInfoValid: true,
          });
        }
      },
    );
  };

  handleSelect = ({ target }) => {
    this.setState(
      {
        [target.name]: target.value,
      },
      () => {
        if (this.state.reasons === this.state.reasons2) {
          this.setState({
            show_error: true,
            api_error_message: "Reason & Reason 2 should be unique.",
            isFormValid: false,
          });
          setTimeout(() => {
            this.setState({ show_error: false });
          }, 4000);
        } else {
          const { lpn, make, modal, color, template, language, reasons, photos } = this.state;
          if (
            language?.length &&
            reasons?.length &&
            lpn &&
            make &&
            color &&
            modal &&
            template &&
            photos?.length &&
            photos[0].image !== defaultImage &&
            this.state.selectedCountry?.value &&
            this.state.selectedState?.value
          ) {
            this.checkAllValidations(language, reasons, lpn, make, color, modal);
          } else {
            this.setState({ isFormValid: false });
          }
        }
      },
    );
  };

  handleAddPhoto = (selectorFile) => {
    const { photos, lpn, reasons, make, modal, color, language } = this.state;
    const extension = selectorFile[0].name.split(".").pop();
    const validExtensions = ["jpg", "jpeg", "png", "jfif"];
    if (validExtensions.includes(extension)) {
      if (selectorFile.length > 0) {
        let index;
        let id;
        if (photos && photos.length < 5) {
          const reader = new FileReader();
          reader.readAsDataURL(selectorFile[0]);
          let old_photos = [];
          if (photos?.length && photos[0].image === defaultImage) {
            id = 0;
            old_photos = [];
          } else {
            index = photos.length - 1;
            id = photos.length && photos[index].id + 2;
            old_photos = photos;
          }
          reader.onloadend = (_e) => {
            this.setState(
              {
                photos: [
                  ...old_photos,
                  {
                    image: reader.result,
                    filename: selectorFile[0].name,
                    id: id,
                  },
                ],
              },
              () => {
                if (this.state.reasons === this.state.reasons2) {
                  this.setState({
                    show_error: true,
                    api_error_message: "Please select unique reasons and reasons2.",
                    isFormValid: false,
                  });
                  setTimeout(() => {
                    this.setState({ show_error: false });
                  }, 4000);
                } else if (language?.length && reasons?.length && lpn && make && color && modal) {
                  this.checkAllValidations(language, reasons, lpn, make, color, modal);
                } else {
                  this.setState({ isFormValid: false });
                }
              },
            );
          };
        } else {
          this.setState({
            show_error: true,
            api_error_message: "Can not add more than 5 photos",
          });
          setTimeout(() => {
            this.setState({ show_error: false });
          }, 4000);
        }
      }
      this.setState({
        invalidMessage: "",
      });
    } else {
      this.setState({
        invalidMessage: "Please select valid image",
      });
    }
  };

  handleRemovePhoto = (id) => {
    const { lpn, reasons, make, modal, color, language } = this.state;
    const { photos } = this.state;
    const inputPhotos = [...photos];
    inputPhotos.splice(id, 1);
    this.setState(
      {
        photos: inputPhotos,
      },
      () => {
        let isVIN = true;
        if (!this.state.vin_visibility) {
          isVIN = !!this.state.VIN;
        }
        if (language?.length && reasons?.length && lpn && make && color && modal && isVIN && inputPhotos && inputPhotos?.length) {
          if (inputPhotos?.length === 1) {
            this.setState({ isFormValid: true });
          }
        } else {
          this.setState({ isFormValid: false });
        }
      },
    );
  };

  urltoFile = async (url, filename) => {
    const mimeType = (url.match(/^data:([^;]+);/) || "")[1];
    return fetch(url, { mode: "no-cors" })
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  isSubdomain = (url) => {
    url = url.replace(/^\s+/, "");
    url = url.replace(/\s+$/, "");
    url = url.replace(/\\/g, "/");
    url = url.replace(/^http:\/\/|^https:\/\/|^ftp:\/\//i, "");
    url = url.replace(/^www\./i, "");
    url = url.replace(/\/(.*)/, "");
    let domain = "";
    if (url.match(/\.[a-z]{2,3}\.[a-z]{2}$/i)) {
      url = url.replace(/\.[a-z]{2,3}\.[a-z]{2}$/i, "");
    } else if (url.match(/\.[a-z]{2,4}$/i)) {
      domain = url.split(".")[2];
    }
    return domain;
  };

  handleVinInfo = async () => {
    const Vin = this.state.VIN;
    this.setState({ isVinInfoValid: true });
    const response = await this.props.getVinInfo(Vin);
    if (response) {
      const resMake = response?.data?.Results[0]?.Make;
      const resModel = response?.data?.Results[0]?.Model;
      const carMake = response?.data?.Results[0]?.Make ? resMake : this.state.make;
      const carModal = response?.data?.Results[0]?.Model ? resModel : this.state.modal;
      this.setState({
        make: carMake.charAt(0).toUpperCase() + carMake.slice(1).toLowerCase(),
        modal: carModal,
        color: resMake && resModel ? "" : this.state.color,
      });

      const { lpn, reasons, make, modal, color, language } = this.state;

      this.checkAllValidations(language, reasons, lpn, make, color, modal);
      setTimeout(() => {
        this.setState({
          showvin: true,
        });
      }, 2000);
    }
  };

  handleSubmit() {
    this.setState({ isFormValid: false, pdfLoader: true, listLoader: true }, async () => {
      const {
        photos,
        VIN,
        lpn,
        reasons,
        reasons2,
        make,
        modal,
        color,
        language,
        internalNotes,
        printNotes,
        property_id,
        audioBlob,
        domain,
        old_ticket_id,
        old_audio_url,
        duration,
        lot_id,
        noCharge,
      } = this.state;

      const violations = [];
      if (reasons?.length) {
        violations.push(reasons);
      }
      if (reasons2?.length) {
        violations.push(reasons2);
      }
      const form_data = new FormData();
      form_data.append("property_id", property_id);
      for (const item of violations) {
        form_data.append("violations", item);
      }
      form_data.append("lpn", lpn);
      form_data.append("car_make", make);
      form_data.append("car_model", modal);
      form_data.append("car_color", color);
      form_data.append("vin", VIN);
      form_data.append("language", language);
      if (audioBlob instanceof Blob) {
        form_data.append("audio", audioBlob, duration + domain + ".wav");
      }
      form_data.append("old_ticket_id", old_ticket_id);
      form_data.append("old_audio_url", old_audio_url);
      form_data.append("notes_internal", internalNotes);
      form_data.append("notes_external", printNotes);
      form_data.append("no_charge", noCharge);
      form_data.append("vin_visibility", this.state.vin_visibility);
      form_data.append("citation_footer", this.props.reasonSetupDetail?.citation_footer);
      if (lot_id) {
        form_data.append("lot_id", lot_id);
      }
      if (this.state.selectedCountry?.value) {
        if (this.state.selectedCountry && this.state.selectedCountry.value === "Other") {
          if (this.state.otherCountry?.length) {
            form_data.append("country_name", this.state.otherCountry);
          }
        } else {
          form_data.append("country_name", this.state.selectedCountry.value);
        }
      }
      if (this.state.selectedState?.value) {
        if (this.state.selectedState && this.state.selectedState.value === "Other") {
          if (this.state.otherState) {
            form_data.append("state_name", this.state.otherState);
          }
        } else {
          form_data.append("state_name", this.state.selectedState.value);
        }
      }

      for (const item of photos) {
        const file = await this.urltoFile(item.image, item.filename);
        form_data.append("files", file);
      }

      await this.props.generateTicket(form_data);
      if (this.props.api_response) {
        let violations;
        if (this.props.generateTicketData?.reasons?.length) {
          const violations_arr = this.props.generateTicketData.reasons.map((el) => el.title);
          const resID = this.props.generateTicketData.reasons.map(({ id }) => id);
          this.setState({ resID });
          violations = violations_arr.toString();
        }
        const encodedImage = await this.props.getEncodedFileFromLabelaryApi(this.props.generateTicketData[0]?.ticket_photos[0]?.photo);
        const generateTicketData = this.props.generateTicketData[0];
        generateTicketData.vin_visibility = this.state.vin_visibility;
        generateTicketData.citation_footer = this.props.reasonSetupDetail?.citation_footer;
        const zplData = await generateZplFile(generateTicketData, encodedImage.data);
        const blobUrl = getBlobUrl(zplData, "zpl");
        console.log("Zpl blob urlUrl", blobUrl);
        const pdfUrl = await this.saveAndGeneratePdf(zplData, false);
        console.log("Pdf blob url", pdfUrl);
        let ifSelected = true;
        if (this.state.selected_device) {
          ifSelected = false;
        }
        this.setState({
          zplUrl: blobUrl,
          printButtonDisable: ifSelected,
          listLoader: false,
        });

        this.setState({
          zplData: zplData,
          show_alert: true,
          api_success_message: this.props.api_success_message,
          isFormValid: false,
          show: true,
          generateTicketData: this.props.generateTicketData,
          violations: violations,
          VIN: "",
          lpn: "",
          vinWarn: "",
          reasonList: this.props.sequencedReasons,
          reasons: [],
          reasons2: [],
          make: "",
          modal: "",
          color: "",
          photos: [
            {
              image: defaultImage,
              filename: "plate.jpg",
              id: 1,
            },
          ],
          internalNotes: "",
          printNotes: "",
          pdfUrl,
          dateTobeShown: tzDate(this.state.timezone, this.props.generateTicketData?.created_at),
          duration: "",
          isVinInfoValid: true,
          pdfLoader: false,
          noCharge: false,
        });
      } else {
        this.setState({
          show_error: true,
          api_error_message: this.props.api_error_message,
        });
      }
    });
  }

  saveAndGeneratePdf = async (zplData, iflog) => {
    return this.props.getPDFandPNGfromZpl(zplData, "pdf", iflog);
  };

  handleViewTicketDetails = async () => {
    const url = window.location.href;
    const TLD = this.isSubdomain(url);
    this.setState({
      topLevelDomain: TLD,
      vinWarn: "",
      isVinInfoValid: false,
    });
    await this.props.getLastTicketDetails(this.state.property_id);
    if (this.props.api_response) {
      const photos = [];
      const viewLastTicket = this.props.viewLastTicket;
      if (viewLastTicket?.ticket_photos) {
        viewLastTicket.ticket_photos.forEach((item, index) => {
          const imageExtension = item.filename.split(".").pop();
          photos.push({
            image: "data:image/png;base64," + item.photo2,
            filename: `image.${imageExtension}`,
            id: index + 1,
          });
        });
      }
      this.setState({
        photos: photos,
      });
      const audio_url = {
        url: "https://api.worldstream." + this.state.topLevelDomain + "/" + this.props.viewLastTicket?.audio_file_internal,
      };
      this.setState({
        lpn: this.props.viewLastTicket ? this.props.viewLastTicket.lpn : "",
        VIN: this.props.viewLastTicket ? this.props.viewLastTicket.vin : "",
        printNotes: this.props.viewLastTicket ? this.props.viewLastTicket.notes_external : "",
        internalNotes: this.props.viewLastTicket ? this.props.viewLastTicket.notes_internal : "",
        make: this.props.viewLastTicket ? this.props.viewLastTicket.car_make : "",
        modal: this.props.viewLastTicket ? this.props.viewLastTicket.car_model : "",
        color: this.props.viewLastTicket ? this.props.viewLastTicket.car_color : "",
        language: this.props.viewLastTicket ? this.props.viewLastTicket.language : [],
        reasons: this.props.viewLastTicket ? this.props.viewLastTicket.reasons[0].id : [],
        reasons2: this.props.viewLastTicket?.reasons?.length > 1 ? this.props.viewLastTicket?.reasons[1].id : [],
        old_ticket_id: this.props.viewLastTicket ? this.props.viewLastTicket.id : "",
        old_audio_url: this.props.viewLastTicket?.audio_file_internal,
        audioData: audio_url,
        country_name: this.props.viewLastTicket ? this.props.viewLastTicket.country_name : "",
        state_name: this.props.viewLastTicket ? this.props.viewLastTicket.state_name : "",
      });
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
    }
  };

  printTicketDetails() {
    const iframe = document.querySelector(".pdf-frame");
    iframe.contentWindow.print();
  }

  CountStart = () => {
    this.setState({ seconds: this.state.seconds + 1 }, () => {
      const getSeconds = `0${this.state.seconds % 60}`.slice(-2);
      const minutes = `${Math.floor(this.state.seconds / 60)}`;
      const getMinutes = `0${minutes % 60}`.slice(-2);
      const getHours = `0${Math.floor(this.state.seconds / 3600)}`.slice(-2);
      this.setState({
        duration: `${getHours}:${getMinutes}:${getSeconds}`,
      });
    });
  };

  start = () => {
    this.setState(
      {
        recordState: RecordState.START,
        seconds: 0,
        isRecording: false,
        isPause: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            recordState: RecordState.STOP,
          });
        }, 60000);
        this.f = setInterval(this.CountStart, 1000);
      },
    );
  };

  onStop = async (data) => {
    clearInterval(this.f);
    data.duration = this.state.duration;
    this.setState({
      recordState: RecordState.STOP,
      audioBlob: data.blob,
      audioData: data,
      isPause: false,
      isRecording: true,
    });
  };

  handleTakePhoto = (imageUrl) => {
    const { photos } = this.state;
    const { lpn, reasons, make, modal, color, language } = this.state;

    let index;
    let id;
    let old_photos = [];
    if (photos?.length < 5) {
      if (photos.length !== 0) {
        if (photos[0].image === defaultImage) {
          id = 0;
          old_photos = [];
        } else {
          index = photos.length - 1;
          id = photos[index].id + 2;
          old_photos = photos;
        }
      }
      this.setState(
        {
          isOpenCamera: false,
          photos: [
            ...old_photos,
            {
              image: imageUrl,
              filename: "cameraImg.png",
              id: id,
            },
          ],
        },
        () => {
          this.checkAllValidations(language, reasons, lpn, make, color, modal);
        },
      );
    } else {
      this.setState({
        show_error: true,
        isOpenCamera: false,
        api_error_message: "Can not add more than 5 photos",
      });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  checkAllValidations = (language, reasons, lpn, make, color, modal) => {
    let isVIN = true;
    if (!this.state.vin_visibility) {
      isVIN = !!this.state.VIN;
    }
    if (language?.length && reasons?.length && lpn && make && color && modal && isVIN) {
      this.checkCountValidation();
    } else {
      this.setState({ isFormValid: false });
    }
  };

  checkCountValidation = () => {
    const { selectedCountry, selectedState, otherCountry, otherState } = this.state;
    if (selectedCountry?.value === "Other" && selectedState?.value === "Other") {
      if (otherCountry?.length && otherState?.length) {
        this.setState({ isFormValid: true });
      } else {
        this.setState({ isFormValid: false });
      }
    } else {
      if (selectedCountry?.value) {
        this.setState({ isFormValid: true });
      } else {
        this.setState({ isFormValid: false });
      }
      if (selectedState?.value) {
        this.setState({ isFormValid: true });
      } else {
        this.setState({ isFormValid: false });
      }
    }
  };

  handleBackAction = () => {
    const history = require("../../history").default;
    history.push({
      pathname: "/photo-verify",
    });
  };

  ticketDataPdfToMail = async (sendMailData) => {
    await this.props.pdfSendToMail(sendMailData);

    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        isSendMailModalOpen: false,
      });
      setTimeout(() => {
        this.setState({ show_alert: false });
      }, 4000);
    }
  };

  handleCameraError = (error) => {
    console.log("camera error", error);
    this.setState({
      cameraError: "Permission Denied",
    });
  };

  onCountryChange(country) {
    let selectedState = this.state.selectedState;

    if (this.state.selectedCountry && this.state.selectedCountry.value !== country.value) {
      selectedState = null;
    }

    this.setState(
      {
        selectedCountry: country,
        selectedState: selectedState,
        ticketStates: country.states,
      },
      () => {
        const {
          VIN,
          lpn,
          make,
          modal,
          color,
          template,
          // internalNotes,
          // printNotes,
          language,
          reasons,
          // reasons2,
          photos,
        } = this.state;
        if (
          language?.length &&
          reasons?.length &&
          lpn &&
          make &&
          color &&
          modal &&
          template &&
          VIN &&
          // internalNotes &&
          // printNotes &&
          // reasons2 &&
          photos?.length &&
          photos[0].image !== defaultImage &&
          this.state.selectedCountry?.value &&
          this.state.selectedState?.value
        ) {
          this.checkAllValidations(language, reasons, lpn, make, color, modal);
        } else {
          this.setState({ isFormValid: false });
        }
      },
    );
    localStorage.setItem("ticket_country", country.value);
    if (!selectedState) {
      if (localStorage.getItem("ticket_state")) localStorage.removeItem("ticket_state");
    }
  }

  onStateChange(state) {
    this.setState(
      {
        selectedState: state,
      },
      () => {
        const {
          lpn,
          make,
          modal,
          color,
          template,
          // internalNotes,
          // printNotes,
          language,
          reasons,
          // reasons2,
          photos,
        } = this.state;
        if (
          language?.length &&
          reasons?.length &&
          lpn &&
          make &&
          color &&
          modal &&
          template &&
          // internalNotes &&
          // printNotes &&
          // reasons2 &&
          photos?.length &&
          photos[0].image !== defaultImage &&
          this.state.selectedCountry?.value &&
          this.state.selectedState?.value
        ) {
          this.checkAllValidations(language, reasons, lpn, make, color, modal);
        } else {
          this.setState({ isFormValid: false });
        }
      },
    );
    localStorage.setItem("ticket_state", state.value);
  }

  onToggleChange(event) {
    this.setState({
      noCharge: event.target.checked,
    });
  }

  getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  render() {
    const {
      photos,
      show,
      showvin,
      VIN,
      lpn,
      reasons,
      reasons2,
      make,
      modal,
      color,
      language,
      isFormValid,
      isVinInfoValid,
      validReasons,
      validReasons2,
      validPlate,
      validMake,
      validModal,
      validColor,
      validVIN,
      ValidLanguage,
      validNotesPrint,
      internalNotes,
      validNotesInternal,
      printNotes,
      show_error,
      api_error_message,
      api_success_message,
      show_alert,
      recordState,
      duration,
      isPause,
      isRecording,
      isOpenCamera,
      loader,
      imageURL,
      zplUrl,
      printButtonDisable,
      languageSelectDisable,
      noCharge,
      vinWarn,
      detectedDevice,
      listLoader,
    } = this.state;
    const result = this.props.viewVinInformation.Results?.[0];
    return (
      <div>
        <Modal
          show={showvin}
          onHide={this.handleCloseVin}
          lg={"lg"}
          backdrop={"static"}
          dialogClassName="modal-90w modal-xl opacity: 0.8"
          fullscreen={"lg-down"}
          className="modalData"
          size="full"
        >
          <Modal.Header id="ignoreHeaderone" className="d-flex justify-content-between bg-white align-items-center">
            <h4 className="m-0 font-weight-bold" style={{ marginBottom: 10 }}>
              <Modal.Title as="h4" className="m-0">
                VIN INFORMATION
              </Modal.Title>
            </h4>
            <Button id="ignoreButton" title={"Deactivate"} type="button" onClick={this.handleCloseVin} className="close-btn py-0" data-dismiss="modal">
              <i className="nc-icon nc-simple-remove text-danger" />
            </Button>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="scrollTable">
                  <Table className="table-hover table-striped mb-0" responsive bordered style={{ overflow: "scroll" }}>
                    <thead className="timing-header">
                      <tr>
                        <th className="border-0">VIN INFORMATION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result &&
                        Object.keys(result).map((key) => {
                          if (result[key]) {
                            return (
                              <tr key={key}>
                                <td className="text-center">{key}</td>
                                <td className="text-center">{result[key]}</td>
                              </tr>
                            );
                          }
                          return true;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        {listLoader ? (
          <Loader />
        ) : (
          <Container fluid>
            <Row>
              <Col>
                <Card className="mb-0">
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center">
                      <i className="fa fa-arrow-left mr-2 cursor-pointer" aria-hidden="true" onClick={this.handleBackAction}></i>{" "}
                      <h4 className="card-title">Citation </h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <Row>
                        <Col lg="3" md="4" xs="12" className="mb-3">
                          <Form.Label>
                            Plate
                            <span className="asterisk">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Plate"
                            value={lpn}
                            name="lpn"
                            // value={this.state.value}
                            onChange={this.handleChange}
                            className="fs-14 h-40"
                            maxLength="11"
                          />
                          {validation_message(lpn, "Lpn", validPlate)}
                        </Col>
                        <Col md={this.state.selectedCountry && this.state.selectedCountry.value === "Other" ? "3" : "4"}>
                          <Form.Group>
                            <label>Plate Issued Country</label>
                            <span className="asterisk">*</span>
                            <Select
                              name="country"
                              value={this.state.selectedCountry}
                              onChange={this.onCountryChange.bind(this)}
                              options={this.state.ticketCountry}
                            />
                          </Form.Group>
                        </Col>
                        {this.state.selectedCountry && this.state.selectedCountry.value === "Other" && (
                          <Col md="3">
                            <Form.Group>
                              <label>Country</label>
                              <span className="asterisk">*</span>
                              <Form.Control
                                placeholder="Country"
                                type="text"
                                name="country"
                                value={this.state.otherCountry}
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                            </Form.Group>
                          </Col>
                        )}
                        <Col md={this.state.selectedState && this.state.selectedState.value === "Other" ? "3" : "4"}>
                          <Form.Group>
                            <label>Plate Issued State / Province</label>
                            <span className="asterisk">*</span>
                            <Select name="state" value={this.state.selectedState} onChange={this.onStateChange.bind(this)} options={this.state.ticketStates} />
                          </Form.Group>
                        </Col>
                        {this.state.selectedState && this.state.selectedState.value === "Other" && (
                          <Col md="3">
                            <Form.Group>
                              <label>State</label>
                              <span className="asterisk">*</span>
                              <Form.Control
                                placeholder="State"
                                type="text"
                                name="state"
                                value={this.state.otherState}
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                            </Form.Group>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col lg="3" md="4" xs="12" className="mb-3">
                          <Form.Label c>
                            Print Ticket in <span className="asterisk">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            value={language}
                            name="language"
                            onChange={this.handleSelect}
                            className="fs-14 h-40"
                            disabled={languageSelectDisable}
                          >
                            <option value="">-- Choose Language --</option>
                            <option value="en">English</option>
                            <option value="fn">French</option>
                            <option value="sp">Spanish</option>
                          </Form.Control>
                          {validation_message(language, "Language", ValidLanguage)}
                        </Col>
                        <Col lg="3" md="4" xs="12" className="mb-3">
                          <Form.Label>
                            Reason <span className="asterisk">*</span>
                          </Form.Label>
                          <Form.Control as="select" custom value={reasons} name="reasons" onChange={this.handleSelect} className="fs-14 h-40">
                            <option value="">-- Choose Reason --</option>
                            {this.state?.reasonList?.length > 0 &&
                              this.state.reasonList.map((e, key) => {
                                return (
                                  <option key={e.id} value={e.id}>
                                    {e.title}
                                  </option>
                                );
                              })}
                          </Form.Control>
                          {validation_message(reasons, "Reasons", validReasons)}
                        </Col>
                        <Col lg="3" md="4" xs="12" className="mb-3">
                          <Form.Label>Reason2</Form.Label>
                          <Form.Control as="select" custom value={reasons2} name="reasons2" onChange={this.handleSelect} className="fs-14 h-40">
                            <option value="">-- Choose Reason --</option>
                            {(this.state.reasonList ?? []).map((e, key) => {
                              return (
                                <option key={e.id} value={e.id}>
                                  {e.title}
                                </option>
                              );
                            })}
                          </Form.Control>
                          {validation_message(reasons2, "Reasons2", validReasons2)}
                        </Col>
                        <Col lg="3" md="4" xs="12" className="mb-3">
                          <Form.Label>
                            <label className="fancy-checkbox">Charge for 2nd reason</label>
                          </Form.Label>
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              name={"noCharge"}
                              onChange={this.onToggleChange.bind(this)}
                              checked={noCharge}
                              value={noCharge}
                              disabled={!reasons2.length}
                            />
                          </div>
                        </Col>
                      </Row>
                      <h5 className="mt-3">Car Description</h5>
                      <Row>
                        <Col md="6" lg="4" xl="3" className="mb-3">
                          <SearchAutocomplete
                            isRequired={true}
                            label={"Make"}
                            name={"make"}
                            value={make}
                            type={"make"}
                            handleChange={(value) => {
                              this.handleChange({
                                target: {
                                  name: "make",
                                  value: value.make,
                                },
                              });
                            }}
                          />
                          {validation_message(make, "Make", validMake)}
                        </Col>
                        <Col md="6" lg="4" xl="3" className="mb-3">
                          <SearchAutocomplete
                            isRequired={true}
                            label={"Model"}
                            name={"modal"}
                            value={modal}
                            type={"model"}
                            make={make}
                            handleChange={(value) => {
                              if (!make && value.make) {
                                setTimeout(() => {
                                  this.handleChange({
                                    target: {
                                      name: "make",
                                      value: value.make,
                                    },
                                  });
                                }, 500);
                              }
                              this.handleChange({
                                target: {
                                  name: "modal",
                                  value: value.model,
                                },
                              });
                            }}
                          />
                          {validation_message(modal, "Modal", validModal)}
                        </Col>
                        <Col md="6" lg="4" xl="2" className="mb-3">
                          <SearchAutocomplete
                            isRequired={true}
                            label={"Color"}
                            name={"color"}
                            value={color}
                            type={"color"}
                            defaultOptions={[
                              "White",
                              "Black",
                              "Gray",
                              "Silver",
                              "Red",
                              "Blue",
                              "Brown",
                              "Green",
                              "Beige",
                              "Orange",
                              "Gold",
                              "Yellow",
                              "Purple",
                            ]}
                            handleChange={(value) => {
                              this.handleChange({
                                target: {
                                  name: "color",
                                  value: value.color,
                                },
                              });
                            }}
                          />
                          {validation_message(color, "Color", validColor)}
                        </Col>
                        {!this.state.vin_visibility ? (
                          <>
                            <Col md="6" lg="4" xl="2" className="mb-3">
                              <Form.Label>
                                VIN <span className="asterisk">*</span>
                              </Form.Label>
                              <Form.Control type="text" placeholder="VIN" value={VIN} name="VIN" onChange={this.handleChange} maxLength="17" />
                              <p className="help-block">{this.props.viewVinWarningNumberDetails ? vinWarn : ""}</p>
                              {validation_message(VIN, "VIN", validVIN)}
                            </Col>

                            <Col md="6" lg="4" xl="2" className="mb-3">
                              <Button type="button" className="mr-2 text-nowrap ml-2 mt-4" onClick={() => this.handleVinInfo()} disabled={isVinInfoValid}>
                                VIN INFO
                              </Button>
                            </Col>
                          </>
                        ) : null}
                      </Row>

                      <Row className="photo-choose my-3">
                        <div className="images-block col-lg-12 d-flex align-items-end flex-wrap">
                          {photos?.length ? (
                            photos.map((item, index) => (
                              <div key={item?.id} className="photo-choose-items position-relative mr-2 mb-2">
                                <img
                                  // src={photos ? item.image : ""}
                                  src={item.image ? item.image : ""}
                                  alt=""
                                  height="140"
                                  width="140"
                                  name="image"
                                  // value={item.image}
                                  className="image-preview"
                                  accept="image/png, image/jpeg"
                                />
                                {item.image !== defaultImage && (
                                  <Button
                                    onClick={() => this.handleRemovePhoto(index)}
                                    className="position-absolute btn btn-danger btn-sm"
                                    style={{ top: "0", right: "0" }}
                                    variant="danger"
                                  >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                  </Button>
                                )}
                              </div>
                            ))
                          ) : (
                            <img src={defaultImage} width="140" height="140" />
                          )}
                          <div className="upload-image-button ml-2">
                            <div className="input-file-upload-btn">
                              <Form.Control
                                className="btn-bottom d-none"
                                type="file"
                                name="image"
                                id="file-input-choose"
                                accept="image/png, image/jpeg"
                                onChange={(e) => this.handleAddPhoto(e.target.files)}
                                onClick={this.handleClick}
                              ></Form.Control>
                              <span className={"help-block"}>{this.state.invalidMessage}</span>
                              {isOpenCamera && (
                                <div>
                                  {!loader ? (
                                    <>
                                      {this.state.cameraError === "" ? (
                                        <Camera
                                          onTakePhoto={(dataUri) => {
                                            this.handleTakePhoto(dataUri);
                                          }}
                                          // isImageMirror={true}
                                          idealFacingMode={FACING_MODES.ENVIRONMENT}
                                          isImageMirror={false}
                                          onCameraError={(error) => {
                                            this.handleCameraError(error);
                                          }}
                                          isDisplayStartCameraError={true}
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            marginTop: "60px",
                                            marginBottom: "60px",
                                          }}
                                        >
                                          <span>{this.state.cameraError}</span>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <div className="loader">
                                      <Loader />
                                    </div>
                                  )}
                                </div>
                              )}
                              <>{!isOpenCamera && <img src={imageURL} alt="" className="image-preview" accept="image/*" />}</>

                              <div className="d-flex flex-column">
                                <label className="form-label">
                                  {" "}
                                  Notes: Image Required <span className="asterisk">*</span>
                                </label>
                                <div className="">
                                  <a className="mr-2 p-0 btn btn-primary" title="Upload Photo">
                                    <label htmlFor="file-input-choose" className="mb-0 btn-icon fs-1rem">
                                      <i className="fa fa-upload cursor-pointer" aria-hidden="true"></i>{" "}
                                    </label>
                                  </a>
                                  <span>OR</span>
                                  <Button
                                    className="mr-2 ml-2"
                                    Title="Take Photo"
                                    onClick={() => this.setState({ isOpenCamera: true })}
                                    disabled={photos.length === 5}
                                  >
                                    <span>
                                      <i className="fa fa-camera" aria-hidden="true"></i>{" "}
                                    </span>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label> Notes: Internal use only</Form.Label>
                            <Form.Control as="textarea" value={internalNotes} name="internalNotes" onChange={this.handleChange} />
                            {validation_message(internalNotes, "Internal Notes", validNotesInternal)}
                          </Form.Group>
                        </Col>
                        <Col lg="4">
                          <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label> Notes: Print on Citation (External use only) </Form.Label>
                            <Form.Control as="textarea" value={printNotes} name="printNotes" onChange={this.handleChange} maxLength="120" />
                            {validation_message(printNotes, "Print Notes", validNotesPrint)}
                          </Form.Group>
                        </Col>
                        <Col lg="4">
                          <label> Record Audio Clip: Internal use only </label>
                          <div className="d-flex align-items-center">
                            <AudioReactRecorder state={recordState} onStop={this.onStop} backgroundColor="rgb(255,255,255)" />
                            {/* <audio
                            id="audio"
                            controls
                            src={
                              this.state.audioData
                                ? this.state.audioData.url
                                : null
                            }
                          ></audio> */}
                            <div className="d-flex align-items-center ml-2">
                              {isRecording ? (
                                <Button className="btn btn-primary p-0">
                                  <i
                                    id="record"
                                    name="record"
                                    onClick={this.start}
                                    style={{ cursor: "pointer" }}
                                    className="fa fa-microphone btn-icon"
                                    aria-hidden="true"
                                    disabled={!isRecording}
                                  ></i>
                                </Button>
                              ) : (
                                <Button className="btn btn-primary p-0 pp-btn">
                                  <i
                                    className="fas fa-stop-circle btn-icon"
                                    id="stop"
                                    name="stop"
                                    style={{ cursor: "pointer" }}
                                    onClick={this.onStop}
                                    disabled={isPause}
                                  ></i>
                                </Button>
                              )}
                              <span className="ml-2">{duration}</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex align-items-center justify-content-end mt-5">
                        <Button onClick={this.handleViewTicketDetails}>View Last Citation</Button>
                        <Button
                          type="button"
                          className="check-plate-input mr-2 text-nowrap ml-3"
                          onClick={this.handleSubmit.bind(this)}
                          disabled={!isFormValid}
                        >
                          Generate
                        </Button>
                      </div>
                    </Form>
                    {this.state.show && (
                      <CitationPrintModal
                        onOpen={show}
                        onClose={this.handleClose}
                        modalTitle="Parking Violation"
                        pdfLoader={this.state.pdfLoader}
                        pdfUrl={this.state.pdfUrl}
                        zplData={this.state.zplData}
                        isMobile={isMobile}
                        detectedDevice={detectedDevice}
                        // onDocumentLoadSuccess={this.onDocumentLoadSuccess}
                        printTicketDetails={this.printTicketDetails}
                        printToZebra={() => this.sendFile(zplUrl)}
                        pngurl={this.state.pngurl}
                        // saveAsPng={() => this.handleTicketType()}
                        sendPdfPngMail={() => this.openSendMailModal(this.state.generateTicketData.id)}
                        printButtonDisable={printButtonDisable}
                      />
                    )}
                    {this.state.isSendMailModalOpen && (
                      <SendMailModal
                        closeModal={this.closeSendMailModal}
                        handleMailChange={this.handleMailChange}
                        emailToSend={this.state.emailToSend}
                        emailError={this.state.emailError}
                        ticketDataPdf={this.sendMailClick}
                        isMailFormValid={this.state.isMailFormValid}
                      />
                    )}
                    {show_alert && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
                    {show_error && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, TicketDetails, Logindetails, ResidenDetails, SuperAdminDetails }) => {
  const { timezone, property_id, property, id } = UserProfileDetails;
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { currentLOT, currentTR } = ResidenDetails;
  const {
    reasonList,
    viewLastTicket,
    generateTicketData,
    ticketPDFData,
    reasonSetupDetail,
    viewLastPlateNumberDetails,
    viewVinWarningNumberDetails,
    viewVinInformation,
    sequencedReasons,
  } = TicketDetails;
  const { lots } = SuperAdminDetails;
  return {
    property_id,
    api_response,
    api_error_message,
    api_success_message,
    reasonList,
    viewLastTicket,
    property,
    generateTicketData,
    timezone,
    main_body,
    ticketPDFData,
    reasonSetupDetail,
    viewLastPlateNumberDetails,
    viewVinWarningNumberDetails,
    viewVinInformation,
    currentLOT,
    currentTR,
    lots,
    id,
    sequencedReasons,
  };
};

const mapStateToDispatch = {
  getReasonDetails: TicketActions.getReasonDetails,
  generateTicket: TicketActions.generateTicket,
  getLastTicketDetails: TicketActions.getLastTicketDetails,
  pdfSendToMail: TicketActions.pdfSendToMail,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  getBlobPdf: TicketActions.getBlobPdf,
  getPng: TicketActions.getPng,
  viewLastPlateNumber: TicketActions.viewLastPlateNumber,
  viewVinWarningNumber: TicketActions.viewVinWarningNumber,
  getVinInfo: TicketActions.getVinInfo,
  get_all_lots: SuperAdminAction.get_all_lots,
  getPDFandPNGfromZpl: TicketActions.getPDFandPNGfromZpl,
  getEncodedFileFromLabelaryApi: TicketActions.getEncodedFileFromLabelaryApi,
};

export default connect(mapStateToProps, mapStateToDispatch)(Ticket);

import t from "./types";
import permitType from "../CurrentPermitActions/types";
import apiType from "../APIResponseActions/type";
import { AuthAPI } from "../../../Auth/requestHandler";

export const fetchPassagePassTransactionData = (data) => async (dispatch) => {
  try {
    let permitId = data.id;
    if (data.permit_class === "PermitPackage" && data?.original_permit_id) {
      permitId = data.original_permit_id;
    }
    const bodyData = {
      id: permitId,
      property_id: data.property_id,
    };
    const url = `report/passage-pass-transaction`;
    const response = await AuthAPI("domain", url, "POST", bodyData);
    if (response?.success) {
      await dispatch({
        type: t.GET_PASSAGEPASS_DATA,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const createPassagePassRefund = (data) => async (dispatch) => {
  try {
    const url = `report/passage-pass-refund`;
    const response = await AuthAPI("domain", url, "POST", data);
    if (response?.success) {
      await dispatch({
        type: permitType.PASSAGEPASS_REFUND,
        payload: data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getWaitList = (pageObj) => async (dispatch) => {
  try {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: "" });

    const body = {
      property_id: pageObj.property_id,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportValue ? pageObj.exportValue : false,
      lot_id: pageObj.lot_id ? pageObj.lot_id : null,
      package_name: pageObj.package_name ? pageObj.package_name : null,
    };

    if (pageObj.childcheckbox) {
      body.childcheckbox = pageObj.childcheckbox;
    }
    const url = "permit/waitlist";
    if (body.exportValue) {
      try {
        const data = await BufferAuthAPI("domain", url, "POST", body);
        await dispatch({
          type: t.FETCH_WAIT_EXPORT_LIST,
          payload: data,
        });
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_WAIT_LIST,
          payload: response.data,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const cancelWaitList = (selectedPermitId, sent_from, type, waitListData) => async (dispatch) => {
  try {
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
    const body = {
      id: selectedPermitId,
      sent_from,
      type,
    };
    let url;
    if (selectedPermitId) {
      url = `permit/waitlist/autorenew-cancel`;
    }
    const response = await AuthAPI("domain", url, "POST", body);
    if (response) {
      dispatch(getWaitList(waitListData));
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const setWaitList = (waitListData, ListData) => async (dispatch) => {
  await dispatch({
    type: apiType.API_RESPONSE_SUCCESS,
    payload: "",
  });
  const data = {
    waitListData: waitListData,
  };
  try {
    const url = `permit/waitlist/reorder-manage`;
    const response = await AuthAPI("domain", url, "POST", data);
    if (response) {
      dispatch(getWaitList(ListData));
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

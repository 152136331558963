import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const PermitNotes = (props) => {
  const handleNoteOptions = (index, value) => {
    props.setNoteOptions([
      ...props.options.slice(0, index),
      value,
      ...props.options.slice(index + 1),
    ]);
  };
  const removeNoteOption = (index) => {
    props.setNoteOptions([...props.options.filter((_x, i) => i !== index)]);
  };
  const handleAddNoteOption = () => {
    props.setNoteOptions([...props.options, ""]);
  };
  return (
    <>
      <Row className="d-flex align-items-center ml-2 my-2">
        <Form.Label>Options for notes</Form.Label>
        <Button
          size="sm"
          className="ml-3"
          disabled={props.options?.length > 4}
          onClick={handleAddNoteOption}
        >
          <i className="fas fa-plus-circle"></i>
        </Button>
      </Row>
      {props.options &&
        typeof props.options === "object" &&
        Array.isArray(props.options) &&
        props.options.map((option, index) => {
          return (
            <Row key={index} className="my-0">
              <Col className="d-flex align-items-center">
                <Form.Group className="p-0 m-1">
                  {/* <Form.Label>Option {index + 1}</Form.Label> */}
                  {/* <span className="asterisk">*</span> */}
                  <Form.Control
                    placeholder="Purpose of visit"
                    type="text"
                    name="title"
                    value={option}
                    // onBlur={(e) => e.target.value.trim() === "" && removeNoteOption(index)}
                    onChange={(e) => handleNoteOptions(index, e.target.value)}
                    autoComplete="off"
                  />
                </Form.Group>
                <Button
                  size="sm"
                  className="ml-2"
                  onClick={() => removeNoteOption(index)}
                >
                  <i className="fas fa-minus"></i>
                </Button>
              </Col>
            </Row>
          );
        })}
      {props.errorState && props.errorState.note_options.length > 0 && (
        <div className="help-block">{props.errorState.note_options}</div>
      )}
    </>
  );
};

export default PermitNotes;

import apiType from "../APIResponseActions/type";
import { AuthAPI } from "../../../Auth/requestHandler";
import t from "./types";

export const fetchAllDeviceTypes = () => async (dispatch) => {
  try {
    const url = "edge_device/types";
    const response = await AuthAPI("domain", url, "GET");
    await dispatch({
      type: t.FETCH_ALL_DEVICE_TYPE,
      payload: response,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
    console.log("ERROR!", error);
  }
};

export const fetchFieldsForDeviceType =
  (typename = "VPC") =>
  async (dispatch) => {
    try {
      const url = `edge_device/fields/${typename}`;
      const response = await AuthAPI("domain", url, "GET");
      await dispatch({
        type: t.FETCH_ALL_FIELDS_FOR_DEVICE_TYPE,
        payload: response,
      });
      await dispatch({
        type: t.SET_DEVICE_TYPE,
        payload: response,
      });
    } catch (error) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: error.toString(),
      });
      console.log("ERROR!", error);
    }
  };
export const deleteSelectedDevice =
  (deviceId, propertyId) => async (dispatch) => {
    try {
      const url = `edge_device/${deviceId}?property_id=${propertyId}`;
      await AuthAPI("domain", url, "DELETE");
      await dispatch({
        type: t.SET_EDGE_DEVICE_SUCCESS,
        payload: "Edge Device successfully deleted.",
      });
      dispatch(fetchAllEdgeDevices());
    } catch (error) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: error.toString(),
      });
      console.log("ERROR!", error);
    }
  };

export const fetchAllEdgeDevices = () => async (dispatch) => {
  try {
    const propertyId = window.document.location.href.split("?")[1];
    const url = `edge_device/${propertyId}`;
    const response = await AuthAPI("domain", url, "GET");

    await dispatch({
      type: t.GET_ALL_EDGE_DEVICES,
      payload: response,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
    console.log("ERROR!", error);
  }
};

export const fetchAllLots = () => async (dispatch) => {
  try {
    const propertyId = window.document.location.href.split("?")[1];
    const url = `lot?property_id=${propertyId}`;
    const response = await AuthAPI("domain", url, "GET");

    await dispatch({
      type: t.SET_LOT_DATA,
      payload: response,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
    console.log("ERROR!", error);
  }
};
export const fetchAllLanes = () => async (dispatch) => {
  try {
    const propertyId = window.document.location.href.split("?")[1];
    const url = `lane?property_id=${propertyId}`;
    const response = await AuthAPI("domain", url, "GET");

    await dispatch({
      type: t.SET_LANE_DATA,
      payload: response,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
    console.log("ERROR!", error);
  }
};
export const fetchAllCameras = () => async (dispatch) => {
  try {
    const propertyId = window.document.location.href.split("?")[1];
    const url = `camera/paginate?page_size=1000&page=1&search&order_by=ip_addr&sorting=DESC&property_id=${propertyId}`;
    const response = await AuthAPI("domain", url, "GET");

    await dispatch({
      type: t.SET_CAMERA_DATA,
      payload: response.rows,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
    console.log("ERROR!", error);
  }
};

export const addEdgeDevice = (edgeDeviceConfig) => async (dispatch) => {
  try {
    const url = edgeDeviceConfig.id
      ? `edge_device/${edgeDeviceConfig.id}`
      : "edge_device";
    try {
      try {
        const response = await AuthAPI(
          "domain",
          url,
          !edgeDeviceConfig.id ? "POST" : "PATCH",
          edgeDeviceConfig
        );
        if (response.success === 1) {
          dispatch({
            type: t.SET_EDGE_DEVICE_SUCCESS,
            payload: edgeDeviceConfig.id
              ? "Edge device updated Successfully"
              : "Edge device added Successfully",
          });
          dispatch({
            type: t.CLOSE_OPEN_MODEL,
            payload: false,
          });
          dispatch({
            type: t.SET_EDGE_DEVICE_DATA,
            payload: {},
          });
          dispatch({
            type: t.SET_DEVICE_TYPE,
            payload: [],
          });
        } else {
          dispatch({
            type: t.SET_EDGE_DEVICE_ERROR,
            payload: response.message,
          });
        }
        await dispatch(fetchAllEdgeDevices());
      } catch (e) {
        dispatch({
          type: t.SET_EDGE_DEVICE_ERROR,
          payload: "An error occured while adding Edge device.",
        });
      }
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: false,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
    console.log("ERROR!", error);
  }
};

import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { checkCouponValidity, resetCouponCodeDetails } from "../../Actions/ValidationCouponActions/actions";
import { SuperAdminAction } from "../../Actions";

function CheckCouponValidity(props) {
  const [coupon_code, setCoupon_code] = useState("");
  const [loader, setLoader] = useState(false);
  const couponValidationDetail = useSelector((state) => state.ValidationDetails.couponValidationDetail);
  const UserData = useSelector((userState) => userState.UserProfileDetails);
  const dispatch = useDispatch();

  const checkForCouponCode = () => {
    console.log("coupon_code", coupon_code);
    if (coupon_code) {
      setLoader(true);
      dispatch(checkCouponValidity(coupon_code));
      setLoader(false);
    } else {
      resetRes();
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.key === "Enter" && coupon_code) {
      checkForCouponCode();
    }
  };

  const resetRes = () => {
    dispatch(resetCouponCodeDetails());
    setCoupon_code("");
  };

  const handleChange = (e) => {
    setCoupon_code(e.target.value);
    const history = require("../../history").default;
    history.push({
      pathname: "/check-discount-coupon-validity",
      state: { discount_code: e.target.value },
    });
  };

  useEffect(() => {
    if (!coupon_code) {
      resetRes();
    }
  }, [coupon_code]);

  useEffect(() => {
    const selectedPropertyName = UserData.property?.property_name;
    const selectedDomainName = UserData.property?.domain;
    dispatch(SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName));
  }, [UserData]);

  useEffect(() => {
    resetRes();
    const discountCode = props.location?.state?.discount_code;
    if (discountCode) {
      setCoupon_code(discountCode);
      setLoader(true);
      dispatch(checkCouponValidity(discountCode));
      setLoader(false);
    }
  }, []);

  const discountType = {
    FIXED_COST: { prefix: "Flat", sufix: "$" },
    FIXED_DISCOUNT: { prefix: "Flat", sufix: "$ Off" },
    TIME_CREDIT: { prefix: "", sufix: "minutues Off" },
    PERCENTAGE: { prefix: "Flat", sufix: "% Off" },
  };

  const discountToShow = useMemo(() => {
    const discountText = discountType[couponValidationDetail?.data?.discountType];
    return `${discountText?.prefix} ${couponValidationDetail?.data?.discountValue} ${discountText?.sufix}`;
  }, [couponValidationDetail]);

  return (
    <>
      <Container fluid className="manage-unit-list-page page-no-fliter">
        <Row>
          <Col md="12">
            <Card className="mb-0">
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <Card.Title as="h4">Check Validity Of Parking Discount Coupon</Card.Title>
                </div>
              </Card.Header>
              <Card.Body style={{ minHeight: "80vh" }}>
                <Row className="mt-3">
                  <Col xl="6" lg="8" md="8" sm="12">
                    <label>Coupon Code</label>
                    <span className="asterisk">*</span>
                    <Form.Control
                      placeholder="Enter Coupon Code"
                      type="text"
                      name="coupon_code"
                      value={coupon_code}
                      onChange={handleChange}
                      onKeyDown={onKeyDownHandler}
                      autoComplete="off"
                      required
                      maxLength={8}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row>
                  <Col xl="6" lg="8" md="8" sm="12">
                    {loader ? (
                      <Spinner animation="border" variant="info" />
                    ) : (
                      <Button onClick={checkForCouponCode} disabled={!coupon_code} className="my-3 mr-3 alignItem:right">
                        Check Validity
                      </Button>
                    )}
                    <Button onClick={resetRes} disabled={loader} className="my-3 alignItem:right">
                      Reset
                    </Button>
                  </Col>
                </Row>
                <Row>
                  {!loader && Object.keys(couponValidationDetail).length > 0 && (
                    <Col md="12">
                      {couponValidationDetail.message && (
                        <h5
                          style={{
                            color: `${couponValidationDetail.success ? "green" : "red"}`,
                          }}
                        >
                          {couponValidationDetail.message}
                        </h5>
                      )}
                      {Object.keys(couponValidationDetail.data).length > 0 && (
                        <>
                          <div>
                            <span>Start Date : </span> {couponValidationDetail.data?.startDate.split(" ")[0]}
                          </div>
                          <div>
                            <span>Expiry Date :</span> {couponValidationDetail.data?.endDate.split(" ")[0]}
                          </div>
                          <div>
                            <span>Discount :</span> {discountToShow}
                          </div>
                          <div>
                            {couponValidationDetail?.data?.isCouponUsed === 1 && (
                              <>
                                <span>Used On :</span> {couponValidationDetail?.data?.couponUsed.split(" ")[0]}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CheckCouponValidity;

/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import { LotCountingActions, SuperAdminAction } from "../../Actions";

class ViewOccupancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_status: "NOT FULL",
      current_occupancy: 0,
      current_free: 0,
      total_stalls: 0,
      show_full_above: 0,
      show_not_full_below: 0,
      update_button: true,
      api_response: false,
      PropertyId: this.props.property_id,
      LotCounterLots: [],
      isDisabled: true,
      ShowCondition: false,
      lotSelectionError: "",
      userlots: [],
    };
  }

  componentDidMount = async () => {
    const { PropertyId } = this.state;
    const selectedPropertyName = this.props.property?.property_name;
    const selectedDomainName = this.props.property?.domain;
    this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    await this.props.get_all_lots(PropertyId);
    const current_lot = this.props.lots;
    let lot_id_value = "";
    if (current_lot?.length) {
      current_lot.map((el) => {
        return el.id;
      });
    }

    this.setState(
      {
        selectedDomainName: selectedDomainName,
        userlots: current_lot,
      },
      () => {
        console.log("what is the value of this lots", this.state.userlots);
      },
    );

    if (current_lot?.length && current_lot?.length == 1) {
      lot_id_value = this.state.userlots[0]?.id;
      this.setState({ lot_id: lot_id_value });
      await this.props.select_edit_lotcounting(PropertyId, lot_id_value);
    }
    this.setState({ lot_id: lot_id_value });
    this.setState(
      {
        editedLotCounted: this.props.lotcounting_list,
        isFormValid: true,
      },
      () => {
        this.settingData();
      },
    );
    this.settingData();
  };
  settingData = () => {
    const { editedLotCounted } = this.state;
    if (editedLotCounted) {
      const { current_status, current_occupancy, current_free, total_stalls, show_full_above, show_not_full_below } = editedLotCounted;

      this.setState({
        current_status,
        current_occupancy,
        total_stalls,
        current_free,
        show_full_above,
        show_not_full_below,
        updated_current_occupancy: current_occupancy,
      });
    }
  };

  refresh = () => {
    window.location.reload();
  };

  handleSubmit = async () => {
    const { current_occupancy, total_stalls, show_full_above, show_not_full_below } = this.state;
    const lotcounting_list = {
      current_occupancy,
      total_stalls,
      show_full_above: +show_full_above,
      show_not_full_below,
    };
    const Obj = { ...lotcounting_list };
    await this.props.edit_lotcounting(Obj, this.props.property_id, this.state.lot_id);
    if (this.props.api_response) {
      this.setState(
        {
          show_alert: true,
          isDisabled: true,
          api_success_message: this.props.api_success_message,
        },
        () => {
          setTimeout(() => {
            this.setState({ show_alert: false });
          }, 4000);
        },
      );
    } else {
      this.setState({ show_error: true });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  updateTotalStalls(event) {
    this.setState({
      total_stalls: event.target.value,
    });
  }

  updateShowFullAbove(event) {
    const { total_stalls, show_full_above, show_not_full_below, current_occupancy } = this.state;
    if (
      show_full_above > 0 &&
      show_full_above < total_stalls &&
      show_not_full_below > 0 &&
      show_not_full_below <= show_full_above &&
      current_occupancy <= total_stalls
    ) {
      this.setState({
        ShowCondition: false,
        update_button: false,
      });
    } else {
      this.setState({
        update_button: true,
        ShowCondition: true,
      });
    }

    this.setState({
      show_full_above: event.target.value,
    });
  }

  changeProperty = (event) => {
    console.log("changes ppppp:===", event.target.value);
    this.setState(
      {
        selectedLot: event.target.value,
      },
      () => {
        const { selectedLot } = this.state;
        if (!selectedLot) {
          this.setState({
            lotSelectionError: "Please select lot first",
            isScanEnabled: false,
            isOpenCamera: false,
            stallDisabled: true,
          });
        }
        if (selectedLot) {
          this.setState({
            isFormValid: true,
            lotSelectionError: "",
          });
        } else {
          this.setState({
            isFormValid: false,
          });
        }
      },
    );
  };

  updateShowNotFullAbove(event) {
    const { total_stalls, show_full_above, show_not_full_below, current_occupancy } = this.state;
    if (
      show_full_above > 0 &&
      show_full_above < total_stalls &&
      show_not_full_below > 0 &&
      show_not_full_below <= show_full_above &&
      current_occupancy <= total_stalls
    ) {
      this.setState({
        ShowCondition: false,
        update_button: false,
      });
    } else {
      this.setState({
        update_button: true,
        ShowCondition: true,
      });
    }

    this.setState({
      show_not_full_below: event.target.value,
    });
  }

  updateCurrentOccupancy(event) {
    const { total_stalls, show_full_above, show_not_full_below, current_occupancy } = this.state;
    if (
      show_full_above > 0 &&
      show_full_above < total_stalls &&
      show_not_full_below > 0 &&
      show_not_full_below <= show_full_above &&
      current_occupancy <= total_stalls
    ) {
      this.setState({
        ShowCondition: false,
        update_button: false,
      });
    } else {
      this.setState({
        update_button: true,
        ShowCondition: true,
      });
    }
    this.setState({
      current_occupancy: event.target.value,
    });
  }

  componentDidUpdate = (prevProps) => {
    if (JSON.stringify(this.props.lotcounter_list) !== JSON.stringify(prevProps.lotcounter_list)) {
      this.setState({ cameraList: this.props.lotcounter_list });
    }
    if (!isEqual(prevProps.lots, this.props.lots)) {
      this.setState({
        userlots: this.props.lots,
      });
    }
  };

  handleChangeLot = async ({ target }) => {
    this.setState({
      lot_id: target.value,
    });
    if (target.value !== "Select Lot") {
      console.log("Check the value of the specific lot selected:", target.value);
      const lot_counting_list = await this.props.select_edit_lotcounting(this.props.property_id, target.value);
      if (typeof lot_counting_list !== "undefined") {
        this.setState({
          current_status: lot_counting_list?.current_status,
          current_occupancy: lot_counting_list?.current_occupancy,
          current_free: lot_counting_list?.current_free,
          total_stalls: lot_counting_list?.total_stalls,
          show_full_above: lot_counting_list?.show_full_above,
          show_not_full_below: lot_counting_list?.show_not_full_below,
          lot_id: target?.value,
          updated_current_occupancy: lot_counting_list?.current_occupancy,
        });
      } else {
        this.setState({
          current_status: "Not Full",
          current_occupancy: 0,
          current_free: 0,
          total_stalls: 0,
          show_full_above: 0,
          show_not_full_below: 0,
          lot_id: target.value,
          updated_current_occupancy: 0,
        });
      }
    } else {
      await this.props.get_all_lots(this.state.PropertyId);
      const current_lot = this.props.lots;
      if (current_lot?.length) {
        current_lot.map((el) => {
          return el.id;
        });
      }
      this.setState({
        lot_id: current_lot,
      });
    }
  };

  handleEditButton(e) {
    e.preventDefault();
    this.setState({
      update_button: false,
      isDisabled: false,
    });
  }

  render() {
    const {
      current_status,
      current_occupancy,
      current_free,
      total_stalls,
      show_full_above,
      show_not_full_below,
      update_button,
      isDisabled,
      updated_current_occupancy,
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="contact-container mb-0">
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <Card.Title as="h4">Lot Count</Card.Title>
                  <p className="card-category"></p>
                </Card.Header>
                <Card.Body>
                  <Row className="d-flex flex-wrap">
                    <Form.Group>
                      <Col className="min-w-200px">
                        <Form.Label>LOTS:</Form.Label>
                        <Form.Control as="select" name="lot_id" value={this.state.lot_id} onChange={this.handleChangeLot}>
                          <option value="">Select Lot</option>
                          {(this.state.userlots ?? []).map((e) => {
                            return (
                              <option key={e.id} value={e.id}>
                                {e.alias}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Row>
                  <Row className="d-flex flex-wrap">
                    <Col className="min-w-200px">
                      <Form.Label>CURRENT STATUS</Form.Label>
                      <p>{current_status || "-"}</p>
                    </Col>
                    <Col className="min-w-200px">
                      <Form.Label>CURRENT OCCUPANCY</Form.Label>
                      <p>{updated_current_occupancy || "-"}</p>
                    </Col>
                    <Col className="min-w-200px">
                      <Form.Group>
                        <Form.Label>CURRENT FREE</Form.Label>
                        <p>{current_free || "-"}</p>
                      </Form.Group>
                    </Col>
                    <Col className="min-w-200px">
                      <Form.Group>
                        <Button className="btn-fill mt-4 btn-sm" type="button" variant="info" onClick={this.refresh}>
                          Refresh
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="d-flex flex-wrap">
                    <Col className="min-w-200px">
                      <Form.Label>TOTAL STALLS</Form.Label>
                      <Form.Control
                        type="number"
                        id="total_stalls"
                        value={total_stalls}
                        onChange={this.updateTotalStalls.bind(this)}
                        pattern="[0-9]*"
                        disabled={isDisabled}
                        min={0}
                        className="form-control form-control-sm"
                      />
                    </Col>
                    <Col className="min-w-200px">
                      <Form.Label>SHOW FULL ABOVE</Form.Label>
                      <Form.Control
                        type="number"
                        value={show_full_above}
                        onChange={this.updateShowFullAbove.bind(this)}
                        id="show_full_above"
                        className="form-control form-control-sm"
                        pattern="[0-9]*"
                        disabled={isDisabled}
                        min={0}
                      />
                    </Col>
                    <Col className="min-w-200px">
                      <Form.Label>SHOW NOT FULL BELOW</Form.Label>
                      <Form.Control
                        type="number"
                        id="show_not_full_below"
                        value={show_not_full_below}
                        onChange={this.updateShowNotFullAbove.bind(this)}
                        className="form-control form-control-sm"
                        pattern="[0-9]*"
                        disabled={isDisabled}
                        min={0}
                        placeholder=""
                      />
                    </Col>
                    <Col className="min-w-200px">
                      <Form.Label>CURRENT OCCUPANCY</Form.Label>
                      <Form.Control
                        type="number"
                        id="current_occupancy"
                        value={current_occupancy}
                        onChange={this.updateCurrentOccupancy.bind(this)}
                        className="form-control form-control-sm"
                        placeholder=""
                        pattern="[0-9]*"
                        disabled={isDisabled}
                        min={0}
                      />
                    </Col>
                    <Col className="min-w-200px">
                      <Button className="btn-fill mt-4 btn-sm" type="button" variant="info" onClick={this.handleEditButton.bind(this)}>
                        Edit
                      </Button>
                      <Button
                        className="btn-fill ml-2 mt-4 btn-sm"
                        type="button"
                        variant="info"
                        disabled={update_button}
                        onClick={this.handleSubmit.bind(this)}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ LotCountingDetails, APIResponseDetails, UserProfileDetails, Logindetails, SuperAdminDetails }) => {
  const { lotcounting_list, LotCounterLots, userlots } = LotCountingDetails;
  const { property } = UserProfileDetails;
  const { api_response, api_success_message, api_error_message } = APIResponseDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { lots } = SuperAdminDetails;
  return {
    userlots,
    lots,
    lotcounting_list,
    api_response,
    api_success_message,
    api_error_message,
    property,
    main_body,
    LotCounterLots,
  };
};

const mapStateToDispatch = {
  select_edit_lotcounting: LotCountingActions.select_edit_lotcounting,
  edit_lotcounting: LotCountingActions.edit_lotcounting,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  get_all_lots: SuperAdminAction.get_all_lots,
};

export default connect(mapStateToProps, mapStateToDispatch)(ViewOccupancy);

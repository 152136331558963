const types = {
  FETCH_ALL_DEVICE_TYPE: "FETCH_ALL_DEVICE_TYPE",
  SET_DEVICE_TYPE: "SET_DEVICE_TYPE",
  FETCH_ALL_FIELDS_FOR_DEVICE_TYPE: "FETCH_ALL_FIELDS_FOR_DEVICE_TYPE",
  GET_ALL_EDGE_DEVICES: "GET_ALL_EDGE_DEVICES",
  SET_EDGE_DEVICE_DATA: "SET_EDGE_DEVICE_DATA",
  SET_LOT_DATA: "SET_LOT_DATA",
  SET_LANE_DATA: "SET_LANE_DATA",
  SET_CAMERA_DATA: "SET_CAMERA_DATA",
  SET_EDGE_DEVICE_SUCCESS: "SET_EDGE_DEVICE_SUCCESS",
  SET_EDGE_DEVICE_ERROR: "SET_EDGE_DEVICE_ERROR",
  UPDATE_EDGE_DEVICE_LIST: "UPDATE_EDGE_DEVICE_LIST",
  CLOSE_OPEN_MODEL: "CLOSE_OPEN_MODEL",
  SHOW_HIDE_LOADER: "SHOW_HIDE_LOADER",
};

export default types;

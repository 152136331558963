import t from "../../Actions/SelfRegisterActions/types";
import actionCreator from "../../Actions/Action.Helper";
import reducerHandler from "../Reducer.Helper";
const getPermitDetails = actionCreator("GET_PERMIT_DETAIL");

const initialAsyncState = {
  isLoading: false,
  loaded: false,
  data: null,
  error: null,
  message: "",
  success: null,
};

const initial = {
  isPinValid: 0,
  property_id: "",
  quickPermitSetupDetail: {},
  quickPermitDetail: {},
  quickPermitDetails: initialAsyncState,
  quickPermitCreateDetail: {},
};
const reducer = (state = initial, action) => {
  switch (action.type) {
    case t.IS_PIN_VALID: {
      const test = {
        ...state,
        isPinValid: JSON.parse(JSON.stringify(action.payload)),
      };
      return { ...test };
    }
    case t.RESET_QUICK_PERMIT_DATA: {
      return {
        ...state,
        quickPermitDetail: {},
      };
    }
    case t.GET_PROPERTY_ID: {
      return {
        ...state,
        property_id: action.payload,
      };
    }
    case t.GET_QUICK_PERMIT_SETUP_DETAIL: {
      return {
        ...state,
        quickPermitSetupDetail: action.payload,
      };
    }
    case t.GET_PERMIT_DETAIL: {
      return {
        ...state,
        quickPermitDetail: action.payload,
      };
    }
    case getPermitDetails.REQUEST:
    case getPermitDetails.SUCCESS:
    case getPermitDetails.FAILURE:
      return {
        ...state,
        quickPermitDetails: reducerHandler(
          state.quickPermitDetail,
          action,
          getPermitDetails
        ),
      };
    case t.GET_QUICK_PEMIT_CREATE_DATA: {
      return {
        ...state,
        quickPermitCreateDetail: action.payload,
      };
    }
    default:
      return state;
  }
};
export default reducer;

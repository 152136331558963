const highSecurityActionList = {
  ADD_UNIT: "ADD",
  UPDATE_UNIT: "UPDATE",
  REMOVE_UNIT: "DELETE",
  VIEW_UNIT: "VIEW",
  VIEW_DASHBOARD: "VIEW",
  ADD_PERMIT: "ADD",
  ADD_QUICK_PERMIT: "ADD",
  UPDATE_PERMIT: "UPDATE",
  VIEW_PERMIT: "VIEW",
  VIEW_WAITLIST: "VIEW",
  DEACTIVATE_PERMIT: "DEACTIVATE",
  CWH_ACTIVATED: "CWH ACTIVATED",
  CWH_DEACTIVATED: "CWH DEACTIVATED",
  VIEW_CURRENT_VISITORS: "VIEW",
  VIEW_OCCUPANCY: "VIEW",
  UPDATE_OCCUPANCY: "UPDATE",
  VIEW_PARKING_LOG: "VIEW",
  SCAN_PLATE: "SCAN PLATE",
  PLATE_CHECK: "PLATE CHECK",
  PLATE_AUDIT_CHECK_LPR: "PLATE AUDIT - LPR",
  PLATE_AUDIT_CHECK_PERMITS: "PLATE AUDIT - PERMITS",
  PLATE_SEND_MAIL_PERMIT: "SEND EMAIL PLATE",
  ADD_CITATION: "ADD",
  VIEW_CITATION: "VIEW",
  CLOSE_CITATION: "CLOSE",
  REOPEN_CITATION: "REOPEN",
  SAVE_CITATION_PDF: "SAVE CITATION PDF",
  SAVE_CITATION_PNG: "SAVE CITATION PNG",
  SEND_PDF_PNG_MAIL_CITATION: "SEND CITATION IN MAIL",
  SEND_TRANSACTION_MAIL: "SEND TRANSACTION MAIL",
  EXPORT_CITATION: "EXPORT",
  ADD_LOT_SHARING: "ADD",
  VIEW_LOT_SHARING: "VIEW",
  DELETE_LOT_SHARING: "DELETE",
  VIEW_LPR_REPORT: "VIEW",
  EXPORT_LPR_REPORT: "EXPORT",
  VIEW_CITATION_ISSUED_REPORT: "VIEW",
  EXPORT_CITATION_ISSUED_REPORT: "EXPORT",
  VIEW_CITATION_ISSUED_REPORT_BY_ENFORCER: "VIEW",
  EXPORT_CITATION_ISSUED_REPORT_BY_ENFORCER: "EXPORT",
  VIEW_CITATION_PAID_REPORT: "VIEW",
  EXPORT_CITATION_PAID_REPORT: "EXPORT",
  VIEW_CITATION_PAID_REPORT_BY_PAYMENT: "VIEW",
  EXPORT_CITATION_PAID_REPORT_BY_PAYMENT: "EXPORT",
  VIEW_AUDIT_REPORT: "VIEW",
  VIEW_REVENUE_REPORT: "VIEW",
  EXPORT_REVENUE_REPORT: "EXPORT XLS",
  EXPORT_REVENUE_REPORT_PDF: "EXPORT PDF",
  VIEW_DISPENSER_REPORT: "VIEW",
  EXPORT_DISPENSER_REPORT: "EXPORT",
  VIEW_VALIDATION_REPORT: "VIEW",
  VIEW_RATE_TRACKING_REPORT: "VIEW",
  EXPORT_RATE_TRACKING_REPORT: "EXPORT",
  VIEW_TRANSACTION_REPORT: "VIEW",
  VIEW_PERMIT_TRANSACTION_REPORT: "VIEW",
  EXPORT_TRANSACTION_REPORT: "EXPORT",
  EXPORT_PERMIT_TRANSACTION_REPORT: "EXPORT",
  VIEW_VEND_CONTROL_REPORT: "VIEW",
  EXPORT_VEND_CONTROL_REPORT: "EXPORT",
  VIEW_CITATION_TOTAL_REPORT: "VIEW TOTAL",
  EXPORT_CITATION_TOTAL_REPORT: "EXPORT TOTAL",
  VIEW_CITATION_PAYABLE_REPORT: "VIEW PAYBLE",
  EXPORT_CITATION_PAYABLE_REPORT: "EXPORT PAYBLE",
  VIEW_CITATION_BY_STATUS_REPORT: "VIEW BY STATUS",
  EXPORT_CITATION_BY_STATUS_REPORT: "EXPORT BY STATUS",
  VIEW_CITATION_BY_ENFORCER_REPORT: "VIEW BY ENFORCER",
  EXPORT_CITATION_BY_ENFORCER_REPORT: "EXPORT BY ENFORCER",
  VIEW_BY_ENFORCER_PAID_REPORT: "VIEW BY ENFORCER PAID",
  EXPORT_BY_ENFORCER_PAID_REPORT: "EXPORT BY ENFORCER PAID",
  VIEW_REFUND_REPORT: "VIEW",
  EXPORT_REFUND_REPORT: "EXPORT XLS",
  UPDATE_PROFILE: "UPDATE PROFILE",
  CHANGE_PASSWORD: "CHANGE PASSWORD",
  VIEW_LAST_CITATION: "VIEW LAST CITATION",
  CONTACT_SUPPORT: "VIEW",
  OPEN_STREAM: "OPEN STREAM",
  CLOSE_STREAM: "CLOSE STREAM",
  VIEW_LIVE_FEED: "VIEW",
  OPEN_LIVE_FEED: "OPEN",
  CLOSE_LIVE_FEED: "CLOSE",
  LOCK_OPEN_LIVE_FEED: "LOCK OPEN",
  LOCK_CLOSE_LIVE_FEED: "LOCK CLOSE",
  LOOP_VEND_MESSAGE_LIVE_FEED: "LOOP VEND MESSAGE",
  REGULAR_OPERATION_LIVE_FEED: "REGULAR OPERATION",
  LOGOUT: "LOGOUT",
  LOGIN: "LOGIN",
  EXPORT: "EXPORT",
  IMPORT: "IMPORT",
  SEND_CHAT_MSG: "SEND CHAT MSG",
  UPDATE_TICKET_STATUS: "UPDATE TICKET STATUS",
  VIEW_DISPUTE: "VIEW",
  VIEW_ALL_PROPERTIES: "VIEW ALL PROPERTIES",
  VIEW_ARCHIVED_PROPERTIES: "VIEW ARCHIVED PROPERTIES",
  ARCHIVED_PROPERTY: "ARCHIVED PROPERTY",
  UNARCHIVED_PROPERTY: "UNARCHIVED PROPERTY",
  DELETE_PROPERTY: "DELETE PROPERTY",
  ADD_PROPERTY: "ADD PROPERTY",
  VIEW_USER_SEARCH: "VIEW USER SEARCH",
  SITE_SETUP_VIEW_USERS: "VIEW USERS",
  SEARCH_BY_EMAIL: "SEARCH BY EMAIL",
  SEARCH_BY_NAME: "SEARCH BY NAME",
  SEARCH_BY_PHONE: "SEARCH BY PHONE",
  SEARCH_BY_LPN: "SEARCH BY LPN",
  IMPORT_PROPERTY: "IMPORT PROPERTY",
  IMPORT_PERMIT: "IMPORT",
  EXPORT_PERMITS: "EXPORT",
  COPY_SETTING: "COPY SETTING",
  VIEW_SITE_SETTING: "VIEW SITE SETTING",
  UPDATE_USER_TYPES: "UPDATE",
  UPDATE_PERMISSIONS: "UPDATE PERMISSIONS",
  ADD_USER: "ADD",
  DELETE_USER: "DELETE",
  UPDATE_USER: "UPDATE",
  VIEW_PERMISSIONS: "VIEW PERMISSIONS",
  VIEW_PDF_TEMPLATE: "VIEW PDF TEMPLATE",
  CREATE_PDF_TEMPLATE: "CREATE PDF TEMPLATE",
  DELETE_PDF_TEMPLATE: "DELETE PDF TEMPLATE",
  UPDATE_PDF_TEMPLATE: "UPDATE PDF TEMPLATE",
  ADD_REASON: "ADD",
  UPDATE_REASON: "UPDATE",
  VIEW_REASON: "VIEW",
  DELETE_LOT: "DELETE",
  UPDATE_LOT: "UPDATE",
  ADD_LOT: "ADD",
  VIEW_LOT: "VIEW",
  VIEW_LANE: "VIEW",
  DELETE_LANE: "DELETE",
  UPDATE_LANE: "UPDATE",
  ADD_LANE: "ADD",
  VIEW_CAMERA: "VIEW",
  DELETE_CAMERA: "DELETE",
  UPDATE_CAMERA: "UPDATE",
  ADD_CAMERA: "ADD",
  ADD_EDGE_DEVICE: "ADD",
  ADD_METER_DEVICE: "ADD",
  UPDATE_EDGE_DEVICE: "UPDATE",
  UPDATE_METER_DEVICE: "UPDATE",
  DELETE_EDGE_DEVICE: "DELETE",
  DELETE_METER_DEVICE: "DELETE",
  VIEW_EDGE_DEVICE: "VIEW",
  VIEW_METER_DEVICE: "VIEW",
  UPDATE_SLOTS: "UPDATE SLOTS",
  UPDATE_PROPERTY_DETAILS: "UPDATE PROPERTY DETAILS",
  CREATE_SELF_SERVE_QUICK_PERMIT_SETUP: "CREATE SELF SERVE QUICK PERMIT_SETUP",
  UPDATE_SELF_SERVE_QUICK_PERMIT_SETUP: "UPDATE SELF SERVE QUICK PERMIT_SETUP",
  UPDATE_PARKING_PERMIT_SETUP: "UPDATE PARKING PERMIT_SETUP",
  ADD_PERMIT_PACKAGES: "ADD",
  VIEW_MANAGE_PERMIT_PACKAGES: "VIEW",
  ADD_CARD_DETAILS: "ADD CARD",
  UPDATE_PERMIT_PACKAGES: "UPDATE",
  ADD_UNIT_PERMIT_PACKAGES: "ADD",
  VIEW_UNIT_PERMIT_PACKAGES: "VIEW",
  UPDATE_UNIT_PERMIT_PACKAGES: "UPDATE",
  VIEW_PAID_PERMIT_HISTORY: "VIEW",
  CREATE_PAID_PERMIT_BY_DRIVER: "CREATE PAID PERMIT BY DRIVER",
  VIEW_DISCOUNT_COUPON_SUMMARY_REPORT: "VIEW",
  EXPORT_DISCOUNT_COUPON_SUMMARY_REPORT: "EXPORT",
  VIEW_BATCH_VALIDATION_REPORT: "VIEW",
  EXPORT_BATCH_VALIDATION_REPORT: "EXPORT",
  VIEW_GATE_SCHEDULER: "VIEW",
  ADD_GATE_SCHEDULER: "ADD",
  UPDATE_GATE_SCHEDULER: "EXPORT",
  GENERATE_COUPONS: "GENERATE COUPONS",
  VIEW_PARKING_RATE_CHECK: "VIEW",
  ADD_PASSAGEPASS_PAYMENT_TRANSACTION: "ADD",
  INSERT_PASSAGEPASS_PERMIT_HISTORY: "INSERT",
  UPDATE_PASSAGEPASS_PERMIT_HISTORY: "UPDATE",
  PASSAGEPASS_PERMIT_HISTORY: "ADD/UPDATE",
  SESSION_END: "SESSION END",
  SESSION_START: "SESSION START",
  START_END_SESSION: "START_END_SESSION",
  EXPORT_BATTERY_REPORT: "EXPORT",
  VIEW_BATTERY_REPORT: "VIEW",
  VIEW_LENGTH_OF_STAY_REPORT: "VIEW",
  EXPORT_LENGTH_OF_STAY_REPORT: "EXPORT",
  VIEW_METER_STATUS_REPORT: "VIEW",
  EXPORT_METER_STATUS_REPORT: "EXPORT",
  VIEW_LAST_TRANSACTION_REPORT: "VIEW",
  EXPORT_LAST_TRANSACTION_REPORT: "EXPORT",
  WAITLIST_POSITION_CHANGE: "UPDATE",
};

exports.highSecurityActionList = highSecurityActionList;

import React from "react";
import {
    Tooltip,
    OverlayTrigger,
    Button,
} from "react-bootstrap";

export const customRow = ({
    value,
    className = 'text-truncate text-nowrap pr-2',
    delay = { show: 250, hide: 400 },
    placement = 'top',
    onClick
}) => {
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <OverlayTrigger
                delay={delay}
                placement={placement}
                overlay={<Tooltip id={`tooltip-qw`}>{value}</Tooltip>}
            >
                <div className={className}>
                    {value}
                </div>
            </OverlayTrigger>
        </div>
    )
}

export const linkRow = ({
    active,
    value,
    link,
    className = 'text-truncate text-nowrap pr-2',
    target = '_blank'
}) => {
    return (
        <>
            {active ? (
                <>
                    <a
                        className={className}
                        href={link}
                        target={target}
                        rel="noreferrer"
                    >
                        {value}
                    </a>
                </>
            ) : (
                value
            )}
        </>
    )
}

export const buttonRow = ({
    active = true,
    value,
    className = '',
    variant = '',
    size = '',
    title = '',
    onClick
}) => {
    return (
        <>
            {
                active ? (
                    <Button
                        title={title}
                        className={className}
                        variant={variant}
                        size={size}
                        onClick={onClick}
                    >
                        {value}
                    </Button>
                ) : (
                    "-"
                )
            }
        </>
    )
}

export const checkBoxRow = ({
    active = true,
    value,
    onChange
}) => {
    return (
        <>
            {
                active ? (
                    <div>
                        <input
                            type="checkbox"
                            checked={value}
                            onChange={onChange}
                        />
                    </div>
                ) : (
                    "-"
                )
            }
        </>
    )
}

export const overlayTriggerRow = ({
    value,
    delay = { show: 250, hide: 400 },
    placement = 'top',
    className = "text-nowrap text-truncate pr-2",
    // style = { maxWidth: "140px" }
}) => {
    return (
        <>
            <OverlayTrigger
                delay={delay}
                placement={placement}
                overlay={<Tooltip id={`tooltip-qw`}>{value}</Tooltip>}
            >
                <div
                    className={className}
                    // style={style}
                >
                    {value}
                </div>
            </OverlayTrigger>
        </>
    )
}

const Types = {
  FETCH_NEW_REASON: "FETCH_NEW_REASON",
  FETCH_VIEW_LAST_TICKET: "FETCH_VIEW_LAST_TICKET",
  FETCH_TICKET_REASONS: "FETCH_TICKET_REASONS",
  FETCH_GENERATE_TICKET: "FETCH_GENERATE_TICKET",
  FETCH_TICKET_INFO: "FETCH_TICKET_INFO",
  FETCH_TICKET_LIST: "FETCH_TICKET_LIST",
  UPDATE_TICKET_LIST: "UPDATE_TICKET_LIST",
  UPDATE_TICKET_DATA: "UPDATE_TICKET_DATA",
  PAYMENT_STEP1: "PAYMENT_STEP1",
  PAYMENT_STEP3: "PAYMENT_STEP3",
  UPDATE_MESSAGE_LIST: "UPDATE_MESSAGE_LIST",
  FETCH_MESSAGE_LIST: "FETCH_MESSAGE_LIST",
  GET_TICKET_DETAILS: "GET_TICKET_DETAILS",
  UPDATE_STATUS: "UPDATE_STATUS",
  FETCH_PAYMENT_STATUS: "FETCH_PAYMENT_STATUS",
  UPDATE_TICKET_STATUS: "UPDATE_TICKET_STATUS",
  SELECT_LANG: "SELECT_LANG",
  FETCH_VIEW_LAST_PLATE_NUMBER: "FETCH_VIEW_LAST_PLATE_NUMBER",
  FETCH_RATE_PAYMENT_LIST: "FETCH_RATE_PAYMENT_LIST",
  FETCH_VEND_CONTROL_LIST: "FETCH_VEND_CONTROL_LIST",
  FETCH_VIN_WARNING_NUMBER: "FETCH_VIN_WARNING_NUMBER",
  FETCH_VIN_INFO: "FETCH_VIN_INFO",
  FETCH_TRANSACTIONS_LIST: "FETCH_TRANSACTIONS_LIST",
  FETCH_TOTAL_CITATIONS_LIST: "FETCH_TOTAL_CITATIONS_LIST",
  FETCH_TOTAL_PAID_UNPAID_LIST: "FETCH_TOTAL_PAID_UNPAID_LIST",
  FETCH_TOTAL_STATUS_LIST: "FETCH_TOTAL_STATUS_LIST",
  FETCH_TOTAL_ENFOCER_LIST: "FETCH_TOTAL_ENFOCER_LIST",
  FETCH_TOTAL_ENFOCER_PAID_LIST: "FETCH_TOTAL_ENFOCER_PAID_LIST",
  FETCH_QR_CODE: "FETCH_QR_CODE",
  FETCH_REFUND_LIST: "FETCH_REFUND_LIST",
  FETCH_EXPORT_LIST: "FETCH_EXPORT_LIST",
  FETCH_REASON_LIST: "FETCH_REASON_LIST",
  FETCH_PERMIT_TRANSACTIONS_LIST: "FETCH_PERMIT_TRANSACTIONS_LIST",
  FETCH_OFFLINE_CITATION_LIST: "FETCH_OFFLINE_CITATION_LIST",
  FETCH_SESSION_DATA: "FETCH_SESSION_DATA",
};

export default Types;

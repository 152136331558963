import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as SelfRegisterActions from "../../Actions/SelfRegisterActions/actions";
import * as ResidentsActions from "../../Actions/ResidentsActions/actions";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import CustomContentAlert, { phoneValidation, removeSpecialChar, routing_using_history } from "../helper";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import { getSelfRegisterDetails } from "../../Selectors/SelfRegisterDetails";
import NoteSelector from "./NoteSelector";
import t from "../../Actions/SelfRegisterActions/types";

const labelFontSize = { fontSize: "15px" };

const intiailFormData = {
  country_code: "+1",
  phone: "",
  firstName: "",
  lastName: "",
  lpn: "",
  note: "",
};

const intialErrorData = {
  phone: "",
  firstName: "",
  lastName: "",
  lpn: "",
};

const AddQuickPermit = ({ props }) => {
  const country_code_list = [
    { id: 1, text: "+1", value: "+1" },
    { id: 2, text: "+91", value: "+91" },
    { id: 3, text: "+44", value: "+44" },
    { id: 4, text: "+61", value: "+61" },
  ];
  const [error, setError] = useState("");
  const [formData, setformData] = useState(intiailFormData);
  const [validations, setValidations] = useState(intialErrorData);
  const [isSubmit, setIsSubmit] = useState(false);
  const apiError = useSelector(getAPIResponse);
  const { quickPermitSetupDetail, quickPermitDetail } = useSelector(getSelfRegisterDetails);

  const { id, permission_id } = useSelector((state) => state.UserProfileDetails);

  const dispatch = useDispatch();

  const validateFields = (data) => {
    const obj = {
      phone: phoneValidation(data),
      firstName: !data.firstName ? "Firstname can't be empty" : "",
      lastName: !data.lastName ? "Lastname can't be empty" : "",
      lpn: !data.lpn ? "LPN can't be empty" : "",
    };
    setValidations(obj);
    return !!Object.keys(obj).find((x) => obj[x].length > 0);
  };

  useEffect(() => {
    const property_id = props?.property_id;
    setformData({ ...formData, phone: props.phone || "", country_code: props.country_code });
    if (property_id) {
      dispatch(SelfRegisterActions.getPermitDataWithPhoneOrMemberID(props?.phone, props?.memberId, property_id));
      dispatch(SelfRegisterActions.getQuickPermitSetup(property_id));
    } else {
      routing_using_history("/qp");
    }
    return () => {
      setIsSubmit(false);
      setformData(intiailFormData);
      setValidations(intialErrorData);
      setError("");
      dispatch({ type: t.RESET_QUICK_PERMIT_DATA });
    };
  }, []);

  useEffect(() => {
    if (isSubmit) {
      if (apiError?.api_error_message) {
        setError(apiError?.api_error_message);
      } else {
        setformData(intiailFormData);
        routing_using_history(`/success-page`);
      }
    }
  }, [apiError]);

  useEffect(() => {
    if (quickPermitDetail && Object.keys(quickPermitDetail).length > 0) {
      setformData({
        country_code: quickPermitDetail?.country_code || props?.country_code || formData?.country_code,
        phone: quickPermitDetail.phone || props.phone || formData.phone,
        firstName: quickPermitDetail.firstName || formData.firstName,
        lastName: quickPermitDetail.lastName || formData.lastName,
        lpn: quickPermitDetail.plate || formData.plate,
        note: quickPermitDetail.note || formData.note,
      });
    }
  }, [quickPermitDetail]);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      let updatedValue;
      if (name === "country_code") {
        updatedValue = value;
      } else {
        updatedValue = removeSpecialChar(value);
      }
      if (name === "lpn") updatedValue = updatedValue?.toUpperCase();
      if (name === "phone") {
        updatedValue = updatedValue?.replace(/\D/gi, "");
      }
      if (name === "firstName" || name === "lastName") {
        updatedValue = updatedValue?.replace(/\d/g, "");
      }
      const obj = { ...formData, [name]: updatedValue };
      setformData(obj);
      setError("");
      if (isSubmit) validateFields(obj);
    },
    [formData, error, isSubmit],
  );

  const getUTCWT = useMemo(() => {
    const currentdate = new Date().toLocaleString("en-US", {
      timeZone: props.timezone,
    });
    const formatEndDate = moment(currentdate).format("YYYY-MM-DD");
    const endDateWithTime = `${formatEndDate} 23:59:59`;
    const formatedET = moment(endDateWithTime).format("YYYY-MM-DD HH:mm:SS");
    const convertedExpireTime = moment.tz(formatedET, props.timezone).format();
    return moment.tz(convertedExpireTime, "UTC").format();
  }, [props.timezone]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (validateFields(formData)) return;
    setError("");

    const obj = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      phone: formData.phone,
      country_code: formData.country_code,
      vehicle_license: formData.lpn,
      note: formData.note,
      suite_id: "Quick Permit",
      email: "",
      memberId: props?.memberId ? props?.memberId : "",
      self_server: 1,
      lot_id: props?.lot_id,
      call_when_here: 1,
    };
    const uid = permission_id === 2 ? id : "self";
    dispatch(ResidentsActions.visitorCreate(props?.property_id, "", getUTCWT, uid, obj, [props.lot_id], true, "", true));
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xl="6" lg="8" md="8" sm="12">
            {quickPermitSetupDetail?.show_id === 1 && (
              <>
                <Row>
                  <Col className="col-auto">
                    <Form.Group className="mb-3">
                      <label>Country Code:</label>
                      <Form.Control as="select" name="country_code" onChange={handleChange} value={formData.country_code}>
                        {country_code_list ? (
                          <>
                            {(country_code_list ?? []).map((item) => (
                              <option key={item?.id} value={item.value}>
                                {item.text}
                              </option>
                            ))}
                          </>
                        ) : null}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-1">
                      <label style={labelFontSize}>Mobile Phone</label>
                      <span className="fs-12px"> (numbers only)</span>
                      <span className="asterisk">*</span>
                      <Form.Control type="text" value={formData.phone} name="phone" maxLength={"10"} onChange={handleChange}></Form.Control>
                      {isSubmit && validations.phone && <div className="help-block">{validations.phone}</div>}
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            <Form.Group className="mb-1">
              <label style={labelFontSize}>First Name</label>
              <span className="fs-12px"> (alphabets and spaces only)</span>
              <span className="asterisk">*</span>
              <Form.Control type="text" value={formData.firstName} name="firstName" onChange={handleChange}></Form.Control>
              {isSubmit && validations.firstName && <div className="help-block">{validations.firstName}</div>}
            </Form.Group>
            <Form.Group className="mb-1">
              <label style={labelFontSize}>Last Name</label>
              <span className="fs-12px"> (alphabets and spaces only)</span>
              <span className="asterisk">*</span>
              <Form.Control type="text" value={formData.lastName} name="lastName" onChange={handleChange}></Form.Control>
              {isSubmit && validations.lastName && <div className="help-block">{validations.lastName}</div>}
            </Form.Group>
            <Form.Group className="mb-1">
              <label style={labelFontSize}>License Plate</label>
              <span className="fs-12px"> (alphabets and numbers only)</span>
              <span className="asterisk">*</span>
              <Form.Control type="text" value={formData.lpn} name="lpn" onChange={handleChange}></Form.Control>
              {isSubmit && validations.lpn && <div className="help-block">{validations.lpn}</div>}
            </Form.Group>
            {quickPermitSetupDetail?.self_server === 1 && (
              <Form.Group className="mb-1">
                <NoteSelector
                  setNote={(note) => setformData({ ...formData, note: note })}
                  note={formData.note}
                  quickPermitSetupDetail={quickPermitSetupDetail}
                  labelFontSize={labelFontSize}
                />
              </Form.Group>
            )}
          </Col>
        </Row>
        <Row>
          <Col xl="6" lg="8" md="8" sm="12">
            <Form.Group className="mb-5 mt-3">
              <Button name="submit" type="submit">
                SUBMIT
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </form>
      {error?.length ? <CustomContentAlert delay={4000} message={error} className="toast-error" /> : null}
    </>
  );
};

export default AddQuickPermit;

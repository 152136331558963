import apiType from "../APIResponseActions/type";
import { AuthAPI, UploadFormData, BufferAuthAPI, UnAuthUploadFormData } from "../../../Auth/requestHandler";
import { timestamp } from "../../Components/helper";
import t from "./types";
import { findStartDateAndEndDateForAllReports } from "../common";

export const scanPlate = (formData) => async (dispatch) => {
  try {
    const scan_time = timestamp();
    const url = "lpr/scan_plate/" + scan_time;
    const result = await UploadFormData("domain", url, "POST", formData);
    if (result?.success) {
      const tickets = result.extra && Object.keys(result.extra).length && result.extra.tickets && result.extra.tickets.length ? result.extra.tickets : [];
      await dispatch({
        type: t.FETCH_PALTE_VALIDITY,
        payload: {
          data: result.data,
          time_elapsed: result?.time_elapsed,
          scan_time: scan_time,
          isPlateApproved: true,
          tickets: tickets,
          lpn: result?.extra?.report_data?.lpn,
          end: result?.extra?.report_data?.end,
        },
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: result.message,
      });
    } else {
      if (result.status === 500) {
        dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: result.message });
      } else {
        await dispatch({
          type: t.FETCH_PALTE_VALIDITY,
          payload: {
            data: result?.extra?.report_data,
            time_elapsed: result?.time_elapsed,
            scan_time: scan_time,
            lpn: result?.extra?.report_data?.lpn,
            isPlateApproved: result?.extra?.report_data?.result === "Approved",
            tickets: result?.extra?.tickets,
          },
        });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: result?.message,
        });
      }
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const manuallyPlateCheck = (plateData) => async (dispatch) => {
  try {
    const { lpn, selectedLot, selectedStall } = plateData;
    let selectedStall1;
    if (!selectedStall) {
      selectedStall1 = null;
    } else {
      selectedStall1 = selectedStall;
    }
    const scan_time = timestamp();
    const url = "lpr/check/" + lpn + "/" + scan_time + "/" + selectedLot + "/" + selectedStall1;
    const result = await AuthAPI("domain", url, "GET");
    if (result?.success) {
      const tickets = result.extra && Object.keys(result.extra).length && result.extra.tickets && result.extra.tickets.length ? result.extra.tickets : [];
      await dispatch({
        type: t.FETCH_PALTE_VALIDITY,
        payload: {
          data: result.data,
          time_elapsed: result.time_elapsed,
          scan_time: scan_time,
          isPlateApproved: true,
          tickets: tickets,
          lpn: result?.extra?.report_data?.lpn,
          end: result?.extra?.report_data?.end,
        },
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: result.message,
      });
    } else {
      if (result.status === 500) {
        dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: result.message });
      } else {
        await dispatch({
          type: t.FETCH_PALTE_VALIDITY,
          payload: {
            data: result?.extra?.report_data,
            time_elapsed: result?.time_elapsed,
            scan_time: scan_time,
            isPlateApproved: result?.extra?.report_data?.result === "Approved",
            tickets: result?.extra?.tickets,
          },
        });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: result.message,
        });
      }
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const sendEmail = (formData) => async (dispatch) => {
  try {
    const url = "permit/email";
    const response = await UploadFormData("domain", url, "POST", formData);
    if (response?.data) {
      await dispatch({ type: t.UPDATE_TERNINAl, payload: response.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.msg,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getAuditReoprts = (property_id, lot_id, pageObj) => async (dispatch) => {
  try {
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    const body = {
      property_id,
      lot_id,
      timezone: pageObj.timezone,
    };
    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;
    const url = "lpr/audit_report";
    const response = await AuthAPI("domain", url, "POST", body);
    if (response) {
      await dispatch({ type: t.GET_AUDIT_REPORT, payload: response });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.msg,
      });
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: false,
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const get_user_lots = () => async (dispatch) => {
  try {
    const url = "user/list";
    const response = await AuthAPI("domain", url, "GET");
    if (response.success === 1) {
      await dispatch({ type: t.GETUSERLOTS, payload: response.lots });
      await dispatch({ type: t.GETALLUSERS, payload: response.users });
      await dispatch({ type: t.GET_OFFLINE_USER, payload: response?.offline_user });
      await dispatch({ type: t.CREATE_OR_UPDATE_OFFLINE_SESSIONS, payload: response?.offline_session });

      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: "fetch successfully",
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response?.message || "failed to fetch",
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "failed to fetch",
    });
  }
};

export const CreateOrUpdateOfflineUser = (data) => async (dispatch) => {
  try {
    const url = `ticket/add-lot-offline-users`;
    const response = await AuthAPI("domain", url, "POST", data);
    console.log("response on CreateOrUpdateOfflineUser", response);
    if (response?.success === 1) {
      await dispatch({ type: t.GET_OFFLINE_USER, payload: response?.data?.lot_offline_user });
      if (Object.keys(response.data?.offlineData).length) {
        console.log("permits: data:::111", response.data?.offlineData);
        await dispatch({ type: t.CREATE_OR_UPDATE_OFFLINE_SESSIONS, payload: response.data?.offline_session });
        await dispatch({ type: t.GETOFFLINEDATA, payload: response.data?.offlineData });
        dispatch({
          type: t.GET_CLOUD_TO_OFFLINE_SYNC,
          payload: true,
        });
      } else {
        await dispatch({ type: t.CREATE_OR_UPDATE_OFFLINE_SESSIONS, payload: {} });
        dispatch({
          type: t.GET_OFFLINE_TO_CLOUD_SYNC,
          payload: true,
        });
      }
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response?.message || "failed to fetch",
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "failed to fetch",
    });
  }
};

export const get_user_type = () => async (dispatch) => {
  try {
    const url = "user/type";
    const response = await AuthAPI("domain", url, "GET");

    await dispatch({ type: t.GETUSERTYPES, payload: response.users });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "fetch successfully",
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "failed to fetch",
    });
  }
};

export const getCitationIssuedSummary = (pageObj) => async (dispatch) => {
  try {
    const {
      timezone,
      // Norow,
      PropertyId,
      lot_id,
      childcheckbox,
      exportData,
    } = pageObj;
    const body = {
      PropertyId: PropertyId,
      timezone: timezone,
      exportValue: exportData || false,
      childcheckbox: childcheckbox || false,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);

    body.startDate = startDate;
    body.endDate = endDate;

    if (lot_id) {
      body.lot_id = lot_id;
    }

    const url = "ticket/citation-issued-summary";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_CITATION_ISSUED_SUMMARY,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getCitationIssuedByEnforcerSummary = (pageObj) => async (dispatch) => {
  try {
    const { timezone, PropertyId, childcheckbox, exportData } = pageObj;
    const body = {
      PropertyId: PropertyId,
      timezone: timezone,
      exportValue: exportData || false,
      childcheckbox: childcheckbox || false,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;

    const url = "ticket/citation-issued-enforcer-summary";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: error,
        });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_CITATION_ISSUED_SUMMARY,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getCitationPaidSummary = (pageObj) => async (dispatch) => {
  try {
    const { timezone, PropertyId, exportData, childcheckbox } = pageObj;
    const body = {
      timezone: timezone,
      PropertyId: PropertyId,
      exportValue: exportData || false,
      childcheckbox: childcheckbox || false,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;
    const url = "ticket/citation-paid-summary";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_CITATION_PAID_SUMMARY,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getCitationPaidPaymentSummary = (pageObj) => async (dispatch) => {
  try {
    const { timezone, PropertyId, exportData, childcheckbox } = pageObj;
    const body = {
      timezone: timezone,
      PropertyId: PropertyId,
      exportValue: exportData || false,
      childcheckbox: childcheckbox || false,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;
    const url = "ticket/citation-paid-payment-summary";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_CITATION_PAID_SUMMARY,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getRevenueList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      page_size: pageObj.Norow,
      page: pageObj.page,
      order_by: pageObj.selectedColumn,
      sorting: pageObj.sort_direction,
      PropertyId: pageObj.PropertyId,
      lot_id: pageObj.lot_id,
      unit_id: pageObj.unit_id,
      childcheckbox: pageObj.childcheckbox,
      exportLink: pageObj.exportLink,
      pdfLink: pageObj.pdfLink,
      selectedRadio: pageObj.selectedRadio,
      timezone: pageObj.timezone,
      type: pageObj.agent_id_type,
      status: pageObj.status,
      payment_type: pageObj.payment_type,
      completed: pageObj?.completed,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj, true);
    body.startDate = startDate;
    body.endDate = endDate;

    const url = "report/list";

    if (pageObj.exportLink || pageObj.pdfLink) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_REVENUE_REPORT_LIST,
          payload: response,
        });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: "",
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    console.error("error on revenue action:::", error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getRevenueSummary = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.PropertyId,
      lot_id: pageObj.lot_id,
      unit_id: pageObj.unit_id,
      exportLink: pageObj.exportLink,
      pdfLink: pageObj.pdfLink,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportData || false,
      status: pageObj?.status,
      childcheckbox: pageObj.childcheckbox || false,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj, true);
    body.startDate = startDate;
    body.endDate = endDate;

    const url = "report/summary/list";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_REVENUE_REPORT_SUMMARY_LIST,
          payload: response,
        });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: "",
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const scanPlateForGoogleGlass = (formData) => async (dispatch) => {
  try {
    const scan_time = timestamp();
    const url = "public/lpr/scan-glass/" + scan_time;
    const result = await UnAuthUploadFormData(url, "POST", formData);
    if (result?.success) {
      const tickets = result?.extra?.tickets?.length ? result.extra.tickets : [];
      await dispatch({
        type: t.FETCH_PALTE_VALIDITY,
        payload: {
          data: result.data,
          scan_time: scan_time,
          isPlateApproved: true,
          tickets: tickets,
          lpn: result?.extra?.report_data?.lpn,
          end: result?.extra?.report_data?.end,
        },
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: result.message,
      });
    } else {
      if (result.status === 500) {
        dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: result?.message });
      } else {
        await dispatch({
          type: t.FETCH_PALTE_VALIDITY,
          payload: {
            data: result?.extra?.report_data,
            scan_time: scan_time,
            lpn: result?.extra?.report_data?.lpn,
            isPlateApproved: result?.extra?.report_data?.result === "Approved",
            tickets: result?.extra?.tickets,
          },
        });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: result?.message,
        });
      }
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const FetchGracePermit = (property_id, lot_id, lpn) => async (dispatch) => {
  try {
    lot_id = lot_id || null;
    const url = "lpr/check_grace" + "/property_id/" + property_id + "/lot_id/" + lot_id + "/lpn/" + lpn;
    const result = await AuthAPI("domain", url, "GET");
    console.log("Result: ", result);
    await dispatch({ type: t.FETCH_CITATION_PERMIT_GRACE, payload: result });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: result.msg,
    });
    return result;
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};
import React, { Component } from "react";
import { Row, Card, Col, Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { SuperAdminAction } from "../../Actions";
import { loadPlayer } from "rtsp-relay/browser";
import { groupBy, isEmpty } from "lodash";
import openAction from "../../../assets/img/svg/live_feed_icons/live-feed-open.svg";
import closeAction from "../../../assets/img/svg/live_feed_icons/live-feed-close.svg";
import start from "../../../assets/img/svg/live_feed_icons/live-view-open-feed.svg";
import close from "../../../assets/img/svg/live_feed_icons/live-feed-exit-page.svg";
import SmartGateConfirmModal from "../Modal/smartGateModal";
class Remote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      api_response: false,
      property: this.props.PropertyId,
      propertyData: this.props.property,
      timezone: this.props.timezone,
      isSearchInputDisabled: true,
      isSearchButtonDisabled: true,
      show: false,
      selectedGate_no: "",
      selectedLane: "",
      url: "",
      streamData: [],
      liveStreamData: [],
      feedData: {},
      disableCloseButton: true,
      timer: null,
      action: "",
      data: {},
      smartGateModal: false,
      buttonDisable: false,
    };
  }

  componentDidMount = async () => {
    const selectedPropertyName = this.state.propertyData?.property_name;
    const selectedDomainName = this.state.propertyData?.domain;
    await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    const feedData = await this.props.getLiveFeed(this.props.PropertyId, "Remote");
    const groupFeed = groupBy(feedData, "gate_no");
    let url;

    const windowUrl = window?.location?.href.split("/");
    const baseUrl = windowUrl[0];
    if (baseUrl === "https:") {
      url = `${process.env.REACT_APP_WSS}${process.env.REACT_APP_MAIN_URL}:${process.env.REACT_APP_LIVEFEED_PORT}`;
    } else {
      url = `${process.env.REACT_APP_WSS}${process.env.REACT_APP_LIVEFEED_URL}`;
    }

    this.setState({
      url: url,
      feedData: groupFeed,
    });
  };

  smartGateOperationChange = async () => {
    this.setState({
      buttonDisable: true,
    });
    const { gate_no, domain, camera_ip, lane_alias, control_message_suffix } = this.state.data;
    const data = {
      action: this.state.action,
      gate_no: gate_no,
      property_id: this.state.property,
      domain: domain,
      camera_ip: camera_ip,
      lane_alias: lane_alias,
      control_message_suffix: control_message_suffix,
      type: "REMOTE",
    };
    await this.props.setRtsp(data);
    this.setState({
      smartGateModal: false,
      buttonDisable: false,
    });
  };

  rtspApiCall = async (action, e) => {
    this.setState(
      {
        action: action,
        data: e,
      },
      async () => {
        await this.modelOpen();
      },
    );
  };

  handleClose = async () => {
    console.log("Called again");
    const allPromices = [...this.state.streamData];
    clearTimeout(this.state.timer);
    this.setState({
      show: false,
      disableCloseButton: false,
      streamData: [],
      timer: null,
    });
    allPromices?.map(async (item) => {
      try {
        (await item).destroy();
        const streamData = {
          userId: this.props.id,
          propertyId: this.props.PropertyId,
          alias: this.state.liveStreamData[0].lane_alias,
          action: "CLOSE_STREAM",
          type: "REMOTE",
        };
        console.log("streamDataaaaa", streamData);
        await this.props.destroyStreamActivityLog(streamData);
      } catch (error) {
        console.log("Error while destroying live feed");
      }
    });
  };

  openStream = (e) => {
    const data = [];
    let Sdata;
    this.setState(
      {
        liveStreamData: e,
        show: true,
        disableCloseButton: true,
      },
      async () => {
        for (const i of this.state.liveStreamData) {
          const feedUrl = `${this.state.url}/api/stream/${i.id}/${this.props.id}/REMOTE`;
          Sdata = loadPlayer({
            url: `${feedUrl}`,
            canvas: document.getElementById(`canvas${i.id}`),
            onDisconnect: () => console.log("Connection lost!"),
          });
          data.push(Sdata);
        }
        this.setState({
          streamData: data,
          disableCloseButton: false,
        });
        clearTimeout(this.state.timer);
        this.setState({
          timer: setTimeout(() => {
            this.handleClose();
          }, 180000),
        });
      },
    );
  };

  modelOpen = async () => {
    this.setState({
      smartGateModal: true,
    });
  };

  render() {
    const { show, smartGateModal } = this.state;
    return (
      <div>
        <div fluid>
          <Row>
            <Col md="12">
              <Card className="mb-0">
                <Card.Body>
                  {(Object.values(this.state.feedData) ?? []).map((data, index) => {
                    return (
                      <div className="guest-section d-flex align-items-center mb-2" key={data[0]?.id}>
                        <h5 className="mb-0 w-100 min-width-modal">{data[0].lane_alias}</h5>
                        <div className="d-flex align-items-center">
                          <div id="ignoreButton" size={"sm"} type="button" className="ml-2 startButton" onClick={() => this.openStream(data)}>
                            <img className="ml-0 mr-1 customliveButton" src={start} width={25} />
                            <span>View</span>
                          </div>
                          <div>
                            <div className="d-flex align-items-center AllActionGroup">
                              <div className="d-flex align-items-center">
                                <div
                                  id="ignoreButton"
                                  // type="button"
                                  className="ml-2 mb-2 ActionButton AllActionGroupInner"
                                  onClick={() => this.rtspApiCall("OPEN", data[0])}
                                >
                                  <img className="ml-0 mr-1" src={openAction} width={27} />
                                  <span>Open</span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <div
                                  id="ignoreButton"
                                  // type="button"
                                  className="ml-2 mb-2 ActionButton AllActionGroupInner"
                                  onClick={() => this.rtspApiCall("CLOS", data[0])}
                                >
                                  <img className="ml-0 mr-1" src={closeAction} width={27} />
                                  <span>Close</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <Modal
                    show={show}
                    onHide={this.handleClose}
                    lg={"lg"}
                    backdrop={"static"}
                    dialogClassName="modal-90w modal-xl opacity: 0.8"
                    fullscreen={"sm-down"}
                    className="modalData"
                  >
                    <Modal.Header id="ignoreHeader" className="d-flex justify-content-between bg-white align-items-center">
                      <h4 className="m-0 font-weight-bold" style={{ marginBottom: 10 }}>
                        <Modal.Title as="h4" className="m-0">
                          Live Feed
                        </Modal.Title>
                      </h4>
                      <Button
                        id="ignoreButton"
                        type="button"
                        onClick={this.handleClose}
                        className="close-btn py-0"
                        data-dismiss="modal"
                        //  disabled={disableCloseButton}
                      >
                        <img className="ml-0 mr-1" src={close} width={30} />
                      </Button>
                    </Modal.Header>
                    <Modal.Body className="pt-0">
                      <Row>
                        {(this.state.liveStreamData ?? []).map((e, index) => {
                          return (
                            <Col lg={6} md={12} sm={12} xs={12} key={index}>
                              <div className="cameraStream">
                                <canvas id={`canvas${e.id}`} style={{ width: "100%", marginTop: 20 }} />
                              </div>
                            </Col>
                          );
                        })}
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <div className="d-flex align-items-center AllActionGroup">
                            <div>
                              <h5 className="mb-0 ml-3">{this.state.liveStreamData[0]?.lane_alias}</h5>
                            </div>
                            <div className="d-flex align-items-center">
                              <div
                                id="ignoreButton"
                                // type="button"
                                className="ml-2 mb-2 ActionButton AllActionGroupInner"
                                onClick={() => this.rtspApiCall("OPEN", this.state.liveStreamData[0])}
                              >
                                <img className="ml-0 mr-1" src={openAction} width={27} />
                                <span>Open</span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div
                                id="ignoreButton"
                                // type="button"
                                className="ml-2 mb-2 ActionButton AllActionGroupInner"
                                onClick={() => this.rtspApiCall("CLOS", this.state.liveStreamData[0])}
                              >
                                <img className="ml-0 mr-1" src={closeAction} width={27} />
                                <span>Close</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Modal>
                  {isEmpty(this.state.feedData) && <div className="citation-list-table"><div className="text-center py-3">There are no records to display</div></div>}
                </Card.Body>
              </Card>
              <SmartGateConfirmModal
                showBrowserModal={smartGateModal}
                smartGateLoader={this.state.smartGateLoader}
                closeModal={() =>
                  this.setState({
                    smartGateModal: !this.state.smartGateModal,
                  })
                }
                saveit={this.smartGateOperationChange}
                action={this.state.action}
                buttonDisable={this.state.buttonDisable}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = ({ SuperAdminDetails, APIResponseDetails, UserProfileDetails, EnforcementDetails }) => {
  const { userList, userCount: count, getAllUserlist, getRtspData } = SuperAdminDetails;
  const { api_response } = APIResponseDetails;
  const {
    property_id: PropertyId,
    // user_type,
    // suite_id,
    // timezone,
    id,
    property,
  } = UserProfileDetails;
  const { userlots } = EnforcementDetails;
  return {
    api_response,
    userList,
    count,
    getAllUserlist,
    PropertyId,
    getRtspData,
    property,
    userlots,
    id,
  };
};
const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  setRtsp: SuperAdminAction.setRtsp,
  getLiveFeed: SuperAdminAction.getLiveFeed,
  destroyStreamActivityLog: SuperAdminAction.destroyStreamActivityLog,
};

export default connect(mapStatetoProps, mapStateToDispatch)(Remote);

import t from "../../Actions/ValidationCouponActions/type";

const initialState = {
  merchantCodeList: [],
  couponPDF: {},
  couponValidationDetail: {},
  GetParkingDiscountData: {},
  loader: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case t.FETCH_MERCHANT_CODE: {
      return {
        ...state,
        merchantCodeList: action.payload || [],
      };
    }
    case t.SET_COUPON_PDF: {
      return {
        ...state,
        couponPDF: action.payload || {},
      };
    }
    case t.SET_COUPON_VALIDATION_RES: {
      return {
        ...state,
        couponValidationDetail: action.payload || {},
      };
    }
    case t.RESET_COUPON_VALIDATION_RES: {
      return {
        ...state,
        couponValidationDetail: {},
      };
    }
    case t.GET_DISCOUNT_RATE: {
      return {
        ...state,
        GetParkingDiscountData: action.payload,
      };
    }
    case t.SHOW_HIDE_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;

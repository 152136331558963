import t from "./type";
import { AuthAPI } from "../../../Auth/requestHandler";
import apiType from "../APIResponseActions/type";

export const fetch_cameras =
  (property_id, need_log = false) =>
  async (dispatch) => {
    try {
      const url =
        "camera/paginate?page_size=" +
        10 +
        "&page=" +
        1 +
        "&search=" +
        "" +
        "&order_by=" +
        "created_at" +
        "&sorting=" +
        "ASC" +
        "&property_id=" +
        property_id +
        `${need_log ? "&need_log=true" : ""}`;

      const result = await AuthAPI("domain", url, "GET");
      await dispatch({ type: t.FETCHCAMERAS, payload: result.rows });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: result.msg,
      });
    } catch (error) {
      await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
    }
  };

export const addCamera = (data) => async (dispatch) => {
  try {
    if (!data.ip_addr || !data.plate || !data.lane || !data.type) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: "Required fields can not be empty",
      });
    } else {
      const cameraData = {
        lane_id: data.lane.id,
        entry: data.lane.entry_lane,
        exit: data.lane.exit_lane,
        ip_addr: data.ip_addr,
        type: data.type,
        plate: data.plate,
      };
      const response = await AuthAPI("domain", "camera", "POST", cameraData);

      if (!response?.success) {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
        return;
      }
      if (response.action === "add") {
        await dispatch({
          type: t.ADDCAMERA,
          payload: response.data,
        });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: response.msg,
        });
      }
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const editCamera = (data) => async (dispatch) => {
  try {
    const url = "camera/" + data.id;
    const response = await AuthAPI("domain", url, "PATCH", data);
    await dispatch({ type: t.EDITCAMERA, payload: response.data });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const deleteCamera =
  ({ id, propertyId }) =>
  async (dispatch) => {
    try {
      const cameraId = {
        id: id,
        property_id: propertyId,
      };
      const response = await AuthAPI("domain", "camera", "DELETE", cameraId);
      if (response.success === 1) {
        await dispatch({ type: t.DELETECAMERA, payload: cameraId });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: response.message,
        });
      }
    } catch (error) {
      await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
    }
  };

export const getScheduler = (data) => async (dispatch) => {
  try {
    console.log("hello =>>>>>>>>>>>>>>>");
    const url = "user/get-scheduler";
    const result = await AuthAPI("domain", url, "POST", data);
    await dispatch({ type: t.GET_SCHEDULER_DATA, payload: result });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const fetch_Gates = (data) => async (dispatch) => {
  try {
    const url = "user/get-gate/" + data;
    await AuthAPI("domain", url, "GET");
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

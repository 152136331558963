import React, { Component } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap"; // Form
import { SuperAdminAction } from "../Actions";
import { connect } from "react-redux";
import defaultImage from "../../assets/img/plate-check.png";
import scan from "../../assets/img/svg/scan.svg";
import email from "../../assets/img/svg/email.svg";
import ticket from "../../assets/img/svg/ticket.svg";
import audit from "../../assets/img/svg/audit.svg";
import add_unit_permit from "../../assets/img/svg/add-unit-permit.svg";
import { isMobile } from "react-device-detect";
import { validations } from "../Components/helper"; // validation_message
import audioFileNotAllowed from "../../assets/sound/NotAllowed.mp3";
import audioFileAllowed from "../../assets/sound/Allowed.wav";
import Approved from "../../assets/img/svg/approved.svg";
import BellIcon from "../../assets/img/svg/bell.svg";

import Unknown from "../../assets/img/svg/unknown.svg";
import { Rejected } from "../Components/AuditReport/AuditReport.jsx";
import CitationModal from "./CitationModal";
const moment = require("moment-timezone");

class PlateCheck extends Component {
  constructor(props) {
    super();
    this.state = {
      image: "",
      lpn: "",
      isPlateApproved: false,
      plateData: null,
      scan_time: 0,
      email: "",
      property: "",
      domain: "",
      lastEntry: "",
      selectedLot: "",
      selectedLotName: "",
      tickets: [],
      show: false,
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location) {
      const image = this.props.location.state.image;
      const lpn = this.props.location.state.lpn;
      const email = this.props.location.state.email;
      const scan_time = this.props.location.state.scan_time;
      const isPlateApproved = this.props.location.state.isPlateApproved;
      const selectedLot = this.props.location.state.selectedLot;
      const selectedLotName = this.props.location?.state?.selectedLotName ? this.props.location.state.selectedLotName : "";
      const selectedLotCapacity = this.props.location?.state?.selectedLotCapacity ? this.props.location.state.selectedLotCapacity : "";
      const tickets = this.props.location?.state?.tickets?.length ? this.props.location.state.tickets : [];
      const isVisitorLot = this.props.location?.state?.isVisitorLot ? this.props.location.state.isVisitorLot : false;
      const result = this.props.location?.state?.result ? this.props.location.state.result : "";

      const _plateData = this.props.location.state.data;
      const plateData = _plateData && Object.keys(_plateData).length ? _plateData : null;
      let lastEntry = plateData?.last_scan_time ? plateData?.last_scan_time : "Not available";
      if (lastEntry !== "Not available") {
        lastEntry = moment(lastEntry).tz(this.props.timezone).format("lll");
      }
      const hours = Math.floor(plateData?.time_elapsed / 60);
      const hoursPart = hours > 0 ? `${hours} hours and ` : "";
      const remainingMinutes = plateData?.time_elapsed % 60;
      const time_elapsed = plateData?.time_elapsed ? `${hoursPart} ${remainingMinutes} minutes` : "0 minutes";
      this.setState({
        isPlateApproved: isPlateApproved,
        image: image,
        lpn: lpn,
        plateData: { ...plateData, timeElapsed: time_elapsed },
        scan_time: scan_time,
        email: email,
        lastEntry: lastEntry,
        selectedLot: selectedLot,
        selectedLotName: selectedLotName,
        selectedLotCapacity: selectedLotCapacity,
        isVisitorLot: isVisitorLot,
        tickets: tickets,
        result: result,
      });
    } else {
      const history = require("../history").default;
      history.push({
        pathname: `/photo-verify`,
        state: {
          selectedLot: this.state.selectedLot,
        },
      });
    }
    this.setState(
      {
        property: this.props.property?.property_name,
        domain: this.props.property?.domain,
      },
      () => {
        const selectedPropertyName = this.state.property;
        const selectedDomainName = this.state.domain;
        this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      },
    );
  }

  handleBeforeUnload = () => {
    const history = require("../history").default;
    history.replace({ ...history.location, state: undefined });
  };

  componentWillUnmount() {
    this.handleBeforeUnload();
  }

  handleCheckPlate = () => {
    const history = require("../history").default;
    history.push({
      pathname: `/current-occupancy`,
      search: "verify",
      state: {
        lpn: this.state.lpn,
        isPlateApproved: this.state.isPlateApproved,
        image: this.state.image,
        plateData: this.state.plateData,
        scan_time: this.state.scan_time,
        lastEntry: this.state.lastEntry,
      },
    });
  };

  handleRescan = () => {
    const history = require("../history").default;
    history.push({
      pathname: `/photo-verify`,
      state: {
        selectedLot: this.state.selectedLot,
        reScan: "re-scan",
      },
    });
  };

  sendEmail = () => {
    const history = require("../history").default;
    history.push({
      pathname: `/send-email`,
      state: {
        lpn: this.state.lpn,
        image: this.state.image,
        plateData: this.state.plateData,
        scan_time: this.state.scan_time,
        email: this.state.email,
      },
    });
  };

  addPermit = () => {
    const history = require("../history").default;
    history.push({
      pathname: `/visitor-permit`,
      state: {
        lpn: this.state.lpn,
        redirect_from: "plate-check",
        image: this.state.image,
        email: this.state.email,
        scan_time: this.state.scan_time,
        isPlateApproved: this.state.isPlateApproved,
      },
    });
  };

  addUnitPermit = () => {
    const history = require("../history").default;
    history.push({
      pathname: `/add-unit-permit`,
      state: {
        lpn: this.state.lpn,
        redirect_from: "plate-check",
        image: this.state.image,
        email: this.state.email,
        scan_time: this.state.scan_time,
        isPlateApproved: this.state.isPlateApproved,
      },
    });
  };

  handleChange = (e) => {
    this.setState({ lpn: e.target.value }, async () => {
      const { lpn } = this.state;
      const plateNumber = validations(e.target.name, "lpn", lpn);
      this.setState({
        lpn: plateNumber.value,
        validPlateNumber: plateNumber.validator,
      });
      if (lpn) {
        this.setState({
          isFormValid: true,
        });
      } else {
        this.setState({
          isFormValid: false,
        });
      }
    });
  };

  redirectToTicket = () => {
    const history = require("../history").default;
    history.push({
      pathname: "/citation",
      state: {
        lpn: this.state.lpn,
        image: this.state.image,
        plateData: this.state.plateData,
        scan_time: this.state.scan_time,
        email: this.state.email,
      },
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  modalOpen = () => {
    this.setState({
      show: true,
    });
  };

  render() {
    const { image, lpn, isPlateApproved, tickets } = this.state; // validPlateNumber
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <div>
                    <h3>
                      <b>Plate:</b> {lpn}
                    </h3>
                  </div>
                  {isPlateApproved ? (
                    <Row>
                      <Col lg={4}>
                        <div className="text-success sucess-content-section">
                          <img src={Approved} width="22" />
                          <span className={"ml-2"}>Approved</span>
                        </div>
                        <span>Selected Lot: {this.state?.selectedLotName}</span>
                        <div>
                          {this.state?.plateData?.sharedLot?.length > 0}
                          Shared by Lots:{" "}
                          {(this.state?.plateData?.sharedLot ?? []).map((item) =>
                            this.state?.plateData?.sharedLot?.length > 0 ? item?.alias + ". " : item?.alias,
                          )}
                        </div>
                        <div>
                          {this.state?.plateData?.allowedLot?.length > 0 && <img src={BellIcon} width="22"></img>}
                          Allowed Lots (Permit):{" "}
                          {(this.state?.plateData?.allowedLot ?? []).map((item) =>
                            this.state?.plateData?.allowedLot?.length > 0 ? item?.lots?.alias + ". " : item?.lots?.alias,
                          )}
                        </div>
                        {/* <div>Lot Capacity: {this.state?.selectedLotCapacity}</div> */}
                        <div>Visitor Lot: {this.state?.isVisitorLot ? "Yes" : "No"}</div>
                        <span>Last Entry Scan: {this.state.lastEntry}</span>
                      </Col>
                      <Col lg={6}>
                        <span>Permit Class: {this.state.plateData?.permit_class}</span>
                        <div>Stall Number: {this.state.plateData?.stall_id}</div>
                        <div>Source: {this.state.plateData?.source}</div>
                        <div className="d-flex">
                          Tickets:
                          {tickets?.length
                            ? tickets.map((item, index) => (
                                <div key={item?.id} style={{ marginLeft: 3 }}>
                                  <label onClick={this.modalOpen} className="curserPointer">
                                    {item.ticket_no}
                                    {tickets.length === index + 1 ? "" : ","}
                                  </label>
                                </div>
                              ))
                            : "Not Available"}
                        </div>
                        <div>End date: {this.props.location?.state?.end}</div>
                        <div>Time in lot: {this.state?.plateData?.timeElapsed}</div>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <div className="text-danger error-content-section">
                        {this.state.result === "unknown" ? <img src={Unknown} width="22" /> : <Rejected />}
                        <span className={"ml-2"}>{this.state.result ? this.state.result : "Not Approved"}</span>
                      </div>
                      <Row>
                        <Col lg={3} md={3} sm={6} xs={12}>
                          <span className="">Selected Lot: {this.state?.selectedLotName}</span>
                          <div>
                            {this.state?.plateData?.sharedLot?.length > 0}
                            Shared by Lots:{" "}
                            {(this.state?.plateData?.sharedLot ?? []).map((item) =>
                              this.state?.plateData?.sharedLot?.length > 0 ? item?.alias + ". " : item?.alias,
                            )}
                          </div>
                          <div className="mt-1">
                            {this.state?.plateData?.allowedLot?.length > 0 && <img src={BellIcon} className="mr-2" width="22"></img>}
                            Allowed Lots (Permit):{" "}
                            {(this.state?.plateData?.allowedLot ?? []).map((item) =>
                              this.state?.plateData?.allowedLot?.length > 0 ? item?.lots?.alias + ". " : item?.lots?.alias,
                            )}
                          </div>
                          {/* <div>Lot Capacity: {this.state?.selectedLotCapacity}</div> */}
                          <div>Visitor Lot: {this.state?.isVisitorLot ? "Yes" : "No"}</div>
                          <span>Last Entry Scan: {this.state?.lastEntry}</span>
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={12}>
                          <span>Permit Class: {this.state.plateData?.permit_class}</span>
                          <div>Stall Number: {this.state.plateData?.stall_id}</div>

                          <div>Source: {this.state.plateData?.source}</div>

                          <div className="d-flex">
                            Tickets:{" "}
                            {tickets?.length
                              ? tickets.map((item, index) => (
                                  <div key={item?.id} style={{ marginLeft: 3 }}>
                                    <label onClick={this.modalOpen} className="curserPointer">
                                      {item.ticket_no}
                                      {tickets.length === index + 1 ? "" : ","}
                                    </label>
                                  </div>
                                ))
                              : "Not Available"}
                          </div>
                          <div>End date: {this.props.location?.state?.end}</div>
                          <div>Time in lot: {this.state?.plateData?.timeElapsed}</div>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card.Header>
                <Card.Body>
                  <div>
                    <audio
                      id="audio"
                      style={{ display: "none" }}
                      controls
                      // autoPlay={true}
                      preload
                    >
                      <source src={isPlateApproved ? audioFileAllowed : audioFileNotAllowed}></source>
                    </audio>
                  </div>
                  {!isPlateApproved && (
                    <Row>
                      <Col lg="12" className="pr-md-1 center text-center">
                        <Button className="mr-2" onClick={this.redirectToTicket}>
                          <img src={ticket} width="18" />
                          {!isMobile && <span className="pl-2">Citation</span>}
                        </Button>
                        <Button onClick={this.handleCheckPlate} className="mr-2">
                          <img src={audit} width="18" />
                          {!isMobile && <span className="pl-2">Plate Audit</span>}
                        </Button>
                        <Button onClick={this.sendEmail} className="mr-2">
                          <img src={email} width="18" />
                          {!isMobile && <span className="pl-2">Send Email</span>}
                        </Button>
                        <Button onClick={this.addUnitPermit} className="mr-2">
                          <img src={add_unit_permit} width="18" />
                          {!isMobile && <span className="pl-2">Add Permit</span>}
                        </Button>
                        <Button onClick={this.addPermit} className="mr-2">
                          <i className="fas fa-user-plus" width="18" />
                          {/* <img src={add_unit_permit} width="18" /> */}
                          {!isMobile && <span className="pl-2">Add Visitor</span>}
                        </Button>
                      </Col>
                      {/*   <Col lg="3" className="pr-md-1 center">
                        <Button onClick={this.handleCheckPlate}>
                          <img src={plate_check} width="18" />
                          {!isMobile && <span className="pl-2">Plate Check</span>}
                        </Button>
                      </Col>
                      <Col lg="3" className="pr-md-1 center">
                        <Button onClick={this.addPermit}>
                          <img src={add_unit_permit} width="18" />
                          {!isMobile && <span className="pl-2">Add Permit</span>}
                        </Button>
                      </Col>
                      <Col lg="3" className="pr-md-1 center">
                        <Button onClick={this.sendEmail}>
                          <img src={email} width="18" />
                          {!isMobile && <span className="pl-2">Send Email</span>}
                        </Button>
                      </Col> */}
                    </Row>
                  )}
                  <Row className="scanned-image top-space">
                    <Col lg="12" className="pr-md-1 center">
                      <img src={image ? this.state.image : defaultImage} alt="" className="image-preview" accept="image/*" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="5" md="8" sm="8" xs="12" className="pr-md-1 center">
                      <Button onClick={this.handleRescan}>
                        <img src={scan} width="18" />
                        {!isMobile && <span className="pl-2">Re-scan</span>}
                      </Button>
                      {isPlateApproved && (
                        <Button className="ml-2" onClick={this.handleCheckPlate}>
                          <img src={audit} width="18" />
                          {!isMobile && <span className="pl-2">Plate Audit</span>}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {this.state.show && (
          <CitationModal
            show={this.state.show}
            handleClose={this.handleClose}
            tickets={this.state.tickets}
            PropertyId={this.props.PropertyId}
            userId={this.props.id}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails }) => {
  const { property_id: PropertyId, timezone, property, id } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  return {
    PropertyId,
    timezone,
    property,
    main_body,
    id,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(PlateCheck);

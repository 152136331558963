import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { PermitActions } from "../../Actions";
import { useDispatch, useSelector } from "react-redux";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import { Translation } from "react-i18next";

const SendRefundModal = ({ closeModal, data, permitData }) => {
  const [apiCalled, setApiCalled] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const returnRefundedAmount = () => {
    if (data?.permit_class === "PermitPackage" || data?.permit_class === "Visitor") {
      return data.paid_amount;
    } else {
      return data.fee;
    }
  };
  const [state, setState] = React.useState({
    selectAction: "partial",
    amount: null,
    reason: "",
    reasonError: "",
    amountError: "",
    paidFee: returnRefundedAmount(),
    disableSelectAction: false,
  });
  const dispatch = useDispatch();
  const [refundFormValid, setRefundFormValid] = useState(true);
  const apiResponse = useSelector(getAPIResponse);

  const refundSubmit = async () => {
    const refundData = {
      amount: state.selectAction === "partial" ? parseFloat(state.amount).toFixed(2) : state.paidFee,
      reason: state.reason,
      refundAction: state.selectAction,
      property_id: data.property_id,
      permit_id: data.id,
      transaction_id: permitData.transaction_id,
      permit_class: data.permit_class,
    };
    dispatch(PermitActions.createPassagePassRefund(refundData));
    setApiCalled(true);
  };

  const blockInvalidChar = (e) => {
    return ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const handleChange = useCallback(
    (e) => {
      if (e.target.name === "amount") {
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: +e.target.value >= 0 ? e.target.value.replace(/[^\d.]/g, "") : "",
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      }
      if (e.target.name === "reason" && !e.target.value) {
        setState((prevState) => ({
          ...prevState,
          reasonError: "Reason is required",
        }));
      } else if (e.target.name === "reason" && e.target.value) {
        setState((prevState) => ({
          ...prevState,
          reasonError: "",
        }));
      }

      if (e.target.name === "amount" && !e.target.value) {
        setState((prevState) => ({
          ...prevState,
          amountError: "Amount is required",
        }));
      } else if (e.target.name === "amount" && e.target.value) {
        setState((prevState) => ({
          ...prevState,
          amountError: "",
        }));
      }
      if (e.target.value === "full" || e.target.value === "void") {
        setState((prevState) => ({
          ...prevState,
          amount: null,
        }));
      }
    },

    [state.amount, state.amountError, state.reasonError],
  );

  useEffect(() => {
    if (apiCalled === true) {
      setApiCalled(false);
      setIsSuccess(apiResponse?.api_response === true);
      setIsFailed(apiResponse?.api_response === false);
      setTimeout(() => {
        closeModal();
        setIsSuccess(false);
        setIsFailed(false);
        console.log(isFailed, isSuccess);
      }, 2000);
    }
  }, [apiResponse]);

  React.useEffect(() => {
    if (permitData && permitData.refund_type === "Refund") {
      if (state.selectAction === "partial" ? state.amount && state.reason : state.reason || +state.amount !== 0) {
        setRefundFormValid(false);
      } else {
        setRefundFormValid(true);
      }

      if (+state.amount >= +state.paidFee) {
        setState((prevState) => ({
          ...prevState,
          amountError: `Please enter valid amount(less than ${state.paidFee}).`,
        }));
        setRefundFormValid(true);
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        selectAction: "void",
        disableSelectAction: true,
      }));
      if (state.selectAction === "void" && state.reason) {
        setRefundFormValid(false);
      } else {
        setRefundFormValid(true);
      }
    }
  }, [state.amount, state.reason, state.amountError, state.paidFee, permitData]);

  return (
    <React.Fragment>
      <Modal
        id="ticketdetails"
        lg={"medium"}
        show={true}
        // onHide={closeModal}
        dialogClassName="modal-90w"
        fullscreen={"sm-down"}
        className="modalData"
      >
        <Modal.Header id="import" className="d-flex justify-content-between bg-white align-items-center">
          <h5 className="m-0 font-weight-bold">
            <Modal.Title className="m-0">
              <Translation>{(t) => <>{t(`Send Refund`)}</>}</Translation>
            </Modal.Title>
          </h5>
          <Button id="modal" title={"Ticket"} type="button" onClick={closeModal} className="close-btn py-0" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form>
            <Form.Label>
              <Translation>{(t) => <>{t(`Refund`)}</>}</Translation>
              Action <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              custom
              value={state.selectAction}
              name="selectAction"
              onChange={handleChange}
              className="fs-14 h-40"
              disabled={state.disableSelectAction}
            >
              <Translation>{(t) => <option value="partial">{t("Partial Refund")}</option>}</Translation>
              <Translation>{(t) => <option value="full">{t("Full Refund")}</option>}</Translation>
              {permitData.refund_type !== "Refund" && <option value="void">Void</option>}
            </Form.Control>
            {state.selectAction === "partial" && (
              <div>
                <Form.Label>
                  <Translation>{(t) => <>{t(`Amount`)}</>}</Translation>
                  <span className="asterisk">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={state.amount}
                  onChange={handleChange}
                  onKeyDown={blockInvalidChar}

                  //  max={state.paidFee}
                  min={0}
                  autoComplete="off"
                  required
                />
                <span className="help-block">{state.amountError}</span>
              </div>
            )}
            <Form.Label>
              <Translation>{(t) => <>{t(`Reason`)}</>}</Translation>
              <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control type="text" name="reason" value={state.reason} onChange={handleChange} autoComplete="off" required />
            <span className="help-block">{state.reasonError}</span>
          </Form>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button disabled={refundFormValid} onClick={refundSubmit}>
            <Translation>{(t) => <>{t(`Submit`)}</>}</Translation>
          </Button>
          <Button onClick={closeModal}>
            <Translation>{(t) => <>{t(`Cancel`)}</>}</Translation>
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default SendRefundModal;

import React, { Component } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { Translation } from "react-i18next";

class ConfirmModal extends Component {
  render() {
    const { showBrowserModal, closeModal, saveit, deactivateLoader } = this.props;
    return (
      <Modal lg={"medium"} show={showBrowserModal} onHide={closeModal}>
        <Modal.Header className="no-border">
          <h4 className="m-0 font-weight-bold">
            <Translation>{(t) => <>{t("Deactivate")} </>}</Translation>
          </h4>
          <Translation>
            {(t) => (
              <Button title={t("Deactivate")} type="button" onClick={closeModal} className="close-btn" data-dismiss="modal">
                <i className="nc-icon nc-simple-remove"></i>
              </Button>
            )}
          </Translation>
        </Modal.Header>

        <Modal.Body className="modal-data-body">
          <p className="theme-color">
            <Translation>{(t) => <>{t("Are you sure you want to Deactivate?")} </>}</Translation>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ minWidth: "93px" }} onClick={closeModal} type="button" className="btn btn-danger btn-cancel">
            <Translation>{(t) => <>{t("No")} </>}</Translation>
          </Button>
          {!deactivateLoader ? (
            <Button style={{ minWidth: "93px" }} onClick={saveit} type="button" className="btn btn-fill btn-info">
              <Translation>{(t) => <>{t("Yes")} </>}</Translation>
            </Button>
          ) : (
            <Spinner animation="border" variant="info" />
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmModal;

import translationEN from "../../../Translation/en/translation.json";
import translationFN from "../../../Translation/fn/translation.json";
import translationSp from "../../../Translation/sp/translation.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: translationEN,
  },
  fn: {
    translation: translationFN,
  },
  sp: {
    translation: translationSp,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;

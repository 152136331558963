import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { Translation } from "react-i18next";
import { getBookingSlotDateInFrenchLanguage } from "../language";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { DeleteCreditCardDetails, getCardDetails } from "../../Actions/PermitOptionsActions/actions";
import CustomContentAlert, { validateCardNumber, isEmailValid, removeSpecialChar } from "../helper";
import DeleteCardModal from "./DeleteCardModal";

const ConfirmReservation = ({ showBrowserModal, closeModal, confirm_data, onSubmit, isButtonDisable, unit_language }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    isCardEdit: true,
    disable_card_options: false,
    card_details_id: "",
    card_name: "",
    card_number: "",
    card_expiry: "",
    card_cvv: "",
    show_delete_card_model: false,
  });
  const { api_success_message, api_error_message } = useSelector((state) => state.APIResponseDetails);

  const cardDetailData = useSelector((state) => state.PermitOptionsDetails.cardDetails);

  useEffect(() => {
    if (api_success_message === "Card Details deleted successfully!") {
      setFormData({
        ...formData,
        show_delete_card_model: false,
      });
      dispatch(getCardDetails(confirm_data?.propertyId, confirm_data?.suite_id));
    }
  }, [api_success_message, api_error_message]);

  console.log("driver_credit_card:111111111111", cardDetailData);

  useEffect(() => {
    console.log("driver_credit_card:111111111111", cardDetailData);
    let initialObj = {};
    if (cardDetailData && Object.keys(cardDetailData).length > 0) {
      initialObj = {
        isCardEdit: false,
        disable_card_options: true,
        card_details_id: cardDetailData.id,
        card_name: cardDetailData.name,
        card_email: cardDetailData?.email,
        card_number: cardDetailData.card_number, // `************${driver_credit_card.last_four_card_number}`,
        card_expiry: cardDetailData.expiry_date,
      };
    } else {
      initialObj = {
        isCardEdit: true,
        card_name: "",
        card_number: "",
        disable_card_options: !formData.disable_card_options,
        card_email: "",
        card_expiry: "",
        card_cvv: "",
        card_details_id: "",
      };
    }
    setFormData({ ...formData, ...initialObj });
  }, [cardDetailData]);

  const handleChange = async ({ target }) => {
    let { name, value } = target;

    let newValue = !["card_email"].includes(name) ? removeSpecialChar(value) : value;

    let Obj = { ...formData };

    if (["card_number", "card_cvv"].includes(name)) {
      newValue = newValue.replace(/[^0-9.]/g, "");
    }
    if (["lpn"].includes(name)) {
      newValue = newValue.toUpperCase().replace(/\s/g, "");
    }
    Obj = { ...Obj, [name]: newValue };

    const mainObj = { ...formData, ...Obj };
    setFormData(mainObj);
    checkValidationsOnChange(target);
  };

  const editCardDetails = () => {
    setFormData((prevState) => ({
      ...prevState,
      disable_card_options: !formData.disable_card_options,
      card_name: formData.card_name,
      card_email: formData.card_email,
      card_number: null,
      card_expiry: "",
      card_cvv: "",
      isCardEdit: true,
    }));
  };

  const handleCalender = (date) => {
    const errors = {};
    errors.card_expiry = "";
    if (date) {
      setFormData((prevState) => ({
        ...prevState,
        card_expiry: date,
        errors: { ...prevState.errors, ...errors },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        card_expiry: "",
      }));
    }
  };

  const checkValidationsOnChange = useCallback(
    async (target) => {
      let is_form_valid = true;
      let errors = {};
      if (!target.value.toString().length) {
        is_form_valid = false;
        errors[target.name] = <Translation>{(t) => <>{t(`${_.startCase(target.name).split("_").join(" ")} can't be empty`)}</>}</Translation>;
      } else {
        is_form_valid = true;
        errors[target.name] = "";
      }

      if (target.name === "card_number") {
        const { error, valid } = await card_number_validation(target);
        errors = {
          ...errors,
          ...error,
        };
        is_form_valid = valid;
      }

      if (target.name === "card_cvv") {
        const { error, valid } = await card_cvv_validation(target);
        errors = {
          ...errors,
          ...error,
        };
        is_form_valid = valid;
      }

      setFormData((prevState) => ({
        ...prevState,
        is_form_valid: is_form_valid,
        errors: { ...prevState.errors, ...errors },
      }));
      return is_form_valid;
    },
    [formData],
  );

  const card_number_validation = async (target) => {
    let is_form_valid = true;
    const errors = {};
    const cardData = !/^\d+$/.test(target.value);
    if (!cardData && target.value.length <= 16) {
      if (target.value.length > 14) {
        const validCard = await validateCardNumber(target.value);
        if (validCard) {
          errors.card_number = "";
        } else {
          is_form_valid = false;
          errors.card_number = "Invalid Card Number";
        }
      } else {
        is_form_valid = false;
        errors.card_number = "Invalid Card Number. Enter at least 16 digits.";
      }
    }
    return {
      error: errors,
      valid: is_form_valid,
    };
  };

  const checkValidations = () => {
    const { card_number, card_expiry, card_cvv, card_name, isCardEdit, card_email } = formData;
    let is_form_valid = true;
    let errors = {};

    const { error, valid } = cardValidation(card_number, card_expiry, card_cvv, card_name, card_email, isCardEdit);
    errors = {
      ...errors,
      ...error,
    };
    is_form_valid = valid;

    setFormData({
      ...formData,
      is_form_valid: is_form_valid,
      errors: errors,
    });
    return is_form_valid;
  };

  const cardValidation = (card_number, card_expiry, card_cvv, card_name, card_email, isCardEdit) => {
    const errors = {};
    let is_form_valid = true;
    if (!card_name?.toString().length) {
      is_form_valid = false;
      errors.card_name = <Translation>{(t) => <>{t(`Card Holder Name can't be empty`)}</>}</Translation>;
    }

    if (!card_number?.toString().length) {
      is_form_valid = false;
      errors.card_number = <Translation>{(t) => <>{t(`Card Number can't be empty`)}</>}</Translation>;
    }
    if (!card_expiry.toString().length) {
      is_form_valid = false;
      errors.card_expiry = <Translation>{(t) => <>{t(`Card Expiry date can't be empty`)}</>}</Translation>;
    }
    if (isCardEdit) {
      if (!card_cvv.toString().length) {
        is_form_valid = false;
        errors.card_cvv = <Translation>{(t) => <>{t(`CVV can't be empty`)}</>}</Translation>;
      }
    }
    if (!card_email.toString().length || !isEmailValid(card_email)) {
      is_form_valid = false;
      errors.card_email = <Translation>{(t) => <>{t(`Email can't be empty`)}</>}</Translation>;
    }

    return {
      error: errors,
      valid: is_form_valid,
    };
  };

  const showError = (type) => {
    const { isCardEdit } = formData;
    let valid = true;
    let errorMsg = "";
    if (type === "card_email" && formData.errors?.card_email) {
      valid = false;
      errorMsg = formData.errors.card_email;
    }

    if (type === "card_name" && formData.errors?.card_name) {
      valid = false;
      errorMsg = formData.errors.card_name;
    }

    if (type === "card_number" && formData.errors?.card_number) {
      valid = false;
      errorMsg = formData.errors.card_number;
    }
    if (type === "card_expiry" && formData.errors?.card_expiry) {
      valid = false;
      errorMsg = formData.errors.card_expiry;
    }
    if (isCardEdit) {
      if (type === "card_cvv" && formData.errors?.card_cvv) {
        valid = false;
        errorMsg = formData.errors.card_cvv;
      }
    }
    if (!valid) {
      return <div className="help-block">{errorMsg}</div>;
    }
    return true;
  };

  const card_cvv_validation = async (target) => {
    const cvvdata = !/^\d+$/.test(target.value);
    let is_form_valid = true;
    const errors = {};
    if ((!cvvdata && target.value.length <= 4) || target.value.length === 0) {
      if (target.value.length >= 3) {
        errors.card_cvv = "";
      } else if (target.value.length > 1) {
        errors.card_cvv = <Translation>{(t) => <>{t("Invalid CVV. Enter at least 3 digits.")}</>}</Translation>;
        is_form_valid = false;
      } else {
        errors.card_cvv = "";
      }
    }
    return {
      error: errors,
      valid: is_form_valid,
    };
  };

  const localSubmit = () => {
    if (confirm_data.leftCredit < confirm_data.usedCredits && confirm_data.visitor_credit_payment && confirm_data.permits_allowed_type !== 2) {
      if (checkValidations()) {
        console.log("formData on card save:::1111", formData);
        onSubmit(formData);
      }
    } else {
      onSubmit();
    }
  };

  const handleShowDeleteCardModal = () => {
    setFormData({
      ...formData,
      isDeleteCard: false,
      show_delete_card_model: !formData.show_delete_card_model,
    });
  };

  const deleteCardDetails = () => {
    setFormData((prevState) => ({
      ...prevState,
      show_delete_card_model: true,
      isDeleteCard: false,
    }));
  };

  const handleDeleteCard = () => {
    setFormData((prevState) => ({
      ...prevState,
      isDeleteCard: true,
      show_delete_card_model: false,
    }));

    dispatch(DeleteCreditCardDetails(formData.card_details_id));
  };

  return (
    <Modal lg={"medium"} show={showBrowserModal} onHide={closeModal}>
      <Modal.Header className="no-border">
        <h4 className="m-0 font-weight-bold">
          <Translation>{(t) => <>{t(confirm_data.title)}</>}</Translation>
        </h4>
        <Translation>
          {(t) => (
            <Button title={t("Cancel")} type="button" onClick={closeModal} className="close-btn" data-dismiss="modal">
              <i className="nc-icon nc-simple-remove"></i>
            </Button>
          )}
        </Translation>
      </Modal.Header>

      <Modal.Body className="modal-data-body">
        <p>
          <Translation>{(t) => <>{t("For")}</>}</Translation> {confirm_data.firstName} {confirm_data.lastName},{" "}
          <Translation>{(t) => <>{t("PlateNumber")}</>}</Translation> {confirm_data.plateNumber}
        </p>
        <p>
          <Translation>{(t) => <>{t("CreditsUsed")}</>}</Translation> : {confirm_data.usedCredits}
        </p>

        {confirm_data.leftCredit < confirm_data.usedCredits && confirm_data.visitor_credit_payment && confirm_data.permits_allowed_type !== 2 && (
          <>
            <p>
              <Translation>{(t) => <>{t("Free Credit Left")}</>}</Translation> : {+confirm_data.leftCredit <= 0 ? 0 : confirm_data.leftCredit}
            </p>
            <p>
              <Translation>{(t) => <>{t("You can make this reservation by paying")}</>}</Translation>
              <b>{` $${parseFloat(confirm_data.usedCredits - (confirm_data.leftCredit <= 0 ? 0 : confirm_data.leftCredit)).toFixed(2)}`}</b>
              <Translation>{(t) => <>{t(" for more credits")}</>}</Translation>.
              <Translation>{(t) => <>{t(" Please enter your credit card information below")}</>}</Translation> :{" "}
            </p>
            {!formData.isCardEdit ? (
              <Row>
                <div className="mb-2 mt-3 change-credit-card-box">
                  <div className="mt-2">
                    <Row>
                      <Col lg="6">
                        <div className="form-group mb-3">
                          <label>
                            <Translation>{(t) => <>{t("Card Holder Name")}</>}</Translation>
                            <span className="asterisk">*</span>
                          </label>
                          <div className="d-flex align-items-center position-relative">
                            <input
                              required={true}
                              type="text"
                              className="form-control pe-5"
                              name="card_name"
                              value={formData.card_name || ""}
                              onChange={handleChange}
                              disabled={formData.disable_card_options}
                            />
                            <div className="payment-formicon">
                              <span className="fa fa-user" aria-hidden="true" />
                            </div>
                          </div>
                          {showError("card_name")}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group mb-3">
                          <label className="mb-0">
                            <Translation>{(t) => <>{t("Email")}</>}</Translation>
                            <span className="asterisk ml-1">*</span>
                          </label>
                          <div className="d-flex align-items-center position-relative">
                            <input
                              required
                              type="email"
                              className="form-control"
                              name="card_email"
                              value={formData.card_email}
                              onChange={handleChange}
                              disabled={formData.disable_card_options}
                            />
                            <div className="payment-formicon">
                              <span className="fa fa-envelope" aria-hidden="true" />
                            </div>
                          </div>
                          {showError("card_email")}
                        </div>
                      </Col>
                    </Row>
                    <div className="form-group mb-3">
                      <label>
                        <Translation>{(t) => <>{t("Credit Card number")}</>}</Translation>
                        <span className="asterisk">*</span>
                      </label>
                      <div className="d-flex align-items-center position-relative">
                        <input
                          type="text"
                          required={true}
                          className="form-control pe-5"
                          name="card_number"
                          value={formData.card_number}
                          onChange={handleChange}
                          maxLength={16}
                          disabled={formData.disable_card_options}
                        />
                        <div className="payment-formicon">
                          <span className="fa fa-credit-card" aria-hidden="true" />
                        </div>
                      </div>
                      {showError("card_number")}
                    </div>

                    <div className="form-group mb-2">
                      <Row>
                        <Col lg="12">
                          <div className="form-group mb-3">
                            <label>
                              <Translation>{(t) => <>{t("Expiration Date")}</>}</Translation>
                              <span className="asterisk">*</span>
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <input
                                type="text"
                                required={true}
                                className="form-control"
                                name="card_expiry"
                                value={formData.card_expiry}
                                onChange={handleChange}
                                disabled={formData.disable_card_options}
                              />
                              <div className="payment-formicon">
                                <span className="fa fa-calendar" style={{ cursor: "pointer" }} aria-hidden="true" />
                              </div>
                            </div>
                            {showError("card_expiry")}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end gap-3">
                    <div>
                      <Button className="btn-fill btn-sm mb-2" type="button" variant="info" onClick={() => editCardDetails()}>
                        <span className="pe-2">
                          <i className="far fa-edit"></i>
                        </span>
                        <Translation>{(t) => <>{t("Edit Card")}</>}</Translation>
                      </Button>
                    </div>
                    <div>
                      <Button className="btn-fill btn-sm mb-2 ml-2" type="button" variant="danger" onClick={() => deleteCardDetails()}>
                        <span className="pe-2">
                          <i className="far fa-trash-alt"></i>
                        </span>
                        <Translation>{(t) => <>{t("Delete Card")}</>}</Translation>
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            ) : (
              <Row>
                <Col>
                  <div className="mb-2 mt-3 change-credit-card-box">
                    <div className="mt-2">
                      <Row>
                        <Col lg="6">
                          <div className="form-group mb-3">
                            <label>
                              <Translation>{(t) => <>{t("Card Holder Name")}</>}</Translation>
                              <span className="asterisk">*</span>
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <input
                                required={true}
                                type="text"
                                className="form-control pe-5"
                                name="card_name"
                                value={formData.card_name}
                                onChange={handleChange}
                              />
                              <div className="payment-formicon">
                                <span className="fa fa-user" aria-hidden="true" />
                              </div>
                            </div>
                            {showError("card_name")}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="form-group mb-3">
                            <label className="mb-0">
                              <Translation>{(t) => <>{t("Email")}</>}</Translation>
                              <span className="asterisk ml-1">*</span>
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <input
                                required={true}
                                type="email"
                                className="form-control"
                                name="card_email"
                                value={formData.card_email}
                                onChange={handleChange}
                              />
                              <div className="payment-formicon">
                                <span className="fa fa-envelope" aria-hidden="true" />
                              </div>
                            </div>
                            {showError("card_email")}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <div className="form-group mb-3">
                            <label>
                              <Translation>{(t) => <>{t("Credit Card number")}</>}</Translation>
                              <span className="asterisk">*</span>
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <input
                                type="text"
                                required={true}
                                className="form-control pe-5"
                                name="card_number"
                                value={formData.card_number}
                                onChange={handleChange}
                                maxLength={16}
                              />
                              <div className="payment-formicon">
                                <span className="fa fa-credit-card" aria-hidden="true" />
                              </div>
                            </div>
                            {showError("card_number")}
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group mb-4">
                        <Row>
                          <Col md="6" lg="6">
                            <div className="form-group mb-3">
                              <label>
                                <Translation>{(t) => <>{t("Expiration Date")}</>}</Translation>
                                <span className="asterisk">*</span>
                              </label>
                              <div className="d-flex align-items-center position-relative">
                                <DatePicker
                                  required
                                  placeholderText="MM/YY"
                                  // className="form-control datePickerPayment pe-5"
                                  name="card_expiry"
                                  selected={formData?.card_expiry}
                                  onChange={handleCalender}
                                  dateFormat="MM/yy"
                                  showMonthYearPicker
                                  showFullMonthYearPicker
                                  minDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
                                  customInput={
                                    <InputMask
                                      mask="99/99"
                                      maskChar={null}
                                      // placeholder="MM/YY"
                                      className="form-control datePickerPayment pe-5"
                                      name="card_expiry"
                                      value={formData?.card_expiry}
                                      onChange={handleCalender}
                                      // onChangeRaw
                                      // showMask
                                    />
                                  }
                                ></DatePicker>
                                <div className="payment-formicon">
                                  <span className="fa fa-calendar" style={{ cursor: "pointer" }} aria-hidden="true" />
                                </div>
                              </div>
                              {showError("card_expiry")}
                            </div>
                          </Col>
                          <Col md="6" lg="6">
                            <div className="form-group mb-3">
                              <label>
                                CVV
                                <span className="asterisk">*</span>
                              </label>
                              <div className="d-flex align-items-center position-relative">
                                <input
                                  required={true}
                                  type="password"
                                  className="form-control pe-5"
                                  name="card_cvv"
                                  value={formData.card_cvv}
                                  onChange={handleChange}
                                  maxLength={4}
                                />
                                <div className="payment-formicon">
                                  <span className="fa fa-key" aria-hidden="true" />
                                </div>
                              </div>
                              {showError("card_cvv")}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
        {formData.show_delete_card_model ? (
          <DeleteCardModal
            onOpen={formData.show_delete_card_model}
            onConfirm={handleDeleteCard}
            message="Are You sure you want to Delete this card? It will not auto renew permits."
            onClose={handleShowDeleteCardModal}
            title={"Delete Card"}
            isDeleteCarddisabled={formData.isDeleteCard}
            loading={false}
          />
        ) : null}
        <p>
          <Translation>{(t) => <>{t("Starting")}</>}</Translation> : {getBookingSlotDateInFrenchLanguage(confirm_data.startTime, unit_language)}
        </p>
        <p>
          <Translation>{(t) => <>{t("Ending")}</>}</Translation> : {getBookingSlotDateInFrenchLanguage(confirm_data.endTime, unit_language)}
        </p>
        {api_success_message && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
        {api_error_message && <CustomContentAlert delay={10000} message={api_error_message} className="toast-error" />}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal} type="button" className="btn btn-danger">
          <Translation>{(t) => <>{t("Cancel")}</>}</Translation>
        </Button>
        <Button style={{ minWidth: "93px" }} onClick={localSubmit} type="button" disabled={isButtonDisable} className="btn btn-fill btn-info d-md-block d-none">
          <Translation>{(t) => <>{t("Done")}</>}</Translation>
        </Button>
        <Button style={{ minWidth: "93px" }} onClick={localSubmit} type="button" disabled={isButtonDisable} className="btn btn-fill btn-info d-md-none">
          <Translation>{(t) => <>{t("Done")}</>}</Translation>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmReservation;

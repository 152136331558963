import t from "../../Actions/EdgeDeviceActions/types";

const intitialState = {
  deviceData: {},
  deviceTypes: [],
  edgeDevices: [],
  fieldsForDeviceType: [],
  selectedDeviceType: [],
  lotData: [],
  laneData: [],
  cameraData: [],
  message: {
    isSuccess: false,
    message: "",
  },
  modelOpenClose: false,
  loader: false,
};

const reducer = (state = intitialState, action) => {
  switch (action.type) {
    case t.FETCH_ALL_DEVICE_TYPE: {
      return {
        ...state,
        deviceTypes: action.payload,
      };
    }
    case t.FETCH_ALL_FIELDS_FOR_DEVICE_TYPE: {
      return {
        ...state,
        // fieldsForDeviceType: action.payload,
      };
    }
    case t.SET_DEVICE_TYPE: {
      return {
        ...state,
        selectedDeviceType: action.payload,
      };
    }
    case t.GET_ALL_EDGE_DEVICES: {
      return {
        ...state,
        edgeDevices: action.payload,
      };
    }
    case t.SET_EDGE_DEVICE_DATA: {
      return {
        ...state,
        deviceData: action.payload,
      };
    }
    case t.SET_LOT_DATA: {
      return {
        ...state,
        lotData: action.payload,
      };
    }
    case t.SET_LANE_DATA: {
      return {
        ...state,
        laneData: action.payload,
      };
    }
    case t.SET_CAMERA_DATA: {
      return {
        ...state,
        cameraData: action.payload,
      };
    }
    case t.SET_EDGE_DEVICE_SUCCESS: {
      return {
        ...state,
        message: {
          isSuccess: true,
          message: action.payload,
        },
      };
    }
    case t.SET_EDGE_DEVICE_ERROR: {
      return {
        ...state,
        message: {
          isSuccess: false,
          message: action.payload,
        },
      };
    }
    case t.UPDATE_EDGE_DEVICE_LIST: {
      const oldEdgeDeviceList = state.edgeDevices;
      oldEdgeDeviceList.push(action.payload);
      return {
        ...state,
        edgeDevices: oldEdgeDeviceList,
      };
    }
    case t.CLOSE_OPEN_MODEL: {
      return {
        ...state,
        modelOpenClose: action.payload,
      };
    }
    case t.SHOW_HIDE_LOADER: {
      return { ...state, loader: action.payload };
    }
    default:
      return state;
  }
};

export default reducer;

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, FormLabel, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useHistory } from "react-router-dom";
import { GateSchedulerActions, SuperAdminAction } from "../../Actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CustomContentAlert from "../helper";

function AddGateScheduler() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const { pathname } = location;
  const { property_id, property } = useSelector((state) => state.UserProfileDetails);
  const { addUpdateDeleteResponse } = useSelector((state) => state.GateSchedulerDetails);
  const [gatesOptions, setGatesOptions] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrAlert, setShowErrAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isSetMaxTime, setIsSetMaxTime] = useState(false);
  const [groupId, setGroupId] = useState(0);
  const [disabledDates, setDisabledDates] = useState([]);
  useEffect(() => {
    dispatch(SuperAdminAction.setDomainHeader(property?.property_name, property?.domain));
    if (pathname === "/edit-gate-scheduler") {
      const { state: obj } = location;
      setIsEdit(true);

      if (obj) {
        setGroupId(obj?.group_id);
        if (obj?.date && selectedDate !== "") {
          setSelectedDate(new Date(obj?.date));
        }
        setFormData({
          gate: JSON.stringify({
            lane_id: obj?.lane_id,
            gate_no: parseInt(obj?.gate_no),
            lane_alias: obj?.lane_alias,
          }),
          gateData: {
            lane_id: obj?.lane_id,
            gate_no: parseInt(obj?.gate_no),
            lane_alias: obj?.lane_alias,
          },
          holiday: obj?.holiday || "0",
          date: obj?.date ? new Date(obj?.date) : "",
          state: "",
          active: obj?.active?.toString() || null,
        });
        setSelectedOptions(obj?.dayofweek);
        setFilteredOption([...optionList, ...(obj?.dayofweek || [])]);
        if (obj?.timeOption && obj?.timeOption?.length) {
          const updatedTimes = obj?.timeOption.map((item) => {
            const [hours, minutes, seconds] = (item?.startTime ?? "").split(":").map(Number);
            const [Ehours, Eminutes, Eseconds] = (item?.endTime ?? "").split(":").map(Number);
            if (Ehours === 23 && Eminutes === 59) {
              setIsSetMaxTime(true);
            }
            return {
              startTime: new Date(new Date().setHours(hours, minutes, seconds)),
              endTime: new Date(new Date().setHours(Ehours, Eminutes, Eseconds)),
              state: item?.state,
              id: item?.id,
            };
          });
          setTimeOption(updatedTimes);
        }
      }
    }
    dispatch(GateSchedulerActions.fetch_Gates(property_id));
  }, [pathname, property_id]);
  const { gateList } = useSelector((state) => state.GateSchedulerDetails);
  useEffect(() => {
    if (gateList?.data?.length) {
      setGatesOptions(gateList?.data?.filter((item) => item?.lane_alias !== null));
    }
  }, [gateList]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [timeOption, setTimeOption] = useState([
    {
      startTime: "",
      endTime: "",
      state: "RGLR",
      id: new Date().getTime(),
    },
  ]);
  const [formData, setFormData] = useState({
    gate: "",
    gateData: {},
    holiday: "0",
    date: "",
    state: "",
    active: "1",
  });
  const stateOptions = [
    { value: "RGLR", key: "Regular Operation" },
    { value: "TOPN", key: "Timed Open" },
    { value: "TCLS", key: "Timed Close" },
  ];
  const [higherTime, setHigherTime] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [startHigherTime, setstartHigherTime] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const optionList = [
    { value: "0", label: "Sunday" },
    { value: "1", label: "Monday" },
    { value: "2", label: "Tuesday" },
    { value: "3", label: "Wednesday" },
    { value: "4", label: "Thursday" },
    { value: "5", label: "Friday" },
    { value: "6", label: "Saturday" },
  ];
  const [filteredOption, setFilteredOption] = useState([]);
  function handleSelect(data) {
    // (data) => setFormData((prevState) => ({ ...prevState, days: [...formData.days, data] }))
    setSelectedOptions(data);
  }
  const handleAddOption = (index) => {
    const filterdStateOptions = stateOptions.filter((item) => {
      const data = timeOption.find((i) => i?.state === item.value);
      return !data;
    });
    const timeOptionObj = {
      startTime: "",
      endTime: "",
      state: filterdStateOptions[0]?.value,
      id: new Date().getTime(),
    };
    setTimeOption((prevState) => [...prevState, timeOptionObj]);
    if (new Date(higherTime).getTime() > new Date().setHours(0, 0, 0, 0)) {
      setHigherTime(new Date(new Date(higherTime).getTime() - 2 * 60000));
    }
    const lastEndTime = timeOption[timeOption?.length - 1].endTime;
    setstartHigherTime(new Date(lastEndTime));
  };

  const handleRemoveOption = (index) => {
    const updatedTimeOptions = timeOption.filter((item, ind) => ind !== index);
    setTimeOption(updatedTimeOptions);
    setHigherTime(new Date(new Date(updatedTimeOptions[updatedTimeOptions.length - 1].endTime).getTime() - 2 * 60000));
    if (isSetMaxTime) {
      setIsSetMaxTime(false);
    }
  };
  const handleStartTimeOptionChange = (index, date) => {
    const updatedTime = timeOption.map((item, ind) => {
      if (ind === index) {
        return { ...item, startTime: date };
      } else {
        return item;
      }
    });
    setTimeOption(updatedTime);
    if (higherTime && new Date(higherTime).getTime() < new Date(date).getTime()) {
      setHigherTime(new Date(new Date(date).getTime() + 1 * 60000));
    }
  };

  const handleEndTimeOptionChange = (index, date) => {
    const updatedTime = timeOption.map((item, ind) => {
      if (ind === index) {
        return { ...item, endTime: date };
      } else {
        return item;
      }
    });
    setTimeOption(updatedTime);
    if (higherTime && new Date(higherTime).getTime() < new Date(date).getTime()) {
      setHigherTime(new Date(new Date(date).getTime() - 1 * 60000));
    }
  };

  const handleStateOptionChange = (index, target) => {
    const updatedTime = timeOption.map((item, ind) => {
      if (ind === index) {
        return { ...item, state: target?.value };
      } else {
        return item;
      }
    });
    setTimeOption(updatedTime);
  };

  const checkFormValidations = () => {
    if (formData?.gate !== "" && formData?.active !== null && formData?.active !== "Select") {
      if (
        Boolean(timeOption.find((item) => item.startTime !== "")) &&
        Boolean(timeOption.find((item) => item.endTime !== "")) &&
        Boolean(timeOption.find((item) => item.status !== ""))
      ) {
        setIsFormValid(true);
        if (parseInt(formData?.holiday)) {
          if (selectedDate !== "") {
            setIsFormValid(true);
          } else {
            setIsFormValid(false);
          }
        } else {
          if (selectedOptions?.length) {
            setIsFormValid(true);
          } else {
            setIsFormValid(false);
          }
        }
      } else {
        setIsFormValid(false);
      }
    } else {
      setIsFormValid(false);
    }
  };
  useEffect(() => {
    checkFormValidations();
  }, [formData, timeOption, selectedOptions]);
  const handleChange = ({ target }) => {
    if (target.name === "gate" && target.value?.length > 2) {
      setSelectedDate("");
      setSelectedOptions([]);
      setFormData((prevState) => ({
        ...prevState,
        gateData: JSON.parse(target.value),
      }));
      if (gatesOptions && target.value?.length > 0) {
        const targetDays = gatesOptions?.find(
          (item) => item?.lane_alias === JSON.parse(target.value)?.lane_alias && item?.gate_no === JSON.parse(target.value)?.gate_no,
        )?.days;

        const filterdOptionList = optionList.filter((item) => {
          return !targetDays?.find((i) => i?.value === item?.value);
        });
        setFilteredOption(filterdOptionList);
      }
      if (gatesOptions) {
        const targetDates = gatesOptions?.find(
          (item) => item?.date && item?.lane_alias === JSON.parse(target.value)?.lane_alias && item?.gate_no === JSON.parse(target.value)?.gate_no,
        )?.date;
        if (targetDates) {
          const rowDate = targetDates.map((item) => new Date(item?.value));
          setDisabledDates(rowDate);
        }
      }
    }
    if (target.name === "holiday") {
      setSelectedOptions([]);
      setFormData({ ...formData, date: "" });
      setSelectedDate("");
    }
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataObj = {
      ...formData?.gateData,
      holiday: parseInt(formData?.holiday) || "0",
      date: selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : "",
      active: parseInt(formData?.active),
    };
    if (!isEdit) {
      dataObj.type = "Add";
    } else {
      dataObj.type = "Edit";
      dataObj.group_id = groupId;
    }
    dataObj.property_id = property_id;
    if (timeOption?.length && timeOption[0].startTime !== "" && timeOption[0].endTime !== "") {
      const formatedTimeOption = timeOption.map((item) => {
        return {
          startTime: item.startTime?.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          endTime: item?.endTime?.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          state: item?.state,
          id: isEditData(isEdit, item),
        };
      });
      dataObj.timeOption = formatedTimeOption;
    }
    dataObj.dayofweek = selectedOptions || [];
    dispatch(GateSchedulerActions.createGateScheduler(dataObj));
    setTimeout(() => {
      dispatch(GateSchedulerActions.clearAddUpdateDeleteRes());
    }, 2500);
  };

  const isEditData = (isEdited, item) => {
    if (isEdited) {
      if (item?.id > 1000000000) {
        return null;
      } else {
        return item?.id;
      }
    } else {
      return null;
    }
  };

  const handleMaxTime = (e, index) => {
    setIsSetMaxTime(!isSetMaxTime);
    const updatedTimeOPtions = timeOption.map((item, ind) => {
      if (ind === index) {
        if (!isSetMaxTime) {
          return { ...item, endTime: new Date(new Date().setHours(23, 59)) };
        } else {
          return { ...item, endTime: "" };
        }
      } else {
        return item;
      }
    });
    setTimeOption(updatedTimeOPtions);
  };
  useEffect(() => {
    if (addUpdateDeleteResponse?.success !== null) {
      if (addUpdateDeleteResponse?.success === true) {
        setShowAlert(true);
        setSuccessMsg(addUpdateDeleteResponse?.message);
        if (!isEdit && addUpdateDeleteResponse?.success) {
          setSelectedDate("");
          setFormData({
            gate: "",
            gateData: {},
            holiday: "0",
            date: "",
            state: "",
            active: "1",
          });
          setIsSetMaxTime(false);
          setSelectedOptions([]);
          setTimeOption([
            {
              startTime: "",
              endTime: "",
              state: "RGLR",
              id: new Date().getTime(),
            },
          ]);
        }
        setTimeout(() => {
          setShowAlert(false);
          setSuccessMsg("");
        }, 2000);
      } else {
        setShowErrAlert(true);
        setErrorMsg(addUpdateDeleteResponse?.message);
        setTimeout(() => {
          setShowErrAlert(false);
          setErrorMsg("");
        }, 2000);
      }
    }
  }, [addUpdateDeleteResponse]);
  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title as="h4">{isEdit ? "Update" : "Add"} Gate Schedule</Card.Title>
                <p className="card-category">Fill out the below form to {isEdit ? "update" : "add"} a gate schedule.</p>
              </Card.Header>
              <Card.Body className="pos-rel-overflow-hide">
                <Form>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>
                          Gate ID
                          <span className="asterisk">*</span>
                        </Form.Label>
                        <Form.Control as="select" name="gate" value={formData?.gate} onChange={handleChange} required>
                          <option value={""}>Select Gate</option>
                          {gatesOptions?.map((i, index) => (
                            <option
                              key={i?.date_no}
                              value={JSON.stringify({
                                lane_id: i["lane_id"],
                                gate_no: i?.gate_no,
                                lane_alias: i?.lane_alias,
                              })}
                            >
                              {i?.gate_no} ({i?.lane_alias})
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>
                          Event / Holiday / Special Day
                          <span className="asterisk">*</span>
                        </Form.Label>
                        <Form.Control as="select" name="holiday" value={formData?.holiday} onChange={handleChange}>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <FormLabel>
                          Event Date
                          <span className="asterisk">*</span>
                        </FormLabel>
                        <div>
                          <DatePicker
                            className="form-control w-100"
                            placeholderText="Select Date"
                            selected={selectedDate}
                            onChange={handleDateChange}
                            excludeDates={disabledDates}
                            minDate={moment().toDate()}
                            dateFormat="yyyy-MM-dd"
                            disabled={!parseInt(formData?.holiday)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <FormLabel>
                          Day(s) of week
                          <span className="asterisk">*</span>
                        </FormLabel>
                        <Select
                          isDisabled={parseInt(formData?.holiday)}
                          options={formData?.gate !== "" ? filteredOption : optionList}
                          placeholder="Select day(s) of week"
                          value={selectedOptions}
                          onChange={handleSelect}
                          required
                          // isSearchable={true}
                          isMulti
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {timeOption?.map((i, index) => (
                    <Row key={i.id}>
                      <Col md="6">
                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <FormLabel>
                                Begin Time
                                <span className="asterisk">*</span>
                              </FormLabel>
                              <DatePicker
                                className="form-control"
                                selected={i?.startTime}
                                onChange={(date) => handleStartTimeOptionChange(index, date)}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                selectsEnd={true}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                minTime={startHigherTime}
                                maxTime={new Date().setHours(23, 59)}
                                disabled={timeOption.length > index + 1}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <div class="d-flex align-items-center">
                                <FormLabel className="w-100 text-nowrap">
                                  End Time
                                  <span className="asterisk">*</span>{" "}
                                </FormLabel>
                                {index === timeOption.length - 1 && (
                                  <OverlayTrigger
                                    delay={{ show: 250, hide: 400 }}
                                    placement={"top"}
                                    overlay={<Tooltip>When checked, it sets max_time to 23:59, indicating end of day.</Tooltip>}
                                  >
                                    <FormLabel className="ml-auto d-flex align-items-center text-nowrap">
                                      <input
                                        type="checkbox"
                                        className="mr-1"
                                        checked={isSetMaxTime}
                                        disabled={i?.startTime === ""}
                                        onChange={(e) => handleMaxTime(e, index)}
                                      ></input>
                                      <span>Max Time </span>{" "}
                                    </FormLabel>
                                  </OverlayTrigger>
                                )}
                              </div>
                              <DatePicker
                                className="form-control"
                                selected={i?.endTime}
                                onChange={(date) => handleEndTimeOptionChange(index, date)}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                disabled={i?.startTime === "" || isSetMaxTime || timeOption.length > index + 1}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                minTime={higherTime}
                                maxTime={new Date().setHours(23, 59)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="d-flex align-items-end">
                        <Form.Group className="w-100">
                          <Form.Label>
                            Gate State
                            <span className="asterisk">*</span>
                          </Form.Label>
                          <Form.Control as="select" name="state" onChange={(e) => handleStateOptionChange(index, e.target)} value={i?.state}>
                            {stateOptions?.map((item, index) => (
                              <option disabled={item.value === timeOption[index]?.state} key={item.key} value={item.value}>
                                {item.key}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        {index === 0 ? (
                          <Button
                            size="sm"
                            disabled={
                              timeOption[timeOption.length - 1].startTime === "" ||
                              timeOption[timeOption.length - 1].endTime === "" ||
                              timeOption.length > 2 ||
                              isSetMaxTime
                            }
                            onClick={() => handleAddOption(index)}
                            className="h-40px mb-3 ml-3"
                          >
                            <i className="fas fa-plus-circle"></i>
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            onClick={() => handleRemoveOption(index)}
                            className="h-40px mb-3 ml-3"
                            disabled={timeOption.length > 2 && index === 1}
                          >
                            <i className="fas fa-minus-circle"></i>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  ))}
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>
                          Enable Schedule
                          <span className="asterisk">*</span>
                        </Form.Label>
                        <Form.Control as="select" name="active" value={formData?.active} onChange={handleChange} required>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Button className="btn-fill" disabled={!isFormValid} type="Submit" variant="info" onClick={handleSubmit}>
                        {isEdit ? "Update" : "Create"}
                      </Button>
                      <Button className="btn-fill ml-2" type="button" variant="danger" onClick={() => history.push("/gate-scheduler")}>
                        Back
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <>
          {showAlert && <CustomContentAlert delay={2000} message={successMsg} className="toast-success" />}
          {showErrAlert && <CustomContentAlert delay={2000} message={errorMsg} className="toast-error" />}
        </>
      </Container>
    </div>
  );
}

export default AddGateScheduler;

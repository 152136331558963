import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Table, Tab, Tabs, Button } from "react-bootstrap";
import moment from "moment";
import { utcToSpecificTimeZone } from "../helper";
import { Translation } from "react-i18next";
import { getBookingSlotDateInFrenchLanguage } from "../language";

const CreditTable = (props) => {
  const {
    usedCredits,
    allocatedCredits,
    runningCredits,
    credit_used_30days,
    credit_30days_reports,
    single_credit_availability,
    dateRange,
    isOpenDateList,
    timezone,
    permits_allowed_type,
    unit_language,
  } = props;

  const [isOpen, setShow] = useState(false);
  const CollaspeToggle = () => {
    setShow(!isOpen);
  };

  const menuRef = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (isOpenDateList && !menuRef?.current?.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  });

  let scan_time = new Date().toLocaleString("en-US", { timeZone: timezone });
  scan_time = moment(scan_time).format("YYYY-MM-DD HH:mm:ss");

  function getCreditAvailability(passed_single_credit_availability) {
    if (permits_allowed_type === 2) {
      return "Unlimited";
    } else if (passed_single_credit_availability > 0) {
      return passed_single_credit_availability;
    } else {
      return 0;
    }
  }
  function getCreditLimit(passed_single_credit_availability) {
    if (permits_allowed_type === 2) {
      return "Unlimited";
    } else if (passed_single_credit_availability > 0) {
      return passed_single_credit_availability;
    } else {
      return 0;
    }
  }

  const defaultActiveKeyGet = () => {
    let result;
    if (usedCredits?.length) {
      result = "credit_count";
    } else if (allocatedCredits?.length) {
      result = "credit_allocated";
    } else {
      result = "last_thirty";
    }
    return result;
  };

  const activeKeyGet = () => {
    let result;
    if (usedCredits?.length) {
      result = "last_thirty";
    } else if (allocatedCredits?.length) {
      result = "credit_allocated";
    } else {
      result = "credit_count";
    }
    return result;
  };

  return (
    <div>
      {runningCredits !== undefined && isOpenDateList ? (
        <div ref={menuRef}>
          <div className={"collapse multi-collapse credit-collapse " + (isOpen ? "show" : "")} id="multiCollapseExample2">
            <div className="card-body">
              <Row>
                {usedCredits?.length ||
                allocatedCredits?.length ||
                credit_used_30days?.length ||
                credit_30days_reports?.length ||
                Object.keys(single_credit_availability).length ? (
                  <Col lg={12} md={12}>
                    <Tabs defaultActiveKey={defaultActiveKeyGet()} id="uncontrolled-tab-example" className="font-14 justify-content-center" fill>
                      <Tab eventKey="credit_count" title={<Translation>{(t) => <>{t("CreditsCount")}</>}</Translation>} activekey={defaultActiveKeyGet()}>
                        <Table className="table-striped mb-0" responsive bordered>
                          <thead>
                            <tr className="font-14">
                              <td className="pl-3">
                                <Translation>{(t) => <>{t("AvailableCredit")}</>}</Translation>
                              </td>
                              {credit_30days_reports.length > 0 &&
                                credit_30days_reports.map((item) => (
                                  <th style={{ borderTop: "1px solid #dee2e6" }} key={item?.id}>
                                    <Translation>{(t) => <>{t(`${item.slot}`)}</>}</Translation>
                                  </th>
                                ))}
                            </tr>
                          </thead>
                          <tbody className="font-14">
                            <tr>
                              <td>
                                <Translation>{(t) => <>{t("Limit")}</>}</Translation>
                              </td>
                              {credit_30days_reports.length > 0 ? (
                                credit_30days_reports.map((item) => <td key={item?.id}>{getCreditLimit(item?.credit_limit?.toFixed(2))}</td>)
                              ) : (
                                <td>{getCreditAvailability(single_credit_availability?.credit_limit?.toFixed(2))}</td>
                              )}
                            </tr>
                            <tr>
                              <td>
                                <Translation>{(t) => <>{t("Used")}</>}</Translation> ( <Translation>{(t) => <>{t("Last")}</>}</Translation> {dateRange}{" "}
                                <Translation>{(t) => <>{t("days")}</>}</Translation>)
                              </td>
                              {credit_30days_reports?.length > 0 ? (
                                credit_30days_reports.map((item) => <td key={item?.id}>{item.credit_used?.toFixed(2)}</td>)
                              ) : (
                                <td>{single_credit_availability?.credit_used?.toFixed(2)}</td>
                              )}
                            </tr>
                            <tr>
                              <td>
                                <Translation>{(t) => <>{t("Allocated")}</>}</Translation> (<Translation>{(t) => <>{t("Next")}</>}</Translation> {dateRange}{" "}
                                <Translation>{(t) => <>{t("days")}</>}</Translation>)
                              </td>
                              {credit_30days_reports?.length > 0 ? (
                                credit_30days_reports.map((item) => <td key={item?.id}>{item.credit_allocated?.toFixed(2)}</td>)
                              ) : (
                                <td>{single_credit_availability?.credit_allocated?.toFixed(2)}</td>
                              )}
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <Translation>{(t) => <>{t("Available")}</>}</Translation>
                              </td>
                              {credit_30days_reports?.length > 0 ? (
                                credit_30days_reports.map((item) => <td key={item?.id}>{getCreditLimit(item.credit_available?.toFixed(2))}</td>)
                              ) : (
                                <td>{getCreditAvailability(single_credit_availability?.credit_available?.toFixed(2))}</td>
                              )}
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab
                        eventKey="last_thirty"
                        title={
                          <>
                            <Translation>{(t) => <>{t("CreditsUsed")}</>}</Translation>
                            {" ("}
                            <Translation>{(t) => <>{t("Last")}</>}</Translation> {dateRange} <Translation>{(t) => <>{t("days")}</>}</Translation>
                            {" )"}
                          </>
                        }
                        activekey={activeKeyGet()}
                      >
                        <div
                          style={{
                            height: "250px",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <div className="border-top">
                            <Table className="table-striped mb-0" responsive bordered>
                              <thead className="timing-header">
                                <tr className="font-12">
                                  <th className="border-0" style={{ whiteSpace: "nowrap" }}>
                                    <Translation>{(t) => <>{t("CREDITS")}</>}</Translation>{" "}
                                  </th>
                                  <th className="border-0" style={{ whiteSpace: "nowrap" }}>
                                    <Translation>{(t) => <>{t("InvitedOn")}</>}</Translation>{" "}
                                  </th>
                                  <th className="border-0" style={{ whiteSpace: "nowrap" }}>
                                    <Translation>{(t) => <>{t("STARTTIME")}</>}</Translation>{" "}
                                  </th>
                                  <th className="border-0" style={{ whiteSpace: "nowrap" }}>
                                    <Translation>{(t) => <>{t("EndTime")}</>}</Translation>{" "}
                                  </th>
                                  <th className="border-0" style={{ whiteSpace: "nowrap" }}>
                                    LPN
                                  </th>
                                  <th className="border-0" style={{ whiteSpace: "nowrap" }}>
                                    <Translation>{(t) => <>{t("VisitorName")}</>}</Translation>{" "}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {usedCredits.length ? (
                                  usedCredits.map((item, index) => (
                                    <tr key={index} className="font-12">
                                      <td className="text-center">{item.cost}</td>
                                      <td className="text-start">
                                        {getBookingSlotDateInFrenchLanguage(utcToSpecificTimeZone(timezone, item.created_at), unit_language)}
                                      </td>
                                      <td className="text-start">
                                        {getBookingSlotDateInFrenchLanguage(utcToSpecificTimeZone(timezone, item.begin_time), unit_language)}
                                      </td>
                                      <td className="text-start">
                                        {getBookingSlotDateInFrenchLanguage(utcToSpecificTimeZone(timezone, item.expire_time), unit_language)}
                                      </td>
                                      <td className="text-center">{item.lpn}</td>
                                      <td className="text-center">
                                        {item.first_name || "-"} {item.last_name}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr className="text-center">
                                    <td colSpan="6" className="py-5 font-14">
                                      <Translation>{(t) => <>{t("There_are_no_record_to_display")}</>}</Translation>{" "}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="credit_allocated"
                        title={<Translation>{(t) => <>{t("CreditsAllocated")}</>}</Translation>}
                        activekey={defaultActiveKeyGet()}
                      >
                        <div
                          className="scrollTable border-top"
                          style={{
                            height: "250px",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <Table className="table-striped mb-0" responsive bordered>
                            <thead className="timing-header">
                              <tr className="font-12">
                                <th className="border-0" style={{ whiteSpace: "nowrap" }}>
                                  <Translation>{(t) => <>{t("CREDITS")}</>}</Translation>{" "}
                                </th>
                                <th className="border-0" style={{ whiteSpace: "nowrap" }}>
                                  <Translation>{(t) => <>{t("InvitedOn")}</>}</Translation>{" "}
                                </th>
                                <th className="border-0" style={{ whiteSpace: "nowrap" }}>
                                  <Translation>{(t) => <>{t("STARTTIME")}</>}</Translation>{" "}
                                </th>
                                <th className="border-0" style={{ whiteSpace: "nowrap" }}>
                                  <Translation>{(t) => <>{t("EndTime")}</>}</Translation>{" "}
                                </th>
                                <th className="border-0" style={{ whiteSpace: "nowrap" }}>
                                  Lpn
                                </th>
                                <th className="border-0" style={{ whiteSpace: "nowrap" }}>
                                  <Translation>{(t) => <>{t("VisitorName")}</>}</Translation>{" "}
                                </th>
                                <th className="border-0" style={{ whiteSpace: "nowrap" }}>
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {allocatedCredits?.length ? (
                                allocatedCredits.map((item, index) => (
                                  <tr key={index} className="font-12">
                                    <td className="text-center">{item.cost}</td>
                                    <td className="text-start">
                                      {getBookingSlotDateInFrenchLanguage(utcToSpecificTimeZone(timezone, item.created_at), unit_language)}
                                    </td>
                                    <td className="text-start">
                                      {getBookingSlotDateInFrenchLanguage(utcToSpecificTimeZone(timezone, item.begin_time), unit_language)}
                                    </td>
                                    <td className="text-start">
                                      {getBookingSlotDateInFrenchLanguage(utcToSpecificTimeZone(timezone, item.expire_time), unit_language)}
                                    </td>
                                    <td className="text-center">{item.lpn}</td>
                                    <td className="text-center">
                                      {item.first_name || "-"} {item.last_name}
                                    </td>
                                    <td className="text-center">
                                      {scan_time < utcToSpecificTimeZone(timezone, item.begin_time) ? (
                                        <Button className="btn-fill text-nowrap" variant="danger" size="xs" onClick={() => props.visitorDelete(item.id)}>
                                          Deactivate
                                        </Button>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr style={{ textAlign: "center" }}>
                                  <td colSpan="7" className="py-5 font-14">
                                    <Translation>{(t) => <>{t("There_are_no_record_to_display")}</>}</Translation>{" "}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Tab>
                    </Tabs>
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
          <button className="btn btn-primary credit-btn" type="button" aria-controls="multiCollapseExample2" onClick={CollaspeToggle}>
            <Translation>{(t) => <>{t("Credits")}</>}</Translation>{" "}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default CreditTable;

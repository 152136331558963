import t from "./type";
import apiType from "../APIResponseActions/type";
import { UnAuthAPI } from "../../../Auth/requestHandler";

export const setNewPassword = (body) => async (dispatch) => {
  try {
    const url = "user/password/reset";
    const rest = await UnAuthAPI(url, "POST", body);
    if (rest.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: rest.message,
      });
      await dispatch({
        type: t.NEW_PASSWORD,
        payload: body.password,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: rest.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

const ResidentsActionType = {
  FETCH_RESIDENTS: "FETCH_RESIDENTS",
  REMOVE_SPCIFIC_RESIDENTS: "REMOVE_SPCIFIC_RESIDENTS",
  FETCH_SPCIFIC_RESIDENTS: "FETCH_SPCIFIC_RESIDENTS",
  // EDIT_SPCIFIC_RESIDENTS: "EDIT_SPCIFIC_RESIDENTS",
  FETCH_NEW_RESIDENTS: "FETCH_NEW_RESIDENTS",
  FETCH_ALL_LOTS: "FETCH_ALL_LOTS",
  FETCH_LPR_ALL_LOTS: "FETCH_LPR_ALL_LOTS",
  FETCH_LPR_LANES: "FETCH_LPR_LANES",
  FETCH_CURRENT_LPR: "FETCH_CURRENT_LPR",
  FETCH_IMPORT_UNITS: "FETCH_IMPORT_UNITS",
  FETCH_UNIT_STALL: "FETCH_UNIT_STALL",
  FETCH_CURRENT_LOT: "FETCH_CURRENT_LOT",
  FETCH_CURRENT_TR: "FETCH_CURRENT_TR",
  FETCH_CURRENT_LANE: "FETCH_CURRENT_LANE",
  SHOW_HIDE_LOADER: "SHOW_HIDE_LOADER",
};

export default ResidentsActionType;

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap"; // Assuming you are using Bootstrap for Row and Col components

export default function DateRangeAndAmount(props) {
  const { ReportList, startDateTime, endDateTime, amountName } = props;
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    console.log("ReportListReportList::::::::::::::::::::", ReportList);
    const total = ReportList?.reduce((sum, item) => {
      const amount = parseFloat(item?.[amountName] || 0);
      return sum + amount;
    }, 0);
    console.log("total Amount ::::::::", total);
    setTotalAmount(total ? parseFloat(total).toFixed(2) : "0.00");
  }, [ReportList]);

  return (
    <div className="pt-3 px-1 pb-1" style={{ fontSize: 13 }}>
      <Row>
        {totalAmount && amountName && (
          <Col lg={12}>
            <span className="font-weight-600">Total amount</span>
            <span className="mr-1"> :</span> {totalAmount}
          </Col>
        )}
        {startDateTime && endDateTime ? (
          <Col lg={12}>
            <span className="font-weight-600">Period</span>
            <span className="mr-1"> :</span>
            {startDateTime} - {endDateTime}
          </Col>
        ) : null}
      </Row>
    </div>
  );
}

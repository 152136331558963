import t from "../../Actions/UserProfileActions/types";

const initialState = {
  // backgroundColor: {},
  user_offline: {},
};

const UserProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.LOGIN_USER: {
      return {
        ...state,
      };
    }
    case t.FETCH_USERPROFILE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case t.CHANGE_SUITE_ID:
      return {
        ...state,
        suite_id: action.payload,
      };
    case t.GET_OFFLINE_USER: {
      return { ...state, user_offline: action.payload };
    }
    case t.LOGOUT_USER: {
      return {};
    }
    default:
      return state;
  }
};

export default UserProfileReducer;

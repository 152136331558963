import t from "./types";
import { AuthAPI, BufferAuthAPI } from "../../../Auth/requestHandler";
import apiType from "../APIResponseActions/type";
import Config from "../../../Auth/config";
import { findStartDateAndEndDateForAllReports } from "../common";

export const getDispenserList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.PropertyId,
      lot_id: pageObj.lot_id,
      unit_id: pageObj.unit_id,
      timezone: pageObj.timezone,
      exportValue: pageObj?.exportData || false,
      childcheckbox: pageObj?.childcheckbox,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;

    const url = "ticket/dispenser_report";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_TICKET_DISPENSER_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getValidationList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.PropertyId,
      lot_id: pageObj.lot_id,
      merchant_id: pageObj.merchant_id,
      unit_id: pageObj.unit_id,
      selectedRadio: pageObj.selectedRadio,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportData || false,
      childcheckbox: pageObj.childcheckbox || false,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;
    const url = "report/validation/list";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_VALIDATION_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getAgentIds = () => async (dispatch) => {
  try {
    const url = "report/validation/agent_id/list";
    const response = await AuthAPI("domain", url, "GET");
    if (response?.success) {
      await dispatch({
        type: t.FETCH_VALIDATION_AGENT_LIST,
        payload: response.data,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getBatchValidationList = (pageObj) => async (dispatch) => {
  try {
    console.log("write here::::::::::::::::::::::", pageObj);
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.PropertyId,
      lot_id: pageObj.lot_id,
      unit_id: pageObj.unit_id,
      selectedRadio: pageObj.selectedRadio,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportData ? pageObj.exportData : false,
      childcheckbox: pageObj.childcheckbox || false,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;
    const url = "report/discount-validation/list";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_BATCH_VALIDATION_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getDiscountSummaryByUnit = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.PropertyId,
      lot_id: pageObj.lot_id,
      unit_id: pageObj.unit_id,
      selectedRadio: pageObj.selectedRadio,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportData ? pageObj.exportData : false,
      childcheckbox: pageObj.childcheckbox || false,
    };
    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;
    const url = "report/discount-validation-summary/list";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_VALIDATION_SUMMARY_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getPaymentInfoData = (row) => async (dispatch) => {
  try {
    console.log("check this::::::::::::::::::", row);
    const url = "public/payment-receipt/" + row.ticketNo + "/" + row.lang + "/" + row.isMobile;
    const data = await new Promise((resolve) => {
      fetch(`${Config.baseURL}/${url}`)
        .then((blobData) => blobData.blob())
        .then((response) => {
          console.log("==== response", response);
          resolve(URL.createObjectURL(response));
        })
        .catch((error) => {
          console.log("==== error", error);
        });
    });
    await dispatch({
      type: t.FETCH_PAYMENT_DOWNLOAD_DATA,
      payload: data,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const sendPaymentMail = (sendMailData) => async (dispatch) => {
  try {
    const url = "report/send-payment-mail";
    const body = sendMailData;
    const response = await AuthAPI("domain", url, "POST", body);
    console.log("Mail send Response success::::::", response);
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    console.log(error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const getInvoiceReport = (pageObj) => async (dispatch) => {
  try {
    await dispatch({
      type: t.FETCH_ADMIN_INVOICE_EXPORT_LIST,
      payload: "",
    });
    const body = {
      property_id: pageObj.property_id,
      unit_id: pageObj.unit_id,
      timezone: pageObj.timezone,
      month_year: pageObj.month || "",
      exportValue: pageObj.exportValue || false,
      childcheckbox: pageObj?.childcheckbox,
    };

    const url = "report/admin-invoice-report/list";
    if (body.exportValue) {
      try {
        const data = await BufferAuthAPI("domain", url, "POST", body);
        await dispatch({
          type: t.FETCH_ADMIN_INVOICE_EXPORT_LIST,
          payload: data,
        });
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_ADMIN_INVOICE_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (e) {
    console.error("getInvoiceReport: Error", e);
  }
};

export const getOverUsageInvoiceReport = (pageObj) => async (dispatch) => {
  try {
    const body = {
      property_id: pageObj.property_id,
      suite_id: pageObj.unit_id,
      timezone: pageObj.timezone,
      type: pageObj.type,
      date: pageObj.month || "",
      exportValue: pageObj.exportValue ? pageObj.exportValue : false,
    };

    if (pageObj.childcheckbox) {
      body.childcheckbox = pageObj.childcheckbox;
    }
    const url = "report/overusage-invoice-report/list";
    if (body.exportValue) {
      try {
        const data = await BufferAuthAPI("domain", url, "POST", body);
        await dispatch({
          type: t.FETCH_USAGE_INVOICE_EXPORT_LIST,
          payload: data,
        });
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_USAGE_INVOICE_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (e) {
    console.error("getInvoiceReport: Error", e);
  }
};

export const getUnitInvoiceList = (pageObj) => async (dispatch) => {
  try {
    await dispatch({
      type: t.FETCH_INVOICE_EXPORT_LIST,
      payload: "",
    });
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.property_id,
      month_year: pageObj.month_year,
      unit_id: pageObj.unit_id,
      selectedRadio: pageObj.selectedRadio,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportValue ? pageObj.exportValue : false,
    };

    if (pageObj.childcheckbox) {
      body.childcheckbox = pageObj.childcheckbox;
    }
    const url = "report/unit-invoice-report/list";
    if (body.exportValue) {
      try {
        const data = await BufferAuthAPI("domain", url, "POST", body);
        await dispatch({
          type: t.FETCH_INVOICE_EXPORT_LIST,
          payload: data,
        });
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_UNIT_INVOICE_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const PdfDownload = (body) => async (dispatch) => {
  try {
    const url = `report/invoice-pdf-download`;

    if (body.mail_sent) {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response.success) {
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: response.message,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    } else {
      const data = await BufferAuthAPI("domain", url, "POST", body);
      await dispatch({
        type: t.FETCH_ADMIN_INVOICE_EXPORT_PDF_LIST,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const OverUsagePdfDownload = (body) => async (dispatch) => {
  try {
    const url = `report/overusage-invoice-pdf-download`;

    if (body.mail_sent) {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response.success) {
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: response.message,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    } else {
      const data = await BufferAuthAPI("domain", url, "POST", body);
      await dispatch({
        type: t.FETCH_OVERUSAGE_INVOICE_EXPORT_PDF_LIST,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const cashoutList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.property_id,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportValue ? pageObj.exportValue : false,
      type: pageObj.type,
    };

    if (pageObj.childcheckbox) {
      body.childcheckbox = pageObj.childcheckbox;
    }
    const url = "report/cashout/list";
    if (body.exportValue) {
      try {
        const data = await BufferAuthAPI("domain", url, "POST", body);
        await dispatch({
          type: t.FETCH_CASHOUT_EXPORT_LIST,
          payload: data,
        });
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      await dispatch({
        type: t.FETCH_CASHOUT_LIST,
        payload: [],
      });
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_CASHOUT_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: false,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const createUpdateCashout = (pageObj) => async (dispatch) => {
  try {
    const url = "report/cashout-insert-update";
    let response = await AuthAPI("domain", url, "POST", pageObj);
    if (response?.success) {
      if (pageObj.action === "update") {
        if (pageObj.cashout_id) {
          response.data.type = pageObj.type;
          await dispatch({
            type: t.FETCH_CASHOUT_UPDATE,
            payload: response?.data,
          });
        } else {
          pageObj.cashout_id = response?.data?.id;
          pageObj.last_collected_time = response?.data?.last_collected_time;
          pageObj.calculated_cashout = response?.data?.calculated_cashout;
          pageObj.audited_username = response?.data?.audited_username;
          await dispatch({
            type: t.FETCH_CASHOUT_INSERT,
            payload: pageObj,
          });
        }
      }
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const meterBatteryList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.property_id,
      timezone: pageObj.timezone,
      exportValue: pageObj?.exportValue || false,
      agent_id: pageObj.agent_id || "",
      childcheckbox: pageObj.childcheckbox,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;

    const url = "report/meter-battery/list";
    if (body.exportValue) {
      try {
        const data = await BufferAuthAPI("domain", url, "POST", body);
        await dispatch({
          type: t.FETCH_BATTERY_EXPORT_LIST,
          payload: data,
        });
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      await dispatch({
        type: t.FETCH_BATTERY_LIST,
        payload: [],
      });
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_BATTERY_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: false,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const meterStatusList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.property_id,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportValue ? pageObj.exportValue : false,
    };

    if (pageObj.childcheckbox) {
      body.childcheckbox = pageObj.childcheckbox;
    }
    const url = "report/meter-status/list";
    if (body.exportValue) {
      try {
        const data = await BufferAuthAPI("domain", url, "POST", body);
        await dispatch({
          type: t.FETCH_BATTERY_EXPORT_LIST,
          payload: data,
        });
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      await dispatch({
        type: t.FETCH_BATTERY_LIST,
        payload: [],
      });
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_BATTERY_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: false,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const lastTransactionList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.property_id,
      timezone: pageObj.timezone,
      exportValue: pageObj?.exportValue || false,
      childcheckbox: pageObj.childcheckbox,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;
    const url = "report/last-transaction/list";
    if (body.exportValue) {
      try {
        const data = await BufferAuthAPI("domain", url, "POST", body);
        await dispatch({
          type: t.FETCH_BATTERY_EXPORT_LIST,
          payload: data,
        });
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      await dispatch({
        type: t.FETCH_BATTERY_LIST,
        payload: [],
      });
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_BATTERY_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: false,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const accessloghistoryList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.PropertyId,
      timezone: pageObj.timezone,
      exportValue: pageObj?.exportValue || false,
      childcheckbox: pageObj.childcheckbox,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;
    const url = "report/accesshistorylog/list";
    if (body.exportValue) {
      try {
        const data = await BufferAuthAPI("domain", url, "POST", body);
        console.log(data);
        await dispatch({
          type: t.FETCH_ACCESSLOG_EXPORT_LIST,
          payload: data,
        });
        return data;
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_ACCESSLOG_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: false,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const paidAnalysisList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.property_id,
      timezone: pageObj.timezone,
      exportValue: pageObj?.exportValue || false,
      agent_id: pageObj.agent_id || "",
      childcheckbox: pageObj?.childcheckbox,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;

    const url = "report/paid-analysis/list";
    if (body.exportValue) {
      try {
        const data = await BufferAuthAPI("domain", url, "POST", body);
        await dispatch({
          type: t.FETCH_PAID_ANALYSIS_EXPORT_LIST,
          payload: data,
        });
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      await dispatch({
        type: t.FETCH_PAID_ANALYSIS_LIST,
        payload: [],
      });
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_PAID_ANALYSIS_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: false,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getAgentIdData = (propertyId) => async (dispatch) => {
  try {
    const url = "report/agent_id/list/" + propertyId;
    const response = await AuthAPI("domain", url, "GET");
    if (response?.success) {
      await dispatch({
        type: t.FETCH_AGENT_LIST,
        payload: response.data,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const CreatePassagePassRate = (pageObj) => async (dispatch) => {
  try {
    const url = "passagepass/rate";
    // if (pageObj.exportValue) {
    //   try {
    //     const data = await BufferAuthAPI("domain", url, "POST", pageObj);
    //     await dispatch({
    //       type: t.EXPORT_RATE_LIST,
    //       payload: data,
    //     });
    //   } catch (error) {
    //     await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
    //   }
    // } else {
    const response = await AuthAPI("domain", url, "POST", pageObj);
    if (response?.success) {
      await dispatch({
        type: t.CREATE_RATE_LIST,
        payload: response,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
    // }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const GetPassagePassRate = (pageObj) => async (dispatch) => {
  try {
    const url = "passagepass/get-rate";
    const response = await AuthAPI("domain", url, "POST", pageObj);
    if (response?.success) {
      await dispatch({
        type: t.FETCH_RATE_LIST,
        payload: response,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: "",
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const UpdatePassagePassRate = (body) => async (dispatch) => {
  try {
    const url = `passagepass/update-rate/${body.id}`;
    const response = await AuthAPI("domain", url, "PATCH", body);
    if (response?.success) {
      await dispatch({
        type: t.UPDATE_RATE_LIST,
        payload: response,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const DeletePassagePassRate = (id) => async (dispatch) => {
  try {
    const url = `passagepass/delete-rate/${id}`;
    const response = await AuthAPI("domain", url, "DELETE");
    if (response?.success) {
      await dispatch({
        type: t.DELETE_RATE_LIST,
        payload: id,
      });
      console.log(" response.message", response.message);
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getPassagePassRateType = () => async (dispatch) => {
  try {
    const url = "passagepass/get-rate-type";
    const response = await AuthAPI("domain", url, "GET");
    if (response?.success) {
      await dispatch({
        type: t.FETCH_RATE_TYPE_LIST,
        payload: response.data,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const GetAllParkingLocation = (lot_id) => async (dispatch) => {
  try {
    const url = `passagepass/get-location/${lot_id}`;
    const response = await AuthAPI("domain", url, "GET");
    if (response?.success) {
      await dispatch({
        type: t.FETCH_LOCATION_LIST,
        payload: response.data,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getAllSessionsPerSiteOrStatsList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.PropertyId,
      timezone: pageObj.timezone,
      exportValue: pageObj?.exportValue || false,
      childcheckbox: pageObj.childcheckbox,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;
    const url = `sessions/sessions_or_stats/list`;
    
    if (body.exportValue) {
      try {
        const data = await BufferAuthAPI("domain", url, "POST", body);
        console.log(data);
        await dispatch({
          type: t.FETCH_SESSIONORSTATS_LIST,
          payload: data,
        });
        return data;
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_SESSIONORSTATS_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: false,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};
import React, { useState } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import OverUsage from "./OverUsageReport";
import Invoice from "./Invoice";

const InvoiceReport = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const onTabClick = async (k) => {
    setActiveTab(k);
    console.log("activeTab", activeTab);
  };
  return (
    <>
      <Container fluid>
        <Tabs activeKey={activeTab} id="controlled-tab-example" onSelect={(k) => onTabClick(k)} transition={false}>
          <Tab eventKey="1" title="Invoice" className="m-0">
            {activeTab === "1" && <Invoice />}
          </Tab>
          <Tab eventKey="2" title="OverUsage Paid Permit" className="m-0">
            {activeTab === "2" && <OverUsage />}
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};
export default InvoiceReport;

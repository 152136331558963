import t from "../SuperAdminAction/types";
import { AuthAPI } from "../../../Auth/requestHandler";
import apiType from "../APIResponseActions/type";

export const editLane = (data) => async (dispatch) => {
  try {
    const url = "lane/" + data.id;
    const body = {
      alias: data.alias,
      lot_id: data.lot.id,
      lat: "41.40338",
      lon: "2.17403",
      entry: data.entry_lane.toString() === "1" ? 1 : 0,
      exit: data.entry_lane.toString() === "1" ? 0 : 1,
      property_id: data.propertyId,
      old_data: data.old_data,
    };
    const response = await AuthAPI("domain", url, "PATCH", body);
    if (response?.data && response?.success) {
      await dispatch({ type: t.EDIT_LANE, payload: response.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const deleteLane =
  ({ id, propertyId }) =>
  async (dispatch) => {
    try {
      const laneId = {
        id: id,
        property_id: propertyId,
      };
      const response = await AuthAPI("domain", "lane", "DELETE", laneId);
      if (response?.success) {
        await dispatch({ type: t.DELETE_LANE, payload: laneId.id });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: response.message,
        });
      }
    } catch (error) {
      await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
    }
  };

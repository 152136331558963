import React, { Component, useEffect, useState } from "react";
import { Row, Card, Col, Form, Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { SuperAdminAction } from "../../Actions";
import { loadPlayer } from "rtsp-relay/browser";
import { groupBy, isEmpty } from "lodash";
import openAction from "../../../assets/img/svg/live_feed_icons/live-feed-open.svg";
import Switch from "@material-ui/core/Switch";
import { CircularProgress } from "@material-ui/core";
import SmartGateConfirmModal from "../Modal/smartGateModal";

class OnPremise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      api_response: false,
      property: this.props.PropertyId,
      propertyData: this.props.property,
      timezone: this.props.timezone,
      isSearchInputDisabled: true,
      isSearchButtonDisabled: true,
      show: false,
      selectedGate_no: "",
      selectedLane: "",
      url: "",
      streamData: [],
      liveStreamData: [],
      feedData: {},
      disableCloseButton: true,
      timer: null,
    };
  }

  componentDidMount = async () => {
    const selectedPropertyName = this.state.propertyData?.property_name;
    const selectedDomainName = this.state.propertyData?.domain;
    await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    const feedData = await this.props.getLiveFeed(this.props.PropertyId, "OnPremise");
    const groupFeed = groupBy(feedData, "gate_no");
    let url;
    const windowUrl = window?.location?.href?.split("/");
    const baseUrl = windowUrl[0];
    if (baseUrl === "https:") {
      url = `${process.env.REACT_APP_WSS}${process.env.REACT_APP_MAIN_URL}:${process.env.REACT_APP_LIVEFEED_PORT}`;
    } else {
      url = `${process.env.REACT_APP_WSS}${process.env.REACT_APP_LIVEFEED_URL}`;
    }

    this.setState({
      url: url,
      feedData: groupFeed,
    });
  };

  render() {
    return (
      <div>
        <div fluid>
          <Row>
            <Col md="12">
              <Card className="mb-0">
                <Card.Body>
                  <Row>
                    {(Object.values(this.state.feedData) ?? []).map((data, index) => {
                      return (
                        <LiveView
                          key={index}
                          data={data}
                          index={index}
                          url={this.state.url}
                          property={this.state.property}
                          userId={this.props.id}
                          setRtsp={this.props.setRtsp}
                        />
                      );
                    })}
                  </Row>
                  {isEmpty(this.state.feedData) && (
                    <div className="citation-list-table">
                      <div className="p-3 text-center">There are no records to display</div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = ({ SuperAdminDetails, APIResponseDetails, UserProfileDetails, EnforcementDetails }) => {
  const { userList, userCount: count, getAllUserlist, getRtspData } = SuperAdminDetails;
  const { api_response } = APIResponseDetails;
  const {
    property_id: PropertyId,
    // user_type,
    // suite_id,
    // timezone,
    id,
    property,
  } = UserProfileDetails;
  const { userlots } = EnforcementDetails;
  return {
    api_response,
    userList,
    count,
    getAllUserlist,
    PropertyId,
    getRtspData,
    property,
    userlots,
    id,
  };
};
const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  setRtsp: SuperAdminAction.setRtsp,
  getLiveFeed: SuperAdminAction.getLiveFeed,
};

export default connect(mapStatetoProps, mapStateToDispatch)(OnPremise);

const LiveView = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setLiveStreamData([...props.data]);

    return () => {
      closeStream();
    };
  }, [props.data]);

  const [checkValue, setCheckValuer] = useState(false);
  const [liveStreamData, setLiveStreamData] = useState([]);
  const [currentLivePlayer, setCurrentLivePlayer] = useState([]);
  const [isSwitchDisable, setIsSwitchDisable] = useState(false);
  const [pushStream, setPushStream] = useState([]);
  const [state, setState] = useState({
    action: "",
    data: {},
    smartGateModal: false,
    buttonDisable: false,
  });

  const openStream = async () => {
    const data = [];
    let Sdata;
    for (const i of liveStreamData) {
      const feedUrl = `${props.url}/api/stream/${i.id}/${props.userId}/ONPREMISE`;

      Sdata = loadPlayer({
        url: `${feedUrl}`,
        canvas: document.getElementById(`canvas${i.id}`),
      });
      data.push({ id: i.id, player: Sdata });
    }
    setIsSwitchDisable(true);
    const resolvePromise = await Promise.all(
      data.map((item) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              id: item.id,
              status: false,
              data: item.player,
            });
          }, 13000);
          item.player.then(() =>
            resolve({
              id: item.id,
              status: true,
              data: item.player,
            }),
          );
        });
      }),
    );
    setIsSwitchDisable(false);
    setCurrentLivePlayer(resolvePromise);
  };

  const closeStream = async () => {
    const allPromices = [...currentLivePlayer];
    setCurrentLivePlayer([]);
    setLiveStreamData([]);
    setIsSwitchDisable(true);

    await Promise.all(
      allPromices?.map((item) => {
        return new Promise((resolve) => {
          if (item.status) {
            item.data.then((data) => {
              data.destroy();
              const streamData = {
                userId: props.userId,
                propertyId: props.property,
                alias: liveStreamData[0].lane_alias,
                action: "CLOSE_STREAM",
                type: "ONPREMISE",
              };
              dispatch(SuperAdminAction.destroyStreamActivityLog(streamData));
              resolve(true);
            });
          } else resolve();
        });
      }),
    );
    setIsSwitchDisable(false);
    setLiveStreamData([...props.data]);
  };

  const toggleSwitch = async () => {
    setCheckValuer(!checkValue);
    !checkValue ? await openStream() : await closeStream();
  };

  const rtspApiCall = async (action, e) => {
    setState((prev) => ({
      ...prev,
      action: action,
      data: e,
    }));
    await modelOpen();
  };

  const modelOpen = async () => {
    setState((prev) => ({
      ...prev,
      smartGateModal: true,
    }));
  };

  const smartGateOperationChange = async () => {
    setState((prev) => ({
      ...prev,
      buttonDisable: true,
    }));

    const { gate_no, domain, camera_ip, lane_alias, control_message_suffix } = state.data;
    const data = {
      action: state.action,
      gate_no: gate_no,
      property_id: props.property,
      domain: domain,
      camera_ip: camera_ip,
      lane_alias: lane_alias,
      control_message_suffix: control_message_suffix,
      type: "ONPREMISE",
    };
    await props.setRtsp(data);
    setState((prev) => ({
      ...prev,
      smartGateModal: false,
      buttonDisable: false,
    }));
  };

  const Open = async (value) => {
    const data = currentLivePlayer.find((item) => item.id === value);
    const getValue = await data.data;
    getValue.play();
    setPushStream(pushStream.filter((item) => item !== value));
  };

  const Close = async (value) => {
    const data = currentLivePlayer.find((item) => item.id === value);
    const getValue = await data.data;
    getValue.pause();
    setPushStream([...pushStream, value]);
  };

  const checkValueFunction = async (id) => {
    if (checkValue) {
      if (currentLivePlayer?.length > 0 && currentLivePlayer.find((item) => item.id === id).status === true) {
        return "block";
      } else {
        return "none";
      }
    } else {
      return "none";
    }
  };

  const SwitchDisableFunction = (id) => {
    if (checkValue && !isSwitchDisable) {
      if (currentLivePlayer?.length > 0 && currentLivePlayer?.find((item) => item.id === id).status === true) {
        return "none";
      } else {
        return "block";
      }
    } else {
      return "none";
    }
  };

  return (
    <>
      <Col lg={12}>
        <div className="mt-3">
          <div className="d-flex">
            <h5 className="mt-2">{liveStreamData.length && liveStreamData[0]?.lane_alias}</h5>
            <Form.Group>
              <Switch value={checkValue} onClick={toggleSwitch} disabled={isSwitchDisable} color="primary" />
            </Form.Group>
            <div id="ignoreButton" type="button" className="ActionButton AllActionGroupInner" onClick={() => rtspApiCall("OPEN", liveStreamData[0])}>
              <img className="ml-0 mr-1" src={openAction} width={27} />
              <span>Open</span>
            </div>
            {isSwitchDisable && <CircularProgress size={30} className="mt-1" color="primary" />}
          </div>
          <Row>
            {liveStreamData.map((e, _index) => {
              return (
                <Col lg={6} className="col-xxl-auto col-sm-6" key={e.id} style={{ display: "flex" }}>
                  <div
                    className="cameraStream"
                    style={{
                      display: checkValueFunction(e.id),
                    }}
                  >
                    <canvas
                      id={`canvas${e.id}`}
                      style={{
                        width: "480px",
                        maxWidth: "100%",
                        marginTop: 20,
                      }}
                    />
                    {currentLivePlayer.find((item) => item.id === e.id) && (
                      <div className="play-pause-btn">
                        <Button hidden={pushStream.includes(e.id)} onClick={() => Close(e.id)} size="small">
                          <div className="playpause"></div>
                        </Button>
                        <Button onClick={() => Open(e.id)} hidden={!pushStream.includes(e.id)} size="small">
                          <div className="pp-btns"></div>
                        </Button>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: SwitchDisableFunction(e.id),
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 14,
                        padding: "0",
                        fontWeight: "500",
                        display: "flex",
                        alignItems: "center",
                        background: "#eee",
                        width: "410px",
                        maxWidth: "100%",
                        justifyContent: "center",
                        margin: "20px 0 5px 0",
                        aspectRatio: "auto 800/450",
                      }}
                    >
                      No Stream Available
                    </div>
                  </div>
                  <div
                    style={{
                      display: checkValue && !isSwitchDisable ? "none" : "block",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 14,
                        padding: "0",
                        fontWeight: "500",
                        display: "flex",
                        alignItems: "center",
                        background: "#eee",
                        width: "480px",
                        maxWidth: "100%",
                        justifyContent: "center",
                        margin: "5px 0",
                        aspectRatio: "auto 800/450",
                      }}
                    >
                      Stream Not Enabled
                    </div>
                    {/* <img
                        src={No_Stream_White}
                        width="100%"
                        className="mt-3 mb-3"
                        style={{ padding: "100px" }}
                        height="220"
                      ></img> */}
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </Col>
      <SmartGateConfirmModal
        showBrowserModal={state.smartGateModal}
        smartGateLoader={state.smartGateLoader}
        closeModal={() => setState((prev) => ({ ...prev, smartGateModal: !state.smartGateModal }))}
        saveit={smartGateOperationChange}
        action={state.action}
        buttonDisable={state.buttonDisable}
      />
    </>
  );
};

import React, { Component } from "react";
import { Card, Row, Col, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import DataTable from "react-data-table-component";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { connect } from "react-redux";
import { SuperAdminAction, TicketActions } from "../../Actions";
import Loader from "../Loader/Loader";
import { tableHeightScrollBars, findBySearch, utcToSpecificTimeZone } from "../helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropertDetails from "../LandingPage/PropertySelection";
import { saveAs } from "file-saver";
import moment from "moment";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";
const moment_timezone = require("moment-timezone");

class ByEnfocer extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.state = {
      PropertyId: this.props.PropertyId,
      enforcerList: [],
      firstDay: firstDay,
      lastDay: lastDay,
      loader: false,
      searchValue: "",
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      selectedPropertyObj: {},
      all_properties: [],
      childcheckbox: true,
      ticketReportLots: [],
      startDate: firstDay,
      endDate: lastDay,
      currentSummaryDate: "",
      month: "",
      finalTableHeight: 0,
      daysDifference: 30,
      newAdd: false,
      startDateTime: "",
      endDateTime: "",
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "Domain",
        width: "130px",
        sortable: true,
        cell: (row) => this.showDomainField(row),
        selector: (row) => row?.domain,
      },
      {
        name: "Mon",
        width: "100px",
        sortable: true,
        cell: (row) => this.showMonField(row),
        selector: (row) => row.mon,
      },
      {
        name: "Month",
        width: "130px",
        sortable: true,
        cell: (row) => this.showMonthField(row),
        selector: (row) => row.month,
      },
      {
        name: "Enforcer",
        width: "130px",
        sortable: true,
        cell: (row) => this.showEnforcerField(row),
        selector: (row) => row.enforcer,
      },
      {
        name: "Issued #",
        width: "100px",
        sortable: true,
        cell: (row) => this.showIssuedCountField(row),
        selector: (row) => row.issued,
      },
      {
        name: "Issued $",
        width: "100px",
        sortable: true,
        cell: (row) => this.showIssuedFeeField(row),
        selector: (row) => row.issuedFee,
      },
      {
        name: "Payable #",
        width: "100px",
        sortable: true,
        cell: (row) => this.showCitationPayableCountField(row),
        selector: (row) => row.actualCitations,
      },
      {
        name: "Payable $",
        width: "100px",
        sortable: true,
        cell: (row) => this.showActualFeeField(row),
        selector: (row) => row.actualFee,
      },
      {
        name: "Payable # %",
        width: "130px",
        sortable: true,
        cell: (row) => this.showActualPaidField(row),
        selector: (row) => row?.actualPaidPer,
      },
    ];

    this.addNewColumn = [
      {
        name: "Mon",
        width: "100px",
        sortable: true,
        cell: (row) => (row.mon ? row.mon : "-"),
        selector: (row) => row.mon,
      },
      {
        name: "Date",
        width: "170px",
        sortable: true,
        cell: (row) => (row.date ? utcToSpecificTimeZone(this.props.timezone, row.date, true) : "-"),
        selector: (row) => row.Date,
      },
      {
        name: "Enforcer",
        width: "130px",
        sortable: true,
        cell: (row) => (row.enforcer ? row.enforcer : "-"),
        selector: (row) => row.enforcer,
      },
      {
        name: "Issued #",
        width: "100px",
        sortable: true,
        cell: (row) => (row.issued ? row.issued : "-"),
        selector: (row) => row.issued,
      },
      {
        name: "Issued $",
        width: "100px",
        sortable: true,
        cell: (row) => (row.issuedFee ? `$${row.issuedFee}` : "-"),
        selector: (row) => row.issuedFee,
      },
      {
        name: "Payable #",
        width: "100px",
        sortable: true,
        cell: (row) => (row.actualCitations ? row.actualCitations : "-"),
        selector: (row) => row.actualCitations,
      },
      {
        name: "Payable $",
        width: "100px",
        sortable: true,
        cell: (row) => (row.actualFee ? `$${row.actualFee}` : "-"),
        selector: (row) => row.actualFee,
      },
      {
        name: "Payable # %",
        width: "130px",
        sortable: true,
        cell: (row) => (row.actualPaidPer ? `${row.actualPaidPer}` : "-"),
        selector: (row) => row?.actualPaidPer,
      },
      {
        name: "Refund #",
        sortable: true,
        width: "100px",
        cell: (row) => (row.refund ? row.refund : "0"),
        selector: (row) => row.refund,
      },
      {
        name: "Refund $",
        sortable: true,
        width: "100px",
        cell: (row) => (row.refundFee ? `$${row.refundFee}` : "0"),
        selector: (row) => row.refundFee,
      },
      {
        name: "Refund # %",
        sortable: true,
        width: "120px",
        cell: (row) => (row.refundPer ? row.refundPer : "0"),
        selector: (row) => row.refundPer,
      },
    ];
  }

  showActualPaidField = (row) => (
    <>
      {row.actualPaidPer ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.actualPaidPer}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row.actualPaidPer}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showActualFeeField = (row) => (
    <>
      {row.actualFee ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${row.actualFee}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">${row.actualFee}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showCitationPayableCountField = (row) => (
    <>
      {row.actualCitations ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.actualCitations}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row.actualCitations}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showIssuedFeeField = (row) => (
    <>
      {row.issuedFee ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${row.issuedFee}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">${row.issuedFee}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showIssuedCountField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.issued}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-3">{row?.issued}</div>
    </OverlayTrigger>
  );

  showEnforcerField = (row) => (
    <>
      {row.enforcer ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.enforcer}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row.enforcer}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showMonthField = (row) => (
    <>
      {row.month ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.month}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row.month}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showMonField = (row) => (
    <>
      {row.mon ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.mon}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row.mon}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showDomainField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.domain}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-3">{row?.domain}</div>
    </OverlayTrigger>
  );

  changeProperty(event) {
    this.setState({
      selectedProperty: event.value,
      selectedPropertyObj: event,
      startDate: this.state.firstDay,
      endDate: this.state.lastDay,
      month: this.state.firstDay,
      currentSummaryDate: null,
    });
  }

  handleCheckBox = () => {
    this.setState({ childcheckbox: !this.state.childcheckbox });
  };

  componentDidMount = async () => {
    await this.getTickets();
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    this.setState(
      {
        all_properties: this.props?.getAllProperties,
      },
      async () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );

    const height = tableHeightScrollBars();
    this.setState({
      finalTableHeight: height,
    });
  };

  GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj);
    this.setState({
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    });
  };

  getTickets = async () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, childcheckbox, timezone, startDate, endDate } = this.state;
      const pageobj = {
        PropertyId,
        timezone,
        childcheckbox,
      };
      if (startDate) {
        pageobj.startDate = startDate;
      }
      if (endDate) {
        pageobj.endDate = endDate;
      }

      const monthFormat = moment_timezone(this.state.firstDay).format("YYYY/MM");
      pageobj.month = monthFormat;
      this.setState({
        newAdd: false,
      });

      await this.props.getReportList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        enforcerList: this.props?.enforcerList?.rows?.length ? JSON.parse(JSON.stringify(this.props.enforcerList.rows)) : [],
        count: this.props.enforcerList.count,
        month: pageobj.startDate,
      });
      this.setState({ loader: false });
    });
  };

  dataTableHandle = (enforcerList) => {
    return (
      <DataTable
        data={this.state.searchValue.length ? this.state.searchedtransactionsList : enforcerList}
        columns={this.state.currentSummaryDate ? this.addNewColumn : this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={this.state.Norow}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleCurrentDate = (date) => {
    this.setState({
      currentSummaryDate: date,
      month: null,
      startDate: null,
      endDate: null,
    });
  };

  handleSearch = () => {
    this.setState({ loader: true }, async () => {
      const { childcheckbox, month, currentSummaryDate, startDate, endDate, selectedPropertyObj, PropertyId, timezone } = this.state;
      const pageobj = {
        childcheckbox,
        PropertyId,
        timezone,
      };
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }

      if (currentSummaryDate) {
        pageobj.currentSummaryDate = currentSummaryDate;
        this.setState({
          newAdd: true,
        });
      }
      if (month) {
        const monthFormat = moment_timezone(month).format("YYYY/MM");
        pageobj.month = monthFormat;
        this.setState({
          newAdd: false,
        });
      }
      if (startDate && endDate) {
        pageobj.startDate = startDate;
        pageobj.endDate = endDate;
      }
      await this.props.getReportList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);

      this.setState({
        enforcerList: JSON.parse(JSON.stringify(this.props.enforcerList.rows)),
        api_response: this.props.api_response,
        count: this.props.enforcerList.count,
      });
      this.setState({ loader: false });
      document.body.classList.remove("foc-open");
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ searchValue: val }, async () => {
      const columnList = ["mon", "month", "date", "enforcer", "issued", "issuedFee", "actualCitations", "actualFee", "actualPaidPer"];
      if (this.state.searchValue.length) {
        if (!this.state.findMore) {
          this.setState(
            {
              searchedtransactionsList: findBySearch(this.state.searchValue, this.state.enforcerList, this.state.enforcerList, columnList, this.props.timezone),
            },
            () => {
              this.setState({
                count: this.state.searchedtransactionsList.length,
              });
            },
          );
        }
      } else {
        this.setState({
          count: this.props.enforcerList.count,
          searchedtransactionsList: [],
          enforcerList: this.props.enforcerList.rows,
        });
      }
    });
  };

  resetAll = async () => {
    this.setState({
      month: this.state.firstDay,
      startDate: this.state.firstDay,
      endDate: this.state.lastDay,
      currentSummaryDate: null,
      PropertyId: this.state.PropertyId,
      childcheckbox: true,
      newAdd: false,
    });
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleExport = async () => {
    const { childcheckbox, month, currentSummaryDate, startDate, endDate, selectedPropertyObj, PropertyId, selectedDomainName, timezone } = this.state;
    const pageobj = {
      childcheckbox,
      PropertyId,
      timezone,
      exportData: true,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (month) {
      const monthFormat = moment_timezone(month).format("YYYY/MM");
      pageobj.month = monthFormat;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    const bufferData = await this.props.getReportList(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `BY_ENFOCER-${currentTimeDate}-${selectedDomainName}.xls`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  handleMonth = (date) => {
    this.setState({
      month: date,
      startDate: null,
      endDate: null,
      currentSummaryDate: null,
      loader: false,
    });
  };

  render() {
    const { enforcerList, loader, childcheckbox, currentSummaryDate, searchValue, month } = this.state;

    return (
      <div>
        <Card className="mb-0">
          <Card.Body className="pos-rel-overflow-hide">
            <>
              <div className="sidebarFilter">
                <Card className="contact-container mb-0">
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title as="h4">By Enforcer</Card.Title>
                      <a
                        onClick={this.hideSlider}
                        href="javascript:void(0);"
                        className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <label className="fancy-checkbox">
                            <input
                              type="checkbox"
                              name={"childcheckbox"}
                              checked={childcheckbox}
                              onChange={this.handleCheckBox}
                              className="custom-control-input"
                              id="childcheckbox1"
                            ></input>
                            <span>
                              <i></i>Include Children Sites
                            </span>
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Specific Date:</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Date"
                            selected={currentSummaryDate}
                            value={currentSummaryDate}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleCurrentDate}
                            dateFormat="MM/dd/yyyy"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Specific Month:</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Month"
                            selected={month}
                            value={month}
                            disabled={loader}
                            onChange={this.handleMonth}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-right justify-content-between d-flex">
                    <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                      Reset
                    </Button>
                    <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                      Search
                    </Button>
                  </Card.Footer>
                </Card>
              </div>
              <header>
                <div className="active-permit-header sm-flex-column sm-align-items-start">
                  <h5 className="mb-2 mb-md-0"></h5>
                  <div className="filter-button d-flex flex-wrap">
                    <div className="d-flex align-items-center search-mb-0">
                      <DatatableHeader
                        className="mt-0 mb-0"
                        searchValue={searchValue}
                        HandleChange={this.handleSearchChange}
                        // findMorePressed={this.findMorePressed}
                      />
                    </div>
                    <div className="ml-2">
                      <Button variant="info" className="btn-fill btn-sm" onClick={this.handleExport} disabled={enforcerList.length > 0 ? 0 : 1} size="medium">
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </header>
            </>
            {loader ? (
              <Loader />
            ) : (
              <>
                {enforcerList && <div className="citation-list-table has-parent-tab">{this.dataTableHandle(enforcerList)}</div>}
                <DateRangeAndAmount startDateTime={this.state.startDateTime} endDateTime={this.state.endDateTime} />
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails, SuperAdminDetails, TicketDetails }) => {
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;

  const { backgroundColor } = Logindetails;
  const { getAllProperties } = SuperAdminDetails;
  const { MainBackground: main_body } = backgroundColor;
  const { enforcerList } = TicketDetails;
  return {
    PropertyId,
    timezone,
    property,
    enforcerList,
    main_body,
    getAllProperties,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  getReportList: TicketActions.getByEnfocerList,
};

export default connect(mapStateToProps, mapStateToDispatch)(ByEnfocer);

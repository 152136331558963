const types = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGIN_INFO: "USER_LOGIN_INFO",
  TOGGLE_PROPERTY_MODAL: "TOGGLE_PROPERTY_MODAL",
  USER_PROFILE_INFO: "USER_PROFILE_INFO",
  SUBDOMIAN_NOT_EXIT_ERROR: "SUBDOMIAN_NOT_EXIT_ERROR",
  IS_SUBDOMIAN_NOT_EXIT: "IS_SUBDOMIAN_NOT_EXIT",
  SETBACKGROUNDCOLOR: "SETBACKGROUNDCOLOR",
  USER_PROPERTY_INFO: "USER_PROPERTY_INFO",
  SEMI_AUTHENTICATION: "SEMI_AUTHENTICATION",
  HIGH_SECURITY: "HIGH_SECURITY",
  HIGH_SECURITY_RESULT: "HIGH_SECURITY_RESULT",
  HIGH_SECURITY_LOGINOBJ: "HIGH_SECURITY_LOGINOBJ",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
};

export default types;

import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import PropertDetails from "../LandingPage/PropertySelection";
import { useSelector, useDispatch } from "react-redux";
import * as SuperAdminAction from "../../Actions/SuperAdminAction/actions";
import { getUnitInvoiceList } from "../../Actions/ReportsAction/action";
import { saveAs } from "file-saver";
import Loader from "../Loader/Loader";
import CustomContentAlert, { tableHeightScrollBars, findBySearch, scroolToTop, isEmailValid } from "../helper";
import moment from "moment";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";

const InvoiceDetails = (props) => {
  const today = new Date();
  const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
  const urlParams = new URLSearchParams(location?.search?.split("?")[1]);
  const unit_id = decodeURIComponent(urlParams.get("unit_id"));
  const date = urlParams.get("month_year");
  const property_id = urlParams.get("property_id");
  const invoiceData = unit_id && date;
  const dispatch = useDispatch();
  const unitInvoiceList = useSelector((state) => state.ReportsDetails.unitInvoiceList);
  const apiResponse = useSelector(getAPIResponse);
  const exportList = useSelector((exportState) => exportState.ReportsDetails.invoiceExportList);
  const invoiceDownloadData = useSelector((invoice) => invoice.ReportsDetails.invoiceDownloadData);
  const UserData = useSelector((userState) => userState.UserProfileDetails);
  const selectAllProperty = useSelector((propertyState) => propertyState.SuperAdminDetails.getAllProperties);
  const [apiCalled, setApiCalled] = useState(false);
  const [apiMailCalled, setMailApiCalled] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [state, setState] = useState({
    selectedProperty: UserData.property.id,
    selectedPropertyObj: {},
    childcheckbox: true,
    startDate: new Date(datebeforeThirtyDays),
    endDate: new Date(),
    currentDate: null,
    timezone: UserData.timezone,
    exportValue: false,
    selectedDomainName: "",
    exportButtonDisable: true,
    finalTableHeight: 0,
    searchValue: "",
    count: 0,
    loader: false,
    invoiceList: [],
    searchedInvoiceList: [],
    showInvoiceModal: false,
    pdfLoader: false,
    emailToSend: "",
    permitId: "",
    isSendMailModalOpen: false,
    emailError: "",
    isMailFormValid: false,
  });

  React.useEffect(() => {
    const height = tableHeightScrollBars();
    dispatch(SuperAdminAction.get_all_properties());
    setState((prev) => ({
      ...prev,
      finalTableHeight: height,
    }));
  }, []);

  useEffect(() => {
    const body = {
      property_id: property_id,
      childcheckbox: state.childcheckbox,
      timezone: state.timezone,
      exportValue: state.exportValue,
      unit_id: unit_id || "",
      month_year: date || "",
    };
    if (unit_id && date) {
      dispatch(getUnitInvoiceList(body));
    } else {
      const history = require("../../history").default;
      history.push({
        pathname: `/dashboard`,
      });
    }
    setApiCalled(true);
  }, [UserData?.property_id]);

  useEffect(() => {
    const selectedProperty = selectAllProperty.find((item) => item.id === state.selectedProperty);
    const selectedPropertyName = UserData.property?.property_name;
    const selectedDomainName = UserData.property?.domain;
    if (selectedProperty) {
      setState((prev) => ({
        ...prev,
        selectedPropertyObj: {
          label: selectedProperty.site_name,
          value: selectedProperty.id,
        },
        selectedDomainName: selectedDomainName,
      }));
    }
    dispatch(SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName));
  }, [selectAllProperty]);

  React.useEffect(() => {
    if (unitInvoiceList && apiCalled === true) {
      setState((prev) => ({
        ...prev,
        invoiceList: unitInvoiceList.rows,
        count: unitInvoiceList.count,
        loader: false,
      }));
    }
  }, [unitInvoiceList]);

  useEffect(() => {
    if (state.searchedInvoiceList && state.searchedInvoiceList.length > 0) {
      setState((prev) => ({
        ...prev,
        count: state.searchedInvoiceList.length,
      }));
    }
  }, [state.searchedInvoiceList]);

  useEffect(() => {
    if (exportList) {
      const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
      const exportDataName = `INVOICE-${currentTimeDate}-${state.selectedDomainName}.xls`;
      if (apiCalled === true) {
        setApiCalled(false);
        saveAs(
          new Blob([exportList], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
          exportDataName,
        );
      }
    }
  }, [exportList]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Domain",
      width: "140px",
      sortable: true,
      cell: (row) => showDomainField(row),
      selector: (row) => row?.properties?.domain,
    },
    {
      name: "Min Permit #",
      width: "140px",
      sortable: true,
      cell: (row) => showMinPermitField(row),
      selector: (row) => row.min_permit,
    },
    {
      name: "Daily Amount $",
      width: "140px",
      sortable: true,
      cell: (row) => showDailyAmountField(row),
      selector: (row) => row.daily_amount,
    },
    {
      name: "Monthly Amount $",
      width: "160px",
      sortable: true,
      cell: (row) => showMonthlyAmountField(row),
      selector: (row) => row.monthly_amount,
    },
    {
      name: "Lot Name",
      width: "140px",
      sortable: true,
      cell: (row) => showLotNameField(row),
      selector: (row) => row?.lots?.alias,
    },
    {
      name: "Package Name",
      width: "140px",
      sortable: true,
      cell: (row) => showPackageNameField(row),
      selector: (row) => row?.paid_permit_options?.package_name,
    },
    {
      name: "Permit #",
      width: "130px",
      sortable: true,
      cell: (row) => showPermitAutoRenewCreatedTotal(row),
      selector: (row) => row?.permit_autorenew_created_total,
    },
    {
      name: "Permit $",
      width: "130px",
      sortable: true,
      cell: (row) => showPermitAutoRenewAmount(row),
      selector: (row) => row.permit_autorenew_amount,
    },
    {
      name: "Partial Permit #",
      width: "140px",
      sortable: true,
      cell: (row) => showPartialTotalCount(row),
      selector: (row) => row.partial_total,
    },
    {
      name: "Partial Permit $",
      width: "140px",
      sortable: true,
      cell: (row) => showPartialTotalAmount(row),
      selector: (row) => row.partial_total_amount,
    },
    {
      name: "Cancellation Permit #",
      width: "180px",
      sortable: true,
      cell: (row) => showCancellationTotal(row),
      selector: (row) => row.cancellation_total,
    },
    {
      name: "Refund $",
      width: "130px",
      sortable: true,
      cell: (row) => showRefundTotalAmount(row),
      selector: (row) => row.refund_total_amount,
    },
    {
      name: "Permit Share group #",
      width: "180px",
      sortable: true,
      cell: (row) => showPermitShareGroupTotalNumber(row),
      selector: (row) => row.permit_share_group_total,
    },
    {
      name: "Permit Share group $",
      width: "180px",
      sortable: true,
      cell: (row) => showPermitShareGroupTotalAmount(row),
      selector: (row) => row.permit_share_group_total_amount,
    },
    {
      name: "Discount Coupons Total #",
      width: "200px",
      sortable: true,
      cell: (row) => showDiscountCouponTotalNumber(row),
      selector: (row) => row?.discount_coupons_total,
    },
    {
      name: "Discount Coupons Total Amount $",
      width: "240px",
      sortable: true,
      cell: (row) => showDiscountCouponTotalAmount(row),
      selector: (row) => row?.discount_coupons_total_amount,
    },
    {
      name: "Total Visitor Permits #",
      width: "200px",
      sortable: true,
      cell: (row) => showTotalVisitorPermitsCount(row),
      selector: (row) => row?.total_visitor_permits,
    },
    {
      name: "Visitor Permit Total Amount $",
      width: "240px",
      sortable: true,
      cell: (row) => showVisitorPermitTotalAmount(row),
      selector: (row) => row?.total_visitor_permits_amount,
    },
    {
      name: "Total Amount",
      width: "140px",
      sortable: true,
      cell: (row) => showTotalAmount(row),
      selector: (row) => row.total_amount,
    },
  ];

  const showTotalAmount = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${row.total_amount}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-3">${row.total_amount}</div>
      </OverlayTrigger>
    </>
  );

  const showVisitorPermitTotalAmount = (row) => (
    <>
      {row?.total_visitor_permits_amount ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${row?.total_visitor_permits_amount}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">${row?.total_visitor_permits_amount}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showTotalVisitorPermitsCount = (row) => (
    <>
      {row?.total_visitor_permits ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.total_visitor_permits}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row?.total_visitor_permits}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showDiscountCouponTotalAmount = (row) => (
    <>
      {row?.discount_coupons_total_amount ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${row?.discount_coupons_total_amount}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">${row?.discount_coupons_total_amount}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showDiscountCouponTotalNumber = (row) => (
    <>
      {row?.discount_coupons_total ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.discount_coupons_total}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row?.discount_coupons_total}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showPermitShareGroupTotalAmount = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${row.permit_share_group_total_amount}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-3">${row.permit_share_group_total_amount}</div>
      </OverlayTrigger>
    </>
  );

  const showPermitShareGroupTotalNumber = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.permit_share_group_total}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-3">{row.permit_share_group_total}</div>
      </OverlayTrigger>
    </>
  );

  const showRefundTotalAmount = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${row.refund_total_amount}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-3">${row.refund_total_amount}</div>
      </OverlayTrigger>
    </>
  );

  const showCancellationTotal = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.cancellation_total}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-3">{row.cancellation_total}</div>
      </OverlayTrigger>
    </>
  );

  const showPartialTotalAmount = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${row.partial_total_amount}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-3">${row.partial_total_amount}</div>
      </OverlayTrigger>
    </>
  );

  const showPartialTotalCount = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.partial_total}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-3">{row.partial_total}</div>
      </OverlayTrigger>
    </>
  );

  const showPermitAutoRenewAmount = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>${row.permit_autorenew_amount}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-3">${row.permit_autorenew_amount}</div>
      </OverlayTrigger>
    </>
  );

  const showPermitAutoRenewCreatedTotal = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.permit_autorenew_created_total}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-3">{row?.permit_autorenew_created_total}</div>
      </OverlayTrigger>
    </>
  );

  const showPackageNameField = (row) => (
    <>
      {row?.paid_permit_options?.package_name ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{row?.paid_permit_options?.package_name}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-3">{row?.paid_permit_options?.package_name}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showLotNameField = (row) => (
    <>
      {row?.lots?.alias ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.lots?.alias}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-3">{row?.lots?.alias}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showMonthlyAmountField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.monthly_amount}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-3">{row.monthly_amount}</div>
      </OverlayTrigger>
    </>
  );

  const showDailyAmountField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.daily_amount}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-3">{row.daily_amount}</div>
      </OverlayTrigger>
    </>
  );

  const showMinPermitField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.min_permit}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-3">{row.min_permit}</div>
      </OverlayTrigger>
    </>
  );

  const showDomainField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.properties?.domain}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-3">{row?.properties?.domain}</div>
      </OverlayTrigger>
    </>
  );

  useEffect(() => {
    if (apiMailCalled === true) {
      setState({
        ...state,
        isSendMailModalOpen: false,
      });
      setMailApiCalled(false);
      setIsSuccess(apiResponse?.api_response === true);
      setIsFailed(apiResponse?.api_response === false);
      setTimeout(() => {
        setIsSuccess(false);
        setIsFailed(false);
      }, 3000);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (invoiceDownloadData) {
      setState((prev) => ({
        ...prev,
        pdfUrl: invoiceDownloadData,
        pdfLoader: false,
      }));
    }
  }, [invoiceDownloadData]);

  const dataTableHandle = (data) => {
    return (
      <DataTable
        data={state.searchValue?.length ? state.searchedInvoiceList : data}
        columns={columns}
        customStyles={customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  const handleSearchChange = async ({ target }) => {
    setState((prev) => ({ ...prev, searchValue: target.value }));
    const columnList = [
      "unit_id",
      "min_permit",
      "permit_autorenew_created_total",
      "permit_autorenew_amount",
      "partial_total",
      "partial_total_amount",
      "cancellation_total",
      "refund_total_amount",
      "permit_share_group_total",
      "permit_share_group_total_amount",
      "total_amount",
      "created_at",
    ];
    if (target.value.length) {
      if (!state.findMore) {
        setState((prev) => ({
          ...prev,
          searchedInvoiceList: findBySearch(target.value, state.invoiceList, state.invoiceList, columnList, state.timezone),
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        count: unitInvoiceList.count,
        searchedInvoiceList: [],
        invoiceList: unitInvoiceList.rows,
      }));
    }
  };

  const handleExport = async () => {
    setState({ ...state, exportValue: true });
    const {
      childcheckbox,
      timezone,
      //  startDate,
      //  endDate,
      //  currentDate,
    } = state;
    const pageobj = {
      childcheckbox,
      timezone,
      exportValue: true,
      //  startDate,
      //  endDate,
      //  currentDate,
      unit_id: unit_id,
      month_year: date || "",
    };

    pageobj.property_id = property_id;

    dispatch(getUnitInvoiceList(pageobj));
    setApiCalled(true);
  };

  const resetAll = async () => {
    setState((prev) => ({
      ...prev,
      selectedProperty: UserData.property.id,
      selectedPropertyObj: {
        label: UserData.property.property_name,
        value: UserData.property.id,
      },
      childcheckbox: true,
      unit_id: unit_id,
      month_year: date || "",
    }));
  };

  const handleSearch = () => {
    setState((prev) => ({ ...prev, loader: true }));
    const { childcheckbox, currentDate, startDate, endDate, timezone } = state;
    const pageobj = {
      childcheckbox,
      timezone,
    };
    pageobj.property_id = property_id;
    if (currentDate) {
      pageobj.currentDate = currentDate;
    } else if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    pageobj.unit_id = unit_id;
    pageobj.month_year = date || "";
    dispatch(getUnitInvoiceList(pageobj));
    document.body.classList.remove("foc-open");
  };

  const hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  const handleCheckBox = () => {
    setState((prev) => ({
      ...prev,
      childcheckbox: !state.childcheckbox,
    }));
  };

  const changeProperty = (event) => {
    setState((prev) => ({
      ...prev,
      selectedProperty: event.value,
      selectedPropertyObj: event,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentDate: null,
    }));
  };

  const redirectToInvoiceReport = () => {
    const history = require("../../history").default;
    history.push({
      pathname: `/report-invoice`,
      search: `?month_year=${date}&property_id=${property_id}`,
    });
  };

  useEffect(() => {
    if (state.isSendMailModalOpen) {
      if (!state.emailToSend.trim().length) {
        setState({
          ...state,
          emailError: "Please Enter email!",
        });
      }
      if (state.emailToSend.trim().length) {
        setState({
          ...state,
          emailError: !isEmailValid(state.emailToSend) ? "Email is invalid!" : "",
          isMailFormValid: isEmailValid(state.emailToSend),
        });
      }
    }
  }, [state.emailToSend]);

  return (
    <div>
      <Card className="mb-0 manage-unit-list-page page-no-fliter">
        <Card.Body className="pos-rel-overflow-hide">
          <>
            <div className="sidebarFilter">
              <Card className="contact-container mb-0">
                <Card.Header // style={{ backgroundColor: this.props.main_body }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <Card.Title as="h4">Permit Invoice Report</Card.Title>
                    <a
                      onClick={hideSlider}
                      href="javascript:void(0);"
                      className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md="12">
                      <div className="mb-2">
                        <label className="fancy-checkbox">
                          <input
                            type="checkbox"
                            name={"childcheckbox"}
                            checked={state.childcheckbox}
                            onChange={handleCheckBox}
                            className="custom-control-input"
                            id="childcheckbox1"
                          ></input>
                          <span>
                            <i></i>Include Children Sites
                          </span>
                        </label>
                      </div>
                    </Col>
                    <Col md="12">
                      <PropertDetails selectedPropertyObj={state.selectedPropertyObj} changeProperty={changeProperty.bind(this)} />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-right justify-content-between d-flex">
                  <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={resetAll} disabled={state.loader}>
                    Reset
                  </Button>
                  <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={handleSearch} disabled={state.loader}>
                    Search
                  </Button>
                </Card.Footer>
              </Card>
            </div>
            <header>
              <div className="active-permit-header sm-flex-column sm-align-items-start">
                <div>
                  <h5 className="mb-2 mb-md-0">
                    {" "}
                    <i style={{ marginRight: 5, cursor: "pointer" }} className="fa fa-arrow-left" onClick={redirectToInvoiceReport} aria-hidden="true"></i>
                    Permit Invoice Reports{" "}
                  </h5>
                </div>
                <div className="filter-button d-flex flex-wrap">
                  <div className="d-flex align-items-center search-mb-0">
                    <DatatableHeader className="mt-0 mb-0" searchValue={state.searchValue} HandleChange={handleSearchChange} />
                  </div>
                  <div className="ml-2">
                    <Button variant="info" className="btn-fill btn-sm" onClick={handleExport} disabled={state.invoiceList?.length > 0 ? 0 : 1} size="medium">
                      Export
                    </Button>
                  </div>
                </div>
              </div>
            </header>
            <div className="fs-1 mb-3" style={{ fontSize: "13px" }}>
              {invoiceData && (
                <div className="mt-3">
                  <Row>
                    <Col lg={3} md={6} xs={12}>
                      <b>Unit Id:</b> {unit_id}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={6} xs={12}>
                      <b>Year-Month: </b>
                      {date}
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </>
          {state.loader ? (
            <Loader />
          ) : (
            <>
              {state.invoiceList && (
                <>
                  <div className="citation-list-table">{dataTableHandle(state.invoiceList)}</div>
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
      {isSuccess && <CustomContentAlert delay={2000} message={apiResponse?.api_success_message} className="toast-success" />}
      {isFailed && <CustomContentAlert delay={2000} message={apiResponse?.api_error_message} className="toast-error" />}
    </div>
  );
};

export default InvoiceDetails;

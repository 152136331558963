import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import * as ReportsAction from "../../Actions/ReportsAction/action";
import { useDispatch, useSelector } from "react-redux";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import CustomContentAlert from "../helper";

const SendRefundModal = ({ closeModal, rowData }) => {
  const [apiCalled, setApiCalled] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [state, setState] = React.useState({
    cash_float: +rowData?.cash_float?.toString() || 0,
    audited_amount: +rowData?.last_audited_cashout?.toString() || 0,
  });
  const dispatch = useDispatch();

  const apiResponse = useSelector(getAPIResponse);
  const api_success_message = useSelector((state) => state.APIResponseDetails.api_success_message);
  const api_error_message = useSelector((state) => state.APIResponseDetails.api_error_message);

  const cashoutSubmit = async () => {
    const Data = {
      ...rowData,
      action: "update",
      last_audited_cashout: state.audited_amount,
      cash_float: state.cash_float,
    };
    dispatch(ReportsAction.createUpdateCashout(Data));
    setApiCalled(true);
  };

  const handleChange = useCallback(
    (e) => {
      if (!isNaN(e.target.value)) {
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      }
    },

    [state.amount, state.amountError, state.reasonError],
  );

  useEffect(() => {
    if (apiCalled === true) {
      setApiCalled(false);
      setIsSuccess(apiResponse?.api_response === true);
      setIsFailed(apiResponse?.api_response === false);
      setTimeout(() => {
        closeModal();
        setIsSuccess(false);
        setIsFailed(false);
        console.log(isFailed, isSuccess);
      }, 2000);
    }
  }, [apiResponse]);

  return (
    <React.Fragment>
      <Modal
        id="ticketdetails"
        lg={"medium"}
        show={true}
        // onHide={closeModal}
        dialogClassName="modal-90w"
        fullscreen={"sm-down"}
        className="modalData"
      >
        <Modal.Header id="import" className="d-flex justify-content-between bg-white align-items-center">
          <h5 className="m-0 font-weight-bold">
            <Modal.Title className="m-0">Update Cashout</Modal.Title>
          </h5>
          <Button id="modal" title={"Close"} type="button" onClick={closeModal} className="close-btn py-0" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form>
            {rowData.type === "float" && (
              <>
                <Form.Label>
                  Case Float
                  <span className="asterisk">*</span>
                </Form.Label>
                <Form.Control type="text" name="cash_float" value={state.cash_float} onChange={handleChange} autoComplete="off" required />
              </>
            )}
            {rowData.type === "audited_amount" && (
              <>
                <Form.Label>
                  Audited Amount
                  <span className="asterisk">*</span>
                </Form.Label>
                <Form.Control type="text" name="audited_amount" value={state.audited_amount} onChange={handleChange} autoComplete="off" required />
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button // disabled={refundFormValid}
            onClick={cashoutSubmit}
          >
            Update
          </Button>
          <Button onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      {api_success_message && isSuccess && <CustomContentAlert delay={4000} message={api_success_message} className={"toast-success"} />}
      {api_error_message && isFailed && <CustomContentAlert delay={4000} message={api_error_message} className={"toast-success"} />}
    </React.Fragment>
  );
};

export default SendRefundModal;

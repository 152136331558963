import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import { fetchResidentList } from "../../Actions/CurrentPermitActions/actions";
import { get_all_lots } from "../../Actions/SuperAdminAction/actions";
import {
  getCardDetails,
  unsetCardDetail,
  closePermitMaxLimitWarningModal,
  deleteUnitPermitByLotAndPackage,
  updateUnitPermitOptionsLotPackage,
  unSetToasterMessage,
  getAllPermitOptionsByLots,
  DeleteCreditCardDetails,
} from "../../Actions/PermitOptionsActions/actions";
import CustomContentAlert, { validateCardNumber } from "../helper";
import moment from "moment";
import Loader from "../Loader/Loader";
import ConfirmationModal from "../Common/ConfirmationModal";
import DeleteCardModal from "../Modal/DeleteCardModal";

const UnitPermitOptionsModal = (props) => {
  const propertyId = useSelector((state) => state.UserProfileDetails?.property?.id);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    residentList: [],
    lotList: [],
    packageList: [],
    description: "",
    unit_id: "",
    lot_package: [],
    max_permits: "",
    min_permits: "",
    carpool_size: "",
    payment_type: "invoice",
    credit_card_info: "",
    is_form_valid: "",
    errors: {},
    card_name: "",
    card_email: "",
    card_expiry: "",
    card_cvv: "",
    card_number: "",
    disable_card_options: true,
    disableButton: false,
    loader: true,
    warningMsg: "",
    cardError: false,
    submitLoader: false,
    showConfirmationModal: false,
    selected_row_id: "",
    show_alert: false,
    show_error: false,
    show_delete_card_model: false,
    isDeleteCard: false,
    card_details_id: "",
    allow_permit_share: true,
  });

  const { api_response, api_success_message, api_error_message, api_failed_attempt_count } = useSelector((state) => state.APIResponseDetails);

  const allLots = useSelector((state) => state.SuperAdminDetails.lots);

  const residentListData = useSelector((state) => state.CurrentPermitDetails.residentList);

  const cardDetailData = useSelector((state) => state.PermitOptionsDetails.cardDetails);

  const showMaxPermitWarning = useSelector((state) => state.PermitOptionsDetails.showMaxPermitWarning);

  const maxLimitModalWarningMessage = useSelector((state) => state.PermitOptionsDetails.maxLimitModalWarningMessage);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      loader: false,
    }));
  }, []);

  useEffect(() => {
    dispatch(fetchResidentList(propertyId));
    dispatch(get_all_lots(propertyId, "", false, true));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(unsetCardDetail());
      dispatch(closePermitMaxLimitWarningModal());
      dispatch(unSetToasterMessage());
      setFormData((prevState) => ({
        ...prevState,
        warningMsg: "",
      }));
    };
  }, []);

  useEffect(() => {
    if (api_response && api_success_message) {
      if (api_success_message !== "Unit Permit deleted successfully!" && api_success_message !== "Card Details deleted successfully!") {
        showSuccessAlert();

        setTimeout(() => {
          props.closeModal();
        }, 1000);
      } else {
        dispatch(getCardDetails(props.property_id, props.selectedRowData.suite_id));
        showSuccessAlert();
        setFormData((prevState) => ({
          ...prevState,
          submitLoader: false,
        }));
      }
    } else {
      if (api_error_message) {
        setFormData((prevState) => ({
          ...prevState,
          submitLoader: false,
        }));
        showErrorAlert();
      }
      if (showMaxPermitWarning) {
        setFormData((prevState) => ({
          ...prevState,
          warningMsg: maxLimitModalWarningMessage,
          // submitLoader: false,
        }));
        showErrorAlert();
      } else {
        setFormData((prevState) => ({
          ...prevState,
          warningMsg: "",
          // submitLoader: false,
        }));
      }
    }
  }, [api_response, api_success_message, showMaxPermitWarning, api_error_message, api_failed_attempt_count]);

  const showErrorAlert = () => {
    setFormData((prevState) => ({
      ...prevState,
      show_error: true,
    }));
    setTimeout(() => {
      setFormData((prevState) => ({
        ...prevState,
        show_error: false,
      }));
    }, 4000);
  };

  const showSuccessAlert = () => {
    setFormData((prevState) => ({
      ...prevState,
      show_alert: true,
    }));
    setTimeout(() => {
      setFormData((prevState) => ({
        ...prevState,
        show_alert: false,
      }));
    }, 4000);
  };

  const getSetEdit = async () => {
    const { suite_id, max_permit, lot_packages, min_permit, description, payment_type, allow_permit_share } = props.selectedRowData;

    const lotPackage = [];
    for (const item of lot_packages) {
      const packageListDatas = await dispatch(getAllPermitOptionsByLots(propertyId, item.lot_id));
      lotPackage.push({
        packageList: packageListDatas,
        lot_id: item.lot_id,
        package_name: item.package_name,
        min_permit: item.min_permit,
        max_permit: item.max_permit,
        issued: item.issued,
        lotList: allLots,
        error: {},
        disable: props.ifEdit,
      });
    }
    setFormData((prevState) => ({
      ...prevState,
      property_id: props.property_id,
      unit_id: suite_id,
      allow_permit_share: allow_permit_share,
      lot_package: [...lotPackage],
      max_permits: max_permit,
      description: description,
      min_permits: min_permit,
      payment_type: payment_type,
    }));
  };

  useEffect(() => {
    if (props.ifEdit) {
      if (props.selectedRowData.payment_type === "main_card") {
        dispatch(getCardDetails(props.property_id, props.selectedRowData.suite_id));
      }
      getSetEdit();
    }
  }, [props.ifEdit, allLots]);

  useEffect(() => {
    if (cardDetailData && Object.keys(cardDetailData).length && props.ifEdit === true) {
      setFormData((prevState) => ({
        ...prevState,
        card_name: cardDetailData?.name,
        card_email: cardDetailData?.email,
        card_number: cardDetailData?.card_number, // `************${cardDetailData.last_four_card_number}`,
        card_expiry: cardDetailData?.expiry_date,
        card_cvv: "",
        card_details_id: cardDetailData?.id,
        disable_card_options: true,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        card_name: "",
        card_email: "",
        card_number: "",
        card_expiry: "",
        card_cvv: "",
        card_details_id: "",
        disable_card_options: false,
      }));
    }
  }, [cardDetailData]);

  useEffect(() => {
    if (residentListData?.length) {
      setFormData((prevState) => ({
        ...prevState,
        residentList: residentListData,
      }));
    }

    if (allLots?.length) {
      setFormData((prevState) => ({
        ...prevState,
        lotList: allLots,
      }));
    }
  }, [residentListData, allLots]);

  const handleChange = ({ target }) => {
    let { name, value } = target;

    if (name === "email") {
      value = value.replace(/\s/g, "");
    }
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: value,
      errors: {
        ...prevState.errors,
        [target.name]: "",
      },
    }));
  };

  const handleChangeMinPermit = (e, index) => {
    const lot_packageListItem = formData.lot_package[index];
    if (e.target.value.toString().length) {
      lot_packageListItem.error = {
        ...lot_packageListItem.error,
        min_permit: "",
      };
      lot_packageListItem.min_permit = e.target.value;
    } else {
      lot_packageListItem.error = {
        ...lot_packageListItem.error,
        min_permit: `Min Permit can't be empty`,
      };
    }
    formData.lot_package[index] = lot_packageListItem;
    setFormData((prevState) => ({
      ...prevState,
      lot_package: [...formData.lot_package],
    }));
  };

  const handleMaxPermitChange = (e, index) => {
    const lot_packageListItem = formData.lot_package[index];
    lot_packageListItem.max_permit = e.target.value;
    const errors = {
      ...lot_packageListItem.error,
    };
    if (parseInt(e.target.value) <= parseInt(lot_packageListItem.min_permit)) {
      errors.max_permit = "Max Number of permits should be more than Min Number of permits";
    } else {
      errors.max_permit = "";
    }
    lot_packageListItem.error = errors;
    formData.lot_package[index] = lot_packageListItem;
    setFormData((prevState) => ({
      ...prevState,
      lot_package: [...formData.lot_package],
    }));
  };

  const handleChangeLot = async (e, index) => {
    const lots = formData.lot_package;
    const lot_packageListItem = lots[index];
    if (e.target.value) {
      lot_packageListItem.lot_id = e.target.value;
      const packageListDatas = await dispatch(getAllPermitOptionsByLots(propertyId, e.target.value));
      lot_packageListItem.packageList = packageListDatas;
      lot_packageListItem.error = {
        ...lot_packageListItem.error,
        lot_id: "",
        package_name: "",
      };
      lots[index] = lot_packageListItem;
      setFormData({
        ...formData,
        lot_package: [...lots],
      });
    } else {
      lot_packageListItem.lot_id = "";
      lot_packageListItem.error = {
        ...lot_packageListItem.error,
        lot_id: "Lot can't be empty",
      };
      lots[index] = lot_packageListItem;
      setFormData({
        ...formData,
        lot_package: [...lots],
      });
    }
  };

  const handleChangeUnit = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        errors: {
          ...prevState.errors,
          [e.target.name]: "",
        },
      };
    });
  };

  const handleChangePermit = (e, lot_id, index) => {
    const lot_packageListItem = formData.lot_package[index];
    if (e.target.value && lot_id) {
      const alreadyExistsCheck = formData.lot_package.filter((item) => item.lot_id === lot_id && item.package_name === e.target.value);
      if (alreadyExistsCheck.length) {
        lot_packageListItem.error = {
          ...lot_packageListItem.error,
          package_name: "This Package Already Selected.",
        };
      } else {
        lot_packageListItem.error = {
          ...lot_packageListItem.error,
          lot_id: "",
          package_name: "",
        };
        lot_packageListItem.package_name = e.target.value;
      }
    } else {
      lot_packageListItem.package_name = "";
      lot_packageListItem.error = {
        ...lot_packageListItem.error,
        package_name: "Package can't be empty",
      };
    }
    formData.lot_package[index] = lot_packageListItem;
    setFormData({
      ...formData,
      lot_package: [...formData.lot_package],
    });
  };

  const handleCalender = (date) => {
    if (date) {
      setFormData((prevState) => ({
        ...prevState,
        card_expiry: date,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        card_expiry: "",
      }));
    }
  };

  const checkValidations = () => {
    let is_form_valid = true;
    const errors = {};
    const { unit_id, lot_package, payment_type, card_name, card_email, card_number, card_cvv, card_expiry, disable_card_options } = formData;
    if (!lot_package.length) {
      is_form_valid = false;
      errors.lot_package = `Add atleast one Lot & Package`;
    } else {
      const lotPackage = lot_package.map((item) => {
        const error = {};
        if (item.lot_id === "") {
          is_form_valid = false;
          error.lot_id = "Lot can't be empty";
        }
        if (item.package_name === "") {
          is_form_valid = false;
          error.package_name = "Package can't be empty";
        }
        if (!item.min_permit.toString().length) {
          is_form_valid = false;
          error.min_permit = `Min Permit can't be empty`;
        }
        if (!item.max_permit.toString().length) {
          is_form_valid = false;
          error.max_permit = `Max Permit can't be empty`;
        }
        if (item.min_permit.toString().length && item.max_permit.toString().length) {
          if (parseInt(item.max_permit) <= parseInt(item.min_permit)) {
            is_form_valid = false;
            error.max_permit = "Max Number of permits should be more than Min Number of permits";
          }
        }
        return {
          ...item,
          error: error,
        };
      });
      setFormData({
        ...formData,
        lot_package: [...lotPackage],
      });
    }
    if (!payment_type.toString().length) {
      is_form_valid = false;
      errors.payment_type = `Payment type can't be empty`;
    }
    if (!unit_id.trim().length) {
      is_form_valid = false;
      errors.unit_id = `Unit can't be empty`;
    }
    if (payment_type.toString().length && payment_type === "main_card") {
      if (props.ifEdit) {
        if (!disable_card_options) {
          if (!card_name.toString().length) {
            is_form_valid = false;
            errors.card_name = `Name can't be empty`;
          }
          if (!card_email.toString().length) {
            is_form_valid = false;
            errors.card_email = `Email can't be empty`;
          }
          if (!card_number.toString().length) {
            is_form_valid = false;
            errors.card_number = `Card number can't be empty`;
          }
          if (!card_cvv.toString().length) {
            is_form_valid = false;
            errors.card_cvv = `CVV can't be empty`;
          }
          if (!card_expiry.toString().length) {
            is_form_valid = false;
            errors.card_expiry = `Expiry Date can't be empty`;
          }
        }
      } else {
        if (!card_name.toString().length) {
          is_form_valid = false;
          errors.card_name = `Name can't be empty`;
        }
        if (!card_email.toString().length) {
          is_form_valid = false;
          errors.card_email = `Email can't be empty`;
        }
        if (!card_number.toString().length || formData.cardError) {
          is_form_valid = false;
          errors.card_number = formData.cardError ? "Please enter valid card number" : `Card number can't be empty`;
        }
        if (!card_cvv.toString().length) {
          is_form_valid = false;
          errors.card_cvv = `CVV can't be empty`;
        }
        if (!card_expiry.toString().length) {
          is_form_valid = false;
          errors.card_expiry = `Expiry can't be empty`;
        }
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      is_form_valid: is_form_valid,
      errors: errors,
    }));
    return is_form_valid;
  };

  const showError = (type) => {
    let valid = true;
    let errorMsg = "";
    if (type === "unit_id") {
      valid = false;
      errorMsg = formData.errors.unit_id;
    }
    if (type === "lot_package") {
      valid = false;
      errorMsg = formData.errors.lot_package;
    }
    if (type === "description") {
      valid = false;
      errorMsg = formData.errors.description;
    }
    if (type === "min_permits") {
      valid = false;
      errorMsg = formData.errors.min_permits;
    }
    if (type === "max_permits") {
      valid = false;
      errorMsg = formData.errors.max_permits;
    }
    if (type === "payment_type") {
      valid = false;
      errorMsg = formData.errors.payment_type;
    }
    if (type === "card_name") {
      valid = false;
      errorMsg = formData.errors.card_name;
    }
    if (type === "card_number") {
      valid = false;
      errorMsg = formData.errors.card_number;
    }
    if (type === "card_email") {
      valid = false;
      errorMsg = formData.errors.card_email;
    }
    if (type === "card_expiry") {
      valid = false;
      errorMsg = formData.errors.card_expiry;
    }
    if (type === "card_cvv") {
      valid = false;
      errorMsg = formData.errors.card_cvv;
    }

    if (!valid) {
      return <div className="help-block">{errorMsg}</div>;
    }
    return true;
  };

  const handleAddUnitOptions = async () => {
    const { unit_id, lot_package, payment_type, card_name, card_email, card_number, card_expiry, card_cvv, disable_card_options, warningMsg } = formData;
    const card_expiry_ = moment(new Date(card_expiry)).format("YYYY-MM-DD hh:mm:ss");
    let data;
    let valid = false;
    if (checkValidations()) {
      valid = true;
      data = {
        property_id: props.property_id,
        suite_id: unit_id,
        description: "",
        lot_packages: lot_package?.map((item) => {
          return {
            lot_id: item.lot_id,
            package_name: item.package_name,
            min_permit: item.min_permit,
            max_permit: item.max_permit,
            issued: item.issued,
          };
        }),
        payment_type: payment_type,
      };
      if (props.ifEdit) {
        if (!disable_card_options) {
          if (payment_type === "main_card") {
            data.payment_info = {
              card_name: card_name,
              card_email: card_email,
              card_number: card_number,
              card_expiry: card_expiry_,
              card_cvv: card_cvv,
            };
          }
        } else {
          data.payment_info = {};
        }
      } else if (payment_type === "main_card") {
        data.payment_info = {
          card_name: card_name,
          card_email: card_email,
          card_number: card_number,
          card_expiry: card_expiry_,
          card_cvv: card_cvv,
        };
      }
    }
    if (valid) {
      setFormData((prevState) => ({
        ...prevState,
        submitLoader: true,
      }));
      console.log("formData.allow_permit_share", formData.allow_permit_share);
      data.allow_permit_share = formData.allow_permit_share;
      if (props.ifEdit) {
        if (showMaxPermitWarning) {
          data.approvedWarning = true;
        }
        props.addUpdateUnitPermitOptions(data, props.selectedRowId);
      } else {
        props.addUpdateUnitPermitOptions(data);
      }
      console.log(warningMsg);
      if (warningMsg) {
        setFormData((prevState) => ({
          ...prevState,
          submitLoader: false,
        }));
      }
    }
  };

  const editCardDetails = () => {
    setFormData((prevState) => ({
      ...prevState,
      disable_card_options: !formData.disable_card_options,
      card_name: formData.card_name,
      card_email: formData.card_email,
      card_number: "",
      card_expiry: "",
      card_cvv: "",
    }));
  };

  const handleDeleteCard = () => {
    setFormData((prevState) => ({
      ...prevState,
      isDeleteCard: true,
      show_delete_card_model: false,
    }));

    dispatch(DeleteCreditCardDetails(formData.card_details_id));
  };

  const handleShowDeleteCardModal = () => {
    setFormData({
      ...formData,
      isDeleteCard: false,
      show_delete_card_model: !formData.show_delete_card_model,
    });
  };

  const deleteCardDetails = () => {
    setFormData((prevState) => ({
      ...prevState,
      show_delete_card_model: true,
      isDeleteCard: false,
    }));
  };

  const handleChangeCard = async (e) => {
    const errors = {};
    if (e.target.value) {
      const cardData = !/^\d+$/.test(e.target.value);
      if ((!cardData && e.target.value.length <= 16) || e.target.value.length === 0) {
        setFormData((prevState) => ({
          ...prevState,
          card_number: e.target.value,
        }));

        if (e.target.value.length > 14) {
          const validCard = await validateCardNumber(e.target.value);
          if (validCard) {
            errors.card_number = "";
            setFormData((prevState) => ({
              ...prevState,
              errors: errors,
              cardError: false,
              disableButton: true,
            }));
          } else {
            errors.card_number = "Please enter valid card number";
            setFormData((prevState) => ({
              ...prevState,
              errors: errors,
              cardError: true,
              disableButton: false,
            }));
          }
        } else {
          errors.card_number = "Please enter valid 16 digits card number";
          setFormData((prevState) => ({
            ...prevState,
            errors: errors,
            cardError: true,
            disableButton: false,
          }));
        }
      }
    } else {
      errors.card_number = "Card number can't be empty";
      setFormData((prevState) => ({
        ...prevState,
        cardError: false,
        errors: errors,
        card_number: e.target.value,
        disableButton: false,
      }));
    }
  };

  const handleCvvChange = (e) => {
    const cvvdata = !/^\d+$/.test(e.target.value);

    if ((!cvvdata && e.target.value.length <= 4) || e.target.value.length === 0) {
      const errors = {};
      setFormData((prevState) => ({
        ...prevState,
        card_cvv: e.target.value,
      }));
      if (e.target.value.length >= 3) {
        errors.card_cvv = "";
        setFormData((prevState) => ({
          ...prevState,
          errors: errors,
          disableButton: true,
        }));
      } else if (e.target.value.length > 1) {
        errors.card_cvv = "CVV should be at least 3 digits.";
        setFormData((prevState) => ({
          ...prevState,
          errors: errors,
        }));
      } else {
        errors.card_cvv = "";
        setFormData((prevState) => ({
          ...prevState,
          errors: errors,
        }));
      }
    }
  };

  const blockInvalidChar = (e) => {
    return ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
  };

  const showUnitList = () => {
    if (formData?.residentList?.length) {
      return (
        <>
          <option value="">Select Unit</option>
          {formData.residentList.map((item, index) => (
            <>
              <option key={item.suite_id} value={item.suite_id}>
                {item.suite_id}
              </option>
            </>
          ))}
        </>
      );
    } else {
      return <option value="">Select Unit</option>;
    }
  };

  const showLotList = (lotList) => {
    if (lotList?.length) {
      return (
        <>
          <option value="">Select Lots</option>
          {lotList.map((item, index) => (
            <>
              <option key={item.id} value={item.id}>
                {item.alias}
              </option>
            </>
          ))}
        </>
      );
    } else {
      return <option value="">Select Lots</option>;
    }
  };

  const showPackageList = (packageList) => {
    if (packageList?.length) {
      return (
        <>
          <option value="">Select Package</option>
          {packageList.map((item, index) => (
            <>
              <option key={item.package_name} value={item.package_name}>
                {item.package_name}
                {+item?.usage_count > 0 ? ` (${item.usage_count} Per Month)` : " (Unlimited)"}
              </option>
            </>
          ))}
        </>
      );
    } else {
      return <option value="">Select Package</option>;
    }
  };

  const showHideUnitCardFieldOnEdit = () => {
    if (props.ifEdit) {
      if (formData.payment_type === "unit_card") {
        return (
          <Col md="12">
            <Form.Check
              inline
              label="Charge Credit Card(unit enters)"
              name="payment_type"
              type="radio"
              value="unit_card"
              id="2"
              onChange={handleChange}
              checked={formData.payment_type === "unit_card"}
              disabled
            />
          </Col>
        );
      }
    } else {
      return (
        <Col md="12">
          <Form.Check
            inline
            label="Charge Credit Card(unit enters)"
            name="payment_type"
            type="radio"
            value="unit_card"
            id="2"
            onChange={handleChange}
            checked={formData.payment_type === "unit_card"}
          />
        </Col>
      );
    }
  };

  const showHideInvoiceRadioFieldOnEdit = () => {
    if (props.ifEdit) {
      if (formData.payment_type === "invoice") {
        return (
          <Col md="12">
            <Form.Check
              inline
              label="Invoice"
              name="payment_type"
              type="radio"
              value="invoice"
              id="3"
              onChange={handleChange}
              checked={formData.payment_type === "invoice"}
              disabled
            />
          </Col>
        );
      }
    } else {
      return (
        <Col md="12">
          <Form.Check
            inline
            label="Invoice"
            name="payment_type"
            type="radio"
            value="invoice"
            id="3"
            onChange={handleChange}
            checked={formData.payment_type === "invoice"}
          />
        </Col>
      );
    }
  };

  const showSubmitButton = () => {
    if (!formData.submitLoader) {
      return (
        <Button className="btn-fill" type="button" variant="info" onClick={handleAddUnitOptions}>
          {"Submit"}
        </Button>
      );
    } else {
      return <Spinner animation="border" variant="info" />;
    }
  };

  const showFormFieldAccoridingToSiteSettings = () => {
    if (props.ifEdit && formData.disable_card_options) {
      return (
        <>
          {formData.payment_type === "main_card" && (
            <Col lg={12}>
              <Form.Check
                inline
                label="Charge Credit Card"
                name="payment_type"
                type="radio"
                value="main_card"
                id="1"
                onChange={handleChange}
                checked={formData.payment_type === "main_card"}
                disabled={props.ifEdit}
              />
              {formData.payment_type === "main_card" && (
                <>
                  <div className="mb-2 change-credit-card-box">
                    <Row>
                      <Col lg="6">
                        <div className="form-group mb-3">
                          <label className="mb-0">
                            Name
                            <span className="asterisk ml-1">*</span>
                          </label>
                          <div className="d-flex align-items-center position-relative">
                            <input
                              required={true}
                              type="text"
                              className="form-control"
                              name="card_name"
                              value={formData.card_name}
                              onChange={handleChange}
                              disabled={formData.disable_card_options}
                            />
                            <div className="payment-formicon">
                              <span className="fa fa-user" aria-hidden="true" />
                            </div>
                          </div>
                          {showError("card_name")}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group mb-3">
                          <label className="mb-0">
                            Email
                            <span className="asterisk ml-1">*</span>
                          </label>
                          <div className="d-flex align-items-center position-relative">
                            <input
                              required
                              type="email"
                              className="form-control"
                              name="card_email"
                              value={formData.card_email}
                              onChange={handleChange}
                              disabled={formData.disable_card_options}
                            />
                            <div className="payment-formicon">
                              <span className="fa fa-envelope" aria-hidden="true" />
                            </div>
                          </div>
                          {showError("card_email")}
                        </div>
                      </Col>
                    </Row>
                    <div className="form-group mb-3">
                      <label className="mb-0">
                        Credit Card number
                        <span className="asterisk ml-1">*</span>
                      </label>
                      <div className="d-flex align-items-center position-relative">
                        <input
                          type="text"
                          required={true}
                          className="form-control"
                          name="card_number"
                          value={formData.card_number}
                          onChange={handleChangeCard}
                          disabled={formData.disable_card_options}
                          maxLength="16"
                        />
                        <div className="payment-formicon">
                          <span classNa me="fa fa-credit-card" aria-hidden="true" />
                        </div>
                      </div>
                      {showError("card_number")}
                    </div>

                    <div className="form-group mb-0">
                      <Row>
                        <Col lg="6">
                          <div className="form-group mb-3">
                            <label className="mb-0">
                              Expiration Date
                              <span className="asterisk ml-1">*</span>
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <input
                                type="text"
                                required={true}
                                className="form-control"
                                name="card_expiry"
                                value={formData.card_expiry}
                                onChange={handleChange}
                                disabled={formData.disable_card_options}
                              />
                              <div className="payment-formicon">
                                <span
                                  className="fa fa-calendar"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                            {showError("card_expiry")}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="form-group mb-3">
                            <label className="mb-0">
                              CVV
                              <span className="asterisk ml-1">*</span>
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <input
                                required={true}
                                type="password"
                                className="form-control"
                                name="card_cvv"
                                value={formData.card_cvv}
                                onChange={handleCvvChange}
                                maxLength="4"
                                disabled={formData.disable_card_options}
                              />
                              <div className="payment-formicon">
                                <span className="fa fa-key" aria-hidden="true" />
                              </div>
                            </div>
                            {showError("card_cvv")}
                          </div>
                        </Col>
                        {formData.payment_type === "main_card" && cardDetailData && (
                          <Col lg={12} className="d-flex justify-content-end gap-3 mt-2">
                            <Button className="btn-fill btn-sm mb-2" type="button" variant="info" onClick={() => editCardDetails()}>
                              <span className="pe-2">
                                <i class="far fa-edit"></i>
                              </span>
                              Edit Card
                            </Button>
                            <Button className="btn-fill btn-sm mb-2 ml-2" type="button" variant="danger" onClick={() => deleteCardDetails()}>
                              <span className="pe-2">
                                <i class="far fa-trash-alt"></i>
                              </span>
                              Delete Card
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div>
                </>
              )}
            </Col>
          )}
        </>
      );
    } else {
      return (
        <>
          <Col lg={12}>
            <Form.Check
              inline
              label="Charge Credit Card"
              name="payment_type"
              type="radio"
              value="main_card"
              id="1"
              onChange={handleChange}
              checked={formData.payment_type === "main_card"}
              disabled={props.ifEdit}
            />
            {formData.payment_type === "main_card" && (
              <>
                <div className="mb-2 change-credit-card-box">
                  <Row>
                    <Col lg="6">
                      <div className="form-group mb-3">
                        <label className="mb-0">
                          Name
                          <span className="asterisk ml-1">*</span>
                        </label>
                        <div className="d-flex align-items-center position-relative">
                          <input required={true} type="text" className="form-control" name="card_name" value={formData.card_name} onChange={handleChange} />
                          <div className="payment-formicon">
                            <span className="fa fa-user" aria-hidden="true" />
                          </div>
                        </div>
                        {showError("card_name")}
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="form-group mb-3">
                        <label className="mb-0">
                          Email
                          <span className="asterisk ml-1">*</span>
                        </label>
                        <div className="d-flex align-items-center position-relative">
                          <input required={true} type="email" className="form-control" name="card_email" value={formData.card_email} onChange={handleChange} />
                          <div className="payment-formicon">
                            <span className="fa fa-envelope" aria-hidden="true" />
                          </div>
                        </div>
                        {showError("card_email")}
                      </div>
                    </Col>
                  </Row>
                  <div className="form-group mb-3">
                    <label className="mb-0">
                      Credit Card number
                      <span className="asterisk ml-1">*</span>
                    </label>
                    <div className="d-flex align-items-center position-relative">
                      <input
                        type="text"
                        required={true}
                        className="form-control"
                        name="card_number"
                        value={formData.card_number}
                        onChange={handleChangeCard}
                        maxLength="16"
                      />
                      <div className="payment-formicon">
                        <span classNa me="fa fa-credit-card" aria-hidden="true" />
                      </div>
                    </div>
                    {showError("card_number")}
                  </div>

                  <div className="form-group mb-0">
                    <Row>
                      <Col lg="6">
                        <div className="form-group mb-3">
                          <label className="mb-0">
                            Expiration Date
                            <span className="asterisk ml-1">*</span>
                          </label>
                          <div className="d-flex align-items-center position-relative">
                            <DatePicker
                              placeholderText="MM/YY"
                              name="card_expiry"
                              selected={formData?.card_expiry}
                              onChange={handleCalender}
                              dateFormat="MM/yy"
                              showMonthYearPicker
                              showFullMonthYearPicker
                              minDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
                              customInput={
                                <InputMask
                                  mask="99/99"
                                  maskChar={null}
                                  // placeholder="MM/YY"
                                  className="form-control datePickerPayment pe-5"
                                  name="card_expiry"
                                  value={formData?.card_expiry}
                                  onChange={handleCalender}
                                  // onChangeRaw
                                  showMask
                                />
                              }
                            ></DatePicker>
                            <div className="payment-formicon">
                              <span
                                className="fa fa-calendar"
                                style={{
                                  cursor: "pointer",
                                }}
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                          {showError("card_expiry")}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group mb-3">
                          <label className="mb-0">
                            CVV
                            <span className="asterisk ml-1">*</span>
                          </label>
                          <div className="d-flex align-items-center position-relative">
                            <input
                              required={true}
                              type="password"
                              className="form-control"
                              name="card_cvv"
                              value={formData.card_cvv}
                              maxLength="4"
                              onChange={handleCvvChange}
                            />
                            <div className="payment-formicon">
                              <span className="fa fa-key" aria-hidden="true" />
                            </div>
                          </div>
                          {showError("card_cvv")}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            )}
          </Col>
        </>
      );
    }
  };

  const addNewLotPackage = () => {
    if (formData.lot_package.length < 10) {
      setFormData({
        ...formData,
        errors: {
          ...formData.errors,
          lot_package: "",
        },
        lot_package: [
          ...formData.lot_package,
          {
            packageList: [],
            lotList: allLots,
            lot_id: "",
            package_name: "",
            error: {},
            issued: 0,
            disable: false,
            min_permit: "",
            max_permit: "",
          },
        ],
      });
    } else {
      setFormData({
        ...formData,
        errors: {
          ...formData.errors,
          lot_package: `Maximum 10 Lot's & Packages are allowed`,
        },
      });
    }
  };

  const showErrorToaster = () => {
    setFormData((prevState) => ({
      ...prevState,
      showConfirmationModal: false,
      show_error: true,
    }));
    setTimeout(() => {
      setFormData((prevState) => ({
        ...prevState,
        show_error: false,
      }));
    }, 4000);
  };
  const showSuccessToaster = () => {
    updateUnitPermit();
    setFormData((prevState) => ({
      ...prevState,
      showConfirmationModal: false,
      show_alert: true,
    }));
    setTimeout(() => {
      setFormData((prevState) => ({
        ...prevState,
        show_alert: false,
      }));
    }, 4000);
  };

  const deleteRow = () => {
    const packageData = formData.lot_package[formData.selected_row_id];
    if (packageData?.package_name && packageData.lot_id) {
      dispatch(deleteUnitPermitByLotAndPackage(propertyId, formData.unit_id, packageData?.lot_id, packageData?.package_name));
      if (api_response) {
        formData.lot_package.pop(formData.selected_row_id - 1);
        setFormData({
          ...formData,
          errors: {
            ...formData.errors,
            lot_package: "",
          },
          lot_package: [...formData.lot_package],
        });
        showSuccessToaster();
      } else {
        showErrorToaster();
      }
    } else {
      formData.lot_package.pop(formData.selected_row_id - 1);
      setFormData({
        ...formData,
        errors: {
          ...formData.errors,
          lot_package: "",
        },
        lot_package: [...formData.lot_package],
        showConfirmationModal: false,
      });
    }
  };

  const openConfirmationModal = (id) => {
    let ID = -1;
    if (id >= 0) {
      ID = id;
    }
    if (props.ifEdit) {
      setFormData((prevState) => ({
        ...prevState,
        showConfirmationModal: !formData.showConfirmationModal,
        selected_row_id: ID,
      }));
    } else {
      formData.lot_package.pop(formData.selected_row_id - 1);
      setFormData({
        ...formData,
        errors: {
          ...formData.errors,
          lot_package: "",
        },
        lot_package: [...formData.lot_package],
      });
    }
  };

  const updateUnitPermit = async () => {
    const { unit_id, lot_package } = formData;
    const data = {
      property_id: props.property_id,
      suite_id: unit_id,
      lot_packages: lot_package.map((item) => {
        return {
          lot_id: item.lot_id,
          package_name: item.package_name,
          min_permit: item.min_permit,
          max_permit: item.max_permit,
          issued: item.issued,
        };
      }),
    };
    dispatch(updateUnitPermitOptionsLotPackage(data, props.selectedRowId));
  };

  return (
    <div>
      <Modal lg={"medium"} show={props.show} onHide={props.closeModal} dialogClassName="modal-90w" fullscreen={"sm-down"}>
        <Card.Header className="d-flex justify-content-between bg-white align-items-center">
          <h4 className="m-0 font-weight-bold">
            <Card.Title as="h4" className="m-0">
              {props.ifEdit ? "Edit" : "Add"} Unit Permit Packages
            </Card.Title>
          </h4>
          <Button title={"Close"} type="button" onClick={props.closeModal} className="close-btn py-0 pr-0" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove text-danger"></i>
          </Button>
        </Card.Header>
        {formData.loader ? (
          <Modal.Body className="pt-3">
            <Loader />
          </Modal.Body>
        ) : (
          <>
            <Modal.Body className="pt-3">
              <Row>
                <>
                  <Col lg={6} md={6} className="mb-2">
                    <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                      <Form.Label className="mb-0">Units:</Form.Label>
                      <span className="asterisk">*</span>
                      <Form.Control
                        as="select"
                        name="unit_id"
                        value={formData.unit_id}
                        onChange={handleChangeUnit}
                        placeholder="Select Unit"
                        disabled={props.ifEdit}
                      >
                        {showUnitList()}
                      </Form.Control>
                    </Form.Group>
                    {showError("unit_id")}
                  </Col>
                  <Col lg={12} md={12} className="mb-2">
                    <Row>
                      <Col lg={12} md={12} className="mb-2">
                        <span>
                          Add Lots & Packages
                          <span className="asterisk">*</span>
                        </span>
                        <Button
                          className="btn-blue-bg btn-sm ml-2"
                          type="button"
                          style={{ height: "30px", width: "30px" }}
                          disabled={formData?.lot_package?.filter((item) => item.lot_id === "" || item.package_name === "")?.length !== 0}
                          onClick={() => {
                            addNewLotPackage();
                          }}
                        >
                          +
                        </Button>
                      </Col>
                    </Row>
                    {showError("lot_package")}
                  </Col>
                  {formData?.lot_package.map((item, index) => {
                    return (
                      <Col lg={12} md={12} key={index}>
                        <Row>
                          <Col lg={12} md={12}>
                            <div className="row">
                              <div className="col-10">
                                {index + 1}. Permit, Lots & Packages
                                <span className="asterisk">*</span>
                              </div>
                              <div className="col-2 text-right">
                                <Button className="btn-blue-bg btn-sm" type="button" onClick={() => openConfirmationModal(index)}>
                                  -
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} className="mb-2">
                            <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                              <Form.Label className="mb-0">Lot:</Form.Label>
                              <span className="asterisk">*</span>
                              <Form.Control
                                as="select"
                                name="lot_id"
                                value={item.lot_id}
                                onChange={(e) => handleChangeLot(e, index)}
                                placeholder="Select Lot"
                                disabled={item.disable}
                              >
                                {showLotList(item.lotList)}
                              </Form.Control>
                            </Form.Group>
                            <div className="help-block">{item?.error?.lot_id}</div>
                          </Col>
                          <Col lg={6} md={6} className="mb-2">
                            <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                              <Form.Label className="mb-0">Packages:</Form.Label>
                              <span className="asterisk">*</span>
                              <Form.Control
                                as="select"
                                name="package_name"
                                value={item.package_name}
                                onChange={(e) => handleChangePermit(e, item.lot_id, index)}
                                placeholder="Select Package"
                                disabled={!item?.lot_id || item.disable}
                              >
                                {showPackageList(item.packageList)}
                              </Form.Control>
                            </Form.Group>
                            <div className="help-block">{item?.error?.package_name}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} className="mb-2">
                            <Form.Group className="mb-0">
                              <Form.Label className="mb-0">
                                Min Number of permits <span className="asterisk ml-1">*</span>
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="min_permits"
                                value={item.min_permit}
                                onChange={(e) => handleChangeMinPermit(e, index)}
                                onKeyDown={blockInvalidChar}
                                min={0}
                                autoComplete="off"
                              />
                            </Form.Group>
                            <div className="help-block">{item?.error?.min_permit}</div>
                          </Col>
                          <Col lg={6} md={6} className="mb-2">
                            <Form.Group className="mb-0">
                              <Form.Label className="mb-0">
                                Max Number of permits <span className="asterisk ml-1">*</span>
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="max_permits"
                                value={item.max_permit}
                                onChange={(e) => handleMaxPermitChange(e, index)}
                                onKeyDown={blockInvalidChar}
                                min={0}
                                autoComplete="off"
                              />
                            </Form.Group>
                            <div className="help-block">{item?.error?.max_permit}</div>
                          </Col>
                          <br />
                        </Row>
                      </Col>
                    );
                  })}
                  <br />
                  <div className="checkGroupLabel mt-2 mb-2">
                    {showFormFieldAccoridingToSiteSettings()}
                    {showHideUnitCardFieldOnEdit()}
                    {showHideInvoiceRadioFieldOnEdit()}
                  </div>
                </>

                <br />
                {formData.warningMsg.length > 0 && (
                  <Col lg={12} md={12}>
                    <Row>
                      <div className="help-block padding-15"> {formData.warningMsg}</div>
                    </Row>
                  </Col>
                )}
                <Col lg={12} md={12} className="mb-4">
                  <label className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="allow_permit_share"
                      checked={formData.allow_permit_share}
                      onChange={(e) => {
                        const newValue = e.target.checked;
                        setFormData((prevData) => ({
                          ...prevData,
                          allow_permit_share: newValue,
                        }));
                      }}
                      className="mr-2"
                      id="handleChangeUnit"
                    ></input>
                    <span>Allow Permit Share</span>
                  </label>
                </Col>
                <Col lg={12}>{showSubmitButton()}</Col>
              </Row>
            </Modal.Body>
          </>
        )}
        <Modal.Footer></Modal.Footer>
      </Modal>

      {formData.showConfirmationModal ? (
        <ConfirmationModal
          onOpen={formData.showConfirmationModal}
          onConfirm={deleteRow}
          message="All Permits for this package/account (Active, Auto-Renewal) will be cancelled and Refund would be issued as applicable. Do you want to DELETE this permit package ?"
          onClose={openConfirmationModal}
          loading={false}
          title={"Delete Permit Option for Unit"}
          showSuccessToaster={showSuccessToaster}
          showErrorToaster={showErrorToaster}
        />
      ) : null}

      {formData.show_delete_card_model ? (
        <DeleteCardModal
          onOpen={formData.show_delete_card_model}
          onConfirm={handleDeleteCard}
          message="Are You sure you want to Delete this card? It will not auto renew permits."
          onClose={handleShowDeleteCardModal}
          title={"Delete Card"}
          isDeleteCarddisabled={formData.isDeleteCard}
          loading={false}
        />
      ) : null}

      {formData.show_alert && api_success_message ? <CustomContentAlert delay={6000} message={api_success_message} className="toast-success" /> : null}
      {formData.show_error && api_error_message ? <CustomContentAlert delay={6000} message={api_error_message} className="toast-error" /> : null}
    </div>
  );
};

export default UnitPermitOptionsModal;

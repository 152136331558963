import React, { Component } from "react"; //
import { Card, Container, Row, Col } from "react-bootstrap";
import MaterialTable, { MTableAction } from "material-table";
import isEqual from "lodash/isEqual";
import CustomContentAlert, { navigationButtons } from "../helper";
import { SuperAdminAction, LaneAction } from "../../Actions";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import { isEmpty } from "lodash";

class AddLaneForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addLanId: "",
      addLanName: "",
      direction: "Entry/Exit",
      validLot: true,
      validAddLanName: true,
      formIsValid: false,
      searchValue: "",
      searchedLan: [],
      filteredLan: [],
      lotName: [],
      lanes_: [],
      lots: [],
      loader: false,
      adding: false,
      property_id: "",
      show_alert: false,
      show_error: false,
      api_success_message: "",
      api_error_message: "",
      all_properties: [],
      columnsDataRender: 0,
    };

    this.addActionRef = React.createRef();
  }

  componentDidMount = () => {
    this.setState(
      {
        loader: true,
      },
      async () => {
        const id = this.props.location?.search?.split("?")?.[1] || "";
        await this.props.get_all_properties();
        this.setState(
          {
            property_id: id,
            all_properties: this.props?.getAllProperties,
          },
          () => {
            const selectedPropertyObj = (this.state.all_properties ?? []).filter((item) => item.id === this.state.property_id);
            const selectedPropertyName = selectedPropertyObj?.[0]?.site_name;
            const selectedDomainName = selectedPropertyObj?.[0]?.domain;
            this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
          },
        );
        await this.props.get_all_lots(id);
        await this.props.fetch_lane(id, true);

        const newArray = [];

        this.setState(
          {
            lots: this.props.lots,
            lanes_: this.props.lanes,
            api_response: this.props.api_response,
            loader: false,
          },
          () => {
            if (Array.isArray(this.state.lots)) {
              (this.state.lots ?? []).forEach((e) => newArray.push(e.alias));
            }
          },
        );
        this.setState({
          lotName: newArray,
        });
      },
    );
  };

  componentDidUpdate = (prevProps) => {
    if (!isEqual(prevProps.lanes, this.props.lanes)) {
      this.setState({ lanes_: this.props.lanes });
    }
  };

  handleSubmit = async (newData) => {
    await this.props.createLane({
      ...newData,
      propertyId: this.state.property_id,
    });
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        columnsDataRender: 1,
      });
      setTimeout(() => {
        this.setState({
          show_alert: false,
        });
      }, 4000);
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
        columnsDataRender: 1,
      });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  handleLaneEdit = async (editData) => {
    await this.props.editLane({
      ...editData,
      propertyId: this.state.property_id,
      old_data: this.props.lanes.find((x) => x.id === editData.id),
    });
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        columnsDataRender: 1,
      });
      setTimeout(() => {
        this.setState({
          show_alert: false,
        });
      }, 4000);
    } else {
      this.setState(
        {
          show_error: true,
          api_error_message: this.props.api_error_message,
        },
        () =>
          this.setState({
            columnsDataRender: 1,
          }),
      );
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  handleLaneDelete = async (laneId) => {
    await this.props.deleteLane({
      id: laneId,
      propertyId: this.state.property_id,
    });
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      setTimeout(() => {
        this.setState({
          show_alert: false,
        });
      }, 4000);
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  handleLaneAction = (props) => {
    if (typeof props.action === typeof Function || props.action.tooltip !== "Add") {
      return <MTableAction {...props} />;
    } else {
      return <div ref={this.addActionRef} onClick={props.action.onClick} />;
    }
  };

  handleLaneRowAdd = (newData) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (newData.alias && !isEmpty(newData.lot) && newData.entry_lane) {
          this.handleSubmit(newData);
          resolve();
        } else {
          reject(Error);
          this.setState({
            show_error: true,
            api_error_message: "All fields are mandatory",
          });
          setTimeout(() => {
            this.setState({ show_error: false });
          }, 4000);
        }
      }, 1000);
    });
  };

  handleRowUpdate = (newData) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.handleLaneEdit(newData);
        console.log("lane data", newData);
        resolve();
      }, 1000);
    });
  };

  rowDelete = (oldData) => {
    const { lanes_ } = this.state;
    return new Promise((resolve) => {
      setTimeout(() => {
        const dataDelete = [...lanes_];
        const index = oldData.tableData.id;
        const laneId = oldData.id;
        dataDelete.splice(index, 1);
        this.setState([...dataDelete]);
        this.handleLaneDelete(laneId);
        resolve();
      }, 1000);
    });
  };

  render() {
    const { loader, lots, lanes_, show_alert, show_error, columnsDataRender } = this.state;
    let lotDropdownObj = {};
    if (Array.isArray(lots)) {
      lotDropdownObj = lots.reduce(function (acc, cur) {
        acc[cur.id] = cur.alias;
        return acc;
      }, {});

      console.log(lotDropdownObj);
    } else {
      console.error("lots is not an array.");
    }
    if (!columnsDataRender) {
      this.columns = [
        {
          title: "Lot",
          field: "lot.id",
          lookup: lotDropdownObj,
          validate: (rowData) => (rowData.lot === "" ? "Please select lot" : ""),
        },
        {
          title: "Name",
          field: "alias",
          validate: (rowData) => (rowData.alias === "" ? "Name cann't be empty" : ""),
        },
        {
          title: "Direction",
          field: "entry_lane",
          lookup: { 1: "Enter", 0: "Exit" },
          validate: (rowData) => (rowData.lot === "" ? "Please select direction" : ""),
        },
      ];
    }
    return (
      <>
        <Container fluid>
          <Row className="h-100">
            <Col md="12">
              <Card className="h-100">
                <Card.Header>
                  <Card.Title as="h4">Manage Lanes</Card.Title>
                  <p className="card-category">Fill out below form to create new lane.</p>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide">
                  {/* <h4>Lane Details</h4> */}
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      <div style={{ maxWidth: "100%" }}>
                        <MaterialTable
                          title=""
                          components={{
                            Action: this.handleLaneAction,
                          }}
                          options={{
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            draggable: false,
                          }}
                          columns={this.columns}
                          data={lanes_ ?? []}
                          editable={{
                            onRowAddCancelled: () => console.log("Row adding cancelled"),
                            onRowUpdateCancelled: () => console.log("Row editing cancelled"),
                            onRowAdd: this.handleLaneRowAdd,
                            onRowUpdate: this.handleRowUpdate,
                            onRowDelete: this.rowDelete,
                          }}
                        />
                      </div>
                      <Col lg="12" className="p-0">
                        {navigationButtons("/addlotform", "/addcameraform", this.state.property_id, this.addActionRef, "Add Lane")}
                      </Col>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {show_alert ? <CustomContentAlert delay={4000} message={this.state.api_success_message} className="toast-success" /> : null}
        {show_error ? <CustomContentAlert delay={4000} message={this.state.api_error_message} className="toast-error" /> : null}
      </>
    );
  }
}

const mapStateToProps = ({ SuperAdminDetails, APIResponseDetails }) => {
  const { lots, lanes, getAllProperties } = SuperAdminDetails;
  const { api_response, api_success_message, api_error_message } = APIResponseDetails;
  return {
    lanes,
    lots,
    getAllProperties,
    api_response,
    api_success_message,
    api_error_message,
  };
};

const mapStateToDispatch = {
  get_all_lots: SuperAdminAction.get_all_lots,
  fetch_lane: SuperAdminAction.fetch_lane,
  createLane: SuperAdminAction.createLane,
  editLane: LaneAction.editLane,
  deleteLane: LaneAction.deleteLane,
  get_all_properties: SuperAdminAction.get_all_properties,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(AddLaneForm);

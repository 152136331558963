const Types = {
  FETCH_PALTE_VALIDITY: "FETCH_PALTE_VALIDITY",
  SEND_EMAIL: "SEND_EMAIL",
  FETCH_PALTE_SCANNED_DATA: "FETCH_PALTE_SCANNED_DATA",
  GETUSERLOTS: "GETUSERLOTS",
  GETALLUSERS: "GETALLUSERS",
  GET_AUDIT_REPORT: "GET_AUDIT_REPORT",
  SHOW_HIDE_LOADER: "SHOW_HIDE_LOADER",
  GETUSERTYPES: "GETUSERTYPES",
  FETCH_CITATION_REPORT_LIST: "FETCH_CITATION_REPORT_LIST",
  FETCH_CITATION_ISSUED_SUMMARY: "FETCH_CITATION_ISSUED_SUMMARY",
  FETCH_CITATION_PAID_SUMMARY: "FETCH_CITATION_PAID_SUMMARY",
  FETCH_REVENUE_REPORT_LIST: "FETCH_REVENUE_REPORT_LIST",
  FETCH_REVENUE_REPORT_SUMMARY_LIST: "FETCH_REVENUE_REPORT_SUMMARY_LIST",
  GETOFFLINEDATA: "GETOFFLINEDATA",
  CREATE_OR_UPDATE_OFFLINE_SESSIONS: "CREATE_OR_UPDATE_OFFLINE_SESSIONS",
  GET_OFFLINE_USER: "GET_OFFLINE_USER",
  GET_CLOUD_TO_OFFLINE_SYNC: "GET_CLOUD_TO_OFFLINE_SYNC",
  GET_OFFLINE_TO_CLOUD_SYNC: "GET_OFFLINE_TO_CLOUD_SYNC",
  FETCH_CITATION_PERMIT_GRACE: "FETCH_CITATION_PERMIT_GRACE",
};

export default Types;

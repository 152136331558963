import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

const SidebarReport = (prop) => {
  const location = useLocation();
  const [dropDownState, setDropDownState] = React.useState(
    [prop.path, "/audit-report"].includes(location.pathname)
  );
  const shadeColor = React.useMemo(() => {
    const { color, percent } = prop;
    if (color && percent) {
      let R = parseInt(color.substring(1, 3), 16);
      let G = parseInt(color.substring(3, 5), 16);
      let B = parseInt(color.substring(5, 7), 16);

      R = parseInt((R * (100 + percent)) / 100);
      G = parseInt((G * (100 + percent)) / 100);
      B = parseInt((B * (100 + percent)) / 100);

      R = R < 255 ? R : 255;
      G = G < 255 ? G : 255;
      B = B < 255 ? B : 255;

      const RR =
        R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
      const GG =
        G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
      const BB =
        B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

      return "#" + RR + GG + BB;
    } else {
      return "#303b93";
    }
  }, [prop.color, prop.percent]);
  const allPermissions = prop.permissions;
  const getSubSideBarName = (name) => {
    let nameToBePrinted;
    if (name.toLowerCase().replace(/[^a-z]/g, "") === "photoverifyreport") {
      nameToBePrinted = "Verify Audit";
    } else if (
      name.toLowerCase().replace(/[^a-z]/g, "") === "validationreport"
    ) {
      nameToBePrinted = "Discount";
    } else {
      nameToBePrinted = name.substring(0, name.lastIndexOf(" "));
    }
    return nameToBePrinted;
  };
  return (
    <div className={`dropdown ${dropDownState ? "show" : ""}`}>
      <li>
        <Btn
          className="nav-link dd-link ml-0"
          onClick={() => setDropDownState(!dropDownState)}
        >
          <i className={prop.icon} />
          <p>Reports</p>
        </Btn>
      </li>
      <ShadedDiv shadeColor={shadeColor} className="dropdowngroup">
        {allPermissions.map((permission) => {
          return (
            <>
              <li key={permission.path}>
                <NavLink
                  className={`nav-link${
                    location.pathname === permission.path ? " active" : ""
                  }`}
                  to={permission.path}
                  onClick={() => {
                    prop.handleClick(permission.path);
                  }}
                >
                  <p>{getSubSideBarName(permission.name)}</p>
                </NavLink>
              </li>
            </>
          );
        })}
      </ShadedDiv>
    </div>
  );
};
const ShadedDiv = styled.ul`
  .nav-link {
    margin-left: 65px !important;
  }
  background: ${(props) => props.shadeColor} !important;
`;
const Btn = styled.a`
  cursor: pointer;
`;

export default SidebarReport;

/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, Button, Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import swicthimg from "../../../assets/img/swicth.svg";
import * as UserProfileActions from "../../Actions/UserProfileActions/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectedUnitLanguage } from "../../Actions/CurrentPermitActions/actions";

const Header = (props) => {
  const [domainName, setDomainName] = React.useState(`worldstream.${process.env.REACT_APP_ENV}`);
  const dispatch = useDispatch();
  const { permission_id, language } = useSelector((state) => state.UserProfileDetails);

  const [selectedLang, setSelectedLang] = useState(language);

  useEffect(() => {
    dispatch(selectedUnitLanguage(selectedLang));
  }, [selectedLang]);

  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    props.handleSiderHide();
  };

  React.useEffect(() => {
    const allDomains = window.location.hostname.split(".");

    if (allDomains.length >= 3) {
      setDomainName(allDomains.slice(allDomains.length - 2, allDomains.length).join("."));
    }
    if (allDomains.length === 2) {
      setDomainName(allDomains.join("."));
    }
  }, []);

  const Click = React.useCallback(() => {
    dispatch(UserProfileActions.showHidePropertyModal(true));
  }, [dispatch]);

  const changeLanguage = async ({ target }) => {
    const { value } = target;
    setSelectedLang(value);
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          {props.isShowSidebar && (
            <Button
              variant="dark"
              className="btn-fill d-flex justify-content-center align-items-center rounded-circle btn-toggle p-2 btn-toggle-new"
              onClick={mobileSidebarToggle}
            >
              <img src={require("../../../assets/img/menu.svg").default} alt="..." />
            </Button>
          )}
          {props.admin && (
            <div className="ml-3">
              <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"bottom"} overlay={<Tooltip id={`tooltip-qw`}>Switch Property</Tooltip>}>
                <div className="text-nowrap text-truncate cursor-pointer" onClick={Click}>
                  <img src={swicthimg} width="26" />
                </div>
              </OverlayTrigger>
            </div>
          )}
          {props.isShowSidebar && props.domainHeader && props.domainHeader.propertyName && props.domainHeader.domainName && (
            <span className="ml-2 fs-14px">
              {`${props.domainHeader.propertyName} `}
              {props.permission_id === 1 && !props.adminLogin ? (
                <a
                  className={"top-header--title"}
                  href={`https://${props.domainHeader.domainName}.${domainName}`}
                  target="_blank"
                  rel="noreferrer"
                >{`${props.domainHeader.domainName}.${domainName}`}</a>
              ) : null}
            </span>
          )}
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav mr-auto" navbar></Nav>
          <Nav className="ml-auto" navbar>
            {permission_id === 2 ? (
              <div>
                <Form.Group className="mb-0">
                  <Form.Control
                    as="select"
                    name="selectedProperty"
                    value={selectedLang}
                    placeholder="Language"
                    onChange={changeLanguage}
                    className="form-select py-1"
                    style={{ height: "30px", fontSize: "12px" }}
                  >
                    <option value="en">English </option>
                    <option value="fn">French </option>
                  </Form.Control>
                </Form.Group>
              </div>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;

import React from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import * as TicketActions from "../Actions/TicketActions/actions";
const CitationModal = ({ handleClose, tickets, PropertyId, userId }) => {
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    pdfUrl: "",
  });

  const handleTicketType = async (id) => {
    const value = await dispatch(TicketActions.getBlobPdf(id, "pdf", userId, PropertyId));
    setState({
      pdfUrl: value,
    });
    document.getElementById("pdf").click();
  };

  const showDomain = (row) => <div>{row.property.domain}</div>;

  const showFee = (row) => <div>{`$ ${row.chargable_fee}`}</div>;

  const showStatus = (row) => <div>{row.status}</div>;

  const showState = (row) => <div>{row.state}</div>;

  const showAdderess = (row) => <div>{row.property.address}</div>;

  const showPlate = (row) => <div>{row.lpn}</div>;

  const chargableFee = (row) => <div>{`$ ${row.chargable_fee}`}</div>;

  const showTicketNumber = (row) => (
    <div
      className="curserPointer"
      onClick={() => {
        handleTicketType(row.id);
      }}
    >
      {row.ticket_no}
    </div>
  );

  const columns = React.useMemo(() => {
    return [
      {
        name: "Ticket No",
        center: true,
        sortable: true,
        selector: (row) => row.ticket_no,
        cell: showTicketNumber,
      },
      {
        name: "Plate",
        center: true,
        sortable: true,
        selector: (row) => row.lpn,
        cell: showPlate,
      },
      {
        name: "Address",
        sortable: true,
        center: true,
        selector: (row) => row.address,
        cell: showAdderess,
      },
      {
        name: "State",
        center: true,
        sortable: true,
        selector: (row) => row.state,
        cell: showState,
      },
      {
        name: "Status",
        center: true,
        sortable: true,
        selector: (row) => row.status,
        cell: showStatus,
      },
      {
        name: "Fee",
        center: true,
        sortable: true,
        selector: chargableFee,
        cell: showFee,
      },
      {
        name: "Domain",
        center: true,
        sortable: true,
        selector: (row) => row.property.domain,
        cell: showDomain,
      },
    ];
  });

  return (
    <div>
      <a id="pdf" href={state.pdfUrl} target={"_blank"} rel="noopener noreferrer" />
      <Modal
        id="CitationModal"
        lg={"lg"}
        backdrop={"static"}
        show={true}
        onHide={handleClose}
        dialogClassName="modal-90w modal-xl opacity: 0.8"
        fullscreen={"sm-down"}
        className="modalData"
      >
        <Modal.Header id="import" className="d-flex justify-content-between bg-white align-items-center">
          <h5 className="m-0 font-weight-bold">
            <Modal.Title className="m-0">Citation List</Modal.Title>
          </h5>
          <Button id="modal" type="button" onClick={handleClose} className="close-btn py-0" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove text-danger" />
          </Button>
        </Modal.Header>
        <ModalBody className="pt-0">
          <>
            <StyledDatatable
              data={tickets}
              columns={columns}
              pagination={true}
              responsive={true}
              defaultSortAsc={false}
              fixedHeader={true}
              subHeader
              fixedHeaderScrollHeight="38vh"
            />
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CitationModal;
const StyledDatatable = styled(DataTable)`
  div[role="row"] {
    div[role="gridcell"] {
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
      }
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  header {
    border-bottom: none;
    padding: 0px;
    margin-bottom: -30px;
  }
`;

import React, { Component } from "react";
import { Row, Card, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { SuperAdminAction } from "../../Actions";
import { loadPlayer } from "rtsp-relay/browser";
import { groupBy, isEmpty } from "lodash";
import lockCloseAction from "../../../assets/img/svg/live_feed_icons/live-feed-lock-close.svg";
import lockOpenAction from "../../../assets/img/svg/live_feed_icons/live-feed-lock-open.svg";
import regularAction from "../../../assets/img/svg/live_feed_icons/live-feed-regular-operation.svg";
import looplivefeed from "../../../assets/img/svg/live_feed_icons/live-feed-loop.svg";
import SmartGateConfirmModal from "../Modal/smartGateModal";

class SmartGate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      api_response: false,
      property: this.props.PropertyId,
      propertyData: this.props.property,
      timezone: this.props.timezone,
      isSearchInputDisabled: true,
      isSearchButtonDisabled: true,
      selectedGate_no: "",
      selectedLane: "",
      url: "",
      streamData: [],
      liveStreamData: [],
      feedData: {},
      disableCloseButton: true,
      timer: null,
      smartGateModal: false,
      action: "",
      data: {},
      smartGateLoader: false,
      confirmDelete: false,
      buttonDisable: false,
    };
  }

  componentDidMount = async () => {
    const selectedPropertyName = this.state.propertyData?.property_name;
    const selectedDomainName = this.state.propertyData?.domain;
    await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    const feedData = await this.props.getLiveFeed(this.props.PropertyId, "SmartGate");
    const groupFeed = groupBy(feedData, "gate_no");
    let url;

    const windowUrl = window?.location?.href.split("/");
    const baseUrl = windowUrl[0];
    if (baseUrl === "https:") {
      url = `${process.env.REACT_APP_WSS}${process.env.REACT_APP_MAIN_URL}:${process.env.REACT_APP_LIVEFEED_PORT}`;
    } else {
      url = `${process.env.REACT_APP_WSS}${process.env.REACT_APP_LIVEFEED_URL}`;
    }
    this.setState({
      url: url,
      feedData: groupFeed,
    });

    this.refreshInterval = setInterval(() => {
      window.location.reload();
    }, 300000);
  };

  componentWillUnmount() {
    clearInterval(this.refreshInterval); // Clear the interval when the component unmounts
  }

  rtspApiCall = async (action, e) => {
    this.setState(
      {
        action: action,
        data: e,
      },
      async () => {
        await this.modelOpen();
      },
    );
  };

  smartGateOperationChange = async () => {
    this.setState({
      buttonDisable: true,
    });
    const { gate_no, domain, camera_ip, lane_alias, control_message_suffix } = this.state.data;
    const data = {
      action: this.state.action,
      gate_no: gate_no,
      property_id: this.state.property,
      domain: domain,
      camera_ip: camera_ip,
      lane_alias: lane_alias,
      control_message_suffix: control_message_suffix,
      type: "SMART_GATE",
    };
    await this.props.setRtsp(data);
    const feedData = await this.props.getLiveFeed(this.props.PropertyId, "SmartGate");
    const groupFeed = groupBy(feedData, "gate_no");
    this.setState({
      feedData: groupFeed,
      smartGateModal: false,
      buttonDisable: false,
    });
  };

  modelOpen = async () => {
    this.setState({
      smartGateModal: true,
    });
  };

  handleClose = async () => {
    console.log("Called again");
    const allPromices = [...this.state.streamData];
    clearTimeout(this.state.timer);
    this.setState({
      disableCloseButton: false,
      streamData: [],
      timer: null,
    });
    allPromices?.map(async (item) => {
      try {
        (await item).destroy();
      } catch (error) {
        console.log("Error whilke destroying live feed");
      }
    });
  };

  openStream = (e) => {
    const data = [];
    let Sdata;
    this.setState(
      {
        liveStreamData: e,
        disableCloseButton: true,
      },
      async () => {
        for (const i of this.state.liveStreamData) {
          const feedUrl = `${this.state.url}/api/stream/${i.id}`;
          Sdata = loadPlayer({
            url: `${feedUrl}`,
            canvas: document.getElementById(`canvas${i.id}`),
            onDisconnect: () => console.log("Connection lost!"),
          });
          data.push(Sdata);
        }
        this.setState({
          streamData: data,
          disableCloseButton: false,
        });
        clearTimeout(this.state.timer);
        this.setState({
          timer: setTimeout(() => {
            this.handleClose();
          }, 180000),
        });
      },
    );
  };

  render() {
    const { smartGateModal } = this.state;

    return (
      <div>
        <div fluid>
          <Row>
            <Col md="12">
              <Card className="mb-0">
                <Card.Header>
                  <Card.Title as="h4">Smart Gate</Card.Title>
                </Card.Header>
                <Card.Body>
                  {(Object.values(this.state.feedData) ?? []).map((data, index) => {
                    return (
                      <div className="guest-section d-flex align-items-center mb-2" key={data[0]?.id}>
                        <h5 className="mb-0 w-100 min-width-modal">{data[0].lane_alias}</h5>
                        <div>
                          <div className="d-flex align-items-center AllActionGroup">
                            <div className="d-flex align-items-center">
                              <div
                                id="ignoreButton"
                                type="button"
                                className="ml-2 mb-2 ActionButton AllActionGroupInner"
                                onClick={() => this.rtspApiCall("LOPN", data[0])}
                              >
                                <img className="ml-0 mr-1 " src={lockOpenAction} width={27} />
                                <span
                                  style={{
                                    color: data[0].currentAction === "Lock Open" && "red",
                                  }}
                                >
                                  Lock Open
                                </span>
                              </div>
                              <div
                                id="ignoreButton"
                                type="button"
                                className="ml-2 mb-2 ActionButton AllActionGroupInner"
                                onClick={() => this.rtspApiCall("LCLS", data[0])}
                              >
                                <img className="ml-0 mr-1 liveFeedIcon" src={lockCloseAction} width={27} />
                                <span
                                  style={{
                                    color: data[0].currentAction === "Lock Close" && "red",
                                  }}
                                >
                                  Lock Close
                                </span>
                              </div>
                              <div
                                id="ignoreButton"
                                type="button"
                                className="ml-2 mb-2 ActionButton AllActionGroupInner"
                                onClick={() => this.rtspApiCall("LPVN", data[0])}
                              >
                                <img className="ml-0 mr-1" src={looplivefeed} width={27} />
                                <span
                                  style={{
                                    color: data[0].currentAction === "Loop Vend" && "red",
                                  }}
                                >
                                  Loop Vend
                                </span>
                              </div>
                              <div
                                id="ignoreButton"
                                type="button"
                                className="ml-2 mb-2 ActionButton AllActionGroupInner"
                                onClick={() => this.rtspApiCall("RGLR", data[0])}
                              >
                                <img className="ml-0 mr-1" src={regularAction} width={27} />
                                <span
                                  style={{
                                    color: data[0].currentAction === "Regular Operation" && "red",
                                  }}
                                >
                                  {" "}
                                  Regular Operation
                                </span>
                              </div>
                            </div>
                            <div className="ml-2 mt-3 ActionButton AllActionGroupInner currentActionbox">
                              <span>
                                {" "}
                                Last Action : <span className="font-weight-bold">{data[0].currentAction ? data[0].currentAction : "-"}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {isEmpty(this.state.feedData) && <div style={{ fontSize: 15, textAlign: "center" }}>There are no records to display</div>}
                </Card.Body>
              </Card>
              <SmartGateConfirmModal
                showBrowserModal={smartGateModal}
                smartGateLoader={this.state.smartGateLoader}
                closeModal={() =>
                  this.setState({
                    smartGateModal: !this.state.smartGateModal,
                  })
                }
                saveit={this.smartGateOperationChange}
                action={this.state.action}
                buttonDisable={this.state.buttonDisable}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = ({ SuperAdminDetails, APIResponseDetails, UserProfileDetails, EnforcementDetails }) => {
  const { userList, userCount: count, getAllUserlist, getRtspData } = SuperAdminDetails;
  const { api_response } = APIResponseDetails;
  const {
    property_id: PropertyId,
    // user_type,
    // suite_id,
    // timezone,
    property,
  } = UserProfileDetails;
  const { userlots } = EnforcementDetails;
  return {
    api_response,
    userList,
    count,
    getAllUserlist,
    PropertyId,
    getRtspData,
    property,
    userlots,
  };
};
const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  setRtsp: SuperAdminAction.setRtsp,
  getLiveFeed: SuperAdminAction.getLiveFeed,
};

export default connect(mapStatetoProps, mapStateToDispatch)(SmartGate);

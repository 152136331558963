import React from "react";

const PaginationforData = (props) => {
  return (
    <>
      {props.data?.length > 0 ? (
        <div className={"custom-pagination rdt_Pagination"}>
          <label className={"mr-2 d-block"}>Total: {props.count}</label>
          <div>
            <i onClick={props.handlepageinitial} className="fas fa-angle-double-left"></i>
          </div>
          <div>
            <i onClick={props.handlepageprevious} className="fas fa-chevron-left"></i>
          </div>
          <label>Page:</label>
          <div> {props.page}</div>
          <div>
            <i onClick={props.handlepagenext} className="fas fa-chevron-right"></i>
          </div>
          <div>
            <i onClick={props.handlepagefinal} className="fas fa-angle-double-right"></i>
          </div>
          <label>Rows:</label>
          <select onChange={props.handlepage} name="Norow" value={props.Norow}>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="300">300</option>
            <option value="400">400</option>
          </select>
        </div>
      ) : null}
    </>
  );
};

export default PaginationforData;

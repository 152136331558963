import React, { Component } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  // Tooltip,
  // OverlayTrigger,
} from "react-bootstrap";
import MaterialTable, { MTableAction } from "material-table";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import { SuperAdminAction } from "../../Actions";
import isEqual from "lodash/isEqual";
import CustomContentAlert, { navigationButtons } from "../helper";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import SetCashierPermission from "./SetCashierPermission";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import _ from "lodash";
import PermissionCashierModel from "./PermissionCashierModel";


class CashierView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cashier_name: "",
      user_name: "",
      property_id: this.props.PropertyId,
      property: this.props.property,
      formIsValid: false,
      searchValue: "",
      searchedCashier: [],
      cashierList: [],
      propertyList: [],
      api_response: false,
      api_error_message: "",
      api_success_message: "",
      show_alert: false,
      show_error: false,
      nameError: {
        error: false,
        label: "",
        helperText: "",
        validateInput: false,
      },
      setNameError: {
        error: false,
        label: "",
        helperText: "",
        validateInput: false,
      },
      modelOpenClose: false,
      modelPermissionOpenClose: false,
      module_permissions: {},
      cashierDataToBeEdited: {},
    };
    this.addActionRef = React.createRef();
  }

  componentDidMount = async () => {
    let PropertyId = this.props.PropertyId;
 
    if (this.props.user_type === "superadmin") {
      const searchParams = this.props?.location?.search;
      PropertyId = searchParams ? searchParams.split("?")[1] : "";
    }

    console.log(PropertyId);
    this.setState({ loader: true }, async () => {
      let cashier_list = await this.props.fetchCashier(PropertyId);
      await this.props.get_all_properties();
      console.log(this.props.cashierList);
      this.setState(
        {
          cashierList: cashier_list,
          propertyList: this.props.getAllProperties,
          loader: false,
          api_response: this.props.api_response,
          property_id: PropertyId,
          modelOpenClose: this.props.modelOpenClose,
        },
        () => {
          let selectedPropertyName;
          let selectedDomainName;

          const selectedPropertyObj = this.state.propertyList?.filter((item) => item.id === this.state.property_id);

          selectedPropertyName = selectedPropertyObj?.[0]?.site_name;
          selectedDomainName = selectedPropertyObj?.[0]?.domain;
          if (selectedPropertyObj.length === 0) {
            selectedPropertyName = this.props?.property?.property_name;
            selectedDomainName = this.props?.property?.domain;
          }

          this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
        },
      );
    });
  };

  componentDidUpdate = (prevProps) => {
    if (!isEqual(prevProps.cashierList, this.props.cashierList)) {
      this.setState({ cashierList: this.props.cashierList });
    }
    if (prevProps.modelOpenClose !== this.props.modelOpenClose) {
      this.setState({ modelOpenClose: this.props.modelOpenClose });
    }
  };

  handleSubmit = async (form_data) => {

    let cashier_exist = await this.props.exist_cashier(form_data.property_id, form_data.user_name);
    console.log(cashier_exist);

    if(cashier_exist){
      this.setState(
        {
          show_error: true,
          api_error_message: 'You cannot use the same username. Please try with different username.',
        },
        () => {
          setTimeout(() => {
            this.setState({ show_error: false });
          }, 4000);
        },
      );
    }else{
      await this.props.addCashier(form_data);
      if (this.props.api_response) {
        this.setState(
          {
            show_alert: true,
            api_success_message: this.props.api_success_message,
          },
          () => {
            this.hideAlert();
          },
        );
      } else {
        this.setState(
          {
            show_error: true,
            api_error_message: this.props.api_error_message,
          },
          () => {
            setTimeout(() => {
              this.setState({ show_error: false });
            }, 4000);
          },
        );
      }
    }
  };

  hideAlert = async () => {
    let property_id = this.props.PropertyId;
    let cashier_list = await this.props.fetchCashier(property_id);
    setTimeout(() => {
      this.setState({
        show_alert: false,
        cashierList: cashier_list,
      });
    }, 4000);
  };

  handleCashierEdit = async (editData) => {
      await this.props.editCashier({
        ...editData,
        old_data: this.state.cashierList.find((x) => x.id === editData.id),
      });
      if (this.props.api_response) {
        this.setState({
          show_alert: true,
          api_success_message: this.props.api_success_message,
        });
        this.hideAlert();
      } else {
        this.setState({
          show_error: true,
          api_error_message: this.props.api_error_message,
        });
        setTimeout(() => {
          this.setState({ show_error: false });
        }, 4000);
      }
  };

  handleCashierDelete = async (cashierId) => {
    await this.props.deleteCashier(cashierId);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      this.hideAlert();
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  AddRow = () => {
    this.setState({
      modelOpenClose: true,
      cashier_permission: [],
    });
  };

  EditRow = (rowData) => {
    this.setState({
      modelOpenClose: true,
      cashierDataToBeEdited: rowData,
    });
  };

  DeleteRow = async (rowData) => {
    const confirmation = window.confirm("Are you sure you want to delete this entry?");
    if (confirmation) {
      this.handleCashierDelete(rowData.id);
    }
  };

  handleClose = () => {
    this.setState({
      modelOpenClose: false,
      cashierDataToBeEdited: {},
    });
  };

  handleCashierPermission = (cashierPermissions, cashierData, isEdit) => {
    const module_permissions = [];
    !_.isEmpty(cashierPermissions) &&
      cashierPermissions.map((ele) => {
        module_permissions.push({
          permission_module_name: ele.label,
          is_active: ele.is_active,
          permission_module_id: ele.permission_module_id,
        });
        return ele;
      });

    this.setState({
      module_permissions: module_permissions,
    });
    const new_form_data = {
      cashier_name: cashierData.cashier_name,
      user_name: cashierData.user_name,
      password: cashierData.password,
      property_id: this.props.PropertyId,
    };
    if (!isEdit) {
      this.handleSubmit(new_form_data);
    } else {
      new_form_data.id = cashierData.id;
      this.handleCashierEdit(new_form_data);
    }
    this.handleClose();
  };

  handleActionField = (props) => {
    if (typeof props.action === typeof Function || props.action.tooltip !== "Add") {
      return <MTableAction {...props} />;
    } else {
      return <div ref={this.addActionRef} onClick={props.action.onClick} />;
    }
  };

  handleIconField = () => (
    <Button className="btn-blue-bg" data-mycustomid={"add-icon-handler"}>
      Add Cashier
    </Button>
  );

  handlePassword = (props) => {
    return <TextField type="password" value={props.value} autoComplete="off" onChange={(e) => props.onChange(e.target.value)} />;
  };

  render() {
    const {
      loader,
      cashierList,
      api_success_message,
      api_error_message,
      show_alert,
      show_error,
      modelOpenClose,
      modelPermissionOpenClose,
      cashier_permission,
    } = this.state;
    this.columns = [
      {
        title: "Name",
        field: "cashier_name",
        validate: (rowData) => (rowData.cashier_name === "" ? "Cashier name is required!" : ""),
      },
      {
        title: "Username",
        field: "user_name",
        validate: (rowData) => (rowData.user_name === "" ? "User name is required!" : ""),
      },
      {
        title: "Action",
        field: "-",
        render: (rowData) =>
          !modelOpenClose && (
            <div className={"d-flex"}>
              <CustomButton className={"mr-2"} onClick={() => this.EditRow(rowData)}>
                <Edit />
              </CustomButton>
              <CustomButton className={"ml-2"} onClick={() => this.DeleteRow(rowData)}>
                <Delete />
              </CustomButton>
            </div>
          ),
      },
    ];
    return (
      <>
        <Container fluid>
          <Row className="h-100">
            <Col md="12">
              <Card className="h-100">
                <Card.Header>
                  <Card.Title as="h4">Manage Cashier</Card.Title>
                  <p className="card-category"></p>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide uer-list">
                  {loader ? (
                    <Loader />
                  ) : (
                    <StyledDiv style={{ maxWidth: "100%" }}>
                      <MaterialTable
                        title=""
                        components={{
                          Action: this.handleActionField,
                        }}
                        options={{
                          actionsColumnIndex: -1,
                          addRowPosition: "first",
                          draggable: false,
                        }}
                        columns={this.columns}
                        data={cashierList}
                        icons={{
                          Add: this.handleIconField,
                          hidden: true,
                        }}
                        actions={[{ hidden: true }]}
                      />
                    </StyledDiv>
                  )}
                  {window.location.pathname !== "/manage-admin" ? (
                    <Col lg="12" className="p-0">
                      {navigationButtons(
                        "/settings",
                        "/permissions",
                        this.state.property_id,
                        this.addActionRef,
                        "Add Cashier",
                        null,
                        null,
                        () => {
                          const history = require("../../history").default;
                          history.push({
                            pathname: "/permissions",
                            search: this.state.property_id,
                          });
                        },
                        () => {
                          this.AddRow();
                        },
                      )}
                    </Col>
                  ) : (
                    <Col lg="12" className="p-0 mt-2">
                      <Button
                        className="btn-blue-bg"
                        onClick={() => {
                          this.AddRow();
                        }}
                      >
                        {"Add Cashier"}
                      </Button>
                    </Col>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {modelOpenClose && (
          <SetCashierPermission
            modelOpenClose={modelOpenClose}
            handleClose={this.handleClose}
            propertyId={this.state.property_id}
            handleCashierPermission={this.handleCashierPermission}
            cashierDataToBeEdited={this.state.cashierDataToBeEdited}
          />
        )}
        {modelPermissionOpenClose && (
          <PermissionCashierModel
            modelPermissionOpenClose={modelPermissionOpenClose}
            handleClose={this.handlePermissionModelClose}
            cashier_permission={cashier_permission}
          />
        )}
        {show_alert && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
        {show_error && <CustomContentAlert delay={5000} message={api_error_message} className="toast-error" />}
      </>
    );
  }
}

const mapStateToProps = ({ SuperAdminDetails, APIResponseDetails, UserProfileDetails }) => {
  const { getAllProperties, cashierList, modelOpenClose } = SuperAdminDetails;
  const { api_response, api_success_message, api_error_message } = APIResponseDetails;
  const { property, property_id: PropertyId, id } = UserProfileDetails;
  return {
    cashierList,
    getAllProperties,
    api_response,
    api_success_message,
    api_error_message,
    modelOpenClose,
    PropertyId,
    property,
    id,
  };
};

const mapStateToDispatch = {
  addCashier: SuperAdminAction.addCashier,
  fetchCashier: SuperAdminAction.fetchCashier,
  editCashier: SuperAdminAction.editCashier,
  deleteCashier: SuperAdminAction.deleteCashier,
  exist_cashier: SuperAdminAction.exist_cashier,
  get_all_properties: SuperAdminAction.get_all_properties,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(CashierView);
const CustomButton = styled(Button)`
  height: 40px;
  width: 40px;
  border-radius: 25px;
  padding: 5px;
`;
const StyledDiv = styled.div`
  max-width: 100%;
  table > thead {
    th:last-child {
      display: none;
    }
  }
  table > tbody {
    td:last-child {
      display: none;
    }
  }
`;

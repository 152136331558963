import t from "./types";
import { AuthAPI, UploadFormData, UnAuthAPI, BufferAuthAPI } from "../../../Auth/requestHandler";
import apiType from "../APIResponseActions/type";
import convert from "xml-js";
import Config from "../../../Auth/config";
import { convertDateTimeToUtc, AddTimeInDate } from "../../Components/helper";
import moment from "moment";
import { findStartDateAndEndDateForAllReports } from "../common";

export const createTicketReasons = (body, isUpdate) => async (dispatch) => {
  try {
    const property_id = body.property_id;
    const url = isUpdate ? "reason/" + property_id : "reason";
    const type = isUpdate ? "PATCH" : "POST";
    const response = await AuthAPI("domain", url, type, body);
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.msg,
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getReasonDetails =
  (propertyId, fromSite = false) =>
  async (dispatch) => {
    try {
      const url = "reason/" + propertyId + `${fromSite ? "?fromSite=true" : ""}`;
      const response = await AuthAPI("domain", url, "GET");
      await dispatch({
        type: t.FETCH_NEW_REASON,
        payload: response,
      });
    } catch (error) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: error.toString(),
      });
    }
  };

export const generateTicket = (body) => async (dispatch) => {
  try {
    const response = await UploadFormData("domain", "ticket", "POST", body);
    if (response.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.msg,
      });
      await dispatch({
        type: t.FETCH_REASON_LIST,
        payload: response.reasons,
      });
      await dispatch({
        type: t.FETCH_GENERATE_TICKET,
        payload: response,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    console.error("in catch", error);
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: "Error in generating citation" });
  }
};

export const getQRcode = () => async (dispatch) => {
  try {
    const response = await AuthAPI("domain", "self-register/qrcode", "GET");
    await dispatch({
      type: t.FETCH_QR_CODE,
      payload: response.data,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getLastTicketDetails = (propertyId) => async (dispatch) => {
  try {
    const url = "ticket/view_last/" + propertyId;
    const response = await AuthAPI("domain", url, "GET");
    await dispatch({
      type: t.FETCH_VIEW_LAST_TICKET,
      payload: response,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const viewLastPlateNumber = (lpn) => async (dispatch) => {
  try {
    const url = "ticket/view_last_plate/" + lpn;
    const response = await AuthAPI("domain", url, "GET");
    await dispatch({
      type: t.FETCH_VIEW_LAST_PLATE_NUMBER,
      payload: response,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const viewVinWarningNumber = (lpn) => async (dispatch) => {
  try {
    const url = "ticket/view_warnng_vin/" + lpn;
    const response = await AuthAPI("domain", url, "GET");
    await dispatch({
      type: t.FETCH_VIN_WARNING_NUMBER,
      payload: response,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getVinInfo = (vin) => async (dispatch) => {
  console.log("getVinInfo Called:----------");
  try {
    const url = "ticket/vin_info/" + vin;
    const response = await AuthAPI("domain", url, "GET");
    await dispatch({
      type: t.FETCH_VIN_INFO,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

// export const getTicketInfo = (ticketNo, lpn, isDriver) => async (dispatch) => {
//   try {
//     let url = "";
//     let response = "";
//     if (isDriver) {
//       url = `public/ticket/info/${ticketNo}/?lpn= ${lpn}`;
//       response = await UnAuthAPI(url, "GET");
//     } else {
//       url = `ticket/info/${ticketNo}`;
//       response = await AuthAPI("domain", url, "GET");
//     }
//     if (response && response.success === 1) {
//       await dispatch({
//         type: t.FETCH_TICKET_INFO,
//         payload: response,
//       });
//       await dispatch({
//         type: apiType.API_RESPONSE_SUCCESS,
//         payload: response.message,
//       });
//     } else {
//       await dispatch({
//         type: apiType.API_RESPONSE_FAILURE,
//         payload: response.message,
//       });
//     }
//   } catch (error) {
//     await dispatch({
//       type: apiType.API_RESPONSE_FAILURE,
//       payload: error.toString(),
//     });
//   }
// };

export const getTicketList = (pageObj) => async (dispatch) => {
  try {
    const exportValue = pageObj?.exportData ? pageObj.exportData : false;
    const searchValue = pageObj.searchValue ? pageObj.searchValue : "";
    /* Ticket - WORLD-582 Filter */
    pageObj.selectedSession = pageObj?.selectedSession || "";
    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);

    const url =
      "ticket/paginate?selectedOption=" +
      pageObj.selectedOption +
      "&property_id=" +
      pageObj.PropertyId +
      "&childcheckbox=" +
      pageObj.childcheckbox +
      "&search=" +
      searchValue +
      "&order_by=" +
      pageObj.selectedColumn +
      "&sorting=" +
      pageObj.sort_direction +
      "&status=" +
      pageObj.status +
      "&state=" +
      pageObj.state +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&export_data=" +
      exportValue +
      "&session_id=" +
      pageObj.selectedSession;

    if (pageObj.exportData) {
      try {
        return await BufferAuthAPI("domain", url, "GET");
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "GET");
      if (response?.success) {
        await dispatch({
          type: t.FETCH_TICKET_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getSessionList = (pageObj) => async (dispatch) => {
  try {
    let { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    const body = {
      childcheckbox: pageObj.childcheckbox,
      startDate: startDate,
      endDate: endDate,
    };
    const url = `ticket/session_data/${pageObj.PropertyId}`;
    const response = await AuthAPI("domain", url, "POST", body);
    if (response?.success) {
      await dispatch({
        type: t.FETCH_SESSION_DATA,
        payload: response?.rows,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const doCloseTicket = (ticket_id, data) => async (dispatch) => {
  try {
    const url = "ticket/close/" + ticket_id;
    const response = await AuthAPI("domain", url, "POST", data);
    if (response?.success === 1 && response?.message) {
      await dispatch({
        type: t.UPDATE_TICKET_LIST,
        payload: response,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response?.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response?.message,
      });
    }
  } catch (error) {
    console.error("Error on  doCloseTicket:", error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const TicketPaymentStep1 = (body, domain) => async (dispatch) => {
  let driverUrl;
  const windowUrl = window?.location?.href.split("/");
  const baseUrl = windowUrl[0];
  if (baseUrl === "https:") {
    driverUrl = `<redirect-url>https://${domain}.${process.env.REACT_APP_MAIN_URL}/ticket-payment/payment/step2</redirect-url>`;
  } else {
    driverUrl = `<redirect-url>${process.env.REACT_APP_MAIN_URL}/ticket-payment/payment/step2</redirect-url>`;
  }

  const data = await fetch("https://locomobiworld.transactiongateway.com/api/v2/three-step", {
    method: "POST",
    headers: new Headers({
      "Content-Type": "text/xml",
    }),
    body: `
        <sale>
          <api-key>2F822Rw39fx762MaV7Yy86jXGTC7sCDy</api-key>
          ${driverUrl}
          <amount>${body.fee}</amount>
          <currency>USD</currency>
          <order-description>token_no:${body.tokenId}</order-description>
        </sale >
  `,
  });
  const paymentData = await data.text();
  const xmlDoc = JSON.parse(convert.xml2json(paymentData));
  if (xmlDoc) {
    await dispatch({
      type: t.PAYMENT_STEP1,
      payload: xmlDoc,
    });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
  }
};

export const TicketPaymentStep3 = (body) => async (dispatch) => {
  const data = await fetch("https://locomobiworld.transactiongateway.com/api/v2/three-step", {
    method: "POST",
    headers: new Headers({
      "Content-Type": "text/xml",
    }),
    body: `<complete-action>
        <api-key>2F822Rw39fx762MaV7Yy86jXGTC7sCDy</api-key>
        <token-id>${body}</token-id>
        </complete-action >
  `,
  });
  const paymentData = await data.text();
  const xmlDoc = JSON.parse(convert.xml2json(paymentData));
  if (xmlDoc) {
    await dispatch({
      type: t.PAYMENT_STEP3,
      payload: xmlDoc,
    });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
  }
};

export const PaymentStatus = (body, tokenId) => async (dispatch) => {
  try {
    const url = "public/ticket/payment_status/" + tokenId;
    const response = await UnAuthAPI(url, "PATCH", body);
    if (response.success === 1) {
      await dispatch({
        type: t.FETCH_PAYMENT_STATUS,
        payload: response,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const TicketDispute = (body) => async (dispatch) => {
  try {
    const response = await UnAuthAPI("dispute", "POST", body);
    if (response.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const sendText = (body, isDriver) => async (dispatch) => {
  try {
    let response;
    const formData = new FormData();
    Object.keys(body).forEach((key) => {
      formData.append(key, body[key]);
    });
    console.log(formData);
    if (isDriver) {
      response = await UnAuthAPI("public/dispute/chat", "POST", formData);
    } else {
      response = await UploadFormData("domain", "dispute/chat", "POST", formData);
    }
    if (response.success === 1) {
      await dispatch({
        type: t.UPDATE_MESSAGE_LIST,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getMessageList = (ticketId, isDriver, log, language) => async (dispatch) => {
  try {
    let response = "";
    let responseData = "";
    const activeLog = log ? 1 : 0;
    if (isDriver) {
      response = await UnAuthAPI(`public/ticket/info/${ticketId}?language=${language}`, "GET");
      responseData = response?.data[0].dispute_chats ? response.data[0].dispute_chats : "";
    } else {
      response = await AuthAPI("domain", `ticket/info/${ticketId}?language=${language}&activeLog=${activeLog}`, "GET");
      responseData = response?.data[0].dispute_chats ? response.data[0].dispute_chats : "";
    }
    if (response.success === 1) {
      await dispatch({
        type: t.FETCH_MESSAGE_LIST,
        payload: response?.data ? responseData : [],
      });
      await dispatch({
        type: t.GET_TICKET_DETAILS,
        payload: response,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
    return response;
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const statusChange = (body) => async (dispatch) => {
  try {
    const response = await await AuthAPI("domain", "dispute/status/change", "POST", body);
    if (response.success === 1) {
      await dispatch({
        type: t.UPDATE_STATUS,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const ticketStatusChange = (body) => async (dispatch) => {
  try {
    const response = await await AuthAPI("domain", "dispute/ticket/status/change", "POST", body);

    if (response.success === 1) {
      await dispatch({
        type: t.UPDATE_STATUS,
        payload: response.data,
      });
      await dispatch({
        type: t.UPDATE_TICKET_STATUS,
        payload: response.data,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const pdfSendToMail = (sendMailData) => async (dispatch) => {
  try {
    const url = "ticket/send-email/" + sendMailData.selectedTicketId;
    const body = sendMailData;
    const response = await AuthAPI("domain", url, "POST", body);
    console.log("Mail send Response success::::::", response);
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    console.log(error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const getBlobPdf = (ticketId, type, userId, propertyId) => async () => {
  try {
    const url = `download-pdf/${ticketId}/${type}/${userId}/${propertyId}`;
    if (type === "zpl") {
      return await AuthAPI("domain", url, "GET");
    } else {
      return await new Promise((resolve) => {
        fetch(`${Config.baseURL}/${url}`)
          .then((blobData) => blobData.blob())
          .then((response) => {
            console.log("==== response", response);
            resolve(URL.createObjectURL(response));
          })
          .catch((error) => {
            console.log("==== error", error);
          });
      });
    }
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const selectedLanguage = (lang) => async (dispatch) => {
  try {
    await dispatch({
      type: t.SELECT_LANG,
      payload: lang,
    });
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const getPng = (ticketId, type, userId, propertyId) => async () => {
  console.log("type action side   getPng:::::::::", type);
  console.log("type action side   getPng:::::::::", ticketId);

  try {
    const url = `download-pdf/${ticketId}/${type}/${userId}/${propertyId}`;
    return await new Promise((resolve) => {
      fetch(`${Config.baseURL}/${url}`)
        .then((response) => {
          console.log("==== response", response);
          return response.json();
        })
        .then((response) => {
          console.log("response:::", response);
          resolve(response.data);
        });
    });
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const findTicketRow = (ticketId) => async (dispatch) => {
  try {
    const url = "ticket/find-ticket-by-id/" + ticketId;
    return await AuthAPI("domain", url, "GET");
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getRatePaymentList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.PropertyId,
      lot_id: pageObj.lot_id,

      timezone: pageObj.timezone,
      exportValue: pageObj?.exportData || false,
      childcheckbox: pageObj.childcheckbox,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj, true);
    body.startDate = startDate;
    body.endDate = endDate;

    const url = "report/rate-payment/list";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_RATE_PAYMENT_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getVendControlList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.PropertyId,
      selectedRadio: pageObj.selectedRadio,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportData || false,
      childcheckbox: pageObj.childcheckbox || false,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;

    const url = "ticket/vend_control_report";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_VEND_CONTROL_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getCarImagePath = (id, scan_id, property_id, ticket_time) => async (dispatch) => {
  try {
    const url = "ticket/get-ticket-dispenser-image?property_id=" + property_id + "&scan_id=" + scan_id + "&id=" + id + "&ticket_time=" + ticket_time;
    return await AuthAPI("domain", url, "GET");
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getTransactionsList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.PropertyId,
      selectedRadio: pageObj.selectedRadio,
      timezone: pageObj.timezone,
      status: pageObj.status,
      exportValue: pageObj.exportData || false,
      childcheckbox: pageObj.childcheckbox || false,
    };
    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;
    const url = "report/transactions/list";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_TRANSACTIONS_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

//  This function use in both side(refund and transaction reports)
// so whatever has to be changed please test both the reports

export const getPermitTransactionsList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      selectedOption: pageObj.selectedOption,
      PropertyId: pageObj.PropertyId,
      lot_id: pageObj.lot_id,
      unit_id: pageObj.unit_id,
      selectedRadio: pageObj.selectedRadio,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportData || false,
      type: pageObj.location_type,
      filterStatus: pageObj.filterStatus,
      status: pageObj.data_show_type === "Refunded" ? "Refunded" : "",
      childcheckbox: pageObj.childcheckbox || false,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;
    const url = "report/permit-transactions/list";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_PERMIT_TRANSACTIONS_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getTotalAnalysisList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      PropertyId: pageObj.PropertyId,
      timezone: pageObj.timezone,
      exportValue: pageObj?.exportData || false,
      childcheckbox: pageObj?.childcheckbox || false,
    };

    const url = "report/total-citations/list";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_TOTAL_CITATIONS_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getPaidUnpaidList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      PropertyId: pageObj.PropertyId,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportData ? pageObj.exportData : false,
      childcheckbox: pageObj.childcheckbox || false,
    };

    const url = "report/paid-unpaid/list";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_TOTAL_PAID_UNPAID_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getTotalStatusList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      PropertyId: pageObj.PropertyId,
      timezone: pageObj.timezone,
      exportValue: pageObj?.exportData || false,
      childcheckbox: pageObj.childcheckbox || false,
    };

    const url = "report/total-status/list";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_TOTAL_STATUS_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const changeTicketStateAndStatus = (ticketId) => async (dispatch) => {
  try {
    const body = {
      status: "Unpaid",
      state: "Open",
    };
    const url = "ticket/reopen-ticket/" + ticketId;
    const response = await AuthAPI("domain", url, "POST", body);
    if (response?.success) {
      body.id = ticketId;
      await dispatch({
        type: t.UPDATE_TICKET_STATUS,
        payload: body,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getByEnfocerList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      PropertyId: pageObj.PropertyId,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportData || false,
      month: pageObj.month,
      childcheckbox: pageObj.childcheckbox,
    };
    if (pageObj.childcheckbox) {
      body.childcheckbox = pageObj.childcheckbox;
    }

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;

    console.log("currentDateStartTime,currentDateEndTime", body.currentDateStartTime, body.currentDateEndTime);
    const url = "report/enfocer/list";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_TOTAL_ENFOCER_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getByEnfocerPaidList = (pageObj) => async (dispatch) => {
  try {
    console.log("get by enforcer paid list is running.....", pageObj.timezone);
    const body = {
      PropertyId: pageObj.PropertyId,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportData || false,
      month: pageObj.month,
      childcheckbox: pageObj.childcheckbox || false,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;

    const url = "report/enforcerpaid/list";
    if (body.exportValue) {
      try {
        return await BufferAuthAPI("domain", url, "POST", body);
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_TOTAL_ENFOCER_PAID_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getPDFandPNGfromZpl = (zpl, type, iflog) => async (dispatch) => {
  try {
    const body = {
      zpl: zpl,
      type: type,
      iflog: iflog,
    };
    const url = "ticket/generate-pdf-from-zpl";
    const Bearer_Token = localStorage.getItem("basicAuth");
    return await new Promise((resolve) => {
      fetch(`${Config.baseURL}/${url}`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(Bearer_Token),
        },
      })
        .then((blobData) => blobData.blob())
        .then((response) => {
          if (type === "pdf") {
            resolve(URL.createObjectURL(response));
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log("error on getPDFandPNGfromZpl", error);
        });
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getRefundList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      property_id: pageObj.property_id,
      timezone: pageObj.timezone,
      exportValue: pageObj?.exportValue || false,
      childcheckbox: pageObj.childcheckbox,
    };

    const { startDate, endDate } = await findStartDateAndEndDateForAllReports(pageObj);
    body.startDate = startDate;
    body.endDate = endDate;

    const url = "report/refund/list";
    if (body.exportValue) {
      try {
        const data = await BufferAuthAPI("domain", url, "POST", body);
        await dispatch({
          type: t.FETCH_EXPORT_LIST,
          payload: data,
        });
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_REFUND_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getOfflineCitationList = (pageObj) => async (dispatch) => {
  try {
    const body = {
      property_id: pageObj.property_id,
      timezone: pageObj.timezone,
      exportValue: pageObj.exportValue ? pageObj.exportValue : false,
    };

    if (pageObj.childcheckbox) {
      body.childcheckbox = pageObj.childcheckbox;
    }

    if (pageObj.currentDate) {
      const formatStartDate = moment(pageObj.currentDate).format("YYYY-MM-DD");
      const startDateWithTime = `${formatStartDate} 00:00:00`;
      const formatEndDate = moment(pageObj.currentDate).format("YYYY-MM-DD");
      const endDateWithTime = `${formatEndDate} 23:59:59`;

      const { startcurrent_time, endcurrent_time } = convertDateTimeToUtc(pageObj.timezone, startDateWithTime, endDateWithTime);
      body.startDate = startcurrent_time;
      body.endDate = endcurrent_time;
    } else if (pageObj.startDate && pageObj.endDate) {
      const { startTimeFormat, EndTimeFormat } = AddTimeInDate(pageObj.startDate, pageObj.endDate);
      const { startcurrent_time, endcurrent_time } = convertDateTimeToUtc(pageObj.timezone, startTimeFormat, EndTimeFormat);
      body.startDate = startcurrent_time;
      body.endDate = endcurrent_time;
    }

    const url = "ticket/offline_citation/list";
    if (body.exportValue) {
      try {
        const data = await BufferAuthAPI("domain", url, "POST", body);
        await dispatch({
          type: t.FETCH_EXPORT_LIST,
          payload: data,
        });
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const response = await AuthAPI("domain", url, "POST", body);
      if (response?.success) {
        await dispatch({
          type: t.FETCH_OFFLINE_CITATION_LIST,
          payload: response,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const createRefund = (data) => async (dispatch) => {
  try {
    const url = `ticket/refund/${data.ticket_id}`;
    const response = await AuthAPI("domain", url, "POST", data);
    if (response.success) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      await dispatch({
        type: t.UPDATE_TICKET_DATA,
        payload: response.data,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
    return response;
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const sendTransactionMail = (sendMailData) => async (dispatch) => {
  try {
    const url = "ticket/send_transaction_mail/" + sendMailData.ticket_id;
    const body = sendMailData;
    const response = await AuthAPI("domain", url, "POST", body);
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    console.log(error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const getEncodedFileFromLabelaryApi = (body) => async () => {
  try {
    const obj = {
      url: body,
    };
    const url = "ticket/get-zpl-image-url";
    return AuthAPI("domain", url, "POST", obj);
  } catch (error) {
    console.log(error);
  }
};

import t from "../../Actions/CameraActions/type";
const initialState = {
  camera_list: [],
};

const CameraReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.FETCHCAMERAS: {
      return { ...state, camera_list: action.payload };
    }
    case t.ADDCAMERA: {
      const camera_list = JSON.parse(JSON.stringify(state.camera_list));
      camera_list.push(action.payload);
      return Object.assign({}, state, {
        camera_list: sortData(camera_list),
      });
    }
    case t.EDITCAMERA: {
      const camera_list = JSON.parse(JSON.stringify(state.camera_list));
      const index = camera_list.findIndex((Camera) => Camera.id === action.payload.id);
      camera_list[index] = action.payload;
      return {
        ...state,
        camera_list: JSON.parse(JSON.stringify(sortData(camera_list))),
      };
    }
    case t.DELETECAMERA: {
      let camera_list = JSON.parse(JSON.stringify(state.camera_list));
      camera_list = camera_list.filter((camera) => camera.id !== action.payload.id);
      return {
        ...state,
        camera_list: JSON.parse(JSON.stringify(sortData(camera_list))),
      };
    }
    default:
      return state;
  }
};

const sortData = (data) => {
  data.sort((a, b) => {
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  });
  return data;
};

export default CameraReducer;

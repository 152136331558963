const types = {
  FETCH_CURRENT_VISITOR_PERMIT: "FETCH_CURRENT_VISITOR_PERMIT",
  FETCH_QUICK_PERMIT_SETTING_DETAILS: "FETCH_QUICK_PERMIT_SETTING_DETAILS",
  FETCH_QUICK_PERMIT_PIN_DETAILS: "FETCH_QUICK_PERMIT_PIN_DETAILS",
  FETCH_QUICK_PERMIT_SETTING_DETAILS_BLANK:
    "FETCH_QUICK_PERMIT_SETTING_DETAILS_BLANK",
  FETCH_PARKING_PERMIT_SETTING_DETAILS: "FETCH_PARKING_PERMIT_SETTING_DETAILS",
  FETCH_PARKING_PERMIT_SETTING_DETAILS_BLANK:
    "FETCH_PARKING_PERMIT_SETTING_DETAILS_BLANK",
};

export default types;

import React, { useEffect, useState } from "react";
import { Row, Col, Card, Container, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import { useSelector, useDispatch } from "react-redux";
import * as SuperAdminAction from "../../Actions/SuperAdminAction/actions";
import { GateSchedulerActions } from "../../Actions";
import { saveAs } from "file-saver";
import CustomContentAlert, { tableHeightScrollBars, findBySearch, scroolToTop } from "../helper";
import moment from "moment";
import { useHistory } from "react-router-dom";
import GateSchedulerActiveButton from "./GateSchedulerActiveButton";

function GateScheduler() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { property_id, timezone, property } = useSelector((userState) => userState.UserProfileDetails);

  const { gateSchedulerList } = useSelector((state) => state.GateSchedulerDetails);
  const { addUpdateDeleteResponse } = useSelector((state) => state.GateSchedulerDetails);
  const [gateSchedulerListData, setGateSchedulerListData] = useState([]);
  const [searchGateSchedulerListData, setSearchGateSchedulerListData] = useState([]);
  const stateOptions = [
    { value: "RGLR", key: "Regular Operation" },
    { value: "TOPN", key: "Timed Open" },
    { value: "TCLS", key: "Timed Close" },
  ];
  const [tableHeight, setTableHeight] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [findMore, setFindMore] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [actionDisable, setActionDisable] = useState(false);
  const [showErrAlert, setShowErrAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isDeleteUpdate, setIsdeleteUpdate] = useState(false);

  useEffect(() => {
    setFindMore(false);
    const height = tableHeightScrollBars();
    dispatch(SuperAdminAction.setDomainHeader(property?.property_name, property?.domain));
    dispatch(
      GateSchedulerActions.getGateSchedulerList({
        exports: false,
        property_id: property_id,
      }),
    );
    setTableHeight(height);
  }, [property, isDeleteUpdate]);

  useEffect(() => {
    if (gateSchedulerList.success !== null) {
      if (gateSchedulerList) {
        setGateSchedulerListData(gateSchedulerList?.data);
      }
    }
  }, [gateSchedulerList, isDeleteUpdate]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  const handleDelete = (row) => {
    dispatch(
      GateSchedulerActions.deleteGateScheduler({
        property_id: property_id,
        group_id: row?.group_id,
      }),
    );
    setIsdeleteUpdate(!isDeleteUpdate);
    setTimeout(() => {
      dispatch(GateSchedulerActions.clearAddUpdateDeleteRes());
    }, 2500);
  };

  const handleActive = (e, row) => {
    dispatch(
      GateSchedulerActions.createGateScheduler({
        type: "updateActive",
        active: e.target?.checked,
        group_id: row?.group_id,
        property_id: property_id,
      }),
    );
    setTimeout(() => {
      dispatch(GateSchedulerActions.clearAddUpdateDeleteRes());
    }, 2500);
  };

  useEffect(() => {
    if (addUpdateDeleteResponse?.success !== null) {
      if (addUpdateDeleteResponse?.success === true) {
        setShowAlert(true);
        setActionDisable(true);
        setSuccessMsg(addUpdateDeleteResponse?.message);
        setTimeout(() => {
          setShowAlert(false);
          setSuccessMsg("");
          setActionDisable(false);
        }, 2000);
      } else {
        setShowErrAlert(true);
        setActionDisable(true);
        setErrorMsg(addUpdateDeleteResponse?.message);
        setTimeout(() => {
          setShowErrAlert(false);
          setActionDisable(false);
          setErrorMsg("");
        }, 2000);
      }
    }
  }, [addUpdateDeleteResponse]);

  const showGateNo = (row) => (
    <div>
      <a>{row?.gate_no ? row?.gate_no : "-"}</a>
    </div>
  );

  const ActiveButton = (row) => <GateSchedulerActiveButton row={row} handleActive={handleActive} />;

  const actionButton = (row) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="Primary" className="btn-xs" id="dropdown-basic" disabled={actionDisable}>
          Action
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => history.push({ pathname: "edit-gate-scheduler", state: row })}>Edit Schedule</Dropdown.Item>
          <Dropdown.Item onClick={() => handleDelete(row)}>Delete Schedule</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = [
    {
      name: "Gate ID",
      width: "150px",
      sortable: true,
      cell: showGateNo,
      selector: (row) => row?.gate_no,
    },
    {
      name: "Gate Lane",
      width: "140px",
      sortable: true,
      cell: (row) => row.lane_alias,
      selector: (row) => row.lane_alias,
    },
    {
      name: "Day(s) of Week",
      sortable: true,
      width: "180px",
      cell: (row) => row.dayofweek.map((i) => i.label).toString(),
      selector: (row) => row.dayofweek.map((i) => i.label),
    },
    {
      name: "Event / Holiday / Special Day",
      width: "240px",
      sortable: true,
      cell: (row) => (row.holiday ? "Yes" : "No"),
      selector: (row) => row.holiday,
    },
    {
      name: "Event Date",
      width: "170px",
      sortable: true,
      cell: (row) => row?.date || "",
      selector: (row) => row.date,
    },
    {
      name: "Enable Schedule",
      width: "140px",
      sortable: true,
      cell: ActiveButton,
      // selector: (row) => row?.active,
    },
    {
      name: "Action",
      width: "100px",
      cell: actionButton,
    },
  ];

  const expandedComponent = ({ data }) => {
    return (
      <div className="extend-row">
        {data?.timeOption &&
          data?.timeOption.map((item) => (
            <Row className="expandedView mb-1" key={item.id}>
              <Col>
                <Row>
                  <Col xs="4">
                    <b>Begin Time</b>
                  </Col>
                  <Col xs="8">
                    <span>{item.startTime}</span>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col xs="4">
                    <b>End Time</b>
                  </Col>
                  <Col xs="8">
                    <span>{item.endTime ? item.endTime : "-"}</span>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col xs="4">
                    <b>Gate State</b>
                  </Col>
                  <Col xs="8">
                    <span>{item.state ? stateOptions.find((i) => i?.value === item?.state)?.key : "-"}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
      </div>
    );
  };

  const dataTableHandle = (data) => {
    return (
      <DataTable
        data={searchValue?.length ? searchGateSchedulerListData : gateSchedulerListData}
        columns={columns}
        customStyles={customStyles}
        expandableRows
        expandableRowsComponent={expandedComponent}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={tableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={gateSchedulerListData?.length}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  const handleSearchChange = async ({ target }) => {
    setSearchValue(target?.value);
    const columnList = ["state", "lane_alias"];
    if (target.value.length) {
      if (!findMore) {
        setSearchGateSchedulerListData(findBySearch(target.value, gateSchedulerListData, gateSchedulerListData, columnList, timezone));
      }
    }
  };

  const handleExport = async () => {
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `GATE_SCHEDULER-${currentTimeDate}-${property?.domain}.xls`;
    const bufferData = dispatch(
      GateSchedulerActions.getGateSchedulerList({
        exports: true,
        property_id: property_id,
      }),
    );
    if (bufferData) {
      saveAs(
        new Blob([await bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  return (
    <div>
      <Container fluid className="manage-unit-list-page page-no-fliter">
        <Card className="mb-0">
          <Card.Body className="pos-rel-overflow-hide">
            <>
              <header>
                <div className="active-permit-header sm-flex-column sm-align-items-start">
                  <h5 className="mb-2 mb-md-0">Gate Scheduler</h5>
                  <div className="filter-button d-flex flex-wrap">
                    <div className="d-flex align-items-center search-mb-0">
                      <DatatableHeader className="mt-0 mb-0" searchValue={searchValue} HandleChange={handleSearchChange} />
                    </div>
                    <div className="buttons-dd">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="Primary"
                          id="dropdown-basic"
                          className="btn btn-sm border-0 bg-light  w-30px h-30px d-flex align-items-center justify-content-center ml-2"
                        >
                          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item className="text-sm" onClick={() => history.push("/add-gate-scheduler")}>
                            <span className="pl-2">Add Schedule</span>
                          </Dropdown.Item>
                          <Dropdown.Item className="text-sm" onClick={handleExport} disabled={gateSchedulerListData?.length > 0 ? 0 : 1}>
                            <span className="pl-2"> Export</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </header>
            </>
            <>
              {gateSchedulerListData && (
                <>
                  <div className="citation-list-table">{dataTableHandle(gateSchedulerListData)}</div>
                </>
              )}
            </>
          </Card.Body>
        </Card>
        <>
          {showAlert && <CustomContentAlert delay={2000} message={successMsg} className="toast-success" />}
          {showErrAlert && <CustomContentAlert delay={2000} message={errorMsg} className="toast-error" />}
        </>
      </Container>
    </div>
  );
}

export default GateScheduler;

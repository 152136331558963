import React from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import { Button, Col, Row, Spinner, Card, Form } from "react-bootstrap";
import * as UserProfileActions from "../../Actions/UserProfileActions/actions";
import CustomContentAlert, { routing_using_history, PasswordValidation } from "../helper";
import apiType from "../../Actions/APIResponseActions/type";
import { useParams } from "react-router-dom";
import { Translation } from "react-i18next";

const ChangeProfile = () => {
  const { id } = useParams();
  const mainBody = useSelector((state) => state.Logindetails.backgroundColor);
  const high_security = useSelector((state) => state.UserProfileDetails?.property?.high_security);
  const property_id = useSelector((state) => state.UserProfileDetails?.property?.id);
  const high_security_auth = useSelector((state) => state.Logindetails?.high_security);
  const login_id = useSelector((state) => state.UserProfileDetails?.login_id);
  const user_offline = useSelector((state) => state.UserProfileDetails?.user_offline);

  const user_id = useSelector((state) => state.UserProfileDetails?.id);
  const permission_id = useSelector((state) => state.UserProfileDetails?.permission_id);

  const login = useSelector((state) => state);
  const errorMessage = useSelector((state) => state.APIResponseDetails.api_error_message);

  const successMessage = useSelector((state) => state.APIResponseDetails.api_success_message);
  const [oldPassword, setOldPassword] = React.useState(undefined);
  const [oldPasswordvisible, setOldPasswordvisible] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState(undefined);
  const [newPasswordvisible, setNewPasswordvisible] = React.useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = React.useState("");
  const [passwordMatchErrorMsg, setPasswordMatchErrorMsg] = React.useState("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState(undefined);
  const [confirmNewPasswordvisible, setConfirmNewPasswordvisible] = React.useState(false);
  const [newPasswordLoader, setNewPasswordLoader] = React.useState("");
  const dispatch = useDispatch();

  const HandleChangePassword = React.useCallback(
    (e) => {
      setOldPassword(e.target.value);
      if (e.target.value === newPassword) {
        setPasswordMatchErrorMsg(<Translation>{(t) => <>{t("Old and new password can not be same!")}</>}</Translation>);
      } else {
        setPasswordMatchErrorMsg("");
      }
    },
    [newPassword],
  );

  const HandleChangeNewPassword = React.useCallback(
    (e) => {
      const errMsg = PasswordValidation(e.target.value);
      if (high_security || high_security_auth) {
        setPasswordErrorMsg(errMsg);
      }

      setNewPassword(e.target.value);
      if (e.target.value === oldPassword) {
        setPasswordMatchErrorMsg(<Translation>{(t) => <>{t("Old and new password can not be same!")}</>}</Translation>);
      } else {
        setPasswordMatchErrorMsg("");
      }
    },
    [oldPassword],
  );

  const HandleChangeConfirmNewPassword = React.useCallback((e) => {
    setConfirmNewPassword(e.target.value);
  }, []);

  const ToggleOldPasswordVisibility = React.useCallback(() => {
    setOldPasswordvisible(!oldPasswordvisible);
  }, [oldPasswordvisible]);
  const ToggleNewPasswordVisibility = React.useCallback(() => {
    setNewPasswordvisible(!newPasswordvisible);
  }, [newPasswordvisible]);
  const ToggleConfirmNewPasswordVisibility = React.useCallback(() => {
    setConfirmNewPasswordvisible(!confirmNewPasswordvisible);
  }, [confirmNewPasswordvisible]);

  const SubmitEvent = React.useCallback(async () => {
    if (oldPassword && newPassword && newPassword === confirmNewPassword) {
      setNewPasswordLoader(true);
      if (!login) {
        dispatch(
          UserProfileActions.resetPassword({
            old_password: oldPassword,
            password: newPassword,
            id: id,
          }),
        );
      } else {
        dispatch(
          UserProfileActions.resetPassword({
            old_password: oldPassword,
            password: newPassword,
            id: id,
          }),
        );
        setNewPasswordLoader(false);
      }
      setNewPasswordLoader(false);
    }
  }, [dispatch, oldPassword, newPassword, confirmNewPassword]);

  const disableSubmit = React.useMemo(() => {
    if (!(oldPassword && newPassword && confirmNewPassword && !passwordErrorMsg && !passwordMatchErrorMsg)) {
      return true;
    }
    return newPassword !== confirmNewPassword;
  }, [oldPassword, newPassword, confirmNewPassword, passwordErrorMsg, passwordMatchErrorMsg]);

  React.useEffect(() => {
    if (successMessage || errorMessage) {
      setTimeout(() => {
        dispatch({
          type: apiType.API_RESPONSE_RESET,
          payload: null,
        });
      }, 3000);
    }
    if (successMessage?.toLowerCase().includes("password")) {
      batch(() => {
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setNewPasswordLoader(false);
      });
      setTimeout(async () => {
        batch(() => {
          setOldPassword(undefined);
          setNewPassword(undefined);
          setConfirmNewPassword(undefined);
        });
        if (window.location.href.includes("user-profile")) {
          dispatch(
            UserProfileActions.userLogout({
              userid: user_id,
              permission_id: permission_id,
              highSecureProperty: high_security,
              property_id: property_id,
              login_id: login_id,
              user_offline: user_offline,
            }),
          );
          localStorage.setItem("language", "");
        } else {
          routing_using_history("/login");
        }
      }, 2050);
    }
  }, [dispatch, errorMessage, successMessage]);

  return (
    <Card className={window.location.href.includes("user-profile") ? "" : "change-profile-card position-absolute"}>
      <Card.Header style={{ backgroundColor: mainBody }}>
        <Card.Title as="h4">
          <Translation>{(t) => <>{t("Change Password")}</>}</Translation>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg="12">
            <Form.Group>
              <label>
                <Translation>{(t) => <>{t("Old Password")}</>}</Translation>
              </label>
              <Translation>
                {(t) => (
                  <div className="input-mask-btn">
                    <Form.Control
                      placeholder={t("Old Password")}
                      name="oldPassword"
                      value={oldPassword}
                      type={oldPasswordvisible ? "text" : "password"}
                      onChange={HandleChangePassword}
                      autoComplete="new-password"
                    />
                    <button className="mask-password" type="button" onClick={ToggleOldPasswordVisibility}>
                      <i className={`fas ${oldPasswordvisible ? "fa-eye" : "fa-eye-slash"}`} />
                    </button>
                  </div>
                )}
              </Translation>

              {oldPassword === "" && newPassword !== "" && (
                <div className="help-block">
                  <Translation>{(t) => <>{t("Old password is required")}</>}</Translation>
                </div>
              )}
            </Form.Group>
            <Form.Group>
              <label>
                <Translation>{(t) => <>{t("New Password")}</>}</Translation>
              </label>
              <Translation>
                {(t) => (
                  <div className="input-mask-btn">
                    <Form.Control
                      type={newPasswordvisible ? "text" : "password"}
                      placeholder={t("New Password")}
                      name="password"
                      value={newPassword}
                      onChange={HandleChangeNewPassword}
                      autoComplete="off"
                    />
                    <button className="mask-password" type="button" onClick={ToggleNewPasswordVisibility}>
                      <i className={`fas ${newPasswordvisible ? "fa-eye" : "fa-eye-slash"}`} />
                    </button>
                  </div>
                )}
              </Translation>

              {newPassword === "" && confirmNewPassword !== "" && (
                <div className="help-block">
                  <Translation>{(t) => <>{t("New password is required")}</>}</Translation>
                </div>
              )}
              {passwordErrorMsg && <div className="help-block" dangerouslySetInnerHTML={{ __html: passwordErrorMsg }}></div>}
              {passwordMatchErrorMsg && <div className="help-block">{passwordMatchErrorMsg}</div>}
            </Form.Group>

            <Form.Group className="mb-0">
              <label>
                <Translation>{(t) => <>{t("Confirm New Password")}</>}</Translation>
              </label>
              <Translation>
                {(t) => (
                  <div className="input-mask-btn">
                    <Form.Control
                      type={confirmNewPasswordvisible ? "text" : "password"}
                      placeholder={t("Confirm New Password")}
                      name="confirmPassword"
                      value={confirmNewPassword}
                      onChange={HandleChangeConfirmNewPassword}
                      autoComplete="off"
                    />
                    <button className="mask-password" type="button" onClick={ToggleConfirmNewPasswordVisibility}>
                      <i className={`fas ${confirmNewPasswordvisible ? "fa-eye" : "fa-eye-slash"}`} />
                    </button>
                  </div>
                )}
              </Translation>

              {confirmNewPassword === "" && oldPassword !== newPassword && (
                <div className="help-block">
                  <Translation>{(t) => <>{t("Confirm password is required")}</>}</Translation>
                </div>
              )}
            </Form.Group>
            {confirmNewPassword !== undefined && confirmNewPassword !== "" && confirmNewPassword !== newPassword ? (
              <div className="help-block">
                {" "}
                <Translation>{(t) => <>{t("Password did not match")}</>}</Translation>
              </div>
            ) : null}
          </Col>
        </Row>
        <Row className={"row mt-4 float-right"}>
          <Col lg="3">
            {!newPasswordLoader ? (
              <Button
                type="button"
                // className="w-100 mb-1"
                className="btn-fill"
                variant="info"
                onClick={SubmitEvent}
                disabled={disableSubmit}
              >
                <Translation>{(t) => <>{t("Submit")}</>}</Translation>
              </Button>
            ) : (
              <Spinner animation="border" variant="info" />
            )}
          </Col>
        </Row>
        <Row>
          <Col lg="9">
            {(errorMessage || successMessage) && (
              <Translation>
                {(t) => (
                  <CustomContentAlert delay={4000} message={t(successMessage || errorMessage)} className={successMessage ? "toast-success" : "toast-error"} />
                )}
              </Translation>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default ChangeProfile;

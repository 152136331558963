import React from "react";
import { Form } from "react-bootstrap";

const GateSchedulerActiveButton = ({ row, handleActive }) => {
  return (
    <div>
      <Form>
        <Form.Check
          type="switch"
          style={{ zIndex: "unset" }}
          id={`custom-switch${row?.group_id}`}
          checked={row?.active}
          onChange={(e) => handleActive(e, row)}
        />
      </Form>
    </div>
  );
};

export default GateSchedulerActiveButton;

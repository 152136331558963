import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const TitleSectionInSetting = (props) => {
  const { formData, handleChange, errorState } = props;
  return (
    <>
      <Row>
        <Col md="4">
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <span className="asterisk">*</span>
            <Form.Control
              placeholder="Domain Permit Registration"
              type="text"
              name="quick_permit_form_title"
              value={formData.quick_permit_form_title}
              onChange={handleChange}
              autoComplete="off"
            />
            {errorState && errorState.quick_permit_form_title.length > 0 && (
              <div className="help-block">
                {errorState.quick_permit_form_title}
              </div>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg="4">
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label> Instructions</Form.Label>
            <Form.Control
              as="textarea"
              value={formData.instruction}
              name="instruction"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Form.Group>
            <Form.Label>Permit created message</Form.Label>
            <span className="asterisk">*</span>
            <Form.Control
              placeholder="Permit created message"
              type="text"
              name="parking_message"
              value={formData.parking_message}
              onChange={handleChange}
              autoComplete="off"
            />
            {errorState && errorState.parking_message.length > 0 && (
              <div className="help-block">{errorState.parking_message}</div>
            )}
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default TitleSectionInSetting;

import React, { Component } from "react";
import { Card, Row, Col, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import DataTable from "react-data-table-component";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { connect } from "react-redux";
import { SuperAdminAction, TicketActions } from "../../Actions";
import Loader from "../Loader/Loader";
import { tableHeightScrollBars, findBySearch, utcToSpecificTimeZone, scroolToTop } from "../helper";
import isEqual from "lodash/isEqual";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropertDetails from "../LandingPage/PropertySelection";
import { saveAs } from "file-saver";
import moment from "moment";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";

class Transaction extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.state = {
      PropertyId: this.props.PropertyId,
      permitTransactionsList: [],
      loader: false,
      searchValue: "",
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      ticketDetails: {},
      selectedPropertyObj: {},
      all_properties: [],
      childcheckbox: true,
      ticketReportLots: [],
      lot_id: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentSummaryDate: "",
      finalTableHeight: 0,
      daysDifference: 30,
      location_type: this.props?.location_type || "",
      selectedProperty: "",
      filterStatus: "",
      totalAmount: 0,
      startDateTime: "",
      endDateTime: "",
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    this.columns = [
      {
        name: "Transaction Time",
        width: "160px",
        sortable: true,
        cell: (row) => this.showTransactionTime(row),
        selector: (row) => row.transaction_time,
      },
      {
        name: "Transaction Id",
        width: "130px",
        sortable: true,
        cell: (row) => this.showTransactionIdField(row),
        selector: (row) => row?.transaction_id,
      },
      {
        name: "Payment Status",
        sortable: true,
        width: "140px",
        cell: (row) => this.getSuccessFailedData(row),
        selector: (row) => row.payment_status,
      },
      {
        name: "LPN",
        width: "120px",
        sortable: true,
        cell: (row) => this.showLPNField(row),
        selector: (row) => row?.lpn,
      },
      {
        name: "Card Number",
        width: "120px",
        sortable: true,
        cell: (row) => this.showCardNumberField(row),
        selector: (row) => row?.card,
      },
      {
        name: "Amount",
        sortable: true,
        width: "100px",
        cell: (row) => this.showAmountField(row),
        selector: (row) => row.amount,
      },
      {
        name: "Domain",
        sortable: true,
        width: "130px",
        cell: (row) => this.showDomainField(row),
        selector: (row) => row?.properties?.domain,
      },
      {
        name: "Type",
        width: "120px",
        sortable: true,
        cell: (row) => this.showTypeField(row),
        selector: (row) => row?.type,
      },
      {
        name: "Status",
        width: "120px",
        sortable: true,
        cell: (row) => this.getStatusData(row),
        selector: (row) => row?.status,
      },
      {
        name: "Message Response",
        sortable: true,
        width: "160px",
        cell: (row) => this.showMessageResponseField(row),
        selector: (row) => row.payment_status,
      },
    ];
  }

  expandedComponent = ({ data }) => {
    return (
      <div className="extend-row">
        <Row className="expandedView mb-1">
          <Col className="min-w-150px">
            <Row>
              <Col xs="4">
                <b>Card Expiry</b>
              </Col>
              <Col xs="8">{data.card_expiry ? data.card_expiry : data.card_details?.expiry_date || "-"}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b>Permit Id</b>
              </Col>
              <Col xs="8">
                <span>{data.permit_id || "-"}</span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4">
                <b>Processor Id</b>
              </Col>
              <Col xs="8">
                <span>{data.properties.processor_id || "-"}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="expandedView mb-1">
          <Col>
            <Row>
              <Col xs="4">
                <b>Card Type</b>
              </Col>
              <Col xs="8">{data.card_type || "-"}</Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4"></Col>
              <Col xs="8"></Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="4"></Col>
              <Col xs="8"></Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  showMessageResponseField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.payment_status}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2" style={{ maxWidth: "140px" }}>
        {row?.payment_status}
      </div>
    </OverlayTrigger>
  );

  showTypeField = (row) => (
    <>
      {row?.type ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.type}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.type}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showDomainField = (row) => (
    <>
      {row?.properties?.domain ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.properties?.domain}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.properties?.domain}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showAmountField = (row) => {
    if (row.amount) {
      const formattedAmount = parseFloat(row.amount).toFixed(2);
      return (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{row.status === "Refunded" ? `-${formattedAmount}` : formattedAmount}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{row.status === "Refunded" ? `-${formattedAmount}` : formattedAmount}</div>
        </OverlayTrigger>
      );
    } else {
      return "-";
    }
  };

  showCardNumberField = (row) => (
    <>
      {row?.card ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.card}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.card}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showLPN = (row) => {
    return (
      <div className="curserPointer text-truncate" onClick={() => this.handleRedirect(row.lpn)}>
        {row?.lpn ? (
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.lpn}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{row?.lpn}</div>
          </OverlayTrigger>
        ) : (
          "-"
        )}
      </div>
    );
  };

  showLPNField = (row) => this.showLPN(row);

  showTransactionIdField = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.transaction_id}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2">{row?.transaction_id}</div>
    </OverlayTrigger>
  );

  showTransactionTime = (row) => (
    <>
      {row.transaction_time ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(this.props.timezone, row.transaction_time, true)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(this.props.timezone, row.transaction_time, true)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  handleRedirect = (lpn) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/current-occupancy`,
      search: `audit-report&${lpn}`,
    });
  };

  changeProperty(event) {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState(
      {
        selectedProperty: event.value,
        selectedPropertyObj: event,
        startDate: new Date(datebeforeThirtyDays),
        endDate: new Date(),
        currentSummaryDate: null,
      },
      () => {
        const selectedPropertyObj = this.state.all_properties.length && this.state.all_properties.find((item) => item.id === this.state.selectedProperty);
        this.props.selected_Property(selectedPropertyObj);
        this.props.get_all_lots(event.value);
        const current_lot = this.props.lots;
        this.setState({
          ticketReportLots: current_lot,
        });
      },
    );
  }

  handleCheckBox = () => {
    this.setState({ childcheckbox: !this.state.childcheckbox });
  };

  getSuccessFailedData = (row) => {
    const paymentStatus = row?.payment_status;
    const validPaymentStatuses = ["Approved", "SUCCESS", "AP", "Approval", "APPROVED           *"];

    if (!validPaymentStatuses.includes(paymentStatus)) {
      return <div style={{ color: "red" }}>Failed</div>;
    } else if (validPaymentStatuses.includes(paymentStatus)) {
      return <div style={{ color: "green" }}>Approved</div>;
    }
  };

  getStatusData = (row) => {
    const paymentStatus = row?.payment_status;
    const validPaymentStatuses = ["Approved", "SUCCESS", "AP", "Approval", "APPROVED           *"];

    if (!validPaymentStatuses.includes(paymentStatus)) {
      return <div>Failed</div>;
    } else if (validPaymentStatuses.includes(paymentStatus)) {
      return (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.status}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.status}</div>
        </OverlayTrigger>
      );
    }
  };

  componentDidMount = async () => {
    await this.getTickets();
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    await this.props.get_all_lots(PropertyId);
    const current_lot = this.props.lots;
    this.setState(
      {
        all_properties: this.props?.getAllProperties,
        ticketReportLots: current_lot,
      },
      async () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );

    const height = tableHeightScrollBars();
    this.setState({
      finalTableHeight: height,
    });
  };

  getTickets = async () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, childcheckbox, timezone, startDate, endDate, filterStatus } = this.state;
      const pageobj = {
        PropertyId,
        timezone,
        childcheckbox,
        location_type: this.props.location_type,
        data_show_type: this.props.data_show_type,
        filterStatus,
      };
      if (startDate) {
        pageobj.startDate = startDate;
      }
      if (endDate) {
        pageobj.endDate = endDate;
      }
      await this.props.getReportList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState({
        permitTransactionsList:
          this.props?.permitTransactionsList?.rows && this.props.permitTransactionsList.rows.length
            ? JSON.parse(JSON.stringify(this.props.permitTransactionsList.rows))
            : [],
        count: this.props.permitTransactionsList.count,
      });
      this.setState({ loader: false });
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.permitTransactionsList, this.props.permitTransactionsList)) {
      this.setState({
        permitTransactionsList: JSON.parse(JSON.stringify(this.props.permitTransactionsList.rows)),
        count: this.props.permitTransactionsList.count,
      });
    }
    if (!isEqual(prevProps.lots, this.props.lots)) {
      this.setState({
        ticketReportLots: this.props.lots,
      });
    }
  };

  dataTableHandle = (permitTransactionsList) => {
    return (
      <DataTable
        data={this.state.searchValue.length ? this.state.searchedtransactionsList : permitTransactionsList}
        columns={this.columns}
        expandableRows
        expandableRowsComponent={this.expandedComponent}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleChange = async (e) => {
    this.setState({
      selectedOption: e.target.value,
    });
    await this.getTickets();
  };

  handleChangeLot = ({ target }) => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState({
      [target.name]: target.value,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentSummaryDate: null,
    });
  };

  handleCurrentDate = (date) => {
    this.setState({
      currentSummaryDate: date,
      startDate: null,
      endDate: null,
    });
  };

  handleChangeStartingDates = (date) => {
    const dt = new Date(date);
    const getEndDate = new Date(dt.setMonth(dt.getMonth() + 3));
    let selectEndDate;
    if (getEndDate > new Date()) {
      selectEndDate = new Date();
    } else {
      selectEndDate = getEndDate;
    }
    this.setState({
      startDate: date,
      endDate: selectEndDate,
      currentSummaryDate: null,
    });
  };

  handleChangeEndDates = (date) => {
    this.setState({
      endDate: date,
      currentSummaryDate: null,
    });
  };

  handleSearch = () => {
    this.setState({ loader: true }, async () => {
      const { lot_id, childcheckbox, currentSummaryDate, startDate, endDate, selectedPropertyObj, PropertyId, location_type, timezone, filterStatus } =
        this.state;
      const pageobj = {
        childcheckbox,
        PropertyId,
        timezone,
        location_type,
        data_show_type: this.props.data_show_type,
      };
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }
      if (lot_id) {
        pageobj.lot_id = lot_id;
      }
      if (currentSummaryDate) {
        pageobj.currentSummaryDate = currentSummaryDate;
      }
      if (startDate && endDate) {
        pageobj.startDate = startDate;
        pageobj.endDate = endDate;
      }
      pageobj.filterStatus = filterStatus;
      await this.props.getReportList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      this.setState({
        permitTransactionsList: JSON.parse(JSON.stringify(this.props.permitTransactionsList.rows)),
        count: this.props.permitTransactionsList.count,
      });
      this.setState({ loader: false });
      document.body.classList.remove("foc-open");
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ searchValue: val }, async () => {
      const columnList = ["transaction_time", "transaction_id", "permit_id", "lpn", "card", "amount", "status", "type"];
      if (this.state.searchValue.length) {
        this.setState(
          {
            searchedtransactionsList: findBySearch(
              this.state.searchValue,
              this.state.permitTransactionsList,
              this.state.permitTransactionsList,
              columnList,
              this.props.timezone,
            ),
          },
          () => {
            this.setState({
              count: this.state.searchedtransactionsList.length,
            });
          },
        );
      } else {
        this.setState({
          count: this.props.permitTransactionsList.count,
          searchedtransactionsList: [],
          permitTransactionsList: this.props.permitTransactionsList.rows,
        });
      }
    });
  };

  resetAll = async () => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - this.state.daysDifference);
    this.setState({
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentSummaryDate: null,
      selectedPropertyObj: {
        label: this.state.selectedDomainName,
        value: this.state.PropertyId,
      },
      lot_id: "",
      childcheckbox: true,
      location_type: "",
      filterStatus: "",
    });
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj);
    this.setState({
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    });
  };

  handleExport = async () => {
    const {
      lot_id,
      childcheckbox,
      currentSummaryDate,
      startDate,
      endDate,
      location_type,
      selectedPropertyObj,
      PropertyId,
      selectedDomainName,
      timezone,
      filterStatus,
    } = this.state;
    const pageobj = {
      childcheckbox,
      PropertyId,
      timezone,
      exportData: true,
      location_type,
      data_show_type: this.props.data_show_type,
      filterStatus,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (lot_id) {
      pageobj.lot_id = lot_id;
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    const bufferData = await this.props.getReportList(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `PERMIT-${this.props.data_show_type}-TRANSACTIONS-${currentTimeDate}-${selectedDomainName}.xls`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  handleChangeType = ({ target }) => {
    this.setState({
      location_type: target.value,
    });
  };

  handleStatusFilter = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  render() {
    const {
      permitTransactionsList,
      loader,
      childcheckbox,
      ticketReportLots,
      lot_id,
      startDate,
      endDate,
      currentSummaryDate,
      searchValue,
      location_type,
      filterStatus,
    } = this.state;

    return (
      <div>
        <Card className="mb-0">
          <Card.Body className="pos-rel-overflow-hide">
            <>
              <div className="sidebarFilter">
                <Card className="contact-container mb-0">
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title as="h4">{this.props.transaction_title} Report</Card.Title>
                      <a
                        onClick={this.hideSlider}
                        href="javascript:void(0);"
                        className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <label className="fancy-checkbox">
                            <input
                              type="checkbox"
                              name={"childcheckbox"}
                              checked={childcheckbox}
                              onChange={this.handleCheckBox}
                              className="custom-control-input"
                              id="childcheckbox1"
                            ></input>
                            <span>
                              <i></i>Include Children Sites
                            </span>
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>LOTS:</Form.Label>
                          <Form.Control
                            as="select"
                            name="lot_id"
                            value={lot_id}
                            onChange={this.handleChangeLot}
                            disabled={ticketReportLots.length < 1}
                            placeholder="Select Lot"
                          >
                            {ticketReportLots?.length ? (
                              <>
                                <option value="">Any</option>
                                {ticketReportLots.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.alias}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      {this.props.data_show_type !== "Paid" && (
                        <Col md="12">
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Type:</Form.Label>
                            <Form.Control as="select" name="location_type" value={location_type} onChange={this.handleChangeType} placeholder="Select Lot">
                              <>
                                <option value="">Any</option>
                                <option value="Driver">Driver (ParkingPermit)</option>
                                <option value="Unit">Unit (PermitPackage)</option>
                                <option value="PassagePass">PassagePass</option>
                                <option value="Visitor">Visitor</option>
                              </>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      )}
                      {this.props.data_show_type === "Paid" && (
                        <Col md="12">
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Status:</Form.Label>
                            <Form.Control as="select" name="filterStatus" onChange={this.handleStatusFilter} value={filterStatus}>
                              <>
                                <option value="">Any</option>
                                <option value="Paid">Paid</option>
                                <option value="Failed">Failed</option>
                                <option value="Refunded">Refunded</option>
                              </>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      )}
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Specific Date:</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Date"
                            selected={currentSummaryDate}
                            value={currentSummaryDate}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleCurrentDate}
                            dateFormat="MM/dd/yyyy"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Start Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Start Date"
                            selected={startDate}
                            defaultHour={24}
                            value={startDate}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleChangeStartingDates}
                            dateFormat="MM/dd/yyyy"
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">End Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select End Date"
                            selected={endDate}
                            disabled={loader}
                            value={endDate}
                            selectsEnd
                            timeInputLabel="Time:"
                            onChange={this.handleChangeEndDates}
                            // dateFormat="MM/dd/yyyy hh:mm:ss aa"
                            dateFormat="MM/dd/yyyy"
                            // showTimeInput
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-right justify-content-between d-flex">
                    <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                      Reset
                    </Button>
                    <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                      Search
                    </Button>
                  </Card.Footer>
                </Card>
              </div>
              <header>
                <div className="active-permit-header sm-flex-column sm-align-items-start">
                  <h5 className="mb-2 mb-md-0">{this.props.transaction_title}</h5>
                  <div className="filter-button d-flex flex-wrap">
                    <div className="d-flex align-items-center search-mb-0">
                      <DatatableHeader
                        className="mt-0 mb-0"
                        searchValue={searchValue}
                        HandleChange={this.handleSearchChange}
                        // findMorePressed={this.findMorePressed}
                      />
                    </div>
                    <div className="ml-2">
                      <Button
                        variant="info"
                        className="btn-fill btn-sm"
                        onClick={this.handleExport}
                        disabled={permitTransactionsList.length > 0 ? 0 : 1}
                        size="medium"
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </header>
            </>
            {loader ? (
              <Loader />
            ) : (
              <>
                {permitTransactionsList && (
                  <>
                    <div className="citation-list-table has-parent-tab">{this.dataTableHandle(permitTransactionsList)}</div>
                    <DateRangeAndAmount
                      ReportList={this.props.permitTransactionsList.rows}
                      amountName="amount"
                      startDateTime={this.state.startDateTime}
                      endDateTime={this.state.endDateTime}
                    />
                  </>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails, SuperAdminDetails, CurrentPermitDetails, TicketDetails }) => {
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { residentList } = CurrentPermitDetails;
  const { getAllProperties, lots } = SuperAdminDetails;
  const { MainBackground: main_body } = backgroundColor;
  const { permitTransactionsList } = TicketDetails;
  return {
    PropertyId,
    timezone,
    property,
    permitTransactionsList,
    main_body,
    getAllProperties,
    residentList,
    lots,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  getBlobPdf: TicketActions.getBlobPdf,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  get_all_lots: SuperAdminAction.get_all_lots,
  getReportList: TicketActions.getPermitTransactionsList,
};

export default connect(mapStateToProps, mapStateToDispatch)(Transaction);

import t from "../../Actions/PermitActions/types";
const initialState = {
  getPassagePass: {},
  waitList: [],
  exportWaitList: "",
};

const PermitReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.GET_PASSAGEPASS_DATA: {
      return { ...state, getPassagePass: action.payload };
    }
    case t.FETCH_WAIT_LIST: {
      return { ...state, waitList: action.payload };
    }
    case t.FETCH_WAIT_EXPORT_LIST: {
      return { ...state, exportWaitList: action.payload };
    }
    default:
      return state;
  }
};

export default PermitReducer;

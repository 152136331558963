import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import QuickPermitRadioButton from "./QuickPermitRadioButton";

const RegisterOnTerminalSectionInSetting = (props) => {
  const { handleTerminalUpdateChange, formData } = props;
  return (
    <Row>
      <Col md="12" className="mt-4">
        <label>Register on Terminal</label>
        <Form.Group className="ml-0 mt-2 mb-2 mr-3">
          <QuickPermitRadioButton
            label="Not supported"
            name="nope"
            value="nope"
            onChange={handleTerminalUpdateChange}
            checked={formData.register_on_terminal === "nope"}
          />
          <QuickPermitRadioButton
            label="Supported with triple tap"
            name="tap"
            value="tap"
            onChange={handleTerminalUpdateChange}
            checked={formData.register_on_terminal === "tap"}
          />
          <QuickPermitRadioButton
            label="Show Link with QR"
            name="url"
            value="url"
            onChange={handleTerminalUpdateChange}
            checked={formData.register_on_terminal === "url"}
          />
          <QuickPermitRadioButton
            label="Show Link without QR"
            name="no-qr"
            value="no-qr"
            onChange={handleTerminalUpdateChange}
            checked={formData.register_on_terminal === "no-qr"}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default RegisterOnTerminalSectionInSetting;

import React, { Component } from "react";
import { Card, Container, Row, Col, Button, Dropdown, Tooltip, OverlayTrigger, Form, Tabs, Tab, Modal } from "react-bootstrap";
import CustomContentAlert, { findBySearch, tableHeightScrollBars, scroolToTop } from "../helper";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import { CurrentPermitActions, APIResponseActions, SuperAdminAction, PermitActions, PermitOptionsActions } from "../../Actions";
import Loader from "../Loader/Loader";
import DataTable from "react-data-table-component";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import ConfirmModal from "../Modal/ConfirmCurrent-permit";
import moment from "moment";
import EditCurrentPermit from "../Modal/EditCurrentPermit";
import { saveAs } from "file-saver";
import ConfirmationModal from "../Common/ConfirmationModal";
import PropertDetails from "../LandingPage/PropertySelection";
import ModalLoader from "../Loader/ModalLoader";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import SendInvoiceModal from "../Modal/SendInvoiceModal";
import SendRefundModal from "../PassagePass/SendPermitRefundModal";
import { Translation } from "react-i18next";
import _i18n from "../Language/i18n";
import WaitListData from "./WaitList";

let PageObject = {};
class CurrentPermit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _currentPermits: [],
      _currentPermitPackages: [],
      count: 0,
      countPermitPackages: 0,
      loader: false,
      deactivateLoader: false,
      filterdPermits: [],
      filterdPermitPackages: [],
      permitType: "all",
      status: "current",
      showError: true,
      suite_id: "ALL",
      lot_id: "all",
      package_name: "all",
      propertyId: this.props.property_id,
      user_type: this.props.user_type,
      searchValue: "",
      searchPermitPackages: "",
      isModalOpen: false,
      Id: "",
      call_when_here: "",
      show_modal: false,
      confirmDelete: false,
      show_alert: false,
      show_error_alert: true,
      api_success_message: "",
      api_error_message: "",
      arrAfter: [],
      temp_deactivation: true,
      permit_class: "",
      page: 1,
      Norow: 100,
      prevSearch: "",
      prevSearchPermitPackage: "",
      prevPage: "",
      prevNorow: "",
      selectedColumn: "created_at",
      asc: "false",
      sort_direction: "DESC",
      pageChanged: false,
      findMore: false,
      beforeState: "",
      timezone: this.props.timezone,
      property: this.props.property,
      standard: "",
      visitor_current: "",
      visitor_future: "",
      modelOpen: false,
      finalTableHeight: 0,
      exportLink: false,
      listHeading: "Current",
      show_cancel_modal: false,
      selectedPermitId: "",
      activeTab: "viewallpermits",
      selectedPropertyObj: {
        value: this.props?.property_id,
        label: this.props?.property?.property_name,
      },
      childcheckbox: true,
      auto_renew: "all",
      primary_permit: "all",
      cron_auto_renew: "all",
      refundPopupOpen: false,
      getPermits: {},
      selectRowData: {},
      detectedDevice: "",
      unit_language: this.props?.selectedLang || this.props?.language,
    };
    this.customStyles = {
      data: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "0", // override the cell padding for head cells
          paddingRight: "0",
        },
      },
      cells: {
        style: {
          paddingLeft: "0", // override the cell padding for data cells
          paddingRight: "0",
        },
      },
    };

    this.columns = [
      {
        name: <Translation>{(t) => <>{t("Domain")}</>}</Translation>,
        sortable: true,
        width: "120px",
        selector: (row) => row?.domain,
        cell: this.domainRow,
      },
      {
        name: <Translation>{(t) => <>{t("LPN")}</>}</Translation>,
        sortable: true,
        width: "120px",
        selector: (row) => row?.lpn,
        cell: this.lpnRow,
        sortFunction: (a, b) => {
          if (typeof a === "object" && typeof b === "object") {
            if (a.lpn && b.lpn) {
              const compareResult = a?.lpn?.toString()?.toLowerCase()?.localeCompare(b?.lpn?.toString()?.toLowerCase());
              return compareResult;
            } else {
              return -1;
            }
          }
          return a - b;
        },
      },
      {
        name: <Translation>{(t) => <>{t("Begin Time")}</>}</Translation>,
        sortable: true,
        width: "150px",
        selector: (row) => row?.begin_time,
        cell: this.beginTimeRow,
      },
      {
        name: <Translation>{(t) => <>{t("Expiry Time")}</>}</Translation>,
        sortable: true,
        width: "150px",
        selector: (row) => row?.expire_time,
        cell: this.expiryTimeRow,
      },
      {
        name: <Translation>{(t) => <>{t("Unit Id")}</>}</Translation>,
        sortable: true,
        width: "150px",
        cell: this.unitIdRow,
        selector: (row) => row?.suite_id,
        sortFunction: (a, b) => {
          if (typeof a === "object" && typeof b === "object") {
            if (a.suite_id && b.suite_id) {
              const compareResult = a?.suite_id?.toString()?.toLowerCase()?.localeCompare(b?.suite_id?.toString()?.toLowerCase());
              return compareResult;
            } else {
              return -1;
            }
          }
          return a - b;
        },
      },
      {
        name: <Translation>{(t) => <>{t("Permit Class")}</>}</Translation>,
        sortable: true,
        width: "150px",
        cell: this.permitClassRow,
        selector: (row) => row?.permit_class,
        sortFunction: (a, b) => {
          if (typeof a === "object" && typeof b === "object") {
            if (a.permit_class && b.permit_class) {
              const compareResult = a?.permit_class?.toString()?.toLowerCase()?.localeCompare(b?.permit_class?.toString()?.toLowerCase());
              return compareResult;
            } else {
              return -1;
            }
          }
          return a - b;
        },
      },
      {
        name: <Translation>{(t) => <>{t("Package Name")}</>}</Translation>,
        sortable: true,
        width: "140px",
        cell: this.packageNameRow,
        selector: (row) => row?.package_name,
        sortFunction: (a, b) => {
          if (typeof a === "object" && typeof b === "object") {
            if (a.package_name && b.package_name) {
              const compareResult = a?.package_name?.toString()?.toLowerCase()?.localeCompare(b?.package_name?.toString()?.toLowerCase());
              return compareResult;
            } else {
              return -1;
            }
          }
          return a - b;
        },
      },
      {
        name: <Translation>{(t) => <>{t("First Name")}</>}</Translation>,
        sortable: true,
        width: "140px",
        cell: this.firstName,
        selector: (row) => row?.first_name,
        sortFunction: (a, b) => {
          if (typeof a === "object" && typeof b === "object") {
            if (a.first_name && b.first_name) {
              const compareResult = a?.first_name?.toString()?.toLowerCase()?.localeCompare(b?.first_name?.toString()?.toLowerCase());
              return compareResult;
            } else {
              return -1;
            }
          }
          return a - b;
        },
      },
      {
        name: <Translation>{(t) => <>{t("Last Name")}</>}</Translation>,
        sortable: true,
        width: "140px",
        cell: this.lastNameRow,
        selector: (row) => row.last_name,
        sortFunction: (a, b) => {
          if (typeof a === "object" && typeof b === "object") {
            if (a.last_name && b.last_name) {
              const compareResult = a?.last_name?.toString()?.toLowerCase()?.localeCompare(b?.last_name?.toString()?.toLowerCase());
              return compareResult;
            } else {
              return -1;
            }
          }
          return a - b;
        },
      },
      {
        name: <Translation>{(t) => <>{t("Phone")}</>}</Translation>,
        sortable: true,
        width: "120px",
        cell: this.phoneRow,
        selector: (row) => row?.phone,
      },
      {
        name: <Translation>{(t) => <>{t("Status")}</>}</Translation>,
        sortable: true,
        width: "80px",
        cell: this.statusRow,
        selector: (row) => row?.phone,
      },
      {
        name: <Translation>{(t) => <>{t("Edit Count")}</>}</Translation>,
        sortable: true,
        width: "100px",
        cell: this.editCountRow,
        selector: (row) => row?.edit_count,
      },
      {
        name: <Translation>{(t) => <>{t("Paid Status")}</>}</Translation>,
        sortable: true,
        width: "100px",
        cell: this.paidStatusRow,
        selector: (row) => row?.paid_status,
      },
      {
        name: "Action",
        width: "100px",
        cell: this.actionButton,
      },
    ];

    if (this.props.permission_id === 2 || this.state.activeTab === "viewallpermits") {
      this.columns.splice(9, 0, {
        name: "CWH",
        sortable: true,
        center: true,
        cell: this.cwhRow,
        selector: (row) => row.call_when_here,
      });
      this.columns.splice(10, 0, {
        name: <Translation>{(t) => <>{t("Deactivate")}</>}</Translation>,
        sortable: true,
        cell: this.deactiveButton,
        column_identification: "Deactivate",
      });
    }

    this.columns.splice(10, 0, {
      name: <Translation>{(t) => <>{t("Cancel")}</>}</Translation>,
      sortable: true,
      center: true,
      cell: this.cancelButton,
      selector: (row) => row?.auto_renew,
      column_identification: "Cancel",
    });

    this.columns.splice(11, 0, {
      name: <Translation>{(t) => <>{t("View Receipt")}</>}</Translation>,
      sortable: true,
      center: true,
      cell: this.viewReceiptButton,
      selector: (row) => row.primary_permit && row.paid_permit_type !== "invoice",
      column_identification: "View Receipt",
    });

    this.columns.splice(11, 0, {
      name: <Translation>{(t) => <>{t("Auto Renew")}</>}</Translation>,
      sortable: true,
      center: true,
      cell: this.autoRenewButton,
      selector: (row) => row?.auto_renew,
      column_identification: "Auto Renew",
    });

    this.columns.splice(12, 0, {
      name: <Translation>{(t) => <>{t("Auto Cancel")}</>}</Translation>,
      sortable: true,
      center: true,
      cell: this.autoCancelButton,
      selector: (row) => row?.auto_cancel,
      column_identification: "Auto Cancel",
    });
  }

  deactiveButton = (row) => {
    if (this.state.user_type === "admin" || this.state.user_type === "superadmin") {
      if (row.active) {
        return (
          <Button
            className="btn-fill text-nowrap"
            variant="danger"
            size="xs"
            disabled={row.status.toLowerCase() === "inactive" || !row.active}
            onClick={() => this.handleDeActivatePermit(row.id)}
          >
            <Translation>{(t) => <>{t("Deactivate")}</>}</Translation>
          </Button>
        );
      } else if (row.deactivated === 1) {
        return "-";
      }
    } else {
      return "-";
    }
  };
  // Permit Packages row

  checkLpn = (lpn) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/current-occupancy`,
      search: `audit-report&${lpn}`,
    });
  };

  checkUnit = (suite_id) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/units`,
      search: "search",
      state: {
        search: suite_id,
      },
    });
  };

  domainRow = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.domain || ""}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2">{row?.domain || ""}</div>
    </OverlayTrigger>
  );

  renderTooltip = (lpn, lpnOptionsLength, primary_permit) => {
    let text = "-";
    if (lpn) {
      if (lpnOptionsLength && primary_permit) {
        text = `${lpn}+(${lpnOptionsLength})`;
      } else {
        text = lpn;
      }
    }
    return <Tooltip id="tooltip-qw">{text}</Tooltip>;
  };

  renderLpnSpan = (lpn, lpnOptionsLength, isPrimaryPermit, maxWidth) => {
    let text = "-";

    if (lpn) {
      if (isPrimaryPermit && maxWidth) {
        text = `${lpn}+(${lpnOptionsLength})`;
      } else {
        text = lpn;
      }
    }
    const style = !isPrimaryPermit && maxWidth ? { color: "#359ed8", maxWidth } : {};

    return (
      <span className="text-truncate text-nowrap" style={style}>
        {text}
      </span>
    );
  };

  lpnRow = (row) => {
    const { groupid, permit_class, lpn, primary_permit, lpn_options } = row;
    const isPermitPackage = groupid && permit_class === "PermitPackage";
    const lpnOptionsLength = lpn_options?.length || 0;

    return (
      <div className={`curserPointer ${isPermitPackage ? "lpnIcon" : ""} text-truncate text-nowrap pr-2`} onClick={() => this.checkLpn(lpn)}>
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={this.renderTooltip(lpn, lpnOptionsLength, primary_permit)}>
          {this.renderLpnSpan(lpn, lpnOptionsLength, primary_permit, isPermitPackage ? "85px" : null)}
        </OverlayTrigger>
      </div>
    );
  };

  beginTimeRow = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.begin_time ? row.begin_time : "-"}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2">{row?.begin_time ? row.begin_time : "-"}</div>
    </OverlayTrigger>
  );

  expiryTimeRow = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.expire_time ? row.expire_time : "-"}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2">{row.expire_time ? row.expire_time : "-"}</div>
    </OverlayTrigger>
  );

  unitIdRow = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.suite_id ? row?.suite_id : "-"}</Tooltip>}>
      <div className="curserPointer text-nowrap text-truncate pr-2" onClick={() => this.checkUnit(row.suite_id)}>
        {row?.suite_id ? row?.suite_id : "-"}
      </div>
    </OverlayTrigger>
  );
  permitClassRow = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{row?.permit_class ? row?.permit_class : "-"}</Tooltip>}
    >
      <div className="text-nowrap text-truncate pr-2">{row?.permit_class ? row?.permit_class : "-"}</div>
    </OverlayTrigger>
  );

  packageNameRow = (row) => {
    if (this.state.user_type === "admin" || this.state.user_type === "superadmin") {
      return (
        <>
          {row?.package_name ? (
            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.package_name}</Tooltip>}>
              <a className="curserPointer text-nowrap text-truncate pr-2" href="/managepermit-packages">
                {row?.package_name}
              </a>
            </OverlayTrigger>
          ) : (
            "-"
          )}
        </>
      );
    } else if (row?.package_name) {
      return (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.package_name}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.package_name}</div>
        </OverlayTrigger>
      );
    } else {
      return "-";
    }
  };

  firstName = (row) => (
    <>
      {" "}
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.first_name || "-"}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.first_name || "-"}</div>
      </OverlayTrigger>
    </>
  );

  lastNameRow = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.last_name || "-"}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2">{row?.last_name || "-"}</div>
    </OverlayTrigger>
  );

  phoneRow = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{row?.phone ? (row?.country_code || "") + row?.phone?.substring(0, 10) : "-"}</Tooltip>}
    >
      <div className="text-nowrap text-truncate pr-2">{row?.phone ? (row?.country_code || "") + row?.phone?.substring(0, 10) : "-"}</div>
    </OverlayTrigger>
  );

  editCountRow = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.edit_count}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2">{row.edit_count}</div>
    </OverlayTrigger>
  );

  paidStatusRow = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.paid_status}</Tooltip>}>
      <div className="text-nowrap text-truncate pr-2">{row.paid_status}</div>
    </OverlayTrigger>
  );

  statusRow = (row) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.status ? row?.status : "-"}</Tooltip>}>
      <div className={row.status.toLowerCase() === "active" ? "text-nowrap text-truncate text-green" : "text-nowrap text-truncate text-danger"}>
        {row?.status ? row?.status : "-"}
      </div>
    </OverlayTrigger>
  );

  cwhRow = (row) => {
    return (
      <div>
        <input
          type="checkbox"
          checked={row.call_when_here}
          onChange={() => {
            this.handleCall_when_here_activate_deactive(row);
          }}
          disabled={(this.props.user_type !== "superadmin" && this.props.user_type !== "admin") || row.status === "Inactive"}
        />
      </div>
    );
  };

  cancelButton = (row) => {
    if (row.active && row.primary_permit && row.permit_type === "monthly_permits" && row.allow_cancellation) {
      return (
        <Button className="btn-fill text-nowrap" variant="danger" size="xs" onClick={() => this.handleShowCancelPermitModal(row)}>
          <Translation>{(t) => <>{t("Cancel")}</>}</Translation>
        </Button>
      );
    } else {
      return "-";
    }
  };

  viewReceiptButton = (row) => {
    row.language = this.state.unit_language;
    if ((row.primary_permit && row.paid_permit_type !== "invoice") || (row.permit_class.toLowerCase() === "visitor" && +row.paid_amount > 0)) {
      return (
        <Button className="btn-fill text-nowrap" variant="primary" size="xs" onClick={() => this.handleShowReceiptDetails(row)}>
          <Translation>{(t) => <>{t("View Receipt")}</>}</Translation>
        </Button>
      );
    } else {
      return "-";
    }
  };

  autoRenewButton = (row) => {
    if (row.active && row.primary_permit && row.allow_cancellation) {
      return (
        <div>
          <input
            type="checkbox"
            checked={row?.auto_renew}
            onChange={() => {
              this.handleAutoRenewActivateDeactivate(row);
            }}
            disabled={row.permit_type === "usage_permits"}
          />
        </div>
      );
    } else {
      return "-";
    }
  };

  autoCancelButton = (row) => {
    if (row.active && row.primary_permit && !row.groupid && row.permit_type === "monthly_permits" && row.suite_id === "ParkingPermit") {
      return (
        <div>
          <input
            type="checkbox"
            checked={row?.auto_cancel}
            onChange={() => {
              this.handleAutoCancelActivateDeactivate(row);
            }}
          />
        </div>
      );
    } else {
      return "-";
    }
  };

  renderRow = (label, value, tooltip = false) => (
    <Col md={4} lg={4} xs={4}>
      <Row>
        <Col xs="4">
          <b>
            <Translation>{(t) => <>{t(label)}</>}</Translation>
          </b>
        </Col>
        <Col xs="8">
          {tooltip ? (
            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-${label}`}>{value}</Tooltip>}>
              <span>{value}</span>
            </OverlayTrigger>
          ) : (
            <span>{value}</span>
          )}
        </Col>
      </Row>
    </Col>
  );

  expandedComponent = ({ data }) => {
    return (
      <div className="extend-row">
        <Row className="expandedView mb-1">
          {this.renderRow("Email", data.email, true)}
          {this.renderRow("Invited By", data.resident_name ? data.resident_name : "-", true)}
          {this.renderRow("Fee", data.fee ? data.fee : "-")}
          {this.renderRow("Create Time", data.permit_created_at ? data.permit_created_at : "-")}
          {this.renderRow("Invited Suite", data.suite_id ? data.suite_id : "-")}
          {this.renderRow("Discount", data.discount_amount ? data.discount_amount : "-")}
          {this.renderRow("Permit Tag", data.permit_tag ? data.permit_tag : "-")}
          {this.renderRow("Lot Name", data.lot_name ? data.lot_name : "-", true)}
          {this.renderRow("Paid Amount", data.paid_amount && data.paid_amount > 0 ? data.paid_amount : "-")}
          {this.handleSubViewExpansion(data)}
        </Row>
      </div>
    );
  };

  handleSubViewExpansion = (data) => {
    return (
      <>
        {this.renderRow("Stall Id", data.stall_id ? data.stall_id : "-", true)}
        {this.renderRow("Group Id", data.groupid ? data.groupid : "-", true)}
        {this.renderRow("Paid By", data.payment_type ? data.payment_type : "-", true)}
        {this.renderRow("Employee Reference", data.owner_reference ? data.owner_reference : "-")}
        {this.renderRow("Group Name", data?.permit_share_group?.group_type === "system" ? "SystemGroup" : data?.permit_share_group?.group_name, true)}
        {this.renderRow("Access Card/ Tag", data.card_number ? data.card_number : "-")}
        {this.renderGroupLpnInfo(data)}
        {this.renderRow("Refunded Amount", data.primary_permit ? data.refunded_amount : "-")}
        {data.permit_class === "PermitPackage" && this.renderRow("Deactivated At", moment(data?.deactivated_at).isValid() ? data?.deactivated_at : "-")}
        {data.permit_class.toLowerCase() === "visitor" && this.renderRow("Cost", data.cost ? data.cost : "-")}
        {this.renderMakeModalAndColor(data)}
        {this.renderUsageCount(data)}
      </>
    );
  };

  renderUsageCount = (data) => {
    if (data.permit_class === "PermitPackage" && data.permit_type === "usage_permits") {
      return (
        <>
          {this.renderRow("Uses", data.usage_count || "-")}
          {this.renderRow("Used", data.used_count || "-")}
        </>
      );
    }
  };

  renderMakeModalAndColor = (data) => {
    if (data.is_extended_lpn_info && data.permit_class.toLowerCase() === "resident") {
      return (
        <>
          {this.renderRow("Make", data.car_make || "-")}
          {this.renderRow("Modal", data.car_model || "-")}
          {this.renderRow("Color", data.car_color || "-")}
        </>
      );
    }
  };

  renderGroupLpnInfo = (data) => {
    if (data.groupid && data.permit_class === "PermitPackage") {
      return (
        <>
          {this.renderRow("Group LPN", data.lpn_options ? data.lpn_options.join(", ") : "-")}
          {this.renderRow("Max Allowed In", data?.permit_share_group?.max_allowed_in || "-")}
          {data?.permit_share_group?.group_type !== "system" && (
            <Col md={4} lg={4} xs={4} className="expanded-row-col">
              <Row className="expanded-row">
                <Col xs="4">
                  <b>
                    <Translation>{(t) => <>{t("Over Group Permit Count")}</>}</Translation>
                  </b>
                </Col>
                <Col xs="8">
                  {+data?.permit_share_group?.max_allowed_in < data.lpn_options?.length + 1 ? (
                    <span>{data.lpn_options?.length + 1 - +data?.permit_share_group?.max_allowed_in || 0}</span>
                  ) : (
                    <span>-</span>
                  )}
                </Col>
              </Row>
            </Col>
          )}
        </>
      );
    }
  };

  checkValidation = (row) => {
    let isDisabled;
    if (row.permit_class.toLowerCase() === "visitor" && row.status === "Inactive") {
      if (row.deactivation_reason === "CRON: Due to past expiry date") {
        isDisabled = false;
      } else {
        isDisabled = true;
      }
    } else {
      if (row.status === "Inactive") {
        isDisabled = true;
      }
    }
    return isDisabled;
  };

  actionButton = (row) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="Primary" className="btn-xs" id="dropdown-basic">
          Action
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="" onClick={() => this.modelOpen(row)} disabled={this.checkValidation(row)}>
            <Translation>{(t) => <>{t("Edit Permit")}</>}</Translation>
          </Dropdown.Item>

          {this.refundAction(row)}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  finalReturn = (row) => {
    return (
      <Dropdown.Item href="" onClick={() => this.refundModelOpen(row)}>
        <Translation>{(t) => <>{t("Refund")}</>}</Translation>
      </Dropdown.Item>
    );
  };

  refundAction = (row) => {
    if (row.permit_class === "PermitPackage") {
      if (row.primary_permit && row.paid_permit_type !== "invoice" && row.refunded_amount <= 0 && !row.active) {
        return this.finalReturn(row);
      }
    } else if (row.permit_class === "PassagePass") {
      if (row.refunded_amount <= 0 && !row.active) {
        return this.finalReturn(row);
      }
    } else if (row.permit_class === "Visitor") {
      if (row.refunded_amount <= 0 && !row.active && row.paid_amount > 0) {
        return this.finalReturn(row);
      }
    }
  };

  refundModelOpen = async (row) => {
    await this.props.fetchPassagePassTransactionData(row);
    if (this.props.api_response) {
      this.setState({
        getPermits: this.props.getPassagePass,
        refundPopupOpen: true,
        selectRowData: row,
      });
    } else {
      this.setState({
        show_error_alert: false,
        api_error_message: this.props.api_error_message,
      });
    }
    setTimeout(() => {
      this.setState({
        show_error_alert: true,
        api_error_message: "",
      });
    }, 2000);
  };

  closeRefundModel = async () => {
    this.setState({
      refundPopupOpen: false,
    });
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      setTimeout(() => {
        this.setState({ show_alert: false, api_success_message: "" });
      }, 4000);
    } else {
      this.setState({
        show_error_alert: false,
        api_error_message: this.props.api_error_message,
      });
      setTimeout(() => {
        this.setState({
          show_error_alert: true,
          api_error_message: "",
          selectRowData: {},
        });
      }, 4000);
    }
  };

  ticketDataPdf = async (sendMailData) => {
    sendMailData.language = this.state.unit_language;
    console.log("sendMailData::::::::::::", sendMailData);
    await this.props.pdfSendToMail(sendMailData);
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        isSendMailModalOpen: false,
        showReceiptModal: false,
      });
      setTimeout(() => {
        this.setState({ show_alert: false });
      }, 4000);
    }
  };

  closeModal = () => {
    this.setState({
      showReceiptModal: false,
    });
  };

  handleShowReceiptDetails = async (row) => {
    console.log("this is the row", row);
    this.setState(
      {
        showReceiptModal: true,
        pdfLoader: true,
      },
      async () => {
        if (row) {
          const data = await this.props.getParkingReceiptPDF(row);
          if (this.props.api_response || data) {
            this.setState({
              pdfUrl: data,
              selectedReceiptId: row.id,
              propertyId: row.property_id,
              pdfLoader: false,
            });
          }
        }
      },
    );
  };

  sendMailClick = (e, emailToSend) => {
    e.preventDefault();
    const type = "pdf";
    const sendMailData = {
      type,
      emailToSend,
      permitId: this.state.selectedReceiptId,
      propertyId: this.state.propertyId,
    };
    this.ticketDataPdf(sendMailData);
  };

  async componentDidMount() {
    const { user_type } = this.state;
    const selectedPropertyName = this.state.property?.property_name;
    const selectedDomainName = this.state.property?.domain;
    this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    this.props.fetchResidentList(this.state.property?.id);
    this.props.get_all_lots(this.state.property?.id, "", false, true);
    if (this.props.location.search === "") {
      // for admin view all permits
      this.setState({ loader: true }, async () => {
        this.setState({ beforeState: this.state.permit_class });
        _i18n.changeLanguage(this.state.unit_language);
        if (this.props.location?.data) {
          this.setState({
            suite_id: this.props.location?.data?.suite_id,
            lot_id: this.props.location?.data?.lot_id,
            package_name: this.props.location?.data?.package_name,
            activeTab: this.props.location?.data?.activeTab,
            status: this.props.location?.data?.status,
          });
          this.props.getAllPermitOptionsByLots(this.state.property?.id, this.props.location?.data?.lot_id);
        }
        let suite_id = this.state.suite_id;
        if (this.props.permission_id === 2) {
          suite_id = this.props.suite_id;
          this.setState({
            suite_id: this.props.suite_id,
          });
        }
        const {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          permitType,
          status,
          auto_renew,
          selectedPropertyObj,
          PropertyId,
          childcheckbox,
          package_name,
          lot_id,
          primary_permit,
          cron_auto_renew,
          unit_language,
        } = this.state;
        let pageobj = {
          suite_id: suite_id,
          userType: user_type,
          permitType,
          status,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          unit_language,
        };
        if (Object.keys(selectedPropertyObj).length) {
          pageobj.PropertyId = selectedPropertyObj.value;
        } else {
          pageobj.PropertyId = PropertyId;
        }
        if (childcheckbox) {
          pageobj.childcheckbox = childcheckbox;
        }
        await this.props.fetchCurrentPermits(pageobj);

        pageobj = {
          suite_id: this.props.location?.data?.suite_id || suite_id,
          userType: user_type,
          permitType,
          status: this.props.location?.data?.status || status,
          page,
          auto_renew,
          cron_auto_renew,
          package_name: this.props.location?.data?.package_name || package_name,
          lot_id: this.props.location?.data?.lot_id || lot_id,
          primary_permit,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        if (Object.keys(selectedPropertyObj).length) {
          pageobj.PropertyId = selectedPropertyObj.value;
        } else {
          pageobj.PropertyId = PropertyId;
        }
        if (childcheckbox) {
          pageobj.childcheckbox = childcheckbox;
        }

        PageObject = pageobj;

        await this.props.fetchPermitsPackages(pageobj);
        await this.props.get_all_properties();

        this.setState({
          api_response: this.props?.api_response,
          all_properties: this.props?.getAllProperties,
        });

        const { currentPermitData, currentPermitPackagesData } = await this.manageCurrentPermitForPackages();

        this.setState({
          _currentPermits: currentPermitData,
          count: currentPermitData.length,
          filterdPermits: currentPermitData,
          _currentPermitPackages: currentPermitPackagesData,
          filterdPermitPackages: currentPermitPackagesData,
          countPermitPackages: currentPermitPackagesData.length,
          loader: false,
          showError: this.props?.api_response,
          show_alert: false,
        });
      });
    } else {
      const { user_type: userType } = this.state;
      // for admin looking for resident
      const suite_id_ = this.props.location.search.split("?")[1] ? this.props.location.search.split("?")[1] : this.props.suite_id;
      this.setState({ loader: true, suite_id: suite_id_ }, async () => {
        this.setState({ beforeState: this.state.permit_class });
        _i18n.changeLanguage(this.state.unit_language);
        if (this.props.location?.data) {
          this.setState({
            suite_id: this.props.location?.data?.suite_id,
            lot_id: this.props.location?.data?.lot_id,
            package_name: this.props.location?.data?.package_name,
            activeTab: this.props.location?.data?.activeTab,
            status: this.props.location?.data?.status,
          });
        }
        const {
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          permitType,
          status,
          selectedPropertyObj,
          PropertyId,
          childcheckbox,
          package_name,
          lot_id,
          unit_language,
        } = this.state;
        let pageobj = {
          suite_id: suite_id_,
          userType,
          permitType,
          status,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
          unit_language,
        };
        if (Object.keys(selectedPropertyObj).length) {
          pageobj.PropertyId = selectedPropertyObj.value;
        } else {
          pageobj.PropertyId = PropertyId;
        }
        if (childcheckbox) {
          pageobj.childcheckbox = childcheckbox;
        }
        await this.props.fetchCurrentPermits(pageobj);
        pageobj = {
          suite_id: this.props.location?.data?.suite_id || suite_id_,
          userType,
          permitType,
          status: this.props.location?.data?.status || status,
          package_name: this.props.location?.data?.package_name || package_name,
          lot_id: this.props.location?.data?.lot_id || lot_id,
          page,
          Norow,
          searchValue,
          selectedColumn,
          sort_direction,
        };
        if (Object.keys(selectedPropertyObj).length) {
          pageobj.PropertyId = selectedPropertyObj.value;
        } else {
          pageobj.PropertyId = PropertyId;
        }
        if (childcheckbox) {
          pageobj.childcheckbox = childcheckbox;
        }
        PageObject = pageobj;

        await this.props.fetchPermitsPackages(pageobj);
        await this.props.get_all_properties();

        const { currentPermitData, currentPermitPackagesData } = await this.manageCurrentPermitForPackages();

        this.setState({
          _currentPermits: currentPermitData,
          count: currentPermitData.length,
          filterdPermits: currentPermitData,
          _currentPermitPackages: currentPermitPackagesData,
          filterdPermitPackages: currentPermitPackagesData,
          countPermitPackages: currentPermitPackagesData.length,
          loader: false,
          showError: this.props?.api_response,
          show_alert: false,
        });
      });
    }
    const exportUrl = process.env.REACT_APP_CONFIG_BASE_URL;
    const height = tableHeightScrollBars();
    const detectedDevice = this.getMobileOperatingSystem();

    this.setState(
      {
        finalTableHeight: height,
        detectedDevice: detectedDevice,
      },
      () => {
        console.log("showReceiptModal: true,showReceiptModal: true,", this.state.detectedDevice);
      },
    );
    if (exportUrl) {
      this.setState({
        exportUrl: `${exportUrl}/api/v1/user/export_csv/${this.state?.property?.id}/permits`,
        newPermitUrl: `${exportUrl}/api/v1/user/export_csv/${this.state?.property?.id}/permits`,
      });
    }
  }

  getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  manageCurrentPermitForPackages = async () => {
    const currentPermitData = this.props?.currentPermits?.data ? JSON.parse(JSON.stringify(this.props?.currentPermits?.data)) : [];

    const group_data_tobe_join = currentPermitData.filter((item) => item.groupid);

    currentPermitData.forEach((item) => {
      if (item?.groupid && item?.permit_class === "PermitPackage") {
        const l = group_data_tobe_join.filter((item2) => item2?.groupid === item?.groupid && item.id !== item2.id);
        const lpns = [];
        l.forEach((lpn) => {
          lpns.push(lpn.lpn);
        });
        item.lpn_options = lpns;
      }
    });
    const currentPermitPackagesData = this.props?.currentPermitPackages?.data ? JSON.parse(JSON.stringify(this.props?.currentPermitPackages?.data)) : [];

    const package_group_data_tobe_join = currentPermitPackagesData.filter((item) => item?.groupid);

    currentPermitPackagesData.forEach((item) => {
      if (item?.groupid && item?.permit_class === "PermitPackage") {
        const l = package_group_data_tobe_join.filter((item2) => item2?.groupid === item?.groupid && item?.id !== item2?.id);
        const lpns = [];
        l.forEach((lpn) => {
          lpns.push(lpn.lpn);
        });
        item.lpn_options = lpns;
      }
    });
    return { currentPermitData, currentPermitPackagesData };
  };

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState({ suite_id: "" });
    }
    if (!isEqual(prevProps?.currentPermitPackages, this?.props?.currentPermitPackages)) {
      const { currentPermitPackagesData } = await this.manageCurrentPermitForPackages();
      this.setState(
        {
          _currentPermitPackages: currentPermitPackagesData,
          filterdPermitPackages: currentPermitPackagesData,
          countPermitPackages: currentPermitPackagesData.length,
          showError: this.props?.api_response,
          temp_deactivation: true,
        },
        () => {
          if (this.state.filterdPermitPackages.length === 0) {
            this.setState({ page: 1 }, async () => {
              this.setState({
                filterdPermitPackages: currentPermitPackagesData,
                countPermitPackages: currentPermitPackagesData.length,
              });
            });
          }
          if (this.state.searchValue?.length) {
            this.handleSearchChange(this.state.searchValue);
          }
        },
      );
    }
    if (!isEqual(prevProps.currentPermits, this.props.currentPermits)) {
      const { currentPermitData } = await this.manageCurrentPermitForPackages();
      this.setState(
        {
          _currentPermits: currentPermitData,
          filterdPermits: currentPermitData,
          count: currentPermitData.length,
          showError: this.props?.api_response,
          temp_deactivation: true,
        },
        () => {
          if (this.state.filterdPermits?.length === 0) {
            this.setState({ page: 1 }, async () => {
              this.setState({
                filterdPermits: currentPermitData,
                count: currentPermitData.length,
              });
            });
          }
          if (this.state.searchValue?.length) {
            this.handleSearchChange(this.state.searchValue);
          }
        },
      );
    }
    if (!isEqual(prevProps.selectedLang, this.props.selectedLang)) {
      this.setState({
        unit_language: this.props.selectedLang,
      });
      _i18n.changeLanguage(this.props.selectedLang);
    }
  }

  modelOpen = async (row) => {
    this.setState({
      modelOpen: !this.state.modelOpen,
      permitData: row,
    });
    if (row.permit_class === "PermitPackage" && row.stall_type !== "no_stall") {
      await this.props.getAllStallData(row.property_id, row.lot_id);
    }
  };

  exportUrlCall = () => {
    const unix_time = Math.round(new Date().getTime() / 1000);
    const current_time = unix_time - 14400;
    const { permitType, status, searchValue, exportUrl } = this.state;
    const newPermitUrl = `${exportUrl}/?permit_type=${permitType}&status=${status}&search=${searchValue}&current_time=${current_time}`;
    this.setState({
      newPermitUrl: newPermitUrl,
    });
  };

  handleShowCancelPermitModal = (row) => {
    this.setState({
      show_cancel_modal: !this.state.show_cancel_modal,
      selectedPermitId: row.id,
      permitData: row,
    });
  };

  handleCancelPermit = async (e, selected_options, amount) => {
    e.preventDefault();
    this.setState({
      deactivateLoader: true,
    });
    if (selected_options === "based_on_days") {
      await this.props.cancelAndRefundPaidPermit(this.state.selectedPermitId, true);
    } else {
      await this.props.cancelAndRefundPaidPermit(this.state.selectedPermitId, false, amount);
    }
    await this.handleUpdatedPermitsAfterCancel();
  };

  handleUpdatedPermitsAfterCancel = async () => {
    if (this.props.api_response) {
      this.setState({
        api_success_message: this.props.api_success_message,
        selectedPermitId: "",
        show_cancel_modal: !this.state.show_cancel_modal,
        show_alert: true,
        deactivateLoader: false,
      });
      await this.handlePermitsAfterDeactivateAndCancel();
    } else {
      this.setState({
        show_error_alert: false,
        api_error_message: this.props.api_error_message,
        selectedPermitId: "",
        show_cancel_modal: !this.state.show_cancel_modal,
        deactivateLoader: false,
      });
    }
    setTimeout(() => {
      this.setState({
        show_error_alert: false,
        api_error_message: "",
        show_alert: false,
        api_success_message: "",
      });
    }, 2000);
  };

  handleDeActivatePermit = (permitId) => {
    this.setState({
      show_modal: true,
      Id: permitId,
      confirmDelete: true,
      isModalOpen: !this.state.isModalOpen,
      temp_deactivation: false,
      show_alert: false,
      api_success_message: "",
      api_error_message: "",
    });
  };

  saveit = () => {
    const Id = this.state.Id;
    this.setState({ deactivateLoader: true }, async () => {
      await this.props.deActivatePermit(Id);
      await this.handleUpdatedPermitsAfterDeactivate();
    });
  };

  handleUpdatedPermitsAfterDeactivate = async () => {
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
        Id: "",
        call_when_here: "",
        confirmDelete: false,
        deactivateLoader: false,
        isModalOpen: false,
      });
      await this.handlePermitsAfterDeactivateAndCancel();
    } else {
      this.setState({
        show_error_alert: false,
        api_error_message: this.props.api_error_message,
        Id: "",
        call_when_here: "",
        confirmDelete: false,
        deactivateLoader: false,
        isModalOpen: false,
      });
    }
    setTimeout(() => {
      this.setState({
        show_error_alert: false,
        api_error_message: "",
        Id: "",
        call_when_here: "",
        // confirmDelete: false,
        temp_deactivation: true,
      });
    }, 1500);
  };

  handlePermitsAfterDeactivateAndCancel = async () => {
    const { user_type: userType } = this.state;
    const suite_id_ = this.props.location?.search.split("?")[1] || this.props.suite_id || "";

    const {
      page,
      Norow,
      searchValue,
      selectedColumn,
      sort_direction,
      permitType,
      package_name,
      primary_permit,
      auto_renew,
      cron_auto_renew,
      lot_id,
      status,
      selectedPropertyObj,
      PropertyId,
      childcheckbox,
      unit_language,
    } = this.state;
    let pageobj = {
      suite_id: suite_id_,
      userType,
      permitType,
      status,
      page,
      Norow,
      searchValue,
      selectedColumn,
      sort_direction,
      unit_language,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (childcheckbox) {
      pageobj.childcheckbox = childcheckbox;
    }
    await this.props.fetchCurrentPermits(pageobj);
    pageobj = {
      suite_id: suite_id_,
      userType,
      lot_id,
      permitType,
      package_name,
      primary_permit,
      page,
      Norow,
      auto_renew: auto_renew,
      cron_auto_renew,
      searchValue,
      selectedColumn,
      sort_direction,
      status,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (childcheckbox) {
      pageobj.childcheckbox = childcheckbox;
    }
    await this.props.fetchPermitsPackages(pageobj);

    const { currentPermitData, currentPermitPackagesData } = await this.manageCurrentPermitForPackages();

    this.setState({
      _currentPermits: currentPermitData,
      count: currentPermitData.length,
      filterdPermits: currentPermitData,
      _currentPermitPackages: currentPermitPackagesData,
      filterdPermitPackages: currentPermitPackagesData,
      countPermitPackages: currentPermitPackagesData.length,
    });
  };

  SearchBarViewAllPermit = (target, columnList) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({
      prevSearch: this.state.searchValue,
      page: 1,
      pageChanged: true,
    });
    this.setState({ searchValue: val }, () => {
      if (this.state.searchValue.length) {
        this.exportUrlCall();
        const { searchValue } = this.state;
        if (!this.state.findMore) {
          this.setState(
            {
              pageChanged: true,
              filterdPermits: findBySearch(searchValue, this.state._currentPermits, this.state._currentPermits, columnList, this.props.timezone),
            },
            () => {
              this.setState({ count: this.state.filterdPermits.length });
            },
          );
        }
        this.setState({
          searchValue,
        });
      } else {
        this.setState({
          _currentPermits: this.state._currentPermits,
          count: this.state._currentPermits.length,
          filterdPermits: [],
        });
      }
    });
  };

  SearchBarPermitPackages = (target, columnList) => {
    const val = target ? target.value.toString().trim() : this.state.searchPermitPackages.toString().trim();
    this.setState({
      prevSearchPermitPackage: this.state.searchPermitPackages,
      page: 1,
      pageChanged: true,
    });
    this.setState({ searchPermitPackages: val }, () => {
      if (this.state.searchPermitPackages.length) {
        this.exportUrlCall();
        const { searchPermitPackages } = this.state;
        if (!this.state.findMore) {
          this.setState(
            {
              pageChanged: true,
              filterdPermitPackages: findBySearch(
                searchPermitPackages,
                this.state._currentPermitPackages,
                this.state._currentPermitPackages,
                columnList,
                this.props.timezone,
              ),
            },
            () => {
              this.setState({
                countPermitPackages: this.state.filterdPermitPackages.length,
              });
            },
          );
        }
        this.setState({
          searchPermitPackages,
        });
      } else {
        this.setState({
          _currentPermitPackages: this.state._currentPermitPackages,
          countPermitPackages: this.state._currentPermitPackages.length,
          filterdPermitPackages: [],
        });
      }
    });
  };

  handleSearchChange = ({ target }) => {
    const columnList = [
      "lpn",
      "begin_time",
      "expire_time",
      "suite_id",
      "permit_class",
      "first_name",
      "last_name",
      "phone",
      "phone_options",
      "email",
      "permit_created_at",
      "permit_tag",
      "usage",
      "stall_id",
      "lot_name",
      "card_number",
      "package_name",
      "groupid",
      "card_number",
    ];
    this.SearchBarViewAllPermit(target, columnList);
    this.SearchBarPermitPackages(target, columnList);
  };

  handleCall_when_here_activate_deactive = async (row) => {
    await this.props.call_when_here_activate_deactive(row.id);
    this.handleAlertMessage(row);
  };

  handleAutoRenewActivateDeactivate = async (row) => {
    await this.props.activateDeactivateAutoRenewForPermit(row);
    this.handleAlertMessage(row);
  };

  handleAutoCancelActivateDeactivate = async (row) => {
    await this.props.activateDeactivateAutoCancelForPermit(row);
    this.handleAlertMessage(row);
  };

  handleAlertMessage = async (row) => {
    this.setState({
      Id: row.id,
    });
    if (this.props.api_response) {
      this.setState({
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
    } else {
      this.setState({
        show_error_alert: false,
        api_error_message: this.props.api_error_message,
      });
    }
    setTimeout(() => {
      this.setState({ show_alert: false, show_error_alert: true });
    }, 2000);
  };

  datatableHandle = (searchValue, currentPermits, filterdPermits) => {
    let column = [];
    if (this.state.activeTab === "viewallpermits") {
      column = this.columns.filter(
        (item) => item.column_identification !== "Cancel" && item.column_identification !== "Auto Renew" && item.column_identification !== "Auto Cancel",
      );
    } else {
      column = this.columns.filter((item) => item.name !== "CWH" && item.column_identification !== "Deactivate");
    }
    return (
      <DataTable
        data={searchValue?.length ? filterdPermits : currentPermits}
        columns={column}
        customStyles={this.customStyles}
        expandableRows
        expandableRowsComponent={this.expandedComponent}
        responsive={true}
        // subHeader
        noDataComponent={<Translation>{(t) => <>{t("There_are_no_record_to_display")}</>}</Translation>}
        onChangePage={scroolToTop}
        defaultSortAsc={false}
        fixedHeader={true}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        pagination={true}
        paginationPerPage={this.state.Norow}
        paginationTotalRows={this.state.activeTab === "viewallpermits" ? this.state.count : this.state.countPermitPackages}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  CloseModal() {
    this.setState({ modelOpen: false });
  }

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleFilterChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    if (name === "lot_id") {
      if (value === "all") {
        this.setState({ package_name: "all" });
      }
      this.props.getAllPermitOptionsByLots(this.state.property?.id, value);
    }
  };

  resetPermitFilters = () => {
    this.setState({
      permitType: "all",
      status: "current",
      auto_renew: "all",
      suite_id: "ALL",
      cron_auto_renew: "all",
      primary_permit: "all",
      package_name: "all",
      lot_id: "all",
      selectedPropertyObj: {
        label: this.state.property?.property_name,
        value: this.props.property_id,
      },
    });
  };

  handleSearch = async () => {
    this.setState({ loader: true }, async () => {
      const {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        permitType,
        status,
        primary_permit,
        suite_id,
        lot_id,
        package_name,
        user_type,
        selectedPropertyObj,
        childcheckbox,
        auto_renew,
        cron_auto_renew,
        PropertyId,
        unit_language,
      } = this.state;
      const pageObj = {
        suite_id,
        lot_id,
        package_name,
        user_type,
        permitType,
        status,
        primary_permit,
        childcheckbox,
        page,
        Norow,
        auto_renew: auto_renew,
        cron_auto_renew,
        searchValue,
        selectedColumn,
        sort_direction,
        unit_language,
      };
      pageObj.exportLink = false;
      if (Object.keys(selectedPropertyObj).length) {
        pageObj.PropertyId = selectedPropertyObj.value;
      } else {
        pageObj.PropertyId = PropertyId;
      }
      await this.props.fetchCurrentPermits(pageObj);
      await this.props.fetchPermitsPackages(pageObj);
      this.setState({
        loader: false,
        listHeading: this.ListHeadingManage(status),
      });
      document.body.classList.remove("foc-open");
      const history = require("../../history").default;
      history.replace({
        pathname: "/current-permits",
        state: {},
      });
    });
  };

  ListHeadingManage = (status) => {
    const statusHeadings = {
      deactivated_past_90: "Deactivated past 90",
      deactivated: "Deactivated",
      current: "Current",
      future: "Future",
    };
    return statusHeadings[status] || status;
  };

  onTabClick = (value) => {
    console.log("value tab", value);
    this.setState({ activeTab: value });
  };

  exportPemrits = () => {
    this.setState({ loader: true }, async () => {
      const {
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        permitType,
        status,
        suite_id,
        user_type,
        property,
        activeTab,
        selectedPropertyObj,
        PropertyId,
        childcheckbox,
        lot_id,
        package_name,
        primary_permit,
        auto_renew,
        cron_auto_renew,
        unit_language,
      } = this.state;
      const pageObj = {
        suite_id,
        user_type,
        permitType: permitType,
        status,
        page,
        Norow,
        searchValue,
        selectedColumn,
        sort_direction,
        lot_id,
        package_name,
        primary_permit,
        auto_renew,
        cron_auto_renew,
        unit_language,
      };

      pageObj.exportLink = true;

      if (Object.keys(selectedPropertyObj).length) {
        pageObj.PropertyId = selectedPropertyObj.value;
      } else {
        pageObj.PropertyId = PropertyId;
      }
      if (childcheckbox) {
        pageObj.childcheckbox = childcheckbox;
      }

      let bufferData;
      if (activeTab === "viewallpermits") {
        bufferData = await this.props.fetchCurrentPermits(pageObj);
      } else {
        bufferData = await this.props.fetchPermitsPackages(pageObj);
      }
      console.log("bufferData ===>", bufferData);
      const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
      const exportDataName = `PERMITS-${currentTimeDate}-${property?.domain}.xls`;
      if (bufferData) {
        saveAs(
          new Blob([bufferData], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
          exportDataName,
        );
      }

      this.setState({ loader: false });
    });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    console.log("numPages", numPages);
  };

  render() {
    const {
      permitType,
      suite_id,
      lot_id,
      package_name,
      primary_permit,
      cron_auto_renew,
      status,
      loader,
      isModalOpen,
      confirmDelete,
      show_alert,
      show_error_alert,
      api_success_message,
      api_error_message,
      searchValue,
      filterdPermits,
      _currentPermits,
      _currentPermitPackages,
      filterdPermitPackages,
      searchPermitPackages,
      modelOpen,
      listHeading,
      show_cancel_modal,
      selectedPropertyObj,
      childcheckbox,
      auto_renew,
      showReceiptModal,
      isSendMailModalOpen,
      activeTab,
      selectRowData,
      refundPopupOpen,
      unit_language,
    } = this.state;

    return (
      <div>
        <Container fluid>
          {loader ? (
            <Loader />
          ) : (
            <>
              <Tabs activeKey={activeTab} defaultActiveKey="viewallpermits" id="controlled-tab-example" onSelect={(k) => this.onTabClick(k)}>
                <Tab
                  eventKey="viewallpermits"
                  title={<Translation>{(t) => <>{t("View All Permits")}</>}</Translation>}
                  className="m-0 p-2 bg-white border-0 card"
                  style={{ boxShadow: "none", borderRadius: "0" }}
                >
                  <div className="sidebarFilter">
                    <Card className="contact-container mb-0">
                      <Card.Header style={{ backgroundColor: this.props.main_body }}>
                        <div className="d-flex align-items-center justify-content-between">
                          <Card.Title as="h4">
                            <Translation>{(t) => <>{t(`List of ${listHeading} Permit`)}</>}</Translation>
                          </Card.Title>
                          <button
                            onClick={this.hideSlider}
                            className="btn btn-sm border-0 bg-light rounded-circle w-30px h-30px d-flex align-items-center justify-content-center"
                            aria-label="Hide Slider"
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </button>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md="12">
                            <div className="mb-2">
                              <label className="fancy-checkbox">
                                <input
                                  type="checkbox"
                                  name={"childcheckbox"}
                                  checked={childcheckbox}
                                  onChange={(e) => {
                                    this.setState({
                                      childcheckbox: !this.state.childcheckbox,
                                    });
                                  }}
                                  className="custom-control-input"
                                  id="childcheckbox1"
                                ></input>
                                <span>
                                  <i></i>
                                  <Translation>{(t) => <>{t("Include Children Sites")}</>}</Translation>
                                </span>
                              </label>
                            </div>
                          </Col>
                          <Col md="12">
                            <PropertDetails
                              selectedPropertyObj={selectedPropertyObj}
                              changeProperty={(e) => {
                                this.setState({
                                  selectedPropertyObj: e,
                                });
                              }}
                            />
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>
                                <Translation>{(t) => <>{t("Unit")}</>}</Translation>:
                              </Form.Label>
                              <Form.Control as="select" name="suite_id" value={suite_id} onChange={this.handleFilterChange} placeholder="Select UnitId">
                                <option value="ALL">ALL</option>
                                <option value="ParkingPermit">ParkingPermit</option>
                                {(this.props?.residentList ?? []).map((i) => (
                                  <option key={i.id}>{i?.suite_id.length > 30 ? `${i.suite_id.slice(0, 30)}...` : i.suite_id}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>
                                <Translation>{(t) => <>{t("Permit Type")}</>}</Translation>:
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="permitType"
                                value={permitType}
                                onChange={this.handleFilterChange}
                                placeholder="Select Permit Type"
                              >
                                <Translation>{(t) => <option value="all">{t("All")}</option>}</Translation>
                                <Translation>{(t) => <option value="Resident">{t("Standard")}</option>}</Translation>
                                <Translation>{(t) => <option value="Visitor">{t("Visitor")}</option>}</Translation>
                                <Translation>{(t) => <option value="PassagePass">{t("PassagePass")}</option>}</Translation>
                                <Translation>{(t) => <option value="Kiosk">{t("Kiosk")}</option>}</Translation>
                                <Translation>{(t) => <option value="Grace">{t("Grace")}</option>}</Translation>
                                <Translation>{(t) => <option value="Validation">{t("Validation")}</option>}</Translation>
                                <Translation>{(t) => <option value="PermitPackage">{t("PermitPackage")}</option>}</Translation>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>
                                <Translation>{(t) => <>{t("Status")}</>}</Translation>:
                              </Form.Label>
                              <Form.Control as="select" name="status" value={status} onChange={this.handleFilterChange} placeholder="Select Status">
                                <Translation>{(t) => <option value="current">{t("Current")}</option>}</Translation>
                                <Translation>{(t) => <option value="future">{t("Future")}</option>}</Translation>
                                <Translation>{(t) => <option value="deactivated">{t("Deactivated (past 30 days)")}</option>}</Translation>
                                <Translation>{(t) => <option value="deactivated_past_90">{t("Deactivated (past 90 days)")}</option>}</Translation>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer className="text-right justify-content-between d-flex">
                        <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetPermitFilters} disabled={loader}>
                          <Translation>{(t) => <>{t("Reset")}</>}</Translation>
                        </Button>
                        <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                          <Translation>{(t) => <>{t("Search")}</>}</Translation>
                        </Button>
                      </Card.Footer>
                    </Card>
                  </div>
                  <header>
                    <div className="active-permit-header sm-flex-column sm-align-items-start">
                      <div className="d-flex align-items-center"></div>
                      <div className="filter-button d-flex flex-wrap">
                        <div className="d-flex align-items-center search-mb-0">
                          <DatatableHeader className="mt-0 mb-0" searchValue={searchValue} HandleChange={this.handleSearchChange} />
                          {filterdPermits.length || _currentPermits.length > 0 ? (
                            <div className="ml-2">
                              <span onClick={this.exportPemrits} className="btn-fill btn-sm btn btn-info btn-medium">
                                <Translation>{(t) => <>{t("Export")}</>}</Translation>
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </header>
                  <div className="citation-list-table view-all-permit-table">{this.datatableHandle(searchValue, _currentPermits, filterdPermits)}</div>
                </Tab>
                <Tab
                  eventKey="permitpackages"
                  title={<Translation>{(t) => <>{t("Permit Packages")}</>}</Translation>}
                  className="m-0 p-2 bg-white border-0 card"
                  style={{ boxShadow: "none", borderRadius: "0" }}
                >
                  <div className="sidebarFilter">
                    <Card className="contact-container mb-0">
                      <Card.Header style={{ backgroundColor: this.props.main_body }}>
                        <div className="d-flex align-items-center justify-content-between">
                          <Card.Title as="h4">
                            <Translation>{(t) => <>{t(`List of ${listHeading} Permit`)}</>}</Translation>
                          </Card.Title>
                          <button
                            onClick={this.hideSlider}
                            className="btn btn-sm border-0 bg-light rounded-circle w-30px h-30px d-flex align-items-center justify-content-center"
                            aria-label="Hide Slider"
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </button>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md="12">
                            <div className="mb-2">
                              <label className="fancy-checkbox">
                                <input
                                  type="checkbox"
                                  name={"childcheckbox"}
                                  checked={childcheckbox}
                                  onChange={(e) => {
                                    this.setState({
                                      childcheckbox: !this.state.childcheckbox,
                                    });
                                  }}
                                  className="custom-control-input"
                                  id="childcheckbox1"
                                ></input>
                                <span>
                                  <i></i>
                                  <Translation>{(t) => <>{t("Include Children Sites")}</>}</Translation>{" "}
                                </span>
                              </label>
                            </div>
                          </Col>
                          <Col md="12">
                            <PropertDetails
                              selectedPropertyObj={selectedPropertyObj}
                              changeProperty={(e) => {
                                this.setState({
                                  selectedPropertyObj: e,
                                });
                              }}
                            />
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>
                                <Translation>{(t) => <>{t("Permit Type")}</>}</Translation>:
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="permitType"
                                value={permitType}
                                onChange={this.handleFilterChange}
                                placeholder="Select Permit Type"
                              >
                                <option value="PermitPackage">Permit Package</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>
                                <Translation>{(t) => <>{t("Unit")}</>}</Translation>:
                              </Form.Label>
                              <Form.Control as="select" name="suite_id" value={suite_id} onChange={this.handleFilterChange} placeholder="Select UnitId">
                                <option value="ALL">ALL</option>
                                <option value="ParkingPermit">ParkingPermit</option>
                                {(this.props?.residentList ?? []).map((i) => (
                                  <option key={i.id}>{i?.suite_id.length > 30 ? `${i.suite_id.slice(0, 30)}...` : i.suite_id}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>
                                <Translation>{(t) => <>{t("Lot Name")}</>}</Translation>:
                              </Form.Label>
                              <Form.Control as="select" name="lot_id" value={lot_id} onChange={this.handleFilterChange} placeholder="Select Lot Name">
                                <option value="all">ALL</option>
                                {(this.props?.lots ?? []).map((i) => (
                                  <option key={i.id} value={i.id}>
                                    {i?.alias}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>
                                <Translation>{(t) => <>{t("Package Name")}</>}</Translation>:
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="package_name"
                                value={package_name}
                                onChange={this.handleFilterChange}
                                placeholder="Select Package Name"
                              >
                                <option value="all">ALL</option>
                                {(this.props?.allPermitOptionsList ?? []).map((i) => (
                                  <option key={i.id}>{i?.package_name}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>
                                <Translation>{(t) => <>{t("Auto Renew")}</>}</Translation>:
                              </Form.Label>
                              <Form.Control as="select" name="auto_renew" value={auto_renew} onChange={this.handleFilterChange} placeholder="Select auto renew">
                                <option value="all">ALL</option>
                                <option>true</option>
                                <option>false</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>
                                <Translation>{(t) => <>{t("Primary Permit")}</>}</Translation>:
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="primary_permit"
                                value={primary_permit}
                                onChange={this.handleFilterChange}
                                placeholder="Select primary permit"
                              >
                                <option value="all">ALL</option>
                                <option>true</option>
                                <option>false</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>
                                Cron <Translation>{(t) => <>{t("Auto Renew")}</>}</Translation>:
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="cron_auto_renew"
                                value={cron_auto_renew}
                                onChange={this.handleFilterChange}
                                placeholder="Select Cron Auto Renew"
                              >
                                <option value="all">ALL</option>
                                <option>true</option>
                                <option>false</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>
                                <Translation>{(t) => <>{t("Status")}</>}</Translation>:
                              </Form.Label>
                              <Form.Control as="select" name="status" value={status} onChange={this.handleFilterChange} placeholder="Select Status">
                                <Translation>{(t) => <option value="current">{t("Current")}</option>}</Translation>
                                <Translation>{(t) => <option value="issued_count">{t("Issued Count")}</option>}</Translation>
                                <Translation>{(t) => <option value="future">{t("Future")}</option>}</Translation>
                                <Translation>{(t) => <option value="deactivated">{t("Deactivated (past 30 days)")}</option>}</Translation>
                                <Translation>{(t) => <option value="deactivated_past_90">{t("Deactivated (past 90 days)")}</option>}</Translation>
                                <Translation>{(t) => <option value="allow_cancellation">{t("Allow Cancellation")}</option>}</Translation>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer className="text-right justify-content-between d-flex">
                        <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetPermitFilters} disabled={loader}>
                          <Translation>{(t) => <>{t("Reset")}</>}</Translation>
                        </Button>
                        <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                          <Translation>{(t) => <>{t("Search")}</>}</Translation>
                        </Button>
                      </Card.Footer>
                    </Card>
                  </div>
                  <header>
                    <div className="active-permit-header sm-flex-column sm-align-items-start">
                      <div className="d-flex align-items-center"></div>
                      <div className="filter-button d-flex flex-wrap">
                        <div className="d-flex align-items-center search-mb-0">
                          <DatatableHeader className="mt-0 mb-0" searchValue={searchPermitPackages} HandleChange={this.handleSearchChange} />
                          {filterdPermitPackages.length || _currentPermitPackages.length > 0 ? (
                            <div className="ml-2">
                              <button onClick={this.exportPemrits} className="btn-fill btn-sm btn btn-info btn-medium">
                                <Translation>{(t) => <>{t("Export")}</>}</Translation>
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </header>
                  <div className="citation-list-table view-all-permit-table">
                    {this.datatableHandle(searchPermitPackages, _currentPermitPackages, filterdPermitPackages)}
                  </div>
                </Tab>
                {this.state.user_type === "admin" ||
                  (this.state.user_type === "superadmin" && (
                    <Tab
                      eventKey="waitlist"
                      title={<Translation>{(t) => <>{t("WaitList")}</>}</Translation>}
                      className="m-0 p-2 bg-white border-0 card"
                      style={{ boxShadow: "none", borderRadius: "0" }}
                    >
                      {activeTab === "waitlist" && <WaitListData />}
                    </Tab>
                  ))}
              </Tabs>
              <>
                {show_alert && api_success_message && (
                  <Translation>{(t) => <CustomContentAlert delay={4000} message={t(api_success_message)} className="toast-success" />}</Translation>
                )}
                {!show_error_alert && api_error_message && (
                  <Translation>{(t) => <CustomContentAlert delay={4000} message={t(api_error_message)} className="toast-error" />}</Translation>
                )}
              </>
            </>
          )}
        </Container>
        {modelOpen && (
          <EditCurrentPermit
            closeModal={this.CloseModal.bind(this)}
            modelOpen={modelOpen}
            permitData={this.state.permitData}
            status={status}
            PageObject={PageObject}
            unit_language={unit_language}
          />
        )}

        <ConfirmModal
          showBrowserModal={isModalOpen}
          deactivateLoader={this.state.deactivateLoader}
          closeModal={() =>
            this.setState((prevState) => ({
              isModalOpen: !prevState.isModalOpen,
              Id: "",
              call_when_here: "",
              confirmDelete: false,
            }))
          }
          {...(confirmDelete && {
            deActivatePermit: this.handleDeActivatePermit,
            Id: this.state.Id,
            saveit: this.saveit,
          })}
        />
        {show_cancel_modal ? (
          <ConfirmationModal
            onOpen={show_cancel_modal}
            onConfirm={this.handleCancelPermit}
            message="Are You sure you want to cancel permit?"
            onClose={this.handleShowCancelPermitModal}
            loading={this.state.deactivateLoader}
            title={"Cancel Permit"}
            permitData={this.state.permitData}
            userType={this.state.user_type}
          />
        ) : null}
        {refundPopupOpen && (
          <SendRefundModal
            closeModal={this.closeRefundModel}
            // handleSave={this.onConfrim}
            data={selectRowData}
            permitData={this.state.getPermits}
          />
        )}

        {
          <Modal show={showReceiptModal} onHide={this.closeModal}>
            <Modal.Header className="no-border">
              <Modal.Title as="h4" className="m-0">
                <h4 className="m-0 font-weight-bold">
                  <Translation>{(t) => <>{t("Parking Receipt")}</>}</Translation>
                </h4>
              </Modal.Title>
              <Translation>
                {(t) => (
                  <Button title={t("Close")} type="button" onClick={this.closeModal} className="close-btn py-0" data-dismiss="modal">
                    <i className="nc-icon nc-simple-remove"></i>
                  </Button>
                )}
              </Translation>
            </Modal.Header>
            <Modal.Body className="modal-data-body">
              {this.state.pdfLoader ? (
                <PdfContainer>
                  <ModalLoader />
                </PdfContainer>
              ) : (
                <div>
                  {this.state.pdfUrl && !isMobile && <EmbedIframe className={"pdf-frame"} src={this.state.pdfUrl} rotate={180} />}
                  {this.state.pdfUrl && isMobile && (
                    <>
                      {this.state.detectedDevice === "iOS" && <EmbedIframe className={"pdf-frame"} src={this.state.pdfUrl} rotate={180} />}
                      {this.state.detectedDevice !== "iOS" && (
                        <div>
                          <Document file={this.state.pdfUrl} onLoadSuccess={this.onDocumentLoadSuccess}>
                            <Page pageNumber={1} />
                          </Document>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <a id="pdf" href={this.state.pdfUrl} target={"_blank"} rel="noopener noreferrer" style={{ display: "none" }}>
                pdf
              </a>
              <div
                className={
                  unit_language === "fn" ? "d-flex w-100 justify-content-md-center flex-wrap flex-column" : "d-flex w-100 justify-content-md-center flex-wrap"
                }
              >
                <Dropdown className="mx-2 mt-2" id="ignoreButton2">
                  <Dropdown.Toggle>
                    <i className="fa fa-download" aria-hidden="true"></i>
                    <Translation>{(t) => <>{t("Save as")}</>}</Translation>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        document.getElementById("pdf").click();
                      }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="mx-2 mt-2" id="ignoreButton2">
                  <Dropdown.Toggle>
                    {/* <img className="" src={email} width={18} /> */}

                    <Translation>{(t) => <>{t("Send mail as")}</>}</Translation>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        this.setState({
                          isSendMailModalOpen: true,
                          showReceiptModal: false,
                        })
                      }
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button id="ignoreButton3" className="btn-fill mx-2 mt-2" type="button" variant="secondary" onClick={this.closeModal}>
                  {/* <img className="ml-0 mr-1" src={cancel} width={18} /> */}

                  <Translation>{(t) => <>{t("Cancel")}</>}</Translation>
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        }
        {isSendMailModalOpen && (
          <SendInvoiceModal
            closeModal={() =>
              this.setState({
                isSendMailModalOpen: false,
              })
            }
            ticketDataPdf={this.sendMailClick}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  CurrentPermitDetails,
  APIResponseDetails,
  UserProfileDetails,
  Logindetails,
  SuperAdminDetails,
  PermitReducer,
  PermitOptionsDetails,
}) => {
  const { currentPermits, currentPermitPackages, residentList, updateUnitLanguage: selectedLang } = CurrentPermitDetails;
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { suite_id, PropertyId, user_type, timezone, property, permission_id } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { getAllProperties, lots } = SuperAdminDetails;
  const { getPassagePass } = PermitReducer;
  const { allPermitOptionsList } = PermitOptionsDetails;
  return {
    currentPermits,
    currentPermitPackages,
    residentList,
    api_response,
    api_error_message,
    suite_id,
    lots,
    allPermitOptionsList,
    PropertyId,
    user_type,
    api_success_message,
    timezone,
    property,
    main_body,
    permission_id,
    getAllProperties,
    getPassagePass,
    selectedLang,
  };
};

const mapStateToDispatch = {
  fetchCurrentPermits: CurrentPermitActions.fetchCurrentPermits,
  fetchResidentList: CurrentPermitActions.fetchResidentList,
  get_all_properties: SuperAdminAction.get_all_properties,
  getAllPermitOptionsByLots: PermitOptionsActions.getAllPermitOptionsByLots,
  get_all_lots: SuperAdminAction.get_all_lots,
  fetchPermitsPackages: CurrentPermitActions.fetchPermitsPackages,
  APIResponseSuccess: APIResponseActions.APIResponseSuccess,
  deActivatePermit: CurrentPermitActions.deActivatePermit,
  call_when_here_activate_deactive: CurrentPermitActions.call_when_here_activate_deactive,
  getParkingReceiptPDF: CurrentPermitActions.getParkingReceiptPDF,
  pdfSendToMail: CurrentPermitActions.pdfSendToMail,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  activateDeactivateAutoRenewForPermit: CurrentPermitActions.activateDeactivateAutoRenewForPermit,
  activateDeactivateAutoCancelForPermit: CurrentPermitActions.activateDeactivateAutoCancelForPermit,
  cancelAndRefundPaidPermit: CurrentPermitActions.cancelAndRefundPaidPermit,
  fetchPassagePassTransactionData: PermitActions.fetchPassagePassTransactionData,
  getAllStallData: PermitOptionsActions.getAllUnitStall,
};

export default connect(mapStateToProps, mapStateToDispatch)(CurrentPermit);

const EmbedIframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 200px);
  rotate: degrees(-45);
`;
const PdfContainer = styled.div`
  width: 100%;
  height: calc(100vh - 200px);
`;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, FormLabel, Container, Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ResidentsActions, SuperAdminAction } from "../../Actions";
import CustomContentAlert, {
  routing_using_history,
  isEmailValid,
  validation_message,
  regExForPhone,
  email_validation_message,
  validations,
  PasswordValidation,
  removeSpecialChar,
} from "../helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class AddResidents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suite_id: "",
      parking_spot: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      do_not_allow_login: false,
      do_not_allow_visitor: false,
      default_credits: true,
      unlimited_credits: false,
      permits_allowed_type: "default_credits",
      password: "",
      company_id: this.props.company_id,
      isFormValid: false,
      validSuiteId: true,
      validParkingSpot: true,
      validFirstName: true,
      validLastName: true,
      validPhone: true,
      validEmail: true,
      show_alert: false,
      api_success_message: "",
      api_error_message: "",
      show_error: false,
      property_id: "",
      property: this.props.property,
      phoneErrorMessage: "",
      passwordErrorMsg: "",
      passwordValue: true,
      passwordVisible: false,
      country_code: "",
      validationTimeType: "HOURS",
      validationTimeInHours: "",
      selectedTime: "",
      country_code_list: [
        { id: 1, text: "+1", value: "+1" },
        { id: 2, text: "+91", value: "+91" },
        { id: 3, text: "+44", value: "+44" },
        { id: 4, text: "+61", value: "+61" },
      ],
      phone_options: [],
      defalutLanguage: "en",
      isSetMaxTime: false,
    };
  }

  componentDidMount() {
    this.setState(
      {
        property_id: this.props.property_id,
      },
      () => {
        const selectedPropertyName = this.state.property?.property_name;
        const selectedDomainName = this.state.property?.domain;
        this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      },
    );
  }

  TogglePasswordVisibility = () => {
    this.setState({
      passwordVisible: !this.state.passwordVisible,
    });
  };

  handlePhoneOptions = (index, value, type) => {
    console.log("index, value :::::", index, value, type);
    const { phone_options } = this.state;
    console.log("phone_options:::", phone_options);

    let phone_values;
    let country_code_values;

    if (type === "phone_number_options") {
      phone_values = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      phone_options[index].phone = phone_values;
    }
    if (type === "country_code_options") {
      country_code_values = value;
      phone_options[index].country_code = country_code_values;
    }

    this.setState(
      {
        phone_options: [...phone_options],
      },
      async () => {
        this.handlephoneOptionValidation();
      },
    );

    console.log("phone_options::::", phone_options);
  };

  removePhoneOption = (index) => {
    const { phone_options } = this.state;
    console.log("index:::::::::::::: Unit:::::", index);

    this.setState(
      {
        phone_options: [...phone_options.filter((_x, i) => i !== index)],
      },
      async () => {
        this.handlephoneOptionValidation();
      },
    );
  };

  handleAddPhoneOptions = () => {
    console.log("handleAddPhoneOptions called::---");
    const { phone_options } = this.state;

    this.setState(
      {
        phone_options: [...phone_options, { country_code: "+1", phone: "" }],
      },
      async () => {
        this.handlephoneOptionValidation();
      },
    );
  };

  handlephoneOptionValidation = () => {
    const { email, first_name, last_name, suite_id, phone, password } = this.state;

    const isEmailValidate = isEmailValid(email);
    const high_security = this.props.property.high_security;
    let passwordValue = true;

    let isValidPhone = true;
    if ((phone && phone.length >= 11) || (phone && phone.length < 10)) {
      this.setState({
        phoneErrorMessage: "Please enter valid phone number",
      });
      isValidPhone = false;
    } else {
      this.setState({
        phoneErrorMessage: "",
      });
    }

    if (high_security) {
      const errMsg = PasswordValidation(password);
      this.setState({
        passwordErrorMsg: errMsg,
      });
      if (errMsg) {
        passwordValue = false;
        this.setState({
          passwordErrorMsg: errMsg,
        });
      } else {
        this.setState({
          passwordErrorMsg: "",
        });
      }
    }
    this.checkAllValidations(isEmailValidate, first_name, last_name, suite_id, phone.length, isValidPhone, passwordValue);
  };

  isMultiplePhoneValid = () => {
    const { phone_options, phone, country_code } = this.state;
    const phoneListCount = {};
    const phoneList = phone_options.map((item) => {
      return item.country_code + item.phone;
    });
    const phoneNumberCountry_code = country_code === "" ? "+1" : country_code;
    const phoneNumber = phone === "" ? "" : phone;
    phoneList.push(phoneNumberCountry_code + phoneNumber);
    phoneList.forEach(function (x) {
      phoneListCount[x] = (phoneListCount[x] || 0) + 1;
    });
    const phoneListStatus = Object.keys(phoneListCount).filter((item) => phoneListCount[item] > 1);
    const validPhone = phone_options.map((options) => {
      if (options.phone.trim().length > 10 || options.phone.trim().length < 10 || options.phone.trim().length === 0 || phoneListStatus.length) {
        return !(options.phone.trim().length > 10 || options.phone.trim().length < 10 || options.phone.trim().length === 0 || phoneListStatus.length);
      }
      return true;
    });
    return !validPhone.includes(false);
  };

  handleChange = ({ target }) => {
    const prevstate = this.state.phone;
    const prevCost = this.state.cost;
    const prevValidationTime = this.state.validationTimeInHours;
    this.setState({ [target.name]: target.value }, async () => {
      const { suite_id, phone, email, first_name, last_name, property, defalutLanguage } = this.state;
      let testemail = email;
      const high_security = this.props.property.high_security;
      let passwordValue = 1;
      switch (target.name) {
        case "suite_id":
          {
            const SuiteID = validations(target.name.replace(/\s+/g, "").trim(), "suite_id", this.state.suite_id);
            const suiteIdTobePassed = removeSpecialChar(SuiteID.value.replace(/\s+/g, "").trim());
            testemail = SuiteID.validator ? `${suiteIdTobePassed}@${property?.domain}.com` : "";
            if (target.value.trim().length) {
              this.setState({
                suite_id: suiteIdTobePassed,
                validSuiteId: SuiteID.validator,
                email: testemail,
              });
            }
          }
          break;
        case "first_name":
          {
            const FirstName = validations(target.name, "first_name", this.state.first_name);
            this.setState({
              first_name: removeSpecialChar(FirstName.value),
              validFirstName: FirstName.validator,
            });
          }
          break;
        case "last_name":
          {
            const LastName = validations(target.name, "last_name", this.state.last_name);
            this.setState({
              last_name: removeSpecialChar(LastName.value),
              validLastName: LastName.validator,
            });
          }
          break;
        case "phone": {
          if (phone.trim().length) {
            if (regExForPhone.test(target.value)) {
              this.setState({ phone: target.value });
            } else {
              this.setState({ phone: prevstate });
            }
          } else {
            const Phone = validations(target.name, "phone", this.state.phone);
            this.setState({
              phone: Phone.value,
              validPhone: Phone.validator,
            });
          }
          break;
        }
        case "defalutLanguage": {
          this.setState({
            defalutLanguage: defalutLanguage,
          });
          break;
        }
        case "password": {
          if (high_security) {
            const errMsg = PasswordValidation(target.value);
            this.setState({
              passwordErrorMsg: errMsg,
            });
            if (errMsg) {
              passwordValue = false;
              this.setState({
                passwordErrorMsg: errMsg,
              });
            } else {
              passwordValue = true;
              this.setState({
                passwordErrorMsg: "",
              });
            }
          }
          break;
        }
        case "country_code": {
          this.setState({
            country_code: target.value.replace(/[a-zA-Z]/, ""),
          });
          break;
        }
        case "email":
          {
            const Email = validations(target.name, "email", this.state.email);
            this.setState({
              email: Email.value,
              validEmail: Email.validator,
            });
          }
          break;
        case "cost":
          {
            if (target?.value && /^\d{1,2}(\.\d{0,2}|\.\d{0,2})?$/.test(target.value)) {
              this.setState({
                cost: Number(target.value),
                costErrorMessage: "", // Clear any previous error message
                isFormValid: true,
              });
            } else {
              if (target.value === "") {
                this.setState({
                  cost: 0,
                  costErrorMessage: "Cost is required",
                });
              } else {
                this.setState({
                  cost: prevCost,
                  costErrorMessage: "Please enter a valid numeric value",
                });
              }
              this.setState({
                isFormValid: false,
              });
            }
          }
          break;
        case "validationTimeInHours":
          {
            if (target?.value && /^(1?\d|2[0-4])(\.\d{0,2})?$/.test(target.value)) {
              this.setState({
                validationTimeInHours: target.value,
                validationTimeInHoursErrorMessage: "", // Clear any previous error message
                isFormValid: true,
              });
            } else {
              if (target.value === "") {
                this.setState({
                  validationTimeInHours: "",
                  validationTimeInHoursErrorMessage: "Time is required",
                });
              } else {
                this.setState({
                  validationTimeInHours: prevValidationTime,
                  validationTimeInHoursErrorMessage: "The value must be a numeric figure and should not exceed 24.",
                });
              }
              this.setState({
                isFormValid: false,
              });
            }
          }
          break;
        case "validationTimeType":
          {
            this.setState({
              validationTimeInHoursErrorMessage: "",
              isSetMaxTime: false,
            });
          }
          break;
        default:
          break;
      }

      this.setState({
        email: testemail,
      });
      const isEmailValidate = isEmailValid(testemail);

      this.setState({ wrongEmail: !isEmailValidate }, () => {
        let isValidPhone = true;
        if ((phone && phone.length >= 11) || (phone && phone.length < 10)) {
          this.setState({
            phoneErrorMessage: "Please enter valid phone number",
          });
          isValidPhone = false;
        } else {
          this.setState({
            phoneErrorMessage: "",
          });
        }
        this.checkAllValidations(isEmailValidate, first_name, last_name, suite_id, this.state.phone.length, isValidPhone, passwordValue);
      });
    });
  };

  handleValidationTimeChange = (time) => {
    this.setState({ selectedTime: time }, () => {
      const { phone, email, first_name, last_name, suite_id, passwordValue } = this.state;
      const isEmailValidate = isEmailValid(email);
      let isValidPhone = true;
      if ((phone && phone.length >= 11) || (phone && phone.length < 10)) {
        this.setState({
          phoneErrorMessage: "Please enter valid phone number",
        });
        isValidPhone = false;
      }
      this.checkAllValidations(isEmailValidate, first_name, last_name, suite_id, phone.length, isValidPhone, passwordValue);
    });
  };

  handleCheckBox = ({ target }) => {
    const { phone, email, first_name, last_name, suite_id } = this.state;
    const isEmailValidate = isEmailValid(email);
    let isValidPhone = true;
    let passwordValue = true;
    if ((phone && phone.length >= 11) || (phone && phone.length < 10)) {
      this.setState({
        phoneErrorMessage: "Please enter valid phone number",
      });
      isValidPhone = false;
    }
    this.setState({ [target.name]: target.checked }, () => {
      if (target.name === "allow_quick_permit") {
        if (!target.checked) {
          this.setState({
            validationTimeInHours: "",
            cost: 0,
            selectedTime: "",
          });
        }
      }
      this.checkAllValidations(isEmailValidate, first_name, last_name, suite_id, phone.length, isValidPhone, passwordValue);
    });
  };

  handlePermitCheckBox = ({ target }) => {
    this.setState({ [target.name]: target.checked, permits_allowed_type: target.name }, () => {
      if (target.name === "unlimited_credits") {
        this.setState({
          do_not_allow_visitor: false,
          default_credits: false,
        });
      } else if (target.name === "default_credits") {
        this.setState({
          do_not_allow_visitor: false,
          unlimited_credits: false,
        });
      } else if (target.name === "do_not_allow_visitor") {
        this.setState({
          default_credits: false,
          unlimited_credits: false,
        });
      }
    });
  };

  handleSubmit = () => {
    const userInfo = this.props.UserProfileDetails;
    this.setState({ isFormValid: false }, async () => {
      const {
        suite_id,
        parking_spot,
        first_name,
        last_name,
        phone,
        email,
        do_not_allow_login,
        password,
        company_id,
        property_id,
        country_code,
        phone_options,
        permits_allowed_type,
        defalutLanguage,
        invoice_visitor_credits,
        hst_applied,
        allow_quick_permit,
        validationTimeInHours,
        selectedTime,
        validationTimeType,
        cost,
      } = this.state;

      const obj = {
        ...userInfo,
        suite_id,
        parking_spot,
        first_name,
        last_name,
        phone,
        email,
        do_not_allow_login,
        password,
        level: 3,
        user: email,
        company_id,
        active: 0,
        permits_allowed: "4",
        user_type: "unit",
        property_id: property_id,
        country_code,
        phone_options,
        permits_allowed_type,
        defalutLanguage,
        invoice_visitor_credits,
        hst_applied,
        allow_quick_permit,
        validation_time_value: validationTimeType === "HOURS" ? validationTimeInHours : selectedTime,
        validationTimeType,
        cost,
      };
      if (suite_id.trim().length && first_name.trim().length && last_name.trim().length && phone.trim().length && email.trim().length) {
        await this.props.add_resident(obj);
        if (this.props.api_response) {
          this.setState({
            show_alert: true,
            api_success_message: "Unit created successfully",
          });
          setTimeout(() => {
            this.setState({ show_alert: false });
            routing_using_history("/units");
          }, 4000);
        } else {
          this.setState({
            show_error: true,
            api_error_message: this.props.api_error_message,
          });
          setTimeout(() => {
            this.setState({ show_error: false });
          }, 4000);
        }
      }
    });
  };

  checkAllValidations = (isEmailValidate, first_name, last_name, suite_id, phoneLength, isValidPhone, passwordValue) => {
    let isValidationReq = true;
    let isCostValid = true;
    if (this.state.allow_quick_permit) {
      if (this.state.validationTimeType === "HOURS") {
        isValidationReq = !!this.state.validationTimeInHours && /^\d{1,2}(\.\d{0,2}|\.\d{0,2})?$/.test(this.state.validationTimeInHours);
        this.setState({
          selectedTime: "",
        });
      } else {
        this.setState({
          validationTimeInHours: "",
        });
        isValidationReq = !!this.state.selectedTime;
      }
      if (!this.state.cost) {
        isCostValid = false;
      }
      if (this.state.cost && !/^\d{1,2}(\.\d{0,2}|\.\d{0,2})?$/.test(this.state.cost)) {
        isCostValid = false;
      }
    }

    if (
      isEmailValidate &&
      first_name &&
      last_name &&
      suite_id &&
      phoneLength &&
      isValidPhone &&
      passwordValue &&
      this.isMultiplePhoneValid() &&
      isValidationReq &&
      isCostValid
    ) {
      this.setState({ isFormValid: true });
    } else {
      this.setState({ isFormValid: false });
    }
  };

  handleBack = () => {
    routing_using_history("/units");
  };

  handleMaxTime = (e) => {
    if (!this.state.isSetMaxTime) {
      this.setState({
        selectedTime: new Date(new Date().setHours(23, 59)),
        isSetMaxTime: !this.state.isSetMaxTime,
      });
    } else {
      this.setState({
        selectedTime: "",
        isSetMaxTime: !this.state.isSetMaxTime,
      });
    }
  };

  render() {
    const {
      suite_id,
      parking_spot,
      first_name,
      last_name,
      phone,
      email,
      do_not_allow_login,
      password,
      wrongEmail,
      validSuiteId,
      validParkingSpot,
      validFirstName,
      validLastName,
      validPhone,
      validEmail,
      isFormValid,
      show_alert,
      api_success_message,
      api_error_message,
      show_error,
      passwordErrorMsg,
      passwordVisible,
      country_code,
      country_code_list,
      phone_options,
      phoneErrorMessage,
      defalutLanguage,
      invoice_visitor_credits,
      hst_applied,
      allow_quick_permit,
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <Card.Title as="h4">Add New Units</Card.Title>
                  <p className="card-category">Fill the Form to add new Units</p>
                </Card.Header>
                {show_alert && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
                {show_error && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}
                <Card.Body className="pos-rel-overflow-hide">
                  <Row>
                    <Col md="4" sm="12" className="">
                      <Form.Group>
                        <label>
                          Unit Id
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="2100"
                          type="text"
                          name="suite_id"
                          value={suite_id}
                          onChange={this.handleChange}
                          autoComplete="off"
                          required
                        />
                        {validation_message(suite_id, "unit Id", validSuiteId)}
                      </Form.Group>
                    </Col>
                    <Col md="4" sm="12">
                      <Form.Group>
                        <label>Notes / Reference</label>
                        <Form.Control
                          placeholder="Unit notes"
                          type="text"
                          name="parking_spot"
                          value={parking_spot}
                          onChange={this.handleChange}
                          autoComplete="new-notes"
                          required
                        />
                        {validation_message(parking_spot, "Parking spot", validParkingSpot)}
                      </Form.Group>
                    </Col>
                    <Col md="4" sm="12">
                      <Form.Group>
                        <label>Password</label>
                        <div className="input-mask-btn">
                          <Form.Control
                            type={passwordVisible ? "text" : "password"}
                            placeholder="Password"
                            name="password"
                            value={password}
                            autoComplete="new-password"
                            onChange={this.handleChange}
                          />
                          <button className="mask-password" type="button" onClick={this.TogglePasswordVisibility}>
                            <i className={`fas ${passwordVisible ? "fa-eye" : "fa-eye-slash"}`} />
                          </button>
                        </div>
                        <div style={{ color: "red", fontSize: 13 }} dangerouslySetInnerHTML={{ __html: passwordErrorMsg }}></div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" sm="6" className="">
                      <Form.Group>
                        <label>
                          First Name
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="John"
                          type="text"
                          name="first_name"
                          value={first_name}
                          onChange={this.handleChange}
                          autoComplete="off"
                          required
                        />
                        {validation_message(first_name, "First Name", validFirstName)}
                      </Form.Group>
                    </Col>
                    <Col md="4" sm="6">
                      <Form.Group>
                        <label>
                          Last Name
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="Smith"
                          type="text"
                          name="last_name"
                          value={last_name}
                          onChange={this.handleChange}
                          autoComplete="off"
                          required
                        />
                        {validation_message(last_name, "Last Name", validLastName)}
                      </Form.Group>
                    </Col>

                    <Col md="4" sm="12" className="">
                      <Form.Group>
                        <label>
                          Email Address
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="example@gmail.com"
                          type="text"
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                          autoComplete="off"
                          required
                        />
                        {email_validation_message(email, validEmail, wrongEmail)}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="m-w-480px">
                    <Col className="col-auto">
                      <Form.Group>
                        <label>Country Code</label>
                        <Form.Control className="w-85px" as="select" name="country_code" onChange={this.handleChange} value={country_code} required>
                          {country_code_list ? (
                            <>
                              {(country_code_list ?? []).map((item) => (
                                <option key={item?.id} value={item.value}>
                                  {item.text}
                                </option>
                              ))}
                            </>
                          ) : null}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="col p-0">
                      <Form.Group>
                        <label>
                          Phone
                          <span className="asterisk">*</span>
                        </label>
                        <Form.Control
                          placeholder="2145602323"
                          type="text"
                          name="phone"
                          value={phone}
                          onChange={this.handleChange}
                          maxLength="10"
                          autoComplete="off"
                          required
                        />
                        {validation_message(phone, "Phone Number", validPhone)}
                        <span className="help-block">{this.state.phoneErrorMessage}</span>
                      </Form.Group>
                    </Col>
                    <Col className="col-auto">
                      <div>
                        <label>&nbsp;</label>
                      </div>
                      <Button
                        className="h-40px"
                        size="sm"
                        disabled={
                          phone_options?.length > 9 || phoneErrorMessage.trim().length !== 0 || phone.trim().length === 0 || !this.isMultiplePhoneValid()
                        }
                        onClick={this.handleAddPhoneOptions}
                      >
                        <i className="fas fa-plus-circle"></i>
                      </Button>
                    </Col>
                  </Row>
                  {phone_options &&
                    typeof phone_options === "object" &&
                    Array.isArray(phone_options) &&
                    phone_options.map((option, index) => {
                      return (
                        <Row key={option?.country_code} className="my-0 m-w-480px">
                          <Col className="col-auto">
                            <Form.Group>
                              <Form.Control
                                className="w-85px"
                                as="select"
                                name="country_code_options"
                                onChange={(e) => this.handlePhoneOptions(index, e.target.value, "country_code_options")}
                                value={option?.country_code}
                                required
                              >
                                {country_code_list ? (
                                  <>
                                    {(country_code_list ?? []).map((item) => (
                                      <option key={item?.id} value={item.value}>
                                        {item.text}
                                      </option>
                                    ))}
                                  </>
                                ) : null}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="col p-0">
                            <Form.Group className="p-0">
                              <Form.Control
                                placeholder="Phone Number"
                                type="text"
                                name="phone_number_options"
                                value={option?.phone}
                                onChange={(e) => this.handlePhoneOptions(index, e.target.value, "phone_number_options")}
                                maxLength="10"
                                autoComplete="off"
                              />
                            </Form.Group>
                          </Col>
                          <Col className="col-auto">
                            <Button className="h-40px" size="sm" onClick={() => this.removePhoneOption(index)}>
                              <i className="fas fa-minus"></i>
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                  <Row>
                    <Col md="4" sm="6">
                      <Form.Group>
                        <label>Default Language for Unit</label>
                        <span className="asterisk">*</span>
                        <Form.Control as="select" name="defalutLanguage" value={defalutLanguage} onChange={this.handleChange} autoComplete="off">
                          <option value="en">English</option>
                          <option value="fn">French</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="4" sm="6" className="mt-sm-4 mb-sm-0 my-2">
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name={"allow_quick_permit"}
                          checked={allow_quick_permit}
                          onChange={this.handleCheckBox}
                          className="custom-control-input"
                          id="do_not_allow_login1"
                        ></input>
                        <span>
                          <i></i>Allow Quick Permit
                        </span>
                      </label>
                    </Col>
                  </Row>
                  {this.state.allow_quick_permit && (
                    <Row>
                      <Col md="4" sm="6">
                        <Form.Group>
                          <label>Validation Type</label>
                          <span className="asterisk">*</span>
                          <Form.Control
                            as="select"
                            name="validationTimeType"
                            value={this.state.validationTimeType}
                            onChange={this.handleChange}
                            autoComplete="off"
                          >
                            <option value="HOURS">Hours</option>
                            <option value="UNTIL">Until</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="4" sm="6">
                        <Form.Group>
                          {this.state.validationTimeType === "HOURS" && (
                            <>
                              {" "}
                              <label>Validation Time</label>
                              <span className="asterisk">*</span>
                              <Form.Control
                                placeholder="2"
                                type="text"
                                name="validationTimeInHours"
                                value={this.state.validationTimeInHours}
                                onChange={this.handleChange}
                                autoComplete="off"
                                required
                              ></Form.Control>
                              <span className="help-block">{this.state.validationTimeInHoursErrorMessage}</span>
                            </>
                          )}
                          {this.state.validationTimeType === "UNTIL" && (
                            <Form.Group>
                              <div class="d-flex align-items-center">
                                <label>
                                  Validation Time
                                  <span className="asterisk">*</span>{" "}
                                </label>
                                <FormLabel className="ml-auto d-flex align-items-center text-nowrap">
                                  <OverlayTrigger
                                    delay={{ show: 250, hide: 400 }}
                                    placement={"top"}
                                    overlay={<Tooltip>When checked, it sets max_time to 23:59, indicating end of day.</Tooltip>}
                                  >
                                    <input type="checkbox" className="mr-1" checked={this.state.isSetMaxTime} onChange={(e) => this.handleMaxTime(e)}></input>
                                  </OverlayTrigger>{" "}
                                  <span>Max Time </span>{" "}
                                </FormLabel>
                              </div>
                              <DatePicker
                                className="form-control"
                                selected={this.state.selectedTime}
                                onChange={this.handleValidationTimeChange}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                minTime={new Date(new Date().setHours(0, 0, 0, 0))}
                                maxTime={new Date().setHours(23, 59)}
                              />
                            </Form.Group>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg="2" md="4" sm="6">
                        <Form.Group>
                          <label>Cost</label>
                          <span className="asterisk">*</span>
                          <Form.Control
                            placeholder="Cost"
                            type="text"
                            name="cost"
                            value={this.state?.cost || 0}
                            onChange={this.handleChange}
                            autoComplete="off"
                            required
                          />
                          <span className="help-block">{this.state.costErrorMessage}</span>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col lg="12">
                      <p className="blue my-3">
                        <span className="font-weight-bold">**Note:</span> The units will receive an email to set their password
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" className="mt-4">
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name={"do_not_allow_login"}
                          checked={do_not_allow_login}
                          onChange={this.handleCheckBox}
                          className="custom-control-input"
                          id="do_not_allow_login1"
                        ></input>
                        <span>
                          <i></i>Do not allow login
                        </span>
                      </label>
                    </Col>
                    <Col md="4" className="mt-4">
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name={"invoice_visitor_credits"}
                          checked={invoice_visitor_credits}
                          onChange={this.handleCheckBox}
                          className="custom-control-input"
                          id="invoice_visitor_credits1"
                        ></input>
                        <span>
                          <i></i>Invoice visitor credits
                        </span>
                      </label>
                    </Col>
                    <Col md="4" className="mt-4">
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name={"hst_applied"}
                          checked={hst_applied}
                          onChange={this.handleCheckBox}
                          className="custom-control-input"
                          id="hst_applied1"
                        ></input>
                        <span>
                          <i></i>HST Applied
                        </span>
                      </label>
                    </Col>
                  </Row>
                  <Form>
                    <Row>
                      <Col lg="4" md="4" sm="12" xs="12" className="d-flex align-items-center">
                        <Form.Check
                          inline
                          id="credit"
                          // label={`Don't allow visitors`}
                          type="radio"
                          name={"do_not_allow_visitor"}
                          checked={this.state.do_not_allow_visitor}
                          onChange={this.handlePermitCheckBox}
                        ></Form.Check>
                        <label class="text-black mt-2">Don&apos;t allow visitors</label>
                      </Col>
                      <Col lg="4" md="4" sm="12" xs="12" className="d-flex align-items-center">
                        <Form.Check
                          inline
                          type="radio"
                          name="default_credits"
                          // label="Default credits"
                          checked={this.state.default_credits}
                          onChange={this.handlePermitCheckBox}
                          id="credit1"
                        ></Form.Check>
                        <label class="text-black mt-2">Allow visitors per default site settings</label>
                      </Col>
                      <Col lg="4" md="4" sm="12" xs="12" className="d-flex align-items-center">
                        <Form.Check
                          inline
                          type="radio"
                          // label="Unlimited credits"
                          name={"unlimited_credits"}
                          checked={this.state.unlimited_credits}
                          onChange={this.handlePermitCheckBox}
                          id="credit2"
                        ></Form.Check>
                        <label class="text-black mt-2">Unlimited visitors</label>
                      </Col>
                    </Row>
                  </Form>
                  <Row>
                    <Col lg="12" className="text-right">
                      <Button className="btn-fill" type="button" variant="danger" onClick={this.handleBack}>
                        Back
                      </Button>
                      <Button className="btn-fill ml-2" type="button" variant="info" disabled={!isFormValid} onClick={this.handleSubmit}>
                        Add Units
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, Logindetails }) => {
  const { company_id, property_id, property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  return {
    company_id,
    property_id,
    api_response,
    api_error_message,
    api_success_message,
    property,
    main_body,
  };
};

const mapStateToDispatch = {
  add_resident: ResidentsActions.add_resident,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(AddResidents);

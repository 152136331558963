import React from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import * as SuperAdminAction from "../../Actions/SuperAdminAction/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomContentAlert, { navigationButtons } from "../helper";
import _ from "lodash";
import t from "../../Actions/APIResponseActions/type";
import Loader from "../Loader/Loader";

const Permissions = () => {
  const [pages, setPages] = React.useState();
  const [subPages, setSubPages] = React.useState();
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [subPagesDisability, setSubPagesDisability] = React.useState(false);
  const [propertyId, setPropertyId] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const addActionRef = React.useRef(null);
  const dispatch = useDispatch();
  const properties = useSelector((state) => state.SuperAdminDetails.getAllProperties);

  React.useEffect(() => {
    const propertyId_ = window.document.location.href.split("?")[1];
    setPropertyId(propertyId_);
    const selectedPropertyObj = !_.isEmpty(properties) && Array.isArray(properties) && (properties ?? []).filter((item) => item.id === propertyId_);
    const selectedPropertyName = selectedPropertyObj?.length ? selectedPropertyObj[0]?.site_name : "";
    const selectedDomainName = selectedPropertyObj?.length ? selectedPropertyObj[0]?.domain : "";
    dispatch(SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName));
  }, [properties]);

  React.useEffect(() => {
    dispatch(SuperAdminAction.get_all_properties());
    if (propertyId) {
      dispatch(SuperAdminAction.get_permissions(propertyId, true));
    }
  }, [dispatch, propertyId]);

  const modulePermissions = useSelector((state) => state.SuperAdminDetails.modulePermissions);
  const apiMessage = useSelector((state) => state.APIResponseDetails.api_success_message);
  const { property_id: prop_id } = useSelector((state) => state.UserProfileDetails);

  React.useEffect(() => {
    const permissions = modulePermissions;
    const _pages = [];
    const _subPages = [];
    if (modulePermissions) {
      delete permissions.id;
      delete permissions.property_id;
      delete permissions.domain;
      delete permissions.module1;
      delete permissions.module2;
      delete permissions.module3;
      delete permissions.module4;
      delete permissions.module5;
      if (prop_id) {
        delete permissions.user_types;
      }
      for (const [key, value] of Object.entries(permissions)) {
        let isActive = false;
        if (value === 1) {
          isActive = true;
        }
        if (key.includes("report")) {
          _subPages.push({
            label: _.startCase(key),
            is_active: isActive,
            module_name: key.replace(" ", "_"),
          });
        } else {
          _pages.push({
            label: _.startCase(key),
            is_active: isActive,
            module_name: key.replace(" ", "_"),
          });
        }
      }
      _subPages.forEach(function (item, i) {
        if (item.label === "Report") {
          _subPages.splice(i, 1);
          _subPages.unshift(item);
          handleSubPageDisability(item);
        }
      });
    }
    setPages(_pages);
    setSubPages(_subPages);
  }, [modulePermissions]);

  const handleSubPageDisability = (item) => {
    if (!item.is_active) {
      setSubPagesDisability(true);
    }
  };

  const handleCheckBox = React.useCallback(
    (e) => {
      console.log("e", e.target.is_active);
      setPages(() => {
        pages.map((page) => {
          if (e.target.name === page.module_name) {
            page.is_active = !page.is_active;
          }
          return page;
        });
        return [...pages];
      });
      if (e.target.name === "report" && subPages.find((ele) => ele.module_name === "report").is_active) {
        setSubPages(() => {
          subPages.map((subPage) => {
            if (subPage.module_name === "report") {
              subPage.is_active = !subPage.is_active;
            }
            return subPage;
          });
          return [...subPages];
        });
        setSubPagesDisability(true);
        // } else {
        //   setSubPages(() => {
        //     subPages.map((subPage) => {
        //       if (e.target.name === subPage.module_name) {
        //         subPage.is_active = !subPage.is_active;
        //       }
        //       return subPage;
        //     });
        //     return [...subPages];
        //   });
        //   setSubPagesDisability(false);
        // }
      } else {
        handleSubPages(e.target.name, subPages);
      }
      setIsDisabled(true);
    },
    [pages, subPages],
  );

  const handleSubPages = (targetName, _subPages) => {
    if (targetName === "report" && !_subPages.find((ele) => ele.module_name === "report").is_active) {
      setSubPagesDisability(false);
    }
    setSubPages(() => {
      _subPages.map((subPage) => {
        if (targetName === subPage.module_name) {
          subPage.is_active = !subPage.is_active;
        }
        return subPage;
      });
      return [..._subPages];
    });
  };

  const handleSave = React.useCallback(() => {
    setLoader(true);
    const obj = {};
    setValues(pages, obj);
    setValues(subPages, obj);
    const dataToBeSend = {
      property_id: propertyId,
      module_permissions: obj,
      old_data: modulePermissions,
    };
    dispatch(SuperAdminAction.set_permissions(dataToBeSend));
  }, [pages, dispatch, propertyId, modulePermissions]);

  const setValues = (data, obj) => {
    data.map((item) => {
      if (item.is_active) {
        obj[item.module_name] = 1;
      } else {
        obj[item.module_name] = 0;
      }
      return true;
    });
    return obj;
  };

  React.useEffect(() => {
    let timeout = null;
    if (apiMessage) {
      setLoader(false);
      setIsDisabled(false);
      timeout = setTimeout(() => {
        dispatch({
          type: t.API_RESPONSE_SUCCESS,
          payload: "",
        });
      }, 4000);
    }
    return () => {
      if (timeout !== null) clearTimeout(timeout);
    };
  }, [dispatch, apiMessage]);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Header>Site setup: Manage Site Permissions</Card.Header>

              <Card.Body>
                <Form.Group>
                  {!_.isEmpty(pages) &&
                    pages.map((page, Idx) => (
                      <div key={Idx}>
                        <label className="fancy-checkbox mt-2">
                          <input type="checkbox" name={page.module_name} checked={page.is_active} onChange={handleCheckBox} />
                          <span>
                            <i></i>
                            {page.label === "TICKET" ? "CITATION" : page.label}
                          </span>
                        </label>
                      </div>
                    ))}
                  {!_.isEmpty(subPages) &&
                    subPages.map((subPage) => (
                      <div key={subPage?.label} className={subPage.module_name !== "report" && "ml-5"}>
                        <label
                          className={subPage.module_name !== "report" && subPagesDisability ? "fancy-checkbox mt-2 disabled-field" : "fancy-checkbox mt-2"}
                        >
                          <input
                            type="checkbox"
                            name={subPage.module_name}
                            checked={subPage.is_active}
                            onChange={handleCheckBox}
                            disabled={subPage.module_name !== "report" && subPagesDisability}
                          />
                          <span>
                            <i></i>
                            {subPage.module_name === "report" && subPage.label + "s"}
                            {subPage.module_name !== "report" && subPage?.label?.substring(0, subPage?.label?.lastIndexOf(" "))}
                          </span>
                        </label>
                      </div>
                    ))}
                </Form.Group>
                {!loader && (
                  <>
                    <Button disabled={!isDisabled} onClick={handleSave}>
                      {"Update"}
                    </Button>
                    <Col lg="12" className="p-0">
                      {navigationButtons("/adduserform", "/citation-setup", propertyId, addActionRef, "Permission", null, null, null, () => {
                        handleSave();
                      })}
                    </Col>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {loader && <Loader />}
      {((apiMessage !== "" && apiMessage !== undefined) || !_.isEmpty(apiMessage)) && (
        <CustomContentAlert delay={4000} message={apiMessage} className={apiMessage ? "toast-success" : ""} />
      )}
    </div>
  );
};

export default Permissions;

import React from "react";
import { Col, Container, Row, Card, InputGroup } from "react-bootstrap";
import MaterialTable from "material-table";
import CustomContentAlert, { navigationButtons } from "../helper";
import { useSelector, useDispatch } from "react-redux";
import * as SuperAdminAction from "../../Actions/SuperAdminAction/actions";

const PrintedCitationSetup = () => {
  const addActionRef = React.useRef(null);
  const dispatch = useDispatch();
  const [propertyId, setProertyId] = React.useState("");
  const searchParams = window.document.location.href.split("?")[1];
  const columns = React.useRef([]);
  const ShowCell = React.useCallback((row, _, column) => {
    let data = "";
    const moment = require("moment-timezone");
    switch (column.title) {
      case "Citation Height":
        data = row.id;
        break;
      case "Citation Width":
        data = moment(row.created_at).format("lll");
        break;
      case "Citation Header":
        data =
          row.users.first_name && row.users.last_name
            ? `${row.users.first_name} ${row.users.last_name}`
            : "";
        break;
      case "Citation Footer":
        data = row.lpn;
        break;
      case "Selected":
        data = (
          <InputGroup>
            <InputGroup.Check
              type="switch"
              name="visitor_lot"
              checked={true}
              aria-label="visitor_lot"
            />
          </InputGroup>
        );
        break;
      // case "rotationSelected":
      // data = (
      //   <InputGroup>
      //     <InputGroup.Check
      //       type="switch"
      //       name="visitor_lot"
      //       checked={true}
      //       aria-label="visitor_lot"
      //     />
      //   </InputGroup>
      // );

      default:
        break;
    }
    return <CellDisplay data={data} />;
  }, []);
  React.useEffect(() => {
    dispatch(SuperAdminAction.get_all_ticket_templates(searchParams));
  }, [dispatch]);

  const CheckValidation = React.useCallback((rowData, key, name) => {
    const regex = /^\d*$/;
    if (rowData) {
      if (rowData[key] === "") {
        return `${name} cann't be empty`;
      } else if (rowData[key] && !regex.test(rowData[key])) {
        return `Please enter valid ${name}`;
      }
    }
    return "";
  }, []);

  React.useEffect(() => {
    columns.current = [
      {
        title: "Citation Height (mm)",
        field: "page_height",
        cell: ShowCell,
        type: "numeric",
        validate: (rowData) =>
          CheckValidation(rowData, "page_height", "Height"),
      },
      {
        title: "Citation Width (mm)",
        field: "page_width",
        type: "numeric",
        validate: (rowData) => CheckValidation(rowData, "page_width", "Width"),
      },
      {
        title: "Citation Header Height (mm)",
        field: "page_header_height",
        type: "numeric",
        validate: (rowData) =>
          CheckValidation(rowData, "page_header_height", "Header height"),
      },
      {
        title: "Citation Footer Height (mm)",
        field: "page_footer_height",
        type: "numeric",
        validate: (rowData) =>
          CheckValidation(rowData, "page_footer_height", "Footer height"),
      },
      // {
      //   title: "Selected",
      //   field: "is_selected",
      //   type: "boolean",
      // },
      {
        title: "Rotation(180deg)",
        field: "is_rotationselected",
        type: "boolean",
      },
    ];
  }, []);
  const ticketTemplates = useSelector(
    (state) => state.SuperAdminDetails.ticketTemplates
  );

  const properties = useSelector(
    (state) => state.SuperAdminDetails.getAllProperties
  );

  const api_success_message = useSelector(
    (state) => state.APIResponseDetails.api_success_message
  );
  React.useEffect(() => {
    dispatch(SuperAdminAction.get_all_properties());
  }, [dispatch]);

  /* To set top header */
  React.useEffect(() => {
    setProertyId(searchParams);
    const selectedPropertyObj = properties.filter(
      (item) => item.id === searchParams
    );
    const selectedPropertyName =
      selectedPropertyObj && selectedPropertyObj.length
        ? selectedPropertyObj[0].site_name
        : "";
    const selectedDomainName =
      selectedPropertyObj && selectedPropertyObj.length
        ? selectedPropertyObj[0].domain
        : "";
    dispatch(
      SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName)
    );
  }, [properties]);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header className="d-flex align-items-start justify-content-between">
                <div>
                  <Card.Title as="h4">Manage Citations Template</Card.Title>
                  <p className="card-category">
                    Fill out below form to manage citations template.{" "}
                  </p>
                </div>
              </Card.Header>
              <Card.Body>
                <MaterialTable
                  title=""
                  options={{
                    actionsColumnIndex: -1,
                    addRowPosition: "first",
                    draggable: false,
                    sorting: true,
                  }}
                  columns={columns.current}
                  data={ticketTemplates} // need to pass array containing template list
                  onRowClick={(rowData) => console.log("Row clicked....")}
                  onRowSelected={(rowData) =>
                    console.log("Row clionRowSelectedcked....")
                  }
                />
                <Col lg="12" className="p-0">
                  {navigationButtons(
                    "/citation-setup",
                    "/addlotform",
                    propertyId,
                    addActionRef,
                    "Citation Setup Template",
                    null,
                    null,
                    null
                  )}
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {api_success_message && (
        <CustomContentAlert
          delay={4000}
          message={api_success_message}
          className={"toast-success"}
        />
      )}
    </div>
  );
};

export default PrintedCitationSetup;
const CellDisplay = ({ data }) => {
  return <div>{data}</div>;
};

import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import DataTable from "react-data-table-component";
import { Form, Row, Col, Card, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as SuperAdminAction from "../../Actions/SuperAdminAction/actions";
import * as ReportsAction from "../../Actions/ReportsAction/action";
import PropertDetails from "../LandingPage/PropertySelection";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import Loader from "../Loader/Loader";
import { tableHeightScrollBars, findBySearch, scroolToTop } from "../helper";
import t from "../../Actions/ReportsAction/types";
import DatePicker from "react-datepicker";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";

const LengthOfStayReport = () => {
  const today = new Date();
  const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
  const dispatch = useDispatch();
  const paid_analysis_list = useSelector((state) => state.ReportsDetails.paidAnalysisList);
  const exportList = useSelector((exportState) => exportState.ReportsDetails.paidAnalysisExportList);
  const UserData = useSelector((userState) => userState.UserProfileDetails);
  const selectAllProperty = useSelector((propertyState) => propertyState.SuperAdminDetails.getAllProperties);
  const loader = useSelector((loader) => loader.ReportsDetails.loader);

  const [state, setState] = useState({
    startDate: new Date(datebeforeThirtyDays),
    endDate: new Date(),
    currentSummaryDate: null,
    selectedProperty: UserData.property.id,
    selectedPropertyObj: {},
    childcheckbox: true,
    timezone: UserData.timezone,
    exportValue: false,
    selectedDomainName: "",
    paidAnalysisListData: [],
    exportButtonDisable: true,
    finalTableHeight: 0,
    searchValue: "",
    paidAnalysisListCount: 0,
    startDateTime: "",
    endDateTime: "",
  });

  const [apiCalled, setApiCalled] = useState(false);

  useEffect(() => {
    const height = tableHeightScrollBars();
    dispatch(SuperAdminAction.get_all_properties());
    setState((prev) => ({
      ...prev,
      finalTableHeight: height,
    }));
  }, []);

  console.log("paid_analysis_list::::::::::", paid_analysis_list);

  useEffect(() => {
    if (state.searchedPaidAnalysisList) {
      setState((prev) => ({
        ...prev,
        paidAnalysisListCount: state.searchedPaidAnalysisList.length,
      }));
    }
  }, [state.searchedPaidAnalysisList]);

  useEffect(() => {
    const selectedProperty = selectAllProperty.length && selectAllProperty.find((item) => item.id === state.selectedProperty);
    const selectedPropertyName = UserData.property?.property_name;
    const selectedDomainName = UserData.property?.domain;
    if (selectedProperty) {
      setState((prev) => ({
        ...prev,
        selectedPropertyObj: {
          label: selectedProperty.site_name,
          value: selectedProperty.id,
        },
        selectedDomainName: selectedDomainName,
      }));
    }
    dispatch(SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName));
  }, [selectAllProperty]);

  useEffect(() => {
    const body = {
      property_id: state.selectedProperty,
      childcheckbox: state.childcheckbox,
      timezone: state.timezone,
      exportValue: state.exportValue,
      startDate: state.startDate,
      endDate: state.endDate,
      currentSummaryDate: state.currentSummaryDate,
    };
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    dispatch(ReportsAction.paidAnalysisList(body));
    GetDateRangeWithFormat(body);
    setApiCalled(true);
  }, []);

  const GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj);
    console.log("object:::::::::::::::::::::", pageobj, start_date_without_utc, end_date_without_utc);
    setState(() => ({
      ...state,
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    }));
  };

  useEffect(() => {
    if (paid_analysis_list && apiCalled === true) {
      setState((prev) => ({
        ...prev,
        paidAnalysisListData: paid_analysis_list.rows,
        paidAnalysisListCount: paid_analysis_list.count,
      }));
    }
  }, [paid_analysis_list, apiCalled]);

  useEffect(() => {
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `PAID-DURATION-ANALYSIS-${currentTimeDate}-${state.selectedDomainName}.xls`;
    if (apiCalled === true) {
      setApiCalled(false);
      saveAs(
        new Blob([exportList], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  }, [exportList]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const showLength = (row) => (
    <>
      {row?.length ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.length}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.length}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showDomainField = (row) => (
    <>
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement={"top"}
        overlay={<Tooltip id={`tooltip-qw`}>{row.length !== "Total" ? row?.domain : "-"}</Tooltip>}
      >
        <div className="text-nowrap text-truncate pr-2">{row.length !== "Total" ? row?.domain : "-"}</div>
      </OverlayTrigger>
    </>
  );

  const showFields = (name) => {
    return (
      <>
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{name}</Tooltip>}>
          <div className="text-nowrap text-truncate">{name}</div>
        </OverlayTrigger>
      </>
    );
  };

  const columns = [
    {
      name: "Domain",
      sortable: true,
      cell: (row) => showDomainField(row),
      selector: (row) => row?.domain,
    },
    {
      name: "Length",
      sortable: true,
      cell: (row) => showLength(row),
      selector: (row) => row.length,
    },
    {
      name: "Paid Cash",
      sortable: true,
      cell: (row) => showFields(row.paid_cash),
      selector: (row) => row?.paid_cash,
    },
    {
      name: "Paid Card",
      sortable: true,
      cell: (row) => showFields(row.paid_card),
      selector: (row) => row.paid_card,
    },
    {
      name: "Total",
      sortable: true,
      cell: (row) => showFields(row.total),
      selector: (row) => row.total,
    },
    {
      name: "Used PDC",
      sortable: true,
      cell: (row) => showFields(row.used_PDC),
      selector: (row) => row.used_PDC,
    },
  ];

  const dataTableHandle = (data) => {
    return (
      <DataTable
        data={state.searchValue?.length ? state.searchedPaidAnalysisList : data}
        columns={columns}
        customStyles={customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={state.paidAnalysisListCount}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  const handleSearchChange = async ({ target }) => {
    const val = target?.value?.toString().trim();

    setState((prev) => ({ ...prev, searchValue: val }));
    const columnList = ["domain", "length", "paid_card", "paid_cash", "total", "used_PDC"];
    if (val?.length) {
      if (!state.findMore) {
        setState((prev) => ({
          ...prev,
          searchedPaidAnalysisList: findBySearch(val, state.paidAnalysisListData, state.paidAnalysisListData, columnList, state.timezone),
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        count: paid_analysis_list.count,
        searchedPaidAnalysisList: [],
        paidAnalysisListData: paid_analysis_list.rows,
      }));
    }
  };

  const handleExport = async () => {
    setState({ ...state, exportValue: true });
    const { selectedProperty, childcheckbox, timezone } = state;
    const pageobj = {
      childcheckbox,
      timezone,
      exportValue: true,
      startDate: state.startDate,
      endDate: state.endDate,
      currentSummaryDate: state.currentSummaryDate,
    };
    if (Object.keys(state.selectedPropertyObj).length) {
      pageobj.property_id = state.selectedPropertyObj.value;
    } else {
      pageobj.property_id = selectedProperty;
    }
    setApiCalled(true);
    dispatch(ReportsAction.paidAnalysisList(pageobj));
    GetDateRangeWithFormat(pageobj);
  };

  const resetAll = async () => {
    setState((prev) => ({
      ...prev,
      currentSummaryDate: null,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      selectedProperty: UserData.property.id,
      selectedPropertyObj: {
        label: UserData.property.property_name,
        value: UserData.property.id,
      },
      childcheckbox: true,
    }));
  };

  const handleSearch = () => {
    const { childcheckbox, selectedPropertyObj, selectedProperty, timezone, startDate, endDate, currentSummaryDate } = state;
    const pageobj = {
      childcheckbox,
      timezone,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.property_id = selectedPropertyObj.value;
    } else {
      pageobj.property_id = selectedProperty;
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    } else if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    dispatch(ReportsAction.paidAnalysisList(pageobj));
    GetDateRangeWithFormat(pageobj);
    document.body.classList.remove("foc-open");
  };

  const hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  const handleCheckBox = () => {
    setState((prev) => ({
      ...prev,
      childcheckbox: !state.childcheckbox,
    }));
  };

  const changeProperty = (event) => {
    setState((prev) => ({
      ...prev,
      selectedProperty: event.value,
      selectedPropertyObj: event,
    }));
  };

  const handleCurrentDate = (date) => {
    setState((prev) => ({
      ...prev,
      currentSummaryDate: date,
      startDate: null,
      endDate: null,
    }));
  };

  const handleChangeStartingDates = (date) => {
    const dt = new Date(date);
    const getEndDate = new Date(dt.setMonth(dt.getMonth() + 3));
    let selectEndDate;
    if (getEndDate > new Date()) {
      selectEndDate = new Date();
    } else {
      selectEndDate = getEndDate;
    }
    setState((prev) => ({
      ...prev,
      currentSummaryDate: null,
      startDate: date,
      endDate: selectEndDate,
    }));
  };

  const handleChangeEndDates = (date) => {
    setState((prev) => ({
      ...prev,
      currentSummaryDate: null,
      endDate: date,
    }));
  };

  return (
    <div className="container-fluid">
      <Row>
        <Col xs="12">
          <Card className="mb-0">
            <Card.Body className="pos-rel-overflow-hide">
              <>
                <div className="sidebarFilter">
                  <Card className="contact-container mb-0">
                    <Card.Header>
                      <div className="d-flex align-items-center justify-content-between">
                        <Card.Title as="h4">Length Of Stay Report</Card.Title>
                        <a
                          onClick={hideSlider}
                          href="javascript:void(0);"
                          className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </a>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md="12">
                          <div className="mb-2">
                            <label className="fancy-checkbox">
                              <input
                                type="checkbox"
                                name={"childcheckbox"}
                                checked={state.childcheckbox}
                                onChange={handleCheckBox}
                                className="custom-control-input"
                                id="childcheckbox1"
                              ></input>
                              <span>
                                <i></i>Include Children Sites
                              </span>
                            </label>
                          </div>
                        </Col>
                        <Col md="12">
                          <PropertDetails selectedPropertyObj={state.selectedPropertyObj} changeProperty={changeProperty.bind(this)} />
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <label className="mb-0 mr-2">Specific Date:</label>
                            <DatePicker
                              className="form-control"
                              placeholderText="Select Date"
                              selected={state.currentSummaryDate}
                              value={state.currentSummaryDate}
                              disabled={state.loader}
                              selectsStart
                              timeInputLabel="Time:"
                              onChange={handleCurrentDate}
                              dateFormat="MM/dd/yyyy"
                            />
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <label className="mb-0 mr-2">Start Date(Date Range):</label>
                            <DatePicker
                              className="form-control"
                              placeholderText="Select Start Date"
                              selected={state.startDate}
                              defaultHour={24}
                              value={state.startDate}
                              disabled={state.loader}
                              selectsStart
                              timeInputLabel="Time:"
                              onChange={handleChangeStartingDates}
                              dateFormat="MM/dd/yyyy"
                              startDate={state.startDate}
                              endDate={state.endDate}
                              maxDate={new Date()}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <label className="mb-0 mr-2"> End Date(Date Range):</label>
                            <DatePicker
                              className="form-control"
                              placeholderText="Select End Date"
                              selected={state.endDate}
                              disabled={state.loader}
                              value={state.endDate}
                              selectsEnd
                              timeInputLabel="Time:"
                              onChange={handleChangeEndDates}
                              dateFormat="MM/dd/yyyy"
                              startDate={state.startDate}
                              endDate={state.endDate}
                              minDate={state.startDate}
                              maxDate={new Date()}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer className="text-right justify-content-between d-flex">
                      <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={resetAll} disabled={loader}>
                        Reset
                      </Button>
                      <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={handleSearch} disabled={loader}>
                        Search
                      </Button>
                    </Card.Footer>
                  </Card>
                </div>
                <header>
                  <div className="active-permit-header sm-flex-column sm-align-items-start">
                    <h5 className="mb-2 mb-md-0">Length Of Stay Report</h5>
                    <div className="filter-button d-flex flex-wrap">
                      <div className="d-flex align-items-center search-mb-0">
                        <DatatableHeader className="mt-0 mb-0" searchValue={state.searchValue} HandleChange={handleSearchChange} />
                      </div>
                      <div className="ml-2">
                        <Button
                          variant="info"
                          className="btn-fill btn-sm"
                          onClick={handleExport}
                          disabled={state.paidAnalysisListData?.length > 0 ? 0 : 1}
                          size="medium"
                        >
                          Export
                        </Button>
                      </div>
                    </div>
                  </div>
                </header>
              </>
              {loader ? (
                <Loader />
              ) : (
                <>
                  {state.paidAnalysisListData && (
                    <>
                      <div className="citation-list-table">{dataTableHandle(state.paidAnalysisListData)}</div>
                      <DateRangeAndAmount
                        ReportList={state.paidAnalysisListData?.rows}
                        startDateTime={state.startDateTime}
                        endDateTime={state.endDateTime}
                        amountName="total"
                      />
                    </>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LengthOfStayReport;

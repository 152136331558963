import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Spinner, FormLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import t from "../../Actions/ValidationCouponActions/type";
import { getDiscountRate } from "../../Actions/ValidationCouponActions/actions";
import { get_all_lots, setDomainHeader } from "../../Actions/SuperAdminAction/actions";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import CustomContentAlert from "../helper";

function CheckCouponValidity(props) {
  const getPropertyData = useSelector((state) => state.UserProfileDetails.property);
  const AllLots = useSelector((state) => state.SuperAdminDetails.lots);
  const GetParkingDiscountData = useSelector((state) => state.ValidationDetails.GetParkingDiscountData);
  const loader = useSelector((state) => state.ValidationDetails.loader);
  const UserData = useSelector((userState) => userState.UserProfileDetails);
  const apiResponse = useSelector(getAPIResponse);
  const [apiCalled, setApiCalled] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [dateError, setDateError] = useState("");
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 30);
  const date = new Date();
  date.setHours(date.getHours() + 1);
  const [state, setState] = useState({
    startDate: new Date(),
    coupon_code: "",
    endDate: date,
    isSetMaxTime: 0,
    maxEndDate: maxDate,
    lot_id: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = ({ target }) => {
    setState((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  useEffect(() => {
    const selectedPropertyName = UserData.property?.property_name;
    const selectedDomainName = UserData.property?.domain;
    dispatch(setDomainHeader(selectedPropertyName, selectedDomainName));
  }, [UserData]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_all_lots(getPropertyData?.id));
    dispatch(getDiscountRate({}));
  }, []);

  const handleMaxTime = () => {
    if (!state.isSetMaxTime) {
      const endDate = moment(state.endDate).set({ hour: 23, minute: 59, second: 59 });
      setState((prevState) => ({
        ...prevState,
        endDate: new Date(endDate),
      }));
    }
    setState((prevState) => ({
      ...prevState,
      isSetMaxTime: !state.isSetMaxTime,
    }));
  };

  const handleChangeStartingDates = (date) => {
    const selectedStartDate = moment(date);
    const selectedStartDateFormatted = selectedStartDate.format("YYYY-MM-DD");
    const stateSelectedStartDateFormatted = moment(state.startDate).format("YYYY-MM-DD");
    if (selectedStartDateFormatted !== stateSelectedStartDateFormatted) {
      selectedStartDate.startOf("day");
    }

    let endDate = "";
    if (state.isSetMaxTime) {
      endDate = moment(selectedStartDate).set({ hour: 23, minute: 59, second: 59 });
    } else {
      endDate = selectedStartDate.clone().add(1, "hour").toDate();
    }

    setState((prevState) => ({
      ...prevState,
      startDate: new Date(selectedStartDate),
      endDate: new Date(endDate),
    }));
  };

  const handleChangeEndDates = (date) => {
    let selectedEndDate = moment(date);
    if (state.isSetMaxTime) {
      selectedEndDate = moment(date).set({ hour: 23, minute: 59, second: 59 });
    }
    if (state.startDate && selectedEndDate.isAfter(state.startDate)) {
      setState((prevState) => ({
        ...prevState,
        endDate: new Date(selectedEndDate),
      }));
    } else {
      setDateError("Please select an end date and time that comes after the start date.");
      setTimeout(() => {
        setDateError("");
      }, 4000);
    }
  };

  useEffect(() => {
    const selectedEndDate = moment(state.startDate).clone().add(30, "days");
    setState((prevState) => ({
      ...prevState,
      maxEndDate: new Date(selectedEndDate),
    }));
    console.log("selectedEndDateaaa", new Date(selectedEndDate));
  }, [state.startDate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setApiCalled(true);
    const validationErrors = validateForm(state);
    if (Object.keys(validationErrors).length === 0) {
      // Make API request here using formData
      dispatch({
        type: t.SHOW_HIDE_LOADER,
        payload: true,
      });
      console.log("Form data:", state);
      setErrors({});
      const data = {
        lot_id: state.lot_id,
        startDate: state.startDate,
        endDate: state.endDate,
        PDC: state.coupon_code,
      };
      dispatch(getDiscountRate(data));
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    // Field validation logic
    if (!data.lot_id) {
      errors.lot_id = "Lot is required";
    }
    if (!data.startDate) {
      errors.startDate = "Start Time is required";
    }
    if (!data.endDate) {
      errors.endDate = "Stop Time is required";
    }

    return errors;
  };

  const resetRes = () => {
    setApiCalled(true);
    let currentTime = new Date();
    currentTime.setHours(currentTime.getHours() + 1);
    setState((prevState) => ({
      ...prevState,
      lot_id: "",
      startDate: new Date(),
      coupon_code: "",
      endDate: currentTime,
      isSetMaxTime: 0,
      maxEndDate: null,
    }));
    setErrors({});
    const data = {};
    dispatch(getDiscountRate(data));
    setApiCalled(false);
  };

  useEffect(() => {
    if (apiCalled === true) {
      console.log("api message", apiResponse);
      setApiCalled(false);
      setIsFailed(apiResponse?.api_response === false);
      setTimeout(() => {
        setIsFailed(false);
      }, 3000);
    }
  }, [apiResponse]);

  return (
    <>
      <Container fluid className="manage-unit-list-page page-no-fliter">
        <Row>
          <Col md="12">
            <Card className="mb-0">
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <Card.Title as="h4">Calculate Parking Rate & Discount</Card.Title>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col lg="6">
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>
                        Parking Lot
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="lot_id"
                        value={state.lot_id}
                        onChange={handleChange}
                        disabled={AllLots.length < 1}
                        placeholder="Select Lot"
                      >
                        {AllLots?.length ? (
                          <>
                            <option value="">Select Lot</option>
                            {AllLots.map((item, index) => (
                              <option key={item.id} value={item.id}>
                                {item.alias}
                              </option>
                            ))}
                          </>
                        ) : null}
                      </Form.Control>
                      {errors.lot_id && <span className="help-block">{errors.lot_id}</span>}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="mb-0 mr-2">
                        Start Time
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <DatePicker
                        className="form-control"
                        placeholderText="Select Start Date"
                        selected={state.startDate}
                        value={state.startDate}
                        //  disabled={loader}
                        selectsStart
                        onChange={handleChangeStartingDates}
                        dateFormat="MM/dd/yyyy h:mm aa"
                        startDate={state.startDate}
                        endDate={state.endDate}
                        showTimeSelect
                      />
                      {errors.startDate && <span className="help-block">{errors.startDate}</span>}
                    </Form.Group>
                    <Form.Group>
                      <div class="d-flex align-items-center">
                        <Form.Label className="mb-0 mr-2">
                          Stop Time
                          <span className="asterisk">*</span>
                        </Form.Label>
                        <OverlayTrigger
                          delay={{ show: 250, hide: 400 }}
                          placement={"top"}
                          overlay={<Tooltip style={{ zIndex: "20000" }}>When checked, it sets Max Time to 23:59, indicating end of day.</Tooltip>}
                        >
                          <FormLabel className="ml-auto d-flex align-items-center text-nowrap">
                            <input type="checkbox" className="mr-1" checked={state.isSetMaxTime} onChange={handleMaxTime}></input>
                            <span>Max Time</span>{" "}
                          </FormLabel>
                        </OverlayTrigger>{" "}
                      </div>
                      <DatePicker
                        className="form-control"
                        placeholderText="Select End Date"
                        selected={state.endDate}
                        //  disabled={loader}
                        value={state.endDate}
                        selectsEnd
                        timeInputLabel="Time:"
                        onChange={handleChangeEndDates}
                        dateFormat="MM/dd/yyyy h:mm aa"
                        startDate={state.startDate}
                        endDate={state.endDate}
                        minDate={state.startDate}
                        maxDate={state.maxEndDate}
                        showTimeSelect
                      />
                      {errors.endDate && <span className="help-block">{errors.endDate}</span>}
                    </Form.Group>
                    <Form.Group>
                      <label>Parking Discount Coupon</label>
                      <Form.Control
                        placeholder="Enter Discount Code"
                        type="text"
                        name="coupon_code"
                        value={state.coupon_code}
                        onChange={handleChange}
                        autoComplete="off"
                        required
                        //  maxLength={8}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="6" lg="8" md="8" sm="12" className="d-flex align-items-center">
                    {loader ? (
                      <div style={{ width: "126px" }} className="d-flex justify-content-center">
                        <Spinner animation="border" className="me-2" variant="info" />
                      </div>
                    ) : (
                      <Button onClick={handleSubmit} className="mb-2">
                        Check Rate
                      </Button>
                    )}
                    <Button onClick={resetRes} className="mb-2 ml-3">
                      Reset
                    </Button>
                  </Col>
                </Row>
                {Object.keys(GetParkingDiscountData).length ? (
                  <>
                    <table>
                      <tr>
                        <td>
                          <p className="mb-2 ">Parking Duration </p>
                        </td>
                        <td>
                          <p className="mb-2  ml-2">: {GetParkingDiscountData?.duration}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="mb-2 ">Actual Cost </p>
                        </td>
                        <td>
                          <p className="mb-2  ml-2">
                            : ${GetParkingDiscountData?.actual_cost ? parseFloat(GetParkingDiscountData?.actual_cost).toFixed(2) : "0.00"}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="mb-2 ">Discount </p>
                        </td>
                        <td>
                          <p className="mb-2  ml-2">
                            : ${GetParkingDiscountData?.discounted_cost ? parseFloat(GetParkingDiscountData?.discounted_cost).toFixed(2) : "0.00"}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="mb-2 ">Convenience Fee </p>
                        </td>
                        <td>
                          <p className="mb-2  ml-2">
                            : ${GetParkingDiscountData?.convenience_fee ? parseFloat(GetParkingDiscountData?.convenience_fee).toFixed(2) : "0.00"}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="mb-2  font-weight-bold">Final Cost </p>
                        </td>
                        <td>
                          <p className="mb-2  font-weight-bold ml-2">
                            : ${GetParkingDiscountData?.final_cost ? parseFloat(GetParkingDiscountData?.final_cost).toFixed(2) : "0.00"}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </>
                ) : (
                  <></>
                )}
              </Card.Body>
            </Card>
          </Col>
          {isFailed && apiResponse?.api_error_message && <CustomContentAlert delay={2000} message={apiResponse?.api_error_message} className="toast-error" />}
          {dateError && <CustomContentAlert delay={3000} message={dateError} className="toast-error" />}
        </Row>
      </Container>
    </>
  );
}

export default CheckCouponValidity;

import t from "./types";
import { UnAuthAPI } from "../../../Auth/requestHandler";
import apiType from "../APIResponseActions/type";
import actionCreator from "../Action.Helper";

const getPermitDetails = actionCreator("GET_PERMIT_DETAIL");

export const validatePin = (domain, pin) => async (dispatch) => {
  try {
    const url = "validate_pin/" + domain + "/" + pin;
    const response = await UnAuthAPI(url, "GET");

    await dispatch({
      type: t.IS_PIN_VALID,
      payload: response.success,
    });
    await dispatch({
      type: t.GET_PROPERTY_ID,
      payload: response.property_id,
    });
    if (response.success === 1) {
      localStorage.setItem("selfServeToken", response.token);
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
    return response;
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getQuickPermitSetup = (property_id) => async (dispatch) => {
  try {
    const url = "quick_permit_setup/" + property_id;
    const response = await UnAuthAPI(url, "GET");
    await dispatch({
      type: t.GET_QUICK_PERMIT_SETUP_DETAIL,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};
export const getPermitDataWithPhoneOrMemberID = (
  phone,
  memberId,
  property_id
) => {
  return async (dispatch) => {
    try {
      const url = "get_permit_data";
      const body = {
        property_id: property_id,
      };
      if (memberId) {
        body.member_id = memberId;
      }
      if (phone) {
        body.phone = phone;
      }
      const response = await UnAuthAPI(url, "POST", body);
      if (response.success) {
        dispatch(
          getPermitDetails.success({
            data: response?.responseData,
            message: "Data fetched successfully",
          })
        );
        await dispatch({
          type: t.GET_PERMIT_DETAIL,
          payload: response.responseData,
        });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: "Data fetched successfully",
        });
      } else {
        dispatch(
          getPermitDetails.failure({
            message: response.message,
          })
        );
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    } catch (error) {
      dispatch(
        getPermitDetails.failure({
          data: error,
          message: error?.message,
        })
      );
      await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
    }
  };
};

export const getQuickPermitCreateData =
  (property_id, member_id, phone) => async (dispatch) => {
    try {
      const url = `get_permit_data/${property_id}/${member_id}/${phone}`;
      const response = await UnAuthAPI(url, "GET");
      await dispatch({
        type: t.GET_QUICK_PEMIT_CREATE_DATA,
        payload: response,
      });
    } catch (error) {
      await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
    }
  };

import t from "../../Actions/PermitOptionsActions/types";
const initialState = {
  allPermitOptionsList: [],
  unitOptionsList: [],
  termOptionsList: [],
  unitPaymentSetting: {},
  cardDetails: {},
  showMaxPermitWarning: false,
  maxLimitModalWarningMessage: "",
  getPermitShareGroupList: [],
};

const PermitOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.ADD_PAID_PERMIT_OPTION: {
      const list = JSON.parse(JSON.stringify(state.allPermitOptionsList));
      list.push(action.payload);
      const data = sortData(list);
      return { ...state, allPermitOptionsList: data };
    }
    case t.GET_PAID_PERMIT_OPTIONS_LIST: {
      return { ...state, allPermitOptionsList: action.payload };
    }
    case t.GET_TERM_PERMIT_OPTIONS_LIST: {
      return { ...state, termOptionsList: action.payload };
    }
    case t.ADD_UNIT_PERMIT_OPTION: {
      const list = JSON.parse(JSON.stringify(state.unitOptionsList));
      list.push(action.payload);
      const data = sortData(list);
      return { ...state, unitOptionsList: data };
    }
    case t.GET_UNIT_OPTIONS_LIST: {
      return { ...state, unitOptionsList: action.payload };
    }
    case t.DELETE_UNIT_PERMIT: {
      const oldUnitPermits = state.unitOptionsList;
      const deletedlist = oldUnitPermits.filter((permit) => permit.id !== action.payload);
      return {
        ...state,
        unitOptionsList: JSON.parse(JSON.stringify(deletedlist)),
      };
    }
    case t.UPDATE_PERMIT_OPTION_DATA: {
      const prevList = state.allPermitOptionsList;
      const updatedAllPermitOptions = prevList?.map((item) => {
        if (item.id === action.payload.old_id) {
          item = action.payload.new_row;
        }
        return item;
      });

      return { ...state, allPermitOptionsList: JSON.parse(JSON.stringify(updatedAllPermitOptions)) };
    }
    case t.GET_UNIT_PAYMENT_SETTING: {
      return { ...state, unitPaymentSetting: action.payload };
    }
    case t.UPDATE_UNIT_PERMIT_DATA: {
      const datas = JSON.parse(JSON.stringify(state.unitOptionsList));
      const updateList = datas.map((item) => {
        if (item.id === action.payload.id) {
          item.unit_id = action.payload.suite_id;
          item.max_permit = action.payload.max_permit;
          item.min_permit = action.payload.min_permit;
          item.car_pool_size = action.payload.car_pool_size;
          item.payment_type = action.payload.payment_type;
        }
        return item;
      });
      const data = sortData(updateList);
      return { ...state, unitOptionsList: data };
    }
    case t.GET_CARD_DETAILS: {
      return { ...state, cardDetails: action.payload };
    }
    case t.GET_PERMIT_GROUP_LIST: {
      return { ...state, getPermitShareGroupList: action.payload };
    }
    case t.SET_PERMIT_GROUP_LIST: {
      const data = JSON.parse(JSON.stringify(state.getPermitShareGroupList));
      data.push(action.payload);
      return { ...state, getPermitShareGroupList: data };
    }
    case t.SHOW_MAX_PERMIT_LIMIT_MODAL: {
      return {
        ...state,
        showMaxPermitWarning: action.payload.data,
        maxLimitModalWarningMessage: action.payload.msg,
      };
    }
    default:
      return state;
  }
};

const sortData = (data) => {
  data.sort((a, b) => {
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  });
  return data;
};
export default PermitOptionsReducer;

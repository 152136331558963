import React, { Component } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { SuperAdminAction } from "../../Actions";
import CreatePropertyForm from "../CreateProperty/CreatePropertyForm";
import { routing_using_history } from "../helper";
import PropertDetails from "./PropertySelection";
import ImportSites from "./ImportSites";

const history = require("../../history").default;

class LandingPage extends Component {
  state = {
    properties: [],
    pages: [
      "Site Settings", // "Settings",
      "Manage Users", // Add Users
      "Manage Site Permissions", // Permissions
      "Manage Citations", // Citation Setup
      "Manage Citation Template", // Printed Citation Setup
      "Manage Parking Area", // Add Lot
      "Manage Lanes", // Add Lane
      "Manage Cameras", // Add Camera
      "Manage Edge Devices", // Add Edge Device
      "Manage Meter Configuration", // Add Meter Config
      "QuickPermit Setup",
      "Manage ParkingPermit",
    ],
    selectedProperty: "",
    selectedPropertyObj: {},
    pageDropdownDisabled: true,
    all_properties: [],
    selectedPage: "",
    showPropertyModal: false,
    isModalOpen: false,
    isFormValid: false,
  };

  componentDidMount = async () => {
    await this.props.get_all_properties();
    this.props.setDomainHeader("");
    if (this.props.getAllProperties?.length) {
      const redirectUrl = history.location.pathname.split("/").pop();
      const url = this.props.getAllProperties.filter((item) => item.id === redirectUrl);
      if (url.length) {
        const selectedPropertyName = url ? url[0].site_name : "";
        const selectedDomainName = url ? url[0].domain : "";

        this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
        this.setState(
          {
            selectedPropertyObj: {
              value: url[0].id,
              label: url[0].site_name,
            },
            selectedProperty: url[0].id,
            pageDropdownDisabled: false,
          },
          () => {
            this.props.selected_Property(this.state.selectedPropertyObj);
          },
        );
      }
    }
  };

  changeProperty(event) {
    this.setState({ selectedProperty: event.value, selectedPropertyObj: event }, () => {
      const selectedPropertyObj = this.state.all_properties.find((item) => item.id === this.state.selectedProperty);
      const selectedPropertyName = selectedPropertyObj ? selectedPropertyObj.site_name : "";
      const selectedDomainName = selectedPropertyObj ? selectedPropertyObj.domain : "";

      this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.props.selected_Property(selectedPropertyObj);
      this.setState({
        isFormValid: true,
        pageDropdownDisabled: false,
      });
    });
  }

  newProperty() {
    this.setState({
      showPropertyModal: true,
      isModalOpen: !this.state.isModalOpen,
    });
  }

  handlePageChange({ target }) {
    this.setState({ selectedPage: target.value }, () => {
      const { selectedPage } = this.state;
      switch (selectedPage) {
        case "Site Settings": // Settings
          history.push({
            pathname: "/settings",
            search: this.state.selectedProperty,
          });
          break;
        case "Manage Users": // Add Users
          history.push({
            pathname: "/adduserform",
            search: this.state.selectedProperty,
          });
          break;
        case "Manage Site Permissions": // Permissions
          history.push({
            pathname: "/permissions",
            search: this.state.selectedProperty,
          });
          break;
        case "Manage Citations": // Citation Setup
          history.push({
            pathname: "/citation-setup",
            search: this.state.selectedProperty,
          });
          break;
        case "Manage Citation Template": // Printed Citation Setup
          history.push({
            pathname: "/printed-citation-setup",
            search: this.state.selectedProperty,
          });
          break;
        case "Manage Parking Area": // Add Lot
          history.push({
            pathname: "/addlotform",
            search: this.state.selectedProperty,
          });
          break;
        case "Manage Lanes": // Add Lane
          history.push({
            pathname: "/addlaneform",
            search: this.state.selectedProperty,
          });
          break;
        case "Manage Cameras": // Add Camera
          history.push({
            pathname: "/addcameraform",
            search: this.state.selectedProperty,
          });
          break;
        case "Manage Edge Devices": // Add Edge Device
          history.push({
            pathname: "/add-edge-device",
            search: this.state.selectedProperty,
          });
          break;
        case "Manage Meter Configuration": // Add Edge Device
          history.push({
            pathname: "/manage-meter-config",
            search: this.state.selectedProperty,
          });
          break;
        case "QuickPermit Setup":
          history.push({
            pathname: "/quick-permit-setup",
            search: this.state.selectedProperty,
          });
          break;
        case "Manage ParkingPermit":
          history.push({
            pathname: "/parking-permit-setup",
            search: this.state.selectedProperty,
          });
          break;
        default:
          routing_using_history("/landingpage");
      }
    });
  }

  render() {
    const { showPropertyModal, isModalOpen, selectedProperty, isFormValid } = this.state;
    return (
      <>
        <Container fluid>
          <Row className="h-100">
            <Col md="12">
              <Card className="h-100">
                <Card.Header>
                  <div className="d-flex align-items-sm-center align-items-start justify-content-between sm-flex-column ">
                    <div>
                      <Card.Title as="h4" className="my-1">
                        Site Wizard
                      </Card.Title>
                    </div>
                    <div>
                      <Button onClick={this.newProperty.bind(this)} type="button" variant="primary" className="btn-blue-bg mr-3 mb-2 mb-sm-0">
                        Add Property
                      </Button>
                      <ImportSites />
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide">
                  <h5>Select Property</h5>
                  <Row>
                    <Col lg="12">
                      <Row>
                        <Col md="6">
                          <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <div>
                              <Form.Label>Page:</Form.Label>
                              <Form.Control
                                as="select"
                                name="selectedPage"
                                disabled={this.state.pageDropdownDisabled}
                                placeholder="City"
                                value={this.state.selectedPage}
                                onChange={this.handlePageChange.bind(this)}
                              >
                                <option value="">--Choose Page--</option>
                                {(this.state.pages ?? []).map((e, i) => {
                                  return <option key={i}>{e}</option>;
                                })}
                              </Form.Control>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn-fill btn btn-info"
                      type="button"
                      variant="info"
                      disabled={!isFormValid}
                      onClick={() => {
                        history.push({
                          pathname: "/settings",
                          search: selectedProperty,
                        });
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {showPropertyModal ? <CreatePropertyForm show={isModalOpen} closeModal={() => this.setState({ isModalOpen: !this.state.isModalOpen })} /> : null}
      </>
    );
  }
}

const mapStatetoProps = ({ SuperAdminDetails }) => {
  const { getAllProperties } = SuperAdminDetails;
  return { getAllProperties };
};

const mapStateToDispatch = {
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStatetoProps, mapStateToDispatch)(LandingPage);

/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Card, Container, Row, Col } from "react-bootstrap";
import { APIResponseActions, ValidationPassagepassActions, SuperAdminAction, CurrentPermitActions } from "../../Actions";
import AsyncSelect from "react-select/async";
import "react-perfect-scrollbar/dist/css/styles.css";
import isEqual from "lodash/isEqual";
import CustomContentAlert, { regExForPhone, validations, validation_message } from "../helper";
import { timeOut } from "../Common/SetTimeout";

class ValidationPassagepass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableSubmit: true,
      limitForToday: "",
      residentList_: [],
      userDetails:
        this.props.UserProfileDetails && Object.keys(this.props.UserProfileDetails).length
          ? this.props.UserProfileDetails
          : this.props.semiAuthorizedUserProfile,
      user_type: this.props.user_type === undefined ? this.props.semiAuthorizedUserProfile?.user_type : this.props.user_type,
      selected_suite_id: "",
      creditLimit: 0,
      leftCredit: 0,
      credit: this.props.credits,
      suite_id: this.props.suite_id,
      propertyId: this.props.property_id,
      fullname: "",
      validFullName: true,
      emptying_form: false,
      guestnumber: "",
      guestname: "",
      isFormValid: false,
      show_alert: false,
      show_alert_on_error: true,
    };
  }

  formatName = (user) => {
    return user.first_name + " " + user.last_name;
  };

  filterResidentFirstNameLastName = (selected_suite_id) => {
    console.log(selected_suite_id);
    console.log(this.state.residentList_);
    if (selected_suite_id) {
      const searchResidentList = this.state.residentList_.filter((selectResident) => {
        console.log(selectResident.suite_id.includes(selected_suite_id));
        return selectResident.suite_id.includes(selected_suite_id);
      });
      return (
        searchResidentList?.length &&
        searchResidentList.map((item) => {
          return [
            {
              value: item.first_name + " " + item.last_name,
              label: item.first_name,
            },
          ];
        })
      );
    } else {
      if (this.state.residentList_?.length) {
        return this.state.residentList_.map((item) => {
          return [
            {
              value: item.first_name + " " + item.last_name,
              label: item.first_name + " " + item.last_name,
            },
          ];
        });
      } else {
        return [];
      }
    }
  };

  promiseOptions = async (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterResidentFirstNameLastName(inputValue));
      }, 800);
    });

  filterResident = (selected_suite_id) => {
    if (selected_suite_id) {
      const searchResidentList = this.state.residentList_.filter((selectResident) => {
        return selectResident.suite_id.toLowerCase().includes(selected_suite_id.toLowerCase());
      });

      return (
        searchResidentList?.length &&
        searchResidentList.map((item) => {
          return {
            value: item.suite_id,
            label: item.suite_id,
          };
        })
      );
    } else {
      if (this.state.residentList_?.length) {
        return this.state.residentList_.map((item) => {
          return {
            value: item.suite_id,
            label: item.suite_id,
          };
        });
      } else {
        return [];
      }
    }
  };

  promiseOptions = async (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterResident(inputValue));
      }, 800);
    });

  componentDidUpdate(prevProps) {
    if (prevProps.data_parked !== this.props.data_parked) {
      this.setState({
        data_parked: this.props.data_parked,
        showError: this.props.api_response,
        show_alert: false,
        show_alert_on_error: false,
      });
    }
    if (prevProps.usedAllocatedCredits !== this.props.usedAllocatedCredits) {
      this.setState({
        usedAllocatedCredits: this.props.usedAllocatedCredits,
        showError: this.props.api_response,
      });
    }
    if (prevProps.single_credit_availability !== this.props.single_credit_availability) {
      this.setState({
        single_credit_availability: this.props.single_credit_availability,
        showError: this.props.api_response,
      });
    }
    if (prevProps.currentCredits !== this.props.currentCredits) {
      this.setState({
        currentCredits: this.props.currentCredits,
        showError: this.props.api_response,
      });
    }
    if (prevProps.usedCredits !== this.props.usedCredits) {
      this.setState({
        usedCredits: this.props.usedCredits,
        showError: this.props.api_response,
      });
    }
    if (prevProps.allocatedCredits !== this.props.allocatedCredits) {
      this.setState({
        allocatedCredits: this.props.allocatedCredits,
        showError: this.props.api_response,
      });
    }
    if (!isEqual(prevProps.credit_30days_reports, this.props.credit_30days_reports)) {
      this.setState({
        credit_30days_reports: this.props.credit_30days_reports,
        showError: this.props.api_response,
      });
    }
    if (!isEqual(prevProps.single_credit_availability, this.props.single_credit_availability)) {
      this.setState({
        single_credit_availability: this.props.single_credit_availability,
        showError: this.props.api_response,
      });
    }
    if (!isEqual(prevProps.credit_used_30days, this.props.credit_used_30days)) {
      this.setState({
        credit_used_30days: this.props.credit_used_30days,
        showError: this.props.api_response,
      });
    }
    if (!isEqual(prevProps.UserProfileDetails, this.props.UserProfileDetails)) {
      this.setState({
        userDetails: this.props.UserProfileDetails,
        showError: this.props.api_response,
      });
    }
  }

  componentDidMount = async () => {
    if (this.state.user_type === "unit") {
      if (this.props.property === undefined) {
        await this.props.getCreditTemplateGroups(this.props.semiAuthorizedUserProfile?.property?.template_id);
      } else {
        await this.props.getCreditTemplateGroups(this.props.property?.template_id);
      }
      const { propertyId, credit, userDetails } = this.state;
      const suite_id = this.state.suite_id ? this.state.suite_id : this.props.semiAuthorizedUserProfile?.suite_id;
      this.setState({
        fullname: userDetails.first_name + " " + userDetails.last_name,
      });
      this.setState({ loader: true, isOpenDateList: true }, async () => {
        this.setState({
          day_availablity: this.props.day_availablity,
          evening_availability: this.props.evening_availability,
          night_availability: this.props.night_availability,
          single_credit_availability: this.props?.single_credit_availability,
          template: this.props?.property?.credit_group,
          multiplePermits: this.props.multiple_permits,
        });
        this.props.setResidentCredits(userDetails);
        this.props.getResidents(userDetails);
        this.getResidentCreditSummary(suite_id, propertyId, credit);
        this.setState({
          loader: false,
          showError: this.props.api_response,
        });
      });
      if (this.state.leftCredit <= 0 && this.props.permits_allowed_type !== 2) {
        this.setState({
          creditLimitMessage: "You don't have credit limit to add visitors",
        });
      }
    } else if (this.state.user_type === "admin" || this.state.user_type === "superadmin") {
      await this.props.InitializePermitAllowedType();
    }
    const selectedPropertyName = this.state.property?.property_name;
    const selectedDomainName = this.state.property?.domain;
    await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
    await this.props.fetchResidentList();
    this.setState({ showAsyncHead: true });
    if (this.props.api_response) {
      this.setState(
        {
          residentList_: this.props.residentList,
        },
        () => {
          if (this.state.residentList_.length > 0) {
            this.filterResident();
            this.filterResidentFirstNameLastName();
          }
        },
      );
    }
    // return newList;
  };

  validateContactNumber = () => {
    const { guestnumber } = this.state;
    const regExForContactNumber = regExForPhone;
    const isContactNumberValidate = regExForContactNumber.test(String(guestnumber));
    if (isContactNumberValidate) {
      if (guestnumber?.length >= 11 || guestnumber?.length < 10) {
        this.setState({
          phoneErrorMessage: "Please enter valid phone number",
        });
        return false;
      } else {
        this.setState({
          phoneErrorMessage: "",
        });
        return true;
      }
    } else {
      return false;
    }
  };

  handleInputSelect = async (e) => {
    this.setState(
      {
        selected_suite_id: e.value,
        selectedValue: {
          value: e.value,
          label: e.label,
        },
        credit: this.props.credits,
      },
      async () => {
        const { selected_suite_id, propertyId, credit, userDetails, fullname, selectedValue } = this.state;
        const newUserDetails = {
          ...userDetails,
          suite_id: selected_suite_id,
          fullname: fullname,
        };
        const suite_id_select = Object.values(selectedValue)[0];
        const getunitbyname = this.filterResidentFirstNameLastName(suite_id_select);
        const unitfullname = Object.values(getunitbyname[0][0])[0];
        this.filterResidentFirstNameLastName(suite_id_select);
        this.setState({
          userDetails: newUserDetails,
          fullname: unitfullname,
        });
        this.props.setResidentCredits(userDetails);
        this.props.getResidents(userDetails);
        console.log(userDetails);
        this.getResidentCreditSummary(selected_suite_id, propertyId, credit);
        if (this.state.leftCredit <= 0 && this.props.permits_allowed_type !== 2) {
          this.setState({
            creditLimitMessage: "You don't have credit limit to add visitors",
          });
        }
      },
    );
  };

  handleChange = ({ target }) => {
    const prevstate = this.state.guestnumber;
    this.setState({ [target.name]: target.value, showBookingError: false }, async () => {
      if (this.state.guestnumber.trim().length) {
        this.validateContactNumber();
      }
      if (target.name === "guestnumber" && this.state.guestnumber.trim().length) {
        if (regExForPhone.test(this.state.guestnumber)) {
          this.setState({ guestnumber: target.value });
        } else {
          this.setState({ guestnumber: prevstate });
        }
      }
      this.PassagepassvalidationForm();
      switch (target.name) {
        case "guestnumber": {
          if (this.state.guestnumber.trim().length) {
            if (regExForPhone.test(this.state.guestnumber)) {
              this.setState({ guestnumber: target.value });
            } else {
              this.setState({ guestnumber: prevstate });
            }
          } else {
            const CN = validations(target.name, "guestnumber", this.state.guestnumber);
            this.setState({
              guestnumber: CN.value,
              validContactNumber: CN.validator,
            });
          }
          break;
        }
        case "fullname": {
          const fullname = validations(target.name, "fullname", this.state.fullname);
          this.setState({
            fullname: fullname.value,
            validFullName: fullname.validator,
          });
          break;
        }
        case "suite_id": {
          const SI = validations(target.name, "suite_id", this.state.suite_id);
          this.setState({
            suite_id: SI.value,
            validSuite_id: SI.validator,
          });
          break;
        }
      }
    });
  };

  handleSubmit = async () => {
    const { selected_suite_id, fullname, guestname, guestnumber, isFormValid } = this.state;
    const residentData = {
      selected_suite_id,
      fullname,
      guestname,
      guestnumber,
      isFormValid,
    };
    this.setState({ isFormValid: false });
    const Obj = { ...residentData };
    await this.props.create_passagepass_validation(Obj, this.props.property_id);

    if (this.props.api_response) {
      this.setState({
        selectedValue: "",
        fullname: "",
        guestname: "",
        guestnumber: "",
        show_alert: true,
        api_success_message: this.props.api_success_message,
      });
      timeOut({
        fun: () => {
          this.setState({
            show_alert: false,
          });
        },
      });
    } else {
      this.setState({
        show_error: true,
        api_error_message: this.props.api_error_message,
      });
      timeOut({
        fun: () => {
          this.setState({
            show_error: false,
          });
        },
      });
    }
  };

  PassagepassvalidationForm = () => {
    if (this.state.user_type !== "unit") {
      if (this.state.fullname.trim().length && this.state.guestname.trim().length && this.state.guestnumber.trim().length && this.validateContactNumber()) {
        this.setState({
          isFormValid: true,
        });
      } else {
        this.setState({
          isFormValid: false,
        });
      }
    } else {
      if (this.state.fullname.trim().length && this.state.guestname.trim().length && this.state.guestnumber.trim().length && this.validateContactNumber()) {
        this.setState({
          isFormValid: true,
        });
      } else {
        this.setState({
          isFormValid: false,
        });
      }
    }
  };

  getResidentCreditSummary = (credit) => {
    this.setState({ usedAllocatedCredits: this.props.usedAllocatedCredits }, () => {
      let allocatedCredits = this.state.usedAllocatedCredits;
      if (allocatedCredits?.length) {
        allocatedCredits = allocatedCredits[0].cost;
      } else {
        allocatedCredits = 0;
      }
      const usedCredits = parseInt(allocatedCredits);

      this.setState({ currentCredits: this.props.currentCredits }, () => {
        const allocatedCredits = this.state.currentCredits;
        let creditAllocated = credit;
        if (allocatedCredits?.length) {
          creditAllocated = parseInt(allocatedCredits[0].cost);
        } else {
          creditAllocated = 0;
        }
        let leftCredit = credit - creditAllocated;
        let creditLimit = 0;
        if (this.state.credit_type === "single") {
          const credit_available = this.state.single_credit_availability?.credit_available;
          leftCredit = credit_available;
          creditLimit = credit;
        } else {
          const credit = this.props.day + this.props.evening + this.props.night;
          const credit_available =
            this.state.credit_30days_reports?.length &&
            this.state.credit_30days_reports.map((item) => item.credit_available).reduce((prev, curr) => prev + curr, 0);
          leftCredit = credit_available;
          creditLimit = credit;
        }
        this.setState({
          leftCredit,
          creditLimit,
          runningCredits: usedCredits,
          creditAllocated,
        });
        this.setState({ usedCredits: this.props.usedCredits });
        this.setState({
          allocatedCredits: this.props.allocatedCredits,
        });
      });
    });
    this.setState({
      show_error_on_get_resident_credit_summary: this.props.api_response,
    });
    // });
  };

  blockInvalidChar = (e) => {
    if (e.key === "Enter" && this.state.isFormValid) {
      this.handleSubmit();
    } else {
      return ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
    }
  };

  handleEnter = (event) => {
    if (event.key === "Enter" && this.state.isFormValid) {
      this.handleSubmit();
    }
  };

  render() {
    const {
      user_type,
      show_alert,
      api_success_message,
      show_alert_on_error,
      api_error_message,
      fullname,
      validFullName,
      emptying_form,
      guestname,
      guestnumber,
      isFormValid,
    } = this.state;

    return (
      <div>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="contact-container mb-0">
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  {show_alert && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
                  {!show_alert_on_error && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}
                  <Card.Title as="h4">
                    Écran de validation / <em>Parking Validations</em>
                    <span>
                      {this.props.UserProfileDetails?.suite_id && this.props.semiAuthorizedUserProfile
                        ? " (" + this.props.UserProfileDetails?.suite_id + ")"
                        : ""}
                    </span>
                  </Card.Title>
                  <p className="card-category"></p>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <section id="content">
                      <div>
                        <p>
                          Émettre jusqu’à 4 dollars de réduction sur le stationnement des invités/
                          <em>Issue Up to 4 Dollars Off Guest Parking</em>
                        </p>
                        <Row>
                          {user_type !== "unit" ? (
                            <Col xl="6" lg="6" sm="8" xs="12">
                              <Form.Group>
                                <label>
                                  Unit your guest is visiting
                                  <span className="asterisk">*</span>
                                </label>
                                <AsyncSelect
                                  loadOptions={this.promiseOptions}
                                  defaultOptions
                                  cacheOptions
                                  value={this.state.selectedValue}
                                  onChange={(e) => this.handleInputSelect(e)}
                                />
                              </Form.Group>
                            </Col>
                          ) : null}
                        </Row>
                        <Row>
                          <Col xl="6" lg="6" sm="8" xs="12">
                            <Form.Group>
                              <label htmlFor="guest-fullname m-t-s">
                                Votre nom / <em>Your Name:</em>
                              </label>
                              <Form.Control
                                placeholder="John"
                                name="fullname"
                                value={fullname}
                                autoComplete="off"
                                required
                                type="string"
                                onChange={this.handleChange}
                                onKeyDown={this.handleEnter}
                              ></Form.Control>
                              {!emptying_form && validation_message(fullname, "Last Name", validFullName)}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" lg="6" sm="8" xs="12">
                            <div>
                              <Form.Group>
                                <label htmlFor="guest-fullname m-t-s">
                                  Nom de l’invité / <em>Guest Name:</em>
                                </label>
                                <Form.Control
                                  placeholder="John"
                                  type="text"
                                  name="guestname"
                                  value={guestname}
                                  autoComplete="off"
                                  required
                                  onChange={this.handleChange}
                                  onKeyDown={this.handleEnter}
                                ></Form.Control>
                              </Form.Group>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" lg="6" sm="8" xs="12">
                            <div>
                              <Form.Label>
                                Téléphone invité / <em>Guest Phone:</em>
                              </Form.Label>
                              <span className="asterisk">*</span>
                              <Form.Control
                                value={guestnumber}
                                type="text"
                                name="guestnumber"
                                placeholder="333-222-2323"
                                onKeyDown={this.blockInvalidChar}
                                onChange={this.handleChange}
                                maxlength="10"
                                autoComplete="off"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6" lg="6" sm="8" xs="12">
                            <div>
                              <p></p>
                              <Button className="btn btn-primary" disabled={!isFormValid} onClick={this.handleSubmit}>
                                Send
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <div id="div_instructions">
                            <br />
                            <ul>
                              <li>
                                Votre invité recevra un SMS avec des instructions sur la façon de réclamer son rabais de 4 dollars. /{" "}
                                <em>Your guest will get an SMS with instructions on how to claim their 4 dollar off discount.</em>
                              </li>
                              <li>
                                Vous ne pouvez émettre qu’un seul laissez-passer de 4 $ par jour. /<em>You can only issue one $4 off pass per day.</em>
                              </li>
                              <li>
                                Votre invité devra utiliser son pass aujourd’hui, sinon il expirera. /{" "}
                                <em>Your guest will have to use their pass today, or else it will expire.</em>
                              </li>
                            </ul>
                          </div>
                        </Row>
                      </div>
                    </section>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ CurrentPermitDetails, APIResponseDetails, UserProfileDetails, SuperAdminDetails, Logindetails }) => {
  const { data_parked, residentList } = CurrentPermitDetails;
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { credits, suite_id, property_id, user_type, available_spot, appConfig, globaleCredit, credit_type, company_id, property, timezone } =
    UserProfileDetails;
  const { time_slot_templates, templateCreditGroup } = SuperAdminDetails;
  const { backgroundColor, semiAuthorizedUserProfile } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  return {
    semiAuthorizedUserProfile,
    timezone,
    globaleCredit,
    data_parked,
    api_response,
    api_error_message,
    credits,
    suite_id,
    property_id,
    UserProfileDetails,
    user_type,
    company_id,
    available_spot,
    appConfig,
    api_success_message,
    credit_type,
    property,
    residentList,
    main_body,
    time_slot_templates,
    templateCreditGroup,
  };
};

const mapStateToDispatch = {
  getCurrentPermits: CurrentPermitActions.getCurrentPermits,
  getCreditsIndividual: CurrentPermitActions.getCreditsIndividual,
  fetchResidentList: CurrentPermitActions.fetchResidentList,
  getCreditTemplateGroups: SuperAdminAction.getCreditTemplateGroups,
  getCreditsIndividualDetails: CurrentPermitActions.getCreditsIndividualDetails,
  getPastCreditsIndividual: CurrentPermitActions.getPastCreditsIndividual,
  AddPermits: CurrentPermitActions.AddPermits,
  InitializePermitAllowedType: CurrentPermitActions.InitializePermitAllowedType,
  getSettings: SuperAdminAction.getSettings,
  APIResponseSuccess: APIResponseActions.APIResponseSuccess,
  getCredits: CurrentPermitActions.getCredits,
  getPastCredits: CurrentPermitActions.getPastCredits,
  setResidentCredits: CurrentPermitActions.setResidentCredits,
  getResidentPermits: CurrentPermitActions.getResidentPermits,
  getResidents: CurrentPermitActions.setResidentCredits,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  visitorPermitDelete: CurrentPermitActions.visitorPermitDelete,
  create_passagepass_validation: ValidationPassagepassActions.create_passagepass_validation,
};

export default connect(mapStateToProps, mapStateToDispatch)(ValidationPassagepass);

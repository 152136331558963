import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import QuickPermitRadioButton from "./QuickPermitRadioButton";

const IdFieldSectionSetting = (props) => {
  const {
    formData,
    handleCheckBox,
    handleChange,
    handleUpdateChange,
    errorState,
  } = props;
  return (
    <>
      <Row className="mt-2 mb-2">
        {/* WORLD-677 */}
        <Col md="auto" className="pr-0">
          <label className="fancy-checkbox mb-0">Show ID field</label>
        </Col>
        {/* <Col md="2"><label>Self server</label></Col> */}
        <Col>
          <Form.Group>
            <div className="d-flex mt-1">
              {console.log("show_id", formData.show_id)}
              <input
                type="checkbox"
                name={"show_id"}
                onChange={handleCheckBox}
                checked={formData.show_id}
                value={formData.show_id}
              />
              <label className="ml-2 mb-0">Yes</label>
            </div>
          </Form.Group>
        </Col>
      </Row>
      {(formData.show_id === true || formData.show_id === 1) && (
        <>
          <Row>
            <Col md="4">
              <Form.Group>
                <label>Name of ID field</label>
                <Form.Control
                  placeholder="Member ID"
                  type="text"
                  name="name_of_id"
                  value={formData.name_of_id}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {errorState && errorState.name_of_id.length > 0 && (
                  <div className="help-block">{errorState.name_of_id}</div>
                )}
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group>
                <label>Min Characters</label>
                <Form.Control
                  placeholder=""
                  type="text"
                  name="mincharacter"
                  value={formData.mincharacter}
                  onChange={handleChange}
                  autoComplete="off"
                  pattern="[0-9]*"
                  min={0}
                />
                {errorState && errorState.mincharacter.length > 0 && (
                  <div className="help-block">{errorState.mincharacter}</div>
                )}
                {/* <span className="help-block">
                  {formData.minError}
                </span> */}
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group>
                <label>Max Characters</label>
                <Form.Control
                  placeholder=""
                  type="text"
                  name="maxcharacter"
                  value={formData.maxcharacter}
                  onChange={handleChange}
                  autoComplete="off"
                  pattern="[0-9]*"
                  min={0}
                />
                {errorState && errorState.maxcharacter.length > 0 && (
                  <div className="help-block">{errorState.maxcharacter}</div>
                )}
                {/* <span className="help-block">
                  {formData.maxError}
                </span> */}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="">
                <div className="w-100">
                  <Form.Group className="ml-0 mt-2 mb-2 mr-3">
                    <QuickPermitRadioButton
                      label="Number Only"
                      name="numeric"
                      value="numeric"
                      onChange={handleUpdateChange}
                      checked={formData.id_validation === "numeric"}
                    />
                    <QuickPermitRadioButton
                      label="Letter Only"
                      name="alphabets"
                      value="alphabets"
                      onChange={handleUpdateChange}
                      checked={formData.id_validation === "alphabets"}
                    />
                    <QuickPermitRadioButton
                      label="Numbers and Letters"
                      name="alphanumeric"
                      value="alphanumeric"
                      onChange={handleUpdateChange}
                      checked={formData.id_validation === "alphanumeric"}
                    />
                  </Form.Group>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default IdFieldSectionSetting;

import React, { Component } from "react";
import { Card, Row, Col, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import DataTable from "react-data-table-component";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { connect } from "react-redux";
import { SuperAdminAction, ReportsAction } from "../../Actions";
import Loader from "../Loader/Loader";
import { tableHeightScrollBarsTab, findBySearch, utcToSpecificTimeZone, scroolToTop } from "../helper";
import isEqual from "lodash/isEqual";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropertDetails from "../LandingPage/PropertySelection";
import { saveAs } from "file-saver";
import moment from "moment";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";

class BatchValidation extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.state = {
      PropertyId: this.props.PropertyId,
      validationList: [],
      loader: false,
      suite_id: this.props.suite_id,
      searchValue: "",
      count: 0,
      timezone: this.props.timezone,
      property: this.props.property,
      selectedProperty: "",
      selectedPropertyObj: {},
      childcheckbox: true,
      ticketReportLots: [],
      lot_id: "",
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentSummaryDate: "",
      finalTableHeight: 0,
      daysDifference: 30,
      all_properties: [],
      units: [],
      unit_id: "",
      startDateTime: "",
      endDateTime: "",
    };

    this.customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };
    this.columns = [
      {
        name: "Domain",
        sortable: true,
        width: "120px",
        cell: (row) => this.showDomainField(row),
        selector: (row) => row?.domain,
      },
      {
        name: "Discounted On",
        sortable: true,
        width: "200px",
        cell: (row) => this.showDiscountedOnField(row),
        selector: (row) => row?.discounted_on,
      },
      {
        name: "Unit",
        sortable: true,
        width: "160px",
        cell: (row) => this.showUnitField(row),
        selector: (row) => row.Unit,
      },
      {
        name: "Name",
        sortable: true,
        width: "140px",
        cell: (row) => this.showNameField(row),
        selector: (row) => row.Name,
      },
      {
        name: "Discount Name",
        width: "160px",
        sortable: true,
        cell: (row) => this.showDiscountNameField(row),
        selector: (row) => row.discount_name,
      },
      {
        name: "Discount Type",
        width: "160px",
        sortable: true,
        cell: (row) => this.showDiscountTypeField(row),

        selector: (row) => row.discount_type,
      },
      {
        name: "Discount #",
        width: "160px",
        sortable: true,
        cell: (row) => this.showDiscountCodeField(row),
        selector: (row) => row.discount_code,
      },
      {
        name: "Discount %/$",
        width: "160px",
        sortable: true,
        cell: (row) => this.showCellValue(row.discount_value, "text"),
        selector: (row) => row.discount_value,
      },
      {
        name: "Rate $",
        width: "160px",
        sortable: true,
        cell: (row) => this.showRateNumberField(row),
        selector: (row) => row.rate,
      },
      {
        name: "Discount $",
        width: "160px",
        sortable: true,
        cell: (row) => this.showDiscountField(row),
        selector: (row) => row.discount,
      },
      {
        name: "Driver Paid $",
        width: "160px",
        sortable: true,
        cell: (row) => this.showCellValue(row.charged_amount, "amount"),
        selector: (row) => row.charged_amount,
      },
      {
        name: "Unit Charged $",
        width: "160px",
        sortable: true,
        cell: (row) => this.showCellValue(row.unit_charged, "amount"),
        selector: (row) => row.unit_charged,
      },
      {
        name: "Tracking Code",
        width: "160px",
        sortable: true,
        cell: (row) => this.showTrackingCodeField(row),
        selector: (row) => row.tracking_code,
      },
    ];
  }

  showTrackingCodeField = (row) => (
    <>
      {row.tracking_code ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.tracking_code}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.tracking_code}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showDiscountField = (row) => (
    <>
      {row.discount ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.discount}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.discount}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showRateNumberField = (row) => (
    <>
      {row.rate ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.rate}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.rate}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showDiscountCodeField = (row) => (
    <>
      {row.discount_code ? (
        <a className="curserPointer" onClick={() => this.checkCouponValidity(row)}>
          <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.discount_code}</Tooltip>}>
            <div className="text-nowrap text-truncate pr-2">{row.discount_code}</div>
          </OverlayTrigger>
        </a>
      ) : (
        "-"
      )}
    </>
  );

  showDiscountTypeField = (row) => (
    <>
      {row.discount_type ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.discount_type}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.discount_type}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showDiscountNameField = (row) => (
    <>
      {row.discount_name ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.discount_name}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.discount_name}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showNameField = (row) => (
    <>
      {row.Name ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.Name}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.Name}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showUnitField = (row) => (
    <>
      {row.Unit ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row.Unit}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row.Unit}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  showDiscountedOnField = (row) => (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement={"top"}
      overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(this.props.timezone, row.discounted_on, true)}</Tooltip>}
    >
      <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(this.props.timezone, row.discounted_on, true)}</div>
    </OverlayTrigger>
  );

  showDomainField = (row) => (
    <>
      {row?.domain ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.domain}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.domain}</div>
        </OverlayTrigger>
      ) : (
        ""
      )}
    </>
  );

  showCellValue = (cellValue, valueType) => {
    switch (valueType) {
      case "text":
        return (
          <>
            {cellValue ? (
              <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{cellValue}</Tooltip>}>
                <div className="text-nowrap text-truncate pr-2">{cellValue}</div>
              </OverlayTrigger>
            ) : (
              "-"
            )}
          </>
        );
      case "amount":
        return (
          <>
            {cellValue ? (
              <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                placement={"top"}
                overlay={<Tooltip id={`tooltip-qw`}>{Math.max(0, parseFloat(cellValue)).toFixed(2)}</Tooltip>}
              >
                <div className="text-nowrap text-truncate pr-2">{Math.max(0, parseFloat(cellValue)).toFixed(2)}</div>
              </OverlayTrigger>
            ) : (
              "-"
            )}
          </>
        );
      default:
        break;
    }
  };

  checkCouponValidity = (row) => {
    const history = require("../../history").default;
    history.push({
      pathname: "/check-discount-coupon-validity",
      state: { discount_code: row?.discount_code },
    });
  };

  changeProperty(event) {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState(
      {
        selectedProperty: event.value,
        selectedPropertyObj: event,
        startDate: new Date(datebeforeThirtyDays),
        endDate: new Date(),
        currentSummaryDate: null,
        unit_id: "",
      },
      () => {
        const selectedPropertyObj = this.state.all_properties.find((item) => item.id === this.state.selectedProperty);

        this.props.selected_Property(selectedPropertyObj);
        this.props.get_all_lots(event.value);
        const current_lot = this.props.lots;
        this.setState({
          ticketReportLots: current_lot,
        });
      },
    );
  }

  handleCheckBox = () => {
    this.setState({ childcheckbox: !this.state.childcheckbox });
  };

  componentDidMount = async () => {
    console.log("locadert========>", this.state.loader);
    await this.getTickets();
    console.log("validationList====>", this.state.validationList);
    await this.props.get_all_properties();
    const { PropertyId } = this.state;
    await this.props.get_all_lots(PropertyId);
    await this.props.get_all_units(PropertyId);
    const current_lot = this.props.lots;
    this.setState(
      {
        all_properties: this.props?.getAllProperties,
        ticketReportLots: current_lot,
        units: this.props.units,
      },
      async () => {
        const selectedPropertyObj = (this.state.all_properties ?? []).find((item) => item.id === PropertyId);
        if (selectedPropertyObj) {
          this.setState({
            selectedPropertyObj: {
              label: selectedPropertyObj.site_name,
              value: selectedPropertyObj.id,
            },
            selectedProperty: PropertyId,
            selectedDomainName: selectedPropertyObj.site_name,
          });
        }
      },
    );

    const exportUrl = process.env.REACT_APP_CONFIG_BASE_URL;
    if (exportUrl) {
      this.setState({
        exportUrl: `${exportUrl}/api/v1/revenue_report/export/${this.state?.property?.id}`,
        exportPdfUrl: `${exportUrl}/api/v1/revenue_report/exportPdf`,
      });
    }
    console.log("locadert========> last", this.state.loader);
  };

  getTickets = async () => {
    this.setState({ loader: true }, async () => {
      const { PropertyId, childcheckbox, timezone, startDate, endDate } = this.state;
      const pageobj = {
        PropertyId,
        timezone,
        childcheckbox,
      };
      if (startDate) {
        pageobj.startDate = startDate;
      }
      if (endDate) {
        pageobj.endDate = endDate;
      }
      await this.props.getReportList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      const selectedPropertyName = this.state.property?.property_name;
      const selectedDomainName = this.state.property?.domain;
      await this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
      this.setState(
        {
          validationList: this.props?.validationList?.rows?.length ? JSON.parse(JSON.stringify(this.props.validationList.rows)) : [],
        },
        () => {
          console.log("brfore betch false", this.state.loader);
          this.setState({ loader: false });
          const height = tableHeightScrollBarsTab();
          this.setState({
            finalTableHeight: height,
          });
          console.log("validationList====>inn", this.state.validationList);
        },
      );
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (!isEqual(prevProps.validationList, this.props.validationList)) {
      this.setState({
        validationList: JSON.parse(JSON.stringify(this.props.validationList.rows)),
        count: this.props.validationList.count,
      });
    }
    if (!isEqual(prevProps.lots, this.props.lots)) {
      this.setState({
        ticketReportLots: this.props.lots,
        lotsSummary: this.props.lots,
      });
    }
  };

  dataTableHandle = (validationList) => {
    return (
      <DataTable
        data={this.state.searchValue.length ? this.state.searchedvalidationList : validationList}
        columns={this.columns}
        customStyles={this.customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={this.state.finalTableHeight}
        // fixedHeaderScrollHeight={20}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={this.state.count}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  handleChangeLot = ({ target }) => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - 30);
    this.setState({
      [target.name]: target.value,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentSummaryDate: null,
    });
  };

  handleCurrentDate = (date) => {
    this.setState({
      currentSummaryDate: date,
      startDate: null,
      endDate: null,
    });
  };

  handleChangeStartingDates = (date) => {
    const dt = new Date(date);
    const getEndDate = new Date(dt.setMonth(dt.getMonth() + 3));
    let selectEndDate;
    if (getEndDate > new Date()) {
      selectEndDate = new Date();
    } else {
      selectEndDate = getEndDate;
    }
    this.setState({
      startDate: date,
      endDate: selectEndDate,
      currentSummaryDate: null,
    });
  };

  handleChangeEndDates = (date) => {
    this.setState({
      endDate: date,
      currentSummaryDate: null,
    });
  };

  GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj);
    this.setState({
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    });
  };

  handleSearch = () => {
    this.setState({ loader: true }, async () => {
      const { lot_id, childcheckbox, currentSummaryDate, startDate, endDate, selectedPropertyObj, PropertyId, timezone, unit_id } = this.state;
      const pageobj = {
        childcheckbox,
        PropertyId,
        timezone,
      };
      if (Object.keys(selectedPropertyObj).length) {
        pageobj.PropertyId = selectedPropertyObj.value;
      } else {
        pageobj.PropertyId = PropertyId;
      }
      if (lot_id) {
        pageobj.lot_id = lot_id;
      }
      if (currentSummaryDate) {
        pageobj.currentSummaryDate = currentSummaryDate;
      }
      if (unit_id) {
        pageobj.unit_id = unit_id;
      }
      if (startDate && endDate) {
        pageobj.startDate = startDate;
        pageobj.endDate = endDate;
      }
      await this.props.getReportList(pageobj);
      await this.GetDateRangeWithFormat(pageobj);
      this.setState({
        validationList: JSON.parse(JSON.stringify(this.props.validationList.rows)),
        count: this.props.validationList.count,
      });
      this.setState({ loader: false });
      document.body.classList.remove("foc-open");
    });
  };

  handleSearchChange = async ({ target }) => {
    const val = target ? target.value.toString().trim() : this.state.searchValue.toString().trim();
    this.setState({ searchValue: val }, async () => {
      const columnList = [
        "discounted_on",
        "Unit",
        "Name",
        "discount_type",
        "discount_code",
        "discount_value",
        "rate",
        "discount",
        "charged_amount",
        "discount_name",
      ];
      if (this.state.searchValue.length) {
        this.setState(
          {
            searchedvalidationList: findBySearch(this.state.searchValue, this.state.validationList, this.state.validationList, columnList, this.props.timezone),
          },
          () => {
            this.setState({
              count: this.state.searchedvalidationList.length,
            });
          },
        );
      } else {
        this.setState({
          count: this.props.validationList.count,
          searchedvalidationList: [],
          validationList: this.props.validationList.rows,
        });
      }
    });
  };

  resetAll = async () => {
    const today = new Date();
    const datebeforeThirtyDays = today.setDate(today.getDate() - this.state.daysDifference);
    this.setState({
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      currentSummaryDate: null,
      selectedPropertyObj: {},
      lot_id: "",
      childcheckbox: true,
      unit_id: "",
      selectedPropertyObj: {
        label: this.state.selectedDomainName,
        value: this.state.PropertyId,
      },
    });
  };

  hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  handleExport = async () => {
    const { lot_id, childcheckbox, currentSummaryDate, startDate, endDate, selectedPropertyObj, PropertyId, selectedDomainName, timezone, unit_id } =
      this.state;
    const pageobj = {
      childcheckbox,
      PropertyId,
      timezone,
      exportData: true,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.PropertyId = selectedPropertyObj.value;
    } else {
      pageobj.PropertyId = PropertyId;
    }
    if (lot_id) {
      pageobj.lot_id = lot_id;
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    }
    if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    if (unit_id) {
      pageobj.unit_id = unit_id;
    }
    const bufferData = await this.props.getReportList(pageobj);
    await this.GetDateRangeWithFormat(pageobj);
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `BATCH-VALIDATION-${currentTimeDate}-${selectedDomainName}.xls`;
    if (bufferData) {
      saveAs(
        new Blob([bufferData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  };

  render() {
    const { validationList, loader, childcheckbox, ticketReportLots, lot_id, startDate, endDate, currentSummaryDate, searchValue, unit_id } = this.state;

    return (
      <div>
        <Card className="mb-0">
          <Card.Body className="pos-rel-overflow-hide">
            <>
              <div className="sidebarFilter">
                <Card className="contact-container mb-0">
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Card.Title as="h4">Discount Coupons</Card.Title>
                      <a
                        onClick={this.hideSlider}
                        href="javascript:void(0);"
                        className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <div className="mb-2">
                          <label className="fancy-checkbox">
                            <input
                              type="checkbox"
                              name={"childcheckbox"}
                              checked={childcheckbox}
                              onChange={this.handleCheckBox}
                              className="custom-control-input"
                              id="childcheckbox1"
                            ></input>
                            <span>
                              <i></i>Include Children Sites
                            </span>
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <PropertDetails selectedPropertyObj={this.state.selectedPropertyObj} changeProperty={this.changeProperty.bind(this)} />
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>LOTS:</Form.Label>
                          <Form.Control
                            as="select"
                            name="lot_id"
                            value={lot_id}
                            onChange={this.handleChangeLot}
                            disabled={ticketReportLots.length < 1}
                            placeholder="Select Lot"
                          >
                            {ticketReportLots?.length ? (
                              <>
                                <option value="">Any</option>
                                {ticketReportLots.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.alias}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>Unit:</Form.Label>
                          <Form.Control
                            as="select"
                            name="unit_id"
                            value={unit_id}
                            onChange={this.handleChangeLot}
                            disabled={this.state.units.length < 1}
                            placeholder="Select Unit"
                          >
                            {this.state?.units?.length ? (
                              <>
                                <option value="">Any</option>
                                {this.state.units.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.suite_id}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Specific Date:</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Date"
                            selected={currentSummaryDate}
                            value={currentSummaryDate}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleCurrentDate}
                            dateFormat="MM/dd/yyyy"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">Start Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select Start Date"
                            selected={startDate}
                            defaultHour={24}
                            value={startDate}
                            disabled={loader}
                            selectsStart
                            timeInputLabel="Time:"
                            onChange={this.handleChangeStartingDates}
                            dateFormat="MM/dd/yyyy"
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label className="mb-0 mr-2">End Date(Date Range):</label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select End Date"
                            selected={endDate}
                            disabled={loader}
                            value={endDate}
                            selectsEnd
                            timeInputLabel="Time:"
                            onChange={this.handleChangeEndDates}
                            // dateFormat="MM/dd/yyyy hh:mm:ss aa"
                            dateFormat="MM/dd/yyyy"
                            // showTimeInput
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            maxDate={new Date()}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-right justify-content-between d-flex">
                    <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={this.resetAll} disabled={loader}>
                      Reset
                    </Button>
                    <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={this.handleSearch} disabled={loader}>
                      Search
                    </Button>
                  </Card.Footer>
                </Card>
              </div>
              <header>
                <div className="active-permit-header sm-flex-column sm-align-items-start">
                  <h5 className="mb-2 mb-md-0">Discount Coupons</h5>
                  <div className="filter-button d-flex flex-wrap">
                    <div className="d-flex align-items-center search-mb-0">
                      <DatatableHeader
                        className="mt-0 mb-0"
                        searchValue={searchValue}
                        HandleChange={this.handleSearchChange}
                        // findMorePressed={this.findMorePressed}
                      />
                    </div>
                    <div className="ml-2">
                      <Button variant="info" className="btn-fill btn-sm" onClick={this.handleExport} disabled={validationList.length < 1} size="medium">
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </header>
            </>
            {loader ? (
              <Loader />
            ) : (
              <>
                {validationList && <div className="citation-list-table has-parent-tab">{this.dataTableHandle(validationList)}</div>}
                <DateRangeAndAmount startDateTime={this.state.startDateTime} endDateTime={this.state.endDateTime} />
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, Logindetails, SuperAdminDetails, CurrentPermitDetails, ReportsDetails }) => {
  const { property_id: PropertyId, timezone, property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { residentList } = CurrentPermitDetails;
  const { getAllProperties, lots, getAllUserlist: units } = SuperAdminDetails;
  const { MainBackground: main_body } = backgroundColor;
  const { ticketBatchValidationList: validationList } = ReportsDetails;
  return {
    PropertyId,
    timezone,
    property,
    validationList,
    main_body,
    getAllProperties,
    residentList,
    lots,
    units,
  };
};

const mapStateToDispatch = {
  setDomainHeader: SuperAdminAction.setDomainHeader,
  get_all_properties: SuperAdminAction.get_all_properties,
  selected_Property: SuperAdminAction.selected_Property,
  get_all_lots: SuperAdminAction.get_all_lots,
  get_all_units: SuperAdminAction.get_all_units,
  getReportList: ReportsAction.getBatchValidationList,
};

export default connect(mapStateToProps, mapStateToDispatch)(BatchValidation);

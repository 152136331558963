import t from "./type";
import apiType from "../APIResponseActions/type";
import { AuthAPI } from "../../../Auth/requestHandler";
import Config from "../../../Auth/config";
import moment from "moment";

export const getMerchantCodes = () => async (dispatch) => {
  try {
    const res = await AuthAPI("domain", "merchant_codes", "GET");
    await dispatch({ type: t.FETCH_MERCHANT_CODE, payload: res });
  } catch (error) {
    console.log("ERROR:::::::::::::::::", error);
  }
};

export const checkCouponValidity = (coupon_code) => async (dispatch) => {
  try {
    const Bearer_Token = localStorage.getItem("basicAuth");
    const data = await new Promise((resolve) => {
      fetch(`${Config.baseURL}/merchant_codes/is-coupon-valid`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(Bearer_Token),
        },
        body: JSON.stringify({ coupon_code }),
      })
        .then((res) => res.json())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("checkCouponValidity API Hit ERROR:::::::::::::::::", error);
        });
    });
    await dispatch({
      type: t.SET_COUPON_VALIDATION_RES,
      payload: data,
    });
    await dispatch({
      type: data?.success ? apiType.API_RESPONSE_SUCCESS : apiType.API_RESPONSE_FAILURE,
      payload: data?.message,
    });
  } catch (error) {
    console.error("checkCouponValidity Main Catch ERROR:::::::::::::::::", error);
  }
};

export const resetCouponCodeDetails = () => (dispatch) => {
  dispatch({ type: t.RESET_COUPON_VALIDATION_RES });
};

export const createValidationCouponPDF = (couponsDataToBeSaved) => async (dispatch) => {
  try {
    const Bearer_Token = localStorage.getItem("basicAuth");
    const data = await new Promise((resolve) => {
      fetch(`${Config.baseURL}/merchant_codes/validation_coupons`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(Bearer_Token),
        },
        body: JSON.stringify({
          ...couponsDataToBeSaved,
        }),
      })
        .then((blobData) => blobData.blob())
        .then((response) => {
          resolve(URL.createObjectURL(response));
        }).catch((error) => {
          console.log("error", error);
        });
    });
    await dispatch({
      type: t.SET_COUPON_PDF,
      payload: data,
    });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "Coupons fetched successfully",
    });
  } catch (error) {
    console.log("ERROR:::::::::::::::::", error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getDiscountRate = (data) => async (dispatch) => {
  try {
    if (Object.keys(data).length === 0) {
      return await dispatch({
        type: t.GET_DISCOUNT_RATE,
        payload: {},
      });
    }
    console.log("Rate Discount body data", data);
    data.startDate = moment(data.startDate).format("YYYY-MM-DD HH:mm:ss");
    data.endDate = moment(data.endDate).format("YYYY-MM-DD HH:mm:ss");
    const url = "report/parking-discount-rate-check";
    const response = await AuthAPI("domain", url, "POST", data);
    console.log("response", response);
    if (response?.success) {
      await dispatch({
        type: t.GET_DISCOUNT_RATE,
        payload: response.data,
      });
    } else {
      await dispatch({
        type: t.GET_DISCOUNT_RATE,
        payload: {},
      });
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: false,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

import t from "./types";
import { AuthAPI } from "../../../Auth/requestHandler";
import apiType from "../APIResponseActions/type";
import moment from "moment";
import moment_timezone from "moment-timezone";

const next_day = (i) => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  dd = Number(dd - i);
  if (dd < 1) {
    const dx = dd * -1;
    mm = today.getMonth();
    dd = new Date(yyyy, mm, 0).getDate();
    dd = Number(dd - dx);
  }
  today = mm + "/" + dd + "/" + yyyy;
  const unix_date = new Date(today).getTime() / 1000;
  return {
    unix_date,
    today,
  };
};

export const fetch_dashboard = (uid, propertyId, timezone) => async (dispatch) => {
  try {
    const days = [];
    let chart_data = [];
    const formatedTime = moment().utcOffset(timezone);
    const convertedTime = moment_timezone.tz(formatedTime, timezone).format();
    const UTCTIME = moment_timezone.tz(convertedTime, "UTC").format();

    const url = `permit/dashboard/all/?current_time=${UTCTIME}&timezone=${timezone}&property_id=${propertyId}`;
    const result = await AuthAPI("domain", url, "GET");
    const current_permit = result.current_permit;
    const visitor_permit = result.current_visitor;
    const past_seven_days = result.past_seven_days;
    for (let i = 0; i < 7; i++) {
      const { today } = next_day(i);
      days.push(today);
      if (past_seven_days.length && past_seven_days[i]) {
        chart_data.push(past_seven_days[i]?.count);
      } else {
        chart_data.push(0);
      }
    }
    chart_data = chart_data.reverse();
    const data = {
      response: chart_data,
      days: days,
      current_permit,
      visitor_permit,
    };
    await dispatch({ type: t.FETCH_DASHBOARD, payload: data });
    await dispatch({ type: apiType.API_RESPONSE_SUCCESS, payload: "" });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

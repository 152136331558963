import React, { useEffect } from "react";
import { Button, Modal, Row, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import * as ReportsAction from "../../Actions/ReportsAction/action";
import Loader from "../Loader/ModalLoader";
import download from "../../../assets/img/svg/ticket-icons/citation-download.svg";
import email from "../../../assets/img/svg/ticket-icons/citation-email.svg";
import cancel from "../../../assets/img/svg/ticket-icons/citation-cancel.svg";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

const ViewPaymentDetails = ({ data, closeModal, handleMailModal }) => {
  const dispatch = useDispatch();
  const paymentData = useSelector(
    (receiptData) => receiptData.ReportsDetails.getPaymentReceiptData
  );
  const [state, setState] = React.useState({
    detectedDevice: "",
    showReceiptModal: false,
    pdfUrl: "",
    isSendMailModalOpen: false,
    pdfLoader: false,
  });

  useEffect(() => {
    setState({
      ...state,
      pdfUrl: paymentData,
      pdfLoader: false,
    });
  }, [paymentData]);

  useEffect(() => {
    const detectedDevice = getMobileOperatingSystem();
    dispatch(
      ReportsAction.getPaymentInfoData({
        ticketNo: data,
        lang: null,
        isMobile: detectedDevice === "iOS" ? 1 : 0,
      })
    );
    setState({
      ...state,
      detectedDevice: detectedDevice,
      pdfLoader: true,
    });
  }, []);

  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || window.opera; // || navigator.vendor;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  };

  return (
    <div style={{ width: !isMobile ? "10px" : "20px" }}>
      <Modal show={true}>
        <Modal.Header className="no-border">
          <Modal.Title as="h4" className="m-0">
            <h4 className="m-0 font-weight-bold">Payment Receipt</h4>
          </Modal.Title>
          <Button
            title={"Close"}
            type="button"
            onClick={closeModal}
            className="close-btn py-0"
            data-dismiss="modal"
          >
            <i className="nc-icon nc-simple-remove"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="modal-data-body">
          {state.pdfLoader ? (
            <PdfContainer>
              <Loader />
            </PdfContainer>
          ) : (
            <div>
              <>
                {state.pdfUrl && !isMobile && (
                  <>
                    <EmbedIframe
                      className={"pdf-frame"}
                      src={state.pdfUrl}
                      // rotate={180}
                    />
                  </>
                )}
                {state.pdfUrl && isMobile && (
                  <>
                    {state.detectedDevice === "iOS" && (
                      <EmbedIframe
                        className={"pdf-frame"}
                        src={state.pdfUrl}
                        // rotate={180}
                      />
                    )}
                    {state.detectedDevice !== "iOS" && (
                      <div>
                        <Document
                          file={state.pdfUrl}
                          // onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page pageNumber={1} />
                        </Document>
                      </div>
                    )}
                  </>
                )}
              </>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <a
            id="pdf"
            href={state.pdfUrl}
            target={"_blank"}
            rel="noopener noreferrer"
            style={{ display: "none" }}
          >
            pdf
          </a>
          <Row>
            <Dropdown className="m-0 mr-2 mb-2">
              <Dropdown.Toggle>
                <img className="ml-0" src={download} width={18} /> Save as
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    document.getElementById("pdf").click();
                  }}
                >
                  PDF
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="m-0 mb-2 mr-1">
              <Dropdown.Toggle>
                <img className="" src={email} width={18} /> Send mail as
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleMailModal}> PDF</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              id="ignoreButton3"
              className="btn-fill mt-sm-0 ml-sm-2 mb-2"
              type="button"
              variant="secondary"
              onClick={closeModal}
            >
              <img className="ml-0 mr-1" src={cancel} width={18} />
              Cancel
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ViewPaymentDetails;
const EmbedIframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 200px);
  rotate: degrees(-45);
`;
const PdfContainer = styled.div`
  width: 100%;
  height: calc(100vh - 200px);
`;

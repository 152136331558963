import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import CustomContentAlert, { navigationButtons } from "../helper";
import { getParkingPermitSetupDetailForProperty } from "../../Selectors/CurrentVisitorPermitSelector";

import {
  getParkingPermitSetupDetails,
  createParkingPermitSetup,
} from "../../Actions/CurrentVisitorPermitActions/actions";

import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import { useLocation } from "react-router";
import useGetAllProperty from "../../Hooks/Property/useGetAllProperty";

const initialFormData = {
  instruction: "",
};

const intialError = {
  instruction: "",
};
const ParkingPermitSetup = () => {
  const [formData, setFormData] = useState({ ...initialFormData });
  const [errorState, setErrorState] = useState({ ...intialError });
  const [isFormValid, setIsFormValid] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const { propertyId } = useGetAllProperty(location.search?.slice(1));

  const ParkingPermitSetupDetail = useSelector(
    getParkingPermitSetupDetailForProperty
  );

  const { api_response, api_error_message, api_success_message } =
    useSelector(getAPIResponse);

  useEffect(() => {
    if (propertyId) {
      dispatch(getParkingPermitSetupDetails(propertyId));
    }
    return () => {
      dispatch({ type: "FETCH_PARKING_PERMIT_SETTING_DETAILS_BLANK" });
    };
  }, []);

  useEffect(() => {
    if (api_response) {
      if (
        ParkingPermitSetupDetail &&
        Object.keys(ParkingPermitSetupDetail).length
      ) {
        setIsUpdate(true);
        setFormData({
          ...formData,
          instruction: ParkingPermitSetupDetail?.instruction,
        });
      } else {
        setIsUpdate(false);
      }
    }
  }, [api_response, ParkingPermitSetupDetail]);

  const handleChange = async ({ target }) => {
    const { name, value } = target;
    const newValue = value;
    if (newValue === "") {
      setIsFormValid(false);
    }
    let obj = { ...formData };

    obj = { ...obj, [name]: newValue };
    handleValidation(obj);
    setFormData({
      ...formData,
      ...obj,
    });
  };

  const handleValidation = (obj) => {
    let errObj = { ...errorState };
    errObj = validateInstructionField(obj, errObj);

    setErrorState({ ...errObj });
    setIsFormValid(!Object.keys(errObj).find((x) => errObj[x].length !== 0));
    return !Object.keys(errObj).find((x) => errObj[x].length === 0);
  };

  const validateInstructionField = (obj, err) => {
    const errObj = { ...err };
    errObj.instruction = validateEmpty(
      obj?.instruction,
      "Instructions are required."
    );
    return errObj;
  };

  const validateEmpty = (data, errorStr) =>
    data?.trim()?.length === 0 ? errorStr : "";

  const handleCreateParkingPermitSetup = async () => {
    const data = {
      property_id: propertyId,
      instruction: formData.instruction,
    };
    dispatch(createParkingPermitSetup(data, isUpdate));
    if (api_response) {
      setShowAlert(true);
      setIsUpdate(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(true);
      }, 4000);
    }
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <Card.Title as="h4">Manage Parking Permit</Card.Title>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="pos-rel-overflow-hide d-flex flex-column">
                <Row>
                  <Col xs="12">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label> Instructions</Form.Label>
                      <span class="asterisk">*</span>
                      <Form.Control
                        as="textarea"
                        value={formData.instruction}
                        name="instruction"
                        onChange={handleChange}
                        rows="25"
                      />
                    </Form.Group>
                    {errorState && errorState.instruction.length > 0 && (
                      <div className="help-block">{errorState.instruction}</div>
                    )}
                  </Col>
                  <Col lg="12" className="">
                    <Button
                      className=""
                      onClick={handleCreateParkingPermitSetup}
                      disabled={!isFormValid}
                    >
                      {isUpdate ? "Update" : "Save"}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    {navigationButtons(
                      "/quick-permit-setup",
                      "",
                      propertyId,
                      "",
                      "Manage ParkingPermit",
                      true
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {showAlert && api_success_message && (
        <CustomContentAlert
          delay={4000}
          message={api_success_message}
          className="toast-success"
        />
      )}
      {showError && api_error_message && (
        <CustomContentAlert
          delay={4000}
          message={api_error_message}
          className="toast-error"
        />
      )}
    </div>
  );
};

export default ParkingPermitSetup;

import React, { Component } from "react";

export class PrivacyFrench extends Component {
  render() {
    const https_url = `worldstream.${process.env.REACT_APP_ENV}`;
    return (
      <div>
        <h1>Politique de confidentialité</h1>
        <p>Dernière mise à jour : 20 décembre 2021</p>
        <p>
          Cette politique de confidentialité décrit nos politiques et procédures
          sur le la collecte, l&apos;utilisation et la divulgation de vos
          informations lorsque vous utilisez le service et vous informe de vos
          droits à la vie privée et de la manière dont la loi Vous protège.
        </p>
        <p>
          Nous utilisons vos données personnelles pour fournir et améliorer le
          service. En utilisant le Service, Vous acceptez la collecte et
          l&apos;utilisation d&apos;informations dans conformément à la présente
          politique de confidentialité.
        </p>
        <h1>Interprétation et définitions</h1>
        <h2>Interprétation</h2>
        <p>
          Les mots dont la lettre initiale est en majuscule ont des
          significations défini dans les conditions suivantes. Les définitions
          suivantes auront la même signification, qu&apos;ils apparaissent ou
          non dans singulier ou au pluriel.
        </p>
        <h2>Définitions</h2>
        <p>Aux fins de la présente politique de confidentialité :</p>
        <ul>
          <li>
            <p>
              <strong>Compte</strong> désigne un compte unique créé pour vous
              afin de accéder à notre Service ou à des parties de notre Service.
            </p>
          </li>
          <li>
            <p>
              <strong>Société</strong> (appelée soit la Société, Nous, Nous ou
              Notre dans cet accord) fait référence à Locomobi World Inc, 4170
              Croissant Sladeview , bureau 2.
            </p>
          </li>
          <li>
            <p>
              Les <strong>cookies</strong> sont de petits fichiers qui sont
              placés sur votre ordinateur, appareil mobile ou tout autre
              appareil par un site Web, contenant les détails de votre
              historique de navigation sur ce site Web parmi ses nombreuses
              utilisations.
            </p>
          </li>
          <li>
            <p>
              <strong>Pays</strong> fait référence à : Ontario, Canada
            </p>
          </li>
          <li>
            <p>
              <strong>Appareil</strong> désigne tout appareil pouvant accéder au
              Service tel qu&apos;un ordinateur, un téléphone portable ou une
              tablette numérique.
            </p>
          </li>
          <li>
            <p>
              Les <strong>données personnelles</strong> sont toutes les
              informations relatives à une personne identifiée ou identifiable.
            </p>
          </li>
          <li>
            <p>
              <strong>Service</strong> fait référence au site Web.
            </p>
          </li>
          <li>
            <p>
              <strong>Prestataire de services</strong> désigne toute personne
              physique ou morale personne qui traite les données pour le compte
              de la Société. Il fait référence à des sociétés tierces ou à des
              personnes employées par la Société pour faciliter le Service,
              fournir le Service au nom du Société, pour exécuter des services
              liés au Service ou pour aider la Société dans l&apos; analyse de
              l&apos;utilisation du Service.
            </p>
          </li>
          <li>
            <p>
              Les <strong>données d&apos;utilisation</strong> font référence aux
              données collectées automatiquement, soit généré par
              l&apos;utilisation du Service, soit par l&apos;infrastructure du
              Service elle-même (par exemple, la durée d&apos;un visite de la
              page).
            </p>
          </li>
          <li>
            <p>
              <strong>Site Web</strong> fait référence à WorldStream ,
              accessible depuis{" "}
              <a
                href={https_url}
                rel="pas de suivi externe pas d'ouverture "
                cible="_blank"
              >
                {https_url}
              </a>
            </p>
          </li>
          <li>
            <p>
              <strong>Vous</strong> signifie la personne accédant ou utilisant
              le Service, ou la société, ou toute autre entité juridique au nom
              de laquelle cette personne accède ou utilise le Service, selon le
              cas.
            </p>
          </li>
        </ul>
        <h1>Collecte et utilisation de vos données personnelles</h1>
        <h2>Types de données collectées</h2>
        <h3>Données personnelles</h3>
        <p>
          Lors de l&apos;utilisation de notre service, nous pouvons vous
          demander de nous fournir certains informations personnellement
          identifiables qui peuvent être utilisées pour contacter ou vous
          identifier. Les informations personnellement identifiables peuvent
          inclure, mais sont pas limité à:
        </p>
        <ul>
          <li>
            <p>Adresse e-mail</p>
          </li>
          <li>
            <p>Prénom et nom</p>
          </li>
          <li>
            <p>Numéro de téléphone</p>
          </li>
          <li>
            <p>Adresse, État, Province, Code postal/ZIP, Ville</p>
          </li>
          <li>
            <p>Données d&apos;utilisation</p>
          </li>
        </ul>
        <h3>Données d&apos;utilisation</h3>
        <p>
          Les données d&apos;utilisation sont collectées automatiquement lors de
          l&apos;utilisation du service.
        </p>
        <p>
          Les données d&apos;utilisation peuvent inclure des informations telles
          que Vos appareils Internet Adresse de protocole ( par exemple ,
          adresse IP), type de navigateur, version du navigateur, pages de notre
          Service que Vous visitez, l&apos;heure et la date de Votre visite, le
          temps passé sur ces pages, les identifiants d&apos;appareil uniques et
          d&apos;autres données diagnostiques.
        </p>
        <p>
          Lorsque vous accédez au service par ou via un appareil mobile, nous
          pouvons collecter automatiquement certaines informations, y compris,
          mais sans s&apos;y limiter au type d&apos;appareil mobile que vous
          utilisez, l&apos;identifiant unique de votre appareil mobile,
          l&apos;adresse IP de Votre appareil mobile, Votre système
          d&apos;exploitation mobile, le type de navigateur Internet mobile que
          vous utilisez, les identifiants uniques de l&apos;appareil et
          d&apos;autres données diagnostiques.
        </p>
        <p>
          Nous pouvons également collecter des informations que votre navigateur
          envoie chaque fois que vous visitez notre service ou lorsque vous
          accédez au service par ou via un appareil mobile.
        </p>
        <h3>Technologies de suivi et cookies</h3>
        <p>
          Nous utilisons des cookies et des technologies de suivi similaires
          pour suivre l&apos;activité sur Notre Service et stocker certaines
          informations. Technologies de suivi utilisés sont des balises, des
          balises et des scripts pour collecter et suivre les informations et
          pour améliorer et analyser Notre Service. Les technologies que nous
          utilisons peuvent inclure:
        </p>
        <ul>
          <li>
            <strong>Cookies ou cookies de navigateur .</strong> Un cookie est un
            petit fichier placé sur votre appareil. Vous pouvez demander à votre
            navigateur de refuser tous les cookies ou pour indiquer quand un
            cookie est envoyé. Toutefois, si Vous n&apos;acceptez pas les
            cookies, vous ne pourrez peut-être pas utiliser certaines parties de
            notre service. Sauf si vous avez ajusté les paramètres de votre
            navigateur afin que il refusera les Cookies, notre Service pourra
            utiliser des Cookies.
          </li>
        </ul>
        <p>
          Les cookies peuvent être des cookies persistants ou de session.
          Cookies persistants rester sur votre ordinateur personnel ou votre
          appareil mobile lorsque vous vous déconnectez, tandis que les cookies
          de session sont supprimés dès que vous fermez votre site Web
          navigateur.{" "}
        </p>
        <p>
          Nous utilisons à la fois des cookies de session et des cookies
          persistants aux fins énoncées au dessous de:
        </p>
        <ul>
          <li>
            <p>
              <strong>Cookies nécessaires/essentiels</strong>
            </p>
            <p>Type : Cookies de session</p>
            <p>Géré par : Nous</p>
            <p>
              Finalité : Ces Cookies sont indispensables pour Vous fournir des
              services disponible sur le site Web et pour vous permettre
              d&apos;utiliser certains de ses fonctionnalités. Ils aident à
              authentifier les utilisateurs et à prévenir les fraudes
              l&apos;utilisation des comptes utilisateurs. Sans ces Cookies, les
              services que Vous ont demandé ne peuvent pas être fournis, et Nous
              n&apos;utilisons que ces Cookies pour vous fournir ces services.
            </p>
          </li>
          <li>
            <p>
              <strong>
                Politique relative aux cookies / Avis d&apos;acceptation des
                cookies
              </strong>
            </p>
            <p>Type : Cookies persistants</p>
            <p>Géré par : Nous</p>
            <p>
              Finalité : Ces cookies identifient si les utilisateurs ont accepté
              l&apos;utilisation de cookies sur le site Web.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies de fonctionnalité</strong>
            </p>
            <p>Type : Cookies persistants</p>
            <p>Géré par : Nous</p>
            <p>
              Finalité : ces cookies nous permettent de mémoriser les choix que
              vous faites lorsque Vous utilisez le site Web, comme la
              mémorisation de vos informations de connexion ou Préférence de
              langue. Le but de ces Cookies est de fournir Vous avec une
              expérience plus personnelle et pour Vous éviter d&apos;avoir à
              ressaisissez vos préférences à chaque fois que vous utilisez le
              site Web.
            </p>
          </li>
        </ul>
        <p>
          Pour plus d&apos;informations sur les cookies que nous utilisons et
          vos choix concernant les cookies, veuillez consulter notre politique
          en matière de cookies ou les cookies section de notre politique de
          confidentialité.
        </p>
        <h2>Utilisation de vos données personnelles</h2>
        <p>
          La Société peut utiliser les Données personnelles aux fins suivantes :
        </p>
        <ul>
          <li>
            <p>
              <strong>Pour fournir et maintenir notre Service</strong>, y
              compris pour surveiller l&apos;utilisation de notre service.
            </p>
          </li>
          <li>
            <p>
              <strong>Pour gérer Votre Compte :</strong> pour gérer Votre
              inscription en tant qu&apos;utilisateur du Service. Les données
              personnelles que vous fournir peut Vous donner accès à différentes
              fonctionnalités du Service qui vous est disponible en tant
              qu&apos;utilisateur enregistré.
            </p>
          </li>
          <li>
            <p>
              <strong>Pour l&apos;exécution d&apos;un contrat :</strong> le
              élaboration, mise en conformité et engagement du contrat
              d&apos;achat pour les produits, articles ou services que vous avez
              achetés ou de tout autre contrat avec Nous par le biais du
              Service.
            </p>
          </li>
          <li>
            <p>
              <strong>Pour vous contacter :</strong> pour vous contacter par
              e-mail, appels téléphoniques, SMS ou autres formes équivalentes de
              communication, comme les notifications push des applications
              mobiles concernant les mises à jour ou les communications
              informatives liées à la fonctionnalités, produits ou services
              sous-traités, y compris les mises à jour de sécurité, lorsque cela
              est nécessaire ou raisonnable pour leur la mise en oeuvre.
            </p>
          </li>
          <li>
            <p>
              <strong>Pour vous fournir</strong> des nouvelles, des offres
              spéciales et des informations générales sur d&apos;autres biens,
              services et événements qui nous proposons des produits similaires
              à ceux que vous avez déjà achetés ou demandé à moins que vous
              n&apos;ayez choisi de ne pas recevoir ces information.
            </p>
          </li>
          <li>
            <p>
              <strong>Pour gérer vos demandes :</strong> Pour assister et gérer
              Vos demandes à Nous.
            </p>
          </li>
          <li>
            <p>
              <strong>Pour les transferts d&apos;entreprise :</strong> Nous
              pouvons utiliser Votre informations pour évaluer ou réaliser une
              fusion, une cession, restructuration, réorganisation, dissolution
              ou autre vente ou transfert de tout ou partie de nos actifs, que
              ce soit en tant qu&apos;entreprise en activité ou dans le cadre
              d&apos;une faillite, d&apos;une liquidation ou d&apos;une
              procédure similaire, en quelles données personnelles que nous
              détenons sur nos utilisateurs du service sont parmi les actifs
              transférés.
            </p>
          </li>
          <li>
            <p>
              <strong>À d&apos;autres fins</strong> : nous pouvons utiliser vos
              informations à d&apos;autres fins, telles que l&apos;analyse des
              données, l&apos;identification de l&apos;utilisation tendances,
              déterminant l&apos;efficacité de nos campagnes promotionnelles et
              pour évaluer et améliorer notre service, nos produits, nos
              services, commercialisation et votre expérience.
            </p>
          </li>
        </ul>
        <p>
          Nous pouvons partager vos informations personnelles dans les
          situations suivantes :
        </p>
        <ul>
          <li>
            <strong>Avec les fournisseurs de services :</strong> nous pouvons
            partager vos données personnelles informations avec les fournisseurs
            de services pour surveiller et analyser l&apos;utilisation de notre
            service, pour vous contacter.
          </li>
          <li>
            <strong>Pour les transferts d&apos;entreprise :</strong> nous
            pouvons partager ou transférer Vos informations personnelles dans le
            cadre ou pendant les négociations de, toute fusion, vente
            d&apos;actifs de la Société, financement ou acquisition de tout ou
            partie de Notre activité à une autre société.
          </li>
          <li>
            <strong>Avec des affiliés :</strong> nous pouvons partager vos
            informations avec Nos affiliés, auquel cas nous demanderons à ces
            affiliés de honorer cette politique de confidentialité. Les sociétés
            affiliées comprennent notre société mère et toute autre filiale,
            coentreprise ou autre société que Nous contrôlons ou qui sont sous
            contrôle commun avec Nous.
          </li>
          <li>
            <strong>Avec des partenaires commerciaux :</strong> nous pouvons
            partager votre informations avec nos partenaires commerciaux pour
            vous offrir certains produits, services ou promotions.
          </li>
          <li>
            <strong>Avec d&apos;autres utilisateurs :</strong> lorsque vous
            partagez des informations personnelles informations ou interagir
            autrement dans les espaces publics avec d&apos;autres utilisateurs,
            ces informations peuvent être vues par tous les utilisateurs et
            peuvent être distribué publiquement à l&apos;extérieur.
          </li>
          <li>
            <strong>Avec votre consentement</strong> : nous pouvons divulguer
            vos données personnelles informations à toute autre fin avec votre
            consentement.
          </li>
        </ul>
        <h2>Conservation de vos données personnelles</h2>
        <p>
          La Société ne conservera Vos Données Personnelles que le temps
          nécessaires aux fins énoncées dans la présente politique de
          confidentialité. Nous allons conserver et utiliser vos données
          personnelles dans la mesure nécessaire pour se conformer à nos
          obligations légales (par exemple, si nous sommes tenus de conserver
          vos données pour se conformer aux lois applicables), résoudre les
          litiges et appliquer nos accords et politiques juridiques.
        </p>
        <p>
          La Société conservera également les données d&apos;utilisation à des
          fins d&apos;analyse interne fins. Les données d&apos;utilisation sont
          généralement conservées pendant une période plus courte de moment,
          sauf lorsque ces données sont utilisées pour renforcer la sécurité ou
          pour améliorer la fonctionnalité de Notre Service, ou Nous sommes
          légalement obligés de conserver ces données plus longtemps.
        </p>
        <h2>Transfert de vos données personnelles</h2>
        <p>
          Vos informations, y compris les données personnelles, sont traitées au
          Les bureaux d&apos;exploitation de la société et dans tous les autres
          endroits où les parties impliqués dans le traitement se trouvent. Cela
          signifie que ces informations peuvent être transférés et conservés sur
          des ordinateurs situés à l&apos;extérieur de votre état, province,
          pays ou autre juridiction gouvernementale où les lois sur la
          protection des données peuvent différer de celles de Votre
          juridiction.
        </p>
        <p>
          Votre consentement à cette politique de confidentialité suivi de votre
          soumission de ces informations représentent votre accord pour ce
          transfert.
        </p>
        <p>
          La Société prendra toutes les mesures raisonnablement nécessaires pour
          s&apos;assurer que Vos données sont traitées en toute sécurité et
          conformément à cette politique de confidentialité politique et aucun
          transfert de vos données personnelles n&apos;aura lieu à un
          organisation ou un pays, sauf si des contrôles adéquats sont en place
          y compris la sécurité de vos données et autres informations
          personnelles.
        </p>
        <h2>Divulgation de vos données personnelles</h2>
        <h3>Transactions commerciales</h3>
        <p>
          Si la Société est impliquée dans une opération de fusion,
          d&apos;acquisition ou de cession d&apos;actifs, Vos données
          personnelles peuvent être transférées. Nous vous aviserons avant Vos
          données personnelles sont transférées et font l&apos;objet d&apos;un
          autre Politique de confidentialité.
        </p>
        <h3>Application de la loi</h3>
        <p>
          Dans certaines circonstances, la Société peut être tenue de divulguer
          Vos données personnelles si la loi l&apos;exige ou en réponse à une
          les demandes des autorités publiques ( par exemple , un tribunal ou un
          organisme gouvernemental).
        </p>
        <h3>Autres exigences légales</h3>
        <p>
          La Société peut divulguer vos données personnelles en toute bonne foi
          qu&apos;une telle action est nécessaire pour :
        </p>
        <ul>
          <li>Se conformer à une obligation légale</li>
          <li>Protéger et défendre les droits ou la propriété de la Société</li>
          <li>
            Prévenir ou enquêter sur d&apos;éventuels actes répréhensibles liés
            à la Un service
          </li>
          <li>
            Protéger la sécurité personnelle des Utilisateurs du Service ou du
            public
          </li>
          <li>Protection contre la responsabilité légale</li>
        </ul>
        <h2>Sécurité de vos données personnelles</h2>
        <p>
          La sécurité de vos données personnelles est importante pour nous, mais
          rappelez-vous qu&apos;aucune méthode de transmission sur Internet, ou
          méthode de le stockage électronique est 100% sécurisé. Alors que nous
          nous efforçons d&apos;utiliser commercialement moyens acceptables pour
          protéger vos données personnelles, nous ne pouvons garantir sa
          sécurité absolue.
        </p>
        <h1> Confidentialité des enfants </h1>
        <p>
          Notre service ne s&apos;adresse à personne de moins de 13 ans. Nous ne
          recueillir sciemment des informations personnellement identifiables
          auprès de quiconque moins de 13 ans. Si vous êtes un parent ou un
          tuteur et que vous savez que votre enfant nous a fourni des données
          personnelles, veuillez nous contacter. Si nous apprenons que nous
          avons collecté des données personnelles auprès de quiconque de moins
          de 13 ans sans vérification du consentement parental, nous prenons
          étapes pour supprimer ces informations de nos serveurs.
        </p>
        <p>
          Si nous devons nous fier au consentement comme base juridique pour le
          traitement de votre informations et votre pays nécessite le
          consentement d&apos;un parent, nous pouvons exiger le consentement de
          vos parents avant de collecter et d&apos;utiliser information.
        </p>

        <h1>Modifications de cette politique de confidentialité</h1>
        <p>
          Nous pouvons mettre à jour notre politique de confidentialité de temps
          à autre. Nous vous aviserons de tout changement en publiant la
          nouvelle politique de confidentialité sur cette page.
        </p>
        <p>
          Nous Vous informerons par e-mail et/ou par un avis visible sur Notre
          Service, avant que le changement ne devienne effectif et mettre à jour
          le dernier date de mise à jour en haut de cette politique de
          confidentialité.
        </p>
        <p>
          Il vous est conseillé de consulter périodiquement cette politique de
          confidentialité pour toute changements. Les modifications apportées à
          cette politique de confidentialité entrent en vigueur
          lorsqu&apos;elles sont publié sur cette page.
        </p>
        <h1>Contactez-nous</h1>
        <p>
          Si vous avez des questions concernant cette politique de
          confidentialité, vous pouvez contacter nous:
        </p>
        <ul>
          <li>Par e-mail : support@{https_url} </li>
        </ul>
      </div>
    );
  }
}

export default PrivacyFrench;

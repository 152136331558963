import React, {
  useCallback,
  useEffect, // useEffect,
  useState,
} from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { createGroupShare } from "../../Actions/PermitOptionsActions/actions";
import { useDispatch, useSelector } from "react-redux";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import { Translation } from "react-i18next";

const PermitShareGroupModal = ({
  closeModal,
  // unitPaymentSetting,
  lotPackage,
  property_id,
  formData,
}) => {
  const [apiCalled, setApiCalled] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [state, setState] = React.useState({
    unit_id: "",
    max_allowed_in: "",
    lot_id: "",
    package_id: "",
    group_name: "",
    group_name_error: "",
    max_allowed_error: "",
  });

  const dispatch = useDispatch();
  const [formValid, setFormValid] = useState(true);
  const apiResponse = useSelector(getAPIResponse);

  const permitGroupSubmit = async () => {
    const permitGroupData = {
      unit_id: formData.suite_id,
      max_allowed_in: state.max_allowed_in,
      lot_id: lotPackage?.lot_id,
      package_id: lotPackage?.id,
      property_id: property_id,
      group_name: state.group_name,
    };
    dispatch(createGroupShare(permitGroupData));
    setApiCalled(true);
    console.log("formdata", state);
  };

  const handleChange = useCallback((e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.name === "max_allowed_in" ? e.target.value.replace(/\D/, "") : e.target.value,
    }));
    if (!e.target.value && e.target.name === "max_allowed_in") {
      setState((prevState) => ({
        ...prevState,
        max_allowed_error: "Max Allowed In is Required",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        max_allowed_error: "",
      }));
    }

    if (!e.target.value && e.target.name === "group_name") {
      setState((prevState) => ({
        ...prevState,
        group_name_error: "Group Name is Required",
      }));
    } else if (e.target.value && e.target.name === "group_name") {
      setState((prevState) => ({
        ...prevState,
        group_name_error: "",
      }));
    }
  });

  useEffect(() => {
    if (state.max_allowed_in && state.group_name && !state.group_name_error && !state.max_allowed_error) {
      setFormValid(false);
    }
  }, [state.max_allowed_in, state.group_name]);

  useEffect(() => {
    if (apiCalled === true) {
      setApiCalled(false);
      setIsSuccess(apiResponse?.api_response === true);
      setIsFailed(apiResponse?.api_response === false);
      setTimeout(() => {
        closeModal();
        setIsSuccess(false);
        setIsFailed(false);
        console.log(isFailed, isSuccess);
      }, 3000);
    }
  }, [apiResponse]);

  return (
    <React.Fragment>
      <Modal
        id="ticketdetails"
        lg={"medium"}
        show={true}
        // onHide={closeModal}
        dialogClassName="modal-90w"
        fullscreen={"sm-down"}
        className="modalData"
      >
        <Modal.Header id="import" className="d-flex justify-content-between bg-white align-items-center">
          <h5 className="m-0 font-weight-bold">
            <Modal.Title className="m-0">
              <Translation>{(t) => <>{t("Add Permit Share")}</>}</Translation>
            </Modal.Title>
          </h5>
          <Translation>
            {(t) => (
              <Button id="modal" title={t("Close")} type="button" onClick={closeModal} className="close-btn py-0" data-dismiss="modal">
                <i className="nc-icon nc-simple-remove text-danger" />
              </Button>
            )}
          </Translation>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form>
            <br />
            <Form.Label>
              <Translation>{(t) => <>{t("Group Name")}</>}</Translation>
              <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control type="text" name="group_name" value={state.group_name} onChange={handleChange} autoComplete="off" required />
            <div className="help-block">{state.group_name_error}</div>
            <Form.Label>
              <Translation>{(t) => <>{t("Max Allowed In")}</>}</Translation>
              <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control type="text" name="max_allowed_in" value={state.max_allowed_in} onChange={handleChange} autoComplete="off" required />
            <div className="help-block">{state.max_allowed_error}</div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button disabled={formValid} onClick={permitGroupSubmit}>
            <Translation>{(t) => <>{t("Submit")}</>}</Translation>
          </Button>
          <Button onClick={closeModal}>
            <Translation>{(t) => <>{t("Cancel")}</>}</Translation>
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default PermitShareGroupModal;

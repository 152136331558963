import React, { useEffect, useRef } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getQuickPermitSetupDetail } from "../../Selectors/SelfRegisterDetails";
import * as UserProfileActions from "../../Actions/UserProfileActions/actions";
import * as SelfRegisterActions from "../../Actions/SelfRegisterActions/actions";
import { routing_using_history } from "../helper";

const SuccessPage = (props) => {
  const dispatch = useDispatch();

  const property_id = useSelector(
    (state) => state?.Logindetails?.login_info?.id
  );
  const { parking_message } = useSelector(getQuickPermitSetupDetail);

  useEffect(() => {
    if (!property_id) {
      dispatch(UserProfileActions.getLoginInfo());
    } else if (!parking_message) {
      dispatch(SelfRegisterActions.getQuickPermitSetup(property_id));
    }
  }, [property_id, parking_message]);

  const timer = useRef(0);
  useEffect(() => {
    if (props?.allPermissions?.includes("self_register")) {
      timer.current = setTimeout(() => {
        routing_using_history(`/self-register`);
      }, 10 * 1000);
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [props]);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <Card className="info-container" style={{ alignItems: "center" }}>
              <Card.Body className="mt-5 info-container">
                <p className="h2">{parking_message}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SuccessPage;

import { AuthAPI, BufferAuthAPI } from "../../../Auth/requestHandler";
import actionCreator from "../Action.Helper";

// actions and types
const addUpdateDeleteResponse = actionCreator("ADD_UPDATE_DELETE_RES");
const getGateList = actionCreator("GET_GATE_LIST");
const gateSchedulerList = actionCreator("GATE_SCHEDULER_LIST");
const updateSchedulerList = actionCreator("UPDATE_LIST");
export const fetch_Gates = (property_id) => async (dispatch) => {
  try {
    const url = `user/get-gate/${property_id}`;
    const result = await AuthAPI("domain", url, "GET");
    if (result.length) {
      dispatch(
        getGateList.success({
          data: result,
          message: "Data fetched successfully",
        })
      );
    } else {
      dispatch(
        getGateList.success({
          data: [],
          message: "No data found!",
        })
      );
    }
  } catch (error) {
    console.log("error ====>", error);
    dispatch(
      getGateList.failure({
        data: error,
        message: error.message || "Somthing went wrong!",
      })
    );
  }
};
export const createGateScheduler = (data) => async (dispatch) => {
  try {
    const url = "user/gate-scheduler";
    const result = await AuthAPI("domain", url, "POST", data);
    if (result) {
      if (data?.type === "add") {
        dispatch(
          addUpdateDeleteResponse.success({
            message: result.message,
          })
        );
      } else {
        dispatch(
          addUpdateDeleteResponse.success({
            message: result.message,
          })
        );
        dispatch(
          updateSchedulerList.success({
            data: data.group_id,
          })
        );
      }
    }
  } catch (error) {
    console.log("error", error);
    dispatch(
      addUpdateDeleteResponse.failure({
        data: error,
        message: error.message || "Somthing went wrong!",
      })
    );
  }
};

export const getGateSchedulerList = (data) => async (dispatch) => {
  try {
    const url = `user/get-scheduler/${data?.property_id}`;
    if (data?.exports) {
      return await BufferAuthAPI("domain", url, "POST", data);
    } else {
      const result = await AuthAPI("domain", url, "POST", data);
      if (result.length) {
        dispatch(
          gateSchedulerList.success({
            data: result,
            message: "Successfully get Gate Scheduler data.",
          })
        );
      } else {
        dispatch(
          gateSchedulerList.success({
            data: [],
            message: "No data Found.",
          })
        );
      }
    }
  } catch (error) {
    console.log("resut error", error);
    dispatch(
      gateSchedulerList.failure({
        data: error,
        message: error.message || "Somthing went wrong!",
      })
    );
  }
};

export const deleteGateScheduler = (data) => async (dispatch) => {
  try {
    const url = "user/gate-scheduler";
    const result = await AuthAPI("domain", url, "Delete", data);
    if (result) {
      dispatch(
        addUpdateDeleteResponse.success({
          message: result.message,
        })
      );
    }
  } catch (error) {
    console.log("error", error);
    dispatch(
      addUpdateDeleteResponse.failure({
        data: error,
        message: error.message || "Somthing went wrong!",
      })
    );
  }
};

export const clearAddUpdateDeleteRes = () => async (dispatch) => {
  dispatch(
    addUpdateDeleteResponse.request({
      success: null,
      message: "",
    })
  );
};

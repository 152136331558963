/** @format */

import t from "./types";
import { AuthAPI } from "../../../Auth/requestHandler";
import apiType from "../APIResponseActions/type";

export const create_passagepass_validation =
  (data, property_id) => async (dispatch) => {
    try {
      console.log(data);
      const url = `passagepassvalidation/property_id/${property_id}`;
      if (!data) {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: "Required fields can not be empty",
        });
      } else {
        const body = {
          validationid: data.validation_id,
          issuersubaccount: data.selected_suite_id,
          matchphone: data.guestnumber,
          notify: data.notify_logic,
          property_id: property_id,
          guestname: data.guestname,
          fullname: data.fullname,
          guestnumber: data.guestnumber,
        };

        const response = await AuthAPI("domain", url, "POST", body);
        if (response.action === "add") {
          await dispatch({
            type: t.CREATEPASSAGEPASSVALIDATION,
            payload: response.data,
          });
          await dispatch({
            type: apiType.API_RESPONSE_SUCCESS,
            payload: response.msg,
          });
        } else {
          await dispatch({
            type: apiType.API_RESPONSE_FAILURE,
            payload: response.message,
          });
        }
      }
    } catch (error) {
      await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
    }
  };
